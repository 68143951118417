export const PAGE_TITLE = 'Set up payments for your client'
export const SUB_HEADER =
  'Help your landlord clients simplify payment collection with RentSpree. It’s free, secure, and reliable.'
export const PANEL_ERROR_MESSAGE =
  'Looks like a payment setup is already in progress for this property. We suggest checking in with the landlord, as they might be setting it up themselves.'

export const ADDITIONAL_PAYMENTS_LIMIT = 5

export const ADD_ANOTHER_MOVE_IN_FEE_FORM_BTN_TEXT = 'Add another one-time fee'
export const ADD_ANOTHER_RECURRING_PAYMENT_FORM_BTN_TEXT = 'Add another recurring payment'

export const DEFAULT_ONE_TIME_CATEGORY_PAYMENT_SECTION_TITLE = 'Security deposit'
export const DEFAULT_RECURRING_CATEGORY_PAYMENT_SECTION_TITLE = 'Rent'

export const DEFAULT_ADDITIONAL_ONE_TIME_PAYMENT_SECTION_TITLE = 'Additional one-time fee'
export const DEFAULT_ADDITIONAL_RECURRING_PAYMENT_SECTION_TITLE = 'Additional recurring payments'

export const QUOTATION_LABELS = {
  CATEGORY: 'Category',
  OTHER_CATEGORY_TEXT: 'Specify category',
}

export const PAYMENT_CATEGORIES = {
  RENT: 'Rent',
  SECURITY_DEPOSIT: 'Security deposit',
  PRORATED_RENT: 'Prorated rent',
  LATE_FEE: 'Late fee',
  MOVE_IN_FEE: 'Move-in fee',
  UTILITIES: 'Utilities',
  PET_FEE: 'Pet fee',
  PARKING: 'Parking',
  OTHER: 'Other',
}

export const VALUE_PROPOSITION_TEXT =
  'Go the extra mile to make your client’s experience hassle-free with RentSpree payments. It’s free, secure and reliable.'

export const DRAFT_PAYMENT_FORM_SECTION_ID = {
  PROPERTY: 'property',
  TENANT: 'tenant',
  LANDLORD: 'landlord',
  QUOTATIONS: 'quotations',
}
