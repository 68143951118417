import { combineReducers } from 'redux'
import head from 'lodash/head'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import includes from 'lodash/includes'
import without from 'lodash/without'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import { APPLICATION_TYPE } from 'constants/application-type'
import * as Types from '../constants/action-types'
import { PAYER } from '../constants/request-const'
import { PRICING_SCHEME } from '../constants/screening-plan-consts'
const payer = (state = null, action) => {
  switch (action.type) {
    case Types.SELECT_RENTER_AS_PAYER:
      return PAYER.RENTER
    case Types.SELECT_AGENT_AS_PAYER:
      return PAYER.AGENT
    case Types.CLEAR_REQUEST_OPTIONS:
      return null
    case Types.GET_USER_PREFERENCE_SUCCESS:
      return action.data.result.payerType
    default:
      return state
  }
}

const applicationType = (state = null, action) => {
  switch (action.type) {
    case Types.SET_APPLICATION_TYPE:
      return action.applicationType
    case Types.GET_USER_PREFERENCE_SUCCESS: {
      const isApplicationSelected = action.data.result.defaultScreeningOption.application
      const preferredApplicationType =
        action.data.result.applicationType || APPLICATION_TYPE.RENTSPREE
      return isApplicationSelected ? preferredApplicationType : null
    }
    default:
      return state
  }
}
/**
 * itemList save the item that should be shown as the selection for the screening option
 * @param {Array} state the initial state
 * @param {Object} action the action
 * @returns {Array} the available selection item object
 */
const itemList = (state = [PRICING_SCHEME], action) => {
  switch (action.type) {
    case Types.GET_SCREENING_PLAN_SUCCESS:
      if (action.data) {
        const firstPriceItem = head(action.data)
        // if the payer type return as all, ensure that there wil be two records with different payer type
        if (firstPriceItem && firstPriceItem.payerType === 'all') {
          const cloneArray = [cloneDeep(firstPriceItem), cloneDeep(firstPriceItem)]
          cloneArray[0].payerType = 'renter'
          cloneArray[1].payerType = 'landlord'
          return cloneArray
        }
        return action.data
      }
      return state
    default:
      return state
  }
}

/**
 * selectingItems collect the choice user selecting in the form
 * This reducer will handle the select true and select false of each options
 * @param {Array} state the current state
 * @param {Object} action the action
 * @returns {Array} the array of selecting object
 */
const selectingItems = (state = [], action) => {
  const { item, currentPriceScheme } = action
  let selectingOptions = []
  const defaultScreeningOption = get(action, 'data.result.defaultScreeningOption', {})
  switch (action.type) {
    case Types.SELECT_PRICING_SCHEME:
      if (includes(state, item)) {
        // this is a deselection
        if (item === 'credit_report') {
          // also remove eviction and criminal
          return without(state, 'credit_report', 'criminal', 'eviction')
        }
        return without(state, item)
      }
      if (includes(['criminal', 'eviction'], item) && !includes(state, 'credit_report')) {
        // if the selecting item is criminal & eviction and in the array does not have a credit report
        // disallow selecting them
        return state
      }
      if (item === 'credit_report') {
        // should add eviction and criminal too
        return [
          ...state,
          !currentPriceScheme.credit_report.disabled && 'credit_report',
          !currentPriceScheme.criminal.disabled && 'criminal',
          !currentPriceScheme.eviction.disabled && 'eviction',
        ]
      }
      // this is a addition
      return [...state, item]
    case Types.GET_USER_PREFERENCE_SUCCESS:
      selectingOptions = keys(pickBy(defaultScreeningOption, t => t))
      if (!includes(selectingOptions, 'credit_report')) {
        // if no credit report select return without eviction and criminal
        selectingOptions = without(selectingOptions, 'criminal', 'eviction')
      }
      return selectingOptions
    default:
      return state
  }
}
const selectedProperty = (state = {}, action) => {
  const { selectedProperty: payloadSelectedProperty, type } = action
  switch (type) {
    case Types.SAVE_SELECTED_PROPERTY: {
      return payloadSelectedProperty
    }
    default: {
      return state
    }
  }
}

const selectedDocumentProOption = (state = {}, action) => {
  const { type, documentKey, change } = action
  switch (type) {
    case Types.UPDATE_DOCUMENT_PRO_OPTION:
      return isEmpty(state[documentKey])
        ? {
          ...state,
          [documentKey]: { ...change },
        }
        : {
          ...state,
          [documentKey]: { ...state[documentKey], ...change },
        }
    case Types.CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS:
      return {}
    default:
      return state
  }
}

const selectedRefCheckProOption = (state = {}, action) => {
  const { type, refCheckKey, isSelected = false } = action
  switch (type) {
    case Types.UPDATE_REF_CHECK_PRO_OPTION:
      return { ...state, [refCheckKey]: isSelected }
    case Types.CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS:
      return {}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case Types.GET_SCREENING_PLAN_REQUEST:
    case Types.CREATE_SCREENING_REQUEST_REQUEST:
      return true
    case Types.GET_SCREENING_PLAN_FAILED:
    case Types.CREATE_SCREENING_REQUEST_FAILED:
    case Types.GET_SCREENING_PLAN_SUCCESS:
    case Types.CREATE_SCREENING_REQUEST_SUCCESS:
      return false
    default:
      return state
  }
}

const isGetScreeningPlanError = (state = false, action) => {
  switch (action.type) {
    case Types.GET_SCREENING_PLAN_FAILED:
      return true
    case Types.GET_SCREENING_PLAN_REQUEST:
      return false
    case Types.GET_SCREENING_PLAN_SUCCESS:
      return action.data && Array.isArray(action.data) && action.data.length === 0
    default:
      return state
  }
}

const isValidationError = (state = false, action) => {
  switch (action.type) {
    case Types.SET_SCREENING_OPTION_VALIDATION_ERROR:
      return action.hasValidationError
    case Types.CLEAR_SCREENING_OPTION_VALIDATION_ERROR:
      return false
    default:
      return state
  }
}

const isAcceptedBackgroundDisclaimer = (state = null, action) => {
  switch (action.type) {
    case Types.GET_USER_PREFERENCE_SUCCESS:
      return get(action, 'data.result.isAcceptedBackgroundDisclaimer', false)
    case Types.UPDATE_USER_PREFERENCE_SUCCESS:
      return get(action, 'data.result.isAcceptedBackgroundDisclaimer', false)
    default:
      return state
  }
}

export default combineReducers({
  payer,
  applicationType,
  isFetching,
  isGetScreeningPlanError,
  isValidationError,
  itemList,
  selectingItems,
  selectedProperty,
  isAcceptedBackgroundDisclaimer,
  selectedDocumentProOption,
  selectedRefCheckProOption,
})
