import React from "react"
import { useSelector } from "react-redux"
import { DropBox } from "containers/drag-n-drop/drop-box"
import { DocumentPage } from "components/envelope"
import { BASE_DIGIDOC_URL } from "constants/route"

import {
  makeSelectEnvelopeFiles,
  makeSelectIsUsingTemplate,
} from "../selectors"

const DocumentPageWithDrop = DropBox(DocumentPage)

export const MemoizedDocumentPage = React.memo(
  ({
    actions,
    viewMode,
    uniquePageKey,
    page,
    fileIndex,
    pageIndex,
    recipientsList,
    validationErrors,
    showSignError,
    signErrors,
    isUsingTemplate,
    ratio = 1,
    targetRef,
  }) => {
    const defaultWidth = 800
    const originalWidth = page.width
    const originalHeight = page.height
    const width = Math.round(ratio * defaultWidth)
    const height = Math.round((originalHeight * width) / originalWidth)
    return (
      <DocumentPageWithDrop
        targetRef={targetRef}
        id={uniquePageKey}
        page={`${fileIndex},${pageIndex}`}
        recipientsList={recipientsList}
        dropBoxes={page.fields}
        width={width}
        ratio={ratio}
        height={height}
        actions={actions}
        viewMode={viewMode}
        validationErrors={validationErrors}
        showSignError={showSignError}
        signErrors={signErrors}
        small={isUsingTemplate}>
        <img
          width={width}
          height={height}
          src={`${BASE_DIGIDOC_URL}${page.imgSrc}`}
          alt={uniquePageKey}
          draggable="false"
        />
      </DocumentPageWithDrop>
    )
  },
)

const DropBoxWrapper = ({
  ratio = 1,
  actions,
  recipientsList,
  viewMode,
  validationErrors,
  showSignError,
  signErrors,
}) => {
  const files = useSelector(makeSelectEnvelopeFiles())
  const isUsingTemplate = useSelector(makeSelectIsUsingTemplate())
  return files.map(({ pages = [], order }, i) =>
    pages.map((page, j) => {
      const targetRef = React.useRef()
      const uniquePageKey = `page-${order}-${page.page}`
      return (
        <MemoizedDocumentPage
          ratio={ratio}
          targetRef={targetRef}
          key={uniquePageKey}
          actions={actions}
          viewMode={viewMode}
          uniquePageKey={uniquePageKey}
          page={page}
          fileIndex={i}
          pageIndex={j}
          recipientsList={recipientsList}
          validationErrors={validationErrors}
          showSignError={showSignError}
          signErrors={signErrors}
          isUsingTemplate={isUsingTemplate}
        />
      )
    }),
  )
}

export default React.memo(DropBoxWrapper)
