import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import createAction from '../apis/utils/create-action'
import * as Types from '../constants/action-types'
import * as api from '../apis/rental-submission.api'
import * as ERROR from '../constants/error-messages'
import { ALERT_PRESET } from '../libs/sweet-alert/sweet-alert'
import tracker from '../tracker'

export const fetchRequestApplicants = createAction(
  api.ofSend,
  [Types.SEND_APPLICANTS_REQUEST, Types.SEND_APPLICANTS_SUCCESS, Types.SEND_APPLICANTS_FAILED],
  data => data,
)

export const sendTextMessageApi = createAction(
  api.ofSend,
  [
    Types.SEND_TEXT_MESSAGE_REQUEST,
    Types.SEND_TEXT_MESSAGE_SUCCESS,
    Types.SEND_TEXT_MESSAGE_FAILED,
  ],
  data => data,
)

const handleSendRequestError = dispatch =>
  dispatch({
    type: Types.OPEN_SWEET_ALERT,
    preset: ALERT_PRESET.ERROR,
    option: {
      title: ERROR.REQUEST.SEND_EMAILS.TITLE,
      content: {
        element: 'div',
        attributes: {
          innerHTML: ERROR.REQUEST.SEND_EMAILS.MESSAGE,
        },
      },
    },
  })

export const sendEmails =
  (...args) =>
  (dispatch, getState) =>
    fetchRequestApplicants(...args)(dispatch, getState).then((res = {}) => {
      if (res.status >= 400) {
        handleSendRequestError(dispatch)
      } else {
        tracker.trackEvent(EVENT_REQUEST_SCREENING.SHARE_APPLY_LINK, {
          sharing_method: EVENT_REQUEST_SCREENING.EVENT_PROPERTY.SHARING_METHOD.SEND_EMAIL,
        })
      }
      return res
    })

export const sendTextMessage =
  (...args) =>
  (dispatch, getState) =>
    sendTextMessageApi(...args)(dispatch, getState).then((res = {}) => {
      if (res.status >= 400) {
        handleSendRequestError(dispatch)
      } else {
        tracker.trackEvent(EVENT_REQUEST_SCREENING.SHARE_APPLY_LINK, {
          sharing_method: EVENT_REQUEST_SCREENING.EVENT_PROPERTY.SHARING_METHOD.SEND_SMS,
        })
      }
      return res
    })

export const sendMoreEmails = () => ({ type: Types.INIT_SEND_APPLICANTS })
export const sendMoreTextMessage = () => ({
  type: Types.SEND_MORE_TEXT_MESSAGE,
})
export const clearIsSendEmail = () => ({ type: Types.CLEAR_IS_SEND_EMAIL })
