import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'

import CreateAssociationContactFormTemplate from 'components/contacts/templates/create-association-contact-form-template'
import tracker from 'tracker'
import { EVENT_LISTING_OVERVIEW } from 'tracker/const'
import { withConnect, withReducer, withSaga } from './connect'

export const CreateAssociationContactModal = ({
  isOpen,
  isSubmitting,
  actions,
  isCreating,
  propertyId,
  associationContact,
}) => {
  const { EVENT_NAME, EVENT_PROPERTY } = EVENT_LISTING_OVERVIEW
  const handleCloseCreateContactModal = () => {
    // eslint-disable-next-line no-unused-expressions
    actions?.closeCreateAssociationContactModal()
  }
  const handleCreateAssociationContactFromModal = value => {
    actions.createAssociationContactFromModal(value)
  }
  const handleEditAssociationContactFromModal = value => {
    actions.editAssociationContactFromModal(value)
  }
  const associationContactDetails = associationContact
  return (
    <RentSpreeBaseModal
      isOpen={isOpen}
      overflow="visible"
      onClose={handleCloseCreateContactModal}
      modalSize="medium"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <CreateAssociationContactFormTemplate
        id="create-association-contact-modal"
        isCreating={isCreating}
        values={associationContactDetails}
        onCreateContact={values => {
          tracker.trackEvent(EVENT_NAME.CLICK_SAVE_LANDLORD_DETAILS, {
            click_from: EVENT_PROPERTY.CLICK_FROM.ADD,
          })
          handleCreateAssociationContactFromModal({ ...values, propertyId })
        }}
        onUpdateContact={values => {
          handleEditAssociationContactFromModal({
            ...values,
            propertyId,
            // eslint-disable-next-line no-underscore-dangle
            contactId: get(associationContact, '_id'),
          })
          if (
            get(values, 'firstName') !== get(associationContact, 'firstName') ||
            get(values, 'lastName') !== get(associationContact, 'lastName') ||
            get(values, 'middleName') !== get(associationContact, 'middleName') ||
            get(values, 'mobileNumber') !== get(associationContact, 'mobileNumber')
          ) {
            tracker.trackEvent(EVENT_NAME.CLICK_SAVE_LANDLORD_DETAILS, {
              click_from: EVENT_PROPERTY.CLICK_FROM.EDIT,
            })
          }
        }}
        onCloseModal={handleCloseCreateContactModal}
        isSubmittingForm={isSubmitting}
        inputEmailChange={() => false}
      />
    </RentSpreeBaseModal>
  )
}
export default compose(withConnect, withReducer, withSaga)(CreateAssociationContactModal)
