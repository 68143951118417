const eventCategory = {
  requestFlow: "Request application flow",
  submitFlow: "Submit application flow",
  newUserActivation: "New user activation",
  agentEmailReference: "Agent email reference",
  proLandingActions: "RentSpree PRO landing actions",
  proLandingFaq: "RentSpree PRO FAQ",
  reqDocsFaq: "Request Documents FAQ",
  reqDocsPage: "Request Documents Page",
  subscriptionPayment: "Subscription Payment flow",
  mySubscription: "My subscription actions",
  arcsInfoPage: "Reference Checks Info Page",
  arcsFeature: "Reference checks Tab",
  otherDocFeature: "Other docs Tab",
  landlordPayment: "Landlord Payment",
  deactivateProperty: "Deactivate Property",
  alacarte: "A la carte Page",
  filter: "Filter Event",
  lraIntegration: "LRA integration flow",
  inactiveSignup: "Inactive Signup",
}
const eventReferenceList = {
  checkCurrentEmployer: {
    eventName: "ARCs - Check current employer",
    properties: { event_category: eventCategory.arcsFeature },
  },
  checkFormerEmployer: {
    eventName: "ARCs - Check former employer",
    properties: { event_category: eventCategory.arcsFeature },
  },
  checkCurrentLandlord: {
    eventName: "ARCs - Check current landlord",
    properties: { event_category: eventCategory.arcsFeature },
  },
  checkPreviousLandlord: {
    eventName: "ARCs - Check previous landlord",
    properties: { event_category: eventCategory.arcsFeature },
  },
  checkProfessional: {
    eventName: "ARCs - Check professional reference",
    properties: { event_category: eventCategory.arcsFeature },
  },
  checkPersonal: {
    eventName: "ARCs - Check personal reference",
    properties: { event_category: eventCategory.arcsFeature },
  },
  checkAllReference: {
    eventName: "ARCs - Check all reference",
    properties: { event_category: eventCategory.arcsFeature },
  },
  clickSendRequestsReference: {
    eventName: "ARCs - Click send requests",
    properties: { event_category: eventCategory.arcsFeature },
  },
  clickPrintReference: {
    eventName: "ARCs - Click print ref checks doc",
    properties: { event_category: eventCategory.arcsFeature },
  },
  clickSaveAsReference: {
    eventName: "ARCs - Click save as PDF",
    properties: { event_category: eventCategory.arcsFeature },
  },
}
const eventOtherDocList = {
  checkPayStub: {
    eventName: "Docs - Check pay stubs",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadPayStub: {
    eventName: "Docs - Download pay stubs",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkBankStatements: {
    eventName: "Docs - Check bank statements",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadBankStatements: {
    eventName: "Docs - Download bank statements",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkTaxReturns: {
    eventName: "Docs - Check tax returns",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadTaxReturns: {
    eventName: "Docs - Download tax returns",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkW2s: {
    eventName: "Docs - Check w-2s",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadW2s: {
    eventName: "Docs - Download w-2s",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkDriverLicenseOrPassport: {
    eventName: "Docs - Check driver license or passport",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadLicenseOrPassport: {
    eventName: "Docs - download driver license or passport",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkPetPhotos: {
    eventName: "Docs - Check pet photos",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadPetPhotos: {
    eventName: "Docs - Download pet photos",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkPetApplication: {
    eventName: "Docs - Check pet applications",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  downloadPetApplication: {
    eventName: "Docs - Download pet applications",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  checkAllDocs: {
    eventName: "Docs - Check all docs",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
  sendRequestedOtherDoc: {
    eventName: "Docs - Click send requests",
    properties: {
      event_category: eventCategory.otherDocFeature,
    },
  },
}
const eventRequestFlow = {
  screenFromExistingListing: {
    eventName: "Click screen new tenants",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  screenFromExistingListingEmailWithApplicationListDropdown: {
    eventName:
      "Click screen new tenants with email request from actions dropdown in application list page",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  screenFromExistingListingLinkWithApplicationListDropdown: {
    eventName:
      "Click screen new tenants with link request from actions dropdown in application list page",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  screenFromExistingListingFlyerWithApplicationListDropdown: {
    eventName:
      "Click screen new tenants with flyer request from actions dropdown in application list page",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  screenFromExistingListingEmail: {
    eventName:
      "Click screen new tenants with email request from actions dropdown in property list page",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  screenFromExistingListingLink: {
    eventName:
      "Click screen new tenants with link request from actions dropdown in property list page",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  screenFromExistingListingFlyer: {
    eventName:
      "Click screen new tenants with flyer request from actions dropdown in property list page",
    properties: {
      event_category: eventCategory.requestFlow,
      event_label: "Screen tenant from existing listing",
    },
  },
  clickSendEmailRequest: {
    eventName: "Click send email request",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  successSendEmailRequest: {
    eventName: "Success fire API send email request",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickSendMoreRequest: {
    eventName: "Click send more email request",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickSendTextMessageRequest: {
    eventName: "Click send text message request",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  successSendTextMessageRequest: {
    eventName: "Success fire API send text message request",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickSendMoreTextMessageRequest: {
    eventName: "Click send more text message request",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickCopyPermalink: {
    eventName: "Click copy permalink",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickSavePdfFlyerCustomMessage: {
    eventName: "Click save custom pdf flyer msg",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickPrintPdfFlyer: {
    eventName: "Click print PDF flyer",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickGoToDashboardInRequestPage: {
    eventName: "Click go to dashboard in request page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickGoToDashboardInAddNewListingPage: {
    eventName: "Click go to dashboard in add new listing page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickPreviousInRequestPage: {
    eventName: "Click previous button in request page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickAddNewListingFromRequestPage: {
    eventName: "Click add new listing from request page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickBackToDashboardFromAlacartePage: {
    eventName: "Click back to dashboard from alacarte page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickPreviousInRequestActionsPage: {
    eventName: "Click previous button in request actions page",
    event_category: eventCategory.requestFlow,
  },
  clickGoToDashboardInRequestActionsPage: {
    eventName: "Click go to dashboard in request actions page",
    event_category: eventCategory.requestFlow,
  },
  clickAddNewListingFromLeftMenu: {
    eventName: "Click add new listing from left menu",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickNextInCreatePropertyPage: {
    eventName: "Click next in create property page",
    properties: { event_category: eventCategory.requestFlow },
  },
  clickNextFromRequestOptionsPage: {
    eventName: "Click next in request options page",
    properties: { event_category: eventCategory.requestFlow },
  },
  clickScreenNewTenantInApplicationListFilterBar: {
    eventName: "Click screen new tenant in application list filter bar",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickScreenNewTenantInNoApplicationPage: {
    eventName: "Click screen new tenant in no application page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickNextInUpdatePersonalInfoPage: {
    eventName: "Click next in update personal info page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
  clickBackInUpdatePersonalInfoPage: {
    eventName: "Click back to dashboard in update personal info page",
    properties: {
      event_category: eventCategory.requestFlow,
    },
  },
}
const eventNewUserActivation = {
  clickGetStartedNowInGettingStartedPage: {
    eventName: "Click get started now in get started page",
    properties: {
      event_category: eventCategory.newUserActivation,
    },
  },
  clickAddNewListingInNoPropertyPage: {
    eventName: "Click add new listing in no property page",
    properties: {
      event_category: eventCategory.newUserActivation,
    },
  },
  clickLearnMoreInNoPropertyPage: {
    eventName: "Click learn more in no property page",
    properties: {
      event_category: eventCategory.newUserActivation,
    },
  },
  clickOpenWatchTutorialVideo: {
    eventName: "Click button watch tutorial in getting started page",
    properties: {
      event_category: eventCategory.newUserActivation,
    },
  },
  clickDownloadSampleFile: {
    eventName: "Click see a sample in getting started page",
    properties: {
      event_category: eventCategory.newUserActivation,
    },
  },
  clickViewProcessInGettingStartedPage: {
    eventName: "Click view process in getting started page",
    properties: {
      event_category: eventCategory.newUserActivation,
    },
  },
}
const eventProLandingActions = {
  clickProTryItFree: {
    eventName: "Click try it free in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickReactivate: {
    eventName: "Click reactivate",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickProHaveCouponCode: {
    eventName: "Click have a coupon code in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickOpenProRestrictionsModal: {
    eventName: "Click open pro restrictions modal in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickProLearnMoreRequestDocs: {
    eventName:
      "Click link to learn more about request documents in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickProLearnMoreRefChecks: {
    eventName:
      "Click link to learn more about reference checks in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickChooseProPlan: {
    eventName: "Click choose pro plan to payment page in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickChooseBrokeragePlan: {
    eventName:
      "Click choose brokerage plan to contact us page in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
  clickVisitHelpCenter: {
    eventName: "Click visit rentspree help center in pro landing page",
    properties: {
      event_category: eventCategory.proLandingActions,
    },
  },
}
const eventProLandingFaq = {
  clickFaqWhatIsPro: {
    eventName: "Click Faq on what is rentspree pro",
    properties: {
      event_category: eventCategory.proLandingFaq,
    },
  },
  clickFaqHowAccessPro: {
    eventName: "Click Faq on How can I access RentSpree PRO features",
    properties: {
      event_category: eventCategory.proLandingFaq,
    },
  },
  clickFaqNeedCreditCard: {
    eventName: "Click Faq on do I need a credit card to sign up",
    properties: {
      event_category: eventCategory.proLandingFaq,
    },
  },
  clickFaqTrialEnds: {
    eventName: "Click Faq what happens when my trial ends",
    properties: {
      event_category: eventCategory.proLandingFaq,
    },
  },
  clickFaqHowUseCoupon: {
    eventName: "Click Faq on how to use a coupon code",
    properties: {
      event_category: eventCategory.proLandingFaq,
    },
  },
  clickFaqCancelMember: {
    eventName: "Click Faq on what if I want to cancel my membership",
    properties: {
      event_category: eventCategory.proLandingFaq,
    },
  },
}
const eventReqDocsPage = {
  clickReqDocsTryItFree: {
    eventName: "Click try it free in request documents page",
    properties: {
      event_category: eventCategory.reqDocsPage,
    },
  },
  clickReqDocsHaveCouponCode: {
    eventName: "Click have a coupon code in request documents page",
    properties: {
      event_category: eventCategory.reqDocsPage,
    },
  },
  clickReqDocsProRestrictionsModal: {
    eventName: "Click open pro restrictions modal in request documents page",
    properties: {
      event_category: eventCategory.reqDocsPage,
    },
  },
}
const eventReqDocsFaq = {
  clickFaqHowToStartReqDocs: {
    eventName: "Click Faq on how can I start using Renter Document Upload",
    properties: {
      event_category: eventCategory.reqDocsFaq,
    },
  },
  clickFaqWhatDocsCanCollect: {
    eventName: "Click Faq on what documents can I collect",
    properties: {
      event_category: eventCategory.reqDocsFaq,
    },
  },
}
const eventArcsInfoPage = {
  clickArcsTryFreeLink: {
    eventName: "Click try it free in reference checks info page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickArcReactivate: {
    eventName: "Click reactivate now in reference checks info page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickArcsHaveCouponCodeLink: {
    eventName: "Click have a coupon code in reference checks info page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickArcsProRestrictionsModal: {
    eventName:
      "Click open pro restrictions modal in reference checks info page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowToStartUsingArcSub: {
    eventName:
      "Click Faq on how can I start using Auto Reference Checks in reference checks info for subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowRspContactRefSub: {
    eventName:
      "Click Faq on how does RentSpree contact a reference in reference checks info for subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowRefVerifyInfoSub: {
    eventName:
      "Click Faq on how does a reference verify information in reference checks info for subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowTrustworthyIsVerficationProcessSub: {
    eventName:
      "Click Faq on how trustworthy is the verification process in reference checks info for subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowToStartUsingArcNonsub: {
    eventName:
      "Click Faq on how can I start using Auto Reference Checks in reference checks info for non-subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowRspContactRefNonsub: {
    eventName:
      "Click Faq on how does RentSpree contact a reference in reference checks info for non-subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowRefVerifyInfoNonsub: {
    eventName:
      "Click Faq on how does a reference verify information in reference checks info for non-subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickFaqHowTrustworthyIsVerficationProcessNonsub: {
    eventName:
      "Click Faq on how trustworthy is the verification process in reference checks info for non-subscriber page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
  clickArcVisitHelpCenter: {
    eventName:
      "Click visit rentspree help center in reference checks info page",
    properties: {
      event_category: eventCategory.arcsInfoPage,
    },
  },
}
const eventMySubscription = {
  clickManageSubscription: {
    eventName: "Click go to manage subscription on recurly",
    properties: {
      event_category: eventCategory.mySubscription,
    },
  },
  clickGoToReqDocsFromMySubscription: {
    eventName: "Click go to request documents page on my subscription page",
    properties: {
      event_category: eventCategory.mySubscription,
    },
  },
  clickGoToRefChecksFromMySubscription: {
    eventName: "Click go to reference checks page on my subscription page",
    properties: {
      event_category: eventCategory.mySubscription,
    },
  },
  clickReactivateFromMySubscription: {
    eventName: "Click reactivate subscription on my subscription page",
    properties: {
      event_category: eventCategory.mySubscription,
    },
  },
}
const eventAgentEmailReference = {
  clickInviteAndEarnMenu: {
    eventName: "Click invite and earn on menu",
    properties: {
      event_category: eventCategory.agentEmailReference,
    },
  },
}
const eventSubscriptionPayment = {
  clickToCreditPayment: {
    eventName: "Click go to credit payment to subscribe",
    properties: {
      event_category: eventCategory.subscriptionPayment,
    },
  },
  clickRedeemCoupon: {
    eventName: "Click redeem coupon to subscribe",
    properties: {
      event_category: eventCategory.subscriptionPayment,
    },
  },
}
const eventLandlordPayment = {
  clickCancelPayment: {
    eventName: "Click cancel on landlord payment page",
    properties: {
      event_category: eventCategory.landlordPayment,
    },
  },
  clickPayPayment: {
    eventName: "Click pay and view report on landlord payment page",
    properties: {
      event_category: eventCategory.landlordPayment,
    },
  },
  paymentSuccess: {
    eventName: "Payment success",
    properties: {
      event_category: eventCategory.landlordPayment,
    },
  },
}
const eventDeactivateProperty = {
  clickConfirmDeactivateProperty: {
    eventName: "Click confirm deactivate property from deactivate modal",
    properties: {
      event_category: eventCategory.deactivateProperty,
    },
  },
  clickConfirmReactivateProperty: {
    eventName: "Click reactivate property from reactivate modal",
    properties: {
      event_category: eventCategory.deactivateProperty,
    },
  },
}

const eventAlacarte = {
  selectPayerToAgent: {
    eventName: "Click checkbox to change payer to landlord",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  selectPayerToRenter: {
    eventName: "Click checkbox to change payer to renter",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  checkapplicationInAlacarte: {
    eventName: "Click checkbox to select application in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  checkcredit_reportInAlacarte: {
    eventName: "Click checkbox to select credit report in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  checkcriminalInAlacarte: {
    eventName: "Click checkbox to select criminal in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  checkevictionInAlacarte: {
    eventName: "Click checkbox to select eviction in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  uncheckapplicationInAlacarte: {
    eventName: "Click checkbox to unselect application in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  uncheckcredit_reportInAlacarte: {
    eventName: "Click checkbox to unselect credit report in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  uncheckcriminalInAlacarte: {
    eventName: "Click checkbox to unselect criminal in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
  uncheckevictionInAlacarte: {
    eventName: "Click checkbox to unselect eviction in a la carte menu",
    properties: {
      event_category: eventCategory.alacarte,
    },
  },
}

const eventFilter = {
  filterInactiveProperty: {
    eventName:
      "Click filter inactive property on property list filter dropdown",
    properties: {
      event_category: eventCategory.filter,
    },
  },
  filterActiveProperty: {
    eventName: "Click filter active property on property list filter dropdown",
    properties: {
      event_category: eventCategory.filter,
    },
  },
  filterAllProperty: {
    eventName: "Click filter all property on property list filter dropdown",
    properties: {
      event_category: eventCategory.filter,
    },
  },
}

const lraIntegration = {
  clickExportCarForm: {
    eventName: "Click Export CAR Form",
    properties: {
      event_category: eventCategory.lraIntegration,
    },
  },
  clickGetCarForm: {
    eventName: "Click Get CAR Form",
    properties: {
      event_category: eventCategory.lraIntegration,
    },
  },
  clickLearnMoreLRAModal: {
    eventName: "Click Learn more in LRA integration modal",
    properties: {
      event_category: eventCategory.lraIntegration,
    },
  },
  clickCancelLRAModal: {
    eventName: "Click cancel in LRA integration modal",
    properties: {
      event_category: eventCategory.lraIntegration,
    },
  },
}

const inactiveSignup = {
  clickOnStartScreeningButton: {
    eventName: "Clicks on Start screening button",
    properties: {
      event_category: eventCategory.inactiveSignup,
    },
  },
  clickOnLaterButton: {
    eventName: "Clicks on Later button",
    properties: {
      event_category: eventCategory.inactiveSignup,
    },
  },
  clickOnCloseIcon: {
    eventName: "Clicks on Close icon",
    properties: {
      event_category: eventCategory.inactiveSignup,
    },
  },
  playVideo: {
    eventName: "Play the video",
    properties: {
      event_category: eventCategory.inactiveSignup,
    },
  },
  finishVideo: {
    eventName: "Finish the video",
    properties: {
      event_category: eventCategory.inactiveSignup,
    },
  },
}

// ***** Note: Key of the properties object must be _ because of GA structure
const eventTrackerList = {
  ...eventReferenceList,
  ...eventOtherDocList,
  ...eventRequestFlow,
  ...eventNewUserActivation,
  ...eventProLandingActions,
  ...eventProLandingFaq,
  ...eventReqDocsPage,
  ...eventReqDocsFaq,
  ...eventArcsInfoPage,
  ...eventMySubscription,
  ...eventAgentEmailReference,
  ...eventSubscriptionPayment,
  ...eventLandlordPayment,
  ...eventDeactivateProperty,
  ...eventAlacarte,
  ...eventFilter,
  ...lraIntegration,
  ...inactiveSignup,
}

export default eventTrackerList
