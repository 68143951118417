import React from "react"
import Row from "components/atoms/row"
import Span from "@rentspree/component-v2/dist/typography/span"
import { COLOR } from "styles/settings"

/*
 UI Example:
      Text              Label
  -> Date time:       10/10/2020
*/

export const TextWithLabel = ({ title, subTitle, fontColor, ...style }) => (
  <Row width="50%" mWidth="100%" {...style}>
    <Span width="40%" mWidth="50%" size="14px" weight="600">
      {title}
    </Span>
    <Span width="60%" mWidth="50%" color={fontColor || COLOR.textBlack}>
      {subTitle}
    </Span>
  </Row>
)

export default TextWithLabel
