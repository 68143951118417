import { useEffect, useRef } from 'react'

export const useDebounce = (callback, delay) => {
  const callbackRef = useRef(callback)
  const timerRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return (...args) => {
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      callbackRef.current(...args)
    }, delay)
  }
}
