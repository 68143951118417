import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withReducer } from 'containers/tenant-screening/assign-property/connect'
import { createStructuredSelector } from 'reselect'

import { selectProfile } from 'containers/user/selectors'
import { selectAgreement } from 'containers/agreement/selectors'
import { getSubmissionParticipantList } from 'containers/tenant-screening/list/actions'
import { selectIsVerifyingPropertyAddress, selectAddressVerification } from 'containers/address-verification/selectors'
import { verifyPropertyAddress, resetAddressVerification } from 'containers/address-verification/actions'
import {
  getAssignPropertyList,
  selectProperty,
  createAndSelectProperty,
  openCreatePropertyModal,
  closeCreatePropertyModal,
  clearCreatePropertyModalError,
  openExistingSubmissionErrorModal,
  openAssignPropertyModal,
  closeAssignPropertyModal,
  assignProperty,
  appendAssignPropertyList,
  autoSelectProperty,
} from '../../assign-property/actions'
import {
  selectPropertyList,
  selectIsLoading,
  selectError,
  selectSelectedPropertyId,
  selectCreatePropertyModal,
  selectExistingSubmissionErrorModal,
  selectAssignPropertyModal,
  selectIsSubmitting,
} from '../../assign-property/selectors'

const SelectPropertyConnect = ComposedComponent =>
  compose(
    connect(
      createStructuredSelector({
        propertyList: selectPropertyList,
        isLoading: selectIsLoading,
        error: selectError,
        selectedPropertyId: selectSelectedPropertyId,
        createPropertyModal: selectCreatePropertyModal,
        existingSubmissionErrorModal: selectExistingSubmissionErrorModal,
        profile: selectProfile,
        agreement: selectAgreement,
        assignPropertyModal: selectAssignPropertyModal,
        isSubmitting: selectIsSubmitting,
        isVerifyingPropertyAddress: selectIsVerifyingPropertyAddress,
        addressVerification: selectAddressVerification,
      }),
      dispatch => ({
        actions: bindActionCreators(
          {
            selectProperty,
            assignProperty,
            getAssignPropertyList,
            createAndSelectProperty,
            openAssignPropertyModal,
            openCreatePropertyModal,
            closeCreatePropertyModal,
            closeAssignPropertyModal,
            clearCreatePropertyModalError,
            openExistingSubmissionErrorModal,
            getSubmissionParticipantList,
            verifyPropertyAddress,
            resetAddressVerification,
            appendAssignPropertyList,
            autoSelectProperty,
          },
          dispatch,
        ),
      }),
    ),
    withReducer,
  )(ComposedComponent)

export default SelectPropertyConnect
