import React from 'react'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import get from 'lodash/get'
import includes from 'lodash/includes'
import { compose } from 'redux'
import { withStatsigProvider } from 'hoc/with-statsig'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { CenterContent, Loading } from 'components/layout/main'
import { RENTAL_STATUS } from 'legacy/constants/rental-consts'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import * as ERRORS from 'legacy/constants/error-messages'
import { NOT_FOUND } from 'constants/route'

import { useSelector } from 'react-redux'
import { withAssociationContact } from 'containers/overview/association-contact/connect'
import {
  selectAssociationContact,
  selectIsFetchingAssociationContact,
} from 'containers/overview/association-contact/selectors'
import { selectIsAlreadyShow } from 'containers/share-report-prompt/selectors'
import { selectReportAvailable } from 'containers/share-report/selectors'
import { SHARE_PROMPT_PROPERTY_SKIP_LIST } from 'containers/share-report-prompt/constants'
import ShareReport from 'containers/share-report'
import ShareReportPrompt from 'containers/share-report-prompt'
import { withConnect } from './connect'
import { GRANTING_ROUTE_PATHS, CONTAINERS } from './constants'
import { ReportPageContext } from './context'
import { selectIsOwnReport } from './selectors'

import Reports from './reports'
import ReportReviewing from './reviewing'

const { appDetailReportPaths } = GRANTING_ROUTE_PATHS

export const RouteReports = ({
  getRentSubmissionById,
  getAssociationContact,
  clearShowPdf,
  clearRental,
  clearCreditReport,
  clearCreditReportData,
  clearApplicationDetail,
  clearReferenceChecks,
  clearContactByEmail,
  isFetchingRentalDetail,
  isFetchingParticipant,
  getNotificationCall,
  getTenantScreeningNotification,
  getReportsAvailableCall,
  getContactByEmail,
  errorRental,
  match,
  history,
  container,
  acceptDenyPayload,
  rentalDetail = {},
  isRenter = false,
  isSharedParticipant = false,
  toggleShareReportPromptModal,
  resetToggleShareReportPromptModal,
  getSubmissionParticipantByEmailCall,
  getMultiShareStatusCall,
  clearIncomeVerification,
}) => {
  const { path, url, params: { rentalAppId, propertyId } = {} } = match
  const isAppDetailPage = container === CONTAINERS.APPLICATION_DETAIL // Else `PRINT_REPORTS` page
  const isRenterViewReportFirst = rentalDetail?.isRenterViewReportFirst
  const isReportSubmittedStatus = rentalDetail?.status === RENTAL_STATUS.SUBMITTED
  const shouldRenderReportReviewingPage = !!(
    isRenter &&
    isRenterViewReportFirst &&
    !isReportSubmittedStatus
  )

  const reportPageContextValue = React.useMemo(
    () => ({
      isPrintPage: !isAppDetailPage,
      propertyId,
    }),
    [propertyId, isAppDetailPage],
  )
  const currentPath = get(history, 'location.pathname', '')
  const splittedPath = split(currentPath, '/')
  const reportPageType = splittedPath[splittedPath.length - 1]

  React.useEffect(() => {
    getRentSubmissionById(rentalAppId, propertyId).then(() => {
      getNotificationCall({ propertyId })
      getTenantScreeningNotification()
    })

    getMultiShareStatusCall()

    return () => {
      // NOTE: reference checks & application clear only printData for now.
      clearReferenceChecks()
      clearShowPdf()
      clearCreditReport()
      clearCreditReportData()
      clearApplicationDetail()
      clearRental()
      clearIncomeVerification()
    }
  }, [])

  const landlordInformation = useSelector(selectAssociationContact)
  const isFetchingLandlordInfo = useSelector(selectIsFetchingAssociationContact)
  const isReportOwner = useSelector(selectIsOwnReport)
  const isAlreadyShow = useSelector(selectIsAlreadyShow)
  const reportAvailable = useSelector(selectReportAvailable)
  const isShareOptionAvailable = React.useMemo(
    () =>
      Object.keys(reportAvailable || {}).reduce((acc, type) => reportAvailable[type] || acc, false),
    [reportAvailable],
  )

  React.useEffect(() => {
    const isRentalDetailEmpty = !get(rentalDetail, '_id')
    if (!isRentalDetailEmpty) {
      getReportsAvailableCall()
    }
  }, [rentalDetail])

  React.useEffect(() => {
    const hasLandlordContactInfo = propertyId === landlordInformation?.propertyId
    const isFetching = isFetchingParticipant || isFetchingLandlordInfo
    if (!isFetching && isReportOwner && !hasLandlordContactInfo) {
      getAssociationContact({ propertyId })
    }
  }, [isReportOwner])

  React.useEffect(() => {
    if (landlordInformation?.email) {
      const { email } = landlordInformation
      getSubmissionParticipantByEmailCall(rentalAppId, email)
    }
  }, [landlordInformation])

  const isAllowToPrompt = page => {
    const notAllowPages = [
      'all',
      'application',
      'credit-report',
      'criminal',
      'eviction',
      'reference-checks',
      'documents',
      'income-verification',
    ]
    if (notAllowPages.includes(page)) return false
    return true
  }

  React.useEffect(() => {
    const unblock = history.block(location => {
      // isAssociationContact is true = Have Landlord Information
      // isSharedParticipant is true = This report is shared to landlord
      // isAlreadyShow is true = the prompt modal is already appear once and by logic should not appear again in the same page-view
      // isShareOptionAvailable is true = at least one type of share option is true
      // isPropertySkipped is true = prompt was already display for this listed property
      const { isAssociationContact } = landlordInformation
      const targetPath = location.pathname
      const targetSplittedPath = split(targetPath, '/')
      const targetPage = targetSplittedPath[targetSplittedPath.length - 1]

      const sharePromptSkipList = JSON.parse(
        window.localStorage.getItem(SHARE_PROMPT_PROPERTY_SKIP_LIST),
      )

      const isPropertySkipped = includes(sharePromptSkipList, propertyId)

      const shouldSharePromptDisplay =
        !isSharedParticipant &&
        isAssociationContact &&
        !isAlreadyShow &&
        isAllowToPrompt(targetPage) &&
        isShareOptionAvailable &&
        !isPropertySkipped

      if (shouldSharePromptDisplay) {
        toggleShareReportPromptModal({ toggle: true, navigationUnblock: { unblock, location } })
        // if SharePromptModal should be display, return false to block router-navigation
        return false
      }

      // if SharePromptModal should not be display, return true to bypass blocker
      return true
    })

    return () => {
      // Clean-up blocking when condition effect change
      unblock()
    }
  }, [isSharedParticipant, landlordInformation, isAlreadyShow, isShareOptionAvailable])

  React.useEffect(
    () => () => {
      // Handle routing effect when unmount and clean-up prompt state
      resetToggleShareReportPromptModal()
    },
    [history],
  )

  React.useEffect(() => {
    if (acceptDenyPayload?.email) {
      getContactByEmail(acceptDenyPayload?.email)
      return () => {
        clearContactByEmail()
      }
    }
    return () => {}
  }, [acceptDenyPayload])

  if (!isEmpty(errorRental)) {
    return (
      <FailedToFetch
        withBreadcrumb
        withReportTabs
        noMargin
        title={ERRORS.APPLICATION_DETAIL.RENTAL_FORM.TITLE}
        text={ERRORS.APPLICATION_DETAIL.RENTAL_FORM.MESSAGE}
      />
    )
  }

  if (isFetchingRentalDetail || isFetchingParticipant)
    return (
      <CenterContent noMargin withBreadcrumb withReportTabs>
        <Loading />
      </CenterContent>
    )

  return (
    <>
      {/* For share report modal */}
      <ShareReport />
      <ShareReportPrompt
        property={rentalDetail.property}
        rentalAppId={rentalAppId}
        reportPageType={reportPageType}
      />
      <ReportPageContext.Provider value={reportPageContextValue}>
        <Switch>
          {/**
           * This route is default the `APP_DETAIL` page
           * After the user selected rental-submission on `APP_LISTS` page
           * this route will redirect the user to /all report path on `APP_DETAIL` page
           */}
          <Route exact strict path={path} render={() => <Redirect to={`${url}/all`} />} />
          {/**
           * This route compose `APP_DETAIL` page and `PRINT_REPORTS` page
           * so this route will access paths that matching in `appDetailReportPaths` constant
           * consist of (all|application|credit-report|criminal|eviction|reference-checks|documents|income-verification)
           */}
          <Route
            path={`${path}/:type(${appDetailReportPaths})`}
            render={routeProps => {
              if (shouldRenderReportReviewingPage) {
                return <ReportReviewing {...routeProps} hideMobile={isAppDetailPage} />
              }
              return <Reports {...routeProps} isAppDetailPage={isAppDetailPage} />
            }}
          />
          <Route
            render={() => {
              window.location.href = NOT_FOUND
            }}
          />
        </Switch>
      </ReportPageContext.Provider>
    </>
  )
}

export default compose(
  withRouter,
  withConnect,
  withAssociationContact,
  withStatsigProvider,
)(RouteReports)
