import { createSelector } from 'reselect'
import { matchPath } from 'react-router-dom'
import get from 'lodash/get'
import {
  getIsSelectedReportUnavailable,
  getIsRefCheckUnavailable,
  getIsOtherDocsUnavailable,
} from 'containers/reports/helper'
import { APPLICATION_TYPE } from 'constants/application-type'
import {
  selectScreeningOption,
  selectRentalDetail,
  selectIsSharedReport,
  selectShareOption,
  selectRentalDocuments,
  selectIsExpired,
} from '../reports/selectors'
import { REPORT_TYPES } from '../reports/constants'
import { selectPdfFile, selectShowPdfSuccess } from '../reports/other-doc/selectors'

const initialState = {}
const getUser = state => get(state, 'user', initialState)
const getPrintData = state => get(state, 'printData', {})

export const selectUser = createSelector(getUser, state => state)

export const selectIsFetchedAppDetail = createSelector(getPrintData, data =>
  get(data, 'isFetchedApplication'),
)

export const selectIsFetchedCreditReport = createSelector(getPrintData, data =>
  get(data, 'isFetchedCreditReport'),
)

export const selectIsFetchedCreditReportImage = createSelector(getPrintData, data =>
  get(data, 'isFetchedCreditReportImage'),
)

export const selectIsFetchedCriminalImage = createSelector(getPrintData, data =>
  get(data, 'isFetchedCriminalImage'),
)

export const selectIsFetchedEvictionImage = createSelector(getPrintData, data =>
  get(data, 'isFetchedEvictionImage'),
)

export const selectIsFetchedReferenceChecks = createSelector(getPrintData, data =>
  get(data, 'isFetchedReferenceChecks'),
)

export const selectIsFetchedApplicationDocuments = createSelector(getPrintData, data =>
  get(data, 'isFetchedApplicationDocuments'),
)

export const selectIsFetchedIncomeVerificationReport = createSelector(getPrintData, data =>
  get(data, 'isFetchedIncomeVerificationReport'),
)

export const makeSelectIsApplicationReady = () =>
  createSelector(
    [selectScreeningOption, selectIsFetchedAppDetail, selectIsFetchedApplicationDocuments],
    (screeningOption, isFetchedApplication, isFetchedApplicationDocuments) => {
      const { applicationType } = screeningOption
      switch (applicationType) {
        case APPLICATION_TYPE.RENTSPREE:
          return isFetchedApplication
        case APPLICATION_TYPE.CAR_LRA:
          return isFetchedApplication && isFetchedApplicationDocuments
        default:
          return isFetchedApplication
      }
    },
  )

export const selectIsCreditReportDataReady = createSelector(
  selectIsFetchedCreditReport,
  selectIsFetchedCreditReportImage,
  (isFetchedCreditReport, isFetchedCreditReportImage) =>
    isFetchedCreditReport && isFetchedCreditReportImage,
)

export const selectIsCriminalDataReady = createSelector(
  selectIsFetchedCreditReport,
  selectIsFetchedCriminalImage,
  (isFetchedCreditReport, isFetchedCriminalImage) =>
    isFetchedCreditReport && isFetchedCriminalImage,
)

export const selectIsEvictionDataReady = createSelector(
  selectIsFetchedCreditReport,
  selectIsFetchedEvictionImage,
  (isFetchedCreditReport, isFetchedEvictionImage) =>
    isFetchedCreditReport && isFetchedEvictionImage,
)

export const selectIsReferenceChecksReady = createSelector(
  selectRentalDetail,
  selectIsFetchedReferenceChecks,
  selectIsSharedReport,
  selectShareOption,
  (rental, isFetchedReferenceChecks, isSharedReport, shareOption) => {
    const references = get(rental, 'references')
    return getIsRefCheckUnavailable(references, isSharedReport, shareOption)
      ? true
      : isFetchedReferenceChecks
  },
)

export const selectIsOtherDocsReady = createSelector(
  [
    selectPdfFile,
    selectShowPdfSuccess,
    selectRentalDocuments,
    selectIsSharedReport,
    selectShareOption,
  ],
  (pdfFile, showPdfSuccess, rentalDocuments, isSharedReport, shareOption) => {
    const havePdf = pdfFile && pdfFile.byteLength > 0
    const isFetchedPdf = !!(havePdf && showPdfSuccess)
    const isOtherDocsUnavailable = getIsOtherDocsUnavailable(
      rentalDocuments,
      havePdf,
      isSharedReport,
      shareOption,
    )
    return isOtherDocsUnavailable ? true : isFetchedPdf
  },
)

export const selectIsAllReportsReady = createSelector(
  [
    makeSelectIsApplicationReady(),
    selectIsCreditReportDataReady,
    selectIsCriminalDataReady,
    selectIsEvictionDataReady,
    selectIsReferenceChecksReady,
    selectIsOtherDocsReady,
    selectPdfFile,
    selectIsSharedReport,
    selectShareOption,
    selectScreeningOption,
    selectIsExpired,
  ],
  (
    isFetchedApplication,
    isCreditReportDataReady,
    isCriminalDataReady,
    isEvictionDataReady,
    isReferenceChecksReady,
    isOtherDocsReady,
    pdfFile,
    isSharedReport,
    shareOption,
    screeningOption,
    isExpired,
  ) => {
    const isApplicationReady = getIsSelectedReportUnavailable(
      REPORT_TYPES.APPLICATION,
      isSharedReport,
      screeningOption,
      shareOption,
    )
      ? true
      : isFetchedApplication
    const isCreditReportReady = getIsSelectedReportUnavailable(
      REPORT_TYPES.CREDIT_REPORT,
      isSharedReport,
      screeningOption,
      shareOption,
    )
      ? true
      : isCreditReportDataReady
    const isCriminalReady = getIsSelectedReportUnavailable(
      REPORT_TYPES.CRIMINAL,
      isSharedReport,
      screeningOption,
      shareOption,
    )
      ? true
      : isCriminalDataReady
    const isEvictionReady = getIsSelectedReportUnavailable(
      REPORT_TYPES.EVICTION,
      isSharedReport,
      screeningOption,
      shareOption,
    )
      ? true
      : isEvictionDataReady
    const havePdf = pdfFile && pdfFile.byteLength > 0
    if ((get(screeningOption, 'application') || havePdf) && isExpired) {
      // no need to check screening report when expired in all page with app or other docs
      return isApplicationReady && isReferenceChecksReady && isOtherDocsReady
    }
    return (
      isApplicationReady &&
      isCreditReportReady &&
      isCriminalReady &&
      isEvictionReady &&
      isReferenceChecksReady &&
      isOtherDocsReady
    )
  },
)

export const selectReportTypeFromProp = (_, props) => {
  let reportType = get(props, 'match.params.type')
  if (!reportType) {
    const match = matchPath(get(props, 'location.pathname'), {
      path: `${get(props, 'match.path')}/:type`,
      exact: true,
      strict: false,
    })
    reportType = get(match, 'params.type')
  }
  return reportType
}

export const makeSelectIsReportsReady = () =>
  createSelector(
    [
      selectReportTypeFromProp,
      makeSelectIsApplicationReady(),
      selectIsCreditReportDataReady,
      selectIsCriminalDataReady,
      selectIsEvictionDataReady,
      selectIsFetchedReferenceChecks,
      selectIsAllReportsReady,
      selectIsFetchedIncomeVerificationReport,
    ],
    (
      reportType,
      isFetchedApplication,
      isCreditReportDataReady,
      isCriminalDataReady,
      isEvictionDataReady,
      isFetchedReferenceChecks,
      isAllReportsReady,
      isFetchedIncomeVerificationReport
    ) => {
      switch (reportType) {
        case REPORT_TYPES.ALL:
          return isAllReportsReady
        case REPORT_TYPES.APPLICATION:
          return isFetchedApplication
        case REPORT_TYPES.CREDIT_REPORT:
          return isCreditReportDataReady
        case REPORT_TYPES.CRIMINAL:
          return isCriminalDataReady
        case REPORT_TYPES.EVICTION:
          return isEvictionDataReady
        case REPORT_TYPES.REFCHECKS:
          return isFetchedReferenceChecks
        case REPORT_TYPES.INCOME_VERIFICATION:
          return isFetchedIncomeVerificationReport
        default:
          return false
      }
    },
  )
