import get from "lodash/get"
import { createSelector } from "reselect"
import isEmpty from "lodash/isEmpty"
import { generateFullName } from "@rentspree/helper"

import { selectRentalDetail } from "containers/application/selectors"
import { initialState } from "./reducer"
import { ACCEPT_DENY_TYPE } from "./constants"

export const getAcceptDeny = state => get(state, "acceptDeny", initialState)

export const selectAcceptDeny = createSelector(
  getAcceptDeny,
  acceptDeny => acceptDeny,
)

export const selectIsFormCompleted = createSelector(
  getAcceptDeny,
  acceptDeny =>
    get(acceptDeny, "isFormCompleted", initialState.isFormCompleted),
)

export const selectAcceptDenyType = createSelector(
  getAcceptDeny,
  acceptDeny => get(acceptDeny, "type", ""),
)

export const selectIsDisclaimerAccepted = createSelector(
  getAcceptDeny,
  acceptDeny => get(acceptDeny, "isDisclaimerAccepted", false),
)

export const selectIsOpen = createSelector(
  selectAcceptDenyType,
  // TODO: should avoid using type as modal state to prevent unexpected behavior
  type => !isEmpty(type),
)

export const selectIsShowEmailPreview = createSelector(
  selectIsFormCompleted,
  selectAcceptDenyType,
  (isFormCompleted, type) =>
    isFormCompleted || type === ACCEPT_DENY_TYPE.ACCEPT,
)

export const selectIsShowForm = createSelector(
  selectIsFormCompleted,
  selectAcceptDenyType,
  selectIsDisclaimerAccepted,
  (isFormCompleted, type, isDisclaimerAccepted) =>
    !isFormCompleted &&
    type !== ACCEPT_DENY_TYPE.ACCEPT &&
    isDisclaimerAccepted,
)

export const selectAcceptDenyPayload = createSelector(
  selectRentalDetail,
  rentalDetail => ({
    id: rentalDetail.id,
    email: rentalDetail.email,
    renter: rentalDetail.renter,
  }),
)

export const selectIsShowDisclaimer = createSelector(
  selectIsDisclaimerAccepted,
  selectAcceptDenyType,
  (isDisclaimerAccepted, type) =>
    type !== ACCEPT_DENY_TYPE.ACCEPT && !isDisclaimerAccepted,
)

export const selectRentalSubmission = createSelector(
  getAcceptDeny,
  acceptDeny =>
    get(acceptDeny, "rentalSubmission", initialState.rentalSubmission),
)

export const selectRenterName = createSelector(
  selectRentalSubmission,
  rentalSubmission => generateFullName(get(rentalSubmission, "renter", {})),
)

export const selectRenterEmail = createSelector(
  selectAcceptDeny,
  acceptDeny => get(acceptDeny, "tenantEmail", ""),
)

export const selectBaseEventProperty = createSelector(
  selectAcceptDeny,
  acceptDeny => {
    const eventProperty = {
      rental_id: get(acceptDeny, "rentalSubmission.id", ""),
      applicant: get(acceptDeny, "rentalSubmission.email", ""),
      property_id: get(acceptDeny, "propertyId", ""),
      click_from: get(acceptDeny, "clickFrom", ""),
    }
    if (acceptDeny.isSharedReport) eventProperty.is_shared_report = true
    if (!acceptDeny.screeningResult) eventProperty.is_first_time = true

    return eventProperty
  },
)

export const selectSecurityDepositClickFrom = createSelector(
  selectAcceptDeny,
  acceptDeny =>get(acceptDeny, "securityDepositClickFrom", "")
)


export const selectAcceptDenyPropertyId = createSelector(
  getAcceptDeny,
  acceptDeny => get(acceptDeny, "propertyId", ""),
)

export const selectAcceptDenyPropertyAddress = createSelector(
  getAcceptDeny,
  acceptDeny => get(acceptDeny, "propertyAddress", {}),
)

export const selectShowSecurityDepositModal = createSelector(
  getAcceptDeny,
  acceptDeny => get(acceptDeny, "showSecurityDepositModal", false),
)