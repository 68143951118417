import get from 'lodash/get'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { refreshProfile } from 'legacy/actions/user.action'
import { selectRentalDetail } from '../../selectors'

export const mapStateToProps = state => ({
  profile: get(state, 'user.profile', {}),
  rentalDetail: selectRentalDetail(state),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      refreshProfile,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)