import { TYPES } from "./constants"

const setChildrenBox = payload => ({ type: TYPES.SET_CHILDREN_BOX, payload })
const removeChildrenBox = payload => ({
  type: TYPES.REMOVE_CHILDREN_BOX,
  payload,
})

const addChildren = payload => ({
  type: TYPES.ADD_CHILDREN_BOX,
  payload,
})

const setActive = payload => ({
  type: TYPES.SET_ACTIVE_ID,
  payload,
})

const resetActive = () => ({
  type: TYPES.RESET_ACTIVE,
})

const setAssigneeToBox = payload => ({
  type: TYPES.SET_ASSIGNEE_BOX,
  payload,
})

const setConfigBox = payload => ({
  type: TYPES.SET_CONFIG_BOX,
  payload,
})

const changeBoxPage = payload => ({
  type: TYPES.CHANGE_BOX_PAGE,
  payload,
})

export {
  setChildrenBox,
  removeChildrenBox,
  setActive,
  resetActive,
  addChildren,
  setAssigneeToBox,
  setConfigBox,
  changeBoxPage,
}
