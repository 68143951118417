import React from "react"
import { compose } from "redux"
import PaymentProcessing from "components/templates/payment-processing"
import { withSaga, withConnect } from "./connect"

export const TenantScreeningPaymentProcessing = ({ actions, match }) => {
  const { rentalAppId, propertyId, process } = match.params
  const func =
    process === "complete"
      ? actions.callCompleteCreditReportPayment
      : actions.callCancelCreditReportPayment
  return (
    <PaymentProcessing
      processingAction={() => func({ rentalAppId, propertyId })}
      loadingText="Processing, one moment..."
    />
  )
}

export default compose(
  withSaga,
  withConnect,
)(TenantScreeningPaymentProcessing)
