import React from "react"
import get from "lodash/get"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import { FailedToFetch } from "legacy/components/layout/failed-to-fetch"
import * as ERRORS from "legacy/constants/error-messages"
import { withConnect } from "./connect"
import { REPORT_TYPES } from "../../constants"

export const withApplicationError = WrappedComponent => props => {
  if (get(props, "errorApplication"))
    return get(props.match, "params.type") === REPORT_TYPES.ALL ? null : (
      <FailedToFetch
        withBreadcrumb
        withReportTabs
        noMargin
        title={ERRORS.APPLICATION_DETAIL.APPLICATION.TITLE}
        text={ERRORS.APPLICATION_DETAIL.APPLICATION.MESSAGE}
      />
    )
  return <WrappedComponent {...props} />
}

export default compose(
  withRouter,
  withConnect,
  withApplicationError,
)
