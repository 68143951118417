import styled, { css } from "styled-components"

// language=SCSS prefix=dummy{ suffix=}
export const ul = styled.ul`
  ${props =>
    props.bullet &&
    css`
      list-style: inherit;
      padding-left: 20px;
    `};
`

export const ol = styled.ol`
  padding-left: 20px;
`
