import produce from 'immer'

import {
  GET_ALL_TAGS_REQUEST,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILURE,
  APPEND_TAG,
} from './constants'

export const initialState = {
  data: [],
  isLoading: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_ALL_TAGS_REQUEST:
        draft.isLoading = true
        break
      case GET_ALL_TAGS_SUCCESS:
        draft.isLoading = false
        draft.data = payload?.data || []
        break
      case GET_ALL_TAGS_FAILURE:
        draft.isLoading = false
        break
      case APPEND_TAG: {
        const isExist = draft.data.find(tag => tag?._id === payload?.tag?._id)
        if (!isExist && payload?.tag?._id) draft.data.push(payload.tag)
        break
      }
      default:
        break
    }
    return draft
  })

export default reducer
