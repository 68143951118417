import React from "react"
import get from "lodash/get"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import B14 from "@rentspree/component-v2/dist/typography/b14"
import Span from "@rentspree/component-v2/dist/typography/span"
import { Icon } from "@rentspree/component-v2/dist/icon"
import Hr from "@rentspree/component-v2/dist/hr"
import moment from "moment"
import "moment-timezone"

import Col from "components/atoms/col"
import Row from "components/atoms/row"
import TextWithLabel from "components/molecules/text-with-label"
import { TopicHeaderWithUnderline } from "components/molecules/topic-header-with-underline"
import { COLOR } from "styles/settings"
import { RENTER_INSURANCE_STATUS } from "components/renter-insurance/constants"
import { mapStatusContent } from "containers/renter-insurance"
import { convertDateFormat, convertNumberFormat } from "utils/convert"

export const generatePolicyTime = (rentersInsuranceDetail, isProcessing) => {
  if (isProcessing) {
    return `Policy purchased ${moment(
      get(rentersInsuranceDetail, "updatedAt"),
    ).fromNow()}`
  }
  return `As of ${moment(get(rentersInsuranceDetail, "updatedAt")).format(
    "MM/DD/YYYY hh:mm A",
  )} (${moment.tz(moment.tz.guess()).format("zz")})`
}

export const OnlineChannel = props => {
  const { rentersInsuranceDetail } = props
  const { text, color } = mapStatusContent(rentersInsuranceDetail)
  const PROCESSING = "Processing"
  const isProcessing = !get(rentersInsuranceDetail, "sureApp.result")
  const isCancel =
    get(rentersInsuranceDetail, "status") === RENTER_INSURANCE_STATUS.INACTIVE
  const policyStatusDescription = generatePolicyTime(
    rentersInsuranceDetail,
    isProcessing,
  )

  return (
    <Col padding="30px" mPadding="20px" maxWidth="980px" margin="0 auto">
      <Col>
        <S24 margin="0" lineHeight="32px" tLineHeight="26px">
          Policy Status:
          <Span margin="0 0 0 10px" color={color}>
            {text}
          </Span>
        </S24>
        <B14 margin="0 0 30px">{policyStatusDescription}</B14>
      </Col>
      <TopicHeaderWithUnderline topicMargin="0" title="Policy Info" />
      <Col mFlexColumn>
        <Row mFlexColumn>
          <TextWithLabel
            title="Holder Name:"
            subTitle={get(
              rentersInsuranceDetail,
              "sureApp.result.nameOfPolicyholder",
              PROCESSING,
            )}
            margin="0 0 5px"
            fontColor={isProcessing && COLOR.placeholderGrey}
          />
          <TextWithLabel
            title="Holder Email:"
            subTitle={get(rentersInsuranceDetail, "requestee.email")}
            margin="0 0 5px"
          />
        </Row>
        <Row mFlexColumn>
          <TextWithLabel
            title="Carrier:"
            subTitle={get(
              rentersInsuranceDetail,
              "sureApp.result.carrier",
              PROCESSING,
            )}
            margin="0 0 5px"
            fontColor={isProcessing && COLOR.placeholderGrey}
          />
          <TextWithLabel
            title="Liability Coverage:"
            subTitle={
              isProcessing
                ? PROCESSING
                : convertNumberFormat(
                    get(
                      rentersInsuranceDetail,
                      "sureApp.result.liabilityAmount",
                    ),
                    "$0,0",
                  )
            }
            margin="0 0 5px"
            fontColor={isProcessing && COLOR.placeholderGrey}
          />
        </Row>
        <Row mFlexColumn>
          <TextWithLabel
            title="Start Date:"
            subTitle={
              isProcessing
                ? PROCESSING
                : convertDateFormat(
                    get(
                      rentersInsuranceDetail,
                      "sureApp.result.policyStartDate",
                    ),
                    "MM/DD/YYYY",
                  )
            }
            margin="0 0 5px"
            fontColor={isProcessing && COLOR.placeholderGrey}
          />
          <TextWithLabel
            title={isCancel ? "Cancellation Date:" : "End Date:"}
            subTitle={
              isProcessing
                ? PROCESSING
                : convertDateFormat(
                    get(
                      rentersInsuranceDetail,
                      `sureApp.result.${
                        isCancel ? "policyCancellationDate" : "policyEndDate"
                      }`,
                    ),
                    "MM/DD/YYYY",
                  )
            }
            margin="0 0 5px"
            fontColor={isProcessing && COLOR.placeholderGrey}
          />
        </Row>
      </Col>
      <Hr thickness="1px" margin="15px 0 10px" color={COLOR.borderGrey} />
      <B14>
        {isProcessing ? (
          <>
            <Icon
              name="clock"
              size="sm"
              color={COLOR.placeholderGrey}
              style={{ top: "2px" }}
            />
            <Span weight="600" margin="0 0 0 5px" color={COLOR.placeholderGrey}>
              Processing
            </Span>
            <Span margin="0 0 0 10px">
              The policy information will be ready within 24 hours after renters
              insurance is purchased.
            </Span>
          </>
        ) : (
          <>
            <Icon
              name="check"
              size="sm"
              color={COLOR.fontBlue}
              style={{ top: "1px" }}
            />
            <Span weight="600" margin="0 0 0 5px" color={COLOR.fontBlue}>
              Verified
            </Span>
            <Span margin="0 0 0 10px">
              This policy was purchased through RentSpree.
            </Span>
          </>
        )}
      </B14>
    </Col>
  )
}

export default OnlineChannel
