export const GET_ALL_PROPERTY_LIST_CALL = "GET_ALL_PROPERTY_LIST_CALL"
export const GET_ALL_PROPERTY_LIST_REQUEST = "GET_ALL_PROPERTY_LIST_REQUEST"
export const GET_ALL_PROPERTY_LIST_SUCCESS = "GET_ALL_PROPERTY_LIST_SUCCESS"
export const GET_ALL_PROPERTY_LIST_FAILED = "GET_ALL_PROPERTY_LIST_FAILED"
export const CLEAR_PROPERTY_TYPE_ERROR = "CLEAR_PROPERTY_TYPE_ERROR"

export const CLEAR_SELECT_PROPERTY_WARNING = "CLEAR_SELECT_PROPERTY_WARNING"
export const INIT_SELECT_PROPERTY_WARNING = "INIT_SELECT_PROPERTY_WARNING"

export const AUTO_SELECT_PROPERTY = "AUTO_SELECT_PROPERTY"
export const SELECT_DROP_DOWN_PLACEHOLDER = {
  label: "Select",
  value: "",
}

export const FETCH_ERROR = {
  subTitle:
    "Sorry, we’re having some trouble loading the page. Please try refresh the page.",
  title: "Error loading page",
}
