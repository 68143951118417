export const GET_ESIGN_TEMPLATE_LIST_CALL = "GET_ESIGN_TEMPLATE_LIST_CALL"
export const GET_ESIGN_TEMPLATE_LIST_REQUEST = "GET_ESIGN_TEMPLATE_LIST_REQUEST"
export const GET_ESIGN_TEMPLATE_LIST_SUCCESS = "GET_ESIGN_TEMPLATE_LIST_SUCCESS"
export const GET_ESIGN_TEMPLATE_LIST_FAILURE = "GET_ESIGN_TEMPLATE_LIST_FAILURE"
export const CLEAR_ESIGN_TEMPLATE_LIST_CALL = "CLEAR_ESIGN_TEMPLATE_LIST_CALL"
export const SET_CAR_MODAL_STATE = "SET_CAR_MODAL_STATE"
export const CLOSE_ERROR_IN_MODAL = "CLOSE_ERROR_IN_MODAL"
export const LINKING_ACCOUNT_WITHOUT_SSO_CALL =
  "LINKING_ACCOUNT_WITHOUT_SSO_CALL"
export const LINKING_ACCOUNT_WITHOUT_SSO_REQUEST =
  "LINKING_ACCOUNT_WITHOUT_SSO_REQUEST"
export const LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS =
  "LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS"
export const LINKING_ACCOUNT_WITHOUT_SSO_FAILED =
  "LINKING_ACCOUNT_WITHOUT_SSO_FAILED"

export const TITLE = "Select Documents"

export const SELECT_OPTIONS_STEP_ERROR = {
  TITLE: "The template service are temporarily unavailable",
  MESSAGE:
    "Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the leases, contact us at (323) 515-7757 or support@rentspree.com.",
}

export const VALIDATE_CAR_MODAL = {
  TITLE: "Validate C.A.R. Membership",
  DESCRIPTION: `This one-time process will grant you access to all
  features available for C.A.R. members such as C.A.R. form`,
}

export const ERROR_MESSAGE = {
  NOT_FOUND_AND_INVALID:
    "We could not verify your membership record for the information you entered. Please try again.",
  NRDS_ID_ALREADY_LINKED:
    "Your NRDS ID has already been linked to another account. Retry with another ID or unlink the ID from your other account.",
  GENERAL:
    "Sorry, we have encountered an error linking your C.A.R. account. Please try again.",
}

export const CONSENT_WORD = {
  TITLE: "E-Sign and E-Delivery Service Agreement",
  TERMS: [
    {
      text: `RentSpree provides you with the ability to enter into transactions electronically with your applicants and tenants and to obtain electronic signatures and deliver documents electronically (the “E-Sign Service”).  RentSpree offers this service to you subject to its Disclaimer of Warranties and Limitation of Liability above.  Moreover, in using our E-Sign Service, you are responsible for authenticating and verifying the identity of the parties using that service, and RentSpree is not responsible or liable for verifying or authenticating any information entered into our platform.  You must have a documented identity verification process, and we may from time to time request that you provide us a copy of that process.  In addition, you must retain for a period of seven (7) years the document or source you relied on to verify the identity of any applicant or tenant who used the E-Sign Service.  You are solely responsible for the documents you upload or deliver through the E-Sign Service, for providing paper copies of documents to your applicants and tenants when requested, and for determining whether it is appropriate to enter into electronic transactions with your applicants or tenants.  From time to time, we may receive requests to opt-out of electronic transactions or delivery of electronic documents from your applicants or tenants and we will forward that information to you.  Other than forwarding opt-out requests to you, we have no responsibility to manage opt-outs to our E-Sign Service, and you are solely responsible for complying with any opt-out requests from your applicants and/or tenants.`,
    },
  ],
}
export const LATEST_LR_REVISION = "12-2021"

export const ESIGN_SELECT_DOCUMENT_BUTTON = {
  MANUAL_UPLOAD: {
    TITLE: "Manual Upload",
    DESCRIPTION: "Add your lease agreements and collect e-signatures.",
  },
}
