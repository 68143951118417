/* eslint-disable no-restricted-syntax */
import includes from "lodash/includes"
import isEmpty from "lodash/isEmpty"

export const flatternObjectFunc = obj => {
  const toReturn = {}
  for (const i in obj) {
    if (typeof obj[i] === "object") {
      const flatObject = flatternObjectFunc(obj[i])
      for (const x in flatObject) {
        if (Object.prototype.hasOwnProperty.call(flatObject, x)) {
          toReturn[`${i}.${x}`] = flatObject[x]
        }
      }
    } else {
      toReturn[i] = obj[i]
    }
  }
  return toReturn
}

export const validateObject = obj =>
  includes(flatternObjectFunc(obj), true) ||
  !isEmpty(obj.anotherUnrelatedReasons)
