export const SHARE_MS_MODAL = {
    DOWNLOAD_TITLE: "Why download when you can share safely?",
    PRINT_TITLE: "Why print when you can share safely?",
    SUBTITLE: "Keep your data safe by sharing your application directly through RentSpree. It’s fast, secure, and protects your information.",
    PRINT_BUTTON_TEXT: "Print anyway",
    DOWNLOAD_BUTTON_TEXT: "Download anyway",
    PRINT_BUTTON_TEXT_MOBILE: "Print",
    DOWNLOAD_BUTTON_TEXT_MOBILE: "Download",
}

export const SHARE_MS_MODAL_STATE = {
    PRINT: "print",
    DOWNLOAD: "download",
}