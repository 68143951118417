import React from 'react'

export const pageIndexesTemplate = {
  // no matter what, we'll always keep the top level page value, might as well add it in to start
  pageL1Index: 0,
  pageL2Index: 0,
  drawerOpen: false,
}

// separating context for now, might be abstraction overkill
export const PageIndexesContext = React.createContext(pageIndexesTemplate)

// another top-level context wrapper for the data the user is filling out throughout the Rent/Fee Payment flow
export const PaymentInfoContext = React.createContext()

/* We can instead pass this as props, but inside context keepts it clean.*/
export const HistoryContext = React.createContext()

/*
 * if pages are controlling the validation & index-progression logic (open drawer next, or sub page, or next L1 page),
 * they'll need to set their progression functions it from within their render method.
 *
 * PageIndexesContext is set at a higher level for now, though there is conceptual overlap otherwise
 */
export const PageProgressionContext = React.createContext()
