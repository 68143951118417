import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import tracker from 'tracker'
import { useDisableFeature } from '@rentspree/feature-toggle'
import isEmpty from 'lodash/isEmpty'

import AcceptDenyModalComponent from 'components/organisms/accept-deny-modal'
import AcceptDenyEmailPreviewTemplate from 'components/templates/accept-deny-email-preview'
import AcceptDenyDisclaimerTemplate from 'components/templates/accept-deny-disclaimer'
import AcceptDenyFormTemplate from 'components/templates/accept-deny-form'
import { EVENT_ACCEPT_DENY } from 'tracker/const'
import { RENT_PAYMENTS_ID } from 'utils/feature-toggle/constants'
import { withConnect, withSaga, withReducer } from './connect'
import { ACCEPT_DENY_TYPE, ACCEPT_DENY_AGREEMENT_TYPE } from './constants'
import SecurityDepositPromotion from './security-deposit-promotion'

const { ACCEPT_ON_CONDITION } = ACCEPT_DENY_TYPE

export const AcceptDenyModal = ({
  acceptDeny,
  property,
  propertyByListing,
  profile,
  renterName,
  actions,
  isShowEmailPreview,
  isShowDisclaimer,
  isShowForm,
  isOpen,
  eventProperty,
}) => {
  const {
    type,
    acceptOnConditionForm,
    denyForm,
    isSubmitting,
    rentalSubmission,
    isFormCompleted,
    disclaimer,
  } = acceptDeny

  const { disableFeature, isReady: isFeatureToggleReady } = useDisableFeature()

  const handleClose = () => {
    actions.clearAcceptDenyModal()
    tracker.trackEvent(EVENT_ACCEPT_DENY.closeAcceptDenyModal, eventProperty)
  }

  const handleChangeRadio = event => {
    actions.changeAcceptDenyType(event.target.value)
  }

  const handleSendEmail = () => {
    const isRentPaymentFeatureToggleEnabled =
      isFeatureToggleReady && disableFeature.isFeatureEnabled(RENT_PAYMENTS_ID)

    actions.acceptDenyCall({
      isRentPaymentFeatureEnabled: isRentPaymentFeatureToggleEnabled,
    })
  }

  const handleClickContinueDisclaimer = () => {
    actions.agreeDisclaimerCall({
      type: ACCEPT_DENY_AGREEMENT_TYPE,
      version: disclaimer.data.version,
    })
  }

  React.useEffect(() => {
    if (isOpen) actions.getDisclaimerContentCall()
  }, [isOpen])

  const handleBackToForm = () => {
    actions.backToForm()
  }

  const handleCompleteForm = values => {
    if (type === ACCEPT_ON_CONDITION) {
      actions.completeAcceptOnConditionForm(values)
    } else {
      actions.completeDenyForm(values)
    }
  }

  // TODO: move to selectors?
  const values = type === ACCEPT_ON_CONDITION ? acceptOnConditionForm : denyForm
  const hasConditionInput = type === ACCEPT_ON_CONDITION

  const propertyObject = !isEmpty(property) ? property : propertyByListing

  return (
    <>
      <AcceptDenyModalComponent
        isOpen={isOpen}
        onClose={handleClose}
        title="Send Screening Result Email"
        onChangeRadio={handleChangeRadio}
        radioValue={type}
        componentKey={`${type}-${isFormCompleted}`}
      >
        {isShowEmailPreview && (
          <AcceptDenyEmailPreviewTemplate
            type={type}
            property={propertyObject}
            rentalDetail={rentalSubmission}
            profile={profile}
            acceptOnConditionForm={acceptOnConditionForm}
            denyForm={denyForm}
            email={rentalSubmission?.email}
            renterName={renterName}
            onClickSend={handleSendEmail}
            onClickBack={handleBackToForm}
            isLoading={isSubmitting}
          />
        )}
        {isShowDisclaimer && (
          <AcceptDenyDisclaimerTemplate
            content={get(disclaimer, 'data.content', '')}
            title={get(disclaimer, 'data.title', '')}
            onClickContinue={handleClickContinueDisclaimer}
            isFetchingContent={disclaimer.isFetchingContent}
            isButtonLoading={disclaimer.isSubmitting}
          />
        )}
        {isShowForm && (
          <AcceptDenyFormTemplate
            renterName={renterName}
            values={values}
            hasConditionInput={hasConditionInput}
            type={type}
            onSubmit={handleCompleteForm}
          />
        )}
      </AcceptDenyModalComponent>
      <SecurityDepositPromotion />
    </>
  )
}

export default compose(withConnect, withSaga, withReducer)(AcceptDenyModal)
