import styled, { css } from "styled-components"
import { ANIMATE } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const StyledRedeemCouponBox = styled.div`
  > h3 {
    margin: 0 0 15px;
  }
  @media (max-width: 991px) {
    .input-group {
      width: 100%;
    }
    .coupon-action {
      > .coupon-btn {
        margin: 15px auto 0;
        min-width: 160px;
      }
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const StyledRedeemSuccessBox = styled.div`
  opacity: 0;
  max-height: 0;
  transform: translateY(-20px);
  overflow-y: hidden;
  pointer-events: none;
  > h3 {
    margin: 0;
  }
  ${props =>
    props.isSubscribed &&
    css`
      transition: max-height ease ${ANIMATE.slow},
        opacity ease ${ANIMATE.normal}, transform ease ${ANIMATE.fast};
      transform: translateY(0);
      opacity: 1;
      max-height: 100vh;
      pointer-events: auto;
      > h3 {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    `}
`
