import { call, put, takeLatest } from 'redux-saga/effects'

import { SEARCH_RENTER_INFO, searchRenterInfoAPI } from '../../actions'
import { searchRenterInfoInstance } from '../../api-instances'

export function* searchRenterInfoSaga({ payload }) {
  try {
    const options = {
      propertyId: payload?.propertyId?.length ? payload?.propertyId : undefined,
    }

    yield put(searchRenterInfoAPI.request())
    const response = yield call(searchRenterInfoInstance, options)
    yield put(searchRenterInfoAPI.success(response.data))
  } catch (err) {
    yield put(searchRenterInfoAPI.failure(err))
  }
}

export function* watchSearchRenterInfoSaga() {
  yield takeLatest(SEARCH_RENTER_INFO, searchRenterInfoSaga)
}
