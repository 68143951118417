import generateApiCall from "utils/generate-api-call"
import {
  UPDATE_LRA_REQUEST_CALL,
  UPDATE_LRA_REQUEST_REQUEST,
  UPDATE_LRA_REQUEST_SUCCESS,
  UPDATE_LRA_REQUEST_FAILURE,
  GET_LRA_REQUEST_REQUEST,
  GET_LRA_REQUEST_SUCCESS,
  GET_LRA_REQUEST_FAILURE,
  GET_LRA_REQUEST_CALL,
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
} from "./constants"

export const updateLraRequestCall = payload => ({
  type: UPDATE_LRA_REQUEST_CALL,
  payload,
})

export const updateLraRequestApi = generateApiCall([
  UPDATE_LRA_REQUEST_REQUEST,
  UPDATE_LRA_REQUEST_SUCCESS,
  UPDATE_LRA_REQUEST_FAILURE,
])

export const getLraRequestCall = payload => ({
  type: GET_LRA_REQUEST_CALL,
  payload,
})

export const getLraRequestApi = generateApiCall([
  GET_LRA_REQUEST_REQUEST,
  GET_LRA_REQUEST_SUCCESS,
  GET_LRA_REQUEST_FAILURE,
])
export const createListingScreeningRequestActions = generateApiCall([
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
])
