import React from "react"
import { ModalDefault } from "./modal-default"

export const VideoModal = props => (
  <ModalDefault
    header={props.video.title}
    className="rentspreeModal largeModal no-padding no-header"
    {...props}>
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        title={props.video.title}
        src={props.video.link}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  </ModalDefault>
)
