import generateApiCall from 'utils/generate-api-call'

import {
  GET_RENTAL_PAYMENT_BY_ID,
  GET_RENTAL_PAYMENT_BY_ID_REQUESTED,
  GET_RENTAL_PAYMENT_BY_ID_SUCCEEDED,
  GET_RENTAL_PAYMENT_BY_ID_FAILED,
} from './constants'

export const getRentalPaymentById = payload => ({
  type: GET_RENTAL_PAYMENT_BY_ID,
  payload,
})

export const getRentalPaymentByIdAPI = generateApiCall([
  GET_RENTAL_PAYMENT_BY_ID_REQUESTED,
  GET_RENTAL_PAYMENT_BY_ID_SUCCEEDED,
  GET_RENTAL_PAYMENT_BY_ID_FAILED,
])
