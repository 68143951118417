import React from "react"

export const Routable = ComposedComponent => (props, context) => {
  const currentPath = props.location.pathname || "/"
  const redirectTo = path => context.router.push.bind(null, path)
  const isAt = path =>
    path instanceof RegExp ? !!currentPath.match(path) : currentPath === path
  const decoratedProps = { ...props, redirectTo, currentPath, isAt }
  return <ComposedComponent {...decoratedProps} />
}
