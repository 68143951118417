import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { query } from '@rentspree/path'
import { S24, SelectDropdown } from '@rentspree/component-v2'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import { ButtonSetV2 } from 'components/buttons/button-set'
import { CREATE_DROPDOWN_VALUE } from 'components/organisms/select-property-form-v2/const'
import CreatePropertyForm from 'containers/rent-payment/shared/create-property-modal/form'
import tracker from 'tracker'
import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks'
import { get } from 'lodash'
import { BUTTON_TYPE_SUBMIT } from 'v3/containers/rent-payment/constants'
import SelectPropertyConnect from '../../request/select-property/connect'
import { withSaga } from '../connect'
import { NEXT_ACTION_TYPES, CREATE_PROPERTY_MODAL_TITLE } from '../constants'
import './modal.scss'

export const AssignPropertyModal = ({
  actions,
  propertyList,
  selectedPropertyId,
  assignPropertyModal,
  isSubmitting,
  location,
  createPropertyModal,
}) => {
  const PAGE_NAME = 'assign-property-modal'
  const { page, filter, search } = query.parse(location?.search)
  const { isSubmitting: isCreatingProperty, isOpen: isCreatingPropertyOpen } = createPropertyModal
  const {
    isOpen: isOpenAssignPropertyModal,
    participantId: selectedParticipantId,
    nextAction,
    nextActionProps,
    createdPropertyId,
  } = assignPropertyModal
  const addressList = [
    { value: '', label: ' ' },
    { value: CREATE_DROPDOWN_VALUE, label: '+ Add New Property' },
    ...propertyList,
  ]

  const displayTitle = () => {
    switch (nextAction) {
      case NEXT_ACTION_TYPES.SHARE:
        return 'Assign a property to share application'
      case NEXT_ACTION_TYPES.EXPORT_CAR_FORM:
        return 'Assign a property to export application'
      default:
        return 'Assign a property to make a decision'
    }
  }

  useEffect(() => {
    actions.getAssignPropertyList()
  }, [])

  const { open: openPropertyCreateFormPanel, setupCreateProperty } = useCreatePropertyHook()

  const selectCreatePropertyOption = () => {
    const buttonLocation = 'assign property for screening w/o property'
    tracker.trackButtonClick({
      location: buttonLocation,
      click_text: 'Add new property',
    })
    openPropertyCreateFormPanel()
    const options = {
      location: buttonLocation,
      onSuccessCallback: ({ property }) => {
        actions.appendAssignPropertyList(property)
        actions.autoSelectProperty(get(property, '_id'))
      },
    }
    setupCreateProperty(options)
  }

  const handleOnChangeDropdown = value => {
    if (value === CREATE_DROPDOWN_VALUE) {
      selectCreatePropertyOption()
    } else {
      actions.selectProperty(value)
    }
  }

  const getPropertyDataById = id => propertyList?.find(item => item.value === id)?.data

  const onCloseAssignPropertyModal = () => {
    actions.closeAssignPropertyModal()
  }

  const onCloseCreatePropertyModal = () => {
    actions.closeCreatePropertyModal()
  }

  const handleSubmit = formValues => {
    const { street, unitNumber, city, state, zipcode, propertyType } = formValues
    const property = {
      street,
      unitNumber,
      city,
      state,
      zipcode,
      isNewProperty: true,
      propertyType,
    }
    actions.createAndSelectProperty({ property })
  }

  const onSaveAndNext = () => {
    const propertyAddress = getPropertyDataById(selectedPropertyId)
    const isAssignToCreatedProperty = createdPropertyId === selectedPropertyId
    actions.assignProperty({
      propertyId: selectedPropertyId,
      propertyAddress,
      submissionParticipantId: selectedParticipantId,
      nextAction,
      nextActionProps,
      queryOptions: { page, filter, search },
      isAssignToCreatedProperty,
    })
  }

  return (
    <>
      <RentSpreeBaseModal isOpen={isOpenAssignPropertyModal} onClose={onCloseAssignPropertyModal}>
        <S24 mSize="24px" margin="0 0 25px" lineHeight="30.17px">
          <div id={`${PAGE_NAME}-title`} data-testid={`${PAGE_NAME}-title`}>
            {displayTitle()}
          </div>
        </S24>
        <SelectDropdown
          isRequired
          id="dropDownSelectProperty"
          options={addressList}
          label="Select Existing or Add New Property"
          name="selectedPropertyId"
          data-testid={`${PAGE_NAME}-select-dropdown`}
          onChange={handleOnChangeDropdown}
          value={selectedPropertyId}
        />
        <ButtonSetV2
          id="selectPropertyModalButtonSet"
          minWidth="100"
          margin="24px 0 0 0"
          continueBtnType="submit"
          continueText="Continue"
          btnHeight="40px"
          onBorder
          onBorderDesktop
          hideBackButton
          hidePrevButton
          hideIconPrevButton
          onSaveAndNext={onSaveAndNext}
          isLoading={isSubmitting}
          disabledNextButton={!selectedPropertyId}
          dataTestKey={PAGE_NAME}
          isShowPrev={false}
          data-testid={`${PAGE_NAME}-continue-button`}
        />
      </RentSpreeBaseModal>
      {isOpenAssignPropertyModal && (
        <RentSpreeBaseModal
          id="createPropertyModal"
          modalSize="medium"
          padding="25px"
          isOpen={isCreatingPropertyOpen}
          onClose={onCloseCreatePropertyModal}
          closeButton={!isCreatingPropertyOpen}
          shouldCloseOnOverlayClick={false}
        >
          <S24
            mSize="24px"
            margin="0 0 25px"
            lineHeight="32px"
            data-testid={`${PAGE_NAME}-create-property-title`}
          >
            {CREATE_PROPERTY_MODAL_TITLE}
          </S24>
          <CreatePropertyForm
            data-testid={`${PAGE_NAME}-create-property-modal`}
            continueText={BUTTON_TYPE_SUBMIT}
            hideIconNextButton
            hideBackButton
            isCreatingProperty={isCreatingProperty}
            onHandleSubmit={handleSubmit}
            handleCloseModal={onCloseCreatePropertyModal}
          />
        </RentSpreeBaseModal>
      )}
    </>
  )
}

export default compose(withRouter, withSaga, SelectPropertyConnect)(AssignPropertyModal)
