import { useQuery } from '@tanstack/react-query'
import { getUserPreference } from "./get-user-preference"

export const queryUserPreferenceData = () => {
  const userPreferenceData = useQuery({
    queryKey: ['get_user_preference'],
    queryFn: getUserPreference,
  })

  return userPreferenceData
}
