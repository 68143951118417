import produce from "immer"

import {
  SUBMIT_REFUND_REQUEST,
  SUBMIT_REFUND_SUCCESS,
  SUBMIT_REFUND_FAILURE,
} from "./constants"

export const initialState = {
  isSubmitting: false,
  error: null,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case SUBMIT_REFUND_REQUEST:
        draft.isSubmitting = true
        draft.error = null
        break
      case SUBMIT_REFUND_SUCCESS:
        draft.isSubmitting = false
        draft.error = null
        break
      case SUBMIT_REFUND_FAILURE:
        draft.isSubmitting = false
        draft.error = payload
        break
      default:
        break
    }
    return draft
  })

export default reducer
