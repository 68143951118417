/*
 * App Personal Messages
 *
 * This contains all the text for the App Personal component.
 */

import { defineMessages } from "react-intl"

const messages =  defineMessages({
  required: {
    id: "required",
    defaultMessage: "Required",
  },
  emptyMessageRequired: {
    id: "emptyMessageRequired",
    defaultMessage: " ",
  },
  radioRequired: {
    id: "radio-required",
    defaultMessage: "Please select one option",
  },
  minChar: {
    id: "minChar",
    defaultMessage: "Must contain at least {min} characters",
    values: { min: 2 },
  },
  maxChar: {
    id: "maxChar",
    defaultMessage: "Must not exceed {max} characters",
    values: { max: 35 },
  },
  minAge: {
    id: "minAge",
    defaultMessage: "You must be older than {min} years old",
    values: { min: 18 },
  },
  maxAge: {
    id: "maxAge",
    defaultMessage: "You must be under {max} years old",
    values: { max: 125 },
  },
  dobNoFuture: {
    id: "dobNoFuture",
    defaultMessage: "Date of birth cannot be in the future",
  },
  invalidDate: {
    id: "invalidDate",
    defaultMessage: "Please enter a valid date",
  },
  invalidZipCode: {
    id: "invalidZipCode",
    defaultMessage: "Allow only 5 digit number",
  },
  invalidState: {
    id: "invalidState",
    defaultMessage: "Invalid state code",
  },
  invalidLengthOfAcquaintance: {
    id: "invalidLoA",
    defaultMessage: "Must not exceed 99 years",
  },
  invalidPhone: {
    id: "invalidPhone",
    defaultMessage: "Invalid mobile number",
  },
  phoneNumbersIncomplete: {
    id: "phoneNumbersIncomplete",
    defaultMessage: "Mobile numbers must have 10 digits",
  },
  duplicatePhoneNumbers: {
    id: "duplicatePhoneNumbers",
    defaultMessage: "Duplicate numbers are not allowed",
  },
  invalidEmail: {
    id: "invalidEmail",
    defaultMessage: "Invalid email format",
  },
  invalidEmailChar: {
    id: "invalidEmailChar",
    defaultMessage: "Email contains invalid character",
  },
  tuEmailLength: {
    id: "emailLength",
    defaultMessage: "Email should be between 0 and 50 characters",
  },
  duplicateEmails: {
    id: "duplicateEmails",
    defaultMessage: "Duplicate emails are not allowed",
  },
  sharedWithThemself: {
    id: "sharedWithThemself",
    defaultMessage: "The report(s) cannot be shared with yourself.",
  },
  sharedWithOwner: {
    id: "sharedWithOwner",
    defaultMessage: "The original subject of the report can access the report via the Renter Dashboard.",
  },
  mustLetterYear: {
    id: "mustLetterYear",
    defaultMessage: "Must be later than {year} years ago",
    values: { year: 125 },
  },
  noFutureDate: {
    id: "noFutureDate",
    defaultMessage: "{dateName} cannot be in the future",
    values: { dateName: "date" },
  },
  endLessThanStart: {
    id: "endLessThanStart",
    defaultMessage: "Must not be prior to {dateName}",
    values: { dateName: "date" },
  },
  networkError: {
    id: "networkError",
    defaultMessage:
      "Sorry, your info could not be saved due to an internal error. Please review the form and try again.",
  },
  invalidCharacterTuProperty: {
    id: "characterTuPropertyError",
    defaultMessage: "Character {invalidCharacter} is invalid",
    value: { invalidCharacter: "" },
  },
  invalidCharacter: {
    id: "characterError",
    defaultMessage: "Character {invalidCharacter} is invalid",
    value: { invalidCharacter: "" },
  },
  hasNumber: {
    id: "hasNumber",
    defaultMessage: "Must contain at least 1 number",
  },
  hasLetter: {
    id: "hasLetter",
    defaultMessage: "Must contain at least 1 letter",
  },
  yearBuilt: {
    id: "yearBuilt",
    defaultMessage: "Allow only 4 digit number",
  },
  area: {
    id: "area",
    defaultMessage: "Square feet must be over {min}",
    values: { min: 100 },
  },
  notFutureDate: {
    id: "notFutureDate",
    defaultMessage: "The past date is not allowed",
  },
  noPastDate: {
    id: "noPastDate",
    defaultMessage: "{dateName} cannot be in the past",
    values: { dateName: "Date" },
  },
  expireDate: {
    id: "expireDate",
    defaultMessage: "Must be greater than the {dateName}",
    values: { dateName: "Start Date" },
  },
  invalidContactNumber: {
    id: "invalidContactNumber",
    defaultMessage: "Number and ()-+ only",
  },
  listingSyndNotFutureDate: {
    id: "listingSyndNotFutureDate",
    defaultMessage: "Try entering today's date or future date",
  },
  invalidDuplicatedEmail: {
    id: "invalidDuplicatedEmail",
    defaultMessage: "You cannot use the same email as your signed-in email",
  },
  maxAgeTaxInformation: {
    id: "maxAgeTaxInformation",
    defaultMessage: "You date of birth must be greater than or equal to {date}",
    values: { date: '01/01/1900' },
  }
})

export default messages

export const ERROR_FORM_MESSAGES_TYPES = Object.fromEntries(
  Object.keys(messages).map(key => [key, key]),
)
