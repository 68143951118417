import { createSelector } from 'reselect'
import get from 'lodash/get'
import { KEY } from 'legacy/containers/pro/landing/constants'
import { initialState as initialLandingState } from 'legacy/containers/pro/landing/reducers'

export const selectSubdomain = ({ subdomain }) => subdomain || {}

const getSubscriptionPlanState = state => get(state, KEY, initialLandingState)

export const makeSelectSubdomain = () =>
  createSelector(selectSubdomain, ({ subdomain }) => get(subdomain, 'sub', null))

export const makeSelectUserSubscription = () =>
  createSelector(getSubscriptionPlanState, state =>
    get(state, 'subscription', initialLandingState.subscription),
  )
