import React from 'react'
import { InformationLine, SubSectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import { Content, InformationPanel, Section } from 'components/reports/screening-reports/share/responsive-doc-style'

export const SocialDetail = ({ citizenship, ethnicity }) => (
  <Section>
      <SubSectionHeader title="Social Details" />
      <Content>
        <InformationPanel>
            <InformationLine label="Citizenship" value={citizenship} />
            <InformationLine label="Ethnicity" value={ethnicity} />
        </InformationPanel>
      </Content>
  </Section>
)
