import produce from 'immer'
import { API_STATUS } from './constants'
import {
  CHECK_RENTAL_PAYMENT_EXISTS_FAILED,
  CHECK_RENTAL_PAYMENT_EXISTS_REQUEST,
  CHECK_RENTAL_PAYMENT_EXISTS_SUCCESS,
  FETCH_LATEST_RENTAL_PAYMENT_FAILED,
  FETCH_LATEST_RENTAL_PAYMENT_REQUEST,
  FETCH_LATEST_RENTAL_PAYMENT_SUCCESS,
  RESET_UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_STATUS,
  UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_FAILED,
  UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_REQUEST,
  UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_SUCCESS,
} from '../actions'

export const rentalPaymentInitialState = {
  rentalPaymentExists: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  entities: {},
  ids: [],
  latestRentalPayment: {
    status: API_STATUS.NONE,
    id: '',
  },
  updatePayoutAllActiveRentalPayments: {
    status: API_STATUS.NONE,
  },
}

/* eslint-disable no-param-reassign */
export const rentalPaymentReducer = (state = rentalPaymentInitialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case CHECK_RENTAL_PAYMENT_EXISTS_REQUEST:
        draft.rentalPaymentExists.status = API_STATUS.FETCHING
        break
      case CHECK_RENTAL_PAYMENT_EXISTS_SUCCESS:
        draft.rentalPaymentExists.status = API_STATUS.SUCCESS
        draft.rentalPaymentExists.data = payload
        break
      case CHECK_RENTAL_PAYMENT_EXISTS_FAILED:
        draft.rentalPaymentExists.status = API_STATUS.ERROR
        break

      case FETCH_LATEST_RENTAL_PAYMENT_REQUEST:
        draft.latestRentalPayment.status = API_STATUS.FETCHING
        break
      case FETCH_LATEST_RENTAL_PAYMENT_SUCCESS:
        draft.latestRentalPayment.status = API_STATUS.SUCCESS
        if (!payload) {
          break
        }
        if (!draft.entities[payload.id]) {
          draft.ids.push(payload.id)
        }
        draft.latestRentalPayment.id = payload.id
        draft.entities[payload.id] = payload
        break
      case FETCH_LATEST_RENTAL_PAYMENT_FAILED:
        draft.latestRentalPayment.status = API_STATUS.ERROR
        break

      case UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_REQUEST:
        draft.updatePayoutAllActiveRentalPayments.status = API_STATUS.UPDATING
        break
      case UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_SUCCESS:
        draft.updatePayoutAllActiveRentalPayments.status = API_STATUS.SUCCESS
        break
      case UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_FAILED:
        draft.updatePayoutAllActiveRentalPayments.status = API_STATUS.ERROR
        break
      case RESET_UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_STATUS:
        draft.updatePayoutAllActiveRentalPayments.status = API_STATUS.NONE
        break

      default:
        break
    }
    return draft
  })
