export const CREATE_ACCOUNT_SESSION_CALL = 'identityVerification/cmd/createAccountSession'
export const CREATE_ACCOUNT_SESSION_REQUEST =
  'identityVerification/evt/createAccountSessionRequested'
export const CREATE_ACCOUNT_SESSION_SUCCESS =
  'identityVerification/evt/createAccountSessionSucceeded'
export const CREATE_ACCOUNT_SESSION_FAILED = 'identityVerification/evt/createAccountSessionFailed'
export const RESET_ACCOUNT_SESSION = 'identityVerification/cmd/resetAccountSessionSession'

// Account information
export const FETCH_ACCOUNT_INFORMATION = 'identityVerification/cmd/fetchAccountInformation'
export const FETCH_ACCOUNT_INFORMATION_REQUEST =
  'identityVerification/evt/fetchAccountInformationRequested'
export const FETCH_ACCOUNT_INFORMATION_SUCCESS =
  'identityVerification/evt/fetchAccountInformationSucceeded'
export const FETCH_ACCOUNT_INFORMATION_FAILED =
  'identityVerification/evt/fetchAccountInformationFailed'
export const RESET_ACCOUNT_INFORMATION = 'identityVerification/cmd/resetAccountInformation'

// Update account information
export const UPDATE_ACCOUNT_INFORMATION = 'identityVerification/cmd/updatedAccountInformation'
export const UPDATE_ACCOUNT_INFORMATION_REQUEST =
  'identityVerification/evt/updatedAccountInformationRequested'
export const UPDATE_ACCOUNT_INFORMATION_SUCCESS =
  'identityVerification/evt/updatedAccountInformationSucceeded'
export const UPDATE_ACCOUNT_INFORMATION_FAILED =
  'identityVerification/evt/updatedAccountInformationFailed'
export const RESET_UPDATE_ACCOUNT_INFORMATION =
  'identityVerification/cmd/resetUpdatedAccountInformation'
