import generateApiCall from "utils/generate-api-call"
import {
  UPDATE_LRA_ENVELOPE_SIGN_CALL,
  UPDATE_LRA_ENVELOPE_SIGN_FAILURE,
  UPDATE_LRA_ENVELOPE_SIGN_REQUEST,
  UPDATE_LRA_ENVELOPE_SIGN_SUCCESS,
  GET_LRA_APPLICATION_CALL,
  GET_LRA_APPLICATION_REQUEST,
  GET_LRA_APPLICATION_SUCCESS,
  GET_LRA_APPLICATION_FAILURE,
  GET_ENVELOPE_CALL,
  GET_LRA_ENVELOPE_REQUEST,
  GET_LRA_ENVELOPE_SUCCESS,
  GET_LRA_ENVELOPE_FAILURE,
} from "./constants"

export const getApplicationCall = payload => ({
  type: GET_LRA_APPLICATION_CALL,
  payload,
})

export const getApplicationApi = generateApiCall([
  GET_LRA_APPLICATION_REQUEST,
  GET_LRA_APPLICATION_SUCCESS,
  GET_LRA_APPLICATION_FAILURE,
])

export const getEnvelopeCall = payload => ({
  type: GET_ENVELOPE_CALL,
  payload,
})

export const getEnvelopeApi = generateApiCall([
  GET_LRA_ENVELOPE_REQUEST,
  GET_LRA_ENVELOPE_SUCCESS,
  GET_LRA_ENVELOPE_FAILURE,
])

export const updateEnvelopeSign = (rentalSubmissionId, payload) => ({
  type: UPDATE_LRA_ENVELOPE_SIGN_CALL,
  rentalSubmissionId,
  payload,
})

export const updateEnvelopeSignApi = generateApiCall([
  UPDATE_LRA_ENVELOPE_SIGN_REQUEST,
  UPDATE_LRA_ENVELOPE_SIGN_SUCCESS,
  UPDATE_LRA_ENVELOPE_SIGN_FAILURE,
])
