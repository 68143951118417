import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { FeatureFlagProvider } from 'utils/feature-flag/provider'

import tracker from 'tracker'
import { CenterContent, Loading } from 'components/layout/main'
import { getAgentProfileCall } from 'containers/agent-profile/actions'
import { parseProfileToStatsigUser } from 'helpers/statsig-user-profile-parser'
import { useInjectSaga } from 'utils/inject-saga'
import { useInjectReducer } from 'utils/inject-reducer'
import { agentProfileReducer } from 'containers/agent-profile/reducer'
import saga from 'containers/agent-profile/saga'
import stonly from 'utils/stonly'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { querySubscriptionData } from 'utils/subscription/query-subscription-data'
import { queryUserPreferenceData } from 'utils/query-user-preference-data'
import { getProfile } from '../../legacy/actions/user.action'
import { selectUser, selectAccessToken, selectProfile } from '../user/selectors'
import { redirectToLogin } from '../../legacy/helpers/location-href'
import { removeAccessToken } from '../../legacy/store/middleware/access-token'
import { selectSubdomain } from './selectors'
import { ProfileWrapperGlobalStyle } from './profile-wrapper-global-style'

export const ProfileWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const token = useSelector(selectAccessToken)
  const profile = useSelector(selectProfile)
  const subdomain = useSelector(selectSubdomain) || 'rentspree'
  const userSubscriptionData = querySubscriptionData()
  const isProUser = userSubscriptionData.data?.userPlan === SUBSCRIPTION_PLAN.PRO
  const userPreferenceData = queryUserPreferenceData()
  const isInDesignConceptExperiment = !!(
    isEmpty(userPreferenceData?.data?.defaultScreeningOption) ||
    userPreferenceData?.data?.isInDesignConceptExperiment
  )

  useInjectSaga({ key: 'agentProfile', saga })
  useInjectReducer({ key: 'agentProfile', reducer: agentProfileReducer })
  useEffect(() => {
    if (token) {
      dispatch(getProfile())
      dispatch(getAgentProfileCall())
    } else {
      // this means the user is not logged in
      // clear all cookies
      removeAccessToken()
      redirectToLogin(true)
    }
  }, [])

  useEffect(() => {
    if (
      user.isFetched &&
      !isEmpty(user.profile) &&
      userSubscriptionData.isFetched
    ) {
      // identify by amplitude
      tracker.identifyUser({
        ...user.profile,
        is_pro: isProUser,
        subdomain,
      })
      // identify by stonly
      stonly.identifyUser(user.profile)
    }
  }, [user.isFetched, userSubscriptionData.isFetched ])

  if (
    !user.isFetched ||
    !token ||
    !user.profile ||
    !userSubscriptionData.isFetched
  ) {
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )
  }

  // NOTE: place statsig provider here due to the fact that we need to wait for the user profile to be fetched
  return (
    <FeatureFlagProvider
      statsigUser={parseProfileToStatsigUser(
        {
          ...profile,
          is_pro: isProUser,
          isInDesignConceptExperiment,
        },
        subdomain,
      )}
    >
      <ProfileWrapperGlobalStyle />
      {children}
    </FeatureFlagProvider>
  )
}

ProfileWrapper.defaultProps = {
  children: null,
}
