import generateApiCall from "utils/generate-api-call"
import {
  GET_AGENT_PROFILE_CALL,
  GET_AGENT_PROFILE_FAILURE,
  GET_AGENT_PROFILE_REQUEST,
  GET_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_LICENSES_FAILURE,
  UPDATE_AGENT_LICENSES_REQUEST,
  UPDATE_AGENT_LICENSES_SUCCESS,
  UPDATE_AGENT_BROKERAGE_NAME,
} from "./constants"

export const getAgentProfileCall = () => ({
  type: GET_AGENT_PROFILE_CALL,
})

export const getAgentProfileApiState = generateApiCall([
  GET_AGENT_PROFILE_REQUEST,
  GET_AGENT_PROFILE_SUCCESS,
  GET_AGENT_PROFILE_FAILURE,
])

export const updateAgentLicenseApiState = generateApiCall([
  UPDATE_AGENT_LICENSES_REQUEST,
  UPDATE_AGENT_LICENSES_SUCCESS,
  UPDATE_AGENT_LICENSES_FAILURE,
])

export const updateAgentBrokerageName = payload => ({
  type: UPDATE_AGENT_BROKERAGE_NAME,
  payload,
})