import {
  GET_SUBSCRIPTION_PRODUCT_CALL,
  GET_SUBSCRIPTION_PRODUCT_REQUEST,
  GET_SUBSCRIPTION_PRODUCT_SUCCESS,
  GET_SUBSCRIPTION_PRODUCT_FAILURE,
  GET_USER_SUBSCRIPTION_CALL,
  GET_USER_SUBSCRIPTION_REQUEST,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_FAILURE,
  SET_SELECTED_PRICE_NAME,
} from "./constants"

export const getSubscriptionProduct = payload => ({
  type: GET_SUBSCRIPTION_PRODUCT_CALL,
  payload,
})

export const getSubscriptionProductRequest = () => ({
  type: GET_SUBSCRIPTION_PRODUCT_REQUEST,
})
export const getSubscriptionProductSuccess = payload => ({
  type: GET_SUBSCRIPTION_PRODUCT_SUCCESS,
  payload,
})
export const getSubscriptionProductFailure = () => ({
  type: GET_SUBSCRIPTION_PRODUCT_FAILURE,
})

export const getUserSubscription = payload => ({
  type: GET_USER_SUBSCRIPTION_CALL,
  payload,
})
export const getUserSubscriptionRequest = () => ({
  type: GET_USER_SUBSCRIPTION_REQUEST,
})
export const getUserSubscriptionSuccess = payload => ({
  type: GET_USER_SUBSCRIPTION_SUCCESS,
  payload,
})
export const getUserSubscriptionFailure = () => ({
  type: GET_USER_SUBSCRIPTION_FAILURE,
})

export const setSelectedPriceName = payload => ({
  type: SET_SELECTED_PRICE_NAME,
  payload,
})
