import { buildPath, parseSubdomain, getSubdomain } from "@rentspree/path"
import { PARTNER_NAME } from "constants/partner"
import { MAIN_URL } from "../../env"
import { removeAccessToken } from "../store/middleware/access-token" // eslint-disable-line import/no-cycle
import { getLocationHost, getLocationHref } from "../utils/window"

export const PARTNERS = {
  RENTALS: PARTNER_NAME.RENTALS,
}

export const USER_TYPE = {
  OWNER: "landlord",
  APPLICANT: "renter",
}

export const mapUserTypeSSO = userType => {
  switch (userType) {
    case USER_TYPE.OWNER:
      return "owner"
    case USER_TYPE.APPLICANT:
      return "applicant"
    default:
      return false
  }
}

export const loginRedirectHandler = userType => {
  removeAccessToken()
  const subdomain = getSubdomain(getLocationHost())
  const isPartnerSSO = Object.values(PARTNERS).includes(subdomain)
  if (isPartnerSSO) {
    const ssoSubdomainUrl = parseSubdomain(subdomain, MAIN_URL)
    const mappedUserType = mapUserTypeSSO(userType)
    if (mappedUserType) {
      const ssoQueryString = {
        userType: mappedUserType,
        continue: getLocationHref(),
      }
      const ssoPath = buildPath(
        `${ssoSubdomainUrl}/account/oauth`,
        null,
        ssoQueryString,
      )
      window.location.assign(ssoPath)
    } else {
      const notFoundPath = buildPath(`${MAIN_URL}/not-found`)
      window.location.assign(notFoundPath)
    }
  } else {
    const loginPath = buildPath(`${MAIN_URL}/account/login`, null, {
      continue: getLocationHref(),
    })
    window.location.assign(loginPath)
  }
}
