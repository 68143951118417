import generateApiCall from 'utils/generate-api-call'

import {
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_FAILED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_REQUESTED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_SUCCEEDED,
} from './constants'

export const getOrCreateDraftRentalPayment = payload => ({
  type: GET_OR_CREATE_DRAFT_RENTAL_PAYMENT,
  payload,
})

export const getOrCreateDraftRentalPaymentAPI = generateApiCall([
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_REQUESTED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_SUCCEEDED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_FAILED,
])
