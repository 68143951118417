import generateApiCall from 'utils/generate-api-call'

import {
  FETCH_ACCOUNT_INFORMATION,
  FETCH_ACCOUNT_INFORMATION_FAILED,
  FETCH_ACCOUNT_INFORMATION_REQUEST,
  FETCH_ACCOUNT_INFORMATION_SUCCESS,
} from './constants'

export const fetchAccountInformationAPI = generateApiCall([
  FETCH_ACCOUNT_INFORMATION_REQUEST,
  FETCH_ACCOUNT_INFORMATION_SUCCESS,
  FETCH_ACCOUNT_INFORMATION_FAILED,
])

export const fetchAccountInformation = payload => ({
  type: FETCH_ACCOUNT_INFORMATION,
  payload,
})
