import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import { takeLatest, all, put, call, select, takeEvery } from 'redux-saga/effects'

import { NOT_FOUND, CONTACTS } from 'constants/route'
import { addToast } from 'containers/toast/actions'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'
import { apiInstance } from 'utils/api-interceptor'

import { openSweetAlertBaseError } from '../../../utils/sweet-alert-actions'
import { setSeenContacts } from '../actions'
import { getTagByTagId } from '../helpers'
import { createTagSaga, tagContactApi, untagContactApi } from '../tags/saga'
import { selectAllTags } from '../tags/selectors'

import {
  deleteContactApiState,
  updateContactDetailApiState,
  getContactDetailApiState,
  starContact,
  tagContactApiState,
  untagContactApiState,
} from './actions'
import {
  CONTACT_DETAIL_API,
  DELETE_CONTACT_CALL,
  DELETE_TOAST_SUCCESS,
  DELETE_TOAST_FAILURE,
  GET_CONTACT_DETAIL_CALL,
  UPDATE_CONTACT_DETAIL_CALL,
  UPDATE_CONTACT_DETAIL_INSTANTLY,
  CONTACT_STAR_API,
  STAR_CONTACT,
  CREATE_AND_TAG_CONTACT_CALL,
  UNTAG_CONTACT_CALL,
  TAG_CONTACT_CALL,
  TAG_TOAST_FAILURE,
  UPDATE_CONTACT_TOAST_FAILED,
} from './constants'
import { selectContactDetail } from './selectors'
import { parseContactDetail } from './utils'

const {
  EVENT_NAME: { ADDED_TAG_SUCCESS, MARK_STAR_ON_CONTACT, UNSTARRED_CONTACT, ENTER_CONTACT_DETAIL },
  EVENT_PROPERTY: {
    SUCCESS_TAG_BY,
    CLICK_FROM: { CONTACTS_PROFILE },
    CATEGORY: { PEOPLE_CONCEPT },
  },
} = EVENT_PEOPLE_CONCEPT

export const getContactDetailApi = contactId =>
  apiInstance.get(
    buildPath(CONTACT_DETAIL_API, {
      contactId,
    }),
  )

export const updateContactDetailApi = (contactId, data) =>
  apiInstance.put(buildPath(CONTACT_DETAIL_API, { contactId }), {
    ...data,
  })

export const deleteContactApi = contactId =>
  apiInstance.delete(buildPath(CONTACT_DETAIL_API, { contactId }))

export const starContactApi = async (contactId, isStar) => {
  if (isStar) {
    const response = await apiInstance.post(
      buildPath(CONTACT_STAR_API, {
        contactId,
      }),
    )
    tracker.trackEvent(MARK_STAR_ON_CONTACT, {
      click_from: CONTACTS_PROFILE,
    })
    return response
  }
  const response = await apiInstance.delete(
    buildPath(CONTACT_STAR_API, {
      contactId,
    }),
  )
  tracker.trackEvent(UNSTARRED_CONTACT, {
    click_from: CONTACTS_PROFILE,
  })
  return response
}

export function* getContactDetailSaga({ payload }) {
  yield put(getContactDetailApiState.request())
  try {
    const response = yield call(getContactDetailApi, payload.contactId)
    yield put(getContactDetailApiState.success(parseContactDetail(response)))

    if (response?.isUnseen) yield put(setSeenContacts([payload.contactId]))

    yield call([tracker, 'trackEvent'], ENTER_CONTACT_DETAIL, {
      contact_email: response?.email,
    })
  } catch (err) {
    yield put(getContactDetailApiState.failure(err))
    if (err.status === 404) {
      yield put(push({ pathname: NOT_FOUND }))
    } else {
      yield put(openSweetAlertBaseError(err.status))
    }
  }
}

export function* updateContactDetailInstantlySaga({ payload }) {
  try {
    yield call(updateContactDetailApi, payload.contactId, payload.data)
  } catch (err) {
    yield put(
      addToast({
        bodyMessage: UPDATE_CONTACT_TOAST_FAILED,
        status: 'error',
        width: '350px',
      }),
    )
  }
}

export function* updateContactDetailSaga({ payload }) {
  const { buttonId, callback } = payload
  yield put(updateContactDetailApiState.request())
  try {
    const updatedKeys = Object.keys(payload.data)
    const response = yield call(updateContactDetailApi, payload.contactId, payload.data)
    yield put(
      updateContactDetailApiState.success({
        response,
        updatedKeys,
        buttonId,
      }),
    )
    if (callback) yield call(callback)
  } catch (err) {
    yield put(
      addToast({
        bodyMessage: UPDATE_CONTACT_TOAST_FAILED,
        status: 'error',
        width: '350px',
      }),
    )
    yield put(updateContactDetailApiState.failure({ err, buttonId }))
  }
}

export function* deleteContactSaga({ payload }) {
  yield put(deleteContactApiState.request())
  try {
    const response = yield call(deleteContactApi, payload.contactId)
    yield put(deleteContactApiState.success(response))
    yield put(
      addToast({
        bodyMessage: DELETE_TOAST_SUCCESS,
        status: 'success',
      }),
    )
    yield put(push({ pathname: CONTACTS }))
  } catch (err) {
    yield put(deleteContactApiState.failure(err))
    yield put(
      addToast({
        bodyMessage: DELETE_TOAST_FAILURE,
        status: 'error',
      }),
    )
  }
}

export function* starContactSaga({ payload }) {
  if (payload.isFallback) return
  const contactDetail = yield select(selectContactDetail)
  try {
    yield call(starContactApi, contactDetail?._id, !!contactDetail.starredAt)
  } catch (err) {
    yield put(
      addToast({
        bodyMessage: DELETE_TOAST_FAILURE,
        status: 'error',
      }),
    )
    yield put(starContact({ isFallback: true, starredAt: payload?.starredAt })) // fallback to previous
  }
}

export function* tagContactSaga({ payload }) {
  const allSelectedTags = yield select(selectAllTags)
  yield put(tagContactApiState.request())
  try {
    const updatedContact = yield call(tagContactApi, payload.contactId, payload.tagId)
    const selectedTag = getTagByTagId(allSelectedTags, payload.tagId)
    yield call([tracker, 'trackEvent'], ADDED_TAG_SUCCESS, {
      tag_name: selectedTag?.name,
      success_tag_by: SUCCESS_TAG_BY.CHOOSE_EXISTING_TAG,
      contact_email: payload.email,
      category: PEOPLE_CONCEPT,
    })
    yield put(tagContactApiState.success(updatedContact))
  } catch (error) {
    yield put(tagContactApiState.failure())
    yield put(
      addToast({
        bodyMessage: TAG_TOAST_FAILURE,
        status: 'error',
      }),
    )
  }
}

export function* createAndTagContactSaga({ payload }) {
  yield put(tagContactApiState.request())
  try {
    const tag = yield call(createTagSaga, { payload })
    const updatedContact = yield call(tagContactApi, payload.contactId, tag?._id)
    yield call([tracker, 'trackEvent'], ADDED_TAG_SUCCESS, {
      tag_name: payload.name,
      success_tag_by: SUCCESS_TAG_BY.CREATE_NEW_TAG,
      contact_email: payload.email,
      category: PEOPLE_CONCEPT,
    })
    yield put(tagContactApiState.success(updatedContact))
  } catch (error) {
    yield put(tagContactApiState.failure())
    yield put(
      addToast({
        bodyMessage: TAG_TOAST_FAILURE,
        status: 'error',
      }),
    )
  }
}

export function* untagContactSaga({ payload }) {
  yield put(untagContactApiState.request())
  try {
    const updatedContact = yield call(untagContactApi, payload.contactId, payload.tagId)
    yield put(untagContactApiState.success(updatedContact))
  } catch (error) {
    yield put(untagContactApiState.failure())
    yield put(
      addToast({
        bodyMessage: TAG_TOAST_FAILURE,
        status: 'error',
      }),
    )
  }
}

export function* watchGetContactDetail() {
  yield takeLatest(GET_CONTACT_DETAIL_CALL, getContactDetailSaga)
}

export function* watchUpdateContactDetailInstantly() {
  yield takeEvery(UPDATE_CONTACT_DETAIL_INSTANTLY, updateContactDetailInstantlySaga)
}

export function* watchUpdateContactDetail() {
  yield takeEvery(UPDATE_CONTACT_DETAIL_CALL, updateContactDetailSaga)
}

export function* watchDeleteContact() {
  yield takeLatest(DELETE_CONTACT_CALL, deleteContactSaga)
}

export function* watchStarContact() {
  yield takeLatest(STAR_CONTACT, starContactSaga)
}

export function* watchCreateAndTagContact() {
  yield takeLatest(CREATE_AND_TAG_CONTACT_CALL, createAndTagContactSaga)
}

export function* watchUntagContact() {
  yield takeLatest(UNTAG_CONTACT_CALL, untagContactSaga)
}

export function* watchTagContact() {
  yield takeLatest(TAG_CONTACT_CALL, tagContactSaga)
}

export function* rootSaga() {
  yield all([
    watchGetContactDetail(),
    watchUpdateContactDetail(),
    watchUpdateContactDetailInstantly(),
    watchDeleteContact(),
    watchStarContact(),
    watchCreateAndTagContact(),
    watchUntagContact(),
    watchTagContact(),
  ])
}

export default rootSaga
