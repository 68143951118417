import { buildPath } from '@rentspree/path'
import { PERMALINK_ACTIONS_ID, SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT } from 'constants/route'
import omit from 'lodash/omit'

/**
 *  useTenantScreeningStepUrl returns tenant screening step url getter functions.
 */
export const useTenantScreeningStepUrl = () => {
  const getFirstScreeningRequestStepUrl = () => SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT
  
  const getBuiltPathPermalinkActionId = ({ propertyId, query }) => buildPath(PERMALINK_ACTIONS_ID, { propertyId }, omit(query, 'agentType'))

  return {
    getFirstScreeningRequestStepUrl,
    getBuiltPathPermalinkActionId
  }
 }