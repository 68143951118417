import produce from 'immer'

import {
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_REQUEST,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_SUCCESS,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_FAILURE,
  CLOSE_CREATE_ASSOCIATION_CONTACT_MODAL,
  OPEN_CREATE_ASSOCIATION_CONTACT_MODAL,
  OPEN_EDIT_ASSOCIATION_CONTACT_MODAL,
} from './constants'

export const initialState = {
  isOpen: false,
  isSubmitting: false,
  isCreating: true,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_CREATE_ASSOCIATION_CONTACT_MODAL:
        draft.isOpen = true
        draft.isSubmitting = false
        draft.isCreating = true
        break
      case CLOSE_CREATE_ASSOCIATION_CONTACT_MODAL:
        draft = initialState
        break
      case CREATE_ASSOCIATION_CONTACT_FROM_MODAL_REQUEST:
        draft.isSubmitting = true
        break
      case CREATE_ASSOCIATION_CONTACT_FROM_MODAL_SUCCESS:
      case CREATE_ASSOCIATION_CONTACT_FROM_MODAL_FAILURE:
        draft.isSubmitting = false
        break
      case OPEN_EDIT_ASSOCIATION_CONTACT_MODAL:
        draft.isOpen = true
        draft.isSubmitting = false
        draft.isCreating = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
