/* eslint-disable no-underscore-dangle */
import { combineReducers } from "redux"
import get from "lodash/get"
import {
  reducerIsFetching,
  reducerError,
} from "legacy/reducers/helper/handle-reducer"
import * as Types from "legacy/constants/action-types"
import {
  ARCHIVE_PROPERTY_SUCCESS,
  UNARCHIVE_PROPERTY_SUCCESS,
} from "containers/property/toggle-archive-property/constants"
import {
  GET_PROPERTY_LIST_REQUEST,
  GET_PROPERTY_LIST_SUCCESS,
  GET_PROPERTY_LIST_FAILED,
  CLEAR_PROPERTY_LIST,
  GET_PROPERTY_DROP_DOWN_REQUEST,
  GET_PROPERTY_DROP_DOWN_SUCCESS,
  GET_PROPERTY_DROP_DOWN_FAILED,
} from "./constants"

export const initialState = {
  list: [],
  pagination: {},
  isFetching: false,
  error: null,
  dropdown: [],
  isFetchingDropdown: false,
  errorDropdown: null,
}

const list = (
  state = initialState.list,
  { type, data, _id: inactivePropertyId, inactive, payload } = {},
) => {
  let propertyList
  switch (type) {
    case GET_PROPERTY_LIST_SUCCESS:
      return data
    case CLEAR_PROPERTY_LIST:
      return []
    // TODO: wow-1391 check this reducer's behaviour
    case Types.DEACTIVATE_PROPERTY_SUCCESS:
      propertyList = state.map(property => {
        const { _id: propertyId } = property
        if (propertyId === inactivePropertyId) {
          const nextProperty = { ...property, inactive }
          return nextProperty
        }
        return property
      })
      return propertyList
    case Types.REACTIVATE_PROPERTY_SUCCESS:
      propertyList = state.map(property => {
        const { _id: propertyId } = property
        if (propertyId === inactivePropertyId) {
          const nextProperty = { ...property, inactive: null }
          return nextProperty
        }
        return property
      })
      return propertyList
    case ARCHIVE_PROPERTY_SUCCESS:
    case UNARCHIVE_PROPERTY_SUCCESS:
      propertyList = state.map(property => {
        if (get(property, "_id") === get(payload, "_id")) {
          return {
            ...property,
            archived: payload?.archived,
          }
        }
        return property
      })
      return propertyList
    default:
      return state
  }
}

const pagination = (state = initialState.pagination, action) => {
  switch (action.type) {
    case GET_PROPERTY_LIST_SUCCESS:
      return action.pagination
    case CLEAR_PROPERTY_LIST:
      return {}
    default:
      return state
  }
}

const isFetching = (state = initialState.isFetching, action) => {
  switch (action.type) {
    case GET_PROPERTY_LIST_REQUEST:
    case Types.DEACTIVATE_PROPERTY_REQUEST:
    case Types.REACTIVATE_PROPERTY_REQUEST:
      return true
    case GET_PROPERTY_LIST_SUCCESS:
    case GET_PROPERTY_LIST_FAILED:
    case CLEAR_PROPERTY_LIST:
    case Types.DEACTIVATE_PROPERTY_SUCCESS:
    case Types.DEACTIVATE_PROPERTY_FAILED:
    case Types.REACTIVATE_PROPERTY_SUCCESS:
    case Types.REACTIVATE_PROPERTY_FAILED:
      return false
    default:
      return state
  }
}

const error = (...arg) =>
  reducerError(...arg, [GET_PROPERTY_LIST_FAILED, CLEAR_PROPERTY_LIST])

const dropdown = (state = initialState.dropdown, action) => {
  switch (action.type) {
    case GET_PROPERTY_DROP_DOWN_SUCCESS:
      return action.propertyDropdown || []
    default:
      return state
  }
}

const isFetchingDropdown = (...arg) =>
  reducerIsFetching(...arg, [
    GET_PROPERTY_DROP_DOWN_REQUEST,
    GET_PROPERTY_DROP_DOWN_SUCCESS,
    GET_PROPERTY_DROP_DOWN_FAILED,
  ])

const errorDropdown = (...arg) =>
  reducerError(...arg, [GET_PROPERTY_DROP_DOWN_FAILED])

export default combineReducers({
  list,
  pagination,
  isFetching,
  error,
  dropdown,
  isFetchingDropdown,
  errorDropdown,
})
