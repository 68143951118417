import generateApiCall from 'utils/generate-api-call'

import {
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUESTED,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCEEDED,
} from './constants'

export const getPayeeProviderAccountInformation = () => ({
  type: GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION,
})

export const getPayeeProviderAccountInformationAPI = generateApiCall([
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUESTED,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCEEDED,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED,
])
