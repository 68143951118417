import { matchPath } from 'react-router-dom'
import {
  APPLICATION_ALL_REPORTS_PAGE,
  APPLICATION_ALL_REPORTS_PAGE_WITHOUT_PROPERTY,
  TENANT_SCREENING,
  TENANT_SCREENING_PENDING,
} from 'constants/route'
import { useLastLocation } from 'react-router-last-location'

export const useToTenantScreeningList = () => {
  const lastLocation = useLastLocation()

  if (
    !matchPath(lastLocation?.pathname, TENANT_SCREENING_PENDING) &&
    !matchPath(lastLocation?.pathname, APPLICATION_ALL_REPORTS_PAGE) &&
    !matchPath(lastLocation?.pathname, APPLICATION_ALL_REPORTS_PAGE_WITHOUT_PROPERTY)
  )
    return null

  return {
    isRedirect: true,
    path: TENANT_SCREENING,
  }
}
