import React from "react"
import { compose } from "redux"
import PaymentProcessing from "components/templates/payment-processing"
import { withSaga, withConnect } from "./connect"
import { withReducer } from "../rent-estimate/connect"

export const RentEstimatePaymentProcessing = ({ actions, match }) => {
  const { rentEstimateId, process } = match.params
  return (
    <PaymentProcessing
      processingAction={() =>
        process === "complete"
          ? actions.callCompleteRentEstimatePayment({ rentEstimateId })
          : actions.callCancelRentEstimatePayment({ rentEstimateId })
      }
      loadingText="Processing, one moment..."
    />
  )
}

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(RentEstimatePaymentProcessing)
