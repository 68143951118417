import generateApiCall from 'utils/generate-api-call'

import {
  RETRY_PAYOUT,
  RETRY_PAYOUT_FAILED,
  RETRY_PAYOUT_REQUESTED,
  RETRY_PAYOUT_SUCCEEDED,
} from './constants'

export const retryPayout = payload => ({
  type: RETRY_PAYOUT,
  payload,
})

export const retryPayoutAPI = generateApiCall([
  RETRY_PAYOUT_REQUESTED,
  RETRY_PAYOUT_SUCCEEDED,
  RETRY_PAYOUT_FAILED,
])
