import React from "react"
import isEmpty from "lodash/isEmpty"
import findIndex from "lodash/findIndex"
import * as Styled from "./text-input-styled"
import { Text } from "../typography"
import loadingGif from "../../images/gifs/loader-black.svg"

export default class Input extends React.Component {
  constructor() {
    super()
    this.state = {
      value: "",
    }
  }

  componentWillMount() {
    const { value } = this.props
    this.setState({ value })
  }

  handleOnChange = e => {
    const { value } = e.target
    const { array, onChange } = this.props
    const error = validateNewDocName(value, array)
    this.setState({ value })
    if (onChange) {
      onChange(e, value, error)
    }
  }

  render() {
    const { value } = this.state
    const { flexGrow, loading, error, success } = this.props
    return (
      <Styled.inputGroup className="input-group" flexGrow={flexGrow}>
        <Styled.input
          {...this.props}
          autoFocus
          isError={!isEmpty(error)}
          value={value}
          onChange={this.handleOnChange}
        />
        {loading && <InputIcon icon="loading" />}
        {success && <InputIcon icon="success" />}
        {error && [
          <InputIcon key="icon" icon="error" />,
          <Styled.errorMessage key="message">
            <Text color="negativeRed">{error}</Text>
          </Styled.errorMessage>,
        ]}
      </Styled.inputGroup>
    )
  }
}

export function validateNewDocName(value, array) {
  if (isEmpty(value)) {
    return "Cannot be empty"
  }
  if (!isEmpty(value) && !value.match(/^[\w\d\-\s]+$/g)) {
    return "Invalid character"
  }
  if (array && findIndex(array, v => v.name === value) !== -1) {
    return "Invalid duplicate name"
  }
  return ""
}

export const InputIcon = ({ icon }) => {
  switch (icon) {
    case "loading":
      return <Styled.inputImg src={loadingGif} alt="coupon loading" />
    case "error":
      return <Styled.inputIcon className="input-icon far fa-times" />
    case "success":
      return <Styled.inputIcon className="input-icon far fa-check" />
    default:
      return null
  }
}
