import { CDN_URL } from 'env'

export const CONTENT_PAGE_HEADER = {
  TITLE: 'Dashboard',
  SUBTITLE: 'Welcome!',
}

export const GET_STARTED_CARDS = {
  TITLE: 'Get started',
  DESCRIPTION: 'Follow these steps to get the most out of RentSpree.',
  USAGE: 'dashboard',
}

export const TO_DO_LIST_CARDS = {
  TITLE: 'To-do list',
  DESCRIPTION: 'Keep up to date with these tasks',
  USAGE: 'dashboard_to-do_list',
}

export const INVITED_FEATURES = {
  PROPERTY_CREATION: 'property_creation',
  SHARE_REPORT: 'share_report',
  AGENT_INITIATE_PAYMENT: 'agent_initiate_payment',

  // NOTE: for those who are uninvited only, this feature doesn't literally exist in the user invitation
  UNINVITED_USER_DASHBOARD: 'uninvited_user_dashboard',

  // NOTE: for those who are uninvited that has at least one application and no user invitation
  UNINVITED_USER_DASHBOARD_TO_DO_LIST: 'uninvited_user_dashboard_to_do_list',
}

export const SEEN_ANNOUNCEMENT_TYPE = {
  GET_STARTED: 'GET_STARTED',
  NOTICES: 'NOTICES',
}
export const SEEN_ANNOUNCEMENT_GUIDED_TOUR_GROUP_KEY = 'onboarding-dashboard-v3-guided-tour'

export const START_GUIDED_TOUR_CMD = 'run_guided_tour'

export const LEARN_DRAWER = {
  TITLE: 'What to expect next',
  DEFAULT_LABEL: name => `Collaborate with ${name}`,
  AGENT_INITIATE_PAYMENT_LABEL: name => `${name} has set up the deposit for you`,
  AGENT_INITIATE_PAYMENT_FOOTER_BUTTON: 'Set up now',
}
export const OPEN_WHAT_TO_EXPECT_NEXT_DRAWER_CMD = 'open_drawer'

export const SEEN_FINAL_STATE_GET_STARTED = 'onboarding-dashboard-v3-seen-final-state-get-started'
export const AGENT_INITIATE_PAYMENT_ANNOUNCEMENT_BANNER =
  'agent-initiate-payment-announcement-banner'

export const SEEN_NEW_LOOK_GUIDED_TOUR_PASSIVE_DIALOG = 'seen_new_looks_guided_tour_passive_dialog'

export const GUIDED_TOUR_PASSIVE_DIALOG_CONTENT = {
  TITLE: 'Check out our new look',
  DESCRIPTION: 'We’ve updated our homepage. Let’s take a look at what’s new.',
  IMAGE_URL: `${CDN_URL}/static-files/onboarding/illus-motor-dog.svg`,
  IMAGE_ALT: 'human-motor-dog',
  CTA_TEXT: 'Take a tour',
}

export const SEEN_ONBOARDING_PARTNER_AGENT = 'onboarding-partner-agent'
