import { buildPath } from '@rentspree/path'

import * as PATH from 'constants/route'
import { PAYER_TYPES, LICENSE_TYPES } from 'constants/user'
import { APPLICATION_TYPE } from 'constants/application-type'

const { DRE } = LICENSE_TYPES

const hasLicense = (licenses = [], type) =>
  licenses.filter(license => license.type === type).length > 0

const generateBuildPath =
  ({ propertyId, screeningRequestId, otherQueryString }) =>
  (path, continuePathOverride = false) =>
    continuePathOverride && otherQueryString.continuePath
      ? otherQueryString.continuePath
      : buildPath(path, { propertyId }, { screeningRequestId, ...otherQueryString })

const createPathRules = path => [
  {
    applicationType: APPLICATION_TYPE.CAR_LRA,
    predicate: ({ licenses, requestOptions }) =>
      hasLicense(licenses, DRE) && requestOptions.payer === PAYER_TYPES.AGENT,
    path: path(PATH.REQUEST_UPDATE_SCREENING_FEE),
  },
  {
    applicationType: APPLICATION_TYPE.CAR_LRA,
    predicate: ({ licenses, requestOptions }) =>
      hasLicense(licenses, DRE) && requestOptions.payer === PAYER_TYPES.RENTER,
    path: path(PATH.PERMALINK_ACTIONS_SCREENING, true),
  },
  {
    applicationType: APPLICATION_TYPE.CAR_LRA,
    predicate: ({ licenses }) => !hasLicense(licenses, DRE),
    path: path(PATH.REQUEST_UPDATE_DRE_LICENSE),
  },
  {
    applicationType: APPLICATION_TYPE.RENTSPREE,
    predicate: () => true,
    path: path(PATH.PERMALINK_ACTIONS_SCREENING, true),
  },
]

export const selectScreeningRequestResultPath = ({
  requestOptions,
  licenses,
  propertyId,
  screeningRequestId,
  otherQueryString = {},
}) => {
  const path = generateBuildPath({
    propertyId,
    screeningRequestId,
    otherQueryString,
  })
  const rules = createPathRules(path)
  const selectedRule = rules.find(
    rule =>
      rule.applicationType === requestOptions.applicationType &&
      rule.predicate({ licenses, requestOptions }),
  )
  return selectedRule?.path || path(PATH.PERMALINK_ACTIONS_SCREENING, true)
}
