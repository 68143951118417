import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import Step from '@rentspree/component-2023.components.organisms.step'
import StepLabel from '@rentspree/component-2023.components.organisms.step-label'
import Stepper from '@rentspree/component-2023.components.organisms.stepper'
import React from 'react'
import styled from 'styled-components'

import useMobile from 'hooks/use-mobile'
import { COLOR } from 'styles/settings'
import { MOBILE_THRESHOLD } from 'utils/constants'
import { Modal } from 'v3/components/molecules/modal'

export const StyledBox = styled(Box)`
  margin-bottom: 16px;
  padding: 16px 24px;
  background: ${COLOR.neutral20};
  border-radius: 8px;
`

export const StyledStepper = styled(Stepper)`
  &&& {
    height: 100%;
  }
`

export const StyledStep = styled(Step)`
  ${({ isMobile }) =>
    `
      &&& {
        height: ${isMobile ? 'auto' : '92px'} !important;
      }
    `}
`

export const StyledStepLabel = styled(StepLabel)`
  ${({ isMobile, labelMaxWidth }) =>
    !isMobile &&
    `
      .MuiStepLabel-label {
        max-width: ${labelMaxWidth || '100px'} !important;
        text-align: center !important;
        white-space: pre-line;
      }
  `}
`

export const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`

export const ButtonWithOutMargin = styled(Button)`
  margin: 0;
`

export const StepperGuideModal = ({
  isOpen,
  title,
  description,
  steps = [],
  activeStep = 0,
  showBackButton = false,
  backButtonText = 'Close',
  confirmButtonText = 'Confirm',
  onClose,
  onConfirm,
  size,
  showCloseButton = false,
  className,
}) => {
  const isMobile = useMobile(MOBILE_THRESHOLD)
  const orientation = isMobile ? 'vertical' : 'horizontal'

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      size={size}
      footer={
        <>
          {showBackButton && (
            <ButtonWithOutMargin
              variant="text"
              color="secondary"
              className="underline"
              onClick={onClose}
            >
              {backButtonText}
            </ButtonWithOutMargin>
          )}
          <ButtonWithOutMargin variant="contained" color="secondary" onClick={onConfirm}>
            {confirmButtonText}
          </ButtonWithOutMargin>
        </>
      }
      onClose={onClose}
      showCloseButton={showCloseButton}
      className={className}
    >
      <StyledBox>
        <StyledStepper activeStep={activeStep} orientation={orientation}>
          {steps.map(({ title: stepTitle, isCompleted, icon, labelMaxWidth }) => (
            <StyledStep completed={isCompleted} isMobile={isMobile}>
              <StyledStepLabel
                labelDirection={isMobile ? '' : 'column'}
                icon={icon}
                isMobile={isMobile}
                labelMaxWidth={labelMaxWidth}
              >
                {stepTitle}
              </StyledStepLabel>
            </StyledStep>
          ))}
        </StyledStepper>
      </StyledBox>
      <Description>{description}</Description>
    </Modal>
  )
}
