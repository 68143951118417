import get from "lodash/get"
import { takeLatest, put, call, all, select } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import isNil from "lodash/isNil"
import { apiInstance } from "utils/api-interceptor"
import { mapRentRangeError } from "utils/rent-estimate"
import { RENT_ESTIMATE, PAYMENT } from "constants/error-messages"
import { disablePaymentBtn } from "legacy/actions/credit-report.action"
import getErrorStripeMessage from "containers/landlord-pay/helper/error-code-mapping"
import tracker from "../../legacy/tracker"
import { RENT_ESTIMATE as RENT_ESTIMATE_TRACK } from "../../legacy/tracker/const"
import {
  TYPES,
  API_RENT_ESTIMATE,
  API_RENT_ESTIMATE_SUBMIT,
  API_CREATE_PAYMENT_RENT_ESTIMATE,
} from "./const"
import {
  createRentEstimateRequest,
  createRentEstimateSuccess,
  createRentEstimateFailed,
  getPaymentRentEstimateSuccess,
  getPaymentRentEstimateFailed,
  submitPaymentRentEstimateSuccess,
  submitPaymentRentEstimateRequest,
  submitPaymentRentEstimateFailed,
  handlePaymentModal,
  handleModalSuccess,
  getPaymentRentEstimateRequest,
} from "./actions"
import { selectSubdomain } from "./selector"

export const createRentEstimateAPI = data =>
  apiInstance.post(buildPath(API_RENT_ESTIMATE), data)

export const submitPaymentRentEstimateAPI = (rentEstimateId, data) =>
  apiInstance.post(
    buildPath(API_RENT_ESTIMATE_SUBMIT, {
      rentEstimateId,
    }),
    data,
  )
export const createPaymentRentEstimateAPI = (rentEstimateId, subdomain) =>
  apiInstance.post(
    buildPath(
      API_CREATE_PAYMENT_RENT_ESTIMATE,
      { rentEstimateId },
      { provider: "stripe" },
    ),
    { subdomain },
  )

export function* getPaymentRentEstimateSaga({ rentEstimateId }) {
  let response
  yield put(getPaymentRentEstimateRequest())
  try {
    const subdomain = yield select(selectSubdomain)
    response = yield call(
      createPaymentRentEstimateAPI,
      rentEstimateId,
      subdomain,
    )
    yield put(getPaymentRentEstimateSuccess(response))

    window.location.assign(response?.paymentObject?.url)
  } catch (err) {
    yield put(getPaymentRentEstimateFailed())
  }
}

export function* createRentEstimateAndGetPaymentSaga({ payload }) {
  const { data } = payload
  let response
  yield put(createRentEstimateRequest())
  try {
    response = yield call(createRentEstimateAPI, data)
    yield call([tracker, "trackEvent"], RENT_ESTIMATE_TRACK.submitSuccess)
    yield call(getPaymentRentEstimateSaga, {
      rentEstimateId: get(response, "_id"),
    })
    yield put(createRentEstimateSuccess(response))
  } catch (err) {
    yield put(createRentEstimateFailed(err.data))
  }
}

export function* submitPaymentRentEstimateSaga({ payload }) {
  yield put(disablePaymentBtn(true))
  yield put(submitPaymentRentEstimateRequest())
  const { stripe, cardElement, paymentDetail, rentEstimateId } = payload
  // Get payment method from stripe
  let paymentMethod
  try {
    const stripeResponse = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement,
    })
    if (stripeResponse.error) {
      throw stripeResponse.error
    }
    paymentMethod = stripeResponse?.paymentMethod
  } catch (err) {
    console.error(
      "[Rent Estimate] Generate Payment method failed with error ======> ",
      err,
    )
    yield call(
      [tracker, "trackEvent"],
      RENT_ESTIMATE_TRACK.generateRentEstimateFail,
    )
    const errorMessageObject = {
      rentEstimatePaymentError: {
        message:
          getErrorStripeMessage({ ...err, errors: err }) ||
          RENT_ESTIMATE.PAYMENT_FAILED,
        contact: RENT_ESTIMATE.CONTACT,
      },
    }
    yield put(submitPaymentRentEstimateFailed(errorMessageObject))
    yield put(disablePaymentBtn(false))
    return
  }

  try {
    const response = yield call(submitPaymentRentEstimateAPI, rentEstimateId, {
      payment: {
        ...paymentDetail,
        paymentObject: {
          ...paymentDetail.paymentObject,
          nonce: paymentMethod.id,
        },
      },
    })
    yield call([tracker, "trackEvent"], RENT_ESTIMATE_TRACK.purchaseSuccess)
    yield put(submitPaymentRentEstimateSuccess(response))
    yield put(disablePaymentBtn(false))
    yield put(handleModalSuccess())
  } catch (err) {
    let errorMessageObject
    const { data = {}, status } = err || {}
    if (isNil(err)) {
      errorMessageObject = {
        rentEstimatePaymentError: {
          message: PAYMENT.FETCH_FAILED.TITLE,
          contact: PAYMENT.FETCH_FAILED.MESSAGE,
        },
      }
      yield call(getPaymentRentEstimateSaga, {
        rentEstimateId: get(payload, "rentEstimateId"),
      })
    } else if (status === 409) {
      errorMessageObject = {
        rentEstimatePaymentError: {
          message: getErrorStripeMessage(data) || RENT_ESTIMATE.PAYMENT_FAILED,
          contact: RENT_ESTIMATE.CONTACT,
        },
      }
      yield call(getPaymentRentEstimateSaga, {
        rentEstimateId: get(payload, "rentEstimateId"),
      })
    } else {
      yield call(
        [tracker, "trackEvent"],
        RENT_ESTIMATE_TRACK.generateRentEstimateFail,
      )
      errorMessageObject = { rentEstimateError: mapRentRangeError(data) }
      yield put(handlePaymentModal())
    }
    yield put(submitPaymentRentEstimateFailed(errorMessageObject))
    yield put(disablePaymentBtn(false))
  }
}

export function* watchCreateRentEstimate() {
  yield takeLatest(
    TYPES.CREATE_RENT_ESTIMATE_CALL,
    createRentEstimateAndGetPaymentSaga,
  )
}
export function* watchSubmitPayment() {
  yield takeLatest(
    TYPES.SUBMIT_RENT_ESTIMATE_CALL,
    submitPaymentRentEstimateSaga,
  )
}
export default function* rootSaga() {
  yield all([watchCreateRentEstimate(), watchSubmitPayment()])
}
