import React from 'react'
import styled from 'styled-components'
import {
  Section,
  Content,
  InformationPanel,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  SubSectionHeader,
  InformationLine,
} from 'components/reports/screening-reports/share/responsive-doc-components'

const ContentStyled = styled(Content)`
  margin-bottom: 0px !important;
`

export const Residence = ({ address1, address2, city, state, postalCode, county }) => (
  <Section>
    <SubSectionHeader title="Residence" />
    <ContentStyled>
      <InformationPanel>
        <InformationLine label="Address" value={address1} />
        {address2 && <InformationLine label=" " value={address2} />}
      </InformationPanel>
    </ContentStyled>
    <ContentStyled>
      <InformationPanel>
        <InformationLine label="City" value={city} />
      </InformationPanel>
      <InformationPanel>
        <InformationLine label="State" value={state} />
      </InformationPanel>
    </ContentStyled>
    <Content>
      <InformationPanel>
        <InformationLine label="Postal Code" value={postalCode} />
      </InformationPanel>
      <InformationPanel>
        <InformationLine label="County" value={county} />
      </InformationPanel>
    </Content>
  </Section>
)
