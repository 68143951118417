import { useEffect } from "react"
import { makeResizeListener } from "./helper"

/**
 * Using resize event's listener to set `--vh` CSS property by window.innerHeight
 */
export const useResizeVh = () => {
  useEffect(() => {
    const resizeListener = makeResizeListener()
    resizeListener()
    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
      document.documentElement.style.removeProperty("--vh")
    }
  }, [])
}
