import produce from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import { GET_APPLY_LINK_REQUEST, GET_APPLY_LINK_SUCCESS, GET_APPLY_LINK_FAILURE } from './constants'
import {
  CLEAR_LISTING_SYNDICATION,
  CREATE_LISTING_SYNDICATION_FAILURE,
  CREATE_LISTING_SYNDICATION_REQUEST,
  CREATE_LISTING_SYNDICATION_SUCCESS,
  DISABLE_LISTING_SYNDICATION_FAILURE,
  DISABLE_LISTING_SYNDICATION_REQUEST,
  DISABLE_LISTING_SYNDICATION_SUCCESS,
  DUPLICATE_LISTING_SYNDICATION_ADDRESS,
  ENABLE_LISTING_SYNDICATION_FAILURE,
  ENABLE_LISTING_SYNDICATION_REQUEST,
  ENABLE_LISTING_SYNDICATION_SUCCESS,
  GET_LISTING_SYNDICATION_FAILURE,
  GET_LISTING_SYNDICATION_REQUEST,
  GET_LISTING_SYNDICATION_SUCCESS,
  INVALID_LISTING_SYNDICATION_ADDRESS,
  LISTING_SYNDICATION_STATUS,
  SET_IS_POTENTIAL_FRAUD,
  UPDATE_CONTACT_DETAILS_FAILURE,
  UPDATE_CONTACT_DETAILS_REQUEST,
  UPDATE_CONTACT_DETAILS_SUCCESS,
} from './listing-syndication/constants'

export const initialState = {
  isFetchingGetApplyLink: false,
  screeningRequest: {
    proOption: {
      documents: [],
      references: [],
    },
    screeningOption: {
      application: false,
      applicationType: 'rentspree',
      credit_report: false,
      criminal: false,
      eviction: false,
      premium: false,
      payerType: 'renter',
      subdomain: 'rentspree',
    },
  },
  isFetchingListingSyndication: false,
  isSyndicatingListing: false,
  isUpdatingContactDetails: false,
  listingSyndication: null,
  isPotentialFraud: false,
}

/* eslint-disable no-param-reassign */
const listingManagementReducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case GET_APPLY_LINK_REQUEST:
        draft.isFetchingGetApplyLink = true
        break
      case GET_APPLY_LINK_SUCCESS:
        draft.isFetchingGetApplyLink = false
        draft.screeningRequest = payload
        break
      case GET_APPLY_LINK_FAILURE:
        draft.isFetchingGetApplyLink = false
        break

      case CLEAR_LISTING_SYNDICATION:
        draft.listingSyndication = null
        break
      case GET_LISTING_SYNDICATION_REQUEST:
        draft.isFetchingListingSyndication = true
        break
      case GET_LISTING_SYNDICATION_SUCCESS:
        draft.isFetchingListingSyndication = false
        draft.listingSyndication = cloneDeep(payload)
        break
      case GET_LISTING_SYNDICATION_FAILURE:
        draft.isFetchingListingSyndication = false
        draft.listingSyndication = {
          status: LISTING_SYNDICATION_STATUS.DISABLED,
        }
        break
      case ENABLE_LISTING_SYNDICATION_REQUEST:
      case CREATE_LISTING_SYNDICATION_REQUEST:
      case DISABLE_LISTING_SYNDICATION_REQUEST:
        draft.isSyndicatingListing = true
        break
      case ENABLE_LISTING_SYNDICATION_SUCCESS:
      case CREATE_LISTING_SYNDICATION_SUCCESS:
      case DISABLE_LISTING_SYNDICATION_SUCCESS:
        draft.isSyndicatingListing = false
        draft.listingSyndication = cloneDeep(payload)
        break
      case ENABLE_LISTING_SYNDICATION_FAILURE:
      case CREATE_LISTING_SYNDICATION_FAILURE:
      case DISABLE_LISTING_SYNDICATION_FAILURE:
        draft.isSyndicatingListing = false
        break
      case INVALID_LISTING_SYNDICATION_ADDRESS:
        draft.listingSyndication = cloneDeep(draft.listingSyndication) || {}
        draft.listingSyndication.isAddressInvalid = true
        break
      case DUPLICATE_LISTING_SYNDICATION_ADDRESS:
        draft.listingSyndication = cloneDeep(draft.listingSyndication) || {}
        draft.listingSyndication.isAddressDuplicated = true
        break
      case UPDATE_CONTACT_DETAILS_REQUEST:
        draft.isUpdatingContactDetails = true
        break
      case UPDATE_CONTACT_DETAILS_SUCCESS:
        draft.listingSyndication = { ...draft.listingSyndication, contactDetails: payload }
        draft.isUpdatingContactDetails = false
        break
      case UPDATE_CONTACT_DETAILS_FAILURE:
        draft.isUpdatingContactDetails = false
        break
      case SET_IS_POTENTIAL_FRAUD:
        draft.isPotentialFraud = payload
        break
      default:
        break
    }
    return draft
  })
/* eslint-enable no-param-reassign */

export default listingManagementReducer
