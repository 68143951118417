import styled from "styled-components"

import {
  S16,
  S24,
  T36,
  BlueGradientButton,
  Span,
} from "@rentspree/component-v2"

export const StyledT36 = styled(T36)`
  margin: 50px 0px 30px 0px;
  @media (max-width: 991px) {
    margin: 30px 0px;
  }
`
export const StyledS24 = styled(S24)`
  margin: 30px 0px 10px 0px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Source Sans Pro;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  @media (max-width: 991px) {
    padding: 0px 20px;
  }
`

export const Img = styled.img`
  width: 100%;
`

export const ActionButton = styled(BlueGradientButton)`
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 25px;
  @media (max-width: 991px) {
    width: 100%;
  }
`

export const HomeIcon = styled.i``

export const HomeInnerText = styled(Span)`
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 16px;
`

export const TextButton = styled(S16)`
  cursor: pointer;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 991px) {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
    padding: 0 20px;
  }
`
