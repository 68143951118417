/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import produce from 'immer'

import { CLEAR_RENTAL_PAYMENT_SETUP, SELECT_PROPERTY, SELECT_RENTER_INFO } from './constants'

export const initialState = {
  selectedProperty: undefined,
  selectedRenterInfo: undefined,
}

export const rentPaymentSetupReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case CLEAR_RENTAL_PAYMENT_SETUP:
        return initialState

      case SELECT_PROPERTY:
        draft.selectedProperty = payload
        break

      case SELECT_RENTER_INFO:
        draft.selectedRenterInfo = payload
        break

      default:
    }

    return draft
  })
