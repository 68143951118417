import produce from 'immer'

import {
  CREATE_AND_ASSIGN_PROPERTY_REQUEST,
  CREATE_AND_ASSIGN_PROPERTY_SUCCESS,
  CREATE_AND_ASSIGN_PROPERTY_FAILURE,
  CREATE_AND_SELECT_PROPERTY_REQUEST,
  CREATE_AND_SELECT_PROPERTY_SUCCESS,
  CREATE_AND_SELECT_PROPERTY_FAILURE,
  OPEN_CREATE_PROPERTY_MODAL,
  CLOSE_CREATE_PROPERTY_MODAL,
  APPEND_ASSIGN_PROPERTY_LIST,
  AUTO_SELECT_PROPERTY,
  SELECT_PROPERTY,
  CLEAR_CREATE_PROPERTY_MODAL_ERROR,
  GET_ASSIGN_PROPERTY_LIST_REQUEST,
  GET_ASSIGN_PROPERTY_LIST_SUCCESS,
  GET_ASSIGN_PROPERTY_LIST_FAILURE,
  ASSIGN_PROPERTY_REQUEST,
  ASSIGN_PROPERTY_SUCCESS,
  ASSIGN_PROPERTY_FAILURE,
  OPEN_EXISTING_SUBMISSION_ERROR_MODAL,
  OPEN_ASSIGN_PROPERTY_MODAL,
  CLOSE_ASSIGN_PROPERTY_MODAL,
} from './constants'

export const initialState = {
  propertyList: {
    data: [],
    selectedPropertyId: undefined,
    selectedParticipantId: undefined,
    isFetching: true,
    isError: false,
  },
  createPropertyModal: {
    isOpen: false,
    isSubmitting: false,
    isSuccess: false,
    isError: false,
    createdPropertyId: '',
  },
  isSubmitting: false,
  existingSubmissionErrorModal: {
    isOpen: false,
    errorPayload: {},
  },
  assignPropertyModal: {
    isOpen: false,
    participantId: undefined,
    nextAction: undefined,
    nextActionProps: undefined,
    createdPropertyId: undefined,
  },
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_ASSIGN_PROPERTY_LIST_REQUEST:
        draft.propertyList.isFetching = true
        break
      case GET_ASSIGN_PROPERTY_LIST_SUCCESS:
        draft.propertyList.isFetching = false
        draft.propertyList.data = payload.data
        draft.propertyList.isError = false
        break
      case GET_ASSIGN_PROPERTY_LIST_FAILURE:
        draft.propertyList.isFetching = false
        draft.propertyList.isError = true
        break
      case CREATE_AND_ASSIGN_PROPERTY_REQUEST:
      case ASSIGN_PROPERTY_REQUEST:
        draft.isSubmitting = true
        break
      case CREATE_AND_ASSIGN_PROPERTY_SUCCESS:
      case CREATE_AND_ASSIGN_PROPERTY_FAILURE:
      case ASSIGN_PROPERTY_SUCCESS:
      case ASSIGN_PROPERTY_FAILURE:
        draft.isSubmitting = false
        break
      case CREATE_AND_SELECT_PROPERTY_REQUEST:
        draft.createPropertyModal.isSubmitting = true
        break
      case CREATE_AND_SELECT_PROPERTY_SUCCESS:
        draft.createPropertyModal.isSubmitting = false
        draft.createPropertyModal.isSuccess = true
        draft.createPropertyModal.createdPropertyId = payload.propertyId
        draft.assignPropertyModal.createdPropertyId = payload.propertyId
        break
      case CREATE_AND_SELECT_PROPERTY_FAILURE:
        draft.createPropertyModal.isSubmitting = false
        draft.createPropertyModal.isError = true
        break
      case OPEN_CREATE_PROPERTY_MODAL:
        draft.createPropertyModal = {
          ...initialState.createPropertyModal,
          isOpen: true,
        }
        break
      case CLOSE_CREATE_PROPERTY_MODAL:
        draft.createPropertyModal = initialState.createPropertyModal
        break
      case CLEAR_CREATE_PROPERTY_MODAL_ERROR:
        draft.createPropertyModal.isError = false
        break
      case APPEND_ASSIGN_PROPERTY_LIST:
        draft.propertyList.data.push(payload)
        break
      case AUTO_SELECT_PROPERTY:
      case SELECT_PROPERTY:
        draft.propertyList.selectedPropertyId = payload
        break
      case OPEN_EXISTING_SUBMISSION_ERROR_MODAL:
        draft.existingSubmissionErrorModal.isOpen = payload.isOpen
        draft.existingSubmissionErrorModal.errorPayload = payload.errorPayload
        break
      case OPEN_ASSIGN_PROPERTY_MODAL:
        draft.assignPropertyModal.isOpen = true
        draft.assignPropertyModal.participantId = payload.participantId
        draft.assignPropertyModal.nextAction = payload.nextAction
        draft.assignPropertyModal.nextActionProps = payload.nextActionProps
        break
      case CLOSE_ASSIGN_PROPERTY_MODAL:
        draft.assignPropertyModal.isOpen = false
        draft.assignPropertyModal.participantId = undefined
        draft.assignPropertyModal.nextAction = undefined
        draft.assignPropertyModal.nextActionProps = undefined
        draft.assignPropertyModal.createdPropertyId = undefined
        draft.propertyList.selectedPropertyId = undefined
        break
      default:
        break
    }
    return draft
  })

export default reducer
