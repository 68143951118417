import generateApiCall from 'utils/generate-api-call'

import {
  CHECK_SHOW_ERROR_BADGE,
  CHECK_SHOW_ERROR_BADGE_FAILED,
  CHECK_SHOW_ERROR_BADGE_REQUESTED,
  CHECK_SHOW_ERROR_BADGE_SUCCEEDED,
} from './constants'

export const checkShowErrorBadge = payload => ({
  type: CHECK_SHOW_ERROR_BADGE,
  payload,
})

export const checkShowErrorBadgeAPI = generateApiCall([
  CHECK_SHOW_ERROR_BADGE_REQUESTED,
  CHECK_SHOW_ERROR_BADGE_SUCCEEDED,
  CHECK_SHOW_ERROR_BADGE_FAILED,
])
