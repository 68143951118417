import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import React from 'react'

import NullDataReport from 'components/reports/screening-reports/share/null-report'
import { HeaderDoc } from 'components/reports/screening-reports/share/doc-components'
import {
  PrintPageBreak,
  ReportWrapper,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  ApplicantOverview,
  ReportsBody,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import HeaderPrint from 'containers/reports/header'
import { convert } from 'helpers/convert'
import { NoReport } from 'legacy/components/reports/share/doc-components'
import CompanyInfoFooter from 'legacy/components/reports-v2/share/company-info-footer'
import { generateHeaderName } from 'legacy/components/reports-v2/share/function'

import { Attention } from './criminal-report/doc-components/attention'
import { criminalMapper } from './criminal-report/mapper'
import RequestedConsumerInformation from './criminal-report/requested-consumer'
import CriminalIdentity from './criminal-report/criminal-identity'

const CriminalReportComponent = ({
  renter,
  landlord,
  applicant,
  generatedOn,
  expiresOn,
  criminalRecords,
  showHeaderComponent,
  customBorder,
  customMargin,
}) => {
  const reportName = 'Background Check'

  if (!criminalRecords) {
    return <NullDataReport generatedOn={generatedOn} headerDoc={reportName} />
  }

  const criminalData = criminalMapper(criminalRecords)
  const { identities, requestedConsumer, records } = criminalData
  const fullName = generateHeaderName(requestedConsumer || generateHeaderName(applicant))
  const email = get(renter, 'email')
  const phoneNumber = get(renter, 'homePhoneNumber')
  const remarks = get(identities, '[0].Remarks')
  const ssnMessage = get(applicant, 'CreditReport[0].reportData.applicants[0].sSNMessage')

  const headerDocProps = {
    ssnMessage,
    text: reportName,
    transUnion: { expiresOn, generatedOn },
    renter,
    landlord,
  }

  const headerProps = {
    name: fullName,
    email,
    phone: convert(phoneNumber, 'phoneNumber'),
  }

  return (
    <ReportWrapper border={customBorder} margin={customMargin}>
      {showHeaderComponent && <HeaderPrint />}
      <ReportsBody>
        <HeaderDoc {...headerDocProps} />
        <ApplicantOverview {...headerProps} withIncorrectInfo />
        {!isEmpty(identities) ? (
          <>
            <RequestedConsumerInformation requestedConsumer={requestedConsumer} records={records} />
            <PrintPageBreak />
            {identities.map((identity, index) => (
              <CriminalIdentity identity={identity} index={index} />
            ))}
          </>
        ) : (
          <NoReport text="No records to return" />
        )}
        <CompanyInfoFooter isTUReport />
        <Attention remarks={remarks} />
      </ReportsBody>
    </ReportWrapper>
  )
}

export default CriminalReportComponent
