export const SUBTITLE_TEXT = "Create a professional profile with RentSpree!"

export const SUBTITLE_TEXT_MOBILE = "Create a professional profile!"

export const PROFILE_BANNER_TEXT =
  "Your profile page helps you promote your experience, feature your expertise, and market your listings to generate more leads."

export const CREATE_AGENT_PROFILE_BUTTON_TEXT = "Create Agent Profile"

export const X_LARGE_WIDTH = 1445

export const AP_BANNER_MAX_WIDTH = 1265

export const AP_BANNER_HEIGHT = 115
