import { PRO_PAGE } from "constants/route"
import iconRequestDoc from "../images/icons/feature-request-doc.png"
import iconRefCheck from "../images/icons/feature-ref-check.png"

const CUSTOM = {
  PAYMENT: "payment",
  SUBSCRIPTION: "subscription",
}
export const FEATURE_PRO = {
  DOCUMENT: "document",
  REFCHECKS: "reference",
  ...CUSTOM,
}

export const REQUIRE_ARC_CONTENT = {
  icon: iconRefCheck,
  title: "Only RentSpree PRO users have access to Auto Reference Checks",
  desc:
    "Looking for a way to quickly and easily verify information provided on rental applications? RentSpree can reach out to employers, landlords, and other references in order to verify crucial applicant information. Activate RentSpree PRO on your account to access this feature!",
  link: PRO_PAGE,
  tab: "ref_check",
}

export const REQUIRE_DOCUMENT_CONTENT = {
  icon: iconRequestDoc,
  title: "This feature is only available for RentSpree PRO users",
  desc:
    "With it, you’ll be able to request and receive supplemental documents from renters (driver license copies, pay stubs, bank statements, W2s, tax returns, etc.)",
  link: PRO_PAGE,
  tab: "other_docs",
}

export const ARC_APPLICATION_NOT_AVAILABLE = {
  icon: iconRefCheck,
  title: "Auto reference checks not available",
  desc:
    "Auto reference checks are not available for this applicant because you haven’t requested an application. Please request an application before using this feature.",
}
