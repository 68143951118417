import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { COLOR } from "styles/settings"
import { Link, Icon as IconV2 } from "@rentspree/component-v2"

const ButtonContainer = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
`

export const Icon = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  ${props => props.icon && `background-image: url("${props.icon}")`};
  @media (max-width: 991px) {
    margin-right: 5px;
  }
`

export const ButtonItem = styled(Link)`
  color: ${COLOR.fontBlue};
  font-size: ${props => props.textSize || "16px"};
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: ${props => (props.colGap ? "5px" : "")};
  > img {
    filter: none;
  }
  :hover {
    color: ${COLOR.newDeepBlue};
    cursor: pointer;
    > ${Icon} {
      ${props =>
        props.iconHover && `background-image: url("${props.iconHover}")`};
    }
  }
  :focus {
    color: ${COLOR.fontBlue};
  }
  ${props => props.disabled && `color: ${COLOR.textGrey};`}
`

export const TextWithIconButton = ({
  id,
  text,
  icon,
  onClick,
  iconHover,
  alt,
  isIconV2,
  iconSize,
  colGap,
  disabled,
  textSize,
}) => (
  <ButtonContainer key={id}>
    <ButtonItem
      id={id}
      textSize={textSize}
      onClick={onClick}
      colGap={colGap}
      iconHover={iconHover}
      disabled={disabled}
      target="_blank">
      {isIconV2 ? (
        <IconV2
          size={iconSize}
          width="10px"
          height="10px"
          name={icon}
          alt={alt}
        />
      ) : (
        <Icon icon={icon} alt={text} />
      )}

      {text}
    </ButtonItem>
  </ButtonContainer>
)

TextWithIconButton.propTypes = {
  /** id of textWithIconButton */
  id: PropTypes.string,
  /** Button wording */
  text: PropTypes.string,
  /** icon in front of text */
  icon: PropTypes.string,
  /** onClick button */
  onClick: PropTypes.func,
  /** icon when hover */
  iconHover: PropTypes.string,
  /** isIconV2 is bool when using icon-v2 components v2 */
  isIconV2: PropTypes.bool,
  /** iconSize */
  iconSize: PropTypes.string,
}

export default TextWithIconButton
