import { useSelector } from 'react-redux'
import { useLastLocation } from 'react-router-last-location'
import { buildPath } from '@rentspree/path'
import isEmpty from 'lodash/isEmpty'
import { usePathname } from 'hooks'
import { RENTAL_STATUS } from 'legacy/constants/rental-consts'
import { selectRentalDetail } from 'containers/reports/selectors'
import { selectPropertyId } from 'containers/tenant-screening/detail/selectors'
import { SINGLE_APPLICATION } from 'constants/route'
import { isGotoNewLocation } from 'hooks/helper'

const { SUBMITTED } = RENTAL_STATUS

export const useToAppDetailPath = queryString => {
  const lastLocation = useLastLocation()
  const { status, _id: rentalAppId } = useSelector(selectRentalDetail)
  const propertyId = useSelector(selectPropertyId)
  const pathname = usePathname()
  const destinationPath = `${SINGLE_APPLICATION}/all`

  if (status !== SUBMITTED || isEmpty(propertyId)) return null

  return {
    isRedirect: isGotoNewLocation({
      pathname,
      destinationPath,
      lastLocationPath: lastLocation?.pathname,
    }),
    path: buildPath(
      destinationPath,
      {
        rentalAppId,
        propertyId,
      },
      queryString,
    ),
  }
}
