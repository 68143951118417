/* eslint-disable no-param-reassign */
import React from "react"
import moment from "moment"
import compact from "lodash/compact"
import divide from "lodash/divide"
import filter from "lodash/filter"
import get from "lodash/get"
import includes from "lodash/includes"
import isArray from "lodash/isArray"
import isEmpty from "lodash/isEmpty"
import isFinite from "lodash/isFinite"
import join from "lodash/join"
import last from "lodash/last"
import round from "lodash/round"
import size from "lodash/size"
import startCase from "lodash/startCase"
import trim from "lodash/trim"
import words from "lodash/words"
import pluralize from "pluralize"
import {
  generateAddress as generateAddressHelper,
  generateFullName,
} from "@rentspree/helper"

import { convert } from "../../helper/convert"
import { CRIMINAL_RECORD_TYPE } from "../../../constants/criminal-report"

export const generateAddress = (address = {}) =>
  !isEmpty(address) &&
  arrayCommaSeparator(
    compact([
      `${address.street || address.streetAddress || ""}${
        address.unit_number ? ` #${address.unit_number}` : ""
      }`,
      address.city,
      address.state,
      address.zipCode || address.postalCode || address.PostalCode,
    ]),
  )

export const generateLocation = (location = {}) => {
  const locationArray = compact([
    location.courtLocationCity,
    location.courtLocationState,
  ])
  return isEmpty(locationArray) ? null : arrayCommaSeparator(locationArray)
}

export const arrayCommaSeparator = (array = [], separator = ", ") =>
  join(array, separator)

export const sumArray = (tradeLines, field) => {
  if (!isEmpty(tradeLines)) {
    return tradeLines.reduce(
      (acc, curr) => acc + (curr[field] ? parseInt(curr[field], 10) : 0),
      0,
    )
  }
  return 0
}

export const pluralizeWord = (word, value) => {
  let valueToInt = parseInt(value, 10)
  if (!isFinite(valueToInt)) {
    valueToInt = 0
  }
  return pluralize(word, valueToInt, true)
}

export const pluralizeWordOnlyUnit = (word, value) => {
  const pluralizedWord = pluralizeWord(word, value)
  return last(words(pluralizedWord))
}

export const countClosed = objectArray => {
  if (!isEmpty(objectArray)) {
    return objectArray.reduce((acc, obj) => {
      if (!isEmpty(obj.dateClosed) || !isEmpty(obj.dateClosedIndicator)) {
        acc += 1
      }
      return acc
    }, 0)
  }
  return 0
}

export const generateAka = (aka = {}) => {
  let birthDate = get(aka, "birthDate")
  birthDate = birthDate && `(${moment.utc(birthDate).format("MM/DD/YYYY")})`
  return compact([
    get(aka, "firstName"),
    get(aka, "middleName"),
    get(aka, "lastName"),
    get(aka, "suffix"),
    birthDate,
  ])
}
export const generateAkas = (akas = []) =>
  arrayCommaSeparator(
    akas.map(aka => arrayCommaSeparator(generateAka(aka), " ")),
  )

export const generatePaymentHistory = (
  paymentPatternStartDate,
  paymentPattern,
) => {
  // TODO: refactor 1 for
  let startMonth = moment(paymentPatternStartDate).month()
  let startYear = moment(paymentPatternStartDate).year()
  const endMonth = size(paymentPattern)
  const countYear = Math.ceil((endMonth - startMonth) / 12) + 1
  const paymentHistory = []

  for (let i = 0; i < countYear; i += 1) {
    paymentHistory.push({
      year: startYear,
      monthsRevers: [],
    })
    startYear = parseInt(startYear, 10) - 1
  }
  let oddStartMonth = 0
  paymentHistory.forEach(obj => {
    for (let i = startMonth; i >= oddStartMonth; i -= 1) {
      if (i < endMonth) {
        obj.monthsRevers.push(paymentPattern[i])
      } else {
        obj.monthsRevers.push(null)
      }
    }
    oddStartMonth = startMonth + 1
    startMonth += 12
  })
  return paymentHistory
}

export const countAccountRating = (paymentPattern, code) => {
  if (isEmpty(paymentPattern)) return 0
  const regex = new RegExp(`[^${code}]`, "g")

  return paymentPattern.replace(regex, "").length
}

export const groupTradelines = (tradelines = []) => ({
  active: tradelines.filter(tradeline => !isTradelineClosed(tradeline)),
  closed: tradelines.filter(tradeline => isTradelineClosed(tradeline)),
})

export const convertToMonthAndYearNum = date =>
  moment
    .utc(date)
    .add(10, "days")
    .calendar()
export const convertToMonthAndYear = date =>
  moment.utc(date).format("MMM, YYYY")
export const convertToMonthDayYear = date =>
  moment.utc(date).format("MMM DD, YYYY")
export const convertToDayMonthYear = date =>
  moment.utc(date).format("DD MMM YYYY")
export const convertToHourMinute = time =>
  moment.utc(time, "hh:mm:ssZ").format("HH:mm")

export const convertPhoneForm = data => convert(data, "phoneNumber")

export const convertToCommaAndDecimal = number =>
  number.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
export const capitalizeFirstLetter = string =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : ""

export const isEmptyArray = array => !isArray(array) || array.length === 0

export const creditLimitPercent = (balance, limit) => {
  let value = 0
  if (balance && limit) {
    value = divide(balance, limit) * 100
    value = value > 0 && value < 1 ? "< 1" : round(value)
  }
  return `${value}%`
}

export const getValueAndDefault = (obj, key) => get(obj, key, key)

export const isClosedRemark = remarkCode => {
  const closedCode = ["ACR", "ACT", "CBC", "CBG", "CLO", "CTR"]
  return includes(closedCode, remarkCode)
}

export const isTradelineClosed = tradeline =>
  !isEmpty(get(tradeline, "dateClosed")) ||
  !isEmpty(get(tradeline, "dateClosedIndicator")) ||
  isClosedRemark(get(tradeline, "remarksCode"))

export const generatePersonName = (person = {}) =>
  !isEmpty(person) &&
  (person.FullName
    ? person.FullName
    : generateFullName({
        prefix: person.PersonPrefixName,
        FirstName: person.PersonGivenName,
        MiddleName: person.PersonMiddleName,
        LastName: person.PersonSurName,
        Suffix: person.PersonSuffixName,
      }))

// CRIMINAL FUNCTION
export const generateCriminalAddress = (address = {}) => {
  if (isEmpty(address)) return null
  const street = get(address, "LocationStreet.StreetFullText")
  const city = get(address, "LocationCityName")
  const state = get(address, "LocationStateName")
  const postalCode = get(address, "LocationPostalCodeID.ID")
  return generateAddressHelper({ street, city, state, postalCode })
}

export const generatePhoneNumber = (telephoneNumber = []) =>
  !isEmpty(telephoneNumber) &&
  telephoneNumber.map((number, index) => (
    <div key={`tel-${index.toString()}`}>{convertPhoneNumber(number)}</div>
  ))

export const generateID = (idList = []) =>
  !isEmpty(idList) && mapPropertyToString(idList, "ID")

export const generateOtherId = (otherIds = []) =>
  !isEmpty(otherIds) &&
  otherIds.map((otherId, index) => (
    <div key={`other-id-${index.toString()}`}>
      {otherId.IDTypeText ? `${otherId.IDTypeText}: ` : ""}
      {otherId.ID}
    </div>
  ))

export const mapPropertyToString = (array = [], property, type) =>
  !isEmpty(array) &&
  array.map((item, index) => (
    <div key={`${property}-${index.toString()}`}>
      {convert(item[property], type)}
    </div>
  ))

export const getCriminalEvictionTypeWording = (isEviction, category) =>
  isEviction ? category : CRIMINAL_RECORD_TYPE[category]

export const getStateHeaderData = (category, isEviction) => ({
  category: isEviction ? category : CRIMINAL_RECORD_TYPE[category],
  idLabel: isEviction ? "Record ID" : "Entity ID",
  dateLabel: isEviction ? "Filed on" : "Last Updated",
})

export const generateHeaderName = (nameObject = {}) =>
  generateFullName(
    {
      FirstName:
        nameObject.PersonGivenName ||
        nameObject.firstName ||
        nameObject.FirstName,
      MiddleName:
        nameObject.PersonMiddleName ||
        nameObject.middleName ||
        nameObject.MiddleName,
      LastName:
        nameObject.PersonSurName || nameObject.lastName || nameObject.LastName,
      Suffix:
        nameObject.PersonSuffixName || nameObject.suffix || nameObject.Suffix,
    },
    { isPascalCase: true },
  )

export const convertNA = data => (data === "N/A" ? "-" : data)

export const parseCamelCaseText = camel => {
  const result = startCase(camel)
  return result.slice(-4) === "Text" ? result.slice(0, -5) : result
}

export const convertPhoneNumber = phoneNumberObject => {
  const areaCode = get(phoneNumberObject, "TelephoneAreaCodeID", "")
  const exchangeID = get(phoneNumberObject, "TelephoneExchangeID", "")
  const subscriberID = get(phoneNumberObject, "TelephoneSubscriberID", "")
  const extendNumber = get(phoneNumberObject, "TelephoneSuffixID")
  const phoneNumber =
    !isEmpty(areaCode) && !isEmpty(exchangeID) && !isEmpty(subscriberID)
      ? `(${areaCode})-${exchangeID}-${subscriberID} ${
          !isEmpty(extendNumber) ? `Ext. ${extendNumber}` : ""
        }`
      : ""
  return phoneNumberObject.TelephoneNumberFullID || trim(phoneNumber)
}

export const filterCoApplicant = (coApplicants, type) =>
  filter(coApplicants, coApplicant => get(coApplicant, "type") === type)

export const generateYearMonthDuration = (startDate, endDate) => {
  const momentStartDate = moment(startDate)
  const momentEndDate = moment(endDate)
  const monthDifferent = momentEndDate.diff(momentStartDate, "month")
  if (monthDifferent > 0) {
    const totalYear = Math.floor(monthDifferent / 12)
    const totalMonth = monthDifferent % 12
    const durations = []
    if (totalYear) durations.push(pluralizeWord("year", totalYear))
    if (totalMonth) durations.push(pluralizeWord("month", totalMonth))
    return join(durations, " and ")
  }
  return "less than a month"
}
