import React from "react"
import get from "lodash/get"

import { PARTICIPANT_TYPE } from "containers/reports/constants"
import { refCheckStatus } from "legacy/constants/ref-check-consts"

import { Td, Th, Tr } from "components/table/table-style"
import { Checkbox } from "../../../../../legacy/components/inputs/checkbox"
import { Text, I } from "../../../../../legacy/components/typography"
import { HeaderWithButton } from "../header-with-button"
import { RequestCheckBoxCol } from "../request-check-box-col"
import { Table, TBody, THead, Wrapper } from "../styled"
import { RefStatus } from "./ref-status"

export const RefChecksTemplate = ({
  application,
  refChecks,
  selectedCount,
  requestDisabled,
  onCheckAll,
  onClickSendRequest,
  onRequestRefs,
  isMobile,
  participantType,
  onClickRow,
  showError,
  checkIsBlur,
}) => (
  <Wrapper>
    <HeaderWithButton
      isMobile={isMobile}
      textButton="Send Requests"
      onClick={onClickSendRequest(onRequestRefs(refChecks))}
      showError={showError}
      description={
        !isMobile ? "Select reference(s) to verify" : "Select reference(s)"
      }
      errorText="Please select reference(s) to verify"
      buttonId="submitRequestRefsBtn"
      shouldShowProBadge={!get(application, "screeningOption.premium")}
    />
    <Table id="refChecksTable" data-testid="refs-table">
      <THead>
        <Tr BgColor="grayLight">
          <Th className="icon-col">
            <Checkbox
              id="checkAllRefCheckbox"
              className={
                selectedCount === refChecks.length ? "checked" : "checkMinus"
              }
              disabled={requestDisabled}
              onChange={onCheckAll}
              checked={selectedCount > 0}
              data-testid="checkbox-header-column"
            />
          </Th>
          <Th data-testid="description-header-column">References</Th>
          <Th
            className="text-left hidden-xs hidden-sm"
            data-testid="status-header-column">
            Status
          </Th>
        </Tr>
      </THead>
      <TBody>
        {refChecks.map((ref, index) => (
          <Tr
            key={ref.id}
            onClick={event => onClickRow(index, event)}
            disabled={
              participantType === PARTICIPANT_TYPE.PARTICIPANT
                ? true
                : ref.isDisabled
            }
            isBlur={checkIsBlur(participantType, ref)}
            data-testid="ref-detail-row">
            <RequestCheckBoxCol
              request={ref}
              requestSelected={ref.isChecked}
              requestDisabled={
                participantType === PARTICIPANT_TYPE.PARTICIPANT
                  ? true
                  : ref.isDisabled
              }
              requestSubmitted={ref.status === refCheckStatus.verified.value}
            />
            <Td
              className="col-xs-10 col-sm-10 col-md-5"
              data-testid="ref-name-and-status">
              <div>
                <Text>{ref.name}</Text>
                {ref.status === refCheckStatus.verified.value && (
                  <I
                    leftSpace="7px"
                    color="green"
                    className="fas fa-check-circle"
                    data-testid="ref-name-icon"
                  />
                )}
              </div>
              <div
                className="visible-xs visible-sm"
                data-testid="ref-status-mobile-view">
                <RefStatus reference={ref} />
              </div>
            </Td>
            <Td
              className="text-left hidden-xs hidden-sm"
              data-testid="ref-status">
              <RefStatus reference={ref} />
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  </Wrapper>
)
