import styled from "styled-components"
import { COLOR } from "../../styles/settings"

export * from "./text"
export * from "./heading"
export * from "./list"
export * from "./badge"

export const Hr = styled.hr`
  color: ${COLOR.lineGrey};
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    ${props => props.mMargin && `margin: ${props.mMargin}`};
  }
`
