import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { OPEN_SWEET_ALERT } from "redux-middleware/sweet-alert/constants"
import { API_ERRORS } from "constants/error-messages"

export const openSweetAlert = ({ ...props }) => ({
  type: OPEN_SWEET_ALERT,
  ...props,
})

export const openSweetAlertBaseError = status => {
  const errorConfig = API_ERRORS[status] || API_ERRORS[500]
  return openSweetAlert({
    preset: ALERT_PRESET.ERROR,
    option: errorConfig.option,
    promise: errorConfig.callback,
  })
}
