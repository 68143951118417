/* eslint-disable no-param-reassign */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { query } from '@rentspree/path'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { getLocalItem } from '@rentspree/cookie'
import { generateAddress } from '@rentspree/helper'

import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { REPRESENTING_RADIO_AVAILABLE_FOR, OTHER_ROLE_NAME_PREFIX } from 'containers/request/step-create-property/constants'
import { LISTING_LANDING_SELECT_PROPERTY_PAGE, REQUEST_UPDATE_PROPERTY } from 'constants/route'
import { LoadingOverlay } from 'legacy/components/request/left-content/index'
import { STORAGE } from 'constants/cookie'
import { RequestContainer } from 'legacy/components/request/request-main-style'
import tracker from 'tracker'
import { ZIPLOGIX_TRACKING, CREATE_PROPERTY_EVENT } from 'tracker/const'
import { BACK_BUTTON_TYPE } from 'constants/back-button-type'
import CreatePropertyTemplate from 'components/templates/create-property/index'
import InvalidAddressConfirmModal from 'containers/address-verification/invalid-address-confirm-modal'
import StandardizedAddressModal from 'containers/address-verification/standardized-address-modal'
import { withAddressVerificationReducer, withAddressVerificationSaga } from "containers/address-verification/connect"
import ValidAddressConfirmModal from 'containers/address-verification/valid-address-confirm-modal'
import { withReducer } from './connect'

const { EVENT_NAME } = CREATE_PROPERTY_EVENT
export class StepCreateProperty extends React.Component {
  // TODO: add modal state
  constructor(props) {
    super(props)
    const { search } = get(props, 'location', {})
    const { continueTo, source } = query.parse(search)

    this.state = {
      continueTo,
      source,
      showConfirmModal: false,
      showInvalidAddressConfirmModal: false,
      showStandardizedAddressModal: false,
      property: {},
      isValid: true,
      isContinueToPropertyDetails: false,
      isAutoFill: false,
      durationStartTimer: undefined,
      clickCount: 1,
      sessionToken: "",
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.durationStartTimer !== nextState.durationStartTimer) {
      return false
    }

    // Don't update if props is not changed
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.props) {
      if (typeof this.props[key] !== 'function') {
        if (JSON.stringify(this.props[key]) !== JSON.stringify(nextProps[key])) {
          return true
        }
      }
    }

    // Don't update if state is not changed
    if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      return true
    }

    return false
  }

  componentDidMount() {
    if (this.isExternalIntegration()) {
      this.getPropertyForZiplogix()
    }
    if (this.props.addressVerification) {
      this.props.actions.resetAddressVerification()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile && this.isExternalIntegration()) {
      this.getPropertyForZiplogix()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isVerified, inputAddress, standardizedAddress } = nextProps.addressVerification
    if (standardizedAddress && !isEqual(standardizedAddress, inputAddress)) {
      this.setState({ showStandardizedAddressModal: true })
      return
    }
    if (isVerified === true) {
      this.setState({ showConfirmModal: true })
      return
    }
    if (isVerified === false) {
      this.setState({ showInvalidAddressConfirmModal: true })
    }
  }

  getPropertyForZiplogix() {
    // get this.props.listProperties
    this.props.actions.getPropertyDropdown()
  }

  isExternalIntegration() {
    return this.props.profile.externalIntegration
  }

  checkExternalIntegration() {
    return this.isExternalIntegration() && this.props.propertyDropdown.dropdown.length > 0
  }

  isLoading() {
    const { propertyDropdown, isCreatingProperty } = this.props
    return propertyDropdown.isFetching || isCreatingProperty
  }

  handleVerifyAddress = (property, isValid = true, isContinueToPropertyDetails = false) => {
    const finishedTime = new Date()
    const { isAutoFill, durationStartTimer, clickCount } = this.state
    const durationInSec = durationStartTimer
      ? Math.floor((finishedTime.getTime() - durationStartTimer.getTime()) / 1000)
      : undefined

    tracker.trackEvent(EVENT_NAME.CLICK_NEXT, {
      duration: durationInSec,
      is_autofill: isAutoFill,
      click_count: clickCount,
    })

    this.setState({
      property,
      isValid,
      isContinueToPropertyDetails,
      clickCount: clickCount + 1,
    })

    const { street, unitNumber, city, state, zip } = property
    const address = { street, unitNumber, city, state, zip }
    this.props.actions.verifyPropertyAddress({ address, sessionToken: this.state.sessionToken })
    this.setSessionToken('')
  }

  handleConfirmSubmitProperty = (property, isValid = true, isContinueToPropertyDetails = false) => {
    const finishedTime = new Date()
    const { isAutoFill, durationStartTimer, clickCount } = this.state
    const durationInSec = durationStartTimer
      ? Math.floor((finishedTime.getTime() - durationStartTimer.getTime()) / 1000)
      : undefined

    tracker.trackEvent(EVENT_NAME.CLICK_NEXT, {
      duration: durationInSec,
      is_autofill: isAutoFill,
      click_count: clickCount,
    })

    // TODO: change modal state -> open
    this.setState({
      showConfirmModal: true,
      property,
      isValid,
      isContinueToPropertyDetails,
      clickCount: clickCount + 1,
    })
  }

  handleCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false })
    tracker.trackEvent(EVENT_NAME.CLICK_CLOSE_MODAL)
    this.props.actions.resetAddressVerification()
  }

  handleCloseInvalidAddressConfirmModal = () => {
    this.setState({ showInvalidAddressConfirmModal: false })
    this.props.actions.resetAddressVerification()
  }

  handleCloseStandardizedAddressModal = () => {
    this.setState({ showStandardizedAddressModal: false })
    this.props.actions.resetAddressVerification()
  }

  handleEditAddress = () => {
    this.setState({ showConfirmModal: false })
    tracker.trackEvent(EVENT_NAME.CLICK_EDIT_ADDRESS_MODAL)
    this.props.actions.resetAddressVerification()
  }

  handleUseSelectedAddress = (property, isValid, isContinueToPropertyDetails, propertyAddress, isAddressVerified) => {
    this.handleSubmitProperty({ ...property, ...propertyAddress }, isValid, isContinueToPropertyDetails, isAddressVerified)
  }

  handleSubmitProperty = (property, isValid = true, isContinueToPropertyDetails = false, isAddressVerified) => {
    const {
      history,
      subdomain,
      saveRequestProperty,
      createProperty,
      actions: { saveSelectedProperty, setContinueToPropertyDetails, resetAddressVerification },
    } = this.props
    property.subdomain = subdomain
    property.isAddressVerified = this.state.isAutoFill || isAddressVerified
    tracker.trackEvent(EVENT_NAME.CLICK_CONFIRM_MODAL)
    setContinueToPropertyDetails(isContinueToPropertyDetails)
    this.setState({ showConfirmModal: false, showInvalidAddressConfirmModal: false, showStandardizedAddressModal: false })

    if (isValid) {
      saveRequestProperty(property)
      createProperty(
        property,
        isContinueToPropertyDetails,
        this.state.continueTo,
        this.state.source,
      )
      resetAddressVerification()
    } else {
      if (this.isExternalIntegration()) {
        tracker.trackEvent(ZIPLOGIX_TRACKING.SELECT_WRONG_VALIDATION)
      }
      saveSelectedProperty(property)
      history.push(REQUEST_UPDATE_PROPERTY)
    }
  }

  chooseButtonType() {
    switch (this.props.lastLocation?.pathname) {
      case LISTING_LANDING_SELECT_PROPERTY_PAGE:
        return BACK_BUTTON_TYPE.PREV
      default:
        return BACK_BUTTON_TYPE.BACK
    }
  }

  submitPropertyHandler() {
    const { isAutoFill } = this.state
    if (!isAutoFill) {
      return this.handleVerifyAddress
    }
    return this.handleConfirmSubmitProperty
  }

  setSessionToken(generatedToken) {
    this.setState({ sessionToken: generatedToken })
  }

  render() {
    const {
      propertyDropdown,
      profile: { registeredUserType },
      addressVerification,
      isVerifyingPropertyAddress,
    } = this.props

    const { inputAddress, standardizedAddress, isVerified } = addressVerification || {}
    const { property, showConfirmModal, showInvalidAddressConfirmModal, showStandardizedAddressModal, isValid, isContinueToPropertyDetails } = this.state
    const { street, unitNumber, city, state, zip, landlordProfile, userInputRole } = property
    const addressInfo = { street, unitNumber, city, state, zipcode: zip }

    const isAgentOrRenter = REPRESENTING_RADIO_AVAILABLE_FOR.includes(registeredUserType)

    const { firstName = "", lastName = "", email = "" } = landlordProfile || {}
    const otherRoleName = userInputRole && userInputRole.startsWith(OTHER_ROLE_NAME_PREFIX) ? userInputRole.split(OTHER_ROLE_NAME_PREFIX)[1] : ""
    const createPropertyFormInfo = { ...addressInfo, firstName, lastName, email, otherRoleName }
    const backButtonType = this.chooseButtonType()

    const handleAutoFilledUsedTracker = ({ isAutoFillValue }) => {
      this.setState({
        isAutoFill: isAutoFillValue,
      })
    }

    const handleAutoFilledStartTimer = ({ startTimer }) => {
      this.setState({ durationStartTimer: startTimer })
    }

    return (
      <RequestContainer>
        {this.isLoading() && <LoadingOverlay />}

        <TitleWithSubtitle title="Add new property" mb="30" />

        {!this.isLoading() && (
          <CreatePropertyTemplate
            prefillProperty={getLocalItem(STORAGE.REQUEST_PROPERTY)}
            properties={propertyDropdown.dropdown}
            externalIntegration={this.checkExternalIntegration()}
            zipformData={getLocalItem(STORAGE.ZF_DATA)}
            submitProperty={this.submitPropertyHandler()}
            isAgentOrRenter={isAgentOrRenter}
            isButtonLoading={isVerifyingPropertyAddress}
            backButtonType={backButtonType}
            onHandleAutoFilledUsedTracker={handleAutoFilledUsedTracker}
            onHandleAutoFilledStartTimer={handleAutoFilledStartTimer}
            sessionToken={this.state.sessionToken}
            setSessionToken={(generatedToken) => this.setSessionToken(generatedToken)}
            {...this.props}
            {...createPropertyFormInfo}
          />
        )}
        <ValidAddressConfirmModal
          isOpen={showConfirmModal}
          onClose={this.handleCloseConfirmModal}
          handleEditAddress={this.handleEditAddress}
          handleSubmitProperty={() =>
            this.handleSubmitProperty(property, isValid, isContinueToPropertyDetails, isVerified)
          }
          generatedAddress={generateAddress(property)}
        />
        <InvalidAddressConfirmModal
          isOpen={showInvalidAddressConfirmModal}
          onClose={this.handleCloseInvalidAddressConfirmModal}
          handleSubmitProperty={() =>
            this.handleSubmitProperty(property, isValid, isContinueToPropertyDetails, isVerified)
          }
          handleEditInvalidAddress={this.handleCloseInvalidAddressConfirmModal}
          generatedAddress={generateAddress(property)}
        />
        <StandardizedAddressModal
          isOpen={showStandardizedAddressModal}
          onClose={this.handleCloseStandardizedAddressModal}
          handleSubmitProperty={(propertyAddress, isAddressVerified) => this.handleUseSelectedAddress(property, isValid, isContinueToPropertyDetails, propertyAddress, isAddressVerified)}
          enteredAddress={inputAddress}
          standardizedAddress={standardizedAddress}
          isAddressVerified={isVerified}
        />
      </RequestContainer>
    )
  }
}

export default compose(withReducer,
  withRouter,
  withAddressVerificationReducer,
  withAddressVerificationSaga,
)(StepCreateProperty)
