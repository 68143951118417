import React from 'react'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import { S24 } from '@rentspree/component-v2'
import styled from 'styled-components'
import { Loading } from 'components/layout/main'
import InviteLandlordRentPaymentForm from './form'
import { TITLE } from './constants'

const TitleSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 440px;
`

export const InviteLandlordRentPaymentModalComponent = ({
  isOpen,
  isLoading,
  isSubmitting,
  email,
  firstName,
  lastName,
  onSubmit,
  onClose,
}) => (
  <RentSpreeBaseModal isOpen={isOpen} onClose={onClose}>
    {isLoading ? (
      <Container>
        <Loading />
      </Container>
    ) : (
      <>
        <TitleSection>
          <S24 mSize="24px" width="313px" margin="8px 0 28px" lineHeight="32px" center>
            {TITLE}
          </S24>
        </TitleSection>

        <InviteLandlordRentPaymentForm
          firstName={firstName}
          lastName={lastName}
          email={email}
          isSending={isSubmitting}
          handleSubmit={onSubmit}
          handleCloseModal={onClose}
        />
      </>
    )}
  </RentSpreeBaseModal>
)
