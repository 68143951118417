import React from "react"
import renderHTML from "react-render-html"
import { CenterContent } from "components/layout/main"
import { Button } from "../buttons/buttons"
import * as Styled from "./failed-to-fetch-style"

export const FailedToFetch = ({ title, text, ...props }) => (
  <CenterContent {...props}>
    <Styled.failedMessageDiv className="text-center">
      {title && <h3>{title}</h3>}
      <p>{text}</p>
      <Button
        id="reload-btn"
        onClick={() => window.location.reload()}
        primary
        text="Try Again"
        width="100%"
        maxWidth="200px"
      />
    </Styled.failedMessageDiv>
  </CenterContent>
)

export const FailedToFetchAndShouldIDo = props => (
  <CenterContent {...props}>
    <Styled.wrapperFailedShouldDo>
      <Styled.failedShouldIDoTitle className="text-center">
        {props.img && <img src={props.img} alt={props.name} />}
        {props.title && <p> {props.title} </p>}
      </Styled.failedShouldIDoTitle>
      <Styled.failedShouldIDo>
        <h3>What should I do?</h3>
        <ul>
          {props.shouldIDo.map(v => (
            <li> {v && renderHTML(v)} </li>
          ))}
        </ul>
      </Styled.failedShouldIDo>
    </Styled.wrapperFailedShouldDo>
  </CenterContent>
)
