import styled from 'styled-components'
import { ANIMATE, COLOR } from '../../styles/settings'
import { APP_STATUS } from '../../constants/application-consts'
import { TableCss } from './table-style'

// language=SCSS prefix=dummy{ suffix=}
export const ApplicationTable = styled.table`
  ${TableCss};

  font-family: Inter;

  span {
    justify-content: center;
  }

  .text-left > span {
    justify-content: flex-start;
  }
  .text-right > span {
    justify-content: flex-end;
  }

  td,
  th {
    @media (max-width: 991px) {
      &:last-child {
        display: table-cell;
        text-align: right;
        padding-right: 0px !important;
      }
    }

    @media (min-width: 992px) {
      &:last-child {
        &:last-child {
          padding-right: 32px !important;
        }
      }
    }
  }
  .open-report {
    font-weight: normal;
    color: ${COLOR.placeholderGrey};
  }
  @media (min-width: 992px) {
    display: ${props => (props.isHideDesktop ? 'none' : 'table')};
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const AppIconStatus = styled.img`
  width: 20px;
  height: 20px;
  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
  }
`
export const AppStatusText = styled.span`
  color: ${props => (props.status ? APP_STATUS[props.status].color : APP_STATUS.all.color)};
`
// language=SCSS prefix=dummy{ suffix=}
export const AppName = styled.div`
  > span {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
`

export const HeadMobile = styled.span`
  a {
    display: initial;
    color: ${COLOR.black};
    display: none;

    @media (max-width: 991px) {
      display: flex;
      align-items: center;
      transition: opacity 0.8s;
      div {
        padding-left: 10px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const HeadDesktop = styled.span`
  @media (max-width: 991px) {
    display: none;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const SortTableTh = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &:hover {
    > i {
      opacity: 1;
    }
  }
  > i {
    transition: opacity ease ${ANIMATE.fast};
    display: ${props => (props.sorting ? 'block' : 'none')};
    font-size: 0.8em;
    margin-left: 7px;
    position: relative;
    top: 5px;
  }
`
