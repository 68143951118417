import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { toggleShareReportModal } from 'containers/share-report/actions'
import {
  openAssignPropertyModal,
  openExistingSubmissionErrorModal,
} from 'containers/tenant-screening/assign-property/actions'
import { addToContact, deleteContact } from 'containers/contacts/add-to-contact/actions'
import { selectExistingSubmissionErrorModal } from 'containers/tenant-screening/assign-property/selectors'
import { selectMultiShare } from 'containers/multi-share/selector'
import { printReport, savePDF } from '../actions'
import {
  selectRentalDetail,
  selectPdfReport,
  makeSelectIsShareAvailable,
  selectApplicationDetail,
  selectParticipantId,
  selectIsRenter,
} from '../selectors'
import {
  selectIsFoundContactByEmail,
  selectSubmissionParticipantContactId,
  selectIsAddDeleteContactLoading,
} from '../../contacts/add-to-contact/selectors'

export const makeMapStateToProps = () =>
  createStructuredSelector({
    rentalDetail: selectRentalDetail,
    pdfReport: selectPdfReport,
    submissionParticipantContactId: selectSubmissionParticipantContactId,
    isShareAvailable: makeSelectIsShareAvailable(),
    isFoundContact: selectIsFoundContactByEmail,
    applicationDetail: selectApplicationDetail,
    isAddDeleteContactLoading: selectIsAddDeleteContactLoading,
    submissionParticipantId: selectParticipantId,
    existingSubmissionErrorModal: selectExistingSubmissionErrorModal,
    multiShare: selectMultiShare,
    isRenter: selectIsRenter,
  })

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      printReport,
      savePDF,
      toggleShareReportModal,
      openAssignPropertyModal,
      deleteContact,
      addToContact,
      openExistingSubmissionErrorModal,
    },
    dispatch,
  )

export const withConnect = compose(connect(makeMapStateToProps, mapDispatchToProps))
