import get from "lodash/get"
import { combineReducers } from "redux"
import { generateAddress } from "@rentspree/helper"
import togglePropertyFeatureModal from "containers/property/toggle-property-feature/reducer"
import archiveModal from "containers/property/toggle-archive-property/reducer"
import {
  DISABLE_FEATURE,
  ENABLE_FEATURE,
} from "containers/property/toggle-property-feature/constants"
import {
  ARCHIVE_PROPERTY_SUCCESS,
  UNARCHIVE_PROPERTY_SUCCESS,
} from "containers/property/toggle-archive-property/constants"
import { UPDATE_PROPERTY_LISTING_STATUS } from "containers/listing-management/constants"
import * as Types from "../constants/action-types"

const property = (state = {}, action) => {
  switch (action.type) {
    case Types.CREATE_PROPERTY_SUCCESS:
      return {
        ...action.data,
        fullNameAddress: generateAddress(action.data) || "",
        createSuccess: true,
        fetchPropertySuccess: false,
      }
    case Types.GET_PROPERTY_SUCCESS:
      return {
        ...action.data,
        fullNameAddress: generateAddress(action.data) || "",
        createSuccess: false,
        fetchPropertySuccess: true,
      }
    case Types.GET_PERMALINK_SUCCESS:
      return {
        ...state,
        ...action.data,
      }
    case Types.REACTIVATE_PROPERTY_SUCCESS:
    case Types.DEACTIVATE_PROPERTY_SUCCESS:
      return {
        ...state,
        inactive: action.inactive || null,
      }
    case Types.CLEAR_PROPERTY:
      return {}
    case Types.CLEAR_CREATE_PROPERTY_NOTI:
      return { ...state, createSuccess: false }
    case DISABLE_FEATURE.SUCCESS:
    case ENABLE_FEATURE.SUCCESS:
      return {
        ...state,
        disabledFeatures: get(action, "payload.disabledFeatures", {}),
        archived: get(action, "payload.archived", undefined),
      }
    case Types.CLEAR_DISABLED_FEATURES:
      return {
        ...state,
        disabledFeatures: {},
      }
    case ARCHIVE_PROPERTY_SUCCESS:
    case UNARCHIVE_PROPERTY_SUCCESS:
      return {
        ...state,
        archived: get(action, "payload.archived", undefined),
      }
    case UPDATE_PROPERTY_LISTING_STATUS:
      return {
        ...state,
        disabledFeatures: {
          ...state.disabledFeatures,
          listingApplication: action.payload,
        },
      }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case Types.CREATE_PROPERTY_REQUEST:
    case Types.GET_PROPERTY_REQUEST:
    case Types.GET_ZIPLOGIX_PROPERTY_REQUEST:
    case Types.GET_PERMALINK_REQUEST:
      return true
    case Types.CREATE_PROPERTY_SUCCESS:
    case Types.CREATE_PROPERTY_FAILED:
    case Types.GET_PROPERTY_SUCCESS:
    case Types.GET_PERMALINK_SUCCESS:
    case Types.GET_ZIPLOGIX_PROPERTY_SUCCESS:
    case Types.GET_PROPERTY_FAILED:
    case Types.GET_ZIPLOGIX_PROPERTY_FAILED:
      return false
    default:
      return state
  }
}

const isFetchingInactive = (state = false, action) => {
  switch (action.type) {
    case Types.DEACTIVATE_PROPERTY_REQUEST:
    case Types.REACTIVATE_PROPERTY_REQUEST:
      return true
    case Types.DEACTIVATE_PROPERTY_FAILED:
    case Types.DEACTIVATE_PROPERTY_SUCCESS:
    case Types.REACTIVATE_PROPERTY_FAILED:
    case Types.REACTIVATE_PROPERTY_SUCCESS:
      return false
    default:
      return state
  }
}

const isGetPropertyFail = (state = false, action) => {
  switch (action.type) {
    case Types.GET_PROPERTY_FAILED:
    case Types.GET_PERMALINK_FAILED:
      return true
    case Types.GET_PROPERTY_REQUEST:
    case Types.GET_PROPERTY_SUCCESS:
    case Types.GET_PERMALINK_SUCCESS:
    case Types.GET_PERMALINK_REQUEST:
      return false
    default:
      return state
  }
}

export default combineReducers({
  property,
  isFetching,
  isGetPropertyFail,
  isFetchingInactive,
  togglePropertyFeatureModal,
  archiveModal,
})
