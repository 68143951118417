import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import papa from 'papaparse'

import {
  NONE_OF_ABOVE,
  REMOVED,
  STATE_FULL,
  STATES_FULL_TO_CODE,
  BIRTH_DATE_NUMBER,
  BIRTH_DATE_TEXT,
  MOVE_IN_DATE_NUMBER,
  MOVE_IN_DATE_TEXT,
  STREET,
  CITY,
  STATE,
  ZIPCODE,
  CSV_MALFORM_ERROR,
  NO_CONTENT_ERROR,
} from './constants'
import { VALIDATORS } from './validations'

export const parseCsv = file =>
  new Promise((resolve, reject) => {
    papa.parse(file, {
      complete: ({ data, errors }) => {
        if (!isEmpty(errors)) {
          reject(new Error(CSV_MALFORM_ERROR))
        } else if (data.length < 2) {
          reject(new Error(NO_CONTENT_ERROR))
        } else {
          resolve(data)
        }
      },
      error: reject,
      skipEmptyLines: true,
    })
  })

export const mapRowToContactData = (row, fields = []) => {
  const contact = {}
  row.forEach((value, i) => {
    let field = fields[i]
    if (
      !isEmpty(field) &&
      ![NONE_OF_ABOVE, REMOVED].includes(field) &&
      VALIDATORS[field]?.isValidSync(value)
    ) {
      let mappedValue = value
      if (field === STATE_FULL) {
        mappedValue = STATES_FULL_TO_CODE[value]
      }

      if ([BIRTH_DATE_NUMBER, MOVE_IN_DATE_NUMBER].includes(field)) {
        mappedValue = moment(value, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
      }

      if ([BIRTH_DATE_TEXT, MOVE_IN_DATE_TEXT].includes(field)) {
        mappedValue = moment(value, 'MMM DD YYYY', true).format('YYYY-MM-DD')
      }

      if (field.includes('_')) field = field.split('_')?.[0]
      if ([STREET, CITY, STATE, ZIPCODE].includes(field)) {
        field = `livingAddress.${field}`
      }

      contact[field] = mappedValue
    }
  })
  return contact
}

export const getMappedHeader = headers => {
  const mappedHeader = []
  headers.forEach(header => {
    const normalizeHeader = header.replace(/[^a-zA-Z]+/g, '').toLowerCase()
    if (['email', 'emailaddress'].includes(normalizeHeader) && !mappedHeader.includes('email')) {
      return mappedHeader.push('email')
    }

    return mappedHeader.push('')
  })
  return mappedHeader
}
