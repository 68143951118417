import React from "react"
import classNames from "classnames/bind"
import style from "./download-pdf.scss"
import { LogoLoading } from "../loading"

const c = classNames.bind(style)

export const LoadingPDF = ({
  loading = true,
  message = "Please wait. We are generating your PDF file. You may close this window when the download is completed.",
  children,
  color,
}) => (
  <div className={c("container")}>
    <div className={c("loading-pdf")}>
      {loading ? (
        <div
          className={c("loading-pdf__title")}
          style={{ color: color || "#d7d7d7" }}>
          <Loader />
          {children ? <div>{children}</div> : <p>{message}</p>}
        </div>
      ) : (
        <div className={c("loading-pdf__title")}>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  </div>
)

export const Loader = () => (
  <div className={c("loader-wrapper")}>
    <LogoLoading />
  </div>
)
