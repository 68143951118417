import { createSelector } from "reselect"
import get from "lodash/get"
import { generateAddress } from "@rentspree/helper"

const getAllPropertyList = state => get(state, "allPropertyList")

export const selectIsLoading = () =>
  createSelector(
    getAllPropertyList,
    state => get(state, "isFetching", false),
  )

export const selectIsError = () =>
  createSelector(
    getAllPropertyList,
    state => get(state, "isError", false),
  )

export const makeSelectError = () =>
  createSelector(
    getAllPropertyList,
    state => get(state, "error", null),
  )

export const makeSelectIsWaring = () =>
  createSelector(
    getAllPropertyList,
    state => get(state, "isWarning", false),
  )

export const makeSelectPropertySelector = () =>
  createSelector(
    getAllPropertyList,
    state => get(state, "propertySelector", ""),
  )

export const makeSelectErrorPropertySelector = () =>
  createSelector(
    getAllPropertyList,
    state => get(state, "errorType", ""),
  )

export const selectPropertyList = () =>
  createSelector(
    getAllPropertyList,
    state =>
      get(state, "propertyList", []).map(property => ({
        label: generateAddress(property),
        value: get(property, "_id", ""),
      })),
  )

export const selectPropertyListWithDisabledOption = () =>
  createSelector(
    getAllPropertyList,
    state =>
      get(state, "propertyList", []).map(property => ({
        label: generateAddress(property),
        value: get(property, "_id", ""),
        disabled: !!get(property, "listing.publishedInformation", false),
      })),
  )
