// Unclassed HTML elements (type selectors)
import { createGlobalStyle } from "styled-components"

// language=SCSS prefix=dummy{ suffix=}
export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
    @import url("https://fonts.googleapis.com/css?family=Merriweather:400,700");

    html {
      height: 100%;
      width: 100%;
    }

    body {
      font-family: 'Lato', sans-serif, 'simple-line-icons';
      font-weight: 400;
      font-size: 14px;
      background: fixed center no-repeat;
      background-size: cover;
      overflow-x: hidden;
      -webkit-font-smoothing: antialiased;

      @media(max-width: 991px) {
        overflow-x: auto;
      }
    }

    a {
      text-decoration: none !important;
      color: rgb(61, 138, 247);
    }

    *:focus, button:focus, a:focus {
      outline: none;
    }

    img {
      max-width: 100%;
    }

    div:has([class^=Mui]) img.brand-logo {
      max-width: unset;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    .noPad {
      padding: 0 0 0 0;
    }

    @media print {
      #tooltipPrint { display: none; }
    }

    .RSPNavigation-content {
      overflow: auto !important;
    }
`
