import React from "react"
import { S16 } from "@rentspree/component-v2"
import { ProBadge } from "components/badge"
import styled from "styled-components"
import { TitleWrapper, ActionButton, TextError } from "./styled"

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const HeaderWithButton = ({
  textButton,
  disabled,
  onClick,
  showError,
  description,
  errorText,
  buttonId,
  shouldShowProBadge = true,
}) => (
  <TitleWrapper>
    <ButtonWrapper>
      <S16 mt="10" mb="10">
        {description}
      </S16>
      <ActionButton
        id={buttonId}
        text={textButton}
        disabled={disabled}
        onClick={onClick}
        data-testid="action-button">
        {shouldShowProBadge && (
          <ProBadge
            fontSize="12px"
            fontStyle="italic"
            fontFamily="Source Sans Pro">
            PRO
          </ProBadge>
        )}
      </ActionButton>
    </ButtonWrapper>
    {showError && <TextError>{errorText}</TextError>}
  </TitleWrapper>
)
