import React from 'react'
import Box from '@mui/material/Box'
import { PropertyListTable } from './table'
import { PropertyListPagination } from './pagination'

const PropertyListTableComponent = props => (
  <Box data-testid="property-list-table-component-container">
    {/* Mobile Card can be place here and toggled between PropertyListTable */}
    <PropertyListTable {...props} />
    <PropertyListPagination {...props} />
  </Box>
)

export default PropertyListTableComponent
