import React from "react"

import * as Styled from "./application-detail-style"

export const MobileTabsWrapper = ({ children, ...props }) => (
  <Styled.MobileTabsWrapper {...props}>{children}</Styled.MobileTabsWrapper>
)

export const MobileBreadcrumb = ({ children, ...props }) => (
  <Styled.MobileBreadcrumb {...props}>{children}</Styled.MobileBreadcrumb>
)

export const MobileTabs = ({ children, ...props }) => (
  <Styled.MobileTabs id="mTabs" {...props}>
    {children}
  </Styled.MobileTabs>
)

export const MobileTabItem = ({ text, children, onClick, ...props }) => (
  <Styled.MobileTabItem onClick={onClick}>
    <Styled.MobileTabItemText {...props}>{text}{children}</Styled.MobileTabItemText>
  </Styled.MobileTabItem>
)

export const MobileReport = ({ children, ...props }) => (
  <Styled.MobileReport {...props}>{children}</Styled.MobileReport>
)

export const MobileReportItem = ({ children, ...props }) => (
  <Styled.MobileReportItem {...props}>{children}</Styled.MobileReportItem>
)

export const MobileReportFooter = ({ children, ...props }) => (
  <Styled.MobileReportFooter {...props}>{children}</Styled.MobileReportFooter>
)
