import React from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import { generateAddress } from '@rentspree/helper'

import tracker from 'tracker'
import { SHARE_REPORT, SHARE_REPORT_PROPERTIES } from 'tracker/const'
import { DefaultModal } from 'components/modal'
import { ConfirmationInfo } from 'components/share-report'
import { selectRentalDetail } from 'containers/application/selectors'
import { selectAssociationContact } from 'containers/overview/association-contact/selectors'
import { withAssociationContact } from 'containers/overview/association-contact/connect'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useFeatureGate } from '@statsig/react-bindings'
import { INCOME_VERIFICATION_SHARE_REPORT } from 'utils/feature-flag/constants'
import { INCOME_VERIFICATION_STATE } from 'containers/reports/screening-reports/income-verification/constants'
import { withConnect, withReducer, withSaga } from './connect'
import { ShareReportForm } from './form'
import { ShareReportFormV2 } from './form-v2'

export const ShareReport = ({
  renter,
  renterDetail,
  renterEmail,
  property,
  screeningOption,
  reportAvailable,
  isLoading,
  isError,
  isShowShareModal,
  getReportsAvailable,
  toggleShareReportModal,
  shareReport,
  closeError,
  isExpired,
  isIDMAExpired,
  expiredDate,
  userEmail,
  applicationType,
  formValuesV2,
  setFormValuesV2,
  submitShareReportForm,
  incomeVerificationStatus,
  getDefaultShareOption,
  defaultShareOption,
}) => {
  const { firstName, lastName } = renter
  const { value: isShareIncomeVerificationReportEnabled } = useFeatureGate(
    INCOME_VERIFICATION_SHARE_REPORT,
  )
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [formValues, setFormValues] = React.useState({})
  const address = generateAddress(property)
  const landlordInformation = useSelector(selectAssociationContact)
  const rental = useSelector(selectRentalDetail)
  const isIncomeVerificationExpired = incomeVerificationStatus === INCOME_VERIFICATION_STATE.EXPIRED
  React.useEffect(() => {
    if (isShowShareModal && !isSubmitted) {
      getReportsAvailable()
      if (isShareIncomeVerificationReportEnabled) {
        getDefaultShareOption()
      }
    }
    if (!isShowShareModal) {
      setIsSubmitted(false)
      setFormValues({})
    }
  }, [isShowShareModal, isSubmitted])

  const handleHide = () => {
    if (!isLoading) toggleShareReportModal({ toggle: false })
  }

  const handleSubmitting = bool => values => {
    if (bool) {
      setFormValues(values)
      tracker.trackEvent(SHARE_REPORT.clickNext, {
        // eslint-disable-next-line no-underscore-dangle
        [SHARE_REPORT_PROPERTIES.rentalId]: rental?._id,
        [SHARE_REPORT_PROPERTIES.landlordAddedToProperty]:
          landlordInformation?.isAssociationContact || false,
      })
    }
    setIsSubmitted(bool)
  }

  const handleShare = () => {
    const { _id: propertyId } = property
    const { email, agentMessage, addLandlordToProperty } = formValues
    const shareOption = {
      application: formValues.application,
      screeningReport: formValues.screeningReport,
      incomeVerificationReport: formValues.incomeVerificationReport,
      otherDocs: formValues.otherDocs,
      referenceCheck: formValues.referenceCheck,
    }

    const landlord = {
      firstName: get(landlordInformation, 'firstName', ''),
      lastName: get(landlordInformation, 'lastName', ''),
    }

    shareReport({
      email,
      agentMessage,
      shareOption,
      landlord: formValues.shareWithLandlord && !isEmpty(landlord.firstName) ? landlord : undefined,
      propertyId,
      addLandlordToProperty,
    })
  }

  return isShareIncomeVerificationReportEnabled ? (
    <ShareReportFormV2
      isLoading={isLoading && !isSubmitted}
      isOpen={isShowShareModal}
      renter={renter}
      renterDetail={renterDetail}
      renterEmail={renterEmail}
      userEmail={userEmail}
      formValues={formValuesV2}
      screeningOption={screeningOption}
      reportAvailable={reportAvailable}
      applicationType={applicationType}
      landlordInformation={landlordInformation}
      onClickClose={handleHide}
      isExpired={isExpired}
      isIDMAExpired={isIDMAExpired}
      isIncomeVerificationExpired={isIncomeVerificationExpired}
      expiredDate={expiredDate}
      onSubmit={submitShareReportForm}
      setFormValues={setFormValuesV2}
      defaultShareOption={defaultShareOption}
      isError={isError}
      closeError={closeError}
    />
  ) : (
    <DefaultModal
      closeButton
      loadingOverlay
      className="share-report-modal"
      showModal={isShowShareModal}
      closeModal={handleHide}
      isLoading={isLoading && !isSubmitted}
      minHeight="587px"
      backdrop="static"
    >
      {isSubmitted ? (
        <ConfirmationInfo
          recipient={formValues.email}
          address={address}
          onClickBack={handleSubmitting(false)}
          onClickShare={handleShare}
          isLoading={isLoading}
          isError={isError}
          closeError={closeError}
        />
      ) : (
        <>
          <S24 margin="0px 0px 10px 0px">{`Share ${firstName} ${lastName}'s reports`}</S24>
          <S16 margin="0px 0px 25px 0px">{`for ${address}`}</S16>
          <ShareReportForm
            onSubmit={handleSubmitting(true)}
            renterEmail={renterEmail}
            userEmail={userEmail}
            formValues={formValues}
            screeningOption={screeningOption}
            reportAvailable={reportAvailable}
            applicationType={applicationType}
            landlordInformation={landlordInformation}
            onClickCancel={handleHide}
            isExpired={isExpired}
            isIDMAExpired={isIDMAExpired}
            expiredDate={expiredDate}
            setFormValues={setFormValues}
          />
        </>
      )}
    </DefaultModal>
  )
}

export default compose(withConnect, withReducer, withAssociationContact, withSaga)(ShareReport)
