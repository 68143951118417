import { takeLatest, all, put, call } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "utils/api-interceptor"
import { getSubmissionParticipantApiState } from "./actions"
import {
  GET_SUBMISSION_PARTICIPANT_API,
  GET_SUBMISSION_PARTICIPANT_CALL,
} from "./constants"

export const getSubmissionParticipantApi = submissionParticipantId =>
  apiInstance.get(
    buildPath(GET_SUBMISSION_PARTICIPANT_API, {
      submissionParticipantId,
    }),
  )

export function* getSubmissionParticipantSaga({ payload }) {
  yield put(getSubmissionParticipantApiState.request())
  try {
    const response = yield call(
      getSubmissionParticipantApi,
      payload.submissionParticipantId,
    )
    yield put(getSubmissionParticipantApiState.success(response))
  } catch (err) {
    yield put(getSubmissionParticipantApiState.failure(err))
  }
}

export function* watchGetSubmissionParticipant() {
  yield takeLatest(
    GET_SUBMISSION_PARTICIPANT_CALL,
    getSubmissionParticipantSaga,
  )
}

export function* rootSaga() {
  yield all([watchGetSubmissionParticipant()])
}

export default rootSaga
