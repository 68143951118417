import { PAYMENT_INCENTIVE_REWARD_PAGE_ACTION } from './constants'

/**
 * @param {object} payload
 * @param {string} payload.fromPathname - indicate which pathname that trigger the action
 * @param {boolean} payload.isShowAlert - indicate whether to show the success alert
 * @param {boolean} payload.isShowBackButton - indicate whether to show the back button
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_REWARD_PAGE_ACTION['OPEN_PAGE']}>}
 */
export const openRewardPage = payload => ({
  type: PAYMENT_INCENTIVE_REWARD_PAGE_ACTION.OPEN_PAGE,
  payload,
})

/**
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_REWARD_PAGE_ACTION['CLOSE_PAGE']}>}
 */
export const closeRewardPage = () => ({
  type: PAYMENT_INCENTIVE_REWARD_PAGE_ACTION.CLOSE_PAGE,
})

/**
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_REWARD_PAGE_ACTION['CLOSE_SUCCESS_ALERT']}>}
 */
export const closeSuccessAlertOnRewardPage = () => ({
  type: PAYMENT_INCENTIVE_REWARD_PAGE_ACTION.CLOSE_SUCCESS_ALERT,
})

/**
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_REWARD_PAGE_ACTION['PLAY_CONFETTI']}>}
 */
export const playConfetti = () => ({
  type: PAYMENT_INCENTIVE_REWARD_PAGE_ACTION.PLAY_CONFETTI,
})

/**
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_REWARD_PAGE_ACTION['COMPLETE_CONFETTI']}>}
 */
export const completeConfetti = () => ({
  type: PAYMENT_INCENTIVE_REWARD_PAGE_ACTION.COMPLETE_CONFETTI,
})
