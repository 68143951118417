import { all } from 'redux-saga/effects'

import {
  watchCheckRentalPaymentExistsSaga,
  watchCheckShowErrorBadgeSaga,
  watchConfirmDraftRentalPaymentSaga,
  watchDeleteRentPaymentSaga,
  watchEditOneTimePaymentSaga,
  watchGetAvailablePayoutSaga,
  watchGetOrCreateDraftRentalPaymentSaga,
  watchGetOrCreateRenterInfoSaga,
  watchGetRentalPaymentByIdSaga,
  watchGetRentalPaymentListSaga,
  watchMarkAsPaidSaga,
  watchRetryPayoutSaga,
  watchSearchRenterInfoSaga,
  watchSendInvitationEmailSaga,
  watchSetSeenNotificationSaga,
  watchUpdateDraftQuotationsSaga,
  watchGetPaymentSummarySaga,
  watchCreateTenantSaga,
} from './services'

export function* rentalPaymentRootSaga() {
  yield all([
    watchDeleteRentPaymentSaga(),
    watchEditOneTimePaymentSaga(),
    watchGetRentalPaymentListSaga(),
    watchGetRentalPaymentByIdSaga(),
    watchMarkAsPaidSaga(),
    watchRetryPayoutSaga(),
    watchSetSeenNotificationSaga(),
    watchSendInvitationEmailSaga(),
    watchCheckShowErrorBadgeSaga(),
    watchCheckRentalPaymentExistsSaga(),
    watchConfirmDraftRentalPaymentSaga(),
    watchGetOrCreateDraftRentalPaymentSaga(),
    watchGetOrCreateRenterInfoSaga(),
    watchSearchRenterInfoSaga(),
    watchUpdateDraftQuotationsSaga(),
    watchGetAvailablePayoutSaga(),
    watchGetPaymentSummarySaga(),
    watchCreateTenantSaga(),
  ])
}
