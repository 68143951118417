export default (error = {}, errorMessage = {}) => {
  const err = {
    message: errorMessage[Object.keys(errorMessage)[0]].MESSAGE,
    title: errorMessage[Object.keys(errorMessage)[0]].TITLE,
  }
  if (error.meta) {
    switch (error.meta.name) {
      case "ValidationError":
        if (error.error) {
          err.message = error.error
        }
        if (
          errorMessage &&
          errorMessage.VALIDATION &&
          errorMessage.VALIDATION.TITLE
        ) {
          err.title = errorMessage.VALIDATION.TITLE
        } else {
          err.title = "Validation Error"
        }
        break
      default:
    }
  }
  return err
}
