/* eslint-disable no-lonely-if */
import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import { MAIN_URL } from 'env'
import * as PATH from 'constants/route'
import { Div, CenterContent, WhiteWrapper, Loading } from 'components/layout/main'
import {
  Container,
  Header,
  LeaseDetailsContent,
  LeaseDetailsCol,
  InfoTextItem,
  GreyWrapper,
} from 'components/lease-agreement-details'
import { EnvelopeDetailCard } from 'components/envelope/envelope-detail-card'
import { B14, B16, T24, FeatureBlockModal, FEATURE_BLOCK_NAME } from '@rentspree/component-v2'
import { convertDateFormat, convertNumberFormat } from 'utils/convert'
import { COLOR } from 'styles/settings'
import EDIT_ICON from 'images/icons/lease-agreement/edit-icon.svg'
import EDIT_ICON_HOVER from 'images/icons/lease-agreement/edit-icon-hover.svg'
import { FailedToFetch } from 'components/layout/failed-to-fetch'
import { LEASE_AGREEMENTS as ERROR_LEASE_AGREEMENT } from 'constants/error-messages'
import ADD_ICON from 'images/icons/lease-agreement/plus-icon.svg'
import ADD_ICON_HOVER from 'images/icons/lease-agreement/plus-icon-hover.svg'

import {
  LEASE_AGREEMENT_EDIT,
  LEASE_AGREEMENT_UNSUPPORT_PAGE,
  ESIGN_DASHBOARD_RECIPIENTS_PAGE,
  ESIGN_DASHBOARD_UPLOAD_PAGE,
  ESIGN_DASHBOARD_LEASE_BUILDER_RECIPIENT_STEP,
} from 'constants/route'
import { query, buildPath } from '@rentspree/path'
import VoidModal from 'components/envelope/modal/void-modal'
import { LEASE_AGREEMENTS } from 'tracker/const'
import tracker from 'tracker'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import ConfirmModalBody from 'components/templates/confirm-modal-body'
import { TopicHeaderWithUnderline } from 'components/molecules/topic-header-with-underline'
import { withSubscriptionData } from 'utils/subscription/with-subscription-data'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { ESIGN_TEMPLATE_PROVIDER } from 'constants/esign-template'
import { PRO_PLAN_VARIANTS } from 'legacy/containers/pro/landing/constants'
import { getSubscriptionProductVariant } from 'legacy/containers/pro/landing/utils'
import Level2Template from '@rentspree/component-2023.components.templates.level-2-template'
import useMobile from 'hooks/use-mobile'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import Breadcrumb2023 from 'containers/breadcrumb-2023'
import PropertyInnerTabs from 'containers/property-inner-tabs'
import { LeaseRecordDetailCard } from 'components/envelope/lease-record-detail-card'
import {
  withSaga as withEnvelopeSaga,
  withReducer as withEnvelopeReducer,
} from '../envelope/connect'
import { isIE } from '../../legacy/components/helper/ua-parser-js'
import { withConnect, withReducer, withSaga } from './connect'
import { HEAD_LABEL, LEASE_DETAILS_LABEL, RESEND_WORD } from './constants'

export const LeaseAgreementDetailsContainer = ({
  isLoading,
  isError,
  isCreating,
  userPlan,
  products,
  leaseAgreementDetail,
  match,
  actions,
  history,
  voidStatus,
  deleteStatus,
  isErrorVoid,
  saveFileLoadingList,
  error,
  resendStatus,
  location,
  street,
}) => {
  const { saveAsTemplateResult } = query.parse(location.search)

  const [isShowResendModal, setIsShowResendModal] = React.useState(false)
  const [selectedRecipient, setSelectedRecipient] = React.useState({})
  const [isShowFeatureBlockModal, setIsShowFeatureBlockModal] = React.useState(false)
  const [envelopeSelect, setEnvelopeSelect] = React.useState('')
  const {
    params: { propertyId, leaseAgreementId },
  } = match

  const monthlyPrice = getSubscriptionProductVariant(products?.data, {
    name: PRO_PLAN_VARIANTS.MONTHLY,
  })?.price

  React.useEffect(() => {
    if (resendStatus.isCloseModalResend) {
      setIsShowResendModal(false)
      setSelectedRecipient({})
    }
  }, [resendStatus.isCloseModalResend])

  React.useEffect(() => {
    if (deleteStatus.isSuccess) {
      actions.leaseAgreementDetailCall({ propertyId, leaseAgreementId })
    }
  }, [deleteStatus.isSuccess])

  React.useEffect(() => {
    actions.createCustomTemplate({ saveAsTemplateResult })
    if (isIE) {
      history.push(
        buildPath(LEASE_AGREEMENT_UNSUPPORT_PAGE, {
          leaseAgreementId,
          propertyId,
        }),
      )
    } else {
      actions.leaseAgreementDetailCall({ propertyId, leaseAgreementId })
      tracker.trackEvent(LEASE_AGREEMENTS.VIEW_LEASE_DETAIL)
    }
    return () => {
      actions.clearLeaseAgreementDetail()
    }
  }, [])

  const handleResend = () => {
    actions.resendEnvelopeCall({
      propertyId,
      leaseAgreementId,
      recipientEmail: selectedRecipient.email,
      envelopeId: envelopeSelect,
    })
  }

  const handleOnCloseModalResend = () => {
    setIsShowResendModal(false)
    setSelectedRecipient({})
  }

  const handleOnClickEditLease = () => {
    history.push(
      buildPath(LEASE_AGREEMENT_EDIT, {
        propertyId,
        leaseAgreementId,
      }),
    )
    tracker.trackEvent(LEASE_AGREEMENTS.CLICK_EDIT_RENTAL_INFORMATION)
  }

  const handleClickResend = (id, recipient) => () => {
    setEnvelopeSelect(id)
    setIsShowResendModal(true)
    setSelectedRecipient(recipient)
  }

  const handleVoidEnvelope = (envelopeId, voidReason) => {
    actions.voidEnvelopeCall({
      propertyId,
      leaseAgreementId,
      envelopeId,
      voidReason,
    })
  }

  const handleClickDelete = envelopeId => {
    actions.deleteEnvelopeCall({ envelopeId })
  }

  if (isError) {
    return (
      <FailedToFetch
        noMargin
        noBreadcrumbMobile
        text={ERROR_LEASE_AGREEMENT.LISTING.MESSAGE}
        title={ERROR_LEASE_AGREEMENT.LISTING.TITLE}
      />
    )
  }

  const handleClickVoid = id => {
    setEnvelopeSelect(id)
    actions.openVoidModal()
    handleCloseError()
  }

  const handleCloseModal = () => {
    actions.closeVoidModal()
    handleCloseError()
  }

  const handleSubmitVoid = voidReason => {
    handleCloseError()
    handleVoidEnvelope(envelopeSelect, voidReason)
  }

  const handleCloseError = () => {
    actions.clearVoidStatus()
  }

  const makeHandleEdit = envelope => () => {
    const { _id } = envelope

    const params = {
      propertyId,
      leaseAgreementId,
      envelopeId: _id,
    }

    const leaseAgreementRecordId = get(envelope, 'leaseAgreementRecordId')

    if (get(envelope, 'templateId')) {
      if (get(envelope, 'templateProvider') === ESIGN_TEMPLATE_PROVIDER.RENTSPREE) {
        if (leaseAgreementRecordId) {
          const leaseBuilderParams = {
            propertyId,
            leaseAgreementId,
            recordId: leaseAgreementRecordId,
          }
          const url = buildPath(ESIGN_DASHBOARD_LEASE_BUILDER_RECIPIENT_STEP, leaseBuilderParams)
          window.location.assign(url)
        } else {
          window.location.assign(buildPath(ESIGN_DASHBOARD_RECIPIENTS_PAGE, params))
        }
      } else if (get(envelope, 'templateProvider') === ESIGN_TEMPLATE_PROVIDER.USER) {
        if (userPlan === SUBSCRIPTION_PLAN.FREE) {
          setIsShowFeatureBlockModal(true)
        } else {
          window.location.assign(buildPath(ESIGN_DASHBOARD_UPLOAD_PAGE, params))
        }
      }
    } else {
      if (userPlan === SUBSCRIPTION_PLAN.FREE) {
        setIsShowFeatureBlockModal(true)
      } else {
        window.location.assign(buildPath(ESIGN_DASHBOARD_UPLOAD_PAGE, params))
      }
    }
  }

  const handleContinueLeaseBuilder = leaseAgreementRecordId => {
    const params = {
      propertyId,
      leaseAgreementId,
      recordId: leaseAgreementRecordId,
    }

    const url = buildPath(ESIGN_DASHBOARD_LEASE_BUILDER_RECIPIENT_STEP, params)
    window.location.assign(url)
  }

  const makeHandleDownload = envelope => () => {
    actions.saveFiles(envelope)
  }

  const rentAmountText = () => {
    const text = convertNumberFormat(get(leaseAgreementDetail, 'rentAmount', 0), '$0,0')
    return text === '-' ? text : `${text} / month`
  }

  const onClickCreateEnvelope = () => {
    tracker.trackEvent(LEASE_AGREEMENTS.CREATE_ENVELOPE)
    const url = buildPath(PATH.ESIGN_DASHBOARD_SELECT_METHODS, { propertyId }, { leaseAgreementId })
    window.location.assign(url)
  }

  const handleGoBack = () => {
    history.push(PATH.PROPERTY)
  }

  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  return (
    <>
      <Level2Template
        title={street}
        isMobile={isMobile}
        onClickBack={handleGoBack}
        breadcrumb={<Breadcrumb2023 />}
        tabs={<PropertyInnerTabs defaultTab="propertyInnerTabLeaseAgreement" />}
      />
      <Div sourceSansPros flex flexGrow="1" flexDirection="column">
        <WhiteWrapper mMargin="0">
          {(isCreating || isLoading) && (
            <CenterContent overlay withBreadcrumb withFilter>
              <Loading />
            </CenterContent>
          )}
          <GreyWrapper>
            <Header>
              <T24 margin="0px" color={COLOR.textBlack}>
                {get(leaseAgreementDetail, 'title', '')}
              </T24>
            </Header>
          </GreyWrapper>
          <Container>
            <TopicHeaderWithUnderline
              title={HEAD_LABEL.LEASE_DETAILS}
              icon={EDIT_ICON}
              iconHover={EDIT_ICON_HOVER}
              onClick={handleOnClickEditLease}
              buttonText="Edit"
              buttonId="editLeaseDetail"
              topicMargin="0"
            />
            <LeaseDetailsContent>
              <LeaseDetailsCol>
                <InfoTextItem>
                  <B14 weight="600">{LEASE_DETAILS_LABEL.MOVE_IN_DATE}</B14>
                  <B16>
                    {!isLoading &&
                      convertDateFormat(get(leaseAgreementDetail, 'moveInDate', ''), 'MM/DD/YYYY')}
                  </B16>
                </InfoTextItem>
                <InfoTextItem>
                  <B14 weight="600">{LEASE_DETAILS_LABEL.LEASE_EXPIRES}</B14>
                  <B16>
                    {get(leaseAgreementDetail, 'isMonthToMonth', false)
                      ? 'Month-to-Month'
                      : !isLoading &&
                        convertDateFormat(
                          get(leaseAgreementDetail, 'expiredDate', ''),
                          'MM/DD/YYYY',
                        )}
                  </B16>
                </InfoTextItem>
              </LeaseDetailsCol>
              <LeaseDetailsCol width="fit-content">
                <InfoTextItem width="135px">
                  <B14 weight="600">{LEASE_DETAILS_LABEL.RENT_AMOUNT}</B14>
                  <B16>{!isLoading && rentAmountText()}</B16>
                </InfoTextItem>
                <InfoTextItem width="135px">
                  <B14 weight="600">{LEASE_DETAILS_LABEL.SECURITY_DEPOSIT}</B14>
                  <B16>
                    {!isLoading &&
                      convertNumberFormat(get(leaseAgreementDetail, 'securityDeposit', 0), '$0,0')}
                  </B16>
                </InfoTextItem>
              </LeaseDetailsCol>
            </LeaseDetailsContent>
            <TopicHeaderWithUnderline
              title={HEAD_LABEL.ENVELOPES}
              icon={ADD_ICON}
              iconHover={ADD_ICON_HOVER}
              onClick={onClickCreateEnvelope}
              buttonText="Set up lease"
              buttonId="addEnvelopeLease"
              hrMargin="8px 0 5px"
              topicMargin="25px 0 0 0"
            />
            {get(leaseAgreementDetail, 'leaseAgreementRecords', []).map((record, idx) => (
              <LeaseRecordDetailCard
                key={get(record, '_id', `record-card-${idx}`)}
                leaseAgreementRecord={record}
                onEdit={() => handleContinueLeaseBuilder(get(record, '_id'))}
              />
            ))}
            {get(leaseAgreementDetail, 'envelopes', []).map(envelope => (
              <EnvelopeDetailCard
                key={get(envelope, '_id', '')}
                envelope={envelope}
                onVoid={handleClickVoid}
                onResend={handleClickResend}
                onDelete={handleClickDelete}
                saveFileLoadingList={saveFileLoadingList}
                onDownload={makeHandleDownload(envelope)}
                onEdit={makeHandleEdit(envelope)}
              />
            ))}
            <VoidModal
              closeModal={handleCloseModal}
              submitVoid={handleSubmitVoid}
              voidStatus={voidStatus}
              closeError={handleCloseError}
              isError={isErrorVoid}
            />
            <FeatureBlockModal
              feature={FEATURE_BLOCK_NAME.E_SIGN}
              isOpen={isShowFeatureBlockModal}
              hasStartTrial
              onClose={() => setIsShowFeatureBlockModal(false)}
              redirectUrl={`${MAIN_URL}/dashboard/v2/properties/${propertyId}/esign-documents/${leaseAgreementId}`}
              totalPrice={monthlyPrice}
            />
            <RentSpreeBaseModal
              id="resendModal"
              rootId="#app"
              isOpen={isShowResendModal}
              onClose={handleOnCloseModalResend}
              closeButton
              shouldCloseOnOverlayClick={false}
              modalSize="small"
            >
              <ConfirmModalBody
                title={RESEND_WORD.TITLE}
                descText={RESEND_WORD.DESC(
                  get(selectedRecipient, 'fullName', ''),
                  get(selectedRecipient, 'email', ''),
                )}
                confirmTxt={RESEND_WORD.CONFIRM}
                confirm
                onClose={handleOnCloseModalResend}
                handleClickBtn={handleResend}
                isLoading={resendStatus.isLoading}
                error={error}
                onClearError={actions.clearLeaseError}
              />
            </RentSpreeBaseModal>
          </Container>
        </WhiteWrapper>
      </Div>
    </>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
  withEnvelopeSaga,
  withEnvelopeReducer,
  withSubscriptionData,
)(LeaseAgreementDetailsContainer)
