export const parseArguments = (orderArgs, defaultArgs) => {
  const orders = orderArgs.map(val =>
    val !== undefined ? String(val) : undefined,
  )
  const defaultValue = defaultArgs.map(val => String(val))

  orders.reverse()
  defaultValue.reverse()

  const args = orders.reduce((acc, val, idx) => {
    if ((val === undefined || val === defaultValue[idx]) && acc.length === 0) {
      return acc
    }

    if (val === undefined || val === defaultValue[idx]) {
      acc.push("")
      return acc
    }
    acc.push(val)
    return acc
  }, [])
  return args.reverse().join(":")
}
