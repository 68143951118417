import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import { withFormik } from 'formik'
import React from 'react'
import styled from 'styled-components'

import ContactForm from '../../organisms/contact-form'

import { schema } from './schema'

const StyledContainer = styled.div`
  font-family: Source Sans Pro, sans-serif;
  overflow: visible;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
`

export const CreateContactForm = ({
  handleSubmit,
  isSubmittingForm,
  onCloseCreateContactModal,
  onCreateTagContact,
  allTags = [],
  onFocusTagInput,
  ...formikProps
}) => (
  <StyledContainer>
    <S24 size="28px" margin="0 0 30px 0">
      Add New Contact
    </S24>
    <form onSubmit={handleSubmit}>
      <ContactForm
        allTags={allTags}
        onCreateTagContact={onCreateTagContact}
        onFocusTagInput={onFocusTagInput}
        {...formikProps}
      />
      <ButtonContainer>
        <Button
          small
          google
          text="Cancel"
          minWidth="140"
          type="button"
          onClick={onCloseCreateContactModal}
        />
        <BlueGradientButton
          small
          text="Create"
          minWidth="140"
          type="submit"
          loading={isSubmittingForm}
        />
      </ButtonContainer>
    </form>
  </StyledContainer>
)

const CreateContactFormTemplate = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: async (values, { props }) => {
    props.onCreateContact(values)
  },
  enableReinitialize: true,
  displayName: 'CreateContactForm',
})(CreateContactForm)

export default CreateContactFormTemplate
