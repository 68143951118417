import React from "react"
import styled from "styled-components"
import classnames from "classnames"
import ResizeDetector from "react-resize-detector"
import TextareaAutosize from "react-textarea-autosize"
import { COLOR } from "styles/settings"
import { getTextWidth } from "utils/get-text-width"
import TooltipPopper from "../tooltip"
import { rgbaColor, borderColor } from "../colors"
import { makeMapCursor } from "../cursor"

const mapCursor = makeMapCursor("text")

export const PositionWrapper = styled.div`
  position: absolute;
  left: ${props => (props.left ? `${props.left}px` : "0")};
  top: ${props => (props.top ? `${props.top}px` : "0")};
  transform: ${props => props.transform || "none"};
`

export const StyledTextBox = styled(TextareaAutosize)`
  border: 1px solid transparent;
  display: block;
  border-radius: 3px;
  cursor: ${props => props.cursor || "default"};
  font-family: ${props =>
    props.styles.fontFamily ? props.styles.fontFamily : "Source Sans Pro"};
  font-size: ${props =>
    props.styles.fontSize ? `${props.styles.fontSize}pt` : "10pt"};
  ${props => props.styles.italic && `font-style: italic`};
  ${props => props.styles.bold && `font-weight: bold`};
  ${props => props.styles.underline && `text-decoration: underline`};
  width: ${props => (props.styles.width ? props.styles.width : "105")}px;
  height: ${props => (props.styles.height ? props.styles.height : "20")}px;
  resize: unset;
  line-height: ${props =>
    props.styles.lineHeight ? `${props.styles.lineHeight}px` : "20px"};
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
  background: ${props => props.backgroundColor};
  transform: ${props => props.styles.transform || "scale(1, 1)"};
  transform-origin: 0% 0%;
  ${props =>
    !props.viewMode
      ? `:hover {
    border: 1px solid ${props.borderColor};
  }`
      : `
  background: ${
    props.disabled
      ? "transparent"
      : `rgba(${COLOR.lightBlueRGBA});
    :hover {
      border: 1px solid ${COLOR.activeBlue};
      box-sizing: border-box;
      border-radius: 3px;
    }
    `
  };

  -webkit-appearance: none;
  opacity: 1; /* required on iOS */
  -moz-appearance: none;
  appearance: none;
  ${props.disabled ? "user-select: none;" : ""}
  `}
  &.readOnly {
    color: ${COLOR.textBlue};
    -webkit-text-fill-color: ${COLOR.textBlue}; /* required on iOS */
  }
  &.active {
    resize: auto;
    ${props => props.unresizable && "resize: none;"}
    background: ${COLOR.white};
    border: 1px solid ${props => props.borderColor};
  }
  &.error {
    border: 1px solid ${COLOR.fontRed};
    background: ${COLOR.lightRed};
    svg {
      fill: ${COLOR.fontRed};
    }
    p {
      color: ${COLOR.fontRed};
    }
  }
  ${props => props.unresizable && "overflow: hidden;"}
`

export const TextBox = ({
  targetRef,
  viewMode,
  isDragging,
  left,
  top,
  value,
  active,
  styles = {},
  onClick,
  onChangeData,
  onChangeStyle,
  page,
  fieldId,
  canEdit,
  showError,
  colorIndex,
  unmovable,
  unresizable,
  hidden,
  ratio,
}) => {
  if (hidden) return null

  const [currentHeight, setCurrentHeight] = React.useState(styles.height || 20)
  const textboxRef = React.useRef(null)

  if (isDragging) {
    return <div ref={targetRef} />
  }

  const handleChange = e => {
    if (unresizable) {
      // build font in format `bold italic 9pt Source Sans Pro`
      const font = []
      if (styles.bold) font.push("bold")
      if (styles.italic) font.push("italic")
      font.push(`${styles.fontSize}pt Source Sans Pro`)
      const currentFont = font.join(" ")

      // width of the next text
      const textWidth = Math.ceil(getTextWidth(e.target.value, currentFont))
      // cannot type when the width of the next text is grather than or equal styles.width (-6 is a padding & border)
      const untypeable = textWidth >= styles.width - 6
      // is adding when the length of the next text is grather than or equal the length of the current text (so `false` is mean deleting)
      const isAdding = e.target.value.length >= value.length

      if (untypeable && isAdding) return
    }
    onChangeData(e.target.value)
  }

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onClick()
  }

  const handleFocus = e => {
    // set cursor of text input to the end of text value when auto focus
    const valueLen = e.target.value.length
    e.target.setSelectionRange(valueLen, valueLen)
    onClick()
  }

  const onResize = (width, height) => {
    if (unresizable) return
    if (active) {
      onChangeStyle({ width, height }, { page, fieldId })
      setCurrentHeight(height)
    }
  }

  const isFilled = !!value
  const isDisabled = viewMode && !canEdit
  const bdColor = borderColor(colorIndex, COLOR.placeholderGrey)
  const bgColor = rgbaColor(colorIndex, COLOR.bgGreyRGB)
  const cursor = mapCursor(viewMode, canEdit, active, unmovable)

  const customOnHeightChange = height => {
    if (unresizable) return
    if (height <= styles.height) {
      const node = textboxRef.current
      node.style.setProperty("height", `${currentHeight}px`, "important")
    }
  }
  return (
    <PositionWrapper top={top * ratio} left={left * ratio} ref={targetRef}>
      <StyledTextBox
        ref={textboxRef}
        id={fieldId}
        key={fieldId}
        viewMode={viewMode}
        disabled={isDisabled}
        unresizable={unresizable}
        cursor={cursor}
        borderColor={bdColor}
        backgroundColor={bgColor}
        className={classnames({
          active,
          error: showError,
          readOnly: isFilled && isDisabled,
        })}
        left={left * ratio}
        top={top * ratio}
        styles={{
          ...styles,
          transform: `scale(${ratio}, ${ratio})`,
        }}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        autoFocus={active}
        onFocus={handleFocus}
        onHeightChange={customOnHeightChange}
        rows={1}
        as={unresizable && "input"}
        autoComplete={unresizable && "off"}
      />
      {showError && <TooltipPopper refNode={targetRef} />}
      <ResizeDetector
        handleWidth
        handleHeight
        skipOnMount
        onResize={onResize}
      />
    </PositionWrapper>
  )
}

export default TextBox
