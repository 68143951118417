import React from 'react'
import styled, { keyframes, createGlobalStyle } from 'styled-components'
import { S18, B16, BlueGradientButton } from '@rentspree/component-v2'
import { COLOR, BACK_DROP, GET_STARTED_WIDTH } from '../../styles/settings'

export const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${BACK_DROP};
  z-index: 100000;
  display: flex;
`

const OpenModal = keyframes`
  0%   {opacity: 0; top: -20px}
  50%  {opacity: 0; top: -20px}
  100% {opacity: 1; top: 0}
`

export const PreventScroll = createGlobalStyle`
  #app {
    height: 100vh; /* fallback if calc did not work */
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
`

export const ModalContent = styled.div`
  max-width: ${GET_STARTED_WIDTH};
  position: relative;
  margin: auto;
  background: white;
  border-radius: 5px;
  font-family: Source Sans Pro;
  overflow: hidden;
  height: 490px;
  animation: ${OpenModal} 0.4s linear;
  /* special case for tablet */
  @media (max-width: 767px) {
    max-width: unset;
    border-radius: 0;
    height: 100vh; /* fallback if calc did not work */
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const SlideModalContainer = styled.div`
  text-align: center;
  font-family: Source Sans Pro;
  min-width: ${GET_STARTED_WIDTH};
  /* special case for tablet */
  @media (max-width: 767px) {
    min-width: 100vw;
  }
`
export const SlideImage = styled.img`
  /* special case for tablet */
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const ModalDetail = styled.div`
  padding: 0px 35px 5px;
`

export const BatchContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BatchCircle = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${props => (props.holder ? COLOR.fontBlue : COLOR.newGrey)};
  border-radius: 3px;
  margin: 0px 3px;
  cursor: pointer;
  :hover {
    background-color: ${COLOR.fontBlue};
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'space-between')};
  align-items: center;
  padding: 20px 20px 25px;
`
export const SkipText = styled(B16)`
  font-weight: 600;
  color: ${COLOR.placeholderGrey};
  margin: 0px;
  margin-left: 15px;
  cursor: pointer;
  :hover {
    color: ${COLOR.textBlack};
  }
`
export const CustomBlueGradientButton = styled(BlueGradientButton)`
  font-size: 16px;
`
export const Slider = styled.div`
  display: flex;
  overflow: hidden;
`
export const ContentHolder = styled.div`
  display: flex;
  width: 100%;
  left: ${props => props.position}px;
  transition: left 0.5s;
  position: relative;
`
export const ImageContainer = styled.div`
  min-width: 375px;
  min-height: 250px;
  /* special case for tablet */
  @media (max-width: 767px) {
    min-width: 0;
  }
`

export const GetStartedModal = ({
  onNext,
  position,
  myRef,
  data,
  indexPosition,
  onClickBatch,
  skipGetStartedModal,
}) => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  return (
    <Modal>
      <ModalContent>
        <Slider>
          <ContentHolder position={position}>
            {data.map(({ title, description, img }) => (
              <SlideModalContainer key={title} ref={myRef}>
                <ImageContainer>
                  <SlideImage src={img} />
                </ImageContainer>
                <ModalDetail>
                  <S18>{title}</S18>
                  <B16 size="14px">{description}</B16>
                </ModalDetail>
              </SlideModalContainer>
            ))}
          </ContentHolder>
        </Slider>
        <div>
          <BatchContainer>
            {data.map(({ title }, index) => (
              <BatchCircle
                key={`batch-${title}`}
                holder={indexPosition === index}
                onClick={() => onClickBatch(index)}
              />
            ))}
          </BatchContainer>
          {indexPosition === data.length - 1 ? (
            <ButtonContainer flexEnd>
              <CustomBlueGradientButton
                semiBold
                small
                text="Done"
                id="smNoShadowBtn"
                onClick={skipGetStartedModal}
              />
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <SkipText onClick={skipGetStartedModal}>Skip</SkipText>
              <CustomBlueGradientButton
                semiBold
                small
                text="Next"
                id="smNoShadowBtn"
                onClick={onNext}
              />
            </ButtonContainer>
          )}
        </div>
      </ModalContent>
      <PreventScroll />
    </Modal>
  )
}

export default GetStartedModal
