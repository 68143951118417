export const PAYMENT_INCENTIVE_REWARD_PAGE_ACTION = {
  OPEN_PAGE: 'PAYMENT_INCENTIVE_REWARD_PAGE_ACTION_OPEN',
  CLOSE_PAGE: 'PAYMENT_INCENTIVE_REWARD_PAGE_ACTION_CLOSE',
  CLOSE_SUCCESS_ALERT: 'PAYMENT_INCENTIVE_REWARD_PAGE_ACTION_CLOSE_SUCCESS_ALERT',
  PLAY_CONFETTI: 'PAYMENT_INCENTIVE_REWARD_PAGE_ACTION_PLAY_CONFETTI',
  COMPLETE_CONFETTI: 'PAYMENT_INCENTIVE_REWARD_PAGE_ACTION_COMPLETE_CONFETTI',
}

export const CONFETTI_STATE = {
  IDLE: 'IDLE',
  PLAYING: 'PLAYING',
  COMPLETED: 'COMPLETED',
}

export const PAYMENT_INCENTIVE_REWARD_PAGE_REDUCER_KEY = 'paymentIncentiveRewardPage'
