import React from 'react'

import {
  APP_DOCUMENT_STATUS,
  APP_EXPIRED_STATUS,
  APP_SUBMITTED_STATUS,
} from 'containers/application/const'
import { COLOR } from 'styles/settings'

export const CREATE_PROPERTY = '+ Create/select a property'
export const NO_SPECIFIC_PROPERTY = 'No specific property'
export const SCREEN_WITHOUT_PROPERTY = 'Screen without property'

export const TABLE_HEADER = [
  { text: 'Applicant', value: 'applicant', class: 'text-left' },
  { text: 'Property', value: 'property', class: 'text-left' },
  {
    text: 'Submission date',
    value: 'submitted_at',
    class: 'text-left',
  },
  { text: 'Application progress', value: 'status', class: 'text-left' },
  { text: 'Actions', value: '', class: 'text-center', align: 'right' },
]

export const APPLICATION_PROGRESS_TEXT = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  SUBMITTED: 'Submitted',
  SUBMITTED_WITH_DOCS: 'Submitted with docs',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  NOT_INTERESTED: 'Not interested',
  EXPIRED: 'Report expired',
}

export const APPLICATION_PROGRESS_TEXT_COLOR = {
  [APPLICATION_PROGRESS_TEXT.NOT_STARTED]: COLOR.statusNeutral,
  [APPLICATION_PROGRESS_TEXT.IN_PROGRESS]: COLOR.statusLookGood,
  [APPLICATION_PROGRESS_TEXT.SUBMITTED]: COLOR.statusSuccess,
  [APPLICATION_PROGRESS_TEXT.SUBMITTED_WITH_DOCS]: COLOR.statusSuccess,
  [APPLICATION_PROGRESS_TEXT.ACCEPTED]: COLOR.statusSuccess,
  [APPLICATION_PROGRESS_TEXT.DECLINED]: COLOR.statusCaution,
  [APPLICATION_PROGRESS_TEXT.NOT_INTERESTED]: COLOR.statusNeutral,
  [APPLICATION_PROGRESS_TEXT.EXPIRED]: COLOR.statusError,
}

export const STATUS_WITH_HIGHLIGHTED_RENTER_NAME = [
  ...APP_SUBMITTED_STATUS,
  ...APP_EXPIRED_STATUS,
  // TODO: consider migrate and remove document_status field from rental submission collection
  APP_DOCUMENT_STATUS.requested_doc.value,
  APP_DOCUMENT_STATUS.partially_uploaded.value,
  APP_DOCUMENT_STATUS.uploaded.value,
]

export const ONE_TIME_POPPER_FOR_INVITED_USER_SEEN_GROUP =
  'share_report_one_time_popper_on_ts_page_for_invited_landlord'

export const POPPER_CONTENT = {
  TITLE: (
    <>
      Here are your new
      <br />
      applications
    </>
  ),
  SUBTITLE: "Select the applicant's name to review.",
}

export const SCREENING_TABLE_FILTER_BAR_OPTIONS = [
  {
    label: 'All',
    value: 'all',
    iconColor: COLOR.blackText,
  },
  {
    label: 'Not started',
    value: 'requested',
    iconColor: COLOR.statusNeutral,
  },
  {
    label: 'In progress',
    value: 'started',
    iconColor: COLOR.statusLookGood,
  },
  {
    label: 'Submitted',
    value: 'submitted',
    iconColor: COLOR.statusSuccess,
  },
  {
    label: 'Unread',
    value: 'unread',
    iconColor: COLOR.statusCaution,
  },
  {
    label: 'Need decision',
    value: 'need_decision',
    iconColor: COLOR.statusCaution,
  },
  {
    label: 'Accepted',
    value: 'accepted',
    iconColor: COLOR.statusSuccess,
  },
  {
    label: 'Declined',
    value: 'denied',
    iconColor: COLOR.statusNeutral,
  },
  {
    label: 'Report expired',
    value: 'expired',
    iconColor: COLOR.statusNeutral,
  },
  {
    label: 'Not interested',
    value: 'not_interested',
    iconColor: COLOR.statusNeutral,
  },
]
