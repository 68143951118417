import generateApiCall from 'utils/generate-api-call'

import {
  CHECK_RENTAL_PAYMENT_EXISTS,
  CHECK_RENTAL_PAYMENT_EXISTS_REQUESTED,
  CHECK_RENTAL_PAYMENT_EXISTS_SUCCEEDED,
  CHECK_RENTAL_PAYMENT_EXISTS_FAILED,
} from './constants'

export const checkRentalPaymentExists = () => ({
  type: CHECK_RENTAL_PAYMENT_EXISTS,
})

export const checkRentalPaymentExistsAPI = generateApiCall([
  CHECK_RENTAL_PAYMENT_EXISTS_REQUESTED,
  CHECK_RENTAL_PAYMENT_EXISTS_SUCCEEDED,
  CHECK_RENTAL_PAYMENT_EXISTS_FAILED,
])
