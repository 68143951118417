import React from "react"
import { ModalDefault, ModalActions, ModalHeader } from "./modal-default"
import { Button } from "../buttons/buttons"
import { H4, TextDiv } from "../typography"
import { Span } from "../typography/text-style"

const ReactivatePropertyModal = props => {
  const { closeModal, confirmReactivateProperty, propertyId } = props
  return (
    <ModalDefault
      {...props}
      header={
        <ModalHeader>
          <H4 size="20px" strong align="center">
            Confirmation
          </H4>
        </ModalHeader>
      }
      className="rentspreeModal smallModal">
      <TextDiv mt={-15} mb={30} size="14px" align="center">
        <Span m0>Are you sure you want to reactivate this property?</Span>
        <br />
        <Span m0>
          All ApplyLink&trade; associated with it will be active so applicants
          will be able to apply.
        </Span>
      </TextDiv>
      <ModalActions>
        <Button
          default
          id="cancelReactivatePropertyBtn"
          text="Cancel"
          minWidth="90"
          onClick={closeModal}
        />
        <Button
          green
          id="confirmReactivatePropertyBtn"
          text="Reactivate"
          minWidth="120"
          onClick={() => confirmReactivateProperty(propertyId)}
        />
      </ModalActions>
    </ModalDefault>
  )
}

export default ReactivatePropertyModal
