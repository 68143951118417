import styled from "styled-components"
import diamondBg from "images/backgrounds/gplay-diamond.png"
import { TOPBAR_HEIGHT, STEPPER_HEIGHT } from "styles/settings"

export const DiamondBG = styled.div`
  width: 100%;
  background: linear-gradient(rgba(249, 249, 249, 0.5) 0%, #f9f9f9),
    url(${diamondBg});
`
export const WrapperWithOutDiamond = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT}
  ); /* fallback if calc did not work */
  min-height: calc(
    var(--vh, 1vh) * 100 - ${TOPBAR_HEIGHT}
      ${props => props.withStepper && `- ${STEPPER_HEIGHT}`}
  );
  position: relative;
  font-family: Source Sans Pro;
  font-size: 14px;
  width: 100%;
`
export const DiamondWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT}
  ); /* fallback if calc did not work */
  min-height: calc(var(--vh, 1vh) * 100 - ${TOPBAR_HEIGHT});
  position: relative;
  font-family: Source Sans Pro;
  font-size: 14px;
  width: 100%;
  background: linear-gradient(rgba(249, 249, 249, 0.5) 0%, #f9f9f9),
    url(${diamondBg});
`
