import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'
import {
  setupCreateProperty,
  openCreateProperty,
  closeCreateProperty,
  goNextStepCreateProperty,
  goBackCreateProperty,
  resetCreateProperty,
  fetchCreateProperty,
  fetchVerifyPropertyAddress,
  fetchPropertyByAddress,
  resetAddressVerification,
  resetPropertyByAddress,
} from 'v3/containers/create-property-form/actions'
import {
  createPropertyStateReducer,
  CREATE_PROPERTY_KEY,
} from 'v3/containers/create-property-form/reducers'
import { createPropertyFormRootSaga } from 'v3/containers/create-property-form/sagas'
import {
  makeSelectIsOpen,
  makeSelectCurrentPageIndex,
  makeSelectPages,
  makeSelectAddressVerificationState,
  makeSelectPropertyByAddress,
  makeSelectCreateProperty,
  makeSelectOnSuccessCallback,
  makeSelectLocation,
  makeSelectPreFillFormValues,
  makeSelectIsLandlordInfoRequired,
} from 'v3/containers/create-property-form/selectors'

export const useCreatePropertyHook = () => {
  const dispatch = useDispatch()
  useInjectReducer({ key: CREATE_PROPERTY_KEY, reducer: createPropertyStateReducer })
  useInjectSaga({ key: CREATE_PROPERTY_KEY, saga: createPropertyFormRootSaga })
  const isOpen = useSelector(makeSelectIsOpen())
  const currentPageIndex = useSelector(makeSelectCurrentPageIndex())
  const pages = useSelector(makeSelectPages())
  const location = useSelector(makeSelectLocation())
  const addressVerification = useSelector(makeSelectAddressVerificationState())
  const propertyByAddress = useSelector(makeSelectPropertyByAddress())
  const createProperty = useSelector(makeSelectCreateProperty())
  const onSuccessCallback = useSelector(makeSelectOnSuccessCallback())
  const preFillFormValues = useSelector(makeSelectPreFillFormValues())
  const isLandlordInfoRequired = useSelector(makeSelectIsLandlordInfoRequired())

  return {
    setupCreateProperty: options => dispatch(setupCreateProperty({ options })),
    open: () => dispatch(openCreateProperty()),
    close: () => dispatch(closeCreateProperty()),
    nextStep: () => dispatch(goNextStepCreateProperty()),
    backStep: () => dispatch(goBackCreateProperty()),
    reset: () => dispatch(resetCreateProperty()),
    fetchCreateProperty: payload => dispatch(fetchCreateProperty(payload)),
    fetchVerifyPropertyAddress: payload => dispatch(fetchVerifyPropertyAddress(payload)),
    fetchPropertyByAddress: payload => dispatch(fetchPropertyByAddress(payload)),
    resetAddressVerification: () => dispatch(resetAddressVerification()),
    resetPropertyByAddress: () => dispatch(resetPropertyByAddress()),
    resetCreateProperty: () => dispatch(resetCreateProperty()),
    isOpen,
    currentPageIndex,
    pages,
    location,
    addressVerification: addressVerification?.addressVerification,
    isLoading:
      addressVerification?.isVerifyingPropertyAddress ||
      propertyByAddress?.isGettingPropertyByAddress ||
      createProperty?.isCreatingProperty,
    isExistingProperty: propertyByAddress?.isExistingProperty,
    createdProperty: createProperty?.createdProperty,
    onSuccessCallback,
    preFillFormValues,
    isLandlordInfoRequired,
  }
}
