import produce from 'immer'
import { TOGGLE_SHARE_REPORT_PROMPT_MODAL, RESET_SHARE_REPORT_PROMPT_MODAL } from './constants'

export const initialState = {
  isShowModal: false,
  isAlreadyShow: false,
  navigationUnblock: undefined,
}

/* eslint-disable default-case, no-param-reassign */
const shareReportPromptReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case TOGGLE_SHARE_REPORT_PROMPT_MODAL:
        draft.isShowModal = payload.toggle
        draft.isAlreadyShow = true
        draft.navigationUnblock = payload.navigationUnblock
        break
      case RESET_SHARE_REPORT_PROMPT_MODAL:
        draft.navigationUnblock = undefined
        draft.isAlreadyShow = false
        break
    }
  })

export default shareReportPromptReducer
