import styled from 'styled-components'
import { COLOR } from 'styles/settings'

export const MessagingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${COLOR.whiteContainer};
`

export const ChatBoxWrapper = styled.div`
  display: ${props => (props.showChatBox ? 'block' : 'none')};
  width: calc(100% - 10px);
  height: calc(100vh - 175px);
  margin: 5px 5px 0 5px;
  background-color: #f1f1f1;
  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    background-color: #fff;
    min-height: 400px;
  }
`

export const ChatBoxLoadingWrapper = styled.div`
  display: ${props => (!props.showChatBox ? 'flex' : 'none')};
  width: calc(100% - 10px);
  height: calc(100vh - 165px);
  justify-content: center;
  align-items: center;
`
