import React from 'react'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import styled from 'styled-components'
import {
  S24,
  B16,
  BlueGradientButton,
} from '@rentspree/component-v2'

import BooleanCardV2 from 'components/molecules/boolean-card-v2'
import RadioImage from 'components/atoms/radio-image'
import { SELECT_OPTION } from './constants'

export const ContentWrapper = styled.div`
  display: flex;
  gap: 10px;
  b {
    font-weight: 600;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
  margin-bottom: 20px;
`
export const DescriptionWrapper = styled.div`
  padding: 5px 0px 20px 0px;
  b {
    font-weight: 600;
  }
`
export const ModalBodyWrapper = styled.div`
  font-family: Source Sans Pro;
  display: flex;
  flex-direction: column;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 5px;
  }
`
export const separateAddressLine = (address) => {
  if (!address) {
    return ''
  }
  const firstLine = address.unitNumber ? `${address.street}, ${address.unitNumber}`: `${address.street}`
  const secondLine = `${address.city}, ${address.state} ${address.zip}`
  return `${firstLine} <br> ${secondLine}`
}

export const highlightChangedWords =  (currentObject, newObject) => {
  if (!currentObject || !newObject) {
    return newObject
  }
  const highlightedObject= {}
  Object.keys(newObject).forEach(key => {
    if (!currentObject[key]) {
      highlightedObject[key] = `<b>${newObject[key]}</b>`
      return
    }
    const splittedCurrentKey = currentObject[key].split(' ')
    const splittedNewKey = newObject[key].split(' ')
    
    highlightedObject[key] = splittedNewKey.map((word, index) => {
      if (splittedCurrentKey[index] !== word) {
        const trimmedWord = word.replace(',', '')
        const highlightedWord = `<b>${trimmedWord}</b>`
        return word.replace(trimmedWord, highlightedWord)
      }
      return word
    }).join(' ')
  })
  
  return highlightedObject
}

const StandardizedAddressModal = ({
  isOpen,
  handleSubmitProperty,
  onClose,
  enteredAddress,
  standardizedAddress,
  isAddressVerified,
}) => {
  const [selected, setSelected] = React.useState(SELECT_OPTION.STANDARDIZED_ADDRESS)

  const formattedEnteredAddress = () => separateAddressLine(enteredAddress)

  const formattedStandardizedAddress = () => {
    const highlightedStandardizedAddress = highlightChangedWords(enteredAddress, standardizedAddress)
    return separateAddressLine(highlightedStandardizedAddress)
  }

  return <RentSpreeBaseModal
    id="standardizedAddressModal"
    isOpen={isOpen}
    onClose={onClose}
    closeButton
    shouldCloseOnOverlayClick={false}
    modalSize="medium"
    selected={selected}
    >
    <ModalBodyWrapper>
      <S24 margin="0px 0px 10px 0px" mMargin="0px 15px 10px 0px" mSize="24px">
        Review property address
      </S24>
      <DescriptionWrapper>
        <B16 margin="0px" mMargin="0px">
          For a smoother experience on RentSpree, please consider using the suggested address below:
        </B16>
      </DescriptionWrapper>
      <ContentWrapper>
        <BooleanCardV2 
          id="enteredAddressBooleanCard"
          data-testid="entered-address-boolean-card"
          checked={selected === SELECT_OPTION.ENTERED_ADDRESS}
          title="Entered address"
          subtitleHTML={formattedEnteredAddress()}
          icon={RadioImage}
          onClick={() => {
            setSelected(SELECT_OPTION.ENTERED_ADDRESS)
          }}
        />
        <BooleanCardV2 
          id="standardizedAddressBooleanCard"
          data-testid="standardized-address-boolean-card"
          checked={selected === SELECT_OPTION.STANDARDIZED_ADDRESS}
          title="Suggested address"
          subtitleHTML={formattedStandardizedAddress()}
          icon={RadioImage}
          onClick={() => {
            setSelected(SELECT_OPTION.STANDARDIZED_ADDRESS)
          }}
        />
      </ContentWrapper>
      <ButtonWrapper>
        <BlueGradientButton
          text="Use selected address"
          onClick={() => {
            const propertyAddress = selected === SELECT_OPTION.ENTERED_ADDRESS ? enteredAddress : standardizedAddress
            const isVerified = selected === SELECT_OPTION.ENTERED_ADDRESS ? isAddressVerified : true
            handleSubmitProperty(propertyAddress, isVerified)}
          }
          small
          semiBold
          height="35px"
          id="useSelectedAddressBtn"
          data-testid="standardized-address-modal-selected-address-btn"
          fontSize="14px"
          mWidth="100%"
        />
      </ButtonWrapper>
    </ModalBodyWrapper>
  </RentSpreeBaseModal>
}
export default StandardizedAddressModal
