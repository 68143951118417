import { Grid, debounce, Typography } from '@mui/material'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useContext, useMemo, useCallback, useEffect } from 'react'

import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { PaymentsCard } from 'v3/containers/overhaul-rent-payment/components/cards/payments-card'
import { RecipientDetailsCard } from 'v3/containers/overhaul-rent-payment/components/cards/recipient-details-card'
import { TenantAndPropertyCard } from 'v3/containers/overhaul-rent-payment/components/cards/tenant-and-property-card'
import { PaymentsDrawer } from 'v3/containers/overhaul-rent-payment/components/drawer/drawer'
import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { EditPayment } from 'v3/containers/overhaul-rent-payment/components/payment-forms/edit-payment'
import {
  recipientFields,
  PAYMENT_TYPES,
  pageOptions,
  recipientDetailsOptions,
} from 'v3/containers/overhaul-rent-payment/constants'
import { PaymentInfoContext, PageIndexesContext } from 'v3/containers/overhaul-rent-payment/context'
import { paymentsField } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page'
import {
  tenantField,
  propertyField,
} from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/page'
import {
  MONTHLY_PAYMENTS,
  ONE_TIME_PAYMENTS,
  PAYMENT_DETAILS,
  RECIPIENT_DETAILS,
  REVIEW,
  TENANT_AND_PROPERTY,
  INVITE_BY_TEXT,
} from 'v3/containers/overhaul-rent-payment/pages/review/constants'
import {
  useGetOrCreateDraftRentalPayment,
  useUpdateDraftQuotations,
} from 'v3/containers/rent-payment/shared/hooks'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

// TODO: define and import from property and tenant details page
const PHONE = 'phone'
const NAME = {
  FIRST: 'firstName',
  LAST: 'lastName',
}
const PROPERTY_FIELDS = {
  STREET: 'street',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
}
export const handleReviewProgression = async (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  const { propertyAndTenantInfo, reviewInfo } = {
    propertyAndTenantInfo: get(curPaymentInfo, pageOptions.PROPERTY_AND_TENANT, {}),
    reviewInfo: get(curPaymentInfo, pageOptions.REVIEW, {}),
  }
  const tenant = get(propertyAndTenantInfo, tenantField, {})
  const inviteByTextEnabled = get(reviewInfo, INVITE_BY_TEXT, false)

  if (increment && inviteByTextEnabled) {
    if (!tenant.phone) {
      console.error('Phone number is required')
      return false
    }

    const phoneDigits = tenant.phone.replace(/\D/g, '')
    if (phoneDigits.length < 10) {
      console.error('Invalid phone number format')
      return false
    }
  }

  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
  }
  // shortcut invalid scenarios
  if (!(decrement || increment) || (increment && decrement)) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Review page progression request')
  } else {
    if (increment) {
      return true
    }

    if (decrement) {
      setPageIndexes(indexChanges)
      indexChanges.pageL1Index -= 1
    }
  }

  return false
}

// eslint-disable-next-line no-inline-comments
export const ReviewPage = () => {
  const [paymentInfo, setPaymentInfo] = useContext(PaymentInfoContext)
  const [pageIndexes, setPageIndexes] = useContext(PageIndexesContext)
  const { data: draftRentalPayment } = useGetOrCreateDraftRentalPayment()
  const { updateDraftQuotations, data: quotations } = useUpdateDraftQuotations()

  const { recipientDetailsInfo, propertyAndTenantInfo, paymentDetailsInfo, reviewDetails } = {
    recipientDetailsInfo: get(paymentInfo, pageOptions.RECIPIENT_DETAILS, {}),
    propertyAndTenantInfo: get(paymentInfo, pageOptions.PROPERTY_AND_TENANT, {}),
    paymentDetailsInfo: get(paymentInfo, pageOptions.PAYMENT_DETAILS, {}),
    reviewDetails: get(paymentInfo, pageOptions.REVIEW, {}),
  }
  const { recipientInfo, recipient } = {
    recipientInfo: get(recipientDetailsInfo, recipientFields.recipientInfo, {}),
    recipient: get(recipientDetailsInfo, recipientFields.recipient, {}),
  }
  const { property, tenant } = {
    property: get(propertyAndTenantInfo, propertyField, {}),
    tenant: get(propertyAndTenantInfo, tenantField, {}),
  }
  const payments = get(paymentDetailsInfo, paymentsField, [])
  const { phone, firstName, lastName } = {
    phone: get(tenant, PHONE, ''),
    firstName: get(tenant, NAME.FIRST, ''),
    lastName: get(tenant, NAME.LAST, ''),
  }

  const setInviteByText = useCallback(
    checked => {
      setPaymentInfo(prevState => ({
        ...prevState,
        [pageOptions.REVIEW]: {
          ...prevState[pageOptions.REVIEW],
          [INVITE_BY_TEXT]: checked,
        },
      }))
    },
    [setPaymentInfo],
  )

  const inviteByTextCheckbox = get(reviewDetails, INVITE_BY_TEXT, false)

  const [selectedPayment, setSelectedPayment] = useState()
  const { monthlyPayments, onetimePayments } = useMemo(() => {
    return {
      monthlyPayments: payments.filter(payment => payment.type === PAYMENT_TYPES.RECURRING),
      onetimePayments: payments.filter(payment => payment.type === PAYMENT_TYPES.ONE_TIME),
    }
  }, [payments])
  const isAgentInitiated = recipient === recipientDetailsOptions.CLIENT
  const { street, city, state, zip } = {
    [PROPERTY_FIELDS.STREET]: get(property, PROPERTY_FIELDS.STREET, ''),
    [PROPERTY_FIELDS.CITY]: get(property, PROPERTY_FIELDS.CITY, ''),
    [PROPERTY_FIELDS.STATE]: get(property, PROPERTY_FIELDS.STATE, ''),
    [PROPERTY_FIELDS.ZIP]: get(property, PROPERTY_FIELDS.ZIP, ''),
  }
  const propertyAddress = `${street}, ${city}, ${state} ${zip}`

  const setDrawerOpen = useCallback(
    ({ isOpen, payment }) => {
      setPageIndexes({ drawerOpen: isOpen })
      setSelectedPayment(payment)
    },
    [setSelectedPayment, setPageIndexes],
  )

  const debouncedSetPaymentInfo = useMemo(
    () =>
      debounce(newPaymentInfo => {
        const updatedPayments = payments.map(p =>
          p.id === newPaymentInfo.id ? { ...p, ...newPaymentInfo } : p,
        )

        setPaymentInfo(prevState => ({
          ...prevState,
          [pageOptions.PAYMENT_DETAILS]: {
            ...prevState[pageOptions.PAYMENT_DETAILS],
            [paymentsField]: updatedPayments,
          },
        }))
      }, 200),
    [payments, setPaymentInfo],
  )

  const validateSelectedPayment = payment => {
    if (!payment) {
      console.error('No payment selected')
      return false
    }

    if (!payment.amount || typeof payment.amount !== 'number' || payment.amount <= 0) {
      console.error('Payment amount must be a number greater than zero')
      return false
    }

    const today = dayjs().startOf('day')
    if (payment.type === PAYMENT_TYPES.RECURRING) {
      if (!payment.name) {
        console.error('Recurring payment must have a name')
        return false
      }
      if (!payment.startDate) {
        console.error('startDate is required')
        return false
      }

      if (dayjs(payment.startDate).isBefore(today)) {
        console.error('startDate must not be before today')
        return false
      }

      if (payment.endDate && dayjs(payment.endDate).isBefore(dayjs(payment.startDate), 'day')) {
        console.error('endDate must be after startDate')
        return false
      }
    } else {
      if (!payment.dueDate) {
        console.error('dueDate is required')
        return false
      }
      if (dayjs(payment.dueDate).isBefore(today)) {
        console.error('dueDate must not be before today')
        return false
      }
    }

    return true
  }

  const onDrawerSaveHandler = useCallback(
    async ({ increment = false, decrement = false }) => {
      try {
        if (decrement) {
          setDrawerOpen({ isOpen: false, payment: null })
          return false
        }

        if (!increment) {
          console.error('Missing data')
          return false
        }

        if (!validateSelectedPayment(selectedPayment)) {
          return false
        }

        debouncedSetPaymentInfo(selectedPayment)

        if (!isAgentInitiated) {
          const mappedQuotations = quotations.map(quotation =>
            selectedPayment.id === quotation.id ? { ...quotation, ...selectedPayment } : quotation,
          )

          updateDraftQuotations({
            rentalPaymentId: draftRentalPayment.id,
            quotations: mappedQuotations,
          })
        }

        setPageIndexes({ drawerOpen: false })
        // we dont want to trigger page save on the click of drawer save
        return false
      } catch (error) {
        console.error('Error saving payment', error)
        return false
      }
    },
    [
      quotations,
      selectedPayment,
      draftRentalPayment?.id,
      updateDraftQuotations,
      setPageIndexes,
      debouncedSetPaymentInfo,
    ],
  )

  const setPhoneNumber = useCallback(
    newPhoneNumber => {
      setPaymentInfo(prevState => ({
        ...prevState,
        [pageOptions.PROPERTY_AND_TENANT]: {
          ...prevState[pageOptions.PROPERTY_AND_TENANT],
          [tenantField]: {
            ...prevState[pageOptions.PROPERTY_AND_TENANT][tenantField],
            phone: newPhoneNumber,
          },
        },
      }))
    },
    [setPaymentInfo],
  )

  useEffect(() => {
    if (!(INVITE_BY_TEXT in reviewDetails)) {
      setInviteByText(Boolean(phone))
    }
  }, [])

  return (
    <>
      <TitleWithSubtitle
        title={REVIEW.TITLE}
        subtitle={REVIEW.DESCRIPTION}
        mTitleMargin="0 0 0px"
        titleMargin="0 0 0px"
        titleSize="24px"
        subTitleSize="16px"
      />

      {isAgentInitiated && !isEmpty(recipientInfo) && (
        <>
          <Typography variant="title">{RECIPIENT_DETAILS}</Typography>
          <RecipientDetailsCard recipientInfo={recipientInfo} />
        </>
      )}

      <Typography variant="title">{TENANT_AND_PROPERTY}</Typography>
      {/* Need to refactor this to send in the whole object */}
      <TenantAndPropertyCard
        name={`${firstName} ${lastName}`.trim()}
        address={propertyAddress}
        phoneNumber={phone}
        inviteByText={inviteByTextCheckbox}
        onSetInviteByText={setInviteByText}
        onSetPhoneNumber={setPhoneNumber}
        isAgentInitiated={isAgentInitiated}
      />
      <TitleWithSubtitle
        title={PAYMENT_DETAILS.title}
        subtitle={PAYMENT_DETAILS.description}
        mTitleMargin="0 0 0px"
        titleMargin="0 0 0px"
        titleSize="20px"
        subTitleSize="16px"
      />
      {monthlyPayments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="title">{MONTHLY_PAYMENTS}</Typography>
          {monthlyPayments.map(payment => (
            <PaymentsCard
              key={`${payment?.category}-${payment?.type}-${payment?.amount}`}
              payment={payment}
              setDrawerOpen={setDrawerOpen}
            />
          ))}
        </Grid>
      )}
      {onetimePayments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="title">{ONE_TIME_PAYMENTS}</Typography>
          {onetimePayments.map(payment => (
            <PaymentsCard
              key={`${payment?.category}-${payment?.type}-${payment?.amount}`}
              payment={payment}
              setDrawerOpen={setDrawerOpen}
            />
          ))}
        </Grid>
      )}
      <PaymentsDrawer drawerOpen={pageIndexes.drawerOpen}>
        <EditPayment
          payment={selectedPayment}
          setPayment={setSelectedPayment}
          pageIndexes={pageIndexes}
        />
        <DrawerFooter
          buttonLayout={buttonLayoutChoices.NEXT_BACK}
          textOverrides={{ back: 'Cancel', next: 'Save' }}
          onProgress={onDrawerSaveHandler}
        />
      </PaymentsDrawer>
    </>
  )
}
