import React from "react"
import * as Styled from "./getting-started-style"
import { Button } from "../buttons/buttons"

export const IconCardWrap = ({ children, ...props }) => (
  <Styled.iconCardWrap>
    <Styled.iconCardScroll {...props}>{children}</Styled.iconCardScroll>
  </Styled.iconCardWrap>
)

export const IconCardSwitch = ({
  children,
  current,
  handleFirst,
  handleSecond,
  handleThird,
  ...props
}) => (
  <Styled.iconCardSwitch {...props}>
    <span
      onClick={handleFirst}
      className={current === 100 ? "active" : "inactive"}
      role="button"
      onKeyDown={handleFirst}
      tabIndex={-1}>
      How it works
    </span>
    <span
      onClick={handleSecond}
      className={current === 0 ? "active" : "inactive"}
      role="button"
      onKeyDown={handleSecond}
      tabIndex={-1}>
      View sample
    </span>
    <span
      onClick={handleThird}
      className={current === -100 ? "active" : "inactive"}
      role="button"
      onKeyDown={handleThird}
      tabIndex={-1}>
      What renters see
    </span>
  </Styled.iconCardSwitch>
)

export const IconCard = ({
  icon,
  title,
  content,
  button,
  onBtnClick,
  ...props
}) => (
  <Styled.iconCard {...props}>
    <img src={icon.src} alt={icon.alt} />
    <Styled.cardTitle>{title}</Styled.cardTitle>
    <Styled.cardDesc>{content}</Styled.cardDesc>
    <Button
      className="button-lg"
      id={button.id}
      text={button.text}
      icon={button.icon}
      transparentBlue
      width="60%"
      minWidth="200px"
      onClick={onBtnClick}
    />
  </Styled.iconCard>
)
