import { takeLatest, all, put, call } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'

import { apiInstance } from 'utils/api-interceptor'
import { addToast } from 'containers/toast/actions'
import { getLandlordInformationApiState, inviteAfterApplicantAcceptedApiStates } from './actions'
import {
  GET_LANDLORD_INFORMATION_CALL,
  GET_LANDLORD_INFORMATION_API_PATH,
  SEND_INVITATION_EMAIL_SUCCESS_TOAST_CONFIG,
  SEND_INVITATION_EMAIL_ERROR_TOAST_CONFIG,
  INVITE_AFTER_APPLICANT_ACCEPTED_CALL,
} from './constants'
import { INVITE_AFTER_APPLICANT_ACCEPTED_API } from '../constants'

export const getLandlordInformationApi = ({ propertyId }) =>
  apiInstance.get(buildPath(GET_LANDLORD_INFORMATION_API_PATH, { propertyId }))

export const inviteAfterApplicantAcceptedApi = ({
  landlordFirstName,
  landlordLastName,
  landlordEmail,
}) =>
  apiInstance.post(buildPath(INVITE_AFTER_APPLICANT_ACCEPTED_API), {
    landlordFirstName,
    landlordLastName,
    landlordEmail,
  })

export function* getLandlordInformationSaga(action) {
  yield put(getLandlordInformationApiState.request())
  try {
    const response = yield call(getLandlordInformationApi, action.payload)
    yield put(getLandlordInformationApiState.success(response?.[0]))
  } catch (err) {
    yield put(getLandlordInformationApiState.failure())
  }
}

export function* inviteAfterApplicantAcceptedSaga({ payload }) {
  const { landlordFirstName, landlordLastName, landlordEmail } = payload
  yield put(inviteAfterApplicantAcceptedApiStates.request())
  try {
    yield call(inviteAfterApplicantAcceptedApi, {
      landlordFirstName,
      landlordLastName,
      landlordEmail,
    })
    yield put(inviteAfterApplicantAcceptedApiStates.success())
    yield put(addToast(SEND_INVITATION_EMAIL_SUCCESS_TOAST_CONFIG))
  } catch (e) {
    yield put(inviteAfterApplicantAcceptedApiStates.failure())
    yield put(addToast(SEND_INVITATION_EMAIL_ERROR_TOAST_CONFIG))
  }
}

export function* watchGetLandlordInformationCall() {
  yield takeLatest(GET_LANDLORD_INFORMATION_CALL, getLandlordInformationSaga)
}

export function* watchInviteAfterApplicantAcceptedCall() {
  yield takeLatest(INVITE_AFTER_APPLICANT_ACCEPTED_CALL, inviteAfterApplicantAcceptedSaga)
}

export function* rootSaga() {
  yield all([watchGetLandlordInformationCall(), watchInviteAfterApplicantAcceptedCall()])
}

export default rootSaga
