import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import { generateAddress } from '@rentspree/helper'

import { SemiCircle } from 'legacy/components/reports-v2/share/semi'
import { convertToMonthDayYear, isEmptyArray } from 'legacy/components/reports-v2/share/function'
import { NO_CREDIT_SCORE_MESSAGE, SMART_MOVE_RESIDENCE_SCORE } from 'legacy/constants/report-consts'
import {
  PanelContainer,
  Section,
  ResidenceScoresLinkContainer,
  FontReport,
  TableRow,
  TableCell,
} from 'legacy/components/reports-v2/share/doc-style'

import { Table } from 'legacy/components/reports-v2/share/doc-components'
import { REJECT_MESSAGE } from 'legacy/constants/credit-report-code'
import { TU_CONTACT } from 'constants/partner'
import styled from 'styled-components'
import { breakpoints } from 'styles/settings'
import {
  InformationArea,
  InformationLine,
  SectionHeader,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  Content,
  InformationPanel,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'

export const NoScoreMessage = ({ noScoreReason }) =>
  !isEmpty(noScoreReason) ? (
    <FontReport EtcDetail>{REJECT_MESSAGE[noScoreReason] || noScoreReason}</FontReport>
  ) : (
    <div>
      <FontReport lineHeight={1.57} EtcDetail>
        {NO_CREDIT_SCORE_MESSAGE}
      </FontReport>
      <FontReport FourTeen rentspreePhoneNumber>
        {TU_CONTACT.PHONE}
      </FontReport>
    </div>
  )

export const NoScoreFactorDetails = ({ isHaveFactor, scoreFactors, noScoreReason }) =>
  !isHaveFactor || !isEmpty(noScoreReason) ? (
    <NoScoreMessage noScoreReason={noScoreReason} />
  ) : (
    <ScoreFactorDetails scoreFactors={scoreFactors} />
  )

export const ScoreFactorDetails = ({ scoreFactors }) => (
  <Table MarginT="10px" MarginB="0px">
    {
      // use index as a key because their is no unique property
      isArray(scoreFactors) ? (
        scoreFactors.map((factor, index) => (
          <TableRow key={`scoreFactor-${index.toString()}`}>
            <TableCell padding="0">{factor?.description}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow key="scoreFactor-0">
          <TableCell padding="0">{scoreFactors}</TableCell>
        </TableRow>
      )
    }
  </Table>
)

const ScoreFactorPanel = styled(InformationPanel)`
  padding-left: 10px;

  ${breakpoints.mobileReport(`
    padding-left: unset;
    align-self: flex-start;
  `)}
`

const ScoreFactorTitle = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;

  ${breakpoints.mobileReport(`
    font-size: 14px;
  `)}
`

export const ScoreFactor = ({ isHaveFactor, scoreFactors, noScoreReason, score }) =>
  isHaveFactor && isEmpty(noScoreReason) && !isEqual(score, 0) && !isEqual(score, -1) ? (
    <ScoreFactorPanel>
      <ScoreFactorTitle>Score Factor(s)</ScoreFactorTitle>
      <ScoreFactorDetails scoreFactors={scoreFactors} />
    </ScoreFactorPanel>
  ) : (
    <ScoreFactorPanel>
      <ScoreFactorTitle>No Score because of:</ScoreFactorTitle>
      <NoScoreFactorDetails
        noScoreReason={noScoreReason}
        isHaveFactor={isHaveFactor}
        scoreFactors={scoreFactors}
      />
    </ScoreFactorPanel>
  )

const ScoreFactorSubSection = styled(SubSection)`
  display: flex;
  padding-top: 10px;

  ${breakpoints.mobileReport(`
    flex-direction: column;
    align-items: center;
  `)}
`

export const ScoreFactorContainer = ({ score, noScoreReason, scoreFactors, isHaveFactor }) => (
  <ScoreFactorSubSection Subsection>
    <SemiCircle score={score} max={850} min={350} />
    <ScoreFactor
      noScoreReason={noScoreReason}
      scoreFactors={scoreFactors}
      isHaveFactor={isHaveFactor}
      score={score}
    />
  </ScoreFactorSubSection>
)

const ResidenceScoreSubSectionHeaderTextContainer = styled.div`
  display: flex;
`

export const EmploymentDetail = ({ employerName, dateEmployed, dateVerified, address }) => (
  <>
    <InformationPanel>
      <InformationLine label="Employer" value={employerName || '-'} />
      <InformationLine
        label="Employed on"
        value={dateEmployed ? convertToMonthDayYear(dateEmployed) : '-'}
      />
      <InformationLine
        label="Verified on"
        value={dateVerified ? convertToMonthDayYear(dateVerified) : '-'}
      />
    </InformationPanel>
    <InformationPanel>
      <InformationArea label="Address" value={address || '-'} />
    </InformationPanel>
  </>
)

export const Employment = ({ employments }) => (
  <SubSection>
    <SubSectionHeader title="Employment" />
    {employments.map((employment, index) => (
      <Content key={`${employment.employerName}-${index.toString()}`}>
        <EmploymentDetail
          {...employment}
          address={generateAddress(employment)}
          isPaddingTop={index !== 0}
        />
      </Content>
    ))}
  </SubSection>
)

export const Address = ({ addressReport }) => (
  <SubSection>
    <SubSectionHeader title="Address(es) Reported" />
    {
      // use index as a key because there is no unique property
      addressReport.map((address, index) => (
        <Content key={`scoreFactor-${index.toString()}`}>
          <AddressDetail
            {...address}
            address={generateAddress(address)}
            isPaddingTop={index !== 0}
          />
        </Content>
      ))
    }
  </SubSection>
)
export const AddressDetail = ({ address, dateReported, isPaddingTop }) => (
  <>
    <InformationPanel sty={!isPaddingTop && 'paddingTZero'}>
      <InformationArea label="Address" value={address} isAreaOnMobile={false} />
    </InformationPanel>
    <InformationPanel sty={!isPaddingTop && 'paddingTZero'}>
      <InformationLine
        label="Reported on"
        value={dateReported ? convertToMonthDayYear(dateReported) : '-'}
      />
    </InformationPanel>
  </>
)

const PersonalInfo = ({ scores, employments, addressReport }) => {
  const scoreFactors = get(scores, 'factors', [])
  const noScoreReason = get(scores, 'noScoreReason')
  const score = get(scores, 'results')
  const isHaveScoreFactor = !isEmptyArray(scoreFactors)
  return (
    <PanelContainer>
      <Section>
        <SectionHeader title="Personal Info" />
        <SubSectionHeader
          title={
            <ResidenceScoreSubSectionHeaderTextContainer>
              Resident Score
              <ResidenceScoresLinkContainer>
                <a href={SMART_MOVE_RESIDENCE_SCORE} rel="noopener noreferrer" target="_blank">
                  <i className="fas fa-info-circle" style={{ color: '#3b99fc' }} />
                </a>
              </ResidenceScoresLinkContainer>
            </ResidenceScoreSubSectionHeaderTextContainer>
          }
        />
        <ScoreFactorContainer
          score={score}
          noScoreReason={noScoreReason}
          scoreFactors={scoreFactors}
          isHaveFactor={isHaveScoreFactor}
        />
      </Section>
      {!isEmptyArray(employments) && <Employment employments={employments} />}
      {!isEmptyArray(addressReport) && <Address addressReport={addressReport} />}
    </PanelContainer>
  )
}

export default PersonalInfo
