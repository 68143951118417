import { call, put, takeLatest } from 'redux-saga/effects'

import { GET_OR_CREATE_DRAFT_RENTAL_PAYMENT, getOrCreateDraftRentalPaymentAPI } from '../../actions'
import { getOrCreateDraftRentalPaymentInstance } from '../../api-instances'

export function* getOrCreateDraftRentalPaymentSaga({ payload }) {
  const { propertyId, renterInfoId, onSuccess, onError } = payload

  yield put(getOrCreateDraftRentalPaymentAPI.request())

  try {
    const draftRentalPayment = yield call(getOrCreateDraftRentalPaymentInstance, {
      propertyId,
      renterInfoId,
    })

    yield put(getOrCreateDraftRentalPaymentAPI.success(draftRentalPayment))
    yield call(onSuccess, draftRentalPayment)
  } catch (error) {
    yield put(getOrCreateDraftRentalPaymentAPI.failure(error))
    yield call(onError, error)
  }
}

export function* watchGetOrCreateDraftRentalPaymentSaga() {
  yield takeLatest(GET_OR_CREATE_DRAFT_RENTAL_PAYMENT, getOrCreateDraftRentalPaymentSaga)
}
