import produce from 'immer'
import {
  CREATE_IDENTITY_VERIFICATION_SESSION_FAILED,
  CREATE_IDENTITY_VERIFICATION_SESSION_REQUEST,
  CREATE_IDENTITY_VERIFICATION_SESSION_SUCCESS,
  FETCH_PAYEE_INFORMATION_FAILED,
  FETCH_PAYEE_INFORMATION_REQUEST,
  FETCH_PAYEE_INFORMATION_SUCCESS,
  FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED,
  FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUEST,
  FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCESS,
  GET_IDENTITY_VERIFICATION_DETAILS_FAILED,
  GET_IDENTITY_VERIFICATION_DETAILS_REQUEST,
  GET_IDENTITY_VERIFICATION_DETAILS_SUCCESS,
  GET_PAYEE_IDV_UPDATE_LINK_FAILED,
  GET_PAYEE_IDV_UPDATE_LINK_REQUEST,
  GET_PAYEE_IDV_UPDATE_LINK_SUCCESS,
  GET_PAYEE_RISK_LEVEL_FAILED,
  GET_PAYEE_RISK_LEVEL_REQUEST,
  GET_PAYEE_RISK_LEVEL_SUCCESS,
  RESET_PAYEE_IDV_UPDATE_LINK_STATUS,
  RESET_UPDATE_IDENTITY_VERIFICATION,
  UPDATE_IDENTITY_VERIFICATION_FAILED,
  UPDATE_IDENTITY_VERIFICATION_REQUEST,
  UPDATE_IDENTITY_VERIFICATION_SUCCESS,
  RESET_IDENTITY_VERIFICATION_SESSION,
} from '../actions'
import { API_STATUS } from './constants'

export const payeeInitialState = {
  identityVerificationDetails: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  updateIdentityVerification: {
    status: API_STATUS.NONE,
  },
  identityVerificationSession: {
    status: API_STATUS.NONE,
    data: undefined,
    errors: []
  },
  providerAccountInformation: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  information: {
    data: {
      defaultPaymentMethodId: '',
      identityVerification: {},
      businessType: '',
    },
    status: API_STATUS.NONE,
  },
  riskLevel: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  idvUpdateLink: {
    data: undefined,
    status: API_STATUS.NONE,
  },
}

/* eslint-disable no-param-reassign */
export const payeeReducer = (state = payeeInitialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUEST:
        draft.providerAccountInformation.status = API_STATUS.FETCHING
        break
      case FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCESS:
        draft.providerAccountInformation.status = API_STATUS.SUCCESS
        draft.providerAccountInformation.data = payload
        break
      case FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED:
        draft.providerAccountInformation.status = API_STATUS.ERROR
        break

      case GET_IDENTITY_VERIFICATION_DETAILS_REQUEST:
        draft.identityVerificationDetails.status = API_STATUS.FETCHING
        break
      case GET_IDENTITY_VERIFICATION_DETAILS_SUCCESS:
        draft.identityVerificationDetails.status = API_STATUS.SUCCESS
        draft.identityVerificationDetails.data = payload
        break
      case GET_IDENTITY_VERIFICATION_DETAILS_FAILED:
        draft.identityVerificationDetails.status = API_STATUS.ERROR
        break

      case UPDATE_IDENTITY_VERIFICATION_REQUEST:
        draft.updateIdentityVerification.status = API_STATUS.FETCHING
        draft.updateIdentityVerification.errors = []
        break
      case UPDATE_IDENTITY_VERIFICATION_SUCCESS:
        draft.updateIdentityVerification.status = API_STATUS.SUCCESS
        break
      case UPDATE_IDENTITY_VERIFICATION_FAILED:
        draft.updateIdentityVerification.status = API_STATUS.ERROR
        draft.updateIdentityVerification.errors = payload?.data?.errors
        break
      case RESET_UPDATE_IDENTITY_VERIFICATION:
        draft.updateIdentityVerification.status = API_STATUS.NONE
        break

      case CREATE_IDENTITY_VERIFICATION_SESSION_REQUEST:
        draft.identityVerificationSession.status = API_STATUS.FETCHING
        draft.identityVerificationSession.errors = []
        break
      case CREATE_IDENTITY_VERIFICATION_SESSION_SUCCESS:
        draft.identityVerificationSession.status = API_STATUS.SUCCESS
        draft.identityVerificationSession.data = payload
        break
      case CREATE_IDENTITY_VERIFICATION_SESSION_FAILED:
        draft.identityVerificationSession.status = API_STATUS.ERROR
        draft.identityVerificationSession.errors = payload?.data?.errors
        break
      case RESET_IDENTITY_VERIFICATION_SESSION:
        draft.identityVerificationSession.status = API_STATUS.NONE
        draft.identityVerificationSession.data = undefined
        draft.identityVerificationSession.errors = []
        break
      case FETCH_PAYEE_INFORMATION_REQUEST:
        draft.information.status = API_STATUS.FETCHING
        break
      case FETCH_PAYEE_INFORMATION_SUCCESS:
        draft.information.status = API_STATUS.SUCCESS

        draft.information.data = payload
        break
      case FETCH_PAYEE_INFORMATION_FAILED:
        draft.information.status = API_STATUS.ERROR
        break

      case GET_PAYEE_RISK_LEVEL_REQUEST:
        draft.riskLevel.status = API_STATUS.FETCHING
        break
      case GET_PAYEE_RISK_LEVEL_SUCCESS:
        draft.riskLevel.status = API_STATUS.SUCCESS
        draft.riskLevel.data = payload
        break
      case GET_PAYEE_RISK_LEVEL_FAILED:
        draft.riskLevel.status = API_STATUS.ERROR
        break

      case GET_PAYEE_IDV_UPDATE_LINK_REQUEST:
        draft.idvUpdateLink.status = API_STATUS.FETCHING
        break
      case GET_PAYEE_IDV_UPDATE_LINK_SUCCESS:
        draft.idvUpdateLink.status = API_STATUS.SUCCESS
        draft.idvUpdateLink.data = payload
        break
      case GET_PAYEE_IDV_UPDATE_LINK_FAILED:
        draft.idvUpdateLink.status = API_STATUS.ERROR
        break
      case RESET_PAYEE_IDV_UPDATE_LINK_STATUS:
        draft.idvUpdateLink.data = undefined
        draft.idvUpdateLink.status = API_STATUS.NONE
        break

      default:
        break
    }
    return draft
  })
