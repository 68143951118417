import { API_URL } from 'env'
import comingSoonLogo from 'images/rent-payments/coming-soon-logo.svg'

export const MAIN_CONTENT_MVP = {
  imgSrc: comingSoonLogo,
  title: 'Rent Payment',
  description: 'Ready to start collecting rent online?  Get set up in minutes at zero cost to you.',
}

export const ERR_MESSAGE = {
  SAVING: "We've encountered an error saving your info. Please try again.",
  REDIRECT_TO_STRIPE:
    'Cannot connect you to identity verification page at this time. Please try again.',
}
export const RENTAL_PAYMENT_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  ENDED: 'ended',
}

export const RENTAL_PAYMENT_BASE_API = `/api/v2/rental-payments/:rentalPaymentId`
export const INITIATE_RENT_PAYMENT_API = '/api/v2/rental-payments'
export const INVITE_AFTER_APPLICANT_ACCEPTED_API =
  '/api/v2/rental-payments/invite/applicant-accepted'

export const GET_RENTAL_PAYMENT_BY_ID_API = '/api/v2/rental-payments/:rentalPaymentId'

export const PAYMENT_ACCOUNTS_API = `${API_URL}/api/v2/payment-accounts`
export const PAYOUT_METHODS_API = `${PAYMENT_ACCOUNTS_API}/payment-methods`
export const ATTACH_PAYOUT_METHOD_API = `${GET_RENTAL_PAYMENT_BY_ID_API}/update-receiving-account`

export const NOTIFY_BUTTON_TEXT = 'Notify Me'

export const NOTIFY_STATE = {
  notify: 'notify',
  unnotify: 'unnotify',
}

export const PAYEE_IDV_STATUS = {
  ONBOARDING: 'onboarding',
  VERIFYING: 'verifying',
  VERIFIED: 'verified',
  FAILED: 'failed',
  RESTRICTED: 'restricted',
  RESTRICTED_SOON: 'restrictedSoon',
  PENDING_BANK_ACCOUNT: 'pendingBankAccount',
}

export const PAYOUT_METHOD_STATUS = {
  PENDING: 'pending',
  VERIFIED: 'verified',
  FAILED: 'failed',
}

export const PAYOUT_METHOD_TYPE = {
  INSTANT: 'instant',
  MICRO_DEPOSITS: 'microdeposits',
}

export const RENTER_STATUS = {
  PENDING: 'pending',
  INVITED: 'invited',
  ACCEPTED: 'accepted',
}

export const RENTER_STATUS_DISPLAY = {
  // deprecate microdeposit [UX improvement]
  // [RENTER_STATUS.PENDING]: 'You need to finish setting up rent payment first',
  [RENTER_STATUS.INVITED]: "Invited, hasn't accepted yet",
  [RENTER_STATUS.ACCEPTED]: 'Invitation accepted',
}

export const RENTER_STATUS_COLOR = {
  [RENTER_STATUS.PENDING]: '#AC932D',
}

export const RECEIVING_ACCOUNT_FROM_STEP = {
  REQUEST: 'request',
  CHANGE_ACCOUNT: 'change-account',
  RETRY_DEPOSIT: 'retry-deposit',
  PAYMENT_DETAIL: 'payment-detail',
}

export const PANEL_TITLE_TAX_VERIFICATION_VERIFIED_FAILED =
  'We need you to re-verify the tax information.'
export const NAVBAR_KEY = 'navbar'

export const PAYMENT_REQUEST_STATUS = {
  READY_TO_DEPOSIT: 'readyToDeposit',
  UPCOMING: 'upcoming',
  SCHEDULED: 'scheduled',
  DUE: 'due',
  PROCESSING: 'processing',
  DEPOSITED: 'deposited',
  PAYMENT_FAILED: 'paymentFailed',
  DEPOSIT_FAILED: 'depositFailed',
  VOIDED: 'voided',
  RETURNED: 'returned',
  MARKED_AS_PAID: 'markedAsPaid',

  // deprecated since integrate deprecate subscription HEST-3753
  // PAID: 'paid',
  // DEPOSITING: 'depositing',
}

// list of payment status that allow user to mark as paid
export const MARK_AS_PAID_AVAILABLE_STATUS = [
  PAYMENT_REQUEST_STATUS.UPCOMING,
  PAYMENT_REQUEST_STATUS.SCHEDULED,
  PAYMENT_REQUEST_STATUS.DUE,
  PAYMENT_REQUEST_STATUS.PAYMENT_FAILED,
]

export const KEY = {
  PAYEE: 'payee',
  RENTAL_PAYMENT: 'rentalPayment',
  RENT_PAYMENT_MODAL_STATE: 'rentPaymentModalState',
}

export const BANK_ACCOUNT_VERIFY_TYPE = {
  UPFRONT: 'upfront',
  INCREMENTAL: 'incremental',
}
