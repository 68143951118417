import React from 'react'
import { generateFullName } from '@rentspree/helper'
import { Landing } from 'components/landing'
import reportUnavailableSvg from 'legacy/images/credit-report/report-unavailable.svg'

export class ReportUnavailable extends React.Component {
  getAgentName = () => {
    const { application } = this.props
    let agentName = 'the agent/owner'
    if (application && application.rentalDetail && application.rentalDetail.property) {
      agentName = generateFullName(application.rentalDetail.property)
      if (!agentName) agentName = 'the agent/owner'
    }
    return agentName
  }

  render() {
    return (
      <div>
        <Landing
          title="Reports Pending"
          description={`Report are waiting for ${this.getAgentName()} to open.`}
          imgSrc={reportUnavailableSvg}
        />
      </div>
    )
  }
}

export default ReportUnavailable
