import generateApiCall from "utils/generate-api-call"
import { TYPES } from "./constants"

export const callCompleteRentEstimatePayment = payload => ({
  type: TYPES.CALL_COMPLETE_RENT_ESTIMATE,
  payload,
})

export const callCancelRentEstimatePayment = payload => ({
  type: TYPES.CALL_CANCEL_RENT_ESTIMATE,
  payload,
})

export const completeRentEstimateAPI = generateApiCall([
  TYPES.COMPLETE_RENT_ESTIMATE_REQUESTED,
  TYPES.COMPLETE_RENT_ESTIMATE_SUCCESS,
  TYPES.COMPLETE_RENT_ESTIMATE_FAILED,
])

export const cancelRentEstimateAPI = generateApiCall([
  TYPES.CANCEL_RENT_ESTIMATE_REQUESTED,
  TYPES.CANCEL_RENT_ESTIMATE_SUCCESS,
  TYPES.CANCEL_RENT_ESTIMATE_FAILED,
])
