import { takeLatest, put, call } from 'redux-saga/effects'

import { CHECK_RENTAL_PAYMENT_EXISTS, checkRentalPaymentExistsAPI } from '../../actions'
import { checkRentalPaymentExistsInstance } from '../../api-instances'

export function* checkRentalPaymentExistsSaga() {
  yield put(checkRentalPaymentExistsAPI.request())
  try {
    const response = yield call(checkRentalPaymentExistsInstance)
    yield put(checkRentalPaymentExistsAPI.success(response))
  } catch (err) {
    yield put(checkRentalPaymentExistsAPI.failure())
  }
}

export function* watchCheckRentalPaymentExistsSaga() {
  yield takeLatest(CHECK_RENTAL_PAYMENT_EXISTS, checkRentalPaymentExistsSaga)
}
