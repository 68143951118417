import styled, { css } from 'styled-components'

import { FlexBox } from 'legacy/components/layout/section'
import { COLOR } from 'styles/settings'

import { MAXIMUM_INPUT_DATE } from './constants'

export const FlexStyled = styled(FlexBox)`
  margin-bottom: 30px;
`

export const BoxContainer = styled.div`
  width: 100%;
  padding: 0 0 50px 0;
  border-bottom: 1px solid ${COLOR.newGrey};
  @media (max-width: 991px) {
    padding: 0 0 40px 0;
  }
`

export const StyledDateInput = styled.input.attrs(() => ({
  type: 'date',
  max: MAXIMUM_INPUT_DATE,
}))`
  appearance: none;
  font-size: 16px;
  font-family: Source Sans Pro, sans-serif;
  height: 40px;
  width: ${props => (props.width ? `${props.width}` : '100%')};
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px`};
  padding: 0 10px;
  background: ${COLOR.white};
  box-sizing: border-box;
  border: 1px solid ${COLOR.grayLv4};
  border-radius: 0;
  color: ${props => (props.empty ? COLOR.titleGrey : COLOR.textBlack)};

  ${props => props.error && `border-color: ${COLOR.negativeRed}`};
  ${props => props.error && `background: ${COLOR.lightSoftRed}`};

  ${props =>
    props.disabled &&
    css`
      background: ${COLOR.whiteTwo};
      color: ${COLOR.grayLv6};
      cursor: not-allowed;
    `};

  &:focus {
    border: 1px solid ${COLOR.activeBlue};
    outline: none;
  }
`
