import React, { useState } from 'react'
import Button from '@rentspree/component-2023.components.atoms.button'
import Alert from '@rentspree/component-2023.components.organisms.alert'
import { FormControlLabel } from '@mui/material'
import Checkbox from '@rentspree/component-2023.components.atoms.checkbox'
import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import styled from 'styled-components'
import {
  ALERT_ERROR_MESSAGE_SUBTITLE,
  ALERT_ERROR_MESSAGE_TITLE,
  MESSAGE_CHARACTER_LIMIT,
} from '../constants'

const ApplicantName = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`

const TransUnionReportSharingCondition = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`

const InputSection = styled.div`
  margin-top: 32px;
`

export const Recipient = React.forwardRef(
  (
    {
      renter,
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      touched,
      setTouched,
      errors,
      landlordInformation = {},
      handleClickEditReportSelection,
      isError,
      closeError,
      isReportsNotSelected,
    },
    ref,
  ) => {
    const { firstName, lastName } = renter
    const [isMessageReachedCharLimit, setIsMessageReachedCharLimit] = useState(false)

    const handleShareWithLandlordCheckbox = (event, checked) => {
      setFieldValue('shareWithLandlord', checked)
      if (checked) {
        setFieldValue('email', landlordInformation.email)
      } else {
        setFieldValue('email', '')
      }
    }

    const handleIsLandlordEmailCheckbox = (event, checked) => {
      setFieldValue('addLandlordToProperty', checked)
    }

    const handleEmailInputChanged = event => {
      if (event.target.value === landlordInformation.email) {
        setFieldValue('shareWithLandlord', true)
      } else {
        setFieldValue('shareWithLandlord', false)
      }
      setTouched({ email: true })
      handleChange(event)
    }

    const handleAgentMessageChanged = event => {
      const message = event.target.value
      setIsMessageReachedCharLimit(message.length >= MESSAGE_CHARACTER_LIMIT)
      setTouched({ agentMessage: true })
      handleChange(event)
    }

    return (
      <div ref={ref}>
        {isError && (
          <Alert
            severity="error"
            onClose={closeError}
            title={ALERT_ERROR_MESSAGE_TITLE}
            sx={{ marginBottom: '8px' }}
          >
            <ALERT_ERROR_MESSAGE_SUBTITLE />
          </Alert>
        )}
        <ApplicantName>Applicant: {`${firstName} ${lastName}`}</ApplicantName>
        <TransUnionReportSharingCondition>
          TransUnion screening reports can only be shared once.
          {isReportsNotSelected && (
            <>
              <br />
              Edit your report selection to share other reports.
            </>
          )}
        </TransUnionReportSharingCondition>
        <InputSection>
          {landlordInformation.isAssociationContact && (
            <FormControlLabel
              sx={{ margin: '0 0 10px 0' }}
              name="shareWithLandlord"
              checked={values.shareWithLandlord || values.email === landlordInformation.email}
              label="Share with landlord"
              control={
                <Checkbox
                  data-testid="share-with-landlord-checkbox"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                />
              }
              onChange={handleShareWithLandlordCheckbox}
            />
          )}
          <TextInput
            data-testid="share-report-form-email-text-input"
            value={values.email}
            name="email"
            label="Recipient's email"
            sx={{ marginBottom: '20px' }}
            required
            onChange={handleEmailInputChanged}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            helperText={errors.email}
          />
          {!landlordInformation.isAssociationContact && (
            <FormControlLabel
              checked={values.addLandlordToProperty}
              label="This is the landlord's email"
              name="addLandlordToProperty"
              control={
                <Checkbox
                  data-testid="add-ll-to-property-from-share-report-checkbox"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, marginLeft: '10px' }}
                />
              }
              onChange={handleIsLandlordEmailCheckbox}
              sx={{ marginBottom: '16px' }}
            />
          )}
          <TextInput
            data-testid="share-report-form-agent-message-text-input"
            label="Add a message"
            name="agentMessage"
            multiline
            value={values.agentMessage}
            onChange={handleAgentMessageChanged}
            onBlur={handleBlur}
            error={touched.agentMessage && isMessageReachedCharLimit}
            helperText={
              touched.agentMessage &&
              isMessageReachedCharLimit &&
              `Maximum ${MESSAGE_CHARACTER_LIMIT} characters allowed`
            }
            validTextCount={isMessageReachedCharLimit ? MESSAGE_CHARACTER_LIMIT : null}
            inputProps={{ maxLength: MESSAGE_CHARACTER_LIMIT }}
          />
        </InputSection>
        <Button
          data-testid="share-report-form-edit-report-selection-btn"
          variant="text"
          size="small"
          color="secondary"
          className="underline"
          onClick={handleClickEditReportSelection}
          sx={{ marginTop: '32px' }}
        >
          Edit report selection
        </Button>
      </div>
    )
  },
)
