import React from "react"
import { compose } from "redux"
import {
  RENTER_INSURANCE_PAGE,
  RENTER_INSURANCE_REQUEST_PAGE,
  RENTER_INSURANCE,
} from "constants/route"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { CenterContent, Loading } from "components/layout/main"
import { buildPath, query } from "@rentspree/path"
import { NOT_AVAILABLE_TYPE } from "./const"
import Form, { MainContainer, Container, Wrapper } from "./form"
import { withConnect, withReducer, withSaga } from "./connect"

export const PropertyUpdatePropertyTypeContainer = ({
  property,
  actions,
  match,
  history,
  location,
  error,
  isError,
  isLoading,
  isPropertyFetching,
}) => {
  const { fromLandingPage } = !isEmpty(query.parse(history.location.search))
    ? query.parse(history.location.search)
    : location.state || {}

  React.useEffect(() => {
    actions.getProperty(match.params.propertyId)
    return () => {
      actions.clearPropertyTypeError()
      actions.clearProperty()
    }
  }, [])
  React.useEffect(() => {
    const { propertyType } = property
    if (propertyType && propertyType !== NOT_AVAILABLE_TYPE) {
      const path = buildPath(
        RENTER_INSURANCE_REQUEST_PAGE,
        {
          propertyId: get(match, "params.propertyId", ""),
        },
        query.parse(history.location.search),
      )
      history.replace(path, location.state)
    }
  }, [property])

  const backToRenterInsurance = () => {
    if (fromLandingPage) {
      history.push(buildPath(RENTER_INSURANCE))
    } else {
      history.push(
        buildPath(RENTER_INSURANCE_PAGE, {
          propertyId: get(match, "params.propertyId", ""),
        }),
      )
    }
  }
  const savePropertyType = async propertyType => {
    const propertyId = get(match, "params.propertyId", "")
    await actions.updatePropertyType(
      { propertyType },
      propertyId,
      history.location,
    )
  }
  if (isPropertyFetching)
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )
  return (
    <MainContainer id="propertyTypeFormPage">
      <Container>
        <Wrapper>
          <Form
            isError={isError}
            saveError={error}
            savePropertyType={savePropertyType}
            backToDashboard={backToRenterInsurance}
            actions={actions}
            property={property}
            isLoading={isLoading}
          />
        </Wrapper>
      </Container>
    </MainContainer>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(PropertyUpdatePropertyTypeContainer)
