import { array, lazy, mixed, object, string } from 'yup'

import { rentalPaymentValidation } from 'constants/validations'
import {
  requiredYup,
  validatePhone,
  validateEmailForRentPayment,
  validateLength,
} from 'legacy/constants/validations'

import { QUOTATION_TYPE } from '../../constants'

import { PAYMENT_CATEGORIES } from './constants'

export const mapPropsToValues = ({ queryParams = {}, selectedProperty, selectedTenant }) => ({
  selectedProperty: selectedProperty || queryParams.propertyId,
  selectedTenant: selectedTenant || queryParams.renterInfo,
  landlordInfo: {
    firstName: '',
    lastName: '',
    email: '',
  },
  oneTimePayments: [
    {
      category: PAYMENT_CATEGORIES.SECURITY_DEPOSIT,
      customCategory: '',
      amount: '',
      dueDate: '',
      key: Date.now(),

      /*
       * isInitiallyAdded: specify that this payment is initially added at the first time
       * the clean form is rendered.
       */
      isInitiallyAdded: true,
    },
  ],
  recurringPayments: [
    {
      category: PAYMENT_CATEGORIES.RENT,
      customCategory: '',
      amount: '',
      startDate: '',
      endDate: '',
      dueOn: '',
      repeat: 'monthly',
      key: Date.now(),

      /*
       * isInitiallyAdded: specify that this payment is initially added at the first time
       * the clean form is rendered.
       */
      isInitiallyAdded: true,
    },
  ],
})

export const validationSchema = () =>
  object().shape({
    selectedProperty: requiredYup(true),
    landlordInfo: object().shape({
      firstName: requiredYup(true),
      lastName: requiredYup(true),
      email: validateEmailForRentPayment(true),
    }),
    selectedTenant: requiredYup(true, string()),
    oneTimePayments: requiredYup(
      true,
      array()
        .of(
          lazy(val => {
            const { amount, dueDate, category, customCategory } = val

            if (!!amount || !!dueDate || !!category || !!customCategory) {
              return object().shape({
                amount: rentalPaymentValidation('amount', false, { type: QUOTATION_TYPE.ONE_TIME }),
                dueDate: rentalPaymentValidation('dueOnDate'),
                category: rentalPaymentValidation('categorySelector'),
                customCategory: string().when('category', {
                  is: PAYMENT_CATEGORIES.OTHER,
                  then: () =>
                    rentalPaymentValidation('customCategory', true, { isCustomCategory: true }),
                }),
              })
            }

            return mixed().nullable()
          }),
        )
        .min(1, 'At least one one-time payment for security deposit is required'),
    ),
    recurringPayments: requiredYup(
      false,
      array().of(
        lazy(val => {
          const { amount, startDate, endDate, category, customCategory, isInitiallyAdded } = val

          if (
            !!amount ||
            !!startDate ||
            !!endDate ||
            !!(category && !isInitiallyAdded) ||
            !!customCategory
          ) {
            return object().shape({
              amount: rentalPaymentValidation('amount', false, { type: QUOTATION_TYPE.RECURRING }),
              startDate: rentalPaymentValidation('firstPaymentSelector'),
              endDate: rentalPaymentValidation('lastPaymentSelector'),
              category: rentalPaymentValidation('categorySelector'),
              customCategory: string().when('category', {
                is: PAYMENT_CATEGORIES.OTHER,
                then: () =>
                  rentalPaymentValidation('customCategory', true, { isCustomCategory: true }),
              }),
            })
          }

          return mixed().nullable()
        }),
      ),
    ),
  })

export const createTenantValidationSchema = ({ ownerEmail }) =>
  object().shape({
    firstName: validateLength(),
    lastName: validateLength(),
    email: validateEmailForRentPayment(true, ownerEmail),
    phone: validatePhone(false),
  })

export const createTenantMapPropsToValues = ({
  firstName = '',
  lastName = '',
  phone = '',
  email = '',
  apiErrors = {},
}) => ({
  firstName,
  lastName,
  email,
  phone,
  apiErrors,
})

export const handleSubmitCreateTenant = (values, { props }) => {
  props.onSubmit(values)
}

export const createTenantSchema = {
  validationSchema: createTenantValidationSchema,
  mapPropsToValues: createTenantMapPropsToValues,
  handleSubmit: handleSubmitCreateTenant,
  enableReinitialize: true,
  displayName: 'CreateTenantForm',
}
