import { APPLICATION_API } from "constants/api-endpoint"

export const GET_LRA_APPLICATION_CALL = "GET_LRA_APPLICATION_CALL"
export const GET_LRA_APPLICATION_REQUEST = "GET_LRA_APPLICATION_REQUEST"
export const GET_LRA_APPLICATION_SUCCESS = "GET_LRA_APPLICATION_SUCCESS"
export const GET_LRA_APPLICATION_FAILURE = "GET_LRA_APPLICATION_FAILURE"

export const GET_ENVELOPE_CALL = "GET_ENVELOPE_CALL"
export const GET_LRA_ENVELOPE_REQUEST = "GET_LRA_ENVELOPE_REQUEST"
export const GET_LRA_ENVELOPE_SUCCESS = "GET_LRA_ENVELOPE_SUCCESS"
export const GET_LRA_ENVELOPE_FAILURE = "GET_LRA_ENVELOPE_FAILURE"

export const UPDATE_LRA_ENVELOPE_SIGN_CALL = "UPDATE_LRA_ENVELOPE_SIGN_CALL"
export const UPDATE_LRA_ENVELOPE_SIGN_REQUEST =
  "UPDATE_LRA_ENVELOPE_SIGN_REQUEST"
export const UPDATE_LRA_ENVELOPE_SIGN_SUCCESS =
  "UPDATE_LRA_ENVELOPE_SIGN_SUCCESS"
export const UPDATE_LRA_ENVELOPE_SIGN_FAILURE =
  "UPDATE_LRA_ENVELOPE_SIGN_FAILURE"

export const SIGNER_TYPE = {
  AGENT: "agent",
}

export const UPDATE_LRA_ENVELOPE_SIGN_API = `${APPLICATION_API}/rental-submissions/:rentalId/application/envelope/sign`
