/* eslint-disable react/no-array-index-key */
import { Button } from '@rentspree/component-2023.components.atoms.button'
import { T20, T24 } from '@rentspree/component-v2'
import React from 'react'
import styled from 'styled-components'

import { Ul } from 'components/atoms/list'
import { CDN_URL } from 'env'

import { useViewModel } from './success-view-model'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 140px 0 0 0;
`

const ContentWrapper = styled.div`
  width: 527px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

export const EXISTING_LANDLORD_BULLETS = [
  'The tenant can pay as soon as they receive the request.',
  'We’ll tell the landlord to add their bank account.',
  'You’ll get an email when the landlord gets paid.',
]

export const NEW_LANDLORD_BULLETS = [
  'We’ll tell the landlord to sign up and add their bank account.',
  'After the landlord signs up, the tenant can pay.',
  'You’ll get an email when the landlord gets paid.',
]

export const AgentInitPaymentSuccessPage = props => {
  const { onPrimaryButtonClick, landlordType } = useViewModel(props)

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <T24 size="28px" margin="0 0 36px 0">
            Payment request sent!
          </T24>
          <img
            src={`${CDN_URL}/static-files/shared-assets/girl-paperplane.svg`}
            alt=""
            style={{ marginBottom: '8px' }}
          />
          <T20 lineHeight="32px" margin="0">
            What to expect next:
          </T20>
          <Ul style={{ fontSize: '16px', lineHeight: '24px' }}>
            {(landlordType === 'existing' ? EXISTING_LANDLORD_BULLETS : NEW_LANDLORD_BULLETS).map(
              (text, i) => (
                <li key={i}>{text}</li>
              ),
            )}
          </Ul>
        </ContentWrapper>
        <Button
          sx={{ m: '52px 0 0 0', alignSelf: 'flex-end' }}
          size="medium"
          variant="contained"
          color="secondary"
          data-testid="agent-init-payment-success-primary-button"
          onClick={onPrimaryButtonClick}
        >
          Go to property
        </Button>
      </Container>
    </Wrapper>
  )
}
