import produce from 'immer'

import {
  GET_CONTACT_DETAIL_REQUEST,
  GET_CONTACT_DETAIL_SUCCESS,
  GET_CONTACT_DETAIL_FAILURE,
  UPDATE_CONTACT_DETAIL_INSTANTLY,
  UPDATE_CONTACT_DETAIL_REQUEST,
  UPDATE_CONTACT_DETAIL_SUCCESS,
  UPDATE_CONTACT_DETAIL_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  STAR_CONTACT,
  TAG_CONTACT_REQUEST,
  TAG_CONTACT_SUCCESS,
  TAG_CONTACT_FAILURE,
  UNTAG_CONTACT_SUCCESS,
  UPDATE_CONTACT_DETAIL_CALL,
} from './constants'

export const initialState = {
  information: {
    data: {},
    isLoading: false,
    isError: false,
    isDeleting: false,
    isTagging: false,
    loadingButtons: [],
  }, // TODO: if don't use activities and note anymore, flatten information
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case GET_CONTACT_DETAIL_REQUEST:
        draft.information.isLoading = true
        draft.information.isError = false
        break
      case GET_CONTACT_DETAIL_SUCCESS:
        draft.information.data = payload
        draft.information.isLoading = false
        draft.information.isError = false
        break
      case GET_CONTACT_DETAIL_FAILURE:
        draft.information.isLoading = false
        draft.information.isError = true
        break
      case UPDATE_CONTACT_DETAIL_INSTANTLY:
        draft.information.data = { ...draft.information.data, ...payload.data }
        break
      case UPDATE_CONTACT_DETAIL_CALL:
        draft.information.loadingButtons = [...draft.information.loadingButtons, payload.buttonId]
        break
      case UPDATE_CONTACT_DETAIL_REQUEST:
        draft.information.isUpdating = true
        break
      case UPDATE_CONTACT_DETAIL_SUCCESS:
        payload.updatedKeys.forEach(key => {
          draft.information.data[key] = payload.response[key]
        })
        draft.information.loadingButtons = draft.information.loadingButtons.filter(
          id => id !== payload.buttonId,
        )
        draft.information.isError = false
        break
      case UPDATE_CONTACT_DETAIL_FAILURE:
        draft.information.isError = true
        draft.information.loadingButtons = draft.information.loadingButtons.filter(
          id => id !== payload.buttonId,
        )
        break
      case DELETE_CONTACT_REQUEST:
        draft.information.isDeleting = true
        break
      case DELETE_CONTACT_SUCCESS:
        draft.information.isDeleting = false
        draft.information.isError = false
        break
      case DELETE_CONTACT_FAILURE:
        draft.information.isDeleting = false
        draft.information.isError = true
        break
      case STAR_CONTACT:
        draft.information.data.starredAt = !state.information.data.starredAt
        break
      case TAG_CONTACT_REQUEST:
        draft.information.isTagging = true
        break
      case TAG_CONTACT_SUCCESS:
        draft.information.isTagging = false
        draft.information.data.tags = payload?.tags
        break
      case UNTAG_CONTACT_SUCCESS: // TODO: not sure about logic to update data in reducer.
        draft.information.data.tags = payload?.tags
        break
      case TAG_CONTACT_FAILURE:
        draft.information.isTagging = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
