import React from 'react'
import HeaderPrint from 'containers/reports/header'
import styled from 'styled-components'
import { ReportsBody, PanelArea } from 'legacy/components/reports-v2/share/doc-components'
import {
  WrapperReport,
  FontReport,
  Section,
  PanelContainer,
} from 'legacy/components/reports-v2/share/doc-style'
import { RENTSPREE_REQUEST_SUPPORT_LINK } from 'containers/envelope/upload-file-step/constants'
import { HeaderDoc } from './doc-components'

const PanelAreaColor = styled(PanelArea)`
  padding: 15px 10px 25px 10px;
`

const NullDataReport = ({ generatedOn, headerDoc }) => (
  <WrapperReport>
    <HeaderPrint />
    <ReportsBody>
      <HeaderDoc text={headerDoc} transUnion={{ generatedOn }} />
      <PanelContainer>
        <Section>
          <PanelAreaColor>
            <FontReport marginB Bold>
              We’re experiencing a delay from TransUnion.
            </FontReport>
            <FontReport marginB FourTeen>
              Please try again in 30 minutes. <br />
              If the problem persists,{' '}
              <a href={RENTSPREE_REQUEST_SUPPORT_LINK} target="_blank">
                contact RentSpree.
              </a>
            </FontReport>
            <FontReport marginB FourTeen></FontReport>
          </PanelAreaColor>
        </Section>
      </PanelContainer>
    </ReportsBody>
  </WrapperReport>
)

export default NullDataReport
