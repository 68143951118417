import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'

import { updateMySeenAnnouncement } from '../services/announcement'

/**
 * useMutateMySeenAnnouncement update acknowledgement of announcement.
 * @param {object} payload with {announcementType, groups}
 * @param {import("react-query").UseMutationOptions} options mutation options
 */
export const useMutateMySeenAnnouncement = ({ announcementType, groups }, options) => {
  const queryClient = useQueryClient()
  const userProfile = useSelector(selectProfile)

  return useMutation({
    mutationKey: ['useMutateMySeenAnnouncement', `${announcementType}_${groups}`],
    mutationFn: () => updateMySeenAnnouncement(announcementType, groups),
    onSuccess: () => {
      queryClient.setQueryData(['getMySeenAnnouncement', userProfile?.id], oldData =>
        oldData
          ? {
              ...oldData,
              seen_groups: {
                ...oldData.seen_groups,
              },
              lazyCacheSeenGroups: {
                ...oldData.lazyCacheSeenGroups,
                [groups]: true,
              },
            }
          : oldData,
      )
    },
    ...options,
  })
}
