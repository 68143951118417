import { Icon } from '@rentspree/component-v2/dist/icon'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { WidgetTitle } from 'components/contacts/atoms/widget'
import { COLOR } from 'styles/settings'

const MinimizeWidgetContainer = styled.div`
  display: flex;
  height: 20px;
`

const PrefixContainer = styled.img`
  margin-right: ${props => props?.prefixIcon && '12px'};
  display: ${props => props?.prefixIcon && 'none'};
`

const WidgetTitleWrapper = styled(WidgetTitle)`
  margin-top: 0px;
  flex: 2;
`

const SuffixIcon = styled.img`
  cursor: pointer;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 15px;
  display: ${props => props?.minimizePage && 'none'};
`

const MinimizeWidget = ({
  prefixIcon,
  prefixIconColor,
  title,
  suffixText,
  suffixIcon,
  onClickSuffixIcon,
}) => (
  <MinimizeWidgetContainer>
    <PrefixContainer src={prefixIcon} color={prefixIconColor} />
    <WidgetTitleWrapper>{title}</WidgetTitleWrapper>
    <div>{suffixText}</div>
    {suffixIcon ? (
      <SuffixIcon src={suffixIcon} onClick={onClickSuffixIcon} />
    ) : (
      <CloseIcon icon="close" color={COLOR.textBlack} onClick={onClickSuffixIcon} />
    )}
  </MinimizeWidgetContainer>
)

export default MinimizeWidget

MinimizeWidget.propType = {
  prefixIcon: PropTypes.string,
  prefixIconColor: PropTypes.string,
  title: PropTypes.string,
  suffixText: PropTypes.string,
  suffixIcon: PropTypes.string,
  onClickSuffixIcon: PropTypes.func,
}
