import React from "react"
import styled from "styled-components"
import { Div } from "components/layout/main"
import { H3 } from "../typography"
import { COLOR, FLEX_CENTER } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const StyledReqDocsStep = styled.div`
  margin-bottom: 20px;
  > h3 {
    margin-bottom: 15px;
  }
`
export const StyledStepImgWrap = styled.div`
  position: relative;
  text-align: center;
`
// language=SCSS prefix=dummy{ suffix=}
export const StyledMark = styled.div`
  ${FLEX_CENTER};
  color: ${COLOR.white};
  font-size: 12px;
  position: absolute;
  background-color: ${COLOR.newOrange};
  width: 25px;
  height: 25px;
  border-radius: 50px;
  top: ${props => props.top && props.top};
  left: ${props => props.top && props.left};
  @media (max-width: 991px) {
    width: 20px;
    height: 20px;
  }
  > .index {
    z-index: 2;
  }
`
// language=SCSS prefix=dummy{ suffix=}
const AnimatedCircle = styled.span`
  background-color: ${COLOR.newOrange};
  width: 25px;
  height: 25px;
  border-radius: 50px;
  animation: markGlow 1.5s infinite;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 991px) {
    width: 20px;
    height: 20px;
  }

  @keyframes markGlow {
    0% {
      opacity: 0.5;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform: scale(2, 2);
    }
  }
`

export const ReqDocsStep = ({ index, step }) => (
  <StyledReqDocsStep>
    <H3 size="1.4em" strong color="titleBlack">
      {index}. &nbsp;&nbsp;{step.title}
    </H3>
    <StyledStepImgWrap className="hidden-xs hidden-sm">
      <Mark index={index} mark={step.mark} />
      <img src={step.image} alt={`step ${step.index} tutorial`} />
    </StyledStepImgWrap>
    <StyledStepImgWrap className="visible-xs visible-sm">
      <Div inlineBlock relative>
        <Mark index={index} mark={step.mMark} />
        <img src={step.mImage} alt={`step ${step.index} tutorial`} />
      </Div>
    </StyledStepImgWrap>
  </StyledReqDocsStep>
)

export const Mark = ({ index, mark }) => (
  <StyledMark top={mark.top} left={mark.left}>
    <AnimatedCircle />
    <span className="index">{index}</span>
  </StyledMark>
)
