import React from 'react'
import { withFormik } from 'formik'
import { ShareReportDialog, ShareReportSheet } from 'components/share-report-modal'
import { isEmpty, pickBy } from 'lodash'
import { APPLICATION_TYPE } from 'constants/application-type'
import useMobile from 'hooks/use-mobile'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import tracker from 'tracker'
import { EVENT_REPORT, EVENT_TENANT_SCREENING } from 'tracker/const'
import { shareReportFormSchema } from './schema'
import { Recipient } from './recipient'
import { ReportSelection } from './report-selection'
import { MODAL_PAGES, MODAL_TITLES } from '../constants'

const getIsSubmitDisabled = (values, errors) =>
  isEmpty(values.email) ||
  !isEmpty(errors) ||
  !(
    values.application ||
    values.screeningReport ||
    values.incomeVerificationReport ||
    values.referenceCheck ||
    values.otherDocs
  )

export const Form = props => {
  const {
    submitForm,
    isLoading,
    isOpen,
    onClickClose,
    reportAvailable,
    isExpired,
    isIncomeVerificationExpired,
    isIDMAExpired,
    screeningOption: { credit_report: creditReport },
    setFormValues,
    values,
    errors,
    landlordInformation,
    defaultShareOption,
    applicationType,
    screeningOption,
    expiredDate,
    setTouched,
    touched,
    handleBlur,
    renter,
    renterDetail,
    handleChange,
    setFieldValue,
    isError,
    closeError,
  } = props
  const isScreeningReportAvailable =
    creditReport && reportAvailable.screeningReport && !isExpired && !isIDMAExpired
  const isIncomeVerificationReportAvailable =
    reportAvailable.incomeVerificationReport && !isIncomeVerificationExpired
  const isApplicationAvailable =
    reportAvailable.application && applicationType !== APPLICATION_TYPE.CAR_LRA
  const [currentPage, setCurrentPage] = React.useState(MODAL_PAGES.RECIPIENT)
  const [contentHeight, setContentHeight] = React.useState(0)
  const recipientComponentRef = React.useRef(null)
  const [selectedReports, setSelectedReports] = React.useState({})
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(
    getIsSubmitDisabled(values, errors),
  )
  const [isReportSelectionChanged, setIsReportSelectionChanged] = React.useState(false)
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  React.useEffect(() => {
    if (isOpen) {
      let reports = {}
      if (isEmpty(defaultShareOption)) {
        reports = {
          application: isApplicationAvailable,
          screeningReport: isScreeningReportAvailable,
          incomeVerificationReport: isIncomeVerificationReportAvailable,
          referenceCheck: reportAvailable.referenceCheck,
          otherDocs: reportAvailable.otherDocs,
        }
      } else {
        reports = {
          application: isApplicationAvailable && defaultShareOption.application,
          screeningReport: isScreeningReportAvailable && defaultShareOption.screeningReport,
          incomeVerificationReport:
            isIncomeVerificationReportAvailable && defaultShareOption.incomeVerificationReport,
          referenceCheck: reportAvailable.referenceCheck && defaultShareOption.referenceCheck,
          otherDocs: reportAvailable.otherDocs && defaultShareOption.otherDocs,
        }
      }
      const recipient = {
        email: landlordInformation?.email,
        shareWithLandlord: !!landlordInformation?.email,
        agentMessage: '',
      }
      setSelectedReports(reports)
      setFormValues({
        ...values,
        ...recipient,
        ...reports,
      })
    }
  }, [
    isScreeningReportAvailable,
    reportAvailable,
    landlordInformation,
    isIncomeVerificationReportAvailable,
    defaultShareOption,
  ])

  React.useEffect(() => {
    setIsSubmitDisabled(getIsSubmitDisabled(values, errors))
    setIsReportSelectionChanged(
      !isEmpty(
        pickBy(
          touched,
          (value, key) =>
            [
              'application',
              'screeningReport',
              'incomeVerificationReport',
              'referenceCheck',
              'otherDocs',
            ].includes(key) && value,
        ),
      ),
    )
  }, [values, errors, touched])

  React.useLayoutEffect(() => {
    if (recipientComponentRef?.current?.clientHeight) {
      setContentHeight(recipientComponentRef?.current?.clientHeight)
    }
  }, [recipientComponentRef?.current?.clientHeight])

  const handleClickClose = () => {
    setTouched({})
    setSelectedReports({})
    setIsSubmitDisabled(getIsSubmitDisabled(values, errors))
    setCurrentPage(MODAL_PAGES.RECIPIENT)
    setIsReportSelectionChanged(false)
    onClickClose()
  }

  const handleClickEditReportSelection = () => {
    setCurrentPage(MODAL_PAGES.REPORT_SELECTION)
    tracker.trackEvent(EVENT_REPORT.clickEditReportSelection, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.SHARE_REPORT_MODAL,
    })
  }

  const handleClickBackFromReportSelection = () => {
    setSelectedReports({
      application: reportAvailable.application && values.application,
      screeningReport: isScreeningReportAvailable && values.screeningReport,
      incomeVerificationReport:
        reportAvailable.incomeVerificationReport && values.incomeVerificationReport,
      referenceCheck: reportAvailable.referenceCheck && values.referenceCheck,
      otherDocs: reportAvailable.otherDocs && values.otherDocs,
    })
    setCurrentPage(MODAL_PAGES.RECIPIENT)
  }
  const handleClickSaveFromReportSelection = () => {
    const customReportOptions = {
      application: reportAvailable.application && selectedReports.application,
      screeningReport: isScreeningReportAvailable && selectedReports.screeningReport,
      incomeVerificationReport:
        reportAvailable.incomeVerificationReport && selectedReports.incomeVerificationReport,
      referenceCheck: reportAvailable.referenceCheck && selectedReports.referenceCheck,
      otherDocs: reportAvailable.otherDocs && selectedReports.otherDocs,
    }
    setFormValues({
      ...values,
      ...customReportOptions,
    })
    tracker.trackEvent(EVENT_REPORT.saveReportSelection, {
      rental_id: renterDetail.renter_id,
      applications: customReportOptions.application,
      credit_report: customReportOptions.screeningReport,
      documents: customReportOptions.otherDocs,
      reference_checks: customReportOptions.referenceCheck,
      income_verification: customReportOptions.incomeVerificationReport,
    })
    setCurrentPage(MODAL_PAGES.RECIPIENT)
  }

  const modalTitle =
    currentPage === MODAL_PAGES.REPORT_SELECTION
      ? MODAL_TITLES.EDIT_REPORT_SELECTION
      : MODAL_TITLES.SHARE_APPLICATION_PACKAGE

  const isReportsNotSelected = isEmpty(
    Object.values(selectedReports).filter(selectedReport => selectedReport),
  )
  const ModalComponent = isMobile ? ShareReportSheet : ShareReportDialog
  return (
    <form>
      <ModalComponent
        isLoading={isLoading}
        isOpen={isOpen}
        onClickClose={handleClickClose}
        onClickBack={
          currentPage === MODAL_PAGES.REPORT_SELECTION && handleClickBackFromReportSelection
        }
        onClickShare={currentPage === MODAL_PAGES.RECIPIENT && submitForm}
        onClickSave={
          currentPage === MODAL_PAGES.REPORT_SELECTION && handleClickSaveFromReportSelection
        }
        title={modalTitle}
        isShareButtonDisabled={isSubmitDisabled}
        isSaveButtonDisabled={isReportsNotSelected || !isReportSelectionChanged}
      >
        {currentPage === MODAL_PAGES.REPORT_SELECTION ? (
          <ReportSelection
            minHeight={contentHeight}
            screeningOption={screeningOption}
            reportAvailable={reportAvailable}
            isExpired={isExpired}
            isIDMAExpired={isIDMAExpired}
            expiredDate={expiredDate}
            selectedReports={selectedReports}
            setSelectedReports={setSelectedReports}
            isScreeningReportAvailable={isScreeningReportAvailable}
            isIncomeVerificationReportAvailable={isIncomeVerificationReportAvailable}
            isIncomeVerificationExpired={isIncomeVerificationExpired}
            isApplicationAvailable={isApplicationAvailable}
            handleBlur={handleBlur}
            setTouched={setTouched}
            touched={touched}
          />
        ) : (
          <Recipient
            ref={recipientComponentRef}
            renter={renter}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            landlordInformation={landlordInformation}
            handleClickEditReportSelection={handleClickEditReportSelection}
            isError={isError}
            closeError={closeError}
            isReportsNotSelected={isReportsNotSelected}
          />
        )}
      </ModalComponent>
    </form>
  )
}

export const ShareReportFormV2 = withFormik({
  mapPropsToValues: props => shareReportFormSchema(props).mapper,
  validationSchema: props => shareReportFormSchema(props).validation,
  handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: 'ShareReportForm',
})(Form)
