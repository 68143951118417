import React from 'react'
import { compose } from 'redux'
import { Switch, Route } from 'react-router-dom'
import AppDetailContainer from 'legacy/containers/application/application-detail'

import {
  TENANT_SCREENING_DETAIL,
  TENANT_SCREENING_PENDING,
  TENANT_SCREENING_WITH_RENTAL_ID,
} from 'constants/route'
import { useAgreementAccepted } from 'hooks'
import { AGREEMENT_TYPE } from 'containers/agreement/constants'
import { withAgreementReducerAndSaga } from 'containers/agreement/connect'
import { AbilityContext } from 'containers/reports/with-pro-report-available/ability-context'
import { ability } from 'containers/reports/with-pro-report-available/ability'
import { withReducer, withSaga } from './connect'
import TenantScreeningDetail from './loadable'
import TenantScreeningPending from './pending'
import { useGetSubmissionParticipantEffect } from '../hooks'

export const TenantScreeningDetailRoute = ({ match, history, location }) => {
  const { submissionParticipantId } = match.params

  useGetSubmissionParticipantEffect(submissionParticipantId)
  useAgreementAccepted(AGREEMENT_TYPE.TU)

  return (
    <Switch>
      <Route
        exact
        path={TENANT_SCREENING_DETAIL}
        render={renderProps => <TenantScreeningDetail {...renderProps} />}
      />
      <Route
        path={TENANT_SCREENING_WITH_RENTAL_ID}
        render={() => (
          <AbilityContext.Provider value={ability}>
            <AppDetailContainer history={history} location={location} match={match} />
          </AbilityContext.Provider>
        )}
      />
      <Route path={TENANT_SCREENING_PENDING}>
        <TenantScreeningPending history={history} />
      </Route>
    </Switch>
  )
}

export default compose(
  withSaga,
  withReducer,
  withAgreementReducerAndSaga,
)(TenantScreeningDetailRoute)
