import generateApiCall from 'utils/generate-api-call'

import {
  GET_OR_CREATE_RENTER_INFO,
  GET_OR_CREATE_RENTER_INFO_FAILED,
  GET_OR_CREATE_RENTER_INFO_REQUESTED,
  GET_OR_CREATE_RENTER_INFO_SUCCEEDED,
} from './constants'

export const getOrCreateRenterInfo = payload => ({
  type: GET_OR_CREATE_RENTER_INFO,
  payload,
})

export const getOrCreateRenterInfoAPI = generateApiCall([
  GET_OR_CREATE_RENTER_INFO_REQUESTED,
  GET_OR_CREATE_RENTER_INFO_SUCCEEDED,
  GET_OR_CREATE_RENTER_INFO_FAILED,
])
