import { useQuery } from '@tanstack/react-query'
import { getUserPlan } from 'utils/get-user-plan'

export const querySubscriptionData = () => {
  const userSubscriptionData = useQuery({
    queryKey: ['subscription_plan_v2'],
    queryFn: getUserPlan,
  })

  return userSubscriptionData
}
