import React from "react"
import styled from "styled-components"
import { COLOR } from "../../styles/settings"

export const Checkbox = ({ label, id, ...props }) => (
  <CheckboxWrap>
    <StyledCheckbox type="checkbox" id={id} {...props} />
    {label ? (
      <StyledLabel id={`${id}Label`} htmlFor={id}>
        {label}
      </StyledLabel>
    ) : (
      <StyledLabel id={`${id}Label`} htmlFor={id} noLabel />
    )}
  </CheckboxWrap>
)

const CheckboxWrap = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// language=SCSS prefix=dummy{ suffix=}
const StyledCheckbox = styled.input`
  display: none;
  &:checked + label {
    &::before {
      background: ${COLOR.deepBlue};
      border: 1px solid ${COLOR.deepBlue};
    }
    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }
  &:disabled + label {
    &::before {
      cursor: not-allowed;
      background: ${COLOR.lineGrey} !important;
    }
    &::after {
      display: none;
    }
  }

  &.checkMinus + label {
    &::before {
      background: ${COLOR.white};
      border: 1px solid ${COLOR.placeholderGrey};
    }
    &::after {
      content: "\f068";
      font-weight: bold;
      font-size: 10px;
      top: 3px;
      left: 3px;
      color: ${COLOR.deepBlue};
    }
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const StyledLabel = styled.label`
  margin: 0;
  position: relative;
  cursor: pointer;
  font-weight: normal;
  height: 100%;
  width: 20px;
  &:before {
    content: " ";
    position: absolute;
    left: ${props => (props.noLabel ? "2px" : "-25px")};
    top: 2px;
    width: 16px;
    height: 16px;
    display: block;
    background: ${COLOR.white};
    border: 1px solid ${COLOR.placeholderGrey};
    border-radius: 2px;
    transition: all 0.3s ease;
  }
  &:after {
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    font-size: 13px;
    font-weight: 900;
    position: absolute;
    top: 2px;
    left: ${props => (props.noLabel ? "3px" : "-25px")};
    width: 15px;
    height: 15px;
    display: block;
    z-index: 1;
    color: white;
    transition: all 0.3s ease;
    transform: scale(0);
    opacity: 0;
  }
`
