import React from 'react'
import Styled from 'styled-components'

import BooleanCardV2 from 'components/molecules/boolean-card-v2'
import RadioImage from 'components/atoms/radio-image'
import {
  APPLICATION_TYPE,
  APPLICATION_SECTION_TEXT,
} from 'containers/tenant-screening/request-v2.2/constants'

const ApplicationTypeBox = props => {
  const { boxControl, requestOptions, handleSelectApplication } = props
  const { applicationType } = requestOptions

  const ApplicationTypeWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
  `

  return (
    <ApplicationTypeWrapper>
      <BooleanCardV2
        id="RentspreeApplicationBooleanCard"
        data-testid="rentspree-application-boolean-card"
        checked={applicationType === APPLICATION_TYPE.RENTSPREE}
        isHidden={!boxControl.enabledItems.standard}
        title={APPLICATION_SECTION_TEXT.application.title}
        icon={RadioImage}
        ascent=""
        onClick={() => {
          handleSelectApplication(APPLICATION_TYPE.RENTSPREE)
        }}
      />
      <BooleanCardV2
        id="CARApplicationBooleanCard"
        data-testid="CAR-application-boolean-card"
        checked={applicationType === APPLICATION_TYPE.CAR_LRA}
        isHidden={!boxControl.enabledItems.CAR}
        title={APPLICATION_SECTION_TEXT.car_lra.title}
        subtitle={APPLICATION_SECTION_TEXT.car_lra.subTitle}
        icon={RadioImage}
        ascent=""
        onClick={() => {
          handleSelectApplication(APPLICATION_TYPE.CAR_LRA)
        }}
      />
      <BooleanCardV2
        id="NoApplicationBooleanCard"
        data-testid='no-application-boolean-card'
        checked={applicationType === APPLICATION_TYPE.NO_APPLICATION}
        isHidden={!boxControl.enabledItems.noApplication}
        title={APPLICATION_SECTION_TEXT.no_application.title}
        subtitle={APPLICATION_SECTION_TEXT.no_application.subTitle}
        icon={RadioImage}
        ascent=""
        onClick={() => {
          handleSelectApplication(APPLICATION_TYPE.NO_APPLICATION)
        }}
      />
    </ApplicationTypeWrapper>
  )
}

export default ApplicationTypeBox
