import { takeLatest, put, call } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "utils/api-interceptor"
import { TYPES, API_GET_NEW_BADGE } from "./constants"
import {
  getNewBadgeRequest,
  getNewBadgeSuccess,
  getNewBadgeFailed,
} from "./actions"

export const getNewBadgeAPI = query =>
  apiInstance.get(buildPath(API_GET_NEW_BADGE, {}, query))

export function* getBadge({ payload }) {
  let response
  yield put(getNewBadgeRequest())
  try {
    response = yield call(getNewBadgeAPI, payload)
    yield put(getNewBadgeSuccess(response))
  } catch (err) {
    yield put(getNewBadgeFailed())
  }
}

export default function* rootSaga() {
  yield takeLatest(TYPES.CALL_GET_NEW_BADGE, getBadge)
}
