import get from "lodash/get"
import { createSelector } from "reselect"
import { initialState } from "./reducer"

export const selectTogglePropertyFeatureModal = state =>
  get(state, "property.togglePropertyFeatureModal", initialState)

export const selectToggleType = createSelector(
  selectTogglePropertyFeatureModal,
  modal => get(modal, "type", ""),
)

export const selectFeature = createSelector(
  selectTogglePropertyFeatureModal,
  modal => get(modal, "feature", ""),
)

export const selectIsOpen = createSelector(
  selectToggleType,
  selectFeature,
  (type, feature) => !!type && !!feature,
)

export const selectIsLoading = createSelector(
  selectTogglePropertyFeatureModal,
  modal => get(modal, "isLoading", false),
)

export const selectSource = createSelector(
  selectTogglePropertyFeatureModal,
  modal => get(modal, "source", ""),
)

export const selectDisabledPropertyFeatures = state =>
  get(state, "property.property.disabledFeatures", {})
