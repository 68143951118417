import get from 'lodash/get'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

export const getModalState = state => get(state, 'inviteLandlordRentPaymentModal', initialState)

export const selectIsOpen = createSelector(getModalState, state => state.isOpen)
export const selectIsSubmitting = createSelector(getModalState, state => state.isSubmitting)
export const selectPropertyId = createSelector(getModalState, state => state.propertyId)

export const selectLandlordInformation = createSelector(getModalState, state => state.landlordInformation)
