import React from 'react'

import Button from '@rentspree/component-2023.components.atoms.button'
import { ACCEPT_DENY_TYPE, CLICK_FROM } from 'containers/accept-deny/constants'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { NEXT_ACTION_TYPES } from 'containers/tenant-screening/assign-property/constants'
import { buildPath } from '@rentspree/path'
import {
  ESIGN_DASHBOARD_SELECT_METHODS,
  PRINT_REPORTS_PAGE,
  PRINT_REPORTS_RENTER_PAGE,
} from 'constants/route'
import tracker from 'tracker'
import { LEASE_AGREEMENTS } from 'tracker/const'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import { withConnect } from './connect'
import { ButtonGroup, StickyFooterContainer, StickyFooterWrapper } from './styled'
import { isAllowAcceptDeny } from '../helper'

const ContentButtonGroup = ({
  result,
  isMobile,
  isAllowSetupLease,
  isDisplayAcceptDeny,
  isDisplaySetupLease,
  onClickSetUpLease,
  onClickOpenAcceptDenyModal,
}) => {
  const buttonSize = isMobile ? 'medium' : 'small'
  const getAcceptDenyButtons = () => {
    if (result) {
      return [
        {
          label: 'Change decision',
          onClick: () => onClickOpenAcceptDenyModal(result),
          variant: 'outlined',
          'data-testid': 'change-decision-button',
        },
      ]
    }
    return [
      {
        label: 'Decline',
        onClick: () => onClickOpenAcceptDenyModal(ACCEPT_DENY_TYPE.DENY),
        variant: 'outlined',
        'data-testid': 'decline-button',
      },
      {
        label: 'Accept',
        onClick: () => onClickOpenAcceptDenyModal(ACCEPT_DENY_TYPE.ACCEPT),
        variant: 'contained',
        color: 'secondary',
        'data-testid': 'accept-button',
      },
    ]
  }
  return (
    <ButtonGroup isMobile={isMobile}>
      {isDisplaySetupLease && (
        <Button
          onClick={onClickSetUpLease}
          variant="text"
          size={buttonSize}
          color="secondary"
          className="underline"
          disabled={!isAllowSetupLease}
          data-testid="set-up-lease-button"
        >
          Set up lease
        </Button>
      )}

      {isDisplayAcceptDeny &&
        getAcceptDenyButtons().map(props => (
          <Button
            key={props.label}
            size={buttonSize}
            variant={props.variant}
            onClick={props.onClick}
            data-testid={props['data-testid']}
            color={props.color}
          >
            {props.label}
          </Button>
        ))}
    </ButtonGroup>
  )
}

export const ReportBottomBar = props => {
  const {
    isMobile,
    rentalDetail,
    isAllowCarLease,
    isShareAvailable,
    acceptDenyPayload,
    openAcceptDenyModal,
    openAssignPropertyModal,
    submissionParticipantId,
    match,
  } = props
  const path = get(match, 'path', '')
  const isPrintPage =
    path.includes(`${PRINT_REPORTS_PAGE}/:type`) ||
    path.includes(`${PRINT_REPORTS_RENTER_PAGE}/:type`)

  const { agentType, property } = rentalDetail || {}
  const propertyId = get(property, '_id')

  const onClickSetUpLease = () => {
    tracker.trackEvent(LEASE_AGREEMENTS.CREATE_LEASE, { click_from: CLICK_FROM.APPLICATION_DETAIL })
    if (propertyId) {
      const url = buildPath(ESIGN_DASHBOARD_SELECT_METHODS, { propertyId })
      window.location.assign(url)
    }
  }

  const onClickOpenAcceptDenyModal = (acceptDenyType, dropdownButtonEventName) => {
    const acceptDenyProps = {
      rentalSubmission: acceptDenyPayload,
      propertyId,
      propertyAddress: property,
      isSharedReport: !isShareAvailable,
      acceptDenyType,
      clickFrom: CLICK_FROM.APPLICATION_DETAIL,
      dropdownButtonEventName,
      screeningResult: rentalDetail?.screeningResult?.result,
    }

    if (property) {
      openAcceptDenyModal({ ...acceptDenyProps })
    } else {
      openAssignPropertyModal({
        participantId: submissionParticipantId,
        nextAction: NEXT_ACTION_TYPES.ACCEPT_DENY,
        nextActionProps: acceptDenyProps,
      })
    }
  }

  const screeningResult = rentalDetail?.screeningResult?.result
  const isDisplayAcceptDeny = isAllowAcceptDeny(agentType)
  const isDisplaySetupLease = isAllowCarLease
  const isAllowSetupLease = !!property && screeningResult !== ACCEPT_DENY_TYPE.DENY

  if (isPrintPage) return null

  return (
    <StickyFooterTemplate position="fixed" variant="navigation">
      <StickyFooterWrapper>
        <StickyFooterContainer>
          <ContentButtonGroup
            isMobile={isMobile}
            result={screeningResult}
            isAllowSetupLease={isAllowSetupLease}
            isDisplaySetupLease={isDisplaySetupLease}
            isDisplayAcceptDeny={isDisplayAcceptDeny}
            onClickSetUpLease={onClickSetUpLease}
            onClickOpenAcceptDenyModal={onClickOpenAcceptDenyModal}
          />
        </StickyFooterContainer>
      </StickyFooterWrapper>
    </StickyFooterTemplate>
  )
}

export default compose(withRouter, withConnect)(ReportBottomBar)
