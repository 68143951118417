import get from 'lodash/get'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { checkRentalPaymentExists } from '../redux/actions/check-rental-payment-exists.action'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'

export const useCheckRentalPaymentExists = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const result = useSelector(state =>
    get(state, `${KEY.RENTAL_PAYMENT}.rentalPaymentExists.data.result`),
  )
  const count = useSelector(state =>
    get(state, `${KEY.RENTAL_PAYMENT}.rentalPaymentExists.data.count`, 0),
  )
  const status = useSelector(state =>
    get(state, `${KEY.RENTAL_PAYMENT}.rentalPaymentExists.status`),
  )

  React.useEffect(() => {
    dispatch(checkRentalPaymentExists())
  }, [])

  return {
    result,
    count,
    status,
    checkRentalPaymentExists: () => dispatch(checkRentalPaymentExists()),
  }
}
