import React from 'react'
import B14 from '@rentspree/component-v2/dist/typography/b14'
import { generateAddress } from '@rentspree/helper'

import { Td } from 'components/table/table-style'
import { COLOR } from 'styles/settings'
import styled from 'styled-components'
import { CREATE_PROPERTY, NO_SPECIFIC_PROPERTY } from './constants'

export const TextEllipsis = styled(B14)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PropertyColumn = styled(Td)`
  max-width: 416px;
`

export const renderProperty = ({ status, propertyAddress }) => {
  const address = (propertyAddress && generateAddress(propertyAddress)) || ''
  return (
    <>
      {status === 'property_required' ? (
        <B14 weight="600" color={COLOR.fontBlue} margin="0">
          {CREATE_PROPERTY}
        </B14>
      ) : (
        <TextEllipsis color={COLOR.textBlack} margin="0" title={address}>
          {address || NO_SPECIFIC_PROPERTY}
        </TextEllipsis>
      )}
    </>
  )
}

const ColumnProperty = ({ status, propertyAddress }) => (
  <PropertyColumn className="text-left">
    {renderProperty({ status, propertyAddress })}
  </PropertyColumn>
)

export default ColumnProperty
