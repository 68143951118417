import produce from "immer"
import {
  GET_RENTER_INSURANCE_LIST_REQUEST,
  GET_RENTER_INSURANCE_LIST_SUCCESS,
  GET_RENTER_INSURANCE_LIST_FAILURE,
  CLEAR_RENTER_INSURANCE_LIST_CALL,
} from "./constants"

export const initialState = {
  renterInsuranceProp: "renterInsurance",
  isFetching: false,
  pagination: {},
  renterInsuranceList: [],
  isError: false,
}

/* eslint-disable default-case, no-param-reassign */
const renterInsuranceReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case CLEAR_RENTER_INSURANCE_LIST_CALL:
        draft.renterInsuranceList = []
        draft.isError = false
        break
      case GET_RENTER_INSURANCE_LIST_REQUEST:
        draft.isFetching = true
        break
      case GET_RENTER_INSURANCE_LIST_SUCCESS:
        draft.isFetching = false
        draft.renterInsuranceList = payload.data
        draft.pagination = payload.pagination
        draft.isError = false
        break
      case GET_RENTER_INSURANCE_LIST_FAILURE:
        draft.isFetching = false
        draft.isError = true
        break
    }
    return draft
  })

export default renterInsuranceReducer
