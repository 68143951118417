export const BANK_ACCOUNT_TYPES = {
  PERSONAL_SAVING: 'personal_saving',
  PERSONAL_CHECKING: 'personal_checking',
  BUSINESS_SAVING: 'business_saving',
  BUSINESS_CHECKING: 'business_checking',
  OTHER: 'other',
}

export const BANK_ACCOUNT_TYPE_TO_WORDING = {
  [BANK_ACCOUNT_TYPES.PERSONAL_SAVING]: 'Personal Saving',
  [BANK_ACCOUNT_TYPES.PERSONAL_CHECKING]: 'Personal Checking',
  [BANK_ACCOUNT_TYPES.BUSINESS_SAVING]: 'Business Saving',
  [BANK_ACCOUNT_TYPES.BUSINESS_CHECKING]: 'Business Checking',
}

export const RENTAL_APPLICATION_VERSION = {
  RENTAL_APPLICATION_3_0: '3.0',
  RENTAL_APPLICATION_3_1: '3.1',
  RENTAL_APPLICATION_3_2: '3.2',
  RENTAL_APPLICATION_3_3: '3.3',
  RENTAL_APPLICATION_3_4: '3.4',
}

export const RENTSPREE_APPLICATION_AVAIABLE_VERSIONS = ['2.5', '3.0', '3.1', '3.2', '3.3', '3.4']
export const CAR_APPLICATION_AVAIABLE_VERSIONS = ['1.0']

export const APPLICANT_TYPE = {
  tenant: {
    value: 'tenant',
    text: 'Tenant',
  },
  guarantor: {
    value: 'guarantor',
    text: 'Guarantor / Co-signer',
  },
}

export const MISCELLANEOUS = {
  TEXT: {
    LIQUID: 'LIQUID',
    UNLAWFUL: 'UNLAWFUL',
    MOVE_OUT: 'MOVE_OUT',
    BANKRUPTCY: 'BANKRUPTCY',
    FELONY: 'FELONY',
  },
  STATUS: {
    NO: 'no',
    YES: 'yes',
  },
  LIQUID: {
    YES: 'I plan to use liquid-filled furniture',
    NO: "I don't plan to use liquid-filled furniture",
  },
  UNLAWFUL: {
    YES: 'I have been a party to an unlawful detainer action or filed bankruptcy within the last seven years.',
    NO: "I haven't been a party to an unlawful detainer action or filed bankruptcy within the last seven years.",
  },
  MOVE_OUT: {
    YES: 'I have been asked to move out of a residence.',
    NO: 'I haven’t been asked to move out of a residence.',
  },
  BANKRUPTCY: {
    YES: 'I have filed bankruptcy within the last seven years.',
    NO: "I haven't filed bankruptcy within the last seven years.",
  },
  FELONY: {
    YES: 'I have been convicted of or pleaded no contest to a felony.',
    NO: "I haven't been convicted of or pleaded no contest to a felony.",
  },
}

export const HOUSING_TYPE = {
  rental: {
    value: 'rental',
    text: 'Rental Property',
  },
  owner: {
    value: 'owner',
    text: 'Owner Occupied',
  },
  none: {
    value: 'none',
    text: 'None',
  },
}
export const RESIDENCE_TYPE = {
  current: {
    value: 'current',
    text: 'Current Residence',
  },
  previous: {
    value: 'previous',
    text: 'Previous Residence',
  },
}
