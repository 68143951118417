import { createInstance } from "@rentspree/feature-toggle"
import { CDN_URL, API_URL } from "env"

const disableFeature = createInstance({
  BASE_URL: `${API_URL}/api/v2`,
  CDN_URL,
  timeout: 5000,
})

export default disableFeature
