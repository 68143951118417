import React from 'react'
import styled from 'styled-components'
import KebabMenu from '../kebab-menu'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FileNameContainer = styled.p`
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  margin: 10px 0;

  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const FileName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const FileType = styled.span`
  padding-right: 25px;
  white-space: nowrap;
`

const KebabMenuContainer = styled.div`
  > div {
    position: static;
  }
`

const getFileType = fileName => fileName?.split('.').pop()

const getFileNameWithoutType = fileName => fileName?.split('.').slice(0, -1).join('.')

export const DocumentFileItem = ({ fileOriginalName, fileMenu }) => {
  const fileName = getFileNameWithoutType(fileOriginalName)
  const fileType = getFileType(fileOriginalName)

  return (
    <Container>
      <FileNameContainer>
        <FileName>{fileName}</FileName>
        <FileType>.{fileType}</FileType>
      </FileNameContainer>
      <KebabMenuContainer>
        <KebabMenu menuItems={fileMenu} />
      </KebabMenuContainer>
    </Container>
  )
}
