import { TOGGLE_ACCEPTING_STATUS_API, GET_APPLY_LINK_API } from "constants/route"
import { put, call, all, takeLatest } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstanceWithErrorHandler } from "utils/api-interceptor"

import { toggleListingStatus,
  toggleAcceptingStatusActions,
  getApplyLinkActions,
} from "./actions"
import { TOGGLE_ACCEPTING_STATUS_CALL, GET_APPLY_LINK_CALL } from "./constants"

const toggleStatus = disable => (disable ? "disable" : "enable")

export const callToggleAcceptingStatusApi = ({ propertyId, disable }) =>
  apiInstanceWithErrorHandler.put(
    buildPath(TOGGLE_ACCEPTING_STATUS_API, {
      propertyId,
      status: toggleStatus(disable),
    }),
  )

export const callGetApplyLinkAPI = ({ screeningRequestId }) =>
  apiInstanceWithErrorHandler.get(buildPath(GET_APPLY_LINK_API, { screeningRequestId }))

export function* createToggleAcceptingStatusSaga({ payload }) {
  const { propertyId, disable } = payload
  yield put(toggleAcceptingStatusActions.request())
  try {
    const response = yield call(callToggleAcceptingStatusApi, {
      propertyId,
      disable,
    })
    yield put(toggleAcceptingStatusActions.success(response))
    yield put(toggleListingStatus(disable))
  } catch (err) {
    yield put(toggleAcceptingStatusActions.failure(err.data))
  }
}

export function* getApplyLinkSaga({ payload }) {
  yield put(getApplyLinkActions.request())
  try {
    const response = yield call(callGetApplyLinkAPI, {
      screeningRequestId: payload.screeningRequestId,
    })
    yield put(getApplyLinkActions.success(response))
  } catch (err) {
    yield put(getApplyLinkActions.failure())
  }
}

export function* watchToggleAcceptingStatus() {
  yield takeLatest(
    TOGGLE_ACCEPTING_STATUS_CALL,
    createToggleAcceptingStatusSaga,
  )
}

export function* watchGetApplyLink() {
  yield takeLatest(
    GET_APPLY_LINK_CALL,
    getApplyLinkSaga,
  )
}

export function* rootSaga() {
  yield all([watchToggleAcceptingStatus(), watchGetApplyLink()])
}

export default rootSaga
