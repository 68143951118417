import { API_URL, USER_API_URL } from 'env'

export const GET_SCREENING_PLANS_CALL = 'GET_SCREENING_PLANS_CALL'

export const GET_SCREENING_PLANS_REQUEST = 'GET_SCREENING_PLANS_REQUEST_TENANT_SCREENING'
export const GET_SCREENING_PLANS_SUCCESS = 'GET_SCREENING_PLANS_SUCCESS_TENANT_SCREENING'
export const GET_SCREENING_PLANS_FAILURE = 'GET_SCREENING_PLANS_FAILURE_TENANT_SCREENING'

export const GET_SCREENING_PLANS_API = `${API_URL}/api/v2/screening-plans`

export const GET_USER_PREFERENCE_CALL = 'GET_USER_PREFERENCE_CALL'

export const GET_USER_PREFERENCE_REQUEST = 'GET_USER_PREFERENCE_REQUEST_TENANT_SCREENING'
export const GET_USER_PREFERENCE_SUCCESS = 'GET_USER_PREFERENCE_SUCCESS_TENANT_SCREENING'
export const GET_USER_PREFERENCE_FAILURE = 'GET_USER_PREFERENCE_FAILURE_TENANT_SCREENING'

export const GET_USER_PREFERENCE_API = `${USER_API_URL}/api/v2/user/me/preference`

export const SELECT_APPLICATION = 'SELECT_APPLICATION'
export const SELECT_CREDIT_REPORT = 'SELECT_CREDIT_REPORT'
export const SELECT_CRIMINAL = 'SELECT_CRIMINAL'
export const SELECT_EVICTION = 'SELECT_EVICTION'

export const SELECT_PAYER_TYPE = 'SELECT_PAYER_TYPE'

export const CREATE_SCREENING_REQUEST_API = `${API_URL}/api/v2/agent/screening-requests`
export const CREATE_SCREENING_REQUEST_CALL = 'CREATE_SCREENING_REQUEST_CALL'
export const CREATE_SCREENING_REQUEST_REQUEST = 'CREATE_SCREENING_REQUEST_REQUEST'
export const CREATE_SCREENING_REQUEST_SUCCESS = 'CREATE_SCREENING_REQUEST_SUCCESS'
export const CREATE_SCREENING_REQUEST_FAILURE = 'CREATE_SCREENING_REQUEST_FAILURE'

export const ACCEPT_BACKGROUND_DISCLAIMER = 'ACCEPT_BACKGROUND_DISCLAIMER'

export const UPDATE_DOCUMENT_PRO_OPTION = 'UPDATE_DOCUMENT_PRO_OPTION'
export const UPDATE_REF_CHECK_PRO_OPTION = 'UPDATE_REF_CHECK_PRO_OPTION'
export const CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS =
  'CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS'

export const AGENT_TYPES = {
  LISTING_AGENT: 'listing_agent',
  RENTER_AGENT: 'renter_agent',
  LANDLORD: 'landlord',
}

export const TENANT_SCREENING_FLOW_ORIGIN = {
  SELECT_AGENT: 'selectAgent',
  SELECT_PROPERTY: 'selectProperty',
}
