import Button from '@rentspree/component-v2/dist/button'
import { RedGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import B16 from '@rentspree/component-v2/dist/typography/b16'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { breakpoints } from 'styles/settings'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  ${breakpoints.mobile(`
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 0 25px 20px;
  `)};
`

export const CancelButton = styled(Button)`
  margin-right: 10px;
`

const DeleteTagConfirmationBox = ({ onClickConfirmDeleteTag, onClickCancel, isLoading }) => (
  <div>
    <B16>The tag will be deleted from all contacts associated with it.</B16>
    <ButtonContainer>
      <CancelButton
        small
        google
        text="Cancel"
        minWidth="110"
        type="button"
        onClick={onClickCancel}
      />
      <RedGradientButton
        small
        id="delete-button"
        text="Delete Tag"
        minWidth="110"
        type="button"
        loading={isLoading}
        onClick={onClickConfirmDeleteTag}
      />
    </ButtonContainer>
  </div>
)

DeleteTagConfirmationBox.defaultProps = {
  isLoading: false,
}

DeleteTagConfirmationBox.propTypes = {
  isLoading: PropTypes.bool,
  onClickCancel: PropTypes.func.isRequired,
  onClickConfirmDeleteTag: PropTypes.func.isRequired,
}

export default DeleteTagConfirmationBox
