import { BlueGradientButton, Button } from '@rentspree/component-v2'
import B18 from '@rentspree/component-v2/dist/typography/b18'
import React from 'react'
import styled from 'styled-components'

import { WidgetTitle } from 'components/contacts/atoms/widget'

const CancelConfirmWrapper = styled.div`
  text-align: center;
`
const B12 = styled(B18)`
  font-size: 12px;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  > button {
    width: 125px;
    height: 24px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    margin: 4px;
  }
`

const CancelWidget = ({ onImport, onConfirmCancel }) => (
  <CancelConfirmWrapper>
    <WidgetTitle>Cancel Import</WidgetTitle>
    <B12>Some completed contacts will be imported, only unfinished will be cancelled.</B12>
    <StyledButtonWrapper>
      <Button google text="Continue import" onClick={onImport} />
      <BlueGradientButton sourceSansPro text="Confirm" onClick={onConfirmCancel} />
    </StyledButtonWrapper>
  </CancelConfirmWrapper>
)

export default CancelWidget
