import produce from "immer"
import {
  ACCEPT_AGREEMENT_REQUEST,
  ACCEPT_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT_FAILURE,
  GET_ACCEPT_AGREEMENT_REQUEST,
  GET_ACCEPT_AGREEMENT_SUCCESS,
  GET_ACCEPT_AGREEMENT_FAILURE,
  GET_AGREEMENT_CONTENT_REQUEST,
  GET_AGREEMENT_CONTENT_SUCCESS,
  GET_AGREEMENT_CONTENT_FAILURE,
  CLEAR_AGREEMENT_ACCEPT_STATUS,
} from "./constants"

export const initialState = {
  isAccepted: null,
  isReConsent: null,
  isFetchingIsAccepted: true,
  isFetchingContent: true,
  errors: null,
  data: {},
}

/* eslint-disable default-case, no-param-reassign */
export const agreementReducer = (
  state = initialState,
  { type, payload = {} },
) =>
  produce(state, draft => {
    switch (type) {
      case GET_ACCEPT_AGREEMENT_REQUEST:
      case ACCEPT_AGREEMENT_REQUEST:
        draft.isFetchingIsAccepted = true
        draft.errors = null
        break
      case GET_AGREEMENT_CONTENT_REQUEST:
        draft.isFetchingContent = true
        draft.errors = null
        break
      case GET_ACCEPT_AGREEMENT_SUCCESS:
        draft = {
          ...draft,
          ...payload,
          isFetchingIsAccepted: false,
        }
        break
      case ACCEPT_AGREEMENT_SUCCESS:
        draft.isAccepted = true
        draft.isFetchingIsAccepted = false
        break
      case GET_ACCEPT_AGREEMENT_FAILURE:
      case ACCEPT_AGREEMENT_FAILURE:
        draft.isFetchingIsAccepted = false
        draft.errors = payload
        break
      case GET_AGREEMENT_CONTENT_FAILURE:
        draft.isFetchingContent = false
        draft.errors = payload
        break
      case GET_AGREEMENT_CONTENT_SUCCESS:
        draft.isFetchingContent = false
        draft.data = payload
        break
      case CLEAR_AGREEMENT_ACCEPT_STATUS:
        draft = initialState
        break
      default:
        break
    }
    return draft
  })

export default agreementReducer
