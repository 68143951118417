import { useSelector } from "react-redux"
import { matchPath } from "react-router-dom"
import get from "lodash/get"

export const useParams = path => {
  const pathname = useSelector(state =>
    get(state, "router.location.pathname", ""),
  )

  return get(matchPath(pathname, path), "params", {})
}
