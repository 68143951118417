import generateApiCall from 'utils/generate-api-call'

import {
  CONFIRM_DRAFT_RENTAL_PAYMENT,
  CONFIRM_DRAFT_RENTAL_PAYMENT_FAILED,
  CONFIRM_DRAFT_RENTAL_PAYMENT_REQUESTED,
  CONFIRM_DRAFT_RENTAL_PAYMENT_SUCCEEDED,
  CLEAR_CONFIRM_DRAFT_RENTAL_PAYMENT_STATUS,
} from './constants'

export const confirmDraftRentalPayment = payload => ({
  type: CONFIRM_DRAFT_RENTAL_PAYMENT,
  payload,
})

export const clearConfirmationStatus = () => ({
  type: CLEAR_CONFIRM_DRAFT_RENTAL_PAYMENT_STATUS,
})

export const confirmDraftRentalPaymentAPI = generateApiCall([
  CONFIRM_DRAFT_RENTAL_PAYMENT_REQUESTED,
  CONFIRM_DRAFT_RENTAL_PAYMENT_SUCCEEDED,
  CONFIRM_DRAFT_RENTAL_PAYMENT_FAILED,
])
