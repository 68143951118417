import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { GET_OR_CREATE_RENTER_INFO_API } from './constants'

export const getOrCreateRenterInfoInstance = ({ firstName, lastName, email, phone }) =>
  apiInstanceWithErrorHandler.post(buildPath(GET_OR_CREATE_RENTER_INFO_API), {
    renterInfo: {
      firstName,
      lastName,
      email,
      phone,
    },
  })
