import React from "react"
import { withRouter } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import { compose } from "redux"
import { query } from "@rentspree/path"
import { Loading, CenterContent } from "components/layout/main"
import { FailedToFetch } from "legacy/components/layout/failed-to-fetch"
import * as ERRORS from "legacy/constants/error-messages"
import { checkRead, isLastUpdatedExpired } from "legacy/helpers/pro-authorize"
import { withConnect, withSaga } from "./connect"

export const Subscription = ({
  location,
  getSubscriptions,
  feature,
  render,
  authorize,
  isFetching,
  errorSubscription,
  user,
}) => {
  const queryString = query.parse(location.search)
  // check timestamp get from auth api longer than 30m or not
  // check if uid in the authorize is still match to the current user.
  const isAuthStillValid = !isLastUpdatedExpired(authorize, user)

  React.useEffect(() => {
    if (
      !isFetching &&
      (!isAuthStillValid || (queryString && queryString.payment))
    ) {
      getSubscriptions(queryString.payment || false)
    }
  }, [])

  const hasAuthToRead = checkRead(authorize, true, feature)

  if (!isEmpty(errorSubscription)) {
    return (
      <FailedToFetch
        withBreadcrumb
        withFilter
        className="-w100"
        title={ERRORS.SUBSCRIPTIONS.AUTHORIZED.TITLE}
        text={ERRORS.SUBSCRIPTIONS.AUTHORIZED.MESSAGE}
      />
    )
  }
  if (isFetching) {
    return (
      <CenterContent withBreadcrumb className="-w100">
        <Loading />
      </CenterContent>
    )
  }
  const isSubscriber = isAuthStillValid && hasAuthToRead
  return render({
    isSubscriber,
  })
}

export default compose(
  withRouter,
  withSaga,
  withConnect,
)(Subscription)
