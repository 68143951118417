export const OPEN_CREATE_ASSOCIATION_CONTACT_MODAL = 'OPEN_CREATE_ASSOCIATION_CONTACT_MODAL'
export const CLOSE_CREATE_ASSOCIATION_CONTACT_MODAL = 'CLOSE_CREATE_ASSOCIATION_CONTACT_MODAL'

export const OPEN_EDIT_ASSOCIATION_CONTACT_MODAL = 'OPEN_EDIT_ASSOCIATION_CONTACT_MODAL'
export const CLOSE_EDIT_ASSOCIATION_CONTACT_MODAL = 'CLOSE_EDIT_ASSOCIATION_CONTACT_MODAL'

export const CREATE_ASSOCIATION_CONTACT_FROM_MODAL_CALL =
  'CREATE_ASSOCIATION_CONTACT_FROM_MODAL_CALL'
export const CREATE_ASSOCIATION_CONTACT_FROM_MODAL_REQUEST =
  'CREATE_ASSOCIATION_CONTACT_FROM_MODAL_REQUEST'
export const CREATE_ASSOCIATION_CONTACT_FROM_MODAL_SUCCESS =
  'CREATE_ASSOCIATION_CONTACT_FROM_MODAL_SUCCESS'
export const CREATE_ASSOCIATION_CONTACT_FROM_MODAL_FAILURE =
  'CREATE_ASSOCIATION_CONTACT_FROM_MODAL_FAILURE'

export const EDIT_ASSOCIATION_CONTACT_FROM_MODAL_CALL = 'EDIT_ASSOCIATION_CONTACT_FROM_MODAL_CALL'

export const CREATE_ASSOCIATION_CONTACT_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'New contact created.',
  status: 'success',
  timeOut: 5000,
  width: '350px',
}

export const CREATE_ASSOCIATION_CONTACT_FAILURE_TOAST_CONFIG = {
  titleMessage: 'Contact not created. Please try again later.',
  status: 'error',
  timeOut: 5000,
  width: '350px',
}

export const EDIT_ASSOCIATION_CONTACT_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'New contact edited.',
  status: 'success',
  timeOut: 5000,
  width: '350px',
}

export const EDIT_ASSOCIATION_CONTACT_FAILURE_TOAST_CONFIG = {
  titleMessage: 'Contact not edit. Please try again later.',
  status: 'error',
  timeOut: 5000,
  width: '350px',
}
