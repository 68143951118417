import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { MARK_AS_PAID_API } from './constants'

export const markAsPaidInstance = ({
  rentalPaymentId,
  paymentRequestId,
  datePaid,
  paymentMethod,
  notes,
}) =>
  apiInstanceWithErrorHandler.post(
    buildPath(MARK_AS_PAID_API, { rentalPaymentId, paymentRequestId }),
    {
      paidDate: datePaid,
      paymentMethod,
      notes,
    },
  )
