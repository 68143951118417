import { createSelector } from "reselect"
import get from "lodash/get"

const selectUser = state => get(state, "user", {})

const selectSubdomain = ({ subdomain }) => subdomain || {}

const selectSidebar = ({ sidebar }) => sidebar || {}

const makeSelectProfile = () =>
  createSelector(
    selectUser,
    ({ profile }) => profile,
  )

const makeSelectSubdomain = () =>
  createSelector(
    selectSubdomain,
    ({ subdomain }) => get(subdomain, "sub", null),
  )

const makeSelectCollapse = () =>
  createSelector(
    selectSidebar,
    ({ isCollapse }) => isCollapse,
  )

export {
  selectUser,
  selectSidebar,
  makeSelectProfile,
  selectSubdomain,
  makeSelectSubdomain,
  makeSelectCollapse,
}

export const selectTenantScreeningCount = createSelector(
  selectSidebar,
  sidebar => {
    const count = get(sidebar, "notice.tenantScreening.unopen", null)
    return count > 99 ? "99+" : count
  },
)

export const selectRentalPaymentNotifications = createSelector(selectSidebar,
  sidebar => get(sidebar, "notice.rentalPayment", []))

export const selectRentalPaymentNotificationsCount = createSelector(
  selectRentalPaymentNotifications,
  rentalPaymentNotifications => rentalPaymentNotifications
      ? Object.keys(rentalPaymentNotifications).length
      : 0,
)

export const selectUnSeenTaskCount = createSelector(
  selectSidebar,
  sidebar => {
    const count = get(sidebar, "notice.task.data.unseen", null)
    return count > 99 ? "99+" : count
  },
)

export const selectSeenTaskCount = createSelector(
  selectSidebar,
  sidebar => {
    const data = get(sidebar, "notice.task.data", null)
    const seen = get(data, "total", null) - get(data, "unseen", 0)
    return seen
  },
)

export const selectIncompletedTaskCount = createSelector(
  selectSidebar,
  sidebar => get(sidebar, "notice.task.data.incompleted", null),
)
