import React from "react"
import get from "lodash/get"
import { withRouter } from "react-router-dom"
import { compose } from "redux"
import { ReportNotAvailable } from "components/reports/screening-reports/report-not-available"
import { CenterContent } from "components/layout/main"
import { withConnect } from "./connect"
import { REPORT_TYPES } from "../constants"
import { getIsSelectedReportUnavailable } from "../helper"

/**
 * This Higher-order handles logic that relevant screening options data
 * @option the screening option type consist with application, credit report, criminal and eviction
 */

export const withReportAvailable = reportType => WrappedComponent => ({
  screeningOption,
  match,
  participantType,
  isSharedReport,
  shareOption,
  ...rest
}) => {
  const isUnavailable = getIsSelectedReportUnavailable(
    reportType,
    isSharedReport,
    screeningOption,
    shareOption,
  )
  
  if (isUnavailable) {
    const reportPath = get(match, "params.type")
    const propertyId = get(match, "params.propertyId", "-")
    return shouldRenderComponent(
      reportPath,
      <CenterContent withBreadcrumb withReportTabs withReportPadding noMargin>
        <ReportNotAvailable
          reportType={reportType}
          propertyId={propertyId}
          participantType={participantType}
        />
      </CenterContent>,
    )
  }
  return <WrappedComponent {...rest} />
}

export const shouldRenderComponent = (reportPath, UnavailableComponent) => {
  if (reportPath === REPORT_TYPES.ALL) return null
  return UnavailableComponent
}
const composedWithReportAvailable = (reportType, unavailableComponent) =>
  compose(
    withRouter,
    withConnect,
    withReportAvailable(reportType, unavailableComponent),
  )

export default composedWithReportAvailable