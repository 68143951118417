import generateApiCall from 'utils/generate-api-call'

import {
  FETCH_PROPERTY_BY_ADDRESS,
  FETCH_PROPERTY_BY_ADDRESS_REQUEST,
  FETCH_PROPERTY_BY_ADDRESS_SUCCESS,
  FETCH_PROPERTY_BY_ADDRESS_FAILURE,
  RESET_PROPERTY_BY_ADDRESS,
} from './constants'

export const fetchPropertyByAddressAPI = generateApiCall([
  FETCH_PROPERTY_BY_ADDRESS_REQUEST,
  FETCH_PROPERTY_BY_ADDRESS_SUCCESS,
  FETCH_PROPERTY_BY_ADDRESS_FAILURE,
])

export const fetchPropertyByAddress = payload => ({
  type: FETCH_PROPERTY_BY_ADDRESS,
  payload,
})

export const resetPropertyByAddress = () => ({
  type: RESET_PROPERTY_BY_ADDRESS,
})
