import { TYPES } from "./constants"

export const callGetNewBadge = data => ({
  type: TYPES.CALL_GET_NEW_BADGE,
  payload: data,
})

export const getNewBadgeRequest = () => ({
  type: TYPES.GET_NEW_BADGE_REQUEST,
})

export const getNewBadgeSuccess = data => ({
  type: TYPES.GET_NEW_BADGE_SUCCESS,
  payload: data,
})

export const getNewBadgeFailed = () => ({
  type: TYPES.GET_NEW_BADGE_FAILED,
})
