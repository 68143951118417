import React, { useEffect } from 'react'
import { compose } from 'redux'
import { buildPath, query } from '@rentspree/path'
import { withRouter } from 'react-router-dom'

import useTrackEnterPage from 'tracker/use-track-enter-page'
import { LRA_INTEGRATION } from 'tracker/const'

import { LoadingOverlay } from 'legacy/components/request/left-content'
import { RequestContainer } from 'legacy/components/request/request-main-style'
import TitleWithSubtitle from 'components/molecules/title-with-subtitle'
import SubtitleWithHr from 'components/molecules/subtitle-with-hr'
import { PERMALINK_ACTIONS_ID } from 'constants/route'
import ScreeningFeeTemplate from 'components/templates/screening-fee'
import { withReducer } from 'containers/request/step-update-screening-fee/connect'
import {withUserPreferenceData} from "utils/tenant-screening/with-user-preference-data"

import { withConnect, withSaga } from './connect'

export const StepUpdateScreeningFeeV2 = ({
  history,
  location,
  match,
  requestOptions,
  actions,
  screeningFeeData,
  isFetchingLraRequest,
  isUpdatingLraRequest,
  handleBackToDashboard,
}) => {
  useTrackEnterPage(LRA_INTEGRATION.ENTER_SCREENING_FEE_PAGE)
  const { search } = location
  const { screeningRequestId } = query.parse(search)
  const { propertyId } = match.params || {}
  const { payerType } = requestOptions

  if (!screeningRequestId || !payerType)
    history.replace(buildPath(PERMALINK_ACTIONS_ID, { propertyId }, { isBackToDashboard: true }))

  const updateScreeningFee = async screeningFee => {
    await actions.updateLraRequestCall({ propertyId, screeningFee })
  }

  useEffect(() => {
    actions.getLraRequestCall({ propertyId, screeningRequestId })
  }, [])

  if (isFetchingLraRequest) return <LoadingOverlay />

  return (
    <RequestContainer>
      <TitleWithSubtitle title="Screening fee breakdown" mb="30" />
      <SubtitleWithHr text="Please provide a breakdown of the screening fee you will charge applicants" />
      <ScreeningFeeTemplate
        id="screeningFeeTemplate"
        handleBackToScreeningOption={handleBackToDashboard}
        onSubmit={updateScreeningFee}
        screeningFeeData={screeningFeeData}
        isUpdatingScreeningFee={isUpdatingLraRequest}
      />
    </RequestContainer>
  )
}

export default compose(
  withRouter,
  withConnect,
  withReducer,
  withSaga,
  withUserPreferenceData,
)(StepUpdateScreeningFeeV2)
