import get from 'lodash/get'
import { createSelector } from 'reselect'

const overviewNotificationState = state => get(state, 'overviewNotification')

const getRentalPaymentNotifications = state => get(state, 'sidebar.notice', {})

export const selectRentalPaymentNotifications = () =>
  createSelector(getRentalPaymentNotifications, notifications =>
    get(notifications, 'rentalPayment', []),
  )

export const selectOverviewNotificationState = createSelector(overviewNotificationState, state => state)

export const selectNotification = () =>
  createSelector(selectOverviewNotificationState, data => get(data, 'notification', []))
