import React from 'react'
import styled from 'styled-components'
import { COLOR, breakpoints } from 'styles/settings'
import { SubSectionTitle, Value } from '../screening-reports/share/responsive-doc-style'

const Container = styled.div`
  display: flex;
  padding-top: 20px;
  column-gap: 8px;
  ${breakpoints.mobileReport(`
    display: block;
    padding-top: 20px;
  `)}
`

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ${breakpoints.mobileReport(`
    margin-bottom: 20px;
  `)}
`

const I = styled.i`
  color: ${COLOR.textBlack};
  font-size: 32px;
  margin-right: 26px;
  width: 32px;
  ${breakpoints.mobileReport(`
    font-size: 30px;
    margin-right: 10px;
    width: 30px;
  `)}
`

const ContactInfo = ({ email, phone }) => (
  <Container>
    <ContactContainer style={{ flexBasis: '50%' }}>
      <I className="material-symbols-outlined">mail</I>
      <div>
        <SubSectionTitle>Email</SubSectionTitle>
        <Value data-testid="coverpage-email-value">{email}</Value>
      </div>
    </ContactContainer>
    <ContactContainer style={{ flexBasis: '50%' }}>
      <I className="material-symbols-outlined">phone_iphone</I>
      <div>
        <SubSectionTitle>Phone</SubSectionTitle>
        <Value data-testid="coverpage-phone-value">{phone}</Value>
      </div>
    </ContactContainer>
  </Container>
)

export default ContactInfo
