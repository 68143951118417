import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import { withFormik } from 'formik'
import React from 'react'
import styled from 'styled-components'

import ContactForm from '../../organisms/association-contact-form'

import { schema } from './schema'

const StyledContainer = styled.div`
  font-family: Source Sans Pro, sans-serif;
  overflow: visible;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
`

export const CreateAssociationContactForm = ({
  handleSubmit,
  isSubmittingForm,
  isCreating,
  onCloseModal,
  ...formikProps
}) => (
  <StyledContainer>
    <S24 size="28px" margin="0 0 30px 0">
      {isCreating ? 'Enter landlord details' : 'Edit landlord details'}
    </S24>
    <form onSubmit={handleSubmit}>
      <ContactForm isCreating={isCreating} {...formikProps} />
      <ButtonContainer>
        <Button small google text="Cancel" minWidth="140" type="button" onClick={onCloseModal} />
        <BlueGradientButton
          small
          text={isCreating ? 'Add' : 'Save'}
          minWidth="140"
          type="submit"
          loading={isSubmittingForm}
        />
      </ButtonContainer>
    </form>
  </StyledContainer>
)

const CreateAssociationContactFormTemplate = withFormik({
  mapPropsToValues: props => schema(props.values).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: async (values, { props }) => {
    if (props.isCreating) {
      props.onCreateContact(values)
    } else {
      props.onUpdateContact(values)
    }
  },
  enableReinitialize: true,
  displayName: 'CreateAssociationContactForm',
})(CreateAssociationContactForm)

export default CreateAssociationContactFormTemplate
