import React from "react"
import Span from "@rentspree/component-v2/dist/typography/span"
import { FEATURE_STATUS } from "containers/property/toggle-property-feature/constants"
import { COLOR } from "styles/settings"

const { DISABLED, ENABLE } = FEATURE_STATUS

export const Body = ({ type, isPropertyArchived }) => (
  <>
    {type === DISABLED && (
      <Span id="disable-body">
        Applicants will{" "}
        <Span bold color={COLOR.highlightRed} id="hilight-text">
          not
        </Span>{" "}
        be able to apply to this listing. You can resume screening at any time.
      </Span>
    )}
    {type === ENABLE && !isPropertyArchived && (
      <Span id="enable-body">
        Applicants will be able to apply to this listing. You can pause
        screening at any time.
      </Span>
    )}
    {type === ENABLE && isPropertyArchived && (
      <Span id="enable-archived-body">
        Resume tenant screening will unarchive the property. You can archive the
        property or pause tenant screening at any time.
      </Span>
    )}
  </>
)

export default Body
