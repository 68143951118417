import generateApiCall from 'utils/generate-api-call'
import {
  OPEN_INVITE_LANDLORD_RENT_PAYMENT_MODAL,
  CLOSE_INVITE_LANDLORD_RENT_PAYMENT_MODAL,
  GET_LANDLORD_INFORMATION_CALL,
  GET_LANDLORD_INFORMATION_REQUEST,
  GET_LANDLORD_INFORMATION_SUCCESS,
  GET_LANDLORD_INFORMATION_FAIL,
  INVITE_AFTER_APPLICANT_ACCEPTED_CALL,
  INVITE_AFTER_APPLICANT_ACCEPTED_FAIL,
  INVITE_AFTER_APPLICANT_ACCEPTED_REQUEST,
  INVITE_AFTER_APPLICANT_ACCEPTED_SUCCESS,
} from './constants'

export const openInviteLandlordRentPaymentModal = payload => ({
  type: OPEN_INVITE_LANDLORD_RENT_PAYMENT_MODAL,
  payload,
})

export const closeInviteLandlordRentPaymentModal = payload => ({
  type: CLOSE_INVITE_LANDLORD_RENT_PAYMENT_MODAL,
  payload,
})

export const getLandlordInformationCall = payload => ({
  type: GET_LANDLORD_INFORMATION_CALL,
  payload,
})

export const getLandlordInformationApiState = generateApiCall([
  GET_LANDLORD_INFORMATION_REQUEST,
  GET_LANDLORD_INFORMATION_SUCCESS,
  GET_LANDLORD_INFORMATION_FAIL,
])

export const inviteAfterApplicantAccepted = payload => ({
  type: INVITE_AFTER_APPLICANT_ACCEPTED_CALL,
  payload,
})

export const inviteAfterApplicantAcceptedApiStates = generateApiCall([
  INVITE_AFTER_APPLICANT_ACCEPTED_REQUEST,
  INVITE_AFTER_APPLICANT_ACCEPTED_SUCCESS,
  INVITE_AFTER_APPLICANT_ACCEPTED_FAIL,
])
