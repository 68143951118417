import React from "react"
import marked from "marked"
import S16 from "@rentspree/component-v2/dist/typography/s16"

import { Loading } from "components/shared/layout/main"
import ButtonSet from "components/molecules/button-set"
import ScrollTextPanel from "components/atoms/scroll-text-panel"

const AcceptDenyDisclaimerTemplate = ({
  content,
  title,
  onClickContinue,
  isFetchingContent,
  isButtonLoading,
}) => {
  const renderAgreementContent = agreementContent => (
    <div
      dangerouslySetInnerHTML={{
        __html: marked(agreementContent),
      }}
    />
  )

  if (isFetchingContent) return <Loading center />
  return (
    <>
      <S16 margin="0 0 10px 0">{title}</S16>
      <ScrollTextPanel height="433px" mHeight="auto">
        {content && renderAgreementContent(content)}
      </ScrollTextPanel>
      <ButtonSet
        margin="20px 0 0"
        minHeight="40px"
        buttons={[
          {
            type: "blueGradientButton",
            option: {
              text: "Agree",
              hasIcon: false,
              onClick: onClickContinue,
              loading: isButtonLoading,
              width: "130px",
            },
            isPullLeft: false,
          },
        ]}
      />
    </>
  )
}

export default AcceptDenyDisclaimerTemplate
