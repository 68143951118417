import { takeLatest, put, all, call } from 'redux-saga/effects'
import get from 'lodash/get'
import { push } from 'connected-react-router'
import {
  TOGGLE_SHARE_REPORT_PROMPT_MODAL_CALL,
  TOGGLE_SHARE_REPORT_PROMPT_MODAL,
  SHARE_PROMPT_PROPERTY_SKIP_LIST,
} from './constants'

export function* toggleModal({ payload: { toggle, navigationUnblock, property } }) {
  yield put({ type: TOGGLE_SHARE_REPORT_PROMPT_MODAL, payload: { toggle, navigationUnblock } })
  if (!toggle && property) {
    const propertyId = get(property, '_id')
    const sharePromptSkipList =
      JSON.parse(window.localStorage.getItem(SHARE_PROMPT_PROPERTY_SKIP_LIST)) || []

    if (!sharePromptSkipList.includes(propertyId)) {
      sharePromptSkipList.push(propertyId)
    }

    window.localStorage.setItem(
      SHARE_PROMPT_PROPERTY_SKIP_LIST,
      JSON.stringify(sharePromptSkipList),
    )
  }
  if (!toggle && navigationUnblock) {
    const { unblock, location } = navigationUnblock
    yield call(unblock)
    yield put(push({ pathname: location.pathname, search: location.search }))
  }
}

export function* watchToggleShareReportPromptModal() {
  yield takeLatest(TOGGLE_SHARE_REPORT_PROMPT_MODAL_CALL, toggleModal)
}

function* rootSaga() {
  yield all([watchToggleShareReportPromptModal()])
}

export default rootSaga
