import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { makeSelectProfile } from "containers/navbar/selectors"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import { selectBrokerageName as selectAgentProfileBrokerageName } from "containers/agent-profile/selectors"
import { selectAccessToken } from "containers/user/selectors"
import { makeSelectProducts } from "legacy/containers/pro/landing/selectors"
import {
  makeSelectESignTemplateList,
  makeSelectIsLoading,
  makeSelectIsError,
  makeSelectCarModalState,
  makeSelectCarLinkingAccountLoading,
  makeSelectCarLinkingAccountSuccess,
  makeSelectCarLinkingAccountError,
  makeSelectIsAcceptTermsLeaseAgreement,
  makeSelectBrokerageName,
} from "./selectors"
import reducer from "./reducer"
import {
  getESignTemplateList,
  clearESignTemplateList,
  setCarModalState,
  linkAccountWithoutSSO,
  closeErrorInModal,
  alertUnknownError,
} from "./actions"
import saga from "./saga"

import {
  updateEnvelopeByTemplate,
  showConsentModal,
  clearLeaseError,
  updateLeaseAgreementConsent,
} from "../actions"
import {
  selectLoadingUpdateEnvelope,
  selectIsAccepting,
  selectEnvelopeError,
  makeSelectIsShowModal,
  makeSelectIsConsentSuccess,
  selectUserIntegration,
} from "../selectors"

export const mapStateToProps = () =>
  createStructuredSelector({
    data: makeSelectESignTemplateList(),
    isLoading: makeSelectIsLoading(),
    isError: makeSelectIsError(),
    profile: makeSelectProfile(),
    carModalState: makeSelectCarModalState(),
    carLinkingAccountLoading: makeSelectCarLinkingAccountLoading(),
    carLinkingAccountSuccess: makeSelectCarLinkingAccountSuccess(),
    carLinkingAccountError: makeSelectCarLinkingAccountError(),
    isLoadingUpdateEnvelope: selectLoadingUpdateEnvelope,
    acceptTermLeaseAgreement: makeSelectIsAcceptTermsLeaseAgreement(),
    isAccepting: selectIsAccepting(),
    error: selectEnvelopeError,
    isShowConsentModal: makeSelectIsShowModal(),
    isConsentSuccess: makeSelectIsConsentSuccess(),
    userBrokerageName: makeSelectBrokerageName(),
    agentProfileBrokerageName: selectAgentProfileBrokerageName,
    userAccessToken: selectAccessToken,
    userIntegration: selectUserIntegration,
    products: makeSelectProducts(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getESignTemplateList,
        clearESignTemplateList,
        updateEnvelopeByTemplate,
        setCarModalState,
        linkAccountWithoutSSO,
        closeErrorInModal,
        showConsentModal,
        clearLeaseError,
        updateLeaseAgreementConsent,
        alertUnknownError,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({
  key: "eSignTemplateList",
  reducer,
})

export const withSaga = injectSaga({ key: "eSignTemplateList", saga })
