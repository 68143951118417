import React from "react"
import get from "lodash/get"
import { buildPath } from "@rentspree/path"
import B14 from "@rentspree/component-v2/dist/typography/b14"

import Col from "components/atoms/col"
import Row from "components/atoms/row"
import TextWithLabel from "components/molecules/text-with-label"
import { TopicHeaderWithUnderline } from "components/molecules/topic-header-with-underline"
import { COLOR } from "styles/settings"
import { convertDateFormat } from "utils/convert"
import { FileCard } from "components/organisms/file-card"
import TransparentTextIconButton from "components/molecules/transparent-button"
import tracker from "tracker"
import { RENTER_INSURANCE } from "tracker/const"
import { RENTER_INSURANCE_FILE_API } from "constants/route"

export const UploadChannel = props => {
  const { rentersInsuranceDetail } = props
  const requesteeEmail = get(rentersInsuranceDetail, "requestee.email")

  const onViewInsurance = () => {
    const { propertyId, rentersInsuranceId } = props.match.params
    const path = buildPath(RENTER_INSURANCE_FILE_API, {
      propertyId,
      rentersInsuranceId,
    })
    tracker.trackEvent(RENTER_INSURANCE.EVENT_NAME.VIEW_UPLOAD_RENTER_INSURANCE)
    window.open(path, "_blank")
  }

  return (
    <Col padding="30px" mPadding="20px" maxWidth="980px" margin="0 auto">
      <TopicHeaderWithUnderline topicMargin="0" title="Policy Info" />
      <Col mFlexColumn>
        <Row mFlexColumn>
          <TextWithLabel
            title="Holder Email:"
            subTitle={requesteeEmail}
            margin="0 0 5px"
          />
        </Row>
        <Row mFlexColumn>
          <TextWithLabel
            title="Carrier:"
            subTitle={get(rentersInsuranceDetail, "file.carrier", "-")}
            margin="0 0 5px"
          />
        </Row>
        <Row mFlexColumn>
          <TextWithLabel
            title="Start Date:"
            subTitle={convertDateFormat(
              get(rentersInsuranceDetail, "file.effectiveDate"),
              "MM/DD/YYYY",
            )}
            margin="0 0 5px"
          />
          <TextWithLabel
            title="End Date:"
            subTitle={convertDateFormat(
              get(rentersInsuranceDetail, "file.endDate"),
              "MM/DD/YYYY",
            )}
            margin="0 0 5px"
          />
        </Row>
      </Col>
      <TopicHeaderWithUnderline
        topicMargin="25px 0 0"
        title="Proof of insurance"
        hrMargin="8px 0 19px"
      />
      <FileCard
        file={{ name: get(rentersInsuranceDetail, "file.fileName") }}
        disabled
        actionButton={
          <TransparentTextIconButton
            margin="0 20px 0 0"
            color={COLOR.fontBlue}
            hoverColor={COLOR.newDeepBlue}
            icon="menu-other-docs"
            iconSize="sm"
            size="14px"
            text="View"
            onClick={onViewInsurance}
          />
        }
      />
      <B14>* This document was uploaded by {requesteeEmail}.</B14>
    </Col>
  )
}

export default UploadChannel
