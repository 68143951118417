/* eslint-disable no-param-reassign */
import produce from 'immer'

import {
  GET_PAYEE_REQUESTED,
  GET_PAYEE_SUCCEEDED,
  GET_PAYEE_FAILED,
  GET_PAYOUT_METHOD_LIST_REQUESTED,
  GET_PAYOUT_METHOD_LIST_SUCCEEDED,
  GET_PAYOUT_METHOD_LIST_FAILED,
  GET_PAYEE_IDV_UPDATE_LINK_REQUESTED,
  GET_PAYEE_IDV_UPDATE_LINK_SUCCEEDED,
  GET_PAYEE_IDV_UPDATE_LINK_FAILED,
  RESET_PAYEE_IDV_UPDATE_LINK,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUESTED,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCEEDED,
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED,
  OVERRIDE_PAYEE_IDV_STATE,
} from '../actions'
import { API_STATUS } from '../constants'

export const paymentAccountInitialState = {
  payee: {
    status: API_STATUS.NONE,
    data: undefined,
  },
  payoutMethodList: {
    status: API_STATUS.NONE,
    data: undefined,
  },
  payeeIdvUpdateLink: {
    status: API_STATUS.NONE,
    data: undefined,
  },
  payeeProviderAccountInformation: {
    status: API_STATUS.NONE,
    data: undefined,
  },
}

// eslint-disable-next-line default-param-last
export const paymentAccountReducer = (state = paymentAccountInitialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_PAYEE_REQUESTED:
        draft.payee.status = API_STATUS.FETCHING
        break
      case GET_PAYEE_SUCCEEDED:
        draft.payee.data = payload
        draft.payee.status = API_STATUS.SUCCESS
        break
      case GET_PAYEE_FAILED:
        draft.payee.status = API_STATUS.ERROR
        break
      case OVERRIDE_PAYEE_IDV_STATE:
        draft.payee.data = {
          ...state.payee.data,
          identityVerification: payload,
        }
        draft.payee.status = API_STATUS.SUCCESS
        break
      case GET_PAYOUT_METHOD_LIST_REQUESTED:
        draft.payoutMethodList.status = API_STATUS.FETCHING
        break
      case GET_PAYOUT_METHOD_LIST_SUCCEEDED:
        draft.payoutMethodList.data = payload
        draft.payoutMethodList.status = API_STATUS.SUCCESS
        break
      case GET_PAYOUT_METHOD_LIST_FAILED:
        draft.payoutMethodList.status = API_STATUS.ERROR
        break
      case GET_PAYEE_IDV_UPDATE_LINK_REQUESTED:
        draft.payeeIdvUpdateLink.status = API_STATUS.FETCHING
        break
      case GET_PAYEE_IDV_UPDATE_LINK_SUCCEEDED:
        draft.payeeIdvUpdateLink.data = payload
        draft.payeeIdvUpdateLink.status = API_STATUS.SUCCESS
        break
      case GET_PAYEE_IDV_UPDATE_LINK_FAILED:
        draft.payeeIdvUpdateLink.status = API_STATUS.ERROR
        break
      case RESET_PAYEE_IDV_UPDATE_LINK:
        draft.payeeIdvUpdateLink.data = undefined
        draft.payeeIdvUpdateLink.status = API_STATUS.NONE
        break
      case GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUESTED:
        draft.payeeProviderAccountInformation.status = API_STATUS.FETCHING
        break
      case GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCEEDED:
        draft.payeeProviderAccountInformation.status = API_STATUS.SUCCESS
        draft.payeeProviderAccountInformation.data = payload
        break
      case GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED:
        draft.payeeProviderAccountInformation.status = API_STATUS.ERROR
        break
      default:
    }
    return draft
  })
