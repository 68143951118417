import generateApiCall from 'utils/generate-api-call'
import {
  GET_SCREENING_PLANS_CALL,
  GET_SCREENING_PLANS_REQUEST,
  GET_SCREENING_PLANS_SUCCESS,
  GET_SCREENING_PLANS_FAILURE,
  GET_USER_PREFERENCE_CALL,
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
  SELECT_PAYER_TYPE,
  CREATE_SCREENING_REQUEST_CALL,
  CREATE_SCREENING_REQUEST_REQUEST,
  CREATE_SCREENING_REQUEST_SUCCESS,
  CREATE_SCREENING_REQUEST_FAILURE,
  ACCEPT_BACKGROUND_DISCLAIMER,
  UPDATE_DOCUMENT_PRO_OPTION,
  UPDATE_REF_CHECK_PRO_OPTION,
  CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS,
} from './constants'

export const getScreeningPlans = () => ({
  type: GET_SCREENING_PLANS_CALL,
})

export const getScreeningPlansApiState = generateApiCall([
  GET_SCREENING_PLANS_REQUEST,
  GET_SCREENING_PLANS_SUCCESS,
  GET_SCREENING_PLANS_FAILURE,
])

export const getUserPreference = () => ({
  type: GET_USER_PREFERENCE_CALL,
})

export const getUserPreferenceApiState = generateApiCall([
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
])

export const createScreeningRequest = payload => ({
  type: CREATE_SCREENING_REQUEST_CALL,
  payload,
})

export const createScreeningRequestApiState = generateApiCall([
  CREATE_SCREENING_REQUEST_REQUEST,
  CREATE_SCREENING_REQUEST_SUCCESS,
  CREATE_SCREENING_REQUEST_FAILURE,
])

export const selectReport = (type, payload) => ({
  type,
  payload,
})

export const selectPayer = payload => ({
  type: SELECT_PAYER_TYPE,
  payload,
})

export const updateDocumentProOption = payload => ({
  type: UPDATE_DOCUMENT_PRO_OPTION,
  payload,
})

export const updateRefCheckProOption = payload => ({
  type: UPDATE_REF_CHECK_PRO_OPTION,
  payload,
})

export const clearScreeningWithoutPropertyProOptions = () => ({
  type: CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS,
})

export const acceptedBackgroundDisclaimer = payload => ({
  type: ACCEPT_BACKGROUND_DISCLAIMER,
  payload,
})
