import { query } from '@rentspree/path'
import { ReportDiv } from 'components/layout/main-style'
import { Wrapper } from 'components/reports'
import { PARTNER_NAME } from 'constants/partner'
import { PRINT_REPORTS_RENTER_PAGE } from 'constants/route'
import { APP_TYPES } from 'containers/application/const'
import { PAGE, PROJECT } from 'containers/popper/const'
import Popper from 'containers/popper/popper'
import tracker from 'legacy/tracker'
import { EVENT_REPORT } from 'legacy/tracker/const'
import get from 'lodash/get'
import React, { Fragment } from 'react'
import { compose } from 'redux'
import { E_SIGNATURE_FEATURE_ID } from 'utils/feature-toggle/constants'
import disableFeature from 'utils/feature-toggle/instance'
import { PrintPageGlobalStyle } from 'components/reports/screening-reports/share/responsive-doc-style'
import SubscriptionRefChecks from 'containers/reports/reference-checks'
import SubscriptionOtherDocsList from 'containers/reports/other-doc/document-list'
import ReportBottomBar from 'containers/reports/bottom-bar'
import {
  INCOME_VERIFICATION_SHARE_REPORT,
  UPDATE_MS_SHARE_REQUEST,
} from 'utils/feature-flag/constants'
import { useFeatureGate } from '@statsig/react-bindings'
import useMobile from 'hooks/use-mobile'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import Application from '../application'
import { BtnOverlay } from '../btn-overlay'
import { REPORT_TYPES, HIDE_CTA_OVERLAY, HIDE_DESKTOP_REPORT_ON_MOBILE } from '../constants'
import CoverPage from '../cover'
import { OtherDoc } from '../other-doc/report/loadable'
import SubscriptionRefChecksReport from '../reference-checks/report'
import ShareableCreditReport from '../screening-reports/shareable/credit-report'
import ShareableCriminalReport from '../screening-reports/shareable/criminal'
import ShareableEvictionReport from '../screening-reports/shareable/eviction'
import withOpen from '../screening-reports/with-open'
import IncomeVerificationReport from '../screening-reports/income-verification'
import withIncomeVerification from '../screening-reports/income-verification/connect'

import { withConnect, withSaga } from './connect'
import { PARTNER_EVENT_NAMES } from './constants'

const {
  ALL,
  APPLICATION,
  CREDIT_REPORT,
  CRIMINAL,
  EVICTION,
  REFCHECKS,
  OTHER_DOCS,
  INCOME_VERIFICATION,
} = REPORT_TYPES

export const AllReports = ({ hideApplication }) => (
  <>
    <PrintPageGlobalStyle />
    {<CoverPage />}
    {!hideApplication && <Application />}
    {<ShareableCreditReport />}
    {<ShareableCriminalReport />}
    {<ShareableEvictionReport />}
    {<IncomeVerificationReport isFromAllTab />}
    <OtherDoc />
    <SubscriptionRefChecksReport />
  </>
)

export const Reports = ({
  match,
  getApplicationDetail,
  rentalDetail,
  isAppDetailPage,
  isFetchingAppDetail,
  isReportsReady,
  isShareAvailable,
  history,
  isLandlord,
  trackEvent,
  incomeVerificationData,
  generateIncomeVerificationReport,
  multiShare,
  isRenter,
}) => {
  const { value: isShareIncomeVerificationReportEnabled } = useFeatureGate(
    INCOME_VERIFICATION_SHARE_REPORT,
  )
  const { reportId: incomeVerificationReportId } = incomeVerificationData
  const [showESign, setShowESign] = React.useState(false)
  const isESignatureEnabled = disableFeature.isFeatureEnabled(E_SIGNATURE_FEATURE_ID)
  // TODO: INIT-521 clean up
  const { value: isUpdateMsShareRequestEnabled } = useFeatureGate(UPDATE_MS_SHARE_REQUEST)
  const showMsHeader = isUpdateMsShareRequestEnabled && multiShare.isMultiShareActive && isRenter
  const path = get(match, 'path', '')
  const isPrintRenterPage = path.includes(`${PRINT_REPORTS_RENTER_PAGE}/:type`)
  const propertyId = get(match, 'params.propertyId')

  const showESignDelay = () => {
    setTimeout(() => {
      setShowESign(true)
    }, 3000)
  }

  React.useEffect(() => {
    const { _id, email } = rentalDetail
    const shouldFetchApplication = get(rentalDetail, 'screeningOption.application', false)
    tracker.trackEvent(EVENT_REPORT.viewReport, {
      rental_id: _id,
      applicant: email,
      property_id: propertyId,
      is_shared_report: !isShareAvailable === true || undefined,
    })
    // event tracking when agent open report for CRMLS
    const subdomain = get(rentalDetail, 'screeningOption.subdomain', 'rentspree')
    if (isLandlord && subdomain === PARTNER_NAME.CRMLS) {
      trackEvent({
        eventName: PARTNER_EVENT_NAMES.OPEN_REPORT,
        subdomain,
        payload: {
          propertyId,
        },
      })
    }

    if (!isFetchingAppDetail && _id && shouldFetchApplication) {
      getApplicationDetail(_id)
    }
  }, [])

  React.useEffect(() => {
      const { screeningOption, incomeVerificationId } = rentalDetail
      if (
        screeningOption?.payerType === 'landlord' &&
        incomeVerificationId &&
        !incomeVerificationReportId
      ) {
        generateIncomeVerificationReport(rentalDetail.id)
      }
  }, [rentalDetail, incomeVerificationReportId])

  React.useEffect(() => {
    if (isESignatureEnabled) {
      showESignDelay()
    }
  }, [isESignatureEnabled])

  const { params: { type } = {} } = match
  const { appType } = query.parse(history?.location?.search) || {}
  const hideApplicationInAllReportPage = appType === APP_TYPES.CAR_LRA
  let reportComponentProps = {}
  let ReportComponent = () => <Fragment />
  switch (type) {
    case ALL:
      reportComponentProps = {
        hideApplication: hideApplicationInAllReportPage,
      }
      ReportComponent = AllReports
      break
    case APPLICATION:
      ReportComponent = Application
      break
    case CREDIT_REPORT:
      ReportComponent = ShareableCreditReport
      break
    case CRIMINAL:
      ReportComponent = ShareableCriminalReport
      break
    case EVICTION:
      ReportComponent = ShareableEvictionReport
      break
    case REFCHECKS:
      /**
       * The reason why ref-check needs to have this condition as it's only one report
       * that shows different layouts between app detail and print page
       * isAppDetailPage: if 'true' indicates that it's the app detail page otherwise it's print page
       * SubscriptionRefChecks: The container that contain the list of ref-check requesting and report
       * SubscriptionRefChecksReport: The container that contain only ref-check report
       */
      ReportComponent = isAppDetailPage ? SubscriptionRefChecks : SubscriptionRefChecksReport
      break
    case OTHER_DOCS:
      ReportComponent = SubscriptionOtherDocsList
      break
    case INCOME_VERIFICATION:
      ReportComponent = IncomeVerificationReport
      break
    default:
      break
  }

  let isShowBtnOverlay = true

  if (showMsHeader) {
    isShowBtnOverlay = false
  } else if (!isShareIncomeVerificationReportEnabled && type === REPORT_TYPES.INCOME_VERIFICATION) {
    isShowBtnOverlay = false
  } else {
    isShowBtnOverlay = !HIDE_CTA_OVERLAY.includes(type)
  }
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const shouldHideOnMobile = isAppDetailPage && !HIDE_DESKTOP_REPORT_ON_MOBILE.includes(type)

  return (
    <Wrapper className="report-div" hideMobile={shouldHideOnMobile}>
      <ReportDiv>
        {isReportsReady && isShareAvailable && showESign && (
          <Popper page={PAGE.APP_DETAIL_REPORT} project={PROJECT} />
        )}
        <ReportComponent {...reportComponentProps} />
        {isShowBtnOverlay && (
          <BtnOverlay hasESignFooter={showESign} isPrintRenterPage={isPrintRenterPage} />
        )}
        {isShowBtnOverlay && !isMobile && <ReportBottomBar />}
      </ReportDiv>
    </Wrapper>
  )
}

export default compose(withOpen, withConnect, withSaga, withIncomeVerification)(Reports)
