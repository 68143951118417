export const countCriminal = criminals =>
  criminals.reduce((acc, curr) => {
    if (Array.isArray(curr.Rapsheet)) {
      return acc + curr.Rapsheet.length
    }
    return acc + 0
  }, 0)

export const countEviction = evictions =>
  evictions.reduce((acc, curr) => {
    if (Array.isArray(curr.Eviction)) {
      return acc + curr.Eviction.length
    }
    return acc + 0
  }, 0)
