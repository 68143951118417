import React from 'react'
import { CriminalImage } from 'legacy/components/reports-v2/share/doc-components'
import styled from 'styled-components'
import { breakpoints } from 'styles/settings'
import { Content, InformationPanel, Section } from '../../../share/responsive-doc-style'
import { InformationLine, SubSectionHeader } from '../../../share/responsive-doc-components'

const CriminalImagePanel = styled(InformationPanel)`
  img {
    border-radius: 5px;
  }
  div {
    width: 170px;
  }

  ${breakpoints.mobileReport(`
    div {
      width: 107px;
      margin-bottom: 10px;
    }
  `)}
`

const IdentityPersonalInformation = ({
  url,
  fullName,
  firstName,
  middleName,
  lastName,
  suffix,
  birthDate,
  age,
  birthPlace,
}) => (
  <Section>
    <SubSectionHeader title="Personal Info" />
    <Content>
      <CriminalImagePanel>
        <CriminalImage src={url} />
      </CriminalImagePanel>
      <InformationPanel>
        <InformationLine label="Full Name" value={fullName || '-'} />
        <InformationLine label="First Name" value={firstName || '-'} />
        <InformationLine label="Middle Name" value={middleName || '-'} />
        <InformationLine label="Last Name" value={lastName || '-'} />
        <InformationLine label="Suffix" value={suffix || '-'} />
        <InformationLine label="Birth Date" value={birthDate || '-'} />
        <InformationLine label="Age" value={age || '-'} />
        <InformationLine label="Record State" value={birthPlace || '-'} />
      </InformationPanel>
      <InformationPanel></InformationPanel>
    </Content>
  </Section>
)

export default IdentityPersonalInformation
