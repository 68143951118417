/* eslint-disable no-param-reassign */
import produce from 'immer'

import {
  CHANGE_PAGE_RENTAL_PAYMENT_LIST_REQUESTED,
  CHECK_RENTAL_PAYMENT_EXISTS_FAILED,
  CHECK_RENTAL_PAYMENT_EXISTS_REQUESTED,
  CHECK_RENTAL_PAYMENT_EXISTS_SUCCEEDED,
  CHECK_SHOW_ERROR_BADGE_FAILED,
  CHECK_SHOW_ERROR_BADGE_REQUESTED,
  CHECK_SHOW_ERROR_BADGE_SUCCEEDED,
  CLEAR_CONFIRM_DRAFT_RENTAL_PAYMENT_STATUS,
  CONFIRM_DRAFT_RENTAL_PAYMENT_FAILED,
  CONFIRM_DRAFT_RENTAL_PAYMENT_REQUESTED,
  CONFIRM_DRAFT_RENTAL_PAYMENT_SUCCEEDED,
  DELETE_RENTAL_PAYMENT_FAILED,
  DELETE_RENTAL_PAYMENT_REQUESTED,
  DELETE_RENTAL_PAYMENT_SUCCEEDED,
  EDIT_ONE_TIME_PAYMENT_FAILED,
  EDIT_ONE_TIME_PAYMENT_REQUESTED,
  EDIT_ONE_TIME_PAYMENT_SUCCEEDED,
  GET_AVAILABLE_PAYOUT_FAILED,
  GET_AVAILABLE_PAYOUT_REQUESTED,
  GET_AVAILABLE_PAYOUT_SUCCEEDED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_FAILED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_REQUESTED,
  GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_SUCCEEDED,
  GET_OR_CREATE_RENTER_INFO_FAILED,
  GET_OR_CREATE_RENTER_INFO_REQUESTED,
  GET_OR_CREATE_RENTER_INFO_SUCCEEDED,
  GET_RENTAL_PAYMENT_BY_ID_FAILED,
  GET_RENTAL_PAYMENT_BY_ID_REQUESTED,
  GET_RENTAL_PAYMENT_BY_ID_SUCCEEDED,
  GET_RENTAL_PAYMENT_LIST_FAILED,
  GET_RENTAL_PAYMENT_LIST_REQUESTED,
  GET_RENTAL_PAYMENT_LIST_SUCCEEDED,
  MARK_AS_PAID_FAILED,
  MARK_AS_PAID_REQUESTED,
  MARK_AS_PAID_SUCCEEDED,
  RESET_MARK_AS_PAID_STATUS,
  RETRY_PAYOUT_FAILED,
  RETRY_PAYOUT_REQUESTED,
  RETRY_PAYOUT_SUCCEEDED,
  SEARCH_RENTER_INFO_FAILED,
  SEARCH_RENTER_INFO_REQUESTED,
  SEARCH_RENTER_INFO_SUCCEEDED,
  SEND_INVITATION_EMAIL_FAILED,
  SEND_INVITATION_EMAIL_REQUESTED,
  SEND_INVITATION_EMAIL_SUCCEEDED,
  UPDATE_DRAFT_QUOTATIONS_FAILED,
  UPDATE_DRAFT_QUOTATIONS_REQUESTED,
  UPDATE_DRAFT_QUOTATIONS_SUCCEEDED,
  GET_PAYMENT_SUMMARY_REQUESTED,
  GET_PAYMENT_SUMMARY_SUCCEEDED,
  GET_PAYMENT_SUMMARY_FAILED,
  CREATE_TENANT_REQUESTED,
  CREATE_TENANT_SUCCEEDED,
  CREATE_TENANT_FAILED,
} from '../actions'
import { API_STATUS } from '../constants'

export const rentalPaymentInitialState = {
  rentalPaymentList: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  rentalPayment: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  rentalPaymentExists: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  retryPayout: {
    status: API_STATUS.NONE,
  },
  deleteRentalPayment: {
    status: API_STATUS.NONE,
  },
  editOneTimePayment: {
    status: API_STATUS.NONE,
  },
  markAsPaid: {
    status: API_STATUS.NONE,
  },
  sendInvitationEmail: {
    status: API_STATUS.NONE,
  },
  checkShowErrorBadge: {
    status: API_STATUS.NONE,
  },
  draftRentalPayment: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  confirmDraftRentalPayment: {
    data: undefined,
    status: API_STATUS.NONE,
    error: undefined,
  },
  renterInfo: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  searchRenterInfo: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  updateDraftQuotations: {
    // the data will be merged to `draftRentalPayment`
    status: API_STATUS.NONE,
  },
  availablePayout: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  paymentSummary: {
    data: undefined,
    status: API_STATUS.NONE,
  },
  createTenant: {
    status: API_STATUS.NONE,
  },
}

// eslint-disable-next-line default-param-last
export const rentalPaymentReducer = (state = rentalPaymentInitialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case CHANGE_PAGE_RENTAL_PAYMENT_LIST_REQUESTED:
        draft.rentalPaymentList.status = API_STATUS.CHANGING_PAGE
        break
      case GET_RENTAL_PAYMENT_LIST_REQUESTED:
        draft.rentalPaymentList.status = API_STATUS.FETCHING
        break
      case GET_RENTAL_PAYMENT_LIST_SUCCEEDED:
        draft.rentalPaymentList.data = payload
        draft.rentalPaymentList.status = API_STATUS.SUCCESS
        break
      case GET_RENTAL_PAYMENT_LIST_FAILED:
        draft.rentalPaymentList.status = API_STATUS.ERROR
        break
      case CHECK_SHOW_ERROR_BADGE_REQUESTED:
        draft.checkShowErrorBadge.status = API_STATUS.FETCHING
        break
      case CHECK_RENTAL_PAYMENT_EXISTS_REQUESTED:
        draft.rentalPaymentExists.status = API_STATUS.FETCHING
        break
      case CHECK_RENTAL_PAYMENT_EXISTS_SUCCEEDED:
        draft.rentalPaymentExists.status = API_STATUS.SUCCESS
        draft.rentalPaymentExists.data = payload
        break
      case CHECK_RENTAL_PAYMENT_EXISTS_FAILED:
        draft.rentalPaymentExists.status = API_STATUS.ERROR
        break
      case CHECK_SHOW_ERROR_BADGE_SUCCEEDED:
        draft.checkShowErrorBadge.data = payload
        draft.checkShowErrorBadge.status = API_STATUS.SUCCESS
        break
      case CHECK_SHOW_ERROR_BADGE_FAILED:
        draft.checkShowErrorBadge.status = API_STATUS.ERROR
        break
      case GET_RENTAL_PAYMENT_BY_ID_REQUESTED:
        draft.rentalPayment.status = API_STATUS.FETCHING
        break
      case GET_RENTAL_PAYMENT_BY_ID_SUCCEEDED:
        draft.rentalPayment.data = payload
        draft.rentalPayment.status = API_STATUS.SUCCESS
        break
      case GET_RENTAL_PAYMENT_BY_ID_FAILED:
        draft.rentalPayment.status = API_STATUS.ERROR
        break
      case RETRY_PAYOUT_REQUESTED:
        draft.retryPayout.status = API_STATUS.UPDATING
        break
      case RETRY_PAYOUT_SUCCEEDED:
        draft.retryPayout.status = API_STATUS.SUCCESS
        break
      case RETRY_PAYOUT_FAILED:
        draft.retryPayout.status = API_STATUS.ERROR
        break
      case DELETE_RENTAL_PAYMENT_REQUESTED:
        draft.deleteRentalPayment.status = API_STATUS.UPDATING
        break
      case DELETE_RENTAL_PAYMENT_SUCCEEDED:
        draft.deleteRentalPayment.status = API_STATUS.SUCCESS
        break
      case DELETE_RENTAL_PAYMENT_FAILED:
        draft.deleteRentalPayment.status = API_STATUS.ERROR
        break
      case EDIT_ONE_TIME_PAYMENT_REQUESTED:
        draft.editOneTimePayment.status = API_STATUS.UPDATING
        break
      case EDIT_ONE_TIME_PAYMENT_SUCCEEDED:
        draft.editOneTimePayment.status = API_STATUS.SUCCESS
        break
      case EDIT_ONE_TIME_PAYMENT_FAILED:
        draft.editOneTimePayment.status = API_STATUS.ERROR
        break
      case MARK_AS_PAID_REQUESTED:
        draft.markAsPaid.status = API_STATUS.UPDATING
        break
      case MARK_AS_PAID_SUCCEEDED:
        draft.markAsPaid.status = API_STATUS.SUCCESS
        draft.rentalPayment.data = {
          ...state.rentalPayment.data,
          quotations: [
            {
              ...state.rentalPayment.data.quotations[0],
              paymentRequests: state.rentalPayment.data.quotations[0].paymentRequests.map(
                paymentRequest =>
                  paymentRequest.id === payload.id
                    ? { ...paymentRequest, ...payload }
                    : paymentRequest,
              ),
            },
          ],
        }
        break
      case MARK_AS_PAID_FAILED:
        draft.markAsPaid.status = API_STATUS.ERROR
        break
      case RESET_MARK_AS_PAID_STATUS:
        draft.markAsPaid.status = API_STATUS.NONE
        break
      case SEND_INVITATION_EMAIL_REQUESTED:
        draft.sendInvitationEmail.status = API_STATUS.UPDATING
        break
      case SEND_INVITATION_EMAIL_SUCCEEDED:
        draft.sendInvitationEmail.status = API_STATUS.SUCCESS
        break
      case SEND_INVITATION_EMAIL_FAILED:
        draft.sendInvitationEmail.status = API_STATUS.ERROR
        break
      case CONFIRM_DRAFT_RENTAL_PAYMENT_REQUESTED:
        draft.confirmDraftRentalPayment.status = API_STATUS.UPDATING
        break
      case CONFIRM_DRAFT_RENTAL_PAYMENT_SUCCEEDED:
        draft.rentalPayment.data = payload
        draft.confirmDraftRentalPayment.status = API_STATUS.SUCCESS
        break
      case CONFIRM_DRAFT_RENTAL_PAYMENT_FAILED:
        draft.confirmDraftRentalPayment.status = API_STATUS.ERROR
        draft.confirmDraftRentalPayment.error = payload
        break
      case CLEAR_CONFIRM_DRAFT_RENTAL_PAYMENT_STATUS:
        draft.confirmDraftRentalPayment.data = undefined
        draft.confirmDraftRentalPayment.status = API_STATUS.NONE
        draft.confirmDraftRentalPayment.error = undefined
        break
      case GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_REQUESTED:
        draft.draftRentalPayment.status = API_STATUS.FETCHING
        break
      case GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_SUCCEEDED:
        draft.draftRentalPayment.data = payload
        draft.draftRentalPayment.status = API_STATUS.SUCCESS
        break
      case GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_FAILED:
        draft.draftRentalPayment.status = API_STATUS.ERROR
        break
      case GET_OR_CREATE_RENTER_INFO_REQUESTED:
        draft.renterInfo.status = API_STATUS.FETCHING
        break
      case GET_OR_CREATE_RENTER_INFO_SUCCEEDED:
        draft.renterInfo.data = payload
        draft.renterInfo.status = API_STATUS.SUCCESS
        break
      case GET_OR_CREATE_RENTER_INFO_FAILED:
        draft.renterInfo.status = API_STATUS.ERROR
        break
      case SEARCH_RENTER_INFO_REQUESTED:
        draft.searchRenterInfo.status = API_STATUS.FETCHING
        break
      case SEARCH_RENTER_INFO_SUCCEEDED:
        draft.searchRenterInfo.data = payload
        draft.searchRenterInfo.status = API_STATUS.SUCCESS
        break
      case SEARCH_RENTER_INFO_FAILED:
        draft.searchRenterInfo.status = API_STATUS.ERROR
        break
      case UPDATE_DRAFT_QUOTATIONS_REQUESTED:
        draft.updateDraftQuotations.status = API_STATUS.UPDATING
        break
      case UPDATE_DRAFT_QUOTATIONS_SUCCEEDED:
        draft.draftRentalPayment.data.quotations = payload
        draft.updateDraftQuotations.status = API_STATUS.SUCCESS
        break
      case UPDATE_DRAFT_QUOTATIONS_FAILED:
        draft.updateDraftQuotations.status = API_STATUS.ERROR
        break
      case GET_AVAILABLE_PAYOUT_REQUESTED:
        draft.availablePayout.status = API_STATUS.FETCHING
        break
      case GET_AVAILABLE_PAYOUT_SUCCEEDED:
        draft.availablePayout.status = API_STATUS.SUCCESS
        draft.availablePayout.data = payload
        break
      case GET_AVAILABLE_PAYOUT_FAILED:
        draft.availablePayout.status = API_STATUS.ERROR
        break
      case GET_PAYMENT_SUMMARY_REQUESTED:
        draft.paymentSummary.status = API_STATUS.FETCHING
        break
      case GET_PAYMENT_SUMMARY_SUCCEEDED:
        draft.paymentSummary.status = API_STATUS.SUCCESS
        draft.paymentSummary.data = payload
        break
      case GET_PAYMENT_SUMMARY_FAILED:
        draft.paymentSummary.status = API_STATUS.ERROR
        break
      case CREATE_TENANT_REQUESTED:
        draft.createTenant.status = API_STATUS.UPDATING
        break
      case CREATE_TENANT_SUCCEEDED:
        draft.createTenant.status = API_STATUS.SUCCESS
        break
      case CREATE_TENANT_FAILED:
        draft.createTenant.status = API_STATUS.ERROR
        break
      default:
    }
    return draft
  })
