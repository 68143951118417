/* eslint react/no-array-index-key: "off", quote-props: "off" */

import React from "react"
import classNames from "classnames/bind"
import style from "./additional-information.scss"
import smokeIcon from "../../../images/icons/report/smoke.svg"
import vehicleIcon from "../../../images/icons/report/vehicle.svg"
import evictedIcon from "../../../images/icons/report/evicted.svg"
import bedbugsIcon from "../../../images/icons/report/big-bug.svg"
import illegalDrugIcon from "../../../images/icons/report/steroids.svg"
import liquidFurnitureIcon from "../../../images/icons/report/drop-silhouette.svg"
import bankruptcyIcon from "../../../images/icons/report/bankruptcy_icon.png"

const c = classNames.bind(style)

export const InfoItem = ({ icon, message, info, isNegative, isVehicles }) => (
  <li className={c("additional-item")}>
    <div className={c("additional-icon-wrapper")}>
      <img className={c("icon")} src={icon} width="16" height="16" alt="+" />
    </div>
    <div
      className={c(
        "additional-item-info-wrapper",
        { negative: isNegative },
        { vehicles: isVehicles },
      )}>
      <p className={c("additional-item-info")}>{message}</p>
      {info && <p className={c("additional-item-info-secondary")}>{info}</p>}
    </div>
  </li>
)

export const AdditionalInformationPartBody = ({ children }) => (
  <div className={c("additional-information-body")}>
    <ul className={c("additional-list")}>{children}</ul>
  </div>
)

export const VehicleItem = ({ message, info }) => (
  <InfoItem icon={vehicleIcon} isVehicles message={message} info={info} />
)

export const SmokingItem = ({ isSmoking }) => (
  <InfoItem
    icon={smokeIcon}
    isNegative={isSmoking}
    message={isSmoking ? "Smokes" : "Doesn't smoke."}
  />
)

export const EvictedItem = ({ beenEvicted }) => (
  <InfoItem
    icon={evictedIcon}
    isNegative={beenEvicted}
    message={
      beenEvicted ? "Has been evicted before." : "Has never been evicted."
    }
  />
)

export const BedbugsItem = ({ haveBedbugs }) => (
  <InfoItem
    icon={bedbugsIcon}
    isNegative={haveBedbugs}
    message={haveBedbugs ? "Has had bedbugs before." : "Has never had bedbugs."}
  />
)

export const IllegalDrug = ({ illegalDrug }) => (
  <InfoItem
    icon={illegalDrugIcon}
    isNegative={illegalDrug}
    message={
      illegalDrug
        ? "Has been convicted of selling, distributing, or manufacturing illegal drugs."
        : "Has never been convicted of selling, distributing, or manufacturing illegal drugs."
    }
  />
)

export const LiquidFurniture = ({ liquidFurniture }) => (
  <InfoItem
    icon={liquidFurnitureIcon}
    isNegative={liquidFurniture}
    message={
      liquidFurniture
        ? "Will have liquid filled furniture."
        : "Will not have liquid filled furniture."
    }
  />
)

export const BeenBankruptcy = ({ beenBankruptcy }) => (
  <InfoItem
    icon={bankruptcyIcon}
    isNegative={beenBankruptcy}
    message={
      beenBankruptcy
        ? "Has filed for bankruptcy before."
        : "Has never filed for bankruptcy."
    }
  />
)

export const AdditionalInformationList = ({ data, fullReport }) => (
  <div>
    <AdditionalInformationPartBody>
      <LiquidFurniture liquidFurniture={data.liquidFurniture} />
      <BedbugsItem haveBedbugs={data.haveBedbugs} />
      <EvictedItem beenEvicted={data.beenEvicted} />
      <BeenBankruptcy beenBankruptcy={data.beenBankruptcy} />
      <SmokingItem isSmoking={data.isSmoking} />
      <IllegalDrug illegalDrug={data.illegalDrug} />
    </AdditionalInformationPartBody>
    {fullReport && <hr />}
    {data.vehicles && data.vehicles.length > 0 && (
      <div>
        <h4 className={c("title-additional", { fullReport })}>Vehicles</h4>
        {!fullReport && <hr />}
        <AdditionalInformationPartBody>
          {data.vehicles.map((vehicle, key) => (
            <VehicleItem
              key={`vehicle ${key}`}
              message={`${vehicle.make} ${vehicle.model}`}
              info={`${vehicle.year} ${vehicle.color} ${vehicle.licensePlate}`}
            />
          ))}
        </AdditionalInformationPartBody>
      </div>
    )}
  </div>
)
