import React from "react"
import styled from "styled-components"
import { Panel } from "react-bootstrap"
import { COLOR } from "../../styles/settings"
import { H3 } from "../typography"

// language=SCSS prefix=dummy{ suffix=}
export const StyledPanelBox = styled.div`
  > .panel {
    border: ${props =>
      props.boldBorder
        ? `2px solid ${COLOR.lineGrey}`
        : `1px solid ${COLOR.lineGrey}`};
    border-radius: 0;
    > .panel-heading {
      background: ${COLOR.bgDarkGrey};
      color: ${COLOR.titleGrey};
      padding: ${props => (props.headerPad ? props.headerPad : "12px 25px")};
      border-bottom: none;
    }
    > .panel-body {
      padding: ${props => (props.bodyPad ? props.bodyPad : "15px 25px 25px")};
      position: relative;
      @media (max-width: 991px) {
        padding: 15px 15px 20px;
      }
    }
  }
  @media (max-width: 991px) {
    > .panel {
      margin-bottom: 0;
    }
  }
`

export const PanelBox = ({ children, header, ...props }) => (
  <StyledPanelBox {...props}>
    <Panel>
      <Panel.Heading>
        <H3 size="1.4em" strong color="textBlack" margin="0">
          {header}
        </H3>
      </Panel.Heading>
      <Panel.Body>{children}</Panel.Body>
    </Panel>
  </StyledPanelBox>
)

export const PanelHeading = ({ children }) => (
  <Panel.Heading>
    <Panel.Title toggle>{children}</Panel.Title>
  </Panel.Heading>
)

// language=SCSS prefix=dummy{ suffix=}
export const PanelFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.marginTop};
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    > button {
      margin-bottom: 15px;
      width: 100%;
    }
  }
`
