import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { sendInvitationEmail } from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'
import { makeSelectSendInvitationEmailStatus } from '../redux/selectors'

export const useSendInvitationEmail = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const status = useSelector(makeSelectSendInvitationEmailStatus())

  return {
    sendInvitationEmail: payload => dispatch(sendInvitationEmail(payload)),
    status,
  }
}
