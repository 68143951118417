export const EVENT_REPORT = {
  switchReportView: 'Switch report view',
  printReport: 'Print Report',
  saveReportPDF: 'Save Report as PDF',
  getCarRental: 'Get CAR rental app',
  exportCarRental: 'Export CAR rental app',
  viewReport: 'View report',
}

export const EVENT_REQUEST_SCREENING = {
  clickStartScreening: {
    name: 'Add New Property',
    properties: {
      popup: {
        click_from: 'popup',
      },
      leftMenu: {
        click_from: 'left_menu',
      },
      centerScreen: {
        click_from: 'center_screen',
      },
      mobileNavbar: {
        click_from: 'mobile_navbar',
      },
      howToPage: {
        click_from: 'how_to',
      },
      listingAction: {
        click_from: 'page_action',
      },
    },
  },
  saveNewProperty: 'Save New Listing',
  selectScreening: 'Select Screening Options',
  sendRequestEmail: {
    name: 'Send Request',
    properties: {
      email: {
        type: 'email',
      },
      textMessage: {
        type: 'text_message',
      },
    },
  },
  copyLeaseLink: 'Copy LeaseLink',
  printHandout: 'Print Screening Handout',
  updateHandoutMessage: 'Update Handout Message',
  clickFromCreateScreeningRequest: 'create screening request',
}

export const EVENT_PAY_REPORT = {
  viewPaymentModal: 'View payment modal',
  payReportSuccess: 'Pay report success',
  payReportFail: 'Pay report fail',
  viewOpenReportModal: 'View Open report modal',
  openReportSuccess: 'Open report success',
  openReportFail: 'Open report fail',
}

export const REF_CHECK = {
  EVENT_PROPERTY: {
    CLICK_FROM: {
      VIEW_REPORT: 'view_report',
      SCREENING_OPTION: 'screening_option',
    },
  },
}
export const OTHER_DOCS = {
  EVENT_PROPERTY: {
    CLICK_FROM: {
      VIEW_REPORT: 'view_report',
      SCREENING_OPTION: 'screening_option',
    },
  },
  DOCUMENT_TYPE_MAPPING: {
    paystubs: 'proof_income',
    bank_statement: 'bank_statements',
    tax_return: 'tax_returns',
    w2s: 'w-2s',
    passport: 'government_issued_id',
    pets: 'photo_each_pet',
    pet_application: 'pet_application',
    other: 'others',
  },
}
export const EVENT_ENTER_SELECT_OPTION_PAGE = {
  eventName: 'enter select option page',
  properties: ({ isPro, isFirstTimeUser }) => ({
    is_pro: isPro,
    is_first_time_user: isFirstTimeUser,
  }),
}

export const EVENT_OTHER_DOCS = {
  CLICK_UPDATE_REQUEST: {
    eventName: 'click update request',
    properties: ({ rentalId, doc, isPro }) => ({
      doc,
      is_pro: isPro,
      rental_id: rentalId,
    }),
  },
  CLICK_CANCEL_REQUEST: {
    eventName: 'click cancel request',
    properties: ({ rentalId, documentType, isPro }) => ({
      is_pro: isPro,
      rental_id: rentalId,
      doc: OTHER_DOCS.DOCUMENT_TYPE_MAPPING[documentType],
    }),
  },
  CLICK_CONFIRM_CANCEL_REQUEST: {
    eventName: 'click confirm cancel request',
    properties: ({ rentalId, documentType, isPro }) => ({
      is_pro: isPro,
      rental_id: rentalId,
      doc: OTHER_DOCS.DOCUMENT_TYPE_MAPPING[documentType],
    }),
  },
  DOWNLOAD_FULL_DOCS: {
    eventName: 'Download all other docs',
    properties: ({ email, rentalId }) => ({
      applicant: email,
      rental_id: rentalId,
    }),
  },
  DOWNLOAD_OTHER_DOCS: {
    eventName: 'Download other docs',
    properties: ({ email, rentalId, documentType }) => ({
      applicant: email,
      rental_id: rentalId,
      doc: OTHER_DOCS.DOCUMENT_TYPE_MAPPING[documentType],
    }),
  },
  CLICK_VIEW_DOCUMENT: {
    eventName: 'click view document',
    properties: ({ isPro, rentalId, documentType }) => ({
      is_pro: isPro,
      rental_id: rentalId,
      doc: OTHER_DOCS.DOCUMENT_TYPE_MAPPING[documentType],
    }),
  },
  CLICK_VIEW_ALL_DOCUMENTS: {
    eventName: 'click view all documents',
    properties: ({ isPro, rentalId, documentType }) => ({
      is_pro: isPro,
      rental_id: rentalId,
      doc: OTHER_DOCS.DOCUMENT_TYPE_MAPPING[documentType],
    }),
  },
  CLICK_ACCORDION_OTHER_DOC: {
    eventName: 'click accordion other doc',
  },
  CLICK_ACCORDION_REF_CHECK: {
    eventName: 'click accordion ref check',
  },
}

export const EVENT_FEATURE_BLOCK_MODAL = {
  VIEW_FEATURE_BLOCK_MODAL: {
    eventName: 'view feature block modal',
    properties: ({ action, feature }) => ({
      action_needed: action,
      click_from: feature,
    }),
  },
  CLOSE_FEATURE_BLOCK_MODAL: {
    eventName: 'close feature block modal',
    properties: ({ action, feature }) => ({
      action_needed: action,
      click_from: feature,
    }),
  },
  CLICK_LEARN_MORE_ON_MODAL: {
    eventName: 'click learn more on feature block modal',
    properties: ({ action, feature }) => ({
      action_needed: action,
      click_from: feature,
    }),
  },
  GO_TO_CHECK_OUT_PAGE: {
    eventName: 'click checkout',
    properties: ({ action, feature, subscriptionPeriod }) => ({
      action_needed: action,
      click_from: feature,
      subscription_period: subscriptionPeriod,
    }),
  },
}

export const FEATURE_BLOCK_ACTION = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  LEARN_MORE: 'LEARN_MORE',
  CHECKOUT: 'CHECKOUT',
}

export const RENTSPREE_PRO_FEATURE_NAME = {
  OTHER_DOCS: 'other_doc',
  REF_CHECK: 'ref_check',
}

export const SUBSCRIPTION_PERIOD = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
}

// TODO: Cleanup and refactor to new format
export const EVENT_RENTSPREE_PRO = {
  GET_RENTSPREE_PRO: {
    eventName: 'Get RentSpree Pro',
    properties: tab => ({
      click_from: tab,
    }),
  },
  REF_CHECK: {
    eventName: 'Request Ref Checks',
    properties: ({ email, rentalAppId, type, isPro, clickFrom, bundle }) => ({
      applicant: email,
      rental_id: rentalAppId,
      reference: type,
      is_pro: isPro,
      click_from: clickFrom,
      bundle,
    }),
  },
  OTHER_DOCS: {
    eventName: {
      request: 'Request other docs',
      download: 'Download other docs',
    },
    properties: ({
      email,
      rentalAppId,
      type,
      isPro,
      isResend,
      hasInstruction,
      clickFrom,
      bundle,
    }) => ({
      applicant: email,
      rental_id: rentalAppId,
      doc: type,
      is_pro: isPro,
      is_resend: isResend,
      has_instruction: hasInstruction,
      click_from: clickFrom,
      bundle,
    }),
    mapping: OTHER_DOCS.DOCUMENT_TYPE_MAPPING,
  },
  REF_CHECK_TAB: 'Click ref checks tab',
  OTHER_DOCS_TAB: 'Click other docs tab',
}

export const RENT_ESTIMATE = {
  viewSampleReport: 'View an sample rent estimate report',
  enterPage: 'Enter rent estimate menu',
  submitSuccess: 'Submit rent estimate request',
  purchaseSuccess: 'Purchase rent estimate report',
  DownloadSuccess: 'Download rent estimate report',
  generateRentEstimateFail: 'Generate rent estimate report fail',
}
