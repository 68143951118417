import React from "react"
import { ssoHandlerConnect } from "./connect"
import { loginRedirectHandler } from "../../helpers/sso-handler"

export default function ssoHandler(ReportComponent) {
  class ssoHandlerContainer extends React.Component {
    componentDidMount() {
      const { isLogin, match, actions } = this.props
      if (!isLogin) {
        const { userType } = match.params
        // passed user type to SSO redirect handling function
        loginRedirectHandler(userType)
      } else {
        // passed user type to reducer for using on sweet alert
        actions.saveURLParams(match.params)
      }
    }

    render() {
      const { isLogin } = this.props
      return isLogin && <ReportComponent {...this.props} />
    }
  }
  return ssoHandlerConnect(ssoHandlerContainer)
}
