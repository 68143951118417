import { ASSOCIATION_CONTACT_API } from 'containers/overview/association-contact/constants'

export const OPEN_INVITE_LANDLORD_RENT_PAYMENT_MODAL = 'OPEN_INVITE_LANDLORD_RENT_PAYMENT_MODAL'
export const CLOSE_INVITE_LANDLORD_RENT_PAYMENT_MODAL = 'CLOSE_INVITE_LANDLORD_RENT_PAYMENT_MODAL'

export const GET_LANDLORD_INFORMATION_CALL = 'GET_LANDLORD_INFORMATION_CALL'
export const GET_LANDLORD_INFORMATION_REQUEST = 'GET_LANDLORD_INFORMATION_REQUEST'
export const GET_LANDLORD_INFORMATION_SUCCESS = 'GET_LANDLORD_INFORMATION_SUCCESS'
export const GET_LANDLORD_INFORMATION_FAIL = 'GET_LANDLORD_INFORMATION_FAIL'

export const INVITE_AFTER_APPLICANT_ACCEPTED_CALL = 'INVITE_AFTER_APPLICANT_ACCEPTED_CALL'
export const INVITE_AFTER_APPLICANT_ACCEPTED_REQUEST = 'INVITE_AFTER_APPLICANT_ACCEPTED_REQUEST'
export const INVITE_AFTER_APPLICANT_ACCEPTED_SUCCESS = 'INVITE_AFTER_APPLICANT_ACCEPTED_SUCCESS'
export const INVITE_AFTER_APPLICANT_ACCEPTED_FAIL = 'INVITE_AFTER_APPLICANT_ACCEPTED_FAIL'

export const SEND_INVITATION_EMAIL_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'Email sent!',
  status: 'success',
}

export const SEND_INVITATION_EMAIL_ERROR_TOAST_CONFIG = {
  titleMessage: 'Cannot send email to landlord, please try again.',
  status: 'error',
}
export const GET_LANDLORD_INFORMATION_API_PATH = `${ASSOCIATION_CONTACT_API}/properties/:propertyId/contacts?roles[]=landlord`
