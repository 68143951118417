import { object } from "yup"
import {
  validateLength,
  validateDate,
  allowEmptyString,
} from "legacy/constants/validations"

export const schema = props => {
  const { values, hasConditionInput } = props || {}
  return {
    mapper: {
      ...values,
    },
    validation: object().shape({
      consumerReport: object().shape({
        creditScoreDetails: object().when("creditScore", (selected, current) =>
          selected
            ? current.shape({
                score: validateLength(true, 1, 3),
                date: allowEmptyString(validateDate(false)),
                factors: validateLength(false, 0, 500),
              })
            : current,
        ),
      }),
      conditionInput: validateLength(hasConditionInput || false, 0, 500),
    }),
  }
}
