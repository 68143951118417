import React from 'react'
import { StrongText } from './styles'

export const BUSINESS_TYPE = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
  UNKNOWN: 'unknown',
}

export const SELECTABLE_BUSINESS_TYPE = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
}

export const BUSINESS_STRUCTURE = {
  SINGLE_LLC: 'single_member_llc',
  MULTIPLE_LLC: 'multi_member_llc',
  PRIVATE_CORPORATION: 'private_corporation',
  PRIVATE_PARTNERSHIP: 'private_partnership',
  SOLE_PROPRIETORSHIP: 'sole_proprietorship',
}

export const VALIDATION_ERROR_MESSAGE = {
  DEFAULT: {
    REQUIRED: 'Required',
  },
  SSN: {
    RANGE: 'Allow only 9 digit number',
    TYPE_ERROR: 'Social Security Number must be a number',
  },
  BUSINESS_STRUCTURE: {
    REQUIRED: 'Company type is require',
  },
  IS_CHECKED_AGREEMENT: {
    REQUIRED: 'Please accept agreement',
  },
  PHONE_NUMBER: {
    RANGE: 'Allow only 10 digit number',
    TYPE_ERROR: 'Invalid mobile number',
  },
  TIN: {
    RANGE: 'Allow only 9 digit number',
    TYPE_ERROR: 'Taxpayer Identification Number must be a number',
  },
}

export const FORM_TITLE = {
  title: 'Tax Information',
  description: [
    'We need more information from you to complete tax verification.',
    <br />,
    ' This information will be securely shared with ',
    <StrongText>Stripe</StrongText>,
    ' and help ensure that payments you collect are correctly reported. ',
    <br />,
    <br />,
    'RentSpree never stores your information or shares with anyone, even your tenants.',
  ],
}

export const BUSINESS_TYPE_FORM_TITLE = 'Are you taxed as an individual or a company?'

export const FOOTER_CONTENT =
  'RentSpree uses Stripe to get you paid quickly and keep your personal and payment information secure. Thousands of companies around the world trust Stripe to process payments for their users. Set up a Stripe account to get paid with RentSpree.'

export const OWNER_CHECK_BOX_LABEL = 'I am an owner of this company'

export const personalTaxFormAdditionalInformation = (businessType, businessStructure) => {
  if (businessType === BUSINESS_TYPE.INDIVIDUAL) {
    return 'These information will appear on your tax documents.'
  }
  switch (businessStructure) {
    case BUSINESS_STRUCTURE.MULTIPLE_LLC:
    case BUSINESS_STRUCTURE.PRIVATE_PARTNERSHIP:
    case BUSINESS_STRUCTURE.PRIVATE_CORPORATION:
      return 'This information is used to verify that you are a representative of this company.'
    case BUSINESS_STRUCTURE.SINGLE_LLC:
    case BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP:
    default:
      return null
  }
}

export const isMultiMemberCompany = businessStructure =>
  [
    BUSINESS_STRUCTURE.MULTIPLE_LLC,
    BUSINESS_STRUCTURE.PRIVATE_CORPORATION,
    BUSINESS_STRUCTURE.PRIVATE_PARTNERSHIP,
  ].includes(businessStructure)
