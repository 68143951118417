import { buildPath } from '@rentspree/path'
import { AUTH_WEB_LOGIN, NODE_ENV, MAIN_URL } from '../../env'

const href = url => {
  if (window && window.location) {
    window.location.href = url
  }
}

const getWelcomeQuery = url => {
  if (url.search(`${MAIN_URL}/dashboard/v2/rent-payments`) !== -1) {
    return { welcome: 'rent-payment-landlord' }
  }
  return {}
}

export const redirectToLogin = isRedirectTo => {
  if (NODE_ENV === 'production' || NODE_ENV === 'test') {
    let queryString = null
    if (isRedirectTo) {
      queryString = { continue: window.location.href }
    }
    const welcomeQuery = getWelcomeQuery(window.location.href)
    const url = buildPath(`${MAIN_URL}${AUTH_WEB_LOGIN}`, null, { ...queryString, ...welcomeQuery })
    href(url)
  }
}

export default href
