import styled from 'styled-components'
import { S14, B12 } from 'components/typography'
import { COLOR } from 'styles/settings'

export const TitleWrapper = styled.div`
  margin-bottom: 30px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Description = styled(S14)`
  ${props => props.weight && `font-weight: ${props.weight}`};
  margin-bottom: 0;
`

export const StrongText = styled.strong`
  font-weight: 600;
`

export const FooterContent = styled(B12)`
  text-align: center;
  color: ${COLOR.placeholderGrey};
`

export const Logo = styled.img``

export const LogoWrapper = styled.div`
  height: 20px;
  margin-bottom: 60px;
  & > :not(:first-child) {
    padding-left: 7px;
  }
  & > :not(:last-child) {
    border-right: 1px solid ${COLOR.placeholderGrey};
    height: 22px;
    padding-right: 13px;
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`

export const TextLink = styled.span`
  cursor: pointer;
  color: ${COLOR.fontBlue};
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
`

export const CompanyOwnerSectionWrapper = styled.div`
  margin-top: ${props => props.marginTop || '20'}px;
`
