import { USER_ROLE_FOR_PROPERTY, USER_INPUT_ROLE } from "containers/request/step-create-property/constants"

export const USER_ROLE_FOR_PROPERTY_FORM_TITLE = "What's your role for this property?"

export const USER_ROLE_FOR_PROPERTY_FORM_SUBTITLE = "Help us personalize your RentSpree experience"

export const USER_ROLE_FOR_PROPERTY_RADIO_CHOICES = [
  { label: 'Owner', id: 'add-new-prop-role-owner', value: USER_ROLE_FOR_PROPERTY.LANDLORD },
  { label: 'Listing agent', id: 'add-new-prop-role-listing-agent', value: USER_ROLE_FOR_PROPERTY.LISTING_AGENT },
  { label: "Renter's agent", id: 'add-new-prop-role-renter_agent', value: USER_ROLE_FOR_PROPERTY.RENTER_AGENT },
  { label: 'Property manager', id: 'add-new-prop-role-prop-manager', value: USER_INPUT_ROLE.PROPERTY_MANAGER },
  { label: 'Other', id: 'add-new-prop-role-other', value: USER_INPUT_ROLE.OTHER },
]

export const OTHER_ROLE_TEXT_INPUT_PLACEHOLDER = 'Enter your role'
