import {
  BlueGradientButton,
  Button,
  S16,
  S24,
  Span,
} from "@rentspree/component-v2"
import styled from "styled-components"
import { COLOR } from "styles/settings"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: Source Sans Pro;
  align-items: center;
`

export const SharingWrapper = styled.div`
  padding: 30px 20px;
  background: ${COLOR.blueLv7};
  border-radius: 8px;
  max-width: 500px;
  .text {
    padding: 0;
  }
  .houseIcon {
    align-self: baseline;
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    max-width: none;
  }
`

export const InnerWrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
    max-width: none;
  }
`

export const SubtitleWrapper = styled(S24)`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
`

export const SendRequestSpan = styled.span`
  margin-bottom: 10px;
`

export const EmailSpan = styled.span`
  color: ${COLOR.blueLv2};
`

export const ActionButton = styled(BlueGradientButton)`
  white-space: nowrap;
  width: 168px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 25px;
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const HomeIcon = styled.i``

export const HomeInnerText = styled(Span)`
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 16px;
`
export const PrevButton = styled(Button)`
  width: 99px;
  background: ${COLOR.white};
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
    justify-content: center;
  }
`
export const TextButton = styled(S16)`
  cursor: pointer;
  align-items: center;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
    padding: 0 20px;
  }
`
