import toNumber from 'lodash/toNumber'
import moment from 'moment'

import { ROLE_OPTIONS } from 'containers/contacts/constants'
import { generateImageFromName, getNameFromEmail } from 'containers/contacts/helpers'

export const contactDataMapper = contact => {
  const imagePlaceholder = generateImageFromName({
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    email: contact?.email,
  })
  const lastContactedAt = moment(contact?.lastContactedAt).format('MMM DD, YYYY')
  const firstName = contact?.firstName || getNameFromEmail(contact?.email)
  const mobileNumber = contact?.mobileNumber || '-'
  const option = ROLE_OPTIONS.find(roleOption => roleOption.value === contact?.role)

  return {
    ...contact,
    imagePlaceholder,
    lastContactedAt,
    firstName,
    mobileNumber,
    role: option?.label,
  }
}

export const contactSortMapper = value => {
  const sortedVal = toNumber(value)
  switch (sortedVal) {
    case 1:
      return 'asc'
    case -1:
      return 'desc'
    default:
      return null
  }
}

export const generateDisplayingTags = (allTags = []) => {
  const newArray = [...allTags]
  newArray.reverse()
  if (newArray.length > 100) return newArray.slice(0, 100)
  return newArray
}
