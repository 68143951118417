import { RENT_ESTIMATE } from "constants/error-messages"
import { TYPES } from "./const"

export const createRentEstimate = data => ({
  type: TYPES.CREATE_RENT_ESTIMATE_CALL,
  payload: {
    data,
  },
})

export const createRentEstimateRequest = () => ({
  type: TYPES.CREATE_RENT_ESTIMATE_REQUEST,
})

export const createRentEstimateSuccess = data => ({
  type: TYPES.CREATE_RENT_ESTIMATE_SUCCESS,
  payload: data,
})

export const createRentEstimateFailed = data => ({
  type: TYPES.CREATE_RENT_ESTIMATE_FAILED,
  payload: data,
})

export const submitPaymentRentEstimate = data => ({
  type: TYPES.SUBMIT_RENT_ESTIMATE_CALL,
  payload: data,
})

export const submitPaymentRentEstimateRequest = () => ({
  type: TYPES.SUBMIT_RENT_ESTIMATE_REQUEST,
})

export const submitPaymentRentEstimateSuccess = data => ({
  type: TYPES.SUBMIT_RENT_ESTIMATE_SUCCESS,
  payload: data,
})

export const submitPaymentRentEstimateFailed = data => ({
  type: TYPES.SUBMIT_RENT_ESTIMATE_FAILED,
  payload: data,
})

export const clearPaymentError = () => ({ type: TYPES.CLEAR_PAYMENT })

export const closeRentRangeError = () => ({
  type: TYPES.CLEAR_RENT_RANGE_ERROR,
})

export const handlePaymentModal = () => ({ type: TYPES.PAYMENT_MODAL })

export const handleModalSuccess = () => ({ type: TYPES.MODAL_SUCCESS })

export const getPaymentRentEstimateRequest = () => ({
  type: TYPES.GET_PAYMENT_RENT_ESTIMATE_REQUEST,
})

export const getPaymentRentEstimateSuccess = data => ({
  type: TYPES.GET_PAYMENT_RENT_ESTIMATE_SUCCESS,
  payload: data,
})

export const getPaymentRentEstimateFailed = data => ({
  type: TYPES.GET_PAYMENT_RENT_ESTIMATE_FAILED,
  payload: data,
})

export const setDefaultRentEstimatePaymentError = () => ({
  type: TYPES.SUBMIT_RENT_ESTIMATE_FAILED,
  payload: {
    rentEstimatePaymentError: {
      message: RENT_ESTIMATE.PAYMENT_FAILED,
      contact: RENT_ESTIMATE.CONTACT,
    },
  },
})
