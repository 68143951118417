import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { UPDATE_PAYOUT_METHOD_API } from './constants'

export const updatePayoutMethodInstance = ({ rentalPaymentId, payoutMethodId }) =>
  apiInstanceWithErrorHandler.patch(buildPath(UPDATE_PAYOUT_METHOD_API, { rentalPaymentId }), {
    payoutMethodId,
  })
