import produce from "immer"
import {
  GET_PROPERTY_CALL_REQUEST,
  GET_PROPERTY_CALL_SUCCESS,
  GET_PROPERTY_CALL_FAILED,
  UPDATE_PROPERTY_DETAILS_REQUEST,
  UPDATE_PROPERTY_DETAILS_SUCCESS,
  UPDATE_PROPERTY_DETAILS_FAILED,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_SUCCESS,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_REQUEST,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_FAILED,
} from "./const"

export const initialState = {
  error: null,
  property: {},
  isFetchingProperty: false,
  isUpdatingPropertyDetails: false,
  isCreatingContact: false,
}

/* eslint-disable no-param-reassign */
const stepPropertyDetailsReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_PROPERTY_CALL_REQUEST:
        draft.isFetchingProperty = true
        break
      case GET_PROPERTY_CALL_SUCCESS:
        draft.isFetchingProperty = false
        draft.property = payload
        break
      case GET_PROPERTY_CALL_FAILED:
        draft.isFetchingProperty = false
        draft.error = payload
        break
      case UPDATE_PROPERTY_DETAILS_REQUEST:
        draft.isUpdatingPropertyDetails = true
        break
      case UPDATE_PROPERTY_DETAILS_SUCCESS:
        draft.isUpdatingPropertyDetails = false
        break
      case UPDATE_PROPERTY_DETAILS_FAILED:
        draft.isUpdatingPropertyDetails = false
        draft.error = payload.error
        break
      case CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_REQUEST:
        draft.isCreatingContact = true
        draft.isCreatingContactSuccess = false
        break
      case CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_SUCCESS:
        draft.isCreatingContact = false
        draft.isCreatingContactSuccess = true
        break
      case CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_FAILED:
        draft.isCreatingContact = false
        draft.isCreatingContactSuccess = false
        draft.error = payload.error
        break

      default:
        break
    }
    return draft
  })

export default stepPropertyDetailsReducer
