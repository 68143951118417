import { takeEvery, put, call } from 'redux-saga/effects'

import { getUnseenRentalPaymentNotificationsSaga } from 'containers/navbar/saga'

import { SET_SEEN_NOTIFICATION, setSeenNotificationByIdAPI } from '../../actions'
import { setSeenNotificationByIdInstance } from '../../api-instances'

export function* setSeenRentalPaymentNotificationSaga({ payload }) {
  try {
    yield put(setSeenNotificationByIdAPI.request())
    yield call(setSeenNotificationByIdInstance, payload)
    yield put(setSeenNotificationByIdAPI.success())
    yield call(getUnseenRentalPaymentNotificationsSaga)
  } catch (e) {
    yield put(setSeenNotificationByIdAPI.failure())
  }
}

export function* watchSetSeenNotificationSaga() {
  yield takeEvery(SET_SEEN_NOTIFICATION, setSeenRentalPaymentNotificationSaga)
}
