import generateApiCall from "utils/generate-api-call"
import {
  GET_ALL_PROPERTY_LIST_CALL,
  GET_ALL_PROPERTY_LIST_REQUEST,
  GET_ALL_PROPERTY_LIST_SUCCESS,
  GET_ALL_PROPERTY_LIST_FAILED,
  CLEAR_SELECT_PROPERTY_WARNING,
  INIT_SELECT_PROPERTY_WARNING,
  AUTO_SELECT_PROPERTY,
} from "./const"

export const getPropertyList = payload => ({
  type: GET_ALL_PROPERTY_LIST_CALL,
  payload,
})
export const autoSelectProperty = payload => ({
  type: AUTO_SELECT_PROPERTY,
  payload,
})
export const initClearSelectPropertyWarning = payload => ({
  type: INIT_SELECT_PROPERTY_WARNING,
  payload,
})

export const clearSelectPropertyWarning = () => ({
  type: CLEAR_SELECT_PROPERTY_WARNING,
})

export const getPropertyListApi = generateApiCall([
  GET_ALL_PROPERTY_LIST_REQUEST,
  GET_ALL_PROPERTY_LIST_SUCCESS,
  GET_ALL_PROPERTY_LIST_FAILED,
])
