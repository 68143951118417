import get from "lodash/get"
import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const getScreeningFee = state => get(state, "screeningFee", initialState)

export const selectScreeningFeeData = () =>
  createSelector(
    getScreeningFee,
    state => get(state, "screeningFeeData"),
  )

export const selectIsFetchingLraRequest = () =>
  createSelector(
    getScreeningFee,
    state => get(state, "isFetchingLraRequest"),
  )

export const selectIsUpdatingLraRequest = () =>
  createSelector(
    getScreeningFee,
    state => get(state, "isUpdatingLraRequest"),
  )
