import produce from 'immer'
import {
  CLEAR_INCOME_VERIFICATION,
  GENERATE_INCOME_VERIFICATION_REPORT_FAILURE,
  GENERATE_INCOME_VERIFICATION_REPORT_REQUEST,
  GENERATE_INCOME_VERIFICATION_REPORT_SUCCESS,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_FAILURE,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_REQUEST,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_SUCCESS,
  GET_INCOME_VERIFICATION_REPORT_FAILURE,
  GET_INCOME_VERIFICATION_REPORT_REQUEST,
  GET_INCOME_VERIFICATION_REPORT_SUCCESS,
} from './constants'

export const initialState = {
  incomeVerification: {},
  reportId: null,
  reportData: null,
  reportStatus: null,
  isFetchingIncomeVerification: false,
  isCallingGenerateReport: false,
  isFetchingReport: false,
  getReportFailStatusCode: '',
  getIncomeVerificationFailStatusCode: '',
  isViewedByAdmin: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_REQUEST: {
        draft.isFetchingIncomeVerification = true
        draft.getIncomeVerificationFailStatusCode = initialState.getIncomeVerificationFailStatusCode
        break
      }
      case GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_SUCCESS: {
        draft.incomeVerification = payload
        draft.isFetchingIncomeVerification = false
        draft.getIncomeVerificationFailStatusCode = initialState.getIncomeVerificationFailStatusCode
        break
      }
      case GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_FAILURE: {
        draft.isFetchingIncomeVerification = false
        draft.incomeVerification = initialState.incomeVerification
        draft.getIncomeVerificationFailStatusCode = payload
        break
      }
      case GENERATE_INCOME_VERIFICATION_REPORT_REQUEST: {
        draft.isCallingGenerateReport = true
        break
      }
      case GENERATE_INCOME_VERIFICATION_REPORT_SUCCESS: {
        const { report } = payload
        draft.isCallingGenerateReport = false
        draft.reportId = report.reportId
        break
      }
      case GENERATE_INCOME_VERIFICATION_REPORT_FAILURE: {
        draft.isCallingGenerateReport = false
        break
      }
      case GET_INCOME_VERIFICATION_REPORT_REQUEST: {
        draft.isFetchingReport = true
        draft.getReportFailStatusCode = ''
        break
      }
      case GET_INCOME_VERIFICATION_REPORT_SUCCESS: {
        const {
          report: reportData,
          status: reportStatus,
          id: reportId,
          isAdmin: isViewedByAdmin,
        } = payload
        draft.isFetchingReport = false
        draft.reportData = reportData
        draft.reportStatus = reportStatus
        draft.reportId = reportId
        draft.getReportFailStatusCode = ''
        draft.isViewedByAdmin = isViewedByAdmin
        break
      }
      case GET_INCOME_VERIFICATION_REPORT_FAILURE: {
        draft.getReportFailStatusCode = payload
        draft.reportData = initialState.reportData
        draft.reportId = initialState.reportId
        draft.reportStatus = initialState.reportStatus
        draft.isFetchingReport = false
        draft.isViewedByAdmin = initialState.isViewedByAdmin
        break
      }
      case CLEAR_INCOME_VERIFICATION: {
        draft.incomeVerification = initialState.incomeVerification
        draft.reportId = initialState.reportId
        draft.reportData = initialState.reportData
        draft.reportStatus = initialState.reportStatus
        draft.reportOpened = initialState.reportOpened
        draft.getReportFailStatusCode = initialState.getReportFailStatusCode
        draft.getIncomeVerificationFailStatusCode = initialState.getIncomeVerificationFailStatusCode
        draft.isViewedByAdmin = initialState.isViewedByAdmin
        break
      }
      default:
        break
    }
    return draft
  })

export default reducer
