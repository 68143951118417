export const reducerIsFetching = (state = false, action, types = []) => {
  switch (action.type) {
    case types[0]:
      return true
    case types[1]:
    case types[2]:
      return false
    default:
      return state
  }
}

export const reducerError = (state = null, action, types = []) => {
  switch (action.type) {
    case types[0]:
      return { message: action.message || "ERROR!!!! FIX IT!!!" }
    case types[1]:
      return null
    default:
      return state
  }
}
