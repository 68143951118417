import PropTypes from "prop-types"
import React from "react"

import Hr from "@rentspree/component-v2/dist/hr"
import S24 from "@rentspree/component-v2/dist/typography/s24"

import { Div } from "components/layout/main"

const SubtitleWithHr = ({
  size,
  text,
  hrMargin,
  textMargin,
  mTextMargin,
  mHrMargin,
}) => (
  <Div sourceSansPros>
    <S24
      size={size}
      margin={textMargin}
      mMargin={mTextMargin}
      lineHeight="32px">
      {text}
    </S24>
    <Hr margin={hrMargin} mMargin={mHrMargin} />
  </Div>
)

SubtitleWithHr.defaultProps = {
  size: "24px",
  textMargin: "0 0 8px 0",
  mTextMargin: "0 0 9px 0",
  hrMargin: "0px 0px 20px",
  mHrMargin: "0px 0px 20px",
}

SubtitleWithHr.propsType = {
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  textMargin: PropTypes.string,
  mTextMargin: PropTypes.string,
  hrMargin: PropTypes.string,
  mHrMargin: PropTypes.string,
}

export default SubtitleWithHr
