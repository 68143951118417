import React from 'react'
import { Hr } from '@rentspree/component-v2'
import HeaderPrint from 'containers/reports/header'
import {
  FontReport,
  PanelContainer,
  Section,
  WrapperReport,
} from 'legacy/components/reports-v2/share/doc-style'
import {
  Header,
  HeaderDoc,
  PanelArea,
  ReportsBody,
} from 'legacy/components/reports-v2/share/doc-components'

const FrozenCreditReport = ({ applicantName, generatedOn, expiresOn, email, phone }) => (
  <WrapperReport>
    <HeaderPrint />
    <ReportsBody>
      <HeaderDoc text="Credit Report" transUnion={{ generatedOn, expiresOn }} />
      <Header Name={applicantName} Email={email} Phone={phone} />
      <PanelContainer>
        <Section>
          <PanelArea>
            <Hr margin="0 0 20px 0" />
            <FontReport marginB Red Bold>
              The credit freeze.
            </FontReport>
            <FontReport marginB FourTeen>
              Agent/owner cannot view the report since it is frozen with TransUnion.
            </FontReport>
            <FontReport marginB FourTeen>
              To unfreeze the account, please have <strong>{applicantName}</strong> follow the
              instructions below:
            </FontReport>
            <FontReport lineHeight={1.71} paddingLeft={2} FourTeen>
              <strong>1.</strong> <strong>{applicantName}</strong> call the TransUnion security
              freeze line at (888) 909-8872.
            </FontReport>
            <FontReport lineHeight={1.71} paddingLeft={2} FourTeen>
              <strong>2.</strong> Request a <strong>Global Lift</strong> and provide the
              applicant&apos;s information.
            </FontReport>
            <FontReport marginB lineHeight={1.71} paddingLeft={2} FourTeen>
              <strong>3.</strong> After successfully lifting the freeze, please email us at{' '}
              <strong>support@rentspree.com</strong> to regenerate the report.
            </FontReport>
            <FontReport FourTeen>This process may take up to 72 hours.</FontReport>
            <FontReport marginB FourTeen>
              If you have any questions, contact <strong>support@rentspree.com</strong> or (323)
              515-7757
            </FontReport>
          </PanelArea>
        </Section>
      </PanelContainer>
    </ReportsBody>
  </WrapperReport>
)

export default FrozenCreditReport
