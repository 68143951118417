export const CRIMINAL_RECORD_TYPE = {
  Criminal: "Criminal/Traffic",
  MostWanted: "MostWanted",
  SexOffender: "SexOffender",
  OFAC: "OFAC",
}

export const ATTENTION_WORDING = [
  "Records coverage may vary due to (1) jurisdictions limiting what records are eligible to return and (2) TransUnion limiting records that do not meet its data quality standards.",
  'All data provided is intended solely for the customer who initially receives such data from the provider. The provider cannot guarantee or warrant the accuracy, correctness, or completeness of the data. The provider delivers all data to customers on an "as is" "as available" basis without any express or implied warranty, guaranty, or representation of any kind concerning the data itself, its merchantability, or its fitness for a particular purpose or function. Neither the provider nor any of their affiliates shall be liable for any damages of whatever kind may result from the customer"s reliance on (or use of) the data provided, even if the provider, or any of their affiliates has been alerted to the possibility of such damages. The data information may include records that have been expunged, sealed, or otherwise have become inaccessible to the public since that date. By accessing any such data, the customer acknowledges and agrees that the customer has not relied on anything that may be inconsistent with this Legal Statement.',
  "RentSpree does not guarantee the accuracy or truthfulness of the information that is the subject of the investigation, only that the information is accurately copied from public records. Information generated as a result of identify theft, including evidence of criminal activity, may be inaccurately associated with the consumer who is the subject of the report.",
]
