import styled from "styled-components"
import { ANIMATE, COLOR } from "../../styles/settings"

export const TabWrapper = styled.div`
  flex-grow: 1;
  padding: 5px 0;
  width: 100%;
  overflow: auto;
  text-align: left;
  transition: width ease ${ANIMATE.fast};
`

export const Tabs = styled.div`
  background-color: ${COLOR.white};
  border-bottom: 1px solid ${COLOR.lineGrey};
  @media (max-width: 991px) {
    display: none;
  }
`

export const TabList = styled.ul`
  display: flex;
  width: fit-content;
  margin: 0;
  padding: 0 32px;
`

export const TabItem = styled.li`
  flex-grow: 1;
  display: inline-block;
  color: ${COLOR.textBlack};
  display: block;
  width: 33px;
  padding: 11px 0;
  margin-right: 14px;
  font-size: 14px;
  text-align: center;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  ${props =>
    props.active &&
    `border-bottom: 2px solid;
    color: ${COLOR.activeBlue};`}
  &:hover {
    border-bottom: 2px solid;
    color: ${COLOR.darkDeepBlue};
  }
`
