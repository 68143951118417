import React from 'react'
import { Landing } from 'components/landing'
import reportUnavailableSvg from 'legacy/images/credit-report/report-unavailable.svg'
import { Level2Template } from '@rentspree/component-2023.components.templates.level-2-template'
import useMobile from 'hooks/use-mobile'
import { MOBILE_THRESHOLD } from 'utils/constants'
import Breadcrumb2023 from 'containers/breadcrumb-2023'

const TenantScreeningPending = history => {
  const isMobile = useMobile(MOBILE_THRESHOLD)

  return (
    <>
      <Level2Template
        onClickBack={history.goBack}
        isMobile={isMobile}
        breadcrumb={<Breadcrumb2023 />}
      />
      <Landing
        title="Reports Pending"
        description="Report are waiting for the agent/owner to open."
        imgSrc={reportUnavailableSvg}
      />
    </>
  )
}

export default TenantScreeningPending
