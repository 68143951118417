import { buildPath } from '@rentspree/path'
import { takeLatest, all, put, call } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'
import { apiInstance } from 'utils/api-interceptor'

import { deleteContactApi } from '../detail/saga'
import { createContactApi } from '../saga'

import { deleteContactApiState, addToContactApiState, getContactByEmailApiState } from './actions'
import {
  GET_CONTACT_BY_EMAIL_API,
  GET_CONTACT_CALL,
  DELETE_CONTACT_CALL,
  ADD_TO_CONTACT_CALL,
  DELETE_CONTACT_SUCCESS_TOAST_CONFIG,
  DELETE_CONTACT_FAILURE_TOAST_CONFIG,
  ADD_TO_CONTACT_SUCCESS_TOAST_CONFIG,
  ADD_TO_CONTACT_FAILURE_TOAST_CONFIG,
} from './constants'

export const getContactByEmailApi = email =>
  apiInstance.get(
    buildPath(GET_CONTACT_BY_EMAIL_API, {
      email,
    }),
  )

export function* getContactSaga({ payload }) {
  yield put(getContactByEmailApiState.request())
  try {
    const response = yield call(getContactByEmailApi, payload.email)
    yield put(getContactByEmailApiState.success(response))
  } catch (err) {
    yield put(getContactByEmailApiState.failure(err))
  }
}

export function* deleteContactSaga({ payload }) {
  yield put(deleteContactApiState.request())
  try {
    const response = yield call(deleteContactApi, payload.contactId)
    yield put(deleteContactApiState.success(response))
    if (payload.callback) yield call(payload.callback)
    yield put(addToast(DELETE_CONTACT_SUCCESS_TOAST_CONFIG))
  } catch (err) {
    yield put(deleteContactApiState.failure(err))
    yield put(addToast(DELETE_CONTACT_FAILURE_TOAST_CONFIG))
  }
}

export function* addToContactSaga({ payload }) {
  yield put(addToContactApiState.request())
  try {
    const response = yield call(createContactApi, { contact: payload.contact })
    yield put(addToContactApiState.success(response))
    yield put(addToast(ADD_TO_CONTACT_SUCCESS_TOAST_CONFIG))
  } catch (err) {
    yield put(addToContactApiState.failure(err))
    yield put(addToast(ADD_TO_CONTACT_FAILURE_TOAST_CONFIG))
  }
}

export function* watchGetContact() {
  yield takeLatest(GET_CONTACT_CALL, getContactSaga)
}

export function* watchDeleteContact() {
  yield takeLatest(DELETE_CONTACT_CALL, deleteContactSaga)
}

export function* watchAddToContact() {
  yield takeLatest(ADD_TO_CONTACT_CALL, addToContactSaga)
}

export function* rootSaga() {
  yield all([watchGetContact(), watchDeleteContact(), watchAddToContact()])
}

export default rootSaga
