import React from 'react'
import propTypes from 'prop-types'

import CenterWithError from 'components/templates/center-with-error-template'
import SelectPropertyForm from './select-property-form'

const SelectPropertyTemplate = ({
  isError,
  errorDetail,
  handleCloseError,
  noHeightCal,
  warning,
  errors,
  propertyList,
  handleSubmit,
  backToDashboard,
  onCreateProperty,
  allowToCreateProperty = true,
  dropdownLabel,
  pageTitle,
  continueText,
  createNewPropertyText,
}) => (
  <CenterWithError
    isError={isError}
    errorDetail={errorDetail}
    handleCloseError={handleCloseError}
    noHeightCal={noHeightCal}
    warning={warning}
    error={errors}
  >
    <SelectPropertyForm
      onSubmit={handleSubmit}
      backToDashboard={backToDashboard}
      onCreateProperty={onCreateProperty}
      errors={errors}
      propertyList={propertyList}
      allowToCreateProperty={allowToCreateProperty}
      dropdownLabel={dropdownLabel}
      pageTitle={pageTitle}
      continueText={continueText}
      createNewPropertyText={createNewPropertyText}
    />
  </CenterWithError>
)

SelectPropertyTemplate.propTypes = {
  isError: propTypes.bool,
  errorDetail: propTypes.object,
  handleCloseError: propTypes.func,
  noHeightCal: propTypes.bool,
  warning: propTypes.bool,
  errors: propTypes.object,
  propertyList: propTypes.array,
  handleSubmit: propTypes.func,
  backToDashboard: propTypes.func,
  onCreateProperty: propTypes.func,
  allowToCreateProperty: propTypes.bool,
  pageTitle: propTypes.string,
  dropdownLabel: propTypes.string,
  continueText: propTypes.string,
  createNewPropertyText: propTypes.string,
}

SelectPropertyTemplate.defaultProps = {
  allowToCreateProperty: true,
  pageTitle: 'Select a property',
  dropdownLabel: 'Select Your Property',
  continueText: 'Next',
  createNewPropertyText: 'create a new property',
}

export default SelectPropertyTemplate
