import debounce from "lodash/debounce"
import PropTypes from "prop-types"
import get from "lodash/get"
import isNil from "lodash/isNil"
import React, { useCallback, useState } from "react"
import styled from "styled-components"

import TextInput from "@rentspree/component-v2/dist/input/text"
import { COLOR } from "styles/settings"
import { CloseIcon } from "./close-icon"

const InputWrapper = styled.div`
  float: left;
  -webkit-box-flex: 1;
  flex-grow: 1;
  position: relative;
  margin: ${props => (props.margin ? props.margin : "0")};

  @media (max-width: 991px) {
    margin: ${props => (props.mMargin ? props.mMargin : "0")};
  }
`

// to remove fontawesome pro, we need to hide default placeholder
// and use the custom one below instead
const InputBox = styled(TextInput)`
  ::placeholder {
    color: transparent;
  }
`

const CustomPlaceholder = styled.div`
  white-space: pre;
  position: absolute;
  top: 0;
  left: ${({hasIcon}) => (hasIcon ? '12px': '16px')};
  width: calc(100% - 24px);
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: ${COLOR.placeholderGrey};
  overflow: hidden;
  font-family: Source Sans Pro, "Font Awesome 6 Free";
  font-size: 16px;
`

const HIDE_LABEL_STYLE = { display: "none" }

export const DebounceInput = ({
  placeholder,
  placeholderIcon,
  value,
  onChange,
  onFocus,
  name,
  label,
  type = "text",
  id = "debounceInput",
  maxLength = 50,
  debounceTimeout = 300,
  width,
  height,
  margin,
  mMargin,
  className,
  isShowClearButton = false,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [isInitial, setIsInitial] = useState(false)
  const onChangeDebounced = useCallback(debounce(onChange, debounceTimeout), [
    onChange,
  ])
  const placeholderIconString = placeholderIcon
    ? `${String.fromCharCode(placeholderIcon)}   `
    : ""

  const handleChange = event => {
    event.preventDefault()
    const targetValue = get(event, "target.value")
    setInputValue(targetValue)
    setIsInitial(false)
  }

  const handleClear = () => {
    setInputValue("")
  }

  React.useEffect(() => {
    if (inputValue === undefined && value !== undefined) {
      setInputValue(value)
      setIsInitial(true)
    }
  }, [value])

  React.useEffect(() => {
    if (!isInitial && !isNil(inputValue)) {
      onChangeDebounced(inputValue)
    }
  }, [inputValue])

  return (
    <InputWrapper
      width={width}
      margin={margin}
      mMargin={mMargin}
      className={className}>
      <InputBox
        id={id}
        type={type}
        name={name}
        value={inputValue}
        onChange={handleChange}
        onFocus={onFocus}
        label={label}
        labelStyle={label ? undefined : HIDE_LABEL_STYLE}
        maxLength={maxLength}
        height={height}
        disabled={disabled}
      />
      {!inputValue && (
        <CustomPlaceholder hasIcon={!!placeholderIcon}>
          <span style={{ fontWeight: 900 }}>{placeholderIconString}</span>
          <span>{placeholder}</span>
        </CustomPlaceholder>
      )}
      {isShowClearButton && inputValue && <CloseIcon onClick={handleClear} />}
    </InputWrapper>
  )
}

DebounceInput.propTypes = {
  placeholder: PropTypes.string,
  placeholderIcon: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  debounceTimeout: PropTypes.number,
  height: PropTypes.string,
  margin: PropTypes.string,
  mMargin: PropTypes.string,
}

DebounceInput.defaultProps = {}

export default DebounceInput
