import generateApiCall from "utils/generate-api-call"
import {
  GET_IS_WHITELISTING_CALL,
  GET_IS_WHITELISTING_REQUEST,
  GET_IS_WHITELISTING_SUCCESS,
  GET_IS_WHITELISTING_FAILURE,
} from "./constants"

export const getIsWhitelisting = () => ({
  type: GET_IS_WHITELISTING_CALL,
})

export const getIsWhitelistingApi = generateApiCall([
  GET_IS_WHITELISTING_REQUEST,
  GET_IS_WHITELISTING_SUCCESS,
  GET_IS_WHITELISTING_FAILURE,
])
