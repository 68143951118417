/* eslint-disable radix */
import { isEmpty } from 'lodash'

import { PAYEE_IDV_STATUS } from '../constants'

import {
  WELCOME_LANDLORD_STEP_KEY,
  WELCOME_LANDLORD_STEPS,
  RENT_PAYMENT_OVERRIDE_PAYEE_IDV_UNTIL_TIMESTAMP,
} from './constants'

export const isFirstTimeVerifyingMicroDeposit = payoutMethod =>
  !isEmpty(payoutMethod?.pending) && isEmpty(payoutMethod?.verified)

export const getWelcomeLandlordSteps = ({
  taxInfoFinished,
  taxVerificationStatus,
  receivingAccountAdded,
  payoutMethod,
}) => {
  return WELCOME_LANDLORD_STEPS.map(step => {
    const isCompleted =
      step.key === WELCOME_LANDLORD_STEP_KEY.PAYMENT_REQUEST_SENT ||
      (step.key === WELCOME_LANDLORD_STEP_KEY.VERIFY_IDENTITY &&
        taxInfoFinished &&
        taxVerificationStatus !== PAYEE_IDV_STATUS.FAILED) ||
      (step.key === WELCOME_LANDLORD_STEP_KEY.ADD_BANK_ACCOUNT &&
        receivingAccountAdded &&
        !isFirstTimeVerifyingMicroDeposit(payoutMethod))
    return { ...step, isCompleted }
  })
}

export const getWelcomeLandlordCurrentStepKey = ({
  taxInfoFinished,
  taxVerificationStatus,
  receivingAccountAdded,
  payoutMethod,
}) => {
  if (!taxInfoFinished) return WELCOME_LANDLORD_STEP_KEY.VERIFY_IDENTITY
  if (taxInfoFinished && taxVerificationStatus === PAYEE_IDV_STATUS.FAILED)
    return WELCOME_LANDLORD_STEP_KEY.VERIFY_IDENTITY
  if (!receivingAccountAdded || isFirstTimeVerifyingMicroDeposit(payoutMethod))
    return WELCOME_LANDLORD_STEP_KEY.ADD_BANK_ACCOUNT
  return WELCOME_LANDLORD_STEP_KEY.COLLECT_MONTHLY_RENT
}

export const getWelcomeLandlordContent = ({
  taxInfoFinished,
  taxVerificationStatus,
  receivingAccountAdded,
  payoutMethod,
}) => {
  const currentStepKey = getWelcomeLandlordCurrentStepKey({
    taxInfoFinished,
    taxVerificationStatus,
    receivingAccountAdded,
    payoutMethod,
  })
  const step = WELCOME_LANDLORD_STEPS.find(({ key }) => key === currentStepKey)

  // continue button
  let continueButtonText = step?.continueButtonText
  if (step?.key === WELCOME_LANDLORD_STEP_KEY.VERIFY_IDENTITY) {
    continueButtonText =
      taxVerificationStatus === PAYEE_IDV_STATUS.FAILED
        ? step?.reverifyContinueButtonText
        : step?.continueButtonText
  }
  if (step?.key === WELCOME_LANDLORD_STEP_KEY.ADD_BANK_ACCOUNT) {
    continueButtonText = isFirstTimeVerifyingMicroDeposit(payoutMethod)
      ? step?.verifyBankContinueButton
      : step?.continueButtonText
  }

  return {
    currentStepKey,
    currentStep: WELCOME_LANDLORD_STEPS.findIndex(({ key }) => key === currentStepKey),
    backButtonText: step?.backButtonText,
    continueButtonText,
  }
}

export const getTotalAmountFromSummary = paymentSummary => {
  return Object.keys(paymentSummary || {}).reduce((prv, key) => prv + paymentSummary[key].amount, 0)
}

export const saveOverridePayeeIdvUntilTimestamp = timestamp => {
  localStorage.setItem(RENT_PAYMENT_OVERRIDE_PAYEE_IDV_UNTIL_TIMESTAMP, timestamp)
}

export const isOverridePayeeIdv = () => {
  const timestamp = localStorage.getItem(RENT_PAYMENT_OVERRIDE_PAYEE_IDV_UNTIL_TIMESTAMP)
  if (timestamp && typeof parseInt(timestamp) === 'number') {
    return Date.now() <= parseInt(timestamp)
  }
  return false
}

export const addShowWelcomeModalToUrlSearch = ({ location, history }) => {
  const queryParams = new URLSearchParams(location.search)
  queryParams.set('showWelcomeModal', 'true')
  history.replace({
    search: queryParams.toString(),
  })
}

export const removeShowWelcomeModalFromUrlSearch = ({ location, history }) => {
  const queryParams = new URLSearchParams(location.search)
  if (queryParams.has('showWelcomeModal')) {
    queryParams.delete('showWelcomeModal')
    history.replace({
      search: queryParams.toString(),
    })
  }
}

export const removePaymentSetupSuccessFromUrlSearch = ({ location, history }) => {
  const queryParams = new URLSearchParams(location.search)
  if (queryParams.has('paymentSetupSuccess')) {
    queryParams.delete('paymentSetupSuccess')
    history.replace({
      search: queryParams.toString(),
    })
  }
}
