import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import reducer from './reducer'
import saga from './saga'

const mapStateToProps = createStructuredSelector({})

export const withConnect = connect(mapStateToProps)

export const withSaga = injectSaga({ key: 'associationContact', saga })

export const withReducer = injectReducer({
  key: 'associationContact',
  reducer,
})

export const withAssociationContact = compose(withConnect, withSaga, withReducer)
