import { takeLatest, put, call, all, select } from "redux-saga/effects"
import { buildPath, query, stringify } from "@rentspree/path"
import { push } from "connected-react-router"
import { UserApiInstance } from "utils/api-interceptor"
import { openSweetAlertBaseError } from "utils/sweet-alert-actions"
import {
  PERMALINK_ACTIONS_SCREENING,
  REQUEST_UPDATE_SCREENING_FEE,
} from "constants/route"
import { updateAgentLicensesSaga } from "containers/agent-profile/saga"
import { selectLocation } from "containers/agreement/selectors"
import { getRequestOptionsState } from "containers/request/step-screening-options/selectors"
import { PAYER_TYPES } from "constants/user"
import tracker from "tracker"
import { LRA_INTEGRATION } from "tracker/const"

import { updateDreLicenseApi } from "./actions"
import {
  UPDATE_DRE_LICENSE_ENDPOINT,
  UPDATE_DRE_LICENSE_CALL,
} from "./constants"

export const callUpdateDreLicenseApi = ({ licenses }) =>
  UserApiInstance.put(buildPath(UPDATE_DRE_LICENSE_ENDPOINT, {}), {
    licenses,
  })

const shouldUseContinuePath = (payer, continuePath) =>
  continuePath && payer === PAYER_TYPES.RENTER
export function* updateDreLicenseSaga({ payload }) {
  yield put(updateDreLicenseApi.request())
  try {
    const { search } = yield select(selectLocation)
    const { payer } = yield select(getRequestOptionsState)
    const { screeningRequestId, screeningMethod, continuePath } = query.parse(
      search,
    )
    const { licenses, propertyId } = payload
    yield call(updateAgentLicensesSaga, licenses?.[0])
    const response = yield call(callUpdateDreLicenseApi, { licenses })
    yield put(updateDreLicenseApi.success(response))
    yield call([tracker, "trackEvent"], LRA_INTEGRATION.COMPLETE_DRE_LICENSE)
    let nextPath =
      payer === PAYER_TYPES.AGENT
        ? REQUEST_UPDATE_SCREENING_FEE
        : PERMALINK_ACTIONS_SCREENING

    nextPath = shouldUseContinuePath(payer, continuePath)
      ? continuePath
      : buildPath(nextPath, { propertyId })

    const queryString = shouldUseContinuePath(payer, continuePath)
      ? undefined
      : stringify({ screeningRequestId, screeningMethod, continuePath })

    yield put(
      push({
        pathname: nextPath,
        search: queryString,
      }),
    )
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(updateDreLicenseApi.failure())
  }
}

export function* watchUpdateDreLicenseApiCall() {
  yield takeLatest(UPDATE_DRE_LICENSE_CALL, updateDreLicenseSaga)
}

export function* rootSaga() {
  yield all([watchUpdateDreLicenseApiCall()])
}

export default rootSaga
