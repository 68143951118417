import {
  takeLatest,
  put,
  call,
  all,
  select,
  take,
  takeLeading,
} from "redux-saga/effects"
import { eventChannel } from "redux-saga"
import { buildPath } from "@rentspree/path"
import Talk from "talkjs"
import { getLatestConversationId, createTalkJSInstance } from "./helper"
import { UserApiInstance } from "../../utils/api-interceptor"

import {
  startConversationApi,
  initiateTalkJsSuccess,
  initiateTalkJsFailure,
  getSignatureApi,
  getConversationApi,
  setUnreadCount,
} from "./actions"
import {
  START_CONVERSATION_CALL,
  INITIATE_TALK_JS_CALL,
  CONVERSATION_API,
  SIGNATURE_API,
  GET_CONVERSATION_CALL,
  START_UNREAD_CHANNEL,
} from "./constants"

import { selectSignature } from "./selectors"

export const callGetSignatureApi = () =>
  UserApiInstance.get(buildPath(SIGNATURE_API))

export const callStartConversationApi = payload =>
  UserApiInstance.post(buildPath(CONVERSATION_API), payload)

export const callGetConversationApi = () =>
  UserApiInstance.get(buildPath(CONVERSATION_API))

export function* getSignature() {
  yield put(getSignatureApi.request())
  try {
    const response = yield call(callGetSignatureApi)
    yield put(getSignatureApi.success(response))
  } catch (err) {
    yield put(getSignatureApi.failure())
  }
}

export const TalkReady = () => Talk.ready

// TODO: consider that should we save signature to redux store?
// should we just call get signature api and use response to initiate talk session without saving to store?
export function* initiateTalkJs() {
  try {
    yield call(TalkReady)
    if (!window.talkSession) {
      yield call(getSignature)
      const talkJsSignature = yield select(selectSignature)
      const { me } = talkJsSignature
      const talkJsUser = yield call(createTalkJSInstance, Talk.User, me)
      const { signature, appId } = talkJsSignature
      window.talkSession = yield call(createTalkJSInstance, Talk.Session, {
        appId,
        signature,
        me: talkJsUser,
      })
    }
    yield put({
      type: START_UNREAD_CHANNEL,
    })
    yield put(initiateTalkJsSuccess())
  } catch (err) {
    yield put(initiateTalkJsFailure())
  }
}

export function* startConversation({ payload }) {
  yield put(startConversationApi.request())
  try {
    const response = yield call(callStartConversationApi, payload)
    yield put(startConversationApi.success(response))
  } catch (err) {
    yield put(startConversationApi.failure())
  }
}

export function* getConversation() {
  yield put(getConversationApi.request())
  try {
    const response = yield call(callGetConversationApi)
    const latestConversationId = yield call(
      getLatestConversationId,
      response.data,
    )
    yield put(
      getConversationApi.success({ conversationId: latestConversationId }),
    )
  } catch (err) {
    yield put(getConversationApi.failure())
  }
}

export const createUnreadChannel = () =>
  eventChannel(emit => {
    const handler = data => {
      emit(data)
    }
    window.talkSession.unreads.on("change", handler)
    return () => {
      window.talkSession.unreads.off("change", handler)
    }
  })

export function* listenUnreadSaga() {
  const socketChannel = yield call(createUnreadChannel)

  while (true) {
    const unReads = yield take(socketChannel)
    yield put(setUnreadCount(unReads.length))
  }
}

export function* watchGetInitiateTalkJs() {
  yield takeLatest(INITIATE_TALK_JS_CALL, initiateTalkJs)
}

export function* watchStartConversationApi() {
  yield takeLatest(START_CONVERSATION_CALL, startConversation)
}

export function* watchGetConversation() {
  yield takeLatest(GET_CONVERSATION_CALL, getConversation)
}

export function* watchStartUnreadChannel() {
  yield takeLeading(START_UNREAD_CHANNEL, listenUnreadSaga)
}

export function* rootSaga() {
  yield all([
    watchGetInitiateTalkJs(),
    watchStartConversationApi(),
    watchGetConversation(),
    watchStartUnreadChannel(),
  ])
}

export default rootSaga
