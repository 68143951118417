import { combineReducers } from "redux"
import pick from "lodash/pick"
import * as Types from "../constants/action-types"

const showVideoTutorialModal = (state = false, action) => {
  switch (action.type) {
    case Types.SHOW_VIDEO_TUTORIAL_MODAL:
      return action.data
    default:
      return state
  }
}

const showVideoViewProcessModal = (state = false, action) => {
  switch (action.type) {
    case Types.SHOW_VIDEO_VIEW_PROCESS_MODAL:
      return action.data
    default:
      return state
  }
}

const showProRestrictionsModal = (state = false, action) => {
  switch (action.type) {
    case Types.SHOW_PRO_RESTRICTIONS_MODAL:
      return action.data
    default:
      return state
  }
}

const showOpenCreditReportModal = (
  state = { isShow: false, currentApp: null, creditReportDetail: null },
  action,
) => {
  switch (action.type) {
    case Types.SHOW_OPEN_CREDIT_REPORT_MODAL:
      if (action.isShow) {
        return pick(action, ["isShow", "currentApp", "creditReportDetail"])
      }
      return { isShow: false, currentApp: state.currentApp }
    default:
      return state
  }
}

const showDeactivatePropertyModal = (
  state = { isShow: false, propertyId: null },
  action,
) => {
  switch (action.type) {
    case Types.SHOW_DEACTIVATE_PROPERTY_MODAL:
      return { isShow: action.isShow, propertyId: action.propertyId || null }
    default:
      return state
  }
}

const showReactivatePropertyModal = (
  state = { isShow: false, propertyId: null },
  action,
) => {
  switch (action.type) {
    case Types.SHOW_REACTIVATE_PROPERTY_MODAL:
      return { isShow: action.isShow, propertyId: action.propertyId || null }
    default:
      return state
  }
}

const showLRALearnMoreModal = (state = false, action) => {
  switch (action.type) {
    case Types.SHOW_LRA_LEARN_MORE_MODAL:
      return action.data
    default:
      return state
  }
}

// TODO: refactor to saga logic
const showPostLogInAdvertiseModal = (state = false, action) => {
  switch (action.type) {
    case Types.SHOW_POST_LOG_IN_ADVERTISE_MODAL:
      return action.isShow
    default:
      return state
  }
}

export default combineReducers({
  showVideoTutorialModal,
  showVideoViewProcessModal,
  showProRestrictionsModal,
  showOpenCreditReportModal,
  showDeactivatePropertyModal,
  showReactivatePropertyModal,
  showLRALearnMoreModal,
  showPostLogInAdvertiseModal,
})
