import { bindActionCreators, compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'
import {
  selectApplicationDetail,
  selectRentalDetail,
  selectScreeningOption,
} from 'containers/reports/selectors'
import { connect } from 'react-redux'
import { selectUser } from 'containers/user/selectors'
import { changeMobileActiveTab } from 'legacy/actions/application.action'
import incomeVerificationSaga from './saga'
import incomeVerificationReducer from './reducer'
import {
  selectIncomeVerification,
  selectIncomeVerificationReportData,
  selectIncomeVerificationState,
  selectIncomeVerificationStatus,
  selectIncomeVerificationCreatedAt,
  selectGetReportFailStatusCode,
  selectGetIncomeVerificationFailStatusCode,
  selectIncomeVerificationReportStatus,
  selectIsViewedByAdmin,
} from './selectors'
import {
  generateIncomeVerificationReport,
  getIncomeVerificationByRentalSubmission,
  getIncomeVerificationReport,
} from './actions'
import { makeSelectedExternalRenter } from '../selectors'

export const mapStateToProps = createStructuredSelector({
  user: selectUser,
  applicationDetail: selectApplicationDetail,
  rentalDetail: selectRentalDetail,
  screeningOption: selectScreeningOption,
  externalRenterDetail: makeSelectedExternalRenter,
  getReportFailStatusCode: selectGetReportFailStatusCode,
  getIncomeVerificationFailStatusCode: selectGetIncomeVerificationFailStatusCode,
  incomeVerificationData: selectIncomeVerificationState,
  incomeVerificationReportStatus: selectIncomeVerificationReportStatus,
  incomeVerificationReportData: selectIncomeVerificationReportData,
  incomeVerification: selectIncomeVerification,
  incomeVerificationStatus: selectIncomeVerificationStatus,
  incomeVerificationCreatedAt: selectIncomeVerificationCreatedAt,
  isViewedByAdmin: selectIsViewedByAdmin,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getIncomeVerificationByRentalSubmission,
      generateIncomeVerificationReport,
      getIncomeVerificationReport,
      changeMobileActiveTab,
    },
    dispatch,
  )

export const withIncomeVerificationSaga = injectSaga({
  key: 'income-verification',
  saga: incomeVerificationSaga,
})

export const withIncomeVerificationReducer = injectReducer({
  key: 'income-verification',
  reducer: incomeVerificationReducer,
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withIncomeVerificationReducer, withIncomeVerificationSaga)
