import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectProfile } from 'containers/navbar/selectors'

import injectSaga from 'utils/inject-saga'
import injectReducer from 'utils/inject-reducer'
import { makeSelectProducts } from 'legacy/containers/pro/landing/selectors'
import { selectStreet } from 'containers/listing/selectors'
import {
  selectIsLoading,
  selectIsError,
  selectLeaseAgreementDetailData,
  makeSelectorVoidStatus,
  makeSelectorDeleteStatus,
  selectIsErrorVoid,
  makeSelectIsAcceptTermsLeaseAgreement,
  makeSelectError,
  selectIsAccepting,
  selectIsResending,
} from './selectors'
import reducer from './reducer'
import * as actions from './actions'
import saga from './saga'
import { saveFiles } from '../envelope/actions'
import { makeSelectSavingFileLoadingList } from '../envelope/selectors'

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading(),
  isError: selectIsError(),
  leaseAgreementDetail: selectLeaseAgreementDetailData(),
  voidStatus: makeSelectorVoidStatus(),
  deleteStatus: makeSelectorDeleteStatus(),
  isErrorVoid: selectIsErrorVoid(),
  saveFileLoadingList: makeSelectSavingFileLoadingList(),
  acceptTermLeaseAgreement: makeSelectIsAcceptTermsLeaseAgreement(),
  error: makeSelectError(),
  isAccepting: selectIsAccepting(),
  resendStatus: selectIsResending(),
  profile: makeSelectProfile(),
  products: makeSelectProducts(),
  street: selectStreet,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...actions,
        saveFiles,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({
  key: 'leaseAgreementDetail',
  reducer,
})
export const withSaga = injectSaga({ key: 'leaseAgreementDetail', saga })
