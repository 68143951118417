import { HTML5_FMT } from "moment"
import { TextBox } from "components/drag-and-drop/text-box"
import { SignBox } from "components/drag-and-drop/sign-box"
import { InitialBox } from "components/drag-and-drop/initial-box"
import { DateSignBox } from "components/drag-and-drop/date-sign-box"
import { Checkbox } from "components/drag-and-drop/check-box"
import { DateBox } from "components/drag-and-drop/date-box"
import { AmountBox } from "components/drag-and-drop/amount-box"
import { TimeBox } from "components/drag-and-drop/time-box"

export const TYPES = {
  SET_CHILDREN_BOX: "SET_CHILDREN_BOX",
  REMOVE_CHILDREN_BOX: "REMOVE_CHILDREN_BOX",
  ADD_CHILDREN_BOX: "ADD_CHILDREN_BOX",
  SET_ACTIVE_ID: "SET_ACTIVE_ID",
  RESET_ACTIVE: "RESET_ACTIVE",
  SET_ASSIGNEE_BOX: "SET_ASSIGNEE_BOX",
  SET_CONFIG_BOX: "SET_CONFIG_BOX",
  CHANGE_BOX_PAGE: "CHANGE_BOX_PAGE",
}

export const DROP_BOX_STATE = {
  INIT: "INIT",
  READY: "READY",
}

export const TYPES_COMPONENT = {
  TEXT_BOX: "TEXT_BOX",
  CHECK_BOX: "CHECK_BOX",
  INITIAL_SIGNS: "INITIAL_SIGNS",
  SIGNATURE_SIGNS: "SIGNATURE_SIGNS",
  DATE_SIGNS: "DATE_SIGNS",
  ADDRESS: "ADDRESS",
  DATE_BOX: "DATE_BOX",
  AMOUNT_BOX: "AMOUNT_BOX",
  TIME_BOX: "TIME_BOX",
}

export const ACCEPTED_TYPES = [
  TYPES_COMPONENT.TEXT_BOX,
  TYPES_COMPONENT.CHECK_BOX,
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.DATE_SIGNS,
  TYPES_COMPONENT.DATE_BOX,
  TYPES_COMPONENT.AMOUNT_BOX,
  TYPES_COMPONENT.TIME_BOX,
]

export const SIGNABLE_COMPONENT = [
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
]

export const MUST_ASSIGNED_COMPONENTS = [
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.DATE_SIGNS,
]
export const UNASSIGNED_ABLE_COMPONENTS = [
  TYPES_COMPONENT.TEXT_BOX,
  TYPES_COMPONENT.CHECK_BOX,
  TYPES_COMPONENT.DATE_BOX,
  TYPES_COMPONENT.AMOUNT_BOX,
  TYPES_COMPONENT.TIME_BOX,
]

export const NO_ONE_RECIPIENT = { value: "unassigned", label: "No One" }

export const DEFAULT_DRAGGING_VALUE = {
  [TYPES_COMPONENT.TEXT_BOX]: "Text",
  [TYPES_COMPONENT.CHECK_BOX]: "true",
}

export const TOOL_BAR_TYPE = {
  TEXT_PANEL: "TEXT_PANEL",
  SIGN_PANEL: "SIGN_PANEL",
  ACTIONS_PANEL: "ACTIONS_PANEL",
}

export const TOOL_BAR_LIST = {
  TEXT_BOX: [
    TOOL_BAR_TYPE.TEXT_PANEL,
    TOOL_BAR_TYPE.SIGN_PANEL,
    TOOL_BAR_TYPE.ACTIONS_PANEL,
  ],
  SIGNATURE_SIGNS: [TOOL_BAR_TYPE.SIGN_PANEL, TOOL_BAR_TYPE.ACTIONS_PANEL],
  INITIAL_SIGNS: [TOOL_BAR_TYPE.SIGN_PANEL, TOOL_BAR_TYPE.ACTIONS_PANEL],
  CHECK_BOX: [TOOL_BAR_TYPE.SIGN_PANEL, TOOL_BAR_TYPE.ACTIONS_PANEL],
  DATE_SIGNS: [
    TOOL_BAR_TYPE.TEXT_PANEL,
    TOOL_BAR_TYPE.SIGN_PANEL,
    TOOL_BAR_TYPE.ACTIONS_PANEL,
  ],
  DATE_BOX: [
    TOOL_BAR_TYPE.TEXT_PANEL,
    TOOL_BAR_TYPE.SIGN_PANEL,
    TOOL_BAR_TYPE.ACTIONS_PANEL,
  ],
  AMOUNT_BOX: [
    TOOL_BAR_TYPE.TEXT_PANEL,
    TOOL_BAR_TYPE.SIGN_PANEL,
    TOOL_BAR_TYPE.ACTIONS_PANEL,
  ],
  TIME_BOX: [
    TOOL_BAR_TYPE.TEXT_PANEL,
    TOOL_BAR_TYPE.SIGN_PANEL,
    TOOL_BAR_TYPE.ACTIONS_PANEL,
  ],
}

export const MAP_COMPONENT = {
  TEXT_BOX: TextBox,
  SIGNATURE_SIGNS: SignBox,
  CHECK_BOX: Checkbox,
  INITIAL_SIGNS: InitialBox,
  DATE_SIGNS: DateSignBox,
  DATE_BOX: DateBox,
  AMOUNT_BOX: AmountBox,
  TIME_BOX: TimeBox,
}

export const SIGN_TYPE = [
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.INITIAL_SIGNS,
]

export const HAS_MOMENT_VALIDATION = [
  TYPES_COMPONENT.DATE_BOX,
  TYPES_COMPONENT.TIME_BOX,
]

export const VALIDATION_FORMAT = {
  DATE_BOX: HTML5_FMT.DATE,
  TIME_BOX: "hh:mm",
}
