import React from "react"

export const HandleBeforeUnload = ({
  eventName = "beforeunload",
  handleBeforeUnload = event => {
    event.preventDefault()
    const returnValue = "Undone process will be lost"
    // eslint-disable-next-line no-param-reassign
    event.returnValue = returnValue
    return returnValue
  },
}) => {
  React.useEffect(() => {
    window.addEventListener(eventName, handleBeforeUnload)
    return () => {
      window.removeEventListener(eventName, handleBeforeUnload)
    }
  }, [])
  return <></>
}

export default HandleBeforeUnload
