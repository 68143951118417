import React from "react"
import SignatureCanvas from "react-signature-canvas"
import styled from "styled-components"
import { COLOR } from "styles/settings"
import { B16 } from "@rentspree/component-v2"

const Wrapper = styled.div`
  display: block;
  width: fit-content;
  height: fit-content;
`

const CanvasContainer = styled.div`
  position: relative;
  background-color: ${COLOR.bgGrey};
  box-sizing: border-box;
  border-radius: 5px;
  ${props => props.width && `width: ${props.width}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.isError && `border: 1px solid ${COLOR.negativeRed}`};
  canvas {
    width: 100%;
    height: 100%;
  }
`

export const ClearButton = styled.div`
  font-size: 12px;
  color: ${COLOR.newDeepBlue};
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`

const Label = styled.p`
  margin-bottom: 10px;
  font-family: Source Sans Pro;
  font-size: 16px;
`

const RedText = styled.span`
  color: ${COLOR.negativeRed};
`

export const DrawInput = ({
  setRef = () => {},
  dWidth,
  dHeight,
  isRequired,
  labelText,
  isError,
  onClear = () => {},
}) => (
  <Wrapper>
    <Label>
      {labelText}
      {isRequired && <RedText> *</RedText>}
    </Label>
    <CanvasContainer width={dWidth} height={dHeight} isError={isError}>
      <SignatureCanvas
        ref={setRef}
        canvasProps={{ width: dWidth, height: dHeight }}
        penColor={COLOR.textBlue}
      />
      <ClearButton onClick={onClear}>Clear</ClearButton>
    </CanvasContainer>
    {isError && (
      <B16 color={COLOR.negativeRed} margin="3px 0 0 0">
        Required
      </B16>
    )}
  </Wrapper>
)

export default DrawInput
