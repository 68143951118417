import Span from '@rentspree/component-v2/dist/typography/span'
import React from 'react'

export const GET_RENT_PAYMENTS_DATA_CALL = 'GET_RENT_PAYMENTS_DATA_CALL'

export const INITIATE_RENT_PAYMENT_WITH_NEW_RENTER_INFO =
  'INITIATE_RENT_PAYMENT_WITH_NEW_RENTER_INFO'

export const INITIATE_RENT_PAYMENT_CALL = 'INITIATE_RENT_PAYMENT_CALL'
export const INITIATE_RENT_PAYMENT_REQUEST = 'INITIATE_RENT_PAYMENT_REQUEST'
export const INITIATE_RENT_PAYMENT_SUCCESS = 'INITIATE_RENT_PAYMENT_SUCCESS'
export const INITIATE_RENT_PAYMENT_FAILED = 'INITIATE_RENT_PAYMENT_FAILED'

export const SHOW_ERROR_RENT_PAYMENT = 'SHOW_ERROR_RENT_PAYMENT'
export const CLEAR_ERROR_RENT_PAYMENT = 'CLEAR_ERROR_RENT_PAYMENT'
export const SET_QUOTATIONS = 'SET_QUOTATIONS'

export const CONTACT_US_LINK = 'https://www.rentspree.com/contact-us'

export const FETCH_ERROR = {
  title: 'Let’s Try This Again',
  subTitle: (
    <Span>
      {' '}
      We had a technical issue on our end. Please try reloading the page.{' '}
      <Span underline weight="600" color="#3b99fc">
        <a href={CONTACT_US_LINK}>Contact us</a>
      </Span>{' '}
      if this message continues.
    </Span>
  ),
  button: 'Reload Page',
}

export const PAGES = {
  REQUESTS: 'requests',
  TENANTS: 'tenants',
  PAYMENT_DETAILS: 'payment-details',
  MONTHLY: 'monthly',
  ONE_TIME: 'one-time',
  RECEIVING_ACCOUNT: 'receiving-accounts',
  CONFIRMATION: 'confirmation',
}

export const STORAGE_KEYS = {
  AGENT_DRAFTING_PAYMENT: 'drafting_for_landlord',
}

export const PAYOUT_METHOD_TYPE = {
  INSTANT: 'instant',
  MICRO_DEPOSITS: 'microdeposits',
}
