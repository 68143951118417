import { createSelector } from "reselect"
import get from "lodash/get"

const getAgentProfile = state => get(state, "agentProfile", {})

export const selectAgentProfileData = createSelector(
  getAgentProfile,
  state => state?.agentProfileData || {},
)

export const selectBrokerageName = createSelector(
  getAgentProfile,
  state => get(state, "agentProfileData.contactInformation.brokerage.name", ""),
)

export const selectAgentLicenses = createSelector(
  selectAgentProfileData,
  state => state.experience?.agentLicenses || [],
)
