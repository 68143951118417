import React from 'react'
import { Small, Span } from 'components/typography/text-style'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import { BUNDLE_TYPE } from 'containers/tenant-screening/request-v2.2/constants'

const ScreeningRequestFormContainer = styled.div`
  .have-screening {
    text-align: left;
    display: block;
  }
  .price-container {
    text-align: right;
    display: 'block';
  }
`

const SmallWithStyledFont = styled(Small)`
  font-family: 'Source Sans Pro';
`

export const ScreeningRequestForm = ({ children, totalPrice, requestOptions }) => {
  let priceLabel = 'Total application fee'
  if (!isEmpty(requestOptions)) {
    if (
      requestOptions.bundleType === BUNDLE_TYPE.PREMIUM ||
      !isEmpty(requestOptions.documentOption) ||
      requestOptions.refCheckOption ||
      requestOptions.incomeVerificationOption
    ) {
      priceLabel = 'Screening Reports & Documents fee'
    } else {
      priceLabel = 'Screening Reports fee'
    }
  }

  return (
    <ScreeningRequestFormContainer>
      {children}
      <SmallWithStyledFont className="price-container" sourceSansPro margin="0" mt="30" flex>
        <Span noWrap size="18px" weight="600" align="right" mAlign="right">
          {priceLabel}: ${totalPrice}
        </Span>
      </SmallWithStyledFont>
    </ScreeningRequestFormContainer>
  )
}
