import React from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import size from 'lodash/size'
import omit from 'lodash/omit'
import { generateAddress } from '@rentspree/helper'
import { hasPresent } from 'helpers/has-features'
import { isGreaterOrEqual } from '@rentspree/helper/build/is-greater-or-equal'
import { APPLICATION_TYPE } from 'constants/application-type'
import {
  filterCoApplicant,
  convertToMonthDayYear,
  generateYearMonthDuration,
  convertToCommaAndDecimal,
  pluralizeWordOnlyUnit,
} from 'legacy/components/reports-v2/share/function'
import { convert } from 'legacy/components/helper/convert'
import { RENTAL_APPLICATION_VERSION } from 'components/reports/application/constants'

import { SubSectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  Value,
  GapContent,
  InformationHalfPanel,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { APPLICANT_TYPE } from 'legacy/constants/rental-consts'

const generateOccupantCosignerText = ({ numberOfCoOccupant, numberOfCoSigner }) =>
  `${numberOfCoOccupant} ${pluralizeWordOnlyUnit(
    'occupants',
    numberOfCoOccupant,
  )} and ${numberOfCoSigner} ${pluralizeWordOnlyUnit('guarantor', numberOfCoSigner)}/co-
  ${pluralizeWordOnlyUnit('signer', numberOfCoSigner)}`

const generatePetAndVehicleText = ({ numberOfPet, numberOfVehicle }) =>
  `${numberOfPet} ${pluralizeWordOnlyUnit('pet', numberOfPet)} ${
    numberOfVehicle !== undefined
      ? `and ${numberOfVehicle} ${pluralizeWordOnlyUnit('vehicle', numberOfVehicle)}`
      : ''
  }`

const generateEmploymentText = ({ occupationStatus, employer, jobTitle, employmentDuration }) => {
  if (occupationStatus === 'unemployed') return 'Currently unemployed.'
  if (occupationStatus === 'retired') return 'Currently retired.'
  if (occupationStatus === 'employed')
    return `Works at ${employer} as ${jobTitle} for ${employmentDuration}.`
  return ''
}

const generateMonthlyIncomeLabel = additionalIncomes => {
  if (size(additionalIncomes) === 0) {
    return <Value data-testid="additional-incomes-label">No other sources of income.</Value>
  }
  if (size(additionalIncomes) >= 1) {
    return (
      <Value>
        Other source(s) of income:
        {additionalIncomes.map((item, index) => (
          <li key={index} data-testid="additional-incomes-label">
            {item}
          </li>
        ))}
      </Value>
    )
  }
  return <Value data-testid="additional-incomes-label" />
}

const generateResidenceTypeText = ({ residenceType, monthlyRent }) => {
  if (residenceType === 'rental') return `Current monthly rent is ${monthlyRent}.`
  if (residenceType === 'owner') return ' Current residence is Owner Occupied.'
  return ''
}

const generateMiscText = ({ choice, yesText, noText, text }) =>
  `${choice === 'yes' ? yesText : noText} ${text}`

const ApplicationReportSummary = ({ application, rentalDetail, agentName }) => {
  const version = get(application, 'version', '2.5')
  const type = get(application, 'type', 'rentspree')
  const numberOfPet = size(get(application, 'miscellaneous.pets'))
  const numberOfVehicle = get(application, 'miscellaneous.vehicles')
    ? size(get(application, 'miscellaneous.vehicles'))
    : undefined
  const moveInDate = convertToMonthDayYear(get(rentalDetail, 'moveDate'))
  let numberOfCoOccupant = size(filterCoApplicant(get(rentalDetail, 'coApplicants'), 'occupant'))
  let numberOfCoSigner = size(filterCoApplicant(get(rentalDetail, 'coApplicants'), 'cosigner'))
  const occupationStatus = get(application, 'occupation.current.workStatus')
  const employer = get(application, 'occupation.current.employed.employer')
  const jobTitle = get(application, 'occupation.current.employed.jobTitle')

  const endDate = get(application, 'occupation.current.employed.endDate')
  const employmentDuration = generateYearMonthDuration(
    get(application, 'occupation.current.employed.startDate'),
    hasPresent(version, type) && endDate === 'Present' ? new Date() : endDate,
  )
  const additionalIncomes = map(
    get(application, 'occupation.additionalIncomes'),
    income => income.source,
  )
  const monthlyIncome = convertToCommaAndDecimal(get(application, 'monthlyIncome', 0))
  const residenceType = get(application, 'residence.current.selected')
  const address = generateAddress(omit(get(application, 'residence.current'), ['street']))
  const dateOut = get(application, 'residence.current.dateOut')
  const duration = generateYearMonthDuration(
    get(application, 'residence.current.dateIn'),
    hasPresent(version, type) && dateOut === 'Present' ? new Date() : dateOut,
  )
  const monthlyRent = convert(get(application, 'residence.current.monthlyRent', 0), '$')
  const additionalInfo = get(application, 'miscellaneous.additionalInfo')
  const applicantType = get(rentalDetail, 'applicant_type')
  numberOfCoOccupant = applicantType === 'tenant' ? numberOfCoOccupant + 1 : numberOfCoOccupant
  numberOfCoSigner = applicantType === 'guarantor' ? numberOfCoSigner + 1 : numberOfCoSigner

  const liquidFurnitureChoice = get(additionalInfo, 'liquidFurniture.choice')
  const unlawfulChoice = get(additionalInfo, 'unlawful.choice')
  const moveOutChoice = get(additionalInfo, 'moveOut.choice')
  const felonyChoice = get(additionalInfo, 'felony.choice')
  const bankruptcyChoice = get(additionalInfo, 'bankruptcy.choice')
  const isSkipFelonyQuestion =
    isGreaterOrEqual(version, RENTAL_APPLICATION_VERSION.RENTAL_APPLICATION_3_3) &&
    type === APPLICATION_TYPE.RENTSPREE

  return (
    <GapContent>
      <InformationHalfPanel>
        <SubSectionHeader title="Agent/Owner" />
        <Value>{agentName}</Value>
      </InformationHalfPanel>
      <InformationHalfPanel>
        <SubSectionHeader title="Application" />
        {applicantType === APPLICANT_TYPE.tenant.value && (
          <Value id="moveInDateValue" data-testid="move-in-date-value-label">
            Desired move-in date of {moveInDate}
          </Value>
        )}
        <Value>{generateOccupantCosignerText({ numberOfCoOccupant, numberOfCoSigner })}</Value>
        <Value>{generatePetAndVehicleText({ numberOfPet, numberOfVehicle })}</Value>
      </InformationHalfPanel>
      <InformationHalfPanel>
        <SubSectionHeader title="Income" />
        <Value>Total monthly income is {monthlyIncome}</Value>
        <Value>
          {generateEmploymentText({ occupationStatus, employer, jobTitle, employmentDuration })}
        </Value>
        {generateMonthlyIncomeLabel(additionalIncomes)}
      </InformationHalfPanel>
      <InformationHalfPanel>
        <SubSectionHeader title="Resident" />
        <Value>{generateResidenceTypeText({ residenceType, monthlyRent })}</Value>
        <Value>
          Lives in {address} for {duration}.
        </Value>
      </InformationHalfPanel>
      {additionalInfo && (
        <InformationHalfPanel>
          <SubSectionHeader title="Misc" />
          {liquidFurnitureChoice && (
            <Value>
              {generateMiscText({
                choice: liquidFurnitureChoice,
                yesText: 'Plans',
                noText: 'Does not plan',
                text: 'to use liquid-filled furniture.',
              })}
            </Value>
          )}
          {unlawfulChoice && (
            <Value>
              {generateMiscText({
                choice: unlawfulChoice,
                yesText: 'Has been',
                noText: 'Has not been',
                text: 'a party to an unlawful detainer action or filed bankruptcy within the last seven years.',
              })}
            </Value>
          )}
          {moveOutChoice && (
            <Value>
              {generateMiscText({
                choice: moveOutChoice,
                yesText: 'Has been',
                noText: 'Has not been',
                text: 'asked to move out of a residence.',
              })}
            </Value>
          )}
          {bankruptcyChoice && (
            <Value>
              {generateMiscText({
                choice: bankruptcyChoice,
                yesText: 'Has',
                noText: 'Has not',
                text: 'filed bankruptcy within the last seven years.',
              })}
            </Value>
          )}
          {!isSkipFelonyQuestion && felonyChoice && (
            <Value>
              {generateMiscText({
                choice: felonyChoice,
                yesText: 'Has been',
                noText: 'Has not been',
                text: 'convicted of or pleaded no contest to a felony.',
              })}
            </Value>
          )}
        </InformationHalfPanel>
      )}
    </GapContent>
  )
}

export default ApplicationReportSummary
