import React from "react"
import Subscription from "containers/subscription"
import { FEATURE_PRO } from "../../../constants/feature-pro-consts"
import ArcsSubscribedPage from "./subscribed-page"
import ArcsInfoPage from "./info-page"

const ReferenceCheckDetail = props => (
  <Subscription
    feature={FEATURE_PRO.SUBSCRIPTION}
    render={({ isSubscriber }) => {
      if (isSubscriber) {
        return <ArcsSubscribedPage {...props} />
      }
      return <ArcsInfoPage {...props} />
    }}
  />
)

export default ReferenceCheckDetail
