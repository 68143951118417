import generateApiCall from "utils/generate-api-call"
import {
  GET_SUBMISSION_PARTICIPANT_CALL,
  GET_SUBMISSION_PARTICIPANT_REQUEST,
  GET_SUBMISSION_PARTICIPANT_SUCCESS,
  GET_SUBMISSION_PARTICIPANT_FAILURE,
} from "./constants"

export const getSubmissionParticipantWithRentalSubmission = submissionParticipantId => ({
  type: GET_SUBMISSION_PARTICIPANT_CALL,
  payload: {
    submissionParticipantId,
  },
})

export const getSubmissionParticipantApiState = generateApiCall([
  GET_SUBMISSION_PARTICIPANT_REQUEST,
  GET_SUBMISSION_PARTICIPANT_SUCCESS,
  GET_SUBMISSION_PARTICIPANT_FAILURE,
])
