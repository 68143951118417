import { takeLatest, put, call, all, select } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import includes from 'lodash/includes'
import get from 'lodash/get'
import merge from 'lodash/merge'
import history from 'utils/history'

import { apiInstanceWithErrorHandler, UserApiInstance } from 'utils/api-interceptor'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import {
  CREATE_LISTING_SCREENING_REQUEST_API,
  USER_LINK_ACCOUNT_API,
  GET_APPLY_LINK_API,
} from 'constants/route'

import { setCarModalState } from 'containers/envelope/select-options-step/actions'
import { APPLICATION_TYPE } from 'constants/application-type'
import tracker from 'tracker'
import { DELAY_CRIMINAL_MODAL } from 'tracker/const'
import { setValidationError } from 'legacy/actions/request.action'
import { SCREENING_REQUEST_SOURCE } from 'containers/constants'
import { PAYER_TYPES } from 'constants/user'
import {
  getLinkAccountDataActions,
  createListingScreeningRequestActions,
  setShowCriminalDisclaimerModal,
  generatePermalinkRequestActions,
} from './actions'
import {
  GET_LINK_ACCOUNT_DATA_CALL,
  SUBMIT_SCREENING_REQUEST,
  CREATE_LISTING_SCREENING_REQUEST_CALL_WITH_REDIRECT,
} from './constants'
import { checkSelectedItemsValidation, validateApplicationTypeRequirement } from './helpers'
import {
  selectIsCarWithPartnerIDIntegrated as makeSelectIsCarWIthPartnerIDIntegrated,
  selectPermalink,
} from './selectors'

const getLinkAccountDataEndpoint = buildPath(USER_LINK_ACCOUNT_API)
const createListingScreeningRequestEndpoint = propertyId =>
  buildPath(CREATE_LISTING_SCREENING_REQUEST_API, { propertyId })

export const callGetLinkAccountDataAPI = () => UserApiInstance.get(getLinkAccountDataEndpoint)

export function* getLinkAccountData() {
  yield put(getLinkAccountDataActions.request())
  try {
    const response = yield call(callGetLinkAccountDataAPI)
    yield put(getLinkAccountDataActions.success(response.result))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(getLinkAccountDataActions.failure())
  }
}

export const callCreateListingScreeningRequestAPI = ({ propertyId, payload }) =>
  apiInstanceWithErrorHandler.put(createListingScreeningRequestEndpoint(propertyId), payload)

export function* createListingScreeningRequest({ propertyId, payload }) {
  const permalink = yield select(selectPermalink)
  const reshapedScreeningRequest = yield call(merge, payload, {
    url: permalink,
  })
  yield put(createListingScreeningRequestActions.request())
  try {
    yield call(callCreateListingScreeningRequestAPI, {
      propertyId,
      payload: reshapedScreeningRequest,
    })
    yield put(createListingScreeningRequestActions.success())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createListingScreeningRequestActions.failure())
  }
}

export const callGenerateApplyLinkAPI = ({ screeningRequestId }) =>
  apiInstanceWithErrorHandler.get(buildPath(GET_APPLY_LINK_API, { screeningRequestId }))

export function* createGenerateApplyLink({ screeningRequestId }) {
  yield put(generatePermalinkRequestActions.request())
  try {
    const { permalink } = yield call(callGenerateApplyLinkAPI, {
      screeningRequestId,
    })
    yield put(generatePermalinkRequestActions.success(permalink))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(generatePermalinkRequestActions.failure())
  }
}

export function* onApplicationTypeRequirementError(selectedApplicationType) {
  if (selectedApplicationType === APPLICATION_TYPE.CAR_LRA) {
    yield put(setCarModalState(true))
  }
}

export const selectIsCarWithPartnerIDIntegrated = makeSelectIsCarWIthPartnerIDIntegrated()

export function* submitScreeningRequest({ payload = {} }) {
  const {
    requestOptions,
    createScreeningRequest,
    screeningMethod,
    propertyId,
    source,
    currentPriceScheme,
    agentType,
  } = payload
  const createScreeningRequestForListing = source === SCREENING_REQUEST_SOURCE.LISTING
  const { applicationType, selectingItems, payer } = requestOptions

  const isSelectedItemsValidationError = yield call(checkSelectedItemsValidation, requestOptions)

  if (isSelectedItemsValidationError) {
    yield put(setValidationError(isSelectedItemsValidationError))
    return
  }

  yield call(getLinkAccountData)

  const isCarWithPartnerIDIntegrated = yield select(selectIsCarWithPartnerIDIntegrated)
  const isApplicationTypeRequirementValid = yield call(
    validateApplicationTypeRequirement,
    applicationType,
    isCarWithPartnerIDIntegrated,
  )

  if (!isApplicationTypeRequirementValid) {
    yield call(onApplicationTypeRequirementError, applicationType)
    return
  }

  const shouldShowDisclaimer =
    includes(selectingItems, 'criminal') &&
    !currentPriceScheme?.criminal?.disabled &&
    !get(requestOptions, 'isAcceptedBackgroundDisclaimer')
  if (shouldShowDisclaimer) {
    yield call([tracker, 'trackEvent'], 'clickNextFromRequestOptionsPage')
    yield put(setShowCriminalDisclaimerModal(shouldShowDisclaimer))
    yield call([tracker, 'trackEvent'], DELAY_CRIMINAL_MODAL.SEE_CRIMINAL_DISCLAIMER)
    return
  }

  yield call([tracker, 'trackEvent'], 'clickNextFromRequestOptionsPage')

  if (!createScreeningRequestForListing) {
    yield call(createScreeningRequest, {
      propertyId,
      screeningMethod,
      currentPriceScheme,
      source,
      agentType,
    })
    return
  }

  const {
    nextPage,
    apiPayload,
    apiResponse: { _id: screeningRequestId },
  } = yield call(createScreeningRequest, {
    propertyId,
    screeningMethod,
    currentPriceScheme,
    returnResult: true,
    source,
    agentType,
  })
  if (
    applicationType === APPLICATION_TYPE.RENTSPREE ||
    applicationType === null ||
    (applicationType === APPLICATION_TYPE.CAR_LRA && payer === PAYER_TYPES.RENTER)
  ) {
    try {
      yield call(createGenerateApplyLink, { screeningRequestId })
      yield call(createListingScreeningRequest, {
        propertyId,
        payload: {
          applicationType: get(apiPayload, 'applicationType'),
          hasApplication: get(apiPayload, 'selectedScreeningOption.application', false),
          hasCreditReport: get(apiPayload, 'selectedScreeningOption.credit_report', false),
          payerType: get(apiPayload, 'payerType'),
          hasCriminalRecord: get(apiPayload, 'selectedScreeningOption.criminal', false),
          hasEviction: get(apiPayload, 'selectedScreeningOption.eviction', false),
        },
      })
    } catch {
      return
    }
  }
  history.push(nextPage)
}

export function* createListingScreeningRequestWithRedirect({ payload }) {
  const { nextPage, propertyId, screeningRequestId, payloadRequest } = payload
  yield call(createGenerateApplyLink, { screeningRequestId })
  yield call(createListingScreeningRequest, {
    propertyId,
    payload: payloadRequest,
  })
  history.push(nextPage)
}

export function* watchGetLinkAccountDataCall() {
  yield takeLatest(GET_LINK_ACCOUNT_DATA_CALL, getLinkAccountData)
}

export function* watchSubmitScreeningRequest() {
  yield takeLatest(SUBMIT_SCREENING_REQUEST, submitScreeningRequest)
}

export function* watchcreateListingScreeningRequestWithRedirect() {
  yield takeLatest(
    CREATE_LISTING_SCREENING_REQUEST_CALL_WITH_REDIRECT,
    createListingScreeningRequestWithRedirect,
  )
}

export function* rootSaga() {
  yield all([
    watchGetLinkAccountDataCall(),
    watchSubmitScreeningRequest(),
    watchcreateListingScreeningRequestWithRedirect(),
  ])
}

export default rootSaga
