export const feeField = 'fees'
export const rentField = 'rent'
export const INCENTIVE_V5_QUERY_PARAM_CAMPAIGN_NAME = 'landlord-gets-advance-payment'

export const selectionOptions = Object.freeze({
  [rentField]: 'Rent',
  [feeField]: 'Deposits & other fees',
})

export const ButtonLayoutEnum = Object.freeze({
  // an enum to sync button layout options between CreateButtons & the footer's parent renderers
  NEXT: 'next',
  NEXT_BACK: 'next-back',
})

export const PaymentCategories = Object.freeze({
  RENT: 'Rent',
  LATE_FEE: 'Late fee',
  SECURITY_DEPOSIT: 'Security deposit',
  UTILITIES: 'Utilities',
  PRORATED_RENT: 'Prorated rent',
  PET_DEPOSIT: 'Pet deposit',
  OTHER: 'Other',
})

// deposit-fees.js
const { RENT, LATE_FEE, SECURITY_DEPOSIT, UTILITIES, PRORATED_RENT, PET_DEPOSIT, OTHER } =
  PaymentCategories
export const DEPOSIT_AND_FEES = [SECURITY_DEPOSIT, UTILITIES, PRORATED_RENT, PET_DEPOSIT, OTHER]
export const ONE_TIME_PAYMENTS = [SECURITY_DEPOSIT, PRORATED_RENT, PET_DEPOSIT]
export const RENT_PAYMENTS = [RENT, LATE_FEE]
export const MULTIPLE_SELECTION_PAYMENTS = [OTHER, UTILITIES]

export const pageOptions = Object.freeze({
  // an enum to sync button layout options between CreateButtons & the footer's parent renderers
  RECIPIENT_DETAILS: 'recipient-details',
  PROPERTY_AND_TENANT: 'property-and-tenant',
  PAYMENT_DETAILS: 'payment-details',
  REVIEW: 'review',
})

export const PAYMENT_TYPES = Object.freeze({
  RECURRING: 'recurring',
  ONE_TIME: 'oneTime',
})

export const RECIPIENT_INFO_FIELDS = Object.freeze({
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  RECIPIENT_INFO: 'recipient-info',
})

export const recipientDetailsOptions = Object.freeze({
  ME: 'me',
  CLIENT: 'client',
})

export const INCENTIVE_V5 = Object.freeze({
  TAG: 'get paid early *',
  REMARK:
    '* We reward your tenants if they pay rent early every month so you can boost your cashflow!',
})

export const recipientFields = {
  recipient: 'recipient',
  recipientInfo: 'recipient-info',
}
