import produce from "immer"

import {
  SEND_APPLY_LINK_START,
  SEND_APPLY_LINK_SUCCESS,
  SEND_APPLY_LINK_FAILED,
} from "./const"

export const initialState = {
  isSendingApplyLink: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case SEND_APPLY_LINK_START:
        draft.isSendingApplyLink = true
        break
      case SEND_APPLY_LINK_SUCCESS:
        draft.isSendingApplyLink = false
        break
      case SEND_APPLY_LINK_FAILED:
        draft.isSendingApplyLink = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
