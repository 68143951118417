/* eslint-disable react/no-unused-state */
import React from 'react'
import { compose } from 'redux'
import filter from 'lodash/filter'
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isFinite from 'lodash/isFinite'
import sum from 'lodash/sum'
import { Alert } from '@rentspree/component-v2'
import { withLastLocation } from 'react-router-last-location'
import { Redirect } from 'react-router-dom'
import CriminalDisclaimerModal from 'components/modal/criminal-disclaimer-modal'
import { ButtonSetLeaseAgreement } from 'components/buttons/button-set'
import { DELAY_CRIMINAL_MODAL } from 'tracker/const'
import tracker from 'tracker'
import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { handleGoBackByDefault } from 'utils/history'
import { query } from '@rentspree/path'
import ValidateCARModal from 'components/modal/validate-car-modal'
import { VALIDATE_CAR_MODAL } from 'containers/envelope/select-options-step/constants'
import BooleanCard from 'components/molecules/boolean-card'
import { ScreeningRequestForm } from 'components/organisms/screening-request-form'

import {
  PRICING_SCHEME,
  ALERT_WORDING,
  ALERT_PAYER_TYPE,
} from 'legacy/constants/screening-plan-consts'
import { RequestContainer } from 'legacy/components/request/request-main-style'
import { Section } from 'legacy/components/request/heading'
import { PayerBoxWrapper } from 'legacy/components/request/select-payer'
import * as ERRORS from 'legacy/constants/error-messages'
import { LoadingOverlay } from 'legacy/components/request/left-content/index'
import { NotiSuccess } from 'legacy/components/notification/noti-success'
import { PAYER } from 'legacy/constants/request-const'
import { PARTNER } from 'legacy/constants/partner-consts'
import { STATE } from 'legacy/constants/state-consts'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import { Div } from 'components/layout/main'
import CheckboxImage from 'components/atoms/checkbox-image'
import RadioImage from 'components/atoms/radio-image'
import ComponentList from 'components/atoms/component-list'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import { APPLICATION_TYPE } from 'constants/application-type'
import { InfoText } from 'components/atoms/info-text'
import { COLOR } from 'components/constants'

import { SCREENING_REQUEST_SOURCE } from 'containers/constants'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { ProOptionForm } from 'components/organisms/pro-option-form'
import LegalRestrictionText from 'components/molecules/legal-restriction-text'
import {
  getProOptionPayload,
  shouldRedirectToSelectAgentTypePage,
} from 'containers/tenant-screening/request/utils'
import {
  EVENT_ENTER_SELECT_OPTION_PAGE,
  EVENT_RENTSPREE_PRO,
  OTHER_DOCS,
  REF_CHECK,
} from 'legacy/tracker/const'
import { referenceType } from 'legacy/constants/ref-check-consts'
import { SCREENING_REQUEST } from './constants'
import { selectLraTrackerDecorator, isMiamiDadeZipCode } from './helpers'
import { withConnect, withReducer, withSaga } from './connect'

export class RequestStepOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPriceScheme: undefined,
      isGetScreeningPlansSuccess: false,
      isAcceptedBackgroundDisclaimer: false,
      isAlreadyTrackedEnterPageEvent: false,
    }
  }

  componentWillMount() {
    const { actions, match, subdomain, createSuccess, property, screeningRequestActions } =
      this.props
    const { propertyId } = match.params
    const options = { subdomain }
    if (!createSuccess) {
      actions.getProperty(propertyId, options)
    } else {
      this.getScreeningPlans(property)
    }
    actions.getUserPreference()
    screeningRequestActions.getLinkAccountDataCall()
  }

  componentWillReceiveProps(nextProps) {
    const { property, isFetchingUserPlan, userPlan, isFetchedPreference, preference } = nextProps
    const { property: oldProperty } = this.props
    if (oldProperty.isFetching && !property.isFetching) {
      this.getScreeningPlans(property)
    }

    const { isAlreadyTrackedEnterPageEvent } = this.state
    const shouldTrackEnterPageEvent =
      !isAlreadyTrackedEnterPageEvent && !isFetchingUserPlan && isFetchedPreference
    if (shouldTrackEnterPageEvent) {
      const isPro = userPlan === SUBSCRIPTION_PLAN.PRO
      const isFirstTimeUser = isEmpty(preference)
      const { eventName, properties } = EVENT_ENTER_SELECT_OPTION_PAGE
      tracker.trackEvent(
        eventName,
        properties({
          isPro,
          isFirstTimeUser,
        }),
      )
      this.setState({ isAlreadyTrackedEnterPageEvent: true })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearCreatePropertyNoti()
    this.props.actions.clearValidationError()
  }

  selectApplicationType = applicationType => {
    const { setValidationError, setApplicationType, selectPricingItem } = this.props.actions
    setValidationError(false)
    const currentState = this.props.requestOptions.applicationType
    const isSelected = currentState !== applicationType
    if (!isSelected) {
      selectPricingItem('application', 0)
      setApplicationType(null)
    } else {
      if (currentState === null || applicationType === null) {
        selectPricingItem('application', 0)
      }
      setApplicationType(applicationType)
    }
    return { isSelected, applicationType }
  }

  getScreeningPlans(propertyState) {
    const { actions, createSuccess } = this.props
    const { isGetScreeningPlansSuccess } = this.state
    const property = get(propertyState, 'property', {})
    const { fetchPropertySuccess = false, state = '', zip } = property
    if ((fetchPropertySuccess || createSuccess) && !isGetScreeningPlansSuccess) {
      actions.getScreeningPlanApi(state, zip)
      this.setState({ isGetScreeningPlansSuccess: true })
    }
  }

  /**
   * this method convert string to price string
   * @param {String} value the string of the value that is needed to convert to price
   * @returns {String} value but with a dollar sign added, or Free otherwise
   */
  convertToPrice = value => {
    const parsedValue = parseInt(value, 10)
    const priceText = !isFinite(parsedValue) && 0
    return priceText === 0 || parsedValue === 0 ? 'Free' : `$${value}`
  }

  /**
   * this method select the item according to payerType
   * @param {String} payer the payer time
   * @param {Array<Object>} itemList the itemList to be selected from
   * @returns {Object} item
   */
  getCurrentPriceScheme(payer, itemList) {
    return head(filter(itemList, ['payerType', payer]))
  }

  /**
   * Calculate total pricing according to the selectingItem and the current pricing scheme
   * @param {Object} currentPricingScheme the current pricing scheme
   * @returns {Number} the total cost
   */
  getCurrentTotalPrice(currentPricingScheme) {
    let selectingItems = get(this.props, 'requestOptions.selectingItems', [])
    // selectingItems = subdomain === "caa" ? without(selectingItems, "criminal", "eviction") : selectingItems
    if (!Array.isArray(selectingItems)) {
      selectingItems = [selectingItems]
    }
    return sum(selectingItems.map(item => get(currentPricingScheme, `${item}.price`, 0)))
  }

  trackSubmitEvent = (requestOptions, userPlan) => {
    const isProUser = userPlan === SUBSCRIPTION_PLAN.PRO
    const {
      eventName: otherDocsEventName,
      properties: otherDocsProperties,
      mapping: otherDocsMapping,
    } = EVENT_RENTSPREE_PRO.OTHER_DOCS
    const { CLICK_FROM: OTHER_DOCS_CLICK_FROM } = OTHER_DOCS.EVENT_PROPERTY

    const selectedDocumentProOption = get(requestOptions, 'selectedDocumentProOption', {})
    const selectedRefCheckProOption = get(requestOptions, 'selectedRefCheckProOption', {})
    const { documents = [], references = [] } = getProOptionPayload(
      selectedDocumentProOption,
      selectedRefCheckProOption,
    )
    documents.forEach(document => {
      const { type, note } = document
      const selectType = get(otherDocsMapping, [type], '')
      tracker.trackEvent(
        otherDocsEventName.request,
        otherDocsProperties({
          type: selectType,
          isPro: isProUser,
          hasInstruction: !!note,
          clickFrom: OTHER_DOCS_CLICK_FROM.SCREENING_OPTION,
        }),
      )
    })

    const { eventName: refCheckEventName, properties: refCheckProperties } =
      EVENT_RENTSPREE_PRO.REF_CHECK
    const { CLICK_FROM: REF_CHECK_CLICK_FROM } = REF_CHECK.EVENT_PROPERTY
    references.forEach(reference => {
      const { type, arrayIndex } = reference
      const selectType =
        arrayIndex !== null && type === referenceType.personal
          ? `${type}_reference_${arrayIndex + 1}`
          : type
      tracker.trackEvent(
        refCheckEventName,
        refCheckProperties({
          clickFrom: REF_CHECK_CLICK_FROM.SCREENING_OPTION,
          type: selectType,
          isPro: isProUser,
        }),
      )
    })
  }

  handleGoToStepActions = currentPriceScheme => {
    const {
      match,
      source,
      screeningMethod,
      requestOptions,
      screeningRequestActions,
      userPlan,
      agentType,
    } = this.props
    const { propertyId } = match.params
    screeningRequestActions.submitScreeningRequest({
      propertyId,
      source,
      currentPriceScheme,
      screeningMethod,
      requestOptions,
      createScreeningRequest: this.props.createScreeningRequest,
      agentType,
    })

    this.trackSubmitEvent(requestOptions, userPlan)
  }

  handleConfirmCriminalDisclaimerModal = async currentPriceScheme => {
    const {
      match,
      source,
      screeningMethod,
      screeningRequestActions,
      createScreeningRequest,
      agentType,
    } = this.props
    const isListingScreeningRequest = source === SCREENING_REQUEST_SOURCE.LISTING
    const { propertyId } = match.params
    const { isAcceptedBackgroundDisclaimer } = this.state
    const {
      nextPage,
      apiPayload,
      apiResponse: { _id: screeningRequestId },
    } = await createScreeningRequest({
      propertyId,
      screeningMethod,
      currentPriceScheme,
      isAcceptedBackgroundDisclaimer,
      returnResult: isListingScreeningRequest,
      source,
      agentType,
    })
    tracker.trackEvent(
      DELAY_CRIMINAL_MODAL.CONFIRM_CRIMINAL_DISCLAIMER.name,
      DELAY_CRIMINAL_MODAL.CONFIRM_CRIMINAL_DISCLAIMER.properties({
        dontAskMe: isAcceptedBackgroundDisclaimer,
      }),
    )
    screeningRequestActions.setShowCriminalDisclaimerModal(false)

    if (nextPage && isListingScreeningRequest) {
      screeningRequestActions.createListingScreeningRequestCallWithRedirect({
        nextPage,
        propertyId,
        screeningRequestId,
        payloadRequest: {
          applicationType: get(apiPayload, 'applicationType'),
          hasApplication: get(apiPayload, 'selectedScreeningOption.application', false),
          hasCreditReport: get(apiPayload, 'selectedScreeningOption.credit_report', false),
          payerType: get(apiPayload, 'payerType'),
          hasCriminalRecord: get(apiPayload, 'selectedScreeningOption.criminal', false),
          hasEviction: get(apiPayload, 'selectedScreeningOption.eviction', false),
        },
      })
    }
  }

  withTrackFromAlaCartePage = func => () => {
    tracker.trackEvent('clickBackToDashboardFromAlacartePage')
    func()
  }

  handleBackToDashboard = () => {
    this.props.actions.clearRequestOptions()
    this.props.actions.clearScreeningWithPropertyProOptions()
    const { location } = this.props
    const { isBackToDashboard } = query.parse(location.search)
    if (isBackToDashboard) handleGoBackByDefault(null)
    else handleGoBackByDefault(this.props.lastLocation)
  }

  async checkValidationError() {
    const { actions, requestOptions } = this.props
    const { selectingItems, payer } = requestOptions
    if (!payer) {
      actions.setValidationError(true)
      return
    }
    if (isEmpty(selectingItems)) {
      actions.setValidationError(true)
      return
    }
    actions.setValidationError(false)
  }

  clickCheckBox = (reportType, currentPriceScheme) => {
    const { selectingItems } = this.props.requestOptions
    this.props.actions.setValidationError(false)
    if (includes(['application', 'credit_report', 'criminal', 'eviction'], reportType)) {
      switch (includes(selectingItems, reportType)) {
        case true:
          tracker.trackEvent(`uncheck${reportType}InAlacarte`)
          break
        case false:
          tracker.trackEvent(`check${reportType}InAlacarte`)
          break
        default:
          break
      }
    }
    this.props.actions.selectPricingItem(reportType, currentPriceScheme)
  }

  clickDoNotAskCheckBox = () => {
    this.setState(prevState => ({
      isAcceptedBackgroundDisclaimer: !prevState.isAcceptedBackgroundDisclaimer,
    }))
  }

  /**
   * this method handle select payer type
   * @param {String} payerType the type of payer that user select
   */
  handleSelectPayer = payerType => {
    switch (payerType) {
      case PAYER.AGENT:
        tracker.trackEvent('selectPayerToAgent')
        break
      case PAYER.RENTER:
        tracker.trackEvent('selectPayerToRenter')
        break
      default:
        break
    }
    const { requestOptions, actions } = this.props
    // Set default application type for new user
    if (isEmpty(requestOptions.payer)) {
      actions.selectPricingItem('application', 0)
      actions.setApplicationType(APPLICATION_TYPE.RENTSPREE)
    }
    actions.selectPayer(payerType)
  }

  isLoading() {
    const {
      property,
      profile,
      requestOptions,
      isFetchingLinkAccountData,
      isGeneratingPermalink,
      isCreatingListingScreeningRequest,
      isFetchingUserPlan,
    } = this.props
    return (
      isEmpty(profile) ||
      property.isFetching ||
      requestOptions.isFetching ||
      isFetchingLinkAccountData ||
      isCreatingListingScreeningRequest ||
      isGeneratingPermalink ||
      isFetchingUserPlan
    )
  }

  showAlertInfoPayer = state => {
    let text = ''
    switch (state) {
      case STATE.MA:
        text = ALERT_PAYER_TYPE.MA
        break
      default:
        break
    }
    if (isEmpty(text)) {
      return <div />
    }
    return <InfoText text={text} />
  }

  showAlertInfoGray = ({ subdomain, state, zipcode }) => {
    const text = []
    switch (subdomain) {
      case PARTNER.CAA:
        text.push(ALERT_WORDING.CAA)
        break
      default:
        break
    }
    switch (state) {
      case STATE.NY:
        text.push(ALERT_WORDING.NY)
        break
      case STATE.NJ:
        text.push(ALERT_WORDING.NJ)
        break
      case STATE.IL:
        text.push(ALERT_WORDING.IL)
        break
      case STATE.MD:
        text.push(ALERT_WORDING.MD)
        break
      case STATE.DC:
        text.push(ALERT_WORDING.DC)
        break
      case STATE.FL:
        if (isMiamiDadeZipCode(zipcode)) {
          text.push(ALERT_WORDING.MIAMI_DADE)
        }
        break
      default:
        break
    }
    if (isEmpty(text)) {
      return <div />
    }
    return (
      <Alert
        padding="15px 20px"
        fontSize="12px"
        lineHeight="16px"
        infoGray
        sourceSansPro
        margin="20px 0 -6px 0"
        text={text}
      />
    )
  }

  chooseButtonSetProps = defaultButtonSetProps => {
    switch (this.props?.lastLocation?.pathname) {
      case `/properties/${this.props.match.params.propertyId}/listing-request-create-screening-options`:
        return {
          ...defaultButtonSetProps,
          withSystemLayout: true,
          isShowPrev: true,
          hideBackButton: true,
          onBorder: true,
          onBorderDesktop: true,
          continueText: 'Save & Next',
        }
      default:
        return { ...defaultButtonSetProps }
    }
  }

  openValidateCarMemberModal = () => {
    const { actions, requestOptions, isCarWithPartnerIDIntegrated } = this.props
    const currentApplicationState = requestOptions.applicationType
    const isClickLraAppFromAnotherOption = currentApplicationState !== APPLICATION_TYPE.CAR_LRA
    if (!isCarWithPartnerIDIntegrated && isClickLraAppFromAnotherOption)
      actions.setCarModalState(true)
  }

  handlePrevButton = () => {
    const { history } = this.props
    history.goBack()
  }

  shouldRenderScreeningOption = option => option && !option.disabled

  render() {
    const {
      fullNameAddress,
      subdomain,
      createSuccess,
      requestOptions,
      actions,
      carLinkingAccountLoading,
      carLinkingAccountError,
      carLinkingAccountSuccess,
      showCriminalDisclaimerModal,
      screeningRequestActions,
      property,
      userPlan,
      agentType,
      match,
    } = this.props
    const { propertyId } = match.params

    const isProUser = userPlan === SUBSCRIPTION_PLAN.PRO
    const {
      payer,
      isValidationError,
      selectingItems,
      itemList,
      isGetScreeningPlanError,
      applicationType,
    } = requestOptions
    const currentPriceScheme = this.getCurrentPriceScheme(payer, itemList)
    const total = this.getCurrentTotalPrice(currentPriceScheme)

    const isSubdomainCaa = subdomain === PARTNER.CAA

    const state = property?.property?.state

    const defaultButtonSetProps = {
      isShowPrev: true,
      withSystemLayout: true,
      onBorderDesktop: true,
      onBorder: true,
      prevText: 'Back',
      hideBackButton: true,
      text: 'Back to dashboard',
      onClickBackToDashboardBtn: this.withTrackFromAlaCartePage(this.handleBackToDashboard),
      onClickPrevBtn: this.handlePrevButton,
      hasIconNextBtn: true,
      continueText: 'Next',
      onSaveAndNext: () => this.handleGoToStepActions(currentPriceScheme),
      dataTestKey: 'screening-options-page',
    }
    const buttonSetProps = this.chooseButtonSetProps(defaultButtonSetProps)
    if (isGetScreeningPlanError) {
      return (
        <FailedToFetch
          withBreadcrumb
          withReportTabs
          noMargin
          title={ERRORS.REQUEST.GET_SCREENING_PLAN.TITLE}
          text={ERRORS.REQUEST.GET_SCREENING_PLAN.MESSAGE}
        />
      )
    }

    const { shouldRedirect, redirectPath } = shouldRedirectToSelectAgentTypePage({
      agentType,
      propertyId,
    })
    if (shouldRedirect) {
      return <Redirect push to={redirectPath} />
    }

    return (
      <RequestContainer>
        <ValidateCARModal
          id="validateCarMemberModalOnAlaCartPage"
          isOpen={this.props.carModalState}
          linkingAccount={actions.linkAccountWithoutSSO}
          onClose={() => {
            actions.setCarModalState(false, null)

            this.selectApplicationType(null)
            if (!includes(selectingItems, 'credit_report')) {
              this.clickCheckBox('credit_report', currentPriceScheme)
            }
          }}
          closeError={actions.closeCarModalError}
          title={VALIDATE_CAR_MODAL.TITLE}
          loading={carLinkingAccountLoading}
          error={isEmpty(carLinkingAccountError) ? false : carLinkingAccountError}
          body={VALIDATE_CAR_MODAL.DESCRIPTION}
          loaded={carLinkingAccountSuccess}
          linkingFrom={SCREENING_REQUEST}
        />

        <Div sourceSansPros>
          <NotiSuccess message="The property has been added." hidden={!createSuccess} />
          <TitleWithSubtitle
            title="Select Application Options"
            subtitle={`for ${fullNameAddress}`}
            mb="30"
            dataTestKey="screening-option-page-full-address"
          />

          {this.isLoading() && <LoadingOverlay />}
          <Section>
            <S16 mb={15}>Who will pay the application fee?</S16>
            <PayerBoxWrapper>
              <BooleanCard
                title="Applicants"
                checked={payer === PAYER.RENTER}
                onClick={() => this.handleSelectPayer(PAYER.RENTER)}
                validationError={isValidationError && !payer}
                icon={RadioImage}
                dataTestId="screening-option-applicant-pay-radio"
              />
              <BooleanCard
                title="Me"
                checked={payer === PAYER.AGENT}
                onClick={() => this.handleSelectPayer(PAYER.AGENT)}
                validationError={isValidationError && !payer}
                icon={RadioImage}
                dataTestId="screening-option-agent-pay-radio"
              />
            </PayerBoxWrapper>
            {this.showAlertInfoPayer(state)}
          </Section>
          {currentPriceScheme &&
            this.shouldRenderScreeningOption(currentPriceScheme.application) &&
            !isSubdomainCaa && (
              <Section id="SelectApplicationSection">
                <S16 mb={15} mt={0}>
                  {' '}
                  Select Application{' '}
                </S16>
                <ComponentList bottomGap={10}>
                  <BooleanCard
                    id="LraApplicationBooleanCard"
                    title={PRICING_SCHEME.car_lra.text}
                    subtitle={PRICING_SCHEME.car_lra.subtext}
                    checked={applicationType === APPLICATION_TYPE.CAR_LRA}
                    ascent={this.convertToPrice('0')}
                    icon={RadioImage}
                    onClick={() => {
                      if (applicationType !== APPLICATION_TYPE.CAR_LRA) {
                        selectLraTrackerDecorator(
                          this.selectApplicationType,
                          APPLICATION_TYPE.CAR_LRA,
                        )
                        this.openValidateCarMemberModal()
                      }
                    }}
                    validationError={false}
                    dataTestId="screening-option-car-lra-application-radio"
                  />
                  <BooleanCard
                    id="RentSpreeApplicationBooleanCard"
                    title={PRICING_SCHEME.application.text}
                    checked={applicationType === APPLICATION_TYPE.RENTSPREE}
                    ascent={
                      currentPriceScheme
                        ? this.convertToPrice(currentPriceScheme.application.price)
                        : 'Free'
                    }
                    icon={RadioImage}
                    onClick={() => {
                      if (applicationType !== APPLICATION_TYPE.RENTSPREE)
                        this.selectApplicationType(APPLICATION_TYPE.RENTSPREE)
                    }}
                    validationError={false}
                    dataTestId="screening-option-rentspree-application-radio"
                  />

                  <BooleanCard
                    id="NoApplicationBooleanCard"
                    title={PRICING_SCHEME.no_application.text}
                    checked={applicationType === null}
                    ascent={null}
                    icon={RadioImage}
                    onClick={() => {
                      if (applicationType !== null) {
                        this.selectApplicationType(null)
                        if (!includes(selectingItems, 'credit_report')) {
                          this.clickCheckBox('credit_report', currentPriceScheme)
                        }
                      }
                    }}
                    dataTestId="screening-option-no-application-radio"
                  />
                </ComponentList>
              </Section>
            )}
          {currentPriceScheme && (
            <Section mb={10}>
              <ScreeningRequestForm totalPrice={total}>
                <S16 mb={15}>Customize the screening reports</S16>
                <ComponentList bottomGap={10}>
                  {this.shouldRenderScreeningOption(currentPriceScheme.credit_report) && (
                    <BooleanCard
                      title={PRICING_SCHEME.credit_report.text}
                      subtitle={PRICING_SCHEME.credit_report.subtext}
                      ascent={this.convertToPrice(currentPriceScheme.credit_report.price)}
                      checked={includes(selectingItems, 'credit_report')}
                      icon={CheckboxImage}
                      alt="credit_report"
                      onClick={() => this.clickCheckBox('credit_report', currentPriceScheme)}
                      validationError={
                        isValidationError && !includes(selectingItems, 'credit_report')
                      }
                      dataTestId="screening-option-credit-report-checkbox"
                    />
                  )}
                  {this.shouldRenderScreeningOption(currentPriceScheme.criminal) && (
                    <BooleanCard
                      title={PRICING_SCHEME.criminal.text}
                      subtitle={PRICING_SCHEME.criminal.subtext}
                      ascent={this.convertToPrice(currentPriceScheme.criminal.price)}
                      checked={includes(selectingItems, 'criminal')}
                      icon={CheckboxImage}
                      onClick={() => this.clickCheckBox('criminal')}
                      disabled={!includes(selectingItems, 'credit_report')}
                      alt="criminal"
                      validationError={
                        includes(selectingItems, 'credit_report') &&
                        isValidationError &&
                        !includes(selectingItems, 'criminal')
                      }
                      dataTestId="screening-option-criminal-checkbox"
                    />
                  )}
                  {this.shouldRenderScreeningOption(currentPriceScheme.eviction) && (
                    <BooleanCard
                      title={PRICING_SCHEME.eviction.text}
                      subtitle={PRICING_SCHEME.eviction.subtext}
                      ascent={this.convertToPrice(currentPriceScheme.eviction.price)}
                      checked={includes(selectingItems, 'eviction')}
                      icon={CheckboxImage}
                      alt="eviction"
                      onClick={() => this.clickCheckBox('eviction')}
                      disabled={!includes(selectingItems, 'credit_report')}
                      validationError={
                        includes(selectingItems, 'credit_report') &&
                        isValidationError &&
                        !includes(selectingItems, 'eviction')
                      }
                      dataTestId="screening-option-eviction-checkbox"
                    />
                  )}
                </ComponentList>
                {isValidationError && !includes(selectingItems, 'credit_report') && (
                  <S16 color={COLOR.negativeRed} weight="400" mt={10}>
                    Please make a selection
                  </S16>
                )}
                {this.showAlertInfoGray({
                  subdomain,
                  state: currentPriceScheme.state,
                  zipcode: property.property?.zip,
                })}
                <LegalRestrictionText />
                {isProUser && (
                  <ProOptionForm
                    documentOption={get(this.props, `requestOptions.selectedDocumentProOption`, {})}
                    refCheckOption={get(this.props, `requestOptions.selectedRefCheckProOption`)}
                    onDocumentOptionChange={get(this.props, 'actions.updateDocumentProOption')}
                    onRefCheckOptionChange={get(this.props, 'actions.updateRefCheckProOption')}
                  />
                )}
              </ScreeningRequestForm>
            </Section>
          )}

          <ButtonSetLeaseAgreement
            {...buttonSetProps}
            disabledNextButton={isEmpty(payer)}
            margin="0 0 40px 0"
          />

          <CriminalDisclaimerModal
            show={showCriminalDisclaimerModal}
            backdrop="static"
            isSelected={this.state.isAcceptedBackgroundDisclaimer}
            onCheckBoxClick={this.clickDoNotAskCheckBox}
            closeButton
            closeModal={() => screeningRequestActions.setShowCriminalDisclaimerModal(false)}
            onConfirmClick={() => this.handleConfirmCriminalDisclaimerModal(currentPriceScheme)}
          />
        </Div>
      </RequestContainer>
    )
  }
}

export default compose(withLastLocation, withConnect, withReducer, withSaga)(RequestStepOptions)
