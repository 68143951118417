import { useSelector } from 'react-redux'
import { buildPath } from '@rentspree/path'
import { usePathname } from 'hooks'
import { selectRentalDetail } from 'containers/reports/selectors'
import { APPLICATION_ALL_REPORTS_PAGE_WITHOUT_PROPERTY } from 'constants/route'
import { useLastLocation } from 'react-router-last-location'
import { isGotoNewLocation } from 'hooks/helper'
import { selectPropertyId } from 'containers/tenant-screening/detail/selectors'
import { RENTAL_STATUS } from 'legacy/constants/rental-consts'

const { SUBMITTED } = RENTAL_STATUS

export const useToOpenReportWithoutPropertyPath = queryString => {
  const lastLocation = useLastLocation()
  const { isScreeningWithoutProperty, _id: rentalAppId, status } = useSelector(selectRentalDetail)
  const propertyId = useSelector(selectPropertyId)

  const pathname = usePathname()
  const isRedirect = isGotoNewLocation({
    pathname,
    destinationPath: APPLICATION_ALL_REPORTS_PAGE_WITHOUT_PROPERTY,
    lastLocationPath: lastLocation?.pathname,
  })
  if (!isScreeningWithoutProperty || propertyId || status !== SUBMITTED) return null

  return {
    isRedirect,
    path: buildPath(
      APPLICATION_ALL_REPORTS_PAGE_WITHOUT_PROPERTY,
      {
        rentalAppId,
      },
      queryString,
    ),
  }
}
