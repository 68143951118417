import { Button, TextArea } from '@rentspree/component-v2/dist'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import React from 'react'
import styled from 'styled-components'
import { COLOR, breakpoints } from 'styles/settings'

const ContentContainer = styled.div`
  font-family: 'Source Sans Pro';
`

const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  color: ${COLOR.textBlack};
`

const Text = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  color: ${COLOR.textBlack};
`

const TextLabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

const ContentWrapper = styled.div`
  margin-bottom: 20px;
`

const CancelTextArea = styled(TextArea)`
  resize: none;
  height: 90px;
`

const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  ${breakpoints.mobile(`
    position: absolute;
    bottom: 0px;
    right: 0px
    margin: 20px
  `)};
`

export const CancelButton = styled(BlueGradientButton)`
  width: 160px;
  ${breakpoints.mobile(`
    width: auto;
  `)};
`

export const SubmitButton = styled(Button)`
  width: 160px;
  ${breakpoints.mobile(`
    width: auto;
  `)};
`

const INPUT_MESSAGE_MAX_LENGTH = 250

const CancelDocumentRequestModal = ({ isOpen, onClose, onClickConfirm }) => {
  const [message, setMessage] = React.useState('')

  const handleCloseModal = () => {
    setMessage('')
    onClose()
  }

  const handleClickConfirm = () => {
    setMessage('')
    onClickConfirm(message)
  }

  return (
    <RentSpreeBaseModal
      isOpen={isOpen}
      onClose={onClose}
      closeButton={false}
      modalSize="small"
      shouldCloseOnOverlayClick
    >
      <ContentContainer>
        <Title>Cancel Document Request?</Title>
        <Text>The applicant will be notified.</Text>
        <ContentWrapper>
          <TextLabel>Add a Message</TextLabel>
          <CancelTextArea
            value={message}
            onChange={event => setMessage(event.target.value)}
            maxLength={INPUT_MESSAGE_MAX_LENGTH}
          />
        </ContentWrapper>
        <ButtonGroupWrapper>
          <SubmitButton google small id="cancelDocumentRequestButton" onClick={handleClickConfirm}>
            Yes, Cancel
          </SubmitButton>
          <CancelButton small semiBold onClick={handleCloseModal}>
            Back
          </CancelButton>
        </ButtonGroupWrapper>
      </ContentContainer>
    </RentSpreeBaseModal>
  )
}

export default CancelDocumentRequestModal
