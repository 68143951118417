import { SUBSCRIPTION_PLAN_API_V2 } from "constants/route"
import * as sentry from "@sentry/browser"
import { apiInstance } from "./api-interceptor"

export const getUserPlan = async () => {
  try {
    const { result } = await apiInstance.get(SUBSCRIPTION_PLAN_API_V2)
    return result
  } catch (error) {
    sentry.captureException(error)
    return undefined
  }
}
