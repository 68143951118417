import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { makeSelectProfile } from 'containers/navbar/selectors'
import { selectNotification, selectRentalPaymentNotifications } from 'containers/overview/notifications/selectors'
import { getNotificationCall, clearNotification } from 'containers/overview/notifications/actions'

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getNotificationCall,
        clearNotification,
      },
      dispatch,
    ),
  }
}

export const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  notification: selectNotification(),
  rentalPaymentNotifications: selectRentalPaymentNotifications(),
})

export const withPropertyInnerTabs = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
