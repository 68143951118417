import { API_URL } from 'env'

export const IMPORT_CSV = 'IMPORT_CSV'

export const CREATE_CONTACTS_API = `${API_URL}/api/v2/people/contacts/batch`

export const BULK_CREATE_CONTACT_CALL = 'BULK_CREATE_CONTACT_CALL'
export const BULK_CREATE_CONTACT_STARTED = 'BULK_CREATE_CONTACT_STARTED'
export const BULK_CREATE_CONTACT_CANCELLED = 'BULK_CREATE_CONTACT_CANCELLED'
export const BULK_CREATE_CONTACT_CANCEL_COFIRMED = 'BULK_CREATE_CONTACT_CANCEL_COFIRMED'
export const BULK_CREATE_CONTACT_SUCCESS = 'BULK_CREATE_CONTACT_SUCCESS'
export const SET_CURRENT_CREATED_COUNT = 'SET_CURRENT_CREATED_COUNT'
export const CREATE_CHUNK_CONTACT_FAILURE = 'CREATE_CHUNK_CONTACT_FAILURE'
export const CLEAR_IMPORT_CSV_PROGRESS = 'CLEAR_IMPORT_CSV_PROGRESS'
export const CLOSE_IMPORT_WIDGET = 'CLOSE_IMPORT_WIDGET'
export const TRIGGER_MINIMIZE_WIDGET = 'TRIGGER_MINIMIZE_WIDGET'

export const SET_MAPPED_DATA = 'SET_MAPPED_DATA'

export const UPLOAD_STEP = 'uploadStep'
export const MAPPED_TABLE_STEP = 'mappedTableStep'

export const SET_FILE_NAME = 'SET_FILE_NAME'
export const SET_HEADER = 'SET_HEADER'
export const TOGGLE_ROW = 'TOGGLE_ROW'
export const SET_COLUMN_ERROR = 'SET_COLUMN_ERROR'

export const SET_FULL_VALIDATION_ERROR = 'SET_FULL_VALIDATION_ERROR'

// Field header
export const FIRST_NAME = 'firstName'
export const MIDDLE_NAME = 'middleName'
export const LAST_NAME = 'lastName'
export const EMAIL = 'email'
export const MOBILE_NUMBER = 'mobileNumber'
export const PHONE_NUMBER = 'phoneNumber'
export const OFFICE_NUMBER = 'officeNumber'

export const BIRTH_DATE_TEXT = 'birthDate_text'
export const BIRTH_DATE_NUMBER = 'birthDate_number'

export const MOVE_IN_DATE_TEXT = 'moveInDate_text'
export const MOVE_IN_DATE_NUMBER = 'moveInDate_number'

export const STREET = 'street'
export const CITY = 'city'
export const STATE = 'state'
export const STATE_FULL = 'state_full'
export const STATE_CODE = 'state_code'
export const ZIPCODE = 'zipcode'

export const NONE_OF_ABOVE = 'noneOfAbove'
export const REMOVED = 'removed'

export const IMPORT_PROGRESS_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  PARTIALLY_SUCCESS: 'PARTIALLY_SUCCESS',
}

export const COLUMN_HEADER_OPTIONS = [
  {
    label: 'First Name',
    value: FIRST_NAME,
  },
  {
    label: 'Middle Name',
    value: MIDDLE_NAME,
  },
  {
    label: 'Last Name',
    value: LAST_NAME,
  },
  {
    label: 'Email',
    value: EMAIL,
  },
  {
    label: 'Mobile',
    value: MOBILE_NUMBER,
  },
  {
    label: 'Home',
    value: PHONE_NUMBER,
  },
  {
    label: 'Work',
    value: OFFICE_NUMBER,
  },
  {
    label: 'Birthday (e.g. 12/24/1990)',
    value: BIRTH_DATE_NUMBER,
  },
  {
    label: 'Birthday (e.g. Dec 24 1990)',
    value: BIRTH_DATE_TEXT,
  },
  {
    label: 'Move-in (e.g. 12/24/1990)',
    value: MOVE_IN_DATE_NUMBER,
  },
  {
    label: 'Move-in (e.g. Dec 24 1990)',
    value: MOVE_IN_DATE_TEXT,
  },
  {
    label: 'Address',
    value: STREET,
  },
  {
    label: 'City',
    value: CITY,
  },
  {
    label: 'State (e.g. CA, FL, AZ)',
    value: STATE_CODE,
  },
  {
    label: 'State (e.g. California, Florida)',
    value: STATE_FULL,
  },
  {
    label: 'ZIP Code',
    value: ZIPCODE,
  },
  {
    label: 'None of above Skip this column',
    value: NONE_OF_ABOVE,
  },
]
export const STATES_FULL_TO_CODE = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
}

export const STATES_CODE_TO_FULL = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District Of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
}

export const FILE_ERROR = 'file_error'
export const CSV_MALFORM_ERROR = 'csv_malform_error'
export const NO_CONTENT_ERROR = 'no_content_error'
