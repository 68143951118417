import React from "react"
import { MainContent, Div } from "components/layout/main"
import {
  Section,
  SectionContainer,
  SectionLogo,
  SectionHeader,
  FlexCol,
  FlexBox,
  IconContentRow,
} from "../../../components/layout/section"
import { H1, H3 } from "../../../components/typography"
import { Span } from "../../../components/typography/text-style"
import { SUBSCRIBED_PAGE } from "./wording"
import FaqAccordion from "../../../components/panel/faq-accordion"
import {
  StatusCircle,
  StatusSampleImg,
} from "../../../components/pro/arcs/subscribed-comp"

const ArcsSubscribedPage = () => (
  <MainContent className="-w100" relative>
    {/* -- SPLASH -- */}
    <Section className="splash refChecks smallHeader">
      <SectionLogo />
      <FlexCol className="splash-content" noMargin mCenter>
        <H1 align="center" size="1.4em" strong margin="0">
          Reference Checks
        </H1>
      </FlexCol>
    </Section>
    {/* -- BASIC -- */}
    <Section padding="40px 15px 60px" className="whiteBg">
      <SectionContainer>
        <SectionHeader title={SUBSCRIBED_PAGE.basic.header.title} />
        {SUBSCRIBED_PAGE.basic.basics.map(basic => (
          <IconContentRow
            key={basic.title}
            icon={{ src: basic.icon, alt: basic.title }}
            title={basic.title}
            titleSize="1.5em"
            content={basic.content}
            className="arcs-basic"
            contentWidth="75%"
            iconWidth="20%"
          />
        ))}
      </SectionContainer>
    </Section>
    {/* -- STATUS -- */}
    <Section padding="40px 15px 0px" className="blackBg">
      <SectionContainer display="block">
        <SectionHeader title={SUBSCRIBED_PAGE.status.header.title} />
        <FlexBox justify="space-between">
          <FlexCol mCenter width="55%">
            <StatusSampleImg>
              <img
                src={SUBSCRIBED_PAGE.status.image.src}
                alt={SUBSCRIBED_PAGE.status.image.alt}
              />
            </StatusSampleImg>
          </FlexCol>
          <FlexCol width="40%">
            {SUBSCRIBED_PAGE.status.status.map(s => (
              <Div mb="20" key={s.title}>
                <H3 size="1.4em">
                  <StatusCircle color={s.color} />
                  {s.title}
                </H3>
                <Span size="1em">{s.subtitle}</Span>
              </Div>
            ))}
          </FlexCol>
        </FlexBox>
      </SectionContainer>
    </Section>
    {/* -- FAQS -- */}
    <Section padding="40px 15px 30px">
      <SectionContainer>
        <SectionHeader title={SUBSCRIBED_PAGE.faq.header.title} />
        <FaqAccordion name="arcsSubscribedFaq" />
      </SectionContainer>
    </Section>
  </MainContent>
)

export default ArcsSubscribedPage
