import React from 'react'

import Drawer from '@rentspree/component-2023.components.organisms.drawer'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'

import ActionButtonSet from '../../molecules/custom-document/action-button-set'
import CustomDocumentForm from '../../molecules/custom-document/custom-document-form'

const TITLE = 'Customize document request'
const SUBTITLE = 'Settings will be saved for future requests. Update them anytime'

const CustomDocument = ({
  isMobile,
  isDisableSave,
  selectedDocuments,
  isShowCustomDocument,
  handleClose,
  handleSave,
  handleBack,
  handleUpdateCustomDocumentForm,
}) => {
  if (isMobile) {
    const cta = (
      <ActionButtonSet
        isMobile={isMobile}
        isDisableSave={isDisableSave}
        handleSave={handleSave}
        handleBack={handleBack}
      />
    )
    return (
      <Sheet
        actionSection={cta}
        open={isShowCustomDocument}
        onOpen={() => {}}
        onClose={event => {
          if (event.currentTarget.tagName === 'BUTTON') handleClose()
        }}
      >
        <SheetHeader
          header={TITLE}
          subHeader={SUBTITLE}
          sx={{ borderBottom: '1px solid #22222212', paddingBottom: '16px' }}
        />
        <CustomDocumentForm
          isMobile={isMobile}
          selectedDocuments={selectedDocuments}
          handleUpdateCustomDocumentForm={handleUpdateCustomDocumentForm}
        />
      </Sheet>
    )
  }
  return (
    <Drawer
      displayCloseButton
      anchor="right"
      size="large"
      open={isShowCustomDocument}
      title={TITLE}
      subTitle={SUBTITLE}
      onClose={handleClose}
      actionButtonsProps={{
        primaryButtonText: 'Save',
        primaryButtonProps: {
          onClick: handleSave,
          disabled: isDisableSave,
        },
        secondaryButtonText: 'Back',
        secondaryButtonProps: {
          onClick: handleBack,
        },
      }}
    >
      <CustomDocumentForm
        selectedDocuments={selectedDocuments}
        handleUpdateCustomDocumentForm={handleUpdateCustomDocumentForm}
      />
    </Drawer>
  )
}

export default CustomDocument
