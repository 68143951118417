import { Box } from '@mui/material'
import './agent-incentive-payment-adoption-banner.scss'
import Button from '@rentspree/component-2023.components.atoms.button'
import Collapse from '@rentspree/component-2023.components.atoms.collapse'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import React from 'react'

import { CollapseIconButton } from 'v3/components/expand-icon'

import { DayCountDown } from './chat-lines/day-count-down'
import { FomoMessage } from './chat-lines/fomo-message'
import { useChatLine } from './chat-lines/use-chat-line'

/*
 * Only for agent incentive - payment adoption initiative
 * Please don't reuse this component, this is only for temporary experiment work
 */
export const BannerWithTwoImage = ({
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  leftImageUrl,
  rightImageUrl,
  margin = '0',
}) => {
  const [expanded, setExpanded] = React.useState(true)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const { binaryState, fomoMessage } = useChatLine()

  return (
    <Box sx={{ position: 'relative', margin }}>
      <CollapseIconButton
        className="Agent-incentive-payment-adoption-banner-collapse-icon"
        expand={expanded}
        onClick={event => {
          event.stopPropagation()
          handleExpandClick()
        }}
        aria-expanded={expanded}
        aria-label="show more"
        style={{ zIndex: 1 }}
      />
      <Card className="Agent-incentive-payment-adoption-banner-outer-container">
        <Typography
          variant="title-large"
          className={expanded ? 'animation-collapsed' : 'animation-expanded'}
          sx={{ display: expanded ? 'none' : 'block', padding: '0px 0px 0px 20px' }}
        >
          {title}
        </Typography>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit={false}
          sx={{
            padding: '0 !important',
            '& .MuiCollapse-wrapper': { padding: '0 !important' },
          }}
        >
          <div
            className={`Agent-incentive-payment-adoption-banner-container ${
              expanded ? `animation-expanded` : `animation-collapsed`
            }`}
          >
            <div className="Agent-incentive-payment-adoption-left-image-container">
              <img src={leftImageUrl} alt="agent-incentive-payment-adoption-left" />
            </div>
            <div className="Agent-incentive-payment-adoption-content">
              <Typography variant="title-large">{title}</Typography>
              <Typography variant="body-medium">{subtitle}</Typography>
              <div className="Agent-incentive-payment-adoption-buttons">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => onClickPrimaryButton()}
                >
                  {primaryButtonText}
                </Button>
                {secondaryButtonText && onClickSecondaryButton && (
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    className="underline"
                    onClick={() => onClickSecondaryButton()}
                  >
                    {secondaryButtonText}
                  </Button>
                )}
              </div>
            </div>
            <div className="Agent-incentive-payment-adoption-right-image-container">
              {binaryState ? (
                <FomoMessage
                  className="fomo-message-chat-line chat-line-fade-in"
                  text={fomoMessage}
                />
              ) : (
                <DayCountDown className="day-count-down-chat-line chat-line-fade-in" />
              )}
              <img src={rightImageUrl} alt="agent-incentive-payment-adoption-right" />
            </div>
          </div>
        </Collapse>
      </Card>
    </Box>
  )
}
