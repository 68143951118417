export const TYPES = {
  SHOW_NOTICE: "SHOW_NOTICE",
  CALL_GET_NOTICE: "CALL_GET_NOTICE",
  GET_NOTICE_REQUEST: "GET_NOTICE_REQUEST",
  GET_NOTICE_SUCCESS: "GET_NOTICE_SUCCESS",
  GET_NOTICE_FAILED: "GET_NOTICE_FAILED",
  CALL_UPDATE_NOTICE: "CALL_UPDATE_NOTICE",
  UPDATE_NOTICE_REQUEST: "UPDATE_NOTICE_REQUEST",
  UPDATE_NOTICE_SUCCESS: "UPDATE_NOTICE_SUCCESS",
  UPDATE_NOTICE_FAILED: "UPDATE_NOTICE_FAILED",
  NEXT_ACTIVE_NOTICE: "NEXT_ACTIVE_NOTICE",
  NEXT_ACTIVE_NOTICE_SUCCESS: "NEXT_ACTIVE_NOTICE_SUCCESS",
  SKIP_NOTICE_GROUP: "SKIP_NOTICE_GROUP",
  ON_NEXT: "ON_NEXT",
  FORCE_TRIGGER: "FORCE_TRIGGER",
}

export const API_NOTICES = "/api/v2/announcement/feature/notices"

export const PROJECT = "dashboard-v2"

export const PAGE = {
  SIDEBAR: "sidebar",
  APP_DETAIL_REPORT: "appDetailReport",
  APP_DETAILS_TAB: "appDetailTab",
  E_SIGN_DOCUMENT: "eSignDocument",
  LISTING_MENU: "listingMenu",
  APPLICATION_TABLE: "applicationTable",
  INSURANCE_CASE_A_SIDEBAR: "insuranceCaseASidebar",
  INSURANCE_CASE_B_LISTING_MENU: "insuranceCaseBListingMenu",
  NAVBAR: "navbar",
}
