import React from "react"
import styled from "styled-components"
import { ProgressBar, OverlayTrigger } from "react-bootstrap"
import { Icon } from "@rentspree/component-v2/dist/icon"

import B16 from "@rentspree/component-v2/dist/typography/b16"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import get from "lodash/get"

import { COLOR, BOX_SHADOW_1, BOX_SHADOW_3 } from "styles/settings"
import { TooltipOverlay } from "../../navbar/collapse"

const FileCardsContainer = styled.div`
  width: 100%;
`

export const CardContainer = styled.div`
  height: 50px;
  background: white;
  border-radius: 6px;
  position: relative;
  box-shadow: ${BOX_SHADOW_1};
  margin-bottom: 10px;
  :hover {
    ${props => !props.disabled && `box-shadow: ${BOX_SHADOW_3};`}
  }
`

const LoadingProgress = styled(ProgressBar)`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  border-radius: 5px;
  box-shadow: none;
  margin-bottom: 0;
  .progress-bar {
    background-color: ${COLOR.lightBlueHEX};
    box-shadow: none;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  margin: ${props => props.margin || "0 15px 0 0"};
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props =>
    props.disabled &&
    `
    > div {
      display: none;
    }
  `}
`

const CardContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  font-family: Source Sans Pro;
  display: flex;
  justify-content: space-between;
`

export const LeftCard = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 80%;
  @media (max-width: 991px) {
    ${props => props.processing && "width: 70%;"}
  }
  @media (max-width: 425px) {
    ${props => props.processing && "width: 60%;"}
  }
`
const RightCard = styled.div`
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FileNameText = styled(B16)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export const LoadingProgressComponent = ({ progress }) => {
  const processCompleted = progress === 100
  const progressText = processCompleted ? "Processing" : `${progress}%`
  return (
    <>
      {processCompleted && (
        <Icon name="loading" spin size="md" color={COLOR.fontBlue} />
      )}
      <B16
        size="14px"
        weight="600"
        margin="0px 10px 0px 5px"
        color={COLOR.fontBlue}>
        {progressText}
      </B16>
    </>
  )
}

export const chooseIconType = type => {
  switch (type) {
    case "image/png":
    case "image/jpeg":
      return "doc-image"
    default:
      return "doc-pdf"
  }
}

export const chooseColor = file => {
  if (file.error) {
    return COLOR.fontRed
  }
  if (file.loadingProgress) {
    return COLOR.fontBlue
  }
  return COLOR.textBlack
}

export const FileCard = ({
  file,
  disabled,
  actionButton,
  onRemove,
  customIcon,
}) => (
  <CardContainer disabled={disabled} className={customIcon}>
    <CardContentContainer>
      <LeftCard processing={file.loadingProgress && file.progress === 100}>
        {/* TODO: Fix dragable icon */}
        {/* {dragable && (
          <IconWrapper disabled={file.loadingProgress}>
            <img src={DragDot} alt="Drag" />
          </IconWrapper>
        )} */}
        <IconWrapper>
          {customIcon || (
            <Icon
              name={chooseIconType(file.type)}
              size="lg"
              color={COLOR.cyanBlue}
            />
          )}
        </IconWrapper>
        <FileNameText
          size="14px"
          weight="600"
          margin="0"
          color={chooseColor(file)}>
          {file.name}
        </FileNameText>
      </LeftCard>
      <RightCard>
        {file.loadingProgress ? (
          <LoadingProgressComponent progress={file.progress} />
        ) : (
          actionButton || (
            <IconWrapper margin="0" cursor="pointer" disabled={disabled}>
              <OverlayTrigger
                placement="top"
                trigger={["hover"]}
                overlay={TooltipOverlay({
                  text: "Remove",
                  id: `remove-tooltip`,
                })}>
                <Icon
                  name="trash"
                  onClick={() => {
                    onRemove(file)
                  }}
                />
              </OverlayTrigger>
            </IconWrapper>
          )
        )}
      </RightCard>
    </CardContentContainer>
    {file.loadingProgress && <LoadingProgress now={file.progress} />}
  </CardContainer>
)

export const DragableFileCards = ({ files = [], onRemove }) => (
  <DragDropContext>
    <Droppable droppableId="fileCardsDroppable" type="FILE">
      {dropProvided => (
        <FileCardsContainer
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}>
          {files.map((file, index) => (
            <Draggable
              key={get(file, "data._id", `${index}`)}
              draggableId={get(file, "data._id", `${index}`)}
              index={index}>
              {dragProvided => (
                <div
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                  style={{ ...dragProvided.draggableProps.style }}>
                  <FileCard file={file} onRemove={onRemove} />
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </FileCardsContainer>
      )}
    </Droppable>
  </DragDropContext>
)
