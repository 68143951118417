export const PROPERTY_TYPE = [
  {
    value: "",
    label: "",
  },
  {
    value: "Apartment",
    label: "Apartment",
  },
  {
    value: "Condominium",
    label: "Condominium",
  },
  {
    value: "Manufactured Home",
    label: "Mobile/Manufactured home",
  },
  {
    value: "Multi-Family",
    label: "Multi-family home",
  },
  {
    value: "Single Family Home",
    label: "Single-family home",
  },
  {
    value: "Townhouse",
    label: "Townhouse",
  },
]

export const BED_ROOM_CHOICES = [
  {
    value: "",
    label: "",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10+",
  },
]

export const BATH_ROOM_CHOICES = [
  {
    value: "",
    label: "",
  },
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 1.5,
    label: "1.5",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 2.5,
    label: "2.5",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 3.5,
    label: "3.5",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 4.5,
    label: "4.5",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 5.5,
    label: "5.5",
  },
  {
    value: 6,
    label: "6+",
  },
]

export const API_RENT_ESTIMATE = "/api/v2/rent-estimates"
export const API_RENT_ESTIMATE_SUBMIT =
  "/api/v2/rent-estimates/:rentEstimateId/submit"
export const API_CREATE_PAYMENT_RENT_ESTIMATE =
  "/api/v2/rent-estimates/:rentEstimateId/payment"

export const TYPES = {
  // RENT ESTIMATION
  CREATE_RENT_ESTIMATE_CALL: "CREATE_RENT_ESTIMATE_CALL",
  CREATE_RENT_ESTIMATE_REQUEST: "CREATE_RENT_ESTIMATE_REQUEST",
  CREATE_RENT_ESTIMATE_SUCCESS: "CREATE_RENT_ESTIMATE_SUCCESS",
  CREATE_RENT_ESTIMATE_FAILED: "CREATE_RENT_ESTIMATE_FAILED",
  GET_PAYMENT_RENT_ESTIMATE_CALL: "GET_PAYMENT_RENT_ESTIMATE_CALL",
  GET_PAYMENT_RENT_ESTIMATE_REQUEST: "GET_PAYMENT_RENT_ESTIMATE_REQUEST",
  GET_PAYMENT_RENT_ESTIMATE_SUCCESS: "GET_PAYMENT_RENT_ESTIMATE_SUCCESS",
  GET_PAYMENT_RENT_ESTIMATE_FAILED: "GET_PAYMENT_RENT_ESTIMATE_FAILED",
  SUBMIT_RENT_ESTIMATE_CALL: "SUBMIT_RENT_ESTIMATE_CALL",
  SUBMIT_RENT_ESTIMATE_REQUEST: "SUBMIT_RENT_ESTIMATE_REQUEST",
  SUBMIT_RENT_ESTIMATE_SUCCESS: "SUBMIT_RENT_ESTIMATE_SUCCESS",
  SUBMIT_RENT_ESTIMATE_FAILED: "SUBMIT_RENT_ESTIMATE_FAILED",
  CLEAR_PAYMENT: "CLEAR_PAYMENT",
  CLEAR_RENT_RANGE_ERROR: "CLEAR_RENT_RANGE_ERROR",
  PAYMENT_MODAL: "PAYMENT_MODAL",
  MODAL_SUCCESS: "MODAL_SUCCESS",
}

export const FORM_LABEL = {
  PROPERTY_ADDRESS: "Property Address",
  PROPERTY_INFO: "Property Information",
}
export const INFO_BOX = {
  TITLE: " Rent Estimate ",
  DESC:
    "Learn the recommended rent price for your rental property and other market data about the area.",
  INCLUDE_TEXT: "Includes",
  INCLUDE_ITEM: [
    "-    Recommended rent estimate",
    "-    Prices of comparable rentals",
    "-    Local vacancy reports and trends",
  ],
  PRICE: "$19.99",
  LINK_TEXT: "View a sample report",
}
export const HEADER_TEXT = " Rent Estimate Report "
export const DESC_TEXT =
  "Set your rent price with confidence. For $19.99, a Rent Estimate gives you a recommended rent amount for your unit based on similar rentals in the area."
export const SEND_INFO = [
  "Your rent estimate report will be delivered to ",
  " after you complete your purchase.",
]
