import { REGISTERED_USER_TYPE } from 'constants/user'

export const USER_ROLE_FOR_PROPERTY = {
  LANDLORD: 'landlord',
  LISTING_AGENT: 'listing_agent',
  RENTER_AGENT: 'renter_agent',
  PROPERTY_MANAGER: 'property_manager',
  // NOTE: this role is not stored in any service and should only be utilized in the FE, old properties that were created before userRoleForProperty is introduced may not have userRoleForProperty specified
  UNKNOWN: 'unknown',
}

export const USER_INPUT_ROLE = {
  PROPERTY_MANAGER: 'property_manager',
  OTHER: 'other'
}

export const OTHER_ROLE_NAME_PREFIX = `${USER_INPUT_ROLE.OTHER}_`

export const CREATE_PROPERTY_REPRESENTING_RADIO = {
  LANDLORD: {
    id: 'imRepresentingChoice01',
    value: USER_ROLE_FOR_PROPERTY.LISTING_AGENT,
    label: 'Landlord',
  },
  APPLICANT: {
    id: 'imRepresentingChoice02',
    value: USER_ROLE_FOR_PROPERTY.RENTER_AGENT,
    label: 'Applicant(s)',
  },
}

export const REPRESENTING_RADIO_AVAILABLE_FOR = [
  REGISTERED_USER_TYPE.AGENT,
  REGISTERED_USER_TYPE.RENTER,
]

export const SET_CONTINUE_TO_PROPERTY_DETAILS = 'SET_CONTINUE_TO_PROPERTY_DETAILS'

export const REPRESENTING_PROPERTIES = {
  NA: 'N/A', // deprecated after we remove property details exp
  RENTER_AGENT_PROPERTY: 'renter_agent_property',
  LISTING_AGENT_PROPERTY: 'listing_agent_property',
  LANDLORD_PROPERTY: 'landlord_property',
  PROPERTY_MANAGER: "property_manager_property",
  OTHER: "other"
}

export const CREATE_CONTACT_FROM_CREATE_PROPERTY_CALL = 'CREATE_CONTACT_FROM_CREATE_PROPERTY_CALL'
export const CREATE_CONTACT_FROM_CREATE_PROPERTY_REQUEST =
  'CREATE_CONTACT_FROM_CREATE_PROPERTY_REQUEST'
export const CREATE_CONTACT_FROM_CREATE_PROPERTY_SUCCESS =
  'CREATE_CONTACT_FROM_CREATE_PROPERTY_SUCCESS'
export const CREATE_CONTACT_FROM_CREATE_PROPERTY_FAILED =
  'CREATE_CONTACT_FROM_CREATE_PROPERTY_FAILED'
