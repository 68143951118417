import React from "react"
import styled from "styled-components"
import { ModalDefault } from "legacy/components/modal/modal-default"
import {
  S24,
  B14,
  TermBox,
  BlueGradientButton,
  Checkbox,
} from "@rentspree/component-v2"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: Source Sans Pro, sans-serif;
  button {
    align-self: flex-end;
  }
`

export const CriminalDisclaimerModal = props => (
  <ModalDefault
    className="rentspreeModal criminalDisclaimerModal mRentspreeModal"
    data-testid="request-criminal-background-modal"
    closeButton
    {...props}>
    <Wrapper>
      <S24 margin="5px 0 20px 0" mSize="24px">
        Requesting a criminal background check
      </S24>
      <TermBox
        padding="18px"
        mPadding="18px"
        hideOverlay
        titleMobile="Test Mobile Title"
        maxHeight="300px"
        mHeight="200">
        <B14 margin="0px">
          By requesting a criminal background check, you certify that you will
          review and verify each applicant’s financial and other qualifications
          prior to using any criminal history information. You should consult
          with an attorney prior to ordering or using a criminal background
          report.
        </B14>
      </TermBox>
      <Checkbox
        isSelected={props.isSelected}
        id="criminalDisclaimerCheckbox"
        data-testid="criminal-disclaimer-checkbox"
        onClick={props.onCheckBoxClick}
        label="Do not ask me again"
        fontSize="16px"
        simple
        margin="10px 0 20px 0"
        mMargin="10px 0 20px 0"
      />
      <BlueGradientButton
        data-testid="request-criminal-background-confirm-btn"
        sourceSansPro
        small
        semiBold
        right
        mWidth="195px"
        minWidth="195"
        text="Confirm & Continue"
        id="smBtn"
        margin="0 0 5px 0"
        onClick={props.onConfirmClick}
      />
    </Wrapper>
  </ModalDefault>
)

export default CriminalDisclaimerModal
