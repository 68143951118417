/* style global variables */
import { css } from 'styled-components'

export const TOPBAR_HEIGHT = '70px'
export const SIDEBAR_WIDTH = '180px'
export const SIDEBAR_IPAD_WIDTH = '56px'
export const RIGHT_PANEL_WIDTH = '320px'
export const BREADCRUMBS_HEIGHT = '45px'
export const REPORT_TABS_HEIGHT = '50px'
export const REPORT_PADDING = '60px'
export const FILTER_HEIGHT = '50px'
export const BORDER_RADIUS = '5px'
export const MOBILE_SIDEBAR_HEIGHT = '60px'
export const SMALL_HEADER_HEIGHT = '60px'
export const MOBILE_BREADCRUMBS_HEIGHT = '45px'
export const MOBILE_TAB_HEIGHT = '50px'
export const MOBILE_ACCEPT_DENY_HEIGHT = '45px'

// language=SCSS prefix=dummy{ suffix=}
export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const INLINE_FLEX_CENTER = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
export const ANIMATE = {
  fast: '0.2s',
  normal: '0.5s',
  slow: '1s',
}
export const COLOR = {
  darkBlue: 'rgb(50, 120, 179)',
  blue: 'rgb(108, 164, 210)',
  lightBlue: 'rgb(153, 209, 255)',
  steelBlue: '#37495F',
  darkSteelBlue: 'rgb(39, 50, 66)',
  darkSteelBlue50: 'rgba(39, 50, 66, 0.5)',
  darkDeepBlue: 'rgb(29, 102, 206)',
  deepBlue: 'rgb(61, 138, 247)',
  whiteBlue: '#308DCE',
  lightShallowBlue: '#00c9e5',

  darkOrange: 'rgb(184, 76, 26)',
  orange: 'rgb(246, 100, 32)',
  lightOrange: 'rgb(255, 163, 120)',
  newOrange: '#FF8A23',

  darkNegativeRed: '#a72f31',
  negativeRed: '#F15555',
  lightSoftRed: 'rgba(241, 85, 85, 0.1)',

  darkYellow: '#A7903F',
  yellow: '#D3B84D',
  lightYellow: '#FFE79F',
  lemonGreen: '#b0c640',

  darkGreen: '#67A033',
  green: '#80C640',
  lightGreen: '#BCF090',

  bgCream: '#f2f1ef',
  cream: 'rgb(220, 214, 176)',
  choc: 'rgb(69, 54, 41)',

  lineGrey: '#D8D8D8',
  bgGrey: '#F1F1F1',
  bgDarkGrey: '#EEEEEE',
  textBlack: '#333333',
  textGrey: '#888888',
  titleBlack: '#666666',
  titleGrey: '#999999',
  lightTextGrey: '#9a9a9a',
  white: '#FFFFFF',
  black: '#000000',
  blackInput: '#777777',

  selectionGrey: '#F8F8F8',
  placeholderGrey: '#AAAAAA',

  grayFade: '#eceeef',

  // CAA
  newBlack: '#4A4A4A',
  newGrey: '#E5E5E5',

  // new credit-report
  fontBlue: '#3b99fc',
  grayLight: '#f9f9f9',
  fontRed: '#f15555',
  fontOrange: '#ffa65e',
  fontYellowDeep: '#a7903f',
  fontRedDeep: '#a33434',

  // Payment Modal
  bordorDetailColor: 'rgba(151, 151, 151, 0.3)',

  // Report Reviwing Accordion
  thinGrey: '#ebebeb',
  thinBlue: '#1082E1',
  mediumGrey: '#ECEAEA',
}

export const BOX_SHADOW_1 = '0 2px 7px 0 rgba(0, 0, 0, 0.1)'
export const BOX_SHADOW_2 = '0 2px 12px 0 rgba(0, 0, 0, 0.2)'

export const DATE_FORMAT = 'MM/DD/YYYY'
