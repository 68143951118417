import get from "lodash/get"
import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const getDreLicenseState = state =>
  get(state, "dreLicense", initialState)

export const selectIsUpdatingDreLicense = () =>
  createSelector(
    getDreLicenseState,
    state => get(state, "isUpdatingDreLicense"),
  )
