import generateApiCall from 'utils/generate-api-call'

import {
  CREATE_TENANT,
  CREATE_TENANT_FAILED,
  CREATE_TENANT_REQUESTED,
  CREATE_TENANT_SUCCEEDED,
} from './constants'

export const createTenant = payload => ({
  type: CREATE_TENANT,
  payload,
})

export const createTenantAPI = generateApiCall([
  CREATE_TENANT_REQUESTED,
  CREATE_TENANT_SUCCEEDED,
  CREATE_TENANT_FAILED,
])
