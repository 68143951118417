import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withLastLocation } from 'react-router-last-location'

import StepAction from 'legacy/containers/request/step-actions'
import { DiamondWrapper } from 'components/templates/diamond-wrapper-template-style'
import { withUserPreferenceData } from 'utils/tenant-screening/with-user-preference-data'
import Connect from './connect'

export const RequestTenantScreeningStepAction = props => (
  <DiamondWrapper>
    <StepAction {...props} />
  </DiamondWrapper>
)
export default compose(
  withRouter,
  withLastLocation,
  Connect,
  withUserPreferenceData,
)(RequestTenantScreeningStepAction)
