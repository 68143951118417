import styled, { css } from "styled-components"
import {
  COLOR,
  TOPBAR_HEIGHT,
  BREADCRUMBS_HEIGHT,
  FILTER_HEIGHT,
  FLEX_CENTER,
  REPORT_TABS_HEIGHT,
  REPORT_PADDING,
  MOBILE_SIDEBAR_HEIGHT,
  SMALL_HEADER_HEIGHT,
  MOBILE_TAB_HEIGHT,
  MOBILE_ACCEPT_DENY_HEIGHT,
} from "components/constants"

// language=SCSS prefix=dummy{ suffix=}
export const bodyContent = styled.div`
  display: flex;
  min-height: calc(100vh - ${TOPBAR_HEIGHT});
  @media (max-width: 991px) {
    flex-direction: column;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const mainContent = styled.div`
  background-color: ${COLOR.bgDarkGrey};
  flex-grow: 1;
  ${props => props.relative && "position: relative"};
  @media (max-width: 991px) {
    min-height: auto;
  }
  &.-w100 {
    width: 100%;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const centerContent = styled.div`
  ${FLEX_CENTER};
  flex-direction: column;
  position: relative;
  padding: ${props => props.padding || "30px 15px"};
  height: calc(
    100vh - ${TOPBAR_HEIGHT} ${props => (props.noMargin ? "" : "- 10px")}
      ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
      ${props => props.withReportPadding && `- ${REPORT_PADDING}`}
      ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
      ${props => props.extraHeight && `- ${props.extraHeight}px`}
      ${props => props.withSmallHeader && `- ${SMALL_HEADER_HEIGHT}`}
  );
  &.-w100 {
    width: 100%;
  }
  &.-mH100 {
    min-height: 100%;
  }
  ${props =>
    props.overlay &&
    css`
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
      z-index: 3;
    `};
  @media (max-width: 991px) {
    height: calc(
      100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT}
        ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
        ${props => props.withReportPadding && `- ${REPORT_PADDING}`}
        ${props => (props.noMargin ? "" : "- 10px")}
        ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
    );
  }
  @media (max-width: 767px) {
    height: calc(
      100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT}
        ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
        ${props => props.withReportPadding && `- ${REPORT_PADDING}`}
        ${props => (props.noMargin ? "" : "- 10px")}
        ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
        ${props => props.withAcceptDeny && `+ ${MOBILE_ACCEPT_DENY_HEIGHT}`}
        ${props => props.extraMobileHeight && `- ${props.extraMobileHeight}px`}
    );
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const whiteWrapper = styled.div`
  background-color: white;
  margin: ${props => (props.noMargin ? "0" : "5px")};
  padding-bottom: ${props => props.withPagination && "70px"};
  position: relative;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT} ${props => (props.noMargin ? "" : "- 10px")}
      ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
      ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
      ${props => props.extraPx && `-  ${props.extraPx}px`}
  );
  ${props =>
    props.flexCenter &&
    css`
      ${FLEX_CENTER};
      flex-direction: column;
    `};
  @media (max-width: 991px) {
    padding-bottom: ${props => props.withPagination && "60px"};
    padding-bottom: ${props => props.withStickyFooter && "80px"};
    min-height: calc(
      100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT}
        ${props => (props.noMargin ? "" : "- 10px")}
        ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
        ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
        ${props => props.extraPx && `-  ${props.extraPx}px`}
    );
  }
  @media (max-width: 767px) {
    padding-bottom: ${props => props.withPagination && "55px"};
    padding-bottom: ${props => props.withStickyFooter && "75px"};
    min-height: calc(
      100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT}
        ${props => (props.noMargin ? "" : "- 10px")}
        ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
        ${props => props.withMTab && `- ${MOBILE_TAB_HEIGHT}`}
        ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
    );
  }

  ${props =>
    props.overFlow &&
    `
      overflow: auto;
    `}
`
// language=SCSS prefix=dummy{ suffix=}
export const filterRow = styled.div`
  height: ${FILTER_HEIGHT};
  margin: 5px 5px 0 5px;
  display: flex;
  @media (max-width: 767px) {
    ${props => props.mJustify && `justify-content: ${props.mJustify}`};
  }

  > form {
    display: flex;
    flex-grow: 1;
    @media (max-width: 767px) {
      flex-direction: row-reverse;
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const singleAppWrapper = styled.div`
  display: flex;
  position: relative;
`
// language=SCSS prefix=dummy{ suffix=}
export const reportWrapper = styled.div`
  background: ${COLOR.white};
  position: relative;
  @media print {
    padding: 0px;
    background: white;
    overflow-x: auto;
  }
`
export const ReportDiv = styled.div`
  padding: ${props => (props.noPadding ? "0" : "30px")} 0;
  @media print {
    padding: 0;
  }
  &.hideMobile {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
`

export const containerReport = styled.div`
  @media (max-width: 767px) {
    display: ${props => props.hideMobile && "none"};
  }
`

export const div = styled.div`
  ${props =>
    props.borderColor && `border: 1px solid ${COLOR[props.borderColor]}`};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`};
  ${props =>
    props.centerWidth && `max-width: ${props.centerWidth}; margin: 0 auto`};
  ${props => props.inlineBlock && "display: inline-block;"};
  ${props => props.relative && "position: relative;"};
  ${props => props.bgColor && `background: ${props.bgColor}`};
  ${props => props.center && "text-align: center;"};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.m0 && "margin: 0px"};
  ${props => props.minHeight && `min-height: ${props.minHeight}px`};
`

export const loading = styled.div`
  ${props =>
    props.center &&
    css`
      width: 100%;
      ${FLEX_CENTER};
      height: 320px;
      background: white;
      z-index: 4;
    `}
  ${props =>
    props.overlay &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.85);
      z-index: 3;
      @media screen and (max-width: 767px) {
        border-radius: 0px;
      }
    `}
`
