import produce from 'immer'
import {
  GET_REPORTS_AVAILABLE_SUCCESS,
  GET_REPORTS_AVAILABLE_FAILURE,
  GET_REPORTS_AVAILABLE_REQUEST,
  SHARE_REPORT_REQUEST,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_FAILURE,
  TOGGLE_SHARE_REPORT_MODAL,
  CLOSE_ERROR,
  SET_FORM_VALUES,
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
} from './constants'

export const initialState = {
  reportAvailable: {},
  isLoading: false,
  isError: false,
  isShowShareModal: false,
  formValues: {},
  isFetchingDefaultShareOption: false,
  defaultShareOption: {},
}

/* eslint-disable default-case, no-param-reassign */
const shareReportReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // TODO: add GET_REPORT_AVAILABLE in UNO-593
      case SHARE_REPORT_REQUEST:
      case GET_REPORTS_AVAILABLE_REQUEST:
        draft.isLoading = true
        draft.isError = false
        break
      case GET_REPORTS_AVAILABLE_SUCCESS:
        draft.reportAvailable = payload
        draft.isLoading = false
        draft.isError = false
        break
      case SHARE_REPORT_SUCCESS:
        draft.isLoading = false
        draft.isShowShareModal = false
        draft.isError = false
        break
      case GET_REPORTS_AVAILABLE_FAILURE:
        draft.reportAvailable = {}
        draft.isLoading = false
        break
      case GET_USER_PREFERENCE_REQUEST:
        draft.defaultShareOption = {}
        draft.isFetchingDefaultShareOption = true
        break
      case GET_USER_PREFERENCE_SUCCESS:
        draft.defaultShareOption = payload || {}
        draft.isFetchingDefaultShareOption = false
        break
      case GET_USER_PREFERENCE_FAILURE:
        draft.defaultShareOption = {}
        draft.isFetchingDefaultShareOption = false
        break
      case SHARE_REPORT_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
      case CLOSE_ERROR:
        draft.isError = false
        break
      case TOGGLE_SHARE_REPORT_MODAL:
        draft.isShowShareModal = payload
        break
      case SET_FORM_VALUES:
        draft.formValues = payload
        break
    }
  })

export default shareReportReducer
