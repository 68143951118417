import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'
import { makeSelectProfile } from 'containers/navbar/selectors'
import { LEASE_AGREEMENT_TERM } from 'constants/terms'
import { ENVELOPE_STATUS, RECIPIENT_STATUS } from './constants'
import {
  getEmptyOrInvalidAssignedFields,
  getValidationErrorObjectFrom,
} from './helpers/get-validation-error-object'

export const getEnvelope = state => get(state, 'envelope')
export const getState = state => state
export const getUser = state => get(state, 'user')
export const selectSubdomain = state => get(state, 'subdomain', {})

export const makeSelectSubdomain = () =>
  createSelector(selectSubdomain, ({ subdomain }) => get(subdomain, 'sub', null))

export const selectUserIntegration = createSelector(getUser, state =>
  get(state, 'userIntegration', []),
)

export const selectEnvelope = createSelector(getEnvelope, state => state)

const getProfile = state => get(state, 'user')

export const makeSelectIsAcceptTermsLeaseAgreement = () =>
  createSelector(getProfile, state => get(state, `profile.terms.${LEASE_AGREEMENT_TERM}`, false))

// Envelope Data selector
// #region
export const selectEnvelopeData = () =>
  createSelector(selectEnvelope, state => get(state, 'envelopeData', {}))

export const makeSelectEnvelopeFiles = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'files', []))

export const makeSelectDoesEnvelopeExist = () =>
  createSelector(selectEnvelopeData(), state => !isEmpty(state))

export const makeSelectEnvelopeName = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'name', ''))

export const makeSelectEnvelopeDisplayName = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'displayName', ''))

export const makeSelectEnvelopeUpdatedAt = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'updatedAt', ''))

export const makeSelectIsUsingTemplate = () =>
  createSelector(selectEnvelopeData(), state => !!get(state, 'templateId', ''))

export const makeSelectEnvelopePDFPath = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'pdfPath', ''))

export const selectIsAcceptedConsent = () =>
  createSelector(selectEnvelope, state => {
    const envelopeData = get(state, 'envelopeData', {})
    if (!isEmpty(envelopeData)) {
      return !!envelopeData.recipients
        .filter(({ token }) => !!token)
        .find(({ isAcceptedConsent }) => isAcceptedConsent)
    }
    return false
  })
export const selectIsSigned = () =>
  createSelector(selectEnvelope, state => {
    const envelopeData = get(state, 'envelopeData', {})
    if (!isEmpty(envelopeData)) {
      return !!envelopeData.recipients
        .filter(({ token }) => !!token)
        .every(({ status }) => status === 'signed')
    }
    return false
  })
export const selectIsOwner = () =>
  createSelector(selectEnvelope, state => {
    const envelopeData = get(state, 'envelopeData', {})
    if (!isEmpty(envelopeData)) {
      return !!envelopeData.recipients
        .filter(({ token }) => !!token)
        .find(({ userId }) => userId === envelopeData.owner)
    }
    return false
  })

export const selectSignatureData = () =>
  createSelector(selectEnvelope, state => get(state, 'signature', {}))

export const selectLoadingGetEnvelope = createSelector(selectEnvelope, state =>
  get(state, 'isLoadingGetEnvelope'),
)
export const selectLoadingUpdateConsent = createSelector(selectEnvelope, state =>
  get(state, 'isLoadingUpdateConsent'),
)
export const selectUpdateConsentSuccess = createSelector(selectEnvelope, state =>
  get(state, 'updateConsentSuccess'),
)
export const selectLoadingSavingFile = createSelector(selectEnvelope, state =>
  get(state, 'isLoadingSavingFile'),
)

export const selectLoadingUpdateEnvelope = createSelector(selectEnvelope, state =>
  get(state, 'isLoadingUpdateEnvelope'),
)

export const selectIsUploadingSignature = createSelector(selectEnvelope, state =>
  get(state, 'isUploadingSignature'),
)

export const makeSelectOwner = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'owner', []))

export const makeSelectRecipients = () =>
  createSelector(selectEnvelopeData(), state => get(state, 'recipients', []))

export const makeSelectRecipientList = () =>
  createSelector(makeSelectRecipients(), state =>
    state.map(({ fullName, _id, roleId }) => ({
      value: _id,
      label: fullName,
      roleId,
    })),
  )

export const selectIsLoading = () =>
  createSelector(selectEnvelope, state => get(state, 'isLoading'))

export const selectIsError = () => createSelector(selectEnvelope, state => get(state, 'isError'))

export const selectEnvelopeError = createSelector(getEnvelope, state => get(state, 'error'))

export const selectFieldValidationResult = () =>
  createSelector(selectEnvelopeData(), state => {
    if (!isEmpty(state)) {
      return getValidationErrorObjectFrom(state)
    }
    return {}
  })

export const makeSelectShowLeavePopup = () =>
  createSelector([selectEnvelope, makeSelectProfile()], (envelope, profile) => {
    const recipients = get(envelope, 'envelopeData.recipients', [])
    const status = get(envelope, 'envelopeData.status', 'pending')
    const recipient =
      find(recipients, 'token') || find(recipients, { email: get(profile, 'email') })
    if (isNil(recipient)) {
      return false
    }

    return (
      !includes([ENVELOPE_STATUS.COMPLETED, ENVELOPE_STATUS.VOID], status) &&
      recipient.status === RECIPIENT_STATUS.PENDING
    )
  })

export const makeSelectIsAutosaving = () =>
  createSelector([selectEnvelope], envelope => get(envelope, 'isAutosaving', false))

export const makeSelectIsAutosaveError = () =>
  createSelector([selectEnvelope], envelope => get(envelope, 'isAutosaveError', false))
// #endregion

// Drag n Drop selector
// #region
export const makeSelectActiveId = () =>
  createSelector(selectEnvelope, state => get(state, 'activeId', {}))

export const makeSelectActiveIdType = () =>
  createSelector(makeSelectActiveId(), ({ type }) => type || '')

export const makeSelectActiveIdPage = () =>
  createSelector(makeSelectActiveId(), ({ page }) => page || '')

export const makeSelectActiveIdField = () =>
  createSelector(makeSelectActiveId(), ({ fieldId }) => fieldId || '')

export const makeSelectActiveIdStyles = () =>
  createSelector(makeSelectActiveId(), ({ styles }) => styles || {})

export const makeSelectActiveIdAssignee = () =>
  createSelector(makeSelectActiveId(), ({ assignee }) => assignee || '')

export const makeSelectActiveIdUndeletable = () =>
  createSelector(makeSelectActiveId(), ({ undeletable }) => undeletable || false)

export const makeSelectToolbar = () =>
  createSelector(selectEnvelope, state => get(state, 'toolbar', []))
// #endregion

export const makeSelectSavingFileLoadingList = () =>
  createSelector(selectEnvelope, state => get(state, 'saveFileLoadings', []))

export const selectIsAccepting = () =>
  createSelector(selectEnvelope, state => get(state, 'isAccepting'))
export const makeSelectIsConsentSuccess = () =>
  createSelector(selectEnvelope, state => get(state, 'isConsentSuccess', false))
export const makeSelectIsShowModal = () =>
  createSelector(selectEnvelope, state => get(state, 'isShowConsentModal', false))

export const makeSelectAllEmptyOrInvalidFields = () =>
  createSelector(selectEnvelopeData(), state => getEmptyOrInvalidAssignedFields(state))

export const makeSelectUploadSignatureSuccess = () =>
  createSelector(selectEnvelope, state => get(state, 'uploadSignatureSuccess'))

export const makeSelectIsSignatureEdited = () =>
  createSelector(selectEnvelope, state => get(state, 'isSignatureEdited'))

export const makeSelectBase64Files = () =>
  createSelector(selectEnvelope, state => get(state, 'base64Files', {}))

export const selectIsLoadingGeneratePdfFileURL = createSelector(selectEnvelope, state =>
  get(state, 'isLoadingGeneratePdfFileURL'),
)
