import * as PATH from "constants/route"
import MYLISTING_ICON from "images/icons/left-menu/listing-white.svg"
import TENANT_SCREENING_ICON from "images/icons/left-menu/tenant-screening.svg"
import MESSAGING_ICON from "images/icons/messaging/messages-side-menu.svg"
import GET_STARTED_ICON from "images/icons/left-menu/leftmenu-getstarted.svg"
import RENT_ESTIMATE_ICON from "images/icons/rent-estimate/rentestimate.svg"
import RENTERS_INSURANCE_ICON from "images/icons/renter-insurance/menu-insurance.svg"
import INVITE_ICON from "images/icons/left-menu/leftmenu-invite-earn.png"
import SUPPORT_ICON from "images/icons/left-menu/leftmenu-support.svg"
import MOBILE_NAVBAR_INVITE_ICON from "images/icons/left-menu/leftmenu-reference.svg"
import EXPLORE_ICON from "images/icons/left-menu/leftmenu-explore-rentspree.svg"
import RENT_PAYMENT_ICON from "images/icons/left-menu/leftmenu-rent-payment.svg"
import CONTACTS_ICON from "images/icons/contacts/contact-icon.svg"
import TASKS_ICON from "images/icons/tasks/task-icon.svg"
import {
  RENTERS_INSURANCE_FEATURE_ID,
  RENT_PAYMENTS_ID,
  RENT_ESTIMATE_FEATURE_ID,
  MESSAGES_FEATURE_ID,
} from "utils/feature-toggle/constants"

import tracker from "tracker"
import { NAVBAR_CLICK_FROM, RENTSPREE_PRO_EVENT } from "tracker/const"
import { buildPath } from "@rentspree/path"
import { PATH_HOME_PAGE } from "v3/routers/path"
import { PAGE } from "../popper/const"

export const onClickDefault = () => {}

export const LISTING_SIDEBAR_MENU = {
  text: "Properties",
  icon: MYLISTING_ICON,
  href: PATH.PROPERTY,
  path: PATH.PROPERTY,
  mText: "Applications",
  id: "applicationsPageLink",
}

export const TENANT_SCREENING_MENU = (
  onClick = onClickDefault,
  unOpenReports,
) => ({
  text: "Tenant Screening",
  icon: TENANT_SCREENING_ICON,
  href: PATH.TENANT_SCREENING,
  path: PATH.TENANT_SCREENING,
  mText: "Tenant Screening",
  id: "tentantScreeningPageLink",
  onClick,
  ...(unOpenReports && { notice: `${unOpenReports}` }),
})

export const CONTACT_SIDEBAR_MENU = (onClick = onClickDefault) => ({
  text: "Client Manager",
  icon: CONTACTS_ICON,
  href: PATH.CONTACTS,
  path: PATH.CONTACTS,
  mText: "Client Manager",
  id: "contactPageLink",
  onClick,
})

export const TASK_SIDEBAR_MENU = (onClick = onClickDefault, unseenTasks) => ({
  text: "Tasks",
  icon: TASKS_ICON,
  href: PATH.TASKS,
  path: PATH.TASKS,
  mText: "Tasks",
  id: "taskPageLink",
  onClick,
  ...(unseenTasks && { notice: `${unseenTasks}` }),
})

export const RENT_ESTIMATES_SIDEBAR_MENU = (onClick = onClickDefault) => ({
  text: "Rent Estimate",
  icon: RENT_ESTIMATE_ICON,
  href: PATH.RENT_ESTIMATE_PAGE,
  path: PATH.RENT_ESTIMATE_PAGE,
  mText: "Applications",
  id: "rentEstimatePageLink",
  featureId: RENT_ESTIMATE_FEATURE_ID,
  onClick,
})

export const RENT_PAYMENT_SIDEBAR_MENU = (
  onClick = onClickDefault,
  rentPaymentNotificationCount,
) => ({
  text: "Rent Payment",
  icon: RENT_PAYMENT_ICON,
  href: buildPath(PATH.RENT_PAYMENT, {}, { source: 'sidebar' }),
  path: PATH.RENT_PAYMENT,
  mText: "Applications",
  id: "rentPaymentPageLink",
  featureId: RENT_PAYMENTS_ID,
  onClick,
  notice: rentPaymentNotificationCount
    ? `${rentPaymentNotificationCount}`
    : undefined,
})

export const RENTERS_INSURANCE_SIDEBAR_MENU = {
  text: "Renters Insurance",
  icon: RENTERS_INSURANCE_ICON,
  href: PATH.RENTER_INSURANCE,
  path: PATH.RENTER_INSURANCE,
  mText: "Renters Insurance",
  id: "rentersInsurancePageLink",
  featureId: RENTERS_INSURANCE_FEATURE_ID,
  popperPage: PAGE.INSURANCE_CASE_A_SIDEBAR,
}

export const MESSAGING_SIDEBAR_MENU = (
  onClick = onClickDefault,
  unreadMessages,
) => ({
  text: "Messages",
  icon: MESSAGING_ICON,
  href: PATH.MESSAGING_PAGE,
  path: PATH.MESSAGING_PAGE,
  id: "messagingLink",
  featureId: MESSAGES_FEATURE_ID,
  onClick,
  ...(unreadMessages && { notice: `${unreadMessages}` }),
})

export const PRO_SIDEBAR_MENU = {
  text: "RentSpree PRO",
  icon: GET_STARTED_ICON,
  href: PATH.PRO_PAGE,
  path: PATH.PRO_PAGE,
  mText: "RentSpree PRO",
  id: "rentSpreeProLink",
  onClick: () => {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.CLICK_RENTSPREE_PRO_PAGE, {
      click_from: NAVBAR_CLICK_FROM,
    })
  },
}

export const EXPLORE_SIDEBAR_MENU = (onClick = onClickDefault, text, isNewDashboard = false) => ({
  text,
  icon: EXPLORE_ICON,
  href: isNewDashboard ? PATH_HOME_PAGE: PATH.EXPLORE_PAGE,
  path: isNewDashboard ? PATH_HOME_PAGE: PATH.EXPLORE_PAGE,
  mText: text,
  id: "exploreRentSpreeLink",
  onClick,
})

export const REFER_SIDEBAR_MENU = (onClick = onClickDefault) => ({
  text: "Refer & Earn",
  mText: null,
  icon: INVITE_ICON,
  onClick,
  href: PATH.REFERRAL_FORM_PAGE,
  path: PATH.REFERRAL_FORM_PAGE,
  id: "referralFormPageLink",
})

export const SUPPORT_SIDEBAR_MENU = (onClick = onClickDefault) => ({
  text: "Support",
  mText: null,
  icon: SUPPORT_ICON,
  path: PATH.SUPPORT_URL,
  id: "supportPageLink",
  onClick,
})

export const LISTING_NAVBAR_MENU = {
  className: "hideDeskTop",
  id: "listingPageNavBarLink",
  img: MYLISTING_ICON,
  isDivider: false,
  isMobileReloaded: true,
  showMobileIcon: true,
  text: "Properties",
  url: PATH.PROPERTY,
}

export const TENANT_SCREENING_NAVBAR_MENU = (
  onClick = onClickDefault,
  unOpenReports,
) => ({
  className: "hideDeskTop",
  id: "tentantScreeningNavBarLink",
  img: TENANT_SCREENING_ICON,
  isDivider: false,
  isMobileReloaded: true,
  onClick,
  showMobileIcon: true,
  text: "Tenant Screening",
  url: PATH.TENANT_SCREENING,
  ...(unOpenReports && { notice: `${unOpenReports}` }),
})

export const CONTACT_NAVBAR_MENU = (onClick = onClickDefault) => ({
  className: "hideDeskTop",
  id: "contactPageNavBarLink",
  img: CONTACTS_ICON,
  isDivider: false,
  isMobileReloaded: true,
  mText: "Client Manager",
  onClick,
  path: PATH.CONTACTS,
  showMobileIcon: true,
  text: "Client Manager",
  url: PATH.CONTACTS,
})

export const TASK_NAVBAR_MENU = (onClick = onClickDefault, unseenTasks) => ({
  className: "hideDeskTop",
  id: "taskPageNavBarLink",
  img: TASKS_ICON,
  isDivider: false,
  isMobileReloaded: true,
  mText: "Tasks",
  onClick,
  path: PATH.TASKS,
  showMobileIcon: true,
  text: "Tasks",
  url: PATH.TASKS,
  ...(unseenTasks && { notice: `${unseenTasks}` }),
})
export const RENT_PAYMENT_NAVBAR_MENU = (
  onClick = onClickDefault,
  notificationCount,
) => ({
  className: "hideDeskTop",
  featureId: RENT_PAYMENTS_ID,
  id: "rentPaymentPageNavBarLink",
  img: RENT_PAYMENT_ICON,
  isDivider: false,
  isMobileReloaded: true,
  mText: "Rent Payment",
  onClick,
  showMobileIcon: true,
  text: "Rent Payment",
  url: PATH.RENT_PAYMENT,
  ...(notificationCount && { notice: `${notificationCount}` }),
})

export const RENT_ESTIMATES_NAVBAR_MENU = (onClick = onClickDefault) => ({
  className: "hideDeskTop",
  featureId: RENT_ESTIMATE_FEATURE_ID,
  id: "rentEstimatePageNavBarLink",
  img: RENT_ESTIMATE_ICON,
  isDivider: false,
  isMobileReloaded: true,
  mText: "Rent Estimate",
  onClick,
  showMobileIcon: true,
  text: "Rent Estimate",
  url: PATH.RENT_ESTIMATE_PAGE,
})

export const RENTERS_INSURANCE_NAVBAR_MENU = {
  className: "hideDeskTop",
  featureId: RENTERS_INSURANCE_FEATURE_ID,
  id: "rentersInsurancePageNavBarLink",
  img: RENTERS_INSURANCE_ICON,
  isDivider: false,
  isMobileReloaded: true,
  mText: "Renters Insurance",
  showMobileIcon: true,
  text: "Renters Insurance",
  url: PATH.RENTER_INSURANCE,
}

export const MESSAGING_NAVBAR_MENU = (
  onClick = onClickDefault,
  unreadMessages,
) => ({
  className: "hideDeskTop",
  featureId: MESSAGES_FEATURE_ID,
  id: "messagingNavBarLink",
  img: MESSAGING_ICON,
  isDivider: false,
  isMobileReloaded: true,
  notice: unreadMessages ? `${unreadMessages}` : "",
  onClick,
  showMobileIcon: true,
  text: "Messages",
  url: PATH.MESSAGING_PAGE,
})

export const PRO_NAVBAR_MENU = {
  className: "hideDeskTop",
  id: "rentSpreeProNavBarLink",
  img: GET_STARTED_ICON,
  isDivider: false,
  isMobileReloaded: true,
  showMobileIcon: true,
  text: "RentSpree PRO",
  url: PATH.PRO_PAGE,
  onClick: () => {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.CLICK_RENTSPREE_PRO_PAGE, {
      click_from: NAVBAR_CLICK_FROM,
    })
  },
}

export const REFER_NAVBAR_MENU = (onClick = onClickDefault) => ({
  className: "hideDeskTop",
  id: "referralFormPageNavBarLink",
  img: MOBILE_NAVBAR_INVITE_ICON,
  isDivider: true,
  isMobileReloaded: true,
  onClick,
  showMobileIcon: true,
  text: "Refer & Earn",
  url: PATH.REFERRAL_FORM_PAGE,
})

export const TYPES = {
  TOGGLE_COLLAPSE: "TOGGLE_COLLAPSE",
}

export const EXPLORE_NAVBAR_MENU = {
  className: "hideDeskTop",
  id: "exploreRentSpreeNavBarLink",
  img: EXPLORE_ICON,
  isDivider: false,
  isMobileReloaded: true,
  mText: "Home",
  showMobileIcon: true,
  text: "Home",
  url: PATH.EXPLORE_PAGE,
}

export const mapSideBarIdToNavBarId = {
  "sidebar-exploreRentSpreeLink": "exploreRentSpreeNavBarLink",
  "sidebar-rentEstimatePageLink": "rentEstimatePageNavBarLink",
  "sidebar-messagingLink": "messagingNavBarLink",
  "sidebar-rentersInsurancePageLink": "rentersInsurancePageNavBarLink",
  "sidebar-rentPaymentPageLink": "rentPaymentPageNavBarLink",
  "sidebar-contactPageLink": "contactPageNavBarLink",
  "sidebar-taskPageLink": "taskPageNavBarLink",
}

export const FEATURE_TOGGLE_ADDED = [
  RENTERS_INSURANCE_FEATURE_ID,
  RENT_PAYMENTS_ID,
  RENT_ESTIMATE_FEATURE_ID,
  MESSAGES_FEATURE_ID,
]
export const FEATURE_FLAG_ADDED = [RENT_PAYMENTS_ID]
