import React from 'react'
import { buildPath } from '@rentspree/path'
import get from 'lodash/get'
import includes from 'lodash/includes'
import { compose } from 'redux'
import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'

import tracker from 'tracker'
import useTrackEnterPage from 'tracker/use-track-enter-page'
import {
  LRA_INTEGRATION,
  SIGNATURE_TYPE_PROPERTIES,
  EVENT_LEASE_DOC_REVIEW_AND_SIGN,
} from 'tracker/const'

import EnvelopeSigningTemplate from 'components/templates/envelope-signing'
import { SINGLE_PROPERTY_APPLICATION_LIST, APPLICATION_ALL_REPORTS_PAGE } from 'constants/route'
import { Container } from 'components/envelope'
import { LoadingWithCenter } from 'components/layout/main'
import { filterFieldsBySigner, filterPages, findSignerByRecipient } from 'helpers/signing'
import { TYPES_COMPONENT, SIGNABLE_COMPONENT } from 'containers/drag-n-drop/constants'
import { DOCUMENT_LIST_ID } from 'containers/envelope/constants'

import {
  withReducer as withEnvelopeReducer,
  withSaga as withEnvelopeSaga,
} from 'containers/envelope/connect'
import { withConnect as withMainSignConnect } from 'containers/envelope/sign-page/connect'
import { APPLICATION_STATUSES } from 'containers/application/const'

import { useFetchApplication } from 'hooks'
import { APPLICATION_TYPE } from 'constants/application-type'
import { REGISTERED_USER_TYPE } from 'constants/user'
import { withConnect, withReducer, withSaga } from './connect'

export const LraEnvelopeSign = ({
  actions,
  match,
  isFetchingApplication,
  isFetchingEnvelope,
  envelope,
  haveEnvelope,
  pdfPath,
  activeId,
  recipientsList,
  fieldValidation,
  history,
  signatureData,
  isUpdateEnvelope,
  shouldHandleUnload,
  isUploadingSignature,
  isLoadingSavingFile,
  isAcceptedConsent,
  updateConsentSuccess,
  isLoadingUpdateConsent,
  profile,
  isFetchingUser,
  error,
  uploadSignatureSuccess,
  isSignatureEdited,
  isLoadingGeneratePdfFileURL,
}) => {
  const [showSignError, setShowSignError] = React.useState(false)
  const [showSignModal, setShowSignModal] = React.useState({
    show: false,
    mode: 'newSign',
  })
  const [showModal, setShowModal] = React.useState(false)
  const [showConsentModal, setShowConsentModal] = React.useState(!isAcceptedConsent)

  const [selectedCheckBox, setSelectedCheckBox] = React.useState(false)

  const validationErrors = get(fieldValidation, 'validationErrors', {})
  const signErrors = get(fieldValidation, 'signErrors', {})

  const { recipients } = envelope
  const { token: queryToken } = findSignerByRecipient(recipients)

  const signFlagRef = React.useRef(null)
  const propertyId = get(match, 'params.propertyId')
  const rentalId = get(match, 'params.rentalId')

  const simulateClickSignFlag = () => signFlagRef.current?.click()

  const dropdownItems = [
    {
      label: 'Edit',
      onClick: () => {
        setShowSignModal({ show: !showModal })
      },
    },
  ]

  const {
    application: { status: appStatus, lraEnvelopeId: envelopeId },
  } = useFetchApplication({ rentalId })

  useTrackEnterPage(LRA_INTEGRATION.ENTER_APPLICATION_SIGN, {
    template: APPLICATION_TYPE.CAR_LRA,
    recipient_role: REGISTERED_USER_TYPE.AGENT,
  })

  React.useEffect(() => {
    if (appStatus === APPLICATION_STATUSES.AGENT_SIGNED) {
      history.replace(
        buildPath(APPLICATION_ALL_REPORTS_PAGE, {
          propertyId,
          rentalId,
        }),
      )
    }
    if (isEmpty(profile)) actions.getProfile()

    return () => actions.resetEnvelopeReducer()
  }, [])

  React.useEffect(() => {
    if (envelopeId) actions.getEnvelopeCall({ envelopeId })
  }, [envelopeId])

  React.useEffect(() => {
    if (!showSignModal.show && uploadSignatureSuccess && !isSignatureEdited) {
      simulateClickSignFlag()
    }
  }, [showSignModal.show, uploadSignatureSuccess])

  React.useEffect(() => {
    setShowSignModal({
      show:
        (includes(SIGNABLE_COMPONENT, activeId.type, false) &&
          activeId.canEdit &&
          isEmpty(activeId.value) &&
          isEmpty(signatureData)) ||
        showModal,
      mode: isEmpty(activeId.value) ? 'newSign' : 'editSign',
    })

    if (activeId.type === TYPES_COMPONENT.DATE_SIGNS && isEmpty(activeId.value)) {
      actions.dateSignedSave({
        dateSigned: moment().toDate().valueOf(),
        timeZone: moment.tz.guess(true),
      })
    }
    if (isAcceptedConsent || updateConsentSuccess) {
      setShowConsentModal(false)
    }
  }, [
    activeId.type,
    activeId.value,
    activeId.canEdit,
    signatureData,
    showModal,
    updateConsentSuccess,
    isAcceptedConsent,
  ])

  let activeBox = ''
  if (
    !isEmpty(activeId) &&
    activeId.canEdit &&
    activeId.value &&
    includes(SIGNABLE_COMPONENT, activeId.type, false)
  ) {
    activeBox = showModal || showSignModal.show ? '' : activeId.fieldId
  }

  const handleClickConsent = () => {
    actions.updateEnvelopeConsent({ isAcceptedConsent: true }, envelopeId, {
      token: queryToken,
    })
  }

  const onCheckBoxClick = () => {
    setSelectedCheckBox(!selectedCheckBox)
  }

  const handleCloseModal = () => {
    actions.resetActive()
    setShowModal(false)
  }

  const handleEditSignature = value => {
    actions.editSignSignature(value)
    setShowModal(false)
    tracker.trackEvent(
      EVENT_LEASE_DOC_REVIEW_AND_SIGN.REPLACE_SIGNATURE,
      SIGNATURE_TYPE_PROPERTIES.TYPE,
    )
  }

  const handleSaveSignature = value => {
    actions.saveSignSignature(value)
    tracker.trackEvent(
      EVENT_LEASE_DOC_REVIEW_AND_SIGN.ADOPT_SIGNATURE,
      SIGNATURE_TYPE_PROPERTIES.TYPE,
    )
    simulateClickSignFlag()
  }

  const handleOnClickBackToDashboardBtn = () => {
    history.push(
      buildPath(SINGLE_PROPERTY_APPLICATION_LIST, {
        propertyId,
      }),
    )
  }

  const onClickFinish = () => {
    if (!fieldValidation.isValid) {
      setShowSignError(true)
      simulateClickSignFlag()
    } else {
      const { files } = envelope
      const signer = findSignerByRecipient(recipients)
      const newFiles = filterPages(
        filterFieldsBySigner(files, fields =>
          fields.filter(field =>
            // Check the email because we need to support the old signer that only has the email
            [get(signer, 'email'), get(signer, '_id'), get(signer, 'roleId')].includes(
              field.assignee,
            ),
          ),
        ),
        pages => pages.filter(page => !isEmpty(page.fields)),
      )
      const filesData = { files: newFiles }
      actions.updateEnvelopeSign(rentalId, filesData)
    }
  }

  if (
    includes(
      [TYPES_COMPONENT.INITIAL_SIGNS, TYPES_COMPONENT.SIGNATURE_SIGNS],
      activeId.type,
      false,
    ) &&
    isEmpty(activeId.value) &&
    !isEmpty(signatureData)
  ) {
    actions.saveSignSignature(signatureData)
    simulateClickSignFlag()
  }

  if (isFetchingApplication || isFetchingEnvelope || isFetchingUser) return <LoadingWithCenter />

  return (
    <Container>
      <EnvelopeSigningTemplate
        ref={signFlagRef}
        template={APPLICATION_TYPE.CAR_LRA}
        activeBox={activeBox}
        dropdownItems={dropdownItems}
        shouldHandleUnload={shouldHandleUnload}
        haveEnvelope={haveEnvelope}
        pdfPath={pdfPath}
        isLoadingSavingFile={isLoadingSavingFile}
        documentListId={DOCUMENT_LIST_ID}
        recipientsList={recipientsList}
        actions={actions}
        validationErrors={validationErrors}
        showSignError={showSignError}
        setShowSignError={setShowSignError}
        signErrors={signErrors}
        isLoadingUpdateEnvelope={isUpdateEnvelope}
        envelopeId={envelopeId}
        queryToken={queryToken}
        showSignModal={showSignModal}
        signatureData={signatureData}
        handleCloseModal={handleCloseModal}
        handleSaveSignature={handleSaveSignature}
        handleEditSignature={handleEditSignature}
        isUploadingSignature={isUploadingSignature}
        showConsentModal={showConsentModal}
        error={error}
        selectedCheckBox={selectedCheckBox}
        onCheckBoxClick={onCheckBoxClick}
        handleClickConsent={handleClickConsent}
        isLoadingUpdateConsent={isLoadingUpdateConsent}
        // Custimize sign template properties for LRA
        headerTitle="C.A.R. Application to Rent/Screening Fee"
        hideBackButton={false}
        onClickBackToDashboardBtn={handleOnClickBackToDashboardBtn}
        onClickFinish={onClickFinish}
        profile={profile}
        rentalId={rentalId}
        isLoadingGeneratePdfFileURL={isLoadingGeneratePdfFileURL}
      />
    </Container>
  )
}

export default compose(
  withEnvelopeReducer,
  withEnvelopeSaga,
  withMainSignConnect,
  withConnect,
  withReducer,
  withSaga,
)(LraEnvelopeSign)
