import numeral from 'numeral'
import moment from 'moment'
import trim from 'lodash/trim'

export const convert = (value, type, format) => {
  if (typeof value === 'undefined' || value === null) return '-'
  try {
    const date = moment.utc(value).format(format || 'DD MMM YYYY')
    const num = /(\d{3})(\d{3})(\d{4})/.exec(value)

    switch (type) {
      case '$/Mo':
        return `$${numeral(value).format(format || '0,0')} / month`
      case '$':
        return `$${numeral(value).format(format || '0,0')}`
      case '%':
        return `${numeral(value).format(format || '0,0')}%`
      case 'date':
        return date === 'Invalid date' ? value : date
      case 'dateOfUTC':
        return moment.utc(value).format(format || 'DD MMM YYYY')
      case 'dateOfLocal':
        return moment(value).format(format || 'DD MMM YYYY')
      case 'height':
        // TODO: hard coded
        return `${value.substring(0, 1)}'${value.substring(1, 3)}"`
      case 'weight':
        return value && `${value} lbs.`
      case 'firstUppercase':
        if (value) {
          return value.substr(0, 0) + value[0].toUpperCase() + value.substr(0 + 1)
        }
        return value
      case 'phoneNumber':
        return num ? `(${num[1]}) ${num[2]}-${num[3]}` : '-'
      case 'boolean':
        return value || value === 'true' ? 'Yes' : 'No'
      default:
        if (typeof value === 'object') {
          return 'N/A'
        }
        return value
    }
  } catch (e) {
    return 'N/A'
  }
}

export const convertTypeResidence = type => {
  switch (type) {
    case 'rented':
      return 'Rental'
    case 'owned':
      return 'Owner Occupied'
    default:
      return '-'
  }
}

export const addUnitFormat = (value, unit = '', isBegin = false) => {
  if (value) {
    return isBegin ? trim(`${unit} ${value}`) : trim(`${value} ${unit}`)
  }
  return ''
}
