import React, { useCallback, useRef } from 'react'
import FormattedInput from '@rentspree/component-v2/dist/input/formatted'
import SelectDropdown from '@rentspree/component-v2/dist/dropdown/select'
import TextInput from '@rentspree/component-v2/dist/input/text'
import { USStateDropdown } from '@rentspree/component-v2'
import Col from 'components/atoms/col'
import Row from 'components/atoms/row'
import ScrollToError from 'components/scroll/scroll-to-error'

import styled from 'styled-components'
import { useGoogleAutoComplete } from 'containers/google-autocomplete/use-google-autocomplete'
import { PROPERTY_TYPES } from './constant'
import { AddressAutocomplete } from '../address-input'

const ReferenceTopAddress = styled.div`
  position: absolute;
  top: 10px;
`

export const AddressForm = props => {
  const addressTopRef = useRef(null)
  const { addresses, setAddresses, getAddressDetails } = useGoogleAutoComplete()

  const {
    /** Formik props */
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
    omittedStates,
    withPropertyType,
    validateForm,
  } = props

  const autofillAddress = useCallback(
    address =>
      new Promise(resolve => {
        setFieldValue('street', address.streetLine)
        setFieldValue('unitNumber', address.secondary)
        setFieldValue('city', address.city)
        setFieldValue('state', address.state)
        setFieldValue('zipcode', address.zipcode)
        resolve()
      }).then(() => validateForm()),
    [setFieldValue, validateForm],
  )

  const scrollToTop = () => {
    const address = addressTopRef?.current
    if (address) {
      setTimeout(() => address.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500)
    }
  }

  return (
    <form>
      <ScrollToError containerError {...props} />
      <Row wrap>
        {withPropertyType && (
          <Col full margin="0 0 5px" mMargin="0 0 15px">
            <SelectDropdown
              isRequired
              name="propertyType"
              id="propertyDetailsPropertyType"
              placeholder={values.propertyType}
              value={values.propertyType}
              onChange={val => setFieldValue('propertyType', val)}
              onBlur={() => setFieldTouched('propertyType', true)}
              label="Property Type"
              options={PROPERTY_TYPES}
              error={touched.propertyType && errors.propertyType}
            />
          </Col>
        )}
        <Col full margin="0 0 5px" mMargin="0 0 15px">
          <ReferenceTopAddress id="ref-address" ref={addressTopRef} />
          <AddressAutocomplete
            id="dashboardNewPropertyStreet"
            className="newPropertyInput"
            name="street"
            label="Street address"
            value={values.street}
            onDebounceChange={val => setAddresses(val)}
            onValueChange={val => {
              setFieldValue('street', val)
            }}
            onBlur={() => {
              setFieldTouched('street', true)
            }}
            onSelectOption={autofillAddress}
            error={touched.street && errors.street}
            options={addresses}
            onFocus={scrollToTop}
            isRequired
            getAddressDetails={getAddressDetails}
          />
        </Col>
        <Col full margin="0 0 5px" mMargin="0 0 15px">
          <TextInput
            id="dashboardNewPropertyUnitNumber"
            className="newPropertyInput"
            name="unitNumber"
            label="Apartment, unit, etc."
            value={values.unitNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.unitNumber && errors.unitNumber}
          />
        </Col>
        <Col full margin="0 0 5px" mMargin="0 0 15px">
          <TextInput
            id="dashboardNewPropertyCity"
            className="newPropertyInput"
            name="city"
            label="City"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.city && errors.city}
            isRequired
          />
        </Col>
        <Col full margin="0 0 5px" mMargin="0 0 15px">
          <USStateDropdown
            id="dashboardNewPropertyState"
            className="newPropertyInput"
            name="state"
            label="State"
            value={values.state}
            omittedStates={omittedStates}
            onChange={val => setFieldValue('state', val)}
            onBlur={() => setFieldTouched('state', true)}
            error={touched.state && errors.state}
            isRequired
          />
        </Col>
        <Col full margin="0 0 5px" mMargin="0 0 15px">
          <FormattedInput
            formatType="zipcode"
            id="dashboardNewPropertyZipcode"
            className="newPropertyInput"
            name="zipcode"
            label="ZIP code"
            value={values.zipcode}
            onValueChange={vals => {
              setFieldValue('zipcode', vals.value)
            }}
            onBlur={handleBlur}
            error={touched.zipcode && errors.zipcode}
            isRequired
          />
        </Col>
      </Row>
    </form>
  )
}

export default AddressForm
