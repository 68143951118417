export const TYPE_CO_APPLICANT = {
  OCCUPANT: 'occupant',
  CO_SIGNER: 'cosigner',
}

export const APPLICANT_TYPE = {
  tenant: {
    value: 'tenant',
    text: 'Tenant',
  },
  guarantor: {
    value: 'guarantor',
    text: 'Guarantor / Co-signer',
  },
}

export const RENTAL_STATUS = {
  CREATED: 'created',
  REQUESTED: 'requested',
  READY: 'ready',
  STARTED: 'started',
  EDIT: 'edit',
  NOT_INTERESTED: 'not_interested',
  PROPERTY_REQUIRED: 'property_required',
  REVIEW: 'review',
  SUBMITTED: 'submitted',
}
