import React from 'react'
import styled, { css } from 'styled-components'
import {
  BlueGradientButton,
  SecondaryGradientButton,
} from '@rentspree/component-v2/dist/button/gradient'
import Button from '@rentspree/component-v2/dist/button'
import BackButton from '@rentspree/component-v2/dist/button/back'
import { COLOR } from 'styles/settings'
import { convertDataTestId } from 'helpers/convert'

const ButtonSetContainer = styled.div`
  display: flex;
  ${props => props.fullWidth && `width: 100%;`}
  flex-direction: ${props => (props.isNewVersion ? 'column' : 'row')};
  justify-content: space-between;
  font-family: Source Sans Pro;
  margin-top: 30px;
  margin-bottom: 40px;
  ${props => props.margin && `margin: ${props.margin}`};
  ${props =>
    props.withSystemLayout &&
    css`
      /* This will be a primary style refer from our design system */
      flex-direction: column;
    `}
  @media (max-width: 991px) {
    flex-direction: column;
    ${props => props.mMargin && `margin: ${props.mMargin}`};
  }
`
// TODO: FIX "onBorderDesktop" and "onBorder" typo to "noBorderDesktop" and ""noBorder""
const PrevAndNextContainer = styled.div`
  display: flex;
  ${props =>
    props.withSystemLayout &&
    css`
      /* This will be a primary style refer from our design system */
      justify-content: ${props.isShowPrev ? props.justifyButton || 'space-between' : 'flex-end'};
      padding-bottom: ${props.onBorderDesktop ? `0` : `20px`};
      border-bottom: ${props.onBorderDesktop ? `0` : `1px solid ${COLOR.borderGrey}`};
    `}
  @media (max-width: 991px) {
    justify-content: ${props => (props.isShowPrev ? 'space-between' : 'flex-end')};
    border-bottom: ${props => (props.onBorder ? `0` : `1px solid ${COLOR.borderGrey}`)};
    padding-bottom: ${props => (props.onBorder ? `0` : `20px`)};
    ${props =>
    props.mFullWidthButton &&
    css`
        flex-direction: column;
      `}
  }
`

const BackButtonContainer = styled.div`
  ${props =>
    props.withSystemLayout &&
    css`
      /* This will be a primary style refer from our design system */
      order: 1;
      margin: ${props.margin || '20px 0 0'};
      #BackToDashBoardBtn {
        height: 22px;
      }
    `}
  @media (max-width: 991px) {
    order: 1;
    margin: ${props => props.margin || '20px 0 0'};
    #BackToDashBoardBtn {
      height: 22px;
    }
  }
`

const PrevButton = styled(Button)`
  background: ${COLOR.white};
  display: ${props => (props.isShowPrev ? 'inherit' : 'none')};
  @media (max-width: 991px) {
    margin: ${props => props.mMargin || '0'};
  }
`

export const ButtonSetLeaseAgreement = ({
  hasIconNextBtn = true,
  onSaveAndNext,
  continueBtnType,
  isLoading,
  isSuccess,
  isShowPrev = true,
  continueText = 'Continue',
  prevText = 'Prev',
  onClickPrevBtn,
  onClickBackToDashboardBtn,
  btnHeight,
  margin,
  backBtnMargin,
  hideBackButton,
  hidePrevButton,
  hideIconNextButton,
  hideIconBackButton,
  hideIconPrevButton,
  text,
  mWidth,
  disabledNextButton,
  minWidth = '110',
  withSystemLayout,
  onBorder,
  onBorderDesktop = false,
  mMargin,
  hideNextButton = false,
  mFullWidthButton = false,
  fullWidth = false,
  disabledAllButton = false,
  dataTestKey,
  justifyButton,
  size = '16px',
  isNextButtonSecondary = false,
}) => (
  <ButtonSetContainer
    data-testid={dataTestKey ? `${dataTestKey}-btn-set-container` : undefined}
    fullWidth={fullWidth}
    withSystemLayout={withSystemLayout}
    margin={margin}
    mMargin={mMargin}
  >
    <BackButtonContainer data-testid={dataTestKey ? `${dataTestKey}-back-btn-container` : undefined} withSystemLayout={withSystemLayout} margin={backBtnMargin}>
      {!hideBackButton && (
        <BackButton
          data-testid={dataTestKey ? `${dataTestKey}-${text || "back-to-dashboard"}-btn` : undefined}
          text={text || 'Back to dashboard'}
          height={btnHeight}
          grayDisabled={false}
          margin="0"
          padding="0"
          homeIcon={!hideIconBackButton}
          semiBold
          size={size}
          id="BackToDashBoardBtn"
          disabled={isLoading || disabledAllButton}
          onClick={onClickBackToDashboardBtn}
        />
      )}
    </BackButtonContainer>
    <PrevAndNextContainer
      data-testid={dataTestKey ? `${dataTestKey}-prev-and-next-container` : undefined}
      withSystemLayout={withSystemLayout}
      isShowPrev={isShowPrev}
      onBorderDesktop={onBorderDesktop}
      onBorder={onBorder}
      mFullWidthButton={mFullWidthButton}
      justifyButton={justifyButton}
    >
      {!hidePrevButton && (
        <PrevButton
          data-testid={dataTestKey ? `${dataTestKey}-${convertDataTestId(prevText)}-btn` : undefined}
          id="goToPrevPageBtn"
          height={btnHeight}
          semiBold
          google
          small
          leftIcon={!hideIconPrevButton}
          margin={mFullWidthButton ? '0' : '0 15px 0 0'}
          mMargin={mFullWidthButton ? '0 0 15px' : '0'}
          type="button"
          isShowPrev={isShowPrev}
          size={size}
          minWidth={minWidth}
          disabled={isLoading || disabledAllButton}
          onClick={onClickPrevBtn}
        >
          {prevText}
        </PrevButton>
      )}
      {!hideNextButton && isNextButtonSecondary ? (
        <SecondaryGradientButton
          data-testid={
            dataTestKey ? `${dataTestKey}-${convertDataTestId(continueText)}-btn` : undefined
          }
          disabled={isLoading || disabledNextButton || disabledAllButton}
          hasIcon={!hideIconNextButton && hasIconNextBtn}
          small
          height={btnHeight}
          text={continueText}
          id="nextButton"
          semiBold
          mWidth={mWidth}
          onClick={onSaveAndNext}
          type={continueBtnType}
          loading={isLoading}
          loaded={isSuccess}
          size={size}
          minWidth={minWidth}
          tWidth={hidePrevButton ? '100%' : ''}
        />
      ) : (
        <BlueGradientButton
          data-testid={dataTestKey ? `${dataTestKey}-${convertDataTestId(continueText)}-btn` : undefined}
          disabled={isLoading || disabledNextButton || disabledAllButton}
          hasIcon={!hideIconNextButton && hasIconNextBtn}
          small
          height={btnHeight}
          text={continueText}
          id="nextButton"
          semiBold
          mWidth={mWidth}
          onClick={onSaveAndNext}
          type={continueBtnType}
          loading={isLoading}
          loaded={isSuccess}
          size={size}
          minWidth={minWidth}
          tWidth={hidePrevButton ? '100%' : ''}
        />
      )}
    </PrevAndNextContainer>
  </ButtonSetContainer>
)

/**
 * TODO: should change `ButtonSetLeaseAgreement` component name to `ButtonSet` name
 * After Renter Insurance epic is already launch
 */
export const ButtonSet = ({ ...props }) => <ButtonSetLeaseAgreement {...props} />

export const ButtonSetV2 = ({ ...props }) => <ButtonSetLeaseAgreement {...props} withSystemLayout />
