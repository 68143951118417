import { apiInstance } from "utils/api-interceptor"
import { useMutation } from '@tanstack/react-query'
import { buildPath } from '@rentspree/path'
import * as sentry from '@sentry/browser'
import { API_UPDATE_USER_ROLE_FOR_PROPERTY } from "containers/request/step-property-details/const"
import isEmpty from "lodash/isEmpty"
import omitBy from "lodash/omitBy"

/**
 * Calls API to update userRoleByProperty and landlordProfile as passed in with values
 * The empty values will not be passed through to the API's body
 * @param {object} params - property parameters
 * @param {string} params.propertyId - target property id
 * @param {string} params.userRoleForProperty - the user role of the property to be updated
 * @param {object} params.landlordProfile - the landlord's basic information when the userRoleForProperty is listing_agent
 * @param {string} params.landlordProfile.firstName - the landlord's first name
 * @param {string} params.landlordProfile.lastName - the landlord's last name
 * @param {string} params.landlordProfile.middleName - the landlord's middle name
 * @param {string} params.landlordProfile.email - the landlord's email
 * @param {string} params.landlordProfile.mobileNumber - the landlord's mobile number
 * @param {enum} userRoleForProperty - role of the representative user, refer to USER_ROLE_FOR_PROPERTY enum
 * @param {object} landlordProfile - the updating landlord information, normally could contain names, email and phone
 */
export const updateUserRoleForProperty = async ({ propertyId, userRoleForProperty, landlordProfile }) => {
    const path = buildPath(API_UPDATE_USER_ROLE_FOR_PROPERTY, { propertyId })
    const body = omitBy({ userRoleForProperty, landlordProfile }, isEmpty)
    return apiInstance.put(path, body)
}

/**
 * This hook utilizes a mutation function to update property details and captures any errors with Sentry.
 * The empty values will not be passed through to the updating API's body
 * @returns {import("@tanstack/react-query").UseMutationResult<ReturnType<typeof updateUserRoleForProperty>, Error, Parameters<typeof updateUserRoleForProperty>[0]>} A function to execute the mutation with mutateAsync
 */
export const useMutateUpdateUserRoleForProperty = () =>
    useMutation(updateUserRoleForProperty, {
        onError: sentry.captureException,
    })
