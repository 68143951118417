import React from "react"
import { compose } from "redux"

import ConfirmationModal from "components/organisms/confirmation-modal"
import SubtitleWithList from "components/molecules/subtitle-with-list"
import Body from "./body"

import { withConnect, withSaga } from "./connect"
import { MODAL_STATUS, DISABLE_FEATURE_LIST } from "./constants"
const { ARCHIVE, UNARCHIVE } = MODAL_STATUS

const title = {
  archive: "Archive Listing",
  unarchive: "Unarchive Listing",
}

export const ToggleArchivePropertyModal = ({
  actions,
  isOpen,
  isLoading,
  type,
  propertyId,
}) => {
  const handleConfirm = () => {
    if (type === ARCHIVE) {
      actions.archiveProperty(propertyId)
    } else if (type === UNARCHIVE) {
      actions.unarchiveProperty(propertyId)
    }
  }

  const handleClose = () => {
    actions.clearArchiveModal()
  }

  return (
    <ConfirmationModal
      onClickConfirm={handleConfirm}
      isOpen={isOpen}
      modalSize="small"
      isLoading={isLoading}
      onClose={handleClose}
      title={title[type]}>
      <Body type={type} />
      {type === ARCHIVE && (
        <SubtitleWithList
          subtitle={DISABLE_FEATURE_LIST.subtitle()}
          list={DISABLE_FEATURE_LIST.list}
        />
      )}
    </ConfirmationModal>
  )
}

export default compose(
  withConnect,
  withSaga,
)(ToggleArchivePropertyModal)
