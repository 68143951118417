export const START_POSITION_HEIGHT = "151px"
export const VERTICAL_CENTER_POSITION = 270
export const SIGN_FLAG_VERTICAL_OFFSET = -VERTICAL_CENTER_POSITION
export const SIGN_ฺCOMPONENT_HEIGHT = 22

export const SIGN_FLAG_STATE = {
  START: "START",
  SIGN: "SIGN",
  INITIAL: "INITIAL",
  FILL_IN: "FILL_IN",
  NEXT: "NEXT",
}

export const SIGN_FLAG_PROPS = {
  [SIGN_FLAG_STATE.START]: {
    text: "Start",
    buttonType: "default",
  },
  [SIGN_FLAG_STATE.SIGN]: {
    text: "Sign",
    buttonType: "arrow",
  },
  [SIGN_FLAG_STATE.INITIAL]: {
    text: "Initial",
    buttonType: "arrow",
  },
  [SIGN_FLAG_STATE.FILL_IN]: {
    text: "Fill In",
    buttonType: "arrow",
  },
  [SIGN_FLAG_STATE.NEXT]: {
    text: "Next",
    buttonType: "default",
  },
}
