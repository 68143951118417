import generateApiCall from "utils/generate-api-call"
import {
  START_CONVERSATION_CALL,
  START_CONVERSATION_REQUEST,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_FAILURE,
  INITIATE_TALK_JS_CALL,
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
  INITIATE_TALK_JS_SUCCESS,
  INITIATE_TALK_JS_FAILURE,
  TOGGLE_NEW_MESSAGE_MODAL_CALL,
  GET_CONVERSATION_CALL,
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAILURE,
  SET_CONVERSATION,
  CLEAR_CONVERSATION,
  CLOSE_MESSAGING_MODAL_ERROR,
  SET_UNREAD_COUNT,
} from "./constants"

export const toggleNewMessageModal = payload => ({
  type: TOGGLE_NEW_MESSAGE_MODAL_CALL,
  payload,
})

export const startConversation = payload => ({
  type: START_CONVERSATION_CALL,
  payload,
})

export const closeModalError = () => ({
  type: CLOSE_MESSAGING_MODAL_ERROR,
})

export const startConversationApi = generateApiCall([
  START_CONVERSATION_REQUEST,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_FAILURE,
])

export const getSignatureApi = generateApiCall([
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
])

export const initiateTalkJs = () => ({
  type: INITIATE_TALK_JS_CALL,
})

export const initiateTalkJsSuccess = () => ({
  type: INITIATE_TALK_JS_SUCCESS,
})

export const initiateTalkJsFailure = () => ({
  type: INITIATE_TALK_JS_FAILURE,
})

export const getConversation = payload => ({
  type: GET_CONVERSATION_CALL,
  payload,
})

export const getConversationApi = generateApiCall([
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAILURE,
])

export const setConversation = payload => ({
  type: SET_CONVERSATION,
  payload,
})

export const clearConversation = () => ({
  type: CLEAR_CONVERSATION,
})

export const setUnreadCount = payload => ({
  type: SET_UNREAD_COUNT,
  payload,
})
