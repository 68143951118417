import { takeLatest, put, call, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { push } from "connected-react-router"

import { RENT_ESTIMATE } from "constants/error-messages"
import { RENT_ESTIMATE_PAGE } from "constants/route"
import { apiInstance } from "../../utils/api-interceptor"
import { completeRentEstimateAPI, cancelRentEstimateAPI } from "./actions"
import {
  TYPES,
  API_COMPLETE_RENT_ESTIMATE_REPORT,
  API_CANCEL_RENT_ESTIMATE_REPORT,
} from "./constants"
import { handleModalSuccess } from "../rent-estimate/actions"

export const errorMessage = {
  message:
    "We've encountered an error generating your report. Please check all fields and try again.",
  contact: RENT_ESTIMATE.CONTACT,
}

export const callCompleteRentEstimateAPI = rentEstimateId =>
  new Promise(resolve =>
    setTimeout(
      () =>
        resolve(
          apiInstance.post(
            buildPath(API_COMPLETE_RENT_ESTIMATE_REPORT, { rentEstimateId }),
          ),
        ),
      1000,
    ),
  )

export const callCancelRentEstimateAPI = rentEstimateId =>
  apiInstance.post(
    buildPath(API_CANCEL_RENT_ESTIMATE_REPORT, { rentEstimateId }),
  )

export function* completeRentEstimate({ payload }) {
  const { rentEstimateId } = payload
  yield put(completeRentEstimateAPI.request())
  try {
    let completedRentEstimateReport
    let i = 1
    const MAX_RETRY = 10
    while (i <= MAX_RETRY) {
      const response = yield call(callCompleteRentEstimateAPI, rentEstimateId)
      if (response?.status === "completed") {
        completedRentEstimateReport = response
        i = MAX_RETRY + 1
      }
      i += 1
    }
    if (completedRentEstimateReport) {
      yield put(completeRentEstimateAPI.success(completedRentEstimateReport))
      yield put(handleModalSuccess())
    } else {
      console.error("complete rent estimate report failed: MAX_RETRY reached")
      yield put(completeRentEstimateAPI.failure(errorMessage))
    }
    yield put(push(RENT_ESTIMATE_PAGE))
  } catch (err) {
    console.error(
      "complete rent estimate report failed with error ========>",
      err,
    )
    yield put(completeRentEstimateAPI.failure(errorMessage))
    yield put(push(RENT_ESTIMATE_PAGE))
  }
}

export function* cancelRentEstimatePayment({ payload }) {
  const { rentEstimateId } = payload
  yield put(cancelRentEstimateAPI.request())
  try {
    const response = yield call(callCancelRentEstimateAPI, rentEstimateId)
    yield put(cancelRentEstimateAPI.success(response))
    yield put(push(RENT_ESTIMATE_PAGE))
  } catch (err) {
    yield put(cancelRentEstimateAPI.failure(err))
    yield put(push(RENT_ESTIMATE_PAGE))
  }
}

export function* watchCompleteRentEstimate() {
  yield takeLatest(TYPES.CALL_COMPLETE_RENT_ESTIMATE, completeRentEstimate)
}

export function* watchCancelRentEstimate() {
  yield takeLatest(TYPES.CALL_CANCEL_RENT_ESTIMATE, cancelRentEstimatePayment)
}

export function* rootSaga() {
  yield all([watchCompleteRentEstimate(), watchCancelRentEstimate()])
}

export default rootSaga
