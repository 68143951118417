import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { buildPath } from '@rentspree/path'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import Button from '@rentspree/component-2023.components.atoms.button'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import { DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { NEXT_ACTION_TYPES } from 'containers/tenant-screening/assign-property/constants'
import { BASE_NAME } from 'env'
import tracker from 'tracker'
import { withStatsigFeatureGate } from 'hoc/with-statsig'
import ReportHeader from 'components/organisms/report-header'
import { PRINT_REPORTS_PAGE, PRINT_REPORTS_RENTER_PAGE, CONTACT_DETAIL, RENTER_DASHBOARD } from 'constants/route'
import useMobile from 'hooks/use-mobile'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import { UPDATE_MS_SHARE_REQUEST } from 'utils/feature-flag/constants'
import { CLICK_REPORT_FROM } from 'containers/reports/constants'
import { parseContactData } from 'containers/contacts/add-to-contact/helpers'
import { EVENT_PEOPLE_CONCEPT, SHARE_MS_REPORT } from 'tracker/const'
import { withConnect } from './connect'
import 'legacy/components/reports-v2/share/tooltip-style.scss'
import { isAllowAcceptDeny } from '../helper'
import { SHARE_MS_MODAL, SHARE_MS_MODAL_STATE } from './const'

const {
  EVENT_NAME: {
    ADD_TO_CONTACT_REPORT,
    DONT_SAVE_THIS_PERSON,
    VIEW_EDIT_CONTACT_REPORT,
    CLICK_ADDED_CONTACT_DROPDOWN_BUTTON_REPORT,
  },
} = EVENT_PEOPLE_CONCEPT

const StyledDialog = styled(Dialog)`
  @media print {
    display: none;
  }
`

export const HeaderPrint = ({
  match,
  component,
  savePDF,
  pdfReport,
  printReport,
  deleteContact,
  isShareAvailable,
  isFoundContact,
  submissionParticipantId,
  submissionParticipantContactId,
  toggleShareReportModal,
  openAssignPropertyModal,
  rentalDetail,
  applicationDetail,
  isAddDeleteContactLoading,
  addToContact,
  existingSubmissionErrorModal,
  openExistingSubmissionErrorModal,
  history,
  isFeatureEnabled,
  multiShare,
  isRenter,
}) => {
  const { agentType, property } = rentalDetail || {}
  const { type } = match.params
  const { HEADER } = CLICK_REPORT_FROM
  const path = get(match, 'path', '')
  const isPrintPage =
    path.includes(`${PRINT_REPORTS_PAGE}/:type`) ||
    path.includes(`${PRINT_REPORTS_RENTER_PAGE}/:type`)
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  const propertyId = get(match, 'params.propertyId')

  const isUpdateMsShareRequestEnabled = isFeatureEnabled?.[UPDATE_MS_SHARE_REQUEST]
  // TODO: INIT-521 clean up
  const showMsHeader = isUpdateMsShareRequestEnabled && multiShare.isMultiShareActive && isRenter
  const showShareMsButton = !!rentalDetail.credit_report?.price?.multishare || !!rentalDetail.requestToApplyId
  const [isOpenConfirmMsModal, setIsOpenConfirmMsModal] = React.useState(false)
  const [modalState, setModalState] = React.useState(SHARE_MS_MODAL_STATE.PRINT)

  const handleClickPrintReport = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    printReport({
      type,
      autoPrint: true,
      clickFrom: HEADER,
      isPrintPage,
      propertyId,
      isMultiShare: multiShare.isMultiShareActive,
      pageUrl: window.location.href,
      hasBeenShared: rentalDetail.requestToApplyId ? "yes" : "no",
      hasScreeningReport: rentalDetail.credit_report ? "yes" : "no",
    })
  }

  const handleClickDownloadPdfDesktop = () =>
    savePDF({
      type,
      isPrintPage,
      clickFrom: HEADER,
      propertyId,
      isMultiShare: multiShare.isMultiShareActive,
      pageUrl: window.location.href,
      hasBeenShared: rentalDetail.requestToApplyId ? "yes" : "no",
      hasScreeningReport: rentalDetail.credit_report ? "yes" : "no",
    })

  const handleClickDownloadPdfMobile = () =>
    savePDF({
      type,
      device: 'mobile',
      clickFrom: HEADER,
      isPrintPage,
      propertyId,
      isMultiShare: multiShare.isMultiShareActive,
      pageUrl: window.location.href,
      hasBeenShared: rentalDetail.requestToApplyId ? "yes" : "no",
      hasScreeningReport: rentalDetail.credit_report ? "yes" : "no",
    })

  const handleClickShare = () => {
    const shareReportProps = {
      toggle: true,
      clickFrom: type,
      position: HEADER,
    }

    if (property) {
      toggleShareReportModal({ ...shareReportProps })
    } else {
      openAssignPropertyModal({
        participantId: submissionParticipantId,
        nextAction: NEXT_ACTION_TYPES.SHARE,
        nextActionProps: shareReportProps,
      })
    }
  }

  const handleClickDeleteContact = callback => {
    tracker.trackEvent(DONT_SAVE_THIS_PERSON)
    deleteContact(submissionParticipantContactId, callback)
  }

  const handleViewContact = () => {
    const contactDetailPath = buildPath(CONTACT_DETAIL, {
      contactId: submissionParticipantContactId,
    })
    tracker.trackEvent(VIEW_EDIT_CONTACT_REPORT)
    const url = `${BASE_NAME}${contactDetailPath}`
    window.open(url, '_blank')
  }

  const handleClickAddContact = () => {
    tracker.trackEvent(ADD_TO_CONTACT_REPORT)
    addToContact(parseContactData(rentalDetail, applicationDetail))
  }

  const handleClickOpenAddToContactDropdown = () =>
    tracker.trackEvent(CLICK_ADDED_CONTACT_DROPDOWN_BUTTON_REPORT)

  const handleClickMenu = modalType => {
    if (showShareMsButton) {
      if(modalType === SHARE_MS_MODAL_STATE.PRINT) {
        setModalState(SHARE_MS_MODAL_STATE.PRINT)
        setIsOpenConfirmMsModal(true)
      } else if (modalType === SHARE_MS_MODAL_STATE.DOWNLOAD) {
        setModalState(SHARE_MS_MODAL_STATE.DOWNLOAD)
        setIsOpenConfirmMsModal(true)
      }
    } else if (modalType === SHARE_MS_MODAL_STATE.PRINT) {
        handleClickPrintReport()
      } else if (modalType === SHARE_MS_MODAL_STATE.DOWNLOAD) {
        if (isMobile) {
          handleClickDownloadPdfMobile()
        } else {
          handleClickDownloadPdfDesktop()
        }
      }
  }

  const handlePrintOrDownload = () => {
    setIsOpenConfirmMsModal(false)
    if (modalState === SHARE_MS_MODAL_STATE.PRINT) {
      tracker.trackEvent(SHARE_MS_REPORT.printAnyway, {
        pages: "MS Applicant view report print modal",
        page_url: window.location.href,
        has_been_shared: rentalDetail.requestToApplyId ? "yes" : "no",
        has_screening_report: rentalDetail.credit_report ? "yes" : "no",
      })
      handleClickPrintReport()
    } else if (modalState === SHARE_MS_MODAL_STATE.DOWNLOAD) {
      tracker.trackEvent(SHARE_MS_REPORT.downloadAnyway, {
        pages: "MS Applicant view report download modal",
        page_url: window.location.href,
        has_been_shared: rentalDetail.requestToApplyId ? "yes" : "no",
        has_screening_report: rentalDetail.credit_report ? "yes" : "no",
      })
      if (isMobile) {
        handleClickDownloadPdfMobile()
      } else {
        handleClickDownloadPdfDesktop()
      }
    }
  }

  const handleClickShareMsReport = (clickFromModal = false) => {
    let clickFrom = "view_report"
    if (clickFromModal) {
      if (modalState === SHARE_MS_MODAL_STATE.PRINT){
        tracker.trackEvent(SHARE_MS_REPORT.shareBeforePrint, {
          pages: "MS Applicant view report print modal",
          page_url: window.location.href,
          has_been_shared: rentalDetail.requestToApplyId ? "yes" : "no",
          has_screening_report: rentalDetail.credit_report ? "yes" : "no",
        })
        clickFrom = "print"
      } else {
        tracker.trackEvent(SHARE_MS_REPORT.shareBeforeDownload, {
          pages: "MS Applicant view report download modal",
          page_url: window.location.href,
          has_been_shared: rentalDetail.requestToApplyId ? "yes" : "no",
          has_screening_report: rentalDetail.credit_report ? "yes" : "no",
        })
        clickFrom = "download"
      }
    } else {
      tracker.trackEvent(SHARE_MS_REPORT.startShare, {
        pages: "Applicant view report",
        page_url: window.location.href,
        has_been_shared: rentalDetail.requestToApplyId ? "yes" : "no",
        has_screening_report: rentalDetail.credit_report ? "yes" : "no",
      })
    }
    const shareMsReportPath = buildPath(RENTER_DASHBOARD , {}, {
      openShareModal: true,
      click_from: clickFrom,
    })
    window.open(shareMsReportPath, '_blank')
    setIsOpenConfirmMsModal(false)
  }

  return (
    <>
      <StyledDialog size="large" open={isOpenConfirmMsModal} data-testid="report-header-share-ms-modal">
        <DialogTitle handleOnClose={() => setIsOpenConfirmMsModal(false)} data-testid="report-header-share-ms-modal-title">
          {modalState === SHARE_MS_MODAL_STATE.PRINT ? SHARE_MS_MODAL.PRINT_TITLE : SHARE_MS_MODAL.DOWNLOAD_TITLE}
          </DialogTitle>
        <DialogContent>
          <DialogContentText data-testid="report-header-share-ms-modal-subtitle">
            {SHARE_MS_MODAL.SUBTITLE}
          </DialogContentText>
          </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="text"
            size="medium"
            className="underline"
            onClick={() => handlePrintOrDownload()}
            data-testid={`report-header-share-ms-modal-${modalState}-button`}
          >
            { (isMobile && modalState === SHARE_MS_MODAL_STATE.PRINT) && SHARE_MS_MODAL.PRINT_BUTTON_TEXT_MOBILE }
            { (!isMobile && modalState === SHARE_MS_MODAL_STATE.PRINT) && SHARE_MS_MODAL.PRINT_BUTTON_TEXT }
            { (isMobile && modalState === SHARE_MS_MODAL_STATE.DOWNLOAD) && SHARE_MS_MODAL.DOWNLOAD_BUTTON_TEXT_MOBILE }
            { (!isMobile && modalState === SHARE_MS_MODAL_STATE.DOWNLOAD) && SHARE_MS_MODAL.DOWNLOAD_BUTTON_TEXT }
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="medium"
            onClick={() => handleClickShareMsReport(true)}
            data-testid="report-header-share-ms-modal-share-button"
          >
            Share application
          </Button>
        </DialogActions>
      </StyledDialog>
      <ReportHeader
        component={component}
        pdfReport={pdfReport}
        result={rentalDetail?.screeningResult?.result}
        isPrintPage={isPrintPage}
        isShareAvailable={isShareAvailable}
        isContactAdded={isFoundContact}
        isAddDeleteContactLoading={isAddDeleteContactLoading}
        onClickPrintReport={handleClickPrintReport}
        onClickDownloadPdfDesktop={handleClickDownloadPdfDesktop}
        onClickDownloadPdfMobile={handleClickDownloadPdfMobile}
        onClickShare={handleClickShare}
        onClickDeleteContact={handleClickDeleteContact}
        onClickAddContact={handleClickAddContact}
        onClickViewContact={handleViewContact}
        onClickOpenAddToContactDropdown={handleClickOpenAddToContactDropdown}
        isAllowAcceptDeny={isAllowAcceptDeny(agentType)}
        existingSubmissionErrorModal={existingSubmissionErrorModal}
        openExistingSubmissionErrorModal={openExistingSubmissionErrorModal}
        history={history}
        handleClickShareMsReport={handleClickShareMsReport}
        handleClickMenu={handleClickMenu}
        showMsHeader={showMsHeader}
        showShareMsButton={showShareMsButton}
      />
    </>
  )
}

export default compose(withRouter, withConnect, withStatsigFeatureGate(UPDATE_MS_SHARE_REQUEST))(HeaderPrint)
