import styled from 'styled-components'
import React from 'react'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import Span from '@rentspree/component-v2/dist/typography/span'
import propTypes from 'prop-types'
import { COLOR, ANIMATE } from '../../../styles/settings'

export const StyledDiv = styled.div`
  border-radius: 5px;
  background-color: ${COLOR.white};
  border: 2px solid ${COLOR.white};
  padding: 14px;
  display: flex;
  font-family: inherit;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out ${ANIMATE.fast};
  user-select: none;
  position: relative;
  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }
  ${props =>
    props.fixedWidth &&
    `@media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }`}
  ${props =>
    props.checked &&
    `
    background-color: rgba(75, 151, 245, 0.08);
    border: 2px solid #4b97f5;
    box-shadow: none;
  `}
  ${props => props.fixedWidth && `width: calc(50% - 10px);`}
  ${props =>
    props.isDisabled &&
    `
    cursor: not-allowed;
    &:hover{
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }
    span {
      h4 {
        color: ${COLOR.grayLv3};
      }
    }
  `}
  ${props =>
    props.validationError &&
    `
    border-color: ${COLOR.negativeRed};
  `}
`

export const Title = styled(S16)`
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  margin-bottom: 2px;
  ${props =>
    props.validationError &&
    `
    color: ${COLOR.negativeRed};
    `}
`

export const Subtitle = styled(S16)`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  margin: 0;
  strong {
    font-style: italic;
    font-weight: 700;
    color: ${COLOR.newDeepBlue};
  }
`

export const Ascent = styled(Span)`
  font-size: 16px;
  font-weight: 600;
  margin-left: 30px;
  max-width: 50px;
  min-width: 50px;
  text-align: right;
`

export const Content = styled(Span)`
  margin-left: 0;
  margin-right: auto;
`

const BooleanCardV2 = ({
  title,
  subtitle,
  subtitleHTML,
  checked = false,
  ascent,
  type,
  onClick,
  icon: IconComponent,
  alt,
  testId = '',
  isHidden = false,
  isDisabled = false,
  children,
  ...rest
}) => {
  if (isHidden) {
    return <></>
  }

  const handleClick = (...params) => {
    if (!isDisabled) onClick(...params)
  }

  return (
    <StyledDiv
      checked={checked}
      onClick={handleClick}
      fixedWidth={ascent === undefined}
      isDisabled={isDisabled}
      data-testid={`${testId}-boolean-card-v2`}
      {...rest}
    >
      {IconComponent && <IconComponent checked={checked} alt={alt} />}
      <Content strong>
        {title && <Title validationError={rest.validationError}>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {subtitleHTML && <Subtitle dangerouslySetInnerHTML={{ __html: subtitleHTML }} />}
      </Content>
      {ascent && <Ascent color={COLOR.activeBlue}>{ascent}</Ascent>}
      {children}
    </StyledDiv>
  )
}

export default BooleanCardV2

BooleanCardV2.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  ascent: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  onClick: propTypes.func,
  checked: propTypes.bool,
  icon: propTypes.func,
  children: propTypes.element,
}
