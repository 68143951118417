import React from "react"
import get from "lodash/get"
import trim from "lodash/trim"
import { TrackerInstance as Tracker } from "../helpers/tracker"
import { BASE_NAME } from "../../env"
// This function takes a component...
export function withTracker(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    componentDidMount() {
      const { match } = this.props
      const urlWithSearch = trim(get(window, "location.href", ""))
      const pathWithBasename = `${BASE_NAME}${match.path}`
      Tracker.trackPageView(urlWithSearch, pathWithBasename)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
