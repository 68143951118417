import get from "lodash/get"
import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const getRequestOptionsState = state => get(state, "request", {})

export const getScreeningRequestState = state =>
  get(state, "screeningRequest", initialState)

export const selectLinkAccountData = () =>
  createSelector(
    getScreeningRequestState,
    state => get(state, "linkAccountData", initialState.linkAccountData),
  )

export const selectIsFetchingLinkAccountData = () =>
  createSelector(
    getScreeningRequestState,
    state =>
      get(
        state,
        "isFetchingLinkAccountData",
        initialState.isFetchingLinkAccountData,
      ),
  )
export const selectShowCriminalDisclaimerModal = () =>
  createSelector(
    getScreeningRequestState,
    state =>
      get(
        state,
        "showCriminalDisclaimerModal",
        initialState.showCriminalDisclaimerModal,
      ),
  )

export const selectIsCarWithPartnerIDIntegrated = () =>
  createSelector(
    selectLinkAccountData(),
    ({ car }) => (car && !!car.partnerId) || false,
  )

export const selectIsGeneratingPermalink = () =>
  createSelector(
    getScreeningRequestState,
    state =>
      get(state, "isGeneratingPermalink", initialState.isGeneratingPermalink),
  )

export const selectIsCreatingListingScreeningRequest = () =>
  createSelector(
    getScreeningRequestState,
    state =>
      get(
        state,
        "isCreatingListingScreeningRequest",
        initialState.isCreatingListingScreeningRequest,
      ),
  )

export const selectPermalink = createSelector(
  getScreeningRequestState,
  state => get(state, "permalink", initialState.permalink),
)

export const makeSelectPermalink = () => selectPermalink
