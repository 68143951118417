import React from 'react'
import styled from 'styled-components'
import { convert } from 'helpers/convert'
import {
  Content,
  InformationPanel,
  LineBreak,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  InformationLine,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import { Panel } from 'legacy/components/reports-v2/share/doc-style'

export const WhiteGrayPanel = styled(Panel)`
  ${props =>
    props.Gray &&
    `
    background-color: #f9f9f9 !important;
  `}
  ${props =>
    props.FixedHeight &&
    `
    height: 25px !important;
  `}
  padding: 0 20px;
`

const formatDate = 'MMM DD, YYYY'

export const CourtAction = ({
  caseNumber,
  disposition,
  dispositionDate,
  title,
  county,
  filingAgency,
  filingDate,
  type,
  completionDate,
  jurisdiction,
  statusBeginDate,
  statusDate,
  statusDescription,
  statusEndDate,
  recordNumber,
  dataset,
  chargeDisposition,
}) => {
  const EMPTY_LINE = 'EMPTY_LINE'
  const dataRows = [
    {
      title: 'Court Record ID',
      value: caseNumber,
      boldValue: true,
    },
    { title: EMPTY_LINE },
    { title: 'Disposition', value: disposition },
    {
      title: 'Disposition Date',
      value: convert(dispositionDate, 'dateOfLocal', formatDate),
    },
    { title: 'Additional Information', value: chargeDisposition },
    { title: EMPTY_LINE },
    { title: 'Title', value: title },
    { title: 'County', value: county },
    { title: EMPTY_LINE },
    { title: 'Filing Agency', value: filingAgency },
    {
      title: 'Filing Date',
      value: convert(filingDate, 'dateOfLocal', formatDate),
    },
    { title: EMPTY_LINE },
    { title: 'Activity Type', value: type },
    {
      title: 'Completion Date',
      value: convert(completionDate, 'dateOfLocal', formatDate),
    },
    { title: EMPTY_LINE },
    { title: 'Jurisdiction', value: jurisdiction },
    { title: EMPTY_LINE },
    {
      title: 'Status Begin Date',
      value: convert(statusBeginDate, 'dateOfLocal', formatDate),
    },
    {
      title: 'Status Date',
      value: convert(statusDate, 'dateOfLocal', formatDate),
    },
    { title: 'Status Description', value: statusDescription },
    {
      title: 'Status End Date',
      value: convert(statusEndDate, 'dateOfLocal', formatDate),
    },
    { title: 'Record Number', value: recordNumber },
    { title: 'Dataset', value: dataset },
  ]
  return (
    <Section>
      <SubSection>
        <SubSectionHeader title="Court Action" />
        <Content>
          <InformationPanel>
            {dataRows.map((value, index) => {
              if (value.title === EMPTY_LINE) {
                return <LineBreak key={`${value.title}-${index}`} />
              }
              return (
                <InformationLine
                  key={value.title}
                  label={value.title}
                  value={value.value || '-'}
                  labelWidth="calc((100% - 20px) / 3)"
                  boldValue={value.boldValue}
                />
              )
            })}
          </InformationPanel>
        </Content>
      </SubSection>
    </Section>
  )
}
