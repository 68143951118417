import { createGlobalStyle } from "styled-components"

// language=SCSS prefix=dummy{ suffix=}
export default createGlobalStyle`
  .isNoticeFocused {
    position: relative;
    z-index: 9998;
    pointer-events: none;

    @media (max-width: 991px) {
      z-index: inherit;
      pointer-events: inherit;
    }
  }
`
