import { takeLatest, put, call } from 'redux-saga/effects'

import { USER_ROLE } from 'v3/containers/rent-payment/constants'
import { FETCH_LATEST_RENTAL_PAYMENT, fetchLatestRentalPaymentAPI } from '../../actions'
import { fetchRentalPaymentsInstance } from '../../api-instances'

export function* fetchLatestRentalPaymentSaga() {
  yield put(fetchLatestRentalPaymentAPI.request())
  try {
    const options = {
      page: 1,
      perPage: 1,
      role: USER_ROLE.PAYEE,
    }
    const { data } = yield call(fetchRentalPaymentsInstance, { options })
    const latestRentalPayment = data[0]
    yield put(fetchLatestRentalPaymentAPI.success(latestRentalPayment))
  } catch (err) {
    yield put(fetchLatestRentalPaymentAPI.failure(err))
  }
}

export function* watchFetchLatestRentalPayment() {
  yield takeLatest(FETCH_LATEST_RENTAL_PAYMENT, fetchLatestRentalPaymentSaga)
}
