import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import {
  QUOTATION_TYPE,
  MONTH_TO_MONTH_LABEL,
  RECURRING_PAYMENT_TERM,
} from 'v3/containers/rent-payment/constants'

import { PAYMENT_CATEGORIES, RENT_PAYMENT_DEFAULT_FEE_COLLECTION_KEY } from './constants'

export const ordinalSuffix = date => {
  const unit = date % 10
  const specific = date % 100
  let suffix = 'th'
  if (unit === 1 && specific !== 11) suffix = 'st'
  if (unit === 2 && specific !== 12) suffix = 'nd'
  if (unit === 3 && specific !== 13) suffix = 'rd'
  return `${date}${suffix}`
}

export const dateListGenerator = () => {
  const dates = []
  for (let date = 1; date <= 31; date += 1) {
    dates.push({ label: `${ordinalSuffix(date)} of each month`, value: date })
  }
  return dates
}

export const getLastDate = (currentDate, currentMonth, fullYear) => {
  const lastDate = currentDate
  const isLeapYear = currentMonth === 1 && currentDate > 28
  if (isLeapYear || currentDate > 30) return new Date(fullYear, currentMonth + 1, 0).getDate()
  return lastDate
}

export const monthsAbbr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getComingMonths = ({
  date,
  month,
  year,
  monthRange,
  withDuration,
  endOfTheDay,
  hour = 0,
  minute = 0,
  skipMonths = 0,
}) => {
  const dateTimes = []
  let currentYear = year
  for (let i = 0 + skipMonths; i < monthRange; i += 1) {
    const currentMonth = (i + month) % 12
    /*
     * If month of firstPayment is January, the year will
     * not be increased for the first round of the loop.
     */
    if (currentMonth === 0 && i !== 0) currentYear += 1
    const currentDate = getLastDate(date, currentMonth, currentYear)
    const isPlural = i > 0 ? 's' : ''
    const duration = withDuration ? `(${i + 1} month${isPlural})` : ''
    const label = `${monthsAbbr[currentMonth]} ${currentDate}, ${currentYear} ${duration}`
    let timeStamp = new Date(currentYear, currentMonth, currentDate)
    const format = 'YYYY-MM-DD'
    // Check if the same day should be pass the hour and minute too.
    if (!endOfTheDay && moment(moment().format(format)).isSame(moment(timeStamp).format(format))) {
      timeStamp = new Date(currentYear, currentMonth, currentDate, hour, minute)
    }
    if (endOfTheDay) timeStamp.setHours(23, 59, 59)
    const dateString = moment(timeStamp).format(format)
    dateTimes.push({ label, value: dateString })
  }
  return dateTimes
}

export const getLastPaymentList = (dueOn, firstPayment) => {
  const paymentDate = moment(firstPayment)
  return getComingMonths({
    date: dueOn,
    month: paymentDate.month(),
    year: paymentDate.year(),
    monthRange: 36,
    withDuration: true,
    endOfTheDay: true,
    skipMonths: 1,
  })
}

export const dateListSelector = dateListGenerator()

export const getTimeDifferenceInMonths = ({ firstPaymentDate, lastPaymentDate }) =>
  lastPaymentDate.getMonth() -
  firstPaymentDate.getMonth() +
  12 * (lastPaymentDate.getFullYear() - firstPaymentDate.getFullYear()) +
  1

export const dateGeneratorWithSuffix = ({ dateNumber, suffix }) =>
  `${ordinalSuffix(dateNumber)} ${suffix}`

export const dueOnDateGenerator = dateNumber =>
  dateGeneratorWithSuffix({ dateNumber, suffix: 'of each month' })

export const dueDayGenerator = dateNumber =>
  dateGeneratorWithSuffix({ dateNumber, suffix: 'of the month' })

export const generateQuotationByType = ({
  category,
  customCategory,
  monthlyDescription,
  oneTimeDescription,
  amount,
  paymentType,
  // for monthly
  firstPayment,
  // for monthly
  lastPayment,
  // for monthly
  dueOn,
  // for one-time
  dueDate,
  selectedFeeCollection,
}) => {
  let description = ''
  if (paymentType === QUOTATION_TYPE.RECURRING) {
    description = monthlyDescription
  } else {
    description = oneTimeDescription
  }
  const feeCollection = { ...selectedFeeCollection }

  const newQuotation = {
    type: paymentType,
    category: category === PAYMENT_CATEGORIES.OTHER ? customCategory : category,
    description: isEmpty(description) ? undefined : description,
    amount,
    dueDate,
    dueOn,
    feeCollection,
  }
  if (paymentType === QUOTATION_TYPE.RECURRING) {
    newQuotation.startDate = firstPayment
    if (lastPayment === MONTH_TO_MONTH_LABEL) {
      newQuotation.endDate = null
      newQuotation.term = RECURRING_PAYMENT_TERM.PERPETUAL
    } else {
      newQuotation.endDate = lastPayment
      newQuotation.term = RECURRING_PAYMENT_TERM.FIXED
    }
  }
  return newQuotation
}

export const mapQuotationToForm = quotation => {
  if (quotation.type === QUOTATION_TYPE.ONE_TIME) {
    return { ...quotation, oneTimeDescription: quotation.description, paymentType: quotation.type }
  }
  return {
    ...quotation,
    monthlyDescription: quotation.description,
    paymentType: quotation.type,
    dueOn: quotation.dueOn,
  }
}

/**
 * @param {object} feeCollection
 * @param {string} feeCollection.bank
 * @param {string} feeCollection.card
 */
export const saveDefaultFeeCollection = feeCollection => {
  localStorage.setItem(RENT_PAYMENT_DEFAULT_FEE_COLLECTION_KEY, JSON.stringify(feeCollection))
}

/**
 * @param {object} feeCollection
 * @param {string} feeCollection.bank
 * @param {string} feeCollection.card
 */
export const getDefaultFeeCollection = () => {
  const value = localStorage.getItem(RENT_PAYMENT_DEFAULT_FEE_COLLECTION_KEY)

  try {
    return JSON.parse(value)
  } catch {
    return null
  }
}
