import { S24 } from "@rentspree/component-v2"
import styled from "styled-components"

export const Heading = styled(S24)`
  margin-top: 0;
  font-family: Source Sans Pro !important;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`
