import { buffers } from 'redux-saga'
import get from 'lodash/get'
import {
  GET_ENVELOPE,
  UPDATE_ENVELOPE,
  SHARE_ENVELOPE,
  LEASE_AGREEMENT_DETAILS,
  UPDATE_ENVELOPE_SIGN,
  UPLOAD_SIGNATURE,
  DIGIDOC_GET_FILE,
  UPDATE_ENVELOPE_CONSENT,
  UPDATE_TEMPLATE_ENVELOPE,
  USER_TERMS_API,
  EXCHANGE_TOKEN_API,
  ENVELOPE_LINK_EXPIRED_PAGE,
  GET_ENVELOPE_PDF,
} from 'constants/route'

import {
  takeLatest,
  put,
  call,
  all,
  fork,
  debounce,
  select,
  actionChannel,
  take,
  delay,
  race,
  cancel,
} from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import FileSaver from 'file-saver'
import { push } from 'connected-react-router'
import isEmpty from 'lodash/isEmpty'
import { isIOS } from 'legacy/components/helper/ua-parser-js'
import { errorMessage } from 'containers/errors/constants'
import { updateEnvelopeTrackerMapper } from 'tracker/tracker-mapper'
import tracker from 'tracker'
import {
  EVENT_LEASE_DOC_REVIEW_AND_SIGN,
  SIGNATURE_TYPE_PROPERTIES,
  LEASE_AGREEMENTS,
  LEASE_DOC_TEMPLATE_EVENT,
} from 'tracker/const'
import { findSignerByRecipient, findSignerByOwner, mapFilesForSign } from 'helpers/signing'

import { openSweetAlert } from 'legacy/actions/sweet-alert.action'
import { ALERT_PRESET } from 'legacy/libs/sweet-alert/sweet-alert'
import { apiEnvelopeInstance, UserApiInstance } from '../../utils/api-interceptor'
import { LEASE_AGREEMENTS as LEASE_AGREEMENTS_MSG_ERROR } from '../../constants/error-messages'
import {
  envelopeApi,
  updateEnvelopeApi,
  shareEnvelopeApi,
  updateEnvelopeSignApi,
  uploadSignatureApi,
  saveSignSignature,
  editSignSignature,
  savingFilesApi,
  alertPrintAndDownloadNotHaveURL,
  openSweetAlertError,
  updateEnvelopeConsentApi,
  autosaveEnvelope,
  queueAutosave,
  setIsAutosaving,
  updateEnvelopeByTemplateApi,
  updateConsentLeaseAgreementApi,
  updateEnvelopeByTemplate,
  makePopUnderToCARPage,
  getBase64FilesApi,
  savingBufferFilesApi,
  generatePdfFileURLApi,
} from './actions'

import {
  ENVELOPE_CALL,
  UPDATE_ENVELOPE_CALL,
  SHARE_ENVELOPE_CALL,
  UPDATE_ENVELOPE_SIGN_CALL,
  UPLOAD_SIGN_SIGNATURE,
  ENVELOPE_STATUS,
  SAVE_FILES_ENVELOPE,
  UPDATE_ENVELOPE_CONSENT_CALL,
  AUTOSAVE_ENVELOPE_CALL,
  QUEUE_AUTOSAVE,
  UPDATE_ENVELOPE_TEMPLATE_CALL,
  UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
  CAR_POP_UNDER_MODAL,
  GET_BASE64_FILES_CALL,
  EXCHANGE_TOKEN_CALL,
  SAVE_BUFFER_FILES_ENVELOPE,
  GENERATE_PDF_FILE_URL,
} from './constants'
import {
  selectEnvelopeData as makeSelectEnvelopeData,
  makeSelectIsAcceptTermsLeaseAgreement,
  selectUserIntegration,
  makeSelectIsUsingTemplate,
} from './selectors'
import { LEASE_AGREEMENT_TERM, LEASE_TERM_VERSION } from '../../constants/terms'
import { setCarModalState } from './select-options-step/actions'

export const callAPI = ({ envelopeId, token }) =>
  apiEnvelopeInstance.get(buildPath(GET_ENVELOPE, { envelopeId }, { token }))

export const callExchangeToken = ({
  envelopeId,
  queryToken: token,
  scope,
  leaseAgreementId,
  propertyId,
}) =>
  apiEnvelopeInstance.post(buildPath(EXCHANGE_TOKEN_API, { envelopeId }, { token }), {
    scope,
    leaseAgreementId,
    propertyId,
  })

export const callUpdateEnvelope = ({ envelopeId, payload }) =>
  apiEnvelopeInstance.put(buildPath(UPDATE_ENVELOPE, { envelopeId }), payload)

export const callUpdateEnvelopeSign = ({ envelopeId, payload, options }) => {
  const { token, leaseAgreementId, propertyId } = options
  return apiEnvelopeInstance.put(buildPath(UPDATE_ENVELOPE_SIGN, { envelopeId }, { token }), {
    ...payload,
    leaseAgreementId,
    propertyId,
  })
}

export const callUpdateConsent = () =>
  UserApiInstance.post(USER_TERMS_API, {
    termName: LEASE_AGREEMENT_TERM,
    version: LEASE_TERM_VERSION, // format is YYYYMMDD
  })

export const callUpdateEnvelopeTemplate = ({ envelopeId, payload }) =>
  apiEnvelopeInstance.put(buildPath(UPDATE_TEMPLATE_ENVELOPE, { envelopeId }), payload)

export const callShareEnvelope = ({ envelopeId, propertyId, leaseAgreementId }) =>
  apiEnvelopeInstance.post(buildPath(SHARE_ENVELOPE, { envelopeId }), {
    propertyId,
    leaseAgreementId,
  })

export const callUploadSignature = ({ fullName, initials, envelopeId, userToken }) =>
  apiEnvelopeInstance.post(buildPath(UPLOAD_SIGNATURE, { envelopeId }), {
    signature: {
      initials,
      fullName,
    },
    userToken,
  })

export const callUpdateEnvelopeConsent = ({ envelopeId, payload, options }) => {
  const { token } = options
  return apiEnvelopeInstance.put(buildPath(UPDATE_ENVELOPE_CONSENT, { envelopeId }, { token }), {
    ...payload,
  })
}

export function* envelopeSaga({ payload }) {
  yield put(envelopeApi.request())
  try {
    const response = yield call(callAPI, payload)

    if (response.status === ENVELOPE_STATUS.VOID) {
      yield put(
        push({
          pathname: payload.voidedPath,
        }),
      )
    }

    let envelope = response

    if (payload.forSign) {
      const { recipients, files, owner } = response

      const foundSignerByRecipient = yield call(findSignerByRecipient, recipients)
      // for deprecateFlow
      const refreshToken = get(foundSignerByRecipient, 'refreshToken', '')
      if (refreshToken) {
        const url = buildPath(ENVELOPE_LINK_EXPIRED_PAGE, {
          leaseAgreementId: payload.options.leaseAgreementId,
          propertyId: payload.options.propertyId,
          envelopeId: payload.envelopeId,
        })
        yield put(push({ pathname: url, search: `?refreshToken=${refreshToken}` }))
      }
      const foundSignerByOwner = yield call(findSignerByOwner, recipients, owner)
      const signer = foundSignerByRecipient || foundSignerByOwner

      const mappedFiles = yield call(mapFilesForSign, files, signer)

      envelope = { ...envelope, files: mappedFiles }
    }

    yield put(envelopeApi.success(envelope))
  } catch (err) {
    yield put(envelopeApi.failure())
    yield put(push({ pathname: payload.accessDeniedPath }))
  }
}

export function* updateEnvelopeSaga({
  payload,
  envelopeId,
  redirectPath,
  updateStep,
  otherTracker,
}) {
  yield put(updateEnvelopeApi.request())
  try {
    let envelope = payload
    if (isEmpty(envelope)) {
      const { files } = yield select(selectEnvelopeData)
      envelope = { files }
    }

    const response = yield call(callUpdateEnvelope, {
      envelopeId,
      payload: envelope,
    })
    yield put(updateEnvelopeApi.success(response))
    const eventItem = updateEnvelopeTrackerMapper(updateStep, response)
    if (eventItem.eventName) {
      yield call([tracker, 'trackEvent'], eventItem.eventName, eventItem.properties)
    }
    if (otherTracker) {
      yield call([tracker, 'trackEvent'], otherTracker.eventName, otherTracker.properties)
    }
    if (redirectPath) {
      yield put(push({ pathname: redirectPath }))
    }
  } catch (err) {
    err.message = errorMessage.generalSaveError
    yield put(updateEnvelopeApi.failure(err))
  }
}
export function* updateEnvelopeSignSaga({ payload, envelopeId, redirectPath, options }) {
  const { isOwner } = options
  yield put(updateEnvelopeSignApi.request())
  try {
    const response = yield call(callUpdateEnvelopeSign, {
      envelopeId,
      payload,
      options,
    })
    yield put(updateEnvelopeSignApi.success(response))
    yield call([tracker, 'trackEvent'], EVENT_LEASE_DOC_REVIEW_AND_SIGN.FINISH_ESIGN)
    if (redirectPath) {
      yield put(push({ pathname: redirectPath, search: `?isOwner=${isOwner}` }))
    }
  } catch (err) {
    err.message = errorMessage.generalSaveError
    yield put(
      openSweetAlertError({
        title: LEASE_AGREEMENTS_MSG_ERROR.SIGN_ERROR.TITLE,
        text: LEASE_AGREEMENTS_MSG_ERROR.SIGN_ERROR.MESSAGE,
      }),
    )
    yield put(updateEnvelopeSignApi.failure(err))
  }
}
export const selectedIsUsingTemplate = makeSelectIsUsingTemplate()
export function* shareEnvelopeSaga({
  payload: { propertyId, envelopeId, leaseAgreementId, recipients },
}) {
  yield put(shareEnvelopeApi.request())
  try {
    yield call(callShareEnvelope, { propertyId, envelopeId, leaseAgreementId })
    yield put(shareEnvelopeApi.success())
    const isUsingTemplate = yield select(selectedIsUsingTemplate)
    const itemsMappingToTrack = {
      template: isUsingTemplate
        ? LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.LR_FORM
        : LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.CUSTOM,
    }
    recipients
      .filter(({ role }) => role)
      .forEach(({ role }) => {
        if (itemsMappingToTrack[`${role}_recipient_count`]) {
          itemsMappingToTrack[`${role}_recipient_count`] += 1
        } else {
          itemsMappingToTrack[`${role}_recipient_count`] = 1
        }
      })
    yield call(
      [tracker, 'trackEvent'],
      LEASE_AGREEMENTS.REQUEST_SHARE_ENVELOPE,
      itemsMappingToTrack,
    )
    const nextPath = buildPath(LEASE_AGREEMENT_DETAILS, {
      propertyId,
      leaseAgreementId,
    })
    yield put(push({ pathname: nextPath }))
  } catch (err) {
    err.message = errorMessage.sendEmailError
    yield put(shareEnvelopeApi.failure(err))
  }
}

export function* uploadSignatureSaga({
  payload: { type, fullName, initials, envelopeId, userToken },
  mode,
}) {
  yield put(uploadSignatureApi.request())
  try {
    const response = yield call(callUploadSignature, {
      fullName,
      initials,
      envelopeId,
      userToken,
    })
    yield put(uploadSignatureApi.success())
    if (mode === 'save') {
      yield put(
        saveSignSignature({
          type,
          fullName: response.fullName,
          initials: response.initials,
        }),
      )
      yield call(
        [tracker, 'trackEvent'],
        EVENT_LEASE_DOC_REVIEW_AND_SIGN.ADOPT_SIGNATURE,
        SIGNATURE_TYPE_PROPERTIES.DRAW,
      )
    } else if (mode === 'edit') {
      yield put(
        editSignSignature({
          type,
          fullName: response.fullName,
          initials: response.initials,
        }),
      )
      yield call(
        [tracker, 'trackEvent'],
        EVENT_LEASE_DOC_REVIEW_AND_SIGN.REPLACE_SIGNATURE,
        SIGNATURE_TYPE_PROPERTIES.DRAW,
      )
    }
  } catch (err) {
    err.message = errorMessage.generalSaveError
    yield put(uploadSignatureApi.failure(err))
  }
}

export const getFiles = async ({ URL, responseType }) => {
  if (isIOS()) {
    window.open(URL)
    return Promise.resolve({ fileData: URL, isIOS: true })
  }
  const response = await apiEnvelopeInstance.get(URL, {
    responseType,
  })
  return {
    fileData: response,
  }
}

export const getBufferFiles = async url => {
  const { envelopePdf, fileName } = await apiEnvelopeInstance.get(url)
  const uint8Buffer = new Uint8Array(envelopePdf.data)
  const fileData = new Blob([uint8Buffer], {
    type: 'application/pdf',
  })
  const fileURL = URL.createObjectURL(fileData)
  if (isIOS()) {
    window.open(fileURL)
    return Promise.resolve({ fileData, isIOS: true })
  }

  return {
    fileData,
    fileName,
    fileURL,
  }
}

export function* saveFiles({ payload: { pdfPath, _id: envelopeId, token } }) {
  yield put(savingFilesApi.request(envelopeId))
  try {
    const URL = buildPath(DIGIDOC_GET_FILE, { envelopeId }, { token })
    const fileName = pdfPath.split('/')[2]
    const response = yield call(getFiles, { URL, responseType: 'blob' })
    const { fileData } = response
    if (!isIOS()) {
      yield fork([FileSaver, 'saveAs'], fileData, fileName)
    }
    yield put(savingFilesApi.success({ envelopeId }))
  } catch (err) {
    yield put(alertPrintAndDownloadNotHaveURL())
    yield put(savingFilesApi.failure({ err, envelopeId }))
  }
}

export function* saveBufferFiles({ payload: { pdfPath, _id: envelopeId, rentalId } }) {
  yield put(savingBufferFilesApi.request(envelopeId))
  try {
    const URL = buildPath(GET_ENVELOPE_PDF, { envelopeId, rentalId })
    const { fileData, fileName: fileNameFromResponse } = yield call(getBufferFiles, URL)
    const fileName = pdfPath.split('/')[2] || fileNameFromResponse
    if (!isIOS()) {
      yield fork([FileSaver, 'saveAs'], fileData, fileName)
    }
    yield put(savingBufferFilesApi.success({ envelopeId }))
  } catch (err) {
    yield put(alertPrintAndDownloadNotHaveURL())
    yield put(savingBufferFilesApi.failure({ err, envelopeId }))
  }
}

export function* getBase64Files({ payload: { envelopeId, token } }) {
  yield put(getBase64FilesApi.request(envelopeId))
  try {
    const URL = buildPath(DIGIDOC_GET_FILE, { envelopeId }, { token })
    const response = yield call(getFiles, { URL, responseType: 'arraybuffer' })
    const fileData = Buffer.from(response.fileData, 'binary').toString('base64')
    yield put(getBase64FilesApi.success({ fileData }))
  } catch (error) {
    yield put(getBase64FilesApi.failure({ error }))
  }
}

export function* generatePdfFileURL({ payload: { envelopeId, rentalId, callback } }) {
  yield put(generatePdfFileURLApi.request(envelopeId))
  try {
    const URL = buildPath(GET_ENVELOPE_PDF, { rentalId, envelopeId })
    const { fileURL } = yield call(getBufferFiles, URL)
    yield put(generatePdfFileURLApi.success())
    yield call(callback, fileURL)
  } catch (error) {
    yield put(generatePdfFileURLApi.failure({ error }))
  }
}

export function* updateEnvelopeConsent({ payload, envelopeId, options }) {
  yield put(updateEnvelopeConsentApi.request())
  try {
    const response = yield call(callUpdateEnvelopeConsent, {
      envelopeId,
      payload,
      options,
    })
    yield put(updateEnvelopeConsentApi.success(response))
  } catch (err) {
    yield put(updateEnvelopeConsentApi.failure(err))
  }
}

export function* noEligibleToSelectTemplate(provider, params) {
  switch (provider) {
    case 'car':
      yield call([tracker, 'trackEvent'], LEASE_DOC_TEMPLATE_EVENT.EVENT_NAME.SEE_NRDS_ID_POP_UP, {
        click_from: LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.TEMPLATE_SELECTION,
      })
      yield put(setCarModalState(true, params))
      break
    default:
      break
  }
}

export function* checkEligibleToSelectTemplate(digitalDocTemplate) {
  try {
    const userIntegration = yield select(selectUserIntegration)
    const linked = userIntegration.includes(digitalDocTemplate.provider)
    if (linked) {
      return
    }
    const error = new Error()
    error.status = 403
    throw error
  } catch (error) {
    throw error
  }
}

export const selectIsAcceptTermsLeaseAgreement = makeSelectIsAcceptTermsLeaseAgreement()

export function* updateConsentSelectOption({
  envelopeId,
  payload,
  nextPath,
  ignoreUpdateTemplate,
  digitalDocTemplate,
}) {
  yield put(updateConsentLeaseAgreementApi.request())
  try {
    yield call(callUpdateConsent)
    yield put(
      updateConsentLeaseAgreementApi.success({
        isConsentSuccess: true,
        isShowConsentModal: true,
      }),
    )
    yield call([tracker, 'trackEvent'], LEASE_AGREEMENTS.ACCEPT_CONCENT)
    yield delay(1000)
    yield put(
      updateConsentLeaseAgreementApi.success({
        isShowConsentModal: false,
        isConsentSuccess: false,
      }),
    )
    if (!ignoreUpdateTemplate) {
      yield put(updateEnvelopeByTemplate(envelopeId, payload, nextPath, digitalDocTemplate))
    } else {
      yield put(push({ pathname: nextPath }))
    }
  } catch (err) {
    yield put(updateConsentLeaseAgreementApi.failure(err))
  }
}

export function* callExchangeTokenSaga({ payload }) {
  try {
    const res = yield call(callExchangeToken, payload)
    const { link } = res
    window.open(link, '_self')
  } catch (error) {
    console.log(error)
  }
}

export function* updateTemplateToEnvelope({
  envelopeId,
  payload,
  nextPath,
  digitalDocTemplate,
  showPopUnder,
}) {
  try {
    yield call(checkEligibleToSelectTemplate, digitalDocTemplate)
    yield put(updateEnvelopeByTemplateApi.request())
    const response = yield call(callUpdateEnvelopeTemplate, {
      envelopeId,
      payload,
    })
    yield call(
      [tracker, 'trackEvent'],
      LEASE_DOC_TEMPLATE_EVENT.EVENT_NAME.SELECT_TEMPLATE_TO_LEASE_DOC,
      {
        template: LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.LR_FORM,
      },
    )
    if (showPopUnder) {
      const continueToCARPage = yield call(makePopUnderToCARPage, nextPath)
      yield put(
        openSweetAlert({
          preset: ALERT_PRESET.SUCCESS,
          option: CAR_POP_UNDER_MODAL,
          promise: continueToCARPage,
        }),
      )
    } else {
      yield put(push({ pathname: nextPath }))
      yield put(updateEnvelopeByTemplateApi.success(response))
    }
  } catch (error) {
    if (error.status === 403) {
      yield call(noEligibleToSelectTemplate, digitalDocTemplate.provider, {
        envelopeId,
        payload,
        nextPath,
        digitalDocTemplate,
      })
      return
    }
    yield put(updateEnvelopeByTemplateApi.failure(error))
  }
}

export function* queueAutosaveSaga() {
  yield put(queueAutosave())
}

export function* watchShareEnvelope() {
  yield takeLatest(SHARE_ENVELOPE_CALL, shareEnvelopeSaga)
}

export function* watchApiCall() {
  yield takeLatest(ENVELOPE_CALL, envelopeSaga)
}

export function* watchUpdateEnvelope() {
  yield takeLatest(UPDATE_ENVELOPE_CALL, updateEnvelopeSaga)
}
export function* watchUpdateEnvelopeSign() {
  yield takeLatest(UPDATE_ENVELOPE_SIGN_CALL, updateEnvelopeSignSaga)
}
export function* watchUploadSign() {
  yield takeLatest(UPLOAD_SIGN_SIGNATURE, uploadSignatureSaga)
}

export function* watchSaveFiles() {
  yield takeLatest(SAVE_FILES_ENVELOPE, saveFiles)
}

export function* watchSaveBufferFiles() {
  yield takeLatest(SAVE_BUFFER_FILES_ENVELOPE, saveBufferFiles)
}
export function* watchUpdateEnvelopeConsent() {
  yield takeLatest(UPDATE_ENVELOPE_CONSENT_CALL, updateEnvelopeConsent)
}
export function* watchGeneratePdfFileURL() {
  yield takeLatest(GENERATE_PDF_FILE_URL, generatePdfFileURL)
}

export function* watchAutosaveTriggered() {
  while (true) {
    const queueDebounce = yield debounce(5000, AUTOSAVE_ENVELOPE_CALL, queueAutosaveSaga)

    yield take(UPDATE_ENVELOPE_CALL)
    yield cancel(queueDebounce)
  }
}

export function* watchUpdateTemplateEnvelope() {
  yield takeLatest(UPDATE_ENVELOPE_TEMPLATE_CALL, updateTemplateToEnvelope)
}

export function* watchUpdateConsentSelectOption() {
  yield takeLatest(UPDATE_LEASE_AGREEMENT_CONSENT_CALL, updateConsentSelectOption)
}

export function* watchGetBase64Files() {
  yield takeLatest(GET_BASE64_FILES_CALL, getBase64Files)
}

export const selectEnvelopeData = makeSelectEnvelopeData()
export const autosaveQueueBuffer = buffers.expanding()

export function* watchAutosaveQueued() {
  const autosaveQueue = yield actionChannel(QUEUE_AUTOSAVE, autosaveQueueBuffer)
  while (true) {
    yield take(autosaveQueue)
    try {
      const { _id: envelopeId, files, status } = yield select(selectEnvelopeData)
      if (status === ENVELOPE_STATUS.INPROGRESS) {
        yield put(autosaveEnvelope.request())
        const { response, cancelEnvelope } = yield race({
          response: call(callUpdateEnvelope, {
            envelopeId,
            payload: { files },
          }),
          cancelEnvelope: take(UPDATE_ENVELOPE_CALL),
        })
        if (response) {
          const { updatedAt } = response
          yield put(autosaveEnvelope.success(updatedAt))
        }
        if (cancelEnvelope) {
          while (!autosaveQueueBuffer.isEmpty()) autosaveQueueBuffer.flush()
        }
        if (autosaveQueueBuffer.isEmpty()) yield put(setIsAutosaving(false))
      }
    } catch (err) {
      yield put(autosaveEnvelope.failure(err))
    }
  }
}

export function* watchExchangeToken() {
  yield takeLatest(EXCHANGE_TOKEN_CALL, callExchangeTokenSaga)
}

export const watchers = [
  watchApiCall(),
  watchUpdateEnvelope(),
  watchShareEnvelope(),
  watchUpdateEnvelopeSign(),
  watchUploadSign(),
  watchSaveFiles(),
  watchSaveBufferFiles(),
  watchUpdateEnvelopeConsent(),
  watchAutosaveTriggered(),
  watchAutosaveQueued(),
  watchUpdateTemplateEnvelope(),
  watchUpdateConsentSelectOption(),
  watchGetBase64Files(),
  watchExchangeToken(),
  watchGeneratePdfFileURL(),
]

export function* rootSaga() {
  yield all(watchers)
}

export default rootSaga
