import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withReducer } from 'containers/property-list/connect'

import { getAccessToken } from 'legacy/reducers/persist-state.reducer'
import { getPropertyPermalink } from 'legacy/actions/property.action'
import {
  clearIsSendEmail,
  sendEmails,
  sendTextMessage,
  sendMoreEmails,
  sendMoreTextMessage,
} from 'legacy/actions/rental-submission.action'
import { updateFlyerApi } from 'legacy/actions/user.action'
import { createContact, createContacts } from 'containers/contacts/actions'
import { selectCombinedProperty, selectRequestOptionsForStepAction } from 'containers/tenant-screening/request-v2.2/selectors'
import {
  clearRequestOptions,
  clearScreeningWithPropertyProOptions,
  clearScreeningWithoutPropertyProOptions
} from '../actions'

const Connect = ComposedComponent =>
  compose(
    connect(
      state => ({
        requestOptions: selectRequestOptionsForStepAction(state),
        property: selectCombinedProperty(state),
        rentalSubmission: state.rentalSubmission,
        profile: state.user.profile,
        accessToken: getAccessToken(state.persistState),
      }),
      dispatch => ({
        actions: bindActionCreators(
          {
            getPropertyPermalink,
            sendEmails,
            sendTextMessage,
            sendMoreEmails,
            sendMoreTextMessage,
            updateFlyerApi,
            createContact,
            createContacts,
            clearRequestOptions,
            clearIsSendEmail,
            clearScreeningWithPropertyProOptions,
            clearScreeningWithoutPropertyProOptions,
          },
          dispatch,
        ),
      }),
    ),
    withReducer,
  )(ComposedComponent)

export default Connect
