import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

const Wrapper = styled.h1`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 600;
  line-height: 36px;
  margin: ${({ margin }) => margin};
`

// TODO: [lnr-2055]: Add unit tests
const Header = ({ text, margin, fontSize = '28px', lineHeight = '36px' }) => (
  <Wrapper margin={margin} fontSize={fontSize} lineHeight={lineHeight}>
    {text}
  </Wrapper>
)

Header.propTypes = {
  text: propTypes.string.isRequired,
}

export default Header
