export const E_SIGNATURE_FEATURE_ID = "rental_document"
export const ESIGN_TEMPLATE_ID = "esign_template"
export const FEATURE_INTEGRATION_ID = "feature_integration"

export const RENT_PAYMENTS_ID = "rent_payment"
export const RENT_PAYMENTS_AGENT_INITIATED_ID = "rent_payment_agent_initiated"
export const RENTERS_INSURANCE_FEATURE_ID = "renters_insurance"
export const RENT_ESTIMATE_FEATURE_ID = "rent_estimate"
export const REQUEST_DOCS_FEATURE_ID = "request_docs"
export const REFERENCE_CHECK_FEATURE_ID = "reference_checks"
export const MESSAGES_FEATURE_ID = "messages"
export const LISTING_MANAGEMENT_FEATURE_ID = "listing_management"
