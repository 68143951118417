import { takeLatest, put, call } from 'redux-saga/effects'

import {
  GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION,
  getPayeeProviderAccountInformationAPI,
} from '../../actions'
import { getPayeeProviderAccountInformationInstance } from '../../api-instances'

export function* getPayeeProviderAccountInformationSaga() {
  yield put(getPayeeProviderAccountInformationAPI.request())
  try {
    const response = yield call(getPayeeProviderAccountInformationInstance)
    yield put(getPayeeProviderAccountInformationAPI.success(response))
  } catch (err) {
    yield put(getPayeeProviderAccountInformationAPI.failure(err))
  }
}

export function* watchGetPayeeProviderAccountInformationSaga() {
  yield takeLatest(GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION, getPayeeProviderAccountInformationSaga)
}
