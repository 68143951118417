import { API_URL } from 'env'

export const GET_ACTIVITIES_BY_CONTACT_ID_API = `${API_URL}/api/v2/people/contacts/:contactId/activities`

export const GET_ACTIVITIES_BY_CONTACT_ID = 'GET_ACTIVITIES_BY_CONTACT_ID'
export const GET_ACTIVITIES_BY_CONTACT_ID_CALL = 'GET_ACTIVITIES_BY_CONTACT_ID_CALL'
export const GET_ACTIVITIES_BY_CONTACT_ID_REQUEST = 'GET_ACTIVITIES_BY_CONTACT_ID_REQUEST'
export const GET_ACTIVITIES_BY_CONTACT_ID_SUCCESS = 'GET_ACTIVITIES_BY_CONTACT_ID_SUCCESS'
export const GET_ACTIVITIES_BY_CONTACT_ID_FAILURE = 'GET_ACTIVITIES_BY_CONTACT_ID_FAILURE'

export const ACTIVITIES_DEFAULT_LIMIT = 10
export const DEFAULT_SORT = -1
export const ACTIVITIES_ERROR_TOAST_MESSAGE = 'Error occurred, please try again'

export const CLEAR_ACTIVITIES_BY_CONTACT_ID = 'CLEAR_ACTIVITIES_BY_CONTACT_ID'

export const ACTIVITY_TEMPLATES = {
  APPLICATION_SUBMITTED: 'application_submitted',
  SCREENING_REPORT_SUBMITTED: 'screening_report_submitted',
  DOCUMENT_SIGNED: 'document_signed',
  CONTACT_CREATED: 'contact_created',
  CONTACT_CREATED_FROM_TENANT_SCREENING: 'contact_created_from_tenant_screening',
  CONTACT_CREATED_FROM_AGENT_PROFILE: 'contact_created_from_agent_profile',
  CONTACT_CREATED_LISTING_PAGE: 'contact_created_from_listing_page',
  CONTACT_CREATED_RENTSPREE_LISTING_PAGE: 'contact_created_from_renspree_listing_page',
  CONTACT_CREATED_FROM_E_SIGN: 'contact_created_from_esign',
  FIRST_INCOMING_MSG_FROM_AP: 'first_incoming_msg_from_agent_profile',
  FIRST_INCOMING_MSG_FROM_LP: 'first_incoming_msg_from_listing_page',
  CONTACT_IMPORTED: 'contact_imported',
  BIRTHDAY_GIFT_SENT: 'birthday_gift_sent',
  MOVEIN_GIFT_SENT: 'movein_gift_sent',
}
