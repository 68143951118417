import { TableCss } from 'components/table/table-style'
import React from 'react'
import styled, { css } from 'styled-components'
import { COLOR } from 'styles/settings'
import DocumentTableHeader from '../../molecules/document-table-header'
import { DocumentTableRowDesktop, DocumentTableRowMobile } from '../document-table-row'

const DivWithPaddingBottom = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-bottom: 90px;
    `}
`

export const Table = styled.table`
  ${TableCss};
  width: 100%;
  margin-bottom: 0;
  margin-top: 30px;

  thead > tr > th {
    border: none;
  }

  tbody > tr > td {
    vertical-align: middle;
    border: none;
  }

  tbody > tr > td.first {
    padding-bottom: 0;
  }

  tbody > tr > td.last {
    padding-top: 4px;
  }

  tbody > tr:last-child > td {
    border-bottom: none !important;
  }

  thead > tr {
    border-bottom: 1px solid ${COLOR.placeholderGrey};
  }

  tbody > tr {
    border-bottom: 1px solid ${COLOR.grayFade};
  }

  tbody > tr > td.checkbox-cell {
    padding-bottom: 0;
    padding-left: 20.5px;
    padding-top: 12.5px;
    padding-right: 0;
  }

  thead > tr > th.header-checkbox-cell {
    padding: 0;
    padding-left: 20.5px;
  }

  tbody > tr > td.document-type-cell-desktop {
    width: 300px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: block;

      tbody {
        display: block;
        overflow: scroll;
      }

      thead > tr > th,
      tbody > tr > td {
        display: block;
        width: 100%;

        &:nth-child(2) {
          width: 100%;
        }

        &.icon-col {
          display: block;
        }

        &:last-child {
          width: 150px;
        }
      }

      thead > tr > th.header-checkbox-cell {
        padding-top: 10px;
      }

      tbody > tr > td.document-type-data {
        padding-bottom: 5px;
      }

      tbody > tr > td.document-status-data {
        padding-top: 0;
        padding-bottom: 6px;
      }

      tbody > tr > td.hyperlink-button-mobile {
        padding-top: 4px;
      }

      tbody > tr > td.checkbox-mobile {
        padding-bottom: 0;
        padding-left: 20.5px;
        padding-top: 12.5px;
      }

      tbody > tr > td.kebab-cell-mobile {
        height: 35px;
        position: unset;
      }
    `}

  .other-docs-request {
    margin: -3px 0;
  }
`

export const DocumentRequestTableDesktop = ({
  docsList,
  selectedDocs,
  requestDisabled,
  onDownloadDoc,
  onClickRow,
  onClickCloseNote,
  onCheckAll,
  onNoteInputChange,
  isFetchingFullDocZip,
  isMobile,
  getDocumentKebabMenuItems,
  fileMenuItems,
}) => (
  <DivWithPaddingBottom isMobile={isMobile}>
    <Table isMobile={isMobile}>
      <DocumentTableHeader
        requestDisabled={requestDisabled}
        onCheckAll={onCheckAll}
        selectedDocs={selectedDocs}
        onDownloadDoc={onDownloadDoc}
        isFetchingFullDocZip={isFetchingFullDocZip}
        docsList={docsList}
      />
      <tbody>
        {docsList.map(doc => (
          <DocumentTableRowDesktop
            key={doc.id}
            doc={doc}
            selectedDocs={selectedDocs}
            requestDisabled={requestDisabled}
            onClickRow={onClickRow}
            onClickCloseNote={onClickCloseNote}
            onNoteInputChange={onNoteInputChange}
            getDocumentKebabMenuItems={getDocumentKebabMenuItems}
            fileMenuItems={fileMenuItems}
          />
        ))}
      </tbody>
    </Table>
  </DivWithPaddingBottom>
)

export const DocumentRequestTableMobile = ({
  docsList,
  selectedDocs,
  requestDisabled,
  onDownloadDoc,
  onClickRow,
  onClickCloseNote,
  onCheckAll,
  onNoteInputChange,
  isFetchingFullDocZip,
  isMobile,
  getDocumentKebabMenuItems,
  fileMenuItems,
}) => (
  <DivWithPaddingBottom isMobile={isMobile}>
    <Table isMobile={isMobile}>
      <DocumentTableHeader
        requestDisabled={requestDisabled}
        onCheckAll={onCheckAll}
        selectedDocs={selectedDocs}
        onDownloadDoc={onDownloadDoc}
        isFetchingFullDocZip={isFetchingFullDocZip}
        docsList={docsList}
      />
      <tbody>
        {docsList.map(doc => (
          <DocumentTableRowMobile
            key={doc.id}
            doc={doc}
            selectedDocs={selectedDocs}
            requestDisabled={requestDisabled}
            onClickRow={onClickRow}
            onClickCloseNote={onClickCloseNote}
            onNoteInputChange={onNoteInputChange}
            getDocumentKebabMenuItems={getDocumentKebabMenuItems}
            fileMenuItems={fileMenuItems}
          />
        ))}
      </tbody>
    </Table>
  </DivWithPaddingBottom>
)
