import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import RequestButton from '../../atoms/request-button'

const Wrapper = styled.div`
  display: flex;
  padding: 40px;
`

// TODO: [lnr-2055]: Add unit tests
const ButtonSection = ({ isRequestDocumentDisabled, onClickButton }) => (
  <Wrapper>
    <RequestButton disabled={isRequestDocumentDisabled} onClick={onClickButton} text="Request" />
  </Wrapper>
)

ButtonSection.propTypes = {
  isRequestDocumentDisabled: propTypes.bool.isRequired,
  onClickButton: propTypes.func.isRequired,
}

export default ButtonSection
