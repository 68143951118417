import swal from "sweetalert"
import tracker from "tracker"

const funSweetAlert = inputValue => {
  if (inputValue === false) {
    return false
  }
  if (inputValue === "") {
    swal.showInputError("Input field is required")
    return false
  }
  swal("Nice!", `You wrote: ${inputValue}`, "success")
  return true
}

export const ALERT_PRESET = {
  SUCCESS: {
    icon: "success",
    button: {
      text: "OK",
      className: "success-btn",
    },
    className: "rentspree-sweetalert",
  },
  UNAUTHORIZED: {
    icon: "error",
    button: {
      text: "Go to Login",
      className: "error-btn",
    },
    className: "rentspree-sweetalert",
  },
  ERROR: {
    icon: "error",
    button: {
      text: "Close",
      className: "error-btn",
    },
    className: "rentspree-sweetalert",
  },
  TRY_AGAIN: {
    icon: "error",
    button: {
      text: "Try Again",
      className: "error-btn",
    },
    className: "rentspree-sweetalert-error",
  },
  MULTIPLE_CHOICE: {
    icon: "success",
    closeOnClickOutside: false,
    buttons: {
      choice_one: {
        text: "Confirm",
        value: null,
        visible: true,
        className: "success-btn",
      },
      choice_two: {
        text: "Back to page",
        value: true,
        visible: true,
        className: "success-btn",
      },
    },
    className: "rentspree-sweetalert",
  },
  WARNING: {
    icon: "warning",
    button: {
      text: "Got it",
      className: "error-btn",
    },
    className: "rentspree-sweetalert",
  },
  funSweetAlert,
}

export function sweetAlert(
  preset = ALERT_PRESET.SUCCESS,
  options = {},
  promise = Function,
) {
  if (typeof preset.buttons === "object") {
    return swal({
      ...preset,
      ...options,
    }).then(val => {
      const selectChoice = Object.values(options.buttons).find(
        choice => choice.value === val,
      )
      if (selectChoice.eventName) {
        tracker.trackEvent(selectChoice.eventName, selectChoice.eventProperty)
      }
      const callback = selectChoice.promise || promise
      return callback()
    })
  }
  return swal({
    ...preset,
    ...options,
    button: {
      ...(preset.button || {}),
      ...(typeof options.button === "object"
        ? options.button
        : { text: options.button || preset.button.text }),
    },
  }).then(promise)
}
