import React from "react"

export const DesktopViewOnly = () => {
  React.useEffect(() => {
    let viewportMeta
    try {
      viewportMeta = document.querySelector("meta[name='viewport']")
      viewportMeta.setAttribute("content", `width=1024`)
      setTimeout(() => {
        const { innerHeight } = window
        document.documentElement.style.setProperty(
          "--vh",
          `${innerHeight * 0.01}px`,
        )
      }, 500)
    } catch (e) {
      console.log("query meta tag error ==> ", e)
    }
    return () => {
      viewportMeta.setAttribute(
        "content",
        "width=device-width, initial-scale=1, shrink-to-fit=no",
      )
      document.documentElement.removeAttribute("style")
    }
  })

  return <></>
}

export default DesktopViewOnly
