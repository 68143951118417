import React from "react"
import Span from "@rentspree/component-v2/dist/typography/span"

import { PROPERTY_API_V2 } from "constants/route"
import { COLOR } from "styles/settings"

export const ARCHIVE_PROPERTY_CALL = "ARCHIVE_PROPERTY_CALL"
export const ARCHIVE_PROPERTY_REQUEST = "ARCHIVE_PROPERTY_REQUEST"
export const ARCHIVE_PROPERTY_SUCCESS = "ARCHIVE_PROPERTY_SUCCESS"
export const ARCHIVE_PROPERTY_FAILED = "ARCHIVE_PROPERTY_FAILED"
export const UNARCHIVE_PROPERTY_CALL = "UNARCHIVE_PROPERTY_CALL"
export const UNARCHIVE_PROPERTY_REQUEST = "UNARCHIVE_PROPERTY_REQUEST"
export const UNARCHIVE_PROPERTY_SUCCESS = "UNARCHIVE_PROPERTY_SUCCESS"
export const UNARCHIVE_PROPERTY_FAILED = "UNARCHIVE_PROPERTY_FAILED"
export const OPEN_ARCHIVE_MODAL = "OPEN_ARCHIVE_MODAL"
export const CLEAR_ARCHIVE_MODAL = "CLEAR_ARCHIVE_MODAL"

export const ARCHIVE_PROPERTY_API = `${PROPERTY_API_V2}/:propertyId/archive`

export const MODAL_STATUS = {
  ARCHIVE: "archive",
  UNARCHIVE: "unarchive",
}

export const DISABLE_FEATURE_LIST = {
  subtitle: () => (
    <>
      The following feature(s) will be{" "}
      <Span color={COLOR.fontRed}>disabled</Span>.
    </>
  ),
  list: ["Tenant Screening", "Listing Syndication"],
}
