import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getProperty, clearProperty } from 'legacy/actions/property.action'
import { createStructuredSelector } from 'reselect'
import { selectProperty } from './selectors'

export const mapStateToProps = createStructuredSelector({
  property: selectProperty,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getProperty,
      clearProperty,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
