import { COLOR } from "styles/settings"
import { API_URL } from "../../env"

export const ACCEPT_DENY_TYPE = {
  ACCEPT: "accept",
  ACCEPT_ON_CONDITION: "accept_on_condition",
  DENY: "deny",
}

export const CONDITION_FORM = {
  income: {
    belowRequirement: false,
    notVerified: false,
  },
  rentalApplication: {
    insufficientInformation: false,
    erroneousInformation: false,
    notVerifiedInformation: false,
  },
  anotherUnrelatedReasons: "",
  consumerReport: {
    creditScore: false,
    creditScoreDetails: {
      score: "",
      date: "",
      factors: "",
    },
    investigative: false,
  },
  other: {
    thirdPartyInformation: false,
  },
}

export const ACCEPT_DENY_AGREEMENT_TYPE = "accept-deny"

export const ACCEPT_DENY_CALL = "ACCEPT_DENY_CALL"
export const ACCEPT_DENY_REQUEST = "ACCEPT_DENY_REQUEST"
export const ACCEPT_DENY_SUCCESS = "ACCEPT_DENY_SUCCESS"
export const ACCEPT_DENY_FAILURE = "ACCEPT_DENY_FAILURE"

export const OPEN_ACCEPT_DENY_MODAL = "OPEN_ACCEPT_DENY_MODAL"
export const CLEAR_ACCEPT_DENY_MODAL = "CLEAR_ACCEPT_DENY_MODAL"

export const SHOW_SECURITY_DEPOSIT_MODAL = "SHOW_SECURITY_DEPOSIT_MODAL"
export const CLOSE_SECURITY_DEPOSIT_MODAL = "CLOSE_SECURITY_DEPOSIT_MODAL"

export const CHANGE_ACCEPT_DENY_TYPE = "CHANGE_ACCEPT_DENY_TYPE"
export const COMPLETE_ACCEPT_ON_CONDITION_FORM =
  "COMPLETE_ACCEPT_ON_CONDITION_FORM"
export const COMPLETE_DENY_FORM = "COMPLETE_DENY_FORM"
export const BACK_TO_FORM = "BACK_TO_FORM"

export const GET_DISCLAIMER_CONTENT_CALL = "GET_DISCLAIMER_CONTENT_CALL"
export const GET_DISCLAIMER_CONTENT_REQUEST = "GET_DISCLAIMER_CONTENT_REQUEST"
export const GET_DISCLAIMER_CONTENT_SUCCESS = "GET_DISCLAIMER_CONTENT_SUCCESS"
export const GET_DISCLAIMER_CONTENT_FAILURE = "GET_DISCLAIMER_CONTENT_FAILURE"
export const AGREE_DISCLAIMER_CALL = "AGREE_DISCLAIMER_CALL"
export const AGREE_DISCLAIMER_REQUEST = "AGREE_DISCLAIMER_REQUEST"
export const AGREE_DISCLAIMER_SUCCESS = "AGREE_DISCLAIMER_SUCCESS"
export const AGREE_DISCLAIMER_FAILURE = "AGREE_DISCLAIMER_FAILURE"

export const CONTINUE_TO_RENT_PAYMENT = "CONTINUE_TO_RENT_PAYMENT"
export const CONTINUE_TO_ESIGN = "CONTINUE_TO_ESIGN"

export const ACCEPT_DENY_API = `${API_URL}/api/v2/rental-submissions/:rentalSubmissionId/screening-result`

export const ACCEPT_DENY_EMAIL_SUBJECT = {
  [ACCEPT_DENY_TYPE.ACCEPT]: "You have been accepted to ",
  [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]:
    "Action required: Conditional acceptance to ",
  [ACCEPT_DENY_TYPE.DENY]: "Your status with ",
}

export const ACCEPT_DENY_TOAST_MESSAGE = {
  [ACCEPT_DENY_TYPE.ACCEPT]: "Accepted and sent welcome letter successfully",
  [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]:
    "Accepted and sent conditional letter successfully",
  [ACCEPT_DENY_TYPE.DENY]: "Denied and sent denial letter successfully",
  ERROR: "System error, please try again later",
}

export const CLICK_FROM = {
  APPLICATION_DETAIL: "application_detail",
  APPLICATION_LIST: "application_list",
  TENANT_SCREENING: "menu_screening",
  ASSIGN_PROPERTY: "assign property"
}

export const STATUS_TEXT = {
  [ACCEPT_DENY_TYPE.ACCEPT]: 'Accepted',
  [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]: 'Accepted on condition',
  [ACCEPT_DENY_TYPE.DENY]: 'Declined'
}

export const STATUS_COLOR = {
  [ACCEPT_DENY_TYPE.ACCEPT]: '#80C640',
  [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]: '#F2D76B',
  [ACCEPT_DENY_TYPE.DENY]: '#FF7D7D',
}

export const STATUS_BUTTON = {
  [ACCEPT_DENY_TYPE.ACCEPT]: {
    text: "Accepted",
    width: "102px",
    mWidth: "134px",
    gradient: "green",
  },
  [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]: {
    text: "Accepted on cond",
    width: "153px",
    mWidth: "193px",
    gradient: "yellow",
  },
  [ACCEPT_DENY_TYPE.DENY]: {
    text: "Denied",
    width: "90px",
    mWidth: "134px",
    gradient: "red",
  },
}

export const HEADER_COLOR = {
  [ACCEPT_DENY_TYPE.ACCEPT]: COLOR.acceptGreen,
  [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]: COLOR.brown,
  [ACCEPT_DENY_TYPE.DENY]: COLOR.denyRed,
}
