import React from "react"

import { Icon } from "@rentspree/component-v2/dist/icon"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import B14 from "@rentspree/component-v2/dist/typography/b14"

export const LoadingModalContent = ({ showPercentage }) => (
  <div>
    <Icon icon="loading" name="loading" color="#3B99FC" size="6x" spin />
    <S24 margin="20px 0 5px 0">
      Loading..
      {showPercentage && <span id="loadingPercentage">0%</span>}
    </S24>
    <B14 margin="0px">We’re fetching your property data, hang on tight!</B14>
  </div>
)
