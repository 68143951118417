import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getContactSubmissionParticipants = state =>
  get(state, 'contacts.addToContact', initialState)

export const selectIsAddDeleteContactLoading = createSelector(
  getContactSubmissionParticipants,
  contact => get(contact, 'isLoading', initialState.isLoading),
)

export const selectIsFoundContactByEmail = createSelector(
  getContactSubmissionParticipants,
  contact => !isEmpty(get(contact, 'data.contactId', initialState.data.contactId)),
)

export const selectSubmissionParticipantContactId = createSelector(
  getContactSubmissionParticipants,
  contact => get(contact, 'data.contactId', initialState.data.contactId),
)
