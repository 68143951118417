import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import { createStructuredSelector } from "reselect"

import {
  selectIsLoading,
  selectPropertyListWithDisabledOption,
  makeSelectIsWaring,
  makeSelectPropertySelector,
  selectIsError,
  makeSelectError,
} from "./selectors"
import saga from "./saga"
import reducer from "./reducer"
import { getPropertyList } from "./actions"

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading(),
  propertyList: selectPropertyListWithDisabledOption(),
  isWarning: makeSelectIsWaring(),
  propertySelector: makeSelectPropertySelector(),
  isError: selectIsError(),
  errors: makeSelectError(),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPropertyList,
    },
    dispatch,
  ),
})

export const withReducer = injectReducer({ key: "allPropertyList", reducer })
export const withSaga = injectSaga({ key: "allPropertyList", saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
