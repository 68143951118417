import React from "react"
import { Loading } from "components/shared/layout/main"

import styled, { css } from "styled-components"
import { Modal as BootstrapModal } from "react-bootstrap"

export const StyledModal = styled(BootstrapModal)`
  font-family: Source Sans Pro, sans-serif;
  display: flex !important;
  margin: auto;
  @media screen and (max-width: 991px) {
    margin-top: 50px;
    overflow-y: hidden !important;
  }
  .modal-dialog {
    max-width: ${props => props.dialogMaxWidth || "500px"};
    margin: auto;
    @media screen and (max-width: 991px) {
      max-width: ${props => props.mDialogMaxWidth || "500px"};
      width: 100%;
    }
  }
  .modal-content {
    box-shadow: none;
    border: none;
    @media screen and (max-width: 991px) {
      border-radius: 0px;
      height: 100vh; /* fallback if calc did not work */
      height: calc((var(--vh, 1vh) * 100) - 50px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  .modal-header {
    border-bottom: none;
    padding: 0;
    .close {
      position: absolute;
      right: 20px;
      z-index: 1;
      top: 15px;
      span {
        font-size: 1.4em;
        font-weight: 500;
      }
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: ${props => props.bodyPadding || "20px 30px"};
    ${props =>
      props.minHeight &&
      css`
        min-height: ${props.minHeight};
      `};
    @media screen and (max-width: 991px) {
      padding: ${props => props.mBodyPadding || "20px 25px"};
    }
  }
`

const Modal = ({
  className,
  showModal,
  backdrop,
  closeModal,
  closeButton = false,
  title,
  header,
  loadingOverlay,
  isLoading,
  children,
  ...rest
}) => {
  React.useEffect(() => {
    const resizeListener = () => {
      try {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
      } catch (err) {
        console.error(err)
      }
    }
    resizeListener()
    window.addEventListener("resize", resizeListener)
    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return (
    <StyledModal
      className={className}
      show={showModal}
      backdrop={backdrop}
      onHide={closeModal}
      autoFocus
      animation
      {...rest}>
      {loadingOverlay && isLoading && <Loading overlay />}
      <StyledModal.Header closeButton={closeButton}>
        {title && <h3>{title}</h3>}
        {header && header}
      </StyledModal.Header>
      <StyledModal.Body>{children}</StyledModal.Body>
    </StyledModal>
  )
}

export default Modal
