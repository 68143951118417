import { Icon } from '@rentspree/component-v2/dist/icon'
import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'

import ProgressBar from 'components/contacts/atoms/progress-bar'
import { WidgetTitle, WidgetSubTitle, TextButton } from 'components/contacts/atoms/widget'
import successIcon from 'images/contacts/sweetalert-success.svg'
import { COLOR } from 'styles/settings'

const DetailWrapper = styled.div`
  display: flex;
  justify-content: column;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
`

const SuccessIcon = styled.img`
  margin-right: 12px;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
`

const SuccessWidget = ({ current, onViewContact, percentage, onClose }) => (
  <>
    <HeaderWrapper>
      <CloseIcon icon="close" color={COLOR.textBlack} onClick={onClose} />
    </HeaderWrapper>
    <DetailWrapper>
      <SuccessIcon className="maximize" src={successIcon} alt="success-icon" />
      <div>
        <WidgetTitle>Imported Successfully</WidgetTitle>
        <WidgetSubTitle>
          {`${current} ${pluralize('contact', current)} ${pluralize('has', current)} been imported`}
        </WidgetSubTitle>
      </div>
    </DetailWrapper>
    <BottomWrapper>
      <ProgressBar color={COLOR.green} percent={percentage} />
      <TextButton onClick={onViewContact}>View</TextButton>
    </BottomWrapper>
  </>
)

export default SuccessWidget
