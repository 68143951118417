import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { GET_START_MODAL } from 'tracker/const'
import tracker from 'tracker'
import { GROUP_GET_STARTED, PROJECT } from './const'
import GetStartedModal from '../../components/modal/get-started-modal'
import { withGetStarted } from './connect'
export class GetStarted extends React.Component {
  constructor() {
    super()
    this.myRef = React.createRef()
    this.state = {
      position: 0,
      indexPosition: 0,
    }
  }

  onNext = data => () => {
    const { indexPosition: preIndexPosition } = this.state
    if (data.length - 1 > preIndexPosition) {
      const { eventConst } = data[preIndexPosition]
      if (eventConst) {
        tracker.trackEvent(eventConst.name)
      }
      this.setState({
        position: -((preIndexPosition + 1) * this.myRef.current.clientWidth),
        indexPosition: preIndexPosition + 1,
      })
    }
  }

  onSkip = data => () => {
    const {
      actions: { skipGetStartedModal },
    } = this.props
    const { indexPosition } = this.state
    const { eventConst } = data[indexPosition]
    if (eventConst) {
      if (data.length - 1 === indexPosition) {
        tracker.trackEvent(eventConst.name)
        tracker.trackEvent(GET_START_MODAL.VIEW_ALL.name)
      } else {
        tracker.trackEvent(GET_START_MODAL.SKIP.name, {
          feature: eventConst.property,
        })
      }
    }
    skipGetStartedModal()
  }

  onClickBatch = index => {
    this.setState({
      position: -(index * this.myRef.current.clientWidth),
      indexPosition: index,
    })
  }

  componentWillMount() {
    const { fetchGetStartedNotice } = this.props.actions
    fetchGetStartedNotice({ group: GROUP_GET_STARTED, project: PROJECT })
  }

  render() {
    const { sliders } = this.props
    if (isEmpty(sliders)) {
      return null
    }

    return (
      <GetStartedModal
        data={sliders}
        position={this.state.position}
        onNext={this.onNext(sliders)}
        myRef={this.myRef}
        indexPosition={this.state.indexPosition}
        onClickBatch={this.onClickBatch}
        skipGetStartedModal={this.onSkip(sliders)}
      />
    )
  }
}
export default withGetStarted(GetStarted)
