import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  sumArray,
  countClosed,
  pluralizeWordOnlyUnit,
} from 'legacy/components/reports-v2/share/function'
import {
  ACCOUNT_DESIGNATOR,
  ACCOUNT_RAILING_CODES,
  ACCOUNT_TYPE,
  INDUSTRY_CODES,
  REMARK_CODES,
} from 'legacy/constants/credit-report-code'
import { convert } from 'legacy/components/helper/convert'
import { Content, InformationPanel, Section, SubSection } from 'components/reports/screening-reports/share/responsive-doc-style'
import { SectionHeader, SubSectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import { REMOVE_HTML_TAG_FROM_STRING_REGEX } from 'components/reports/screening-reports/share/constants'
import {
  AccountHeader,
  CreditDetailContainer,
  CountBox,
  TotalCountBoxesContainer,
  CreditDetailInformationLine,
  BalanceDetailInformationLine,
} from './shared'

export const CollectionDetails = ({ collection, index }) => (
  <Content>
    <InformationPanel>
      <AccountHeader
        title={`${index + 1}. ${collection.collectionAgencyName}`}
        status={isEmpty(collection.dateClosed) ? 'Open' : 'Closed'}
        balance={`Balance: ${displayBalanceValue(collection.currentBalance)}`}
        detail={`Original Balance: ${displayBalanceValue(collection.highCredit)}`}
      />
      <CreditDetailContainer>
        <InformationPanel>
          <CreditDetailInformationLine
            label="Account Designator"
            value={
              ACCOUNT_DESIGNATOR[collection.accountDesignator] ||
              collection.accountDesignator ||
              '-'
            }
          />
          <CreditDetailInformationLine
            label="Industry"
            value={INDUSTRY_CODES[collection.industryCode] || collection.industryCode || '-'}
          />
          <CreditDetailInformationLine
            label="Credit Grantor"
            value={collection.creditorsName || '-'}
          />
          <CreditDetailInformationLine
            label="Loan Type/Term"
            value={ACCOUNT_TYPE[collection.accountType] || collection.accountType || '-'}
          />
          <CreditDetailInformationLine label="Past Due" value={collection.pastDue || '-'} />
        </InformationPanel>
        <InformationPanel>
          <CreditDetailInformationLine
            label="Status"
            value={ACCOUNT_RAILING_CODES[collection.currentMOP] || collection.currentMOP || '-'}
          />
          <CreditDetailInformationLine
            label="Remarks"
            value={REMARK_CODES[collection.remarksCode] || collection.remarksCode || '-'}
          />
          <CreditDetailInformationLine
            label="Opened Date"
            value={convert(collection.dateOpened, 'date', 'MMM, YYYY') || '-'}
          />
          <CreditDetailInformationLine
            label="Verified Date"
            value={convert(collection.dateVerified, 'date', 'MMM, YYYY') || '-'}
          />
          <CreditDetailInformationLine
            label="Comments"
            value={
              collection.collectionComments
                ? collection.collectionComments.replace(REMOVE_HTML_TAG_FROM_STRING_REGEX, '')
                : '-'
            }
          />
        </InformationPanel>
      </CreditDetailContainer>
    </InformationPanel>
  </Content>
)
const displayBalanceValue = value => {
  if (typeof value === 'undefined' || value === null) return 'N/A'
  return convert(value, '$')
}

const Collection = ({ collections }) => {
  const closedCounted = countClosed(collections)
  const openCounted = collections ? collections.length - countClosed(collections) : 0
  return (
    <>
      <Section>
        <SectionHeader title="Collections" />
        <SubSection>
          <SubSectionHeader title="Collection Total" />
          <CreditDetailContainer>
            <TotalCountBoxesContainer flex noMargin>
              <CountBox
                header={`Open ${pluralizeWordOnlyUnit('Account', openCounted)}`}
                score={`${openCounted}`}
                isActive
              />
              <CountBox
                header={`Closed ${pluralizeWordOnlyUnit('Account', closedCounted)}`}
                score={`${closedCounted}`}
              />
            </TotalCountBoxesContainer>
            <InformationPanel>
              <BalanceDetailInformationLine
                label="Balance Total"
                value={convert(sumArray(collections, 'currentBalance'), '$')}
                labelWidth="calc(100% - 80px)"
                mobileGap="10px"
              />
            </InformationPanel>
          </CreditDetailContainer>
        </SubSection>
      </Section>
      <Section>
        <SubSection>
          <SubSectionHeader title="Collections" />
          {collections.map((collection, index) => (
            <CollectionDetails
              collection={collection}
              index={index}
              key={`${get(collection, 'accountNumber', '')}-${get(collection, 'customerNumber')}-${
                index + 1
              }`}
            />
          ))}
        </SubSection>
      </Section>
    </>
  )
}

export default Collection
