import React from "react"
import isEqual from "lodash/isEqual"
import isNaN from "lodash/isNaN"
import styled from "styled-components"
import { breakpoints } from "styles/settings"
import scoreGraphImg from "../../../images/icons/score-guage.svg"
import scoreGraphGray from "../../../images/icons/score-guage-grey.svg"

const COLOR_SCORE = {
  TRANSPARENT: "rgba(255,255,255,0)",
  GRAY: "#ffffff",
  RED: "#f56161",
  ORANGE: "#eeae5f",
  YELLOW: "#fad323",
  GREEN: "#48db93",
}
const SCORE_GRAPH = {
  0: {
    color: COLOR_SCORE.TRANSPARENT,
    status: "No Score",
    img: scoreGraphGray,
  },
  50: {
    color: COLOR_SCORE.RED,
    status: "Decline",
  },
  65: {
    color: COLOR_SCORE.ORANGE,
    status: "Conditional",
  },
  75: {
    color: COLOR_SCORE.YELLOW,
    status: "Low Accept",
  },
  100: {
    color: COLOR_SCORE.GREEN,
    status: "Accept",
  },
}
const scoreGraph = rotate => {
  if (rotate < 0 || isNaN(rotate)) {
    return SCORE_GRAPH["0"]
  }
  if (rotate <= 50) {
    return SCORE_GRAPH["50"]
  }
  if (rotate <= 65) {
    return SCORE_GRAPH["65"]
  }
  if (rotate <= 75) {
    return SCORE_GRAPH["75"]
  }
  return SCORE_GRAPH["100"]
}

const calculateRotation = (score, min, max) =>
  score > 0 && score <= min ? 0 : ((score - min) / (max - min)) * 180

export const Cat = styled.div`
  padding: 0px;
  height: ${props => (props.model ? "150px" : "130px")};
  margin: 5px 0px;
  padding-left: 20px;
  font-family: "Source Sans Pro";
  margin-right: 40px;
  margin-bottom: 0;
  ${props =>
    props.CoverPage &&
    `
    margin: 0px;
    padding: 0px;
  `}

  ${breakpoints.mobileReport(`
    padding: unset;
    margin: 0 auto;
  `)}

  @media print {
    padding-left: 20px;
    margin: 5px 0px;
    margin-right: 40px;
    margin-bottom: 0;
  }
`
export const Circle = styled.div`
  height: 16px;
  width: 16px;
  background-color: ${props =>
    isEqual(props.color, COLOR_SCORE.TRANSPARENT)
      ? props.color
      : "white"} !important;
  border: 4px solid ${props => props.color};
  border-radius: 50%;
  transition: border 1s;
`

export const PieWrapper = styled.div`
  position: relative;
  width: 180px;
  height: 90px;
`
export const Arc = styled.div`
  transform: rotate(${props => (props.rotate ? props.rotate : "0")}deg);
  transition: all 1s;
  bottom: -10px;
  width: 189px;
  height: 16px;
  position: absolute;
  left: -5px;
  border-top: none;
  border-radius: 0 0 300px 300px;
  z-index: 2;
`
export const ScoreGraphImage = styled.img`
  margin-left: 0px;
  margin-top: 3px;
`

export const Text = styled.span`
  position: absolute;
  z-index: 4;
  left: 0px;
  text-align: center;
  width: 100%;
  line-height: 25px;
  bottom: ${props =>
    props.isNoScore || props.summaryMode ? "-25px" : "-10px"};
`
export const Score = styled.span`
  color: #4a4a4a;
  font-size: 54px;
  font-weight: bold;
  text-align: center;
`

export const StartScore = styled.div`
  position: absolute;
  z-index: 4;
  left: -5px;
  font-size: 12px;
  font-weight: 600;
  bottom: -26px;
`
export const EndScore = styled.div`
  position: absolute;
  z-index: 4;
  font-size: 12px;
  font-weight: 600;
  right: -7px;
  bottom: -26px;
`

export const Rank = styled.span`
  font-size: 16px;
  font-weight: 600;
  display: block;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #4a4a4a;
`
export const Model = styled.span`
  font-size: 14px;
  font-family: "Source Sans Pro";
  word-break: break-word;
  word-wrap: break-word;
  position: absolute;
  z-index: 4;
  left: 0px;
  text-align: center;
  width: 100%;
  line-height: 25px;
  bottom: -55px;
`
export const CreditReportText = styled.span`
  width: 100%;
  font-size: 20px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  bottom: 25px;
  position: absolute;
  left: 0;
  text-align: center;
`

export const SemiCircle = ({
  score,
  min,
  max,
  model,
  summaryMode,
  CoverPage,
}) => {
  const rotate = calculateRotation(score, min, max)
  const s = scoreGraph(rotate, score)
  const isNoScore =
    parseFloat(score) === -1 || parseFloat(score) === 0 || !score
  return (
    <Cat model={model} CoverPage={CoverPage}>
      <PieWrapper>
        <ScoreGraphImage
          src={s.img ? s.img : scoreGraphImg}
          alt="Score graph"
        />
        <Arc rotate={rotate}>
          <Circle color={s.color} />
        </Arc>
        {!isNoScore && summaryMode && (
          <CreditReportText isNoScore={isNoScore}>
            Credit Score
          </CreditReportText>
        )}
        <Text isNoScore={isNoScore} summaryMode={summaryMode}>
          <Score>{isNoScore ? "N/A" : score}</Score>
          {isNoScore && <Rank>No Score</Rank>}
        </Text>
        {model && <Model>{model}</Model>}
        <StartScore>{min}</StartScore>
        <EndScore>{max}</EndScore>
      </PieWrapper>
    </Cat>
  )
}
