import { buildPath } from "@rentspree/path"
import { all, call, put, takeLatest } from "redux-saga/effects"
import tracker from "tracker"

import { apiInstanceWithErrorHandler } from "utils/api-interceptor"
import { openSweetAlertBaseError } from "utils/sweet-alert-actions"
import {
  EVENT_ACTION_DROPDOWN_CLICK_FROM,
  ARCHIVE_PROPERTY,
} from "tracker/const"
import {
  archivePropertyApiCall,
  unarchivePropertyApiCall,
  clearArchiveModal,
} from "./actions"
import {
  ARCHIVE_PROPERTY_CALL,
  UNARCHIVE_PROPERTY_CALL,
  ARCHIVE_PROPERTY_API,
} from "./constants"

const { archiveListing, unarchiveListing } = ARCHIVE_PROPERTY
const { PROPERTY_LIST } = EVENT_ACTION_DROPDOWN_CLICK_FROM

export const archivePropertyAPI = propertyId =>
  apiInstanceWithErrorHandler.post(
    buildPath(ARCHIVE_PROPERTY_API, { propertyId }),
  )

export const unarchivePropertyAPI = propertyId =>
  apiInstanceWithErrorHandler.delete(
    buildPath(ARCHIVE_PROPERTY_API, { propertyId }),
  )

export function* archivePropertySaga({ payload }) {
  yield put(archivePropertyApiCall.request())
  try {
    yield call([tracker, "trackEvent"], archiveListing, {
      click_from: PROPERTY_LIST,
    })
    const response = yield call(archivePropertyAPI, payload)
    yield put(archivePropertyApiCall.success(response))
    yield put(clearArchiveModal())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(archivePropertyApiCall.failure(err))
  }
}

export function* unarchivePropertySaga({ payload }) {
  yield put(unarchivePropertyApiCall.request())
  try {
    yield call([tracker, "trackEvent"], unarchiveListing, {
      click_from: PROPERTY_LIST,
    })
    const response = yield call(unarchivePropertyAPI, payload)
    yield put(unarchivePropertyApiCall.success(response))
    yield put(clearArchiveModal())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(unarchivePropertyApiCall.failure(err))
  }
}

export function* watchArchiveProperty() {
  yield takeLatest(ARCHIVE_PROPERTY_CALL, archivePropertySaga)
}

export function* watchUnarchiveProperty() {
  yield takeLatest(UNARCHIVE_PROPERTY_CALL, unarchivePropertySaga)
}

export const watchers = [watchArchiveProperty(), watchUnarchiveProperty()]

export default function* rootSaga() {
  yield all(watchers)
}
