export const PRICING_SCHEME = {
  application: {
    title: 'application',
    text: 'Standard Rental Application',
    subtext: '',
  },
  car_lra: {
    title: 'car_lra_application',
    text: 'C.A.R. Rental Application (LRA)',
    subtext: 'Exclusive for C.A.R. members',
  },
  no_application: {
    title: 'no_application',
    text: 'No application',
  },
  credit_report: {
    title: 'credit_report',
    text: 'Credit Report & Score*',
    subtext: 'Includes Applicant Identity Verification',
  },
  criminal: {
    title: 'criminal',
    text: 'Criminal Background Check*',
    subtext: '',
  },
  eviction: {
    title: 'eviction',
    text: 'Eviction Related Proceedings*',
    subtext: '',
  }
}

export const ALERT_WORDING = {
  ALL_PROPERTY: `1. NY State: Application fees are capped at $20.
  2. MA State: Application fees are paid by landlord, not applicants.
  3. NY State and Miami-Dade County, FL: Eviction related proceedings unavailable.
  4. NJ State and Cook County, IL: Criminal background checks unavailable.`,
  HEADER: 'Local Regulation Compliance',
  ALL_PROPERTY_WITH_HEADER: `Local Regulation Compliance:
  1. NY State: Application fees are capped at $20.
  2. MA State: Application fees are paid by landlord, not applicants.
  3. NY State and Miami-Dade County, FL: Eviction related proceedings unavailable.
  4. NJ State and Cook County, IL: Criminal background checks unavailable.
  `,
  IL: "In compliance with local regulations, Criminal Background Reports won't be generated for rentals in Cook County, IL.",
  NY: 'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. Please contact an experienced attorney with any questions about the screening process.',
  NJ: 'Due to a recent regulation change regarding background checks, TransUnion is no longer providing criminal records for properties located in New Jersey.',
  CAA: 'A recent change in California regulations places some new limitations on landlords’ ability to use criminal background reports. Landlords should consult with their fair housing attorney before ordering or using a criminal background report. Please also note that CAA is working with RentSpree on developing new options for RentSpree’s platform in light of the new regulations.',
  MIAMI_DADE:
    "In compliance with local regulations, eviction related proceedings won't be generated for rentals in Miami-Dade County, FL.",
  MD: 'In compliance with local regulations, Criminal Background Checks will not be generated for rentals in Montgomery, MD',
  DC: 'In compliance with local regulations, Criminal Background Checks will not be generated for rentals in Washington D.C',
}

export const SCREENING_METHODS = {
  EMAIL: 'email',
  LINK: 'link',
  FLYER: 'flyer',
}

export const ALERT_PAYER_TYPE = {
  MA: 'In compliance with Massachusetts State regulations, application fees are paid by landlord, not applicants.',
}
