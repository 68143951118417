import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { getSub } from "legacy/reducers/subdomain.reducer"
import { openArchiveModal } from "containers/property/toggle-archive-property/actions"
import {
  toggleDeactivatePropertyModal,
  toggleReactivatePropertyModal,
} from "legacy/actions/modals.action"
import injectReducer from "utils/inject-reducer"
import reducer from "./reducer"
import { getPropertyList, clearPropertyList } from "./actions"
import { makeSelectList, makeSelectError } from "./selectors"

const mapStateToProps = createStructuredSelector({
  propertyList: makeSelectList(),
  error: makeSelectError(),
  // ToDo: use selector
  subdomain: ({ subdomain }) => getSub(subdomain),
  modals: ({ modals }) => modals,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPropertyList,
      clearPropertyList,
      openArchiveModal,
      toggleDeactivatePropertyModal,
      toggleReactivatePropertyModal,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "propertyList", reducer })
