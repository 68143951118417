import React from 'react'
import get from 'lodash/get'

import { INDUSTRY_CODES } from 'legacy/constants/credit-report-code'
import { COLOR } from 'styles/settings'
import styled from 'styled-components'
import { convert } from 'legacy/components/helper/convert'
import {
  Content,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  SectionHeader,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import { TableCell, TableHeader, TableRow } from './shared'

const TableContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const InquiriesTable = ({ inquiries = [] }) => {
  const headers = [
    { title: 'Date', styles: { minWidth: '90px', width: '20%' } },
    { title: 'Name', styles: { width: '25%' } },
    { title: 'Type of Business', styles: { width: '55%' } },
  ]
  return (
    <TableContainer>
      <TableRow borderBottom={`0.5px solid ${COLOR.borderGray}`}>
        {headers.map(header => (
          <TableHeader
            key={`inquiries-${header.title}`}
            minWidth={header.styles.minWidth}
            width={header.styles.width}
          >
            {header.title}
          </TableHeader>
        ))}
      </TableRow>
      {inquiries.map((inquiry, i) => {
        const industryCode = get(inquiry, 'industryCode')
        const subscriberName = get(inquiry, 'subscriberName')
        const inquiryDate = get(inquiry, 'inquiryDate')
        return (
          <TableRow
            key={`${i.toString()}-${get(inquiry, 'subscriberId')}`}
            borderBottom={`0.5px solid ${COLOR.borderGray}`}
          >
            <TableCell id={`inquiry${i}-date`} width="20%" minWidth="90px">
              {convert(inquiryDate, 'date')}
            </TableCell>
            <TableCell id={`inquiry${i}-name`} alignRight={false} width="25%">
              {convert(subscriberName)}
            </TableCell>
            <TableCell id={`inquiry${i}-industry`} ailgnRight={false} width="55%">
              {convert(INDUSTRY_CODES[industryCode] || industryCode)}
            </TableCell>
          </TableRow>
        )
      })}
    </TableContainer>
  )
}

export const Inquiries = ({ inquiries }) => (
  <Section>
    <SubSection>
      <SectionHeader title="Inquiries" />
      <SubSectionHeader title="Inquiries" />
      <Content>
        <InquiriesTable inquiries={inquiries} />
      </Content>
    </SubSection>
  </Section>
)

export default Inquiries
