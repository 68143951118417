import produce from "immer"
import { TYPES } from "./const"

export const initialState = {
  getStartSlider: [],
  isFetched: false,
}

/* eslint-disable no-param-reassign */
const getStartedReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case TYPES.FETCH_GET_STARTED_SUCCESS:
        draft = {
          ...draft,
          getStartSlider: payload,
          isFetched: true,
        }
        break
      case TYPES.FETCH_GET_STARTED_FAILED:
        draft = {
          ...draft,
          isFetched: true,
        }
        break
      case TYPES.SKIP_GET_STARTED_MODAL:
        draft = {
          ...draft,
          getStartSlider: [],
        }
        break
      default:
        break
    }
    return draft
  })

export default getStartedReducer
