import React from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { Tr, Th, Td, ColumnsCenter, DefaultTable } from 'components/table/table-style'
import { TextWithIcon } from 'components/molecules/text-with-icon'
import { IconWithNameDesc } from 'components/molecules/icon-with-name-desc'

export const TableContent = ({ columns, list = [], onClickRow, rowRenderMobile }) => (
  <>
    {list.map(item => {
      const {
        _id: itemId,
        greyRow,
        whiteRowForMobile,
        iaDesign,
        fontWeight,
        hightLightMobile,
        bgColor = '',
      } = item

      const generatedPropsMobile = {}
      Object.keys(rowRenderMobile.keys).map(key => {
        generatedPropsMobile[key] = item[rowRenderMobile.keys[key]]
        return null
      })

      return (
        <Tr
          className={item.className}
          greyRow={greyRow}
          whiteRowForMobile={whiteRowForMobile}
          iaDesign={iaDesign}
          fontWeight={fontWeight}
          key={itemId}
          onClick={() => onClickRow(item)}
          bgColor={bgColor}
        >
          <Td hightLightMobile={hightLightMobile} />
          {columns.map(column => {
            const isBlank = column.key === 'blank'
            if (isBlank) {
              return (
                <Td className={column.className} width={column.width}>
                  <ColumnsCenter itemAlign={column.itemAlign} />
                </Td>
              )
            }

            const value = get(item, column.key, column.defaultValue || '')
            if (!isNil(value)) {
              if (column.render) {
                return (
                  <Td className={column.className} width={column.width}>
                    <ColumnsCenter itemAlign={column.itemAlign}>
                      {column.render(value, item)}
                    </ColumnsCenter>
                  </Td>
                )
              }
              return (
                <Td className={column.className} width={column.width}>
                  <ColumnsCenter className={column.className} itemAlign={column.itemAlign}>
                    {value}
                  </ColumnsCenter>
                </Td>
              )
            }

            return <Td />
          })}
          {/* Mobile Row */}
          {!rowRenderMobile.render ? (
            <Td colSpan={30} className="hidden-lg hidden-md">
              <IconWithNameDesc icon={rowRenderMobile.icon} {...generatedPropsMobile} />
            </Td>
          ) : (
            <Td colSpan={30} className="hidden-lg hidden-md">
              {rowRenderMobile.render(item)}
            </Td>
          )}
          <Td />
        </Tr>
      )
    })}
  </>
)

export const RentSpreeTableKung = ({
  className,
  columns,
  list = [],
  id,
  rowRenderMobile,
  limitData = 0,
  onClickRow = () => {},
}) => {
  const [isShowMore, setShowMore] = React.useState(false)
  const hasShowMore = limitData > 0
  const limitLength = limitData === 0 || isShowMore ? list.length : limitData
  const newList = list.map((i, idx) => {
    if (idx + 1 > limitLength) {
      return { ...i, className: 'hideRow' }
    }
    return i
  })
  const showMoreWording = isShowMore
    ? `Show less transaction`
    : `Show all transactions (${list.length - limitLength})`
  const isFlip = isShowMore ? 'flip' : ''

  return (
    <DefaultTable id={id} className={className}>
      <thead className={className}>
        <Tr className="hidden-xs hidden-sm">
          <Th />
          {columns.map(item => (
            <Th key={`${item.key}-header`} className={`${className} ${item.className}`}>
              {item.title}
            </Th>
          ))}
          <Th />
        </Tr>
      </thead>
      <tbody className={className}>
        <TableContent
          columns={columns}
          list={newList}
          onClickRow={onClickRow}
          rowRenderMobile={rowRenderMobile}
        />
        {hasShowMore && (
          <Tr
            onClick={() => {
              setShowMore(!isShowMore)
            }}
          >
            <Td />
            <Td colSpan="100">
              <ColumnsCenter itemAlign="center">
                <TextWithIcon
                  reverseRow
                  className={isFlip}
                  text={showMoreWording}
                  icon="chevron-down"
                />
              </ColumnsCenter>
            </Td>
            <Td className="hidden-xs hidden-sm hidden-md hidden-lg" />
          </Tr>
        )}
      </tbody>
    </DefaultTable>
  )
}

export default RentSpreeTableKung
