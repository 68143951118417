import createAction from "legacy/apis/utils/create-action"
import * as api from "../apis/announcement.api"
import * as Types from "../constants/action-types"

// TODO: refactor to saga logic
const getUnSeenFeature = createAction(
  api.getUnSeenFeature,
  [
    Types.GET_UNSEEN_VIEW_MODAL_REQUEST,
    Types.GET_UNSEEN_VIEW_MODAL_SUCCESS,
    Types.GET_UNSEEN_VIEW_MODAL_FAILED,
  ],
  (res = {}) => ({ data: res }),
)

const updateSeenFeature = createAction(
  api.updateSeenFeature,
  [
    Types.GET_UNSEEN_VIEW_MODAL_REQUEST,
    Types.GET_UNSEEN_VIEW_MODAL_SUCCESS,
    Types.GET_UNSEEN_VIEW_MODAL_FAILED,
  ],
  (res = {}) => ({ data: res }),
)

export const getUnSeenViewModals = group => (dispatch, getState) =>
  getUnSeenFeature(group)(dispatch, getState).then(({ data }) => {
    if (data && data?.length > 0) {
      dispatch({ type: Types.SHOW_POST_LOG_IN_ADVERTISE_MODAL, isShow: !!data })
    }
    return data
  })

export const updateSeenViewModals = groups => (dispatch, getState) =>
  updateSeenFeature(groups)(dispatch, getState)

export const togglePostLogInAdvertiseChecked = checked => ({
  type: Types.NOT_SHOW_POST_LOG_IN_ADVERTISE_CHECKED,
  checked,
})
