import React from "react"
import { ModalDefault, ModalActions, ModalHeader } from "./modal-default"
import { Button } from "../buttons/buttons"
import { H4, TextDiv } from "../typography"
import { Span, Small } from "../typography/text-style"

const DeactivatePropertyModal = props => {
  const { closeModal, confirmDeactivateProperty, propertyId } = props
  return (
    <ModalDefault
      {...props}
      header={
        <ModalHeader>
          <H4 size="20px" strong align="center">
            Confirmation
          </H4>
        </ModalHeader>
      }
      className="rentspreeModal smallModal">
      <TextDiv mt={-15} mb={30} size="14px" align="center">
        <Span m0>Are you sure you want to deactivate this property?</Span>
        <br />
        <Span m0>
          All ApplyLink&trade; associated with it will be deactivated so
          applicants will no longer be able to apply.
        </Span>
      </TextDiv>
      <ModalActions>
        <Small size="14px" strong>
          You can reactivate this property anytime.
        </Small>
        <Button
          default
          id="cancelDeactivatePropertyBtn"
          text="Cancel"
          minWidth="90"
          onClick={closeModal}
        />
        <Button
          redBtn
          id="confirmDeactivatePropertyBtn"
          text="Deactivate"
          minWidth="120"
          onClick={() => confirmDeactivateProperty(propertyId)}
        />
      </ModalActions>
    </ModalDefault>
  )
}

export default DeactivatePropertyModal
