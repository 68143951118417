/* eslint-disable no-underscore-dangle */
/* eslint react/no-array-index-key: "off" */

import React from 'react'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames/bind'
import { CenterContent } from 'components/layout/main'
import styled from 'styled-components'
import { COLOR } from 'components/constants'
import {
  FullReportDetailBody,
  FullReportDetailSection,
  FullReportApplicantSection,
  InfoContentSpace,
  CompanyInfoFooter,
} from './share/doc-components'
import { InfoList, InfoItem, InfoHead } from './share/info-table'

import generateImageProfile from '../helper/generate-image-profile'
import { totalMonthlyIncome } from '../helper/total-monthly-income'
import { convertTypeResidence } from '../helper/convert'

import { AdditionalInformationList } from './share/additional-information'

import { ApplicationNotAvailable } from '.'
import style from './share/doc-components.scss'

const c = classNames.bind(style)
const logo = require('../../images/logos/rentspree-logo-monotone.svg')
const iconPet = require('../../images/icons/report/pets.svg')

const NoteWrapper = styled.div`
  background-color: ${props => (props.status === 'refuted' ? COLOR.lightSoftRed : COLOR.bgGrey)};
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 5px;
  padding: 5px 20px;
  h5 {
    font-weight: 600;
    color: ${props => (props.status === 'refuted' ? COLOR.negativeRed : COLOR.textBlack)};
  }
  ul {
    list-style: inherit;
    padding: 0 20px;
    li {
      color: ${props => (props.status === 'refuted' ? COLOR.negativeRed : COLOR.textBlack)};
    }
  }
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
`

const QuestionsWrapper = styled.div`
  border-style: solid;
  border-color: ${COLOR.newGrey};
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 5px 20px;
`

const StyledTable = styled.table`
  width: 100%;
  margin-left: 5px;
  tr {
    height: 30px;
  }
`

export const Notes = ({ note }) =>
  note && !isEmpty(note.questions) && note.status === 'refuted' ? (
    <div className={c('note-wrapper')}>
      <h5>{note.title}</h5>
      <ul>{note.questions.map(q => q.reason && <li key={q.id}>{q.reason}</li>)}</ul>
    </div>
  ) : null

export const ResidenceItemNotes = ({ note }) => {
  const { questions } = note
  const comment = questions.find(q => q.type === 'optional_comment' || q.type === 'custom_reason')

  return comment?.reason ? (
    <NoteWrapper status={note.status}>
      <h5>{note.title}</h5>
      <ul>
        <li key={comment.id}>{comment.reason}</li>
      </ul>
    </NoteWrapper>
  ) : null
}

export const Questions = ({ questions }) => (
  <QuestionsWrapper>
    <StyledTable>
      {questions
        .filter(q => q.type !== 'optional_comment')
        .map((q, idx) => (
          <tr>
            <td>{`${idx + 1}. ${q.text}`}</td>
            <td>{q.answer ? q.answer.charAt(0).toUpperCase() + q.answer.slice(1) : ''}</td>
          </tr>
        ))}
    </StyledTable>
  </QuestionsWrapper>
)

export const ResidenceItem = ({ data, title, isHideLine = false, note, converter }) =>
  !data ? null : (
    <div className={c('residence-item')}>
      {!isHideLine && <hr />}
      <ResidenceItemNotes note={note} />
      <Questions questions={note.questions} />
      <InfoHead title={title}>
        <InfoList>
          <InfoItem isHideLine>
            <InfoContentSpace title="Type" text={convertTypeResidence(data.type)} />
            {data.type === 'rented' && (
              <InfoContentSpace title="Monthly Rent" text={data.monthlyRent} type="$" />
            )}
          </InfoItem>

          <InfoItem isHideLine>
            <InfoContentSpace title="Address" text={data.address} />
          </InfoItem>

          <InfoItem isHideLine>
            <InfoContentSpace title="Move-in" text={data.moveInDate} type="date" format="MM/YYYY" />
          </InfoItem>

          {data.type === 'rented' && (
            <div>
              <InfoItem isHideLine>
                <InfoContentSpace title="Landlord" text={data.landlordName} />
                <InfoContentSpace
                  title="Landlord's Phone"
                  text={data.landlordPhone}
                  converter={converter}
                  type="phoneNumber"
                />
              </InfoItem>

              <InfoItem isHideLine>
                <InfoContentSpace title=" Landlord's Email" text={data.landlordEmail} />
              </InfoItem>
            </div>
          )}

          <InfoItem isHideLine>
            <InfoContentSpace title="Reason for leaving" text={data.reasonForLeaving || '-'} />
          </InfoItem>
        </InfoList>
      </InfoHead>
    </div>
  )

export const OccupationItem = ({ data, title, isHideLine, note, converter }) =>
  !data ? null : (
    <div>
      {!isHideLine && <hr />}
      <Notes note={note} />
      <InfoHead title={title}>
        {data.type === 'employed' && (
          <InfoList>
            <InfoItem isHideLine>
              <InfoContentSpace title="Status" text={data.type} type="firstUppercase" />
              <InfoContentSpace title="Employer" text={data.employer} />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="Job Title" text={data.jobTitle} />
              {data.workType && <InfoContentSpace title="Job Type" text={data.workType} />}
              <InfoContentSpace title="Monthly Salary" text={data.monthlySalary} type="$" />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="Supervisor" text={data.supervisorName} />
              <InfoContentSpace
                title="Supervisor's Phone"
                text={data.supervisorPhone}
                type="phoneNumber"
                converter={converter}
              />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="Supervisor's Email" text={data.supervisorEmail} />
              <InfoContentSpace title="Work Address" text={data.workAddress || '-'} />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace
                title="Start Date"
                text={data.startDate}
                type="date"
                format="MM/YYYY"
              />
              {data.endDate && (
                <InfoContentSpace
                  title="End Date"
                  text={data.endDate}
                  type="date"
                  format="MM/YYYY"
                />
              )}
            </InfoItem>
          </InfoList>
        )}

        {data.type === 'student' && (
          <InfoList>
            <InfoItem isHideLine>
              <InfoContentSpace title="Status" text={data.type} type="firstUppercase" />
              <InfoContentSpace title="School" text={data.school} />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="Program" text={data.program} />
              <InfoContentSpace
                title="Program Start Date"
                text={data.programStartDate}
                type="date"
                format="MM/YYYY"
              />
              <InfoContentSpace
                title="Program End Date"
                text={data.programEndDate}
                type="date"
                format="MM/YYYY"
              />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="Degree Type" text={data.programType} />
              <InfoContentSpace
                title="School Phone Number"
                text={data.schoolPhoneNumber}
                type="phoneNumber"
                converter={converter}
              />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="School Address" text={data.schoolAddress} />
            </InfoItem>
          </InfoList>
        )}

        {data.type === 'unemployed' && (
          <InfoList>
            <InfoItem isHideLine>
              <InfoContentSpace title="Status" text={data.type} type="firstUppercase" />
              <InfoContentSpace title="Income Source" text={data.incomeSource} />
            </InfoItem>
            <InfoItem isHideLine>
              <InfoContentSpace title="Amount" text={data.monthlySalary} type="$/Mo" />
            </InfoItem>
          </InfoList>
        )}
      </InfoHead>
    </div>
  )

export const CoApplicant = ({ title, data = {} }) => (
  <InfoHead title={title} classNames="new-page">
    <InfoItem isHideLine>
      <InfoContentSpace title="First Name" text={data.firstName} />
      <InfoContentSpace title="Last Name" text={data.lastName} />
    </InfoItem>
    <InfoItem isHideLine>
      <InfoContentSpace title="Date of Birth" text={data.dateOfBirth} type="dateOfUTC" />
      <InfoContentSpace title="Email" text={data.email} />
    </InfoItem>
  </InfoHead>
)

export const Professional = ({ data, title, note, converter }) =>
  !data ? null : (
    <InfoHead title={title}>
      <Notes note={note} />
      <InfoList>
        <InfoItem isHideLine>
          <InfoContentSpace title="Name" text={data.fullName} type="firstUppercase" />
          <InfoContentSpace title="Relationship" text={data.relationship} />
          <InfoContentSpace
            title="Phone Number"
            text={data.phoneNumber}
            type="phoneNumber"
            converter={converter}
          />
        </InfoItem>

        <InfoItem isHideLine>
          <InfoContentSpace title="Address" text={data.address} type="firstUppercase" />
        </InfoItem>
      </InfoList>
    </InfoHead>
  )

export const Personal = ({ data, title, note, converter }) =>
  !data ? null : (
    <InfoHead title={title}>
      <Notes note={note} />
      <InfoList>
        <InfoItem isHideLine>
          <InfoContentSpace title="Name" text={data.fullName} type="firstUppercase" />
          {data.relationship && <InfoContentSpace title="Relationship" text={data.relationship} />}
          <InfoContentSpace
            title="Phone Number"
            text={data.phoneNumber}
            type="phoneNumber"
            converter={converter}
          />
        </InfoItem>
        <InfoItem isHideLine>
          {data.email && <InfoContentSpace title="Email" text={data.email} />}
          {data.occupation && <InfoContentSpace title="Occupation" text={data.occupation} />}
        </InfoItem>
        {data.acquaintanceLength && (
          <InfoItem isHideLine>
            <InfoContentSpace
              title="Length of acquaintance"
              text={`${data.acquaintanceLength} years`}
            />
          </InfoItem>
        )}
        <InfoItem isHideLine>
          <InfoContentSpace
            title="Address"
            text={`${data.city ? data.address : '-'}`}
            type="firstUppercase"
          />
        </InfoItem>
      </InfoList>
    </InfoHead>
  )

const ApplicationReport = ({ application, rentalDetail, download, onPrint, isAvailable }) => {
  const { aboutMe, residence, occupation, additionalInformation } = application
  const references = application.references || {}
  const personal = references.personal || {}
  const nearestRelative = references.nearestRelative || {}

  if (!isAvailable) {
    return (
      <CenterContent withBreadcrumb withReportTabs withReportPadding noMargin>
        <ApplicationNotAvailable propertyId={rentalDetail.property._id} />
      </CenterContent>
    )
  }
  if (isEmpty(application)) {
    return <CenterContent withBreadcrumb withReportTabs withReportPadding noMargin />
  }
  return (
    <FullReportDetailBody
      docClassName="application-report"
      logo={logo}
      title="Rental Application"
      download={download}
      onPrint={onPrint}
      requestedDate={application.created_at}
    >
      <FullReportApplicantSection
        profileImg={generateImageProfile(aboutMe.email)}
        email={aboutMe.email}
        birthDate={aboutMe.dateOfBirth}
        name={`${aboutMe.firstName} ${aboutMe.lastName}`}
        employments={[]}
        address={[]}
      />

      <FullReportDetailSection title="APPLICANT INFO" icon="icon-user">
        <InfoList title="text">
          <InfoItem>
            <InfoContentSpace
              title="Move-in Date"
              text={rentalDetail.moveDate}
              format="MM/DD/YYYY"
              type="dateOfUTC"
            />
            <InfoContentSpace title="Driver License No." text={aboutMe.driverLicenseNo || '-'} />
          </InfoItem>
          <InfoItem isHideLine>
            <InfoContentSpace title="Email" text={aboutMe.email} />
            <InfoContentSpace title="Phone" text={aboutMe.phoneNumber} type="phoneNumber" />
          </InfoItem>
          <InfoItem isHideLine>
            <InfoContentSpace title="About Me" text={rentalDetail.introduction || '-'} />
          </InfoItem>
        </InfoList>
      </FullReportDetailSection>

      {(!isEmpty(rentalDetail.occupants) || !isEmpty(rentalDetail.coSigners)) && (
        <FullReportDetailSection title="OTHER OCCUPANT(S) / CO-SIGNER(S)" icon="icon-user-follow">
          <hr />
          <InfoList title="text">
            {rentalDetail.occupants &&
              rentalDetail.occupants.map((occupant, index) => (
                <CoApplicant title={`Other Occupant #${index + 1}`} data={occupant} key={index} />
              ))}
            {rentalDetail.coSigners &&
              rentalDetail.coSigners.map((coSigner, index) => (
                <CoApplicant title={`Co-Signer #${index + 1}`} data={coSigner} key={index} />
              ))}
          </InfoList>
        </FullReportDetailSection>
      )}

      <FullReportDetailSection title="PETS" img={iconPet}>
        <InfoList title="text">
          <InfoItem>
            <InfoContentSpace title="Dog(s)" text={additionalInformation.dogs} />
            <InfoContentSpace title="Cat(s)" text={additionalInformation.cats} />
            <InfoContentSpace title="Other Pets" text={additionalInformation.otherPets} />
          </InfoItem>
        </InfoList>
      </FullReportDetailSection>

      <FullReportDetailSection title="RESIDENCE HISTORY" icon="icon-home" isNewPage>
        <InfoList title="text">
          <ResidenceItem title="Current Residence" data={residence.current} />

          <ResidenceItem title="Previous Residence" data={residence.previous} />
        </InfoList>
      </FullReportDetailSection>

      <FullReportDetailSection
        title="EMPLOYMENT AND INCOME HISTORY"
        icon="icon-briefcase"
        isNewPage
      >
        <InfoList title="text">
          <OccupationItem title="Current Occupation" data={occupation.current} />

          <OccupationItem title="Previous Occupation" data={occupation.previous} />

          <hr />
          <InfoHead title="Additional Income">
            <InfoItem isHideLine>
              <InfoContentSpace
                title="Where I get the Income"
                text={occupation.additionalIncome.incomeSources || '-'}
                type="firstUppercase"
              />
              <InfoContentSpace
                title="Amount"
                text={occupation.additionalIncome.amount}
                type="$/Mo"
              />
            </InfoItem>
          </InfoHead>

          <hr />
          <InfoHead title="Total Monthly Income">
            <InfoContentSpace
              text={totalMonthlyIncome(
                occupation.current.monthlySalary,
                occupation.additionalIncome.amount,
              )}
              type="$"
            />
          </InfoHead>
        </InfoList>
      </FullReportDetailSection>

      <FullReportDetailSection title="REFERENCES" icon="icon-people" isNewPage>
        <InfoList title="text">
          <hr />
          <Professional title="Professional Reference" data={personal} />
          <hr />
          <Personal title="Personal Reference" data={nearestRelative} />
        </InfoList>
      </FullReportDetailSection>

      <FullReportDetailSection title="ADDITIONAL INFORMATION" icon="icon-doc" isNewPage>
        <InfoList title="text">
          <hr />
          <AdditionalInformationList data={additionalInformation} full_report />
        </InfoList>
      </FullReportDetailSection>
      <CompanyInfoFooter />
    </FullReportDetailBody>
  )
}

export default ApplicationReport
