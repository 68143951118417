import React from "react"
import isNil from "lodash/isNil"
import { useDispatch, useSelector } from "react-redux"
import { getAcceptAgreementCall } from "containers/agreement/actions"
import { selectAgreementResponse } from "containers/agreement/selectors"

const useAgreementAccepted = agreementType => {
  const dispatch = useDispatch()
  const isAccepted = useSelector(selectAgreementResponse())

  React.useEffect(() => {
    if (isNil(isAccepted))
      dispatch(getAcceptAgreementCall({ type: agreementType }))
  }, [isAccepted])

  return isAccepted
}

export default useAgreementAccepted
