import { combineReducers } from "redux"
import moment from "moment"
import isEmpty from "lodash/isEmpty"
import filter from "lodash/filter"
import isObject from "lodash/isObject"
import * as Types from "../constants/action-types"
import { reducerIsFetching } from "./helper/handle-reducer"
import { LOCAL_STORAGE } from "../constants/local-storage-consts"
import { getLocalStorage, saveLocalStorage } from "../helpers/local-storage"
import { FEATURE_PRO } from "../constants/feature-pro-consts"
import { parse } from "../helpers/query-string"

const saveCachedAuthorize = data => {
  if (!data) return parseCacheAuthorize(data)
  const beingSaveAuthorize = {
    ...data,
    timestamp: moment().format(),
  }
  saveLocalStorage(LOCAL_STORAGE.SUBSCRIPTIONS, beingSaveAuthorize)
  return parseCacheAuthorize(beingSaveAuthorize)
}

const parseCacheAuthorize = authorize => {
  const queryString = parse(window.location.search)

  const currentAuthorize =
    authorize ||
    (!queryString.payment && getLocalStorage(LOCAL_STORAGE.SUBSCRIPTIONS))

  if (!isEmpty(currentAuthorize)) {
    const countCheckFeature = filter(currentAuthorize, v => isObject(v)).length
    currentAuthorize[FEATURE_PRO.PAYMENT] = {
      read: filter(currentAuthorize, v => v.read).length === countCheckFeature,
    }
    currentAuthorize[FEATURE_PRO.SUBSCRIPTION] = {
      read: filter(currentAuthorize, v => v.read).length > 0,
    }
    currentAuthorize.isExpires = currentAuthorize.hasPreviousExpired || false
  }
  return {
    ...currentAuthorize,
  }
}

const authorize = (state = parseCacheAuthorize() || {}, action = {}) => {
  switch (action.type) {
    case Types.GET_SUBSCRIPTIONS_AUTH_SUCCESS: {
      return saveCachedAuthorize(action.payload || action.result)
    }
    case Types.GET_SUBSCRIPTIONS_AUTH_REQUEST:
    case Types.CLEAR_SUBSCRIPTIONS_AUTH:
      localStorage.removeItem(LOCAL_STORAGE.SUBSCRIPTIONS)
      return {}
    default:
      return state
  }
}

const errorSubscription = (state = null, action = {}) => {
  switch (action.type) {
    case Types.GET_SUBSCRIPTIONS_AUTH_FAILED:
      return action.message || "Error subscriptions"
    case Types.FETCHING_REFRESH_TOKEN:
    case Types.GET_SUBSCRIPTIONS_AUTH_SUCCESS:
    case Types.GET_SUBSCRIPTIONS_AUTH_REQUEST:
      return null
    default:
      return state
  }
}

const errorRedeemCoupon = (state = null, action = {}) => {
  switch (action.type) {
    case Types.REDEEM_COUPON_FAILED:
      return action.message || "Error redeem coupon"
    case Types.REDEEM_COUPON_REQUEST:
    case Types.REDEEM_COUPON_CLEAR_ERROR:
    case Types.REDEEM_COUPON_SUCCESS:
      return null
    default:
      return state
  }
}

const subscription = (state = {}, action = {}) => {
  switch (action.type) {
    case Types.REDEEM_COUPON_SUCCESS:
      return action.result.subscription || {}
    case Types.REDEEM_COUPON_CLEAR_DATA:
      return {}
    default:
      return state
  }
}

const plans = (state = [], action = {}) => {
  switch (action.type) {
    case Types.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return action.result || []
    default:
      return state
  }
}
const errorPlans = (state = null, action = {}) => {
  switch (action.type) {
    case Types.GET_SUBSCRIPTION_PLANS_FAILED:
      return action.message || "Error redeem coupon"
    case Types.GET_SUBSCRIPTION_PLANS_REQUEST:
    case Types.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return null
    default:
      return state
  }
}

const isFetching = (...args) =>
  reducerIsFetching(...args, [
    Types.GET_SUBSCRIPTIONS_AUTH_REQUEST,
    Types.GET_SUBSCRIPTIONS_AUTH_SUCCESS,
    Types.GET_SUBSCRIPTIONS_AUTH_FAILED,
  ])

const isFetchingRedeemCoupon = (...args) =>
  reducerIsFetching(...args, [
    Types.REDEEM_COUPON_REQUEST,
    Types.REDEEM_COUPON_SUCCESS,
    Types.REDEEM_COUPON_FAILED,
  ])

const isFetchingPlans = (...args) =>
  reducerIsFetching(...args, [
    Types.GET_SUBSCRIPTION_PLANS_REQUEST,
    Types.GET_SUBSCRIPTION_PLANS_SUCCESS,
    Types.GET_SUBSCRIPTION_PLANS_FAILED,
  ])

const mySubscription = (state = {}, action = {}) => {
  switch (action.type) {
    case Types.GET_MY_SUBSCRIPTION_SUCCESS:
      return action.result || {}
    default:
      return state
  }
}
const isFetchingMySubscription = (...args) =>
  reducerIsFetching(...args, [
    Types.GET_MY_SUBSCRIPTION_REQUEST,
    Types.GET_MY_SUBSCRIPTION_SUCCESS,
    Types.GET_MY_SUBSCRIPTION_FAILED,
  ])

export default combineReducers({
  authorize,
  subscription,
  mySubscription,
  isFetchingPlans,
  plans,
  isFetching,
  isFetchingRedeemCoupon,
  isFetchingMySubscription,
  errorSubscription,
  errorPlans,
  errorRedeemCoupon,
})
