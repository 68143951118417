export const TABS_TYPE = {
  OUTSTANDING: 'outstanding',
  COMPLETED: 'completed',
}

export const TAB_NAME_MAPPER = {
  [TABS_TYPE.OUTSTANDING]: 'Outstanding',
  [TABS_TYPE.COMPLETED]: 'Completed',
}

export const TASK_PANEL_CONTENT_TYPE = {
  TASK_DETAIL: 'TASK_DETAIL',
}
