import React, { lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  NOT_FOUND,
  PAYMENT_PAGE,
  PRO_PAGE,
  REF_CHECKS_PAGE,
  REQUEST_DOCS_PAGE,
} from 'constants/route'
import Subscription from 'containers/subscription'
import { ERRORS } from "v3/routers/constants"
import { SuspendWithErrorBoundary } from "v3/components/templates/suspend-with-error-boundary"
import MySubscription from './my-subscription'
import { FEATURE_PRO } from '../../constants/feature-pro-consts'
import ProPaymentContainer from './payment'
import ReferenceCheckDetail from './arcs/authen-route'
import RequestDocsDetail from '../static-page/request-docs-route'
import { withTracker } from '../with-tracker'
import FailedToFetchPage from "../../../components/templates/failed-to-fetch-full-page"

const ProLandingPageV2 = lazy(() => import('containers/pro/landing'))

const ProSubscriptionPage = props => (
    <Subscription
      feature={FEATURE_PRO.SUBSCRIPTION}
      render={({ isSubscriber }) => {
        if (isSubscriber) {
          return <MySubscription {...props} />
        }

        return <SuspendWithErrorBoundary
          ErrorFallback={() => (
            <FailedToFetchPage
              noMargin
              title={ERRORS.DASHBOARD.TITLE}
              subTitle={ERRORS.DASHBOARD.MESSAGE}
            />
          )}
          loadingProps={{ height: 'full' }}
        >
          <ProLandingPageV2 {...props} />
        </SuspendWithErrorBoundary>
      }}
    />
  )

const ProPaymentPage = props => (
  <Subscription
    feature={FEATURE_PRO.PAYMENT}
    render={({ isSubscriber }) => {
      if (isSubscriber) return <Redirect to={PRO_PAGE} />
      return <ProPaymentContainer {...props} />
    }}
  />
)

const RoutePro = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={ProSubscriptionPage} />
    <Route exact path={`${match.path}${PAYMENT_PAGE}`} component={ProPaymentPage} />
    <Route exact path={REQUEST_DOCS_PAGE} component={withTracker(RequestDocsDetail)} />
    <Route exact path={REF_CHECKS_PAGE} component={withTracker(ReferenceCheckDetail)} />
    <Route
      render={() => {
        window.location.href = NOT_FOUND
      }}
    />
  </Switch>
)

export default RoutePro
