export const REPORT_UNAVAILABLE_PAGE_CONTENT = {
  name: 'report_unavailable',
  title: 'Income report unavailable',
  subTitle:
    'Verification through Finicity wasn’t completed.\nInstead, the applicant may upload paystubs, W2s, or other documents.',
  ctaText: 'Manage documents',
}

export const REPORT_NOT_READY_PAGE_CONTENT = {
  name: 'report_not_ready',
  title: 'The report isn’t ready yet',
  subTitle: 'Sorry, it’s taking longer than expected.\nCome back in a little while.',
  ctaText: 'Try again',
}

export const CONTACT_SUPPORT_PAGE_CONTENT = {
  name: 'report_error',
  title: 'We can’t retrieve the income report',
  subTitle:
    'Sorry, the report can’t be generated.\nPlease [contact support](https://support.rentspree.com/en/contact-us).',
}

export const INCOME_VERIFICATION_REST_API_PATH = {
  GENERATE_REPORT:
    '/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/income/report',
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION:
    '/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/income',
  GET_INCOME_VERIFICATION_REPORT:
    '/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/income/report',
}

export const GENERATE_INCOME_VERIFICATION_REPORT = 'GENERATE_INCOME_VERIFICATION_REPORT'
export const GENERATE_INCOME_VERIFICATION_REPORT_REQUEST =
  'GENERATE_INCOME_VERIFICATION_REPORT_REQUEST'
export const GENERATE_INCOME_VERIFICATION_REPORT_SUCCESS =
  'GENERATE_INCOME_VERIFICATION_REPORT_SUCCESS'
export const GENERATE_INCOME_VERIFICATION_REPORT_FAILURE =
  'GENERATE_INCOME_VERIFICATION_REPORT_FAILURE'
export const GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION =
  'GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION'
export const GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_REQUEST =
  'GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_REQUEST'
export const GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_SUCCESS =
  'GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_SUCCESS'
export const GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_FAILURE =
  'GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_FAILURE'
export const GET_INCOME_VERIFICATION_REPORT = 'GET_INCOME_VERIFICATION_REPORT'
export const GET_INCOME_VERIFICATION_REPORT_REQUEST = 'GET_INCOME_VERIFICATION_REPORT_REQUEST'
export const GET_INCOME_VERIFICATION_REPORT_SUCCESS = 'GET_INCOME_VERIFICATION_REPORT_SUCCESS'
export const GET_INCOME_VERIFICATION_REPORT_FAILURE = 'GET_INCOME_VERIFICATION_REPORT_FAILURE'
export const CLEAR_INCOME_VERIFICATION = 'CLEAR_INCOME_VERIFICATION'

export const ALLOW_GENERATE_REPORT_INCOME_VERIFICATION_STATUS = ['submitted', 'ready']
export const INCOME_VERIFICATION_REPORT_STATUS = {
  SUCCESS: 'success',
  IN_PROGRESS: 'inProgress',
  FAILURE: 'failure',
}
export const INCOME_VERIFICATION_STATE = {
  CREATED: 'created',
  STARTED: 'started',
  LINKED: 'linked',
  SUBMITTED: 'submitted',
  GENERATING: 'generating',
  READY: 'ready',
  FAILED: 'failed',
  EXPIRED: 'expired',
}
export const ALLOW_SHOW_NOT_HAVE_REPORT_PAGE_STATUS = [
  INCOME_VERIFICATION_STATE.CREATED,
  INCOME_VERIFICATION_STATE.STARTED,
  INCOME_VERIFICATION_STATE.LINKED,
  INCOME_VERIFICATION_STATE.SUBMITTED,
  INCOME_VERIFICATION_STATE.READY,
  INCOME_VERIFICATION_STATE.FAILED,
]
