import { ALERT_PAYER_TYPE } from 'legacy/constants/screening-plan-consts'
export {
  GET_CREDIT_PAYMENT_REQUEST,
  GET_CREDIT_PAYMENT_SUCCESS,
  GET_CREDIT_PAYMENT_FAILED,
  SUBMIT_CREDIT_PAYMENT_REQUEST,
  SUBMIT_CREDIT_PAYMENT_SUCCESS,
  SUBMIT_CREDIT_PAYMENT_FAILED,
} from 'legacy/constants/action-types'

export const GET_CREDIT_PAYMENT_CALLED = 'GET_CREDIT_PAYMENT_CALLED'
export const SUBMIT_CREDIT_PAYMENT_CALLED = 'SUBMIT_CREDIT_PAYMENT_CALLED'

export const stripeErrorMessage = {
  account_number_invalid: 'The bank account number provided is invalid (e.g., missing digits).',
  balance_insufficient:
    'The transfer or payout could not be completed because the associated account does not have a sufficient balance available. Create a new transfer or payout using an amount less than or equal to the account’s available balance.',
  bank_account_declined:
    'The bank account provided can not be used to charge, either because it is not verified yet or it is not supported.',
  bank_account_unusable:
    'The bank account provided cannot be used for payouts. A different bank account must be used.',
  card_decline_rate_limit_exceeded:
    'This card has been declined too many times. You can try to charge this card again after 24 hours.',
  clearing_code_unsupported: 'The clearing code provided is not supported.',
  country_code_invalid: 'The country code provided was invalid.',
  country_unsupported:
    'Your platform attempted to create a custom account in a country that is not yet supported. Make sure that users can only sign up in countries supported by custom accounts.',
  coupon_expired: 'The coupon provided for a subscription or order has expired.',
  customer_max_payment_methods:
    'The maximum number of PaymentMethods for this Customer has been reached. Either detach some PaymentMethods from this Customer or proceed with a different Customer.',
  email_invalid: 'The email address is invalid',
  expired_card: 'The card has expired. Check the expiration date or use a different card.',
  incorrect_address:
    'The card’s address is incorrect. Check the card’s address or use a different card.',
  incorrect_cvc:
    'The card’s security code is incorrect. Check the card’s security code or use a different card.',
  incorrect_number:
    'The card number is incorrect. Check the card’s number or use a different card.',
  incorrect_zip:
    'The card’s postal code is incorrect. Check the card’s postal code or use a different card.',
  invalid_cvc:
    'The card’s security code is invalid. Check the card’s security code or use a different card.',
  invalid_expiry_month:
    'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
  invalid_expiry_year:
    'The card’s expiration year is incorrect. Check the expiration date or use a different card.',
  invalid_number: 'The card number is invalid. Check the card details or use a different card.',
  postal_code_invalid: 'The postal code provided was incorrect.',
  processing_error:
    'An error occurred while processing the card. Try again later or with a different payment method.',
  rate_limit:
    'Too many requests hit the API too quickly. We recommend an exponential backoff of your requests.',
}

export const stripeCardDeclineErrorMessage = {
  authentication_required: 'The card was declined as the transaction requires authentication.',
  approve_with_id: 'The payment cannot be authorized.',
  call_issuer: undefined, // The card has been declined for an unknown reason.
  card_not_supported: 'The card does not support this type of purchase.',
  card_velocity_exceeded:
    'The customer has exceeded the balance or credit limit available on their card.',
  currency_not_supported: 'The card does not support the specified currency.',
  do_not_honor: undefined, // The card has been declined for an unknown reason.
  do_not_try_again: undefined, // The card has been declined for an unknown reason.
  duplicate_transaction:
    'A transaction with identical amount and credit card information was submitted very recently.',
  expired_card: 'The card has expired. Check the expiration date or use a different card.',
  fraudulent: 'The payment has been declined as Stripe suspects it is fraudulent.',
  generic_decline: undefined, // The card has been declined for an unknown reason.
  incorrect_number: 'The card number is incorrect.',
  incorrect_cvc:
    'The card’s security code is incorrect. Check the card’s security code or use a different card.',
  incorrect_pin:
    'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
  incorrect_zip:
    'The card’s postal code is incorrect. Check the card’s postal code or use a different card.',
  insufficient_funds: 'The card has insufficient funds to complete the purchase.',
  invalid_account: 'The card, or account the card is connected to, is invalid.',
  invalid_amount: 'The payment amount is invalid, or exceeds the amount that is allowed.',
  invalid_cvc:
    'The card’s security code is invalid. Check the card’s security code or use a different card.',
  invalid_expiry_month:
    'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
  invalid_expiry_year:
    'The card’s expiration year is incorrect. Check the expiration date or use a different card.',
  invalid_number: 'The card number is invalid. Check the card details or use a different card.',
  invalid_pin:
    'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
  issuer_not_available:
    'The card issuer could not be reached, so the payment could not be authorized.',
  lost_card: 'The payment has been declined because the card is reported lost.',
  merchant_blacklist:
    "The payment has been declined because it matches a value on the Stripe user's block list.",
  new_account_information_available: 'The card, or account the card is connected to, is invalid.',
  no_action_taken: undefined, // The card has been declined for an unknown reason.
  not_permitted: 'The payment is not permitted.',
  offline_pin_required: 'The card has been declined as it requires a PIN.',
  online_or_offline_pin_required: 'The card has been declined as it requires a PIN.',
  pickup_card:
    'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
  pin_try_exceeded: 'The allowable number of PIN tries has been exceeded.',
  processing_error:
    'An error occurred while processing the card. Try again later or with a different payment method.',
  reenter_transaction: 'The payment could not be processed by the issuer for an unknown reason.',
  restricted_card:
    'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
  revocation_of_all_authorizations: undefined, // The card has been declined for an unknown reason.
  revocation_of_authorization: undefined, // The card has been declined for an unknown reason.
  security_violation: undefined, // The card has been declined for an unknown reason.
  service_not_allowed: undefined, // The card has been declined for an unknown reason.
  stolen_card: 'The payment has been declined because the card is reported stolen.',
  stop_payment_order: undefined, // The card has been declined for an unknown reason.
  testmode_decline: 'A Stripe test card number was used.',
  transaction_not_allowed: undefined, // The card has been declined for an unknown reason.
  try_again_later: undefined, // The card has been declined for an unknown reason.
  withdrawal_count_limit_exceeded:
    'The customer has exceeded the balance or credit limit available on their card.',
}

export const NY_PROPERTY = 'screening_without_property_ny_case'
export const NY_PROPERTY_AFTER_OPENED = 'upgraded_screening_without_property_ny_case'

export const LEGAL_INSTRUCTION_MESSAGES = {
  MA: {
    title: 'Massachusetts regulations',
    instructionMessage: ALERT_PAYER_TYPE.MA,
  },
}
