import isNil from "lodash/isNil"
import { getColor } from "utils/get-color"
import { COLOR } from "styles/settings"

export const getColorByType = (colorIndex, type, defaultColor) =>
  isNil(colorIndex) ? defaultColor : getColor(colorIndex, type)

export const rgbaColor = (colorIndex, defaultColor = COLOR.lightBlueRGB) =>
  `rgba(${getColorByType(colorIndex, "bgRgb", defaultColor)}, 0.8)`

export const contentColor = (colorIndex, defaultColor = COLOR.fontBlue) =>
  getColorByType(colorIndex, "content", defaultColor)

export const borderColor = (colorIndex, defaultColor = COLOR.fontBlue) =>
  getColorByType(colorIndex, "border", defaultColor)
