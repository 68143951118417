export const referenceType = {
  current_employer: "current_employer",
  former_employer: "former_employer",
  current_landlord: "current_landlord",
  previous_landlord: "previous_landlord",
  professional: "professional",
  personal: "personal",
}

export const defaultRefChecks = [
  {
    id: "currentEmployer",
    name: "Current Employer",
    type: referenceType.current_employer,
    isChecked: false,
    report: {
      name: "Current employment",
      icon: "icon-briefcase",
    },
  },
  {
    id: "formerEmployer",
    name: "Former Employer",
    type: referenceType.former_employer,
    isChecked: false,
    report: {
      name: "Previous employment",
      icon: "icon-briefcase",
    },
  },
  {
    id: "currentLandlord",
    name: "Current Landlord",
    type: referenceType.current_landlord,
    isChecked: false,
    report: {
      name: "Current residence",
      icon: "icon-home",
    },
  },
  {
    id: "previousLandlord",
    name: "Previous Landlord",
    type: referenceType.previous_landlord,
    isChecked: false,
    report: {
      name: "Previous residence",
      icon: "icon-home",
    },
  },
]

export const refChecks2 = [
  ...defaultRefChecks,
  {
    id: "professional",
    name: "Professional Reference",
    type: referenceType.professional,
    isChecked: false,
    report: {
      name: "Professional reference",
      icon: "icon-people",
    },
  },
  {
    id: "personal",
    name: "Personal Reference",
    type: referenceType.personal,
    isChecked: false,
    report: {
      name: "Personal reference",
      icon: "icon-people",
    },
  },
]
export const refChecks25 = [
  ...defaultRefChecks,
  {
    id: "personal1",
    name: "Personal Reference #1",
    type: referenceType.personal,
    isChecked: false,
    report: {
      name: "Personal reference #1",
      icon: "icon-people",
    },
    arrayIndex: 0,
  },
  {
    id: "personal2",
    name: "Personal Reference #2",
    type: referenceType.personal,
    isChecked: false,
    report: {
      name: "Personal reference #2",
      icon: "icon-people",
    },
    arrayIndex: 1,
  },
]

export const refCheckStatus = {
  requested: {
    value: "requested",
    text: "Pending",
    longText: "The reference was sent an email/text but hasn’t responded yet.",
    color: "yellow",
  },
  refuted: {
    value: "refuted",
    text: "Refuted",
    longText: "The reference indicated that some information may be incorrect.",
    color: "negativeRed",
  },
  verified: {
    value: "verified",
    text: "Verified",
    longText: "The reference indicated that this information is correct.",
    color: "green",
  },
  unattainable: {
    value: "unattainable",
    text: "Unattainable",
    longText: "We don’t have enough information to contact the reference.",
    color: "textGrey",
  },
}
