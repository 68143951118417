import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { connect } from "react-redux"
import { makeSelectActiveBadge, makeSelectBadgeStyled } from "./selectors"

export const mapStateToProps = () =>
  createStructuredSelector({
    isActive: makeSelectActiveBadge(),
    badgeStyled: makeSelectBadgeStyled(),
  })

export const withBadgeActive = compose(
  connect(
    mapStateToProps,
    null,
  ),
)
