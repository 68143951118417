/* eslint-disable import/no-cycle */
import fetch from "isomorphic-fetch"
import merge from "lodash/merge"
import qs from "qs"
import { getLocalItem } from "@rentspree/cookie"
import { STORAGE } from "../../constants/cookie"
import { REFRESH_TOKEN_DELAY, USER_API_URL } from "../../env"
import { fetchingRefreshToken, saveToken } from "../actions/user.action"
import { store } from "../../store"
import {
  getAccessToken,
  isFetchingRefreshToken,
} from "../reducers/persist-state.reducer"

const URL_REGEX = /^([^?*]+)\??(.*)$/

export default function(url, options, ...args) {
  return fetchWithRefresh(parseUrl(url), options, ...args)
}

export const parseUrl = url => {
  // for cache burst
  const stripped = url.match(URL_REGEX)
  if (!stripped || stripped.length <= 2) return url
  const [, secondPart, thirdPart] = stripped
  const query = qs.parse(thirdPart)
  const fireURL = secondPart // base query
  merge(query, { t: new Date().getTime() }) // append
  return `${fireURL}?${qs.stringify(query)}`
}

export const delay = timeout =>
  new Promise(resolve => {
    setTimeout(() => resolve(), timeout)
  })

const reFetch = (url, options, newToken, ...args) =>
  fetch(
    url,
    merge(
      options,
      {
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      },
      ...args,
    ),
  )

export const fetchWithRefresh = (url, options, ...args) =>
  fetchWithRefreshPassStore(url, options, store, ...args)

// TODO:- waring!! partially tested
export const fetchWithRefreshPassStore = (url, options, passedStore, ...args) =>
  fetch(url, options, ...args).then(response => {
    if (response.status === 401) {
      // should exchange refresh token
      const credentials = getLocalItem(STORAGE.USER_TOKEN)
      if (credentials.refresh_token) {
        // check isFetching Flag
        const isFetching = isFetchingRefreshToken(
          passedStore.getState().persistState,
        )
        if (isFetching) {
          // other request had already call to fetch, delay this request for a while
          return delay(parseInt(REFRESH_TOKEN_DELAY, 10)).then(() => {
            const accessToken = getAccessToken(
              passedStore.getState().persistState,
            )
            return reFetch(url, options, accessToken, ...args)
          })
        }
        // raise isFetching refreshToken flag
        passedStore.dispatch(fetchingRefreshToken(true))
        return exchangeRefreshToken(
          credentials.access_token,
          credentials.refresh_token,
        )
          .then(responseRefresh => {
            passedStore.dispatch(fetchingRefreshToken(false))
            if (responseRefresh.status === 200) {
              // successfully get refresh token
              return responseRefresh.json()
            }
            return responseRefresh
          })
          .then(data => {
            // if the access token is returned
            if (data.access_token) {
              // NOTE:- Dangerously dispatch from outside of action
              passedStore.dispatch(saveToken(data))
              return reFetch(url, options, data.access_token, ...args)
            }
            return data
          })
      }
    }
    return response
  })

// TODO:- change the v2 to be a config
export const exchangeRefreshToken = (accessToken, refreshToken) =>
  fetch(`${USER_API_URL}/api/v2/token`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    }),
  })
