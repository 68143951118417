import { generateFullName, formatPhone } from '@rentspree/helper'
import get from 'lodash/get'
import React from 'react'

import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import { ReportsBody } from 'components/reports/screening-reports/share/responsive-doc-components'
import HeaderPrint from 'containers/reports/header'
import { creditReportMapper } from 'legacy/components/reports-v2/credit-report/data-mapper'
import { generateHeaderName, convertPhoneForm } from 'legacy/components/reports-v2/share/function'

import { hasInLieuOfCredit } from 'containers/reports/helper'
import ContactInfo from './contact'
import Header from './header'
import TransUnionReportSummary from './tu-report-summary'
import ApplicationSummary from './application-summary'
import Footer from './footer'

const CoverPage = ({
  application = {},
  rentalDetail = {},
  creditReport = {},
  generatedOn,
  expiresOn,
  requestedOption,
}) => {
  const creditData = creditReportMapper(creditReport) || {}
  const renterFullName = generateHeaderName(creditData, true)
  const phoneNumber =
    application.version >= '3.2'
      ? formatPhone(application.phoneNumber)
      : convertPhoneForm(application.phoneNumber)

  const addressReport = get(creditData, 'addresses', [])
  const agentName = rentalDetail.landlord?.firstName ? generateFullName(rentalDetail.landlord) : 'Not provided'
  const inLieuOfCredit = hasInLieuOfCredit(application)
  const applicantType = get(rentalDetail, 'applicant_type')
  const requestedScreeningOption = get(requestedOption, 'screeningOption', {})
  const { email, screeningOption } = rentalDetail
  return (
    <ReportWrapper>
      <HeaderPrint />
      <ReportsBody>
        <Header fullName={renterFullName} applicantType={applicantType} />
        <ContactInfo email={email} phone={phoneNumber} />
        <ApplicationSummary
          application={application}
          rentalDetail={rentalDetail}
          agentName={agentName}
        />
        <TransUnionReportSummary
          requestedScreeningOption={requestedScreeningOption}
          screeningOption={screeningOption}
          inLieuOfCredit={inLieuOfCredit}
        />
        <Footer
          addressReport={addressReport}
          transUnion={{ generatedOn, expiresOn }}
          rentalDetail={rentalDetail}
        />
      </ReportsBody>
    </ReportWrapper>
  )
}

export default CoverPage
