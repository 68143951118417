import generateApiCall from "utils/generate-api-call"

import {
  DISABLE_FEATURE,
  ENABLE_FEATURE,
  OPEN_TOGGLE_PROPERTY_FEATURE_MODAL,
  CLEAR_TOGGLE_PROPERTY_FEATURE_MODAL,
} from "./constants"

export const disableFeature = payload => ({
  type: DISABLE_FEATURE.CALL,
  payload,
})

export const enableFeature = payload => ({
  type: ENABLE_FEATURE.CALL,
  payload,
})

export const openTogglePropertyFeatureModal = payload => ({
  type: OPEN_TOGGLE_PROPERTY_FEATURE_MODAL,
  payload,
})

export const clearTogglePropertyFeatureModal = () => ({
  type: CLEAR_TOGGLE_PROPERTY_FEATURE_MODAL,
})

export const disableFeatureApiCall = generateApiCall([
  DISABLE_FEATURE.REQUEST,
  DISABLE_FEATURE.SUCCESS,
  DISABLE_FEATURE.FAILED,
])

export const enableFeatureApiCall = generateApiCall([
  ENABLE_FEATURE.REQUEST,
  ENABLE_FEATURE.SUCCESS,
  ENABLE_FEATURE.FAILED,
])
