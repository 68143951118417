import React from "react"
import * as Styled from "./accordion-style"
import { TrackerInstance as Tracker } from "../../helpers/tracker"
import { RequestDocsFaq } from "./request-docs-faq"
import { SubscriptionFaq } from "./subscription-faq"
import { NonsubRefChecksFaq, SubRefChecksFaq } from "./ref-checks-faq"

export default class FaqAccordion extends React.Component {
  headerWithIcon = text => (
    <Styled.PanelHeader>
      {text}
      <i className="fas fa-angle-down" />
    </Styled.PanelHeader>
  )

  clickPanel = name => {
    Tracker.trackEvent(name)
  }

  selectFaq = name => {
    switch (name) {
      case "requestDocsFaq":
        return (
          <RequestDocsFaq
            clickPanel={this.clickPanel}
            headerWithIcon={this.headerWithIcon}
          />
        )
      case "refChecksFaq":
        return (
          <NonsubRefChecksFaq
            clickPanel={this.clickPanel}
            headerWithIcon={this.headerWithIcon}
          />
        )
      case "arcsSubscribedFaq":
        return (
          <SubRefChecksFaq
            clickPanel={this.clickPanel}
            headerWithIcon={this.headerWithIcon}
          />
        )
      case "subscriptionFaq":
      default:
        return (
          <SubscriptionFaq
            clickPanel={this.clickPanel}
            headerWithIcon={this.headerWithIcon}
          />
        )
    }
  }

  render() {
    const { name } = this.props
    return (
      <Styled.AccordionWrapper>{this.selectFaq(name)}</Styled.AccordionWrapper>
    )
  }
}
