export const makeMapCursor = (focusCursor = "default", editCursor) => (
  viewMode,
  canEdit,
  active,
  unmovable,
) => {
  if (viewMode) {
    if (canEdit) return editCursor || focusCursor
    return "default"
  }
  if (active) return focusCursor
  if (unmovable) return "pointer"
  return "grab"
}
