import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/inject-reducer'

import { openDeleteTagModal, closeDeleteTagModal } from './actions'
import reducer from './reducer'
import { selectIsOpen, selectIsLoading } from './selectors'

const mapStateToProps = createStructuredSelector({
  isOpen: selectIsOpen,
  isLoading: selectIsLoading,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openDeleteTagModal,
      closeDeleteTagModal,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({
  key: 'contacts.deleteTagModal',
  reducer,
})
