import { buildPath } from '@rentspree/path'
import {
  EVENT_REQUEST_SCREENING,
  LEASE_AGREEMENTS,
  RENT_ESTIMATE,
  RENTER_INSURANCE,
  RENT_PAYMENT,
} from 'tracker/const'
import {
  LEASE_AGREEMENT_LIST,
  LISTING_MANAGE,
  PROPERTY_OVERVIEW_PAGE,
  RENT_PAYMENT_PROPERTY_PAGE,
  SINGLE_PROPERTY_APPLICATION_LIST,
} from 'constants/route'
import { LISTING_MANAGEMENT_SOURCE } from 'containers/constants'

import {
  E_SIGNATURE_FEATURE_ID,
  RENT_PAYMENTS_ID,
  LISTING_MANAGEMENT_FEATURE_ID,
} from '../../utils/feature-toggle/constants'

// Add the optimizely feature component
export const PROPERTY_INNER_TABS_FEATURE_TOGGLE_LIST = [
  E_SIGNATURE_FEATURE_ID,
  RENT_PAYMENTS_ID,
  LISTING_MANAGEMENT_FEATURE_ID,
]

export const defaultFnc = () => {}

export function buildOnClickEvent(eventName, propertyName) {
  return {
    name: eventName,
    properties: {
      [propertyName]: 'listing_menu',
    },
  }
}

export const PROPERTY_INNER_TAB_EVENTS = {
  ON_CLICK: {
    tenant: buildOnClickEvent(EVENT_REQUEST_SCREENING.CLICK_SCREENING_MENU, 'click_from'),
    'lease-agreement': buildOnClickEvent(LEASE_AGREEMENTS.CLICK_LEASE_MENU, 'click_from'),
    'rent-estimate': buildOnClickEvent(RENT_ESTIMATE.enterPage, 'source'),
    'renter-insurance': {
      name: RENTER_INSURANCE.EVENT_NAME.CLICK_INSURANCE_MENU,
      properties: {
        click_from: 'listing_menu',
        dashboard: 'agent',
      },
    },
    'rent-payment': buildOnClickEvent(RENT_PAYMENT.EVENT_NAME.ENTER_RENT_PAYMENT_MENU, 'source'),
  },
}

export const NOTIFICATION_KEY = {
  TENANT_SCREENING: 'tenantScreening',
  LEASE_AGREEMENTS: 'eSignDocuments',
  RENTAL_PAYMENT: 'rentalPayments',
}

// === Overview ===
export const PROPERTY_INNER_TAB_OVERVIEW = propertyId => ({
  id: 'propertyInnerTabOverview',
  text: 'Overview',
  path: buildPath(PROPERTY_OVERVIEW_PAGE, { propertyId }),
})

// === Screening ===
export const PROPERTY_INNER_TAB_TENANT_SCREENING = propertyId => ({
  id: 'propertyInnerTabTenantScreening',
  text: 'Applications',
  path: buildPath(SINGLE_PROPERTY_APPLICATION_LIST, { propertyId }),
  notificationKey: NOTIFICATION_KEY.TENANT_SCREENING,
  event: PROPERTY_INNER_TAB_EVENTS.ON_CLICK.tenant,
})

// === Listing Management ===
export const PROPERTY_INNER_TAB_LISTING_MANAGEMENT = propertyId => ({
  id: 'propertyInnerTabListingManagement',
  text: 'Listing',
  path: buildPath(
    LISTING_MANAGE,
    { propertyId },
    { source: LISTING_MANAGEMENT_SOURCE.LISTING_MENU },
  ),
  featureId: LISTING_MANAGEMENT_FEATURE_ID,
})

// === E-Sign / Lease Agreement ===
export const PROPERTY_INNER_TAB_LEASE_AGREEMENT = propertyId => ({
  id: 'propertyInnerTabLeaseAgreement',
  text: 'Lease',
  path: buildPath(LEASE_AGREEMENT_LIST, { propertyId }),
  featureId: E_SIGNATURE_FEATURE_ID,
  event: PROPERTY_INNER_TAB_EVENTS['lease-agreement'],
  notificationKey: NOTIFICATION_KEY.LEASE_AGREEMENTS,
})

// === Rent Payment ===
export const PROPERTY_INNER_TAB_RENT_PAYMENT = propertyId => ({
  id: 'propertyInnerTabRentPayment',
  text: 'Payments',
  path: buildPath(
    RENT_PAYMENT_PROPERTY_PAGE,
    { propertyId },
    {
      source: 'sidebar-property',
    },
  ),
  featureId: RENT_PAYMENTS_ID,
  notificationKey: NOTIFICATION_KEY.RENTAL_PAYMENT,
  event: PROPERTY_INNER_TAB_EVENTS['rent-payment'],
})
