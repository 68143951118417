import get from 'lodash/get'
import { useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'

import { KEY } from '../../../shared/redux/constants'

import { rentPaymentSetupReducer } from './reducer'

export const useRentalPaymentSetup = () => {
  useInjectReducer({ key: KEY.RENTAL_PAYMENT_SETUP, reducer: rentPaymentSetupReducer })

  const setupState = useSelector(state => get(state, KEY.RENTAL_PAYMENT_SETUP))

  return setupState ?? {}
}
