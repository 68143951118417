const STEP1 = require("../../images/pro/request-docs/doc-tutorial-1.png")
const STEP2 = require("../../images/pro/request-docs/doc-tutorial-2.png")
const STEP3 = require("../../images/pro/request-docs/doc-tutorial-3.png")
const STEP4 = require("../../images/pro/request-docs/doc-tutorial-4.png")
const STEP5 = require("../../images/pro/request-docs/doc-tutorial-5.png")
const STEP6 = require("../../images/pro/request-docs/doc-tutorial-6.png")
const mSTEP1 = require("../../images/pro/request-docs/doc-tutorial-m1.png")
const mSTEP2 = require("../../images/pro/request-docs/doc-tutorial-m2.png")
const mSTEP3 = require("../../images/pro/request-docs/doc-tutorial-m3.png")
const mSTEP4 = require("../../images/pro/request-docs/doc-tutorial-m4.png")
const mSTEP5 = require("../../images/pro/request-docs/doc-tutorial-m5.png")
const mSTEP6 = require("../../images/pro/request-docs/doc-tutorial-m6.png")

export const TUTORIALS_STEPS = [
  {
    title: "Click on the property address you're working with.",
    image: STEP1,
    mImage: mSTEP1,
    mark: { top: "28%", left: "32.5%" },
    mMark: { top: "30.2%", left: "53%" },
  },
  {
    title: "Click on the applicant's name.",
    image: STEP2,
    mImage: mSTEP2,
    mark: { top: "35%", left: "25%" },
    mMark: { top: "36.5%", left: "36%" },
  },
  {
    title: 'Click the "Other docs" tab.',
    image: STEP3,
    mImage: mSTEP3,
    mark: { top: "22%", left: "66.8%" },
    mMark: { top: "30.5%", left: "55%" },
  },
  {
    title: "Choose documents you want by checking boxes on the left.",
    image: STEP4,
    mImage: mSTEP4,
    mark: { top: "33%", left: "24%" },
    mMark: { top: "41%", left: "31%" },
  },
  {
    title: 'Click "Send Request".',
    image: STEP5,
    mImage: mSTEP5,
    mark: { top: "28%", left: "38.5%" },
    mMark: { top: "36.2%", left: "79%" },
  },
  {
    title:
      'Click the "Download" button after documents are uploaded to view/save.',
    image: STEP6,
    mImage: mSTEP6,
    mark: { top: "29%", left: "67%" },
    mMark: { top: "38%", left: "84%" },
  },
]
