import { OPEN_SWEET_ALERT } from "redux-middleware/sweet-alert/constants"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import generateApiCall from "utils/generate-api-call"
import {
  GET_RENTER_INSURANCE_LIST_CALL,
  GET_RENTER_INSURANCE_LIST_REQUEST,
  GET_RENTER_INSURANCE_LIST_SUCCESS,
  GET_RENTER_INSURANCE_LIST_FAILURE,
  CLEAR_RENTER_INSURANCE_LIST_CALL,
} from "./constants"

export const getRenterInsuranceListCall = payload => ({
  type: GET_RENTER_INSURANCE_LIST_CALL,
  payload,
})
export const clearRenterInsuranceListCall = () => ({
  type: CLEAR_RENTER_INSURANCE_LIST_CALL,
})

export const renterInsuranceApi = generateApiCall([
  GET_RENTER_INSURANCE_LIST_REQUEST,
  GET_RENTER_INSURANCE_LIST_SUCCESS,
  GET_RENTER_INSURANCE_LIST_FAILURE,
])

export const alertSuccess = () => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.SUCCESS,
  option: {
    title: "The Action is done successfully",
    text: "Default message",
    button: "OK",
  },
})

export const alertError = (errorMessage = {}) => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.ERROR,
  option: {
    title: errorMessage.title,
    content: {
      element: "div",
      attributes: {
        innerHTML: errorMessage.message,
      },
    },
  },
})
