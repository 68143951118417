import { Grid } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Avatar from '@rentspree/component-2023.components.atoms.avatar'
import Checkbox from '@rentspree/component-2023.components.atoms.checkbox'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import FormattedInput from '@rentspree/component-v2/dist/input/formatted'
import React from 'react'

const INVITE_COPY = 'Invite tenant by text'

// eslint-disable-next-line no-inline-comments
export const TenantAndPropertyCard = ({
  name,
  address,
  inviteByText,
  onSetInviteByText,
  phoneNumber,
  onSetPhoneNumber,
  isAgentInitiated,
}) => {
  const initials = name
    .split(' ')
    .map(n => n[0])
    .join('')

  return (
    <Card variant="outlined" style={{ margin: '15px 0', width: '100%' }}>
      <CardElement>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          item
          md={12}
          style={{ marginBottom: '15px' }}
        >
          <Typography variant="title">{name}</Typography>
          <Avatar>{initials}</Avatar>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '15px' }}>
          <Typography variant="body-medium">{address}</Typography>
        </Grid>
        {!isAgentInitiated && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: '18px' },
                    }}
                  />
                }
                checked={inviteByText}
                label={INVITE_COPY}
                onChange={event => onSetInviteByText(event.target.checked)}
              />
            </Grid>
            {inviteByText && (
              <Grid item xs={12}>
                <FormattedInput
                  id="tenant-phone"
                  formatType="phone"
                  className="createTenantInputs"
                  width="250px"
                  value={phoneNumber || ''}
                  label="Tenant’s phone number"
                  onValueChange={vals => {
                    onSetPhoneNumber(vals.value)
                  }}
                  isRequired={inviteByText}
                  disabled={!inviteByText}
                />
              </Grid>
            )}
          </>
        )}
      </CardElement>
    </Card>
  )
}
