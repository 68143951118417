import produce from "immer"
import {
  GET_RENTER_INSURANCE_DETAIL_REQUEST,
  GET_RENTER_INSURANCE_DETAIL_SUCCESS,
  GET_RENTER_INSURANCE_DETAIL_FAILURE,
  CLEAR_RENTER_INSURANCE_DETAIL_CALL,
} from "./constants"

export const initialState = {
  isFetching: false,
  rentersInsuranceDetail: {},
  isError: false,
}

/* eslint-disable default-case, no-param-reassign */
const rentersInsuranceDetailReducer = (
  state = initialState,
  { type, payload },
) =>
  produce(state, draft => {
    switch (type) {
      case CLEAR_RENTER_INSURANCE_DETAIL_CALL:
        draft.rentersInsuranceDetail = {}
        draft.isError = false
        draft.isFetching = false
        break
      case GET_RENTER_INSURANCE_DETAIL_REQUEST:
        draft.isFetching = true
        break
      case GET_RENTER_INSURANCE_DETAIL_SUCCESS:
        draft.isFetching = false
        draft.rentersInsuranceDetail = payload
        draft.isError = false
        break
      case GET_RENTER_INSURANCE_DETAIL_FAILURE:
        draft.isFetching = false
        draft.isError = true
        break
    }
    return draft
  })

export default rentersInsuranceDetailReducer
