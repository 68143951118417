export const REF_CHECKS_FAQ = {
  firstPanel: {
    header: {
      title: "How can I start using Auto Reference Checks?",
    },
    paragraph: {
      beforeLink: "To start, you must first ",
      afterLink:
        " for your account. Once active, you can selectively choose to verify references on a case-by-case basis. To do this, first access a completed rental application on your dashboard. Then click the “Ref Checks” tab on the top of the screen.",
    },
    bullet: {
      paragraph: [
        "You can set RentSpree to automatically attempt to verify application information for each renter who submits " +
          "an application. To do this, visit your dashboard and click the “Reference Checks” tab on the left hand side.",
        "You can selectively choose to verify references on a case-by-case basis. To do this, first access a " +
          "completed rental application on your dashboard. Then click the “auto ref checks” tab on the top of the " +
          "screen.",
      ],
    },
  },
  otherPanel: [
    {
      header: {
        title: "How does RentSpree contact a reference?",
      },
      paragraph:
        "RentSpree sends an email to the reference using the contact information provided on the rental application.",
    },
    {
      header: {
        title: "How does a reference verify information?",
      },
      paragraph:
        "Each reference is given the opportunity to confirm or refute information listed on the rental application. You " +
        "will then be able to review the feedback from the reference.",
    },
    {
      header: {
        title: "How trustworthy is the verification process?",
      },
      paragraph:
        "While this tool can save you a tremendous amount of time and provide crucial information about the applicant, " +
        "keep in mind that there is no way to 100% guarantee the accuracy of all the information provided by " +
        "references.",
    },
  ],
  subLinkId: "faqLinkToSubscriptionPaymentSub",
  nonsubLinkId: "faqLinkToSubscriptionPaymentNonsub",
  subEventName: [
    "clickFaqHowToStartUsingArcSub",
    "clickFaqHowRspContactRefSub",
    "clickFaqHowRefVerifyInfoSub",
    "clickFaqHowTrustworthyIsVerficationProcessSub",
  ],
  nonsubEventName: [
    "clickFaqHowToStartUsingArcNonsub",
    "clickFaqHowRspContactRefNonsub",
    "clickFaqHowRefVerifyInfoNonsub",
    "clickFaqHowTrustworthyIsVerficationProcessNonsub",
  ],
}
