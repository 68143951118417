import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { GET_PAYOUT_METHOD_LIST_API } from './constants'

export const getPayoutMethodListInstance = () =>
  apiInstanceWithErrorHandler.get(
    buildPath(
      GET_PAYOUT_METHOD_LIST_API,
      {},
      { types: { bank: true, card: false }, capabilities: { payment: false, payout: true } },
    ),
  )
