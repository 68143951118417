import styled from "styled-components"

export const TotalScreeningFeeStyled = styled.span`
  color: #3b99fc;
  font-weight: 600;
`

export const ScreeningFeeBlsLinkStyled = styled.a`
  color: #3b99fc;
`
