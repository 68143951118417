import generateApiCall from "utils/generate-api-call"
import {
  PARTNER_EVENT_TRACKER_CALL,
  PARTNER_EVENT_TRACKER_REQUEST,
  PARTNER_EVENT_TRACKER_SUCCESS,
  PARTNER_EVENT_TRACKER_FAILURE,
} from "./constants"

export const partnerEventTrackerApi = generateApiCall([
  PARTNER_EVENT_TRACKER_REQUEST,
  PARTNER_EVENT_TRACKER_SUCCESS,
  PARTNER_EVENT_TRACKER_FAILURE,
])

export const trackEvent = payload => ({
  type: PARTNER_EVENT_TRACKER_CALL,
  payload,
})
