import Typography from '@rentspree/component-2023.components.atoms.typography'
import dayjs from 'dayjs'
import React from 'react'

import { ChatLine } from './chat-line'
import { CAMPAIGN_LAST_DATE } from './constants'

export const DayCountDown = ({ ...props }) => {
  const dayLeft = dayjs().diff(CAMPAIGN_LAST_DATE, 'days') * -1

  if (dayLeft < 0) {
    return null
  }

  return (
    <ChatLine variant="down" {...props}>
      <Typography variant="caption" fontWeight="500">
        {dayLeft} days left
      </Typography>
    </ChatLine>
  )
}
