import React from 'react'
import { TableCell, TableRow } from 'legacy/components/reports-v2/share/doc-style'

import { Table } from 'legacy/components/reports-v2/share/doc-components'
import { FRAUD_INDICATOR_CODES } from 'legacy/constants/credit-report-code'
import {
  Content,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { SectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'

const FraudIndicator = ({ fraudIndicators = [] }) => (
  <Section>
    <SubSection>
      <SectionHeader title="Fraud Indicator(s)" />
      <Content>
        <Table>
          {fraudIndicators.map((fraudIndicatorCode, index) => (
            <TableRow key={`fraudIndicator-${fraudIndicatorCode}-${index + 1}`}>
              <TableCell>
                {FRAUD_INDICATOR_CODES[fraudIndicatorCode] || fraudIndicatorCode}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Content>
    </SubSection>
  </Section>
)

export default FraudIndicator
