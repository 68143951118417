import { CLEAR_RENTAL_PAYMENT_SETUP, SELECT_PROPERTY, SELECT_RENTER_INFO } from './constants'

export const clearRentalPaymentSetup = () => ({
  type: CLEAR_RENTAL_PAYMENT_SETUP,
})

export const selectProperty = payload => ({
  type: SELECT_PROPERTY,
  payload,
})

export const selectRenterInfo = payload => ({
  type: SELECT_RENTER_INFO,
  payload,
})
