import styled from "styled-components"
import {
  ANIMATE,
  COLOR,
  SIDEBAR_WIDTH,
  RIGHT_PANEL_WIDTH,
} from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const TabWrapper = styled.div`
  flex-grow: 1;
  padding: 5px;
  width: calc(
    100vw - ${SIDEBAR_WIDTH}
      ${props => props.showPanel && `- ${RIGHT_PANEL_WIDTH}`}
  );
  overflow: auto;
  transition: width ease ${ANIMATE.fast};
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    overflow: inherit;
    padding: 0px;
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const Tabs = styled.div`
  background-color: ${COLOR.white};
  border-bottom: 1px solid ${COLOR.lineGrey};
  @media (max-width: 991px) {
    display: none;
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const TabList = styled.ul`
  display: flex;
  justify-content: center;
  width: 991px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;
`
// language=SCSS prefix=dummy{ suffix=}
export const TabItem = styled.li`
  flex-grow: 1;
  display: inline-block;

  &.isNoticeFocused {
    background-color: white;
  }

  > a {
    color: ${props =>
      props.reportExpired || props.reportNotAvailable
        ? COLOR.placeholderGrey
        : COLOR.textBlack};
    display: block;
    padding: 12px 10px;
    text-align: center;
    border-bottom: 2px solid transparent;

    @media (min-width: 992px) and (max-width: 1067px) {
      padding: 12px 5px;
    }

    &.active {
      border-bottom: 2px solid
        ${props =>
          props.reportExpired || props.reportNotAvailable
            ? COLOR.placeholderGrey
            : COLOR.deepBlue};
      color: ${props =>
        props.reportExpired || props.reportNotAvailable
          ? COLOR.placeholderGrey
          : COLOR.deepBlue};
    }
    &:hover {
      border-bottom: 2px solid
        ${props =>
          props.reportExpired || props.reportNotAvailable
            ? COLOR.titleBlack
            : COLOR.darkDeepBlue};
      color: ${props =>
        props.reportExpired || props.reportNotAvailable
          ? COLOR.titleBlack
          : COLOR.darkDeepBlue};
    }
    .subText {
      display: none;
    }
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const pricingTabWrapper = styled.div`
  background: ${COLOR.white};
  margin-bottom: -40px;
  padding-bottom: 30px;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.2);
  > .pricing-tabs {
    > .nav-tabs {
      > li[role="presentation"] {
        background: ${COLOR.bgGrey};
        width: calc(100% / 3);
        > a[role="tab"] {
          border: none;
          @media (max-width: 991px) {
            padding: 10px 5px;
          }
        }
      }
    }
    > .tab-content {
      > .tab-pane {
        table {
          display: table;
        }
      }
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`
export const mPricingHeader = styled.div``
