import styled from 'styled-components'
import React from 'react'
import { withFormik } from 'formik'
import { object } from 'yup'
import omit from 'lodash/omit'

import tracker from 'tracker'
import { LEASE_DOC_TEMPLATE_EVENT, LRA_INTEGRATION_PROPERTIES } from 'tracker/const'
import { COLOR } from 'styles/settings'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import { ErrorAlert } from '@rentspree/component-v2'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import B16 from '@rentspree/component-v2/dist/typography/b16'
import ToolsTip from 'components/atoms/toolstip'
import InputTextField from '@rentspree/component-v2/dist/input/text'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import { validateNameTU, requiredYup, validateNrdsId } from 'legacy/constants/validations'
import Row from 'components/atoms/row'
import { SCREENING_REQUEST } from 'containers/request/step-screening-options/constants'

const LinkText = styled.a`
  text-decoration: underline !important;
  color: ${COLOR.textBlack};
  :hover {
    color: ${COLOR.grayLv6};
  }
`
const TitleText = styled(S24)`
  font-family: Source Sans Pro, sans-serif;
`
const ModalContainer = styled.div`
  font-family: Source Sans Pro, sans-serif;
`
const ToolTipContainer = styled.span`
  margin-left: -5px;
`

export const ValidateCARModal = ({
  title,
  body,
  isOpen,
  onClose,
  actions,
  error,
  closeError,
  loading,
  loaded,
  linkingFrom,
  ...props
}) => {
  const { values, touched, errors, handleChange, handleBlur, handleReset } = props

  const handleCloseModal = () => {
    handleReset()
    onClose()
  }

  const handleBlurLastName = (e) => {
    props.setFieldValue('lastName', e.target.value.trim())
    handleBlur(e)
  }

  const handleBlurNrdsId = (e) => {
    const validNrdsId = e.target.value.trim().replace(/[^a-zA-Z0-9*\- ]/g, '')
    props.setFieldValue('nrdsId', validNrdsId)
    handleBlur(e)
  }

  const handleClickNRDSID = () =>
    tracker.trackEvent(LEASE_DOC_TEMPLATE_EVENT.EVENT_NAME.LOOK_UP_NRDS_ID, {
      click_from:
        linkingFrom === SCREENING_REQUEST
          ? LRA_INTEGRATION_PROPERTIES.SCREENING_SELECTION
          : LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.TEMPLATE_SELECTION,
    })

  return (
    <RentSpreeBaseModal
      rootId="#app"
      isOpen={isOpen}
      onClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <ModalContainer>
        <form onSubmit={props.handleSubmit}>
          <TitleText margin="0 0 10px">{title}</TitleText>
          {error && (
            <ErrorAlert
              errorMessage={error.message}
              onClose={closeError}
              margin="10px 0"
              close={closeError}
            />
          )}
          <B16>{body}</B16>
          <InputTextField
            id="lastName"
            name="lastName"
            label="Last Name"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlurLastName}
            disabled={loading}
            error={touched.lastName && errors.lastName}
            isRequired
          />
          <InputTextField
            id="brokerName"
            name="brokerInfo.name"
            label="Brokerage Name"
            labelChildren={
              <ToolTipContainer>
                <ToolsTip
                  width="152px"
                  description="Your name and brokerage will be displayed at the footer of all C.A.R. forms."
                  mobileModalPosition={{ top: '0px' }}
                  mobileTitle="Brokerage Name"
                />
              </ToolTipContainer>
            }
            value={values.brokerInfo?.name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={loading}
            error={touched.brokerInfo?.name && errors.brokerInfo?.name}
            isRequired
          />
          <InputTextField
            mb="5"
            id="nrdsId"
            name="nrdsId"
            label="NRDS ID"
            value={values.nrdsId}
            onChange={handleChange}
            onBlur={handleBlurNrdsId}
            disabled={loading}
            error={touched.nrdsId && errors.nrdsId}
            isRequired
          />
          <B16 margin="0 0 30px" size="14px">
            {'Don’t have your NRDS ID? '}
            <LinkText
              id="nrds_id_link"
              onClick={handleClickNRDSID}
              target="_blank"
              href="https://login.connect.realtor/#!/forgotmember"
            >
              Look up NRDS ID
            </LinkText>
          </B16>
          <Row flexEnd>
            <Row width="140px" mWidth="100%">
              <BlueGradientButton
                id="validateCarMemberButtonModal"
                large
                text="Validate Now"
                fontSize="16px"
                semiBold
                type="submit"
                loading={loading}
                loaded={loaded}
              />
            </Row>
          </Row>
        </form>
      </ModalContainer>
    </RentSpreeBaseModal>
  )
}

export const ValidateCARModalForm = withFormik({
  mapPropsToValues: () => ({
    lastName: '',
    nrdsId: '',
    brokerInfo: {
      name: '',
    },
  }),
  validationSchema: object().shape({
    lastName: validateNameTU,
    nrdsId: validateNrdsId(true),
    brokerInfo: object().shape({
      name: requiredYup(true),
    }),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const { linkingFrom } = props

    const payload = omit(
      {
        provider: 'car',
        ...values,
        lastname: values.lastName,
        ...(linkingFrom && { linkingFrom }),
      },
      ['lastName'],
    )

    props.linkingAccount(payload)
    setSubmitting(false)
  },
  displayName: 'ValidateCARModalForm',
})(ValidateCARModal)

export default ValidateCARModalForm
