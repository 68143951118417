import { buildPath } from "@rentspree/path"
import { AGENT_PROFILE_API } from "constants/route"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { apiInstance } from "utils/api-interceptor"
import { getAgentProfileApiState, updateAgentLicenseApiState } from "./actions"
import { GET_AGENT_PROFILE_CALL } from "./constants"

export const getAgentProfileApi = () =>
  apiInstance.get(buildPath(`${AGENT_PROFILE_API}/me`))

export const updateAgentProfileApi = payload =>
  apiInstance.put(buildPath(`${AGENT_PROFILE_API}`), payload)

export function* getAgentProfileSaga() {
  yield put(getAgentProfileApiState.request())
  try {
    const response = yield call(getAgentProfileApi)
    yield put(getAgentProfileApiState.success(response))
  } catch (err) {
    yield put(getAgentProfileApiState.failure(err))
  }
}

export function* updateAgentLicensesSaga(license) {
  const updatingData = {
    experience: {
      agentLicenseNo: license?.number,
      agentLicenseState: "CA",
    },
  }
  const response = yield call(updateAgentProfileApi, updatingData)
  yield put(updateAgentLicenseApiState.success(response))
}

export function* watchGetAgentProfile() {
  yield takeLatest(GET_AGENT_PROFILE_CALL, getAgentProfileSaga)
}

export function* rootSaga() {
  yield all([watchGetAgentProfile()])
}

export default rootSaga
