import produce from 'immer'
import {
  GET_ENVELOPE_PDF_FAILURE,
  GET_ENVELOPE_PDF_SUCCESS,
  GET_ENVELOPE_PDF_REQUEST,
} from './constants'

export const initialState = {
  isLoadingGetPdf: false,
  pdfFile: null,
}

/* eslint-disable default-case, no-param-reassign */
const reportApplicationReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_ENVELOPE_PDF_REQUEST:
        draft.isLoadingGetPdf = true
        break
      case GET_ENVELOPE_PDF_FAILURE:
        draft.isLoadingGetPdf = false
        break
      case GET_ENVELOPE_PDF_SUCCESS:
        draft.isLoadingGetPdf = false
        draft.pdfFile = payload
        break
    }
    return draft
  })

export default reportApplicationReducer
