import React, { useCallback, useRef, useState } from 'react'
import { TextInput, USStateDropdown, FormattedInput, SelectDropdown } from '@rentspree/component-v2'
import { InputWrapper } from 'components/shared/form'

import { FlexBox } from 'legacy/components/layout/section'

import styled from 'styled-components'
import { useGoogleAutoComplete } from 'containers/google-autocomplete/use-google-autocomplete'

import { PROPERTY_TYPES } from './constant'
import { AddressAutocomplete } from '../address-input'

const ReferenceTopAddress = styled.div`
  position: absolute;
  top: 10px;
`
export const AddressForm = props => {
  const { sessionToken, setSessionToken } = props
  const addressTopRef = useRef(null)
  const { addresses, setAddresses, getAddressDetails } = useGoogleAutoComplete(
    sessionToken,
    setSessionToken,
  )

  const [addressDurationStart, setAddressDurationStart] = useState(false)
  const [isAutoFill, setIsAutoFill] = useState(false)

  const handleStartDurationTimer = () => {
    if (!addressDurationStart) {
      setAddressDurationStart(true)

      if (onHandleAutoFilledStartTimer) {
        onHandleAutoFilledStartTimer({ startTimer: new Date() })
      }
    }
  }

  const handleAutoFillUsed = () => {
    if (!isAutoFill) {
      setIsAutoFill(true)

      if (onHandleAutoFilledUsedTracker) {
        onHandleAutoFilledUsedTracker({ isAutoFillValue: true })
      }
    }
  }

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    validateForm,
    setFieldValue,
    setFieldTouched,
    fullSizeForm,
    omittedStates,
    withPropertyType,
    onHandleAutoFilledUsedTracker,
    onHandleAutoFilledStartTimer,
    isSavePropertyClicked,
  } = props
  const autofillAddress = useCallback(
    address => {
      (async () => {
        await setFieldValue('street', address.streetLine)
        await setFieldValue('unitNumber', address.secondary)
        await setFieldValue('city', address.city)
        await setFieldValue('state', address.state)
        await setFieldValue('zipcode', address.zipcode)
        await validateForm()
      })()
    },
    [setFieldValue, validateForm],
  )

  const scrollToTop = () => {
    const address = addressTopRef?.current
    if (address) {
      setTimeout(() => address.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500)
    }
  }

  const disableAutoFill = event => {
    if (onHandleAutoFilledUsedTracker && event?.type === 'change') {
      setIsAutoFill(false)
      onHandleAutoFilledUsedTracker({ isAutoFillValue: false })
    }
  }

  const handleChangeEvent = (onChangeHandler, event) => {
    onChangeHandler(event)
    disableAutoFill(event)
  }

  const handleChangeSetField = (onChangeHandler, event) => {
    onChangeHandler()
    disableAutoFill(event)
  }

  return (
    <>
      <ReferenceTopAddress id="ref-address" ref={addressTopRef} />
      <FlexBox>
        {withPropertyType && (
          <InputWrapper bgTransparent>
            <SelectDropdown
              isRequired
              name="propertyType"
              id="propertyDetailsPropertyType"
              placeholder={values.propertyType}
              value={values.propertyType}
              onChange={val => setFieldValue('propertyType', val)}
              onBlur={() => setFieldTouched('propertyType', true)}
              label="Property Type"
              options={PROPERTY_TYPES}
              error={touched.propertyType && errors.propertyType}
            />
          </InputWrapper>
        )}
        <InputWrapper bgTransparent>
          <AddressAutocomplete
            id="dashboardNewPropertyStreet"
            className="newPropertyInput"
            name="street"
            label="Address"
            value={values.street}
            onDebounceChange={val => setAddresses(val)}
            onValueChange={(val, event) => {
              handleChangeSetField(() => setFieldValue('street', val.trim()), event)
            }}
            onBlur={() => {
              setFieldTouched('street', true)
            }}
            onSelectOption={autofillAddress}
            error={(touched.street || isSavePropertyClicked) && errors.street}
            options={addresses}
            onFocus={() => {
              handleStartDurationTimer()
              scrollToTop()
            }}
            onHandleAutoFilledUsedTracker={handleAutoFillUsed}
            isRequired
            getAddressDetails={getAddressDetails}
          />
        </InputWrapper>
        <InputWrapper bgTransparent>
          <TextInput
            id="dashboardNewPropertyUnitNumber"
            className="newPropertyInput"
            name="unitNumber"
            label="Apartment, unit, suite, or floor #"
            value={values.unitNumber}
            onChange={event => handleChangeEvent(handleChange, event)}
            onBlur={handleBlur}
            error={(touched.unitNumber || isSavePropertyClicked) && errors.unitNumber}
          />
        </InputWrapper>
        <InputWrapper
          data-testid="set-width-wrapper-1"
          width={fullSizeForm ? '151px' : '146px'}
          bgTransparent
        >
          <TextInput
            id="dashboardNewPropertyCity"
            className="newPropertyInput"
            name="city"
            label="City"
            value={values.city}
            onChange={event => handleChangeEvent(handleChange, event)}
            onBlur={e => {
              e.target.value = e.target.value.trim()
              setFieldValue('city', e.target.value)
              handleBlur(e)
            }}
            error={(touched.city || isSavePropertyClicked) && errors.city}
            isRequired
          />
        </InputWrapper>
        <InputWrapper width={fullSizeForm ? '153px' : '148px'} ml="20" bgTransparent>
          <USStateDropdown
            id="dashboardNewPropertyState"
            className="newPropertyInput"
            name="state"
            label="State"
            value={values.state}
            omittedStates={omittedStates}
            onChange={val => {
              handleChangeSetField(() => setFieldValue('state', val), { type: 'change' })
            }}
            onBlur={() => setFieldTouched('state', true)}
            error={(touched.state || isSavePropertyClicked) && errors.state}
            isRequired
          />
        </InputWrapper>
        <InputWrapper width={fullSizeForm ? '156px' : '146px'} ml="20" bgTransparent>
          <FormattedInput
            formatType="zipcode"
            id="dashboardNewPropertyZipcode"
            className="newPropertyInput"
            name="zipcode"
            label="Zip/Postal Code"
            value={values.zipcode}
            onValueChange={(vals, event) => {
              handleChangeSetField(() => setFieldValue('zipcode', vals.value), event)
            }}
            onBlur={handleBlur}
            error={(touched.zipcode || isSavePropertyClicked) && errors.zipcode}
            isRequired
          />
        </InputWrapper>
      </FlexBox>
    </>
  )
}

export default AddressForm
