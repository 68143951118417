import React from "react"
import * as Styled from "./text-style"

export const Text = ({ children, ...props }) => (
  <Styled.textInput {...props}>{children}</Styled.textInput>
)

export const Label = ({ children, ...props }) => (
  <Styled.label {...props}>{children}</Styled.label>
)

export const I = ({ children, ...props }) => (
  <Styled.i {...props}>{children}</Styled.i>
)

export const P = ({ children, ...props }) => (
  <Styled.p {...props}>{children}</Styled.p>
)

export const PNormal = ({ children, ...props }) => (
  <Styled.withP {...props}>{children}</Styled.withP>
)

export const TextDiv = ({ children, ...props }) => (
  <Styled.textDiv {...props}>{children}</Styled.textDiv>
)
