import React from 'react'
import styled from 'styled-components'

import { COLOR, breakpoints } from 'styles/settings'

import { WIDGET_STATUS } from './constants'
import CancelWidget from './widget-content/cancel-widget'
import ErrorWidget from './widget-content/error-widget'
import InProgressWidget from './widget-content/in-progress-widget'
import PartiallySuccessWidget from './widget-content/partially-success-widget'
import SuccessWidget from './widget-content/success-widget'

const WidgetContainer = styled.div`
  position: fixed;
  background: ${COLOR.white};
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  bottom: 20px;
  z-index: 1;
  max-width: 343px;
  width: 100%;
  left: 20px;
  padding: 20px;
  font-family: Source Sans Pro;
  ${breakpoints.mobileContact(`
    left: 0;
    margin: 0 20px;
    max-width: 335px;
    width: -webkit-fill-available;
`)};
`

const WidgetContent = props => {
  switch (props?.status) {
    case WIDGET_STATUS.IN_PROGRESS:
      return <InProgressWidget {...props} />
    case WIDGET_STATUS.SUCCESS:
      return <SuccessWidget {...props} />
    case WIDGET_STATUS.ERROR:
      return <ErrorWidget {...props} />
    case WIDGET_STATUS.PARTIALLY_SUCCESS:
      return <PartiallySuccessWidget {...props} />
    case WIDGET_STATUS.CANCELLED:
      return <CancelWidget {...props} />
    default:
      return <div />
  }
}
const Widget = props => <WidgetContainer>{WidgetContent(props)}</WidgetContainer>

export default Widget
