import generateApiCall from "utils/generate-api-call"

import {
  UPDATE_PROPERTY_LISTING_STATUS,
  TOGGLE_ACCEPTING_STATUS_CALL,
  TOGGLE_ACCEPTING_STATUS_REQUEST,
  TOGGLE_ACCEPTING_STATUS_SUCCESS,
  TOGGLE_ACCEPTING_STATUS_FAILURE,
  GET_APPLY_LINK_CALL,
  GET_APPLY_LINK_REQUEST,
  GET_APPLY_LINK_SUCCESS,
  GET_APPLY_LINK_FAILURE,
} from "./constants"

export const toggleAcceptingStatusActions = generateApiCall([
  TOGGLE_ACCEPTING_STATUS_REQUEST,
  TOGGLE_ACCEPTING_STATUS_SUCCESS,
  TOGGLE_ACCEPTING_STATUS_FAILURE,
])

export const toggleAcceptingStatus = ({ propertyId, disable }) => ({
  type: TOGGLE_ACCEPTING_STATUS_CALL,
  payload: { propertyId, disable },
})

export const toggleListingStatus = disable => ({
  type: UPDATE_PROPERTY_LISTING_STATUS,
  payload: disable ? { disabledAt: Date.now() } : null,
})

export const getApplyLink = payload => ({
  type: GET_APPLY_LINK_CALL,
  payload,
})

export const getApplyLinkActions = generateApiCall([
  GET_APPLY_LINK_REQUEST,
  GET_APPLY_LINK_SUCCESS,
  GET_APPLY_LINK_FAILURE,
])