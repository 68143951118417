import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { MainContent } from 'components/tasks/layout'
import * as PATH from 'constants/route'

import TasksList from './list/loadable'

export const TasksRoute = () => (
  <MainContent>
    <Switch>
      <Route path={[PATH.TASKS_LIST, PATH.TASKS]} component={TasksList} exact />
      <Route
        render={() => {
          window.location.href = '/not-found'
        }}
      />
    </Switch>
  </MainContent>
)

export default TasksRoute
