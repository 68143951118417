import React from "react"
import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"
import styled from "styled-components"
import { S24, S16, BlueGradientButton } from "@rentspree/component-v2"

import { COLOR } from "components/constants"

export const ContentWrapper = styled.div`
  padding: 15px 20px;
  border: 1px solid ${COLOR.newGrey};
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${COLOR.bgGrey};
  margin-bottom: 20px;
`
export const DescriptionWrapper = styled.div`
  b {
    font-weight: 600;
  }
`
export const ModalBodyWrapper = styled.div`
  font-family: Source Sans Pro;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  button {
    margin-left: 20px;
  }

  @media (max-width: 991px) {
    margin: auto auto 0px auto;
    width: fit-content;
    align-items: center;
    flex-direction: column-reverse;

    button {
      margin-left: 0px;
    }
  }
`
const TextButton = styled(S16)`
  font-family: Source Sans Pro;
  cursor: pointer;

  @media (max-width: 991px) {
    margin: 20px 0px 10px 0px;
  }
`

const ConfirmModal = ({
  isOpen,
  title,
  handleClickBtn,
  confirmTxt,
  content,
  cancelTxt,
  handleClickCancelBtn,
  onClose,
}) => (
  <RentSpreeBaseModal
    onClose={onClose}
    id="confirmModal"
    isOpen={isOpen}
    closeButton={false}
    shouldCloseOnOverlayClick={false}
    modalSize="small">
    <ModalBodyWrapper>
      <S24 margin="0px 0px 10px 0px" mMargin="0px 15px 10px 0px" mSize="24px">
        {title}
      </S24>
      <DescriptionWrapper>{content}</DescriptionWrapper>
      <ButtonWrapper>
        <TextButton id="cancelBtn" onClick={handleClickCancelBtn}>
          {cancelTxt}
        </TextButton>
        <BlueGradientButton
          text={confirmTxt}
          onClick={handleClickBtn}
          small
          semiBold
          height="40px"
          id="smBtn"
          fontSize="16px"
          width="122px"
        />
      </ButtonWrapper>
    </ModalBodyWrapper>
  </RentSpreeBaseModal>
)

export default ConfirmModal
