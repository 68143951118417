import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { withFormik } from 'formik'
import { buildPath, query } from '@rentspree/path'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { TextInput } from '@rentspree/component-v2'

import { PERMALINK_ACTIONS_ID } from 'constants/route'
import { Div } from 'components/layout/main'
import { ButtonSet } from 'components/buttons/button-set'
import SubtitleWithHr from 'components/molecules/subtitle-with-hr'
import PrefixInputInserter from 'components/render-props/input-with-prefix'
import { RequestContainer } from 'legacy/components/request/request-main-style'
import { LICENSE_TYPES } from 'constants/user'
import useTrackEnterPage from 'tracker/use-track-enter-page'
import { LRA_INTEGRATION } from 'tracker/const'
import { withReducer } from 'containers/request/step-update-dre-license/connect'
import { withUserPreferenceData } from 'utils/tenant-screening/with-user-preference-data'
import { dreLicenseSchema } from './schema'
import { withConnect, withSaga } from './connect'

export const StepUpdateDreLicense = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleBlur,
  handleChange,
  handleBackToDashboard,
  history,
  location,
  match,
  isUpdatingDreLicense,
  requestOptions,
}) => {
  useTrackEnterPage(LRA_INTEGRATION.ENTER_DRE_LICENSE_PAGE)
  const { search } = location
  const { screeningRequestId } = query.parse(search)
  const { propertyId } = match.params || {}
  const { payerType } = requestOptions
  if (!screeningRequestId || !payerType)
    history.push(buildPath(PERMALINK_ACTIONS_ID, { propertyId }))

  return (
    <RequestContainer>
      <SubtitleWithHr text="DRE License" />
      <Div mb="30">
        <PrefixInputInserter onChange={handleChange} prefix="#">
          {({ onChange }) => (
            <TextInput
              id="updateDreLicenseInput"
              data-testid="update-Dre-License-input"
              name="dreLicense"
              label="Your DRE license number"
              placeholder="#CA12345"
              value={values.dreLicense}
              error={touched.dreLicense && errors.dreLicense}
              onChange={onChange}
              onBlur={handleBlur}
              disabled={isUpdatingDreLicense}
              isRequired
            />
          )}
        </PrefixInputInserter>
      </Div>
      <ButtonSet
        id="updateDreLicenseButtonSet"
        minWidth="100"
        margin="0 0"
        continueBtnType="submit"
        continueText="Save & Next"
        btnHeight="40px"
        hidePrevButton
        text="Back to screening"
        disabledNextButton={isEmpty(values.dreLicense) || !isEmpty(errors)}
        onClickBackToDashboardBtn={handleBackToDashboard}
        onSaveAndNext={handleSubmit}
        isLoading={isUpdatingDreLicense}
        dataTestKey="update-dre-license-page"
      />
    </RequestContainer>
  )
}

export const StepUpdateDreLicenseForm = withFormik({
  mapPropsToValues: props => dreLicenseSchema(props).mapper,
  validationSchema: props => dreLicenseSchema(props).validation,
  handleSubmit: (values, { props }) => {
    const { actions, match } = props
    const { screeningRequestId } = query.parse(props.location.search)
    const { propertyId } = match.params || {}
    const { dreLicense } = values
    const licenses = [
      {
        type: LICENSE_TYPES.DRE,
        number: dreLicense,
      },
    ]
    actions.updateDreLicense({
      licenses,
      propertyId,
      requestOptions: props.requestOptions,
      screeningRequestId,
      getNextPath: props.getNextPath,
    })
  },
  enableReinitialize: true,
  displayName: 'StepUpdateDreLicenseForm',
})(StepUpdateDreLicense)

export default compose(
  withRouter,
  withConnect,
  withReducer,
  withSaga,
  withUserPreferenceData,
)(StepUpdateDreLicenseForm)
