import styled from 'styled-components'

import { COLOR } from 'components/constants'

export const Wrapper = styled.div`
  margin-bottom: 150px;
  @media (max-width: 1016px) {
    display: ${props => (props.hideMobile ? 'none' : 'block')};
    margin-bottom: unset;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100vh - 162px);
  width: 100%;
  z-index: 5;
  background: #fff;
`

export const MobileTabContent = styled.div`
  text-align: center;
  text-align: -webkit-center;
  display: ${props => (props.hidden ? 'none' : 'block')};
  ${({ padding }) => padding && `padding: ${padding};`}
  @media (min-width: 1016px) {
    display: none;
  }
`

export const MobileReportList = styled.ul`
  margin: 0;
  width: 100%;
  max-width: 616px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${COLOR.white};
  &.add-bottom-width {
    margin-bottom: 45px;
  }
`
