import { combineReducers } from "redux"
import isEmpty from "lodash/isEmpty"
import isEqual from "lodash/isEqual"
import findIndex from "lodash/findIndex"
import get from "lodash/get"
import { ACCEPT_DENY_SUCCESS } from "containers/accept-deny/constants"
import { isRead } from 'legacy/helpers/credit-report'
import * as Types from "../constants/action-types"
import * as CREDIT_REPORT from "../constants/credit-report-consts"
import {
  APP_DOCUMENT_STATUS,
  APP_STATUS,
  APP_NOT_SUBMIT_STATUS,
} from "../constants/application-consts"
import { RENTAL_STATUS } from "../constants/rental-consts"
import { PAYER } from "../constants/request-const"
import { reducerError } from "./helper/handle-reducer"
import { convert } from "../components/helper/convert"

const NOT_APPLICABLE = "N/A"

const MAP_CREDIT_SCORE = {
  credit_report: "creditScore",
  criminal: "criminalRecords",
  eviction: "evictionRecords",
  application: "monthlyIncome",
}

const CHECK_EVER_SUBMITTED = status =>
  !isEqual(status, APP_STATUS.started.value) &&
  !isEqual(status, APP_STATUS.requested.value)

const CHECK_IS_SUBMITTED = status => isEqual(status, APP_STATUS.submitted.value)

const findStatus = ({
  credit_report: creditReport,
  document_status: documentStatus,
  isIDMAExpired,
  status,
  isExpired,
}) => {
  if (APP_NOT_SUBMIT_STATUS.includes(status)) return status
  if (isExpired) return "expired"

  const payerType = get(creditReport, "screening_option.payerType")
  const creditReportStatus = get(creditReport, "status")

  const isSubmissionExpired =
    isIDMAExpired &&
    [RENTAL_STATUS.SUBMITTED, RENTAL_STATUS.PROPERTY_REQUIRED].includes(
      status,
    ) &&
    creditReportStatus !== "opened"

  if (isSubmissionExpired)
    return payerType === PAYER.AGENT
      ? APP_STATUS.submission_expired_landlord_pay.value
      : APP_STATUS.submission_expired_renter_pay.value

  const isDocStatusNotEmpty =
    documentStatus && documentStatus !== APP_DOCUMENT_STATUS.empty.value

  if (isDocStatusNotEmpty)
    return documentStatus === APP_DOCUMENT_STATUS.requested_doc.value
      ? "requested_doc"
      : documentStatus
  return status
}
const findMonthlyIncome = (monthlyIncome, status) => {
  const hasMonthlyIncome = monthlyIncome || monthlyIncome === 0
  /**
   * For the renter ever submitted will showing empty monthly income
   * and will showing `N/A` on the landlord that receive report without application
   * by the API returns monthly income is null when that report didn't share application
   */
  if (hasMonthlyIncome && CHECK_EVER_SUBMITTED(status))
    return convert(monthlyIncome, "$")
  if (CHECK_IS_SUBMITTED(status)) return "N/A"
  return ""
}

export const applicationListSuccessTransform = item => {
  const oldStatus = item.status
  const status = findStatus(item)
  const hasCreditReport = !isEmpty(item.credit_report)
  const isCreditReportHasScore =
    hasCreditReport && !isEmpty(item.credit_report.score)

  const getNotApplicable = screeningOption =>
    Object.keys(screeningOption)
      .filter(
        k => !screeningOption[k] || (!hasCreditReport && k !== "application"),
      )
      .reduce(
        (p, k) => ({
          ...p,
          [MAP_CREDIT_SCORE[k]]: NOT_APPLICABLE,
        }),
        {},
      )
  const isShowAcceptDenyButton = isRead(item) && (get(item, 'screeningOption.application', false) || item.isExpired === false)

  return {
    ...item,
    status,
    ...(isCreditReportHasScore && {
      creditScore: item.credit_report.score.creditScore,
      criminalRecords: item.credit_report.score.criminalRecords,
      evictionRecords: item.credit_report.score.evictionRecords,
    }),
    /**
     * For the renter ever submitted will showing empty monthly income
     * and will showing `N/A` on the landlord that receive report without application
     * by the API returns monthly income is null when that report didn't share application
     */
    monthlyIncome: findMonthlyIncome(item.monthlyIncome, oldStatus),
    isShowAcceptDenyButton,
    ...getNotApplicable(item.screeningOption || {}),
  }
}

const list = (state = [], action) => {
  switch (action.type) {
    case Types.GET_APPLICATION_LIST_SUCCESS:
      return action.data
        ? action.data.map(applicationListSuccessTransform)
        : action.data
    case ACCEPT_DENY_SUCCESS: {
      const index = findIndex(state, { _id: get(action, "payload._id") })
      if (index < 0) return state
      return [
        ...state.slice(0, index),
        {
          ...state[index],
          screeningResult: get(action, "payload.screeningResult", {}),
        },
        ...state.slice(index + 1),
      ]
    }

    case Types.UPDATE_STATUS_CREDIT_REPORT:
      if (!isEmpty(state)) {
        return state.map(s => {
          if (get(s, "_id") === action.rentalId && get(s, "credit_report")) {
            return {
              ...s,
              credit_report: {
                ...get(s, "credit_report"),
                status: CREDIT_REPORT.STATUS.REQUESTED,
              },
            }
          }
          return s
        })
      }
      return state
    case Types.CLEAR_PROPERTY:
      return []
    default:
      return state
  }
}

const pagination = (state = {}, action) => {
  switch (action.type) {
    case Types.GET_APPLICATION_LIST_SUCCESS:
      return action.pagination
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case Types.GET_APPLICATION_LIST_REQUEST:
    case Types.DEACTIVATE_PROPERTY_REQUEST:
    case Types.REACTIVATE_PROPERTY_REQUEST:
      return true
    case Types.GET_APPLICATION_LIST_SUCCESS:
    case Types.GET_APPLICATION_LIST_FAILED:
    case Types.DEACTIVATE_PROPERTY_SUCCESS:
    case Types.DEACTIVATE_PROPERTY_FAILED:
    case Types.REACTIVATE_PROPERTY_SUCCESS:
    case Types.REACTIVATE_PROPERTY_FAILED:
      return false
    default:
      return state
  }
}

const error = (...arg) =>
  reducerError(...arg, [
    Types.GET_APPLICATION_LIST_FAILED,
    Types.CLEAR_PROPERTY,
  ])

export default combineReducers({
  list,
  pagination,
  isFetching,
  error,
})
