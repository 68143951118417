import React from 'react'

import IncomeVerificationErrorPage from 'components/reports/screening-reports/income-verification/error-page'
import { IncomeVerificationReportComponent } from 'components/reports/screening-reports/income-verification'
import {
  CONTACT_SUPPORT_PAGE_CONTENT,
  INCOME_VERIFICATION_REPORT_STATUS,
  REPORT_NOT_READY_PAGE_CONTENT,
  REPORT_UNAVAILABLE_PAGE_CONTENT,
} from '../constants'

export const ReportHandler = ({
  reportData,
  applicantDetail,
  handleManageDocumentButton,
  handleRetryButton,
  isFromAllTab,
  reportStatus,
  handleClickContactSupport,
}) => {
  switch (reportStatus) {
    case null: {
      return null
    }
    case INCOME_VERIFICATION_REPORT_STATUS.IN_PROGRESS: {
      return (
        <IncomeVerificationErrorPage
          pageContent={REPORT_NOT_READY_PAGE_CONTENT}
          onCtaClick={handleRetryButton}
          isFromAllTab={isFromAllTab}
          applicantDetail={isFromAllTab && applicantDetail}
        />
      )
    }
    case INCOME_VERIFICATION_REPORT_STATUS.FAILURE: {
      return (
        <IncomeVerificationErrorPage
          pageContent={REPORT_UNAVAILABLE_PAGE_CONTENT}
          onCtaClick={handleManageDocumentButton}
          isFromAllTab={isFromAllTab}
          applicantDetail={isFromAllTab && applicantDetail}
        />
      )
    }
    case INCOME_VERIFICATION_REPORT_STATUS.SUCCESS: {
      return <IncomeVerificationReportComponent reportData={reportData} isFromAllTab={isFromAllTab}/>
    }
    default: {
      return (
        <IncomeVerificationErrorPage
          pageContent={CONTACT_SUPPORT_PAGE_CONTENT}
          onLinkClick={handleClickContactSupport}
          isFromAllTab={isFromAllTab}
          applicantDetail={isFromAllTab && applicantDetail}
        />
      )
    }
  }
}
