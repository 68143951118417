import { BlueGradientButton } from "@rentspree/component-v2"
import { TableCss } from "components/table/table-style"
import styled from "styled-components"
import { COLOR } from "styles/settings"

export const TitleWrapper = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const ActionButton = styled(BlueGradientButton)`
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 25px;
  height: 35px;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 920px;
  font-family: Source Sans Pro;
  @media (max-width: 991px) {
    max-width: 616px;
  }
`

export const TextError = styled.p`
  margin: 0px;
  margin-top: 5px;
  color: ${COLOR.negativeRed};
  font-weight: 600;
  line-height: 20px;
`

export const THead = styled.thead`
  width: 100%;
  font-family: Source Sans Pro;
  font-size: 16px;
`
export const TBody = styled.tbody`
  width: 100%;
`

export const Table = styled.table`
  ${TableCss};
  width: 100%;
  @media (max-width: 767px) {
    display: block;
    tbody {
      display: block;
      overflow: scroll;
    }
    thead > tr > th,
    tbody > tr > td {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
  margin-bottom: 0;
  thead > tr > th,
  tbody > tr > td {
    @media (max-width: 991px) {
      &.icon-col {
        display: table-cell;
      }
    }
  }

  tbody > tr > td {
    vertical-align: middle;
  }
  thead > tr > th {
    border-top: none;
  }

  .other-docs-request {
    margin: -3px 0;
  }
`
