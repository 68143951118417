import { API_URL } from 'env'

export const CREATE_ASSOCIATION_CONTACT_CALL = 'CREATE_ASSOCIATION_CONTACT_CALL'
export const CREATE_ASSOCIATION_CONTACT_REQUEST = 'CREATE_ASSOCIATION_CONTACT_REQUEST'
export const CREATE_ASSOCIATION_CONTACT_SUCCESS = 'CREATE_ASSOCIATION_CONTACT_SUCCESS'
export const CREATE_ASSOCIATION_CONTACT_FAILURE = 'CREATE_ASSOCIATION_CONTACT_FAILURE'

export const EDIT_ASSOCIATION_CONTACT_CALL = 'EDIT_ASSOCIATION_CONTACT_CALL'
export const EDIT_ASSOCIATION_CONTACT_REQUEST = 'EDIT_ASSOCIATION_CONTACT_REQUEST'
export const EDIT_ASSOCIATION_CONTACT_SUCCESS = 'EDIT_ASSOCIATION_CONTACT_SUCCESS'
export const EDIT_ASSOCIATION_CONTACT_FAILURE = 'EDIT_ASSOCIATION_CONTACT_FAILURE'

export const GET_ASSOCIATION_CONTACT_CALL = 'GET_ASSOCIATION_CONTACT_CALL'
export const GET_ASSOCIATION_CONTACT_REQUEST = 'GET_ASSOCIATION_CONTACT_REQUEST'
export const GET_ASSOCIATION_CONTACT_SUCCESS = 'GET_ASSOCIATION_CONTACT_SUCCESS'
export const GET_ASSOCIATION_CONTACT_FAILURE = 'GET_ASSOCIATION_CONTACT_FAILURE'

export const ASSOCIATION_CONTACT_API = `${API_URL}/api/v2/associations`
export const ASSOCIATION_GET_CONTACT_API = `${ASSOCIATION_CONTACT_API}/properties/:propertyId/contacts?roles[]=landlord`
export const ASSOCIATION_CREATE_CONTACT_API = `${ASSOCIATION_CONTACT_API}/properties/:propertyId/contact`
export const ASSOCIATION_PUT_CONTACT_API = `${ASSOCIATION_CONTACT_API}/properties/:propertyId/contact/:contactId`

export const ASSOCIATION_CONTACT_ROLE = {
  LANDLORD: 'landlord',
}

export const ASSOCIATION_CONTACT_SOURCE = {
  PROPERTY_DETAIL: 'property-detail',
  SHARE_REPORT: 'share-report',
}
