import styled from 'styled-components'

import { COLOR } from 'styles/settings'

export const MainContent = styled.div`
  background-color: ${COLOR.whiteContainer};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
