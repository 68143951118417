import produce from "immer"
import {
  UPDATE_PROPERTY_TYPE_REQUEST,
  UPDATE_PROPERTY_TYPE_SUCCESS,
  UPDATE_PROPERTY_TYPE_FAILED,
  CLEAR_PROPERTY_TYPE_ERROR,
} from "./const"
export const initialState = {
  isFetching: false,
  propertyTypeData: [],
  isError: false,
  error: null,
  isShowConfirmModal: false,
  propertyType: "Single family Home",
}

/* eslint-disable default-case, no-param-reassign */
const propertyTypeReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case UPDATE_PROPERTY_TYPE_REQUEST:
        draft.isFetching = true
        break
      case UPDATE_PROPERTY_TYPE_SUCCESS:
        draft.isFetching = false
        draft.propertyTypeData = payload.data
        draft.isError = false
        break
      case UPDATE_PROPERTY_TYPE_FAILED:
        draft.isFetching = false
        draft.isError = true
        draft.error = payload.error
        draft.isShowConfirmModal = false
        break
      case CLEAR_PROPERTY_TYPE_ERROR:
        draft.isError = false
        draft.error = null
        break
      default:
        break
    }
    return draft
  })

export default propertyTypeReducer
