import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useQueryStrings } from 'hooks'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import tracker from 'tracker'
import TenantScreeningRequestStepOptionTemplateV2 from 'components/tenant-screening-request-step-option-v2.2/templates'
import CriminalDisclaimerModal from 'components/modal/criminal-disclaimer-modal'
import ValidateCARModal from 'components/modal/validate-car-modal'
import { LoadingOverlay } from 'legacy/components/request/left-content'
import { withSubscriptionData } from 'utils/subscription/with-subscription-data'

import { VALIDATE_CAR_MODAL } from 'containers/envelope/select-options-step/constants'
import {
  withSaga as withStepScreeningOptionSaga,
  withReducer as withStepScreeningOptionReducer,
} from 'containers/request/step-screening-options/connect'
import { SCREENING_REQUEST_SOURCE } from 'containers/constants'

import AnnouncementModal from 'components/tenant-screening-request-step-option-v2.2/molecules/income-verification-announcement-modal/announcement-modal'
import AnnouncementSheet from 'components/tenant-screening-request-step-option-v2.2/molecules/income-verification-announcement-modal/announcement-sheet'
import { useQuerySeenAnnouncements } from 'v3/hooks/use-query-seen-announcement'
import { useMutateMySeenAnnouncement } from 'v3/hooks/use-mutate-seen-announcement'
import { useMediaQuery } from 'v3/hooks/use-media-query'
import CustomDocument from 'components/tenant-screening-request-step-option-v2.2/organisms/custom-document/custom-document'
import isBoolean from 'lodash/isBoolean'
import {
  APPLICATION_TYPE,
  INCOME_VERIFICATION_ANNOUNCEMENT_MODAL_CONTENT,
  USER_EXPERIENCE,
  INCOME_VERIFICATION_ANNOUNCEMENT_TYPE,
  INCOME_VERIFICATION_ANNOUNCEMENT_SEEN_GROUP,
  DEFAULT_DOCUMENT_OPTIONS,
  SAMPLE_INCOME_REPORT_URL,
} from './constants'
import { withSaga, withConnect, withReducer } from './connect'
import { selectPriceSchema, selectProperty, selectRequestStepControl } from './selectors'
import {
  trackConfirmScreeningOption,
  trackEnterSelectOptionPage,
  trackRequestOtherDocs,
  trackRequestRefChecks,
  trackSelectApplicationPayer,
  trackSelectApplicationType,
  trackViewIncomeVerificationAnnouncementModal,
  trackingViewSampleIncomeReport,
  trackingClickGetReportCTA,
  trackingOnCloseModal,
} from './tracker'
import { shouldRedirectToSelectAgentTypePage } from '../request/utils'

const { customizeDocumentRequest } = EVENT_REQUEST_SCREENING

const RequestStepOptions = props => {
  const selectedProperty = useSelector(selectProperty)
  const selectedRequestStepControl = useSelector(selectRequestStepControl)
  const selectedPriceSchema = useSelector(selectPriceSchema)
  const [isShowCriminalDisclaimerModal, setIsShowCriminalDisclaimerModal] = useState(false)
  const [selectedDocs, setSelectedDocs] = useState(DEFAULT_DOCUMENT_OPTIONS)
  const [isDisableSaveCustomDocument, setIsDisableSaveCustomDocument] = useState(false)
  const [bundlePrice] = useState({ premium: 0, standard: 0, applicationOnly: 0 })
  const [totalPrice, setTotalPrice] = useState(0)
  const { origin } = useQueryStrings() || {}

  const {
    actions,
    match,
    location,
    isAcceptedBackgroundDisclaimer,
    isCarWithPartnerIDIntegrated,
    history,
    carModalState,
    carLinkingAccountLoading,
    carLinkingAccountSuccess,
    carLinkingAccountError,
    requestOptions,
    agentLicenses,
    getNextPath,
    screeningPlan,
    source = SCREENING_REQUEST_SOURCE.PROPERTY,
    isFetchingScreeningPlan,
    isFetchingUserPreference,
    userPlan,
    userExperience,
    userPreference,
  } = props

  const { propertyId } = match.params
  const isMobile = useMediaQuery('(max-width: 881px)')
  const { agentType, screeningRequest: screeningRequestPreference } = useQueryStrings() || {}
  const isScreeningWithoutProperty = isNil(propertyId)
  const [isShowIncomeVerificationAnnouncementModal, setIsShowIncomeVerificationAnnouncementModal] =
    useState(false)
  const [isShowCustomDocument, setIsShowCustomDocument] = useState(false)

  // Income verification Announcement path
  const { data: seenAnnouncements, isLoading: isSeenAnnouncementLoading } =
    useQuerySeenAnnouncements()
  const mutateSeen = useMutateMySeenAnnouncement({
    announcementType: INCOME_VERIFICATION_ANNOUNCEMENT_TYPE,
    groups: [INCOME_VERIFICATION_ANNOUNCEMENT_SEEN_GROUP],
  })

  const isLoading = isFetchingScreeningPlan || isFetchingUserPreference || isSeenAnnouncementLoading

  useEffect(() => {
    if (
      !isFetchingUserPreference &&
      isBoolean(userPreference?.defaultScreeningOption?.premium) &&
      !isSeenAnnouncementLoading &&
      !isEmpty(selectedPriceSchema)
    ) {
      const hasSeenAnnouncement = Object.keys(seenAnnouncements.seen_groups).includes(
        'income-verification-announcement-modal',
      )

      if (
        !userPreference.defaultScreeningOption.income &&
        !hasSeenAnnouncement &&
        !selectedPriceSchema?.premium?.disabled
      ) {
        setIsShowIncomeVerificationAnnouncementModal(true)
        trackViewIncomeVerificationAnnouncementModal()
      } else {
        setIsShowIncomeVerificationAnnouncementModal(false)
      }
      if (!isEmpty(userPreference.defaultScreeningOption.documentOption)) {
        setSelectedDocs(userPreference.defaultScreeningOption.documentOption)
      }
    }
  }, [isFetchingUserPreference, isSeenAnnouncementLoading])

  useEffect(() => {
    // Without the payload, property will be cleared when change to request step action page
    actions.clearProperty({ isCalledFromUseEffect: true })
    actions.getScreeningLandingRequest({
      propertyId,
      isScreeningWithoutProperty,
      screeningRequestPreference,
    })
    actions.getLinkAccountDataCall()
    actions.setSource(source)
    Sentry.configureScope(scope => {
      scope.setTag('component', 'ScreeningRequestV2')
    })

    return () => {
      Sentry.configureScope(scope => {
        scope.setTag('component', null)
      })
    }
  }, [])

  useEffect(() => {
    if (userExperience) {
      trackEnterSelectOptionPage({
        userPlan,
        isFirstTimeUser: userExperience !== USER_EXPERIENCE.SCREENING_V2_EXPERIENCED,
      })
    }
  }, [userExperience])

  useEffect(() => {
    calculateTotalPrice()
  }, [requestOptions])

  useEffect(() => {
    actions.updateUserPlan(userPlan)
  }, [userPlan])

  useEffect(() => {
    if (!isEmpty(selectedPriceSchema)) {
      const updateStepControlPayload = {}
      updateStepControlPayload.creditReport = !selectedPriceSchema?.creditReport?.disabled
      updateStepControlPayload.eviction = !selectedPriceSchema?.eviction?.disabled
      updateStepControlPayload.criminal = !selectedPriceSchema?.criminal?.disabled
      updateStepControlPayload.premium = !selectedPriceSchema?.premium?.disabled
      updateStepControlPayload.isPro = userPlan === 'PRO'
      actions.updateRequestStepControl(updateStepControlPayload)
    }
    calculateTotalPrice()
  }, [selectedPriceSchema])

  useEffect(() => {
    const updateStepControlPayload = {}
    updateStepControlPayload.CAR = !isScreeningWithoutProperty
    actions.updateRequestStepControl(updateStepControlPayload)
  }, [propertyId, selectedProperty])

  const calculateTotalPrice = () => {
    const creditReportPrice = get(selectedPriceSchema, 'creditReport.price', 0)
    const criminalPrice = get(selectedPriceSchema, 'criminal.price', 0)
    const evictionPrice = get(selectedPriceSchema, 'eviction.price', 0)
    const premiumPrice = get(selectedPriceSchema, 'premium.price', 0)

    let price = 0
    if (requestOptions.creditReport) {
      price += creditReportPrice
    }
    if (requestOptions.incomeVerificationOption && userPlan !== 'PRO') {
      price += premiumPrice
    }
    if (requestOptions.criminal) {
      price += criminalPrice
    }
    if (requestOptions.eviction) {
      price += evictionPrice
    }
    setTotalPrice(price)
  }

  const createScreeningRequest = () => {
    if (isScreeningWithoutProperty) {
      actions.createScreeningRequestWithoutProperty({
        emails: history.location?.state?.emails,
        agentType,
        origin,
        userPlan,
      })
    } else {
      actions.createScreeningRequest({
        emails: history.location?.state?.emails,
        agentType,
        location,
        requestOptions,
        agentLicenses,
        getNextPath,
        userPlan,
      })
    }

    trackConfirmScreeningOption({ propertyId, requestOptions })
    trackRequestOtherDocs({ requestOptions, propertyId, userPlan })
    trackRequestRefChecks({ propertyId, requestOptions, userPlan })
  }

  const confirmCriminalDisclaimerModal = () => {
    setIsShowCriminalDisclaimerModal(false)
    createScreeningRequest()
  }

  const clickDoNotAskCheckBox = () => {
    actions.acceptedBackgroundDisclaimer(!isAcceptedBackgroundDisclaimer)
  }

  const onSaveAndNext = () => {
    if (
      requestOptions.applicationType === APPLICATION_TYPE.CAR_LRA &&
      !isCarWithPartnerIDIntegrated
    ) {
      actions.setCarModalState(true)
      return
    }

    if (
      selectedPriceSchema?.criminal?.disabled ||
      isAcceptedBackgroundDisclaimer ||
      !requestOptions?.criminal
    ) {
      createScreeningRequest()
    } else {
      setIsShowCriminalDisclaimerModal(true)
    }
  }

  const onClickPrevBtn = () => {
    history.goBack()
  }

  const onSelectPayer = payerType => {
    actions.selectPayer({ payerType })
    trackSelectApplicationPayer({ propertyId, payerType })
  }

  const onSelectApplication = applicationType => {
    actions.selectApplication({ applicationType })
    if (applicationType === APPLICATION_TYPE.CAR_LRA) {
      handleSelectCARLRAApplication()
    }
    trackSelectApplicationType({ propertyId, applicationType })
  }

  const handleSelectCARLRAApplication = () => {
    if (!isCarWithPartnerIDIntegrated) {
      // TODO: Refactor this after fixing unexpected reload component after validated CAR. Update user preference to prevent option change after reloading
      actions.updateUserPreference({ userPlan })
      actions.setCarModalState(true)
    }
  }

  const onSelectCreditReportItems = creditReportItems => {
    actions.selectCreditReportItems({ creditReportItems, userPlan })
  }

  const handleSelectIncomeVerification = () => {
    actions.updateIncomeVerification()
  }

  const handleViewCustomDocument = () => {
    setIsShowCustomDocument(true)
    tracker.trackEvent(
      customizeDocumentRequest.name,
      customizeDocumentRequest.properties({
        clickFrom: customizeDocumentRequest.propValues.clickFrom.selectOptionPage,
      }),
    )
  }

  const handleCloseAnnouncementModal = (event, reason) => {
    // Don't allow click outside dialog to close
    if (reason && reason === 'backdropClick') return
    mutateSeen.mutate()
    setIsShowIncomeVerificationAnnouncementModal(false)
    trackingOnCloseModal()
  }

  const handleGetReportCTAInAnnouncementModal = () => {
    handleSelectIncomeVerification()
    mutateSeen.mutate()
    setIsShowIncomeVerificationAnnouncementModal(false)
    trackingClickGetReportCTA()
  }

  const handleViewSampleReport = () => {
    window.open(SAMPLE_INCOME_REPORT_URL)
    trackingViewSampleIncomeReport()
  }

  const onCloseCustomDocument = () => {
    if (!isEmpty(userPreference?.defaultScreeningOption?.documentOption)) {
      setSelectedDocs(userPreference?.defaultScreeningOption?.documentOption)
    } else {
      setSelectedDocs(DEFAULT_DOCUMENT_OPTIONS)
    }
    setIsShowCustomDocument(false)
  }

  const onClickSaveCustomDocument = () => {
    setSelectedDocs(selectedDocs)
    actions.saveCustomDocument(selectedDocs)
    setIsShowCustomDocument(false)
  }

  const onClickBackCustomDocument = () => {
    onCloseCustomDocument()
  }

  const onUpdateCustomDocumentForm = (documentKey, value) => {
    const currentDocuments = [...selectedDocs]
    const targetDoc = currentDocuments.find(doc => doc.type === documentKey)
    const indexOfTargetDoc = currentDocuments.indexOf(targetDoc)
    if (indexOfTargetDoc !== -1) {
      currentDocuments[indexOfTargetDoc] = value
    } else {
      currentDocuments.push(value)
    }
    const selectedDocList = currentDocuments.filter(item => item !== null)
    setIsDisableSaveCustomDocument(selectedDocList.length < 1)
    setSelectedDocs(selectedDocList)
  }

  if (isLoading) {
    return <LoadingOverlay />
  }

  const { shouldRedirect, redirectPath } = shouldRedirectToSelectAgentTypePage({
    agentType,
    propertyId,
  })
  if (shouldRedirect) {
    return <Redirect push to={redirectPath} />
  }

  return (
    <>
      <TenantScreeningRequestStepOptionTemplateV2
        userPlan={userPlan}
        bundlePrice={bundlePrice}
        totalPrice={totalPrice}
        selectRequestStepControl={selectedRequestStepControl}
        requestOptions={requestOptions}
        property={selectedProperty}
        screeningPlan={screeningPlan}
        priceSchema={selectedPriceSchema}
        userExperience={userExperience}
        handleSaveAndNext={onSaveAndNext}
        handleClickPrevBtn={onClickPrevBtn}
        handleSelectPayer={onSelectPayer}
        handleSelectApplication={onSelectApplication}
        handleSelectCreditReportItems={onSelectCreditReportItems}
        handleSelectIncomeVerification={handleSelectIncomeVerification}
        handleViewCustomDocument={handleViewCustomDocument}
      />
      <CriminalDisclaimerModal
        closeButton
        show={isShowCriminalDisclaimerModal}
        backdrop="static"
        isSelected={isAcceptedBackgroundDisclaimer}
        onCheckBoxClick={clickDoNotAskCheckBox}
        closeModal={() => setIsShowCriminalDisclaimerModal(false)}
        onConfirmClick={confirmCriminalDisclaimerModal}
      />
      <ValidateCARModal
        id="validateCarMemberModal"
        isOpen={carModalState}
        title={VALIDATE_CAR_MODAL.TITLE}
        body={VALIDATE_CAR_MODAL.DESCRIPTION}
        linkingAccount={actions.linkAccountWithoutSSO}
        onClose={() => {
          actions.setCarModalState(false)
          actions.selectApplication({ applicationType: APPLICATION_TYPE.NO_APPLICATION })
          trackSelectApplicationType(APPLICATION_TYPE.NO_APPLICATION)
        }}
        loading={carLinkingAccountLoading}
        error={isEmpty(carLinkingAccountError) ? false : carLinkingAccountError}
        loaded={carLinkingAccountSuccess}
        linkingFrom="SCREENING_REQUEST"
      />
      <CustomDocument
        isMobile={isMobile}
        isDisableSave={isDisableSaveCustomDocument}
        selectedDocuments={selectedDocs}
        isShowCustomDocument={isShowCustomDocument}
        handleClose={onCloseCustomDocument}
        handleSave={onClickSaveCustomDocument}
        handleBack={onClickBackCustomDocument}
        handleUpdateCustomDocumentForm={onUpdateCustomDocumentForm}
      />
      {isMobile ? (
        <AnnouncementSheet
          isOpen={isShowIncomeVerificationAnnouncementModal}
          detailText={INCOME_VERIFICATION_ANNOUNCEMENT_MODAL_CONTENT}
          onClickCTAGetReport={handleGetReportCTAInAnnouncementModal}
          onClose={handleCloseAnnouncementModal}
          onViewSampleReport={handleViewSampleReport}
        />
      ) : (
        <AnnouncementModal
          isOpen={isShowIncomeVerificationAnnouncementModal}
          detailText={INCOME_VERIFICATION_ANNOUNCEMENT_MODAL_CONTENT}
          onClickCTAGetReport={handleGetReportCTAInAnnouncementModal}
          onClose={handleCloseAnnouncementModal}
          onViewSampleReport={handleViewSampleReport}
        />
      )}
    </>
  )
}

export default compose(
  withConnect,
  withSaga,
  withReducer,
  withSubscriptionData,
  withStepScreeningOptionSaga,
  withStepScreeningOptionReducer,
)(RequestStepOptions)

export { RequestStepOptions }
