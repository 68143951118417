import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { GET_PAYMENT_SUMMARY_API } from './constants'

export const getPaymentSummaryInstance = ({ statuses, timeFilter }) =>
  apiInstanceWithErrorHandler.get(
    buildPath(GET_PAYMENT_SUMMARY_API, {}, { statuses: statuses.join(','), ...timeFilter }),
  )
