import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState, CREATE_PROPERTY_KEY } from 'v3/containers/create-property-form/reducers'

export const selectCreatePropertyState = state => get(state, CREATE_PROPERTY_KEY, initialState)

export const makeSelectIsOpen = () =>
  createSelector(selectCreatePropertyState, state => get(state, 'isOpen', initialState.isOpen))

export const makeSelectCurrentPageIndex = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'currentPageIndex', initialState.currentPageIndex),
  )

export const makeSelectPages = () =>
  createSelector(selectCreatePropertyState, state => get(state, 'pages', []))

export const makeSelectLocation = () =>
  createSelector(selectCreatePropertyState, state => get(state, 'location', initialState.location))

export const makeSelectAddressVerificationState = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'addressVerification', initialState.addressVerification),
  )

export const makeSelectPropertyByAddress = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'propertyByAddress', initialState.propertyByAddress),
  )

export const makeSelectCreateProperty = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'createProperty', initialState.createProperty),
  )

export const makeSelectOnSuccessCallback = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'onSuccessCallback', initialState.onSuccessCallback),
  )

export const makeSelectPreFillFormValues = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'preFillFormValues', initialState.preFillFormValues),
  )

export const makeSelectIsLandlordInfoRequired = () =>
  createSelector(selectCreatePropertyState, state =>
    get(state, 'isLandlordInfoRequired', initialState.isLandlordInfoRequired),
  )
