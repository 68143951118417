import React from 'react'
import CreatePropertyAddressForm from 'components/organisms/address-form-v2'
import { ButtonSetV2 } from 'components/buttons/button-set'
import { Div } from 'components/layout/main'

import isEmpty from 'lodash/isEmpty'
import { withFormik } from 'formik'
import {
  createPropertySchema,
  createPropertyWithTypeSchema,
} from 'components/templates/create-property/schema'
import { BUTTON_TEXT_CONTINUE } from './constants'

export const InnerForm = ({
  handleSubmit,
  touched,
  errors,
  values,
  isCreatingProperty,
  history,
  handleCloseModal,
  hideBackButton,
  continueText,
  hideIconNextButton,
  isCreatePropertySuccess,
  withPropertyType,
  ...props
}) => (
  <form id="createPropertyFormSubmit" noValidate>
    <Div margin="0 0 20px" mMargin="0 0 20px">
      <CreatePropertyAddressForm
        {...props}
        values={values}
        errors={errors}
        touched={touched}
        withPropertyType={withPropertyType}
      />
    </Div>
    <ButtonSetV2
      id="createPropertyForRentPayment"
      margin="40px 0 0"
      minWidth="118"
      continueBtnType="button"
      continueText={BUTTON_TEXT_CONTINUE}
      prevText="Cancel"
      btnHeight="40px"
      isLoading={isCreatingProperty}
      isSuccess={isCreatePropertySuccess}
      onClickPrevBtn={handleCloseModal}
      backBtnMargin="0"
      hideBackButton
      onBorderDesktop
      onBorder
      mFullWidthButton
      hideIconNextButton
      hideIconPrevButton
      withSystemLayout
      disabledNextButton={isEmpty(touched) || !isEmpty(errors)}
      onSaveAndNext={handleSubmit}
    />
  </form>
)

export const CreatePropertyForm = withFormik({
  validationSchema: props =>
    props.withPropertyType
      ? createPropertyWithTypeSchema(props).validation
      : createPropertySchema(props).validation,
  mapPropsToTouched: props =>
    props.withPropertyType
      ? createPropertyWithTypeSchema(props).touched
      : createPropertySchema(props).touched,
  displayName: 'CreatePropertyTemplateForm',
  handleSubmit: (values, { props }) => {
    props.onHandleSubmit(values)
  },
})(InnerForm)

export default CreatePropertyForm
