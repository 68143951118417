import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { ReportHeader } from './responsive-doc-components'

export const HeaderDoc = ({
  submittedAt,
  text = 'Rental Application',
  transUnion,
  ssnMessage = 'N/A',
  renter,
  landlord,
}) => {
  const generatedOn = transUnion?.generatedOn
  const expiresOn = transUnion?.expiresOn
  const isTransUnionReport = !isEmpty(transUnion)
  return (
    <ReportHeader
      label={text}
      submittedAt={submittedAt}
      generatedOn={generatedOn}
      expiresOn={expiresOn}
      ssnMessage={ssnMessage}
      isTransUnionReport={isTransUnionReport}
      renter={renter}
      landlord={landlord}
    />
  )
}
