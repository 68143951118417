import isObject from "lodash/isObject"
import {
  setLocalItem,
  getLocalItem,
  removeAllLocalItem,
  setOption,
} from "@rentspree/cookie"
// eslint-disable-next-line import/no-cycle
import {
  getAccessToken,
  getRefreshToken,
  getExpiresIn,
} from "../../reducers/persist-state.reducer"
import { STORAGE, COOKIE_OPTION } from "../../../constants/cookie"

export const saveAccessToken = state => {
  const ACCESS_TOKEN = getAccessToken(state)
  const REFRESH_TOKEN = getRefreshToken(state)
  const EXPIRES_IN = getExpiresIn(state)

  if (ACCESS_TOKEN || REFRESH_TOKEN || EXPIRES_IN) {
    const token = {
      access_token: ACCESS_TOKEN,
      refresh_token: REFRESH_TOKEN,
      expires_in: EXPIRES_IN,
    }
    setOption(COOKIE_OPTION)
    setLocalItem(STORAGE.USER_TOKEN, token)
  }
}

export const saveAccessTokenLogin = state => {
  if (state.access_token || state.refresh_token || state.expires_in) {
    const token = Object.assign({}, state)
    delete token.type
    setOption(COOKIE_OPTION)
    setLocalItem(STORAGE.USER_TOKEN, token)
  }
}

export const loadAccessToken = () => {
  const userToken = getLocalItem(STORAGE.USER_TOKEN)
  const defaultToken = {
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
  }
  try {
    const o = isObject(userToken) ? userToken : JSON.parse(userToken)
    return {
      accessToken: o.access_token,
      refreshToken: o.refresh_token,
      expiresIn: o.expires_in,
    }
  } catch (e) {
    return defaultToken
  }
}

export const removeAccessToken = () => {
  setOption(COOKIE_OPTION)
  removeAllLocalItem("rentspree")
  localStorage.clear()
}
