import styled from "styled-components"
import { COLOR } from "styles/settings"
import { Table } from "../styled"

export const InLineLink = styled.button`
  width: 100%;
  padding: 0px;
  font-size: inherit;
  font-weight: normal;
  border: none;
  background: transparent;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${COLOR.activeBlue};
`
export const Icon = styled.i`
  color: ${COLOR.activeBlue};
`

export const OtherDocsTable = styled(Table)`
  thead > tr > th,
  tbody > tr > td {
    &:last-child {
      width: 150px;
    }

    @media (max-width: 991px) {
      &:last-child {
        display: table-cell;
        width: 150px;
      }
    }
  }
`

export const DownloadButtonWrap = styled(InLineLink)`
  width: 20px;
  height: 20px;
  justify-content: center;
  margin-left: auto;
  margin-right: 3px;

  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
  }
`
