import { createSelector } from "reselect"

const selectRequest = ({ request }) => request || {}

const selectProperty = ({ property }) => property || {}

const makeSelectSelectedProperty = () =>
  createSelector(
    selectRequest,
    ({ selectedProperty = {} }) => selectedProperty,
  )

const makeSelectPropertyLoading = () =>
  createSelector(
    selectProperty,
    ({ isFetching = {} }) => isFetching,
  )

export {
  selectRequest,
  makeSelectSelectedProperty,
  makeSelectPropertyLoading,
  selectProperty,
}
