import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'
import { getRentSubmissionById, clearRental } from 'legacy/actions/application.action'
import { clearCreditReport, clearCreditReportData } from 'legacy/actions/credit-report.action'
import { withReducer as withPrintPageReducer } from 'containers/print-report-page/connect'
import { getTenantScreeningNotification } from 'containers/navbar/actions'
import {
  toggleShareReportPromptModal,
  resetToggleShareReportPromptModal,
} from 'containers/share-report-prompt/actions'
import {
  withReducer as withContactParticipantsReducer,
  withSaga as withContactParticipantsSaga,
} from 'containers/contacts/add-to-contact/connect'
import { getAssociationContact } from 'containers/overview/association-contact/actions'
import { getNotificationCall } from 'containers/overview/notifications/actions'
import { getReportsAvailableCall } from 'containers/share-report/actions'
import { getMultiShareStatusCall } from 'containers/multi-share/actions'
import { withMultiShareReducer, withMultiShareSaga } from 'containers/multi-share/connect'
import {
  withReducer as withReportApplicationReducer,
  withSaga as withReportApplicationSaga,
} from './application/connect'
import { clearApplicationDetail } from './application/actions'
import { clearReferenceChecks } from './reference-checks/actions'
import { getSubmissionParticipantByEmailCall } from './actions'
import { getContactByEmail, clearContactByEmail } from '../contacts/add-to-contact/actions'
import {
  selectIsFetchingRentalDetail,
  selectErrorRental,
  selectIsFetchingParticipant,
  selectRentalDetail,
  selectIsRenter,
  selectIsSharedParticipant,
} from './selectors'
import reducer from './reducer'
import saga from './saga'
import { clearShowPdf } from './other-doc/actions'
import { selectIncomeVerificationState } from './screening-reports/income-verification/selectors'
import { clearIncomeVerification } from './screening-reports/income-verification/actions'
import {
  withIncomeVerificationReducer,
  withIncomeVerificationSaga,
} from './screening-reports/income-verification/connect'

const mapStateToProps = createStructuredSelector({
  isFetchingRentalDetail: selectIsFetchingRentalDetail,
  isFetchingParticipant: selectIsFetchingParticipant,
  errorRental: selectErrorRental,
  rentalDetail: selectRentalDetail,
  isRenter: selectIsRenter,
  isSharedParticipant: selectIsSharedParticipant,
  incomeVerificationData: selectIncomeVerificationState,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRentSubmissionById,
      clearShowPdf,
      clearRental,
      clearCreditReport,
      clearCreditReportData,
      clearApplicationDetail,
      clearReferenceChecks,
      getNotificationCall,
      getTenantScreeningNotification,
      getContactByEmail,
      clearContactByEmail,
      toggleShareReportPromptModal,
      resetToggleShareReportPromptModal,
      getSubmissionParticipantByEmailCall,
      getAssociationContact,
      getReportsAvailableCall,
      getMultiShareStatusCall,
      clearIncomeVerification,
    },
    dispatch,
  )

export const withSaga = injectSaga({ key: 'reports', saga })

export const withReducer = injectReducer({
  key: 'submissionParticipant',
  reducer,
})

export const withConnect = compose(
  withSaga,
  withReducer,
  withPrintPageReducer,
  withReportApplicationReducer,
  withReportApplicationSaga,
  withContactParticipantsReducer,
  withContactParticipantsSaga,
  withIncomeVerificationSaga,
  withIncomeVerificationReducer,
  withMultiShareReducer,
  withMultiShareSaga,
  connect(mapStateToProps, mapDispatchToProps),
)
