import { buildPath } from '@rentspree/path'
import * as sentry from '@sentry/browser'

import { apiInstance } from 'utils/api-interceptor'

export const GET_MY_SEEN_ANNOUNCEMENT_PATH = '/api/v2/announcement/seen-announcement/me'
export const UPDATE_MY_SEEN_ANNOUNCEMENT_PATH = '/api/v2/announcement/feature/:announcementType'

/**
 * getMyUserInvitation get user seen announcement resource.
 *
 * @returns {Promise<{seen_groups: { [key: string]: Date}}>}
 */
export const getMySeenAnnouncement = async () => {
  try {
    return await apiInstance.get(GET_MY_SEEN_ANNOUNCEMENT_PATH)
  } catch (e) {
    sentry.captureException(e)
    throw e
  }
}

/**
 * getMyUserInvitation get user seen announcement resource.
 *
 * @returns {Promise<{result: {seenGroups: { [key: string]: Date}}}>}
 */
export const updateMySeenAnnouncement = async (announcementType, groups) => {
  try {
    return await apiInstance.post(
      buildPath(UPDATE_MY_SEEN_ANNOUNCEMENT_PATH, { announcementType }),
      groups,
    )
  } catch (e) {
    sentry.captureException(e)
    throw e
  }
}
