import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import { takeLatest, put, call } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'

import { MARK_AS_PAID, closeMarkAsPaidModal, markAsPaidAPI } from '../../actions'
import { markAsPaidInstance } from '../../api-instances'
import { MARK_AS_PAID_TOAST, MARK_AS_PAID_PAYMENT_METHOD } from '../../constants'

dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)

export function* markAsPaidSaga({
  payload: {
    rentalPaymentId,
    paymentRequest,
    datePaid,
    paymentMethod,
    customPaymentMethod,
    notes,
    onSuccess,
    onError,
  },
}) {
  try {
    yield put(markAsPaidAPI.request())

    const payload = {
      rentalPaymentId,
      paymentRequestId: paymentRequest.id,
      datePaid: dayjs(datePaid).tz(Intl.DateTimeFormat().resolvedOptions().timeZone),
      paymentMethod,
      notes: notes.length ? notes : null,
    }

    if (paymentMethod === MARK_AS_PAID_PAYMENT_METHOD.OTHER) {
      payload.paymentMethod = customPaymentMethod.length ? customPaymentMethod : null
    }

    const updatedPaymentRequest = yield call(markAsPaidInstance, payload)

    yield put(markAsPaidAPI.success(updatedPaymentRequest))
    yield put(addToast(MARK_AS_PAID_TOAST.SUCCESS))
    yield put(closeMarkAsPaidModal())
    yield call(onSuccess, { ...payload, paymentRequest: updatedPaymentRequest })
  } catch (e) {
    yield put(markAsPaidAPI.failure(e))
    yield put(addToast(MARK_AS_PAID_TOAST.ERROR))
    yield call(onError)
  }
}

export function* watchMarkAsPaidSaga() {
  yield takeLatest(MARK_AS_PAID, markAsPaidSaga)
}
