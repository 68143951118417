import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { generateAddress, formatPhone } from '@rentspree/helper'
import classNames from 'classnames/bind'
import styled from 'styled-components'
import { Panel, OverlayTrigger, Tooltip, PanelGroup } from 'react-bootstrap'
import { APPLICATION_TYPE } from 'constants/application-type'

import {
  CAR_APPLICATION_AVAIABLE_VERSIONS,
  RENTSPREE_APPLICATION_AVAIABLE_VERSIONS,
} from 'components/reports/application/constants'
import { FullReportDetailBody } from './share/doc-components'
import { OccupationItem, ResidenceItem, Personal, Professional } from './application-report'
import style from './ref-check-report.scss'
import logo from '../../images/logos/rentspree-logo-monotone.svg'
import { I } from '../typography/text'
import { COLOR } from '../../styles/settings'
import { referenceType, refCheckStatus } from '../../constants/ref-check-consts'
import { convert } from '../helper/convert'

const c = classNames.bind(style)

export const TooltipRef = (status, fullStatus) => (
  <Tooltip id={status} className={c('tooltip-status')}>
    {fullStatus}
  </Tooltip>
)

export const Status = styled.div`
  margin-right: 15px;
  margin-top: 2px;
  padding: 5px 15px;
  color: white !important;
  height: 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7em;
  border-radius: 20px;
  ${props => props.bgColor && `background-color: ${COLOR[props.bgColor]} !important`};
`
export class ReferenceCheckReport extends React.Component {
  header = (ref, collapsible) => (
    <div className={c('panel-header')}>
      <i className={c(ref.report.icon, 'icon-header')} />
      <div className={c('title')}>{ref.report.name}</div>
      <div className={c('right-box')}>
        <Status bgColor={ref.color}>
          {ref.status === refCheckStatus.requested.value ? 'pending' : ref.status}
        </Status>
        {ref.status && collapsible && (
          <div className={c('info')}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="top"
              overlay={TooltipRef(ref.status, ref.longText)}
            >
              <I
                size="20px"
                color="titleGrey"
                rightSpace="15px"
                className={c('fas fa-question-circle')}
              />
            </OverlayTrigger>
          </div>
        )}
        {collapsible && <i className={c('fas fa-caret-down', 'icon-down')} />}
      </div>
    </div>
  )

  handelPanel = (ref, index, collapsible) => {
    const { application } = this.props
    let component
    const note = { title: `${ref.report.name} note(s):`, ...ref }

    const { version, type } = application
    const isRentspreeApplication = type === APPLICATION_TYPE.RENTSPREE
    const isLraApplication = type === APPLICATION_TYPE.CAR_LRA
    const isUSOnly = isLraApplication || (isRentspreeApplication && parseFloat(version) < 3.2)
    if (isRentspreeApplication && version === '2.0') {
      const { residence, occupation, references } = application
      switch (ref.type) {
        case referenceType.current_employer:
          component = !isEmpty(occupation.current) && (
            <OccupationItem isHideLine data={occupation.current} note={note} />
          )
          break
        case referenceType.former_employer:
          component = !isEmpty(occupation.previous) && (
            <OccupationItem isHideLine data={occupation.previous} note={note} />
          )
          break
        case referenceType.current_landlord:
          component = !isEmpty(residence.current) && (
            <ResidenceItem isHideLine data={residence.current} note={note} />
          )
          break
        case referenceType.previous_landlord:
          component = !isEmpty(residence.previous) && (
            <ResidenceItem isHideLine data={residence.previous} note={note} />
          )
          break
        case referenceType.professional:
          component = !isEmpty(references.personal) && (
            <Professional data={references.personal} note={note} />
          )
          break
        case referenceType.personal:
          component = !isEmpty(references.nearestRelative) && (
            <Personal data={references.nearestRelative} note={note} />
          )
          break
        default:
          return null
      }
    } else if (
      (isRentspreeApplication && RENTSPREE_APPLICATION_AVAIABLE_VERSIONS.includes(version)) ||
      (isLraApplication && CAR_APPLICATION_AVAIABLE_VERSIONS.includes(version))
    ) {
      const { residence, occupation, reference } = application
      const converter = isUSOnly ? convert : formatPhone
      switch (ref.type) {
        case referenceType.current_employer:
          component = !isEmpty(occupation.current) && (
            <OccupationItem
              converter={converter}
              isHideLine
              data={mapOccupationData(occupation.current)}
              note={note}
            />
          )
          break
        case referenceType.former_employer:
          component = !isEmpty(occupation.previous) && (
            <OccupationItem
              converter={converter}
              isHideLine
              data={mapOccupationData(occupation.previous)}
              note={note}
            />
          )
          break
        case referenceType.current_landlord:
          component = !isEmpty(residence.current) && (
            <ResidenceItem
              converter={converter}
              isHideLine
              data={mapResidenceData(residence.current)}
              note={note}
            />
          )
          break
        case referenceType.previous_landlord:
          component = !isEmpty(residence.previous) && (
            <ResidenceItem
              converter={converter}
              isHideLine
              data={mapResidenceData(residence.previous)}
              note={note}
            />
          )
          break
        case referenceType.personal:
          component = !isEmpty(reference.personalReferences[ref.arrayIndex]) && (
            <Personal
              converter={converter}
              data={mapPersonalData(reference.personalReferences[ref.arrayIndex])}
              note={note}
            />
          )
          break
        default:
          return null
      }
    }
    if (!component) return null
    return (
      <Panel eventKey={index} collapsible={collapsible} key={index}>
        <Panel.Heading>
          <Panel.Title toggle>{this.header(ref, collapsible)}</Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible={collapsible}>{component}</Panel.Body>
      </Panel>
    )
  }

  render() {
    const { references, onPrint, collapsible, download } = this.props
    return (
      <FullReportDetailBody
        docClassName="ref-check-report"
        title="Reference checks"
        logo={logo}
        onPrint={onPrint}
        download={download}
      >
        <PanelGroup accordion className={c('accordion-reference')}>
          {references.map(
            (ref, index) =>
              !isEmpty(ref.status) &&
              ref.status !== refCheckStatus.unattainable.value &&
              this.handelPanel(ref, index, collapsible),
          )}
        </PanelGroup>
      </FullReportDetailBody>
    )
  }
}

export const mapOccupationData = data => {
  const { employed } = data
  if (!employed) return { type: data.workStatus }
  return {
    type: data.workStatus,
    supervisorName: `${employed.supervisorFirstName} ${employed.supervisorLastName}`,
    supervisorPhone: employed.supervisorPhoneNumber,
    monthlySalary: employed.salary,
    ...(employed.city && { workAddress: generateAddress(employed) }),
    ...employed,
  }
}

export const mapResidenceData = data => ({
  type: mapResidenceType(data.selected),
  moveInDate: data.dateIn,
  address: generateAddress(data),
  reasonForLeaving: data.reason,
  ...data,
})

export const mapPersonalData = data => ({
  fullName: `${data.firstName} ${data.lastName}`,
  address: generateAddress(data),
  ...data,
})

export const mapResidenceType = type => {
  switch (type) {
    case 'rental':
      return 'rented'
    case 'owner':
      return 'owned'
    default:
      return '-'
  }
}
