import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'
import reducer from './reducer'
import saga from './saga'

export const withReducer = injectReducer({
  key: 'shareReportPrompt',
  reducer,
})

export const withSaga = injectSaga({ key: 'shareReportPrompt', saga })
