import React from 'react'
import { FormControlLabel, FormGroup } from '@mui/material'
import Checkbox from '@rentspree/component-2023.components.atoms.checkbox'
import { REPORT_UNAVAILABLE_MESSAGE_V2 } from '../constants'

export const ReportSelection = ({
  minHeight,
  screeningOption: { credit_report: creditReport },
  reportAvailable = {},
  isExpired,
  isIDMAExpired,
  expiredDate,
  selectedReports,
  setSelectedReports,
  isScreeningReportAvailable,
  isIncomeVerificationReportAvailable,
  isIncomeVerificationExpired,
  isApplicationAvailable,
  handleBlur,
  setTouched,
  touched,
}) => {
  const isDisplayIncomeVerificationReportOption =
    isIncomeVerificationReportAvailable || isIncomeVerificationExpired
  const getScreeningReportUnavailableMessage = () => {
    if (isExpired) return `${REPORT_UNAVAILABLE_MESSAGE_V2.EXPIRED}`
    if (isIDMAExpired) return REPORT_UNAVAILABLE_MESSAGE_V2.SCREENING_REPORT.IDMA_EXPIRED
    if (!reportAvailable.screeningReport)
      return REPORT_UNAVAILABLE_MESSAGE_V2.SCREENING_REPORT.ALREADY_SHARED
    return null
  }
  const screeningReportUnavailableMessage = getScreeningReportUnavailableMessage()
  const screeningReportLabel = screeningReportUnavailableMessage
    ? `TransUnion screening reports - ${screeningReportUnavailableMessage}`
    : 'TransUnion screening reports'

  const getIncomeVerificationUnavailableMessage = () =>
    isIncomeVerificationExpired
      ? `Income verification report - ${REPORT_UNAVAILABLE_MESSAGE_V2.EXPIRED}`
      : 'Income verification report'

  const handleCheckbox = (event, checked) => {
    const field = event?.target?.name
    setTouched({ ...touched, [field]: true })
    setSelectedReports({
      ...selectedReports,
      [field]: checked,
    })
  }

  return (
    <FormGroup
      sx={{
        ...(minHeight && { minHeight: `${minHeight}px` }),
      }}
    >
      {isApplicationAvailable && (
        <FormControlLabel
          checked={selectedReports.application}
          label="Rental application"
          control={
            <Checkbox
              data-testid="share-report-form-application-checkbox"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            />
          }
          disabled={!isApplicationAvailable}
          name="application"
          onChange={handleCheckbox}
          onBlur={handleBlur}
        />
      )}
      {creditReport && (
        <FormControlLabel
          checked={selectedReports.screeningReport}
          label={screeningReportLabel}
          control={
            <Checkbox
              data-testid="share-report-form-screening-report-checkbox"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            />
          }
          disabled={!isScreeningReportAvailable}
          name="screeningReport"
          onChange={handleCheckbox}
          onBlur={handleBlur}
        />
      )}
      {isDisplayIncomeVerificationReportOption && (
        <FormControlLabel
          checked={selectedReports.incomeVerificationReport}
          label={getIncomeVerificationUnavailableMessage()}
          control={
            <Checkbox
              data-testid="share-report-form-income-verification-report-checkbox"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            />
          }
          disabled={isIncomeVerificationExpired || !reportAvailable.incomeVerificationReport}
          name="incomeVerificationReport"
          onChange={handleCheckbox}
          onBlur={handleBlur}
        />
      )}
      {reportAvailable.referenceCheck && (
        <FormControlLabel
          checked={selectedReports.referenceCheck}
          label="Reference checks"
          control={
            <Checkbox
              data-testid="share-report-form-ref-checks-checkbox"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            />
          }
          disabled={!reportAvailable.referenceCheck}
          name="referenceCheck"
          onChange={handleCheckbox}
          onBlur={handleBlur}
        />
      )}
      {reportAvailable.otherDocs && (
        <FormControlLabel
          checked={selectedReports.otherDocs}
          label="Documents"
          control={
            <Checkbox
              data-testid="share-report-form-other-docs-checkbox"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            />
          }
          disabled={!reportAvailable.otherDocs}
          name="otherDocs"
          onChange={handleCheckbox}
          onBlur={handleBlur}
        />
      )}
    </FormGroup>
  )
}
