import styled from 'styled-components'
import { COLOR } from '../../styles/settings'
import { TableCss } from './table-style'

// language=SCSS prefix=dummy{ suffix=}
export const PropertyTable = styled.table`
  ${TableCss};
  font-family: Inter;
  @media (max-width: 991px) {
    table-layout: fixed;
    thead > tr > th,
    tbody > tr > td {
      &:nth-child(2) {
        width: 75%;
      }
      &:last-child {
        display: table-cell;
        padding: 7px 0;
        vertical-align: middle;
        width: 100px;
      }
    }
  }
  tr.inactive {
    > td {
      opacity: 0.25;
    }
    > td:last-child {
      opacity: 1;
    }
  }
  tbody > tr > td {
    vertical-align: middle;
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const AddressNoti = styled.span`
  background-color: ${COLOR.negativeRed};
  border-radius: 1em;
  color: white;
  display: inline-block;
  font-size: 0.8em;
  margin-left: 10px;
  padding: 1px 10px;
  text-align: center;
`

export const LinkScreenNew = styled.a`
  img {
    margin-bottom: 5px;
  }
  &:hover {
    color: ${COLOR.darkDeepBlue};
  }
`
