import React from "react"
import styled from "styled-components"
import { COLOR } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
const StyledProBadge = styled.span`
  display: inline-block;
  min-width: 10px;
  padding: 3px 6px;
  font-size: ${props => props.fontSize || "0.75em"};
  font-style: ${props => props.fontStyle || "inherit"};
  font-family: ${props => props.fontFamily || "inherit"};
  font-weight: 600;
  line-height: 1;
  color: ${COLOR.white};
  background: ${COLOR.mintGreen};
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 3px;
  margin-left: 5px;
  position: relative;
  bottom: 1px;
`

const StyledDefaultBadge = styled(StyledProBadge)`
  font-family: "Lato";
  font-style: italic;
  color: ${props => props.color || COLOR.white};
  background: ${props => props.backgroundColor || COLOR.deepBlue};
  margin-left: 13px;
  margin: ${props => props.margin};
`

const StyledBetaBadge = styled(StyledProBadge)`
  font-family: "Lato";
  font-style: italic;
  color: ${props => props.color || COLOR.white};
  background: ${props => props.backgroundColor || COLOR.cyanBlue};
  margin-left: 5px;
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
`

const StyledNewBadge = styled(StyledProBadge)`
  font-family: ${props => props.fontFamily || "inherit"};
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: ${props => props.color || COLOR.white};
  background: ${props => props.backgroundColor || COLOR.lightHawaiiBlue};
  margin-left: 8px;
  margin: ${props => props.margin};
  padding: 0;
  width: 34px;
  height: 16px;
`

export const ProBadge = ({ children, ...props }) => (
  <StyledProBadge {...props}>{children}</StyledProBadge>
)

export const BetaBadge = ({ children, showBadge, ...props }) =>
  showBadge && <StyledBetaBadge {...props}>{children}</StyledBetaBadge>

export const Badge = ({ children, ...props }) => (
  <StyledDefaultBadge {...props}>{children}</StyledDefaultBadge>
)

export const NewBadge = ({ children, ...props }) => (
  <StyledNewBadge {...props}>{children}</StyledNewBadge>
)
