import generateApiCall from 'utils/generate-api-call'

import {
  GET_CONTACT_DETAIL_CALL,
  GET_CONTACT_DETAIL_REQUEST,
  GET_CONTACT_DETAIL_SUCCESS,
  GET_CONTACT_DETAIL_FAILURE,
  UPDATE_CONTACT_DETAIL_INSTANTLY,
  UPDATE_CONTACT_DETAIL_CALL,
  UPDATE_CONTACT_DETAIL_REQUEST,
  UPDATE_CONTACT_DETAIL_SUCCESS,
  UPDATE_CONTACT_DETAIL_FAILURE,
  DELETE_CONTACT_CALL,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  STAR_CONTACT,
  CREATE_AND_TAG_CONTACT_CALL,
  TAG_CONTACT_CALL,
  TAG_CONTACT_REQUEST,
  TAG_CONTACT_SUCCESS,
  TAG_CONTACT_FAILURE,
  UNTAG_CONTACT_CALL,
  UNTAG_CONTACT_REQUEST,
  UNTAG_CONTACT_SUCCESS,
  UNTAG_CONTACT_FAILURE,
} from './constants'

export const getContactDetail = contactId => ({
  type: GET_CONTACT_DETAIL_CALL,
  payload: {
    contactId,
  },
})

export const getContactDetailApiState = generateApiCall([
  GET_CONTACT_DETAIL_REQUEST,
  GET_CONTACT_DETAIL_SUCCESS,
  GET_CONTACT_DETAIL_FAILURE,
])

export const updateContactDetailInstantly = (contactId, data) => ({
  type: UPDATE_CONTACT_DETAIL_INSTANTLY,
  payload: {
    contactId,
    data,
  },
})

export const updateContactDetail = (contactId, data, options = {}) => ({
  type: UPDATE_CONTACT_DETAIL_CALL,
  payload: {
    contactId,
    data,
    buttonId: options.buttonId,
    callback: options.callback,
  },
})

export const updateContactDetailApiState = generateApiCall([
  UPDATE_CONTACT_DETAIL_REQUEST,
  UPDATE_CONTACT_DETAIL_SUCCESS,
  UPDATE_CONTACT_DETAIL_FAILURE,
])

export const deleteContact = contactId => ({
  type: DELETE_CONTACT_CALL,
  payload: {
    contactId,
  },
})

export const deleteContactApiState = generateApiCall([
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
])

export const starContact = ({ isFallback = false, starredAt }) => ({
  type: STAR_CONTACT,
  payload: {
    isFallback,
    starredAt,
  },
})

export const createAndTagContact = (contactId, name, email) => ({
  type: CREATE_AND_TAG_CONTACT_CALL,
  payload: {
    contactId,
    name,
    email,
  },
})

export const unTagContact = (contactId, tagId) => ({
  type: UNTAG_CONTACT_CALL,
  payload: {
    contactId,
    tagId,
  },
})

export const tagContact = (contactId, tagId, email) => ({
  type: TAG_CONTACT_CALL,
  payload: {
    contactId,
    tagId,
    email,
  },
})

export const tagContactApiState = generateApiCall([
  TAG_CONTACT_REQUEST,
  TAG_CONTACT_SUCCESS,
  TAG_CONTACT_FAILURE,
])

export const untagContactApiState = generateApiCall([
  UNTAG_CONTACT_REQUEST,
  UNTAG_CONTACT_SUCCESS,
  UNTAG_CONTACT_FAILURE,
])
