import { combineReducers } from "redux"
import * as Types from "../constants/action-types"

const isNotRedirect = (state = true, action) => {
  switch (action.type) {
    case Types.GET_PROFILE_REQUEST:
    case Types.CANCEL_REDIRECT:
      return true
    case Types.CALL_REDIRECT:
      return false
    default:
      return state
  }
}

export default combineReducers({
  isNotRedirect,
})
