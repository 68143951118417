import { createGlobalStyle } from "styled-components"
import { COLOR, NEW_BLUE_LINEAR_GRADIENT } from "styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export default createGlobalStyle`
  .rsp-swal-container {
    .swal2-popup {
      width: calc(100% - 40px);
      max-width: 350px;
      padding: 0 0 20px;
    }

    .swal2-icon {
      margin: 0 auto;
    }

    .swal2-title {
      padding: 20px 0 0;
      font-size: 24px;
      line-height: 32px;
      color: ${COLOR.textBlack};
    }

    .swal2-html-container {
      font-size: 14px;
      line-height: 20px;
      margin: 5px 20px 0;
      color: ${COLOR.textBlack};
    }

    .swal2-actions {
      gap: 20px;
      margin: 20px 0 0;
    }

    .swal2-success-ring {
      color: ${COLOR.green};
      border-color: ${COLOR.green}!important;
    }

    .swal2-success-line-long, .swal2-success-line-tip {
      background-color: ${COLOR.green}!important;
    }

    .swal2-close {
      font-family: 'Courier New', Courier, monospace;
      color: ${COLOR.textBlack};
      opacity: .5;

      &:hover {
        color: ${COLOR.textBlack};
        opacity: .75;
      }

      &:focus {
        opacity: 1;
      }
    }
  }

  .rsp-swal-btn {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    padding: 8px 25px;
    border-radius: 20px;
  }

  .rsp-swal-cancel-btn {
    color: ${COLOR.textBlack};
    background: ${COLOR.white};
    border: 1px solid ${COLOR.grayLv3};

    &:hover {
      background: ${COLOR.bgGrey};
    }
  }

  .rsp-swal-deny-btn {
    color: ${COLOR.fontBlue};
    background-color: ${COLOR.white};
    border-width: 0;

    &:hover {
      color: ${COLOR.newDeepBlue};
    }
  }

  .rsp-swal-confirm-btn {
    color: ${COLOR.white};
    border-color: ${COLOR.grayLv3};
    border-width: 0px;
    background: ${NEW_BLUE_LINEAR_GRADIENT};

    &:hover {
      background: ${COLOR.textBlue};
    }
  }

  .rentspree-sweetalert {
    font-family: Source Sans Pro, sans-serif;
    width: 350px;
    margin: 0;
    padding: 20px;
    @media (max-width: 370px) {
      width: calc(100% - 20px);
    }
    .swal-icon--error {
      border-color: ${COLOR.negativeRed};
    }
    .swal-icon--error__line {
      background-color: ${COLOR.negativeRed};
    }
    .swal-icon--success__ring {
      border-color: ${COLOR.green};
    }
    .swal-icon--success__line {
      background-color: ${COLOR.green};
    }
    .swal-footer {
      text-align: center;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .swal-button-container {
        margin: 0;
        button.swal-button.swal-button--choice_one.success-btn {
          font-size: 16px;
          line-height: 22px;
          width: 208px;
          text-align: center;
          margin-bottom: 10px;
          padding: 0;
          background: linear-gradient(
            90deg,
            ${COLOR.newDeepBlueGradientMid} 0%,
            ${COLOR.newDeepBlue} 100%);
          border-radius: 29px;
          height: 40px;
          &:focus {
            box-shadow: none;
          }
        }
        button.swal-button.swal-button--choice_two.success-btn {
          font-size: 16px;
          line-height: 22px;
          width: 208px;
          text-align: center;
          padding: 0;
          background-color: white;
          border: 1px solid #AAAAAA;
          color: #333333;
          border-radius: 29px;
          height: 40px;
          &:focus {
            box-shadow: none;
          }
        }
        button.swal-button.swal-button--confirm.success-btn {
          font-size: 16px;
          line-height: 22px;
          background: linear-gradient(
            90deg,
            ${COLOR.newDeepBlueGradientMid} 0%,
            ${COLOR.newDeepBlue} 100%);
          border-radius: 29px;
          padding: 0px 30px;
          height: 40px;
          &:focus {
            box-shadow: none;
          }
        }
        button.swal-button.swal-button--confirm.error-btn {
          font-size: 16px;
          line-height: 22px;
          background: ${COLOR.white};
          color: ${COLOR.textBlack};
          border: 1px solid ${COLOR.placeholderGrey};
          border-radius: 29px;
          padding: 0px 30px;
          height: 40px;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .swal-icon {
      margin: 0 auto 25px;
    }
    .swal-title {
      font-size: 24px;
      line-height: 32px;
      color: ${COLOR.textBlack};
      margin: 0 0 5px;
      padding: 0;
      word-wrap: break-word;
    }
    .swal-content {
      max-width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: ${COLOR.textBlack};
      margin: 0 0 20px;
      padding: 0 20px;
      text-align: center;
      > div {
        word-wrap: break-word;
      }
    }
    .swal-text {
      max-width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: ${COLOR.textBlack};
      margin: 0 0 20px;
      padding: 0 20px;
      text-align: center;
    }
  }
  .rentspree-sweetalert-error {
    font-family: "Source Sans Pro", "Helvetica Neue", "simple-line-icons";
    padding: 25px 20px 20px 20px;
    margin: 0;
    @media (min-width: 500px) {
      width: 350px;
    }
    .swal-footer {
      padding: 0;
      margin: 0;
      text-align: center;
      .swal-button.swal-button--confirm {
        color: ${COLOR.textBlack};
        height: 40px;
        padding: 0px 28px;
        font-size: 16px;
        line-height: 22px;
        background: ${COLOR.white};
        border: 1px solid ${COLOR.placeholderGrey};
        border-radius: 29px;
        transition: all ease-in-out 0.3s;
        &:hover {
          background-color: ${COLOR.bgGrey};
        }
        &:focus {
          box-shadow: none;
        }
      }
      .swal-button-container {
        margin: 0;
      }
    }
    .swal-icon.swal-icon--error {
      margin: 0px auto 20px auto;
    }
    .swal-title {
      color: ${COLOR.textBlack};
      font-size: 24px;
      display: block;
      line-height: 32px;
      padding: 0px;
    }
    .swal-title:not(:last-child) {
      margin-bottom: 5px;
    }
    .swal-content {
      > div {
        word-wrap: break-word;
      }
    }
    .swal-text {
      color: ${COLOR.textBlack};
      max-width: none;
      padding: 0;
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .swal2-popup {
    font-family: Source Sans Pro, sans-serif;
    font-size: 1.6rem !important;
  }

  .swal2-title {
    font-family: Source Sans Pro, sans-serif;
    padding: 25px 0 5px 0 !important;
    font-size: 24px !important;
    font-weight: 600;
  }

  .swal2-html-container {
    font-family: Source Sans Pro, sans-serif;
    margin: 0px !important;
    font-size: 16px !important;
  }

  .swal2-text {
    font-size: 14px !important;
  }

  .custombutton-error {
    color: ${COLOR.textBlack} !important;
    background: ${COLOR.white} !important;
    /* Placeholder Grey */

    border: 1px solid ${COLOR.grayLv6} !important;
  }

  .custombutton {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 9px 25px !important;
    gap: 10px !important;

    width: auto !important;
    min-width: 86 !important;
    height: 40px !important;
    border-radius: 20px !important;

    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;

    text-align: center;
  }

  .custom-htmlcontainer {
    margin: 0.3em !important;
  }
  .custom-title {
    padding: 0.2em !important;
  }
  .custom-icon {
    margin: 0.5em auto !important;
  }
  .custom-actions {
    margin: 5px !important;
  }
  .custom-closebutton {
    justify-content: right !important;
    margin-top: -0.25em !important;
  }
  .custom-popup {
    max-width: 475px !important;
  }
`
