// Get rental payment list
export const GET_RENTAL_PAYMENT_LIST = 'rentPaymentIA/cmd/getRentalPaymentList'
export const GET_RENTAL_PAYMENT_LIST_REQUESTED = 'rentPaymentIA/evt/getRentalPaymentListRequested'
export const GET_RENTAL_PAYMENT_LIST_SUCCEEDED = 'rentPaymentIA/evt/getRentalPaymentListSucceeded'
export const GET_RENTAL_PAYMENT_LIST_FAILED = 'rentPaymentIA/evt/getRentalPaymentListFailed'

// Get payee by id
export const GET_PAYEE = 'rentPaymentIA/cmd/getPayee'
export const GET_PAYEE_REQUESTED = 'rentPaymentIA/evt/getPayeeByRequested'
export const GET_PAYEE_SUCCEEDED = 'rentPaymentIA/evt/getPayeeBySucceeded'
export const GET_PAYEE_FAILED = 'rentPaymentIA/evt/getPayeeByFailed'
export const OVERRIDE_PAYEE_IDV_STATE = 'rentPaymentIA/cmd/overridePayeeState'

// Get rental payment by id
export const GET_RENTAL_PAYMENT_BY_ID = 'rentPaymentIA/cmd/getRentalPaymentById'
export const GET_RENTAL_PAYMENT_BY_ID_REQUESTED = 'rentPaymentIA/evt/getRentalPaymentByIdRequested'
export const GET_RENTAL_PAYMENT_BY_ID_SUCCEEDED = 'rentPaymentIA/evt/getRentalPaymentByIdSucceeded'
export const GET_RENTAL_PAYMENT_BY_ID_FAILED = 'rentPaymentIA/evt/getRentalPaymentByIdFailed'

// Check show error badge
export const CHECK_SHOW_ERROR_BADGE = 'rentPaymentIA/cmd/checkShowErrorBadge'
export const CHECK_SHOW_ERROR_BADGE_REQUESTED = 'rentPaymentIA/evt/checkShowErrorBadgeRequested'
export const CHECK_SHOW_ERROR_BADGE_SUCCEEDED = 'rentPaymentIA/evt/checkShowErrorBadgeSucceeded'
export const CHECK_SHOW_ERROR_BADGE_FAILED = 'rentPaymentIA/evt/checkShowErrorBadgeFailed'

// Retry payout
export const RETRY_PAYOUT = 'rentPaymentIA/cmd/retryPayout'
export const RETRY_PAYOUT_REQUESTED = 'rentPaymentIA/evt/retryPayoutRequested'
export const RETRY_PAYOUT_SUCCEEDED = 'rentPaymentIA/evt/retryPayoutSucceeded'
export const RETRY_PAYOUT_FAILED = 'rentPaymentIA/evt/retryPayoutFailed'

// Delete rental payment
export const DELETE_RENTAL_PAYMENT = 'rentPaymentIA/cmd/deleteRentalPayment'
export const DELETE_RENTAL_PAYMENT_REQUESTED = 'rentPaymentIA/evt/deleteRentalPaymentRequested'
export const DELETE_RENTAL_PAYMENT_SUCCEEDED = 'rentPaymentIA/evt/deleteRentalPaymentSucceeded'
export const DELETE_RENTAL_PAYMENT_FAILED = 'rentPaymentIA/evt/deleteRentalPaymentFailed'

// Edit one-time payment
export const EDIT_ONE_TIME_PAYMENT = 'rentPaymentIA/cmd/editPayment'
export const EDIT_ONE_TIME_PAYMENT_REQUESTED = 'rentPaymentIA/evt/editPaymentRequested'
export const EDIT_ONE_TIME_PAYMENT_SUCCEEDED = 'rentPaymentIA/evt/editPaymentSucceeded'
export const EDIT_ONE_TIME_PAYMENT_FAILED = 'rentPaymentIA/evt/editPaymentFailed'

// Mark as paid
export const MARK_AS_PAID = 'rentalPaymentIA/cmd/markAsPaid'
export const RESET_MARK_AS_PAID_STATUS = 'rentalPaymentIA/cmd/resetMarkAsPaidStatus'
export const MARK_AS_PAID_REQUESTED = 'rentalPaymentIA/evt/markAsPaidRequested'
export const MARK_AS_PAID_SUCCEEDED = 'rentalPaymentIA/evt/markAsPaidSucceeded'
export const MARK_AS_PAID_FAILED = 'rentalPaymentIA/evt/markAsPaidFailed'

// Set seen notification
export const SET_SEEN_NOTIFICATION = 'rentalPaymentIA/cmd/setSeenNotification'
export const SET_SEEN_NOTIFICATION_REQUESTED = 'rentalPaymentIA/evt/setSeenNotificationRequested'
export const SET_SEEN_NOTIFICATION_SUCCEEDED = 'rentalPaymentIA/evt/setSeenNotificationSucceeded'
export const SET_SEEN_NOTIFICATION_FAILED = 'rentalPaymentIA/evt/setSeenNotificationFailed'

// Get payout method list
export const GET_PAYOUT_METHOD_LIST = 'rentalPaymentIA/cmd/setSeenNotification'
export const GET_PAYOUT_METHOD_LIST_REQUESTED = 'rentalPaymentIA/evt/setSeenNotificationRequested'
export const GET_PAYOUT_METHOD_LIST_SUCCEEDED = 'rentalPaymentIA/evt/setSeenNotificationSucceeded'
export const GET_PAYOUT_METHOD_LIST_FAILED = 'rentalPaymentIA/evt/setSeenNotificationFailed'

// Resend email invitation
export const SEND_INVITATION_EMAIL = 'rentalPaymentIA/cmd/sendInvitationEmail'
export const SEND_INVITATION_EMAIL_REQUESTED = 'rentalPaymentIA/evt/sendInvitationEmailRequested'
export const SEND_INVITATION_EMAIL_SUCCEEDED = 'rentalPaymentIA/evt/sendInvitationEmailSucceeded'
export const SEND_INVITATION_EMAIL_FAILED = 'rentalPaymentIA/evt/sendInvitationEmailFailed'

export const CHANGE_PAGE_RENTAL_PAYMENT_LIST_REQUESTED =
  'rentPaymentIA/evt/changePageRentalPaymentListRequested'

// Get payee idv update link
export const GET_PAYEE_IDV_UPDATE_LINK = 'rentalPaymentIA/cmd/getPayeeIdvUpdateLink'
export const GET_PAYEE_IDV_UPDATE_LINK_REQUESTED =
  'rentalPaymentIA/evt/getPayeeIdvUpdateLinkRequested'
export const GET_PAYEE_IDV_UPDATE_LINK_SUCCEEDED =
  'rentalPaymentIA/evt/getPayeeIdvUpdateLinkSucceeded'
export const GET_PAYEE_IDV_UPDATE_LINK_FAILED = 'rentalPaymentIA/evt/getPayeeIdvUpdateLinkFailed'

// Reset payee idv update link
export const RESET_PAYEE_IDV_UPDATE_LINK = 'rentalPaymentIA/cmd/resetPayeeIdvUpdateLink'

export const OPEN_ADD_YOUR_INFORMATION_MODAL = 'rentPaymentsIA/cmd/openAddYourInformationModal'
export const CLOSE_ADD_YOUR_INFORMATION_MODAL = 'rentPaymentsIA/cmd/closeAddYourInformationModal'

export const OPEN_MARK_AS_PAID_MODAL = 'rentPaymentsIA/cmd/openMarkAsPaidModal'
export const CLOSE_MARK_AS_PAID_MODAL = 'rentPaymentsIA/cmd/closeMarkAsPaidModal'

export const OPEN_CREATE_PROPERTY_MODAL = 'rentPaymentsIA/cmd/openCreatePropertyModal'
export const CLOSE_CREATE_PROPERTY_MODAL = 'rentPaymentsIA/cmd/closeCreatePropertyModal'

export const OPEN_CREATE_RENTER_INFO_MODAL = 'rentPaymentsIA/cmd/openCreateRenterInfoModal'
export const CLOSE_CREATE_RENTER_INFO_MODAL = 'rentPaymentsIA/cmd/closeCreateRenterInfoModal'

// Check rental payment exists
export const CHECK_RENTAL_PAYMENT_EXISTS = 'rentPaymentsIA/cmd/checkRentalPaymentExists'
export const CHECK_RENTAL_PAYMENT_EXISTS_REQUESTED =
  'rentPaymentsIA/evt/checkRentalPaymentExistsRequested'
export const CHECK_RENTAL_PAYMENT_EXISTS_SUCCEEDED =
  'rentPaymentsIA/evt/checkRentalPaymentExistsSucceeded'
export const CHECK_RENTAL_PAYMENT_EXISTS_FAILED =
  'rentPaymentsIA/evt/checkRentalPaymentExistsFailed'

// Payee provider account (full name, email)
export const GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION =
  'rentPayment/cmd/getPayeeProviderAccountInformation'
export const GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUESTED =
  'rentPayment/evt/getPayeeProviderAccountInformationRequested'
export const GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCEEDED =
  'rentPayment/evt/getPayeeProviderAccountInformationSucceeded'
export const GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED =
  'rentPayment/evt/getPayeeProviderAccountInformationFailed'

// Confirm draft rental payment
export const CONFIRM_DRAFT_RENTAL_PAYMENT = 'rentPayment/cmd/confirmDraftRentalPayment'
export const CONFIRM_DRAFT_RENTAL_PAYMENT_REQUESTED =
  'rentPayment/evt/confirmDraftRentalPaymentRequested'
export const CONFIRM_DRAFT_RENTAL_PAYMENT_SUCCEEDED =
  'rentPayment/evt/confirmDraftRentalPaymentSucceeded'
export const CONFIRM_DRAFT_RENTAL_PAYMENT_FAILED = 'rentPayment/evt/confirmDraftRentalPaymentFailed'

// Clear confirm draft rental payment
export const CLEAR_CONFIRM_DRAFT_RENTAL_PAYMENT_STATUS =
  'rentPayment/evt/clearConfirmDraftRentalPaymentStatus'

// Get or create draft rental payment
export const GET_OR_CREATE_DRAFT_RENTAL_PAYMENT = 'rentPayment/cmd/getOrCreateDraftRentalPayment'
export const GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_REQUESTED =
  'rentPayment/evt/getOrCreateDraftRentalPaymentRequested'
export const GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_SUCCEEDED =
  'rentPayment/evt/getOrCreateDraftRentalPaymentSucceeded'
export const GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_FAILED =
  'rentPayment/evt/getOrCreateDraftRentalPaymentFailed'

// Get or create renter info
export const GET_OR_CREATE_RENTER_INFO = 'rentPayment/cmd/getOrCreateRenterInfo'
export const GET_OR_CREATE_RENTER_INFO_REQUESTED = 'rentPayment/evt/getOrCreateRenterInfoRequested'
export const GET_OR_CREATE_RENTER_INFO_SUCCEEDED = 'rentPayment/evt/getOrCreateRenterInfoSucceeded'
export const GET_OR_CREATE_RENTER_INFO_FAILED = 'rentPayment/evt/getOrCreateRenterInfoFailed'

// Search renter info
export const SEARCH_RENTER_INFO = 'rentPayment/cmd/searchRenterInfo'
export const SEARCH_RENTER_INFO_REQUESTED = 'rentPayment/evt/searchRenterInfoRequested'
export const SEARCH_RENTER_INFO_SUCCEEDED = 'rentPayment/evt/searchRenterInfoSucceeded'
export const SEARCH_RENTER_INFO_FAILED = 'rentPayment/evt/searchRenterInfoFailed'

// Update draft quotations
export const UPDATE_DRAFT_QUOTATIONS = 'rentPayment/cmd/updateDraftQuotations'
export const UPDATE_DRAFT_QUOTATIONS_REQUESTED = 'rentPayment/evt/updateDraftQuotationsRequested'
export const UPDATE_DRAFT_QUOTATIONS_SUCCEEDED = 'rentPayment/evt/updateDraftQuotationsSucceeded'
export const UPDATE_DRAFT_QUOTATIONS_FAILED = 'rentPayment/evt/updateDraftQuotationsFailed'

// Get my seen announcements
export const GET_MY_SEEN_ANNOUNCEMENTS = 'rentPayment/cmd/getMySeenAnnouncements'
export const GET_MY_SEEN_ANNOUNCEMENTS_REQUESTED = 'rentPayment/evt/getMySeenAnnouncementsRequested'
export const GET_MY_SEEN_ANNOUNCEMENTS_SUCCEEDED = 'rentPayment/evt/getMySeenAnnouncementsSucceeded'
export const GET_MY_SEEN_ANNOUNCEMENTS_FAILED = 'rentPayment/evt/getMySeenAnnouncementsFailed'

// Get available payout
export const GET_AVAILABLE_PAYOUT = 'rentPayment/cmd/getAvailablePayout'
export const GET_AVAILABLE_PAYOUT_REQUESTED = 'rentPayment/evt/getAvailablePayoutRequested'
export const GET_AVAILABLE_PAYOUT_SUCCEEDED = 'rentPayment/evt/getAvailablePayoutSucceeded'
export const GET_AVAILABLE_PAYOUT_FAILED = 'rentPayment/evt/getAvailablePayoutFailed'

// Get payment summary
export const GET_PAYMENT_SUMMARY = 'rentPayment/cmd/getPaymentSummary'
export const GET_PAYMENT_SUMMARY_REQUESTED = 'rentPayment/evt/getPaymentSummaryRequested'
export const GET_PAYMENT_SUMMARY_SUCCEEDED = 'rentPayment/evt/getPaymentSummarySucceeded'
export const GET_PAYMENT_SUMMARY_FAILED = 'rentPayment/evt/getPaymentSummaryFailed'

// Create tenant
export const CREATE_TENANT = 'rentPayment/cmd/createTenant'
export const CREATE_TENANT_REQUESTED = 'rentPayment/evt/createTenantRequested'
export const CREATE_TENANT_SUCCEEDED = 'rentPayment/evt/createTenantSucceeded'
export const CREATE_TENANT_FAILED = 'rentPayment/evt/createTenantFailed'
