import { combineReducers } from "redux"

import { NOT_SHOW_POST_LOG_IN_ADVERTISE_CHECKED } from "legacy/constants/action-types"

// TODO: refactor to saga logic
export const postLogInAdvertiseChecked = (state = false, action) => {
  switch (action.type) {
    case NOT_SHOW_POST_LOG_IN_ADVERTISE_CHECKED:
      return action.checked
    default:
      return state
  }
}

export default combineReducers({
  postLogInAdvertiseChecked,
})
