export const docStatus = {
  created: 'created',
  requested: 'requested',
  submitted: 'submitted',
  completed: 'completed',
  uploaded: 'uploaded',
  canceled: 'canceled',
}
export const docType = {
  paystubs: 'paystubs',
  bank: 'bank_statement',
  tax: 'tax_return',
  w2s: 'w2s',
  passport: 'passport',
  pets: 'pets',
  petApp: 'pet_application',
  custom: 'custom',
  full: 'full',
  other: 'other',
}
export const defaultList = [
  {
    id: 'paystubs',
    name: 'Proof of Income',
    notePlaceholder: 'Upload your last 2 pay stubs.',
    status: docStatus.created,
    type: docType.paystubs,
  },
  {
    id: 'bankStatement',
    name: 'Bank Statements',
    notePlaceholder: 'Upload 2 months of bank statements.',
    status: docStatus.created,
    type: docType.bank,
  },
  {
    id: 'passport',
    name: 'Government Issued ID',
    notePlaceholder: 'Provide a photo of your passport.',
    status: docStatus.created,
    type: docType.passport,
  },
  {
    id: 'w2s',
    name: 'W-2s',
    notePlaceholder: 'Upload your W-2s for last year.',
    status: docStatus.created,
    type: docType.w2s,
  },
  {
    id: 'taxReturn',
    name: 'Tax Returns',
    notePlaceholder: 'Upload your tax return for last year.',
    status: docStatus.created,
    type: docType.tax,
  },
  {
    id: 'petPhotos',
    name: 'Photo of Each Pet',
    notePlaceholder: 'Take a photo of your cat.',
    status: docStatus.created,
    type: docType.pets,
  },
  {
    id: 'petApp',
    name: 'Pet Application',
    notePlaceholder: 'Provide a signed pet application.',
    status: docStatus.created,
    type: docType.petApp,
  },
  {
    id: 'other',
    name: 'Other',
    notePlaceholder: 'Upload a letter of explanation for the gap in employment.',
    status: docStatus.created,
    type: docType.other,
  },
]

const payStubIcon = require('../images/icons/request-docs/doc-landing-icon-paystubs.png')
const bankStatementIcon = require('../images/icons/request-docs/doc-landing-icon-bank.png')
const taxIcon = require('../images/icons/request-docs/doc-landing-icon-tax.png')
const w2sIcon = require('../images/icons/request-docs/doc-landing-icon-w2s.png')
const passportIcon = require('../images/icons/request-docs/doc-landing-icon-passport.png')
const petIcon = require('../images/icons/request-docs/doc-landing-icon-pet.png')

export const docPageList = [
  {
    name: 'Proof of Income',
    subtitle: 'Verifies income & employment',
    icon: payStubIcon,
  },
  {
    name: 'Bank Statements',
    subtitle: 'Verifies assets',
    icon: bankStatementIcon,
  },
  {
    name: 'Tax Returns',
    subtitle: 'Verifies income',
    icon: taxIcon,
  },
  {
    name: 'W-2s',
    subtitle: 'Verifies income',
    icon: w2sIcon,
  },
  {
    name: 'Government Issued ID',
    subtitle: 'Verifies identity',
    icon: passportIcon,
  },
  {
    name: 'Pet Photos / Application',
    subtitle: 'Verifies pet characteristic',
    icon: petIcon,
  },
  {
    name: 'Pet Photos / Application',
    subtitle: 'Verifies pet characteristic',
    icon: petIcon,
  },
]
