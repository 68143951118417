import { buildPath } from '@rentspree/path'
import React, { useEffect } from 'react'

import ShareListingApplyLinkTemplate from 'components/templates/listing-sharing-apply-link'
import * as PATH from 'constants/route'

import { isEmail } from 'validator'
import { SEND_APPLY_LINK_EVENT } from 'tracker/const'
import tracker from 'tracker'
import { withSendApplyLink } from './connect'
import { QUERY_STRING } from './const'

export const ShareApplyLink = ({
  history,
  match,
  actions,
  property,
  location,
  isSendingApplyLink = false,
}) => {
  const source = new URLSearchParams(location.search).get('source')
  const isSourceEmail = source === QUERY_STRING.QUERY_STRING_SOURCE_VALUE
  const recipientEmail = new URLSearchParams(location.search).get('recipientEmail')

  useEffect(() => {
    if (!property.propertyId) {
      const { params } = match
      actions.getProperty(params?.propertyId)
    }
  }, [property?.propertyId])

  useEffect(() => {
    if (!recipientEmail || !isEmail(recipientEmail)) {
      history.push(
        buildPath(PATH.SINGLE_PROPERTY_APPLICATION_LIST, {
          propertyId: match.params.propertyId,
        }),
      )
    }
  }, [recipientEmail])

  const { listing = {}, fullNameAddress, propertyId, disabledFeatures } = property
  const { screeningRequest } = listing

  const goToManageListing = () => {
    history.push(buildPath(PATH.LISTING_MANAGE, { propertyId }))
  }
  const goToPrev = () => {
    history.goBack()
  }

  const handleClickSendApplyLink = () => {
    tracker.trackEvent(SEND_APPLY_LINK_EVENT.SEND_APPLY_LINK)
    const screeningRequestId = property.listing.screeningRequest.url.split('?')[0].split('/').pop()
    actions.sendApplyLink({ propertyId, screeningRequestId, recipientEmail })
  }

  if (!property.propertyId) {
    return <div />
  }

  return (
    <ShareListingApplyLinkTemplate
      propertyAddress={fullNameAddress}
      goToManageListing={goToManageListing}
      tenantScreeningEnabled={
        !disabledFeatures?.listingApplication?.disabledAt && screeningRequest?.url
      }
      renterEmail={recipientEmail}
      isSourceEmail={isSourceEmail}
      isLoading={isSendingApplyLink}
      onClickSendApplyLink={handleClickSendApplyLink}
      onClickPrev={goToPrev}
    />
  )
}

export default withSendApplyLink(ShareApplyLink)
