import { FormattedInput, RedText, TextInput } from "@rentspree/component-v2"
import { InputWrapper } from "components/shared/form"
import PropTypes from "prop-types"
import React from "react"
import {
  CurrencyInputWithDescriptionInputStyles,
  LabelWrapper,
  DescriptionWrapper,
  LeftInputWrapper,
  RightInputWrapper,
} from "./styles"

const CurrencyInputWithDescriptionInput = ({
  label,
  isRequired,
  setFieldValue,
  handleBlur,
  handleChange,
  inputOptions,
  disabled,
}) => (
  <CurrencyInputWithDescriptionInputStyles>
    <LabelWrapper>
      <span>
        {label} {isRequired && <RedText> *</RedText>}
      </span>
    </LabelWrapper>
    <LeftInputWrapper dynamicWidth={false} basis="200px">
      <FormattedInput
        value={inputOptions.currency.value}
        id={inputOptions.currency.fieldName}
        data-testid={inputOptions.currency.testId}
        name={inputOptions.currency.fieldName}
        error={inputOptions.currency.error}
        placeholder={inputOptions.currency.placeholder}
        formatType="currency"
        onValueChange={val =>
          setFieldValue(inputOptions.currency.fieldName, val.value)
        }
        onBlur={handleBlur}
        allowNegative={false}
        disabled={disabled}
        {...inputOptions.currency.otherProps}
      />
    </LeftInputWrapper>
    <RightInputWrapper dynamicWidth basis="200px">
      <DescriptionWrapper
        hasError={
          !!inputOptions.currency.error || !!inputOptions.description.error
        }>
        <span>for</span>
      </DescriptionWrapper>
      <InputWrapper dynamicWidth basis="auto">
        <TextInput
          id={inputOptions.description.fieldName}
          data-testid={inputOptions.description.testId}
          name={inputOptions.description.fieldName}
          placeholder={inputOptions.description.placeholder}
          error={inputOptions.description.error}
          value={inputOptions.description.value}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
        />
      </InputWrapper>
    </RightInputWrapper>
  </CurrencyInputWithDescriptionInputStyles>
)

CurrencyInputWithDescriptionInput.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  inputOptions: PropTypes.shape({
    currency: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      fieldName: PropTypes.string,
      placeholder: PropTypes.string,
      otherProps: PropTypes.object,
    }),
    description: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      fieldName: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  }).isRequired,
  disabled: PropTypes.bool,
}

export default CurrencyInputWithDescriptionInput
