import get from 'lodash/get'
import { createSelector } from 'reselect'

const getProperty = state => get(state, 'property', {})

export const selectProperty = createSelector(getProperty, state => get(state, 'property', {}))

export const selectFullNameAddress = createSelector(selectProperty, state =>
  get(state, 'fullNameAddress', ''),
)

export const selectIsPropertyFetching = createSelector(getProperty, state =>
  get(state, 'isFetching', false),
)

export const selectPropertyListing = createSelector(
  selectProperty,
  ({ listing = {} } = {}) => listing,
)

export const selectPropertyListingStatus = createSelector(
  selectPropertyListing,
  ({ status } = {}) => status,
)

export const selectStreet = createSelector(selectProperty, state => get(state, 'street', ''))

export const selectCityState = createSelector(selectProperty, state =>
  get(state, 'fullNameAddress')
    ? `${get(state, 'city')}, ${get(state, 'state')} ${get(state, 'zip')}`
    : '',
)
export const selectPropertyLandlordProfile = createSelector(selectProperty, state =>
  get(state, 'landlordProfile'),
)
