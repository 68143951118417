import { takeLatest, put, call, all, select } from "redux-saga/effects"
import { buildPath, query } from "@rentspree/path"

import { apiInstance } from "utils/api-interceptor"
import { locationReload } from "legacy/utils/window"
import { ROOT, APPLICATION_V2_DETAIL_API, GET_ENVELOPE } from "constants/route"
import {
  findSignerByRecipient,
  findSignerByOwner,
  mapFilesForSign,
} from "helpers/signing"
import { push } from "connected-react-router"
import { errorMessage } from "containers/errors/constants"
import { openSweetAlertError } from "containers/envelope/actions"
import { selectLocation } from "containers/agreement/selectors"
import { LEASE_AGREEMENTS as LEASE_AGREEMENTS_MSG_ERROR } from "constants/error-messages"
import tracker from "tracker"
import { LRA_INTEGRATION } from "tracker/const"
import { REGISTERED_USER_TYPE } from "constants/user"
import { APPLICATION_TYPE } from "constants/application-type"
import {
  getApplicationApi,
  getEnvelopeApi,
  updateEnvelopeSignApi,
} from "./actions"
import {
  UPDATE_LRA_ENVELOPE_SIGN_CALL,
  GET_LRA_APPLICATION_CALL,
  GET_ENVELOPE_CALL,
  SIGNER_TYPE,
  UPDATE_LRA_ENVELOPE_SIGN_API,
} from "./constants"

export const callGetApplicationApi = ({ rentalId }) =>
  apiInstance.get(buildPath(APPLICATION_V2_DETAIL_API, { rentalId }))

export const callGetEnvelopeApi = ({ envelopeId, token }) =>
  apiInstance.get(buildPath(GET_ENVELOPE, { envelopeId }, { token }))

export const callUpdateEnvelopeSignApi = ({ rentalSubmissionId, payload }) =>
  apiInstance.put(
    buildPath(UPDATE_LRA_ENVELOPE_SIGN_API, { rentalId: rentalSubmissionId }),
    {
      ...payload,
      signerType: SIGNER_TYPE.AGENT,
    },
  )

export function* getApplicationeSaga({ payload }) {
  yield put(getApplicationApi.request())
  try {
    const response = yield call(callGetApplicationApi, payload)
    yield put(getApplicationApi.success(response))
  } catch (err) {
    yield put(getApplicationApi.failure())
  }
}

export function* getEnvelopeSaga({ payload }) {
  yield put(getEnvelopeApi.request())
  try {
    const response = yield call(callGetEnvelopeApi, payload)
    const { recipients, files, owner } = response

    let envelope = response

    const foundSignerByRecipient = yield call(findSignerByRecipient, recipients)
    const foundSignerByOwner = yield call(findSignerByOwner, recipients, owner)
    const signer = foundSignerByRecipient || foundSignerByOwner

    const mappedFiles = yield call(mapFilesForSign, files, signer)

    envelope = { ...envelope, files: mappedFiles }
    yield put(getEnvelopeApi.success(envelope))
  } catch (err) {
    yield put(getEnvelopeApi.failure())
  }
}

export function* updateEnvelopeSignSaga({ rentalSubmissionId, payload }) {
  yield put(updateEnvelopeSignApi.request())
  try {
    const response = yield call(callUpdateEnvelopeSignApi, {
      rentalSubmissionId,
      payload,
    })

    yield put(updateEnvelopeSignApi.success(response))
    yield call(
      [tracker, "trackEvent"],
      LRA_INTEGRATION.COMPLETE_APPLICATION_SIGN,
      {
        recipient_role: REGISTERED_USER_TYPE.AGENT,
        template: APPLICATION_TYPE.CAR_LRA,
      },
    )
    const location = yield select(selectLocation)
    const { search } = location
    const { continuePath } = query.parse(search)

    yield put(push(continuePath || ROOT))
  } catch (err) {
    err.message = errorMessage.generalSaveError
    yield put(
      openSweetAlertError(
        {
          title: LEASE_AGREEMENTS_MSG_ERROR.SIGN_ERROR.TITLE,
          text: LEASE_AGREEMENTS_MSG_ERROR.SIGN_ERROR.MESSAGE,
        },
        locationReload,
      ),
    )
    yield put(updateEnvelopeSignApi.failure(err))
  }
}

export function* watchGetApplicationCall() {
  yield takeLatest(GET_LRA_APPLICATION_CALL, getApplicationeSaga)
}

export function* watchGetEnvelopeCall() {
  yield takeLatest(GET_ENVELOPE_CALL, getEnvelopeSaga)
}

export function* watchUpdateEnvelopeSign() {
  yield takeLatest(UPDATE_LRA_ENVELOPE_SIGN_CALL, updateEnvelopeSignSaga)
}

export function* rootSaga() {
  yield all([
    watchGetApplicationCall(),
    watchGetEnvelopeCall(),
    watchUpdateEnvelopeSign(),
  ])
}

export default rootSaga
