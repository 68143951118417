import PropTypes from "prop-types"
import React from "react"
import FormattedInput from "@rentspree/component-v2/dist/input/formatted"

import { Div } from "components/layout/main"
import { InputWrapper } from "components/shared/form"
import { FlexBox } from "legacy/components/layout/section"
import CurrencyInputWithDescriptionInput from "components/molecules/currency-input-with-description-input"

import { TotalScreeningFeeStyled, ScreeningFeeBlsLinkStyled } from "./styles"

const ScreeningFeeForm = ({ margin, mMargin, ...props }) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isUpdatingScreeningFee,
  } = props
  const [totalScreeningFee, setTotalScreeningFee] = React.useState(0)

  React.useEffect(() => {
    const {
      creditReportFee,
      outOfPocketExpense: { amount },
      processingFee,
    } = values

    const totalFee =
      parseFloat(creditReportFee || 0) +
      parseFloat(amount || 0) +
      parseFloat(processingFee || 0)

    setTotalScreeningFee(totalFee)
  }, [values])

  return (
    <Div margin={margin} mMargin={mMargin}>
      <FlexBox>
        <InputWrapper width="100%" bgTransparent>
          <FormattedInput
            isRequired
            name="creditReportFee"
            id="creditReportFee"
            data-testid="credit-report-fee-input"
            label="for credit reports prepared by TransUnion"
            onBlur={handleBlur}
            value={values.creditReportFee}
            onValueChange={({ value }) =>
              setFieldValue("creditReportFee", value)
            }
            error={touched.creditReportFee && errors.creditReportFee}
            formatType="currency"
            placeholder="$"
            allowNegative={false}
            disabled={isUpdatingScreeningFee}
            decimalScale={2}
            fixedDecimalScale
          />
        </InputWrapper>
        <CurrencyInputWithDescriptionInput
          id="screeningFeeCurrencyInputWithDescriptionInput"
          isRequired
          label="for other out-of-pocket expenses"
          inputOptions={{
            currency: {
              testId: "out-of-pocket-expense-amount-input",
              value: `${values.outOfPocketExpense?.amount}`,
              error:
                touched.outOfPocketExpense?.amount &&
                errors.outOfPocketExpense?.amount,
              fieldName: "outOfPocketExpense.amount",
              placeholder: "$",
              otherProps: { decimalScale: 2, fixedDecimalScale: true },
            },
            description: {
              testId: "out-of-pocket-expense-for-input",
              value: values.outOfPocketExpense?.for,
              error:
                touched.outOfPocketExpense?.for &&
                errors.outOfPocketExpense?.for,
              fieldName: "outOfPocketExpense.for",
              placeholder: "N/A (optional)",
            },
          }}
          disabled={isUpdatingScreeningFee}
          {...props}
        />
        <InputWrapper width="100%" bgTransparent>
          <FormattedInput
            isRequired
            name="processingFee"
            data-testid="processing-fee-input"
            id="processingFee"
            label="for processing"
            onBlur={handleBlur}
            value={values.processingFee}
            onValueChange={({ value }) => setFieldValue("processingFee", value)}
            error={touched.processingFee && errors.processingFee}
            formatType="currency"
            placeholder="$"
            allowNegative={false}
            disabled={isUpdatingScreeningFee}
            decimalScale={2}
            fixedDecimalScale
          />
        </InputWrapper>
        <Div sourceSansPros mMargin="10px 0 0 0">
          Total screening fee payable by applicants:{" "}
          <TotalScreeningFeeStyled id="totalScreeningFee">
            ${totalScreeningFee.toFixed(2)}
          </TotalScreeningFeeStyled>
        </Div>
        <Div
          sourceSansPros
          fontSize="12"
          mt="15"
          mMargin="20px 0 0 0"
          lineHeight="16">
          The screening fee may not exceed $30.00, adjusted annually from 1-1-98
          commensurate with the increase in the Consumer Price Index. A CPI
          inflation calculator is available on the Bureau of Labor Statistics
          website,{" "}
          <ScreeningFeeBlsLinkStyled
            data-testid="bls-link"
            href="https://www.bls.gov/"
            target="_blank">
            www.bls.gov.
          </ScreeningFeeBlsLinkStyled>
        </Div>
      </FlexBox>
    </Div>
  )
}

ScreeningFeeForm.defaultProps = {
  margin: "0 10px",
  mMargin: "0",
}

ScreeningFeeForm.propTypes = {
  margin: PropTypes.string,
  mMargin: PropTypes.string,
}

export default ScreeningFeeForm
