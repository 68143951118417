import get from "lodash/get"

import { createSelector } from "reselect"

const getSubscription = state => get(state, "subscriptions")
const getUser = state => get(state, "user")

export const selectAuthorize = createSelector(
  getSubscription,
  ({ authorize }) => authorize,
)
export const selectIsFetching = createSelector(
  getSubscription,
  ({ isFetching }) => isFetching,
)
export const selectErrorSubscription = createSelector(
  getSubscription,
  ({ errorSubscription }) => errorSubscription,
)

export const selectUserProfile = createSelector(
  getUser,
  ({ profile }) => profile,
)
