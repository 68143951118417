import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { getMyIncentiveTrackRecord } from 'v3/services/incentive'

/**
 * useGetMyIncentiveTrackRecord get my incentive track records.
 *
 * @param {import('@tanstack/react-query').UseQueryOptions<Awaited<ReturnType<typeof getMyIncentiveTrackRecord>>>} options
 */
export const useGetMyIncentiveTrackRecord = (campaignName, options = {}) => {
  const userProfile = useSelector(selectProfile)

  return useQuery({
    queryKey: ['useGetMyIncentiveTrackRecord', userProfile?.id],
    staleTime: 3000,
    queryFn: () => getMyIncentiveTrackRecord(campaignName),
    refetchOnWindowFocus: false,
    ...options,
  })
}
