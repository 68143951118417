import { useDispatch, useSelector } from 'react-redux'
import { useInjectReducer } from 'utils/inject-reducer'
import { KEY } from 'containers/rent-payment/constants'
import { rentalPaymentRootSaga } from 'containers/rent-payment/sagas'
import { useInjectSaga } from 'utils/inject-saga'
import React from 'react'
import { rentalPaymentReducer } from '../reducers'
import {
  makeSelectRentalPaymentById,
  makeSelectLatestRentalPaymentId,
  makeSelectLatestRentalPaymentStatus,
} from '../selectors'
import { fetchLatestRentalPayment } from '../actions'

export const useRentalPayment = () => {
  const dispatch = useDispatch()
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })
  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })

  React.useEffect(() => {
    dispatch(fetchLatestRentalPayment())
  }, [])

  const latestRentalPaymentId = useSelector(makeSelectLatestRentalPaymentId())
  const latestRentalPaymentFetchingStatus = useSelector(makeSelectLatestRentalPaymentStatus())
  const latestRentalPayment = useSelector(makeSelectRentalPaymentById(latestRentalPaymentId))
  return {
    selectRentalPaymentById: rentalPaymentId =>
      useSelector(makeSelectRentalPaymentById(rentalPaymentId)),
    latestRentalPayment,
    latestRentalPaymentFetchingStatus,
  }
}
