import styled from "styled-components"
import { COLOR, ANIMATE } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const AccordionWrapper = styled.div`
  > .panel-group {
    > .panel {
      padding: 0 10px;
      border-radius: 0;
      border: none;
      > .panel-heading {
        background: ${COLOR.white};
        padding: 0;

        a {
          &[aria-expanded="true"] {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }
      > .panel-collapse {
        > .panel-body {
        }
      }
    }
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const PanelHeader = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 15px;
  font-weight: bold;

  @media (max-width: 991px) {
    padding: 12px 5px;
    font-size: 0.9em;
  }

  i {
    position: absolute;
    right: 10px;
    font-size: 1.6em;
    color: ${COLOR.textGrey};
    transform: rotate(0deg);
    transition: transform ease ${ANIMATE.fast};
  }
`
