import React from 'react'
import {
  BoxColor,
  CriminalStatePanel,
  FontReport,
  Space,
} from 'legacy/components/reports-v2/share/doc-style'
import styled from 'styled-components'
import { breakpoints, COLOR } from 'styles/settings'
import { convert } from 'legacy/components/helper/convert'

const CriminalCategoryTitle = styled.div`
  margin: ${props => (props.margin ? props.margin : '0')};
  font-size: 20px;
  font-weight: 600;
  word-break: break-word;
  word-wrap: break-word;
  max-width: 100%;
  white-space: ${props => props.whiteSpace || 'normal'};

  ${props =>
    props.MobileCompact &&
    breakpoints.mobileReport(`
      font-size: 14px;
      margin-bottom: 2px;
  `)}
`

const CriminalRecordID = styled.p`
  word-break: break-word;
  word-wrap: break-word;
  max-width: 100%;
  white-space: ${props => props.whiteSpace || 'normal'};

  margin: 0px 10px 0px 10px;
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR.newBlack};;
  text-align: left;

  ${props =>
    props.MobileCompact &&
    breakpoints.mobileReport(`
      margin-right: 0px;
      font-size: 12px;
      text-align: right;
  `)}
`

export const StateHeader = ({ index, state, category, id, date }) => (
  <CriminalStatePanel MobileCompact>
    <BoxColor CriminalStateHeader StateCount AlignItem="center">
      <FontReport TwentyFour Bold>
        {index}
      </FontReport>
    </BoxColor>
    <BoxColor CriminalStateHeader StateDetail AlignItem="center">
      <Space FlexColumn>
        <FontReport Small>State</FontReport>
        <FontReport Center Twenty fontWeight={700}>
          {state}
        </FontReport>
      </Space>
    </BoxColor>
    <BoxColor CriminalStateHeader StateContent SpaceBetween AlignItem="center">
      <Space width="100%">
        <CriminalCategoryTitle MobileCompact>
          {category}
        </CriminalCategoryTitle>
        <Space Flex width="100%">
          <Space MinWidth="100px">
            <FontReport EtcDetail Bold>
              Record Number
            </FontReport>
          </Space>
          <Space width="100%">
            <CriminalRecordID MobileCompact>
              {id}
            </CriminalRecordID>
          </Space>
        </Space>
      </Space>
      <Space SpaceBetween MinWidth="165px">
        <FontReport EtcDetail Bold>
          Last Updated
        </FontReport>
        <FontReport EtcDetail MarginLeft>
          {convert(date, 'date', 'MMM DD, YYYY')}
        </FontReport>
      </Space>
    </BoxColor>
  </CriminalStatePanel>
)

const IdentityHeader = ({ index, state, category, criminalId, lastUpdated }) => (
  <StateHeader state={state} index={index} category={category} id={criminalId} date={lastUpdated} />
)

export default IdentityHeader
