import { object } from "yup"
import {
  validateRequestReportEmail,
  validateRequestReportPhone,
} from "../../../../constants/validations"

export const requestReportEmailSchema = ({ email, emailValues }) => ({
  mapper: { email: email || emailValues },
  validation: object().shape({ email: validateRequestReportEmail() }),
})

export const requestReportPhoneNumberSchema = ({
  phoneNumber,
  phoneValues,
}) => ({
  mapper: { phoneNumber: phoneNumber || phoneValues },
  validation: object().shape({ phoneNumber: validateRequestReportPhone() }),
})
