import isEmpty from "lodash/isEmpty"
import * as Types from "../constants/action-types"
import createAction from "../apis/utils/create-action"
import handleError from "../helpers/handle-error"
import * as ERROR from "../constants/error-messages"
import * as SUCCESS from "../constants/success-messages"
import { ALERT_PRESET } from "../libs/sweet-alert/sweet-alert"
import * as api from "../apis/ref-check.api"

export const selectedRefCheckRow = index => ({
  type: Types.UPDATE_SELECTED_REFERENCE_CHECK,
  index,
})

export const selectedRefCheckAll = isChecked => ({
  type: Types.UPDATE_SELECTED_REFERENCE_CHECK_ALL,
  isChecked,
})

export const requestReferenceChecks = (rentalAppId, { references }) => (
  dispatch,
  getState,
) =>
  createAction(
    api.ofRequestSend,
    [
      Types.POST_REFERENCE_CHECK_REQUEST,
      Types.POST_REFERENCE_CHECK_SUCCESS,
      Types.POST_REFERENCE_CHECK_FAILED,
    ],
    (res = {}) => ({ result: res }),
  )(rentalAppId, { references })(dispatch, getState).then((res = {}) => {
    if (!res.success && !isEmpty(res.meta)) {
      const errorMessage = handleError(
        res,
        ERROR.APPLICATION_DETAIL.REFERENCE_CHECK,
      )
      dispatch({
        type: Types.OPEN_SWEET_ALERT,
        preset: ALERT_PRESET.ERROR,
        option: {
          title: errorMessage.title,
          content: {
            element: "div",
            attributes: {
              innerHTML: errorMessage.message,
            },
          },
        },
      })
    } else {
      dispatch({
        type: Types.OPEN_SWEET_ALERT,
        preset: ALERT_PRESET.SUCCESS,
        option: {
          title: SUCCESS.REFERENCE_CHECK.REQUEST_SEND.TITLE,
          text: SUCCESS.REFERENCE_CHECK.REQUEST_SEND.MESSAGE,
          button: "OK",
        },
      })
    }
    return res
  })

export const getReferenceCheck = createAction(
  api.ofGetReferences,
  [
    Types.GET_REFERENCE_CHECK_REQUEST,
    Types.GET_REFERENCE_CHECK_SUCCESS,
    Types.GET_REFERENCE_CHECK_FAILED,
  ],
  (res = {}) => ({ result: res }),
)
