import { call, put, takeLatest } from 'redux-saga/effects'

import { GET_AVAILABLE_PAYOUT, getAvailablePayoutAPI } from '../../actions'
import { getAvailablePayoutInstance } from '../../api-instances'

export function* getAvailablePayoutSaga(payload) {
  yield put(getAvailablePayoutAPI.request())

  try {
    const response = yield call(getAvailablePayoutInstance, payload)

    yield put(getAvailablePayoutAPI.success(response))
  } catch (error) {
    yield put(getAvailablePayoutAPI.failure(error))
  }
}

export function* watchGetAvailablePayoutSaga() {
  yield takeLatest(GET_AVAILABLE_PAYOUT, getAvailablePayoutSaga)
}
