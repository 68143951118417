import styled from 'styled-components'
import { breakpoints, COLOR } from 'styles/settings'
import { isDeviceSafari } from '../../helper/ua-parser-js'

export const DocPanel = styled.div`
  font-family: 'Source Sans Pro';
  background-color: white !important;
  max-width: 760px;
  padding: 20px;
  @media print {
    width: 100% !important;
    padding: 0px;
  }
`

export const Section = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }
  ${props =>
    props.Row &&
    `
    display: flex;
    flex-wrap: wrap;
  `}
  ${props =>
    props.Half &&
    `
    width: 50%;
  `}
  ${props =>
    props.Auto &&
    `
    width: auto;
  `}
  ${props =>
    props.FlexRow &&
    `
    display: flex;
  `}
  ${props =>
    props.PaddingT &&
    `
    padding-top: ${props.PaddingT};
  `}
  ${props =>
    props.Footer &&
    `
    @media print {
      position: fixed;
      bottom: 0px;
    }
  `}
  ${props =>
    props.noneSubtitle &&
    `
    border-top: solid 2px #aaaaaa;
  `}
`

export const Panel = styled.div`
  width: 100%;
  @media print {
    width: 100%;
  }
  ${props =>
    props.Wrap &&
    `
    flex-wrap: wrap;
  `}
  ${props =>
    props.Row &&
    `
    display: flex;
  `} 
  ${props =>
    props.Center &&
    `
    align-items: center;
  `}
  ${props =>
    props.RowCenter &&
    `
    justify-content: center;
  `}
  ${props =>
    props.Section &&
    `
   padding: 10px;
  `}
  ${props =>
    props.Title &&
    `
   padding: 10px;
   position: relative;
  `}
  ${props =>
    props.Half &&
    `
   width: 50%;
   @media print {
    width: 50%;
    page-break-inside: avoid !important;
    break-inside: avoid; !important
   }
  `}
    ${props =>
    props.InfoLeft &&
    `
   width: 60%;
   @media print {
    width: 60%;
    page-break-inside: avoid !important;
    break-inside: avoid; !important
   }
  `}
    ${props =>
    props.InfoRight &&
    `
   width: 40%;
   @media print {
    width: 40%;
    page-break-inside: avoid !important;
    break-inside: avoid; !important
   }
  `}
  ${props =>
    props.NonePaddingB &&
    `
   padding-bottom: 0px;
  `}
  ${props =>
    props.NonePaddingT &&
    `
   padding-top: 0px;
  `}
  ${props =>
    props.NonePaddingTPrint &&
    `
    @media print {
      padding-top: 0px;
    }
  `}
  ${props =>
    (props.PaddingTop || props.Subsection) &&
    `
    padding-top: 10px;
  `}
  ${props =>
    (props.PaddingBottom || props.Subsection) &&
    `
    padding-bottom: 10px;
  `}
  ${props =>
    props.PaddingLeft &&
    `
    padding-left: ${props.PaddingLeft};
  `}
  ${props =>
    props.PaddingRight &&
    `
    padding-right: ${props.PaddingRight};
  `}
  ${props =>
    props.MarginContact &&
    `
   padding-bottom: 20px;
  `}
  ${props =>
    props.SpaceBetween &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center; 
  `}
  ${props =>
    props.Baseline &&
    `
    align-items: baseline;
  `}
  ${props =>
    props.score &&
    `
    width: 100%;
    margin-right: 10px;
  `}
  ${props =>
    props.HeightPaddingTop &&
    `
    padding-top: ${props.HeightPaddingTop || '10px'};
    justify-content: center;
  `}
  ${props =>
    props.CoverContact &&
    `
    width: auto;
    @media print {
      width: auto;
    }
    ${props.MarginLeft && `margin-left: ${props.MarginLeft}`}
  `}
  ${props =>
    props.CoverContactContainer &&
    `
    padding-left: 20px;
    padding-top: 6px;
  `}
  ${props =>
    props.CoverLeft &&
    `
    width: 54% !important;
    @media print {
      page-break-inside: avoid !important;
      break-inside: avoid; !important
    }
  `}
  ${props =>
    props.CoverRight &&
    `
    width: 46% !important;
    padding-left: 40px;
    @media print {
      page-break-inside: avoid !important;
      break-inside: avoid; !important
    }
  `}
  ${props =>
    props.PaddingBottomPrint &&
    `
    @media print {
      padding-bottom: ${props.PaddingBottomPrint} !important;
    }
  `}
`

export const PanelContainer = styled.div`
  width: 100%;
  ${props =>
    props.rowFlex &&
    `
    display: flex;
    flex-wrap: wrap;
  `}
  ${props =>
    props.MarginB &&
    `
    margin-bottom: 20px; 
  `}
  ${props =>
    props.MarginB10 &&
    `
    margin-bottom: 10px; 
  `}
  ${props =>
    props.MarginB5 &&
    `
    margin-bottom: 5px; 
  `}
  ${props =>
    props.Row &&
    `
    display: flex;
  `}
  ${props =>
    props.Column &&
    `
    flex-direction: column;
  `}
  ${props =>
    props.panelSubTitle &&
    `
    height: 35px;
    border-bottom: solid 2px #aaaaaa;
    margin-bottom: 10px;
  `}
  ${props =>
    props.noneSubtitle &&
    `
    padding: 10px 10px 0;
  `}
  ${props =>
    props.calWidth &&
    `
    width: calc(100% - 28px) !important;
  `}  
  ${props =>
    props.margin &&
    `
    margin-bottom: 20px
  `}
  ${props =>
    props.SpaceBetween &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center; 
  `}

  ${props =>
    props.pageBreak &&
    `
    @media print {
      page-break-inside: avoid !important;
    }
  `}
  ${props =>
    props.TopHr &&
    `
    border-top: solid 1px #aaaaaa;
    padding: 14px 10px 14px 0;
  `}
  ${props =>
    props.CoverRecordSummary &&
    `
    border-bottom: solid 1px #aaaaaa;
    padding: 15px 10px 15px 0;
  `}
  ${props =>
    props.Center &&
    `
    justify-content: center;
    display: flex;
  `}
  ${props =>
    props.ResidentScore &&
    `
    border-bottom: solid 1px #aaaaaa;
    padding-bottom: 14px;
  `}
`

export const FontGradient = styled.p`
  font-size: 24px;
  font-family: 'Source Sans Pro';
  background: linear-gradient(to right, #3b99fc, #00c9e5) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: 600;
  margin-bottom: 30px;
  @media print {
    margin-bottom: 15px;
  }
`

export const FontReport = styled.p`
  margin: ${props => (props.margin ? props.margin : '0')};
  font-size: 18px;
  font-family: 'Source Sans Pro';
  ${({ fontWeight }) => `font-weight: ${fontWeight};`}
  word-break: break-word;
  word-wrap: break-word;
  max-width: 100%;
  white-space: ${props => props.whiteSpace || 'normal'};

  ${props =>
    props.paddingLeft > 0 &&
    `
    padding-left: ${props.paddingLeft * 10}px;
  `}
  ${props =>
    props.paddingRight > 0 &&
    `
    padding-right: ${props.paddingRight * 10}px;
  `}
  ${props =>
    props.calWidth &&
    `
    max-width: calc(339px - 120px) !important;
  `}
  ${props =>
    props.Title &&
    `
    font-weight: 600;
    position: absolute;
    padding-left: 10px;
    top: 12px;
    left: 10px;
    opacity: 1;
  `}
  ${props =>
    props.Full &&
    `
    width: 100%;
  `}
  ${props =>
    props.White &&
    `
    color: #ffffff !important;
  `}
  ${props =>
    props.WhiteGray &&
    `
    color: #888888 !important;
    line-height: 1.33;
  `}
  ${props =>
    props.BalanceGrey &&
    `
    font-weight: 600;
    color: #aaaaaa !important;
    line-height: 1.33;
  `}
  ${props =>
    props.Gray &&
    `
  color: #ffffff !important;
  visibility: visible;
  @media print{
    visibility: hidden;
  }
  `}
  ${props =>
    props.GrayPrint &&
    `
  visibility: hidden;
  @media print{
    color: #000000 !important;
    visibility: visible;
  }
  `}
  ${props =>
    props.DarkGray &&
    `
    font-weight: 600;
    color: #333333 !important;
  `}
  ${props =>
    props.Red &&
    `
    color: #f15555 !important;
  `}
  ${props =>
    props.MarginTen &&
    `
    margin-right: 10px; 
  `}
  ${props =>
    props.marginB &&
    `
    margin-bottom: 10px;
  `}
  ${props =>
    props.HeaderN &&
    `
    width: 554px;
    font-weight: normal;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    @media print {
      width: 100%;
    }
  `}
  ${props =>
    props.HeaderB &&
    `
    width: 554px;
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    color: #3b99fc !important;
    font-size: 14px;
    @media print {
      width: 100%;
    }
  `}
  ${props =>
    props.Footer &&
    `
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 12px;
  `}
  ${props =>
    props.Detail &&
    `
    font-size: 16px;
  `}
  ${props =>
    props.HeaderDetail &&
    `
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    min-width: ${props.oneLine ? '140px' : '110px'};
    margin-right: 10px; 
    justify-content: flex-start;
    align-items: center;
    @media print {
      min-width: 32%;
    }
  `}
  ${props =>
    props.LargeHeaderDetail &&
    `
    min-width: 161px;
  `}
  ${props =>
    props.CycleHeaderDetail &&
    `
    min-width: 220px;
    margin-right: 10px;
    
  `}
  ${props =>
    props.Name &&
    `
    font-family: "Merriweather";
    font-size: 36px;
    font-weight: bold;
    color: #4a4a4a !important;
    justify-content: flex-start;
  `}
  ${props =>
    props.BigName &&
    `
    font-family: "Merriweather";
    font-size: 46px;
    font-weight: bold;
    color: #4a4a4a !important;
    justify-content: flex-start;
  `}
  ${props =>
    props.SmallDetail &&
    `
    font-size: 14px;
    color: #4a4a4a !important;
  `}
  ${props =>
    props.Score &&
    `
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    text-align: right;
    color: #4a4a4a !important;
  `}
  ${props =>
    props.Half &&
    `
    width: 50%;
    max-width: 50%;
  `}
  ${props =>
    props.AlignRight &&
    `
    text-align: right !important;
  `}
  ${props =>
    props.Blue &&
    `
    font-weight: 600;
    color: #3b99fc !important;
  `}
  ${props =>
    props.BlueOnly &&
    `
    color: #3b99fc !important;
  `}
  ${props =>
    props.ThirtySix &&
    `
    font-size: 36px;
  `}
  ${props =>
    props.TwentyEeight &&
    `
    font-size: 28px;
  `}
  ${props =>
    props.TwentyFour &&
    `
    font-size: 24px;
  `}
  ${props =>
    props.Twenty &&
    `
    font-size: 20px;
  `}
  ${props =>
    props.Sixteen &&
    `
    font-size: 16px;
  `}
  ${props =>
    props.FourTeen &&
    `
    font-size: 14px;
  `}
  ${props =>
    props.Small &&
    `
    font-size: 12px;
  `}
  ${props =>
    props.MarginLeft &&
    `
    margin-left: 10px;
  `}
  ${props =>
    props.EtcDetail &&
    `
    font-size: 14px;
    line-height: 1.57;
    color: #4a4a4a;
  `}
  ${props =>
    props.Inline &&
    `
    display: inline;
  `}
  ${props =>
    props.Bold &&
    `
    font-weight: 600;
  `}
  ${props =>
    props.fontWeight &&
    `
    font-weight: ${props.fontWeight};
  `}
  ${props =>
    props.Center &&
    `
    align-self: center;
  `}
  ${props =>
    props.Attention &&
    `
    font-size: 14px;
    line-height: 1.43;
    color: #333333 !important;
  `}
  ${props =>
    props.Cycle &&
    `
    line-height: 1.79;
    color: #333333 !important;
  `}
  ${props =>
    props.WhiteSpace &&
    `
    white-space: pre-wrap;
  `}
  ${props =>
    props.lineHeight &&
    `
    line-height: ${props.lineHeight} important;
  `}
  ${props =>
    props.rentspreePhoneNumber &&
    `
    color: #4a90e2 !important;
  `}
  ${props => props.paddingLeftValue && `padding-left: ${props.paddingLeftValue}px`}

  ${props =>
    props.InlineWord &&
    `
    display: flex;
    justify-content: flex-end;
  `}

  @media print {
    a[href]:after {
      content: none !important;
    }
  }
`

export const ImgTitle = styled.img`
  height: 30px;
  @media print {
    width: 100%;
  }
  ${props =>
    props.Gray &&
    `
  visibility: visible;
  width: auto;
  @media print{
    width: 0%;
    visibility: hidden;
  }
  `}
  ${props =>
    props.GrayPrint &&
    `
  visibility: hidden;
  width: 0%;
  @media print{
    width: 100%;
    visibility: visible;
  }
  `}
`

export const ImgSubTitle = styled.img`
  height: 20px;
  margin-right: 10px;
`

export const RentSpreeLogo = styled.img`
  height: 40px;
  right: 0;
  ${props =>
    props.Small &&
    `
    height: 30px;
  `}
`
export const RentSpreeLogoWithoutText = styled.img`
  height: 60px;
  margin: 30px 0 20px 0px;
  right: 0;
  @media print {
    margin: 0px 0px 0px 0px;
  }
`
export const TULogo = styled.img`
  height: 26px;
  right: 0;
  margin: 5px 0px;
`

export const Icon = styled.i`
  margin-right: ${props => (props.marginRight ? props.marginRight : '10px')};
  font-size: ${props => (props.size ? props.size : '18px')};
  ${props =>
    props.color &&
    `
    color: ${props.color};
    @media print {
      :before,
      :after {
        color: ${props.color} !important;
      }
  `}
`
export const WrapperReport = styled.div`
  position: relative;
  max-width: 760px;
  margin: ${props => (props.margin ? props.margin : '0 auto 40px;')};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  box-sizing: content-box;
  border: ${props => (props.border ? props.border : '1px solid #d8d8d8')};
  @media print {
    padding: 0px;
    width: 100% !important;
    page-break-inside: avoid !important;
    page-break-after: always !important;
    margin-bottom: ${isDeviceSafari ? '700px' : '0'};
    border: none !important;
  }
  @page {
    size: letter;
    margin: 0.15in 0.25in;
  }
`
export const PanelApplicant = styled.div`
  margin: 25px 0 7px 10px;
`
export const TableStyle = styled.table`
  width: ${props => (props.Width ? props.Width : '100%')};

  margin-bottom: ${props => (props.MarginB ? props.MarginB : '10px')};
  margin-top: ${props => (props.MarginT ? props.MarginT : '0px')};
  ${props =>
    props.Half &&
    `
    width: 50%;
  `}
  ${props =>
    props.noMargin &&
    `
    margin: 0px;
  `}
`
export const TableRow = styled.tr`
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  ${props =>
    props.WhiteGray &&
    `
  &:nth-child(even) {
    background-color: #f1f1f1 !important;
    border-top: 1px solid #f1f1f1 !important;
    border-bottom: 1px solid #f1f1f1 !important;
    box-sizing: border-box;
  }
  `}
  ${props =>
    props.withHeader &&
    `
  background-color: white;
  &:nth-child(odd) {
    background-color: #f1f1f1 !important;
    box-sizing: border-box;    
    border-top: 1px solid #f1f1f1 !important;
    border-bottom: 1px solid #f1f1f1 !important;
  }
  &:nth-child(1) {
    background-color: white !important;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
  }
  `}
  ${props =>
    props.PaymentSummary &&
    `
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 10px;
  `}

  ${props =>
    props.padding &&
    `
    padding: ${props.padding};
  `}

  ${props =>
    props.Flex &&
    `
    display: flex;  
  `}
`

export const TableCell = styled.td`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 1.79;
  color: #4a4a4a;
  padding: 0px 8px;
  padding: ${({ padding }) => padding || '0px 8px'};
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  &:empty {
    height: 25px;
  }
  ${props =>
    props.Row &&
    `
    display: flex;
  `}
  ${props =>
    props.SpaceB &&
    `
    justify-content: space-between;
  `}
  ${props =>
    props.PaymentYear &&
    `
    width: 40px;
    padding: 0px;
    text-align: left;
  `}
  ${props =>
    props.PaymentDetail &&
    `
    height: 24px;
    width: 24px;
    padding: 1px;
    text-align: center;
  `}
  ${props =>
    props.PaymentHeader &&
    `
    padding: 10px 0px;
  `}
  ${props =>
    props.PaymentSummary &&
    `
    padding: 0px;
  `}
  ${props =>
    props.AlignRight &&
    `
    text-align: right;
  `}
`
export const TableHeader = styled.th`
  height: 30px;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 2.14;
  letter-spacing: normal;
  color: #4a4a4a;
  padding: 0px 8px;
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.padding && `padding: ${props.padding};`}
`

export const BoxColor = styled.div`
  height: 70px;
  width: 100%;
  border-radius: 5px;
  background-color: ${({ color }) => color || '#f1f1f1'} !important;
  border: 1px solid ${({ color, borderColor }) => borderColor || color || '#f1f1f1'};
  box-sizing: border-box;
  padding: 15px 20px;

  ${props =>
    props.AlignItem &&
    `
    align-items: ${props.AlignItem};
  `}

  ${props =>
    props.SpaceBetween &&
    `
    display: flex;
    justify-content: space-between;
  `}
  ${props =>
    props.SpaceAround &&
    `
    display: flex;
    justify-content: space-around;
  `}
  ${props =>
    props.Blue &&
    `
    background-color: rgba(59, 153, 252, 0.1) !important;
    border: 1px solid #edf5fe !important;
  `}
  ${props =>
    props.Score &&
    `
    width: 160px;
    height: 80px;
    padding: 10px 15px;
    margin-left: 10px;
  `}
  
  ${props =>
    props.CriminalStateHeader &&
    `
    height: auto;
    background-color: #e7eced !important;
    border-radius: 0px;
    padding: 8px 15px 7px 18px;
  `}

  ${props =>
    props.StateHeader &&
    `
    background-color: #e7eced !important;
    border-radius: 0px;
    height: 60px;
    padding: 8px 15px 7px 18px;
  `}
  ${props =>
    props.StateCount &&
    `
    width: 40px;
    display: flex;
    justify-content: center;
    padding: 0px;
  `}
  ${props =>
    props.StateDetail &&
    `
    width: 60px;
    margin: 0 3px 0 3px;
    padding: 0px;
    background-color: #cad2d3 !important;
    display: flex;
    justify-content: center;
  `}
  ${props =>
    props.StateContent &&
    `
    padding: 8px 20px;

    ${breakpoints.mobileReport(`
      padding: 6px 14px 6px 8px;
    `)}
  `}
  ${props =>
    props.CriminalSummary &&
    `
    width: 220px;
    height: 45px;
    background-color: ${COLOR.grayLight} !important;
    border: 0px;
    align-items: center;
  `}
  ${props =>
    props.First &&
    `
    margin-left: 0px;
  `}
  ${props =>
    props.hiddenBox &&
    `
    visibility: hidden;
  `}
  ${props =>
    props.active &&
    `
    background: none !important;
    border: 0.5px solid ${COLOR.grayLv3};
  `}

  ${props =>
    props.Width &&
    `
    width: ${props.Width} !important;
  `}
`

export const Space = styled.div`
  width: auto;
  ${props =>
    props.mRight &&
    `
    margin-right: ${props.mRight};
  `}
  ${props =>
    props.Flex &&
    `
    display: flex
  `}
  ${props =>
    props.FlexColumn &&
    `
    display: flex;
    flex-direction: column;
  `}
  ${props =>
    props.SpaceBetween &&
    `
    display: flex;
    justify-content: space-between;
  `}
  ${props =>
    props.Padding &&
    `
    padding: ${props.Padding};
  `}
  ${props =>
    props.MinWidth &&
    `
    min-width: ${props.MinWidth};
  `}
  ${props =>
    props.width &&
    `
    width: ${props.width};
  `}
  ${props =>
    props.End &&
    `
    text-align: end;
  `}
  ${props =>
    props.mBottom &&
    `
    margin-bottom: ${props.mBottom};
  `}
  ${props =>
    props.PaddingPrint &&
    `
    @media print{
      padding: ${props.PaddingPrint} !important;
    }
  `}
`

export const IconBlue = styled.i`
  :before {
    color: #3b99fc !important;
  }
  @media print {
    color: #3b99fc !important;
  }
`

export const Check = styled.div`
  height: 100%;
  width: 100%;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  border-radius: 2px;
  padding-top: 2px;
  .fa:before {
    color: red;
  }

  ${props => props.bgColor && `background-color: ${props.bgColor} !important;`}

  &::before {
    ${props =>
      props.fontFamily &&
      `font-family: "${props.fontFamily}";
      font-weight: 900;`}
    ${props => props.color && `color: ${props.color} !important;`}
      ${props => props.content && `content: "${props.content}";`}
  }

  ${props =>
    props.Summary &&
    `
      width: 40px;
      height: 23px;
      padding-top: 0px;
      margin-right: 10px;
    `}
`

export const ResidenceScoresLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-size: 14px;
  padding-bottom: 5px;
  @media print {
    display: none;
  }
`

export const Column = styled.div`
  margin: 0 10px;
  width: 100%;
  ${props => props.flex && 'display: flex;'}
  ${props => props.half && 'width: 50%;'}
  ${props => props.noMargin && 'margin: 0px;'}
`
export const H4 = styled.h4`
  font-weight: ${props => (props.weight ? props.weight : '600')};
  display: inline;
  font-size: 18px;
  font-family: 'Source Sans Pro';
  color: ${props => (props.color ? props.color : '#4a4a4a')} !important;
`
export const CriminalImageContainer = styled.div`
  flex: 0 0 170px;
  margin-right: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.noneImg && !props.trait && 'background-color: #cad2d3 !important;'};

  ${props => props.trait && 'flex: 0 0 104px; min-height: 104px;'}
  ${props =>
    props.noneImg && props.trait && 'background-color: #405369; height:104px; !important;'};
`

export const CriminalRemarkContainer = styled.div`
  font-size: 12px;
`

export const CriminalNoImg = styled.i`
  font-size: 64px;
  color: #ffffff !important;
`

export const CriminalNoTattooImg = styled.div`
  font-size: 14px;
  color: #ffffff !important;
  font-weight: 600;
`

export const CriminalStatePanel = styled.div`
  width: 100%;
  min-height: 60px;
  display: inline-flex;
  padding: 0px;
  margin-bottom: 22px;

  ${breakpoints.mobileReport(`
    min-height: 78px;
    background: ${COLOR.steelBluePale};
    margin-bottom: 10px;
    div {
      height: auto;
      background: none !important;
      border: 0px;
    }
  `)}

  ${props =>
    props.MobileCompact &&
    breakpoints.mobileReport(`
      div:nth-child(3) {
        flex-wrap: wrap;
        div {
          justify-content: space-between;
          width: 100%
        }

        div > p {
          font-size: 12px;
          font-weight: 400;
        }
      }
  `)}
`

export const GradientLine = styled.div`
  @media print {
    ${props => props.MarginPrint && `margin: ${props.MarginPrint};`}
  }
  width: 100%;
  ${props => props.Margin && `margin: ${props.Margin};`}
  height: ${props => (props.Height ? props.Height : '6')}px;
  background-image: linear-gradient(to right, #007adf, #00b4cd 77%, #00ecbc) !important;
`
