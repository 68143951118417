import produce from "immer"
import {
  OPEN_TOGGLE_PROPERTY_FEATURE_MODAL,
  CLEAR_TOGGLE_PROPERTY_FEATURE_MODAL,
  DISABLE_FEATURE,
  ENABLE_FEATURE,
} from "./constants"

export const initialState = {
  isLoading: false,
  feature: "",
  type: "",
  source: "",
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_TOGGLE_PROPERTY_FEATURE_MODAL:
        draft.feature = payload.feature
        draft.type = payload.type
        draft.source = payload.source
        break
      case CLEAR_TOGGLE_PROPERTY_FEATURE_MODAL:
        draft = initialState
        break
      case DISABLE_FEATURE.CALL:
      case ENABLE_FEATURE.CALL:
        draft.isLoading = true
        break
      case DISABLE_FEATURE.SUCCESS:
      case DISABLE_FEATURE.FAILED:
      case ENABLE_FEATURE.SUCCESS:
      case ENABLE_FEATURE.FAILED:
        draft.isLoading = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
