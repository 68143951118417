import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR, NEW_BLUE_LINEAR_GRADIENT } from 'styles/settings'

const ProgressBarContainer = styled.div`
  height: 5px;
  border-radius: 10px;
  background: ${COLOR.newGrey};
  width: 100%;
`
const PercentBar = styled.div`
  height: 5px;
  border-radius: 10px;
  background: ${props => (props?.percent === 100 ? COLOR.green : props.color)};
  width: ${props => props?.percent}%;
`

const ProgressBar = ({ percent, color }) => (
  <ProgressBarContainer>
    <PercentBar color={color} percent={percent} />
  </ProgressBarContainer>
)

export default ProgressBar

ProgressBar.defaultProps = {
  color: NEW_BLUE_LINEAR_GRADIENT,
}

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.string,
}
