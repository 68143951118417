import { BlueGradientButton } from '@rentspree/component-v2'
import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { COLOR, breakpoints } from 'styles/settings'

const Button = styled(BlueGradientButton)`
  margin-left: auto;
  padding: 9px 25px;
  font-size: 16px;
  font-weight: 600;
  color: ${COLOR.white};
  border-radius: 20px;

  ${breakpoints.mobile(`
    position: fixed;
    transform: translate(-50%, 0%);
    left: 50%;
    bottom: 30px;
  `)}
`

// TODO: [lnr-2055]: Add unit tests
const RequestButton = ({ disabled, onClick }) => (
    <Button disabled={disabled} onClick={onClick} text="Request" />
)

RequestButton.propTypes = {
  disabled: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired,
}

export default RequestButton