import { get } from 'lodash'
import { takeLatest, put, call } from 'redux-saga/effects'

import { IDENTITY_VERIFICATION_STATUS } from 'v3/containers/identity-verification/constants'
import { OVERRIDE_PAYEE_IDV_MAPPER } from 'v3/containers/rent-payment/landing-page/constants'
import { isOverridePayeeIdv } from 'v3/containers/rent-payment/landing-page/utils'

import { GET_PAYEE, getPayeeAPI } from '../../actions'
import { getPayeeInstance } from '../../api-instances'

export function* getPayeeSaga() {
  yield put(getPayeeAPI.request())
  try {
    // Override payee idv when fetching
    const isOverride = isOverridePayeeIdv()
    let response = yield call(getPayeeInstance)
    if (isOverride) {
      const payeeIdv = get(response, 'identityVerification', {})
      const payeeIdvStatus = get(payeeIdv, 'status', IDENTITY_VERIFICATION_STATUS.ONBOARDING)
      const overrideIdvStatus = OVERRIDE_PAYEE_IDV_MAPPER[payeeIdvStatus] || payeeIdvStatus
      response = {
        ...response,
        identityVerification: {
          ...payeeIdv,
          status: overrideIdvStatus,
        },
      }
    }
    yield put(getPayeeAPI.success(response))
  } catch (err) {
    yield put(getPayeeAPI.failure(err))
  }
}

export function* watchGetPayeeSaga() {
  yield takeLatest(GET_PAYEE, getPayeeSaga)
}
