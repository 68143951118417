export const GET_AGENT_PROFILE_CALL = "GET_AGENT_PROFILE_CALL"
export const GET_AGENT_PROFILE_REQUEST = "GET_AGENT_PROFILE_REQUEST"
export const GET_AGENT_PROFILE_SUCCESS = "GET_AGENT_PROFILE_SUCCESS"
export const GET_AGENT_PROFILE_FAILURE = "GET_AGENT_PROFILE_FAILURE"

export const UPDATE_AGENT_LICENSES_REQUEST = "UPDATE_AGENT_LICENSES_REQUEST"
export const UPDATE_AGENT_LICENSES_SUCCESS = "UPDATE_AGENT_LICENSES_SUCCESS"
export const UPDATE_AGENT_LICENSES_FAILURE = "UPDATE_AGENT_LICENSES_FAILURE"

export const UPDATE_AGENT_BROKERAGE_NAME = "UPDATE_AGENT_BROKERAGE_NAME"
