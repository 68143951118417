/* eslint-disable jsx-a11y/anchor-is-valid */
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import MenuItem from '@mui/material/MenuItem'
import Button from '@rentspree/component-2023.components.atoms.button'
import Link from '@rentspree/component-2023.components.atoms.link'
import Select from '@rentspree/component-2023.components.atoms.select'
import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import dayjs from 'dayjs'
import { Field } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import React from 'react'

import { DatePickerInput } from 'v3/components/date-picker-input'

import {
  PAYMENT_CATEGORIES,
  ADD_ANOTHER_MOVE_IN_FEE_FORM_BTN_TEXT,
  QUOTATION_LABELS,
  DEFAULT_ONE_TIME_CATEGORY_PAYMENT_SECTION_TITLE,
  DEFAULT_ADDITIONAL_ONE_TIME_PAYMENT_SECTION_TITLE,
} from './constants'
import './styles.scss'

const BaseOneTimePaymentForm = props => {
  const {
    index,
    values,
    errors,
    touched,
    handleRemove,
    setFieldTouched,
    handleChange,
    setFieldValue,
    fixCategoryField,
  } = props

  const getFieldKey = fieldName => `oneTimePayments.${index}.${fieldName}`
  const getFieldValue = fieldName => get(values, getFieldKey(fieldName))

  const isShowCustomCategoryField = getFieldValue('category') === PAYMENT_CATEGORIES.OTHER

  return (
    <>
      <div className="agt-init-payment-form-input-container">
        {!fixCategoryField && (
          <Select
            sx={{ fontSize: '16px' }}
            data-testid={getFieldKey('category')}
            label={QUOTATION_LABELS.CATEGORY}
            name={getFieldKey('category')}
            onChange={handleChange}
            onBlur={() => {
              setFieldTouched(getFieldKey('category'))
            }}
            error={
              get(touched, getFieldKey('category'), false) && get(errors, getFieldKey('category'))
            }
            helperText={
              get(touched, getFieldKey('category'), false) && get(errors, getFieldKey('category'))
            }
            value={getFieldValue('category')}
          >
            {Object.values(PAYMENT_CATEGORIES).map(value => (
              <MenuItem key={value} value={value}>
                <Typography variant="body-medium">{value}</Typography>
              </MenuItem>
            ))}
          </Select>
        )}
        {isShowCustomCategoryField && (
          <TextInput
            data-testid={getFieldKey('customCategory')}
            required
            label={QUOTATION_LABELS.OTHER_CATEGORY_TEXT}
            name={getFieldKey('customCategory')}
            onChange={handleChange}
            onBlur={() => {
              setFieldTouched(getFieldKey('customCategory'))
            }}
            error={
              get(touched, getFieldKey('customCategory'), false) &&
              get(errors, getFieldKey('customCategory'))
            }
            helperText={
              get(touched, getFieldKey('customCategory'), false) &&
              get(errors, getFieldKey('customCategory'))
            }
            value={getFieldValue('customCategory')}
          />
        )}
        <TextInput
          data-testid={getFieldKey('amount')}
          required
          label="Amount"
          name={getFieldKey('amount')}
          startAdornment="$"
          onChange={event => {
            setFieldValue(getFieldKey('amount'), event.target.value)
          }}
          onBlur={() => {
            setFieldTouched(getFieldKey('amount'))
          }}
          numberFormatOptions={{
            thousandSeparator: true,
            decimalScale: 2,
          }}
          error={get(touched, getFieldKey('amount'), false) && get(errors, getFieldKey('amount'))}
          helperText={
            get(touched, getFieldKey('amount'), false) && get(errors, getFieldKey('amount'))
          }
          value={getFieldValue('amount')}
        />
        <Field name={getFieldKey('dueDate')}>
          {({ field }) => (
            <DatePickerInput
              {...field}
              value={field.value}
              data-testid={getFieldKey('dueDate')}
              label="Due date"
              name={getFieldKey('dueDate')}
              onDateChange={value => setFieldValue(getFieldKey('dueDate'), value)}
              onBlur={() => {
                setFieldTouched(getFieldKey('dueDate'))
              }}
              error={
                get(touched, getFieldKey('dueDate'), false) && get(errors, getFieldKey('dueDate'))
              }
              helperText={
                get(touched, getFieldKey('dueDate'), false) && get(errors, getFieldKey('dueDate'))
              }
              minDate={dayjs()}
              required
            />
          )}
        </Field>
      </div>
      {!fixCategoryField && (
        <Button
          data-testid={getFieldKey('remove-button')}
          variant="text"
          color="secondary"
          onClick={handleRemove}
          className="agt-init-payment-form-remove-button"
        >
          <RemoveIcon />
          <Link color="black">Remove</Link>
        </Button>
      )}
    </>
  )
}

const OneTimePaymentForm = props => {
  const { values, touched, errors, setValues, setTouched, setErrors, isQuotationLimitExceeded } =
    props
  const oneTimePaymentsValues = values?.oneTimePayments || []
  const handleRemove = index => {
    const updatedValue = cloneDeep(oneTimePaymentsValues)
    updatedValue.splice(index, 1)
    setValues({
      ...values,
      oneTimePayments: updatedValue,
    })

    const updatedError = cloneDeep(get(errors, 'oneTimePayments'))
    if (updatedError) {
      updatedError.splice(index, 1)
      setErrors({
        ...errors,
        oneTimePayments: updatedError,
      })
    }

    const updatedTouched = cloneDeep(get(touched, 'oneTimePayments'))
    if (updatedTouched) {
      updatedTouched.splice(index, 1)
      setTouched({
        ...touched,
        oneTimePayments: updatedTouched,
      })
    }
  }

  const handleAdd = () => {
    if (isQuotationLimitExceeded) return
    const updatedValue = cloneDeep(oneTimePaymentsValues)
    updatedValue.push({
      category: '',
      customCategory: '',
      amount: '',
      dueDate: values?.oneTimePayments?.[0]?.dueDate || '',
      key: Date.now(),
    })
    setValues({
      ...values,
      oneTimePayments: updatedValue,
    })
  }

  return (
    <>
      {oneTimePaymentsValues?.map((item, idx) => (
        <div className="agt-init-payment-form-section-container">
          {item.isInitiallyAdded && (
            <Typography variant="title">
              {DEFAULT_ONE_TIME_CATEGORY_PAYMENT_SECTION_TITLE}
            </Typography>
          )}
          {idx === 1 && (
            <Typography variant="title">
              {DEFAULT_ADDITIONAL_ONE_TIME_PAYMENT_SECTION_TITLE}
            </Typography>
          )}
          <BaseOneTimePaymentForm
            {...props}
            key={item.key}
            index={idx}
            handleRemove={() => handleRemove(idx)}
            fixCategoryField={item.isInitiallyAdded && PAYMENT_CATEGORIES.SECURITY_DEPOSIT}
          />
        </div>
      ))}
      {!isQuotationLimitExceeded && (
        <Button
          className="agt-init-payment-form-add-button"
          variant="text"
          color="secondary"
          onClick={handleAdd}
          data-testid="one-time-payment-add-additional-button"
        >
          <AddIcon />
          <Link color="black">{ADD_ANOTHER_MOVE_IN_FEE_FORM_BTN_TEXT}</Link>
        </Button>
      )}
    </>
  )
}

export default OneTimePaymentForm
