import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import { openCreateContactModal, closeCreateContactModal, createContactFromModal } from './actions'
import reducer from './reducer'
import saga from './saga'
import { selectIsOpen, selectIsSubmitting } from './selectors'

const mapStateToProps = createStructuredSelector({
  isOpen: selectIsOpen,
  isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCreateContactModal,
      closeCreateContactModal,
      createContactFromModal,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withSaga = injectSaga({ key: 'contacts.createContactModal', saga })

export const withReducer = injectReducer({
  key: 'contacts.createContactModal',
  reducer,
})
