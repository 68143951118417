import React from "react"
import styled, { css } from "styled-components"
import { ANIMATE } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
const StyledCreditPaymentBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  opacity: 1;
  max-height: 100px;
  pointer-events: auto;
  transition: max-height ease ${ANIMATE.normal};
  @media (max-width: 991px) {
    flex-direction: column;
    max-height: 180px;
    > h3 {
      margin-top: 0;
    }
    .payment-action {
      justify-content: space-between;
      > a {
        padding: 10px 15px;
      }
    }
  }

  ${props =>
    props.isSubscribed &&
    css`
      opacity: 0;
      max-height: 0 !important;
      margin-bottom: 0;
      pointer-events: none;
    `}
`

export const CreditPaymentBox = ({ children, ...props }) => (
  <StyledCreditPaymentBox {...props}>{children}</StyledCreditPaymentBox>
)
