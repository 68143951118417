import generateApiCall from "utils/generate-api-call"

import {
  ARCHIVE_PROPERTY_CALL,
  ARCHIVE_PROPERTY_REQUEST,
  ARCHIVE_PROPERTY_SUCCESS,
  ARCHIVE_PROPERTY_FAILED,
  UNARCHIVE_PROPERTY_CALL,
  UNARCHIVE_PROPERTY_REQUEST,
  UNARCHIVE_PROPERTY_SUCCESS,
  UNARCHIVE_PROPERTY_FAILED,
  OPEN_ARCHIVE_MODAL,
  CLEAR_ARCHIVE_MODAL,
} from "./constants"

export const archiveProperty = payload => ({
  type: ARCHIVE_PROPERTY_CALL,
  payload,
})

export const unarchiveProperty = payload => ({
  type: UNARCHIVE_PROPERTY_CALL,
  payload,
})

export const openArchiveModal = payload => ({
  type: OPEN_ARCHIVE_MODAL,
  payload,
})

export const clearArchiveModal = () => ({
  type: CLEAR_ARCHIVE_MODAL,
})

export const archivePropertyApiCall = generateApiCall([
  ARCHIVE_PROPERTY_REQUEST,
  ARCHIVE_PROPERTY_SUCCESS,
  ARCHIVE_PROPERTY_FAILED,
])

export const unarchivePropertyApiCall = generateApiCall([
  UNARCHIVE_PROPERTY_REQUEST,
  UNARCHIVE_PROPERTY_SUCCESS,
  UNARCHIVE_PROPERTY_FAILED,
])
