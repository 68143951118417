import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { SET_SEEN_NOTIFICATION_BY_ID_API } from './constants'

export const setSeenNotificationByIdInstance = ({ notificationId }) =>
  apiInstanceWithErrorHandler.post(
    buildPath(SET_SEEN_NOTIFICATION_BY_ID_API, { notificationBadgeId: notificationId }),
    {},
  )
