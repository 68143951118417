import { createSelector } from "reselect"
import { GET_START_MODAL } from "tracker/const"
import { initialState } from "./reducer"
export const selectGetStarted = ({ getStarted }) => getStarted || initialState

export const selectGetSliders = () =>
  createSelector(
    selectGetStarted,
    ({ getStartSlider }) => {
      const getStartSliderList = getStartSlider.map(item => {
        const trackingObj = GET_START_MODAL.step.find(
          ({ key }) => key === item.key,
        )
        return { ...item, eventConst: trackingObj }
      })
      return getStartSliderList
    },
  )

export const makeSelectIsGetStartedEmpty = () =>
  createSelector(
    selectGetStarted,
    ({ getStartSlider, isFetched }) => isFetched && getStartSlider.length === 0,
  )
