import React from 'react'
import styled from 'styled-components'

const TextWrapper = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: 0;
`

// TODO: [lnr-2055]: Add unit tests
const Text = ({ text, fontSize = '16px', lineHeight = '22px' }) => (
  <TextWrapper fontSize={fontSize} lineHeight={lineHeight}>
    {text}
  </TextWrapper>
)

export default Text
