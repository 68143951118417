import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

// ToDo: refactor reducer data structure
import { withReducer } from 'containers/property-list/connect'

import { setContinueToPropertyDetails } from 'containers/request/step-create-property/actions'
import {
  setCarModalState,
  linkAccountWithoutSSO,
  closeErrorInModal as closeCarModalError,
} from 'containers/envelope/select-options-step/actions'
import { selectAgentLicenses } from 'containers/agent-profile/selectors'
import { createContact, createContacts } from 'containers/contacts/actions'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import {
  verifyPropertyAddress,
  resetAddressVerification,
} from 'containers/address-verification/actions'
import {
  selectIsVerifyingPropertyAddress,
  selectAddressVerification,
} from 'containers/address-verification/selectors'

import { withLegacyScreeningFeatureGate } from 'utils/feature-flag/features/premium-screening'
import * as Subdomain from '../../reducers/subdomain.reducer'
import * as PersistState from '../../reducers/persist-state.reducer'

import * as ApplicationAction from '../../actions/application.action'
import * as PropertyAction from '../../actions/property.action'
import * as PropertyListAction from '../../actions/property-list.action'
import * as RequestActions from '../../actions/request.action'
import * as ShowPermalink from '../../actions/rental-submission.action'
import * as UserActions from '../../actions/user.action'
import * as SweetAlertActions from '../../actions/sweet-alert.action'

const RequestMainConnect = ComposedComponent =>
  compose(
    connect(
      state => ({
        requestOptions: state.request,
        property: state.property,
        accessToken: PersistState.getAccessToken(state.persistState),
        subdomain: Subdomain.getSub(state.subdomain),
        rentalSubmission: state.rentalSubmission,
        // ToDo: use selector
        propertyDropdown: {
          dropdown: state.propertyList ? state.propertyList.dropdown : [],
          isFetching: state.propertyList ? state.propertyList.isFetchingDropdown : false,
        },
        isCreatingProperty: state.property.isFetching,
        profile: state.user.profile,
        agentLicenses: selectAgentLicenses(state),
        userIntegration: state.user.userIntegration,
        isFetchingProfile: state.user.isFetching,
        isFetchedPreference: state.user.isFetchedPreference,
        isUpdatingProfile: state.user.isUpdating,
        fullNameAddress: state.property.property.fullNameAddress,
        preference: state.user.preference,
        createSuccess: state.property.property.createSuccess,
        carModalState: state.eSignTemplateList.carModalState,
        carLinkingAccountLoading: state.eSignTemplateList.carLinkingAccountLoading,
        carLinkingAccountSuccess: state.eSignTemplateList.carLinkingAccountSuccess,
        carLinkingAccountError: state.eSignTemplateList.carLinkingAccountError,
        isVerifyingPropertyAddress: selectIsVerifyingPropertyAddress(state),
        addressVerification: selectAddressVerification(state),
      }),
      dispatch => ({
        actions: bindActionCreators(
          {
            ...ApplicationAction,
            ...PropertyAction,
            ...PropertyListAction,
            ...RequestActions,
            ...ShowPermalink,
            ...SweetAlertActions,
            ...UserActions,
            setContinueToPropertyDetails,
            setCarModalState,
            linkAccountWithoutSSO,
            closeCarModalError,
            openSweetAlertBaseError,
            createContact,
            createContacts,
            verifyPropertyAddress,
            resetAddressVerification,
          },
          dispatch,
        ),
      }),
    ),
    withReducer,
    withLegacyScreeningFeatureGate,
  )(ComposedComponent)

export default RequestMainConnect
