import CircularProgress from '@rentspree/component-2023.components.organisms.circular-progress'
import React from 'react'
import './style.scss'

/**
 * @typedef CenterLoadingProps
 * @property {"small" | "medium"=} [props.loadingSize='medium'] - The size of the loading indicator. Can be 'small' or 'medium'.
 * @property {"primary" | "secondary" | "black"=} [props.color='primary'] - The color scheme of the loading indicator. Can be 'primary', 'secondary', or 'black'.
 * @property {"fit" | "full" | "auto"=} [props.height='fit'] - the height of the loading indicator container. Can be 'fit', full, or 'auto'.
 */

/**
 * A centered loading indicator component.
 *
 * @param {CenterLoadingProps} props
 * @returns {JSX.Element} The JSX element representing the centered loading indicator.
 */
export const CenterLoading = ({
  loadingSize = 'medium',
  color = 'primary',
  height = 'fit',
  ...props
}) => (
  <div {...props} className={`center-loading-container ${height}`}>
    <CircularProgress size={loadingSize} color={color} />
  </div>
)
