import React from "react"
import isEmpty from "lodash/isEmpty"
import TextInput from "@rentspree/component-v2/dist/input/text"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import { AlertPanel } from "components/shared/alert-panel"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import { withFormik } from "formik"
import { messagingEmailSchema } from "./schema"

export const Form = ({
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  loading,
  error,
  closeError,
}) => (
  <form onSubmit={handleSubmit}>
    <S24 margin="0 0 20px 0" mSize="24px">
      New Message
    </S24>
    {error && (
      <AlertPanel
        error
        handleClose={closeError}
        padding="10px 15px"
        margin="10px 0"
      />
    )}
    <TextInput
      id="messaging-email-input"
      name="email"
      label="Enter a recipient's email"
      type="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.email && errors.email}
      disabled={loading}
      autoFocus
      isRequired
    />
    <B16 margin="20px 0 0">
      Converse securely with applicants, landlords and other agents. Simply
      enter their emails and click the button below to get started.
    </B16>
    <BlueGradientButton
      margin="30px 0 0 0"
      small
      semiBold
      loading={loading}
      fontSize="16px"
      minWidth={201}
      id="messaging-start-conversation-button"
      className="left-btn"
      text="Start a Conversation"
      type="submit"
      disabled={isEmpty(values.email)}
    />
  </form>
)

const MessagingEmailForm = withFormik({
  mapPropsToValues: props => messagingEmailSchema(props).mapper,
  validationSchema: props => messagingEmailSchema(props).validation,
  handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
  },
  enableReinitialize: false,
  displayName: "MessagingEmailForm",
})(Form)

export default MessagingEmailForm
