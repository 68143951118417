import styled, { css } from "styled-components"
import { COLOR } from "styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const TextInput = styled.span`
  color: ${props => (props.color ? COLOR[props.color] : "inherit")};
  ${props =>
    props.strong &&
    css`
      font-weight: bold;
    `};
  ${props =>
    props.light &&
    css`
      font-weight: 300;
    `};
  ${props =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
  ${props =>
    props.size &&
    css`
      font-size: ${props.size};
    `};
  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `};
  ${props =>
    props.leftSpace &&
    css`
      margin-left: ${props.leftSpace};
    `};
  ${props =>
    props.rightSpace &&
    css`
      margin-right: ${props.rightSpace};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.m0 && "margin: 0px"};
  ${props =>
    props.mMargin &&
    css`
      @media (max-width: 991px) {
        margin: ${props.mMargin};
      }
    `};
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `};
  ${props => props.mCenter && "@media(max-width:991px) {text-align: center}"};
  ${props => props.lineThrough && "text-decoration: line-through"};
  ${props => props.center && "text-align: center"};
  ${props => props.letterSpacing && `letter-spacing: ${props.letterSpacing}px`};
  ${props =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `};
  @media (max-width: 991px) {
    ${props =>
      props.mSize &&
      css`
        font-size: ${props.mSize};
      `};
    ${props =>
      props.mAlign &&
      css`
        text-align: ${props.mAlign};
      `};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const Label = styled.label`
  margin-bottom: 0;
`

export const Span = styled(TextInput)``

export const I = styled(TextInput).attrs(() => ({ as: "i" }))``

export const WithP = styled(TextInput).attrs(() => ({ as: "p" }))``
export const P16 = styled(WithP)`
  font-size: 16px;
`
export const P = styled(WithP)`
  font-size: ${props => (props.size ? props.size : "16px")};
  color: ${props => (props.inherit ? "inherit" : COLOR.newBlack)};
`
export const Small = styled(WithP)`
  font-size: ${props => (props.size ? props.size : "14px")};
`
export const Large = styled(WithP)`
  font-size: ${props => (props.size ? props.size : "18px")};
`

export const TextDiv = styled(TextInput).attrs(() => ({ as: "div" }))``

export const H1 = styled(TextInput).attrs(() => ({ as: "h1" }))`
  font-size: 28px;
  color: ${COLOR.newBlack};
  @media (max-width: 991px) {
    font-size: 24px;
  }
`

export const H2 = styled(TextInput).attrs(() => ({ as: "h2" }))``
export const H3 = styled(TextInput).attrs(() => ({ as: "h3" }))`
  line-height: 1.3;
`
export const H4 = styled(TextInput).attrs(() => ({ as: "h4" }))`
  line-height: 1.4;
  ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom}` : "")}
`
