import styled from "styled-components"

import { COLOR } from "styles/settings"

const ScrollTextPanel = styled.div`
  padding: 15px 20px;
  height: ${({ height }) => height || "360px"};
  border-radius: 5px;
  background-color: ${COLOR.bgGrey};
  border: solid 1px ${COLOR.borderGrey};
  color: ${COLOR.newBlack};
  overflow-y: scroll;
  @media (min-width: 992px) {
    ${({ flex }) => flex && `flex: ${flex};`}
  }
  @media (max-width: 991px) {
    height: ${({ mHeight }) => mHeight || "260px"}};
    ${({ mFlex }) => mFlex && `flex: ${mFlex};`}
  }
`
export default ScrollTextPanel
