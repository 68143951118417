import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import injectReducer from "utils/inject-reducer"
import { getProfile } from "legacy/actions/user.action"

import reducer from "./reducer"
import { selectUser, makeSelectIsReportsReady } from "./selectors"

const makeMapStateToProps = () =>
  createStructuredSelector({
    isReportsReady: makeSelectIsReportsReady(),
    user: selectUser,
  })

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile,
    },
    dispatch,
  )

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({
  key: "printData",
  reducer,
})
