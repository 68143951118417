export const UPDATE_PROPERTY_LISTING_STATUS = "UPDATE_PROPERTY_LISTING_STATUS"

export const TOGGLE_ACCEPTING_STATUS_CALL = "TOGGLE_ACCEPTING_STATUS_CALL"
export const TOGGLE_ACCEPTING_STATUS_REQUEST = "TOGGLE_ACCEPTING_STATUS_REQUEST"
export const TOGGLE_ACCEPTING_STATUS_SUCCESS = "TOGGLE_ACCEPTING_STATUS_SUCCESS"
export const TOGGLE_ACCEPTING_STATUS_FAILURE = "TOGGLE_ACCEPTING_STATUS_FAILURE"

export const GET_APPLY_LINK_CALL = "GET_APPLY_LINK_CALL"
export const GET_APPLY_LINK_REQUEST = "GET_APPLY_LINK_REQUEST"
export const GET_APPLY_LINK_SUCCESS = "GET_APPLY_LINK_SUCCESS"
export const GET_APPLY_LINK_FAILURE = "GET_APPLY_LINK_FAILURE"