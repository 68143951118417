import React from "react"
import styled from "styled-components"
import RentspreeLogo from "../../images/logos/rentspree-logo-without-text.svg"

export const LogoWrapper = styled.div`
  position: relative;
  margin: 140px 0px 30px 0px;
`

export const Shadow = styled.div`
  z-index: -1;
  height: 10px;
  background: #000;
  border-radius: 80%;
  opacity: 0.5;
  animation: shadowAnimation 2s cubic-bezier(0.75, 0, 0.25, 1) infinite;
  @keyframes shadowAnimation {
    0% {
      animation-timing-function: ease-out;
      transform: scale(0.8, 1);
    }
    50% {
      animation-timing-function: ease-in;
      transform: scale(0.25, 0.5);
      opacity: 0.3;
    }
    100% {
      animation-timing-function: linear;
      transform: scale(0.8, 1);
    }
  }
`

export const LogoIcon = styled.img`
  position: relative;
  z-index: 1;
  animation: rotate 2s infinite;
  height: 100px;
  @keyframes rotate {
    0% {
      animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
      transform-style: preserve-3d;
      transform: rotateY(0deg) scale3d(1, 0.85, 1) translateY(15px);
    }
    50% {
      animation-timing-function: cubic-bezier(0.6, 0, 1, 1);
      transform-style: preserve-3d;
      transform: rotateY(360deg) scale3d(1, 1, 1) translateY(-30px);
    }
    100% {
      animation-timing-function: linear;
      transform-style: preserve-3d;
      transform: rotateY(0deg) scale3d(1, 0.85, 1) translateY(15px);
    }
  }
`

export const LogoLoading = () => (
  <LogoWrapper className="logo-wrapper">
    <LogoIcon src={RentspreeLogo} alt="RentSpree Logo" />
    <Shadow />
  </LogoWrapper>
)
