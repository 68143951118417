import { parseArguments } from "../helper/helper"
import { RESIZING_TYPE } from "../types"

export class ResizeProcessingOption {
  width

  height

  enlarge

  extend

  rsType

  orderOptions = ["rsType", "width", "height", "enlarge", "extend"]

  defaultValue = {
    rsType: RESIZING_TYPE.FIT,
    width: undefined,
    height: undefined,
    enlarge: false,
    extend: false,
  }

  constructor({ rsType, width, height, enlarge, extend }) {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
    this.rsType = rsType
  }

  update({ rsType, width, height, enlarge, extend }) {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
    this.rsType = rsType
    return this
  }

  parseOptions() {
    const argsOrder = this.orderOptions.map(key => this[key])
    const defaultValue = this.orderOptions.map(key => this.defaultValue[key])

    const args = parseArguments(argsOrder, defaultValue)

    return `rs:${args}`
  }
}
