import moment from "moment"
import numeral from "numeral"

export const convertDateFormat = (value, format) =>
  moment.utc(value).isValid()
    ? moment.utc(value).format(format || "DD MMM YYYY")
    : "-"

export const convertNumberFormat = (value, format) =>
  value ? numeral(value).format(format || "0,0") : "-"
