import { combineReducers } from "redux"
import * as Types from "../constants/action-types"
import { loadAccessToken } from "../store/middleware/persist-state" // eslint-disable-line import/no-cycle

const auth = (state = loadAccessToken(), action) => {
  switch (action.type) {
    case Types.LOGIN_SUCCESS:
      if (action.access_token) {
        return {
          accessToken: action.access_token,
          refreshToken: action.refresh_token,
          expiresIn: action.expires_in,
        }
      }
      return {
        accessToken: action.result.access_token,
        refreshToken: action.result.refresh_token,
        expiresIn: action.result.expires_in,
      }
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case Types.LOGIN_FAILED:
      return action.meta
    case Types.LOGIN_FACEBOOK_FAILED:
      return { ...action.meta, type: "facebook" }
    case Types.LOGIN_GOOGLE_FAILED:
      return { ...action.meta, type: "google" }
    case Types.REGISTER_FAILED:
      return action.meta
    case Types.LOGIN_SUCCESS:
      return null
    case Types.CLEAR_ERROR_MESSAGE:
      return null
    default:
      return state
  }
}

const fetchingRefreshToken = (state = null, action) => {
  switch (action.type) {
    case Types.FETCHING_REFRESH_TOKEN:
      return action.isFetching
    default:
      return state
  }
}

export default combineReducers({
  auth,
  errorMessage,
  fetchingRefreshToken,
})

export const getRefreshToken = state => state.auth.refreshToken
export const getExpiresIn = state => state.auth.expiresIn
export const getAccessToken = state => state.auth.accessToken
export const isFetchingRefreshToken = state => state.fetchingRefreshToken
export const getIsLogin = state => !!state.auth.accessToken
export const getErrorMessage = state => state.errorMessage
