import get from 'lodash/get'
import React from 'react'

import NullDataReport from 'components/reports/screening-reports/share/null-report'
import { HeaderDoc } from 'components/reports/screening-reports/share/doc-components'
import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  ApplicantOverview,
  ReportsBody,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import HeaderPrint from 'containers/reports/header'
import { NoReport } from 'legacy/components/reports/share/doc-components'
import CompanyInfoFooter from 'legacy/components/reports-v2/share/company-info-footer'
import { isEmptyArray, generateHeaderName } from 'legacy/components/reports-v2/share/function'
import { convert } from 'helpers/convert'

import { evictionMapper } from './eviction-report/mapper'
import EvictionRecord from './eviction-report/eviction-record'
import { Attention } from './criminal-report/doc-components/attention'

export default function EvictionReportComponent({
  evictionRecords,
  generatedOn,
  expiresOn,
  renter,
  landlord,
  applicant,
  showHeaderComponent,
  customBorder,
  customMargin,
}) {
  const reportName = 'Eviction Related Proceedings'

  if (!evictionRecords) {
    return <NullDataReport generatedOn={generatedOn} headerDoc={reportName} />
  }

  const evictions = evictionMapper(evictionRecords?.Records)
  const fullName = generateHeaderName(evictionRecords?.RequestedConsumer)
  const email = get(renter, 'email')
  const phoneNumber = get(renter, 'homePhoneNumber')
  const formattedPhoneNumber = convert(phoneNumber, 'phoneNumber')
  const ssnMessage = applicant.sSNMessage
  const headerDocProps = {
    ssnMessage,
    text: reportName,
    transUnion: { expiresOn, generatedOn },
    renter,
    landlord,
  }
  return (
    <ReportWrapper border={customBorder} margin={customMargin}>
      {showHeaderComponent && <HeaderPrint />}
      <ReportsBody>
        <HeaderDoc {...headerDocProps} />
        <ApplicantOverview
          name={fullName}
          email={email}
          phone={formattedPhoneNumber}
          withIncorrectInfo
        />
        {!isEmptyArray(evictions) ? (
          evictions.map((eviction, index) => (
            <EvictionRecord
              eviction={eviction}
              index={index + 1}
              state={get(eviction, 'State')}
              key={index.toString()}
            />
          ))
        ) : (
          <NoReport text="No records to return" />
        )}
        <CompanyInfoFooter isTUReport />
        <Attention />
      </ReportsBody>
    </ReportWrapper>
  )
}
