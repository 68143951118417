import { call, put, takeLatest } from 'redux-saga/effects'

import { GET_OR_CREATE_RENTER_INFO, getOrCreateRenterInfoAPI } from '../../actions'
import { getOrCreateRenterInfoInstance } from '../../api-instances'

export function* getOrCreateRenterInfoSaga({ payload }) {
  const { firstName, lastName, email, phone, onSuccess, onError } = payload

  yield put(getOrCreateRenterInfoAPI.request())

  try {
    const renterInfo = yield call(getOrCreateRenterInfoInstance, {
      firstName,
      lastName,
      email,
      phone: phone || '',
    })

    yield put(getOrCreateRenterInfoAPI.success(renterInfo))
    yield call(onSuccess, renterInfo)
  } catch (error) {
    yield put(getOrCreateRenterInfoAPI.failure(error))
    yield call(onError, error)
  }
}

export function* watchGetOrCreateRenterInfoSaga() {
  yield takeLatest(GET_OR_CREATE_RENTER_INFO, getOrCreateRenterInfoSaga)
}
