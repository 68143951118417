import {
  IMPORT_MLS_LISTING_CALLED,
  GET_IMPORTABLE_LISTING_CALLED,
  AUTO_IMPORT_MLS_LISTING_CALLED,
} from "./constants"

export const importMLSListing = payload => ({
  type: IMPORT_MLS_LISTING_CALLED,
  payload,
})

export const getImportableListing = payload => ({
  type: GET_IMPORTABLE_LISTING_CALLED,
  payload,
})

export const autoImportMLSListing = payload => ({
  type: AUTO_IMPORT_MLS_LISTING_CALLED,
  payload,
})
