import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Button from '@rentspree/component-2023.components.atoms.button'
import Menu from '@rentspree/component-2023.components.atoms.menu'
import MenuItem from '@rentspree/component-2023.components.atoms.menu-item'

import { NEXT_ACTION_TYPES } from 'containers/tenant-screening/assign-property/constants'
import { PARTICIPANT_TYPE } from 'containers/reports/constants'
import { ACCEPT_DENY_TYPE } from 'containers/accept-deny/constants'
import { APP_NOT_SUBMIT_STATUS } from 'containers/application/const'
import ActionDropdownIcon from '@mui/icons-material/MoreVert'
import { isApplicationActionable } from './helpers'

export const DropdownContainer = styled.div`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }

  a[role='menuitem'] {
    font-size: 14px;
    padding: 6px 15px 6px 15px;
  }

  .disabled > a {
    color: #22222280 !important;
  }
`

export const DropdownMobileContainer = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    .RSPButton {
      font-size: 1.3rem !important;
    }
  }

  /* for very-small device case, font size need to be push down little more */
  @media (max-width: 375px) {
    .RSPButton {
      font-size: 1rem !important;
    }
  }

  a[role='menuitem'] {
    font-size: 14px;
    padding: 6px 15px 6px 15px;
  }

  .disabled > a {
    color: #22222280 !important;
  }

  .dropdown-toggle {
    background-color: unset !important;
  }

  ul[role='menu'] {
    right: -32px;
    left: auto;
  }
`

export const ActionDropdown = ({
  rentalSubmission = {},
  source,
  eventSource,
  isAllowAcceptDeny,
  isAllowCarLease,
  onOpenAcceptDenyModal,
  onOpenAssignPropertyModal,
  onClickSetupLease,
  onClickSetupRentPayment,
  isNewUi = false,
}) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const generateAcceptDenyFunction = acceptDenyType => {
    const {
      status,
      participantType,
      _id: id,
      email,
      firstName,
      middleName,
      lastName,
      property,
      propertyAddress,
      participantId,
      screeningResult,
    } = rentalSubmission

    // Disable the accept/deny feature (not submitted yet, not allowed, result type is the same with current result)
    if (APP_NOT_SUBMIT_STATUS.includes(status) || !isAllowAcceptDeny) {
      return undefined
    }
    const acceptDenyProps = {
      rentalSubmission: {
        id,
        email,
        renter: { firstName, middleName, lastName },
      },
      propertyId: property,
      propertyAddress,
      isSharedReport: participantType === PARTICIPANT_TYPE.PARTICIPANT,
      acceptDenyType,
      clickFrom: source,
      screeningResult: screeningResult?.result,
      clickFromWidget: eventSource?.clickFromWidget,
    }
    // if have property, open accept/deny modal
    if (property) {
      return () => onOpenAcceptDenyModal({ ...acceptDenyProps })
    }
    // if no property, open assign property modal first
    return () =>
      onOpenAssignPropertyModal({
        participantId,
        nextAction: NEXT_ACTION_TYPES.ACCEPT_DENY_FROM_TABLE,
        nextActionProps: acceptDenyProps,
      })
  }
  const { property, status, screeningResult } = rentalSubmission
  const isAllowSetupLeaseAgreement =
    !isEmpty(property) && isApplicationActionable(status, screeningResult?.result)
  const isAllowSetupPayment =
    !isEmpty(property) && isApplicationActionable(status, screeningResult?.result)
  const menuItems = [
    {
      key: 'accept-applicant',
      label: 'Accept application',
      hideAction: !!rentalSubmission?.screeningResult?.result,
      onClick: generateAcceptDenyFunction(ACCEPT_DENY_TYPE.ACCEPT),
    },
    {
      key: 'decline-applicant',
      label: 'Decline application',
      hideAction: !!rentalSubmission?.screeningResult?.result,
      onClick: generateAcceptDenyFunction(ACCEPT_DENY_TYPE.DENY),
    },
    {
      key: 'change-decision',
      label: 'Change decision',
      hideAction: !rentalSubmission?.screeningResult?.result,
      onClick: generateAcceptDenyFunction(rentalSubmission?.screeningResult?.result),
    },
    {
      key: 'set-up-lease',
      label: 'Set up lease',
      hideAction: !isAllowCarLease,
      onClick: isAllowSetupLeaseAgreement && (() => onClickSetupLease(rentalSubmission?.property)),
    },
    {
      key: 'set-up-payment',
      label: 'Set up payments',
      onClick:
        isAllowSetupPayment &&
        (() => onClickSetupRentPayment(rentalSubmission?.property, rentalSubmission.email)),
    },
  ]

  const handleMenuButtonClick = event => {
    event.stopPropagation()
    setOpenMenu(true)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = event => {
    if (event) event.stopPropagation()
    setAnchorEl(null)
    setOpenMenu(false)
  }

  const renderMenuComponent = () =>
    menuItems.map(
      item =>
        !item.hideAction && (
          <MenuItem
            label={item.label}
            data-testid={`${source}-${item.key}-action`}
            key={`${item.key}`}
            disabled={!item.onClick}
            onClick={e => {
              e.stopPropagation()
              handleCloseMenu()
              if (item.onClick) item.onClick()
            }}
          />
        ),
    )

  return (
    <>
      <DropdownMobileContainer>
        {/* TODO: Remove when clean up feature flag new_ui_table_screening */}
        {isNewUi ? (
          <Button
            data-testid="open-action-mobile-button"
            onClick={handleMenuButtonClick}
            variant="text"
            color="secondary"
            size="small"
          >
            <ActionDropdownIcon sx={{ width: '24px', height: '24px' }} />
          </Button>
        ) : (
          <Button
            data-testid="open-action-mobile-button"
            onClick={handleMenuButtonClick}
            variant="text"
            color="secondary"
            size="small"
            endIcon={<ActionDropdownIcon sx={{ width: '24px', height: '24px' }} />}
          >
            Actions
          </Button>
        )}
      </DropdownMobileContainer>
      <DropdownContainer>
        <Button
          data-testid="open-action-button"
          onClick={handleMenuButtonClick}
          variant="text"
          color="secondary"
          size="small"
        >
          <ActionDropdownIcon sx={{ width: '24px', height: '24px' }} />
        </Button>
      </DropdownContainer>
      <Menu
        data-testid="action-menu"
        autoFocus={false}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        style={{
          marginLeft: 12,
        }}
        onClick={event => {
          event.stopPropagation()
        }}
      >
        {renderMenuComponent()}
      </Menu>
    </>
  )
}

export default ActionDropdown
