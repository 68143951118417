import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as ModalsActions from "../../actions/modals.action"

export default ComposedComponent =>
  connect(
    state => ({
      modals: state.modals,
      subscriptions: state.subscriptions,
      profile: state.user,
      subdomain: state.subdomain && state.subdomain.subdomain,
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          ...ModalsActions,
        },
        dispatch,
      ),
    }),
  )(ComposedComponent)
