import createAction from "../apis/utils/create-action"
import * as Types from "../constants/action-types"
import * as api from "../apis/user.api" // eslint-disable-line import/no-cycle

export const getProfile = createAction(
  api.detail,
  [
    Types.GET_PROFILE_REQUEST,
    Types.GET_PROFILE_SUCCESS,
    Types.GET_PROFILE_FAIL,
  ],
  data => ({ success: data.success, profile: data.result }),
)

export const refreshProfile = createAction(
  api.detail,
  [
    Types.REFRESH_PROFILE_REQUEST,
    Types.REFRESH_PROFILE_SUCCESS,
    Types.REFRESH_PROFILE_FAIL,
  ],
  data => ({ success: data.success, profile: data.result }),
)

export const getUserIntegrations = createAction(
  api.getUserIntegrations,
  [
    Types.GET_USER_INTEGRATION_REQUEST,
    Types.GET_USER_INTEGRATION_SUCCESS,
    Types.GET_USER_INTEGRATION_FAIL,
  ],
  data => ({ success: data.success, integration: data.result }),
)

export const logout = () => ({
  type: Types.REMOVE_ACCESS_TOKEN,
})

export const updateProfile = createAction(
  api.updateUserProfileApi,
  [
    Types.UPDATE_PROFILE_REQUEST,
    Types.UPDATE_PROFILE_SUCCESS,
    Types.UPDATE_PROFILE_FAILED,
  ],
  (data = {}) => ({ profile: data.result ? data.result : data }),
)

export const updateFlyerApi = createAction(
  api.updateFlyerCustomTextApi,
  [
    Types.UPDATE_FLYER_TEXT_REQUEST,
    Types.UPDATE_FLYER_TEXT_SUCCESS,
    Types.UPDATE_FLYER_TEXT_FAILED,
  ],
  data => data,
)

export const updateUserPreference = createAction(
  api.updateUserPreferenceApi,
  [
    Types.UPDATE_USER_PREFERENCE_REQUEST,
    Types.UPDATE_USER_PREFERENCE_SUCCESS,
    Types.UPDATE_USER_PREFERENCE_FAILED,
  ],
  (data = {}) => ({ data }),
)

export const getUserPreference = createAction(
  api.getUserPreferenceApi,
  [
    Types.GET_USER_PREFERENCE_REQUEST,
    Types.GET_USER_PREFERENCE_SUCCESS,
    Types.GET_USER_PREFERENCE_FAILED,
  ],
  (data = {}) => ({ data }),
)

export const saveToken = ({
  access_token: accessToken,
  refresh_token: refreshToken,
}) => ({
  type: Types.LOGIN_SUCCESS,
  access_token: accessToken,
  refresh_token: refreshToken,
})

export const fetchingRefreshToken = (isFetching = true) => ({
  type: Types.FETCHING_REFRESH_TOKEN,
  isFetching,
})
