import { buildPath } from '@rentspree/path'
import { apiInstance } from 'utils/api-interceptor'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  ALLOW_GENERATE_REPORT_INCOME_VERIFICATION_STATUS,
  GENERATE_INCOME_VERIFICATION_REPORT,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION,
  GET_INCOME_VERIFICATION_REPORT,
  INCOME_VERIFICATION_REST_API_PATH,
} from './constants'
import {
  generateIncomeVerificationReportApiState,
  getIncomeVerificationByRentalSubmissionApiState,
  getIncomeVerificationReportApiState,
} from './actions'

export const generateIncomeVerificationReportApi = async rentalSubmissionId => {
  const generateReportResult = await apiInstance.post(
    buildPath(INCOME_VERIFICATION_REST_API_PATH.GENERATE_REPORT, { rentalSubmissionId }),
  )
  return generateReportResult
}

export const getIncomeVerificationByRentalSubmissionApi = async rentalSubmissionId => {
  const incomeVerification = await apiInstance.get(
    buildPath(INCOME_VERIFICATION_REST_API_PATH.GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION, {
      rentalSubmissionId,
    }),
  )
  return incomeVerification
}

export const getIncomeVerificationReportApi = async rentalSubmissionId => {
  const report = await apiInstance.get(
    buildPath(INCOME_VERIFICATION_REST_API_PATH.GET_INCOME_VERIFICATION_REPORT, {
      rentalSubmissionId,
    }),
  )
  return report
}

export function* generateIncomeVerificationReportSaga({ payload }) {
  let incomeVerification
  try {
    const { rentalSubmissionId } = payload
    yield put(getIncomeVerificationByRentalSubmissionApiState.request())
    incomeVerification = yield call(getIncomeVerificationByRentalSubmissionApi, rentalSubmissionId)
    yield put(getIncomeVerificationByRentalSubmissionApiState.success(incomeVerification))
    if (ALLOW_GENERATE_REPORT_INCOME_VERIFICATION_STATUS.includes(incomeVerification.status)) {
      yield put(generateIncomeVerificationReportApiState.request())
      const gernerateIncomeVerificationReportResult = yield call(
        generateIncomeVerificationReportApi,
        payload.rentalSubmissionId,
      )
      yield put(
        generateIncomeVerificationReportApiState.success(gernerateIncomeVerificationReportResult),
      )
    }
  } catch (error) {
    console.error('An error occurred during Income verification Report Generation.', error.message)
    if (!incomeVerification) yield put(getIncomeVerificationByRentalSubmissionApiState.failure())
    yield put(generateIncomeVerificationReportApiState.failure())
  }
}

export function* getIncomeVerificationByRentalSubmissionSaga({ payload }) {
  const { rentalSubmissionId } = payload
  try {
    yield put(getIncomeVerificationByRentalSubmissionApiState.request())
    const incomeVerification = yield call(
      getIncomeVerificationByRentalSubmissionApi,
      rentalSubmissionId,
    )
    yield put(getIncomeVerificationByRentalSubmissionApiState.success(incomeVerification))
  } catch (error) {
    console.error('An error occurred during Income Verification retrieval.', error.message)
    yield put(getIncomeVerificationByRentalSubmissionApiState.failure(error.status))
  }
}

export function* getIncomeVerificationReportSaga({ payload }) {
  try {
    const { rentalSubmissionId } = payload
    yield put(getIncomeVerificationReportApiState.request())
    const reportData = yield call(getIncomeVerificationReportApi, rentalSubmissionId)
    yield put(getIncomeVerificationReportApiState.success(reportData))
  } catch (error) {
    console.error('An error occurred during Income Verification Report retrieval.', error.message)
    yield put(getIncomeVerificationReportApiState.failure(error.status))
  }
}

export function* watchGetIncomeVerificationReport() {
  yield takeLatest(GET_INCOME_VERIFICATION_REPORT, getIncomeVerificationReportSaga)
}

export function* watchGenerateIncomeVerificationReport() {
  yield takeLatest(GENERATE_INCOME_VERIFICATION_REPORT, generateIncomeVerificationReportSaga)
}

export function* watchGetIncomeVerificationByRentalSubmission() {
  yield takeLatest(
    GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION,
    getIncomeVerificationByRentalSubmissionSaga,
  )
}

export default function* rootSaga() {
  yield all([
    watchGenerateIncomeVerificationReport(),
    watchGetIncomeVerificationByRentalSubmission(),
    watchGetIncomeVerificationReport(),
  ])
}
