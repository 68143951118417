import generateApiCall from 'utils/generate-api-call'
import {
 FETCH_LATEST_RENTAL_PAYMENT,
 FETCH_LATEST_RENTAL_PAYMENT_FAILED,
 FETCH_LATEST_RENTAL_PAYMENT_REQUEST,
 FETCH_LATEST_RENTAL_PAYMENT_SUCCESS
} from './constants'

export const fetchLatestRentalPaymentAPI = generateApiCall([
    FETCH_LATEST_RENTAL_PAYMENT_REQUEST,
    FETCH_LATEST_RENTAL_PAYMENT_SUCCESS,
    FETCH_LATEST_RENTAL_PAYMENT_FAILED
])

export const fetchLatestRentalPayment = payload => ({
  type: FETCH_LATEST_RENTAL_PAYMENT,
  payload,
})
