import React from 'react'
import Styled from 'styled-components'
import { RequestContainer } from 'legacy/components/request/request-main-style'
import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { ButtonSetLeaseAgreement as ButtonSet } from 'components/buttons/button-set'
import { ScreeningRequestForm } from 'components/organisms/screening-request-form'
import LegalRestrictionText from '../molecules/legal-restriction-text'
import RequestStepOptions from '../organisms/request-step-options'

const RequestStepOptionsWrapper = Styled(RequestContainer)`
  font-family: 'Source Sans Pro';
`

const TenantScreeningRequestStepOptionTemplateV2 = props => {
  const {
    userPlan,
    property,
    totalPrice,
    requestOptions,
    screeningPlan,
    priceSchema,
    userExperience,
    handleSaveAndNext,
    handleClickPrevBtn,
    handleSelectPayer,
    handleSelectApplication,
    handleSelectCreditReportItems,
    bundlePrice,
    selectRequestStepControl,
    handleSelectIncomeVerification,
    handleViewCustomDocument,
  } = props
  const { payerType, applicationType, isSubmitting } = requestOptions
  const hasRequiredRequestOptions = payerType && applicationType

  const shouldShowLegalRestriction = selectRequestStepControl?.bundleBox?.isEnabled || true
  return (
    <RequestStepOptionsWrapper data-testid="request-step-option-template-v2.2">
      <TitleWithSubtitle
        style={{ backgroundColor: 'red' }}
        title="Select Application Options"
        subtitle={property.fullNameAddress}
        mb="30"
      />
      <RequestStepOptions
        userPlan={userPlan}
        requestOptions={requestOptions}
        screeningPlan={screeningPlan}
        priceSchema={priceSchema}
        userExperience={userExperience}
        handleSelectPayer={handleSelectPayer}
        handleSelectApplication={handleSelectApplication}
        handleSelectCreditReportItems={handleSelectCreditReportItems}
        handleSelectIncomeVerification={handleSelectIncomeVerification}
        bundlePrice={bundlePrice}
        selectRequestStepControl={selectRequestStepControl}
        handleViewCustomDocument={handleViewCustomDocument}
      />
      {shouldShowLegalRestriction && <LegalRestrictionText />}
      <ScreeningRequestForm totalPrice={totalPrice} isShowPrice requestOptions={requestOptions} />
      <ButtonSet
        hasIconNextBtn
        isShowPrev
        withSystemLayout
        onBorderDesktop
        onBorder
        prevText="Back"
        continueText="Next"
        hideBackButton
        onClickPrevBtn={handleClickPrevBtn}
        onSaveAndNext={handleSaveAndNext}
        isLoading={isSubmitting}
        disabledNextButton={!hasRequiredRequestOptions}
        margin="15px 0 40px 0"
        dataTestKey="screening-options-page"
      />
    </RequestStepOptionsWrapper>
  )
}
export default TenantScreeningRequestStepOptionTemplateV2
