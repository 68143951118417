import React from "react"
import styled from "styled-components"
import checkSquare from "../../../images/icons/alacarte/checkbox-checked.png"
import uncheckSquare from "../../../images/icons/alacarte/checkbox-unchecked.png"

const Checkbox = styled.img`
  width: auto;
  height: 26px;
  margin-right: 15px;
`

const styledCheckbox = ({ checked, ...rest }) => (
  <Checkbox src={checked ? checkSquare : uncheckSquare} {...rest} />
)
export default styledCheckbox
