import { Button } from '@rentspree/component-v2'
import { Icon } from '@rentspree/component-v2/dist/icon'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { breakpoints, COLOR } from 'styles/settings'

import { TAG_INPUT_TYPE } from './constants'

export const InputContainer = styled.div`
  max-width: 100%;
  height: ${props => props.height || '24px'};
  max-height: 40px;
  width: ${({ width }) => width || '150px'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ margin }) => margin && `margin: ${margin};`} ${({ mMargin }) =>
    mMargin && breakpoints.mobileContact(`margin: ${mMargin}`)}
  padding: 4.5px 20px;
  border-radius: ${props => (props.inputType === TAG_INPUT_TYPE.ROUND ? '100px' : '0px')};
  border: 1px solid ${props => (props.isFocus ? COLOR.newDeepBlue : COLOR.newGrey)};
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${COLOR.white};
`
export const Input = styled.input`
  width: 100%;
  border: none;
  color: ${COLOR.textBlack};
  background: transparent;
  ::placeholder {
    color: ${COLOR.placeholderGrey};
  }
`

export const AddButton = styled(Button)`
  max-width: 100%;
  width: 90px;
  max-height: 24px;
  display: inline-flex;
  ${({ margin }) => margin && `margin: ${margin};`} ${({ mMargin }) =>
    mMargin && breakpoints.mobileContact(`margin: ${mMargin}`)}
  padding: 4px 0;
  border-radius: 100px;
  ::hover {
    transition: 0.3s;
  }
  ${({ disabled }) =>
    disabled &&
    `
  color: ${COLOR.white} !important;
  background: ${COLOR.newGrey} !important;
  `};
`

export const LoadingContainer = styled(AddButton)`
  cursor: default;
  &:hover {
    background-color: ${COLOR.white};
  }
`

const TagInput = ({
  margin,
  mMargin,
  isLoading,
  isShowInput,
  onFocusInput,
  onChangeValue,
  onEnter,
  height,
  width,
  inputType,
  onFocus,
  disabledButton,
}) => {
  const inputEl = React.useRef(null)
  const [isFocus, setIsFocus] = React.useState(false)

  React.useEffect(() => {
    if (isShowInput && inputType === TAG_INPUT_TYPE.ROUND) {
      inputEl.current.focus()
    }
  }, [isShowInput])

  const handleKeyDown = event => {
    if (event.target.value.length >= 100 && event.key !== 'Backspace') {
      event.preventDefault()
    }
    if (event.key === 'Enter') {
      onEnter()
      event.preventDefault()
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer google margin={margin} mMargin={mMargin}>
        <Icon name="loading" color={COLOR.textBlack} size="md" spin />
      </LoadingContainer>
    )
  }

  if (isShowInput) {
    return (
      <InputContainer
        // TODO: change to prop width
        id="tag-input-container"
        margin={margin}
        mMargin={mMargin}
        height={height}
        width={width}
        isFocus={isFocus}
        inputType={inputType}
      >
        <Input
          ref={inputEl}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            if (typeof onFocus === 'function') {
              onFocus()
            }
            setIsFocus(true)
          }}
          onBlur={() => setIsFocus(false)}
          onClick={event => event.stopPropagation()}
          onChange={event => onChangeValue(event.target.value)}
          placeholder="Add new tag"
        />
      </InputContainer>
    )
  }

  return (
    <AddButton
      // TODO: change to prop width
      id="add-tag-button"
      google
      margin={margin}
      mMargin={mMargin}
      onClick={onFocusInput}
      disabled={disabledButton}
    >
      + Add Tag
    </AddButton>
  )
}

TagInput.defaultProps = {
  disabledButton: false,
}

TagInput.propTypes = {
  margin: PropTypes.string,
  mMargin: PropTypes.string,
  isLoading: PropTypes.bool,
  onChangeValue: PropTypes.func,
  onEnter: PropTypes.func,
  disabledButton: PropTypes.bool,
}

export default TagInput
