import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const HideContainer = styled.div`
  height: 15px;
  display: ${props => props?.minimizePage && 'none'};
  cursor: pointer;
`

const HideSymbol = styled.hr`
  border: 1px solid ${COLOR.placeholderGrey};
  border-radius: 10px;
  width: 16px;
  margin-top: 5px;
`
const HideIcon = ({ onClick, minimizePage }) => (
  <HideContainer onClick={onClick} minimizePage={minimizePage}>
    <HideSymbol />
  </HideContainer>
)

export default HideIcon

HideIcon.defaultProps = {
  minimizePage: false,
}

HideIcon.propTypes = {
  minimizePage: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
