import { QueryErrorResetBoundary } from '@tanstack/react-query'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { CenterLoading } from 'v3/components/molecules/center-loading/center-loading'
import { ErrorContent } from 'v3/components/molecules/error-content/error-content'

/**
 * SuspendWithErrorBoundary is the Suspense components with the react-query QueryErrorResetBoundary
 *
 * @param {object} props
 * @param {ReactNode} props.children the components with to be rendered inside SuspendWithErrorBoundary
 * @param {ReactNode} props.ErrorFallback the custom error fallback components
 * @param {import('v3/components/molecules/center-loading/center-loading').CenterLoadingProps} props.loadingProps the props of CenterLoading
 */
export const SuspendWithErrorBoundary = ({ children, ErrorFallback, loadingProps }) => (
  <Suspense fallback={<CenterLoading color="black" {...loadingProps} />}>
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) =>
            ErrorFallback ? (
              <ErrorFallback resetErrorBoundary={resetErrorBoundary} />
            ) : (
              <ErrorContent onClickCTA={resetErrorBoundary} margin={8} mMargin={24} />
            )
          }
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  </Suspense>
)
