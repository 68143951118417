import generateApiCall from 'utils/generate-api-call'

import {
  CREATE_ASSOCIATION_CONTACT_CALL,
  CREATE_ASSOCIATION_CONTACT_REQUEST,
  CREATE_ASSOCIATION_CONTACT_SUCCESS,
  CREATE_ASSOCIATION_CONTACT_FAILURE,
  EDIT_ASSOCIATION_CONTACT_CALL,
  EDIT_ASSOCIATION_CONTACT_REQUEST,
  EDIT_ASSOCIATION_CONTACT_FAILURE,
  EDIT_ASSOCIATION_CONTACT_SUCCESS,
  GET_ASSOCIATION_CONTACT_CALL,
  GET_ASSOCIATION_CONTACT_FAILURE,
  GET_ASSOCIATION_CONTACT_REQUEST,
  GET_ASSOCIATION_CONTACT_SUCCESS,
} from './constants'

export const createAssociationContact = contact => ({
  type: CREATE_ASSOCIATION_CONTACT_CALL,
  payload: {
    contact,
  },
})

export const editAssociationContact = contact => ({
  type: EDIT_ASSOCIATION_CONTACT_CALL,
  payload: {
    contact,
  },
})

export const getAssociationContact = contact => ({
  type: GET_ASSOCIATION_CONTACT_CALL,
  payload: {
    contact,
  },
})

export const createAssociationContactApiState = generateApiCall([
  CREATE_ASSOCIATION_CONTACT_REQUEST,
  CREATE_ASSOCIATION_CONTACT_SUCCESS,
  CREATE_ASSOCIATION_CONTACT_FAILURE,
])

export const editAssociationContactApiState = generateApiCall([
  EDIT_ASSOCIATION_CONTACT_REQUEST,
  EDIT_ASSOCIATION_CONTACT_SUCCESS,
  EDIT_ASSOCIATION_CONTACT_FAILURE,
])

export const getAssociationContactApiState = generateApiCall([
  GET_ASSOCIATION_CONTACT_REQUEST,
  GET_ASSOCIATION_CONTACT_SUCCESS,
  GET_ASSOCIATION_CONTACT_FAILURE,
])
