import { takeLatest, put, call } from 'redux-saga/effects'

import {
  FETCH_CREATE_PROPERTY,
  fetchCreatePropertyAPI,
} from 'v3/containers/create-property-form/actions'
import { fetchCreatePropertyInstance } from 'v3/containers/create-property-form/api-instances'

export function* fetchCreatePropertySaga({ payload }) {
  yield put(fetchCreatePropertyAPI.request())
  try {
    const response = yield call(fetchCreatePropertyInstance, payload)
    yield put(fetchCreatePropertyAPI.success({ createdProperty: response }))
  } catch (error) {
    yield put(fetchCreatePropertyAPI.failure(error))
  }
}

export function* watchCreateProperty() {
  yield takeLatest(FETCH_CREATE_PROPERTY, fetchCreatePropertySaga)
}
