import React from "react"
import * as Styled from "./tabs-style"
import { TextDiv } from "../typography"

export const TabWrapper = ({ children, ...props }) => (
  <Styled.TabWrapper {...props}>{children}</Styled.TabWrapper>
)

export const Tabs = ({ children, ...props }) => (
  <Styled.Tabs {...props}>
    <Styled.TabList>{children}</Styled.TabList>
  </Styled.Tabs>
)

export const TabItem = ({ children, ...props }) => (
  <Styled.TabItem {...props}>{children}</Styled.TabItem>
)

export const PricingTabWrapper = ({ children, ...props }) => (
  <Styled.pricingTabWrapper {...props}>{children}</Styled.pricingTabWrapper>
)

export const MPricingHeader = ({ title, subtitle, ...props }) => (
  <Styled.mPricingHeader {...props}>
    <TextDiv align="center" strong color="textBlack">
      {title}
    </TextDiv>
    <TextDiv align="center" light color="titleGrey">
      {subtitle}
    </TextDiv>
  </Styled.mPricingHeader>
)
