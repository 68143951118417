import { TextInput, TextArea } from '@rentspree/component-v2'
import { Icon } from '@rentspree/component-v2/dist/icon'
import get from 'lodash/get'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'

const Wrapper = styled.div`
  padding-right: 20px !important;
`

export const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const TextInputWrapper = styled.div`
  flex: 1;
`

export const NoteTextInput = styled(TextInput)`
  padding-right: 35px;
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 45px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const NoteButton = styled.button`
  padding: 0px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  border: none;
  background: transparent;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  color: ${COLOR.activeBlue};
`

const NoteTextWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  color: ${COLOR.textBlack};
`

const INPUT_MAX_LENGTH = 250

const NoteBoxInput = ({
  id,
  type,
  name,
  value,
  docType,
  isSelectedCheckBox,
  isMobile = false,
  isShowCloseButton = true,
  placeholder,
  onClose,
  onClickButton,
  onInputChange,
}) => {
  const [isInputMode, setIsInputMode] = React.useState(false)
  const [isInputFocus, setIsInputFocus] = React.useState(false)

  useEffect(() => {
    if (!isSelectedCheckBox && isInputMode) {
      handleOnClose()
    }

    if (isSelectedCheckBox && value) {
      setIsInputMode(true)
    }
  }, [isSelectedCheckBox])

  const handleClickNote = (val, event) => {
    onClickButton(val, event)
    setIsInputMode(isInput => !isInput)
  }

  const handleOnInputFocus = val => {
    setIsInputFocus(val)
  }

  const handleOnInputChange = event => {
    const targetValue = get(event, 'target.value')
    onInputChange(docType, targetValue)
  }

  const handleOnClose = () => {
    onClose(docType)
    setIsInputMode(false)
  }

  const TextInputProps = {
    id,
    autoFocus: true,
    type,
    name,
    value,
    placeholder,
    maxLength: INPUT_MAX_LENGTH,
    onChange: handleOnInputChange,
    onFocus: () => handleOnInputFocus(true),
    onBlur: () => handleOnInputFocus(false),
  }

  return (
    <Wrapper>
      {isInputMode ? (
        <InputBoxWrapper>
          <TextInputWrapper>
            {isMobile ? <TextArea {...TextInputProps} /> : <NoteTextInput {...TextInputProps} />}
          </TextInputWrapper>
          {isShowCloseButton && (
            <IconWrapper onClick={handleOnClose}>
              <Icon
                name="close"
                size="sm"
                color={isInputFocus ? COLOR.activeBlue : COLOR.placeholderGrey}
              />
            </IconWrapper>
          )}
        </InputBoxWrapper>
      ) : (
        <InstructionNote value={value} onClick={handleClickNote} />
      )}
    </Wrapper>
  )
}

export const InstructionNote = ({ value, onClick }) => {
  if (value) {
    return <NoteTextWrapper>{value}</NoteTextWrapper>
  }
  return <NoteButton onClick={onClick}>Add Instructions</NoteButton>
}

export default NoteBoxInput
