import generateApiCall from 'utils/generate-api-call'

import {
  GET_AVAILABLE_PAYOUT,
  GET_AVAILABLE_PAYOUT_FAILED,
  GET_AVAILABLE_PAYOUT_REQUESTED,
  GET_AVAILABLE_PAYOUT_SUCCEEDED,
} from './constants'

export const getAvailablePayout = payload => ({
  type: GET_AVAILABLE_PAYOUT,
  payload,
})

export const getAvailablePayoutAPI = generateApiCall([
  GET_AVAILABLE_PAYOUT_REQUESTED,
  GET_AVAILABLE_PAYOUT_SUCCEEDED,
  GET_AVAILABLE_PAYOUT_FAILED,
])
