import React from "react"
import styled from "styled-components"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import { COLOR } from "styles/settings"

export const FlexContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  display: flex;
`

const HeaderContainer = styled.div`
  font-family: Source Sans Pro;
  margin: ${({ margin }) => (margin ? `${margin};` : "0 0 20px;")};
  p {
    font-weight: 600;
  }
`

const WidthContainer = styled.div`
  width: 70px;
  @media (max-width: 991px) {
    width: 60px;
    p {
      width: 60px;
    }
  }
`

const EmailHeader = ({ to, subject, margin }) => (
  <HeaderContainer margin={margin}>
    <FlexContainer>
      <WidthContainer>
        <B16 margin="0" color={COLOR.lightTextGrey}>
          To
        </B16>
      </WidthContainer>
      <B16 width="100%" margin="0">
        {to}
      </B16>
    </FlexContainer>
    <FlexContainer>
      <WidthContainer>
        <B16 margin="0" color={COLOR.lightTextGrey}>
          Subject
        </B16>
      </WidthContainer>
      <B16 width="100%" margin="0">
        {subject}
      </B16>
    </FlexContainer>
  </HeaderContainer>
)

export default EmailHeader
