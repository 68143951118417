import React from 'react'
import { oneOf } from 'prop-types'
import styled from 'styled-components'
import { CenterContent } from 'components/layout/main'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import ContainedButton from '@rentspree/component-2023.components.atoms.button'
import Button from '@rentspree/component-v2/dist/button'
import { ButtonIcon } from '@rentspree/component-v2/dist/button/button-icon'

export const StyledCenterContent = styled(CenterContent)`
  background: ${props => props.background || 'white'};
  font-family: Source Sans Pro;
  @media only screen and (max-width: 991px) {
    ${props =>
      !props.noHeightCal && `height: ${props.heightCal || 'calc(100vh - 105px - 60px - 10px)'};`}
  }
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  #landing-img {
    ${props => props.imgWidth && `width: ${props.imgWidth}`};
    ${props => props.imgHeight && `height: ${props.imgHeight}`};
  }
  @media only screen and (max-width: 991px) {
    #landing-img {
      display: ${props => props.imgMobileDisplay || 'none'};
    }
    #landing-title {
      margin-top: ${props => (props.imgMobileDisplay ? '30px' : 'none')};
    }
  }
`

const Title = styled.div`
  max-width: ${props => props.maxWidth || 350}px;
  font-size: ${props => props.fontSize || 28}px;
  font-weight: ${props => props.fontWeight || 600};
  font-style: normal;
  font-stretch: normal;
  line-height: ${props => props.lineHeight || 36}px;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: ${props => props.marginTop || 20}px;
  ${props => (props.fontFamily ? `font-family: ${props.fontFamily};` : undefined)}

  @media (max-width: 991px) {
    font-size: ${props => props.mobileFontSize || 28}px;
    font-weight: ${props => props.mobileFontWeight || 600};
    line-height: ${props => props.mobileLineHeight || 36}px;
  }
`

const Description = styled.div`
  max-width: ${props => props.maxWidth || 350}px;
  font-size: ${props => props.fontSize || 14}px;
  font-weight: ${props => props.fontWeight || 'normal'};
  font-style: normal;
  font-stretch: normal;
  ${props => (props.fontFamily ? `font-family: ${props.fontFamily};` : undefined)}
  line-height: ${props => props.lineHeight || 20}px;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: ${props => props.marginTop || 10}px;
  margin-bottom:  ${props => props.marginBottom || 30}px;

  @media (max-width: 991px) {
    font-size: ${props => props.mobileFontSize || 14}px;
    font-weight: ${props => props.mobileFontWeight || 'normal'};
    line-height: ${props => props.mobileLineHeight || 20}px;
  }
`

const BUTTONS = {
  gradient: BlueGradientButton,
  google: Button,
}

export const Landing = ({
  title,
  description,
  description2,
  buttonName,
  buttonID,
  buttonWidth,
  buttonType,
  buttonStyle,
  isContainedButton,
  buttonMobileFullWidth,
  imgSrc,
  onClick,
  propsWrapper,
  withBreadcrumb,
  withFilter,
  noHeightCal,
  heightCal,
  btnIcon,
  btnIconSize,
  background,
  titleMaxWidth,
  titleFontSize,
  titleMarginTop,
  titleFontWeight,
  titleLineHeight,
  titleFontFamily,
  titleMobileFontSize,
  titleMobileFontWeight,
  titleMobileLineHeight,
  descMaxWidth,
  descMarginTop,
  descMarginBottom,
  descFontFamily,
  descFontSize,
  descFontWeight,
  descLineHeight,
  descMobileFontSize,
  descMobileFontWeight,
  descMobileLineHeight,
  isButtonLoading,
  imgMobileDisplay,
  ...props
}) => {
  const ActionButton = BUTTONS[buttonType]
  return (
    <StyledCenterContent
      id="landing"
      noHeightCal={noHeightCal}
      withBreadcrumb={withBreadcrumb}
      withFilter={withFilter}
      background={background}
      heightCal={heightCal}
      {...propsWrapper}
    >
      <StyledDiv id="landing-div" imgMobileDisplay={imgMobileDisplay} {...props}>
        {imgSrc && <img src={imgSrc} alt="Landing IMG" id="landing-img" />}
        <Title
          id="landing-title"
          maxWidth={titleMaxWidth}
          marginTop={titleMarginTop}
          fontSize={titleFontSize}
          fontWeight={titleFontWeight}
          lineHeight={titleLineHeight}
          fontFamily={titleFontFamily}
          mobileFontSize={titleMobileFontSize}
          mobileFontWeight={titleMobileFontWeight}
          mobileLineHeight={titleMobileLineHeight}
        >
          {title}
        </Title>
        <Description
          id="landing-description"
          maxWidth={descMaxWidth}
          fontFamily={descFontFamily}
          fontSize={descFontSize}
          fontWeight={descFontWeight}
          lineHeight={descLineHeight}
          mobileFontSize={descMobileFontSize}
          mobileFontWeight={descMobileFontWeight}
          mobileLineHeight={descMobileLineHeight}
          marginTop={descMarginTop}
          marginBottom={descMarginBottom}
        >
          {description}
          {description2 && (
            <>
              <br /><br />
              {description2}
            </>
          )}
        </Description>
        {buttonName && !isContainedButton && (
          <ActionButton
            google={buttonType === 'google'}
            style={buttonStyle}
            id={buttonID || 'landing-button'}
            data-testid={buttonID || 'landing-button'}
            small
            semiBold
            size="16px"
            minWidth={buttonWidth || '250'}
            type="submit"
            onClick={onClick}
            loading={isButtonLoading || undefined}
          >
            {btnIcon && <ButtonIcon mr className={btnIcon} size={btnIconSize || '16px'} />}
            {buttonName}
          </ActionButton>
        )}
         {buttonName && isContainedButton && (
            <ContainedButton
              type="submit"
              variant={buttonType || "contained"}
              size="medium"
              id={buttonID || 'landing-button'}
              data-testid={buttonID || 'landing-button'}
              minWidth={buttonWidth || '250'}
              onClick={onClick}
              disabled={isButtonLoading || undefined}
            >
              {buttonName}
            </ContainedButton>
        )}
        {props.children}
      </StyledDiv>
    </StyledCenterContent>
  )
}

Landing.propTypes = {
  buttonType: oneOf(['gradient', 'google', 'contained', 'outlined']),
}

Landing.defaultProps = {
  buttonType: 'gradient',
}
