import { FormControl, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { string, object } from 'yup'

import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

export const ClientDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, SUBTITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_LABEL } =
    RECIPIENT_DETAILS.CLIENT_DETAILS

  const validationSchema = object({
    firstName: string().min(1).required(),
    lastName: string().min(1).required(),
    email: string().email().required(),
  })

  const formik = useFormik({
    initialValues: {
      firstName: renterInfo?.firstName,
      lastName: renterInfo?.lastName,
      email: renterInfo?.email,
    },
    validateOnMount: true,

    validationSchema,
  })

  useEffect(() => {
    const { firstName, lastName, email } = formik.values
    setRenterInfoPiece({ firstName, lastName, email })
  }, [formik.values.firstName, formik.values.lastName, formik.values.email])

  return (
    <>
      <TitleText>{TITLE}</TitleText>
      <Typography fontSize="16px" lineHeight="20px" fontWeight="400" fontFamily="Inter">
        {SUBTITLE}
      </Typography>
      <FormControl fullWidth required>
        <StyledTextField
          id="firstName"
          label={FIRST_NAME_LABEL}
          variant="outlined"
          size="normal"
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          {...formik.getFieldProps('firstName')}
        />
        <StyledTextField
          id="lastName"
          label={LAST_NAME_LABEL}
          variant="outlined"
          size="normal"
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          {...formik.getFieldProps('lastName')}
        />
        <StyledTextField
          id="email"
          label={EMAIL_LABEL}
          variant="outlined"
          size="normal"
          error={formik.touched.email && Boolean(formik.errors.email)}
          {...formik.getFieldProps('email')}
        />
      </FormControl>
      <DrawerFooter
        buttonLayout={buttonLayoutChoices.NEXT_BACK}
        textOverrides={{ back: 'Cancel', next: 'Save' }}
        nextEnabled={formik.isValid}
      />
    </>
  )
}
