/* eslint-disable camelcase */
import React from 'react'
import Tooltip from '@rentspree/component-2023.components.atoms.tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { SortableHeader } from 'components/atoms/sortable-header'
import { CenterContent, Loading } from 'components/layout/main'
import { NoData } from 'components/property-list/no-property'
import { Tr, Th, Td } from 'components/table/table-style'
import { APP_STATUS } from 'containers/application/const'
import { CLICK_FROM } from 'containers/accept-deny/constants'
import * as Styled from 'legacy/components/table/application-table-style'
import { isRead } from 'legacy/helpers/credit-report'
import { getReportVoiceSpeech } from 'utils/screen-reader/voice-speech-generator'
import {
  generateApplicationProgress,
  generateSubmissionDate,
} from 'components/tenant-screening-table/helpers'
import ActionDropdown from 'components/tenant-screening-table/action-dropdown'
import ColumnName from 'components/tenant-screening-table/column-name'
import { OnboardingLandlordTSEmptyState } from 'v3/components/organisms/onboarding-ll-ts-empty-state'
import { useGetMyUserInvitation } from 'v3/hooks/use-get-my-invitations'
import isEmpty from 'lodash/isEmpty'
import { TABLE_HEADER } from './constants'
import { PropertyApplicationZeroState } from './landing'

const ApplicationTable = ({
  data,
  isLoading,
  sort,
  currentSort,
  isAllowAcceptDeny,
  isAllowCarLease,
  eventSource,
  onClickRentalSubmissionRow,
  onOpenAcceptDenyModal,
  onClickSetupLease,
  onClickSetupRentPayment,
  property: { _id: propertyId },
  hasQueryOption,
  history,
  disabledTenantScreening,
}) => {
  const {
    data: userInvitation,
    isFetched: isUserInvitationFetched,
    isLoading: isUserInvitationLoading,
  } = useGetMyUserInvitation()
  const isInvitedUser = !isEmpty(userInvitation?.result)

  if (isLoading || isUserInvitationLoading) {
    return (
      <CenterContent overlay withBreadcrumb withFilter>
        <Loading />
      </CenterContent>
    )
  }

  if (!isLoading && data.length === 0 && isUserInvitationFetched) {
    if (hasQueryOption) {
      return (
        <CenterContent withFilter withBreadcrumb>
          <NoData text="No applications match your search criteria." />
        </CenterContent>
      )
    }
    return (
      // landing for listing menu
      isInvitedUser ? (
        <OnboardingLandlordTSEmptyState />
      ) : (
        <PropertyApplicationZeroState
          history={history}
          propertyId={propertyId}
          disabledTenantScreening={disabledTenantScreening}
        />
      )
    )
  }

  return (
    <Styled.ApplicationTable
      id="applicationTable"
      className="application-table"
      isHideDesktop={data.length === 0}
    >
      <thead>
        <Tr className="hidden-xs hidden-sm" id="applicationTableHeader">
          <Th />
          {TABLE_HEADER.map(header => (
            <Th key={header.value} className={header.class}>
              <SortableHeader
                id={`sort-${header.value}-btn`}
                text={header.text}
                onClick={() => sort(header.value)}
                sorting={currentSort[header.value]}
              >
                {header.value === 'submitted_at' && (
                  <Tooltip
                    title="To keep information up to date, TransUnion reports expire after 30 days."
                    placement="top"
                  >
                    <ErrorOutlineIcon sx={{ marginLeft: '7px', fontSize: 'large' }} />
                  </Tooltip>
                )}
              </SortableHeader>
            </Th>
          ))}
        </Tr>
      </thead>
      <tbody>
        {data?.map((rental, index) => {
          const {
            _id: appId,
            status,
            submitted_at: submittedAt,
            email,
            firstName,
            lastName,
            agentType,
            credit_report: creditReport,
            screeningResult,
          } = rental
          const isReportUnread =
            [APP_STATUS.submitted.value, APP_STATUS.property_required.value].includes(status) &&
            !isRead(rental)
          const speechContent = getReportVoiceSpeech({
            firstName,
            lastName,
            email,
            status,
          })
          const applicationProgress = generateApplicationProgress(status, screeningResult?.result)
          const submissionDate = generateSubmissionDate(
            submittedAt,
            creditReport?.generated_at,
            creditReport?.verified_idma_at,
          )

          return (
            <Tr
              id={`applicationTableRow${index}`}
              data-testid={`application-table-row-${appId}`}
              key={appId}
              greyRow={!isReportUnread}
              tabIndex="0"
              onKeyPress={keyInput => {
                if (keyInput.key === 'Enter') {
                  onClickRentalSubmissionRow(rental)
                }
              }}
              aria-label={speechContent}
              textBold={isReportUnread}
              onClick={() => onClickRentalSubmissionRow(rental)}
              disabled={false}
            >
              <Td />
              <ColumnName
                app={rental}
                applicationProgress={applicationProgress}
                index={index}
                isRead={isRead(rental)}
              />
              <Td className="text-left">{submissionDate}</Td>
              <Td className="text-left">{applicationProgress}</Td>
              <Td>
                <ActionDropdown
                  rentalSubmission={rental}
                  source={CLICK_FROM.TENANT_SCREENING}
                  eventSource={eventSource}
                  isAllowAcceptDeny={isAllowAcceptDeny(agentType)}
                  isAllowCarLease={isAllowCarLease}
                  onOpenAcceptDenyModal={onOpenAcceptDenyModal}
                  onClickSetupLease={onClickSetupLease}
                  onClickSetupRentPayment={onClickSetupRentPayment}
                />
              </Td>
            </Tr>
          )
        })}
      </tbody>
    </Styled.ApplicationTable>
  )
}

export default ApplicationTable
