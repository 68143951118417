import { Icon } from '@rentspree/component-v2/dist/icon'
import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'

import ProgressBar from 'components/contacts/atoms/progress-bar'
import { WidgetTitle, WidgetSubTitle, TextButton } from 'components/contacts/atoms/widget'
import warningIcon from 'images/contacts/sweetalert-warning.svg'
import { COLOR } from 'styles/settings'

const DetailWrapper = styled.div`
  display: flex;
  justify-content: column;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
`

const ErrorIcon = styled.img`
  margin-right: 12px;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
`

const PartiallySuccessWidget = ({ current, percentage, onClose, onViewContact }) => (
  <>
    <HeaderWrapper>
      <CloseIcon icon="close" color={COLOR.textBlack} onClick={onClose} />
    </HeaderWrapper>
    <DetailWrapper>
      <ErrorIcon className="maximize" src={warningIcon} alt="warning-icon" />
      <div>
        <WidgetTitle>Cancelled and imported</WidgetTitle>
        <WidgetSubTitle>
          {`Import cancelled and import ${current} ${pluralize('contact', current)}`}
        </WidgetSubTitle>
      </div>
    </DetailWrapper>
    <BottomWrapper>
      <ProgressBar color={COLOR.green} percent={percentage} />
      <TextButton onClick={onViewContact}>View</TextButton>
    </BottomWrapper>
  </>
)

export default PartiallySuccessWidget
