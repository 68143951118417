import { takeLatest, put, call, all, select, delay } from "redux-saga/effects"
import { buildPath, parse } from "@rentspree/path"
import { push } from "connected-react-router"
import * as Sentry from "@sentry/browser"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { selectLocation } from "containers/agreement/selectors"
import { GENERAL_ERROR } from "constants/error-messages"
import * as PATH from "constants/route"
import {
  openLoadingModal,
  openSuccessModal,
  closeSweetAlertModal,
} from "utils/sweet-alert-2"
import { openSweetAlert } from "utils/sweet-alert-actions"
import { apiInstance } from "utils/api-interceptor"
import { locationReload } from "legacy/utils/window"
import { LISTING_IMPORT_STATUS } from "containers/constants"
import { IMPORT_LISTING_POLLING_TRIALS } from "env"
import { USER_LAST_ACTION } from "constants/user-last-action"
import {
  GET_IMPORTABLE_LISTING_CALLED,
  EVENT_SOURCE_IMPORT_MLS,
  importMLSListingApiState,
  IMPORT_MLS_LISTING_CALLED,
  getImportableListingApiState,
  IMPORT_MLS_LISTING_FAILED,
  AUTO_IMPORT_MLS_LISTING_CALLED,
} from "./constants"
export const importMLSListingAPI = propertyId =>
  apiInstance.post(buildPath(PATH.IMPORT_MLS_LISTING_API, { propertyId }))

export const getImportableListingAPI = propertyId =>
  apiInstance.get(buildPath(PATH.IMPORTABLE_LISTING_API, { propertyId }))

export function* pollListing(propertyId) {
  let response
  for (let trials = 0; trials < IMPORT_LISTING_POLLING_TRIALS; trials += 1) {
    try {
      response = yield call(importMLSListingAPI, propertyId)
    } catch (error) {
      throw error
    }
    switch (response?.listing?.importStatus) {
      case LISTING_IMPORT_STATUS.ERROR:
        throw new Error(
          `Unable to import listing ${propertyId}: Import Status Error`,
        )
      case LISTING_IMPORT_STATUS.IMPORTED:
        return response
      case LISTING_IMPORT_STATUS.LOADING:
      default:
        yield delay(1000)
        break
    }
  }
  throw new Error(
    `Unable to import listing ${propertyId}: Timed Out ${IMPORT_LISTING_POLLING_TRIALS}`,
  )
}

export function* importMLSListing({ payload }) {
  yield put(importMLSListingApiState.request())
  try {
    // TODO: adjust loading to be more realistic
    yield call(openLoadingModal, {})
    const response = yield call(pollListing, payload)
    yield put(importMLSListingApiState.success(response))
    yield call(openSuccessModal, {
      title: "Congratulations!",
      subtitle: "Your property was imported successfully!",
    })
    yield call(closeSweetAlertModal)
    const { search } = yield select(selectLocation)
    yield put(
      push(
        buildPath(
          PATH.CREATE_LISTING_INFORMATION_STEP,
          {
            propertyId: payload,
          },
          { ...parse(search), source: EVENT_SOURCE_IMPORT_MLS },
        ),
        { userLastAction: USER_LAST_ACTION.IMPORT_LISTING },
      ),
    )
  } catch (err) {
    yield put(importMLSListingApiState.failure(err))
    yield call(closeSweetAlertModal)
    yield put(
      openSweetAlert({
        preset: ALERT_PRESET.ERROR,
        option: {
          title: GENERAL_ERROR.UNKNOWN_ERROR.TITLE,
          text: GENERAL_ERROR.UNKNOWN_ERROR.MESSAGE,
          button: "Try Again",
          closeOnClickOutside: false,
          closeOnEsc: false,
        },
        promise: locationReload,
      }),
    )
  }
}

export function* getImportableListing({ payload }) {
  yield put(getImportableListingApiState.request())
  try {
    const response = yield call(getImportableListingAPI, payload)
    yield put(getImportableListingApiState.success(response))
  } catch (err) {
    yield put(getImportableListingApiState.failure(err))
    yield put(
      openSweetAlert({
        preset: ALERT_PRESET.ERROR,
        option: {
          title: GENERAL_ERROR.UNKNOWN_ERROR.TITLE,
          text: GENERAL_ERROR.UNKNOWN_ERROR.MESSAGE,
          button: "Try Again",
          closeOnClickOutside: false,
          closeOnEsc: false,
        },
        promise: locationReload,
      }),
    )
  }
}

export function* autoImportMLSListing({ payload }) {
  yield put(importMLSListingApiState.request())
  try {
    const response = yield call(pollListing, payload)
    yield put(importMLSListingApiState.success(response))
    const { search } = yield select(selectLocation)
    yield put(
      push(
        buildPath(
          PATH.CREATE_LISTING_INFORMATION_STEP,
          {
            propertyId: payload,
          },
          { ...parse(search), source: EVENT_SOURCE_IMPORT_MLS },
        ),
        { userLastAction: USER_LAST_ACTION.IMPORT_LISTING },
      ),
    )
  } catch (err) {
    yield put(importMLSListingApiState.failure(err))
    yield put(
      push(
        buildPath(
          PATH.CREATE_LISTING_INFORMATION_STEP,
          {
            propertyId: payload,
          },
          { source: EVENT_SOURCE_IMPORT_MLS },
        ),
        { userLastAction: USER_LAST_ACTION.IMPORT_LISTING },
      ),
    )
  }
}

export function* onImportListingFailed({ payload }) {
  yield call(Sentry.captureException, payload)
}

export function* watchImportListing() {
  yield takeLatest(IMPORT_MLS_LISTING_CALLED, importMLSListing)
}

export function* watchOnImportListingFailed() {
  yield takeLatest(IMPORT_MLS_LISTING_FAILED, onImportListingFailed)
}

export function* watchGetImportableListing() {
  yield takeLatest(GET_IMPORTABLE_LISTING_CALLED, getImportableListing)
}

export function* watchAutoImportListing() {
  yield takeLatest(AUTO_IMPORT_MLS_LISTING_CALLED, autoImportMLSListing)
}

export default function* rootSaga() {
  yield all([
    watchImportListing(),
    watchGetImportableListing(),
    watchOnImportListingFailed(),
    watchAutoImportListing(),
  ])
}
