import 'v3/global-styles.scss'
import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'

import FailedToFetchPage from 'components/templates/failed-to-fetch-full-page'
import { SuspendWithErrorBoundary } from 'v3/components/templates/suspend-with-error-boundary'

import { ERRORS } from './constants'
import { PATH_HOME_PAGE } from './path'

const PageDashboard = lazy(() => import('v3/containers/page-dashboard'))

/**
 * RouterV3 contains routes for dashboard v3.
 */
const RouterV3 = () => (
  <SuspendWithErrorBoundary
    ErrorFallback={() => (
      <FailedToFetchPage
        noMargin
        title={ERRORS.DASHBOARD.TITLE}
        subTitle={ERRORS.DASHBOARD.MESSAGE}
      />
    )}
    loadingProps={{ height: 'full' }}
  >
    <Switch>
      <Route exact path={PATH_HOME_PAGE} component={PageDashboard} />
    </Switch>
  </SuspendWithErrorBoundary>
)

export default RouterV3
