import React from 'react'

import * as Styled from './main-style'
import loadingImg from '../../images/gifs/ripple.svg'

export const BodyContent = ({ children, ...props }) => (
  <Styled.bodyContent id="bodyContent" {...props}>
    {children}
  </Styled.bodyContent>
)

export const MainContent = ({ children, ...props }) => (
  <Styled.mainContent id="mainContent" {...props}>
    {children}
  </Styled.mainContent>
)

export const WhiteWrapper = ({ children, ...props }) => (
  <Styled.whiteWrapper id="whiteWrapper" {...props}>
    {children}
  </Styled.whiteWrapper>
)

export const CenterContent = ({ children, ...props }) => (
  <Styled.centerContent id="centerContent" {...props}>
    {children}
  </Styled.centerContent>
)

export const FilterRow = ({ children, ...props }) => (
  <Styled.filterRow id="filterRow" {...props}>
    {children}
  </Styled.filterRow>
)

export const SingleAppWrapper = ({ children, ...props }) => (
  <Styled.singleAppWrapper {...props}>{children}</Styled.singleAppWrapper>
)

export const ContainerReport = ({ children, ...props }) => (
  <Styled.containerReport id="containerReport" {...props}>
    {children}
  </Styled.containerReport>
)

export const ReportWrapper = ({ children, ...props }) => (
  <Styled.reportWrapper id="reportWrapper" {...props}>
    {children}
  </Styled.reportWrapper>
)

export const Loading = ({ ...props }) => (
  <Styled.loading {...props}>
    <img src={loadingImg} alt="loading" />
  </Styled.loading>
)

export const Div = ({ children, ...props }) => <Styled.div {...props}>{children}</Styled.div>

export const LoadingWithCenter = () => (
  <CenterContent>
    <Loading />
  </CenterContent>
)
