import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import saga from "./saga"
import {
  selectIsLoading,
  selectPropertyId,
  selectType,
  selectIsOpen,
} from "./selectors"
import {
  archiveProperty,
  unarchiveProperty,
  openArchiveModal,
  clearArchiveModal,
} from "./actions"

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  propertyId: selectPropertyId,
  isOpen: selectIsOpen,
  type: selectType,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      archiveProperty,
      unarchiveProperty,
      openArchiveModal,
      clearArchiveModal,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "ToggleArchivePropertyModal", saga })
