import React from "react"
import styled from "styled-components"
import { Icon } from "@rentspree/component-v2/dist/icon"

import { COLOR } from "styles/settings"

const IconContainer = styled.button`
  position: absolute;
  right: 15px;
  bottom: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 100%;
  border: none;
  background-color: ${COLOR.placeholderGrey};
  transform: rotate(45deg);
  zoom: 75%;
  cursor: pointer;
`

export const CloseIcon = ({ onClick }) => (
  <IconContainer onClick={onClick}>
    <Icon name="add" color={COLOR.white} size="sm" />
  </IconContainer>
)
