export const PARTNER_ONBOARDING_FEATURES = {
  TENANT_SCREENING: {
    spotlightConfig: {
      enabled: true,
      targetSelector: `#DropdownAction0-property-table-email-action`,
    },
  },
  DROPDOWN_BUTTON: {
    spotlightConfig: {
      enabled: true,
      targetSelector: `#DropdownAction0`,
    },
  },
}
