import generateApiCall from 'utils/generate-api-call'

import {
  GET_MULTI_SHARE_STATUS_CALL,
  GET_MULTI_SHARE_STATUS_FAILURE,
  GET_MULTI_SHARE_STATUS_REQUEST,
  GET_MULTI_SHARE_STATUS_SUCCESS,
} from './constants'

export const getMultiShareStatusCall = () => ({
  type: GET_MULTI_SHARE_STATUS_CALL,
})

export const getMultiShareStatusApiState = generateApiCall([
  GET_MULTI_SHARE_STATUS_REQUEST,
  GET_MULTI_SHARE_STATUS_SUCCESS,
  GET_MULTI_SHARE_STATUS_FAILURE,
])
