import generateApiCall from 'utils/generate-api-call'

import {
  GET_PAYMENT_SUMMARY,
  GET_PAYMENT_SUMMARY_REQUESTED,
  GET_PAYMENT_SUMMARY_SUCCEEDED,
  GET_PAYMENT_SUMMARY_FAILED,
} from './constants'

export const getPaymentSummary = payload => ({
  type: GET_PAYMENT_SUMMARY,
  payload,
})

export const getPaymentSummaryAPI = generateApiCall([
  GET_PAYMENT_SUMMARY_REQUESTED,
  GET_PAYMENT_SUMMARY_SUCCEEDED,
  GET_PAYMENT_SUMMARY_FAILED,
])
