import React from 'react'
import ConfirmModal from 'containers/request/step-create-property/confirm-modal'

const ValidAddressConfirmModal = ({
  isOpen,
  onClose,
  handleSubmitProperty,
  handleEditAddress,
  generatedAddress,
}) => (
  <ConfirmModal
    id="confirmPropertyAddress"
    title="Confirm Property Address"
    descText="The address cannot be changed once the property has been created. Please confirm the address is correct."
    confirmTxt="Confirm"
    cancelTxt="Edit Address"
    isOpen={isOpen}
    onClose={onClose}
    handleClickCancelBtn={handleEditAddress}
    handleClickBtn={handleSubmitProperty}
    content={generatedAddress}
  />)

  export default ValidAddressConfirmModal

