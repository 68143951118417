import PropTypes from "prop-types"
import React from "react"
import { B14 } from "@rentspree/component-v2"
import { Icon } from "@rentspree/component-v2/dist/icon"
import styled from "styled-components"
import { COLOR } from "styles/settings"
import Row from "components/atoms/row"
import Col from "components/atoms/col"
const IconWithNameDescStyled = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || "center"};
  ${props => props.spaceBetween && "justify-content: space-between"};
  .text {
    margin: 0px;
    padding-left: 10px;
  }
`
const TextZone = styled.div`
  display: flex;
  flex-flow: column;
  text-align: left;
`
export const IconWithNameDesc = ({
  iconColor,
  icon,
  text,
  textColor,
  descText,
  descColor,
  extraContent,
  spaceBetween,
  alignItemsRow = "center",
  alignItemsIcon = "center",
}) => (
  <IconWithNameDescStyled
    spaceBetween={spaceBetween}
    alignItems={alignItemsRow}>
    <Row alignItems={alignItemsIcon}>
      <Icon icon={icon} color={iconColor} size="2x" />
      <Col>
        <TextZone>
          <B14 className="text" color={textColor}>
            {text}
          </B14>
          <B14 size="12px" className="text" color={descColor}>
            {descText}
          </B14>
        </TextZone>
      </Col>
    </Row>
    {extraContent && <Row>{extraContent}</Row>}
  </IconWithNameDescStyled>
)

IconWithNameDesc.defaultProps = {
  text: "TITLE",
  icon: "menu-listing",
  textColor: COLOR.textBlack,
}

IconWithNameDesc.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  descText: PropTypes.string,
  descColor: PropTypes.string,
}
