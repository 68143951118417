import { TU_CONTACT_KB_URL } from 'env'

export const PARTNER_NAME = {
  CAR: 'car',
  CRMLS: 'crmls',
  RENTALS: 'rentals',
}

export const TU_CONTACT = {
  PHONE: '(833) 458-6338',
  KB_URL: TU_CONTACT_KB_URL || 'https://support.rentspree.com/en/transunion-contact-info-and-hours',
}
