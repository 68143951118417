import * as sentry from '@sentry/browser'
import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'utils/api-interceptor'

/**
 * getCountRentalSubmission get count rental submission.
 * @returns {Promise<{ unread: number, needDecision: number, total: number }>}
 */
export const getCountRentalSubmission = async () => {
  try {
    const result = await apiInstance.get(`/api/v2/agent/submission-participants/count`)
    return result
  } catch (e) {
    sentry.captureException(e)
    throw e
  }
}

/**
 * useGetCountRentalSubmission get count rental submission.
 * @param {import('@tanstack/react-query').UseQueryOptions<ReturnType<typeof getCountRentalSubmission>>} options
 */
export const useGetCountRentalSubmission = (options) => useQuery({
  queryFn: getCountRentalSubmission,
  queryKey: ['getCountRentalSubmission'],
  refetchOnWindowFocus: false,
  networkMode: 'always',
  ...options
})
