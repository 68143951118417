import { put, call, all, takeLatest, select } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstanceWithErrorHandler } from "utils/api-interceptor"
import { DIGIDOC_GET_FILE } from "constants/route"
import { GET_APPLICATION_SUCCESS } from "legacy/constants/action-types"
import { pdfApi } from "./actions"
import { selectIsSharedReport } from "../selectors"

export const getPdfFileAPI = envelopeId =>
  apiInstanceWithErrorHandler.get(buildPath(DIGIDOC_GET_FILE, { envelopeId }), {
    responseType: "arraybuffer",
  })

export function* pdfFileSaga({ application = {} }) {
  const envelopeId = application?.lraEnvelopeId
  if (envelopeId) {
    /**
     * Note: 14-05-2024 As currently we do not allow report sharing on C.A.R. LRA app
     * To prevent unnecessary api call and false alarm on the monitoring
     * So we excluded report sharing case which allow only agent and renter to call api
     */
    const isSharedReport = yield select(selectIsSharedReport)
    if (!isSharedReport) {
      yield put(pdfApi.request())
      try {
        const response = yield call(getPdfFileAPI, envelopeId)
        yield put(pdfApi.success(response))
      } catch (err) {
        yield put(pdfApi.failure())
      }
    }
  }
}

export function* watchGetEnvelopeCall() {
  yield takeLatest(GET_APPLICATION_SUCCESS, pdfFileSaga)
}

export const watchers = [watchGetEnvelopeCall()]

export function* rootSaga() {
  yield all(watchers)
}

export default rootSaga
