import React from 'react'

import { ADDITIONAL_PAYMENTS_LIMIT } from './constants'
import OneTimePaymentForm from './one-time-payment-form'
import RecurringPaymentForm from './recurring-payment-form'

export const QuotationsForm = props => {
  const { values } = props
  const isQuotationLimitExceeded =
    values?.oneTimePayments?.length + values?.recurringPayments?.length >= ADDITIONAL_PAYMENTS_LIMIT

  return (
    <div className="agt-init-payment-form-input-container">
      <OneTimePaymentForm {...props} isQuotationLimitExceeded={isQuotationLimitExceeded} />
      <RecurringPaymentForm {...props} isQuotationLimitExceeded={isQuotationLimitExceeded} />
    </div>
  )
}
