import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { legacyGenerateIncomeVerificationReport as generateIncomeVerificationReport } from 'containers/reports/screening-reports/income-verification/actions'
import {
  withIncomeVerificationSaga,
  withIncomeVerificationReducer,
} from 'containers/reports/screening-reports/income-verification/connect'
import * as CreditReportActions from '../../actions/credit-report.action'
import * as ModalActions from '../../actions/modals.action'
import { updateCurrentReportStatus } from '../../actions/application.action'

export default ComposedComponent =>
  compose(
    connect(
      state => ({
        creditReport: state.creditReport,
        currentApp: state.modals.showOpenCreditReportModal.currentApp,
        creditReportDetail: state.modals.showOpenCreditReportModal.creditReportDetail,
        rentalDetail: state.application?.rentalDetail,
      }),
      dispatch => ({
        actions: bindActionCreators(
          {
            ...CreditReportActions,
            ...ModalActions,
            updateCurrentReportStatus,
            generateIncomeVerificationReport,
          },
          dispatch,
        ),
      }),
    ),
    withIncomeVerificationReducer,
    withIncomeVerificationSaga,
  )(ComposedComponent)
