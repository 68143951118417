import React from "react"
import styled from "styled-components"
import { S24, B16 } from "@rentspree/component-v2"
import { TabWrapper } from "components/layout/tabs"
import VoidEnvelopeForm from "containers/envelope/form/void-envelope"
import { COLOR } from "styles/settings"
import { AlertPanel } from "components/shared/alert-panel"
import { DefaultModal } from "components/modal"

const ModalWrapper = styled(DefaultModal)`
  z-index: 100000;
  #signatureFormTabsWrapper {
    padding: 20px 25px 0px 25px;
  }
  #signatureFormTabs {
    margin: 10px -30px 0px -30px;
  }

  .modal-dialog {
    @media screen and (max-width: 991px) {
      max-width: 100% !important;
    }
  }

  .modal-body {
    padding: 20px 25px 20px 25px !important;

    .col-md-6 {
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
  }
  li {
    padding: 11px 10px;
    margin: 0px;
    width: auto;
    :hover {
      color: ${COLOR.newDeepBlue};
      border-color: ${COLOR.newDeepBlue};
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: Source Sans Pro, sans-serif;
  button {
    align-self: flex-end;
  }
`

export const NavBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0px 0px 0px;
  button {
    align-self: center;
  }
  img {
    height: 88px;
    width: 88px;
    align-self: center;
  }
`

export const VoidModal = props => {
  const { voidStatus, closeError, isError } = props
  const { isShowVoidModal } = voidStatus
  return (
    <ModalWrapper
      header={
        <TabWrapper id="signatureFormTabsWrapper">
          <S24 margin="0px 0px 10px 0px">Void Document</S24>
          {isError && (
            <AlertPanel
              error
              handleClose={closeError}
              padding="10px 15px"
              margin="15px 0 15px 0"
            />
          )}
          <B16 margin="0px" mMargin="0px">
            By voiding this document, recipients can no longer view it or sign
            enclosed documents. Recipients will receive an email notification,
            which includes your reason for voiding the document.
          </B16>
        </TabWrapper>
      }
      closeButton
      dialogMaxWidth="500px"
      show={isShowVoidModal}
      backdrop="static"
      {...props}>
      <VoidEnvelopeForm {...props} />
      <Wrapper />
    </ModalWrapper>
  )
}

export default VoidModal
