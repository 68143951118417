import React from "react"
import { convert } from "../../helper/convert"
import * as Styled from "./info-table-style"

export const InfoList = ({ children, loading }) => (
  <Styled.InfoList className={{ loading }}>{children}</Styled.InfoList>
)

export const InfoItem = ({
  children,
  title,
  text,
  type,
  format,
  align_center: alignCenter,
  isHideLine,
}) => (
  <Styled.InfoItem>
    {!isHideLine && <hr />}
    <Styled.InfoContentWrapper className={{ align_center: alignCenter }}>
      {title && text ? (
        <InfoContent title={title} text={text} type={type} format={format} />
      ) : (
        children
      )}
    </Styled.InfoContentWrapper>
  </Styled.InfoItem>
)

export const InfoContent = ({ title, text, type, format, isHide }) => (
  <Styled.InfoContent>
    {!isHide && (
      <div>
        <p className="info-title">
          <span>{title}</span>
        </p>
        <p className="info-text">
          <span>{convert(text, type, format)}</span>
        </p>
      </div>
    )}
  </Styled.InfoContent>
)

export const InfoHead = ({ title, children, props }) => (
  <Styled.InfoHead {...props}>
    {title && <h5>{title}</h5>}
    {children}
  </Styled.InfoHead>
)
