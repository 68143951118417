import { buildPath } from '@rentspree/path'
import * as sentry from '@sentry/browser'

import { apiInstance } from 'utils/api-interceptor'

export const GET_MY_INCENTIVE_TRACK_RECORD_PATH =
  '/api/v2/rental-payments/draft-payments/agent-initiated/incentive-track-record'
export const GET_MY_INCENTIVE_ELIGIBILITY = '/api/v2/partners/incentives/eligibility'

export const AGENT_INCENTIVE_CAMPAIGN_NAME = 'agent_partner_incentive'

/**
 * getMyIncentiveTrackRecord returns records of incentive invitation history of the user (as an agent)
 *
 * @returns {Promise<{ eligibleCount: number, trackRecords: Array<{ landlordName: string, renterName: string, propertyAddress: string, status: string }}>}>}
 */
export const getMyIncentiveTrackRecord = async campaignName => {
  try {
    const result = await apiInstance.get(
      buildPath(GET_MY_INCENTIVE_TRACK_RECORD_PATH, {}, { campaignName }),
    )
    return result
  } catch (error) {
    sentry.captureException(error)
    return {}
  }
}

/**
 * getMyIncentiveProgramEligibility returns user's eligibility for incentive program
 * @params {string} userId - user's id checking eligible
 * @returns {Promise<{ isEligiblePartner: boolean }>}
 */
export const getMyIncentiveProgramEligibility = async () => {
  try {
    const res = await apiInstance.get(buildPath(GET_MY_INCENTIVE_ELIGIBILITY))
    return res.result
  } catch (error) {
    sentry.captureException(error)
    return {}
  }
}
