/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Styled from 'styled-components'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import Span from '@rentspree/component-v2/dist/typography/span'
import { COLOR } from 'styles/settings'
import { NewBadge, ProBadge } from 'components/badge'
import { useFeatureGate } from '@statsig/react-bindings'
import { CUSTOM_DOCUMENT } from 'utils/feature-flag/constants'

export const Container = Styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${props => !props.isLastItem && `border-bottom: 1px solid ${COLOR.grayLv4};`}
  font-family: inherit;
  ${props =>
    props.isDisabled &&
    `
      cursor: not-allowed;
      span {
        h4 {
          color: ${COLOR.grayLv3};
        }
      }
    `}
  ${props =>
    props.isDisabled ||
    `
        &:hover .check-box-hover {
          background-color: ${props.canHover ? props.hoverColor : 'none'}
        }
      `}
`

export const StyledDiv = Styled.div`
  position: relative;
  padding: 14px 14px 14px 50px;
  display: flex;
  align-items: center;
`

export const Title = Styled(S16)`
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  margin-bottom: 2px;
`

export const Subtitle = Styled(S16)`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  margin: 0;
  strong {
    font-style: italic;
    font-weight: 700;
    color: ${COLOR.newDeepBlue};
  }
`

export const Content = Styled(Span)`
  margin-left: 0;
  margin-right: auto;
`

export const CheckboxHover = Styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  left: ${props => (props.checked ? '8px' : '7px')};
  padding-left: ${props => (props.checked ? '2px' : '0px')};
`

export const PriceContainer = Styled(S16)`
  align-self: center;
`

export const Price = Styled(S16)`
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  margin-bottom: 2px;
  color: ${COLOR.fontBlue};
`

export const CtaContainer = Styled.div`
  margin-left: 42px;
  margin-bottom: 10px;
`

export const TextButton = Styled.button`
  color: ${COLOR.activeBlue};
  background: transparent;
  font-weight: 600;
  border: none;
  &:hover,
  &:focus {
    color: ${COLOR.blueHover};
  }
`

const BooleanCardWithoutBorderWithPrice = ({
  isLastItem,
  title,
  subtitle,
  price,
  checked = false,
  onClick,
  icon: IconComponent,
  alt,
  isShowNewBadge = false,
  isShowProBadge,
  testId = '',
  isHidden = false,
  isDisabled = false,
  canHover = true,
  ctaText,
  handleViewCustomDocument,
}) => {
  const { value: customDocumentGateEnabled } = useFeatureGate(CUSTOM_DOCUMENT)

  if (isHidden) {
    return <></>
  }

  const handleClick = (...params) => {
    if (!isDisabled) onClick(...params)
  }

  const CardCTA = () => {
    if (ctaText && checked)
      return (
        <CtaContainer>
          <TextButton onClick={handleViewCustomDocument}>{ctaText}</TextButton>
        </CtaContainer>
      )
    return null
  }

  return (
    <div>
      <Container
        isLastItem={isLastItem}
        onClick={handleClick}
        isDisabled={isDisabled}
        canHover={canHover}
        hoverColor={checked ? COLOR.payingBackground : COLOR.bgGrey}
        data-testid={`${testId}-boolean-card-without-border`}
      >
        <StyledDiv>
          {IconComponent && (
            <CheckboxHover className="check-box-hover" checked={checked}>
              <IconComponent checked={checked} alt={alt} />
            </CheckboxHover>
          )}
          <Content strong>
            {title && (
              <Title>
                {title} {isShowProBadge && <ProBadge>PRO</ProBadge>}
                {isShowNewBadge && <NewBadge>New</NewBadge>}
              </Title>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Content>
        </StyledDiv>
        {price > 0 ? (
          <PriceContainer>
            <Price> ${price.toFixed(2)} </Price>
          </PriceContainer>
        ) : null}
      </Container>
      {customDocumentGateEnabled && <CardCTA />}
    </div>
  )
}

export default BooleanCardWithoutBorderWithPrice
