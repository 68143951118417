import styled from "styled-components"

export const LoadingIcon = styled.div`
  padding: 3px 0px 0px;
  pointer-events: none;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  margin: 0px 5px;
  padding: 0px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  ${props =>
    props.marginTop &&
    `
    padding: 3px 0px 0px;
    pointer-events: none;
  `}
  ${props =>
    props.Mobile &&
    `
  @media(min-width: 992px) {
    display: none !important;
  }
  `} 
  ${props =>
    props.PC &&
    `
  @media(max-width: 991px) {
    display: none !important;
  }
  `}
`

export const Icon = styled.i`
  color: #ffffff;
  font-size: 19px;
  &:hover {
    color: #ffffff;
  }
`
