import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Tag from 'components/contacts/atoms/tag'
import { COLOR } from 'styles/settings'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 0;
`

const TagWrapper = styled.div`
  width: calc(100% - 20px);
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Icon = styled.img`
  width: ${({ width }) => width || '45px'};
  height: auto;
`

const TagWithIcon = ({ tag, customIcon, iconName, onClickIcon, iconColor }) => (
  <Container>
    <TagWrapper>
      <Tag id={tag?._id} key={tag?._id} name={tag?.name} color={tag?.color} maxWidth="100%" />
    </TagWrapper>
    <IconWrapper onClick={() => onClickIcon(tag)}>
      {customIcon || <Icon name={iconName} color={iconColor} size="sm" />}
    </IconWrapper>
  </Container>
)

export default TagWithIcon

TagWithIcon.defaultProps = {
  tag: {},
  iconColor: COLOR.textBlack,
}

TagWithIcon.propTypes = {
  tag: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  onClickIcon: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  customIcon: PropTypes.element,
  iconColor: PropTypes.string,
}
