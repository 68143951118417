import styled from "styled-components"
import { COLOR } from "../../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const InfoList = styled.ul`
  list-style: none;
  padding-left: 0;
  &:first-child {
    h4 {
      margin-top: 0;
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const InfoHead = styled.div`
  h5 {
    padding: 10px 0px;
    font-size: 1.2em;
    font-weight: 600;
    color: ${COLOR.placeholderGrey};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const InfoItem = styled.li`
  &.margin-bottom {
    margin-bottom: 20px;
  }
  hr {
    margin: 10px 0;
    border-color: ${COLOR.lineGrey};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const InfoContent = styled.p`
  .info-title {
    color: ${COLOR.blue};
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .info-text {
    color: ${COLOR.textBlack};
    font-size: 1em;
    margin: 0;
    .info-text-item {
      padding: 5px 10px;

      &:nth-child(odd) {
        background-color: rgba(${COLOR.lightBlue}, 0.2);
      }
    }
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const InfoContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  &.align-center {
    align-items: center;
  }
`
