import { useContext } from 'react'

import { SnackbarContext } from './snackbar.provider'

/**
 * See https://ui.rentspree.dev/component-2023/components/atoms/snackbar
 *
 * @returns {{showSnackbar: (props: object) => void, hideSnackbar: () => void}}
 */
export const useSnackbar = () => useContext(SnackbarContext)
