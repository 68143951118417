import { Icon } from '@rentspree/component-v2/dist/icon'
import React from 'react'
import styled from 'styled-components'

import ProgressBar from 'components/contacts/atoms/progress-bar'
import { WidgetTitle, WidgetSubTitle, TextButton } from 'components/contacts/atoms/widget'
import warningIcon from 'images/contacts/sweetalert-warning.svg'
import { COLOR } from 'styles/settings'

const DetailWrapper = styled.div`
  display: flex;
  justify-content: column;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BottomWrapper = styled.div`
  margin-top: 7px;
  display: flex;
  align-items: center;
`

const ErrorIcon = styled.img`
  margin-right: 12px;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
`

const ErrorWidget = ({ onClose, percentage, onImport }) => (
  <>
    <HeaderWrapper>
      <CloseIcon icon="close" color={COLOR.textBlack} onClick={onClose} />
    </HeaderWrapper>
    <DetailWrapper>
      <ErrorIcon src={warningIcon} alt="warning-icon" />
      <div>
        <WidgetTitle>Import error</WidgetTitle>
        <WidgetSubTitle>Please retry to continue import</WidgetSubTitle>
      </div>
    </DetailWrapper>
    <BottomWrapper>
      <ProgressBar color={COLOR.negativeRed} percent={percentage} />
      <TextButton onClick={onImport}>Retry</TextButton>
    </BottomWrapper>
  </>
)

export default ErrorWidget
