import generateApiCall from "utils/generate-api-call"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { OPEN_SWEET_ALERT } from "redux-middleware/sweet-alert/constants"
import * as ERROR from "legacy/constants/error-messages"
import { TYPES } from "./constants"

export const printReport = payload => ({
  type: TYPES.PRINT_REPORT,
  payload,
})

export const savePDF = payload => ({
  type: TYPES.SAVE_PDF_CALL,
  payload,
})

export const savePDFApi = generateApiCall([
  TYPES.SAVE_PDF_REQUEST,
  TYPES.SAVE_PDF_SUCCESS,
  TYPES.SAVE_PDF_FAILURE,
])

export const alertSaveTimeout = () => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.ERROR,
  option: {
    title: ERROR.APPLICATION_DETAIL.SAVE_PDF_TIMEOUT.TITLE,
    text: ERROR.APPLICATION_DETAIL.SAVE_PDF_TIMEOUT.MESSAGE,
    className: "rentspree-sweetalert",
    closeOnClickOutside: false,
  },
})

export const getRentalSubmissionCall = ({ rentalId, propertyId }) => ({
  type: TYPES.GET_RENTAL_SUBMISSION_CALL,
  payload: {
    rentalId,
    propertyId,
  },
})

export const getRentalSubmission = generateApiCall([
  TYPES.GET_RENTAL_BY_ID_REQUEST,
  TYPES.GET_RENTAL_BY_ID_SUCCESS,
  TYPES.GET_RENTAL_BY_ID_FAILED,
])

export const getSubmissionParticipant = generateApiCall([
  TYPES.GET_SUBMISSION_PARTICIPANT_REQUEST,
  TYPES.GET_SUBMISSION_PARTICIPANT_SUCCESS,
  TYPES.GET_SUBMISSION_PARTICIPANT_FAILED,
])

export const getSubmissionParticipantByEmailCall = (rentalId, email) => ({
  type: TYPES.GET_SUBMISSION_PARTICIPANT_BY_EMAIL_CALL,
  payload: {
    rentalId,
    email,
  },
})

export const getSubmissionParticipantByEmail = generateApiCall([
  TYPES.GET_SUBMISSION_PARTICIPANT_BY_EMAIL_REQUEST,
  TYPES.GET_SUBMISSION_PARTICIPANT_BY_EMAIL_SUCCESS,
  TYPES.GET_SUBMISSION_PARTICIPANT_BY_EMAIL_FAILURE,
])