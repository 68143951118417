import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import {
  makeSelectActiveId,
  makeSelectRecipientList,
  selectLoadingUpdateEnvelope,
  selectFieldValidationResult,
  selectSignatureData,
  selectIsOwner,
  makeSelectShowLeavePopup,
  selectIsUploadingSignature,
  selectLoadingSavingFile,
  selectIsSigned,
  selectIsAcceptedConsent,
  selectLoadingUpdateConsent,
  selectUpdateConsentSuccess,
  selectEnvelopeError,
  makeSelectDoesEnvelopeExist,
  makeSelectEnvelopePDFPath,
  selectEnvelopeData,
  makeSelectUploadSignatureSuccess,
  makeSelectIsSignatureEdited,
  makeSelectIsUsingTemplate,
  makeSelectBase64Files,
} from '../selectors'
import {
  updateEnvelopeSign,
  dateSignedSave,
  alertPrintAndDownloadNotHaveURL,
  saveFiles,
  saveBufferFiles,
  updateEnvelopeConsent,
  editSignSignature,
  saveSignSignature,
  uploadSaveSignSignature,
  uploadEditSignSignature,
  triggerAutosaveEnvelope,
  getBase64Files,
} from '../actions'
import {
  setChildrenBox,
  setActive,
  resetActive,
  addChildren,
  setAssigneeToBox,
  setConfigBox,
  changeBoxPage,
} from '../../drag-n-drop/actions'
import { clearError } from '../../errors/actions'

export const makeMapStateToProps = () =>
  createStructuredSelector({
    envelope: selectEnvelopeData(),
    haveEnvelope: makeSelectDoesEnvelopeExist(),
    pdfPath: makeSelectEnvelopePDFPath(),
    isLoadingSavingFile: selectLoadingSavingFile,
    recipientsList: makeSelectRecipientList(),
    fieldValidation: selectFieldValidationResult(),
    isSigned: selectIsSigned(),
    activeId: makeSelectActiveId(),
    isLoadingUpdateEnvelope: selectLoadingUpdateEnvelope,
    signatureData: selectSignatureData(),
    isOwner: selectIsOwner(),
    shouldHandleUnload: makeSelectShowLeavePopup(),
    isUploadingSignature: selectIsUploadingSignature,
    isAcceptedConsent: selectIsAcceptedConsent(),
    isLoadingUpdateConsent: selectLoadingUpdateConsent,
    updateConsentSuccess: selectUpdateConsentSuccess,
    error: selectEnvelopeError,
    uploadSignatureSuccess: makeSelectUploadSignatureSuccess(),
    isSignatureEdited: makeSelectIsSignatureEdited(),
    isUsingTemplate: makeSelectIsUsingTemplate(),
    base64Files: makeSelectBase64Files(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        /* envelope actions */
        updateEnvelopeSign,
        dateSignedSave,
        alertPrintAndDownloadNotHaveURL,
        saveFiles,
        saveBufferFiles,
        updateEnvelopeConsent,
        editSignSignature,
        saveSignSignature,
        uploadSaveSignSignature,
        uploadEditSignSignature,
        clearError,
        triggerAutosaveEnvelope,
        getBase64Files,
        /* drag-n-drop actions */
        setChildrenBox,
        setActive,
        resetActive,
        addChildren,
        setAssigneeToBox,
        setConfigBox,
        changeBoxPage,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(makeMapStateToProps, mapDispatchToProps)
