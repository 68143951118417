import { buildPath } from "@rentspree/path"

import * as PATH from "constants/route"
import { LICENSE_TYPES } from "constants/user"
import { APPLICATION_TYPE, PAYER_TYPE } from "../constants"

const { DRE } = LICENSE_TYPES

const hasLicense = (licenses = [], type) =>
  licenses.filter(license => license.type === type).length > 0

const generateBuildPath = ({
  propertyId,
  screeningRequestId,
  otherQueryString,
}) => (path, continuePathOverride = false) =>
    continuePathOverride && otherQueryString.continuePath
      ? otherQueryString.continuePath
      : buildPath(
        path,
        { propertyId },
        { screeningRequestId, ...otherQueryString },
      )

const createPathRules = path => [
  {
    applicationType: APPLICATION_TYPE.CAR_LRA,
    predicate: ({ licenses, requestOptions }) =>
      hasLicense(licenses, DRE) && requestOptions.payer === PAYER_TYPE.ME,
    path: path(PATH.REQUEST_UPDATE_SCREENING_FEE),
  },
  {
    applicationType: APPLICATION_TYPE.CAR_LRA,
    predicate: ({ licenses }) => !hasLicense(licenses, DRE),
    path: path(PATH.REQUEST_UPDATE_DRE_LICENSE),
  },
]

export const selectScreeningRequestResultPath = ({
  requestOptions,
  licenses,
  propertyId,
  screeningRequestId,
  otherQueryString = {},
}) => {
  const pathBuilder = generateBuildPath({
    propertyId,
    screeningRequestId,
    otherQueryString,
  })
  const rules = createPathRules(pathBuilder)
  const selectedRule = rules.find(
    rule =>
      rule.applicationType === requestOptions.applicationType &&
      rule.predicate({ licenses, requestOptions }),
  )
  return selectedRule?.path || pathBuilder(PATH.SCREENING_NEW_TENANT_ACTION_V2, true)
}
