import React from 'react'
import get from 'lodash/get'
import { matchPath } from 'react-router-dom'
import Badge from 'containers/badge/badge-component'
import {
  LEASE_AGREEMENT_DETAILS,
  PROPERTY,
  SINGLE_APPLICATION,
  SINGLE_PROPERTY,
  RENTER_INSURANCE_DETAIL,
  PROPERTY_OVERVIEW_PAGE,
  RENT_PAYMENT_ID_PAGE,
  TENANT_SCREENING,
  TENANT_SCREENING_DETAIL,
  RENT_PAYMENT,
  CONTACTS,
  CONTACT_DETAIL,
  TASKS,
  IA_RENT_PAYMENT_ID_PAGE,
  SINGLE_PROPERTY_RENT_PAYMENT,
  RENT_PAYMENT_REQUEST_PAGE,
} from 'constants/route'
import { BADGE_COMPONENT_TYPES } from 'containers/badge/constants'

const objectIdPathMapper = {
  propertyId: SINGLE_PROPERTY,
  rentalAppId: SINGLE_APPLICATION,
  leaseAgreementId: LEASE_AGREEMENT_DETAILS,
  rentersInsuranceId: RENTER_INSURANCE_DETAIL,
  submissionParticipantId: TENANT_SCREENING_DETAIL,
  contactId: CONTACT_DETAIL,
}

export function createBreadcrumbStepsArray(pageDetails) {
  const { address, fullName, contactName, contactId } = pageDetails

  const breadcrumbSteps = [
    {
      key: 'PropertyList',
      text: 'Properties',
      path: PROPERTY,
      state: { clearPropertyListSearch: true },
    },
    {
      key: 'PropertySingle',
      text: address,
      path: SINGLE_PROPERTY,
      redirectPath: PROPERTY_OVERVIEW_PAGE,
    },
    {
      key: 'PropertyRentPayment',
      text: 'Transaction details',
      path: SINGLE_PROPERTY_RENT_PAYMENT,
    },
    {
      key: 'RentPaymentOnboarding',
      text: 'Payments',
      path: RENT_PAYMENT,
    },
    [
      {
        key: 'PaymentSetup',
        text: 'Create Payment',
        path: RENT_PAYMENT_REQUEST_PAGE,
      },
      {
        key: 'RentPaymentDetail',
        text: 'Transaction details',
        path: IA_RENT_PAYMENT_ID_PAGE,
      },
    ],
    {
      key: 'TenantScreening',
      text: 'Tenant Screening',
      path: TENANT_SCREENING,
    },
    {
      key: 'TenantScreeningDetail',
      text: fullName,
      path: TENANT_SCREENING_DETAIL,
    },
    {
      key: 'Contacts',
      text: (
        <span>
          Contacts
          <Badge
            activeId="rcmMenuBreadcrumb"
            badgeComponentType={BADGE_COMPONENT_TYPES.BETA_BADGE}
            padding="2px"
            fontSize="9px"
          />
        </span>
      ),
      path: CONTACTS,
    },
    {
      key: 'TaskOverview',
      text: (
        <span>
          Tasks
          <Badge
            activeId="taskOverviewBreadcrumb"
            badgeComponentType={BADGE_COMPONENT_TYPES.BETA_BADGE}
            padding="2px"
            fontSize="9px"
          />
        </span>
      ),
      path: TASKS,
    },
  ]
  if (contactId) {
    breadcrumbSteps.push({
      key: 'MobileContactDetail',
      text: contactName,
      path: `${CONTACTS}/${contactId}`,
      redirectPath: CONTACTS,
    })
  }
  return breadcrumbSteps
}

export function getPathObjectId(objName, pathname) {
  const pathObject = matchPath(pathname, {
    path: objectIdPathMapper[objName],
  })
  return get(pathObject, ['params', objName], '/')
}

export function getRentPaymentId(pathname) {
  const rentPaymentDetailPathObject = matchPath(pathname, {
    path: RENT_PAYMENT_ID_PAGE,
  })
  return get(rentPaymentDetailPathObject, 'params.rentPaymentId') || '/'
}

export function selectShowBreadcrumbSteps(breadcrumbSteps, pathname) {
  return breadcrumbSteps
    .map(item =>
      Array.isArray(item)
        ? item.find(
            step =>
              !!matchPath(pathname, {
                path: step.path,
                exact: step.exact,
                strict: step.strict,
              }),
          )
        : item,
    )
    .filter(
      step =>
        step &&
        !!matchPath(pathname, {
          path: step.path,
          exact: step.exact,
          strict: step.strict,
        }),
    )
}
