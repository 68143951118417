import React from 'react'
import { compose } from 'redux'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import has from 'lodash/has'
import { TopNavbar } from '@rentspree/navbar'
import { DisableFeatureProvider, useDisableFeature } from '@rentspree/feature-toggle'
import disabledFeatureInstance from 'utils/feature-toggle/instance'
import tracker from 'tracker'
import { withRouter } from 'react-router-dom'
import { MESSAGING_PAGE } from 'constants/route'
import { USER_TYPE } from 'constants/user'
import withAuth from 'containers/auth'
import { withSaga, withReducer } from '../connect'
import { withConnect } from './connect'
import { DOMAIN_NAME, USER_API_URL, API_URL } from '../../../env'
import Messaging from '../index'
import { RENTER_NAVBAR } from './constants'

export const withFeatureToggle = WrappedComponent => props =>
  (
    <DisableFeatureProvider subdomain="rentspree" disableFeature={disabledFeatureInstance}>
      <WrappedComponent {...props} />
    </DisableFeatureProvider>
  )

export const populateMenus = (badgeData, isReady, disableFeature) => {
  let MENUS = [RENTER_NAVBAR.APPLICATIONS].filter(
    menu => !has(menu, 'featureId') || (isReady && disableFeature.isFeatureEnabled(menu.featureId)),
  )

  if (!isEmpty(badgeData)) {
    MENUS = MENUS.map(item => {
      if (!isEmpty(find(badgeData, { parentId: item.id }))) {
        return { ...item, showBadge: true }
      }
      return item
    })
  }

  return MENUS
}

// This component for renter, it will be moved to the user-dashboard soon
export const MessageWithNavbar = ({
  initiateTalkJs,
  profile,
  history,
  unreadMessages,
  ...rest
}) => {
  const { isReady, disableFeature } = useDisableFeature()

  React.useEffect(() => {
    if (profile.userType === USER_TYPE.LANDLORD) {
      history.push(MESSAGING_PAGE)
    } else {
      initiateTalkJs()
    }
  }, [profile])

  return (
    <>
      <TopNavbar
        message={{ notice: unreadMessages }}
        incomingFeatureMenu={populateMenus({}, isReady, disableFeature)}
        configApi={{
          baseURL: USER_API_URL,
          domain: DOMAIN_NAME,
          apiURL: API_URL,
        }}
        subdomain="rentspree"
        profile={profile}
        otherButton={{
          styles: 'display:none',
        }}
        tracker={tracker}
      />
      <Messaging {...rest} />
    </>
  )
}

export default compose(
  withFeatureToggle,
  withAuth,
  withSaga,
  withReducer,
  withRouter,
  withConnect,
)(MessageWithNavbar)
