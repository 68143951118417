import React from "react"
import styled from "styled-components"

import ConfirmationModal from "components/organisms/confirmation-modal"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import sweetAlertIcon from "./sweetalert-warning.svg"

const Icon = styled.img`
  width: 88px;
  height: 88px;
  display: block;
  margin: 0 auto 24px;
`

const ExistingSubmissionErrorModal = ({ isOpen, onClose, onClickConfirm }) => (
  <ConfirmationModal
    isOpen={isOpen}
    confirmTxt="View Existing Report"
    cancelTxt="Back"
    modalSize="small"
    buttonsJustifyContent="space-between"
    onClickConfirm={onClickConfirm}
    onClose={onClose}>
    <Icon src={sweetAlertIcon} />
    <S24 align="center">Whoops!</S24>
    <B16 align="center">
      The property has already been assigned to this applicant. Select/create a
      new property for the new application, or view the applicant&apos;s
      existing report.
    </B16>
  </ConfirmationModal>
)

export default ExistingSubmissionErrorModal
