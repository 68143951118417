import produce from 'immer'

import {
  GET_ASSOCIATION_CONTACT_REQUEST,
  GET_ASSOCIATION_CONTACT_FAILURE,
  GET_ASSOCIATION_CONTACT_SUCCESS,
} from './constants'

export const initialState = {
  associationContacts: [],
  isFetching: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_ASSOCIATION_CONTACT_REQUEST:
        draft.isFetching = true
        break
      case GET_ASSOCIATION_CONTACT_SUCCESS:
        draft.isFetching = false
        draft.associationContacts = payload
        break
      case GET_ASSOCIATION_CONTACT_FAILURE:
        draft.isFetching = false
        draft.associationContacts = initialState.associationContacts
        break
      default:
        break
    }
    return draft
  })

export default reducer
