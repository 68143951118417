import Typography from '@rentspree/component-2023.components.atoms.typography'
import React from 'react'

import './styles.scss'
import { ChatLine } from './chat-line'

export const FomoMessage = ({ text, ...props }) => {
  return (
    <ChatLine variant="up" {...props}>
      <Typography variant="caption" fontWeight="500">
        {text}
      </Typography>
    </ChatLine>
  )
}
