import React from "react"
import { Landing } from "components/landing"
import errorImg from "images/report/error.png"

const OpenError = () => (
  <Landing
    title="Trouble opening report(s)"
    description="Please contact support@rentspree.com or (323) 515–7757."
    imgSrc={errorImg}
  />
)

export default OpenError
