import { takeLatest, put, call, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { replace } from "connected-react-router"
import { PLEASE_CONTACT, ERROR_SAVING } from "constants/error-messages"
import { PROPERTY_API_V2 } from "constants/route"
import { FILTER_PROPERTY } from "containers/property-list/constants"
import { apiInstance } from "../../utils/api-interceptor"
import { getPropertyListApi } from "./actions"
import { GET_ALL_PROPERTY_LIST_CALL } from "./const"

export const DEFAULT_QUERY = {
  page: 1,
  perPage: 100,
  filter: { status: FILTER_PROPERTY.ACTIVE },
}

export const callGetAllPropertyAPI = () =>
  apiInstance.get(buildPath(PROPERTY_API_V2, {}, DEFAULT_QUERY))

export function* getAllPropertySaga({ payload }) {
  yield put(getPropertyListApi.request())
  try {
    const response = yield call(callGetAllPropertyAPI)
    if (isEmpty(get(response, "data", []))) {
      yield put(
        replace({
          pathname: get(payload, "redirectRoute", ""),
          search: get(payload, "search", ""),
          state: get(payload, "state", {}),
        }),
      )
    }
    yield put(getPropertyListApi.success(response))
  } catch (err) {
    yield put(
      getPropertyListApi.failure({
        error: { errorMessage: ERROR_SAVING, secondaryMessage: PLEASE_CONTACT },
      }),
    )
  }
}

export function* watchGetAllPropertyApiCall() {
  yield takeLatest(GET_ALL_PROPERTY_LIST_CALL, getAllPropertySaga)
}

export function* rootSaga() {
  yield all([watchGetAllPropertyApiCall()])
}

export default rootSaga
