import { takeLatest, put, call } from 'redux-saga/effects'

import { CREATE_TENANT, createTenantAPI } from '../../actions'
import { createTenantInstance } from '../../api-instances'

export function* createTenantSaga({ payload }) {
  yield put(createTenantAPI.request())
  const { firstName, lastName, email, phone, onSuccess = () => {}, onError = () => {} } = payload

  try {
    const response = yield call(createTenantInstance, { firstName, lastName, email, phone })
    yield put(createTenantAPI.success(response))
    yield call(onSuccess, response)
  } catch (err) {
    yield put(createTenantAPI.failure())
    yield call(onError, err)
  }
}

export function* watchCreateTenantSaga() {
  yield takeLatest(CREATE_TENANT, createTenantSaga)
}
