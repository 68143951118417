import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import get from 'lodash/get'

import { getDate } from 'legacy/helpers/expired-credit-report'
import { CenterContent } from 'components/layout/main'
import { ReportExpired } from 'legacy/components/reports'
import { withConnect } from './connect'
import { REPORT_TYPES, SHARED_REPORT_TYPES, SCREENING_OPTION_TYPES } from '../../constants'

export const withExpired =
  WrappedComponent =>
  ({
    creditReport,
    screeningOption,
    match,
    isReportExpired,
    isSubmissionExpired,
    pdfFile,
    shareOption,
    isSharedReport,
    ...rest
  }) => {
    // isSubmissionExpired = agent not open yet but TU expired
    // isReportExpired = agent opened more than 30 days
    if (isReportExpired || isSubmissionExpired) {
      const isRenderInAllPage = get(match, 'params.type') === REPORT_TYPES.ALL

      const hasPdfToShow = isSharedReport
        ? get(shareOption, SHARED_REPORT_TYPES.OTHER_DOCS)
        : pdfFile && pdfFile.byteLength > 0

      const hasApplicationOrRefCheck = isSharedReport
        ? get(shareOption, SHARED_REPORT_TYPES.APPLICATION) ||
          get(shareOption, SHARED_REPORT_TYPES.REFERENCE_CHECK)
        : get(screeningOption, SCREENING_OPTION_TYPES.APPLICATION)

      const hasOnlyScreeningReport = !hasApplicationOrRefCheck && !hasPdfToShow
      if (!isRenderInAllPage || hasOnlyScreeningReport) {
        return (
          <CenterContent withBreadcrumb withReportTabs withReportPadding noMargin>
            <ReportExpired
              expiredDateString={getDate(
                get(
                  creditReport,
                  isReportExpired ? 'generated_at' : 'verified_idma_at', // verified_idma_at = renter submit + 30 day, but generated_at = agent open + 30 days
                ),
              )}
              IDMA={!isReportExpired}
            />
          </CenterContent>
        )
      }
      return null
    }

    return <WrappedComponent {...rest} />
  }

export default compose(withRouter, withConnect, withExpired)
