import React from "react"
import styled from "styled-components"
import { BOX_SHADOW_1, BOX_SHADOW_3 } from "styles/settings"
import { Div } from "components/layout/main"

const WrapCollapsible = styled.div`
  width: 100%;
  border-radius: ${props => props.borderRadius};
  box-shadow: ${BOX_SHADOW_1};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  ${props => props.styles}
  transition: all 0.2s ease-in-out 0s;
  &:hover {
    box-shadow: ${BOX_SHADOW_3};
  }
  @media (max-width: 991px) {
    padding: ${props => props.mPadding};
  }
`

const LabelWrapper = styled.label`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  ${props =>
    props.iconClass &&
    `
    .${props.iconClass} {
      transition: transform 0.4s ease-in-out;
      transform: rotate(${props.defaultRotate}deg);
    }
  `}
`
const CollapsibleContent = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`
const HiddenCheckbox = styled.input`
  display: none;
  &:checked + ${LabelWrapper} + ${CollapsibleContent} {
    max-height: 100vh;
  }
  ${props =>
    props.iconClass &&
    `
  &:checked + ${LabelWrapper} {
    .${props.iconClass} {
      transform: rotate(${props.collapseRotate}deg);
    }
  }
  `}
`

export const AccordionCard = ({
  padding,
  borderRadius = "5px",
  styles,
  headerInfo,
  contentInfo,
  id = 0,
  iconClass = "",
  collapseRotate = 180,
  defaultRotate = 0,
  mPadding,
  margin,
}) => (
  <WrapCollapsible
    key={id}
    padding={padding}
    borderRadius={borderRadius}
    mPadding={mPadding}
    margin={margin}
    styles={styles}>
    <HiddenCheckbox
      type="checkbox"
      id={`collapsible-card-${id}`}
      iconClass={iconClass}
      collapseRotate={collapseRotate}
    />
    <LabelWrapper
      defaultRotate={defaultRotate}
      iconClass={iconClass}
      for={`collapsible-card-${id}`}>
      {headerInfo}
    </LabelWrapper>
    <CollapsibleContent>
      <Div>{contentInfo}</Div>
    </CollapsibleContent>
  </WrapCollapsible>
)

export default AccordionCard
