import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import isNil from 'lodash/isNil'
import { buildPath } from '@rentspree/path'
import { generateFullName } from '@rentspree/helper'
import { Breadcrumbs } from '@rentspree/component-2023.components.organisms.breadcrumbs'
import { Link } from '@rentspree/component-2023.components.atoms.link'

import { withBreadcrumb } from './connect'
import usePathIdParams from './use-path-id-params'
import useBreadcrumbSteps from './use-breadcrumb-steps'

export const Breadcrumb2023 = ({
  history,
  location: { pathname },
  street,
  leaseTitle,
  rentalDetail: { renter, email },
  rentersRequesteeEmail,
  rentPaymentTitle,
  contactName,
  contactId,
}) => {
  const fullName = renter && generateFullName(renter)
  const { desktopBreadcrumbSteps } = useBreadcrumbSteps(pathname, {
    address: street,
    fullName: fullName || email || '',
    leaseTitle,
    rentersRequesteeEmail,
    rentPaymentTitle,
    ...(isNil(contactId) ? {} : { contactId, contactName }),
  })
  const pathIds = usePathIdParams(pathname)

  const onBreadClick = to => {
    history.push(to)
  }

  return (
    <Breadcrumbs>
      {desktopBreadcrumbSteps.map(({ key, text, path, redirectPath, state }, index) => {
        if (index + 1 === desktopBreadcrumbSteps.length) {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link id={key} key={key} color="black" sx={{ cursor: 'default' }}>
              {text}
            </Link>
          )
        }
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            id={key}
            key={key}
            color="black"
            onClick={() =>
              onBreadClick({ pathname: buildPath(redirectPath || path, pathIds), state })
            }
          >
            {text}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default compose(withRouter, withBreadcrumb)(Breadcrumb2023)
