import produce from 'immer'

import {
  IMPORT_CSV,
  SET_HEADER,
  TOGGLE_ROW,
  SET_COLUMN_ERROR,
  REMOVED,
  NONE_OF_ABOVE,
  BULK_CREATE_CONTACT_STARTED,
  BULK_CREATE_CONTACT_SUCCESS,
  BULK_CREATE_CONTACT_CANCELLED,
  BULK_CREATE_CONTACT_CANCEL_COFIRMED,
  SET_CURRENT_CREATED_COUNT,
  CREATE_CHUNK_CONTACT_FAILURE,
  CLEAR_IMPORT_CSV_PROGRESS,
  IMPORT_PROGRESS_STATUS,
  CLOSE_IMPORT_WIDGET,
  TRIGGER_MINIMIZE_WIDGET,
  SET_MAPPED_DATA,
  SET_FULL_VALIDATION_ERROR,
  SET_FILE_NAME,
} from './constants'
import { getMappedHeader } from './utils'

export const initialState = {
  fileName: '',
  header: [],
  content: [],
  mappedHeader: [],
  columnError: [],
  removedRow: [], // TODO: consider using hash for better performance
  mappedData: [],
  createProgress: {
    total: 0,
    current: 0,
    minimizePage: false,
    isOpen: false,
    status: '',
  },
  isFullValidationError: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case IMPORT_CSV:
        draft.header = payload?.[0] || []
        draft.content = payload?.length > 1 ? [...payload.slice(1)] : []
        draft.mappedHeader = getMappedHeader(draft.header)
        draft.columnError = new Array(draft.header.length).fill(false)
        break
      case SET_FILE_NAME:
        draft.fileName = payload
        break
      case SET_HEADER:
        draft.mappedHeader = state.mappedHeader.map((header, index) => {
          // Set new header
          if (index === payload.index) return payload.value

          // Toggle out existing header
          if (
            header?.split('_')?.[0] === payload?.value.split('_')?.[0] &&
            ![REMOVED, NONE_OF_ABOVE].includes(header)
          ) {
            draft.columnError[index] = false // TODO write this test case
            return ''
          }

          // Unrelated header
          return header
        })
        break
      case TOGGLE_ROW:
        draft.removedRow = state.removedRow.includes(payload.index)
          ? state.removedRow.filter(row => row !== payload.index)
          : [...state.removedRow, payload.index]
        break
      case SET_COLUMN_ERROR:
        draft.columnError[payload.index] = payload.isError
        break
      case BULK_CREATE_CONTACT_STARTED:
        draft.createProgress.status = IMPORT_PROGRESS_STATUS.IN_PROGRESS
        draft.createProgress.isOpen = true
        draft.createProgress.total = payload.total
        break
      case BULK_CREATE_CONTACT_SUCCESS:
        draft.createProgress.status = IMPORT_PROGRESS_STATUS.SUCCESS
        draft.createProgress.isOpen = true
        break
      case BULK_CREATE_CONTACT_CANCELLED:
        draft.createProgress.status = IMPORT_PROGRESS_STATUS.CANCELLED
        break
      case BULK_CREATE_CONTACT_CANCEL_COFIRMED:
        draft.createProgress.status = IMPORT_PROGRESS_STATUS.PARTIALLY_SUCCESS
        draft.createProgress.isOpen = true
        break
      case SET_CURRENT_CREATED_COUNT:
        draft.createProgress.current = payload
        break
      case CREATE_CHUNK_CONTACT_FAILURE:
        draft.createProgress.status = IMPORT_PROGRESS_STATUS.ERROR
        draft.createProgress.isOpen = true
        break
      case CLEAR_IMPORT_CSV_PROGRESS:
        draft.createProgress = initialState.createProgress
        break
      case CLOSE_IMPORT_WIDGET:
        draft.createProgress.isOpen = initialState.isOpen
        break
      case TRIGGER_MINIMIZE_WIDGET:
        draft.createProgress.minimizePage = !state.createProgress.minimizePage
        break
      case SET_MAPPED_DATA:
        draft.mappedData = payload
        break
      case SET_FULL_VALIDATION_ERROR:
        draft.isFullValidationError = payload.isError
        break
      default:
        break
    }
    return draft
  })

export default reducer
