import React from 'react'
import HeaderPrint from 'containers/reports/header'
import { COLOR } from 'styles/settings'
import iconReportUnavailable from 'images/icons/credit-report/report-unavailable.png'
import { FontReport, Section } from 'legacy/components/reports-v2/share/doc-style'
import {
  ReportsBody,
  SectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import TextLink from 'components/atoms/anchor'
import styled from 'styled-components'
import {
  Content,
  InformationPanel,
  ReportWrapper,
  SubSection,
} from '../../share/responsive-doc-style'

const IconWrapper = styled.div`
  margin-top: 85px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
  color: ${COLOR.textBlack};
  text-align: center;
`

const Text = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 30px;
  color: ${COLOR.textBlack};
  text-align: center;
`

const MessageWrapper = styled.div`
  width: 75%;
  margin: 0 auto 56px auto;
`

const InLieuOfCreditUnavailableCreditReport = ({
  customMargin,
  customBorder,
  showHeaderComponent,
}) => (
  <ReportWrapper customBorder={customBorder} customMargin={customMargin}>
    {showHeaderComponent && <HeaderPrint />}
    <ReportsBody>
      <Section>
        <IconWrapper>
          <img src={iconReportUnavailable} alt="credit-report-unavailable-by-in-lieu-of-credit" />
        </IconWrapper>
        <MessageWrapper>
          <Title>Credit report unavailable</Title>
          <Text>
            The applicant has indicated they receive government rent subsidy and will provide
            alternative proof of income in lieu of a credit report.
          </Text>
        </MessageWrapper>
      </Section>
      <Section>
        <SectionHeader title="Attention" />
        <SubSection>
          <Content>
            <InformationPanel>
              <FontReport Attention>
                Applicants receiving a government rent subsidy can elect to provide lawful,
                verifiable alternative evidence of their ability to pay instead of a credit report.
                Acceptable evidence includes, but is not limited to, government benefit payments,
                pay records, and bank statements.
                <TextLink
                  to="https://support.rentspree.com/en/government-subsidies-for-applicants-completing-a-ca-application"
                  underline
                  color={COLOR.fontLink}
                >
                  Learn more about this program
                </TextLink>
              </FontReport>
            </InformationPanel>
          </Content>
        </SubSection>
      </Section>
    </ReportsBody>
  </ReportWrapper>
)

export default InLieuOfCreditUnavailableCreditReport
