import { createStructuredSelector } from "reselect"
import { compose, bindActionCreators } from "redux"
import { connect } from "react-redux"

import { createProperty } from "legacy/actions/property.action"
import { selectIsContinueToPropertyDetails } from "containers/request/step-create-property/selectors"

import {
  makeSelectSelectedProperty,
  makeSelectPropertyLoading,
} from "./selectors"

export const mapStateToProps = () =>
  createStructuredSelector({
    selectedProperty: makeSelectSelectedProperty(),
    isCreatingProperty: makeSelectPropertyLoading(),
    isContinueToPropertyDetails: selectIsContinueToPropertyDetails(),
  })

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createProperty,
    },
    dispatch,
  ),
})

export const withRequestUpdateProperty = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
