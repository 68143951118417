import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { CREATE_TENANT_API } from './constants'

export const createTenantInstance = ({ firstName, lastName, email, phone }) =>
  apiInstanceWithErrorHandler.post(buildPath(CREATE_TENANT_API), {
    renterInfo: { firstName, lastName, email, phone },
  })
