import { generateAddress } from '@rentspree/helper'
import { query } from '@rentspree/path'
import dayjs from 'dayjs'
import omit from 'lodash/omit'
import toNumber from 'lodash/toNumber'

import { removeEmptyValue } from 'helpers/object'
import { MOBILE_WIDTH } from 'styles/settings'
import { checkDateIsValid } from 'utils/date'

export const generateImageFromName = (name = {}) => {
  const { email = '', firstName = '', lastName = '' } = name
  if (firstName) {
    return (firstName[0] + (lastName[0] || '')).toUpperCase()
  }
  return (email[0] || '').toUpperCase()
}

export const getNameFromEmail = (email = '') => {
  const emailArr = email.split('@')
  return emailArr[0]
}

export const updateQueryString = (currentQueryString, updatingQueryString) => {
  const currentQuery = query.parse(currentQueryString)
  const newQuery = removeEmptyValue({ ...currentQuery, ...updatingQueryString })
  return query.stringify(newQuery)
}

export const getTagByTagId = (allTags = [], tagId) =>
  // eslint-disable-next-line no-underscore-dangle
  allTags.find(tag => tag?._id === tagId) || null

export const removeKeysFromQueryString = (currentQueryString, removedKeys) => {
  const currentQuery = query.parse(currentQueryString)
  const queryString = omit(currentQuery, removedKeys)
  return query.stringify(queryString)
}

export const enhanceAddressObject = addressObject => {
  if (!addressObject) {
    return null
  }
  const newAddressObject = {
    ...addressObject,
    zipCode: addressObject.zipcode,
  }
  return generateAddress(newAddressObject)
}

export const getContactName = ({ firstName = '', middleName = '', lastName = '', email }) => {
  if ([firstName, middleName, lastName].some(name => name !== '')) {
    return `${firstName} ${middleName} ${lastName}`.trim()
  }

  return getNameFromEmail(email)
}

export const parseContact = contact => {
  const { address, ...rest } = contact
  return removeEmptyValue({ livingAddress: removeEmptyValue(address), ...rest })
}

export const isDesktopDimension = ({ width }) => {
  const mobileWidth = MOBILE_WIDTH.replace('px', '') || '0'
  const isDesktop = width > toNumber(mobileWidth)
  return isDesktop
}

export const getDateDiff = date => {
  if (!checkDateIsValid(date)) return null
  return Math.ceil(dayjs(date).diff(new Date().getTime(), 'day', true))
}
