import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { isEmptyArray, getValueAndDefault } from 'legacy/components/reports-v2/share/function'

import { CONSUMER_STATEMENT, CONSUMER_RIGHTS_STATEMENT } from 'legacy/constants/credit-report-code'
import {
  InformationArea,
  SectionHeader,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'

const StatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const StatementItem = ({ code, codeObj, statement }) => (
  <InformationArea label={statement} value={getValueAndDefault(codeObj, code)} />
)

export const ConsumerStatement = ({ consumers = [], consumerRights = [] }) => (
  <Section>
    <SectionHeader title="Consumer Statement" />
    {!isEmptyArray(consumers) && (
      <SubSection>
        <SubSectionHeader title="Consumer Statement" />
        <StatementContainer>
          {consumers.map((consumer, key) => (
            <StatementItem
              codeObj={CONSUMER_STATEMENT}
              statement={get(consumer, 'statementText')}
              code={get(consumer, 'typeCode')}
              key={key.toString()}
            />
          ))}
        </StatementContainer>
      </SubSection>
    )}
    {!isEmptyArray(consumerRights) && (
      <SubSection>
        <SubSectionHeader title="Consumer Rights Statement" />
        <StatementContainer>
          {consumerRights.map((consumerRight, key) => (
            <StatementItem
              codeObj={CONSUMER_RIGHTS_STATEMENT}
              statement={get(consumerRight, 'statement')}
              code={get(consumerRight, 'contentType')}
              key={key.toString()}
            />
          ))}
        </StatementContainer>
      </SubSection>
    )}
  </Section>
)

export default ConsumerStatement
