import React from "react"
import styled from "styled-components"

import { Button } from "@rentspree/component-v2"

const StyledArrowButton = styled(Button)`
  border: 0px;
  border-radius: 3px;
  padding: 0px 30px 0px 20px;
  ${({ bodyLength }) =>
    bodyLength &&
    `clip-path: polygon(0% 0%, ${bodyLength} 0%, 100% 50%, ${bodyLength} 100%, 0% 100%)`};
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
`
export const StyledDiv = styled.div`
  ${({ shadow }) =>
    shadow && "filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.2))"};
`

const ArrowButton = ({ shadow, ...rest }) => (
  <StyledDiv shadow={shadow}>
    <StyledArrowButton {...rest} />
  </StyledDiv>
)

ArrowButton.defaultProps = {
  bodyLength: "75%",
  bgColor: "#ff8a23",
  color: "white",
}

export default ArrowButton
