import isEmpty from "lodash/isEmpty"

export const isMailingAddressCompleted = profile => {
  if (isEmpty(profile)) return false

  const { firstName, lastName, phone, address } = profile || {}

  const isSomeMADataEmpty =
    isEmpty(firstName) ||
    isEmpty(lastName) ||
    isEmpty(phone) ||
    isEmpty(address)

  if (isSomeMADataEmpty) return false

  const { street, city, state, zip } = address
  const isSomeAddressDataEmpty =
    isEmpty(street) || isEmpty(city) || isEmpty(state) || isEmpty(zip)

  return !isSomeAddressDataEmpty
}
