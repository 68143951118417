import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import get from "lodash/get"
import { initiateTalkJs, setUnreadCount } from "containers/messaging/actions"
import {
  selectIsTalkJsReady,
  selectUnreadCountText,
} from "containers/messaging/selectors"
import * as ProfileActions from "../../actions/user.action"
import * as ModalsActions from "../../actions/modals.action"
import * as RedirectActions from "../../actions/redirect.action"
import * as NoticesActions from "../../../containers/popper/actions"
import * as ExploreActions from "../../../containers/explore/actions"
import * as SubscriptionActions from "../pro/landing/actions"
import { makeSelectProducts } from "../pro/landing/selectors"

export default ComposedComponent =>
  connect(
    state => ({
      profile: state.user,
      isLogin: !!state.persistState.auth.accessToken,
      subdomain:
        state.subdomain && state.subdomain.subdomain
          ? state.subdomain.subdomain.sub
          : null,
      modals: state.modals,
      isFetched: state.user.isFetched,
      isNotRedirect: state.redirect.isNotRedirect,
      explore: state.explore
        ? state.explore
        : { isShow: false, video: { title: null, link: null } },
      isTalkJsReady: selectIsTalkJsReady(state),
      unreadCountText: selectUnreadCountText(state),
      userIntegration: get(state, "user.userIntegration", []),
      products: makeSelectProducts()(),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          ...ProfileActions,
          ...ModalsActions,
          ...RedirectActions,
          ...NoticesActions,
          ...ExploreActions,
          ...SubscriptionActions,
          initiateTalkJs,
          setUnreadCount,
        },
        dispatch,
      ),
    }),
  )(ComposedComponent)
