export const TOAST_POSITION = {
  TOP_LEFT: "TOP_LEFT",
  TOP_CENTER: "TOP_CENTER",
  TOP_CENTER_EDGE: "TOP_CENTER_EDGE",
  TOP_RIGHT: "TOP_RIGHT",
  BOTTOM_LEFT: "BOTTOM_LEFT",
  BOTTOM_CENTER: "BOTTOM_CENTER",
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
}

export const ADD_TOAST = "ADD_TOAST"
export const CLOSE_TOAST = "CLOSE_TOAST"
