import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { object, string } from 'yup'

import { addressValidation } from 'legacy/components/helper/address-utils'
import { propertyDetailsValidation, validateEmail } from 'constants/validations'
import {
  requiredYup,
  requestPropertyValidation,
  validateLength,
  validatePhone,
} from 'legacy/constants/validations'

import { USER_INPUT_ROLE, USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { Errors } from "./errors"

const parseToJson = v => (v ? JSON.parse(v) : {})

export const selectPropertySchema = props => ({
  mapper: {
    selectedAddress: props.selectedAddress,
  },
  validation: object().shape({
    selectedAddress: requiredYup(true),
  }),
})

export const schemaSelectedValidation = object().shape({
  selectedAddress: requiredYup(true)
    .test({
      name: 'isAddressEmpty',
      message: <Errors type="NO_PROPERTY" />,
      test: v => {
        const value = parseToJson(v)
        return !(
          isEmpty(value.address) &&
          isEmpty(value.city) &&
          isEmpty(value.state) &&
          isEmpty(value.zip)
        )
      },
    })
    .test({
      name: 'hasAddressErr',
      message: <Errors type="INCORRECT_ADDRESS" />,
      test: v => {
        const value = parseToJson(v)
        const error = addressValidation(value)
        return !(!isEmpty(error.emptyFields) || !isEmpty(error.validationErrors))
      },
    }),
})

export const createPropertySchema = ({
  street,
  unitNumber,
  city,
  state,
  zipcode,
  firstName,
  lastName,
  middleName,
  email,
  mobileNumber,
  validateOnMount = false,
}) => ({
  mapper: {
    street,
    unitNumber,
    city,
    state,
    zipcode,
    firstName,
    lastName,
    middleName,
    email,
    mobileNumber,
  },
  validation: object().shape({
    street: requestPropertyValidation('street'),
    unitNumber: requestPropertyValidation('unitNumber'),
    city: requestPropertyValidation('city'),
    state: requestPropertyValidation('state'),
    zipcode: requestPropertyValidation('zipcode'),
    firstName: validateLength(false, 1, 50),
    lastName: validateLength(false, 1, 50),
    middleName: validateLength(false, 1, 50),
    email: validateEmail(false),
    mobileNumber: validatePhone(false),
  }),
  touched: {
    street: validateOnMount,
    unitNumber: validateOnMount,
    city: validateOnMount,
    state: validateOnMount,
    zipcode: validateOnMount,
    firstName: validateOnMount,
    lastName: validateOnMount,
    middleName: validateOnMount,
    email: validateOnMount,
    mobileNumber: validateOnMount,
  },
})

export const createPropertySchemaWithUserRoleForProperty = ({
  street,
  unitNumber,
  city,
  state,
  zipcode,
  firstName,
  lastName,
  email,
  validateOnMount = false,
  otherRoleName,
  userRoleForProperty
}) => ({
  mapper: {
    street,
    unitNumber,
    city,
    state,
    zipcode,
    firstName,
    lastName,
    email,
    otherRoleName,
    userRoleForProperty
  },
  validation: object().shape({
    street: requestPropertyValidation('street'),
    unitNumber: requestPropertyValidation('unitNumber'),
    city: requestPropertyValidation('city'),
    state: requestPropertyValidation('state'),
    zipcode: requestPropertyValidation('zipcode'),
    userRoleForProperty: string(),
    firstName: string().when('userRoleForProperty', {
      is: USER_ROLE_FOR_PROPERTY.LISTING_AGENT,
      then: validateLength(true, 1, 50),
    }),
    lastName: string().when('userRoleForProperty', {
      is: USER_ROLE_FOR_PROPERTY.LISTING_AGENT,
      then: validateLength(true, 1, 50),
    }),
    email: string().when('userRoleForProperty', {
      is: USER_ROLE_FOR_PROPERTY.LISTING_AGENT,
      then: validateEmail(true),
    }),
    otherRoleName: string().when('userRoleForProperty', {
      is: USER_INPUT_ROLE.OTHER,
      then: validateLength(true, 1, 50)
    }),
  }),
  touched: {
    street: validateOnMount,
    unitNumber: validateOnMount,
    city: validateOnMount,
    state: validateOnMount,
    zipcode: validateOnMount,
    userRoleForProperty: validateOnMount,
    firstName: validateOnMount,
    lastName: validateOnMount,
    email: validateOnMount,
    otherRoleName: validateOnMount,
  },
})

export const createPropertyWithTypeSchema = ({ propertyType }, ...rest) => {
  const propertySchema = createPropertySchema(rest)
  propertySchema.mapper = { propertyType, ...propertySchema.mapper }
  propertySchema.validation = propertySchema.validation.concat(
    object().shape({
      propertyType: propertyDetailsValidation('propertyType'),
    }),
  )
  propertySchema.touched = {
    propertyType: rest.validateOnMount,
    ...propertySchema.mapper,
  }
  return propertySchema
}
