import { useSelector } from "react-redux"
import { useLastLocation } from "react-router-last-location"
import { query } from "@rentspree/path"
import { REQUEST_UPDATE_INFO } from "constants/route"
import { selectProfile } from "containers/user/selectors"
import { isMailingAddressCompleted } from "helpers/mailing-address-checking"
import { isGotoNewLocation } from "hooks/helper"
import { usePathname } from "./use-pathname"

export const useToFullMailingPath = () => {
  const lastLocation = useLastLocation()
  const pathname = usePathname()
  const profile = useSelector(selectProfile)

  if (isMailingAddressCompleted(profile)) return null

  return {
    isRedirect: isGotoNewLocation({
      pathname,
      destinationPath: REQUEST_UPDATE_INFO,
      lastLocationPath: lastLocation?.pathname,
    }),
    path: {
      pathname: REQUEST_UPDATE_INFO,
      search: query.stringify({
        continuePath: pathname,
      }),
    },
  }
}
