import React from 'react'
import Box from '@mui/material/Box'
import { Pagination } from '@rentspree/component-2023.components.organisms.pagination'

import './index.scss'

export const PropertyListPagination = ({ pagination, handleChangePage }) => {
  const { page, maxPage } = pagination
  const onPageChange = (_, current) => {
    handleChangePage(current)
  }

  return (
    <Box className="property-table-pagination-container">
      <Pagination
        data-testid="property-table-pagination"
        count={maxPage}
        page={page}
        onChange={onPageChange}
      />
    </Box>
  )
}
