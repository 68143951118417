import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getImportCsv = state => get(state, 'contacts.importCsv', initialState)

export const selectFileName = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'fileName', initialState.fileName),
)

export const selectHeader = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'header', initialState.header),
)

export const selectContent = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'content', initialState.content),
)

export const selectMappedData = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'mappedData', initialState.mappedData),
)

export const selectCurrentCreated = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'createProgress.current', initialState.createProgress.current),
)

export const selectProgressStatus = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'createProgress.status', initialState.createProgress.status),
)

export const selectCreateProgress = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'createProgress', initialState.createProgress),
)

export const selectProgressPercentage = createSelector(getImportCsv, importCsv => {
  const current = get(importCsv, 'createProgress.current', 0)
  const total = get(importCsv, 'createProgress.total', 0)
  return Math.round((current / total) * 100) || 0
})

export const selectMappedHeader = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'mappedHeader', initialState.selectMappedHeader),
)

export const selectRemovedRow = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'removedRow', initialState.removedRow),
)

export const selectColumnError = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'columnError', initialState.columnError),
)

export const selectIsFullValidationError = createSelector(getImportCsv, importCsv =>
  get(importCsv, 'isFullValidationError', initialState.isFullValidationError),
)

// TODO add selectors test
