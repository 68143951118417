import React, { useEffect } from 'react'

import { useOnboardingPartnerAgent } from 'v3/hooks/use-onboarding-partner-agent'
import tracker from 'tracker'
import { ONBOARDING_PARTNER_AGENT_EVENT, TS_WIDGET_EVENT_NAME } from 'tracker/const'
import { OnboardingPartnerAgentTemplateAModal } from './template-a-modal'
import { OnboardingPartnerAgentTemplateBModal } from './template-b-modal'

export const OnboardingPartnerAgentModal = () => {
  const { isOnboardingVisible, hideOnboarding, modalTemplate, setIsHighlightScreenTenant } =
    useOnboardingPartnerAgent()

  useEffect(() => {
    if (isOnboardingVisible) {
      tracker.trackEvent(ONBOARDING_PARTNER_AGENT_EVENT.EVENT_NAME.OPEN_ONBOARDING_AGENT_MODAL, {
        modal_template: modalTemplate,
      })
    }
  }, [isOnboardingVisible])

  const handleClose = () => {
    tracker.trackEvent(ONBOARDING_PARTNER_AGENT_EVENT.EVENT_NAME.CLOSE_ONBOARDING_AGENT_MODAL, {
      modal_template: modalTemplate,
    })
    hideOnboarding()
  }

  const handleClickScreenTenants = () => {
    tracker.trackEvent(ONBOARDING_PARTNER_AGENT_EVENT.EVENT_NAME.HIGHLIGHT_SCREEN_TENANT_MENU, {
      modal_template: modalTemplate,
    })
    hideOnboarding()
    setIsHighlightScreenTenant(true)
  }

  const handleClickViewSampleReport = () => {
    tracker.trackEvent(TS_WIDGET_EVENT_NAME.clickViewSampleReport, {
      click_from: 'onboarding_partner_agent',
      modal_template: modalTemplate,
    })
    window.open('https://support.rentspree.com/en/a-completed-rentspree-application', '_blank', 'noopener,noreferrer')
  }

  useEffect(() => {
    setIsHighlightScreenTenant(false)
  }, [])
  // TODO: clean up feature flag and experiment
  return (
    modalTemplate === 'b' ? (<OnboardingPartnerAgentTemplateBModal
      isOpen={isOnboardingVisible}
      onClose={handleClose}
      onClickScreenTenants={handleClickScreenTenants}
      onClickViewSampleReport={handleClickViewSampleReport}
    />) : (<OnboardingPartnerAgentTemplateAModal
      isOpen={isOnboardingVisible}
      onClose={handleClose}
      onClickScreenTenants={handleClickScreenTenants}
      onClickViewSampleReport={handleClickViewSampleReport}
    />)
  )
}
