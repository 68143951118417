import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

import {
  PAYEE_IDV_AVAILABLE_STATE_STATUSES,
  PAYEE_IDV_STATUS,
  PAYOUT_METHOD_STATUS,
} from '../../constants'
import { makeSelectPayeeData, makeSelectPayoutMethodListData } from '../redux/selectors'

export const usePayoutCapability = () => {
  const payee = useSelector(makeSelectPayeeData())
  const payoutMethodList = useSelector(makeSelectPayoutMethodListData())

  const payeeIdvStatus = get(payee, 'identityVerification.status', '')
  const payoutMethodStatus = get(payoutMethodList, '[0].verification.status', '')

  return {
    taxInfoFinished: !isEmpty(payeeIdvStatus) && payeeIdvStatus !== PAYEE_IDV_STATUS.ONBOARDING,
    receivingAccountAdded:
      !isEmpty(payeeIdvStatus) &&
      payeeIdvStatus !== PAYEE_IDV_STATUS.PENDING_BANK_ACCOUNT &&
      !isEmpty(payoutMethodList),
    result:
      PAYEE_IDV_AVAILABLE_STATE_STATUSES.includes(payeeIdvStatus) &&
      payoutMethodStatus === PAYOUT_METHOD_STATUS.VERIFIED,
  }
}
