import get from "lodash/get"
import getHandleAuthorization from "@rentspree/axios-refresh-token"
import { DOMAIN_NAME, USER_API_URL } from "env"
import { sweetAlert, ALERT_PRESET } from "legacy/libs/sweet-alert/sweet-alert"
import { API_ERRORS } from "constants/error-messages"
import { redirectToLogin } from "legacy/helpers/location-href"
export const handleAuthorization = getHandleAuthorization({
  afterRetry: error => Promise.reject(get(error, "response", error)),
  afterRefreshTokenFailure: error =>
    // TODO: uncomment these logic if the error handler work incorrectly
    // store.dispatch(redirectAuthWeb(ROUTE.LOGIN))
    // removeAllLocalItem("rentspree", COOKIE_OPTION)
    Promise.reject(get(error, "response", error)),
  domain: DOMAIN_NAME,
  authAPIBase: USER_API_URL,
})

export async function handleESignAPIErrors(error) {
  const statusCode = get(error, "response.status")
  switch (statusCode) {
    case 401:
      sweetAlert(
        ALERT_PRESET.UNAUTHORIZED,
        API_ERRORS[401].option,
        API_ERRORS[401].callbackWithOutRedirect,
      )
      break
    default:
      window.location.assign("/not-found")
      break
  }
  return Promise.reject(error)
}

export async function handleApiErrors(error) {
  const statusCode = get(error, "status")
  switch (statusCode) {
    case 404:
      window.location.assign("/not-found")
      break
    case 401:
      if (window.location.pathname.includes("view")) {
        sweetAlert(
          ALERT_PRESET.UNAUTHORIZED,
          API_ERRORS[401].option,
          () => redirectToLogin(true), // in view document page, it will redirect with continue params
        )
      } else {
        sweetAlert(
          ALERT_PRESET.UNAUTHORIZED,
          API_ERRORS[401].option,
          API_ERRORS[401].callback,
        )
      }
      break
    case 500:
      sweetAlert(
        ALERT_PRESET.ERROR,
        API_ERRORS[500].option,
        API_ERRORS[500].callback,
      )
      break
    default:
      break
  }
  return Promise.reject(error)
}
