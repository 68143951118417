import { call, takeLatest, all, put } from "redux-saga/effects"
import { GET_IS_WHITELISTING_CALL } from "./constants"
import { getIsWhitelistingApi } from "./actions"
import { USER_WHITELISTING_API } from "../../../../constants/route"
import { UserApiInstance } from "../../../../utils/api-interceptor"

export const getIsWhitelistingAPI = () =>
  UserApiInstance.get(USER_WHITELISTING_API)

export function* getIsWhitelisting() {
  yield put(getIsWhitelistingApi.request())
  try {
    const response = yield call(getIsWhitelistingAPI)
    yield put(getIsWhitelistingApi.success(response.result))
  } catch (error) {
    yield put(getIsWhitelistingApi.failure(error))
  }
}

export function* watchGetIsWhitelisting() {
  yield takeLatest(GET_IS_WHITELISTING_CALL, getIsWhitelisting)
}

export function* rootSaga() {
  yield all([watchGetIsWhitelisting()])
}

export default rootSaga
