import React from "react"
import isEmpty from "lodash/isEmpty"
import { CenterContent, Loading } from "components/layout/main"

import { compose } from "redux"

import { DisableFeatureProvider } from "@rentspree/feature-toggle"
import disableFeature from "utils/feature-toggle/instance"

import { withSaga as withContactSaga } from "containers/contacts/connect"
import WrapperConnect from "./wrapper-connect"
import ProRestrictionsModal from "../../components/modal/pro-restrictions-modal"
import { MODAL_NAME } from "../../constants/modal-consts"
import locationHref from "../../helpers/location-href"
import { VideoModal } from "../../components/modal/video-modal"
import {
  withReducer as withMessagingReducer,
  withSaga as withMessagingSaga,
} from "../../../containers/messaging/connect"
import {
  withReducer as withCheckWhiteListingReducer,
  withSaga as withCheckWhiteListingSaga,
} from "../../../containers/reports/screening-reports/with-open/connect"
import { DASHBOARD_V1_URL } from "../../../env"
import { USER_TYPE, REGISTERED_USER_TYPE } from "../../../constants/user"

import {
  WATCH_TUTORIAL_VIDEO,
  VIEW_PROCESS_VIDEO,
  WATCH_TUTORIAL_VIDEO_FOR_CAA,
  VIEW_PROCESS_VIDEO_FOR_CAA,
} from "../static-page/getting-started-const"

import {
   withSaga as withProSaga, 
   withReducer as withProReducer
 } from "../pro/landing/connect"

export class Wrapper extends React.Component {
  async componentWillMount() {
    const {
      actions,
      profile: { profile },
      products
    } = this.props

    if (!isEmpty(profile)) {
      if (profile.userType === USER_TYPE.RENTER) {
        actions.redirectCall()
        locationHref(DASHBOARD_V1_URL)
      } else {
        actions.redirectCancel()
        await actions.getUserIntegrations()
        actions.initiateTalkJs()
      }
    }
    if (!products.loaded && !products.loading) {
      actions.getSubscriptionProduct()
    }
  }

  componentDidUpdate() {
    const {
      actions,
      profile: { profile },
    } = this.props

    const isRequireRenterEmailVerification = profile?.registeredUserType === REGISTERED_USER_TYPE.RENTER && profile?.userType === USER_TYPE.LANDLORD && !profile?.isEmailVerified
    
    if (isRequireRenterEmailVerification) {
      actions.redirectCall()
      locationHref('/account/renter-verification/email')
    }
  }

  addEmailForHubspot() {
    const { profile } = this.props
    try {
      document.querySelector("input[name='email']").value =
        profile.profile.email
    } catch (e) {
      console.error("Error update email form hubspot=", e)
    }
  }

  closeModal = modal => {
    switch (modal) {
      case MODAL_NAME.VIDEO_TUTORIAL:
        return this.props.actions.toggleVideoTutorialModal(false)
      case MODAL_NAME.VIDEO_VIEW_PROCESS:
        return this.props.actions.toggleVideoViewProcessModal(false)
      case MODAL_NAME.PRO_RESTRICTIONS:
        return this.props.actions.toggleProRestrictionsModal(false)
      case MODAL_NAME.GETTING_STARTED:
        return this.props.actions.toggleGettingStartedModal(false)
      case MODAL_NAME.WATCH_VIDEO:
        return this.props.actions.toggleWatchVideoModal({
          isShow: false,
          video: { title: null, link: null },
        })
      default:
        return false
    }
  }

  render() {
    const {
      children,
      id,
      modals,
      isNotRedirect,
      subdomain,
      explore,
    } = this.props
    if (!isNotRedirect) {
      return (
        <div id={id}>
          <CenterContent>
            <Loading />
          </CenterContent>
        </div>
      )
    }
    return (
      <DisableFeatureProvider
        subdomain={subdomain || "rentspree"}
        disableFeature={disableFeature}>
        <div id={id}>
          {children}
          <VideoModal
            id="videoTutorialModal"
            showModal={modals.showVideoTutorialModal}
            closeModal={() => this.closeModal(MODAL_NAME.VIDEO_TUTORIAL)}
            closeButton
            video={{
              title: "How it works",
              link:
                subdomain === "caa"
                  ? WATCH_TUTORIAL_VIDEO_FOR_CAA
                  : WATCH_TUTORIAL_VIDEO,
            }}
          />
          <VideoModal
            id="VideoViewProcessModal"
            showModal={modals.showVideoViewProcessModal}
            closeModal={() => this.closeModal(MODAL_NAME.VIDEO_VIEW_PROCESS)}
            closeButton
            video={{
              title: "What renters see",
              link:
                subdomain === "caa"
                  ? VIEW_PROCESS_VIDEO_FOR_CAA
                  : VIEW_PROCESS_VIDEO,
            }}
          />
          <VideoModal
            id="WatchVideoModal"
            showModal={explore.isShow}
            closeModal={() => this.closeModal(MODAL_NAME.WATCH_VIDEO)}
            closeButton
            video={explore.video}
          />
          <ProRestrictionsModal
            id="proRestrictionsModal"
            showModal={modals.showProRestrictionsModal}
            closeModal={() => this.closeModal(MODAL_NAME.PRO_RESTRICTIONS)}
          />
        </div>
      </DisableFeatureProvider>
    )
  }
}

export default compose(
  WrapperConnect,
  withMessagingReducer,
  withMessagingSaga,
  withCheckWhiteListingReducer,
  withCheckWhiteListingSaga,
  withContactSaga,
  withProSaga,
  withProReducer,
)(Wrapper)
