import React from 'react'
import Styled from 'styled-components'
import Button from '@rentspree/component-2023.components.atoms.button'
import { Sheet, SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Link from '@rentspree/component-2023.components.atoms.link'
import IncomeVerificationAnnouncement from '../../../../images/tenant-screening/income-verification-announcement.svg'

const UnderlineLink = Styled(Link)`
  text-decoration: underline !important;
`

const SheetContainer = Styled.div`
  margin: 20px 0 20px 20px;
`

const StyledSheet = Styled(Sheet)`
  &#income-verification-announcement-sheet > div.MuiPaper-root > div.contentContainer {
    height: 100%;
  }
`

const GetReportCtaButtons = ({ text, onClick }) => (
  <Button style={{ width: '100%' }} variant="contained" color="secondary" onClick={onClick}>
    {text}
  </Button>
)

const AnnouncementSheet = ({
  isOpen,
  detailText,
  onClickCTAGetReport,
  onClose,
  onViewSampleReport,
}) => (
  <StyledSheet
    actionSection={<GetReportCtaButtons text={detailText.CTA_TEXT} onClick={onClickCTAGetReport} />}
    open={isOpen}
    onClose={onClose}
    id="income-verification-announcement-sheet"
  >
    <img
      width="812px"
      height="auto"
      srcSet={IncomeVerificationAnnouncement}
      src={IncomeVerificationAnnouncement}
      alt="Surfing"
      loading="lazy"
    />
    <SheetHeader
      header={
        <Typography variant="title-medium" style={{ marginBottom: '10px' }}>
          {detailText.HEADER_TEXT}
        </Typography>
      }
      subHeader={<Typography variant="body-medium">{detailText.SUBHEADER_TEXT}</Typography>}
    />
    <SheetContainer>
      {detailText.DESCRIPTIONS.map(element => (
        <li>
          <Typography variant="body-medium">{element}</Typography>
        </li>
      ))}
      <div style={{ marginTop: '10px' }}>
        <UnderlineLink color="black" onClick={onViewSampleReport}>
          {detailText.SAMPLE_REPORT_CTA_TEXT}
        </UnderlineLink>
      </div>
    </SheetContainer>
  </StyledSheet>
)

export default AnnouncementSheet
