export const BUTTON_NAME = {
  ADD_PROPERTY: 'Add property',
  NEXT: 'Next',
  BACK: 'Back',
  CANCEL: 'Cancel',
}

export const TITLE_PAGE_NAME = {
  PROPERTY_ADDRESS: 'Property address',
  USER_ROLE_FOR_PROPERTY: "What's your role for this property?",
}

export const PAGE_NAME = {
  PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
  ROLE: 'ROLE',
}

export const SUB_HEADER = {
  ADD_NEW_PROPERTY: 'Add new property',
}
