import dayjs from "dayjs"

export const checkDateIsValid = date =>
  date ? !Number.isNaN(new Date(date).getTime()) : false

export const isExpired = date => {
  if (!checkDateIsValid(date)) return false
  if (dayjs().isAfter(dayjs(date))) return true

  return false
}
