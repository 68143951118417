import React from "react"
import styled from "styled-components"
import classnames from "classnames"
import { COLOR } from "styles/settings"
import { PositionWrapper } from "../text-box"
import { rgbaColor, contentColor } from "../colors"
import { makeMapCursor } from "../cursor"

const mapCursor = makeMapCursor("pointer")

export const CheckboxLabel = styled.label`
  display: block;
  position: relative;
  cursor: ${props => props.cursor || "default"};
  user-select: none;

  /* Hide checkmark when not checked */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  :hover {
    .hovered-bg {
      background: ${props =>
        props.hoverColor || `rgba(${COLOR.lightBlueRGBA})`};
    }
  }

  .active {
    background: ${props => props.hoverColor || `rgba(${COLOR.lightBlueRGBA})`};
  }
`

export const Checkmark = styled.span`
  /* Custom checkbox style */
  position: absolute;
  top: 0;
  left: 0;
  height: ${props => (props.small ? "10px" : "18px")};
  width: ${props => (props.small ? "10px" : "18px")};
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid ${props => props.color || COLOR.fontBlue};
  border-radius: 3px;
  transform-origin: 0% 0%;
  transform: ${props => `scale(${props.ratio}, ${props.ratio})`};
  /* Custom checkmark style */
  :after {
    left: ${props => (props.small ? "3px" : "6px")};
    top: ${props => (props.small ? "-3.5px" : "-4.5px")};
    width: ${props => (props.small ? "4.7px" : "9.7px")};
    height: ${props => (props.small ? "9.5px" : "17.4px")};
    border: solid ${props => props.color || COLOR.fontBlue};
    border-width: ${props => (props.small ? "0 2px 2px 0" : "0 4px 4px 0")};
    transform: rotate(45deg);
  }
`

export const StyledCheckbox = styled.input.attrs(() => ({
  type: "checkbox",
  tabIndex: "-1",
}))`
  /* Hide default checkbox */
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const HoveredBg = styled.div`
  transform-origin: 0% 0%;
  transform: ${props => `scale(${props.ratio}, ${props.ratio})`};
  width: ${props => (props.small ? "20px" : "36px")};
  height: ${props => (props.small ? "20px" : "36px")};
  top: ${props =>
    props.small ? `${-5 * props.ratio}px` : `${-9 * props.ratio}px`};
  left: ${props =>
    props.small ? `${-5 * props.ratio}px` : `${-9 * props.ratio}px`};
  background: transparent;
  position: absolute;
  transition: all, 0.5s;
  border-radius: 50px;
`

export const Checkbox = ({
  targetRef,
  left,
  top,
  fieldId,
  value = "true",
  onClick,
  onChangeData,
  canEdit,
  viewMode,
  isDragging,
  colorIndex,
  active,
  unmovable,
  hidden,
  small,
  ratio,
}) => {
  if (hidden) return null
  if (isDragging) return <div ref={targetRef} />

  const valueBool = value === "true"

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    if (active || canEdit) {
      const data = !valueBool
      onChangeData(data.toString())
    } else if (!viewMode) onClick()
  }
  const checkmarkColor = contentColor(colorIndex, COLOR.placeholderGrey)
  const hoverColor = rgbaColor(colorIndex, COLOR.bgGreyRGB)
  const cursor = mapCursor(viewMode, canEdit, active, unmovable)

  return (
    <PositionWrapper left={left * ratio} top={top * ratio}>
      <CheckboxLabel // TODO : adjust to use checkbox component from atoms
        ref={targetRef}
        id={fieldId}
        key={fieldId}
        checked={valueBool}
        cursor={cursor}
        onClick={handleClick}
        hoverColor={hoverColor}>
        <HoveredBg
          ratio={ratio}
          className={classnames({
            active: !viewMode && active,
            "hovered-bg": !viewMode || canEdit,
          })}
          small={small}
        />
        <StyledCheckbox checked={valueBool} />
        <Checkmark
          ratio={ratio}
          className="checkmark"
          color={viewMode && !canEdit ? COLOR.placeholderGrey : checkmarkColor}
          small={small}
        />
      </CheckboxLabel>
    </PositionWrapper>
  )
}
