import generateApiCall from 'utils/generate-api-call'
import {
  GET_SCREENING_PLANS_CALL,
  GET_SCREENING_PLANS_REQUEST,
  GET_SCREENING_PLANS_SUCCESS,
  GET_SCREENING_PLANS_FAILURE,
  SELECT_PAYER_TYPE,
  CREATE_SCREENING_REQUEST_CALL,
  CREATE_SCREENING_REQUEST_REQUEST,
  CREATE_SCREENING_REQUEST_SUCCESS,
  CREATE_SCREENING_REQUEST_FAILURE,
  ACCEPT_BACKGROUND_DISCLAIMER,
  SELECT_BUNDLE_TYPE,
  SELECT_APPLICATION_TYPE,
  GET_PROPERTY_CALL,
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_FAILURE,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_CALL,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_REQUEST,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_SUCCESS,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_FAILURE,
  UPDATE_REQUEST_STEP_CONTROL,
  SELECT_CREDIT_REPORT_ITEMS,
  UPDATE_REF_CHECK_PRO_OPTION,
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
  GET_USER_PREFERENCE_CALL,
  UPDATE_USER_PREFERENCE_REQUEST,
  UPDATE_USER_PREFERENCE_SUCCESS,
  UPDATE_USER_PREFERENCE_FAILURE,
  UPDATE_USER_PREFERENCE_CALL,
  GENERATE_PERMALINK_REQUESTED,
  GENERATE_PERMALINK_SUCCESS,
  GENERATE_PERMALINK_FAILED,
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
  SET_SOURCE,
  CLEAR_REQUEST_OPTIONS,
  CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS,
  CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS,
  CLEAR_PROPERTY,
  GET_SCREENING_REQUEST_LANDING_REQUESTED,
  GET_SCREENING_REQUEST_LANDING_SUCCESS,
  GET_SCREENING_REQUEST_LANDING_FAILED,
  GET_SCREENING_REQUEST_LANDING_CALL,
  UPDATE_INCOME_VERIFICATION_OPTION,
  UPDATE_DOCUMENT_PRO_OPTION,
  SAVE_CUSTOM_DOCUMENT,
  UPDATE_USER_PLAN,
} from './constants'

export const getScreeningPlans = () => ({
  type: GET_SCREENING_PLANS_CALL,
})

export const getScreeningPlansApiState = generateApiCall([
  GET_SCREENING_PLANS_REQUEST,
  GET_SCREENING_PLANS_SUCCESS,
  GET_SCREENING_PLANS_FAILURE,
])

export const getProperty = payload => ({
  type: GET_PROPERTY_CALL,
  payload,
})

export const getPropertyApiState = generateApiCall([
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_FAILURE,
])

export const createScreeningRequest = payload => ({
  type: CREATE_SCREENING_REQUEST_CALL,
  payload,
})

export const createScreeningRequestApiState = generateApiCall([
  CREATE_SCREENING_REQUEST_REQUEST,
  CREATE_SCREENING_REQUEST_SUCCESS,
  CREATE_SCREENING_REQUEST_FAILURE,
])

export const createScreeningRequestWithoutProperty = payload => ({
  type: CREATE_SCREENING_REQUEST_WO_PROPERTY_CALL,
  payload,
})

export const createScreeningRequestWithoutPropertyApiState = generateApiCall([
  CREATE_SCREENING_REQUEST_WO_PROPERTY_REQUEST,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_SUCCESS,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_FAILURE,
])
export const getUserPreferenceApiState = generateApiCall([
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
])

export const updateUserPreferenceApiState = generateApiCall([
  UPDATE_USER_PREFERENCE_REQUEST,
  UPDATE_USER_PREFERENCE_SUCCESS,
  UPDATE_USER_PREFERENCE_FAILURE,
])

export const generatePermalinkRequestActions = generateApiCall([
  GENERATE_PERMALINK_REQUESTED,
  GENERATE_PERMALINK_SUCCESS,
  GENERATE_PERMALINK_FAILED,
])

export const createListingScreeningRequestActions = generateApiCall([
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
])

export const getScreeningRequestLandingActions = generateApiCall([
  GET_SCREENING_REQUEST_LANDING_REQUESTED,
  GET_SCREENING_REQUEST_LANDING_SUCCESS,
  GET_SCREENING_REQUEST_LANDING_FAILED,
])

export const setSource = payload => ({
  type: SET_SOURCE,
  payload,
})

export const getUserPreference = () => ({
  type: GET_USER_PREFERENCE_CALL,
})

export const updateUserPreference = payload => ({
  type: UPDATE_USER_PREFERENCE_CALL,
  payload,
})

export const saveCustomDocument = payload => ({
  type: SAVE_CUSTOM_DOCUMENT,
  payload,
})

export const selectPayer = payload => ({
  type: SELECT_PAYER_TYPE,
  payload,
})

export const selectBundle = payload => ({
  type: SELECT_BUNDLE_TYPE,
  payload,
})

export const selectApplication = payload => ({
  type: SELECT_APPLICATION_TYPE,
  payload,
})

export const selectCreditReportItems = payload => ({
  type: SELECT_CREDIT_REPORT_ITEMS,
  payload,
})

export const updateRequestStepControl = payload => ({
  type: UPDATE_REQUEST_STEP_CONTROL,
  payload,
})

export const acceptedBackgroundDisclaimer = payload => ({
  type: ACCEPT_BACKGROUND_DISCLAIMER,
  payload,
})

export const updateDocument = payload => ({
  type: UPDATE_DOCUMENT_PRO_OPTION,
  payload,
})

export const updateReferenceCheck = () => ({
  type: UPDATE_REF_CHECK_PRO_OPTION,
})

export const updateIncomeVerification = () => ({
  type: UPDATE_INCOME_VERIFICATION_OPTION,
})

export const updateUserPlan = payload => ({
  type: UPDATE_USER_PLAN,
  payload,
})

export const clearRequestOptions = () => ({
  type: CLEAR_REQUEST_OPTIONS,
})

export const clearScreeningWithoutPropertyProOptions = () => ({
  type: CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS,
})

export const clearScreeningWithPropertyProOptions = () => ({
  type: CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS,
})

export const clearProperty = payload => ({
  type: CLEAR_PROPERTY,
  payload,
})

export const getScreeningLandingRequest = payload => ({
  type: GET_SCREENING_REQUEST_LANDING_CALL,
  payload,
})
