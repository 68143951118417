import styled, { css } from "styled-components"
import { COLOR, FLEX_CENTER } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const inputGroup = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.flexGrow && `flex-grow: ${props.flexGrow}`};
`
// language=SCSS prefix=dummy{ suffix=}
export const input = styled.input`
  height: ${props => (props.height ? `${props.height}px` : "35px")};
  border: ${props =>
    props.isError
      ? `1px solid ${COLOR.negativeRed}`
      : `1px solid ${COLOR.lineGrey}`};
  border-radius: 4px;
  width: 100%;
  padding: 0 40px 0 10px;
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  &::-webkit-input-placeholder {
    color: ${COLOR.placeholderGrey} !important;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const errorMessage = styled.div`
  margin-top: 7px;
`

export const inputImg = styled.img`
  position: absolute;
  top: 8px;
  right: 5px;
  width: 30px;
`
export const inputIcon = styled(inputImg).attrs(() => ({ as: "i" }))`
  &.input-icon {
    ${FLEX_CENTER};
    font-size: 14px;
    width: 20px;
    height: 20px;
    top: 12px;
    right: 10px;
  }
  &.fa-times {
    color: white;
    background: ${COLOR.negativeRed};
    border-radius: 50px;
  }
  &.fa-check {
    color: white;
    background: ${COLOR.darkGreen};
    border-radius: 50px;
  }
`
