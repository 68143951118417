import { useMemo } from "react"
import { getRentPaymentId, getPathObjectId } from "./helpers"

export default function usePathIdParams(pathname) {
  const pathIdParams = useMemo(
    () => ({
      propertyId: getPathObjectId("propertyId", pathname),
      rentalAppId: getPathObjectId("rentalAppId", pathname),
      leaseAgreementId: getPathObjectId("leaseAgreementId", pathname),
      rentersInsuranceId: getPathObjectId("rentersInsuranceId", pathname),
      submissionParticipantId: getPathObjectId(
        "submissionParticipantId",
        pathname,
      ),
      rentPaymentId: getRentPaymentId(pathname),
      contactId: getPathObjectId("contactId", pathname),
    }),
    [pathname],
  )
  return pathIdParams
}
