import { css } from "styled-components"
import { ANIMATE, COLOR } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const defaultBtn = css`
  background-color: ${COLOR.newGrey};
  border: none;
  color: ${COLOR.newBlack};
  &:hover {
    background-color: ${COLOR.lineGrey};
    color: ${COLOR.newBlack};
  }
  &:disabled {
    transition: none;
    background-color: ${COLOR.textGrey};
    border: none;
    color: white;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const blueBtn = css`
  background-color: ${COLOR.deepBlue};
  border: 1px solid ${COLOR.deepBlue};
  color: white;
  &:hover,
  &:focus {
    background-color: ${COLOR.darkDeepBlue};
    border: 1px solid ${COLOR.darkDeepBlue};
    color: white;
  }
  &:disabled {
    transition: none;
    background-color: ${COLOR.textGrey};
    border: 1px solid ${COLOR.textGrey};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const greenBtn = css`
  color: white;
  background-color: ${COLOR.green};
  border: 1px solid ${COLOR.green};
  &:hover,
  &:focus {
    color: white;
    background-color: ${COLOR.darkGreen};
    border: 1px solid ${COLOR.darkGreen};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const whiteBtn = css`
  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.bgGrey};
  color: ${COLOR.black};
  &:hover,
  &:focus {
    color: ${COLOR.titleBlack};
    background-color: ${COLOR.bgGrey};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const redBtn = css`
  background-color: ${COLOR.negativeRed};
  border: 1px solid ${COLOR.negativeRed};
  color: ${COLOR.white};
  &:hover,
  &:focus {
    color: ${COLOR.white};
    background-color: ${COLOR.darkNegativeRed};
    border: 1px solid ${COLOR.darkNegativeRed};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const transparentBtn = css`
  background-color: transparent;
  border: 1px solid ${COLOR.lineGrey};
  color: ${COLOR.black};
  transition: none;
  &:hover {
    transition: all ease-in-out ${ANIMATE.fast};
    color: ${COLOR.black};
    background-color: #f1f1f1;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const transparentBlueBtn = css`
  background-color: transparent;
  border: 1px solid ${COLOR.deepBlue};
  color: ${COLOR.deepBlue};
  &:hover,
  &:focus {
    background-color: ${COLOR.deepBlue};
    color: ${COLOR.white};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const transparentDarkGreenBtn = css`
  border: 1px solid ${COLOR.darkGreen};
  color: ${COLOR.darkGreen};
  &:hover,
  &:focus {
    background-color: ${COLOR.darkGreen};
    color: ${COLOR.white};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const textBtn = css`
  background-color: transparent;
  border: none;
  color: ${COLOR.black};
  transition: none;
  &:hover {
    color: ${COLOR.black};
    transition: all ease-in-out ${ANIMATE.fast};
  }
`
