import produce from 'immer'

import {
  GET_CREDIT_REPORT_REQUEST,
  GET_CREDIT_REPORT_SUCCESS,
  GET_CREDIT_REPORT_FAILED,
  CLEAR_CREDIT_REPORT_STATE,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILED,
  GET_REFERENCE_CHECK_REQUEST,
  GET_REFERENCE_CHECK_SUCCESS,
  GET_REFERENCE_CHECK_FAILED,
} from 'legacy/constants/action-types'
import {
  GET_INCOME_VERIFICATION_REPORT_REQUEST,
  GET_INCOME_VERIFICATION_REPORT_SUCCESS,
  GET_INCOME_VERIFICATION_REPORT_FAILURE,
} from 'containers/reports/screening-reports/income-verification/constants'
import {
  FETCHED_CREDIT_REPORT_IMAGE,
  FETCHED_CRIMINAL_IMAGE,
  FETCHED_EVICTION_IMAGE,
} from 'containers/reports/screening-reports/constants'
import {
  CLEAR_APPLICATION_DETAIL,
  RENDER_PDF_SUCCESS,
} from 'containers/reports/application/constants'
import { CLEAR_REFERENCE_CHECKS } from 'containers/reports/reference-checks/constants'

export const initialState = {
  isFetchedApplication: false,
  isFetchedCreditReport: false,
  isFetchedCreditReportImage: false,
  isFetchedCriminalImage: false,
  isFetchedEvictionImage: false,
  isFetchedReferenceChecks: false,
  isFetchedApplicationDocuments: false,
  isFetchedIncomeVerificationReport: false,
}

/* eslint-disable default-case, no-param-reassign */
const printReducer = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case GET_APPLICATION_SUCCESS:
        draft.isFetchedApplication = true
        break
      case GET_APPLICATION_REQUEST:
      case GET_APPLICATION_FAILED:
        draft.isFetchedApplication = false
        break
      case GET_REFERENCE_CHECK_SUCCESS:
        draft.isFetchedReferenceChecks = true
        break
      case GET_REFERENCE_CHECK_FAILED:
      case GET_REFERENCE_CHECK_REQUEST:
        draft.isFetchedReferenceChecks = false
        break
      case GET_CREDIT_REPORT_SUCCESS:
        draft.isFetchedCreditReport = true
        break
      case FETCHED_CREDIT_REPORT_IMAGE:
        draft.isFetchedCreditReportImage = true
        break
      case FETCHED_CRIMINAL_IMAGE:
        draft.isFetchedCriminalImage = true
        break
      case FETCHED_EVICTION_IMAGE:
        draft.isFetchedEvictionImage = true
        break
      case GET_CREDIT_REPORT_REQUEST:
      case GET_CREDIT_REPORT_FAILED:
      case CLEAR_CREDIT_REPORT_STATE:
        draft.isFetchedCreditReport = false
        draft.isFetchedCreditReportImage = false
        draft.isFetchedCriminalImage = false
        draft.isFetchedEvictionImage = false
        break
      case CLEAR_APPLICATION_DETAIL:
        draft.isFetchedApplication = false
        break
      case CLEAR_REFERENCE_CHECKS:
        draft.isFetchedReferenceChecks = false
        break
      case RENDER_PDF_SUCCESS:
        draft.isFetchedApplicationDocuments = true
        break
      case GET_INCOME_VERIFICATION_REPORT_REQUEST:
      case GET_INCOME_VERIFICATION_REPORT_FAILURE:
        draft.isFetchedIncomeVerificationReport = false
        break
      case GET_INCOME_VERIFICATION_REPORT_SUCCESS:
        draft.isFetchedIncomeVerificationReport = true
        break
    }
  })

export default printReducer
