import { TextBox } from 'components/drag-and-drop/text-box'
import { SignBox } from 'components/drag-and-drop/sign-box'
import { InitialBox } from 'components/drag-and-drop/initial-box'
import { DateSignBox } from 'components/drag-and-drop/date-sign-box'
import { Checkbox } from 'components/drag-and-drop/check-box'
import { TYPES_COMPONENT } from '../drag-n-drop/constants'

export const GET_BASE64_FILES_CALL = 'GET_BASE64_FILES_CALL'
export const GET_BASE64_FILES_REQUEST = 'GET_BASE64_FILES_REQUEST'
export const GET_BASE64_FILES_SUCCESS = 'GET_BASE64_FILES_SUCCESS'
export const GET_BASE64_FILES_FAILURE = 'GET_BASE64_FILES_FAILURE'

export const ENVELOPE_CALL = 'ENVELOPE_CALL'
export const ENVELOPE_REQUEST = 'ENVELOPE_REQUEST'
export const ENVELOPE_SUCCESS = 'ENVELOPE_SUCCESS'
export const ENVELOPE_FAILURE = 'ENVELOPE_FAILURE'
export const GET_ENVELOPE_CALL = 'GET_ENVELOPE_CALL'
export const GET_ENVELOPE_CALL_REQUEST = 'GET_ENVELOPE_CALL_REQUEST'
export const GET_ENVELOPE_CALL_SUCCESS = 'GET_ENVELOPE_CALL_SUCCESS'
export const GET_ENVELOPE_CALL_FAILURE = 'GET_ENVELOPE_CALL_FAILURE'

export const UPDATE_ENVELOPE_CALL = 'UPDATE_ENVELOPE_CALL'
export const UPDATE_ENVELOPE_REQUEST = 'UPDATE_ENVELOPE_REQUEST'
export const UPDATE_ENVELOPE_SUCCESS = 'UPDATE_ENVELOPE_SUCCESS'
export const UPDATE_ENVELOPE_FAILED = 'UPDATE_ENVELOPE_FAILED'

export const UPDATE_ENVELOPE_SIGN_CALL = 'UPDATE_ENVELOPE_SIGN_CALL'
export const UPDATE_ENVELOPE_SIGN_REQUEST = 'UPDATE_ENVELOPE_SIGN_REQUEST'
export const UPDATE_ENVELOPE_SIGN_SUCCESS = 'UPDATE_ENVELOPE_SIGN_SUCCESS'
export const UPDATE_ENVELOPE_SIGN_FAILED = 'UPDATE_ENVELOPE_SIGN_FAILED'

export const SHARE_ENVELOPE_CALL = 'SHARE_ENVELOPE_CALL'
export const SHARE_ENVELOPE_REQUEST = 'SHARE_ENVELOPE_REQUEST'
export const SHARE_ENVELOPE_SUCCESS = 'SHARE_ENVELOPE_SUCCESS'
export const SHARE_ENVELOPE_FAILED = 'SHARE_ENVELOPE_FAILED'
export const BACK_TO_LEASE_TEXT = 'Back to transaction'

export const DATE_SIGNED_SAVE = 'DATE_SIGNED_SAVE'
export const SIGN_SIGNATURE_SAVE = 'SIGN_SIGNATURE_SAVE'
export const SIGN_SIGNATURE_EDIT = 'SIGN_SIGNATURE_EDIT'
export const UPLOAD_SIGN_SIGNATURE = 'UPLOAD_SIGN_SIGNATURE'

export const UPLOAD_SIGNATURE_REQUEST = 'UPLOAD_SIGNATURE_REQUEST'
export const UPLOAD_SIGNATURE_SUCCESS = 'UPLOAD_SIGNATURE_SUCCESS'
export const UPLOAD_SIGNATURE_FAILED = 'UPLOAD_SIGNATURE_FAILED'

export const SAVE_FILES_ENVELOPE = 'SAVE_FILES_ENVELOPE'
export const SAVE_FILES_ENVELOPE_REQUEST = 'SAVE_FILES_ENVELOPE_REQUEST'
export const SAVE_FILES_ENVELOPE_SUCCESS = 'SAVE_FILES_ENVELOPE_SUCCESS'
export const SAVE_FILES_ENVELOPE_FAILED = 'SAVE_FILES_ENVELOPE_FAILED'

export const SAVE_BUFFER_FILES_ENVELOPE = 'SAVE_BUFFER_FILES_ENVELOPE'
export const SAVE_BUFFER_FILES_ENVELOPE_REQUEST = 'SAVE_BUFFER_FILES_ENVELOPE_REQUEST'
export const SAVE_BUFFER_FILES_ENVELOPE_SUCCESS = 'SAVE_BUFFER_FILES_ENVELOPE_SUCCESS'
export const SAVE_BUFFER_FILES_ENVELOPE_FAILED = 'SAVE_BUFFER_FILES_ENVELOPE_FAILED'
export const REMOVE_ACTIVE_BOX = 'REMOVE_ACTIVE_BOX'

export const GENERATE_PDF_FILE_URL = 'GENERATE_PDF_FILE_URL'
export const GENERATE_PDF_FILE_URL_REQUEST = 'GENERATE_PDF_FILE_URL_REQUEST'
export const GENERATE_PDF_FILE_URL_SUCCESS = 'GENERATE_PDF_FILE_URL_SUCCESS'
export const GENERATE_PDF_FILE_URL_FAILED = 'GENERATE_PDF_FILE_URL_FAILED'

export const UPDATE_ENVELOPE_CONSENT_CALL = 'UPDATE_ENVELOPE_CONSENT_CALL'
export const UPDATE_ENVELOPE_CONSENT_REQUEST = 'UPDATE_ENVELOPE_CONSENT_REQUEST'
export const UPDATE_ENVELOPE_CONSENT_SUCCESS = 'UPDATE_ENVELOPE_CONSENT_SUCCESS'
export const UPDATE_ENVELOPE_CONSENT_FAILED = 'UPDATE_ENVELOPE_CONSENT_FAILED'

export const AUTOSAVE_ENVELOPE_CALL = 'AUTOSAVE_ENVELOPE_CALL'
export const AUTOSAVE_ENVELOPE_REQUEST = 'AUTOSAVE_ENVELOPE_REQUEST'
export const AUTOSAVE_ENVELOPE_SUCCESS = 'AUTOSAVE_ENVELOPE_SUCCESS'
export const AUTOSAVE_ENVELOPE_FAILURE = 'AUTOSAVE_ENVELOPE_FAILURE'
export const QUEUE_AUTOSAVE = 'QUEUE_AUTOSAVE'
export const SET_IS_AUTOSAVING = 'SET_IS_AUTOSAVING'
export const SET_RECIPIENT_ID_SELECTED = 'SET_RECIPIENT_ID_SELECTED'

export const SHOW_CONSENT_MODAL = 'SHOW_CONSENT_MODAL'
export const EXCHANGE_TOKEN_CALL = 'EXCHANGE_TOKEN_CALL'

export const UPDATE_ENVELOPE_TEMPLATE_CALL = 'UPDATE_ENVELOPE_TEMPLATE_CALL'
export const UPDATE_ENVELOPE_TEMPLATE_REQUEST = 'UPDATE_ENVELOPE_TEMPLATE_REQUEST'
export const UPDATE_ENVELOPE_TEMPLATE_SUCCESS = 'UPDATE_ENVELOPE_TEMPLATE_SUCCESS'
export const UPDATE_ENVELOPE_TEMPLATE_FAILED = 'UPDATE_ENVELOPE_TEMPLATE_FAILED'

export const UPDATE_CONSENT_SELECT_OPTION_CALL = 'UPDATE_CONSENT_SELECT_OPTION_CALL'
export const UPDATE_LEASE_AGREEMENT_CONSENT_CALL = 'UPDATE_LEASE_AGREEMENT_CONSENT_CALL'
export const UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST = 'UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST'
export const UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS = 'UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS'
export const UPDATE_LEASE_AGREEMENT_CONSENT_FAILED = 'UPDATE_LEASE_AGREEMENT_CONSENT_FAILED'

export const CLEAR_LEASE_ERROR = 'CLEAR_LEASE_ERROR'
export const RESET_ENVELOPE_REDUCER = 'RESET_ENVELOPE_REDUCER'

export const FIT_BUTTON = {
  FIT_TO_PAGE: {
    text: 'Fit to page',
    icon: 'full-page',
    track: 'fit_to_page',
  },
  FIT_TO_WIDTH: {
    text: 'Fit to width',
    icon: 'full-width',
    track: 'fit_to_width',
  },
}

export const STANDARD_FIELDS = [
  {
    field: 'text',
    icon: 'text-icon',
    label: 'Text',
    type: TYPES_COMPONENT.TEXT_BOX,
    element: TextBox,
    isInterActiveType: true,
  },
  {
    field: 'checkbox',
    icon: 'checkbox-icon',
    label: 'Checkbox',
    type: TYPES_COMPONENT.CHECK_BOX,
    element: Checkbox,
    isInterActiveType: true,
  },
  {
    field: 'initial',
    icon: 'rp-icon',
    label: 'Initial',
    type: TYPES_COMPONENT.INITIAL_SIGNS,
    element: InitialBox,
    isInterActiveType: true,
  },
  {
    field: 'signature',
    icon: 'pencil-icon',
    label: 'Signature',
    type: TYPES_COMPONENT.SIGNATURE_SIGNS,
    element: SignBox,
    isInterActiveType: true,
  },
  {
    field: 'date',
    icon: 'calendar-icon',
    label: 'Date Signed',
    type: TYPES_COMPONENT.DATE_SIGNS,
    element: DateSignBox,
    isInterActiveType: true,
  },
  // {
  //   field: "address",
  //   icon: "text-icon",
  //   label: "Address",
  //   type: TYPES_COMPONENT.ADDRESS,
  // },
]

export const CUSTOM_FIELDS = [
  {
    field: 'text',
    icon: 'text-icon',
    label: 'First Name',
  },
  {
    field: 'text',
    icon: 'text-icon',
    label: 'Middle Name',
  },
  {
    field: 'text',
    icon: 'text-icon',
    label: 'Last Name',
  },
  {
    field: 'text',
    icon: 'text-icon',
    label: 'Email',
  },
  {
    field: 'text',
    icon: 'text-icon',
    label: 'Rent Deposit',
  },
]

export const ENVELOPE_STATUS = {
  CREATED: 'created',
  INPROGRESS: 'inprogress',
  PENDING: 'pending',
  COMPLETED: 'completed',
  VOID: 'void',
}

export const RECIPIENT_STATUS = {
  PENDING: 'pending',
  SIGNED: 'signed',
  UNSIGNED: 'unsigned',
}

export const RECIPIENT_WORD = {
  WAITING_SIGNATURE: 'Wait for signature',
  SIGNED: 'Signed',
  UNSIGNED: 'Not signed',
  DEFAULT: 'Lease not sent'
}

export const ENVELOPE_UPDATE_STEP = {
  UPLOAD_FILES_STEP: 'uploadFiles',
  RECIPIENTS_STEP: 'recipients',
  SIGN_COMPONENTS_STEP: 'signComponents',
}

export const CAR_POP_UNDER_MODAL = {
  title: 'Document is now ready',
  text: 'Click continue to proceed (will open in new tab)',
  button: 'Continue',
}

export const CAR_EXIT_URL = 'https://www.car.org/rentspreeexit'

export const DOCUMENT_LIST_ID = 'documentList'

export const DATE_FIELD_FORMAT = '{{DATE}}'
export const TIME_FIELD_FORMAT = '{{TIME}}'
