import { USER_ROLE_FOR_PROPERTY } from "containers/request/step-create-property/constants"

export const REPRESENTING_ROLES = [
    {
        label: "I own this property",
        value: USER_ROLE_FOR_PROPERTY.LANDLORD
    },
    {
        label: "I represent the owner",
        value: USER_ROLE_FOR_PROPERTY.LISTING_AGENT
    },
    {
        label: "I represent an applicant",
        value: USER_ROLE_FOR_PROPERTY.RENTER_AGENT
    },
]

export const USER_ROLE_FOR_PROPERTY_FORM_TITLE = "What's your role for this property?"
export const USER_ROLE_FOR_PROPERTY_FORM_TITLE_EXPERIMENT_A = "Property owner"
export const USER_ROLE_FOR_PROPERTY_FORM_ENDING_NOTICE = "We’ll send this landlord occasional emails about RentSpree."
export const RADIO_BUTTON_LABEL_EXPERIMENT_A = 'I am the owner'

export const EXPERIMENTAL_LANDLORD_FORM_TITLE = "Owner information"
export const EXPERIMENTAL_LANDLORD_FORM_DESCRIPTION = "To ensure security, owner's info is requested before adding a property."
