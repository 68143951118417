import React, { Component } from 'react'
import { compose } from 'redux'
import { matchPath, withRouter } from 'react-router-dom'
import { buildPath } from '@rentspree/path'
import isEmpty from 'lodash/isEmpty'
import { SINGLE_APPLICATION } from 'constants/route'
import { ADD_TO_CONTACT_ID } from 'components/organisms/report-header/constants'
import Popper from '../../components/popper/popper'
import { withPopper } from './connect'
import tracker from '../../tracker'
import { FEATURE_NOTICE } from '../../tracker/const'

export class PopperContainerPage extends Component {
  componentWillUnmount = () => {
    this.clearReportPageInterval()
  }

  clearReportPageInterval = () => {
    if (this.reportPageInterval) {
      clearInterval(this.reportPageInterval)
    }
  }

  resetStyle = refNode => {
    /* eslint-disable no-param-reassign */
    try {
      refNode.classList.remove('isNoticeFocused')
    } catch (e) {
      console.error(e)
    }
  }

  onNextNotice = (refNode, title) => () => {
    const { actions } = this.props
    if (FEATURE_NOTICE.MAP_EVENT_NAME[title]) {
      tracker.trackEvent(`${FEATURE_NOTICE.MAP_EVENT_NAME[title]}`)
    }
    this.resetStyle(refNode)
    actions.findNextNotice()
    this.clearReportPageInterval()
  }

  onDoneNotice = (refNode, title) => () => {
    this.onNextNotice(refNode, title)()
    tracker.trackEvent(FEATURE_NOTICE.VIEW_ALL_FEATURE_NOTICE)
  }

  onSkip = refNode => () => {
    const { actions, popper, activeData } = this.props
    this.resetStyle(refNode)
    actions.findNextNotice(popper)
    const skipFeatureNotice = FEATURE_NOTICE.SKIP_FEATURE_NOTICE
    tracker.trackEvent(
      skipFeatureNotice.name,
      skipFeatureNotice.properties({
        feature: skipFeatureNotice.mapper[activeData.title],
      }),
    )
  }

  getRouteParams = path => {
    if (!path) {
      return {}
    }
    const match = matchPath(this.props.location.pathname, {
      path,
    })
    return match ? match.params : {}
  }

  onClickCustomLink = (refNode, title, page) => {
    const { activeData = {} } = this.props
    const { buttonLink, isExternalUrl, matchPath: currentMatchPath } = activeData
    if (!buttonLink) {
      return null
    }
    return () => {
      const params = this.getRouteParams(currentMatchPath)
      const url = buildPath(activeData.buttonLink, params)
      this.onNextNotice(refNode, `${title}_${page}`, page)()
      if (isExternalUrl) {
        window.location.assign(buttonLink)
      } else {
        this.props.history.push(url)
      }
    }
  }

  render() {
    const { popper, activeData = {}, actions } = this.props

    const matchReportPath = matchPath(this.props.location?.pathname, {
      path: SINGLE_APPLICATION,
      strict: false,
      exact: false,
    })

    if (popper !== '') {
      try {
        const refNode = document.querySelector(`#${popper}`)
        const { isGroup = false, max = 0, current = 0, focusId } = activeData
        const focusNodeId = isEmpty(focusId) ? popper : focusId
        const focusNode = document.querySelector(`#${focusNodeId}`)
        focusNode.classList.add('isNoticeFocused')

        // Workaround solution for addToContact feature notice
        const appDetailElem = document.getElementById('appDetailTabs')
        if (matchReportPath && popper === ADD_TO_CONTACT_ID && appDetailElem) {
          appDetailElem.scrollIntoView()
          // To fix scrolling momentum effect after 3s
          this.reportPageInterval = setInterval(() => {
            appDetailElem.scrollIntoView()
          }, 1000)
        } else {
          this.clearReportPageInterval()
        }

        const isLastStepGroup = isGroup && max === current
        const isMultipleButton = activeData.buttonLink && activeData.buttonText
        const group = { isGroup, max, current }
        let bottomBtnText = 'Done'
        if (!isLastStepGroup) {
          bottomBtnText = group.isGroup ? 'Next' : 'Got It'
        }
        if (isMultipleButton) {
          bottomBtnText = 'Later'
        }
        if (activeData.bottomBtnText) {
          bottomBtnText = activeData?.bottomBtnText
        }

        actions.updateNotice(activeData)
        return (
          <Popper
            referenceElement={refNode}
            title={activeData.title}
            description={activeData.description}
            htmlDescription={activeData.htmlDescription}
            bottomBtnText={bottomBtnText}
            onClick={this.onNextNotice(focusNode, activeData.title, activeData.page)}
            onDoneClick={this.onDoneNotice(focusNode, activeData.title)}
            placementArrow={activeData.placementArrow}
            offset={activeData.offset}
            group={group}
            onSkip={this.onSkip(focusNode)}
            isLastStepGroup={isLastStepGroup}
            buttonText={activeData.buttonText}
            onClickButtonLink={this.onClickCustomLink(focusNode, activeData.title, activeData.page)}
          />
        )
      } catch (e) {
        return null
      }
    }
    return null
  }
}

export default compose(withRouter, withPopper)(PopperContainerPage)
