import get from 'lodash/get'
import { createSelector } from 'reselect'

import { API_STATUS, KEY } from '../constants'
import { rentalPaymentInitialState } from '../reducers'

export const selectRentalPaymentState = state =>
  get(state, KEY.RENTAL_PAYMENT, rentalPaymentInitialState)

// rental payment list
export const makeSelectRentalPaymentList = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'rentalPaymentList', {}))

export const makeSelectRentalPaymentListData = () =>
  createSelector(makeSelectRentalPaymentList(), state => get(state, 'data', {}))

export const makeSelectRentalPaymentListStatus = () =>
  createSelector(makeSelectRentalPaymentList(), state => get(state, 'status', API_STATUS.NONE))

const selectSideBar = ({ sidebar }) => sidebar || {}

export const selectRentalPaymentNotifications = () =>
  createSelector(selectSideBar, sidebar => get(sidebar, 'notice.rentalPayment', []))

// rental payment error badge
export const makeSelectShowErrorBadge = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'checkShowErrorBadge', {}))

export const makeSelectShowErrorBadgeResult = () =>
  createSelector(makeSelectShowErrorBadge(), state => get(state, 'data.result', false))

export const makeSelectShowErrorBadgeStatus = () =>
  createSelector(makeSelectShowErrorBadge(), state => get(state, 'status', API_STATUS.NONE))
// rental payment
export const makeSelectRentalPayment = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'rentalPayment', {}))

export const makeSelectRentalPaymentData = () =>
  createSelector(makeSelectRentalPayment(), state => get(state, 'data', {}))

export const makeSelectRentalPaymentStatus = () =>
  createSelector(makeSelectRentalPayment(), state => get(state, 'status', API_STATUS.NONE))

export const makeSelectRentalPaymentDataStatus = () =>
  createSelector(makeSelectRentalPayment(), state => get(state, 'data.status', ''))

export const makeSelectRentalPaymentId = () =>
  createSelector(makeSelectRentalPayment(), state => get(state, 'data.id', ''))

// finalize onboarding
export const makeSelectFinalizeOnboarding = () => {
  createSelector(selectRentalPaymentState, state => get(state, 'finalizeOnboarding', {}))
}

export const makeSelectFinalizeOnboardingStatus = () =>
  createSelector(makeSelectFinalizeOnboarding(), state => get(state, 'status', API_STATUS.NONE))

// retry payout
export const makeSelectRetryPayout = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'retryPayout', {}))

export const makeSelectRetryPayoutStatus = () =>
  createSelector(makeSelectRetryPayout(), state => get(state, 'status', API_STATUS.NONE))

// delete rental payment
export const makeSelectDeleteRentalPayment = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'deleteRentalPayment', {}))

export const makeSelectDeleteRentalPaymentStatus = () =>
  createSelector(makeSelectDeleteRentalPayment(), state => get(state, 'status', API_STATUS.NONE))

// edit one time payment
export const makeSelectEditOneTimePayment = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'editOneTimePayment', {}))

export const makeSelectEditOneTimePaymentStatus = () =>
  createSelector(makeSelectEditOneTimePayment(), state => get(state, 'status', API_STATUS.NONE))

// mark as paid
export const makeSelectMarkAsPaid = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'markAsPaid', {}))

export const makeSelectMarkAsPaidStatus = () =>
  createSelector(makeSelectMarkAsPaid(), state => get(state, 'status', API_STATUS.NONE))

// available payout
export const makeSelectAvailablePayout = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'availablePayout', {}))

export const makeSelectAvailablePayoutData = () =>
  createSelector(makeSelectAvailablePayout(), state => get(state, 'data.result', null))

export const makeSelectAvailablePayoutStatus = () =>
  createSelector(makeSelectAvailablePayout(), state => get(state, 'status', API_STATUS.NONE))

// payment summary
export const makeSelectPaymentSummary = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'paymentSummary', {}))

export const makeSelectPaymentSummaryData = () =>
  createSelector(makeSelectPaymentSummary(), state => get(state, 'data', null))

export const makeSelectPaymentSummaryStatus = () =>
  createSelector(makeSelectPaymentSummary(), state => get(state, 'status', API_STATUS.NONE))

// create tenant
export const makeSelectCreateTenant = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'createTenant', {}))

export const makeSelectCreateTenantStatus = () =>
  createSelector(makeSelectCreateTenant(), state => get(state, 'status', API_STATUS.NONE))
