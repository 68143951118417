import generateApiCall from 'utils/generate-api-call'

import {
  UPDATE_DRAFT_QUOTATIONS,
  UPDATE_DRAFT_QUOTATIONS_FAILED,
  UPDATE_DRAFT_QUOTATIONS_REQUESTED,
  UPDATE_DRAFT_QUOTATIONS_SUCCEEDED,
} from './constants'

export const updateDraftQuotations = payload => ({
  type: UPDATE_DRAFT_QUOTATIONS,
  payload,
})

export const updateDraftQuotationsAPI = generateApiCall([
  UPDATE_DRAFT_QUOTATIONS_REQUESTED,
  UPDATE_DRAFT_QUOTATIONS_SUCCEEDED,
  UPDATE_DRAFT_QUOTATIONS_FAILED,
])
