import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { takeLatest, put, call, select } from 'redux-saga/effects'

import { API_ERRORS } from 'constants/error-messages'
import { addToast } from 'containers/toast/actions'
import { ALERT_PRESET } from 'redux-middleware/sweet-alert'
import { openSweetAlert } from 'utils/sweet-alert-actions'

import { EDIT_ONE_TIME_PAYMENT, editOneTimePaymentAPI, getRentalPaymentById } from '../../actions'
import { editOneTimePaymentInstance } from '../../api-instances'
import { EDIT_PAYMENT_TOAST, PAYMENT_CATEGORIES } from '../../constants'
import { makeSelectRentalPaymentId } from '../../selectors'

export const selectRentalPaymentId = makeSelectRentalPaymentId()

export function* editOneTimePaymentSaga({
  payload: {
    paymentDetails: {
      quotationId,
      category,
      customCategory,
      description,
      amount,
      dueDate,
      feeCollection,
    },
    onSuccess = () => {},
    onError = () => {},
  } = {},
}) {
  try {
    yield put(editOneTimePaymentAPI.request())
    const rentalPaymentId = yield select(selectRentalPaymentId)

    const mappedCategory = category === PAYMENT_CATEGORIES.OTHER ? customCategory : category
    const mappedDescription = isEmpty(description) ? undefined : description

    const updatedQuotations = yield call(editOneTimePaymentInstance, {
      rentalPaymentId,
      quotationId,
      category: mappedCategory,
      description: mappedDescription,
      amount,
      dueDate,
      feeCollection,
    })
    yield put(getRentalPaymentById({ rentalPaymentId }))

    yield put(editOneTimePaymentAPI.success(updatedQuotations))

    yield call(onSuccess, {
      description: mappedDescription,
      paymentCategory: mappedCategory,
      amount,
      dueDate: moment(dueDate).format('YYYY-MM-DD'),
    })

    yield put(addToast(EDIT_PAYMENT_TOAST.SUCCESS))
  } catch (err) {
    yield put(editOneTimePaymentAPI.failure(err))
    yield put(
      openSweetAlert({
        preset: ALERT_PRESET.TRY_AGAIN,
        option: {
          title: EDIT_PAYMENT_TOAST.ERROR.title,
          text: EDIT_PAYMENT_TOAST.ERROR.text,
        },
        promise: API_ERRORS[500].callback,
      }),
    )
    yield call(onError)
  }
}

export function* watchEditOneTimePaymentSaga() {
  yield takeLatest(EDIT_ONE_TIME_PAYMENT, editOneTimePaymentSaga)
}
