import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import get from 'lodash/get'
import 'moment-timezone'
import { takeEvery, put, call } from 'redux-saga/effects'

import { ERROR_TYPE } from 'constants/error-type'
import { addToast } from 'containers/toast/actions'

import {
  CONFIRM_DRAFT_RENTAL_PAYMENT,
  confirmDraftRentalPaymentAPI,
  clearConfirmationStatus,
} from '../../actions'
import { confirmDraftRentalPaymentInstance, updatePayoutMethodInstance } from '../../api-instances'
import { TOAST_MESSAGE } from '../../constants'

dayjs.extend(utcPlugin)

export function* popupSuccessToast(message = TOAST_MESSAGE.SUCCESS) {
  const toastConfig = {
    titleMessage: message,
    status: 'success',
    timeOut: 10000,
  }
  yield put(addToast(toastConfig))
}

export function* confirmDraftRentalPaymentSaga({ payload }) {
  const { rentalPaymentId, payoutMethodId, onSuccess, onError } = payload

  yield put(confirmDraftRentalPaymentAPI.request())

  try {
    if (payoutMethodId) {
      yield call(updatePayoutMethodInstance, { rentalPaymentId, payoutMethodId })
    }
    const response = yield call(confirmDraftRentalPaymentInstance, {
      ...payload,
    })

    yield put(confirmDraftRentalPaymentAPI.success(response))

    yield call(onSuccess, response)
  } catch (error) {
    const status = get(error, 'status')

    switch (status) {
      case 422: {
        yield put(confirmDraftRentalPaymentAPI.failure(ERROR_TYPE.START_DATE_IS_PAST))
        break
      }
      default: {
        yield put(confirmDraftRentalPaymentAPI.failure(ERROR_TYPE.SAVING))
        break
      }
    }

    yield call(onError, error)
  } finally {
    yield put(clearConfirmationStatus())
  }
}

export function* watchConfirmDraftRentalPaymentSaga() {
  yield takeEvery(CONFIRM_DRAFT_RENTAL_PAYMENT, confirmDraftRentalPaymentSaga)
}
