import React from "react"
import Subscription from "containers/subscription"
import HotToRequestDocsPage from "./request-docs-subscribed"
import { FEATURE_PRO } from "../../constants/feature-pro-consts"
import RequestDocsPage from "./request-docs"

const RequestDocsDetail = props => (
  <Subscription
    feature={FEATURE_PRO.SUBSCRIPTION}
    render={({ isSubscriber }) => {
      if (isSubscriber) {
        return <HotToRequestDocsPage {...props} />
      }
      return <RequestDocsPage {...props} />
    }}
  />
)

export default RequestDocsDetail
