import React from 'react'
import { ACCEPT_DENY_TYPE, STATUS_TEXT, STATUS_COLOR } from 'containers/accept-deny/constants'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import Box from '@mui/material/Box'
import Typography from '@rentspree/component-2023.components.atoms.typography'

const StatusIcon = ({result}) => {
  const color = STATUS_COLOR[result]
  switch (result) {
    case ACCEPT_DENY_TYPE.ACCEPT:
      return <CheckCircleIcon fontSize="large" style={{ color }} />
    case ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION:
      return <AccessTimeFilledIcon fontSize="large" style={{ color }} />
    case ACCEPT_DENY_TYPE.DENY:
      return <DoDisturbOnIcon fontSize="large" style={{ color }} />
    default:
      return null
  }
}

const AcceptDenyStatus = ({ result }) => (
    <Box display="flex" alignItems="center" gap="8px">
      <StatusIcon result={result}/>
      <Typography variant="body-medium" color="white" component="div">
        {STATUS_TEXT[result]}
      </Typography>
    </Box>
  )

export default AcceptDenyStatus
