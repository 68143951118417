import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectSaga from 'utils/inject-saga'
import injectReducer from 'utils/inject-reducer'
import { selectIsUpdatingDreLicense } from './selectors'
import reducer from './reducer'
import { updateDreLicense } from './actions'
import saga from './saga'

export const mapStateToProps = createStructuredSelector({
  isUpdatingDreLicense: selectIsUpdatingDreLicense(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateDreLicense,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({ key: 'dreLicense', reducer })
export const withSaga = injectSaga({ key: 'dreLicense', saga })
