import generateApiCall from "utils/generate-api-call"
import { TYPES } from "./constants"

export const getNotificationCall = payload => ({
  type: TYPES.GET_NOTIFICATION_CALL,
  payload,
})

export const clearNotification = () => ({
  type: TYPES.CLEAR_NOTIFICATION,
})
export const getNotificationApi = generateApiCall([
  TYPES.GET_NOTIFICATION_REQUEST,
  TYPES.GET_NOTIFICATION_SUCCESS,
  TYPES.GET_NOTIFICATION_FAILURE,
])
