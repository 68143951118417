import React from 'react'

import Verification from 'containers/verification'
import VerificationFailed from 'containers/verification/failed'

export const ReportRequireVerification = () => (
  <Verification heightCal="calc(100vh - 70px - 45px)" />
)

export const ReportVerificationFailed = ({ history }) => (
  <VerificationFailed heightCal="calc(100vh - 70px - 45px)" history={history} />
)
