import Span from '@rentspree/component-v2/dist/typography/span'
import React from 'react'
import styled from 'styled-components'

export const TITLE_OF_PAGE = 'Set Up Your Payment'

export const PAYMENT_DETAIL_TITLE = 'Enter Payment Details'
export const BUTTON_ADD_PAYMENT = '+ Add another payment'

const SpanOveride = styled(Span)`
  font-family: Source Sans Pro;
`

export const NOTIFY_CHANGE_TO_TENANT = (
  <SpanOveride size="14px" lineHeight="20px" weight="600">
    We will notify the tenant of these changes.
  </SpanOveride>
)

export const DESCRIPTION_PLACEHOLDER = 'The tenant will see this'
export const DESCRIPTION_DELETE_PAYMENT_MODAL =
  'Are you sure you want to permanently delete this payment?'

export const PANEL_TITLE_SCHEDULE_PAYMENT = `Payments selected for the 29th, 30th, or 31st will be processed on the last day of shorter months.`

export const PANEL_DUE_DATE = (
  <SpanOveride size="14px" lineHeight="20px" weight="600">
    Tenants can only pay on the due date. Select today’s date to receive the payment faster.
  </SpanOveride>
)

export const PANEL_PROCESSING_TIME = (
  <Span size="16px" lineHeight="22px">
    {'Payments will post to your account within '}
    <Span weight="700">6 business days</Span>
    {' of a tenant’s payment.'}
  </Span>
)

export const MAXIMUM_PAYMENT_LIMIT_WARNING = 'You can only add 5 payments per setup.'

export const SUBMITTING_FLOW = {
  NEXT: 'next',
  ADD: 'add',
  ADD_ANOTHER: 'addAnother',
}

export const PAYMENT_CATEGORIES = {
  RENT: 'Rent',
  SECURITY_DEPOSIT: 'Security deposit',
  PRORATED_RENT: 'Prorated rent',
  LATE_FEE: 'Late fee',
  MOVE_IN_FEE: 'Move-in fee',
  UTILITIES: 'Utilities',
  PET_FEE: 'Pet fee',
  PARKING: 'Parking',
  OTHER: 'Other',
}

/**
 * Payment categories
 * @example
 * [{ label: "Security deposits", value: "Security deposits" }]
 */
export const PAYMENT_CATEGORIES_MAPPED = Object.values(PAYMENT_CATEGORIES).map(value => ({
  label: value,
  value,
}))

export const MAXIMUM_PAYMENT_DETAILS_LIMIT = 5

export const RENT_PAYMENT_DEFAULT_FEE_COLLECTION_KEY = 'rp.defaultFeeCollection'
