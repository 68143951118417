import styled from "styled-components"

export const TransparentStickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
  @media (min-width: 992px) {
    display: none;
  }
`
