import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import injectSaga from "utils/inject-saga"
import {
  clearTogglePropertyFeatureModal,
  disableFeature,
  enableFeature,
} from "./actions"
import {
  selectFeature,
  selectToggleType,
  selectIsLoading,
  selectIsOpen,
} from "./selectors"
import { selectIsPropertyArchived } from "../toggle-archive-property/selectors"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  feature: selectFeature,
  type: selectToggleType,
  isLoading: selectIsLoading,
  isOpen: selectIsOpen,
  isPropertyArchived: selectIsPropertyArchived,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      clearTogglePropertyFeatureModal,
      disableFeature,
      enableFeature,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "togglePropertyFeatureModal", saga })
