import Card from '@rentspree/component-2023.components.organisms.card'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

export const ErrorContentWrapper = styled(Card)`
  width: ${props => `calc(100% - ${2 * (props.margin || 0)}px)`};
  border-color: ${COLOR.outlineRed} !important;
  padding: 16px !important;
  margin-bottom: 24px;
  cursor: initial;
  display: flex;
  flex-direction: row !important;
  gap: 12px !important;
  ${props => props.margin && `margin: 0px ${props.margin}px ${props.margin}px ${props.margin}px;`}

  @media (max-width: 991px) {
    width: ${props => `calc(100% - ${2 * (props.mMargin || 0)}px)`};
    ${props =>
      props.mMargin && `margin: 0px ${props.mMargin}px ${props.mMargin}px ${props.mMargin}px;`}
  }
`

export const ErrorContentCol = styled.div`
  display: flex;
  flex-direction: column;
`
