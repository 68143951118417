import { object } from 'yup'
import defaults from 'lodash/defaults'
import { validateSharedEmail, validateLength } from 'legacy/constants/validations'
import { MESSAGE_CHARACTER_LIMIT } from '../constants'

export const shareReportFormSchema = ({
  email,
  renterEmail,
  userEmail,
  landlordInformation = {},
  application,
  screeningReport,
  incomeVerificationReport,
  otherDocs,
  referenceCheck,
  shareWithLandlord,
  addLandlordToProperty,
  agentMessage,
  formValues = {},
}) => ({
  mapper: defaults(
    defaults(
      {
        email,
        application,
        screeningReport,
        incomeVerificationReport,
        otherDocs,
        referenceCheck,
        shareWithLandlord,
        addLandlordToProperty,
        agentMessage,
      },
      formValues,
    ),
    {
      email: landlordInformation.email || '',
      // TODO: share options should be encapsulated in shareOption object
      application: false,
      screeningReport: false,
      incomeVerificationReport: false,
      otherDocs: false,
      referenceCheck: false,
      shareWithLandlord: true,
      addLandlordToProperty: false,
      agentMessage: '',
    },
  ),
  validation: object().shape({
    email: validateSharedEmail(true, userEmail, renterEmail),
    agentMessage: validateLength(false, 0, MESSAGE_CHARACTER_LIMIT),
  }),
})
