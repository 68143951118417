import styled from 'styled-components'

export const BottomStickyBar = styled.div`
  display: flex;
  position: sticky;
  width: 100%;
  height: 64px;
  bottom: -5px;
  z-index: 1000;
  body:has(.announcement-banner) & {
    bottom: 30px;
  }
  ${props =>
    props.isMobile
      ? `
      justify-content: center;
    `
      : `
      padding: 0 10%;
      background-color: white;
      box-shadow: 0px 0px 16px 0px #3333331a;
  `};
`

export const ButtonGroup = styled.div`
  display: flex;
  align-self: center;
  margin-left: ${props => (props.isMobile ? '0' : 'auto')};
  gap: ${props => (props.isMobile ? '12px' : '16px')};
`

export const StickyFooterContainer = styled.div`
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1016px) {
    width: 100%;
  }
`
export const StickyFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
