import React from 'react'

const withSelectScreeningOptionsPage = (NewComponent, LegacyComponent) => props => {
  const { isLegacyScreening } = props
  if (isLegacyScreening) {
    return <LegacyComponent {...props} />
  }

  return <NewComponent {...props} />
}

export default withSelectScreeningOptionsPage
