import generateApiCall from 'utils/generate-api-call'

import {
  OPEN_CREATE_ASSOCIATION_CONTACT_MODAL,
  CLOSE_CREATE_ASSOCIATION_CONTACT_MODAL,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_REQUEST,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_SUCCESS,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_FAILURE,
  OPEN_EDIT_ASSOCIATION_CONTACT_MODAL,
  EDIT_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
} from './constants'

export const openCreateAssociationContactModal = () => ({
  type: OPEN_CREATE_ASSOCIATION_CONTACT_MODAL,
})

export const closeCreateAssociationContactModal = () => ({
  type: CLOSE_CREATE_ASSOCIATION_CONTACT_MODAL,
})

export const createAssociationContactFromModal = contact => ({
  type: CREATE_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
  payload: {
    contact,
  },
})

export const openEditAssociationContactModal = () => ({
  type: OPEN_EDIT_ASSOCIATION_CONTACT_MODAL,
})

export const editAssociationContactFromModal = contact => ({
  type: EDIT_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
  payload: {
    contact,
  },
})


export const createAssociationContactFromModalState = generateApiCall([
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_REQUEST,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_SUCCESS,
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_FAILURE,
])
