import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"

import {
  selectIsFetchingProperty,
  selectIsUpdatingPropertyDetails,
  selectIsCreatingContact,
  selectGetPropertyErrors,
  makeSelectPropertyAddressName,
  makeSelectUserId,
} from "./selectors"
import reducer from "./reducer"
import * as actions from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  propertyAddressName: makeSelectPropertyAddressName(),
  isFetchingProperty: selectIsFetchingProperty(),
  isUpdatingPropertyDetails: selectIsUpdatingPropertyDetails(),
  isCreatingContact: selectIsCreatingContact(),
  getPropertyError: selectGetPropertyErrors(),
  userId: makeSelectUserId(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "propertyDetails", reducer })
export const withSaga = injectSaga({ key: "propertyDetails", saga })
