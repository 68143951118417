import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import {
  selectScreeningFeeData,
  selectIsFetchingLraRequest,
  selectIsUpdatingLraRequest,
} from "./selectors"
import reducer from "./reducer"
import { getLraRequestCall, updateLraRequestCall } from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  screeningFeeData: selectScreeningFeeData(),
  isFetchingLraRequest: selectIsFetchingLraRequest(),
  isUpdatingLraRequest: selectIsUpdatingLraRequest(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getLraRequestCall,
        updateLraRequestCall,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({
  key: "screeningFee",
  reducer,
})
export const withSaga = injectSaga({ key: "screeningFee", saga })
