import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { getPayeeProviderAccountInformation } from '../redux/actions'
import { KEY } from '../redux/constants'
import { paymentAccountReducer } from '../redux/reducers'
import { paymentAccountRootSaga } from '../redux/sagas'
import {
  makeSelectProviderAccountInfoData,
  makeSelectProviderAccountInfoStatus,
} from '../redux/selectors'

export const usePayeeProviderAccountInformation = (ready = true) => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.PAYMENT_ACCOUNT, saga: paymentAccountRootSaga })
  useInjectReducer({ key: KEY.PAYMENT_ACCOUNT, reducer: paymentAccountReducer })

  const information = useSelector(makeSelectProviderAccountInfoData())
  const status = useSelector(makeSelectProviderAccountInfoStatus())

  React.useEffect(() => {
    if (ready) {
      dispatch(getPayeeProviderAccountInformation())
    }
  }, [ready])

  return {
    fetch: () => dispatch(getPayeeProviderAccountInformation()),
    information,
    status,
  }
}
