import PropTypes from "prop-types"
import React from "react"
import { B14, Span } from "@rentspree/component-v2"
import { Icon } from "@rentspree/component-v2/dist/icon"
import styled from "styled-components"
import { COLOR } from "styles/settings"

const TextWithIconStyled = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  ${props => props.margin && `margin: ${props.margin}`};
  .text {
    margin: 0px;
    ${props =>
      props.reverseRow
        ? `padding-right: ${props.space || "10px"}`
        : `padding-left: ${props.space || "10px"}`}
  }
  ${props => props.reverseRow && `flex-direction:row-reverse;`}
`
export const TextWithIcon = ({
  color,
  text,
  fontSize,
  mFontSize,
  iconColor,
  icon,
  space,
  reverseRow,
  className,
  size,
  iconHeight,
  fontWeight,
  margin,
  alignItems,
}) => (
  <TextWithIconStyled reverseRow={reverseRow} space={space} margin={margin} alignItems={alignItems}>
    <Icon
      className={className}
      style={{ height: iconHeight }}
      icon={icon}
      name={icon}
      color={iconColor}
      size={size}
    />
    {fontSize || mFontSize ? (
      <Span
        className="text"
        size={fontSize}
        tSize={mFontSize}
        color={color}
        weight={fontWeight}>
        {text}
      </Span>
    ) : (
      <B14 className="text" color={color} weight={fontWeight}>
        {text}
      </B14>
    )}
  </TextWithIconStyled>
)

TextWithIcon.defaultProps = {
  text: "TITLE",
  color: COLOR.textBlack,
  icon: "menu-listing",
  iconHeight: "16px",
  size: "md",
}

TextWithIcon.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  iconHeight: PropTypes.string,
  reverseRow: PropTypes.bool,
  alignItems: PropTypes.string,
}
