import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'

import { useGetMyUserInvitation } from './use-get-my-invitations'

export const useAgentNewDashboardConfig = () => {
  const userProfile = useSelector(selectProfile)
  const { data: myInvitation } = useGetMyUserInvitation()

  const isInvitedUser = !!myInvitation?.result?.registeredUserId
  const userStartedAt = new Date(
    userProfile?.emailVerification?.verifiedAt || userProfile.createdAt,
  )

  // TODO: update this date to the configured date
  const newDashboardEnabledAt = new Date('2024-10-29T14:35:00.000+07:00')
  const welcomeModalEnabledAt = new Date('2024-11-19T17:00:00.000+07:00')

  return {
    isAgentNewDashboardEnabled: userStartedAt > newDashboardEnabledAt && !isInvitedUser,
    canShowWelcomeModal: userStartedAt > welcomeModalEnabledAt && !isInvitedUser,
  }
}
