import generateApiCall from "utils/generate-api-call"
import {
  VERIFY_PROPERTY_ADDRESS_CALL,
  VERIFY_PROPERTY_ADDRESS_REQUEST,
  VERIFY_PROPERTY_ADDRESS_SUCCESS,
  VERIFY_PROPERTY_ADDRESS_FAILURE,
  RESET_ADDRESS_VERIFICATION,
} from "./constants"

export const verifyPropertyAddress = payload => ({
  type: VERIFY_PROPERTY_ADDRESS_CALL,
  payload
})

export const verifyPropertyAddressState = generateApiCall([
  VERIFY_PROPERTY_ADDRESS_REQUEST,
  VERIFY_PROPERTY_ADDRESS_SUCCESS,
  VERIFY_PROPERTY_ADDRESS_FAILURE,
])

export const resetAddressVerification = () => ({
  type: RESET_ADDRESS_VERIFICATION,
})