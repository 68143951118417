import produce from 'immer'

import {
  OPEN_INVITE_LANDLORD_RENT_PAYMENT_MODAL,
  CLOSE_INVITE_LANDLORD_RENT_PAYMENT_MODAL,
  GET_LANDLORD_INFORMATION_REQUEST,
  GET_LANDLORD_INFORMATION_SUCCESS,
  GET_LANDLORD_INFORMATION_FAIL,
  INVITE_AFTER_APPLICANT_ACCEPTED_REQUEST,
  INVITE_AFTER_APPLICANT_ACCEPTED_SUCCESS,
  INVITE_AFTER_APPLICANT_ACCEPTED_FAIL,
} from './constants'

export const initialState = {
  isOpen: false,
  isSubmitting: false,
  propertyId: '',
  landlordInformation: {
    email: '',
    firstName: '',
    lastName: '',
    isFetching: false,
  },
}

/* eslint-disable no-param-reassign */
const inviteLandlordRentPaymentModalReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_INVITE_LANDLORD_RENT_PAYMENT_MODAL:
        draft.isOpen = true
        draft.propertyId = payload?.propertyId || ''
        break
      case INVITE_AFTER_APPLICANT_ACCEPTED_SUCCESS:
      case CLOSE_INVITE_LANDLORD_RENT_PAYMENT_MODAL:
        draft = initialState
        break
      case GET_LANDLORD_INFORMATION_REQUEST:
        draft.landlordInformation.isFetching = true
        break
      case GET_LANDLORD_INFORMATION_SUCCESS:
        draft.landlordInformation.isFetching = false
        draft.landlordInformation.email = payload?.email || ''
        draft.landlordInformation.firstName = payload?.firstName || ''
        draft.landlordInformation.lastName = payload?.lastName || ''
        break
      case GET_LANDLORD_INFORMATION_FAIL:
        draft.landlordInformation.isFetching = false
        break
      case INVITE_AFTER_APPLICANT_ACCEPTED_REQUEST:
        draft.isSubmitting = true
        break
      case INVITE_AFTER_APPLICANT_ACCEPTED_FAIL:
        draft.isSubmitting = false
        break
      default:
        break
    }
    return draft
  })

export default inviteLandlordRentPaymentModalReducer
