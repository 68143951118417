import { all } from 'redux-saga/effects'

import {
  watchCreateProperty,
  watchVerifyPropertyAddress,
  watchPropertyByAddress,
} from 'v3/containers/create-property-form/sagas/services'

export function* createPropertyFormRootSaga() {
  yield all([watchCreateProperty(), watchVerifyPropertyAddress(), watchPropertyByAddress()])
}
