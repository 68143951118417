import React from 'react'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'

const DotsContainer = styled.div`
  span {
    display: block;
    width: ${({ dotSize }) => dotSize || '3.75px'};
    height: ${({ dotSize }) => dotSize || '3.75px'};
    margin: 2px auto;
    background-color: ${COLOR.textBlack};
    border-radius: 50%;
  }
`

const Dots = ({ dotSize }) => (
  <DotsContainer dotSize={dotSize}>
    <span />
    <span />
    <span />
  </DotsContainer>
)

export default Dots
