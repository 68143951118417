import React, { useEffect } from "react"
import LogoLoading from "components/molecules/logo-loading"

const PaymentProcessing = ({ processingAction, loadingText = "Loading" }) => {
  useEffect(() => {
    if (processingAction) {
      processingAction()
    }
  }, [])
  return <LogoLoading text={loadingText} zIndex={-999999} />
}

export default PaymentProcessing
