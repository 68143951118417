import React from "react"
import Scroll from "react-scroll"
import isEmpty from "lodash/isEmpty"
import intersection from "lodash/intersection"
import head from "lodash/head"
import isArray from "lodash/isArray"
import findIndex from "lodash/findIndex"

const scroll = Scroll.scroller

export default class ScrollToError extends React.Component {
  componentDidUpdate(prevProps) {
    // if form was submitting, but now is not submitting because it is invalid
    const { isSubmitting, isValid, errors, fieldOrder } = this.props
    if (prevProps.isSubmitting && !isSubmitting && !isValid) {
      let firstError = !isEmpty(errors) ? Object.keys(errors)[0] : 0
      if (!isEmpty(fieldOrder)) {
        const orderedError = intersection(fieldOrder, Object.keys(errors))
        firstError = head(orderedError)
      }

      // if ArrayFields, firstError is an array
      if (isArray(errors[firstError])) {
        const arr = errors[firstError]
        // get index of first error
        const firstErrIndex = findIndex(arr, i => !isEmpty(i))
        firstError = !isEmpty(arr[firstErrIndex])
          ? `${firstError}.${firstErrIndex}.${
              Object.keys(arr[firstErrIndex])[0]
            }`
          : 0
      }

      if (!isEmpty(firstError)) {
        scroll.scrollTo(firstError, {
          duration: 500,
          smooth: true,
          offset: -40,
        })
      }
    }
  }

  render() {
    return null
  }
}
