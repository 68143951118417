import React from 'react'
import Loadable from 'react-loadable'
import { CenterContent, Loading } from 'components/layout/main'

const Load = () => (
  <CenterContent>
    <Loading />
  </CenterContent>
)

export const LoadableAppV2 = Loadable({
  loader: () => import('components/reports/application'),
  loading: () => <Load />,
})

export const LoadableAppV1 = Loadable({
  loader: () => import('legacy/components/reports/application-report'),
  loading: () => <Load />,
})

export const LoadableLraApp = Loadable({
  loader: () => import('containers/reports/lra-application'),
  loading: () => <Load />,
})
