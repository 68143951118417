import produce from 'immer'

import {
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  CLEAR_CONTACT,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  ADD_TO_CONTACT_REQUEST,
  ADD_TO_CONTACT_SUCCESS,
  ADD_TO_CONTACT_FAILURE,
} from './constants'

export const initialState = {
  data: {
    contactId: null,
  },
  isLoading: false,
  isError: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_CONTACT_REQUEST:
        draft.isLoading = true
        break
      case GET_CONTACT_SUCCESS:
        draft.data.contactId = payload?._id
        draft.isLoading = false
        draft.isError = false
        break
      case GET_CONTACT_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
      case CLEAR_CONTACT:
        draft = initialState
        break
      case DELETE_CONTACT_REQUEST:
        draft.isLoading = true
        draft.isError = false
        break
      case DELETE_CONTACT_SUCCESS:
        draft.isLoading = false
        draft.isError = false
        draft.data = initialState.data
        break
      case DELETE_CONTACT_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
      case ADD_TO_CONTACT_REQUEST:
        draft.isLoading = true
        draft.isError = false
        break
      case ADD_TO_CONTACT_SUCCESS:
        draft.isLoading = false
        draft.isError = false
        draft.data = {
          contactId: payload?._id,
        }
        break
      case ADD_TO_CONTACT_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
      default:
        break
    }
    return draft
  })

export default reducer
