import React from "react"
import classnames from "classnames/bind"
import styles from "./left-content.scss"
import loadingIcon from "../../../images/gifs/ripple.svg"
import { P } from "../../typography"
import { Button } from "../../buttons/buttons"

const c = classnames.bind(styles)

export const LoadingOverlay = ({ className }) => (
  <div className={c("loading-overlay", className)}>
    <img src={loadingIcon} alt="Loading" />
  </div>
)

export const LoadingOverall = ({ className }) => (
  <div className={c("loading-overall", className)}>
    <img src={loadingIcon} alt="Loading" />
  </div>
)

export const ErrorOverlay = ({
  className,
  onClick = () => window.location.reload(),
}) => (
  <div className={c("loading-overlay error-overlay", className)}>
    <P strong color="titleBlack">
      Failed to Fetch
    </P>
    <Button primary text="Try Again" onClick={onClick} />
  </div>
)
