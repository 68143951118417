import Handlebars from 'handlebars'

import { SINGLE_PROPERTY } from 'constants/route'
import { BASE_NAME, MAIN_URL } from 'env'
import { COLOR } from 'styles/settings'
import { RENT_PAYMENT } from 'tracker/const'

// Rent payment paths
export const RENT_PAYMENT_URL = '/rent-payments'

export const RENT_PAYMENT_REQUEST_URL = `${RENT_PAYMENT_URL}/requests`
export const RENT_PAYMENT_SETTING_URL = `${RENT_PAYMENT_URL}/settings`
export const RENT_PAYMENT_SETTING_FULL_URL = `${MAIN_URL}${
  BASE_NAME || '/dashboard/v2'
}${RENT_PAYMENT_SETTING_URL}`
export const RENT_PAYMENT_VERIFY_TAX_URL = `${RENT_PAYMENT_URL}/verify-tax`
export const RENT_PAYMENT_TRANSACTION_PAGE = `${RENT_PAYMENT_URL}/:rentalPaymentId`
export const PROPERTY_URL = '/properties'
export const PROPERTY_RENT_PAYMENT_LIST_PAGE = Handlebars.compile(
  `${PROPERTY_URL}/{{propertyId}}${RENT_PAYMENT_URL}`,
)
export const RENT_PAYMENT_TRANSACTION_PROPERTY_PAGE = `${SINGLE_PROPERTY}${RENT_PAYMENT_TRANSACTION_PAGE}`

export const MAIN_RENT_PAYMENT_TITLE = 'Payments'

export const MONTH_TO_MONTH_LABEL = 'Month-to-month'
export const USER_ROLE = {
  PAYEE: 'payee',
  PAYER: 'payer',
}

export const FEE_CONTRIBUTOR_TYPE = {
  PAYEE: USER_ROLE.PAYEE,
  PAYER: USER_ROLE.PAYER,
}

export const RENTAL_PAYMENT_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  ENDED: 'ended',
}

export const QUOTATION_TYPE = {
  ONE_TIME: 'oneTime',
  RECURRING: 'recurring',
}

export const PAYMENT_REQUEST_STATUS = {
  READY_TO_DEPOSIT: 'readyToDeposit',
  UPCOMING: 'upcoming',
  SCHEDULED: 'scheduled',
  DUE: 'due',
  PROCESSING: 'processing',
  DEPOSITED: 'deposited',
  PAYMENT_FAILED: 'paymentFailed',
  DEPOSIT_FAILED: 'depositFailed',
  VOIDED: 'voided',
  RETURNED: 'returned',
  MARKED_AS_PAID: 'markedAsPaid',
}

export const RENTER_STATUS = {
  PENDING: 'pending',
  INVITED: 'invited',
  ACCEPTED: 'accepted',
}

export const PAYOUT_METHOD_STATUS = {
  PENDING: 'pending',
  VERIFIED: 'verified',
  FAILED: 'failed',
}

export const RECEIVING_ACCOUNT_STATUS_COLOR = {
  [PAYOUT_METHOD_STATUS.VERIFIED]: COLOR.darkGreen,
  [PAYOUT_METHOD_STATUS.PENDING]: COLOR.yellow,
  [PAYOUT_METHOD_STATUS.FAILED]: COLOR.yellow,
}

export const DISPLAY_RECEIVING_ACCOUNT_STATUS = {
  [PAYOUT_METHOD_STATUS.VERIFIED]: 'Verified',
  [PAYOUT_METHOD_STATUS.PENDING]: 'Pending',
  [PAYOUT_METHOD_STATUS.FAILED]: 'Failed',
}

export const RENTER_STATUS_COLOR = {
  [RENTER_STATUS.PENDING]: COLOR.yellowDark,
}

export const RENTER_STATUS_DISPLAY = {
  [RENTER_STATUS.INVITED]: "Invited, hasn't accepted yet",
  [RENTER_STATUS.ACCEPTED]: 'Invitation Accepted',
}

export const AGENT_DEFAULT_DISPLAY_NAME = 'You'

export const PAYEE_IDV_STATUS = {
  ONBOARDING: 'onboarding',
  VERIFYING: 'verifying',
  VERIFIED: 'verified',
  FAILED: 'failed',
  RESTRICTED: 'restricted',
  RESTRICTED_SOON: 'restrictedSoon',
  PENDING_BANK_ACCOUNT: 'pendingBankAccount',
}

export const PAYEE_IDV_AVAILABLE_STATE_STATUSES = [
  PAYEE_IDV_STATUS.VERIFIED,
  PAYEE_IDV_STATUS.RESTRICTED_SOON,
]
export const ADD_BANK_ACCOUNT_SUCCESS_MESSAGE = 'Bank account added'

export const AUTO_FETCH_PAYEE_INFO_CONFIG = {
  INITIAL_INTERVAL: 4 * 1000,
  MAX_INTERVAL_TIME: 60 * 1000,
}

export const RECURRING_PAYMENT_TERM = {
  FIXED: 'fixed',
  PERPETUAL: 'perpetual',
}

export const RECEIVING_ACCOUNT_VERIFICATION_TYPE = {
  INSTANT: 'instant',
  MICRO_DEPOSITS: 'microdeposits',
}

export const RECEIVING_ACCOUNT_VERIFICATION_TYPE_EVENT_MAPPER = {
  [RECEIVING_ACCOUNT_VERIFICATION_TYPE.INSTANT]:
    RENT_PAYMENT.EVENT_PROPERTY.VERIFY_TYPE.INSTANT_AUTH,
  [RECEIVING_ACCOUNT_VERIFICATION_TYPE.MICRO_DEPOSITS]:
    RENT_PAYMENT.EVENT_PROPERTY.VERIFY_TYPE.MICRODEPOSITS,
}

export const BUTTON_TYPE_SUBMIT = 'submit'
