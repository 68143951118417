import generateApiCall from 'utils/generate-api-call'
import {
  CLEAR_APPLICATION_DETAIL,
  GET_ENVELOPE_PDF_REQUEST,
  GET_ENVELOPE_PDF_SUCCESS,
  GET_ENVELOPE_PDF_FAILURE,
  RENDER_PDF_SUCCESS,
} from './constants'

export const clearApplicationDetail = () => ({ type: CLEAR_APPLICATION_DETAIL })

export const pdfApi = generateApiCall([
  GET_ENVELOPE_PDF_REQUEST,
  GET_ENVELOPE_PDF_SUCCESS,
  GET_ENVELOPE_PDF_FAILURE,
])

export const renderPDFSuccess = () => ({
  type: RENDER_PDF_SUCCESS,
})
