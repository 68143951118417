import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { initiateTalkJs } from "../actions"
import { selectProfile } from "../../user/selectors"
import { selectUnreadCountText } from "../selectors"

const makeMapStateToProps = () =>
  createStructuredSelector({
    profile: selectProfile,
    unreadMessages: selectUnreadCountText,
  })

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      initiateTalkJs,
    },
    dispatch,
  )

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)
