import React, { useEffect, useRef, useState } from 'react'

import tracker from 'tracker'

import AgentProfileBanner from 'components/organisms/agent-profile-banner'
import { MAIN_URL } from 'env'
import { AGENT_PROFILE_API } from 'constants/route'
import { apiInstance } from 'utils/api-interceptor'
import * as sentry from '@sentry/browser'
import {
  AP_BANNER_MAX_WIDTH,
  X_LARGE_WIDTH,
} from 'components/organisms/agent-profile-banner/constants'
import { LISTING_MANAGEMENT_EVENT } from 'tracker/const'

export const AgentProfileBannerContainer = props => {
  const { displayBanner, hideBanner } = props
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const wrapperRef = useRef(null)
  const [shouldShowBanner, setShouldShowBanner] = useState(false)
  const { EVENT_NAME } = LISTING_MANAGEMENT_EVENT
  const { CLOSE_CREATE_AGENT_PROFILE_BANNER, CLICK_CREATE_AGENT_PROFILE_ON_BANNER } = EVENT_NAME

  const setBannerVisibility = value => {
    setShouldShowBanner(value)
    if (value) {
      displayBanner()
    } else {
      hideBanner()
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(event => {
      const { inlineSize } = event[0].contentBoxSize[0]
      if (window.innerWidth >= X_LARGE_WIDTH) {
        setWrapperWidth(inlineSize < AP_BANNER_MAX_WIDTH ? inlineSize : AP_BANNER_MAX_WIDTH)
      }
    })

    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current)
    }
  }, wrapperRef.current)

  const fetchAgentProfile = async () => {
    try {
      const { slug } = await apiInstance.get(`${AGENT_PROFILE_API}/me`)

      if (slug) {
        setBannerVisibility(false)
      } else {
        setBannerVisibility(true)
      }
    } catch (error) {
      if (error.status === 404) {
        setBannerVisibility(true)
      }
      sentry.captureException(error)
    }
  }

  useEffect(() => {
    fetchAgentProfile()
  }, [])

  const withTracker = func => value => eventName => {
    tracker.trackEvent(eventName)
    func(value)
  }
  const handleCreateButtonClick = value => {
    window.open(`${MAIN_URL}/agent-tools`, '_blank')
    setBannerVisibility(value)
  }

  if (shouldShowBanner) {
    return (
      <AgentProfileBanner
        wrapperRef={wrapperRef}
        largeMarginLeft={`-${wrapperWidth * 0.5}px`}
        handleCloseBannerClick={() => {
          withTracker(setBannerVisibility)(false)(CLOSE_CREATE_AGENT_PROFILE_BANNER)
        }}
        handleCreateButtonClick={() => {
          withTracker(handleCreateButtonClick)(false)(CLICK_CREATE_AGENT_PROFILE_ON_BANNER)
        }}
      />
    )
  }
  return null
}
