import { requiredYup } from "legacy/constants/validations"
import { object } from "yup"

export const schema = props => {
  const { propertySelector } = props
  return {
    mapper: {
      propertySelector: propertySelector || "",
    },
    validation: object().shape({ propertySelector: requiredYup(true) }),
  }
}
