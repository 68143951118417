import moment from "moment"

export default date => {
  moment.fn.fromNowOrNow = function fromNowOrNow(a) {
    if (Math.abs(moment().diff(this)) < 1000) {
      return "a few seconds ago"
    }
    return this.fromNow(a)
  }
  return moment(date).fromNowOrNow()
}
