/**
 * @typedef SpotlightConfig
 * @property {boolean} enabled
 * @property {number} childIndex
 */

/**
 * selectElementToSpotlight selects which element should be used to spotlight.
 * If the elementSelector is not provided, the anchorElement will be used.
 *
 * @param {object} props
 * @param {string=} elementSelector the selector is used to find the element and take higher priority
 * @param {HTMLElement=} anchorElement if the elementSelector is not present, this will be used
 */
export const selectElementToSpotlight = ({ elementSelector, anchorElement }) => {
  if (elementSelector) {
    return document.querySelector(elementSelector)
  }

  return anchorElement
}

/**
 * @param {HTMLElement} elementToRemoveSpotlight
 * @param {SpotlightConfig} config
 */
export function removeSpotlight(elementToRemoveSpotlight, config) {
  if (config?.enabled === false || !elementToRemoveSpotlight) {
    return
  }

  let targetElement = elementToRemoveSpotlight
  if (config?.childIndex >= 0) {
    targetElement = elementToRemoveSpotlight.children[config?.childIndex]
  }

  targetElement.classList.remove('RSP-spotlight')
}

/**
 * @param {HTMLElement} elementToSpotlight
 * @param {SpotlightConfig} config
 */
export function spotlight(elementToSpotlight, config) {
  if (config?.enabled === false || !elementToSpotlight) {
    return
  }

  let targetElement = elementToSpotlight
  if (config?.childIndex >= 0) {
    targetElement = elementToSpotlight.children[config?.childIndex]
  }

  targetElement.classList.add('RSP-spotlight')
}
