import { createSelector } from "reselect"
import get from "lodash/get"
import { LEASE_AGREEMENT_TERM } from "constants/terms"
import { initialState } from "./reducer"

export const getESignTemplateList = state =>
  get(state, "eSignTemplateList", initialState)

const getProfile = state => get(state, "user")

export const makeSelectIsAcceptTermsLeaseAgreement = () =>
  createSelector(
    getProfile,
    state => get(state, `profile.terms.${LEASE_AGREEMENT_TERM}`, false),
  )

export const makeSelectESignTemplateList = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "data", []),
  )

export const makeSelectIsLoading = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "isLoading", false),
  )

export const makeSelectIsError = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "isError", false),
  )

export const makeSelectCarModalState = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "carModalState"),
  )

export const makeSelectCarLinkingAccountLoading = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "carLinkingAccountLoading"),
  )

export const makeSelectCarLinkingAccountSuccess = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "carLinkingAccountSuccess"),
  )

export const makeSelectCarLinkingAccountError = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "carLinkingAccountError"),
  )
export const makeSelectPrevTemplateData = () =>
  createSelector(
    getESignTemplateList,
    state => get(state, "prevTemplateData"),
  )

export const makeSelectBrokerageName = () =>
  createSelector(
    getProfile,
    state => get(state, "profile.brokerInfo.name", ""),
  )
