import { useSelector } from "react-redux"
import { buildPath } from "@rentspree/path"
import { usePathname, useParams } from "hooks"
import { RENTAL_STATUS } from "legacy/constants/rental-consts"
import { selectRentalDetail } from "containers/reports/selectors"
import { ASSIGN_PROPERTY, TENANT_SCREENING_DETAIL } from "constants/route"
import { useLastLocation } from "react-router-last-location"
import { isGotoNewLocation } from "hooks/helper"

const { PROPERTY_REQUIRED } = RENTAL_STATUS

export const useToAssignPropertyPath = () => {
  const lastLocation = useLastLocation()
  const { status } = useSelector(selectRentalDetail)
  const pathname = usePathname()
  const { submissionParticipantId } = useParams(TENANT_SCREENING_DETAIL)
  const isRedirect = isGotoNewLocation({
    pathname,
    destinationPath: ASSIGN_PROPERTY,
    lastLocationPath: lastLocation?.pathname,
  })

  if (status !== PROPERTY_REQUIRED) return null

  return {
    isRedirect,
    path: buildPath(ASSIGN_PROPERTY, {
      submissionParticipantId,
    }),
  }
}
