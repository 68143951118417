import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FilterSelect from 'components/atoms/filter-dropdown/filter-select'
import FilterMobile from 'components/atoms/filter-mobile/filter-mobile'
import DebounceInput from 'components/atoms/debounce-input/debounce-input'
import { FONTAWESOME_HEXCODE_ICONS } from 'styles/settings'

export const StatusFilterBox = styled.div`
  width: ${props => (props.widthFilter ? props.widthFilter : '200px')};
  .Select-control {
    background: white;
    border-radius: 0 !important;
    border: none;
  }

  @media (max-width: 991px) {
    display: flex;
    width: auto;
    .filter-select {
      display: none;
    }
  }
`

export const FormWrapper = styled.form`
  font-family: Source Sans Pro;
  #filterBtnMobile {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  }
`

export const FilterBar = ({
  status,
  options,
  handleFilter,
  placeholder,
  search,
  handleSearch,
  widthFilter,
  newIconOption,
  icon,
  noIconOnMobileSelected,
}) => (
  <FormWrapper id="statusFilterForm">
    <StatusFilterBox id="statusFilterBox" widthFilter={widthFilter}>
      <FilterMobile
        newIconOption={newIconOption}
        icon={icon}
        status={status}
        onChangeStatus={handleFilter}
        options={options}
        noIconOnMobileSelected={noIconOnMobileSelected}
      />
      <FilterSelect
        id="propertyStatusFilterSelect"
        name="property-status-filter"
        status={status}
        options={options}
        onChange={handleFilter}
        newIconOption={newIconOption}
        iconSmall
      />
    </StatusFilterBox>
    <DebounceInput
      id="searchInput"
      type="text"
      name="search"
      placeholder={placeholder}
      placeholderIcon={FONTAWESOME_HEXCODE_ICONS.SEARCH}
      value={search}
      onChange={handleSearch}
      debounceTimeout={700}
    />
  </FormWrapper>
)

FilterBar.propTypes = {
  status: PropTypes.string,
  options: PropTypes.array,
  handleFilter: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  handleSearch: PropTypes.func,
}

FilterBar.defaultProps = {
  options: [],
  handleFilter: () => {},
  handleSearch: () => {},
}

export default FilterBar
