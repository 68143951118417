import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks'
import React from "react"
import { buildPath } from '@rentspree/path'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'
export const WithCreateProperty = (WrappedComponent) => (props) => {
    const { open, setupCreateProperty } = useCreatePropertyHook()
    
    const handleOnClick = () => {
      const options = {
        location: props.location,
        // eslint-disable-next-line no-underscore-dangle
        onSuccessCallback: ({ property }) =>  props.history.push(buildPath(PROPERTY_OVERVIEW_PAGE, { propertyId: property._id }))
      }
      setupCreateProperty(options)
      open()
      props.onClick()
    }

    return <WrappedComponent {...props} onClick={handleOnClick} />
}