import React from 'react'
import {
  GapContent,
  InformationHalfPanel,
  Section,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  InformationLine,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'

const PhysicalInformation = ({ physicalDetails, physicalFeatures }) => (
  <Section>
    <GapContent>
      <InformationHalfPanel>
        <SubSectionHeader title="Physical Details" />
        <InformationLine label="Eye" value={physicalDetails?.eye} />
        <InformationLine label="Hair" value={physicalDetails?.hair} />
        <InformationLine label="Height" value={physicalDetails?.height} />
        <InformationLine label="Weight" value={physicalDetails?.weight} />
        <InformationLine label="Race" value={physicalDetails?.race} />
        <InformationLine label="Sex" value={physicalDetails?.sex} />
      </InformationHalfPanel>
      <InformationHalfPanel>
        <SubSectionHeader title="Physical Features" />
        <InformationLine
          label="Complexion"
          value={physicalFeatures?.complexion || '-'}
          labelWidth="125px"
        />
        <InformationLine
          label="Scar, Mark, or Tattoo"
          value={physicalFeatures?.scarMarkTattoo || '-'}
          labelWidth="125px"
        />
      </InformationHalfPanel>
    </GapContent>
  </Section>
)

export default PhysicalInformation
