/* eslint-disable camelcase */
import React from 'react'

import IncomeVerificationErrorPage from 'components/reports/screening-reports/income-verification/error-page'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'

import { CONTACT_SUPPORT_PAGE_CONTENT } from '../constants'

export const APIResponsesHandler = ({
  children,
  isFromAllTab,
  applicantDetail,
  reportData,
  getIncomeVerificationFailStatusCode,
  getReportFailStatusCode,
  handleClickContactSupport,
  isViewedByAdmin,
}) => {
  const pathname = window.location.pathname
  const renterReportPageRegex = /reports\/rental-submissions\/.*\/all/g
  const isRenterReportPath = pathname.match(renterReportPageRegex) != null
  // TODO: improve report url on the admin dashboard side to be different than on the renter's
  // isViewedByAdmin will always be false if connection to server is lost or failed, e.g. 500 status code.
  // We prioritize not showing income verification information on the renter side over correctness on the admin dashboard.
  const isViewedFromRenterDashboard = isRenterReportPath && !isViewedByAdmin
  const reportStatusCode = parseInt(getReportFailStatusCode, 10)
  const incomeVerificationStatusCode = parseInt(getIncomeVerificationFailStatusCode, 10)
  const SERVER_ERROR_STATUS_CODE = 500
  const NO_PERMISSION_STATUS_CODE = 403
  const isRenterReportData = isObject(reportData) && isEmpty(reportData)
  const isFailedPermission =
    reportStatusCode === NO_PERMISSION_STATUS_CODE ||
    incomeVerificationStatusCode === NO_PERMISSION_STATUS_CODE
  if (isRenterReportData || isFailedPermission || isViewedFromRenterDashboard) {
    return null
  }
  if (reportStatusCode >= SERVER_ERROR_STATUS_CODE) {
    return (
      <IncomeVerificationErrorPage
        pageContent={CONTACT_SUPPORT_PAGE_CONTENT}
        onLinkClick={handleClickContactSupport}
        isFromAllTab={isFromAllTab}
        applicantDetail={isFromAllTab && applicantDetail}
      />
    )
  }
  return children
}
