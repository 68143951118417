import React from 'react'
import isEmpty from 'lodash/isEmpty'
import tracker from 'tracker'

import { REFERRAL_EVENT } from 'tracker/const'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { Box, Grid } from '@mui/material'
import Link from '@rentspree/component-2023.components.atoms.link'
import Button from '@rentspree/component-2023.components.atoms.button'
import REFERRAL_TOP_IMG from '../../images/backgrounds/referral-top-pic.jpg'
import HubspotForm from '../../components/forms/hubspot-form'
import ReferralConnect from './referral-form-connect'

import { SUPPORT_FAQ_URL } from '../../constants/route-consts'

@ReferralConnect
export default class ReferralFormPage extends React.Component {
  constructor() {
    super()
    this.state = {
      formSubmitted: 0,
    }
  }

  onFormSubmitted = position => {
    tracker.trackEvent(REFERRAL_EVENT.SEND_REFERRAL_INVITE, { pages: 'Refer & Earn', position })

    this.setState(prevState => ({ formSubmitted: prevState.formSubmitted + 1 }))
  }

  addReferrerHubspot() {
    const { profile } = this.props
    try {
      const inputArr = document.querySelectorAll("input[name='referrer']")
      for (let i = 0; i < inputArr.length; i += 1) {
        inputArr[i].value = profile.email
      }
    } catch (e) {
      console.error('Error update referrer email form hubspot=', e)
    }
  }

  render() {
    const { profile } = this.props
    const inlineMessage = "<span><i class='fas fa-check'></i> We sent your invite.</span>"
    return (
      <Box>
        <Box id="referralTopBanner" data-testid="image-banner">
          <img src={REFERRAL_TOP_IMG} alt="Referral top banner" />
        </Box>
        <Grid container paddingLeft="16px" paddingRight="16px">
          <Grid item lg={6} md={6} sm={12}>
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              alignItems="flex-start"
              data-testid="copy-writing-contianer"
            >
              <Typography variant="title-large" component="div">
                Get $50 for referring other agents
              </Typography>
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="body" component="div">
                  Know anyone who needs an easy tenant screening tool?
                </Typography>
                <Typography variant="body" component="div">
                  Refer and earn a{' '}
                  <Typography variant="body" component="span" fontWeight="bold">
                    $50 Amazon card
                  </Typography>
                  , plus{' '}
                  <Typography variant="body" component="span" fontWeight="bold">
                    RentSpree PRO for 6 months free
                  </Typography>
                  .
                </Typography>
                <Typography variant="body" component="div">
                  Anyone you invite also gets RentSpree PRO for 6 months free.
                </Typography>
                <Typography variant="body" component="div">
                  You’ll both get your rewards after they sign up and complete their first tenant
                  screening.
                </Typography>
              </Box>
              <Link target="__blank" href={SUPPORT_FAQ_URL} color="secondary" variant="bodySmall">
                Terms apply
              </Link>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} width="100%">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box className="hs-form-wrapper">
                <Typography variant="body-medium" component="div" marginBottom="8px">
                  Enter referral information below
                </Typography>
                {!isEmpty(profile) && [
                  <Box key="referee1">
                    <HubspotForm
                      id="referralForm1"
                      formId="5fafdeca-ebda-4485-b72c-b32d0e3bf30f"
                      portalId="3028281"
                      classForm="single-line-form"
                      onFormReady={() => this.addReferrerHubspot()}
                      onFormSubmitted={this.onFormSubmitted}
                      inlineMessage={inlineMessage}
                      submitDelay={500}
                      position={1}
                    />
                  </Box>,
                  <Box key="referee2">
                    <HubspotForm
                      id="referralForm2"
                      formId="992cac0a-6cfd-4ba2-a8e5-31fa4126910c"
                      portalId="3028281"
                      classForm="single-line-form"
                      onFormReady={() => this.addReferrerHubspot()}
                      onFormSubmitted={this.onFormSubmitted}
                      inlineMessage={inlineMessage}
                      submitDelay={500}
                      position={2}
                    />
                  </Box>,
                  <Box key="referee3">
                    <HubspotForm
                      id="referralForm3"
                      formId="d23a6459-b7b1-49cc-923b-00c0f03cb5b4"
                      portalId="3028281"
                      classForm="single-line-form"
                      onFormReady={() => this.addReferrerHubspot()}
                      onFormSubmitted={this.onFormSubmitted}
                      inlineMessage={inlineMessage}
                      submitDelay={500}
                      position={3}
                    />
                  </Box>,
                ]}
                {this.state.formSubmitted === 3 && (
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => window.location.reload()}
                    >
                      Send more invite
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
