import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { getProperty } from "legacy/actions/property.action"
import { makeSelectProperty, selectFeaturesEnabled } from "../selectors"

export const mapStateToProps = () =>
  createStructuredSelector({
    property: makeSelectProperty,
    featuresEnabled: selectFeaturesEnabled,
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getProperty,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
