export const TITLE_OF_PAGE = 'Payment Detail'
export const DESCRIPTION = address => `For ${address}`
export const BUTTON_TYPE_SUBMIT = 'submit'
export const BUTTON_TEXT_CONTINUE = 'Next'

export const DESCRIPTION_PLACEHOLDER = 'The tenant will see this'
export const DESCRIPTION_DELETE_PAYMENT_MODAL =
  'By deleting this payment, you will permanently delete this payment. Are you sure you want to delete it?'

export const PAYMENT_DETAIL_TITLE = 'Payment detail'
export const ONETIME_PAYMENT_TITLE = 'One-time payment'

export const MAXIMUM_INPUT_DATE = '9999-12-31'
