import { COLOR } from "styles/settings"

export const TYPES = {
  CALL_GET_NEW_BADGE: "CALL_GET_NEW_BADGE",
  GET_NEW_BADGE_REQUEST: "GET_NEW_BADGE_REQUEST",
  GET_NEW_BADGE_SUCCESS: "GET_NEW_BADGE_SUCCESS",
  GET_NEW_BADGE_FAILED: "GET_NEW_BADGE_FAILED",
}
export const API_GET_NEW_BADGE = "/api/v2/announcement/feature/new-badge"

export const PROJECT = "dashboard-v2"

export const BADGE_TYPE = {
  SOON_BADGE: "soon-badge",
  NEW_BADGE: "new-badge",
  BETA_BADGE: "beta-badge",
  PRO_BADGE: "pro-badge",
}

export const BADGE_COMPONENT_TYPES = {
  OVERLAP_BADGE: "OVERLAP_BADGE",
  BETA_BADGE: "BETA_BADGE",
  NEW_BADGE: "NEW_BADGE",
}

export const pickStyledBadge = badgeType => {
  switch (badgeType) {
    case BADGE_TYPE.SOON_BADGE:
      return {
        content: "Soon",
        background: COLOR.darkDeepOrange,
        width: "22px",
      }
    case BADGE_TYPE.NEW_BADGE:
      return {
        content: "New",
        background: COLOR.swallowBlue,
      }
    case BADGE_TYPE.BETA_BADGE:
      return {
        content: "Beta",
        background: COLOR.cyanGreen,
      }
    case BADGE_TYPE.PRO_BADGE:
      return {
        content: "Pro",
        background: COLOR.mintGreen,
      }
    default:
      return {}
  }
}
