const step1 = require("../../../images/icons/ref-checks/ref-check-step1.png")
const step2 = require("../../../images/icons/ref-checks/ref-check-step2.png")
const employer = require("../../../images/icons/ref-checks/ref-checks-employer.png")
const landlord = require("../../../images/icons/ref-checks/ref-checks-landlord.png")
const personal = require("../../../images/icons/ref-checks/ref-checks-personal.png")
const profess = require("../../../images/icons/ref-checks/ref-checks-profess.png")
const basic1 = require("../../../images/icons/ref-checks/ref-checks-basic1.png")
const basic2 = require("../../../images/icons/ref-checks/ref-checks-basic2.png")
const basic3 = require("../../../images/icons/ref-checks/ref-checks-basic3.png")
const REF_CHECKS_SAMPLE = require("../../../images/pro/arcs/ref-check-sample.png")

const faq = {
  header: {
    title: "Frequently Asked Questions",
  },
  helpCenter: "Have other questions? Visit RentSpree's Help Center",
}

export const INFO_PAGE = {
  splash: {
    title: "Auto reference checks",
    subtitle: "Verify rental information effortlessly",
    button: "TRY IT FREE",
    promotion: "Get unlimited access for 1 month.",
    coupon: "Have a coupon code?",
    restriction: "Restrictions apply. Learn more here.",
  },
  feature: {
    header: {
      title: "Don't take information at face value",
      subtitle: "RentSpree contacts references on your behalf.",
    },
    one: {
      icon: step1,
      title: "1. References are emailed and texted.",
      content:
        "When an applicant submits an application, RentSpree will send an email and a text message to applicants’ landlords, employers, and other references.",
    },
    two: {
      icon: step2,
      title: "2. Applicant information is verified.",
      content:
        "References are asked to either confirm or refute information that the applicant has provided on their online rental application.",
    },
  },
  reference: {
    header: {
      title: "Let us help you verify applicant information",
    },
    refs: [
      { icon: employer, title: "Employer(s)", subtitle: "Income/Employment" },
      { icon: landlord, title: "Landlord(s)", subtitle: "Residence History" },
      {
        icon: profess,
        title: "Professional Reference",
        subtitle: "Occupation, etc.",
      },
      {
        icon: personal,
        title: "Personal Reference",
        subtitle: "Contact info/Relationship",
      },
    ],
  },
  video: {
    header: {
      title: "See how easy it is",
    },
    video: {
      title: "",
      link: "https://www.youtube.com/embed/LyHQWnlaHZw?rel=0&amp;showinfo=0",
    },
  },
  faq,
}

export const SUBSCRIBED_PAGE = {
  splash: {
    title: "Reference Checks",
  },
  basic: {
    header: {
      title: "Learn the basics",
    },
    basics: [
      {
        icon: basic1,
        title: "1. Tenants submit an application.",
        content:
          "We notify you that the rental application and screening reports are available for you to view.",
      },
      {
        icon: basic2,
        title: "2. Have RentSpree initiate contact with listed references.",
        content:
          'Visit the "references" tab of the application and have us send an automatic email to references ' +
          "asking them to either confirm or refute the information provided by the applicant.",
      },
      {
        icon: basic3,
        title: "3. References provide their feedback.",
        content:
          "References answer basic questions about the tenant. When done, you can access the results instantly" +
          " in a simple report.",
      },
    ],
  },
  status: {
    header: {
      title: "Understand Verification Status",
    },
    image: {
      src: REF_CHECKS_SAMPLE,
      alt: "Reference Checks Sample",
    },
    status: [
      {
        color: "yellow",
        title: "Pending",
        subtitle:
          "The reference hasn't yet submitted feedback concerning the applicant.",
      },
      {
        color: "darkGreen",
        title: "Verified",
        subtitle:
          "The reference has confirmed that the information provided by applicant is correct.",
      },
      {
        color: "negativeRed",
        title: "Refuted",
        subtitle:
          "The reference has indicated that some information provided by the applicant may not be accurate." +
          " Further investigation may be necessary.",
      },
    ],
  },
  video: {
    header: {
      title: "How to verify references on applications you've received",
    },
    video: {
      title: "",
      link: "https://www.youtube.com/embed/LyHQWnlaHZw?rel=0&amp;showinfo=0",
    },
  },
  faq,
}
