import { withFormik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import T36 from '@rentspree/component-v2/dist/typography/t36'
import { ButtonSet } from 'components/buttons/button-set'
import SubtitleWithHr from 'components/molecules/subtitle-with-hr'
import propertyDetailsSchema from './schema'
import PropertyDetailsForm from './form'

export const PropertyDetails = ({
  title,
  handleBackToDashboard,
  handleSubmit,
  isUpdatingPropertyDetails,
  ...props
}) => {
  const { touched, errors } = props

  return (
    <>
      <T36 mMargin="0 0 30px" margin="0 0 30px">
        {title}
      </T36>
      <SubtitleWithHr text="Property Information" />
      <PropertyDetailsForm {...props} margin="0 10px 20px 10px" mMargin="0 0 20px 0" />
      <ButtonSet
        id="propertyDetailsButtonSet"
        margin="20px 0 0"
        minWidth="100"
        continueBtnType="submit"
        continueText="Next"
        btnHeight="40px"
        hidePrevButton
        onClickBackToDashboardBtn={handleBackToDashboard}
        disabledNextButton={isEmpty(touched) || !isEmpty(errors)}
        onSaveAndNext={handleSubmit}
        isLoading={isUpdatingPropertyDetails}
      />
    </>
  )
}

const PropertyDetailsTemplate = withFormik({
  mapPropsToValues: props => propertyDetailsSchema(props).mapper,
  validationSchema: props => propertyDetailsSchema(props).validation,
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values)
  },
  enableReinitialize: true,
  displayName: 'PropertyDetailsFormTemplate',
})(PropertyDetails)

export default PropertyDetailsTemplate
