import { useMutation } from '@tanstack/react-query'

import { createAgentInitiatedPayment } from 'v3/services/payment'

/**
 * useMutateAgentInitiatePayment returns mutation function for agent-initiate-payment
 *
 * @param {import('@tanstack/react-query').UseMutationOptions<import('v3/services/payment').AgentInitiatedPaymentResponse, Error, import('v3/services/payment').AgentInitiatedPaymentResponse>} options
 */
export const useMutateAgentInitiatePayment = options =>
  useMutation({
    mutationKey: ['useMutateAgentInitiatePayment'],
    mutationFn: createAgentInitiatedPayment,
    ...options,
  })
