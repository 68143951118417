import { put, call, takeLatest, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { push } from "connected-react-router"
import { apiInstance } from "utils/api-interceptor"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { GENERAL_ERROR } from "constants/error-messages"
import * as PATH from "constants/route"
import {
  openLoadingModal,
  openSuccessModalPromise,
  closeSweetAlertModal,
} from "utils/sweet-alert-2"
import { locationReload } from "legacy/utils/window"
import { openSweetAlert } from "utils/sweet-alert-actions"
import {
  sendApplyLinkStart,
  sendApplyLinkSuccess,
  sendApplyLinkFailed,
} from "./actions"
import { SEND_APPLY_LINK_API } from "./routes"
import { SEND_APPLY_LINK } from "./const"

export const sendApplyLinkApi = ({
  screeningRequestId,
  utmOption,
  renterEmails,
}) =>
  apiInstance.post(
    buildPath(SEND_APPLY_LINK_API, { screeningRequestId }, utmOption),
    {
      renterEmails,
    },
  )

export function* sendApplyLinkSaga({ payload }) {
  const { propertyId, screeningRequestId, recipientEmail } = payload
  const utmOption = {
    utm_campaign: "listing_prescreen",
  }
  yield put(sendApplyLinkStart())
  yield call(openLoadingModal, {})
  try {
    yield call(sendApplyLinkApi, {
      screeningRequestId,
      utmOption,
      renterEmails: [recipientEmail],
    })
    yield put(sendApplyLinkSuccess())
    yield call(openSuccessModalPromise, {
      title: "ApplyLink™ Sent!",
      text:
        "Well done! You’ll receive a screening report once your lead has finished filling out an application.",
      padding: "25px 20px 20px",
      showConfirmButton: true,
      confirmButtonText: "Continue",
      mixin: {
        reverseButtons: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "rsp-swal-btn rsp-swal-confirm-btn",
          container: "rsp-swal-container",
          htmlContainer: "swal2-text",
        },
      },
    })
    yield put(
      push(
        buildPath(PATH.SINGLE_PROPERTY_APPLICATION_LIST, {
          propertyId,
        }),
      ),
    )
  } catch (error) {
    yield put(sendApplyLinkFailed({ error: error.data }))
    yield call(closeSweetAlertModal)
    yield put(
      openSweetAlert({
        preset: ALERT_PRESET.ERROR,
        option: {
          title: GENERAL_ERROR.UNKNOWN_ERROR.TITLE,
          text: GENERAL_ERROR.UNKNOWN_ERROR.MESSAGE,
          button: "Try Again",
          closeOnClickOutside: false,
          closeOnEsc: false,
        },
        promise: locationReload,
      }),
    )
  }
}

export function* watchSendApplyLink() {
  yield takeLatest(SEND_APPLY_LINK, sendApplyLinkSaga)
}

export function* rootSaga() {
  yield all([watchSendApplyLink()])
}

export default rootSaga
