import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { createSelector } from "reselect"
import { generateAddress } from "@rentspree/helper"

import { selectProperty } from "containers/listing/selectors"

import { initialState } from "./reducer"

export const getPropertyDetailsState = state =>
  get(state, "propertyDetails", initialState)

export const getUserState = state => get(state, "user", initialState)

export const selectIsFetchingProperty = () =>
  createSelector(
    getPropertyDetailsState,
    state => get(state, "isFetchingProperty"),
  )

export const selectIsUpdatingPropertyDetails = () =>
  createSelector(
    getPropertyDetailsState,
    state => get(state, "isUpdatingPropertyDetails"),
  )

export const selectIsCreatingContact = () =>
  createSelector(
    getPropertyDetailsState,
    state => get(state, "isCreatingContact"),
  )

export const selectGetPropertyErrors = () =>
  createSelector(
    getPropertyDetailsState,
    state => get(state, "error"),
  )

export const selectPropertyDetails = () =>
  createSelector(
    getPropertyDetailsState,
    state => get(state, "property"),
  )
export const userIdSelector =
  createSelector(
    getUserState,
    state => get(state, "profile.id"),
  )

export const makeSelectUserId = () =>
  userIdSelector

export const makeSelectPropertyAddressName = () =>
  createSelector(
    selectProperty,
    selectPropertyDetails(),
    (propertyFromCreated, propertyFromAPI) => {
      const property = !isEmpty(propertyFromCreated)
        ? propertyFromCreated
        : propertyFromAPI
      return generateAddress(property)
    },
  )