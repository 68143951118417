import React from "react"
import styled from "styled-components"
import { Div } from "components/layout/main"
import { BlueGradientButton } from "@rentspree/component-v2"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import T20 from "@rentspree/component-v2/dist/typography/t20"
import Hr from "@rentspree/component-v2/dist/hr"
import { BenefitCard } from "@rentspree/component-v2/dist/cards/benefit-card"
import { Survey } from "components/survey"
import { COLOR } from "styles/settings"
import { IconNotifyButton } from "./notify"

// For extending the mPadding without impact on any other modules that use this Div component
const InnerDiv = styled(Div)`
  ${({ isFullWidth }) => `width: ${isFullWidth ? `100%` : "920px"};`}
  @media (max-width: 991px) {
    ${({ isFullWidth }) => `padding: ${isFullWidth ? `0` : "0 20px"};`}
    width: 100%;
  }
`

export const BenefitWrapper = styled(BenefitCard)`
  position: relative;
  height:100%;
`

export const NewBanner = styled(Div)`
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  position: relative;
  width: 127px;
  height: 22px;
  background: #00c9e5;
  transform: rotate(35deg);
  
  top: 5%;
  right: -10%;
  position: absolute;
`

export const Description = styled(B16)`
  white-space: break-spaces;
`

export const LandingV2 = ({
  mainContent,
  buttonText,
  handleTopOnClick,
  handleBottomOnClick,
  extraTitle,
  extraDetails,
  extraDescription,
  extraDetailsHeight = "326px",
  notify,
  toggle,
  extraContent,
  onExtraContentClick,
  topButtonId,
  bottomButtonId,
  buttonMaxWidth,
  height,
}) => (
  <Div
    sourceSansPros
    flex
    alignItems="center"
    flexDirection="column"
    margin="0 auto"
    width="100%"
    padding="50px 0px"
    relative
    height={height}
    backgroundColor={COLOR.white}>
    <BenefitCard
      width="100%"
      mWidth="375px"
      padding="0px 0px 20px 0px"
      mPadding="0px 12px 0 13px"
      imgSrc={mainContent.imgSrc}
      noShadow
      element={
        <>
          <T20
            weight={700}
            size="36px"
            tSize="24px"
            margin="0 0 10px 0"
            lineHeight="45px"
            tLineHeight="32px">
            {mainContent.title}
          </T20>
          <Description
            size="16px"
            tSize="14px"
            margin="0 0 10px 0"
            lineHeight="22px"
            tLineHeight="20px"
            width="100%">
            {mainContent.description}
          </Description>
          <Div margin="30px auto 30px auto" flex justifyContent="center">
            {notify ? (
              <IconNotifyButton
                toggle={toggle}
                onNotify={handleTopOnClick}
                buttonText={buttonText}
              />
            ) : (
              <BlueGradientButton
                id={topButtonId || "requestRenterInsuranceTop"}
                primary
                center
                large
                semiBold
                text={buttonText}
                height="40px"
                maxWidth={buttonMaxWidth || "229px"}
                margin="auto"
                fontSize="16px"
                sourceSansPro
                onClick={handleTopOnClick}
              />
            )}
          </Div>
        </>
      }
    />
    <InnerDiv
      isFullWidth={extraContent}
      flex
      justifyContent="center"
      flexDirection="column">
      {extraContent ? (
        <Survey onSurvey={onExtraContentClick} surveyContent={extraContent} />
      ) : (
        <Hr />
      )}
      <B16
        weight={600}
        size="20px"
        margin="30px 0 20px 0"
        lineHeight="26px"
        center>
        {extraTitle}
      </B16>
      <Div flex justifyContent="center">
        <Div
          flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth="919"
          listingWrapper>
          {extraDetails.map(detail => (
            <BenefitWrapper
              imgSrc={detail.imgSrc}
              title={detail.title}
              description={detail.description}
              height={extraDetailsHeight}
              mWidth="335px"
              mMargin="10px"
              element={detail.isNew && <NewBanner>New</NewBanner>}
            />
          ))}
        </Div>
      </Div>
      <Div hideDesktop>
        {extraDescription && (
          <B16 tSize="16px" margin="30px 0" tLineHeight="22px">
            {extraDescription}
          </B16>
        )}
        {notify ? (
          <Div flex justifyContent="center" margin="30px 0 0">
            <IconNotifyButton
              toggle={toggle}
              onNotify={handleBottomOnClick}
              buttonText={buttonText}
            />
          </Div>
        ) : (
          <BlueGradientButton
            id={bottomButtonId || "requestRenterInsuranceBottom"}
            primary
            center
            large
            semiBold
            text={buttonText}
            height="40px"
            maxWidth={buttonMaxWidth || "229px"}
            margin={extraDescription ? "auto" : "30px auto auto"}
            fontSize="16px"
            sourceSansPro
            onClick={handleBottomOnClick}
          />
        )}
      </Div>
    </InnerDiv>
  </Div>
)

export default LandingV2
