import { TextInput, FormattedInput } from '@rentspree/component-v2'
import { withFormik } from 'formik'
import React from 'react'

import Col from 'components/atoms/col'
import Row from 'components/atoms/row'
import { ButtonSetV2 } from 'components/buttons/button-set'
import ScrollToError from 'components/scroll/scroll-to-error'

import { schema } from './schema'

export const InnerForm = props => {
  const {
    /** Formik's props */
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    /** Container props */
    onCancel,
    isLoaded,
    isLoading,
  } = props

  const handlePhoneValueChange = data => {
    setFieldValue('phone', data.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError containerError {...props} />
      <Row flexColumn>
        <Col full margin="0 0 5px">
          <TextInput
            isRequired
            id="rentPaymentFirstNameInput"
            className="createTenantInputs"
            name="firstName"
            label="First name"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName && errors.firstName}
          />
        </Col>
        <Col full margin="0 0 5px">
          <TextInput
            isRequired
            id="rentPaymentLastNameInput"
            className="createTenantInputs"
            name="lastName"
            label="Last name"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName && errors.lastName}
          />
        </Col>
        <Col full margin="0 0 5px">
          <TextInput
            isRequired
            id="rentPaymentEmailInput"
            className="createTenantInputs"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
          />
        </Col>
        <Col full margin="0 0 5px">
          <FormattedInput
            formatType="phone"
            id="rentPaymentPhoneInput"
            className="createTenantInputs"
            value={values.phone}
            name="phone"
            label="Phone"
            onValueChange={handlePhoneValueChange}
            onBlur={handleBlur}
            error={touched.phone && errors.phone}
          />
        </Col>
      </Row>
      <ButtonSetV2
        id="createRentPaymentTenant"
        margin="10px 0 0"
        minWidth="96"
        continueBtnType="submit"
        continueText="Add tenant"
        prevText="Cancel"
        btnHeight="40px"
        isLoading={isLoading}
        isSuccess={isLoaded}
        onClickPrevBtn={onCancel}
        backBtnMargin="0"
        hideBackButton
        hideIconNextButton
        hideIconPrevButton
        onBorderDesktop
        onBorder
        mFullWidthButton
        withSystemLayout
      />
    </form>
  )
}

export const CreateTenantForm = withFormik(schema)(InnerForm)
