import React from 'react'

import IncomeVerificationExpiredPage from 'components/reports/screening-reports/income-verification/report-expired-page'
import IncomeVerificationErrorPage from 'components/reports/screening-reports/income-verification/error-page'
import { INCOME_VERIFICATION_STATE, REPORT_UNAVAILABLE_PAGE_CONTENT } from '../constants'
import { getIncomeVerificationExpireDate } from '../helper'

export const ReportMetaDataHandler = ({
  children,
  status,
  reportData,
  createdDate,
  isFromAllTab,
  applicantDetail,
  handleManageDocumentButton,
}) => {
  switch (status) {
    case INCOME_VERIFICATION_STATE.CREATED:
    case INCOME_VERIFICATION_STATE.STARTED:
    case INCOME_VERIFICATION_STATE.LINKED:
    case INCOME_VERIFICATION_STATE.SUBMITTED:
    case INCOME_VERIFICATION_STATE.READY: {
      if (reportData === null)
        return (
          <IncomeVerificationErrorPage
            pageContent={REPORT_UNAVAILABLE_PAGE_CONTENT}
            onCtaClick={handleManageDocumentButton}
            isFromAllTab={isFromAllTab}
            applicantDetail={isFromAllTab && applicantDetail}
          />
        )
      break
    }
    case INCOME_VERIFICATION_STATE.EXPIRED: {
      return (
        <IncomeVerificationExpiredPage
          expiredDate={getIncomeVerificationExpireDate(createdDate)}
          isFromAllTab={isFromAllTab}
          applicantDetail={isFromAllTab && applicantDetail}
        />
      )
    }
    default: {
      break
    }
  }
  if (status === INCOME_VERIFICATION_STATE.EXPIRED) {
    return (
      <IncomeVerificationExpiredPage
        expiredDate={getIncomeVerificationExpireDate(createdDate)}
        isFromAllTab={isFromAllTab}
        applicantDetail={isFromAllTab && applicantDetail}
      />
    )
  }
  return children
}
