import { takeLatest, put, call } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'
import { ALERT_PRESET } from 'redux-middleware/sweet-alert'
import { openSweetAlert } from 'utils/sweet-alert-actions'

import { DELETE_RENTAL_PAYMENT, deleteRentalPaymentAPI } from '../../actions'
import { deleteRentalPaymentInstance } from '../../api-instances'
import { DELETE_RENTAL_PAYMENT_MESSAGE } from '../../constants'

export function* deleteRentPaymentSaga({ payload }) {
  yield put(deleteRentalPaymentAPI.request())
  const { rentalPaymentId, onSuccess, onError } = payload

  try {
    yield call(deleteRentalPaymentInstance, { rentalPaymentId })
    yield put(deleteRentalPaymentAPI.success())
    yield call(onSuccess)
    yield put(addToast(DELETE_RENTAL_PAYMENT_MESSAGE.SUCCESS))
  } catch (err) {
    yield put(deleteRentalPaymentAPI.failure())
    yield put(
      openSweetAlert({
        preset: ALERT_PRESET.TRY_AGAIN,
        option: {
          title: DELETE_RENTAL_PAYMENT_MESSAGE.ERROR.title,
          text: DELETE_RENTAL_PAYMENT_MESSAGE.ERROR.text,
        },
      }),
    )
    yield call(onError)
  }
}

export function* watchDeleteRentPaymentSaga() {
  yield takeLatest(DELETE_RENTAL_PAYMENT, deleteRentPaymentSaga)
}
