import generateApiCall from "utils/generate-api-call"
import {
  UPDATE_DRE_LICENSE_CALL,
  UPDATE_DRE_LICENSE_REQUEST,
  UPDATE_DRE_LICENSE_SUCCESS,
  UPDATE_DRE_LICENSE_FAILED,
} from "./constants"

export const updateDreLicense = payload => ({
  type: UPDATE_DRE_LICENSE_CALL,
  payload,
})

export const updateDreLicenseApi = generateApiCall([
  UPDATE_DRE_LICENSE_REQUEST,
  UPDATE_DRE_LICENSE_SUCCESS,
  UPDATE_DRE_LICENSE_FAILED,
])
