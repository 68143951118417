import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

import { PageIndexesContext, PageProgressionContext } from '../../context'

import { CreateButtons } from './utils'

export const DrawerFooter = ({
  buttonLayout,
  textOverrides,
  onProgress,
  onBack,
  onNext,
  nextEnabled = true,
}) => {
  /*
   * Leverages StickyFooterTemplate for the bottom sticky bar, and then a Grid/flexbox
   * layout for the various button configurations.
   */
  const [pageIndexes] = useContext(PageIndexesContext)
  const contextProgressHandler = useContext(PageProgressionContext)
  const progressionHandler = typeof onProgress === 'function' ? onProgress : contextProgressHandler
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  return (
    <StickyFooterTemplate
      position="absolute"
      variant="navigation"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        // StickyFooterTemplate overrides 'left: 88px' unless we force importance, unfortunately
        left: '0!important',
        width: '100%',
        bottom: 0,
        zIndex: 1300,
      }}
      className="PaymentDrawerFooter"
    >
      {CreateButtons(
        pageIndexes,
        progressionHandler,
        buttonLayout,
        textOverrides,
        nextEnabled,
        isMobile,
        onBack,
        onNext,
      )}
    </StickyFooterTemplate>
  )
}
