/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */

import produce from 'immer'

import {
  CLOSE_ADD_YOUR_INFORMATION_MODAL,
  CLOSE_CREATE_PROPERTY_MODAL,
  CLOSE_CREATE_RENTER_INFO_MODAL,
  CLOSE_MARK_AS_PAID_MODAL,
  OPEN_ADD_YOUR_INFORMATION_MODAL,
  OPEN_CREATE_PROPERTY_MODAL,
  OPEN_CREATE_RENTER_INFO_MODAL,
  OPEN_MARK_AS_PAID_MODAL,
} from '../actions'

export const rentPaymentModalInitialState = {
  updateYourInformationModalState: {
    isOpen: false,
    returnUrl: undefined,
  },
  markAsPaid: {
    isOpen: false,
    paymentRequestId: undefined,
  },
  createProperty: {
    isOpen: false,
  },
  createRenterInfo: {
    isOpen: false,
  },
}

export const rentPaymentModalStateReducer = (
  state = rentPaymentModalInitialState,
  { type, payload },
) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_ADD_YOUR_INFORMATION_MODAL:
        draft.updateYourInformationModalState.isOpen = true
        draft.updateYourInformationModalState.returnUrl = payload.returnUrl
        break
      case CLOSE_ADD_YOUR_INFORMATION_MODAL:
        draft.updateYourInformationModalState.isOpen = false
        break
      case OPEN_MARK_AS_PAID_MODAL:
        draft.markAsPaid.isOpen = true
        draft.markAsPaid.paymentRequestId = payload.paymentRequestId
        break
      case CLOSE_MARK_AS_PAID_MODAL:
        draft.markAsPaid.isOpen = false
        break
      case OPEN_CREATE_PROPERTY_MODAL:
        draft.createProperty.isOpen = true
        break
      case CLOSE_CREATE_PROPERTY_MODAL:
        draft.createProperty.isOpen = false
        break
      case OPEN_CREATE_RENTER_INFO_MODAL:
        draft.createRenterInfo.isOpen = true
        break
      case CLOSE_CREATE_RENTER_INFO_MODAL:
        draft.createRenterInfo.isOpen = false
        break
      default:
        break
    }
    return draft
  })
