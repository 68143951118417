import createAction from "../apis/utils/create-action"
import * as Types from "../constants/action-types"
import * as api from "../apis/property-list.api"

export const getPropertyList = createAction(
  api.fetchPropertyList,
  [
    Types.GET_PROPERTY_LIST_REQUEST,
    Types.GET_PROPERTY_LIST_SUCCESS,
    Types.GET_PROPERTY_LIST_FAILED,
  ],
  (res = {}) => ({
    data: res.data,
    pagination: res.pagination,
  }),
)

export const getPropertyDropdown = createAction(
  api.fetchPropertyDropdown,
  [
    Types.GET_PROPERTY_DROP_DOWN_REQUEST,
    Types.GET_PROPERTY_DROP_DOWN_SUCCESS,
    Types.GET_PROPERTY_DROP_DOWN_FAILED,
  ],
  res => {
    const data = res.result || {}
    return { propertyDropdown: data.properties }
  },
)
