import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { printReport, savePDF } from "containers/reports/actions"
import * as RefCheckActions from "legacy/actions/ref-check.action"
import { getApplicationDetail } from "legacy/actions/application.action"
import { makeSelectProducts } from "legacy/containers/pro/landing/selectors"
import { selectRefChecks } from "./selectors"
import { selectAuthorize } from "../other-doc/selectors"
import {
  selectApplicationDetail,
  selectRentalDetail,
  selectIsFetchingAppDetail,
  selectParticipantType,
  selectPdfReport,
} from "../selectors"

export const mapStateToProps = createStructuredSelector({
  refCheck: selectRefChecks,
  applicationDetail: selectApplicationDetail,
  rentalDetail: selectRentalDetail,
  isFetchingAppDetail: selectIsFetchingAppDetail,
  authorize: selectAuthorize,
  participantType: selectParticipantType,
  pdfReport: selectPdfReport,
  products: makeSelectProducts(),
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RefCheckActions,
      getApplicationDetail,
      printReport,
      savePDF,
    },
    dispatch,
  )

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
