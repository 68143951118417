import { TABS_TYPE } from 'components/tasks/templates/task-dashboard-template/constant'
import { API_URL } from 'env'

export const ACTION_DROPDOWN_ITEMS = [
  {
    label: 'Accept or Deny',
    value: 'application_submitted',
  },
  {
    label: 'Set up lease',
    value: 'screening_request_accepted',
  },
  {
    label: 'Share with Landlord',
    value: 'share_to_landlord',
  },
]

export const TOOLTIP_MOBILE_TITLE = 'Task Suggestion'

export const TASK_ERROR_TOAST_MESSAGE = 'Error occurred, please try again'

const SCREENING_REQUEST_ACCEPTED_TEMPLATE_NAME = 'screening_request_accepted'
const APPLICATION_SUBMITTED_TEMPLATE_NAME = 'application_submitted'
const SHARE_TO_LANDLORD_TEMPLATE_NAME = 'share_to_landlord'
const SUBMITTED_TOOLTIP_MESSAGE = 'Task suggested because you viewed the Tenant Screening report.'
const ACCEPTED_TOOLTIP_MESSAGE = 'Task suggested because you sent an approval letter.'
const SHARE_TO_LANDLORD_TOOLTIP_MESSAGE =
  'Task suggested because you viewed the Tenant Screening report.'

export const TOOLTIP_MESSAGE = {
  [SCREENING_REQUEST_ACCEPTED_TEMPLATE_NAME]: ACCEPTED_TOOLTIP_MESSAGE,
  [APPLICATION_SUBMITTED_TEMPLATE_NAME]: SUBMITTED_TOOLTIP_MESSAGE,
  [SHARE_TO_LANDLORD_TEMPLATE_NAME]: SHARE_TO_LANDLORD_TOOLTIP_MESSAGE,
}

export const PAGE_LIMIT = 8

export const TASK_TYPE = {
  INCOMPLETED: 'incompleted',
  COMPLETED: 'completed',
}

export const DISPLAY_TYPE = {
  LAZYLOADED: 'lazyloaded',
  PAGINATED: 'paginated',
}

export const MAPPING_ACTIVE_TAB_TO_TASK_TYPE = {
  [TABS_TYPE.OUTSTANDING]: TASK_TYPE.INCOMPLETED,
  [TABS_TYPE.COMPLETED]: TASK_TYPE.COMPLETED,
}

export const TASK_API = `${API_URL}/api/v2/people/tasks/:taskId`
export const GET_TASK_LIST_API = `${API_URL}/api/v2/people/tasks`
export const GET_COUNT_TASK_API = `${API_URL}/api/v2/people/tasks/counts`
export const MARK_TASK_API = `${API_URL}/api/v2/people/tasks/:taskId/mark`
export const SET_SEEN_API = `${API_URL}/api/v2/people/tasks/batch/seen`
export const DELETE_TASK_API = `${API_URL}/api/v2/people/tasks/:taskId`

export const TASK_TASK_API = `${API_URL}/api/v2/tasks/tasks/:taskId`
export const TASK_GET_TASK_LIST_API = `${API_URL}/api/v2/tasks/tasks`
export const TASK_GET_COUNT_TASK_API = `${API_URL}/api/v2/tasks/tasks/counts`
export const TASK_MARK_TASK_API = `${API_URL}/api/v2/tasks/tasks/:taskId/mark`
export const TASK_SET_SEEN_API = `${API_URL}/api/v2/tasks/tasks/batch/seen`
export const TASK_DELETE_TASK_API = `${API_URL}/api/v2/tasks/tasks/:taskId`

export const GET_TASK_LIST_CALL = 'GET_TASK_LIST_CALL'
export const GET_TASK_LIST_REQUEST = 'GET_TASK_LIST_REQUEST'
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS'
export const GET_TASK_LIST_FAILURE = 'GET_TASK_LIST_FAILURE'

export const GET_COUNT_TASK_CALL = 'GET_COUNT_TASK_CALL'
export const GET_COUNT_TASK_REQUEST = 'GET_COUNT_TASK_REQUEST'
export const GET_COUNT_TASK_SUCCESS = 'GET_COUNT_TASK_SUCCESS'
export const GET_COUNT_TASK_FAILURE = 'GET_COUNT_TASK_FAILURE'

export const MARK_TASK_FROM_TASK_LIST_CALL = 'MARK_TASK_FROM_TASK_LIST_CALL'
export const MARK_TASK_FROM_TASK_LIST_REQUEST = 'MARK_TASK_FROM_TASK_LIST_REQUEST'
export const MARK_TASK_FROM_TASK_LIST_SUCCESS = 'MARK_TASK_FROM_TASK_LIST_SUCCESS'
export const MARK_TASK_FROM_TASK_LIST_FAILURE = 'MARK_TASK_FROM_TASK_LIST_FAILURE'

export const SET_SEEN_TASKS_CALL = 'SET_SEEN_TASKS_CALL'
export const SET_SEEN_TASKS_REQUEST = 'SET_SEEN_TASKS_REQUEST'
export const SET_SEEN_TASKS_SUCCESS = 'SET_SEEN_TASKS_SUCCESS'
export const SET_SEEN_TASKS_FAILURE = 'SET_SEEN_TASKS_FAILURE'

export const MARK_TASK_TOAST_SUCCESS = 'Task Done'
export const MARK_TASK_TOAST_FAILURE = 'Error occurred, please try again'

export const APPLY_SORT_TASK = 'APPLY_SORT_TASK'

export const DELETE_FROM_TASK_LIST_CALL = 'DELETE_FROM_TASK_LIST_CALL'
export const DELETE_FROM_TASK_LIST_REQUEST = 'DELETE_FROM_TASK_LIST_REQUEST'
export const DELETE_FROM_TASK_LIST_SUCCESS = 'DELETE_FROM_TASK_LIST_SUCCESS'
export const DELETE_FROM_TASK_LIST_FAILURE = 'DELETE_FROM_TASK_LIST_FAILURE'
export const DELETE_TASK_SUCCESS_TOAST_MESSAGE = 'Task Deleted'

export const UPDATE_TASK_FROM_TASK_LIST_CALL = 'UPDATE_TASK_FROM_TASK_LIST_CALL'
export const UPDATE_TASK_FROM_TASK_LIST_REQUEST = 'UPDATE_TASK_FROM_TASK_LIST_REQUEST'
export const UPDATE_TASK_FROM_TASK_LIST_SUCCESS = 'UPDATE_TASK_FROM_TASK_LIST_SUCCESS'
export const UPDATE_TASK_FROM_TASK_LIST_FAILURE = 'UPDATE_TASK_FROM_TASK_LIST_FAILURE'

export const UPDATE_TASK_TOAST_SUCCESS = 'Task Updated'
export const UPDATE_TASK_TOAST_FAILURE = 'Error occurred, please try again'
