import React from "react"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { query } from "@rentspree/path"
import { REQUEST } from 'constants/route'
import { parseUserProfile } from "containers/reports/mailing-address/mapper"
import { MailingAddressForm } from "components/mailing-address/form"
import tracker from "tracker"
import { EVENT_MAILING_ADDRESS } from "tracker/const"
import { ALERT_PRESET } from "legacy/libs/sweet-alert/sweet-alert"
import * as ERRORS from "legacy/constants/error-messages"
import { RequestContainer } from "legacy/components/request/request-main-style"
import { LoadingOverlay } from "legacy/components/request/left-content"

const StepUpdateInfo = props => {
  React.useEffect(() => {
    tracker.trackEvent(
      EVENT_MAILING_ADDRESS.enterMailingAddressPage,
    )
  }, [])
  const { isUpdatingProfile } = props

  const handleSubmit = async values => {
    const { actions } = props
    const { updateProfile } = actions
    const res = await updateProfile(parseUserProfile(values))
    if (!res || isEmpty(res.profile)) {
      const { openSweetAlert } = actions
      openSweetAlert({
        preset: ALERT_PRESET.ERROR,
        option: {
          title: ERRORS.USER.UPDATE_PROFILE_FAILED.TITLE,
          text: ERRORS.USER.UPDATE_PROFILE_FAILED.MESSAGE,
        },
      })
      return res
    }
    const queryString = query.parse(get(props, ["location", "search"]))
    const { continuePath, originContinuePath, ...rest } = queryString
    tracker.trackEvent(EVENT_MAILING_ADDRESS.saveMailingAddress)
    const search = query.stringify({
      continuePath: originContinuePath,
      ...rest,
    })
    return props.history.push({
      pathname: continuePath || REQUEST,
      search: props.location ? search : undefined,
      state: props.location?.state,
    })
  }

  return (
    <RequestContainer>
      {isUpdatingProfile && <LoadingOverlay />}
      <MailingAddressForm onSubmit={handleSubmit} {...props} />
    </RequestContainer>
  )
}

export default StepUpdateInfo
