import {
  ResizeProcessingOption,
  SizeProcessingOption,
} from "./processing-options"
import { SourceUrl } from "./source-url/source-url"

export class ImageProxy {
  processingOptions = {
    resizeProcessingOptions: new ResizeProcessingOption({}),
    sizeProcessingOptions: new SizeProcessingOption({}),
  }

  imageProxyHost

  processedOptions = {}

  constructor(imageProxyHost) {
    this.imageProxyHost = `${imageProxyHost}/insecure`
  }

  parseProcessingOptions() {
    return Object.values(this.processedOptions).join("/")
  }

  resize(resizeOptions) {
    const optionsParsed = this.processingOptions.resizeProcessingOptions
      ?.update(resizeOptions)
      ?.parseOptions()

    this.processedOptions.resize = optionsParsed
    return this
  }

  size(sizeOptions) {
    const optionsParsed = this.processingOptions.sizeProcessingOptions
      ?.update(sizeOptions)
      ?.parseOptions()

    this.processedOptions.size = optionsParsed
    return this
  }

  generateUrl(imageSource, encode = false, extension) {
    const source = new SourceUrl(imageSource)
    return [
      this.imageProxyHost,
      this.parseProcessingOptions(),
      source.getImageSource(encode, extension),
    ]
      .filter(text => text)
      .join("/")
  }
}
