import generateApiCall from 'utils/generate-api-call'

import {
  GET_CONTACT_CALL,
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  CLEAR_CONTACT,
  DELETE_CONTACT_CALL,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  ADD_TO_CONTACT_CALL,
  ADD_TO_CONTACT_REQUEST,
  ADD_TO_CONTACT_SUCCESS,
  ADD_TO_CONTACT_FAILURE,
} from './constants'

export const getContactByEmailApiState = generateApiCall([
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
])

export const deleteContactApiState = generateApiCall([
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
])

export const getContactByEmail = email => ({
  type: GET_CONTACT_CALL,
  payload: { email },
})

export const clearContactByEmail = () => ({
  type: CLEAR_CONTACT,
})

export const deleteContact = (contactId, callback) => ({
  type: DELETE_CONTACT_CALL,
  payload: { contactId, callback },
})

export const addToContactApiState = generateApiCall([
  ADD_TO_CONTACT_REQUEST,
  ADD_TO_CONTACT_SUCCESS,
  ADD_TO_CONTACT_FAILURE,
])

export const addToContact = contact => ({
  type: ADD_TO_CONTACT_CALL,
  payload: { contact },
})
