import React from "react"
import { useSelector } from "react-redux"
import { useDrop } from "react-dnd"
import has from "lodash/has"
import isEmpty from "lodash/isEmpty"
import find from "lodash/find"
import get from "lodash/get"
import { v4 as uuidv4 } from "uuid"
import tracker from "tracker"
import { LEASE_AGREEMENTS } from "tracker/const"
import {
  makeSelectActiveIdPage,
  makeSelectActiveIdField,
} from "containers/envelope/selectors"

import { MUST_ASSIGNED_COMPONENTS, ACCEPTED_TYPES } from "./constants"
import { useDimensions } from "./helpers/use-dimensions"
import { calculatePosition, OPERATIONS } from "./helpers/calculate-position"

export const DropBox = Component => ({
  page,
  dropBoxes,
  actions,
  width,
  height,
  recipientsList,
  viewMode,
  validationErrors = {},
  showSignError = false,
  signErrors = {},
  small,
  ratio,
  targetRef,
  ...props
}) => {
  const activeIdPage = useSelector(makeSelectActiveIdPage())
  const activeIdField = useSelector(makeSelectActiveIdField())
  const [dimensions] = useDimensions(targetRef, ratio)

  const [, drop] = useDrop({
    accept: ACCEPTED_TYPES,
    drop: (item, monitor) => {
      const containerRect =
        targetRef.current?.getBoundingClientRect().toJSON() || {}
      const initialSourceClientOffset = monitor.getInitialSourceClientOffset()
      const initialClientOffset = monitor.getInitialClientOffset()
      const destination = monitor.getSourceClientOffset()
      const diffBetweenPosition = {
        x: initialClientOffset.x - initialSourceClientOffset.x,
        y: initialClientOffset.y - initialSourceClientOffset.y,
      }

      if (item.initBox) {
        /* First drop */
        const id = uuidv4()
        tracker.trackEvent(LEASE_AGREEMENTS.PLACE_COMPONENT, {
          component_type: LEASE_AGREEMENTS.COMPONENT_TYPES[item.type],
        })
        actions.addChildren({
          page,
          box: {
            ...calculatePosition(OPERATIONS.INIT, {
              destination: {
                x: destination.x + diffBetweenPosition.x,
                y: destination.y + diffBetweenPosition.y,
              },
              parentDimensions: { ...containerRect },
              ratio,
            }),
            type: item.type,
            element: item.element,
            styles: item.styles,
            value: item.value,
            assignee: item.assignee,
            colorIndex: item.colorIndex,
            fieldId: id,
            page,
            parentDimensions: dimensions,
            recipient: item.recipient,
          },
        })
      } else if (item.page !== page) {
        /* Drag field to other pages */
        const { x, y } = monitor.getSourceClientOffset()
        actions.changeBoxPage({
          sourcePage: item.page,
          destinationPage: page,
          fieldId: item.fieldId,
          x,
          y,
          width,
          height,
          recipient: item.recipient,
          currentParentDimensions: item.parentDimensions,
          currentDimensions: item.dimensions,
          newDimensions: containerRect,
          ratio,
        })
      } else {
        /* Drag field to other position in the same page */
        const { x, y } = monitor.getDifferenceFromInitialOffset()
        const targetBox = find(dropBoxes, { fieldId: item.fieldId })
        const colorIndex = isEmpty(targetBox.assignee)
          ? -1
          : targetBox.colorIndex
        actions.setChildrenBox({
          page,
          box: {
            ...targetBox,
            ...calculatePosition(OPERATIONS.MOVE, {
              destination: {
                x: Math.round(targetBox.left * ratio + x),
                y: Math.round(targetBox.top * ratio + y),
              },
              parentDimensions: {},
              dimensions: item.dimensions,
              recipient: item.recipient,
              ratio,
            }),
            colorIndex,
          },
        })
      }

      actions.triggerAutosaveEnvelope()
    },
  })

  drop(targetRef)

  return (
    <Component
      ref={targetRef}
      onClick={actions.resetActive}
      {...props}
      style={{ width }}>
      {props.children}
      {dropBoxes
        .filter(({ visible = true }) => visible)
        .map(box => {
          const findRecipient = recipientsList.find(
            ({ value: val, roleId }) =>
              val === box.assignee || roleId === box.assignee,
          )
          const recipient = get(findRecipient, "label", "")

          if (!recipient && MUST_ASSIGNED_COMPONENTS.includes(box.type)) {
            return <></>
          }

          const isActiveField = activeIdField === box.fieldId
          const isActivePage = activeIdPage === box.page
          const active = isActiveField && isActivePage

          const colorIndex = isEmpty(box.assignee) ? -1 : box.colorIndex
          const showError =
            has(validationErrors, box.fieldId) ||
            (showSignError && has(signErrors, box.fieldId))

          return (
            <Field
              ratio={ratio}
              key={box.fieldId}
              setActive={actions.setActive}
              setChildrenBox={actions.setChildrenBox}
              triggerAutosaveEnvelope={actions.triggerAutosaveEnvelope}
              setConfigBox={actions.setConfigBox}
              active={active}
              viewMode={viewMode}
              page={page}
              box={box}
              dimensions={dimensions}
              showError={showError}
              colorIndex={colorIndex}
              recipient={recipient}
              small={small}
            />
          )
        })}
    </Component>
  )
}

export const Field = React.memo(
  ({
    setActive,
    setChildrenBox,
    triggerAutosaveEnvelope,
    setConfigBox,
    active,
    viewMode,
    page,
    box,
    dimensions,
    showError,
    colorIndex,
    recipient,
    small,
    ratio = 1,
  }) => {
    const handleClick = React.useCallback(() => {
      if (!active) {
        setActive(box)
      }
    }, [active, box, setActive])

    const handleChangeData = React.useCallback(
      data => {
        setChildrenBox({ page, box: { ...box, value: data } })
        triggerAutosaveEnvelope()
      },
      [page, box, triggerAutosaveEnvelope],
    )

    const handleChangeStyle = React.useCallback(
      (config, item = {}) => {
        setConfigBox({
          page: item.page,
          fieldId: item.fieldId,
          config,
        })
        triggerAutosaveEnvelope()
      },
      [setConfigBox, triggerAutosaveEnvelope],
    )
    return (
      <box.element
        ratio={ratio}
        viewMode={viewMode}
        item={box}
        {...box}
        colorIndex={viewMode ? 0 : colorIndex}
        recipient={viewMode ? null : recipient}
        parentDimensions={dimensions}
        active={active}
        onClick={handleClick}
        onChangeData={handleChangeData}
        onChangeStyle={handleChangeStyle}
        showError={showError}
        small={small}>
        {box.children}
      </box.element>
    )
  },
)
