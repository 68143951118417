import { buildPath } from '@rentspree/path'
import moment from 'moment'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { CONFIRM_DRAFT_RENTAL_PAYMENT_API } from './constants'

export const confirmDraftRentalPaymentInstance = ({
  propertyId,
  rentalPaymentId,
  invitationDetail,
  hasEligiblePaymentForIncentiveV5,
}) => {
  let payload = { timezone: moment.tz.guess(), invitationDetail }
  if (hasEligiblePaymentForIncentiveV5) {
    payload = { ...payload, campaignName: 'landlord-gets-advance-payment' }
  }

  return apiInstanceWithErrorHandler.post(
    buildPath(CONFIRM_DRAFT_RENTAL_PAYMENT_API, { propertyId, rentalPaymentId }),
    payload,
  )
}
