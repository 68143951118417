import { getAcceptDenyFactory } from "@rentspree/helper"

export const generateTemplateEmail = ({
  type,
  property,
  rentalDetail,
  landlord,
  condition,
}) => {
  const generator = getAcceptDenyFactory(type)
  const obj = generator.generateEmailJSONContent(
    property,
    rentalDetail,
    landlord,
    condition,
  )
  return {
    data: obj,
    html: generator.generateContentInHTML(obj),
  }
}
