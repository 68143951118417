import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import injectSaga from "utils/inject-saga"

import { selectProfile } from "containers/user/selectors"
import saga from "./saga"
import { sendEmailVerificationCall } from "./actions"

const mapStateToProps = createStructuredSelector({
  profile: selectProfile,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendEmailVerificationCall,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "emailVerification", saga })
