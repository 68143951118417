import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getCreateContactModal = state =>
  get(state, 'contacts.createContactModal', initialState)

export const selectIsOpen = createSelector(getCreateContactModal, modal =>
  get(modal, 'isOpen', initialState.isOpen),
)

export const selectIsSubmitting = createSelector(getCreateContactModal, modal =>
  get(modal, 'isSubmitting', initialState.isSubmitting),
)
