import styled from "styled-components"

import { COLOR } from "styles/settings"

export const DiamondWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 70px);
  overflow-x: hidden;
  padding: 50px 0;
  position: relative;
  background: ${COLOR.white};
  @media (max-width: 991px) {
    padding: 30px 0;
  }
`

export const DiamondContainer = styled.div`
  width: ${({ width }) => width || "500px"};
  max-width: ${({ maxWidth }) => maxWidth || "initial"};
  margin: ${({ margin }) => margin || "initial"};
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`
