import { createSelector } from "reselect"
import get from "lodash/get"

const getPropertyType = state => get(state, "propertyType")

export const selectIsLoading = () =>
  createSelector(
    getPropertyType,
    state => get(state, "isFetching", false),
  )

export const selectIsError = () =>
  createSelector(
    getPropertyType,
    state => get(state, "isError", false),
  )

export const makeSelectError = () =>
  createSelector(
    getPropertyType,
    state => get(state, "error", null),
  )
