import { buildPath, query as queryPath } from '@rentspree/path'
import React, { useEffect, useState } from 'react'

import { CenterContentTemplate } from 'components/templates/center-content-template'
import * as ROUTE from 'constants/route'
import tracker from 'tracker'
import { RENT_PAYMENT as RENT_PAYMENT_EVENTS } from 'tracker/const'

import { STORAGE_KEYS } from '../constants'
import { saveDraftingPaymentStorage } from '../utils'

import { PAGE_TITLE, SUB_HEADER, PANEL_ERROR_MESSAGE } from './constants'
import Form from './main-form'

export const PageAgentInitiateDraftPayments = props => {
  const { location, history } = props
  const query = new URLSearchParams(location.search)

  const source = query.get('source')
  const [showPanelError, setShowPanelError] = useState(false)
  useEffect(() => {
    /*
     * FIXME: temporary fixed no scrolling to top of the page when loading the page first time issue.
     * Once the page has been migrated to the component 2023, please reimplement this logic.
     */
    document.getElementById('wrapper').scrollIntoView({ block: 'start', behavior: 'smooth' })

    tracker.trackEvent(RENT_PAYMENT_EVENTS.EVENT_NAME.START_SET_UP_MOVE_IN_FEE, {
      click_from: source,
    })
  }, [])

  const handleSubmit = values => {
    const payment = {
      ...values,
      property: {
        fullProperty: values.selectedProperty.label,
        id: values.selectedProperty.value,
      },
    }
    const queryString = queryPath.parse(location.search, [location.search])
    saveDraftingPaymentStorage(STORAGE_KEYS.AGENT_DRAFTING_PAYMENT, payment)
    history.push(
      buildPath(
        ROUTE.RENT_PAYMENT_REQUEST_AGENT_INITIATED_CONFIRMATION_PAGE,
        {},
        {
          source: queryString.source,
          property: payment.property.id,
        },
      ),
      history.location.state,
    )
  }

  return (
    <CenterContentTemplate
      title={PAGE_TITLE}
      isError={showPanelError}
      errorDetail={PANEL_ERROR_MESSAGE}
      handleCloseError={() => setShowPanelError(false)}
      description={
        <div data-testid="subheaderContainer" style={{ marginTop: '30px' }}>
          {SUB_HEADER}
        </div>
      }
    >
      <Form {...props} setShowPanelError={setShowPanelError} onSubmit={handleSubmit} />
    </CenterContentTemplate>
  )
}
