import React, { useState } from "react"
import { compose } from "redux"
import ConfirmationModal from "components/organisms/confirmation-modal"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import {
  FEATURE_STATUS,
  CONFIRM_TEXT,
  TITLE,
  RADIO,
} from "containers/property/toggle-property-feature/constants"
import SubtitleWithRadio from "components/molecules/subtitle-with-radio"
import Body from "./body"
import { withConnect, withSaga } from "./connect"

const { ENABLE, DISABLED } = FEATURE_STATUS

export const TogglePropertyFeatureModal = ({
  actions,
  feature,
  type,
  isOpen,
  isLoading,
  propertyId,
  modalRootId,
  isPropertyArchived,
}) => {
  const [remark, setRemark] = useState("")
  const handleChangeRemark = e => {
    setRemark(e.target.value)
  }
  const handleClose = () => {
    setRemark("")
    actions.clearTogglePropertyFeatureModal()
  }
  const handleConfirm = () => {
    if (type === DISABLED) {
      actions.disableFeature({
        propertyId,
        data: { feature, remark },
      })
    } else if (type === ENABLE) {
      actions.enableFeature({ propertyId, data: { feature } })
    }
    setRemark("")
  }
  const isConfirmButtonDisabled = type === DISABLED && !remark
  const title = TITLE[type]
  const confirmTxt = CONFIRM_TEXT[type]
  return (
    <ConfirmationModal
      confirmTxt={confirmTxt}
      onClickConfirm={handleConfirm}
      isOpen={isOpen}
      modalSize="small"
      isLoading={isLoading}
      rootId={modalRootId}
      onClose={handleClose}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      title={title}>
      <B16>
        <Body type={type} isPropertyArchived={isPropertyArchived} />
      </B16>
      {type === DISABLED && (
        <SubtitleWithRadio
          subtitle={RADIO.subtitle}
          choices={RADIO.choices}
          onChange={handleChangeRemark}
        />
      )}
    </ConfirmationModal>
  )
}

export default compose(
  withConnect,
  withSaga,
)(TogglePropertyFeatureModal)
