import React from 'react'
import styled from 'styled-components'
import { Icon } from '@rentspree/component-v2/dist/icon'
import { buildPath } from '@rentspree/path'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PrintIcon from '@mui/icons-material/Print'
import DownloadIcon from '@mui/icons-material/Download'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import ContainedButton from '@rentspree/component-2023.components.atoms.button'
import Menu from '@rentspree/component-2023.components.atoms.menu'
import MenuItem from '@rentspree/component-2023.components.atoms.menu-item'

import { HEADER_COLOR } from 'containers/accept-deny/constants'
import { Button } from 'legacy/components/reports-v2/share/header-button-style'
import { isFirefoxAndroid } from 'legacy/components/helper/ua-parser-js'
import Badge from 'containers/badge/badge-component'
// TODO move this scss to styled-components
import 'legacy/components/reports-v2/share/tooltip-style.scss'
import { COLOR } from 'styles/settings'
import ExistingSubmissionErrorModal from 'components/templates/tenant-screening/existing-submission-error-modal'
import { SINGLE_APPLICATION } from 'constants/route'
import AcceptDenyStatus from 'components/atoms/accept-deny-status'
import { SHARE_MS_MODAL_STATE } from 'containers/reports/header/const'

const HeaderPrintContainer = styled.div`
  background-color: ${({ result, isPrintPage, showMsHeader }) => {
    if (showMsHeader) return `${COLOR.whiteHeader};border-bottom: 1px solid var(--comp-table-row-container-outline-color, rgba(34, 34, 34, 0.12))`
    if (isPrintPage) return COLOR.steelBlueDark
    return HEADER_COLOR[result] || COLOR.steelBlueDark
  }};
  width: 100%;
  max-width: 760px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px ${props => props.showMsHeader ? "16px" : "30px"};
  justify-content: space-between;
  @media print {
    display: none;
  }
`

const Container = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.p`
  color: white;
  margin-top: 0;
  font-size: 12px;
  margin-bottom: -10px;
  ${props =>
    props.PC &&
    `
  @media(max-width: 991px) {
    display: none !important;
  }
  `}
  ${props =>
    props.Mobile &&
    `
  @media(min-width: 992px) {
    display: none !important;
  }
  `}
`

const ButtonContainer = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
  text-align: center;
  padding-bottom: 7px;
  text-align: -webkit-center;
  cursor: pointer;
  &:hover ${Button} {
    background-color: rgba(255, 255, 255, 0.2);
  }
  ${props =>
    props.Mobile &&
    `
  margin-left: -20px
  `}
`

const MenuItemWidth = "187px"

export const ReportHeader = ({
  pdfReport,
  isPrintPage,
  isShareAvailable,
  result,
  onClickPrintReport,
  onClickDownloadPdfDesktop,
  onClickDownloadPdfMobile,
  onClickShare,
  isAllowAcceptDeny,
  existingSubmissionErrorModal,
  openExistingSubmissionErrorModal,
  history,
  handleClickShareMsReport,
  handleClickMenu,
  showMsHeader = false,
  showShareMsButton,
}) => {
  const [openReportMenu, setOpenReportMenu] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
    setOpenReportMenu(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpenReportMenu(false)
  }
  
  return (
    <HeaderPrintContainer isPrintPage={isPrintPage} showMsHeader={showMsHeader}>
      <Container>
        {result && !isPrintPage && isAllowAcceptDeny && <AcceptDenyStatus result={result} />}
      </Container>
      <Container>
        {
          showMsHeader ? (
            <>
              { showShareMsButton && (
                <ContainedButton 
                  variant="contained"
                  color="secondary"
                  size="small"
                  data-testid="report-header-share-application-button"
                  onClick={() => handleClickShareMsReport(false)}>
                    Share application
                </ContainedButton>
              )}
              <IconButton color="black" data-testid="report-header-more-button" onClick={(e) => handleOpenMenu(e)}><MoreVertIcon /></IconButton>
              <Menu autoFocus={false} anchorEl={anchorEl} open={openReportMenu} onClose={handleClose}>
                <MenuItem 
                  adornmentStart={<PrintIcon />}
                  label="Print"
                  style={{width: MenuItemWidth}}
                  data-testid="report-header-print-button"
                  onClick={() => handleClickMenu(SHARE_MS_MODAL_STATE.PRINT)} />
                <MenuItem 
                  adornmentStart={<DownloadIcon />}
                  disabled={pdfReport.isFetching}
                  label={pdfReport.isFetching ? "Downloading" : "Download"}
                  style={{width: MenuItemWidth}}
                  data-testid="report-header-download-button"
                  onClick={() => handleClickMenu(SHARE_MS_MODAL_STATE.DOWNLOAD)} />
              </Menu>
            </>
          ) :
          (
            <>
            {isShareAvailable && (
              <ButtonContainer onClick={onClickShare}>
                <Button bsStyle="default" id="share-report-header-button">
                  <Badge activeId="share-report-header-button">
                    <Icon id="share-report-header-icon" name="share" size="md" color="#FFFFFF" />
                  </Badge>
                </Button>
                <Label>Share</Label>
              </ButtonContainer>
            )}
            {!isFirefoxAndroid && (
              <ButtonContainer onClick={onClickPrintReport}>
                <Button id="print-report-header-button" bsStyle="default">
                  <Badge activeId="print-report-header-button">
                    <Icon id="print-report-header-icon" name="print" size="md" color="#FFFFFF" />
                  </Badge>
                </Button>
                <Label>Print</Label>
              </ButtonContainer>
            )}
            {pdfReport.isFetching ? (
              <ButtonContainer width="54.11px">
                <Button id="save-pdf-loading-button-pc" PC bsStyle="default" marginTop>
                  <Icon name="loading" size="md" color="#FFFFFF" spin />
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer onClick={onClickDownloadPdfDesktop}>
                <Button id="save-pdf-header-report-button-pc" PC bsStyle="default">
                  <Badge activeId="download-report-header-button">
                    <Icon
                      id="download-pdf-loading-button-icon"
                      name="download"
                      size="md"
                      color="#FFFFFF"
                    />
                  </Badge>
                </Button>
                {!pdfReport.isFetching && <Label PC>Download</Label>}
              </ButtonContainer>
            )}
            <ButtonContainer Mobile>
              <Button
                id="save-pdf-header-report-button-mobile"
                Mobile
                bsStyle="default"
                onClick={onClickDownloadPdfMobile}
              >
                <Icon
                  id="save-pdf-header-report-button-mobile-icon"
                  name="download"
                  size="md"
                  color="#FFFFFF"
                />
              </Button>
              <Label Mobile>Download</Label>
            </ButtonContainer>
            </>
          )
        }
      </Container>
      <ExistingSubmissionErrorModal
        isOpen={existingSubmissionErrorModal.isOpen}
        errorPayload={existingSubmissionErrorModal.errorPayload}
        onClickConfirm={() => {
          history.push(
            buildPath(SINGLE_APPLICATION, {
              propertyId: existingSubmissionErrorModal.errorPayload.propertyId,
              rentalAppId: existingSubmissionErrorModal.errorPayload.rentalSubmissionId,
            }),
          )
          openExistingSubmissionErrorModal(false, {})
        }}
        onClose={() => openExistingSubmissionErrorModal(false, {})}
      />
    </HeaderPrintContainer>
  )
}

export default ReportHeader
