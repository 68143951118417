import React from "react"
import styled from "styled-components"
import { withFormik } from "formik"
import { TextArea } from "@rentspree/component-v2"
import get from "lodash/get"
import { Button } from "../../../buttons/buttons"

export const StyledForm = styled.form`
  position: relative;
  width: calc(100% - 155px);
  float: left;
  @media (max-width: 991px) {
    width: 100%;
  }
`

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 14px;
  right: 10px;
`

export const Form = props => {
  const { values, handleChange, handleBlur, isSavedCustomMessage } = props
  const saveButtonText = isSavedCustomMessage ? "Updated" : "Update message"
  return (
    <StyledForm onSubmit={props.handleSubmit}>
      <TextArea
        label="Add custom message to handout."
        placeholder="Ex: License No. #12345, Brokerage name"
        id="flyer-custom-message-textarea"
        name="customMessage"
        value={values.customMessage}
        onChange={handleChange}
        onBlur={handleBlur}
        height="130px"
        textAreaStyle={{ resize: "none" }}
        maxLength="50"
        mb="0"
      />
      <StyledButton
        default
        id="flyer-update-message-button"
        text={saveButtonText}
        disabled={isSavedCustomMessage}
        fontSize="10"
        height="auto"
        padding="5px 10px"
      />
    </StyledForm>
  )
}
export const CustomMessageForm = withFormik({
  mapPropsToValues: props => ({
    customMessage: get(props, "customMessage", ""),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.saveCustomMessage(values.customMessage)
    setSubmitting(false)
  },
  displayName: "CustomMessageForm",
})(Form)
