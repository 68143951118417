import { takeLatest, all, put, call } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { UserApiInstance } from "utils/api-interceptor"
import { openSweetAlertBaseError } from "utils/sweet-alert-actions"
import { getProfile } from "legacy/actions/user.action"
import {
  CHANNEL,
  SEND_EMAIL_VERIFICATION_CALL,
  EMAIL_VERIFICATION_HAS_BEEN_VERIFIED_ERROR,
} from "./constants"
import { EMAIL_VERIFICATION_SEND_API } from "../../constants/route"

export const sendEmailVerificationApi = ({ email }) =>
  UserApiInstance.post(buildPath(EMAIL_VERIFICATION_SEND_API, {}), {
    channel: CHANNEL,
    email,
    continuePage: window.location.href,
  })

export function* sendEmailVerification({ payload }) {
  try {
    yield call(sendEmailVerificationApi, payload)
  } catch (err) {
    if (err.data?.code === EMAIL_VERIFICATION_HAS_BEEN_VERIFIED_ERROR) {
      yield put(getProfile())
    } else {
      yield put(openSweetAlertBaseError(err.status))
    }
  }
}

export function* watchSendEmailVerification() {
  yield takeLatest(SEND_EMAIL_VERIFICATION_CALL, sendEmailVerification)
}

export function* rootSaga() {
  yield all([watchSendEmailVerification()])
}

export default rootSaga
