import React from 'react'

import { Div } from 'components/layout/main'
import { S20, B16, Hr, RedText } from '@rentspree/component-v2'
import { Row, Col } from 'react-bootstrap'
import RadioButton from '@rentspree/component-v2/dist/input/radio-button'
import TextInput from '@rentspree/component-v2/dist/input/text'

import { USER_ROLE_FOR_PROPERTY, USER_INPUT_ROLE } from 'containers/request/step-create-property/constants'
import ScrollToError from 'components/scroll/scroll-to-error'
import { InputWrapper } from 'components/shared/form'
import { FlexBox } from 'legacy/components/layout/section'

import { LandlordBasicInformationFromV2 } from '../landlord-information-form/landlord-basic-information-form-v2'
import { EXPERIMENTAL_LANDLORD_FORM_TITLE, EXPERIMENTAL_LANDLORD_FORM_DESCRIPTION } from './constants'

export const UserRoleForPropertyFormWithRadioChoices = (props) => {
  const {
    title,
    subtitle,
    radioChoices,
    selectedUserRoleForProperty,
    handleUserRoleForPropertyDropdown,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    isSavePropertyClicked,
    otherRoleInputPlaceholder,
  } = props

  return (
    <Div sourceSansPros>
      <S20>{title}<RedText> *</RedText></S20>
      <Hr margin="0 0 8px 0" />
      <B16 margin="0 0 20px">{subtitle}</B16>
      {radioChoices.map(item => (
        <Row>
          <Col style={{ margin: "0 10px 8px" }}>
            <RadioButton
              id={item.id}
              key={item.id}
              name={`user_role_for_property_${item.value}`}
              choice={item}
              checked={selectedUserRoleForProperty === item.value}
              onChange={(v) => handleUserRoleForPropertyDropdown(v.target.value)}
              error={isSavePropertyClicked && selectedUserRoleForProperty === undefined}
              margin="0"
            />
          </Col>
        </Row>
      )
      )}
      {selectedUserRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT && (
        <LandlordBasicInformationFromV2 {...props} title={EXPERIMENTAL_LANDLORD_FORM_TITLE} description={EXPERIMENTAL_LANDLORD_FORM_DESCRIPTION} />
      )}
      {selectedUserRoleForProperty === USER_INPUT_ROLE.OTHER && (
        <Div style={{ margin: "0 10px 0" }}>
          <ScrollToError {...props} />
          <FlexBox>
            <InputWrapper bgTransparent>
              <TextInput
                name="otherRoleName"
                id="landlordInformationOtherRoleName"
                label={otherRoleInputPlaceholder}
                value={values?.otherRoleName}
                onChange={handleChange}
                onBlur={e => {
                  e.target.value = e.target.value.trim()
                  setFieldValue("otherRoleName", e.target.value.trim())
                  handleBlur(e)
                }}
                error={(touched?.otherRoleName && errors?.otherRoleName)}
                isRequired
              />
            </InputWrapper>
          </FlexBox>
        </Div>
      )}
    </Div>
  )
}
