import { all } from 'redux-saga/effects'

import {
  watchGetPayeeIdvUpdateLinkSaga,
  watchGetPayoutMethodListSaga,
  watchGetPayeeSaga,
  watchGetPayeeProviderAccountInformationSaga,
} from './services'

export function* paymentAccountRootSaga() {
  yield all([
    watchGetPayoutMethodListSaga(),
    watchGetPayeeIdvUpdateLinkSaga(),
    watchGetPayeeSaga(),
    watchGetPayeeProviderAccountInformationSaga(),
  ])
}
