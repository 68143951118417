import { useDispatch, useSelector } from 'react-redux'
import { useInjectSaga } from 'utils/inject-saga'
import { useInjectReducer } from 'utils/inject-reducer'
import React from 'react'
import { KEY } from '../constants'
import { payeeRootSaga } from '../sagas'
import { payeeReducer } from '../reducers'
import { makeSelectPayeeRiskLevelData, makeSelectPayeeRiskLevelStatus } from '../selectors'
import { getPayeeRiskLevel } from '../actions'

export const usePayeeRiskLevel = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.PAYEE, saga: payeeRootSaga })
  useInjectReducer({ key: KEY.PAYEE, reducer: payeeReducer })

  const result = useSelector(makeSelectPayeeRiskLevelData())
  const status = useSelector(makeSelectPayeeRiskLevelStatus())

  React.useEffect(() => {
    dispatch(getPayeeRiskLevel())
  }, [])

  return {
    result,
    status,
  }
}
