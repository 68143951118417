import React from "react"
import Scroll from "react-scroll"
import { AlertPanel } from "components/shared/alert-panel"

import {
  CenterContentTemplateStyle,
  ContentStyle,
  Center,
} from "./center-with-error-template-styled"

const scroll = Scroll.animateScroll

export const CenterWithError = ({
  children,
  isError,
  errorDetail,
  handleCloseError,
  noHeightCal,
  warning,
  error,
  widthAlert,
  padding,
  margin,
  closeIconColor,
}) => {
  if (isError) {
    scroll.scrollToTop({ smooth: true })
  }

  return (
    <>
      <CenterContentTemplateStyle isError={isError} noHeightCal={noHeightCal}>
        <ContentStyle>
          {isError && (
            <Center>
              <AlertPanel
                warning={warning}
                error={error}
                widthAlert={widthAlert}
                handleClose={handleCloseError}
                padding={padding || "10px"}
                margin={margin || "0px"}
                alertDetail={errorDetail}
                closeIconColor={closeIconColor}
              />
            </Center>
          )}
          {children}
        </ContentStyle>
      </CenterContentTemplateStyle>
    </>
  )
}

export default CenterWithError
