import generateApiCall from 'utils/generate-api-call'

import {
  GET_ALL_TAGS_CALL,
  GET_ALL_TAGS_REQUEST,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILURE,
  APPEND_TAG,
  DELETE_TAG_CALL,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
} from './constants'

export const getAllTags = () => ({
  type: GET_ALL_TAGS_CALL,
})

export const getAllTagsApiState = generateApiCall([
  GET_ALL_TAGS_REQUEST,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILURE,
])

export const appendTag = payload => ({
  type: APPEND_TAG,
  payload,
})

export const deleteTag = ({ tagId }) => ({
  type: DELETE_TAG_CALL,
  payload: {
    tagId,
  },
})

export const deleteTagApiState = generateApiCall([
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
])
