import PropTypes from "prop-types"
import React from "react"
import SelectDropdown from "@rentspree/component-v2/dist/dropdown/select"
import FormattedInput from "@rentspree/component-v2/dist/input/formatted"

import { Div } from "components/layout/main"
import ScrollToError from "components/scroll/scroll-to-error"
import { InputWrapper } from "components/shared/form"
import { FlexBox } from "legacy/components/layout/section"

import { PROPERTY_TYPES, BED_ROOM_CHOICES, BATH_ROOM_CHOICES } from "./consts"

const PropertyDetailsForm = ({ margin, mMargin, ...props }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
  } = props
  return (
    <Div margin={margin} mMargin={mMargin}>
      <ScrollToError {...props} />
      <FlexBox>
        <InputWrapper width="480px" bgTransparent>
          <SelectDropdown
            isRequired
            name="propertyType"
            id="propertyDetailsPropertyType"
            label="Property Type"
            value={values.propertyType}
            options={PROPERTY_TYPES}
            onChange={val => setFieldValue("propertyType", val)}
            onBlur={() => setFieldTouched("propertyType", true)}
            error={touched.propertyType && errors.propertyType}
          />
        </InputWrapper>
        <InputWrapper width="146px" bgTransparent>
          <SelectDropdown
            isRequired
            name="bedrooms"
            id="propertyDetailsBedrooms"
            label="Bedrooms"
            options={BED_ROOM_CHOICES}
            value={values.bedrooms}
            onChange={val => setFieldValue("bedrooms", val)}
            onBlur={() => setFieldTouched("bedrooms", true)}
            error={touched.bedrooms && errors.bedrooms}
          />
        </InputWrapper>
        <InputWrapper width="148px" ml="20" bgTransparent>
          <SelectDropdown
            isRequired
            name="bathrooms"
            id="propertyDetailsBathrooms"
            label="Baths"
            options={BATH_ROOM_CHOICES}
            value={values.bathrooms}
            onChange={val => setFieldValue("bathrooms", val)}
            onBlur={() => setFieldTouched("bathrooms", true)}
            error={touched.bathrooms && errors.bathrooms}
          />
        </InputWrapper>
        <InputWrapper width="146px" ml="20" bgTransparent>
          <FormattedInput
            isRequired
            name="monthlyRent"
            id="propertyDetailsMonthlyRent"
            label="Monthly Rent"
            formatType="currency"
            value={values.monthlyRent}
            onValueChange={val => {
              setFieldValue("monthlyRent", val.value)
            }}
            onBlur={handleBlur}
            error={touched.monthlyRent && errors.monthlyRent}
            placeholder="$"
            allowNegative={false}
          />
        </InputWrapper>
        <InputWrapper width="230px" bgTransparent>
          <FormattedInput
            isRequired
            name="squareFeet"
            id="propertyDetailsSquareFeet"
            label="Square Feet"
            formatType="number"
            value={values.squareFeet}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.squareFeet && errors.squareFeet}
          />
        </InputWrapper>
        <InputWrapper width="230px" ml="20" bgTransparent>
          <FormattedInput
            isRequired
            name="yearBuilt"
            id="propertyDetailsYearBuilt"
            label="Year Built"
            formatType="number"
            format="####"
            value={values.yearBuilt}
            onValueChange={vals => {
              setFieldValue("yearBuilt", vals.value)
            }}
            onBlur={handleBlur}
            error={touched.yearBuilt && errors.yearBuilt}
          />
        </InputWrapper>
      </FlexBox>
    </Div>
  )
}

PropertyDetailsForm.defaultProps = {
  margin: "0 10px",
  mMargin: "0",
}

PropertyDetailsForm.propTypes = {
  margin: PropTypes.string,
  mMargin: PropTypes.string,
}

export default PropertyDetailsForm
