import generateApiCall from "utils/generate-api-call"
import {
  UPDATE_PROPERTY_DETAILS_CALL,
  UPDATE_PROPERTY_DETAILS_REQUEST,
  UPDATE_PROPERTY_DETAILS_SUCCESS,
  UPDATE_PROPERTY_DETAILS_FAILED,
  GET_PROPERTY_CALL,
  GET_PROPERTY_CALL_REQUEST,
  GET_PROPERTY_CALL_SUCCESS,
  GET_PROPERTY_CALL_FAILED,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_SUCCESS,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_CALL,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_REQUEST,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_FAILED,
  UPDATE_PROPERTY_DETAILS_AND_CREATE_CONTACT_CALL,
} from "./const"

export const getProperty = payload => ({
  type: GET_PROPERTY_CALL,
  payload,
})

export const getPropertyApi = generateApiCall([
  GET_PROPERTY_CALL_REQUEST,
  GET_PROPERTY_CALL_SUCCESS,
  GET_PROPERTY_CALL_FAILED,
])

export const createContactFromPropertyDetailApiState = generateApiCall([
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_REQUEST,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_SUCCESS,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_FAILED,
])

export const createContactFromPropertyDetail = payload => ({
  type: CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_CALL,
  payload,
})
export const updatePropertyDetails = payload => ({
  type: UPDATE_PROPERTY_DETAILS_CALL,
  payload,
})

export const updatePropertyDetailsAndCreateContact = payload => ({
  type: UPDATE_PROPERTY_DETAILS_AND_CREATE_CONTACT_CALL,
  payload,
})

export const updatePropertyDetailsApi = generateApiCall([
  UPDATE_PROPERTY_DETAILS_REQUEST,
  UPDATE_PROPERTY_DETAILS_SUCCESS,
  UPDATE_PROPERTY_DETAILS_FAILED,
])
