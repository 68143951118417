import { COLOR } from '../styles/settings'

const AllIcon = require('../images/icons/application/application-all.png')
const RequestedIcon = require('../images/icons/application/application-requested.png')
const StartedIcon = require('../images/icons/application/application-started.png')
const CompletedIcon = require('../images/icons/application/application-completed.png')
const OpenedIcon = require('../images/icons/application/application-opened.png')
const RejectedIcon = require('../images/icons/application/application-rejected.png')
const FailedIcon = require('../images/icons/application/application-failed.png')
const ExpiredIcon = require('../images/icons/application/application-expired.png')
const NotInterestedIcon = require('../images/icons/application/application-notinterest.png')
const UnreadIcon = require('../images/icons/application/application-unread.png')
const NeedDecisionIcon = require('../images/icons/application/application-need-decision.png')

export const FILTER_APP_PROGRESS_OPTIONS = [
  {
    label: 'All',
    value: 'all',
    icon: AllIcon,
  },
  {
    label: 'Not started',
    value: 'requested',
    icon: RequestedIcon,
  },
  {
    label: 'In progress',
    value: 'started',
    icon: StartedIcon,
  },
  {
    label: 'Submitted',
    value: 'submitted',
    icon: CompletedIcon,
  },
  {
    label: 'Unread',
    value: 'unread',
    icon: UnreadIcon,
  },
  {
    label: 'Need decision',
    value: 'need_decision',
    icon: NeedDecisionIcon,
  },
  {
    label: 'Accepted',
    value: 'accepted',
    icon: OpenedIcon,
  },
  {
    label: 'Declined',
    value: 'denied',
    icon: RejectedIcon,
  },
  {
    label: 'Report expired',
    value: 'expired',
    icon: ExpiredIcon,
  },
  {
    label: 'Not interested',
    value: 'not_interested',
    icon: NotInterestedIcon,
  },
]

export const APP_DOCUMENT_STATUS = {
  empty: {
    value: 'empty',
  },
  requested_doc: {
    value: 'requested',
    image: CompletedIcon,
    color: COLOR.darkGreen,
    text: 'Ready; doc(s) pending',
    shortText: 'Requested',
    fullText: 'Report ready; document(s) pending',
  },
  partially_uploaded: {
    value: 'partially_uploaded',
    image: CompletedIcon,
    color: COLOR.darkGreen,
    text: 'Ready; doc(s) pending',
    shortText: 'Partially',
    fullText: 'Report ready; document(s) pending',
  },
  uploaded: {
    value: 'uploaded',
    image: CompletedIcon,
    color: COLOR.darkGreen,
    text: 'Report & doc(s) Ready',
    shortText: 'Uploaded',
    fullText: 'Report and document(s) ready',
  },
}

export const APP_STATUS = {
  all: {
    value: 'all',
    image: AllIcon,
    color: COLOR.textBlack,
    text: 'All',
  },
  created: {
    value: 'created',
    image: StartedIcon,
    color: COLOR.blue,
    text: 'Created',
    shortText: 'Created',
  },
  requested: {
    value: 'requested',
    image: RequestedIcon,
    color: COLOR.yellow,
    text: 'Request Sent',
    shortText: 'Requested',
    fullText: 'Request sent; renter hasn’t started.',
  },
  started: {
    value: 'started',
    image: StartedIcon,
    color: COLOR.blue,
    text: 'Renter Started',
    shortText: 'Started',
    fullText: 'Renter has started.',
  },
  edit: {
    value: 'edit',
    image: StartedIcon,
    color: COLOR.blue,
    text: 'Renter Started',
    shortText: 'Started',
    fullText: 'Renter has started.',
  },
  ready: {
    value: 'ready',
    image: StartedIcon,
    color: COLOR.blue,
    text: 'Renter Started',
    shortText: 'Started',
    fullText: 'Renter has started.',
  },
  review: {
    value: 'review',
    image: StartedIcon,
    color: COLOR.blue,
    text: 'Renter Started',
    shortText: 'Started',
    fullText: 'Renter has started.',
  },
  submitted: {
    value: 'submitted',
    image: CompletedIcon,
    color: COLOR.darkGreen,
    text: 'Ready',
    shortText: 'Submitted',
    fullText: 'Ready',
  },
  property_required: {
    value: 'property_required',
    image: CompletedIcon,
    color: COLOR.darkGreen,
    text: 'Property Required',
    shortText: 'Property Required',
    fullText: 'Property Required',
  },
  completed: {
    value: 'completed',
    image: CompletedIcon,
    color: COLOR.darkGreen,
    text: 'Completed',
  },
  expired: {
    value: 'expired',
    image: ExpiredIcon,
    color: COLOR.textGrey,
    text: 'Report Expired',
    shortText: 'Expired',
    fullText: 'These screening reports expired on ',
  },
  submission_expired_renter_pay: {
    value: 'submission_expired_renter_pay',
    image: ExpiredIcon,
    color: COLOR.textGrey,
    text: 'Submission Expired',
    shortText: 'Expired',
    fullText:
      'Reports were not opened within 30 days of submission. The application fee has been refunded to the renter.',
  },
  submission_expired_landlord_pay: {
    value: 'submission_expired_landlord_pay',
    image: ExpiredIcon,
    color: COLOR.textGrey,
    text: 'Submission Expired',
    shortText: 'Expired',
    fullText: 'Reports were not opened within 30 days of submission.  Reports no longer available.',
  },
  opened: {
    value: 'opened',
    image: OpenedIcon,
    color: COLOR.darkGreen,
    text: 'Report Opened',
  },
  rejected: {
    value: 'rejected',
    image: RejectedIcon,
    color: COLOR.negativeRed,
    text: 'Request Rejected',
  },
  failed: {
    value: 'failed',
    image: FailedIcon,
    color: COLOR.negativeRed,
    text: 'Request Failed',
  },
  not_interested: {
    value: 'not_interested',
    image: NotInterestedIcon,
    color: COLOR.textGrey,
    text: 'Not Interested',
    shortText: 'Not Interested',
    fullText: 'The renter is no longer interested.',
  },
  // ToDo: for be able to test property list on UNO-946, will be correct on UNO-947
  participant: {
    value: 'shared',
    image: OpenedIcon,
    color: COLOR.darkGreen,
    text: 'Shared',
    shortText: 'Shared',
    fullText: 'Shared Report',
  },
  ...APP_DOCUMENT_STATUS,
}

export const APP_NOT_SUBMIT_STATUS = [
  APP_STATUS.created.value,
  APP_STATUS.requested.value,
  APP_STATUS.started.value,
  APP_STATUS.edit.value,
  APP_STATUS.ready.value,
  APP_STATUS.not_interested.value,
  APP_STATUS.review.value,
]
