import React from "react"
import styled from "styled-components"
import marked from "marked"
import {
  S24,
  TermBox,
  TermSection,
  Checkbox,
  BlueGradientButton,
  ErrorAlert,
} from "@rentspree/component-v2"
export const LayoutModalButtonRight = styled.div`
  font-family: "Source Sans Pro";
  button {
    float: right;
  }
`
const TermsModalBody = ({
  error,
  title,
  selectedCheckBox,
  onCheckBoxClick,
  onClearError,
  terms,
  checkboxLabel,
  handleClickBtn,
  isLoading,
  isSuccess,
  markdown,
  height,
}) => (
  <LayoutModalButtonRight>
    {title &&(
    <S24 margin="0px 0px 10px 0px" mMargin="0px 15px 10px 0px" mSize="24px">
      {title}
    </S24>)}
    {error && (
      <ErrorAlert
        errorMessage={error.errorMessage}
        secondaryMessage={error.secondaryMessage}
        onClose={onClearError}
        margin="10px 0"
      />
    )}
    <TermBox
      padding="15px 20px 0px 20px"
      mPadding="15px 20px 0px 20px"
      height={height || "260"}
      hideOverlay>
      {markdown && (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: marked(markdown),
          }}
        />
      )}
      {terms && (
        <TermSection
          termsMargin="0px"
          termsSize="14px"
          termsAlign="left"
          terms={terms}
        />
      )}
    </TermBox>
    <Checkbox
      isSelected={selectedCheckBox}
      onClick={onCheckBoxClick}
      margin="15px 0px 30px 0"
      mMargin="15px 0px 30px 0"
      fontSize="16px"
      id="simpleBox"
      label={checkboxLabel}
      simple
    />
    {handleClickBtn && (
      <BlueGradientButton
        small
        semiBold
        text="Continue"
        id="smBtn"
        fontSize="16px"
        mWidth="100%"
        onClick={handleClickBtn}
        loading={isLoading}
        loaded={isSuccess}
        disabled={!selectedCheckBox}
        tWidth="100%"
      />)
    }
  </LayoutModalButtonRight>
)
TermsModalBody.defaultProps = {
  error: false,
  selectedCheckBox: false,
  onCheckBoxClick: () => {},
  isLoading: false,
  onClearError: () => {},
  terms: [],
  checkboxLabel: "I accept the RentSpree E-Sign and E-Delivery Consent.",
}

export default TermsModalBody
