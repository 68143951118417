import { buildPath } from "@rentspree/path"
import { DOWNLOAD_PDF_API, FULL_DOC_ZIP_API, REQUEST_RENTAL_API } from "constants/route"
import checkStatus from "./handlers/check-status"
import fetch from "../helpers/fetch-utils"

export const ofSend = (screeningRequestId, data) => bearer =>
  fetch(buildPath(REQUEST_RENTAL_API, { screeningRequestId }), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(checkStatus)

export const handleFetchDocuments = (
  url,
  accessToken,
  customHeader = {},
  option = {},
) =>
  fetch(url, {
    method: "GET",
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }), // assign Authorization to header when accessToken exist
      ...customHeader,
    },
    ...option,
  }).then(async response => {
    if (response.status >= 400) {
      return {}
    }
    const header = response.headers.get("Content-Disposition") || ""
    const fileName =
      header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) || []
    return {
      blob: await response.blob(),
      fileName: fileName[1],
    }
  })

export const downloadFullDocumentZip = (rentalId, profile) => bearer => {
  const queryString = {
    firstName: profile.firstName,
    lastName: profile.lastName,
  }
  const uri = buildPath(FULL_DOC_ZIP_API, { rentalId }, queryString)
  return handleFetchDocuments(uri, bearer.accessToken)
}

export const pdfAPI = rentalSubmissionId => bearer => {
  const uri = buildPath(DOWNLOAD_PDF_API, { rentalSubmissionId })
  return fetch(uri, {
    method: "GET",
    headers: { Authorization: `Bearer ${bearer.accessToken}` },
  }).then(res =>
    checkStatus(res, "arraybuffer").then(streamData => ({
      pdfFile: streamData,
    })),
  )
}
