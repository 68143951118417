import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getReducer = state => get(state, 'contacts.tags', initialState)

export const selectAllTags = createSelector(getReducer, tags =>
  get(tags, 'data', initialState.data),
)

export const selectSortedAllTags = createSelector(getReducer, tags =>
  sortBy(get(tags, 'data', initialState.data), [o => lowerCase(o?.name)]),
)

export const selectIsLoadingTags = createSelector(getReducer, tags =>
  get(tags, 'isLoading', initialState.isLoading),
)
