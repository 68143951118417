import React, { useMemo } from 'react'

import TextInput from '@rentspree/component-v2/dist/input/text'

import { Div } from 'components/layout/main'
import ScrollToError from 'components/scroll/scroll-to-error'
import { InputWrapper } from 'components/shared/form'
import { FlexBox } from 'legacy/components/layout/section'
import { S20, B16 } from '@rentspree/component-v2'
import { TwoInputGroup } from './styled-index'

export const LandlordBasicInformationFromV2 = (props) => {
  const { title, description, values, touched, errors, setFieldValue, handleChange, handleBlur, width = 480, gap = 20 } = props
  const halfWidth = useMemo(() => (Number(width) / 2) - (gap / 2))
  return (
    <Div style={{ margin: "36px 10px 0" }}>
      <S20>{title}</S20>
      <B16>{description}</B16>
      <ScrollToError {...props} />
      <FlexBox>
        <TwoInputGroup >
          <InputWrapper width={`${halfWidth}px`} bgTransparent>
            <TextInput
              name="firstName"
              id="landlordInformationFirstName"
              label="Owner First Name"
              value={values?.firstName}
              onChange={handleChange}
              onBlur={e => {
                e.target.value = e.target.value.trim()
                setFieldValue("firstName", e.target.value)
                handleBlur(e)
              }}
              error={(touched?.firstName && errors?.firstName)}
              isRequired
            />
          </InputWrapper>
          <InputWrapper width={`${halfWidth}px`} ml={gap} bgTransparent>
            <TextInput
              name="lastName"
              id="landlordInformationLastName"
              label="Owner Last Name"
              value={values?.lastName}
              onChange={handleChange}
              onBlur={e => {
                e.target.value = e.target.value.trim()
                setFieldValue("lastName", e.target.value)
                handleBlur(e)
              }}
              error={(touched?.lastName && errors?.lastName)}
              isRequired
            />
          </InputWrapper>
        </TwoInputGroup>

        <InputWrapper bgTransparent>
          <TextInput
            id="landlordInformationEmail"
            name="email"
            label="Email"
            value={values?.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(touched?.email && errors?.email)}
            isRequired
          />
        </InputWrapper>
      </FlexBox>
    </Div>
  )
}
