import { SINGLE_APPLICATION } from "constants/route"

export const TYPES = {
  FETCH_GET_STARTED: "FETCH_GET_STARTED",
  FETCH_GET_STARTED_REQUEST: "FETCH_GET_STARTED_REQUEST",
  FETCH_GET_STARTED_SUCCESS: "FETCH_GET_STARTED_SUCCESS",
  FETCH_GET_STARTED_FAILED: "FETCH_GET_STARTED_FAILED",

  SKIP_GET_STARTED_MODAL: "SKIP_GET_STARTED_MODAL",
}

export const API_GET_STARTED = "/api/v2/announcement/feature/get-started"

export const PROJECT = "dashboard-v2"
export const GROUP_GET_STARTED = "get-started"

export const PATHS_TO_POSTPONE = [SINGLE_APPLICATION]
