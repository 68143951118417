import React from "react"
import { Div } from "components/layout/main"
import { S24, B14, S16 } from "@rentspree/component-v2"
import { COLOR } from "components/constants"
import { NotifyButton, SurveyBanner, SurveyWrapper } from "./survey.styled"

export const Survey = ({ surveyContent, onSurvey, gradient }) => (
  <SurveyBanner flex justifyContent="center" gradient={gradient}>
    <SurveyWrapper
      flex
      justifyContent="space-between"
      maxWidth="919"
      width="100%"
      alignItems="center">
      <Div>
        <S24 color={gradient ? COLOR.white : COLOR.textBlack}>
          {surveyContent.title}
        </S24>
        <B14 color={gradient ? COLOR.white : COLOR.textBlack}>
          {surveyContent.description}
        </B14>
      </Div>
      <NotifyButton
        id="surveyButton"
        google
        small
        onClick={onSurvey}
        padding="0 25px">
        <S16 margin="0 0 0 5px" weight="600">
          {surveyContent.button}
        </S16>
      </NotifyButton>
    </SurveyWrapper>
  </SurveyBanner>
)
