import { Ability, AbilityBuilder } from "@casl/ability"
import {
  PREMIUM_CAN_ACTION,
  PREMIUM_CAN_SUBJECT,
  PRO_CAN_ACTION,
  PRO_CAN_SUBJECT,
  TRIAL_CAN_ACTION,
  TRIAL_CAN_SUBJECT,
} from "./constants"

export const ability = new Ability([])

export const updateAbility = (isPro, trialStarted, isPremium) => {
  const { can, rules } = new AbilityBuilder()

  if (isPro) {
    can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
  } else if (!trialStarted) {
    can(TRIAL_CAN_ACTION, TRIAL_CAN_SUBJECT)
  }

  if (isPremium) {
    can(PREMIUM_CAN_ACTION, PREMIUM_CAN_SUBJECT)
  }

  ability.update(rules)
}
