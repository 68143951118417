import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { COLOR } from 'styles/settings'
import { Checkbox } from 'components/atoms/checkbox'
import { TextAreaWithCloseButton } from '../text-area-with-close-button'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0 !important;
  border-bottom: 1px solid ${COLOR.grayLv4};
  color: ${COLOR.textBlack};
`

const LeftContainer = styled.div`
  width: 48px;
  padding: 15px;
  p {
    padding-left: 18px !important;
  }
`

const RightContainer = styled.div`
  display: block;
  flex-grow: 1;
  padding-top: 15px;
  padding-right: 20px;
`

const BlueText = styled.p`
  margin-bottom: 15px;
  font-size: 14px;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 20px;
  color: ${COLOR.activeBlue};
  cursor: pointer;
`

const Title = styled.p`
  font-size: 16px;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 2px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  margin-bottom: 0 !important;
`

const textAreaLabelStyle = {
  display: 'none',
}

export const DocumentProOptionItem = ({
  documentName,
  documentKey,
  isSelected = false,
  note,
  onChange = () => {},
}) => {
  const [isAutoFocusTextArea, setIsAutoFocusTextArea] = useState(false)
  const [showNoteBox, setShowNoteBox] = useState(!isEmpty(note))

  return (
    <Container>
      <LeftContainer>
        <Checkbox
          value={isSelected}
          onClick={() => {
            onChange(
              documentKey,
              isSelected ? { isSelected: !isSelected, note: '' } : { isSelected: !isSelected },
            )
            setShowNoteBox(false)
          }}
        />
      </LeftContainer>
      <RightContainer>
        <TitleContainer>
          <Title>{documentName}</Title>
        </TitleContainer>
        {showNoteBox ? (
          <TextAreaWithCloseButton
            height="120px"
            maxLength="250"
            label={documentName}
            labelStyle={textAreaLabelStyle}
            value={note}
            autoFocus={isAutoFocusTextArea}
            onClose={() => {
              setShowNoteBox(false)
              onChange(documentKey, { note: '' })
            }}
            onChange={e => {
              onChange(documentKey, { note: e.target.value })
            }}
          />
        ) : (
          <BlueText
            onClick={() => {
              setIsAutoFocusTextArea(true)
              setShowNoteBox(true)
              onChange(documentKey, { isSelected: true })
            }}
          >
            Add Instructions
          </BlueText>
        )}
      </RightContainer>
    </Container>
  )
}

DocumentProOptionItem.propTypes = {
  documentName: PropTypes.string.isRequired,
  documentKey: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  note: PropTypes.string,
  onChange: PropTypes.func,
}

DocumentProOptionItem.defaultProps = {
  isSelected: false,
}
