import produce from "immer"
import {
  GET_IMPORTABLE_LISTING_REQUEST,
  GET_IMPORTABLE_LISTING_FAILED,
  GET_IMPORTABLE_LISTING_SUCCESS,
} from "./constants"

export const initialState = {
  MLSListing: {},
  isFetched: false,
}

/* eslint-disable no-param-reassign */
const importListingReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_IMPORTABLE_LISTING_REQUEST:
        draft = {
          ...draft,
          isFetched: false,
        }
        break
      case GET_IMPORTABLE_LISTING_FAILED:
        draft = {
          ...draft,
          isFetched: false,
        }
        break
      case GET_IMPORTABLE_LISTING_SUCCESS:
        draft = {
          ...draft,
          isFetched: true,
          MLSListing: payload,
        }
        break
      default:
        break
    }
    return draft
  })

export default importListingReducer
