import React from "react"
import classnames from "classnames"
import styled from "styled-components"
import { B14 } from "@rentspree/component-v2"
import isNil from "lodash/isNil"
import { getSignWithRecipientFirstName } from "containers/envelope/helpers/get-recipient-name"
import { getColor } from "utils/get-color"
import { BASE_DIGIDOC_URL } from "constants/route"
import { COLOR } from "../../../styles/settings"
import { Icon } from "../../envelope/icon"
import { SIGNATURE_TYPE } from "../constants"
import TooltipPopper from "../tooltip"
import { contentColor, borderColor } from "../colors"
import { makeMapCursor } from "../cursor"

const mapCursor = makeMapCursor("default", "pointer")

export const SignatureWrapper = styled.div`
  .sign-text {
    font-size: ${props => props.size || "28px"};
    font-family: Damion;
  }
`
export const Underline = styled.div`
  width: 100%;
  margin: 0px;
  margin-top: ${props => (props.small ? "-2px" : "1px")};
  border-bottom: 1px solid ${props => `${borderColor(props.colorIndex)}`};
`
export const Box = styled.div`
  transform-origin: 0% 0%;
  transform: ${props =>
    props.ratio ? `scale(${props.ratio}, ${props.ratio})` : "none"};
  padding: ${props => (props.padding ? `${props.padding}` : "15px 0px")};
  width: ${props => (props.width ? `${props.width}px` : "auto")};
  height: ${props => (props.height ? `${props.height}px` : "auto")};
  font-family: Source Sans Pro;
  ${props => props.minHeight && `min-height: ${props.minHeight}px;`}
  ${props => props.minWidth && `min-width: ${props.minWidth}px;`}
  background: ${props =>
    isNil(props.colorIndex)
      ? `rgba(${COLOR.lightBlueRGBA})`
      : `rgba(${getColor(props.colorIndex, "bgRgb")}, 0.9)`};
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  flex-flow: wrap;
  align-items: center;                                                                                                                                                                                       
  position: absolute;
  cursor: ${props => props.cursor || "default"};
  max-width: ${props => (props.left ? `calc(100% - ${props.left}px)` : "none")};
  max-height: ${props => (props.top ? `calc(100% - ${props.top}px)` : "none")};
  left: ${props => (props.left ? `${props.left}px` : "0")};
  top: ${props => (props.top ? `${props.top}px` : "0")};
  ${props => props.flexD && `flex-direction: ${props.flexD};`}
  :hover {
    border: 1px solid
      ${props =>
        isNil(props.colorIndex)
          ? COLOR.newDeepBlue
          : `${getColor(props.colorIndex, "border")}`};
  }
  &.active {
    border: 1px solid
      ${props =>
        isNil(props.colorIndex)
          ? COLOR.newDeepBlue
          : `${getColor(props.colorIndex, "border")}`};
  }
  &.error {
    border: 1px solid ${COLOR.fontRed};
    background: ${COLOR.lightRed};
    svg {
      fill: ${COLOR.fontRed};
    }
    p {
      color: ${COLOR.fontRed};
    }
  }
  &.signed {
    :hover {
      border: 1px solid ${COLOR.textBlue};
    }
    background: none;
    width: auto;
    padding: 0px 10px;
    display: block;
    white-space: nowrap;
    .verified {
      font-size: 6px;
      line-height: 8px;
      float: left;
      width: 51px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: -3px 0 0 12px;
      &::before {
        border-top: 1px solid ${COLOR.textBlue};
        content: "";
        display: block;
        left: 10px;
        position: absolute;
        bottom: ${props => (props.small ? "4px" : "6px")};
        width: 10px;
      }
    }
  }
  &.signed-initial {
    :hover {
      border: 1px solid ${COLOR.textBlue};
    }
    background: none;
    width: auto;
  }
  &.signed-date {
    background: transparent;
    width: auto;
    border: none;
    cursor: default;
  }

  &.draw {
    width: auto;
    padding: ${props => props.padding || "0px 10px 0 10px"};
    img {
      max-height: ${props =>
        // eslint-disable-next-line no-nested-ternary
        props.height
          ? props.small
            ? `${props.height - 10}px`
            : `${props.height - 16}px`
          : "none"};
    }
    .draw-signature {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .verified {
      margin: 0 0 3px 12px;
      float: unset;
    }
  }
  &.draw-initial {
    width: auto;
    align-items: flex-start;
    padding: ${props => props.padding || "10px"};
    img {
      max-height: ${props =>
        props.height ? `${props.height - 10}px` : "none"};
    }
  }
`

export const SignIcon = ({ colorIndex }) => (
  <Icon icon="sign-icon" color={contentColor(colorIndex)} />
)

export const Text = ({ colorIndex, msg, ...props }) => (
  <B14 margin="0px" color={contentColor(colorIndex)} {...props}>
    {msg}
  </B14>
)

export const SignatureText = ({ signature, size, color, ...props }) => (
  <SignatureWrapper size={size}>
    <B14
      className="sign-text"
      margin="0px"
      color={color || COLOR.textBlue}
      {...props}>
      {signature}
    </B14>
  </SignatureWrapper>
)

export const SignBox = ({
  targetRef,
  isDragging,
  left,
  top,
  value,
  signatureType,
  active,
  colorIndex,
  onClick,
  onChangeData,
  checkSum,
  viewMode,
  fieldId,
  showError,
  recipient,
  canEdit,
  unmovable,
  small = false,
  ratio = 1,
}) => {
  const handleChange = event => {
    onChangeData(event.target.value)
  }

  if (isDragging) {
    return <div ref={targetRef} />
  }
  const isSigned = !!value
  const cursor = mapCursor(viewMode, canEdit, active, unmovable)
  const boxHeightSmall = isSigned ? "32" : "22"
  return (
    <Box
      ratio={ratio}
      ref={targetRef}
      id={fieldId}
      key={fieldId}
      cursor={cursor}
      className={classnames({
        active,
        error: showError,
        signed: isSigned,
        draw: signatureType === SIGNATURE_TYPE.DRAW,
      })}
      left={left * ratio}
      top={top * ratio}
      value={value}
      colorIndex={colorIndex}
      padding={small || isSigned ? "0 10px 0 10px" : "15px 10px"}
      height={small ? boxHeightSmall : "50"}
      minHeight="22"
      minWidth="60"
      small={small}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }}
      onChange={handleChange}>
      {showError && <TooltipPopper refNode={targetRef} />}
      {!isSigned && (
        <>
          <SignIcon colorIndex={colorIndex} />
          <Text
            style={{ paddingLeft: "5px" }}
            weight={600}
            msg={getSignWithRecipientFirstName(recipient)}
            colorIndex={colorIndex}
            size={small ? "9pt" : "10pt"}
          />
          <Underline colorIndex={colorIndex} small={small} />
        </>
      )}
      {isSigned &&
        (signatureType === SIGNATURE_TYPE.DRAW ? (
          <div className="draw-signature">
            <img src={`${BASE_DIGIDOC_URL}${value}`} alt="signature" />
            <B14 className="verified" margin="0px" color={COLOR.textBlue}>
              {checkSum}
            </B14>
          </div>
        ) : (
          <div>
            <SignatureText
              size={small ? "24px" : "28px"}
              signature={value}
              lineHeight={small ? "24px" : "40px"}
            />
            <B14 className="verified" margin="0px" color={COLOR.textBlue}>
              {checkSum}
            </B14>
          </div>
        ))}
    </Box>
  )
}
