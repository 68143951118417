import { COLOR } from "styles/settings"

export const ENVELOPE_STATUS = {
  created: {
    text: "In Progress",
    color: COLOR.fontBlue,
  },
  inprogress: {
    text: "In Progress",
    color: COLOR.fontBlue,
  },
  pending: {
    text: "Wait for signature",
    color: COLOR.brown,
  },
  completed: {
    text: "Completed",
    color: COLOR.darkGreen,
  },
  void: {
    text: "Voided",
    color: COLOR.fontRed,
  },
}

export const TABS_LABEL = {
  TYPE: "Type",
  DRAW: "Draw",
}

export const SigningMode = {
  Everyone: 'EVERYONE',
  TenantFirst: 'TENANT_FIRST',
}