import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { getPayeeIdvUpdateLink, resetPayeeIdvUpdateLink } from '../redux/actions'
import { KEY } from '../redux/constants'
import { paymentAccountReducer } from '../redux/reducers'
import { paymentAccountRootSaga } from '../redux/sagas'
import {
  makeSelectPayeeIdvUpdateLinkData,
  makeSelectPayeeIdvUpdateLinkStatus,
} from '../redux/selectors'

export const usePayeeIdvUpdateLink = ({ redirectUrl, provider }) => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.PAYMENT_ACCOUNT, saga: paymentAccountRootSaga })
  useInjectReducer({ key: KEY.PAYMENT_ACCOUNT, reducer: paymentAccountReducer })

  const result = useSelector(makeSelectPayeeIdvUpdateLinkData())
  const status = useSelector(makeSelectPayeeIdvUpdateLinkStatus())

  return {
    fetchLink: () => {
      dispatch(getPayeeIdvUpdateLink({ redirectUrl, provider }))
    },
    reset: () => {
      dispatch(resetPayeeIdvUpdateLink())
    },
    result,
    status,
  }
}
