import styled from "styled-components"

export const CurrencyInputWithDescriptionInputStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const FlexInputWrapper = styled.div`
  ${({ dynamicWidth, basis }) => `
    flex: ${dynamicWidth ? "1 1" : "0 0"} ${basis};
  `}
  [class^="input-group__"] {
    > div {
      background-color: transparent;
      > label {
        word-break: break-word !important;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    flex: 0 0 100%;
  }
`

export const LeftInputWrapper = styled(FlexInputWrapper)`
  > div {
    margin-bottom: 0;
  }
`

export const RightInputWrapper = styled(FlexInputWrapper)`
  display: flex;
  align-self: flex-start;
`

export const LabelWrapper = styled.div`
  flex: 0 0 100%;
  > span {
    display: block;
    color: #333333;
    font-size: 16px;
    font-family: Source Sans Pro, sans-serif;
    font-weight: normal;
  }
`

export const DescriptionWrapper = styled.div`
  margin: 10px;
  align-self: flex-start;
  padding-top: 9px;
  font-family: Source Sans Pro, sans-serif;
  > span {
    font-size: 16px;
  }
  @media only screen and (max-width: 991px) {
    margin-left: 0;
  }
`
