import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getDeleteTagModal = state => get(state, 'contacts.deleteTagModal', initialState)

export const selectIsOpen = createSelector(getDeleteTagModal, modal =>
  get(modal, 'isOpen', initialState.isOpen),
)

export const selectIsLoading = createSelector(getDeleteTagModal, modal =>
  get(modal, 'isLoading', initialState.isLoading),
)
