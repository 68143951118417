import { generateFullName } from '@rentspree/helper/build/generate-full-name'
import get from 'lodash/get'
import { createSelector } from 'reselect'

import { generateImageFromName, enhanceAddressObject } from '../helpers'

import { initialState } from './reducer'

export const getReducer = state => get(state, 'contacts.detail', initialState)

export const selectIsContactDetailLoading = createSelector(getReducer, detail =>
  get(detail, 'information.isLoading', false),
)

export const selectIsContactDeleting = createSelector(getReducer, detail =>
  get(detail, 'information.isDeleting', false),
)

export const selectLoadingButtons = createSelector(getReducer, detail =>
  get(detail, 'information.loadingButtons', []),
)

export const selectContactInformation = createSelector(getReducer, detail =>
  get(detail, 'information.data', initialState.information.data),
)

export const selectContactDetail = createSelector(selectContactInformation, contactInformation => ({
  ...contactInformation,
  livingAddressText: enhanceAddressObject(contactInformation.livingAddress),
  companyAddressText: enhanceAddressObject(contactInformation.companyAddress),
}))

export const selectContactName = createSelector(
  selectContactDetail,
  contactDetail => generateFullName(contactDetail) || contactDetail.email || '',
)

export const selectContactId = createSelector(selectContactDetail, contactDetail =>
  get(contactDetail, '_id', ''),
)

export const selectImagePlaceholder = createSelector(getReducer, detail => {
  const {
    email = '',
    firstName = '',
    lastName = '',
  } = get(detail, 'information.data', initialState.information.data)
  return generateImageFromName({ email, firstName, lastName })
})

// TODO: write test
export const selectIsTagging = createSelector(getReducer, detail =>
  get(detail, 'information.isTagging', initialState.information.isTagging),
)
