import React from "react"
import { withRouter, matchPath } from "react-router-dom"
import { PATHS_TO_POSTPONE } from "./const"
import GetStartedContainer from "./index"

export const GetStartedPostponer = ({ location: { pathname } }) => {
  const [shownGetStarted, setShownGetStarted] = React.useState(false)
  React.useEffect(() => {
    if (shownGetStarted) return
    const isPostpone = PATHS_TO_POSTPONE.some(path =>
      matchPath(pathname, {
        path,
        strict: false,
        exact: false,
      }),
    )
    if (!isPostpone) {
      setShownGetStarted(false)
    }
  }, [pathname, shownGetStarted])
  if (shownGetStarted) {
    return <GetStartedContainer />
  }

  return null
}
export default withRouter(GetStartedPostponer)
