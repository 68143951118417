import React from "react"
import get from "lodash/get"
import styled from "styled-components"
import { Col, Clearfix, Row } from "react-bootstrap"
import TextInput from "@rentspree/component-v2/dist/input/text"
import { withFormik } from "formik"
import Button from "@rentspree/component-v2/dist/button"
import { ButtonGroup } from "components/shared/form"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import PreviewSignature from "components/envelope/modal/preview-signature"
import { S16 } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"
import { textSignSignature } from "../form-schema"

export const Wrapper = styled.form`
  input {
    font-size: 14px;
  }

  #footerButtonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      :last-child {
        padding: 9px 15px 9px 15px;
        margin-left: 15px;
      }
    }
  }
`

export const SignButtonGroup = ({
  closeModal,
  nextButtonText,
  handleSubmit,
  isLoading,
}) => (
  <ButtonGroup id="footerButtonGroup">
    <Button
      google
      small
      semiBold
      fontSize="14px"
      id="signatureCancelBtn"
      text="Cancel"
      type="button"
      onClick={closeModal}
    />
    <BlueGradientButton
      small
      semiBold
      fontSize="14px"
      id="signatureNextBtn"
      className="right-btn"
      text={nextButtonText}
      type="button"
      onClick={handleSubmit}
      loading={isLoading}
    />
  </ButtonGroup>
)

export const InfoText = () => (
  <S16 color={COLOR.placeholderGrey} weight="400" size="12px" lineHeight="1.3">
    By selecting Adopt and Sign, I agree that the signature and initials will be
    the electronic representation of my signature and initials for all purposes
    when I (or my agent) use them on documents, including legally binding
    contracts - just the same as a pen-and-paper signature or initial.
  </S16>
)

export const Form = props => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    values,
    loading,
    nextButtonText,
    closeModal,
  } = props
  return (
    <Wrapper onSubmit={handleSubmit}>
      <Row>
        <Col md={8}>
          <TextInput
            id="fullName"
            name="fullName"
            type="text"
            isRequired
            label="Full Name"
            value={values.fullName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={get(touched, "fullName") && get(errors, "fullName")}
            disabled={loading}
          />
        </Col>
        <Col md={4} style={{ paddingLeft: "5px" }}>
          <TextInput
            id="initials"
            name="initials"
            type="text"
            isRequired
            label="Initials"
            value={values.initials}
            onBlur={handleBlur}
            onChange={handleChange}
            error={get(touched, "initials") && get(errors, "initials")}
            disabled={loading}
          />
        </Col>
      </Row>
      <Clearfix>
        <PreviewSignature
          signature={values.fullName}
          initial={values.initials}
        />
      </Clearfix>
      <InfoText />
      <SignButtonGroup
        closeModal={closeModal}
        nextButtonText={nextButtonText}
        handleSubmit={handleSubmit}
      />
    </Wrapper>
  )
}

const TextSignatureForm = withFormik({
  mapPropsToValues: props => textSignSignature(props).mapper,
  validationSchema: props => textSignSignature(props).validation,
  handleSubmit: (values, { props }) => {
    const { saveSignSignature, editSignSignature, mode } = props
    if (mode === "newSign") {
      saveSignSignature({
        type: "type",
        ...values,
      })
    } else {
      editSignSignature({
        type: "type",
        ...values,
      })
    }
  },
  enableReinitialize: true,
  displayName: "TextSignatureForm",
})(Form)

export default TextSignatureForm
