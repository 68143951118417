import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// TODO: need to create default export for BlueGradientButton only
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import Button from "@rentspree/component-v2/dist/button"
import BackButton from "@rentspree/component-v2/dist/button/back"
import get from "lodash/get"

const ButtonSetContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  font-family: Source Sans Pro;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 30px;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`

const ButtonSetSubContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.right ? "row-reverse" : "row")};
  justify-content: space-evenly;
`

const setMargin = isPullLeft =>
  isPullLeft ? "0px 10px 0px 0px" : "0px 0px 0px 10px"

const TYPES = {
  blueGradientButton: ({ isPullLeft, option = {} }, index) => (
    <BlueGradientButton
      {...option}
      hasIcon={get(option, "hasIcon", true)}
      id={option.id || "blueBtnInSet"}
      loadingText={option.loadingText || ""}
      margin={option.margin || setMargin(isPullLeft)}
      semiBold={get(option, "semiBold", true)}
      small={get(option, "small", true)}
      text={option.text || "Next"}
      type={option.type || "button"}
      key={`${isPullLeft ? "left" : "right"}-${index}`}
    />
  ),

  backButton: ({ isPullLeft, option = {} }, index) => (
    <BackButton
      {...option}
      backIcon={get(option, "backIcon", true)}
      id={option.id || "backBtnInSet"}
      margin={option.margin || setMargin(isPullLeft)}
      semiBold={get(option, "semiBold", true)}
      text={option.text || "Back"}
      key={`${isPullLeft ? "left" : "right"}-${index}`}
    />
  ),

  button: ({ isPullLeft, option = {} }, index) => (
    <Button
      {...option}
      google={(get(option, "google"), true)}
      id={option.id || "borderBackInSet"}
      leftIcon={get(option, "leftIcon", true)}
      margin={option.margin || setMargin(isPullLeft)}
      small={(get(option, "small"), true)}
      text={option.text || "Back"}
      type={option.type || "button"}
      key={`${isPullLeft ? "left" : "right"}-${index}`}
    />
  ),
}

const ButtonSet = ({ margin, buttons, minHeight }) => {
  const right = buttons.filter(button => !button.isPullLeft)
  const left = buttons.filter(button => button.isPullLeft)
  return (
    <ButtonSetContainer margin={margin} minHeight={minHeight}>
      <ButtonSetSubContainer right>
        {right.map((button, index) =>
          TYPES[button.type || "blue"](button, index),
        )}
      </ButtonSetSubContainer>
      <ButtonSetSubContainer>
        {left.map((button, index) =>
          TYPES[button.type || "blue"](button, index),
        )}
      </ButtonSetSubContainer>
    </ButtonSetContainer>
  )
}

ButtonSet.defaultProps = {
  buttons: [
    {
      type: "blueGradientButton",
      option: {
        text: "Next",
      },
      isPullLeft: false,
    },
    {
      type: "button",
      isPullLeft: true,
    },
  ],
}

ButtonSet.propTypes = {
  margin: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      // type of button
      type: PropTypes.oneOf(["blueGradientButton", "backButton", "button"]),
      // option will pass into button component
      option: PropTypes.object,
      // set button to left or right
      isPullLeft: PropTypes.bool,
    }),
  ),
}

export default ButtonSet
