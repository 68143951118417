import get from 'lodash/get'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import { savePDF, printReport } from 'containers/reports/actions'
import { selectParticipantId } from 'containers/reports/selectors'
import { toggleShareReportModal } from 'containers/share-report/actions'
import { selectAcceptDenyPayload } from 'containers/accept-deny/selectors'
import { openAcceptDenyModal } from 'containers/accept-deny/actions'
import { openAssignPropertyModal } from 'containers/tenant-screening/assign-property/actions'
import { addToContact, deleteContact } from 'containers/contacts/add-to-contact/actions'
import {
  selectIsAddDeleteContactLoading,
  selectSubmissionParticipantContactId,
  selectIsFoundContactByEmail,
} from 'containers/contacts/add-to-contact/selectors'
import injectSaga from 'utils/inject-saga'
import injectReducer from 'utils/inject-reducer'
import { selectIncomeVerification } from 'containers/reports/screening-reports/income-verification/selectors'
import * as ApplicationActions from '../../actions/application.action'
import * as CreditReportAction from '../../actions/credit-report.action'
import * as RefCheckActions from '../../actions/ref-check.action'
import * as ModalActions from '../../actions/modals.action'
import { pdfDocs, onShowPdfSuccess } from '../../actions/document.action'
import { currentReportStep } from '../../reducers/application.reducer'
import incomeVerificationSage from '../../../containers/reports/screening-reports/income-verification/saga'
import incomeVerificationReducer from '../../../containers/reports/screening-reports/income-verification/reducer'
import { getIncomeVerificationByRentalSubmission } from '../../../containers/reports/screening-reports/income-verification/actions'

const withReportConnect = connect(
  state => ({
    application: state.application,
    creditReportDetail: get(state.creditReport, 'creditReportDetail', {}),
    pdfReport: state.pdfReport,
    property: state.property && state.property.property,
    isLogin: !!state.persistState.auth.accessToken,
    lraForm: state.lraForm,
    modals: state.modals,
    userIntegration: get(state, 'user.userIntegration', []),
    profile: get(state, 'user.profile', {}),
    currentReportStep: currentReportStep(state),
    subscriptions: state.subscriptions,
    submissionParticipantDetail:
      state.submissionParticipant && state.submissionParticipant.participantData,
    acceptDenyPayload: selectAcceptDenyPayload(state),
    isAddDeleteContactLoading: selectIsAddDeleteContactLoading(state),
    submissionParticipantId: selectParticipantId(state),
    submissionParticipantContactId: selectSubmissionParticipantContactId(state),
    isFoundContact: selectIsFoundContactByEmail(state),
    incomeVerification: selectIncomeVerification(state),
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        ...ApplicationActions,
        ...CreditReportAction,
        ...RefCheckActions,
        ...ModalActions,
        pdfDocs,
        onShowPdfSuccess,
        toggleShareReportModal,
        savePDF,
        printReport,
        openAcceptDenyModal,
        deleteContact,
        addToContact,
        openAssignPropertyModal,
        getIncomeVerificationByRentalSubmission,
      },
      dispatch,
    ),
  }),
)

const withIncomeVerificationSaga = injectSaga({
  key: 'income-verification',
  saga: incomeVerificationSage,
})

const withIncomeVerificationReducer = injectReducer({
  key: 'income-verification',
  reducer: incomeVerificationReducer,
})

export default compose(withReportConnect, withIncomeVerificationSaga, withIncomeVerificationReducer)
