import isEmpty from 'lodash/isEmpty'

import createAction from '../apis/utils/create-action'
import * as Types from '../constants/action-types'
import * as api from '../apis/request.api'
import { PAYER } from '../constants/request-const'
import tracker from '../tracker'
import { EVENT_REQUEST_SCREENING } from '../tracker/const'

export const selectPayer = payer => {
  if (payer === PAYER.AGENT) {
    return { type: Types.SELECT_AGENT_AS_PAYER }
  }
  return { type: Types.SELECT_RENTER_AS_PAYER }
}

export const selectPricingItem = (item, currentPriceScheme) => ({
  type: Types.SELECT_PRICING_SCHEME,
  item,
  currentPriceScheme,
})

export const updateDocumentProOption = (documentKey, change) => ({
  type: Types.UPDATE_DOCUMENT_PRO_OPTION,
  documentKey,
  change,
})

export const updateRefCheckProOption = (refCheckKey, isSelected) => ({
  type: Types.UPDATE_REF_CHECK_PRO_OPTION,
  refCheckKey,
  isSelected,
})

export const clearScreeningWithPropertyProOptions = () => ({
  type: Types.CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS,
})

export const setApplicationType = applicationType => ({
  type: Types.SET_APPLICATION_TYPE,
  applicationType,
})

export const saveSelectedProperty = selectedProperty => ({
  type: Types.SAVE_SELECTED_PROPERTY,
  selectedProperty,
})

export const clearRequestOptions = () => ({ type: Types.CLEAR_REQUEST_OPTIONS })

export const getScreeningPlanApi = createAction(
  api.getScreeningPlanApi,
  [
    Types.GET_SCREENING_PLAN_REQUEST,
    Types.GET_SCREENING_PLAN_SUCCESS,
    Types.GET_SCREENING_PLAN_FAILED,
  ],
  (res = {}) => {
    const data = res || []
    return {
      data,
    }
  },
)

const ofCreateScreeningRequestApi = createAction(
  api.createScreeningRequestApi,
  [
    Types.CREATE_SCREENING_REQUEST_REQUEST,
    Types.CREATE_SCREENING_REQUEST_SUCCESS,
    Types.CREATE_SCREENING_REQUEST_FAILED,
  ],
  (res = {}) => ({ data: res }),
)

export const createScreeningRequest = (propertyId, data) => (dispatch, getState) =>
  ofCreateScreeningRequestApi(propertyId, data)(dispatch, getState).then((res = {}) => {
    const { success = true, data: resData } = res
    if (success) {
      const {
        application = false,
        applicationType,
        credit_report: creditReport = false,
        criminal = false,
        eviction = false,
      } = resData?.screeningOption
      const { documents, references } = resData?.proOption || {}
      tracker.trackEvent(EVENT_REQUEST_SCREENING.selectScreening, {
        click_from: EVENT_REQUEST_SCREENING.clickFromCreateScreeningRequest,
        has_rental_app: application,
        has_credit_report: creditReport,
        has_criminal: criminal,
        has_eviction: eviction,
        template: applicationType || "N/A",
        has_doc_upload: !isEmpty(documents),
        has_ref_check: !isEmpty(references),
      })
    }
    return res
  })

export const setValidationError = hasValidationError => ({
  type: Types.SET_SCREENING_OPTION_VALIDATION_ERROR,
  hasValidationError,
})

export const clearValidationError = () => ({
  type: Types.CLEAR_SCREENING_OPTION_VALIDATION_ERROR,
})
