import React from "react"
import { PanelGroup, Panel } from "react-bootstrap"
import { PRO_PAGE } from "constants/route"
import { RouterLink } from "../buttons/buttons"
import { P } from "../typography"
import { PanelHeading } from "./panel-box"

export const RequestDocsFaq = ({ clickPanel, headerWithIcon }) => (
  <PanelGroup accordion>
    <Panel eventKey={1} onClick={() => clickPanel("clickFaqHowToStartReqDocs")}>
      <PanelHeading>
        {headerWithIcon("How can I start using Renter Document Upload?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          To use the Renter Document Upload feature, you must first have an
          active subscription to RentSpree PRO. If you don’t have one, you can
          learn more about activating RentSpree PRO&nbsp;
          <RouterLink
            id="linkToProLandingPage"
            to={PRO_PAGE}
            inlineLink
            text="here"
          />
          .
        </P>
        <P>
          Once your RentSpree PRO subscription is active, you will be able to
          request documents from renters after you receive an application back.
          To request documents, visit the “Documents” tab when you are viewing
          an application that you’ve received. From there, you will be able to
          select the documents you want the renter to upload for you. When you
          select which documents you want the renter to upload, the renter will
          receive an email notification asking them to upload the documents. You
          will be notified via email when a renter has successfully uploaded all
          the documents you requested.
        </P>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={2}
      onClick={() => clickPanel("clickFaqWhatDocsCanCollect")}>
      <PanelHeading>
        {headerWithIcon("What documents can I collect?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          You will be able to requests documents such as proof of income, bank
          statements, tax returns, W-2s, government-Issued id copies, and pet
          information. Renters can upload copies of these documents and you will
          be able to access them from your dashboard.
        </P>
      </Panel.Body>
    </Panel>
  </PanelGroup>
)
