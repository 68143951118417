import { useState, useCallback } from 'react'
import axios from 'axios'
import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'
import { GOOGLE_PLACE_API_KEY } from 'env'
import * as Sentry from '@sentry/browser'

export const AUTOCOMPLETE_API_BASE_URL = 'https://places.googleapis.com/v1/places:autocomplete'
export const PLACE_DETAILS_API_BASE_URL = 'https://places.googleapis.com/v1/places'
export const includedPrimaryTypes = ['premise', 'subpremise', 'street_number', 'street_address']
export const includedRegionCodes = ['us', 'pr']
export const autocompleteFieldMask =
  'suggestions.placePrediction.placeId,suggestions.placePrediction.text.text'
export const placeDetailsFieldMask = 'addressComponents'

const formatDisplayedAddress = addressDetails => {
  const { streetLine, secondary, city, state, zipcode } = addressDetails
  let formattedAddressDetails = ''

  if (streetLine) {
    formattedAddressDetails += streetLine
  }
  if (secondary) {
    formattedAddressDetails += `${formattedAddressDetails && ', '}${secondary}`
  }
  if (city) {
    formattedAddressDetails += `${formattedAddressDetails && ', '}${city}`
  }
  if (state) {
    formattedAddressDetails += `${formattedAddressDetails && ', '}${state}`
  }
  if (zipcode) {
    formattedAddressDetails += `${formattedAddressDetails && ', '}${zipcode}`
  }

  return formattedAddressDetails
}

export const buildAddressDetails = addressComponents => {
  const addressDetails = { streetLine: '', secondary: '', city: '', state: '', zipcode: '' }

  const typeHandlers = {
    street_number: ({ longText }) => {
      addressDetails.streetLine = longText
    },
    route: ({ longText }) => {
      addressDetails.streetLine += ` ${longText}`
    },
    subpremise: ({ longText }) => {
      addressDetails.secondary = longText
    },
    sublocality: ({ longText }) => {
      if (!addressDetails.city) addressDetails.city = longText
    },
    locality: ({ longText }) => {
      addressDetails.city = longText
    },
    administrative_area_level_1: ({ shortText }) => {
      addressDetails.state = shortText
    },
    postal_code: ({ longText }) => {
      addressDetails.zipcode = longText
    },
  }

  addressComponents.forEach(addressComponent => {
    const { types } = addressComponent

    types.forEach(type => {
      if (typeHandlers[type]) {
        typeHandlers[type](addressComponent)
      }
    })
  })

  const formattedDisplayedAddress = formatDisplayedAddress(addressDetails)
  addressDetails.displayedAddress = formattedDisplayedAddress

  return addressDetails
}

export const getAddressDetails = async (placeId, sessionToken, setIsPlaceDetailsCalled) => {
  try {
    if (!placeId) return {}

    const { data } = await axios.get(`${PLACE_DETAILS_API_BASE_URL}/${placeId}`, {
      headers: {
        'X-Goog-FieldMask': placeDetailsFieldMask,
        'X-Goog-Api-Key': GOOGLE_PLACE_API_KEY,
      },
      params: { sessionToken },
    })
    setIsPlaceDetailsCalled(true)

    const addressDetails = buildAddressDetails(data.addressComponents)

    if (!addressDetails.city) {
      Sentry.captureMessage(
        `[Google Autocomplete] locality and sublocality not exists for placeId: ${placeId}`,
      )
    }

    return addressDetails
  } catch (error) {
    console.error('Call to Google API Place Details failed: ', error)
    return {}
  }
}

/**
 * This hook provides Google Autocomplete functionality.
 * For detailed usage instructions and examples, please refer to the Notion page:
 * https://www.notion.so/rentspree/Autocomplete-Hook-b17600f0d9924257aaefa4133d339c01?pvs=4
 */
export const useGoogleAutoComplete = (parentSessionToken, parentSetSessionToken) => {
  const [addresses, setAddresses] = useState([])
  const [isPlaceDetailsCalled, setIsPlaceDetailsCalled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [localSessionToken, setLocalSessionToken] = useState('')

  const sessionToken = parentSessionToken || localSessionToken
  const setSessionToken = parentSetSessionToken || setLocalSessionToken
  let currentSessionToken = sessionToken

  const fetchAddresses = useCallback(
    async inputAddress => {
      setError(null)
      setIsLoading(true)
      try {
        if (!currentSessionToken || isPlaceDetailsCalled) {
          currentSessionToken = uuidv4()
          setSessionToken(currentSessionToken)
          setIsPlaceDetailsCalled(false)
        }

        const { data } = await axios.post(
          AUTOCOMPLETE_API_BASE_URL,
          {
            input: inputAddress,
            includedPrimaryTypes,
            includedRegionCodes,
            sessionToken: currentSessionToken,
          },
          {
            headers: {
              'X-Goog-Api-Key': GOOGLE_PLACE_API_KEY,
              'X-Goog-FieldMask': autocompleteFieldMask,
            },
          },
        )

        const suggestedAddresses = data?.suggestions || []
        const formattedAddresses = suggestedAddresses.map((address, index) => {
          const formattedAddress = {
            key: index,
            displayedAddress: address.placePrediction.text.text,
            placeId: address.placePrediction.placeId,
          }
          return formattedAddress
        })

        setAddresses(formattedAddresses)
      } catch (e) {
        setAddresses([])
        setError(e)
        Sentry.captureException(get(e, 'data.errors') || e)
      } finally {
        setIsLoading(false)
      }
    },
    [currentSessionToken, isPlaceDetailsCalled],
  )

  return {
    addresses,
    setAddresses: fetchAddresses,
    getAddressDetails: async placeId => {
      const addressDetails = await getAddressDetails(
        placeId,
        currentSessionToken,
        setIsPlaceDetailsCalled,
      )
      return addressDetails
    },
    isLoading,
    error,
  }
}
