import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import { buildPath, query } from '@rentspree/path'
import get from 'lodash/get'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { RENT_PAYMENT_REQUEST_PAGE } from 'constants/route'
import { selectProfile } from 'containers/user/selectors'
import tracker from 'tracker'
import { RENT_PAYMENT } from 'tracker/const'
import {
  useGetOrCreateRenterInfo,
  useRentPaymentModalState,
} from 'v3/containers/rent-payment/shared/hooks'
import { useSearchRenterInfo } from 'v3/containers/rent-payment/shared/hooks/use-search-renter-info'
import { API_STATUS } from 'v3/containers/rent-payment/shared/redux/constants'

import { CreateTenantForm } from './form'

const selectOwnerEmail = createSelector(selectProfile, state => get(state, 'email', ''))

export const CreateTenantModal = ({ history, location, clickFrom }) => {
  const {
    createRenterInfoModal: { isOpen, close },
  } = useRentPaymentModalState()

  const { searchRenterInfo, status: searchRenterInfoStatus } = useSearchRenterInfo()

  const { getOrCreateRenterInfo, status: getOrCreateRenterInfoStatus } = useGetOrCreateRenterInfo()

  const ownerEmail = useSelector(selectOwnerEmail)

  const queryParams = useMemo(() => query.parse(location?.search), [location?.search])

  const handleCloseModal = () => {
    close()

    tracker.trackEvent(RENT_PAYMENT.EVENT_NAME.CANCEL_ADD_NEW_TENANT, {
      user_type: RENT_PAYMENT.EVENT_PROPERTY.USER_TYPE.LANDLORD,
      click_from: clickFrom,
    })
  }

  const handleSubmit = async (values, { setErrors }) => {
    let renterInfo

    try {
      renterInfo = await getOrCreateRenterInfo({ ...values, clickFrom })
    } catch (e) {
      if (
        e?.data?.code === 'INPUT_VALIDATION_ERROR' &&
        e?.data?.data?.errors[0]?.field === 'email'
      ) {
        setErrors({ email: 'Invalid email' })
        return
      }

      throw e
    }

    searchRenterInfo()

    history.push(
      buildPath(RENT_PAYMENT_REQUEST_PAGE, {}, { ...queryParams, renterInfo: renterInfo.id }),
    )

    close()

    tracker.trackEvent(RENT_PAYMENT.EVENT_NAME.ADD_NEW_TENANT_SUCCESS, {
      user_type: RENT_PAYMENT.EVENT_PROPERTY.USER_TYPE.LANDLORD,
      click_from: clickFrom,
    })
  }

  const isLoading =
    searchRenterInfoStatus === API_STATUS.FETCHING ||
    getOrCreateRenterInfoStatus === API_STATUS.FETCHING

  return (
    <RentSpreeBaseModal
      id="rentPaymentCreatePropertyModal"
      rootId="#app"
      modalSize="medium"
      padding="25px"
      isOpen={isOpen}
      onClose={handleCloseModal}
      closeButton={!isLoading}
      shouldCloseOnOverlayClick={false}
    >
      <S24 mSize="24px" margin="0 0 15px" mMargin="0 0 25px" lineHeight="32px">
        Enter the tenant’s details
      </S24>
      <CreateTenantForm
        onSubmit={handleSubmit}
        ownerEmail={ownerEmail}
        onCancel={handleCloseModal}
        isLoading={isLoading}
      />
    </RentSpreeBaseModal>
  )
}
