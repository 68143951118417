import { defineMessages } from "react-intl"

export const CLEAR_ERROR = "CLEAR_ERROR"

export const errorMessage = defineMessages({
  networkError: {
    id: "networkError",
    defaultMessage:
      "Sorry, your info could not be saved due to an internal error. Please review the form and try again.",
  },
  generalSaveError: {
    id: "generalSaveError",
    defaultMessage:
      "We've encountered an error saving your info. Please check all fields and try again.",
  },
  errorSubtitle: {
    id: "errorSubtitle",
    defaultMessage:
      "If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.",
  },
  fileErrorMessage: {
    id: "fileValidationError",
    defaultMessage:
      "Please make sure that each file size is less than 20MB and they are one of these types: PDF, PNG, JPG, JPEG.",
  },
  sendEmailError: {
    id: "sendEmailError",
    defaultMessage:
      "We’re having trouble sending the request. Please try again.",
  },
  fileEmptyErrorMessage: {
    id: "fileEmptyError",
    defaultMessage: "Please upload at least one file.",
  },
})
