import React, { useEffect } from "react"
import { compose } from "redux"
import propTypes from "prop-types"
import { parse, stringify } from "@rentspree/path"

import * as PATH from "constants/route"
import SelectPropertyTemplate from "components/templates/select-property"
import { BACK_BUTTON_TYPE } from "constants/back-button-type"
import { withConnect, withSaga, withReducer } from "./connect"

export const SelectProperty = ({
  propertyList,
  actions,
  history,
  location,
  redirectRoute,
  handleCloseError,
  handleSubmit,
  backToDashboard,
  prev,
  onCreateProperty,
  allowToCreateProperty,
  isLoading,
  customSearch,
  pageTitle,
  dropdownLabel,
  backButtonType,
  ...rest
}) => {
  useEffect(() => {
    const searchObj = parse(history.location.search)
    const searchObjCustomSearch = stringify({
      ...searchObj,
      ...customSearch,
    })
    actions.getPropertyList({
      search: searchObjCustomSearch,
      state: location.state,
      redirectRoute,
    })
  }, [])
  if (isLoading) {
    return <></>
  }
  return (
    <SelectPropertyTemplate
      propertyList={propertyList}
      handleCloseError={handleCloseError}
      handleSubmit={handleSubmit}
      backToDashboard={backToDashboard}
      onCreateProperty={onCreateProperty}
      allowToCreateProperty={allowToCreateProperty}
      isLoading={isLoading}
      pageTitle={pageTitle}
      prev={prev}
      dropdownLabel={dropdownLabel}
      backButtonType={backButtonType}
      {...rest}
    />
  )
}

SelectProperty.propTypes = {
  handleCloseError: propTypes.func,
  handleSubmit: propTypes.func,
  backToDashboard: propTypes.func,
  onCreateProperty: propTypes.func,
  allowToCreateProperty: propTypes.bool,
  redirectRoute: propTypes.string,
  customSearch: propTypes.object,
  pageTitle: propTypes.string,
  dropdownLabel: propTypes.string,
  backButtonType: propTypes.string,
}

SelectProperty.defaultProps = {
  allowToCreateProperty: true,
  redirectRoute: PATH.REQUEST,
  customSearch: {},
  backButtonType: BACK_BUTTON_TYPE.BACK,
}

export default compose(
  withConnect,
  withSaga,
  withReducer,
)(SelectProperty)
