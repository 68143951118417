import get from 'lodash/get'
import { createSelector } from 'reselect'
import { API_STATUS, rentalPaymentInitialState } from '../reducers'
import { KEY } from '../constants'

export const selectRentalPaymentState = state =>
  get(state, KEY.RENTAL_PAYMENT, rentalPaymentInitialState)

// rental payment entities
export const makeSelectRentalPaymentEntities = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'entities', {}))

export const makeSelectRentalPaymentById = rentalPaymentId =>
  createSelector(makeSelectRentalPaymentEntities(), entities => get(entities, rentalPaymentId))

// rental payment exist
export const makeSelectRentalPaymentExists = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'rentalPaymentExists'))

export const makeSelectRentalPaymentExistsResult = () =>
  createSelector(makeSelectRentalPaymentExists(), state => get(state, 'data.result', undefined))

export const makeSelectRentalPaymentExistsCount = () =>
  createSelector(makeSelectRentalPaymentExists(), state => get(state, 'data.count', 0))

export const makeSelectRentalPaymentExistsStatus = () =>
  createSelector(makeSelectRentalPaymentExists(), state => get(state, 'status'))

// latest rental payment
export const makeSelectLatestRentalPaymentId = () =>
  createSelector(selectRentalPaymentState, state => get(state, 'latestRentalPayment.id', ''))

export const makeSelectLatestRentalPaymentStatus = () =>
  createSelector(selectRentalPaymentState, state =>
    get(state, 'latestRentalPayment.status', API_STATUS.NONE),
  )

// update payout method all active rental payments
export const makeSelectUpdatePayoutMethodAllActiveRentalPaymentsStatus = () =>
  createSelector(selectRentalPaymentState, state =>
    get(state, 'updatePayoutAllActiveRentalPayments.status', API_STATUS.NONE),
  )
