import { PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION } from './constants'

export const initialState = {
  isOpen: false,
  fromPathname: '',
  isAutoOpened: false,
  isOpenedFromNavBarClick: false,
  isReferralPageOpened: false,
}

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.OPEN_PAGE:
      return {
        ...state,
        isOpen: true,
        fromPathname: action.payload?.fromPathname || '',
        isAutoOpened: action.payload?.isAutoOpened || false,
        isOpenedFromNavBarClick: action.payload?.isOpenedFromNavBarClick || false,
      }
    case PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.CLOSE_PAGE:
      return {
        ...state,
        ...initialState,
      }
    case PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.OPEN_REFERRAL_PAGE:
      return {
        ...state,
        isReferralPageOpened: true,
      }
    case PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.CLOSE_REFERRAL_PAGE:
      return {
        ...state,
        isReferralPageOpened: false,
      }
    default:
      return state
  }
}
