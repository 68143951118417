/**
 * splitFullAddressPart split full address to street, unitNumber, city, state, and zipcode
 *
 * @param {string} toBeSplitFullAddress - the full address in ups formatted
 */
export const splitFullAddressPart = (toBeSplitFullAddress) => {
  const trimedFullAddress = toBeSplitFullAddress.trim()
  const parts = trimedFullAddress.split(/^((?:\d{1,8}(-)?[a-z]?(\s\d+\/\d+)?\W)?[a-z|\w|\s]{1,}\W?(road|drive|avenue|boulevard|circle|street|lane|waylrd.|st.|dr.|ave.|blvd.|cir.|In.|rd|dr|ave|blvd|cir|ln)?)\W\s?((?:suite|unit|unt|apr|apartment)?\s?\d{1,4})?\W?\s?([a-zA-Z\s]+)(,?\s([A-Z]{2}))?(,?\s(\d{5}(-\d{4})?))?$/gi)

  return {
    street: parts[1]?.replace(/(^,)|(,$)/g, ""),
    unitNumber: parts[5],
    city: parts[6],
    state: parts[8],
    zipcode: parts[10],
  }
}
