import isEmpty from "lodash/isEmpty"

export const getInitialName = fullName => {
  if (isEmpty(fullName)) return "Initial"
  return fullName
    .split(" ")
    .filter(d => d)
    .map(([firstLetter]) => firstLetter && firstLetter)
    .filter((_, index) => index < 5)
    .join("")
    .toUpperCase()
}

const prefixes = [
  "adm",
  "atty",
  "brother",
  "capt",
  "chief",
  "cmdr",
  "col",
  "dean",
  "dr",
  "elder",
  "father",
  "gen",
  "gov",
  "hon",
  "maj",
  "msgt",
  "mr",
  "ms",
  "prince",
  "prof",
  "rabbi",
  "rev",
  "sister",
  "mister",
  "mrs",
  "sir",
]

export const getSignWithRecipientFirstName = fullName => {
  if (isEmpty(fullName)) {
    return "Sign here"
  }
  const arrayOfName = fullName.split(" ")
  const [checkPrefix, preFirstName] = arrayOfName
  let recipientFirstName = checkPrefix
  if (prefixes.includes(checkPrefix.toLowerCase()) && arrayOfName.length > 1) {
    recipientFirstName = preFirstName
  }
  let firstNameWithSign = `${recipientFirstName} sign`
  if (recipientFirstName.length > 11) {
    firstNameWithSign = `${recipientFirstName.substring(0, 11)}... sign`
  }
  return firstNameWithSign
}
