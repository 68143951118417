import {
  APP_NOT_SUBMIT_STATUS,
  APP_STATUS,
} from "legacy/constants/application-consts"
import isEmpty from "lodash/isEmpty"
import { generateFullName } from "@rentspree/helper"

/**
 * This method return the speech according to the report information for screen reader
 *
 * @param {Object} consist of { firstName: string, lastName: string, email: string, status: string }
 * @returns {String}
 */
export const getReportVoiceSpeech = ({
  firstName,
  lastName,
  email,
  status,
}) => {
  const isReportUnOpenable = APP_NOT_SUBMIT_STATUS.includes(status)
  const applicantDisplayName = !isEmpty(firstName)
    ? generateFullName({ firstName, lastName })
    : email

  const statusText = APP_STATUS[status]?.text || status
  return `Submission of ${applicantDisplayName} status ${statusText} ${
    isReportUnOpenable ? "" : "Link"
  }`.trim()
}
