import produce from 'immer'
import {
  OPEN_ACCEPT_DENY_MODAL,
  CLEAR_ACCEPT_DENY_MODAL,
  ACCEPT_DENY_TYPE,
  ACCEPT_DENY_REQUEST,
  ACCEPT_DENY_SUCCESS,
  ACCEPT_DENY_FAILURE,
  CHANGE_ACCEPT_DENY_TYPE,
  COMPLETE_ACCEPT_ON_CONDITION_FORM,
  COMPLETE_DENY_FORM,
  BACK_TO_FORM,
  CONDITION_FORM,
  GET_DISCLAIMER_CONTENT_REQUEST,
  GET_DISCLAIMER_CONTENT_SUCCESS,
  GET_DISCLAIMER_CONTENT_FAILURE,
  AGREE_DISCLAIMER_REQUEST,
  AGREE_DISCLAIMER_SUCCESS,
  AGREE_DISCLAIMER_FAILURE,
  SHOW_SECURITY_DEPOSIT_MODAL,
  CLOSE_SECURITY_DEPOSIT_MODAL,
} from './constants'
export const initialState = {
  isDisclaimerAccepted: false,
  isFormCompleted: false,
  type: '',
  propertyId: '',
  propertyAddress: {},
  isSharedReport: false,
  rentalSubmission: {
    id: '',
    renter: {},
    email: '',
  },
  acceptOnConditionForm: {
    conditionInput: '',
    ...CONDITION_FORM,
  },
  disclaimer: {
    isFetchingContent: false,
    isSubmitting: false,
    data: {},
  },
  denyForm: CONDITION_FORM,
  rentalSubmissionId: '',
  isSubmitting: false,
  clickFrom: '',
  clickFromWidget: '',
  screeningResult: '',
  securityDepositClickFrom: '',
  showSecurityDepositModal: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_ACCEPT_DENY_MODAL:
        draft.isDisclaimerAccepted = false
        draft.type = payload.acceptDenyType || ACCEPT_DENY_TYPE.ACCEPT
        draft.rentalSubmission = payload.rentalSubmission
        draft.propertyId = payload.propertyId
        draft.propertyAddress = payload.propertyAddress
        draft.isSharedReport = payload.isSharedReport
        draft.clickFrom = payload.clickFrom
        draft.screeningResult = payload.screeningResult
        draft.clickFromWidget = payload.clickFromWidget
        break
      case CLEAR_ACCEPT_DENY_MODAL:
        draft = initialState
        break
      case ACCEPT_DENY_REQUEST:
        draft.isSubmitting = true
        break
      case ACCEPT_DENY_SUCCESS:
      case ACCEPT_DENY_FAILURE:
        draft.isSubmitting = false
        break
      case CHANGE_ACCEPT_DENY_TYPE:
        draft.isFormCompleted = false
        draft.type = payload.acceptDenyType
        break
      case COMPLETE_ACCEPT_ON_CONDITION_FORM:
        draft.acceptOnConditionForm = payload.values
        draft.isFormCompleted = true
        break
      case COMPLETE_DENY_FORM:
        draft.denyForm = payload.values
        draft.isFormCompleted = true
        break
      case BACK_TO_FORM:
        draft.isFormCompleted = false
        break
      case GET_DISCLAIMER_CONTENT_REQUEST:
        draft.disclaimer.isFetchingContent = true
        break
      case GET_DISCLAIMER_CONTENT_FAILURE:
        draft.disclaimer.isFetchingContent = false
        break
      case GET_DISCLAIMER_CONTENT_SUCCESS:
        draft.disclaimer.isFetchingContent = false
        draft.disclaimer.data = payload
        break
      case AGREE_DISCLAIMER_REQUEST:
        draft.disclaimer.isSubmitting = true
        break
      case AGREE_DISCLAIMER_FAILURE:
        draft.disclaimer.isSubmitting = false
        break
      case AGREE_DISCLAIMER_SUCCESS:
        draft.disclaimer.isSubmitting = false
        draft.isDisclaimerAccepted = true
        break
      case SHOW_SECURITY_DEPOSIT_MODAL:
        draft.showSecurityDepositModal = true
        draft.propertyId = payload.propertyId
        draft.tenantEmail = payload.tenantEmail
        draft.securityDepositClickFrom = payload.securityDepositClickFrom
        break
      case CLOSE_SECURITY_DEPOSIT_MODAL:
        draft = initialState
        break
      default:
        break
    }
    return draft
  })

export default reducer
