import screeningImage from "images/tenant-screening/tenant-screening.svg"
import withEaseImage from "images/tenant-screening/with-ease.svg"
import anyWhereImage from "images/tenant-screening/anywhere.svg"
import noCostImage from "images/tenant-screening/no-cost.svg"

export const BUTTON_TEXT = "Screen New Tenants"

export const MAIN_CONTENT = {
  imgSrc: screeningImage,
  title: "Tenant Screening",
  description:
    "Collect applications and screen new tenants to receive TransUnion credit reports,\ncriminal background checks, and eviction related proceedings.",
}

export const EXTRA_TITLE =
  "Our free, fast, and easy-to-use verification process is backed by TransUnion"

export const EXTRA_DETAILS = [
  {
    imgSrc: withEaseImage,
    title: "Screen tenants with ease",
    description:
      "Start screening tenants and collecting applications in just minutes.",
  },
  {
    imgSrc: anyWhereImage,
    title: "Access reports from anywhere",
    description:
      "Reports are available 24/7 from your dashboard and are mobile-optimized.",
  },
  {
    imgSrc: noCostImage,
    title: "No cost to you",
    description:
      "Our services are free for agents and property owners. Applicants pay the application fee directly to RentSpree.",
  },
]

export const TOP_BUTTON_ID = "tenantScreeningTop"
export const BOTTOM_BUTTON_ID = "tenantScreeningBottom"
