import React, { useEffect } from "react"
import { DragPreviewImage, useDrag } from "react-dnd"

import isNil from "lodash/isNil"
import get from "lodash/get"
import includes from "lodash/includes"

import tracker from "tracker"
import { EVENT_LEASE_DOC_REVIEW_AND_SIGN } from "tracker/const"
import blankImage from "images/backgrounds/blank.png"

import { SIGN_TYPE, TYPES_COMPONENT } from "./constants"
import { generatorConfig } from "./generator-config"
import { useDimensions } from "./helpers/use-dimensions"

export const InitDragBox = Component => ({
  element,
  children,
  type,
  fieldId,
  parentDimensions,
  isInterActiveType,
  previewElement,
  recipient,
  assignee,
  colorIndex,
  disabled,
  small,
  ratio,
  ...props
}) => {
  const { styles, value } = generatorConfig(type, small)

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type,
      element,
      initBox: true,
      styles,
      value,
      fieldId,
      isInterActiveType,
      parentDimensions,
      previewElement,
      recipient,
      assignee,
      colorIndex,
      small,
      ratio,
    },
    canDrag: !disabled,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 1 : 0,
    }),
  })

  return (
    <>
      <DragPreviewImage connect={preview} src={blankImage} />
      <Component
        ref={drag}
        isDragging={isDragging}
        colorIndex={colorIndex}
        disabled={disabled}
        small={small}
        {...props}>
        {children}
      </Component>
    </>
  )
}

export const DragBox = Component => ({
  page,
  children,
  type,
  fieldId,
  parentDimensions,
  viewMode,
  value,
  onChangeStyle,
  item,
  active,
  styles,
  colorIndex,
  recipient,
  small,
  ratio,
  ...props
}) => {
  const targetRef = React.useRef()
  const [dimensions] = useDimensions(targetRef)

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type,
      fieldId,
      dimensions,
      page,
      parentDimensions,
      value,
      styles,
      previewElement: Component,
      colorIndex,
      recipient,
      unmovable: get(item, "unmovable"),
      unresizable: get(item, "unresizable"),
      small,
      ratio,
    },
    canDrag: !viewMode && !get(item, "unmovable"),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    const canUpdateWidth =
      viewMode &&
      !isNil(value) &&
      item.canEdit &&
      includes(SIGN_TYPE, item.type)
    if (canUpdateWidth) {
      const { offsetWidth, offsetHeight } = get(
        targetRef,
        ["current", "firstChild"],
        {},
      )
      onChangeStyle({ width: offsetWidth, height: offsetHeight }, item)
      const canCAllTracker =
        item.type === TYPES_COMPONENT.SIGNATURE_SIGNS &&
        item.signatureType === "type"
      if (canCAllTracker) {
        // it will call trackEvent when component type is signature and signature value is text, not draw type.
        tracker.trackEvent(
          EVENT_LEASE_DOC_REVIEW_AND_SIGN.SIGNATURE_PLACE_ON_DOC,
          { width: offsetWidth },
        )
      }
    }
  }, [value])

  drag(targetRef)

  return (
    <>
      <DragPreviewImage connect={preview} src={blankImage} />
      <Component
        targetRef={targetRef}
        isDragging={isDragging}
        viewMode={viewMode}
        page={page}
        fieldId={fieldId}
        onChangeStyle={onChangeStyle}
        value={value}
        active={active}
        styles={styles}
        colorIndex={colorIndex}
        recipient={recipient}
        small={small}
        ratio={ratio}
        {...props}>
        {children}
      </Component>
    </>
  )
}
