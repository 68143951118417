import React from "react"
import { buildPath, parse } from "@rentspree/path"

import * as PATH from "constants/route"
import SelectProperty from "../select-property"
import { AGENT_PROFILE } from "./constant"

const ListingLanding = props => {
  const { history } = props
  const CREATE_LISTING_PATH = "/create-listing/listing-info"
  const queryString = parse(history.location.search)
  const handleSubmit = propertyId => {
    const createListingPageURL = buildPath(
      PATH.IMPORT_MLS_LISTING,
      {
        propertyId,
      },
      {
        ...queryString,
      },
    )
    history.push(createListingPageURL)
  }

  const backToDashboard = () => {
    const propertyListPageURL = buildPath(
      PATH.ROOT,
      {},
      {
        ...queryString,
      },
    )
    history.push(propertyListPageURL)
  }

  const back = () => {
    history.goBack()
  }

  const onCreateProperty = () => {
    const createPropertyPageURL = buildPath(
      PATH.REQUEST,
      {},
      {
        ...queryString,
        continueTo: CREATE_LISTING_PATH,
      },
    )
    history.push(createPropertyPageURL)
  }

  return (
    <SelectProperty
      handleSubmit={handleSubmit}
      backToDashboard={
        queryString.source === AGENT_PROFILE ? back : backToDashboard
      }
      onCreateProperty={onCreateProperty}
      customSearch={{ continueTo: CREATE_LISTING_PATH }}
      {...props}
    />
  )
}

export default ListingLanding
