import { API_URL } from 'env'

export const GET_ALL_TAGS_API = `${API_URL}/api/v2/people/tags`
export const CREATE_TAG_API = `${API_URL}/api/v2/people/tags`
export const TAG_CONTACT_API = `${API_URL}/api/v2/people/contacts/:contactId/tag/:tagId`
export const UNTAG_CONTACT_API = `${API_URL}/api/v2/people/contacts/:contactId/tag/:tagId`

export const GET_ALL_TAGS_CALL = 'GET_ALL_TAGS_CALL'
export const GET_ALL_TAGS_REQUEST = 'GET_ALL_TAGS_REQUEST'
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_FAILURE = 'GET_ALL_TAGS_FAILURE'

export const APPEND_TAG = 'APPEND_TAG'

export const DELETE_TAG_API = `${API_URL}/api/v2/people/tags/:tagId`

export const DELETE_TAG_CALL = 'DELETE_TAG_CALL'
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE'

export const DELETE_TAG_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'Tag deleted',
  status: 'success',
  timeOut: 5000,
  width: '350px',
}

export const DELETE_TAG_FAILURE_TOAST_CONFIG = {
  titleMessage: 'Delete tag error',
  status: 'error',
  timeOut: 5000,
  width: '350px',
}
