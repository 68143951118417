import React, { useEffect } from "react"
import { compose } from "redux"
import camelCase from "lodash/camelCase"
import { withRouter } from "react-router-dom"
import get from "lodash/get"
import { RequireSubscriptions } from "legacy/components/subscriptions/require-sub"
import {
  ARC_APPLICATION_NOT_AVAILABLE,
  FEATURE_PRO,
} from "legacy/constants/feature-pro-consts"
import { ReportNotAvailable } from "components/reports/screening-reports/report-not-available"
import { CenterContent } from "components/layout/main"
import { Wrapper } from "components/reports"
import { ReportDiv } from "components/layout/main-style"
import { getUserPlan } from "utils/get-user-plan"
import { isLastUpdatedExpired } from "legacy/helpers/pro-authorize"
import { useQuery } from "@tanstack/react-query"
import { withConnect, withSaga } from "../with-report-available/connect"
import { shouldRenderComponent } from "../with-report-available"
import { SHARED_REPORT_TYPES } from "../constants"
import { updateAbility } from "./ability"
import { checkIsPro, checkTrialStarted } from "./helper"

export const SUBSCRIPTIONS_CONFIG = {
  [SHARED_REPORT_TYPES.REFERENCE_CHECK]: {
    feature: FEATURE_PRO.REFCHECKS,
  },
  [SHARED_REPORT_TYPES.OTHER_DOCS]: {
    feature: FEATURE_PRO.DOCUMENT,
  },
}

/**
 * This Higher-order handles logic to show reference check and other docs
 * show wrappedComponent if the type is participant or type is owner with pro subscription
 * show report is not shared, when type is participant but the reportType is not in shareOption
 * show application required when type is owner and reportType is referenceCheck but not request app
 * show subscription required when type is owner and not pro
 * @reportType the report type option, consist with referenceCheck and otherDocs
 */
export const withProReportAvailable = sharedReportType => WrappedComponent => ({
  screeningOption,
  match,
  participantType,
  isSharedReport,
  shareOption,
  authorize,
  user,
  getSubscriptions,
  ...rest
}) => {

  useEffect(() => {
    if (isLastUpdatedExpired(authorize, user)) {
      getSubscriptions(false)
    }
  }, [])

  const reportPath = get(match, "params.type")

  const userSubscriptionPlan = useQuery({
    queryKey: ["subscription_plan_v2"],
    queryFn: getUserPlan,
  })

  const setAbilityRules = async () => {
    if (userSubscriptionPlan?.data) {
      const {userPlan, trialEnd} = userSubscriptionPlan.data
      updateAbility(checkIsPro(userPlan), checkTrialStarted(trialEnd), screeningOption?.premium)
    }
  }

  // Get the user's subscription plan
  React.useEffect(() => {
    setAbilityRules()
  }, [userSubscriptionPlan])

  // check for shared person
  if (isSharedReport) {
    const isSelectedReportShared = shareOption[camelCase(sharedReportType)]
    if (isSelectedReportShared) {
      return <WrappedComponent {...rest} />
    }
    return shouldRenderComponent(
      reportPath,
      // not available component is used both mobile and desktop
      <Wrapper className="report-div" hideMobile={false}>
        <ReportDiv>
          <CenterContent
            withBreadcrumb
            withReportTabs
            withReportPadding
            noMargin>
            <ReportNotAvailable
              reportType={sharedReportType}
              participantType={participantType}
            />
          </CenterContent>
        </ReportDiv>
      </Wrapper>,
    )
  }
  // for owner
  const REF_CHECK = SHARED_REPORT_TYPES.REFERENCE_CHECK
  const { OTHER_DOCS } = SHARED_REPORT_TYPES

  if (!get(screeningOption, "application") && sharedReportType === REF_CHECK) {
    return shouldRenderComponent(
      reportPath,
      <RequireSubscriptions content={ARC_APPLICATION_NOT_AVAILABLE} />,
    )
  }

  if ([REF_CHECK, OTHER_DOCS].includes(sharedReportType)) {
    return <WrappedComponent {...rest} screeningOption={screeningOption} />
  }

  return null
}

const composedWithProReportAvailable = option =>
  compose(
    withSaga,
    withConnect,
    withRouter,
    withProReportAvailable(option),
  )

export default composedWithProReportAvailable
