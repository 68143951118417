import React from 'react'
import isNumber from 'lodash/isNumber'
import * as Styled from './select-payer-style'
import { Span, P, I } from '../typography/text-style'

const uncheckSquare = require('../../images/icons/alacarte/checkbox-unchecked.png')
const checkSquare = require('../../images/icons/alacarte/checkbox-checked.png')

export const PayerBoxWrapper = ({ children, ...props }) => (
  <Styled.payerWrapper {...props}>{children}</Styled.payerWrapper>
)
export const PayerBox = ({ icon, label, isSelected, ...props }) => (
  <Styled.payerBox isSelected={isSelected} {...props}>
    {isSelected ? (
      <Styled.icon blue className="fas fa-dot-circle" />
    ) : (
      <Styled.icon className="far fa-circle" />
    )}
    <Span strong>{label}</Span>
  </Styled.payerBox>
)

export const ReportItem = ({ icon, text }) => (
  <Styled.reportItem>
    <Styled.imgIcon src={icon} alt={text} />
    <Span strong>{text}</Span>
  </Styled.reportItem>
)

export const CollectItem = ({ text, subtext, isSelected, price = '', dataTestId, ...rest }) => {
  let priceText = price
  if (isNumber(price)) {
    priceText = price === 0 ? 'Free' : `$${price}`
  }
  return (
    <Styled.collectItem data-testid={dataTestId} isSelected={isSelected} {...rest}>
      {isSelected ? (
        <Styled.imgIcon src={checkSquare} alt={text} />
      ) : (
        <Styled.imgIcon src={uncheckSquare} alt={text} />
      )}
      <Span className="item-child" strong>
        <P className="text">{text}</P>
        <I className="subtext">{subtext}</I>
      </Span>
      <Span
        className="item-child-right"
        color={isSelected ? 'deepBlue' : 'placeholderGrey'}
        strong
        size="16px"
      >
        {priceText}
      </Span>
    </Styled.collectItem>
  )
}
