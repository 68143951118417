import produce from "immer"
import {
  UPDATE_DRE_LICENSE_REQUEST,
  UPDATE_DRE_LICENSE_SUCCESS,
  UPDATE_DRE_LICENSE_FAILED,
} from "./constants"

export const initialState = {
  isUpdatingDreLicense: false,
}

/* eslint-disable no-param-reassign */
const stepUpdateDreLicense = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case UPDATE_DRE_LICENSE_REQUEST:
        draft.isUpdatingDreLicense = true
        break
      case UPDATE_DRE_LICENSE_SUCCESS:
        draft.isUpdatingDreLicense = false
        break
      case UPDATE_DRE_LICENSE_FAILED:
        draft.isUpdatingDreLicense = false
        break
      default:
        break
    }
    return draft
  })

export default stepUpdateDreLicense
