import { takeLatest, all, put, take } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'

import {
  closeCreateAssociationContactModal,
  createAssociationContactFromModalState,
} from './actions'
import {
  CREATE_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
  CREATE_ASSOCIATION_CONTACT_SUCCESS_TOAST_CONFIG,
  CREATE_ASSOCIATION_CONTACT_FAILURE_TOAST_CONFIG,
  EDIT_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
  EDIT_ASSOCIATION_CONTACT_SUCCESS_TOAST_CONFIG,
  EDIT_ASSOCIATION_CONTACT_FAILURE_TOAST_CONFIG,
} from './constants'
import { createAssociationContact, editAssociationContact } from '../actions'
import {
  CREATE_ASSOCIATION_CONTACT_FAILURE,
  CREATE_ASSOCIATION_CONTACT_SUCCESS,
  EDIT_ASSOCIATION_CONTACT_FAILURE,
  EDIT_ASSOCIATION_CONTACT_SUCCESS,
  ASSOCIATION_CONTACT_ROLE,
  ASSOCIATION_CONTACT_SOURCE,
} from '../constants'

export function* createAssociationContactFromModalSaga({ payload }) {
  yield put(createAssociationContactFromModalState.request())
  yield put(
    createAssociationContact({
      ...payload.contact,
      role: ASSOCIATION_CONTACT_ROLE.LANDLORD,
      source: ASSOCIATION_CONTACT_SOURCE.PROPERTY_DETAIL,
    }),
  )
  const createAction = yield take([
    CREATE_ASSOCIATION_CONTACT_SUCCESS,
    CREATE_ASSOCIATION_CONTACT_FAILURE,
  ])
  if (createAction.type === CREATE_ASSOCIATION_CONTACT_FAILURE) {
    yield put(addToast(CREATE_ASSOCIATION_CONTACT_FAILURE_TOAST_CONFIG))
    yield put(createAssociationContactFromModalState.failure())
  } else {
    yield put(addToast(CREATE_ASSOCIATION_CONTACT_SUCCESS_TOAST_CONFIG))
    yield put(closeCreateAssociationContactModal())
    yield put(createAssociationContactFromModalState.success())
  }
}

export function* editAssociationContactFromModalSaga({ payload }) {
  yield put(createAssociationContactFromModalState.request())
  yield put(
    editAssociationContact({
      ...payload.contact,
      role: ASSOCIATION_CONTACT_ROLE.LANDLORD,
      source: ASSOCIATION_CONTACT_SOURCE.PROPERTY_DETAIL,
    }),
  )
  const editAction = yield take([
    EDIT_ASSOCIATION_CONTACT_FAILURE,
    EDIT_ASSOCIATION_CONTACT_SUCCESS,
  ])
  if (editAction.type === EDIT_ASSOCIATION_CONTACT_FAILURE) {
    yield put(addToast(EDIT_ASSOCIATION_CONTACT_FAILURE_TOAST_CONFIG))
    yield put(createAssociationContactFromModalState.failure())
  } else {
    yield put(addToast(EDIT_ASSOCIATION_CONTACT_SUCCESS_TOAST_CONFIG))
    yield put(closeCreateAssociationContactModal())
    yield put(createAssociationContactFromModalState.success())
  }
}

export function* watchCreateAssociationContact() {
  yield takeLatest(
    CREATE_ASSOCIATION_CONTACT_FROM_MODAL_CALL,
    createAssociationContactFromModalSaga,
  )
}

export function* watchEditAssociationContact() {
  yield takeLatest(EDIT_ASSOCIATION_CONTACT_FROM_MODAL_CALL, editAssociationContactFromModalSaga)
}

export function* rootSaga() {
  yield all([watchCreateAssociationContact(), watchEditAssociationContact()])
}

export default rootSaga
