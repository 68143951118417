import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'

const StyledLink = styled(Link)`
  font-family: Source Sans Pro;
  color: ${props => props.color};
  ${props => props.bold === 'true' && 'font-weight: bold;'};
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`};
  ${props => props.size && `font-size:${props.size}`};
  ${props => props.underline && `text-decoration: underline !important`};
  :focus {
    color: ${props => props.focuscolor};
  }

  :hover {
    color: ${props => props.hovercolor};
  }
`

const TextLink = ({
  id,
  to,
  color,
  hovercolor,
  focuscolor,
  bold,
  children,
  fontWeight,
  size,
  underline,
  onClick,
}) => (
  <StyledLink
    id={id}
    to={to}
    color={color}
    hovercolor={hovercolor}
    focuscolor={focuscolor}
    bold={bold}
    fontWeight={fontWeight}
    size={size}
    underline={underline}
    onClick={onClick}
  >
    {children}
  </StyledLink>
)

TextLink.propTypes = {
  id: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  color: PropTypes.string,
  hovercolor: PropTypes.string,
  focuscolor: PropTypes.string,
  bold: PropTypes.string,
}

TextLink.defaultProps = {
  id: '',
  color: COLOR.textBlack,
  hovercolor: COLOR.placeholderGrey,
  focuscolor: COLOR.textBlack,
  bold: 'false',
}

export default TextLink
