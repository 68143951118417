import get from "lodash/get"
import { createSelector } from "reselect"

export const SCREENING_ITEMS_TEXT = {
  creditReport: "Credit Report & Score",
  criminal: "Criminal Background Check",
  eviction: "Eviction Related Proceedings",
  income: "Income Verification",
  references: "Reference Checks",
  documents: "Documents",
}

const getProperty = state => get(state, "property", {})
const getListingManagement = state => get(state, "listingManagement", {})

export const selectProperty = createSelector(
  getProperty,
  state => {
    const property = get(state, "property", {})

    return {
      ...property,
      propertyId: get(property, "_id", undefined),
    }
  },
)

export const selectIsFetching = createSelector(
  [getProperty, getListingManagement],
  ({ isFetching }, { isFetchingGetApplyLink }) => isFetching || isFetchingGetApplyLink
)

export const selectListingScreeningRequest = createSelector(
  [getProperty, getListingManagement],
  ({ property }, { screeningRequest }) => {
    const { url } = get(property, "listing.screeningRequest") || {}
    const { applicationType, credit_report: creditReport, criminal, eviction, income }  = screeningRequest?.screeningOption || {}
    const { documents, references } = screeningRequest?.proOption || {}
    const screeningItems = {
      creditReport,
      criminal,
      eviction,
      income,
      documents: !!documents?.length,
      references: !!references?.length,
    }

    return {
      hasRequest: !!url,
      application: applicationType,
      screeningItems: Object.keys(screeningItems)
        .filter(key => screeningItems[key])
        .map(key => SCREENING_ITEMS_TEXT[key]),
    }
  },
)

export const selectExistScreeningRequest = createSelector(
  [getProperty, getListingManagement],
  ({ property }, { screeningRequest }) => {
    const { url } = get(property, "listing.screeningRequest") || {}
    if (!url) {
      return {}
    }
    const { payerType, applicationType, application, credit_report: creditReport, criminal, eviction, premium }  = screeningRequest?.screeningOption || {}
    const { documents, references } = screeningRequest?.proOption || {}
    return {
      payerType,
      applicationType,
      application,
      creditReport,
      criminal,
      eviction,
      premium,
      documents,
      references,
    }
  },
)

