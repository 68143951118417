import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import toNumber from 'lodash/toNumber'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import { query } from '@rentspree/path'
import { Level1Template } from '@rentspree/component-2023.components.templates.level-1-template'

import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import { withTracker } from 'legacy/containers/with-tracker'
import * as ERRORS from 'constants/error-messages'
import { NOT_FOUND } from 'constants/route'
import tracker from 'tracker'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import { NEW_UI_TABLE_PROPERTY, ZERO_STATE_PROPERTIES } from 'utils/feature-flag/constants'
import { withStatsigFeatureGate } from 'hoc/with-statsig'
import { useScreenSize } from 'hooks/use-screen-size'
import { AgentIncentivePaymentAdoptionBanner } from 'v3/components/banners/agent-incentive-payment-adoption-banner'
import { AgentIncentiveEligible } from 'v3/components/agent-incentive-eligible/agent-incentive-eligible'
import { useFeatureGate } from '@statsig/react-bindings'
import { L1_TABLE_PAGINATION_PER_PAGE } from 'env'
import { OnboardingPartnerAgentModal } from './onboarding-partner-agent-modal'
import { withReducer, withConnect } from './connect'
import { FILTER_PROPERTY } from './constants'
import { OldPropertyList } from './old-design'
import { CreatePropertyButton } from './create-property'
import { PropertyListTableContainer } from './list-table'

const DEFAULT_QUERY = {
  page: 1,
  perPage: 8,
  filter: { status: FILTER_PROPERTY.ACTIVE },
}

// [TECH-22068] TODO: can be rename back to DEFAULT_QUERY and remove the old one after clean-up feature-flag
const NEW_DEFAULT_QUERY = {
  page: 1,
  perPage: L1_TABLE_PAGINATION_PER_PAGE,
  filter: { status: FILTER_PROPERTY.ACTIVE },
}

export const PropertyList = props => {
  // [TECH-22068] TODO: clean up feature flag new_ui_table_property
  const { value: isNewUiPropertyTable } = useFeatureGate(NEW_UI_TABLE_PROPERTY)

  // [TECH-22068] TODO: clean up feature flag and use DEFAULT_QUERY
  const [queryOptions, setQueryOptions] = useState(
    isNewUiPropertyTable ? { ...NEW_DEFAULT_QUERY } : { ...DEFAULT_QUERY },
  )
  const { isDesktop } = useScreenSize()

  useEffect(() => {
    const { history, location } = props

    const unlisten = history.listen(({ state, search }, action) => {
      if (action === 'POP' || (state && state.clearPropertyListSearch)) {
        if (isNewUiPropertyTable) {
          setQueryOptions({
            ...NEW_DEFAULT_QUERY,
            filter: { ...NEW_DEFAULT_QUERY.filter },
          })
        } else {
          setQueryOptions({
            ...DEFAULT_QUERY,
            filter: { ...DEFAULT_QUERY.filter },
          })
        }
        const { page, filter, search: querySearch } = query.parse(search)
        getPropertyList(page, filter, false, querySearch)
      }
    })

    const { page, filter, search } = query.parse(location.search)
    getPropertyList(page, filter, false, search)

    return () => {
      props.actions.clearPropertyList()
      unlisten()
    }
  }, [])

  const getPropertyList = (page, filter = {}, pushHistory = true, search) => {
    const { actions, history } = props

    // Verify valid page number
    if (page && isNaN(toNumber(page))) {
      history.push({ pathname: NOT_FOUND })
    }

    const options = {
      ...queryOptions,
      page: page || queryOptions.page,
      filter: filter.status ? { ...filter } : { ...queryOptions.filter },
      search: isNil(search) ? queryOptions.search : search,
    }
    setQueryOptions(options)

    if (pushHistory) {
      addSearchParams(options)
    }

    return actions.getPropertyList(options).then(data => {
      // PREVENT RANDOM PAGE NUMBER AFTER FETCHED
      if (data && data.pagination) {
        verifyValidPagination(data.pagination)
      }
    })
  }

  const addSearchParams = ({ page, filter, search }) => {
    const {
      history,
      location: { pathname },
    } = props
    history.push({
      pathname,
      search: query.stringify({ page, filter, search }),
    })
  }

  const verifyValidPagination = pagination => {
    const { history } = props
    const { maxPage, page, total } = pagination
    if ((page !== 1 && total === 0) || (page !== 1 && page > maxPage)) {
      history.push({ pathname: NOT_FOUND })
    }
  }

  const handleAddNewListing = () => {
    const {
      name,
      properties: { listingAction },
    } = EVENT_REQUEST_SCREENING.clickStartScreening
    tracker.trackEvent(name, listingAction)
  }

  const handleClickLevel1TemplateCTA = () => {
    tracker.trackButtonClick({
      click_text: 'Add property',
      location: 'title_cta',
    })
  }

  const { error, history, actions, propertyList, isFeatureEnabled } = props
  const { filter, search } = queryOptions
  // TODO: [TECH-14813] Zero State Clean-up remove feature flag
  const isZeroStatePropertyPageEnabled = isFeatureEnabled?.[ZERO_STATE_PROPERTIES]
  const hasNoQueryOption = filter?.status !== FILTER_PROPERTY.ARCHIVED && isEmpty(search)
  const hasProperty = !hasNoQueryOption || propertyList.length > 0

  if (!isEmpty(error)) {
    return (
      <FailedToFetch
        noMargin
        noBreadcrumbMobile
        text={ERRORS.PROPERTIES.LISTING.MESSAGE}
        title={ERRORS.PROPERTIES.LISTING.TITLE}
      />
    )
  }

  return (
    <>
      <OnboardingPartnerAgentModal />
      <Level1Template
        title="Properties"
        isMobile={!isDesktop}
        cta={
          !isZeroStatePropertyPageEnabled ||
          (hasProperty && (
            <CreatePropertyButton
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleClickLevel1TemplateCTA}
              location="title cta"
              history={history}
            >
              Add property
            </CreatePropertyButton>
          ))
        }
      />
      {hasProperty && (
        <AgentIncentiveEligible>
          <AgentIncentivePaymentAdoptionBanner margin="0 0 24px" />
        </AgentIncentiveEligible>
      )}
      {isNewUiPropertyTable && (
        <PropertyListTableContainer
          getPropertyList={getPropertyList}
          filter={filter}
          search={search}
          hasProperty={hasProperty}
          history={history}
          actions={actions}
          handleAddNewListing={handleAddNewListing}
        />
      )}
      {!isNewUiPropertyTable && (
        <OldPropertyList
          getPropertyList={getPropertyList}
          filter={filter}
          search={search}
          hasProperty={hasProperty}
          history={history}
          actions={actions}
          handleAddNewListing={handleAddNewListing}
        />
      )}
    </>
  )
}

export default compose(
  // TODO: [TECH-14813] IA Zero State Clean-up remove feature flag
  withStatsigFeatureGate(ZERO_STATE_PROPERTIES),
  withTracker,
  withRouter,
  withConnect,
  withReducer,
)(PropertyList)
