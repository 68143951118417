import generateApiCall from 'utils/generate-api-call'

import {
  OPEN_CREATE_CONTACT_MODAL,
  CLOSE_CREATE_CONTACT_MODAL,
  CREATE_CONTACT_FROM_MODAL_CALL,
  CREATE_CONTACT_FROM_MODAL_REQUEST,
  CREATE_CONTACT_FROM_MODAL_SUCCESS,
  CREATE_CONTACT_FROM_MODAL_FAILURE,
  CREATE_TAG_CONTACT_MODAL_REQUEST,
  CREATE_TAG_CONTACT_MODAL_SUCCESS,
  CREATE_TAG_CONTACT_MODAL_FAILURE,
  CREATE_TAG_CONTACT_MODAL,
} from './constants'

export const openCreateContactModal = () => ({
  type: OPEN_CREATE_CONTACT_MODAL,
})

export const closeCreateContactModal = () => ({
  type: CLOSE_CREATE_CONTACT_MODAL,
})

export const createContactFromModal = contact => ({
  type: CREATE_CONTACT_FROM_MODAL_CALL,
  payload: {
    contact,
  },
})

export const createContactFromModalApiState = generateApiCall([
  CREATE_CONTACT_FROM_MODAL_REQUEST,
  CREATE_CONTACT_FROM_MODAL_SUCCESS,
  CREATE_CONTACT_FROM_MODAL_FAILURE,
])

export const createTagContactModal = name => ({
  type: CREATE_TAG_CONTACT_MODAL,
  payload: {
    name,
  },
})

export const createTagContactApiState = generateApiCall([
  CREATE_TAG_CONTACT_MODAL_REQUEST,
  CREATE_TAG_CONTACT_MODAL_SUCCESS,
  CREATE_TAG_CONTACT_MODAL_FAILURE,
])
