import Scroll from "react-scroll"
import isEmpty from "lodash/isEmpty"
import intersection from "lodash/intersection"
import head from "lodash/head"
import isArray from "lodash/isArray"
import findIndex from "lodash/findIndex"

const scroll = Scroll.scroller

/**
 * Scroll to the first error
 * @param {ErrorFields} errors error fields to scroll
 * @param {string[]=} fieldOrder list of error field name to select
 * @param {number=} offset scroll offset
 * @param {string=} containerId container ID that the scroll will occur
 */
export function scrollToFirstError(
  errors,
  fieldOrder,
  offset = -40,
  containerId,
  focus = false,
) {
  // exit if errors is empty
  if (isEmpty(errors)) return

  let firstError = Object.keys(errors)[0]
  if (!isEmpty(fieldOrder)) {
    const orderedError = intersection(fieldOrder, Object.keys(errors))
    firstError = head(orderedError)
  }

  // if ArrayFields, firstError is an array
  if (isArray(errors[firstError])) {
    const arr = errors[firstError]
    // get index of first error
    const firstErrIndex = findIndex(arr, i => !isEmpty(i))
    firstError =
      firstErrIndex >= 0 && !isEmpty(arr[firstErrIndex])
        ? `${firstError}.${firstErrIndex}.${Object.keys(arr[firstErrIndex])[0]}`
        : 0
  }

  if (!isEmpty(firstError)) {
    const scrollProps = {
      duration: 500,
      smooth: true,
      offset,
    }
    if (containerId) scrollProps.containerId = containerId
    scroll.scrollTo(firstError, scrollProps)

    if (focus) {
      const firstErrorElement = document.getElementsByName(firstError)
      if (firstErrorElement && firstErrorElement[0]) {
        firstErrorElement[0].focus()
      }
    }
  }
}
