import React from "react"
import styled from "styled-components"
import { COLOR } from "styles/settings"

import Safari from "images/browser/safari.svg"
import Chrome from "images/browser/chrome.svg"
import Edge from "images/browser/edge.svg"
import Firefox from "images/browser/firefox.svg"

export const BrowserListContainer = styled.div`
  display: flex;
`

export const BrowserItems = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR.textBlack};
  :hover {
    color: ${COLOR.textBlack};
  }
`
export const BrowserImage = styled.img`
  margin: 0px 15px 10px 15px;
`

export const Browser = () => (
  <BrowserListContainer>
    <BrowserItems href="https://www.google.com/chrome" target="_blank">
      <BrowserImage src={Chrome} />
      Chrome
    </BrowserItems>
    <BrowserItems
      href="https://support.apple.com/downloads/safari"
      target="_blank">
      <BrowserImage src={Safari} />
      Safari
    </BrowserItems>
    <BrowserItems
      href="https://www.mozilla.org/en-US/firefox/new/"
      target="_blank">
      <BrowserImage src={Firefox} />
      Firefox
    </BrowserItems>
    <BrowserItems href="https://www.microsoft.com/en-us/edge" target="_blank">
      <BrowserImage src={Edge} />
      Edge
    </BrowserItems>
  </BrowserListContainer>
)

export default Browser
