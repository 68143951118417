import React from "react"
import { withFormik } from "formik"

import ButtonSet from "components/molecules/button-set"
import { ConditionForm } from "components/organisms/accept-deny-condition-form/index"
import { schema } from "components/organisms/accept-deny-condition-form/schema"

import { validateObject } from "./helper/validate-field"

export const AcceptDenyConditionForm = ({ handleSubmit, ...props }) => (
  <>
    <ConditionForm {...props} id={props.type} />
    <ButtonSet
      margin="20px 0 0"
      minHeight="40px"
      buttons={[
        {
          type: "blueGradientButton",
          option: {
            text: "Next",
            onClick: handleSubmit,
            disabled: !validateObject(props.values),
            width: "130px",
          },
        },
      ]}
    />
  </>
)

const AcceptDenyFormTemplate = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  },
  enableReinitialize: true,
  displayName: "AcceptDenyFormTemplate",
})(AcceptDenyConditionForm)

export default AcceptDenyFormTemplate
