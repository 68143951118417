import React from "react"
import styled from "styled-components"
import renderHTML from "react-render-html"
import B14 from "@rentspree/component-v2/dist/typography/b14"
import InfoBox from "@rentspree/component-v2/dist/info-box"

export const FlexContainer = styled.div`
  display: flex;
  ${props => props.flexWrap && "flex-wrap: wrap"};
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.justify && `justify-content: ${props.justify}`};
  ${props => props.alignContent && `align-content: ${props.alignContent}`};
  ${props => props.margin && `margin: ${props.margin}`};
`

export const B14WithoutMargin = styled(B14)`
  margin: 0px;
  word-break: break-word;
  font-family: Source Sans Pro;
  p {
    margin: 18px 0px;
    line-height: 18px;
  }
  .content-email {
    p {
      margin-bottom: 18px;
    }
  }
  .email-condition-input {
    color: #3278b3;
    margin-bottom: 20px;
  }
  .email-footer {
    color: #9b9b9b;
    font-size: 12px;

    .rentspree-ty {
      margin-top: 16px;
    }
  }
  .email-detail {
    font-size: 12px;
    line-height: 15px;
    p {
      margin-bottom: 10px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1 0 265px;
  height: 100%;
  overflow-y: auto;
  div > div > div:last-child {
    overflow-x: hidden;
  }
  @media (max-width: 991px) {
    display: block;
    height: calc(100vh - 270px);
    flex: 1 0 150px;
  }
`

const EmailBody = ({ emailBody, titleMobile }) => (
  <Container>
    <InfoBox
      height="100%"
      mPadding="15px 20px"
      overlayText="Click to continue reading"
      titleMobile={titleMobile}>
      <B14WithoutMargin
        lineHeight="18px"
        margin="0"
        className="welcome-email-body-template">
        {renderHTML(emailBody)}
      </B14WithoutMargin>
    </InfoBox>
  </Container>
)

export default EmailBody
