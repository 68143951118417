import React from "react"
import isEmpty from "lodash/isEmpty"
import { useDispatch, useSelector } from "react-redux"

import { getApplicationCall } from "containers/application/lra/sign-page/actions"
import {
  selectApplication,
  selectIsFetchingApplication,
} from "containers/application/lra/sign-page/selectors"

const useFetchApplication = ({ rentalId, rentalDetail }) => {
  const dispatch = useDispatch()
  const application = useSelector(selectApplication())
  const isFetchingApplication = useSelector(selectIsFetchingApplication())

  const { _id: rentalDetailId, application: latestAppId } = rentalDetail || {}
  const { _id: currentAppId } = application || {}

  React.useEffect(() => {
    if (isEmpty(application) || latestAppId !== currentAppId) {
      dispatch(getApplicationCall({ rentalId: rentalId || rentalDetailId }))
    }
  }, [])

  return { application, isFetchingApplication }
}

export default useFetchApplication
