import { object, string } from 'yup'

import {
  recipientDetailsOptions,
  recipientFields,
} from 'v3/containers/overhaul-rent-payment/constants'
import { isEmptyOrNotObject, isObject } from 'v3/containers/overhaul-rent-payment/pages/utils'

export const validateRecipient = recipient => {
  // short circuit variable instantiation below if we can help it
  if (
    recipient === null ||
    recipient === undefined ||
    (typeof recipient === 'string' && recipient.length === 0)
  ) {
    return false
  }
  const recipientIsValid = Object.values(recipientDetailsOptions).includes(recipient)

  return recipientIsValid
}

export const validateRecipientInfo = (recipientType, recipientInfo) => {
  if (!recipientInfo) {
    return false
  }

  const validationSchema =
    recipientType === 'client'
      ? object().shape({
          lastName: string().required(),
          firstName: string().required(),
          email: string().email().required(),
        })
      : object().shape({
          lastName: string().required(),
          firstName: string().required(),
        })

  return validationSchema.isValidSync(recipientInfo)
}

export const validateObject = recipientDetailsObj => {
  // short circuit variable instantiation for  below if we can help it
  if (isEmptyOrNotObject(recipientDetailsObj)) {
    return false
  }
  if (!isObject(recipientDetailsObj)) {
    // if this isn't an object, and it isn't null/undefined, it's an invalid value
    return false
  }
  // validate only expected keys are present, and all keys are present
  const keys = isObject(recipientDetailsObj) ? Object.keys(recipientDetailsObj) : []
  const expectedKeys = [recipientFields.recipient, recipientFields.recipientInfo]
  const keysValid =
    keys.every(val => expectedKeys.includes(val)) && expectedKeys.every(val => keys.includes(val))

  // validate recipientData is valid, and all keys are present
  const recipientData = recipientDetailsObj[recipientFields.recipient]
  const recipientValid = validateRecipient(recipientData)

  const recipientInfoData = recipientDetailsObj[recipientFields.recipientInfo]
  const recipientInfoValid = validateRecipientInfo(recipientData, recipientInfoData)

  return keysValid && recipientValid && recipientInfoValid
}
