import React, { useEffect, useState } from "react"
import { compose } from "redux"
import { CenterContent } from "components/layout/main"
import styled from "styled-components"
import useCountDown from "react-countdown-hook"
import tracker from "tracker"
import { EMAIL_VERIFICATION } from "tracker/const"
import {
  BlueGradientButton,
  SecondaryGradientButton,
} from "@rentspree/component-v2/dist/button/gradient"
import emailSentSvg from "images/email-sent.svg"
import { COLOR } from "styles/settings"
import { withConnect, withSaga } from "./connect"

export const StyledContent = styled(CenterContent)`
  margin: 0;
  padding: 0 15px;
  @media only screen and (max-width: 991px) {
    ${props =>
      !props.noHeightCal &&
      `height: ${props.heightCal || "calc(100vh - 105px - 60px - 10px)"};`}
  }
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  #email-verification-img {
    margin-bottom: 12px;
    width: 300px;
    height: 300px;
    @media only screen and (max-width: 991px) {
      margin-bottom: 0;
      width: 250px;
      height: 250px;
    }
  }
`

const Title = styled.div`
  max-width: 350px;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: center;
  color: ${COLOR.newBlack};
`

const Description = styled.div`
  max-width: 350px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: center;
  color: ${COLOR.newBlack};
  margin-top: 10px;
  margin-bottom: 30px;
  strong {
    font-weight: 600;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const FixedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 400px;
  max-width: 400px;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`

export const Input = styled.input`
  height: ${props => (props.height ? `${props.height}px` : "40px")};
  border: 1px solid ${COLOR.borderGrey};
  background: ${COLOR.shallowBluePale};
  ${props => props.disabled && `background: ${COLOR.shallowBluePale}`};
  width: 100%;
  padding: 0 15px 0 5px;
  position: relative;
  float: left;
  display: table-cell;
  margin: 0;
  border-left: 0;
  font-weight: 600;
`

export const InputGroup = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  margin-bottom: 30px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
`

export const InputAddon = styled.span`
  padding: 0 0 0 15px;
  line-height: 1;
  color: ${COLOR.placeholderGrey};
  text-align: center;
  background-color: ${COLOR.shallowBluePale};
  border: 1px solid ${COLOR.borderGrey};
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-right: 0;
  user-select: none;
`

const BtnSubmit = ({ isDisabled, isResend, timeLeft, ...props }) => {
  if (isDisabled) {
    return (
      <BlueGradientButton disabled {...props}>
        Waiting {(timeLeft / 1000).toFixed(0)} Sec.
      </BlueGradientButton>
    )
  }
  if (!isResend) {
    return <BlueGradientButton {...props}>Send Verification</BlueGradientButton>
  }
  return <SecondaryGradientButton {...props}>Resend</SecondaryGradientButton>
}

export const EmailVerification = ({ actions, profile }) => {
  const [isResend, setIsResend] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [timeLeft, timer] = useCountDown(30 * 1000, 1000)
  const [isEnter, setEnter] = useState(false)

  if (!isEnter) {
    tracker.trackEvent(EMAIL_VERIFICATION.enterPage)
    setEnter(true)
  }

  useEffect(() => {
    if (timeLeft > 0 && !isDisabled) {
      setIsDisabled(true)
    } else if (timeLeft <= 0 && isDisabled) {
      setIsDisabled(false)
    }
  })

  const handleSendEmailVerification = () => {
    actions.sendEmailVerificationCall({ email: profile.email })
    tracker.trackEvent(
      EMAIL_VERIFICATION.EVENT_NAME.CLICK_SEND_EMAIL_VERIFICATION,
    )
    setIsResend(true)
    timer.start()
  }

  return (
    <StyledContent
      style={{ background: "white" }}
      id="landing"
      withBreadcrumb
      heightCal="calc(100vh - 70px - 45px)">
      <StyledDiv id="email-verification-div" imgWidth={300} imgHeight={300}>
        <img
          id="email-verification-img"
          src={emailSentSvg}
          alt="Verification Email Sent!"
        />
        <Title id="landing-title">Verify your email to open report</Title>
        <Description id="landing-description">
          Click “Verify email” button in your email, to confirm Verification
          link will expire in <strong>30 minutes</strong>.
        </Description>
        <FixedWrapper>
          <InputGroup>
            <InputAddon>sent to :</InputAddon>
            <Input
              id="email"
              name="email"
              type="email"
              value={profile.email}
              readOnly
              disabled
            />
          </InputGroup>
          <BtnSubmit
            id="send-email-verification"
            small
            semiBold
            size="16px"
            minWidth="250"
            type="submit"
            isDisabled={isDisabled}
            isResend={isResend}
            timeLeft={timeLeft}
            onClick={handleSendEmailVerification}
          />
        </FixedWrapper>
      </StyledDiv>
    </StyledContent>
  )
}

export default compose(
  withConnect,
  withSaga,
)(EmailVerification)
