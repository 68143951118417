import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { createStructuredSelector } from 'reselect'

import { getProfile } from "legacy/actions/user.action"
import { makeSelectSubdomain } from 'containers/navbar/selectors'
import { selectUser } from '../selectors'

export const mapStateToProps = createStructuredSelector({
  profile: selectUser,
  subdomain: makeSelectSubdomain(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getProfile,
      },
      dispatch,
    )
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
