import produce from "immer"
import get from "lodash/get"

import {
  GET_LRA_APPLICATION_REQUEST,
  GET_LRA_APPLICATION_SUCCESS,
  GET_LRA_APPLICATION_FAILURE,
  GET_LRA_ENVELOPE_REQUEST,
  GET_LRA_ENVELOPE_SUCCESS,
  GET_LRA_ENVELOPE_FAILURE,
  UPDATE_LRA_ENVELOPE_SIGN_REQUEST,
  UPDATE_LRA_ENVELOPE_SIGN_SUCCESS,
  UPDATE_LRA_ENVELOPE_SIGN_FAILURE,
} from "./constants"

export const initialState = {
  envelopeId: null,
  application: {},
  error: null,
  isFetchingApplication: true,
  isFetchingEnvelope: true,
  isFetchingUpdateEnvelope: false,
}

/* eslint-disable default-case, no-param-reassign */
const lraSignReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_LRA_APPLICATION_REQUEST:
        draft.isFetchingApplication = true
        break
      case GET_LRA_APPLICATION_SUCCESS:
        draft.application = payload
        draft.envelopeId = get(payload, "lraEnvelopeId")
        draft.isFetchingApplication = false
        break
      case GET_LRA_APPLICATION_FAILURE:
        draft.isFetchingApplication = false
        break
      case GET_LRA_ENVELOPE_REQUEST:
        draft.isFetchingEnvelope = true
        break
      case GET_LRA_ENVELOPE_SUCCESS:
        draft.isFetchingEnvelope = false
        break
      case GET_LRA_ENVELOPE_FAILURE:
        draft.isFetchingEnvelope = false
        break
      case UPDATE_LRA_ENVELOPE_SIGN_REQUEST:
        draft.isFetchingUpdateEnvelope = true
        break
      case UPDATE_LRA_ENVELOPE_SIGN_SUCCESS:
        draft.isFetchingUpdateEnvelope = false
        draft.application = payload
        break
      case UPDATE_LRA_ENVELOPE_SIGN_FAILURE:
        draft.isFetchingUpdateEnvelope = false
        draft.error = payload
        break
    }
    return draft
  })

export default lraSignReducer
