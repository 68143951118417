import generateApiCall from 'utils/generate-api-call'

import {
  GET_PAYOUT_METHOD_LIST,
  GET_PAYOUT_METHOD_LIST_REQUESTED,
  GET_PAYOUT_METHOD_LIST_SUCCEEDED,
  GET_PAYOUT_METHOD_LIST_FAILED,
} from './constants'

export const getPayoutMethodList = payload => ({
  type: GET_PAYOUT_METHOD_LIST,
  payload,
})

export const getPayoutMethodListAPI = generateApiCall([
  GET_PAYOUT_METHOD_LIST_REQUESTED,
  GET_PAYOUT_METHOD_LIST_SUCCEEDED,
  GET_PAYOUT_METHOD_LIST_FAILED,
])
