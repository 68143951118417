export const REFERENCE_CHECK = {
  REQUEST_SEND: {
    TITLE: "Reference request successfully sent",
    MESSAGE:
      "You'll receive an email notification when the reference submits the form.",
  },
}

export const ACCEPT_APPLICATION = {
  SUCCESS_SEND: {
    TITLE: "Email sent successfully.",
    MESSAGE: "The email has been sent to the applicant.",
  },
}

export default REFERENCE_CHECK
