import React from 'react'
import PropTypes from 'prop-types'
import TransparentButton from '@rentspree/component-v2/dist/button/transparent'
import Span from '@rentspree/component-v2/dist/typography/span'
import { Icon } from '@rentspree/component-v2/dist/icon'

export const ICON_POSITION = {
  PREFIX: 'prefix',
  SUFFIX: 'suffix',
}

const MARGIN_BY_ICON_POSITION = {
  [ICON_POSITION.PREFIX]: '0 0 0 6px',
  [ICON_POSITION.SUFFIX]: '0 6px 0 0',
}

export const TransparentTextIconButton = ({
  text,
  margin,
  color,
  hoverColor,
  icon,
  iconSize,
  onClick,
  size,
  lineHeight = '20px',
  disabled,
  showIcon = true,
  iconPosition = ICON_POSITION.PREFIX,
}) => (
  <TransparentButton
    onClick={onClick}
    type="button"
    margin={margin}
    padding="0"
    color={color}
    hoverColor={hoverColor}
    disabled={disabled}
    hoverCursor={disabled && "default"}
  >
    {showIcon && iconPosition === ICON_POSITION.PREFIX && <Icon icon={icon} size={iconSize} />}
    <Span
      margin={showIcon ? MARGIN_BY_ICON_POSITION[iconPosition] : '0px'}
      size={size}
      semiBold
      lineHeight={lineHeight}
      color="inherit"
    >
      {text}
    </Span>
    {showIcon && iconPosition === ICON_POSITION.SUFFIX && <Icon icon={icon} size={iconSize} />}
  </TransparentButton>
)

TransparentTextIconButton.propTypes = {
  /** Button wording */
  text: PropTypes.string,
  /** icon in front of text */
  icon: PropTypes.string,
  /** onClick button */
  onClick: PropTypes.func,
  /** color when hover */
  hoverColor: PropTypes.string,
  /** margin of button */
  margin: PropTypes.string,
  /** color of text and icon */
  color: PropTypes.string,
  /** size of icon */
  iconSize: PropTypes.string,
  /** size of text */
  size: PropTypes.string,
  /** lineHeight of text */
  lineHeight: PropTypes.string,
  /** showIcon with margin in span */
  showIcon: PropTypes.bool,
}

export default TransparentTextIconButton
