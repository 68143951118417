import user from './user.reducer'
import persistState from './persist-state.reducer'
import subdomain from './subdomain.reducer'
import creditReport from './credit-report.reducer'
import pdfReport from './pdf-report.reducer'
import property from './property.reducer'
import applicationList from './application-list.reducer'
import application from './application.reducer'
import rentalSubmission from './rental-submission.reducer'
import modals from './modals.reducer'
import subscriptions from './subscriptions.reducer'
import document from './document.reducer'
import request from './request.reducer'
import refCheck from './ref-check.reducer'
import redirect from './redirect.reducer'
import ssoHandler from './sso-handler-reducer'
import announcement from './announcement.reducer'

export default {
  user,
  persistState,
  property,
  application,
  applicationList,
  creditReport,
  pdfReport,
  subdomain,
  subscriptions,
  rentalSubmission,
  modals,
  document,
  request,
  refCheck,
  redirect,
  ssoHandler,
  announcement,
}
