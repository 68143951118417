import React from "react"
import { compose } from "redux"
import get from "lodash/get"

import {
  Div,
  CenterContent,
  WhiteWrapper,
  Loading,
} from "components/layout/main"
import tracker from "tracker"
import { RENTER_INSURANCE } from "tracker/const"
import { Landing as ErrorLanding } from "components/landing"
import errorImg from "images/report/error.png"
import { withConnect, withReducer, withSaga } from "./connect"
import { ERROR_TITLE, ERROR_DESCRIPTION, CHANNEL } from "./constants"
import OnlineChannel from "./online-channel"
import UploadChannel from "./upload-channel"

export const generateDetailByChannel = props => {
  const { rentersInsuranceDetail } = props
  const channel = get(rentersInsuranceDetail, "channel", "")
  switch (channel) {
    case CHANNEL.ONLINE: {
      return <OnlineChannel {...props} />
    }
    case CHANNEL.UPLOAD: {
      return <UploadChannel {...props} />
    }
    default:
      return <div />
  }
}

export const RentersInsuranceDetails = ({
  isFetching,
  actions,
  isError,
  ...props
}) => {
  React.useEffect(() => {
    tracker.trackEvent(RENTER_INSURANCE.EVENT_NAME.VIEW_RENTERS_INSURANCE)
  }, [])
  React.useEffect(() => {
    const { propertyId, rentersInsuranceId } = props.match.params
    actions.getRenterInsuranceDetailCall({ propertyId, rentersInsuranceId })
    return () => {
      actions.clearRenterInsuranceDetailCall()
    }
  }, [actions.getRenterInsuranceDetailCall])
  if (isError) {
    return (
      <Div sourceSansPros flex flexGrow="1" flexDirection="column">
        <WhiteWrapper mMargin="0" withBreadcrumb>
          <ErrorLanding
            withBreadcrumb
            title={ERROR_TITLE}
            description={ERROR_DESCRIPTION}
            buttonName="Try Again"
            buttonType="google"
            buttonWidth="120"
            imgSrc={errorImg}
            onClick={() => window.location.reload()}
          />
        </WhiteWrapper>
      </Div>
    )
  }

  return (
    <Div sourceSansPros flex flexGrow="1" flexDirection="column">
      <WhiteWrapper mMargin="0" withBreadcrumb>
        {isFetching && (
          <CenterContent overlay withBreadcrumb withFilter>
            <Loading />
          </CenterContent>
        )}
        {generateDetailByChannel(props)}
      </WhiteWrapper>
    </Div>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(RentersInsuranceDetails)
