import { apiInstance } from "utils/api-interceptor"
import { call, put, takeLatest, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import {
  GET_SUBSCRIPTION_PRODUCT_API_URL,
  GET_USER_SUBSCRIPTION_API_URL,
  GET_SUBSCRIPTION_PRODUCT_CALL,
  GET_USER_SUBSCRIPTION_CALL,
} from "./constants"

import {
  getSubscriptionProductRequest,
  getSubscriptionProductSuccess,
  getSubscriptionProductFailure,
  getUserSubscriptionRequest,
  getUserSubscriptionSuccess,
  getUserSubscriptionFailure,
} from "./actions"

export const getSubscriptionProductApi = () =>
  apiInstance.get(
    buildPath(
      GET_SUBSCRIPTION_PRODUCT_API_URL,
      {},
      { category: "subscription" },
    ),
  )

export function* getSubscriptionProduct() {
  yield put(getSubscriptionProductRequest())
  try {
    const response = yield call(getSubscriptionProductApi)
    yield put(getSubscriptionProductSuccess(response))
  } catch (error) {
    yield put(getSubscriptionProductFailure(error))
  }
}

export const getUserSubscriptionApi = () =>
  apiInstance.get(buildPath(GET_USER_SUBSCRIPTION_API_URL))

export function* getUserSubscription() {
  yield put(getUserSubscriptionRequest())
  try {
    const response = yield call(getUserSubscriptionApi)
    yield put(getUserSubscriptionSuccess(response))
  } catch (error) {
    yield put(getUserSubscriptionFailure(error))
  }
}

export function* watchGetSubscriptionProduct() {
  yield takeLatest(GET_SUBSCRIPTION_PRODUCT_CALL, getSubscriptionProduct)
}

export function* watchGetUserSubscription() {
  yield takeLatest(GET_USER_SUBSCRIPTION_CALL, getUserSubscription)
}

export function* rootSaga() {
  yield all([watchGetSubscriptionProduct(), watchGetUserSubscription()])
}

export default rootSaga
