import generateApiCall from "utils/generate-api-call"
import {
  GET_SUBSCRIPTION_CALL,
  GET_SUBSCRIPTIONS_AUTH_REQUEST,
  GET_SUBSCRIPTIONS_AUTH_SUCCESS,
  GET_SUBSCRIPTIONS_AUTH_FAILED,
} from "./constants"

export const getSubscriptions = payload => ({
  type: GET_SUBSCRIPTION_CALL,
  payload,
})

export const getSubscriptionsApiState = generateApiCall([
  GET_SUBSCRIPTIONS_AUTH_REQUEST,
  GET_SUBSCRIPTIONS_AUTH_SUCCESS,
  GET_SUBSCRIPTIONS_AUTH_FAILED,
])
