export const API_STATUS = {
  NONE: 'none',
  FETCHING: 'fetching',
  ERROR: 'error',
  SUCCESS: 'success',
  UPDATING: 'updating',
}

export const KEY = {
  IDENTITY_VERIFICATION: 'identityVerification',
}
