import React from "react"
import set from "lodash/set"
import get from "lodash/get"
import { buildPath } from "@rentspree/path"
import Alert from "@rentspree/component-v2/dist/alert"

import UpdateZiplogixPropertyTemplate, {
  AlertWrapper,
} from "components/templates/update-ziplogix-property"

import {
  IconWarning,
  RequestContainer,
} from "legacy/components/request/request-main-style"

import * as PATH from "constants/route"
import { LoadingOverlay } from "legacy/components/request/left-content/index"
import { ZIPLOGIX_TRACKING, EVENT_REQUEST_SCREENING } from "tracker/const"
import { saveNewListingTrackerPropertyMapper } from "tracker/tracker-mapper"
import tracker from "tracker"

import { UPDATE_ZIPLOGIX_PROPERTY_WORDING } from "./constants"
import { withRequestUpdateProperty } from "./connect"

export class StepUpdateZiplogixProperty extends React.Component {
  handleSubmit = async values => {
    const { street, unitNumber, city, state, zipcode } = values
    const {
      subdomain,
      selectedProperty,
      actions,
      history,
      isContinueToPropertyDetails,
      profile,
    } = this.props || {}

    const objAddress = {
      id: selectedProperty.id,
      street,
      unitNumber,
      city,
      state,
      zip: zipcode,
      isNewProperty: true,
    }

    if (subdomain) {
      set(objAddress, "externalIntegration", true)
      set(objAddress, "subdomain", subdomain)
    }

    const res = await actions.createProperty(objAddress)

    if (res?.data) {
      const { registeredUserType } = profile
      const representing = saveNewListingTrackerPropertyMapper(
        isContinueToPropertyDetails,
        registeredUserType,
      )
      tracker.trackEvent(EVENT_REQUEST_SCREENING.saveNewProperty, {
        representing,
      })
      tracker.trackEvent(ZIPLOGIX_TRACKING.UPDATED_PROPERTY)

      const propertyId = get(res, "data._id", "")
      const nextPage = isContinueToPropertyDetails
        ? PATH.REQUEST_UPDATE_PROPERTY_DETAILS
        : PATH.PROPERTY_OVERVIEW_PAGE
      history.push(buildPath(nextPage, { propertyId }))
    } else tracker.trackEvent(ZIPLOGIX_TRACKING.FAIL_UPDATE_PROPERTY)
  }

  goBack = () => this.props.history.push(PATH.PROPERTY)

  render() {
    const { selectedProperty: property, isCreatingProperty } = this.props

    return (
      <RequestContainer>
        <AlertWrapper>
          <Alert
            warning
            style={{
              width: "100%",
              fontSize: 14,
              lineHeight: "20px",
              fontWeight: "600",
              padding: "10px",
            }}>
            <IconWarning className="fas fa-exclamation-triangle" />
            <div>{UPDATE_ZIPLOGIX_PROPERTY_WORDING.WARNING}</div>
          </Alert>
        </AlertWrapper>
        {isCreatingProperty ? (
          <LoadingOverlay />
        ) : (
          <UpdateZiplogixPropertyTemplate
            title={UPDATE_ZIPLOGIX_PROPERTY_WORDING.TITLE}
            updateZiplogixNote={UPDATE_ZIPLOGIX_PROPERTY_WORDING.NOTE}
            handleBackToDashboard={this.goBack}
            onSubmit={this.handleSubmit}
            isUpdatingZiplogixProperty={isCreatingProperty}
            validateOnMount
            {...property}
          />
        )}
      </RequestContainer>
    )
  }
}
export default withRequestUpdateProperty(StepUpdateZiplogixProperty)
