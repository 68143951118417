import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'

import {
  closeAddYourInformationModal,
  closeCreatePropertyModal,
  closeCreateRenterInfoModal,
  openAddYourInformationModal,
  openCreatePropertyModal,
  openCreateRenterInfoModal,
} from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentPaymentModalStateReducer } from '../redux/reducers'
import {
  makeSelectUpdateYourInformationModalState,
  selectCreatePropertyModalState,
  selectCreateRenterInfoModalState,
} from '../redux/selectors'

export const useRentPaymentModalState = () => {
  const dispatch = useDispatch()

  useInjectReducer({ key: KEY.RENT_PAYMENT_MODAL_STATE, reducer: rentPaymentModalStateReducer })

  const updateYourInformationModalState = useSelector(makeSelectUpdateYourInformationModalState())
  const createPropertyModal = useSelector(selectCreatePropertyModalState)
  const createRenterInfoModal = useSelector(selectCreateRenterInfoModalState)

  return {
    updateYourInformationModalState: {
      ...updateYourInformationModalState,
      open: payload => dispatch(openAddYourInformationModal(payload)),
      close: () => dispatch(closeAddYourInformationModal()),
    },
    createPropertyModal: {
      ...createPropertyModal,
      open: () => dispatch(openCreatePropertyModal()),
      close: () => dispatch(closeCreatePropertyModal()),
    },
    createRenterInfoModal: {
      ...createRenterInfoModal,
      open: () => dispatch(openCreateRenterInfoModal()),
      close: () => dispatch(closeCreateRenterInfoModal()),
    },
  }
}
