import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import { getSubscriptions } from "./actions"
import saga from "./saga"
import {
  selectAuthorize,
  selectIsFetching,
  selectErrorSubscription,
  selectUserProfile,
} from "./selectors"
import { KEY_PLAN } from "./constants"

const mapStateToProps = createStructuredSelector({
  authorize: selectAuthorize,
  isFetching: selectIsFetching,
  errorSubscription: selectErrorSubscription,
  user: selectUserProfile,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubscriptions,
    },
    dispatch,
  )

export const withSaga = injectSaga({ key: KEY_PLAN, saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
