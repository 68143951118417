import { object } from "yup"

import { regexValidation } from "constants/validations"
import { validateLength } from "legacy/constants/validations"
import { LICENSE_TYPES } from "constants/user"

export const dreLicenseSchema = ({ agentLicenses }) => {
  const dreLicense = agentLicenses?.find(
    license => license.type === LICENSE_TYPES.DRE,
  )?.number
  return {
    mapper: {
      dreLicense,
    },
    validation: object().shape({
      dreLicense: validateLength(true, 1, 21).matches(
        regexValidation.dreLicense.regex,
        regexValidation.dreLicense.message,
      ),
    }),
  }
}
