import { useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { buildPath } from "@rentspree/path"
import { useParams, usePathname } from "hooks"
import { TENANT_SCREENING_DETAIL } from "constants/route"
import { selectIsFetching } from "containers/tenant-screening/detail/selectors"
import { useLastLocation } from "react-router-last-location"
import { isGotoNewLocation } from "hooks/helper"

export const useToTenantScreeningDetailPath = () => {
  const lastLocation = useLastLocation()
  const isFetching = useSelector(selectIsFetching)
  const pathname = usePathname()
  const { submissionParticipantId } = useParams(TENANT_SCREENING_DETAIL)
  if (isFetching || isEmpty(submissionParticipantId)) {
    return {
      isRedirect: false,
      path: pathname,
    }
  }

  const isRedirect = isGotoNewLocation({
    pathname,
    destinationPath: TENANT_SCREENING_DETAIL,
    lastLocationPath: lastLocation?.pathname,
  })

  return {
    isRedirect,
    path: buildPath(TENANT_SCREENING_DETAIL, {
      submissionParticipantId,
    }),
  }
}
