import React from "react"
import styled from "styled-components"
import { TooltipBubble } from "@rentspree/component-v2/dist/tooltip/styled"
import B14 from "@rentspree/component-v2/dist/typography/b14"

export const Container = styled.div`
  .visible-when-hover {
    visibility: hidden;
  }
  :hover .visible-when-hover {
    visibility: visible;
  }
`

export const ComponentWithHover = ({
  hoverMessage,
  children,
  width,
  position,
  fontSize,
  fontColor,
}) => (
  <Container>
    {hoverMessage && (
      <TooltipBubble {...position} width={width} className="visible-when-hover">
        <B14 size={fontSize} color={fontColor} margin="5px 0 15px 0">
          {hoverMessage}
        </B14>
      </TooltipBubble>
    )}
    {children}
  </Container>
)
