import styled from "styled-components"
import { ANIMATE, COLOR } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const iconCardWrap = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    width: auto;
    overflow: hidden;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const iconCardScroll = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  @media (max-width: 991px) {
    transition: transform ease-in ${ANIMATE.fast};
    transform: ${props => `translateX(${props.current}vw)`};
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const iconCardSwitch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > span {
    flex: 1;
    display: inline-block;
    min-height: 30px;
    cursor: pointer;
    text-align: center;
    margin: 5px 5px 5px 0;
    padding: 5px 5px;
    background-color: ${COLOR.bgGrey};

    &:first-child {
      margin-left: 5px;
    }

    &.active {
      background-color: ${COLOR.deepBlue};
      color: ${COLOR.white};
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const iconCard = styled.div`
  text-align: center;
  padding: 0 25px;
  width: 33%;
  max-width: 400px;

  @media (max-width: 991px) {
    padding: 10px 25px;
    min-width: 100vw;
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const cardTitle = styled.div`
  font-size: 1.5em;
  line-height: 1.35;
  margin: 20px 0 15px;
`

// language=SCSS prefix=dummy{ suffix=}
export const cardDesc = styled.div`
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 25px;
`
