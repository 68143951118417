import { useFormikContext } from 'formik'
import isArray from 'lodash/isArray'
import { useMemo, useEffect, useState } from 'react'

import { scrollToElement } from './scroll-to-element'
import { toDotNotation } from './to-dot-notation'

const exitCondition = value => {
  if (value?.props?.defaultMessage?.length > 0) {
    return true
  }

  return false
}

export const FormikScrollToFirstError = () => {
  const [lastSubmitCount, setLastSubmitCount] = useState(0)
  const { submitCount, isValid, errors } = useFormikContext()

  const pathToError = useMemo(() => {
    if (errors && Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0]

      if (isArray(errors[key])) {
        const firstErrorIndex = errors[key]?.findIndex(error => !!error)

        /*
         * If FormikErrors contain an "undefined" value at the start of the index, then iterate through
         * the errors until you find a defined error. For example:
         *
         * errors = [undefined, undefined, {'foo': 'error'}, {'bar': 'error'}]
         * Then the return value will be
         * {'error': [undefined, undefined, {'foo': 'error'}]}.
         *
         * This object helps reduce toDotNotation computation because it doesn't have to parse all the errors
         * that are not used.
         */
        const firstInFormikReturnErrorToFirstError = {
          [key]: errors[key].slice(0, firstErrorIndex + 1),
        }

        return toDotNotation(firstInFormikReturnErrorToFirstError, {
          exitConditionFn: exitCondition,
        })[0]
      }

      return toDotNotation(errors, {
        exitConditionFn: exitCondition,
      })[0]
    }
    return null
  }, [errors])

  useEffect(() => {
    const shouldScroll = submitCount !== lastSubmitCount && pathToError && submitCount > 0
    if (shouldScroll) {
      setLastSubmitCount(submitCount)
      if (isValid) return

      scrollToElement(`input[name='${pathToError}'], textarea[name='${pathToError}']`)
    }
  }, [submitCount, pathToError])

  return null
}
