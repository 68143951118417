import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { TextArea, Icon } from '@rentspree/component-v2'
import { COLOR } from 'styles/settings'

const Container = styled.div`
  position: relative;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 15px;
  right: 15px;
  color: ${prop => (prop.isFocus ? COLOR.activeBlue : COLOR.textBlack)};
  cursor: pointer;

  // remove transition
  transition: none !important;
  -webkit-transition: none !important;
`

const StyledTextArea = styled(TextArea)`
  resize: none;
`

export const TextAreaWithCloseButton = ({ onClose = () => {}, ...props }) => {
  const [isFocus, setFocus] = useState(false)
  return (
    <Container>
      <StyledIcon name="close" onClick={onClose} isFocus={isFocus} />
      <StyledTextArea
        autoFocus
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props}
      />
    </Container>
  )
}

TextAreaWithCloseButton.propTypes = {
  onClose: PropTypes.func,
}
