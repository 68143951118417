import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { openAcceptDenyModal } from 'containers/accept-deny/actions'
import { openAssignPropertyModal } from 'containers/tenant-screening/assign-property/actions'
import { selectAcceptDenyPayload } from 'containers/accept-deny/selectors'

import { selectIsAllowCarLease } from 'containers/wrapper/selectors'
import {
  selectRentalDetail,
  makeSelectIsShareAvailable,
  selectApplicationDetail,
  selectParticipantId,
} from '../selectors'

export const makeMapStateToProps = () =>
  createStructuredSelector({
    rentalDetail: selectRentalDetail,
    isAllowCarLease: selectIsAllowCarLease,
    isShareAvailable: makeSelectIsShareAvailable(),
    acceptDenyPayload: selectAcceptDenyPayload,
    applicationDetail: selectApplicationDetail,
    submissionParticipantId: selectParticipantId,
  })

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openAcceptDenyModal,
      openAssignPropertyModal,
    },
    dispatch,
  )

export const withConnect = compose(connect(makeMapStateToProps, mapDispatchToProps))
