import produce from 'immer'
import pick from 'lodash/pick'
import {
  AGENT_OPEN_REPORT_EXECUTION_SUCCESS,
  AGENT_OPEN_REPORT_EXECUTION_REQUEST,
  AGENT_OPEN_REPORT_EXECUTION_FAILED,
} from 'legacy/constants/action-types'
import { TYPES } from './constants'
const { GET_SUBMISSION_PARTICIPANT_BY_EMAIL_SUCCESS } = TYPES

export const initialState = {
  participantData: {
    type: '',
    shareOption: {},
  },
  isFetching: false,
  isSharedParticipant: false,
}

/* eslint-disable no-param-reassign */
const submissionParticipantReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case AGENT_OPEN_REPORT_EXECUTION_REQUEST:
        draft.isFetching = true
        break
      case AGENT_OPEN_REPORT_EXECUTION_FAILED:
        draft.isFetching = false
        break
      case AGENT_OPEN_REPORT_EXECUTION_SUCCESS:
        draft.isFetching = false
        draft.participantData = pick(payload, ['_id', 'type', 'property', 'shareOption'])
        break
      case GET_SUBMISSION_PARTICIPANT_BY_EMAIL_SUCCESS:
        draft.isSharedParticipant = payload.isParticipate
        break
      default:
        break
    }
    return draft
  })

export default submissionParticipantReducer
