import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"

import { ButtonSet } from "components/buttons/button-set"
import { Heading } from "../payment-modal-with-stripe/style"

export const Wrapper = styled.div`
  font-family: Source Sans Pro, sans-serif;
`

const RefundModal = ({
  children,
  onClickConfirm,
  isLoading,
  title,
  confirmText = "Submit",
  onClose,
  isOpen,
  footerMessage,
  onClickBack,
  ...props
}) => (
  <RentSpreeBaseModal
    onClose={onClose}
    isOpen={isOpen}
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEsc={false}
    {...props}>
    <Wrapper>
      <Heading>{title}</Heading>
      {children}
      <ButtonSet
        hideBackButton
        hidePrevButton
        margin="30px 0 0 0"
        minWidth="99"
        hideIconNextButton
        onSaveAndNext={onClickConfirm}
        isLoading={isLoading}
        continueText={confirmText}
      />
    </Wrapper>
  </RentSpreeBaseModal>
)

RefundModal.propTypes = {
  /** The children node of RefundModal */
  children: PropTypes.node,
  /** The function that will trigger when the user click confirm button */
  onClickConfirm: PropTypes.func.isRequired,
  /** The loading indicator for confirm button */
  isLoading: PropTypes.bool,
  /** The title of modal */
  title: PropTypes.string,
  /** The text of confirm button. Default as Submit */
  confirmTxt: PropTypes.string,
  /** The function that will trigger when the user click close modal */
  onClose: PropTypes.func,
  /** The boolean indicate showing of modal status */
  isOpen: PropTypes.bool.isRequired,
  /** The strings showing of footer modal message */
  footerMessage: PropTypes.string,
}

export default RefundModal
