import { PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION } from './constants'

/**
 * @param {object} payload
 * @param {string} payload.fromPathname - indicate which pathname that trigger the action
 * @param {boolean} payload.isAutoOpened - indicator for opening the information page automatically on mobile
 * @param {boolean} payload.isOpenedFromNavBarClick - indicator for determining whether the information page is opened from the navbar's button
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION['OPEN_PAGE']}>}
 */
export const openInformationPage = payload => ({
  type: PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.OPEN_PAGE,
  payload,
})

/**
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION['CLOSE_PAGE']}>}
 */
export const closeInformationPage = () => ({
  type: PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.CLOSE_PAGE,
})

/**
 * @param {object} payload
 * @param {string} payload.fromPathname - indicate which pathname that trigger the action
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION['OPEN_REFERRAL_PAGE']}>}
 */
export const openReferralPage = payload => ({
  type: PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.OPEN_REFERRAL_PAGE,
  payload,
})

/**
 * @returns {import('redux').Action<{type: keyof typeof PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION['CLOSE_REFERRAL_PAGE']}>}
 */
export const closeReferralPage = () => ({
  type: PAYMENT_INCENTIVE_INFORMATION_PAGE_ACTION.CLOSE_REFERRAL_PAGE,
})
