import React from 'react'
import styled from 'styled-components'

import Tag from 'components/contacts/atoms/tag'
import { COLOR } from 'styles/settings'

export const Container = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  max-width: 100%;
  max-height: 125px;
  padding: 0;
  background-color: ${COLOR.white};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
  animation-name: fade-in;
  animation-duration: 0.3s;
`

export const TagsMenu = styled.div`
  padding: 10px 20px;
  padding-bottom: 0;
  border-bottom: 1px solid ${COLOR.borderGrey};
`

export const CreateTagButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  background-color: ${COLOR.white};
  color: ${COLOR.deepBlue};
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR.bgGrey};
  }
  appearance: none !important;
`

const CreateTagText = styled.span`
  width: 100%;

  overflow-wrap: break-word;
`

const DropdownMenus = ({
  inputValue,
  tagsDisplay,
  onCreateAndTagContact,
  onTagContact,
  className,
}) => (
  <Container className={className}>
    {tagsDisplay.length > 0 && (
      <TagsMenu>
        {tagsDisplay.map(tag => (
          <React.Fragment key={tag?._id}>
            <Tag
              id={tag?._id}
              key={tag?._id}
              name={tag?.name}
              color={tag?.color}
              margin="0 0 10px 0"
              onClick={event => {
                onTagContact(tag)
                event.stopPropagation()
              }}
              maxWidth="100%"
            />
            <br />
          </React.Fragment>
        ))}
      </TagsMenu>
    )}
    {!!inputValue && (
      <CreateTagButton tabIndex="0" type="button" onClick={onCreateAndTagContact}>
        <CreateTagText>+ Create tag for {inputValue && `'${inputValue}'`}</CreateTagText>
      </CreateTagButton>
    )}
  </Container>
)

export default DropdownMenus
