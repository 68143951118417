export const OPEN_CREATE_CONTACT_MODAL = 'OPEN_CREATE_CONTACT_MODAL'
export const CLOSE_CREATE_CONTACT_MODAL = 'CLOSE_CREATE_CONTACT_MODAL'

export const CREATE_CONTACT_FROM_MODAL_CALL = 'CREATE_CONTACT_FROM_MODAL_CALL'
export const CREATE_CONTACT_FROM_MODAL_REQUEST = 'CREATE_CONTACT_FROM_MODAL_REQUEST'
export const CREATE_CONTACT_FROM_MODAL_SUCCESS = 'CREATE_CONTACT_FROM_MODAL_SUCCESS'
export const CREATE_CONTACT_FROM_MODAL_FAILURE = 'CREATE_CONTACT_FROM_MODAL_FAILURE'

export const CREATE_TAG_CONTACT_MODAL = 'CREATE_TAG_CONTACT_MODAL'
export const CREATE_TAG_CONTACT_MODAL_REQUEST = 'CREATE_TAG_CONTACT_MODAL_REQUEST'
export const CREATE_TAG_CONTACT_MODAL_SUCCESS = 'CREATE_TAG_CONTACT_MODAL_SUCCESS'
export const CREATE_TAG_CONTACT_MODAL_FAILURE = 'CREATE_TAG_CONTACT_MODAL_FAILURE'

export const CREATE_CONTACT_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'New contact created.',
  status: 'success',
  timeOut: 5000,
  width: '350px',
}

export const CREATE_CONTACT_FAILURE_TOAST_CONFIG = {
  titleMessage: "Can't create contact. Please try again later.",
  status: 'error',
  timeOut: 5000,
  width: '350px',
}
