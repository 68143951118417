import React from "react"
import styled from "styled-components"
import { H3, P } from "../typography"
import { FLEX_CENTER } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
const StyledIconTextBox = styled.div`
  text-align: center;
  .text {
    > h3 {
      margin: 5px 0;
      font-size: 1.3em;
    }
    > p {
      font-size: 1.1em;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    .imgIcon {
      max-width: 100px;
      margin-right: 15px;
      img {
        margin: auto;
      }
    }
    .text {
      ${FLEX_CENTER};
      flex-direction: column;
      align-items: flex-start;
      > h3 {
        margin-bottom: 5px;
      }
      > p {
        margin-bottom: 0px;
      }
    }
  }
`

export const IconTextBox = ({ title, subtitle, icon, ...props }) => (
  <StyledIconTextBox {...props}>
    <div className="imgIcon">
      <img src={icon} alt={`${title} icon`} />
    </div>
    <div className="text">
      <H3>{title}</H3>
      <P color="deepBlue">{subtitle}</P>
    </div>
  </StyledIconTextBox>
)
