import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

export const StyledRow = styled.div`
  display: flex;
  ${props => props.inline && "display: inline-flex"};
  ${props => props.spaceBetween && "justify-content: space-between"};
  ${props => props.spaceAround && "justify-content: space-around"};
  ${props => props.flexStart && "justify-content: flex-start"};
  ${props => props.flexEnd && "justify-content: flex-end"}
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.flexColumn && `flex-direction: column;`};
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.wrap && "flex-wrap: wrap;"}
  ${props => props.colGap && `column-gap: ${props.colGap};`}
  ${props => props.rowGap && `row-gap: ${props.rowGap};`}
  ${props => props.whiteSpace && `white-space: ${props.whiteSpace};`}
  @media (max-width: 991px) {
    ${props => props.mPadding && `padding: ${props.mPadding}`};
    ${props => props.mMargin && `margin: ${props.mMargin}`};
    ${props => props.mFlexColumn && `flex-direction: column;`}
    ${props => props.mWidth && `width: ${props.mWidth};`}
    ${props => props.mColGap && `column-gap: ${props.mColGap};`}
    ${props => props.mRowGap && `row-gap: ${props.mRowGap};`}
    ${props => props.mAlignItems && `align-items: ${props.mAlignItems};`}
  }
`

/** Flex Row component for grid layout */
const Row = ({
  children,
  spaceBetween,
  padding,
  margin,
  mPadding,
  mMargin,
  mFlexColumn,
  alignItems,
  mAlignItems,
  width,
  className,
  mWidth,
  wrap,
  colGap,
  rowGap,
  mColGap,
  mRowGap,
  flexColumn,
  flexEnd = false,
  whiteSpace,
  spaceAround,
}) => (
  <StyledRow
    spaceBetween={spaceBetween}
    padding={padding}
    spaceAround={spaceAround}
    margin={margin}
    mPadding={mPadding}
    mMargin={mMargin}
    alignItems={alignItems}
    mAlignItems={mAlignItems}
    className={className}
    mFlexColumn={mFlexColumn}
    flexColumn={flexColumn}
    width={width}
    mWidth={mWidth}
    wrap={wrap}
    colGap={colGap}
    rowGap={rowGap}
    mColGap={mColGap}
    mRowGap={mRowGap}
    flexEnd={flexEnd}
    whiteSpace={whiteSpace}>
    {children}
  </StyledRow>
)

Row.propTypes = {
  /** The children node of Row */
  children: PropTypes.node,
  /** Set justify-content to space-between */
  spaceBetween: PropTypes.bool,
  /** Set padding */
  padding: PropTypes.string,
  /** Set margin */
  margin: PropTypes.string,
  /** Set padding for mobile */
  mPadding: PropTypes.string,
  /** Set margin for mobile */
  mMargin: PropTypes.string,
  /** Set flex direction to column for mobile */
  mFlexColumn: PropTypes.bool,
  /** Set align items */
  alignItems: PropTypes.string,
  /** set width */
  width: PropTypes.string,
  /** set mobile width */
  mWidth: PropTypes.string,
  flexEnd: PropTypes.bool,
  /** set space between column */
  colGap: PropTypes.string,
  /** set space between row */
  rowGap: PropTypes.string,
}

export default Row
