import React from "react"
import { compose } from "redux"
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"
import { useExperiment } from "@statsig/react-bindings"

import { query, buildPath } from "@rentspree/path"

import { isMailingAddressCompleted } from "helpers/mailing-address-checking"
import { AGREEMENT_PAGE, REQUEST_UPDATE_INFO } from "constants/route"
import { LoadingWithCenter } from "components/layout/main"
import { AGREEMENT_TYPE } from "containers/agreement/constants"
import { withAgreementReducerAndSaga } from "containers/agreement/connect"
import { selectAgreement } from "containers/agreement/selectors"
import { getAcceptAgreementCall } from "containers/agreement/actions"

import { SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT } from "utils/feature-flag/constants"

export const withScreenTenantRequirement = WrappedComponent => props => {
  const { location, profile } = props

  const dispatch = useDispatch()
  const experiment = useExperiment(SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT.VALUE)

  React.useEffect(() => {
    dispatch(getAcceptAgreementCall({ type: AGREEMENT_TYPE.TU }))
  }, [])

  const { isFetchingIsAccepted, isAccepted } = useSelector(selectAgreement())
  
  const { continuePath, ...queryString } = query.parse(location.search)
  const search = query.stringify({
    ...queryString,
    originContinuePath: continuePath,
    continuePath: location.pathname,
  })

  const isMailingCompleted = isMailingAddressCompleted(profile)
  const shouldBeAbleToExperiment = !(isAccepted && isMailingCompleted)
  const isSkip = shouldBeAbleToExperiment && experiment.get(SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT.KEYS.IS_SKIP)

  let RenderComponent
  if (isFetchingIsAccepted) {
    RenderComponent = LoadingWithCenter
  } 
  else if (isSkip) {
    RenderComponent = WrappedComponent
  }
  else if (!isAccepted) {
    return (
      <Redirect
        to={{
          pathname: buildPath(AGREEMENT_PAGE, { type: AGREEMENT_TYPE.TU }),
          search,
        }}
      />
    )
  } else if (!isMailingCompleted) {
    return (
      <Redirect
        to={{
          pathname: REQUEST_UPDATE_INFO,
          search,
        }}
      />
    )
  } else {
    RenderComponent = WrappedComponent
  }

  return <RenderComponent {...props} />
}

export default compose(
  withAgreementReducerAndSaga,
  withScreenTenantRequirement,
)
