import React from 'react'
import styled from 'styled-components'

import Alert from '@rentspree/component-v2/dist/alert'
import B14 from '@rentspree/component-v2/dist/typography/b14'
import { Icon } from '@rentspree/component-v2/dist/icon'
import Panel from '@rentspree/component-v2/dist/panel'

import { COLOR } from 'styles/settings'

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.alignItem ? props.alignItem : 'flex-start')};
  i {
    line-height: 20px;
  }
  border-radius: 8px;
  ${props => props.widthAlert && `width: ${props.widthAlert}`};
  ${props => props.fullWidth && 'width: 100%'};
  ${props => props.error && `background: ${COLOR.redPale}; color: ${COLOR.fontRed}`};
  ${props => props.info && `background: ${COLOR.lightBlueHEX}; color: ${COLOR.activeBlue}`};
  width: ${props => props.widthAlert || 'unset'};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.padding && `padding: ${props.padding}`};
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0px;
  margin: 0 0 0 5px;
  height: 20px;
`

export const defaultAlertDetail = (
  <div>
    <B14 weight="600" color="inherit" mb="5">
      We&apos;ve encountered an error. Please try reloading this page, or coming back to it in a few
      minutes.
    </B14>
    <B14 color="inherit" mb="0">
      If the problem persists, please contact us (323) 515-7757 or support@rentspree.com.
    </B14>
  </div>
)

export const AlertPanel = ({ alertDetail, handleClose, ...rest }) => (
  <StyledAlert {...rest}>
    {alertDetail || defaultAlertDetail}
    {handleClose && (
      <Button onClick={handleClose}>
        <Icon icon="close" />
      </Button>
    )}
  </StyledAlert>
)

export const AlertPanelWithLandingWrapper = styled.div`
  position: absolute;
  width: 92%;
  left: 4%;
  margin-top: 15px;
  z-index: 1;
`

export const LegalInstructionPanel = ({
  title = 'title',
  instructionMsg = 'instruction message',
  onClose = () => {},
  ...props
}) => (
  <AlertPanelWithLandingWrapper>
    <Panel
      id="legal-instruction-panel"
      status="info"
      titleMessage={title}
      onClose={onClose}
      {...props}
    >
      <span style={{ color: COLOR.textBlack }}>{instructionMsg}</span>
    </Panel>
  </AlertPanelWithLandingWrapper>
)
