import React from "react"
import RadioButtonCard from "@rentspree/component-v2/dist/input/radio-button-card"
import styled from "styled-components"

const StyledWrapper = styled.div`
  display: flex;
  > div {
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: 0;
    label {
      margin-bottom: 0;
    }
  }
  @media (min-width: 992px) {
    > div {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    > div {
      margin-right: 0;
      &:not(:last-child) {
        margin-bottom: 10px;
        color: red;
      }
    }
  }
`

const RadioBoxGroup = ({ choices, radioName, onChange, value }) => (
  <StyledWrapper>
    {choices.map(choice => (
      <RadioButtonCard
        name={radioName}
        choice={choice}
        onChange={onChange}
        key={choice.id}
        checked={value === choice.value}
      />
    ))}
  </StyledWrapper>
)
export default RadioBoxGroup
