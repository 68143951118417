import { COLOR } from "../../styles/settings"
import {
  REPORT_TAB_ID_ALL,
  REPORT_TAB_ID_APPLICATION,
  REPORT_TAB_ID_CREDIT,
  REPORT_TAB_ID_CRIMINAL,
  REPORT_TAB_ID_EVICTION,
  REPORT_TAB_ID_REF_CHECK,
  REPORT_TAB_ID_OTHER,
  REPORT_TAB_ID_INCOME_VERIFICATION,
} from "./const"

export const ARC_MOBILE_TAB = {
  id: REPORT_TAB_ID_REF_CHECK,
  value: "referenceReport",
  text: "Ref Checks",
  mText: "Ref Checks",
  path: "reference-checks",
  trackTitle: "ref check",
  color: COLOR.textGrey,
  mHidden: false,
  expirable: false,
  isPro: true,
}

export const INCOME_VERIFICATION_TAB = {
  id: REPORT_TAB_ID_INCOME_VERIFICATION,
  text: "Income Verification",
  path: "income",
  trackTitle: "income verification",
  color: COLOR.orange,
  mHidden: true,
  expirable: false,
  isPro: true,
}

export const REPORT_TABS = [
  {
    id: REPORT_TAB_ID_ALL,
    value: "all",
    text: "All",
    mText: "All Reports",
    path: "all",
    trackTitle: "all",
    color: COLOR.choc,
    mHidden: false,
    expirable: false,
    missable: false,
    class: "blueBg",
  },
  {
    id: REPORT_TAB_ID_APPLICATION,
    value: "application",
    text: "Application",
    mText: "Rental Application",
    path: "application",
    trackTitle: "application",
    color: COLOR.darkGreen,
    mHidden: false,
    expirable: false,
    missable: true,
  },
  {
    id: REPORT_TAB_ID_CREDIT,
    value: "creditReport",
    text: "Credit Report",
    mText: "Credit Report",
    path: "credit-report",
    trackTitle: "credit_report",
    color: COLOR.blue,
    mHidden: false,
    expirable: true,
    missable: true,
  },
  {
    id: REPORT_TAB_ID_CRIMINAL,
    value: "criminalReport",
    text: "Background",
    mText: "Background",
    path: "criminal",
    trackTitle: "background",
    color: COLOR.darkBlue,
    mHidden: false,
    expirable: true,
    missable: true,
  },
  {
    id: REPORT_TAB_ID_EVICTION,
    value: "evictionReport",
    text: "Eviction",
    mText: "Eviction Record",
    path: "eviction",
    trackTitle: "eviction",
    color: COLOR.yellow,
    mHidden: false,
    expirable: true,
    missable: true,
  },
  {
    ...ARC_MOBILE_TAB,
    mHidden: true,
  },
  {
    id: REPORT_TAB_ID_OTHER,
    text: "Other Docs",
    path: "documents",
    trackTitle: "other docs",
    color: COLOR.orange,
    mHidden: true,
    expirable: false,
    isPro: true,
  },
]

export const getReportTabs = (isIncomeVerification) => {
  if (isIncomeVerification) {
    const tabs = [...REPORT_TABS]
    const evictionIndex = tabs.findIndex(tab => tab.id === REPORT_TAB_ID_EVICTION)
    tabs.splice(evictionIndex + 1, 0, INCOME_VERIFICATION_TAB)
    return tabs
  }
  return REPORT_TABS
}

export const credit = [
  { field: "Credit Score", value: "0", color: "blue" },
  { field: "Criminal Records", value: "0", color: "darkBlue" },
  { field: "Eviction Records", value: "0", color: "yellow" },
]
export const personal = [
  { field: "Birthday", value: "-" },
  { field: "Monthly Income", value: "" },
]
export const contact = [
  { field: "Email", value: "-" },
  { field: "Phone", value: "-" },
  { field: "Driver License No.", value: "-" },
]

export const MOBILE_TABS = {
  REPORT: 'report',
  REF_CHECKS: 'ref_checks',
  OTHER_DOCS: 'other-docs',
  INCOME_VERIFICATION: 'income-verification',
  MORE: 'more',
}
