import { useSelector } from "react-redux"
import get from "lodash/get"
import qs from "qs"

export const useQueryStrings = () => {
  const queryStrings = useSelector(state =>
    get(state, "router.location.search", ""),
  )

  return qs.parse(queryStrings.replace(/^\?/gi, ""))
}
