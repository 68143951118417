import React from "react"
import styled from "styled-components"
import { B16 } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"

export const CheckboxLabel = styled.label`
  display: block;
  padding: 2px 2px;
  position: relative;
  cursor: ${props => (props.viewMode ? "default" : "pointer")};
  user-select: none;
  /* Hide checkmark when not checked */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  input:focus + .hovered-bg {
    display: block;
  }
  :hover {
    .hovered-bg {
      display: block;
    }
  }

  .label-text {
    padding-left: 32px;
    margin: 0px;
  }

  .active {
    background: ${props => props.hoverColor || `rgba(${COLOR.lightBlueRGBA})`};
  }
`

export const Checkmark = styled.span`
  /* Custom checkbox style */
  position: absolute;
  top: 5px;
  left: 0;
  height: 18px;
  width: 18px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid ${props => props.color || COLOR.fontBlue};
  border-radius: 3px;

  /* Custom checkmark style */
  :after {
    left: 6px;
    top: -4.5px;
    width: 9.7px;
    height: 17.4px;
    border: solid ${props => props.color || COLOR.fontBlue};
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
  }
`

export const StyledCheckbox = styled.input.attrs(() => ({ type: "checkbox" }))`
  /* Hide default checkbox */
  position: absolute;
  opacity: 0;
  cursor: ${props => (props.viewMode ? "default" : "pointer")};
  height: 0;
  width: 0;
`

const HoveredBg = styled.div`
  display: none;
  width: 36px;
  height: 36px;
  top: -4px;
  left: -9px;
  background: ${props => props.color};
  position: absolute;
  transition: all, 0.5s;
  border-radius: 50px;
`

export const Checkbox = ({ id, name, onClick, label, value }) => {
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleClick(e)
    }
  }
  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onClick()
  }
  const colorCheckbox = {
    primaryColor: value ? COLOR.fontBlue : COLOR.placeholderGrey,
    hoverColor: value ? COLOR.payingBackground : COLOR.bgGrey,
  }
  return (
    <CheckboxLabel
      id={id}
      key={name}
      checked={value}
      onClick={handleClick}
      onKeyDown={handleKeyDown}>
      <StyledCheckbox className="input-checkbox" checked={value} />
      <HoveredBg className="hovered-bg" color={colorCheckbox.hoverColor} />
      <Checkmark className="checkmark" color={colorCheckbox.primaryColor} />
      <B16 lineHeight="22px" className="label-text">
        {label}
      </B16>
    </CheckboxLabel>
  )
}
