import { combineReducers } from 'redux'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import {
  CLEAR_SHOW_DOC_PDF,
  GET_OTHER_DOCS_SUCCESS,
  GET_OTHER_DOCS_CALL,
  GET_OTHER_DOCS_FAILED,
  REQUEST_OTHER_DOC_SUCCESS,
  REQUEST_OTHER_DOC_FAILURE,
  REQUEST_OTHER_DOC_CALL,
  CANCEL_DOCUMENT_REQUEST_SUCCESS,
} from 'containers/reports/other-doc/constants'
import omit from 'lodash/omit'
import * as Types from '../constants/action-types'
import { defaultList, docStatus, docType } from '../constants/other-docs-consts'

export const otherDocs = (state = defaultList, action) => {
  let list = cloneDeep(defaultList)
  let customArr = []
  let docs
  switch (action.type) {
    case GET_OTHER_DOCS_SUCCESS:
    case REQUEST_OTHER_DOC_SUCCESS:
      docs = action.result || action.payload?.documents
      if (isEmpty(docs)) {
        return defaultList
      }

      docs = docs.map(doc => {
        if (doc.status === docStatus.uploaded) {
          return { ...doc, status: docStatus.submitted }
        }
        return { _id: doc.id, ...doc }
      })

      // TODO: this part should consider deprecated
      list = list.map(doc => {
        const index = findIndex(docs, d => d.type === doc.type)
        return merge(doc, docs[index])
      })
      customArr = docs
        .filter(doc => doc.type === docType.custom)
        .map((doc, index) => ({
          ...doc,
          id: `custom${index + 1}Doc`,
        }))
      return [...list, ...customArr]
    case CANCEL_DOCUMENT_REQUEST_SUCCESS:
      return state.map(document => {
        if (document.id === action.payload?.id) {
          return {
            ...document,
            status: action.payload?.status,
            updatedAt: action.payload?.updatedAt,
          }
        }
        return document
      })
    default:
      return state
  }
}

export const documentSet = (state = {}, action) => {
  switch (action.type) {
    case GET_OTHER_DOCS_SUCCESS:
    case REQUEST_OTHER_DOC_SUCCESS:
      return omit(action.payload, 'documents')
    default:
      return state
  }
}

export const isFetchingDocSets = (state = false, action) => {
  switch (action.type) {
    case GET_OTHER_DOCS_CALL:
    case REQUEST_OTHER_DOC_CALL:
      return true
    case GET_OTHER_DOCS_FAILED:
    case REQUEST_OTHER_DOC_FAILURE:
      return false
    case GET_OTHER_DOCS_SUCCESS:
    case REQUEST_OTHER_DOC_SUCCESS:
      return false
    default:
      return state
  }
}

const errorOtherDocs = (state = null, action) => {
  switch (action.type) {
    case Types.GET_OTHER_DOCS_FAILED:
      return { message: 'ERROR!!!! FIX IT!!!' }
    default:
      return state
  }
}
export const isFetchingPdfFile = (state = null, action) => {
  switch (action.type) {
    case Types.GET_DOC_PDF_REQUEST:
      return true
    case Types.GET_DOC_PDF_SUCCESS:
    case Types.GET_DOC_PDF_FAILED:
      return false
    default:
      return state
  }
}

export const pdfFile = (state = null, action) => {
  switch (action.type) {
    case Types.GET_DOC_PDF_SUCCESS:
      return action.pdfFile
    // ToDo: move reducer to other-doc container
    case CLEAR_SHOW_DOC_PDF:
      return null
    default:
      return state
  }
}

export const showPdfSuccess = (state = false, action) => {
  switch (action.type) {
    case Types.SHOW_DOC_PDF_SUCCESS:
      return true
    // ToDo: move reducer to other-doc container
    case CLEAR_SHOW_DOC_PDF:
      return false
    default:
      return state
  }
}

export const isFetchingFullDocZip = (state = false, action) => {
  switch (action.type) {
    case Types.DOWNLOAD_FULL_DOC_ZIP_REQUEST:
      return true
    case Types.DOWNLOAD_FULL_DOC_ZIP_FAILED:
    case Types.DOWNLOAD_FULL_DOC_ZIP_SUCCESS:
      return false
    default:
      return state
  }
}

export default combineReducers({
  otherDocs,
  isFetchingDocSets,
  errorOtherDocs,
  documentSet,
  isFetchingPdfFile,
  pdfFile,
  showPdfSuccess,
  isFetchingFullDocZip,
})
