import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React from 'react'

import { StyledCenterContent } from 'components/landing'

export const EmptyStateLandingTemplate = ({
  imageUrl,
  imageAlt,
  title,
  description,
  leftCTA,
  rightCTA,
  testIdPrefix,
  height,
}) => {
  const isMobile = useMediaQuery('(max-width:991px)')

  /*
   * heightCal 100vh is for mobile view height
   * minHeight 100% is for desktop view height
   * those for preventing grey area in the bottom
   */

  const heightStyle = {
    withBreadcrumb: true,
    withFilter: true,
    heightCal: height,
    minHeight: height,
  }

  return (
    <StyledCenterContent {...heightStyle}>
      <img data-testid={`${testIdPrefix}-image`} alt={imageAlt} src={imageUrl} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '18px',
          gap: '8px',
          alignItems: 'center',
          maxWidth: '44rem',
          wordBreak: 'break-word',
        }}
      >
        <Typography variant="title" fontSize="18px" fontWeight={600} sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Typography variant="body-medium" sx={{ width: '260px', textAlign: 'center' }}>
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: '18px',
          gap: '24px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {leftCTA}
        {rightCTA}
      </Box>
    </StyledCenterContent>
  )
}
