import React from 'react'

import styled from 'styled-components'
import HeaderWithDescription from './molecules/header-with-description'
import ButtonSection from './molecules/button-section'
import RequestButton from './atoms/request-button'
import {
  DocumentRequestTableDesktop,
  DocumentRequestTableMobile,
} from './organisms/document-request-table'

const OtherDocsTemplateWrapper = styled.div`
  font-family: Source Sans Pro, san-serif;
`

const DesktopHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const DocumentRequestTemplateDesktop = ({
  isMobile,
  onClickSendRequest,
  onRequestDocs,
  docsList,
  selectedDocs,
  requestDisabled,
  downloadingDocs,
  onDownloadDoc,
  onClickRow,
  onCheckAll,
  onClickCloseNote,
  onNoteInputChange,
  isFetchingFullDocZip,
  getDocumentKebabMenuItems,
  fileMenuItems,
}) => (
  <OtherDocsTemplateWrapper>
    <DesktopHeaderContainer>
      <HeaderWithDescription
        headerText="Request Applicant Documents"
        descriptionText="Select the document options you want to request and add specific instructions as needed."
        isMobile={isMobile}
      />
      <ButtonSection
        isRequestDocumentDisabled={false}
        onClickButton={onClickSendRequest(onRequestDocs)}
      />
    </DesktopHeaderContainer>
    <DocumentRequestTableDesktop
      onCheckAll={onCheckAll}
      docsList={docsList}
      selectedDocs={selectedDocs}
      requestDisabled={requestDisabled}
      downloadingDocs={downloadingDocs}
      onDownloadDoc={onDownloadDoc}
      onClickRow={onClickRow}
      onClickSendRequest={onClickSendRequest}
      onClickCloseNote={onClickCloseNote}
      onRequestDocs={onRequestDocs}
      onNoteInputChange={onNoteInputChange}
      isFetchingFullDocZip={isFetchingFullDocZip}
      getDocumentKebabMenuItems={getDocumentKebabMenuItems}
      fileMenuItems={fileMenuItems}
    />
  </OtherDocsTemplateWrapper>
)

const DocumentRequestTemplateMobile = ({
  isMobile,
  onClickSendRequest,
  onRequestDocs,
  docsList,
  selectedDocs,
  requestDisabled,
  downloadingDocs,
  onDownloadDoc,
  onClickRow,
  onCheckAll,
  onClickCloseNote,
  onNoteInputChange,
  isFetchingFullDocZip,
  getDocumentKebabMenuItems,
  fileMenuItems,
}) => (
  <OtherDocsTemplateWrapper>
    <HeaderWithDescription
      headerText="Request Applicant Documents"
      descriptionText="Select the document options you want to request and add specific instructions as needed."
      isMobile={isMobile}
    />
    <DocumentRequestTableMobile
      onCheckAll={onCheckAll}
      docsList={docsList}
      selectedDocs={selectedDocs}
      requestDisabled={requestDisabled}
      downloadingDocs={downloadingDocs}
      onDownloadDoc={onDownloadDoc}
      onClickRow={onClickRow}
      onClickSendRequest={onClickSendRequest}
      onClickCloseNote={onClickCloseNote}
      onRequestDocs={onRequestDocs}
      onNoteInputChange={onNoteInputChange}
      isFetchingFullDocZip={isFetchingFullDocZip}
      getDocumentKebabMenuItems={getDocumentKebabMenuItems}
      fileMenuItems={fileMenuItems}
      isMobile
    />
    <RequestButton disabled={false} onClick={onClickSendRequest(onRequestDocs)} />
  </OtherDocsTemplateWrapper>
)

// TODO: [lnr-2055]: Add unit tests
export const DocumentRequestTemplate = props =>
  props.isMobile ? (
    <DocumentRequestTemplateMobile {...props} />
  ) : (
    <DocumentRequestTemplateDesktop {...props} />
  )
