import styled, { css } from "styled-components"
import { COLOR } from "components/constants"
import { Button } from "@rentspree/component-v2"
import { Div } from "components/layout/main"

export const SurveyBanner = styled(Div)`
  background: ${({ gradient }) =>
    gradient
      ? css`linear-gradient(
        90deg,
        ${COLOR.fontBlue} 0%,
        ${COLOR.lightShallowBlue} 100%
  )`
      : COLOR.bgGrey};
  height: 117px;
  padding: 0 56px 0 62px;
  @media (max-width: 991px) {
    height: 171px;
    padding: 0 20px 20px;
  }
`

export const SurveyWrapper = styled(Div)`
  @media (max-width: 991px) {
    flex-direction: column;
  }
`

export const NotifyButton = styled(Button)`
  font-size: 16px;
  color: ${COLOR.textBlack};
  background-color: white;
  height: 40px;
  ${props =>
    props.toggle &&
    `
    color: ${COLOR.fontBlue};
    border-color: ${COLOR.fontBlue};
  `};
  &:not(:hover) {
    background-color: white;
  }
  @media (max-width: 991px) {
    height: 40px;
    background-color: white;
    &:focus {
      background-color: white;
    }
  }
`
