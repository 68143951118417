import React from 'react'
import last from 'lodash/last'
import { withFormik } from 'formik'
import { TagsInput } from '@rentspree/component-v2'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import { requestReportPhoneNumberSchema } from './schema'
import { StyledForm, ButtonContainer } from './styled'

export class Form extends React.Component {
  componentWillMount() {
    // if the error exists set error into formik form
    if (this.props.phoneErrors) {
      this.props.setFieldError('phoneNumber', this.props.phoneErrors)
      // set error state to undefined for solving stuck set state loop
      this.props.handleTagsInputErrors(undefined, false)
    }
  }

  componentWillUnmount() {
    // set latest error for remembering the error
    this.props.handleTagsInputErrors(this.props.errors.phoneNumber, false)
  }

  handlePasteFormat = value => {
    // eslint-disable-next-line no-useless-escape
    const telFormat = /^\((\d{3})\)\s(\d{3})\-(\d{4})$/g
    if (!telFormat.test(value)) {
      const notNumber = /\D+/g
      const telNumber = value.replace(notNumber, '')
      return `(${telNumber.slice(0, 3)}) ${telNumber.slice(3, 6)}-${telNumber.slice(6)}`.trim()
    }
    return value.trim()
  }

  render() {
    const { handleSubmit, errors, setFieldValue, handleTagsInputChange, phoneValues } = this.props
    return (
      <StyledForm onSubmit={handleSubmit}>
        <TagsInput
          name="phoneNumber"
          id="dashboardPhoneListTagsInput"
          tags={phoneValues || []}
          onChange={tagsPhone => {
            setFieldValue('phoneNumber', tagsPhone)
            handleTagsInputChange(tagsPhone, false)
          }}
          label="Enter renters’ mobile numbers separated by comma"
          error={last(errors.phoneNumber) || errors.phoneNumber}
          inputFormat="PHONE"
          pasteFormat={this.handlePasteFormat}
          wrapperStyle={{
            height: '140px',
            overflowY: 'auto',
          }}
          isRequired
        />
        <ButtonContainer>
          <BlueGradientButton
            id="dashboardPhonesListSubmitBtn"
            type="submit"
            small
            fontWeight="600"
            minWidth="150"
            mWidth="100%"
            height="35px"
            lineHeight="35px"
          >
            Send Requests
          </BlueGradientButton>
        </ButtonContainer>
      </StyledForm>
    )
  }
}

export const RequestReportPhoneNumberForm = withFormik({
  mapPropsToValues: props => requestReportPhoneNumberSchema(props).mapper,
  validationSchema: props => requestReportPhoneNumberSchema(props).validation,
  handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: 'RequestReportPhoneNumberForm',
})(Form)
