import produce from 'immer'
import {
  CREATE_CONTACT_FROM_CREATE_PROPERTY_FAILED,
  CREATE_CONTACT_FROM_CREATE_PROPERTY_REQUEST,
  CREATE_CONTACT_FROM_CREATE_PROPERTY_SUCCESS,
  SET_CONTINUE_TO_PROPERTY_DETAILS,
} from './constants'

export const initialState = {
  isContinueToPropertyDetails: false,
  isCreatingContact: false,
}

/* eslint-disable no-param-reassign */
export const stepCreatePropertyReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case SET_CONTINUE_TO_PROPERTY_DETAILS:
        draft.isContinueToPropertyDetails = payload
        break
      case CREATE_CONTACT_FROM_CREATE_PROPERTY_REQUEST:
        draft.isCreatingContact = true
        draft.isCreatingContactSuccess = false
        break
      case CREATE_CONTACT_FROM_CREATE_PROPERTY_SUCCESS:
        draft.isCreatingContact = false
        draft.isCreatingContactSuccess = true
        break
      case CREATE_CONTACT_FROM_CREATE_PROPERTY_FAILED:
        draft.isCreatingContact = false
        draft.isCreatingContactSuccess = false
        draft.error = payload.error
        break

      default:
        break
    }
    return draft
  })

export default stepCreatePropertyReducer
