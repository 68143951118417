import get from "lodash/get"
import { createSelector } from "reselect"
import { initialState } from "./reducer"

const getRenterInsuranceState = state =>
  get(state, "renterInsurance", initialState)

export const selectIsErrorRenterInsuranceList = createSelector(
  getRenterInsuranceState,
  state => get(state, "isError"),
)

export const selectIsFetchingRenterInsuranceList = createSelector(
  getRenterInsuranceState,
  state => get(state, "isFetching"),
)

export const selectRenterInsuranceList = createSelector(
  getRenterInsuranceState,
  state => {
    const list = get(state, "renterInsuranceList")
    return list.map(item => ({
      ...item,
      holder: item.requestee.email,
      greyRow: !item.hasBeenOpen,
      textBold: item.hasBeenOpen,
    }))
  },
)

export const selectRenterInsurancePagination = createSelector(
  getRenterInsuranceState,
  state => get(state, "pagination"),
)
