import get from 'lodash/get'

import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getNewPropertyState = state => get(state, 'newProperty', initialState)

export const selectIsContinueToPropertyDetails = () =>
  createSelector(getNewPropertyState, newProperty =>
    get(newProperty, 'isContinueToPropertyDetails'),
  )
