import get from 'lodash/get'
import { takeLatest, put, call } from 'redux-saga/effects'

import { ALERT_PRESET } from 'redux-middleware/sweet-alert'
import history from 'utils/history'
import { openSweetAlert } from 'utils/sweet-alert-actions'
import { RENT_PAYMENT_URL } from 'v3/containers/rent-payment/constants'

import { GET_RENTAL_PAYMENT_BY_ID, getRentalPaymentByIdAPI } from '../../actions'
import { getRentalPaymentByIdInstance } from '../../api-instances'
import { GET_RENTAL_PAYMENT_MESSAGE, HTTP_STATUS } from '../../constants'

export function* getRentalPaymentByIdSaga({ payload }) {
  yield put(getRentalPaymentByIdAPI.request())
  try {
    const response = yield call(getRentalPaymentByIdInstance, payload)
    yield put(getRentalPaymentByIdAPI.success(response))
  } catch (err) {
    yield put(getRentalPaymentByIdAPI.failure(err))
    if (get(err, 'status') === HTTP_STATUS.FORBIDDEN) {
      yield put(
        openSweetAlert({
          preset: ALERT_PRESET.TRY_AGAIN,
          option: {
            title: GET_RENTAL_PAYMENT_MESSAGE.ERROR.title,
            text: GET_RENTAL_PAYMENT_MESSAGE.ERROR.text,
            button: GET_RENTAL_PAYMENT_MESSAGE.ERROR.button,
          },
          promise: () => {
            history.push(RENT_PAYMENT_URL)
          },
        }),
      )
    }
  }
}

export function* watchGetRentalPaymentByIdSaga() {
  yield takeLatest(GET_RENTAL_PAYMENT_BY_ID, getRentalPaymentByIdSaga)
}
