import Tracker, {
  AmplitudeTracker,
  HubspotTracker,
  UserRecorderTracker,
  GTMTracker,
} from '@rentspree/tracker'
import Amplitude from 'amplitude-js'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'

import isBoolean from 'lodash/isBoolean'
import * as env from '../env'

const userRecorderTracker = new UserRecorderTracker()

export const generateAmplitudeProfileItem = (value, setOnce = false) => ({
  value,
  setOnce,
})

export const amplitudeTracker = new AmplitudeTracker({
  apiKey: env.AMPLITUDE_API_KEY,
  amplitudeSDK: Amplitude,
  amplitudeConfig: {
    domain: env.AMPLITUDE_COOKIE_DOMAIN,
    saveParamsReferrerOncePerSession: false, // Always capture a new UTM value
    includeUtm: true,
  },
  mapUserIdentity: profile => get(profile, '_id'),
  mapUserProfile: profile => {
    const initProfile = {
      email: generateAmplitudeProfileItem(profile.email),
      phone: generateAmplitudeProfileItem(profile.phone),
      createdAt: generateAmplitudeProfileItem(profile.createdAt, true),
      userType: generateAmplitudeProfileItem(profile.userType),
      registeredUserType: generateAmplitudeProfileItem(profile.registeredUserType),
      proContent: generateAmplitudeProfileItem(profile.improveProContentEnabled),
      is_pro: generateAmplitudeProfileItem(profile.is_pro),
      subdomain: generateAmplitudeProfileItem(profile.subdomain),
      is_agent_initiated_ll: generateAmplitudeProfileItem(profile.isAgentInitiatedLandlord),
    }
    if (!isEmpty(profile.firstName)) {
      initProfile.firstName = generateAmplitudeProfileItem(profile.firstName)
    }
    if (!isEmpty(profile.lastName)) {
      initProfile.lastName = generateAmplitudeProfileItem(profile.lastName)
    }
    if (!isEmpty(profile.experiments)) {
      profile.experiments.forEach(({ name, value }) => {
        initProfile[name] = { value }
      })
    }
    return omitBy(initProfile, item => {
      const { value } = item || {}
      return isEmpty(value) && !isBoolean(value)
    })
  },
})

export const hubspotTracker = new HubspotTracker({
  mapUserIdentity: profile => ({
    email: get(profile, 'email'),
    instantlyCreateContact: true,
  }),
})

export const gtmTracker = new GTMTracker({
  trackingId: env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
  mapUserProfile: profile => ({
    id: get(profile, '_id'),
    email: get(profile, 'email'),
  }),
})

const tracker = new Tracker()

tracker.registerTracker(amplitudeTracker)
tracker.registerTracker(hubspotTracker)
tracker.registerTracker(userRecorderTracker)
tracker.registerTracker(gtmTracker)

export default tracker
