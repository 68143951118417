import { stripeCardDeclineErrorMessage, stripeErrorMessage } from "../constants"

const getErrorStripeMessage = ({ code, errors }) => {
  // eslint-disable-next-line camelcase
  const declineCode = errors?.decline_code
  if (code === "card_declined" && declineCode) {
    return stripeCardDeclineErrorMessage[declineCode]
  }
  return stripeErrorMessage[code]
}

export default getErrorStripeMessage
