export const TYPES = {
  CALL_COMPLETE_RENT_ESTIMATE: "CALL_COMPLETE_RENT_ESTIMATE",
  COMPLETE_RENT_ESTIMATE_REQUESTED: "COMPLETE_RENT_ESTIMATE_REQUESTED",
  COMPLETE_RENT_ESTIMATE_SUCCESS: "COMPLETE_RENT_ESTIMATE_SUCCESS",
  COMPLETE_RENT_ESTIMATE_FAILED: "COMPLETE_RENT_ESTIMATE_FAILED",
  CALL_CANCEL_RENT_ESTIMATE: "CALL_CANCEL_RENT_ESTIMATE",
  CANCEL_RENT_ESTIMATE_REQUESTED: "CANCEL_RENT_ESTIMATE_REQUESTED",
  CANCEL_RENT_ESTIMATE_SUCCESS: "CANCEL_RENT_ESTIMATE_SUCCESS",
  CANCEL_RENT_ESTIMATE_FAILED: "CANCEL_RENT_ESTIMATE_FAILED",
}

export const API_COMPLETE_RENT_ESTIMATE_REPORT =
  "/api/v2/rent-estimates/:rentEstimateId/complete"

export const API_CANCEL_RENT_ESTIMATE_REPORT =
  "/api/v2/rent-estimates/:rentEstimateId/cancel"
