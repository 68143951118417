import { connect } from 'react-redux'
import injectSaga from 'utils/inject-saga'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectIsUpdatingDreLicense } from 'containers/request/step-update-dre-license/selectors'
import { updateDreLicense } from './actions'
import { selectRequestOptions } from '../selectors'
import saga from './saga'

export const mapStateToProps = createStructuredSelector({
  isUpdatingDreLicense: selectIsUpdatingDreLicense(),
  requestOptions: selectRequestOptions,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateDreLicense,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
export const withSaga = injectSaga({ key: 'dreLicenseV2.2', saga })
