import styled from "styled-components"

const componentList = styled.div`
  div {
    &:not(:last-child) {
      ${props => props.bottomGap && `margin-bottom: ${props.bottomGap}px;`}
  }
`

export default componentList
