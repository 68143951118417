import { takeLatest, put, call } from 'redux-saga/effects'

import {
  GET_RENTAL_PAYMENT_LIST,
  getRentalPaymentListAPI,
  changePageRentalPaymentListAPI,
} from '../../actions'
import { getRentalPaymentListInstance } from '../../api-instances'

export function* getRentalPaymentListSaga({ payload }) {
  // Add change page boolean, just so we can handle loading differently than loading new page
  const { changePage } = payload
  const api = changePage ? changePageRentalPaymentListAPI : getRentalPaymentListAPI
  yield put(api.request())
  try {
    const response = yield call(getRentalPaymentListInstance, payload)
    yield put(api.success(response))
  } catch (err) {
    yield put(api.failure(err))
  }
}

export function* watchGetRentalPaymentListSaga() {
  yield takeLatest(GET_RENTAL_PAYMENT_LIST, getRentalPaymentListSaga)
}
