import { API_URL } from 'env'

export const GET_CONTACT_BY_EMAIL_API = `${API_URL}/api/v2/people/contacts/email/:email`
export const GET_CONTACT_CALL = 'GET_CONTACT_BY_EMAIL_CALL'
export const GET_CONTACT_REQUEST = 'GET_CONTACT_BY_EMAIL_REQUEST'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_BY_EMAIL_SUCCESS'
export const GET_CONTACT_FAILURE = 'GET_CONTACT_BY_EMAIL_FAILURE'
export const CLEAR_CONTACT = 'CLEAR_CONTACT'

export const DELETE_CONTACT_CALL = 'DELETE_CONTACT_REPORT_CALL'
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REPORT_REQUEST'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_REPORT_SUCCESS'
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_REPORT_FAILURE'

export const DELETE_CONTACT_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'Applicant Deleted Successfully',
  status: 'success',
  timeOut: 5000,
  width: '350px',
}

export const DELETE_CONTACT_FAILURE_TOAST_CONFIG = {
  titleMessage: 'Contact removed error',
  status: 'error',
  timeOut: 5000,
  width: '350px',
}

export const ADD_TO_CONTACT_SUCCESS_TOAST_CONFIG = {
  titleMessage: 'Contact added successfully',
  status: 'success',
  timeOut: 5000,
  width: '350px',
}

export const ADD_TO_CONTACT_FAILURE_TOAST_CONFIG = {
  titleMessage: 'Contact added error',
  status: 'error',
  timeOut: 5000,
  width: '350px',
}

export const ADD_TO_CONTACT_CALL = 'ADD_TO_CONTACT_CALL'
export const ADD_TO_CONTACT_REQUEST = 'ADD_TO_CONTACT_REQUEST'
export const ADD_TO_CONTACT_SUCCESS = 'ADD_TO_CONTACT_SUCCESS'
export const ADD_TO_CONTACT_FAILURE = 'ADD_TO_CONTACT_FAILURE'
