import get from "lodash/get"
import isNull from "lodash/isNull"
import eventTracker from "./event-tracker-const"
import publicIP from "./public-ip" // eslint-disable-line import/no-cycle

// Main tracker class of the application
class Tracker {
  constructor() {
    this.trackers = []
  }

  trackPageView(url, path) {
    this.trackers.forEach(t => t.trackPageView && t.trackPageView(url, path))
  }

  identifyUser(profile) {
    const identifier = {
      email: profile.email,
      phone: profile.phone,
      registeredUserType: profile.registeredUserType,
      userType: profile.userType,
    }
    let displayName = null
    if (get(profile, "firstName", null) || get(profile, "lastName", null)) {
      displayName = `${profile.firstName} ${profile.lastName}`
    }
    if (!isNull(displayName)) {
      identifier.displayName = displayName
    }

    publicIP().then(ip => {
      this.trackers.forEach(
        t => t.identifyUser && t.identifyUser(profile, ip || ""),
      )
    })
  }

  trackEvent(referenceId, properties) {
    const eventObject = eventTracker[referenceId] || {}
    const { eventName } = eventObject
    const eventProperties = { ...eventObject.properties, ...properties }
    this.trackers.forEach(
      t => t.trackEvent && t.trackEvent(eventName, eventProperties),
    )
  }
}

export const TrackerInstance = new Tracker()
export const eventTrackerList = eventTracker
