import { TU_CONTACT } from "constants/partner"

export const MISCELLANEOUS = {
  TEXT: {
    LIQUID: "LIQUID",
    UNLAWFUL: "UNLAWFUL",
    MOVE_OUT: "MOVE_OUT",
    BANKRUPTCY: "BANKRUPTCY",
    FELONY: "FELONY",
  },
  STATUS: {
    NO: "no",
    YES: "yes",
  },
  LIQUID: {
    YES: "I plan to use liquid-filled furniture",
    NO: "I don't plan to use liquid-filled furniture",
  },
  UNLAWFUL: {
    YES:
      "I have been a party to an unlawful detainer action or filed bankruptcy within the last seven years.",
    NO:
      "I haven't been a party to an unlawful detainer action or filed bankruptcy within the last seven years.",
  },
  MOVE_OUT: {
    YES: "I have been asked to move out of a residence.",
    NO: "I haven’t been asked to move out of a residence.",
  },
  BANKRUPTCY: {
    YES: "I have filed bankruptcy within the last seven years.",
    NO: "I haven't filed bankruptcy within the last seven years.",
  },
  FELONY: {
    YES: "I have been convicted of or pleaded no contest to a felony.",
    NO: "I haven't been convicted of or pleaded no contest to a felony.",
  },
}

export const HOUSING_TYPE = {
  rental: {
    value: "rental",
    text: "Rental Property",
  },
  owner: {
    value: "owner",
    text: "Owner Occupied",
  },
  none: {
    value: "none",
    text: "None",
  },
}
export const RESIDENCE_TYPE = {
  current: {
    value: "current",
    text: "Current Residence",
  },
  previous: {
    value: "previous",
    text: "Previous Residence",
  },
}

export const SMART_MOVE_RESIDENCE_SCORE =
  "https://www.mysmartmove.com/SmartMove/landlord-credit-check-service.page"

export const COMPANY_INFO = [
  {
    title: "TransUnion",
    name: "TransUnion",
    phone: TU_CONTACT.PHONE,
    address: "6430 South Fiddlers Green Circle, Greenwood Village, CO 80111",
  },
  {
    title: "RentSpree",
    name: "RentSpree Inc.",
    phone: "(323) 515-7757",
    address: "15303 Ventura Blvd Suite 1150, Sherman Oaks, CA 91403",
    email: "support@rentspree.com",
  },
]

export const INCORRECT_INFO_TEXT = `All of the information on the screening reports comes directly from TransUnion. If there is any information on the screening reports that you feel is not correct, you can contact TransUnion at ${
  TU_CONTACT.PHONE
} and provide your email address.`

export const NO_CREDIT_SCORE_MESSAGE =
  "File not scored because subject does not have any tradelines or sufficient credit. If this information is incorrect, please contact TransUnion at "

export const REPORT_STEP = {
  EMAIL_VERIFICATION: "email_verification",
  VERIFICATION: "verification",
  VERIFICATION_FAIL: "verification_fail",
  NONE: "none",
  PAYMENT: "payment",
  PROCESSING_PAYMENT: "processing_payment",
  REFUND: "refund",
  READY: "ready",
  OPENED: "opened",
  EXPIRED: "expired",
  OPEN_ERROR: "openError",
  SIGN: "sign",
  SKIP_PAYMENT: "skipPayment",
}
