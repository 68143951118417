export const SETUP_CREATE_PROPERTY = 'createProperty/cmd/setupCreateProperty`'
export const OPEN_CREATE_PROPERTY = 'createProperty/cmd/openCreateProperty`'
export const CLOSE_CREATE_PROPERTY = 'createProperty/cmd/closeCreateProperty`'
export const GO_NEXT_STEP_CREATE_PROPERTY = 'createProperty/cmd/goNextStep'
export const GO_BACK_STEP_CREATE_PROPERTY = 'createProperty/cmd/goBackStep'
export const RESET_CREATE_PROPERTY = 'createProperty/cmd/reset'

export const FETCH_CREATE_PROPERTY = 'createProperty/cmd/fetchCreateProperty'
export const FETCH_CREATE_PROPERTY_REQUEST = 'createProperty/evt/fetchCreatePropertyRequested'
export const FETCH_CREATE_PROPERTY_SUCCESS = 'createProperty/evt/fetchCreatePropertySucceeded'
export const FETCH_CREATE_PROPERTY_FAILED = 'createProperty/evt/fetchCreatePropertyFailed'

export const FETCH_VERIFY_PROPERTY_ADDRESS = 'createProperty/cmd/fetchVerifyPropertyAddress'
export const FETCH_VERIFY_PROPERTY_ADDRESS_REQUEST =
  'createProperty/evt/fetchVerifyPropertyAddressRequested'
export const FETCH_VERIFY_PROPERTY_ADDRESS_SUCCESS =
  'createProperty/evt/fetchVerifyPropertyAddressSucceeded'
export const FETCH_VERIFY_PROPERTY_ADDRESS_FAILURE =
  'createProperty/evt/fetchVerifyPropertyAddressFailed'
export const RESET_ADDRESS_VERIFICATION = 'createProperty/cmd/resetAddressVerification'

export const FETCH_PROPERTY_BY_ADDRESS = 'createProperty/cmd/fetchPropertyByAddress'
export const FETCH_PROPERTY_BY_ADDRESS_REQUEST =
  'createProperty/evt/fetchPropertyByAddressRequested'
export const FETCH_PROPERTY_BY_ADDRESS_SUCCESS =
  'createProperty/evt/fetchPropertyByAddressSucceeded'
export const FETCH_PROPERTY_BY_ADDRESS_FAILURE = 'createProperty/evt/fetchPropertyByAddressFailed'
export const RESET_PROPERTY_BY_ADDRESS = 'createProperty/cmd/resetPropertyByAddress'
