import React from "react"
import values from "lodash/values"
import classnames from "classnames/bind"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { PARTICIPANT_TYPE } from "containers/share-report/constants"
import { ReportStatusImage } from "legacy/components/table/table-style"
import { APP_STATUS } from "legacy/constants/application-consts"
import * as CREDIT_REPORT from "legacy/constants/credit-report-consts"
import { PAYER } from "legacy/constants/request-const"
import styles from "legacy/components/application/application-table.scss"
import { I, Text } from "legacy/components/typography"

import shareReportReadyIcon from "images/share-report-desktop-ready.svg"
import shareReportExpiredIcon from "images/share-report-desktop-expired.svg"

const c = classnames.bind(styles)

export const CreditCardIcon = () => (
  <I size="0.9em" ml={7} mMargin="0" className="fas fa-credit-card" />
)

export const ShareReportIcon = isExpired => (
  <ReportStatusImage
    src={isExpired ? shareReportExpiredIcon : shareReportReadyIcon}
    alt={`share-report-${isExpired ? "expired" : "ready"}-image"`}
  />
)

const ReportStatusIcon = ({
  creditReport = {},
  appStatus,
  participantType = PARTICIPANT_TYPE.OWNER,
  hasTooltip = false,
}) => {
  const screenOption = creditReport.screening_option || {}
  const isReportReady = appStatus === APP_STATUS.submitted.value
  const isReportExpired = appStatus === APP_STATUS.expired.value
  const isStatusPaid = values(CREDIT_REPORT.PAID_STATUS).includes(
    creditReport.status,
  )

  const isAvailableColor = isAvailable =>
    isAvailable ? "darkGreen" : "placeholderGrey"

  if (participantType === PARTICIPANT_TYPE.PARTICIPANT) {
    return (
      <ReportStatusComponent
        text="Shared Report"
        color={isAvailableColor(!isReportExpired)}
        icon={() => <ShareReportIcon isExpired={isReportExpired} />}
        hasTooltip={hasTooltip}
      />
    )
  }

  if (screenOption && screenOption.payerType === PAYER.AGENT && isReportReady) {
    return (
      <ReportStatusComponent
        text={isStatusPaid ? "Paid" : "Payment Required"}
        color={isAvailableColor(isStatusPaid)}
        icon={CreditCardIcon}
        hasTooltip={hasTooltip}
      />
    )
  }
  return null
}

export const TooltipProvider = ({ hasTooltip, text, children }) =>
  hasTooltip ? (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={
        <Tooltip id={text} className={c("tooltip-status")}>
          {text}
        </Tooltip>
      }>
      {children}
    </OverlayTrigger>
  ) : (
    <>{children}</>
  )

export const ReportStatusComponent = ({
  text,
  hasTooltip = false,
}) => (
  <TooltipProvider hasTooltip={hasTooltip} text={text}>
    <>
      {!hasTooltip && (
        <Text className="visible-xs-inline" leftSpace="7px">
          {text}
        </Text>
      )}
    </>
  </TooltipProvider>
)

export default ReportStatusIcon
