import produce from "immer"
import {
  VERIFY_PROPERTY_ADDRESS_REQUEST,
  VERIFY_PROPERTY_ADDRESS_SUCCESS,
  VERIFY_PROPERTY_ADDRESS_FAILURE,
  RESET_ADDRESS_VERIFICATION,
} from "./constants"

export const initialState = {
  isVerifyingPropertyAddress: false,
  addressVerification: {
    isVerified: undefined,
    standardizedAddress: undefined,
    inputAddress: undefined,
  }
}

export const errorState = {
  isVerifyingPropertyAddress: false,
  addressVerification: {
    isVerified: false,
    standardizedAddress: null,
    inputAddress: null,
  }
}

/* eslint-disable default-case, no-param-reassign */
const addressVerificationReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case VERIFY_PROPERTY_ADDRESS_REQUEST:
        draft.isVerifyingPropertyAddress = true
        break
      case VERIFY_PROPERTY_ADDRESS_SUCCESS:
        draft.addressVerification = payload.result
        draft.isVerifyingPropertyAddress = false
        break
      case VERIFY_PROPERTY_ADDRESS_FAILURE:
        draft = errorState
        break
      case RESET_ADDRESS_VERIFICATION:
        draft = initialState
        break
      default:
        break
    }
    return draft
  })

  export default addressVerificationReducer