import { call, put, takeLatest } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'

import { GET_PAYEE_IDV_UPDATE_LINK, getPayeeIdvUpdateLinkAPI } from '../../actions'
import { getPayeeIdvUpdateLinkInstance } from '../../api-instances'
import { UPDATE_YOUR_INFORMATION_TOAST } from '../../constants'

export function* getPayeeIdvUpdateLinkSaga({ payload }) {
  const { redirectUrl, provider = 'stripe' } = payload

  yield put(getPayeeIdvUpdateLinkAPI.request())
  try {
    const { url, expiresAt } = yield call(getPayeeIdvUpdateLinkInstance, { redirectUrl, provider })
    yield put(getPayeeIdvUpdateLinkAPI.success({ url, expiresAt }))
  } catch (err) {
    yield put(getPayeeIdvUpdateLinkAPI.failure(err))
    yield put(addToast(UPDATE_YOUR_INFORMATION_TOAST.ERROR))
  }
}

export function* watchGetPayeeIdvUpdateLinkSaga() {
  yield takeLatest(GET_PAYEE_IDV_UPDATE_LINK, getPayeeIdvUpdateLinkSaga)
}
