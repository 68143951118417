import isUndefined from 'lodash/isUndefined'
import pickBy from 'lodash/pickBy'

export const parseContactData = (rentalSubmission = {}, application = {}) => {
  const { moveDate, email } = rentalSubmission

  let applicationParsedValue
  if (application) {
    const {
      dateOfBirth,
      monthlyIncome,
      residence,
      occupation,
      phoneNumber,
      workPhoneNumber,
      homePhoneNumber,
      firstName,
      middleName,
      lastName,
    } = application

    applicationParsedValue = {
      birthDate: dateOfBirth,
      monthlySalary: monthlyIncome,
      workStatus: occupation?.current?.workStatus,
      mobileNumber: phoneNumber,
      officeNumber: workPhoneNumber,
      phoneNumber: homePhoneNumber,
      firstName,
      middleName,
      lastName,
    }

    if (residence?.current) {
      applicationParsedValue.livingAddress = {
        street: residence?.current?.street,
        city: residence?.current?.city,
        state: residence?.current?.state,
        zipcode: residence?.current?.zipCode,
      }
    }

    if (occupation?.current?.workStatus === 'employed') {
      applicationParsedValue.companyAddress = {
        name: occupation?.current?.employed?.employer,
        street: occupation?.current?.employed?.street,
        city: occupation?.current?.employed?.city,
        state: occupation?.current?.employed?.state,
        zipcode: occupation?.current?.employed?.zipCode,
      }
    }
  }

  return pickBy(
    {
      ...applicationParsedValue,
      email,
      moveInDate: moveDate,
      source: 'application',
    },
    v => !isUndefined(v),
  )
}
