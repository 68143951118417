import { API_URL } from '../../../env'

export const CONTACT_DETAIL_API = `${API_URL}/api/v2/people/contacts/:contactId`
export const CONTACT_CONTACT_API = `${API_URL}/api/v2/people/contacts/:contactId/contact`
export const CONTACT_STAR_API = `${API_URL}/api/v2/people/contacts/:contactId/star`

export const GET_CONTACT_DETAIL_CALL = 'GET_CONTACT_DETAIL_CALL'
export const GET_CONTACT_DETAIL_REQUEST = 'GET_CONTACT_DETAIL_REQUEST'
export const GET_CONTACT_DETAIL_SUCCESS = 'GET_CONTACT_DETAIL_SUCCESS'
export const GET_CONTACT_DETAIL_FAILURE = 'GET_CONTACT_DETAIL_FAILURE'

export const UPDATE_CONTACT_DETAIL_INSTANTLY = 'UPDATE_CONTACT_DETAIL_INSTANTLY'

export const UPDATE_CONTACT_DETAIL_CALL = 'UPDATE_CONTACT_DETAIL_CALL'
export const UPDATE_CONTACT_DETAIL_REQUEST = 'UPDATE_CONTACT_DETAIL_REQUEST'
export const UPDATE_CONTACT_DETAIL_SUCCESS = 'UPDATE_CONTACT_DETAIL_SUCCESS'
export const UPDATE_CONTACT_DETAIL_FAILURE = 'UPDATE_CONTACT_DETAIL_FAILURE'

export const CONTACT_CONTACT_CALL = 'CONTACT_CONTACT_CALL'

export const DELETE_CONTACT_CALL = 'DELETE_CONTACT_CALL'
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE'

export const STAR_CONTACT = 'STAR_CONTACT'

export const CREATE_AND_TAG_CONTACT_CALL = 'CREATE_AND_TAG_CONTACT_CALL'
export const TAG_CONTACT_CALL = 'TAG_CONTACT_CALL'
export const TAG_CONTACT_REQUEST = 'TAG_CONTACT_REQUEST'
export const TAG_CONTACT_SUCCESS = 'TAG_CONTACT_SUCCESS'
export const TAG_CONTACT_FAILURE = 'TAG_CONTACT_FAILURE'

export const UNTAG_CONTACT_REQUEST = 'UNTAG_CONTACT_REQUEST'
export const UNTAG_CONTACT_SUCCESS = 'UNTAG_CONTACT_SUCCESS'
export const UNTAG_CONTACT_FAILURE = 'UNTAG_CONTACT_FAILURE'
export const UNTAG_CONTACT_CALL = 'UNTAG_CONTACT_CALL'

export const DELETE_MODAL_TITLE = 'Delete Contact'
export const DELETE_MODAL_BODY =
  'Are you sure you would like to delete selected contacts? Deleted contact(s) cannot be recovered.'
export const DELETE_MODAL_CONFIRM = 'Confirm'
export const DELETE_MODAL_RECONSIDER = 'Reconsider'

export const DELETE_TOAST_SUCCESS = 'Delete successfully'
export const DELETE_TOAST_FAILURE = 'Error occurred, please try again'
export const TAG_TOAST_FAILURE = 'Error occurred, please try again'
export const CONTACT_TOAST_SUCCESS = 'copied'
export const UPDATE_CONTACT_TOAST_FAILED = 'Failed to update contact, please try again'

export const DELETE_NOTE_MODAL_TITLE = 'Delete Note?'
export const DELETE_NOTE_MODAL_BODY = 'Are you sure you want to delete this note?'
export const DELETE_NOTE_MODAL_CONFIRM = 'Confirm'
export const DELETE_NOTE_MODAL_RECONSIDER = 'Reconsider'
export const DELETE_NOTE_MODAL_DELETE = 'Delete'
export const DELETE_NOTE_MODAL_CANCEL = 'Cancel'

export const NAME_PANEL_SAVE_BUTTON = 'NAME_PANEL_SAVE_BUTTON'
export const EDIT_ADDRESS_PANEL_SAVE_BUTTON = 'EDIT_ADDRESS_PANEL_SAVE_BUTTON'
export const DATE_PANEL_SAVE_BUTTON = 'DATE_PANEL_SAVE_BUTTON'

export const DELETE_TASK_MODAL_TITLE = 'Delete Task?'
export const DELETE_TASK_MODAL_BODY = 'Are you sure you want to delete this task?'
export const DELETE_TASK_MODAL_CONFIRM = 'Confirm'
export const DELETE_TASK_MODAL_RECONSIDER = 'Reconsider'
export const DELETE_TASK_MODAL_DELETE = 'Delete'
export const DELETE_TASK_MODAL_CANCEL = 'Cancel'

export const MARK_TASK_DONE_TOAST_SUCCESS = 'Task Done'
export const MARK_TASK_DONE_TOAST_FAILURE = 'Error occurred, please try again'

export const CONTACT_DETAIL_COLLAPSE_STATE = 'contact_detail_collapse_state'
export const COLLAPSABLE_SECTION = {
  ABOUT_THIS_CONTACT: 'about_this_contact',
  ADDRESS: 'address',
  CAREER: 'career',
  CLIENT_INFORMATION: 'client_information',
  DOCUMENT: 'document',
}

export const INPUT_FIELD_VALUE = {
  MONTHLY_INCOME: 'monthlySalary',
  MOBILE_NUMBER: 'mobileNumber',
  WORK_STATUS: 'workStatus',
}
