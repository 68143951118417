import styled from "styled-components"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import { COLOR } from "styles/settings"

export const OfferWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Source Sans Pro, sans-serif;
  gap: 30px;

  @media (max-width: 1281px) {
    flex-direction: column;
    gap: 12px;
  }

  * {
    font-family: Source Sans Pro, sans-serif;
  }
`
export const OfferDetail = styled.div`
  width: 100%;

  @media (max-width: 1281px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const OfferButton = styled(BlueGradientButton)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  border-radius: 20px;
  padding: 9px 25px;
  height: 40px;
  width: 100%;

  @media (max-width: 1281px) {
    width: auto;
  }
`

export const StrikeThroughText = styled.span`
  display: block;
  position: relative;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${COLOR.black};
    transform: rotate(-7deg);
  }
`

export const PriceTagContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin: 5px 0px;
`

export const FeatureTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const ProPackageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 5px;
`

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;

  i {
    color: ${COLOR.cyanBlue};
    font-size: 24px;
  }
`
export const FeatureItemWrapper = styled.ul`
  ${FeatureItem}:not(:last-child) {
    margin-bottom: 30px;
  }

  margin: 0px;
`

export const ContentWrapper = styled.div`
  margin-top: 20px;
`
