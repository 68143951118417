import { object } from 'yup'

import { validateLength, validateEmail, validateContactNumber } from 'legacy/constants/validations'

export const schema = ({
  email = '',
  firstName = '',
  lastName = '',
  middleName = '',
  mobileNumber = '',
} = {}) => ({
  mapper: {
    email,
    firstName,
    lastName,
    middleName,
    mobileNumber,
  },
  validation: object().shape({
    email: validateEmail(true),
    firstName: validateLength(false, 1, 50),
    lastName: validateLength(false, 1, 50),
    middleName: validateLength(false, 1, 50),
    mobileNumber: validateContactNumber(false),
  }),
})
