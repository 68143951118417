export const E_SIGN_CONSENT_MODAL = {
  TITLE: "E-Sign and E-Delivery Consent",
  CONTENT:
    "I agree to conduct transactions electronically. I certify that I have access to an internet connection and an electronic device with a modern browser, equipped with ability to view PDF documents or other electronic attachments, and that I have access to an email account through which I can view, download, and retain these documents as necessary. I consent to the use of electronic signatures in connection with this Agreement and to receive all notices and copies of documents, including those that are legally required to be delivered to me in writing at the email address I have provided to Property, which I will keep updated. I also understand that I may withdraw this consent at any time by sending an email to <b>optout@rentspree.com</b> with the subject line <b>“Opt-Out.”</b> Doing so will mean that the Property will send me paper copies of documents and conduct transactions in writing from thereon with respect to this transaction. I will otherwise print documents you send me if I want paper copies or contact the Property to obtain paper copies.",
}

export const SIGNING_BTN = {
  SAVE: "Finish Signing",
  BACK: "Back to dashboard",
}
