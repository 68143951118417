import React from 'react'

import { PinLocationIcon } from '../pin-location-icon'

import { AutocompleteItemDiv, AutocompleteItemText, Icon } from './styles'

export const AutocompleteItem = ({
  id,
  option,
  isHighlight,
  onSelectOption,
  handleHover,
  isShowIcon = true,
}) => (
  <AutocompleteItemDiv
    id={id}
    data-testid="div-autocomplete-item"
    isHighlight={isHighlight}
    key={option.key}
    onMouseDown={() => onSelectOption(option)}
    onTouchStart={handleHover}
    onMouseEnter={handleHover}
  >
    {isShowIcon && (
      <Icon>
        <PinLocationIcon />
      </Icon>
    )}
    <AutocompleteItemText>{option.displayedAddress}</AutocompleteItemText>
  </AutocompleteItemDiv>
)
