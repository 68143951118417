import React from "react"
import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"
import styled from "styled-components"
import {
  S24,
  S16,
  B16,
  BlueGradientButton,
  Button,
} from "@rentspree/component-v2"

import { COLOR } from "components/constants"

export const ContentWrapper = styled.div`
  padding: 15px 20px;
  border: 1px solid ${COLOR.newGrey};
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${COLOR.bgGrey};
  margin-bottom: 20px;
`
export const DescriptionWrapper = styled.div`
  padding: 5px 0px 20px 0px;
  b {
    font-weight: 600;
  }
`
export const ModalBodyWrapper = styled.div`
  font-family: "Source Sans Pro";
  display: flex;
  flex-direction: column;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : "row-reverse")};
  justify-content: center;
  button {
    margin: 5px;
  }
`
const ConfirmModal = ({
  id,
  isOpen,
  title,
  handleClickBtn,
  confirmTxt,
  cancelTxt,
  descText,
  isLoading,
  onClose,
  content,
  handleClickCancelBtn,
  buttonDirection
}) => (
  <RentSpreeBaseModal
    id={id}
    isConfirm
    isOpen={isOpen}
    onClose={onClose}
    closeButton
    shouldCloseOnOverlayClick={false}
    modalSize="small"
    mHeight="50%">
    <ModalBodyWrapper>
      <S24 margin="0px 0px 10px 0px" mMargin="0px 15px 10px 0px" mSize="24px">
        {title}
      </S24>
      <DescriptionWrapper>
        <B16 margin="0px" mMargin="0px">
          {descText}
        </B16>
      </DescriptionWrapper>
      <ContentWrapper>
        <S16>{content}</S16>
      </ContentWrapper>
      <ButtonWrapper flexDirection={buttonDirection}>
        <BlueGradientButton
          text={confirmTxt}
          onClick={handleClickBtn}
          loading={isLoading}
          small
          semiBold
          height="35px"
          id="smBtn"
          fontSize="14px"
          width="100%"
        />

        <Button
          google
          small
          semiBold
          height="35px"
          fontSize="14px"
          id="CancelVoidBtn"
          text={cancelTxt || "Cancel"}
          type="button"
          disabled={isLoading}
          onClick={handleClickCancelBtn}
          width="100%"
        />
      </ButtonWrapper>
    </ModalBodyWrapper>
  </RentSpreeBaseModal>
)

export default ConfirmModal
