// ToDo: migrate to use components/shared/modal
import React from "react"
import { Modal } from "react-bootstrap"
import * as Styled from "./modal-style"
import "./modal.scss"

export const ModalDefault = props => (
  <Modal
    className="rentspreeModal"
    show={props.showModal}
    backdrop={props.backdrop}
    onHide={props.closeModal}
    autoFocus
    animation
    {...props}>
    {props.stickyCloseButton && (
      <Styled.StickyWrapper>
        <Styled.CloseButtonWrapper onClick={props.closeModal}>
          <i className="fas fa-times" />
        </Styled.CloseButtonWrapper>
      </Styled.StickyWrapper>
    )}
    <Modal.Header closeButton={props.closeButton || false}>
      {props.splash && <img src={props.splash} alt="rentspree modal splash" />}
      {props.header && <h3>{props.header}</h3>}
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
    {props.footer && <Modal.Footer>{props.footer}</Modal.Footer>}
  </Modal>
)

export const ModalActions = ({ children }) => (
  <Styled.ModalActions>{children}</Styled.ModalActions>
)

export const ModalHeader = ({ children }) => (
  <Styled.ModalHeader>{children}</Styled.ModalHeader>
)

export const GettingStartedModalActions = ({ children }) => (
  <Styled.GettingStartedModalActions>
    {children}
  </Styled.GettingStartedModalActions>
)
