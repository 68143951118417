import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import isNumber from "lodash/isNumber"
import max from "lodash/max"
import map from "lodash/map"
import sortBy from "lodash/sortBy"
import last from "lodash/last"
import { UNREADS_MAX, UNREADS_MAX_TEXT } from "./constants"

export const getLatestConversationId = conversations => {
  if (isEmpty(conversations)) return null
  const conversationWithLatestMessage = map(conversations, conversation => ({
    id: conversation.id,
    lastMessage: max([
      get(conversation, "lastMessage.createdAt"),
      get(conversation, "createdAt"),
    ]),
  }))
  const sortedConversation = sortBy(conversationWithLatestMessage, [
    "lastMessage",
  ])
  const latestConversationId = get(last(sortedConversation), "id", null)
  return latestConversationId
}

export const createTalkJSInstance = (SomeClass, ...args) =>
  new SomeClass(...args)

export const paresUnreadText = totalUnreads => {
  if (totalUnreads === 0 || !isNumber(totalUnreads)) return ""

  if (totalUnreads > UNREADS_MAX) return UNREADS_MAX_TEXT

  return totalUnreads.toString()
}
