import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"

import { getProperty, clearProperty } from "legacy/actions/property.action"
import { selectProperty, selectIsPropertyFetching } from "../listing/selectors"
import { selectIsLoading, selectIsError, makeSelectError } from "./selectors"
import reducer from "./reducer"
import * as actions from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  property: selectProperty,
  isLoading: selectIsLoading(),
  isPropertyFetching: selectIsPropertyFetching,
  isError: selectIsError(),
  error: makeSelectError(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getProperty,
        clearProperty,
        ...actions,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "propertyType", reducer })
export const withSaga = injectSaga({ key: "propertyType", saga })
