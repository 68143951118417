import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import {
  CREATE_LISTING_SYNDICATION_API,
  DISABLE_LISTING_SYNDICATION_API,
  ENABLE_LISTING_SYNDICATION_API,
  GET_LISTING_SYNDICATION_API,
  UPDATE_CONTACT_DETAILS_API,
} from 'constants/route'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { apiInstance } from 'utils/api-interceptor'

import tracker from 'tracker'
import { LISTING_SYNDICATION_EVENT } from 'tracker/const'
import {
  createListingSyndicationActions,
  disableListingSyndicationActions,
  duplicateListingSyndicationAddress,
  enableListingSyndicationActions,
  getListingSyndicationActions,
  invalidListingSyndicationAddress,
  updateContactDetailsActions,
} from './actions'
import {
  BLOCK_ENABLE_INVALID_CONTACT_LISTING_SYNDICATION_TOAST,
  CREATE_LISTING_SYNDICATION_FAILURE,
  CREATE_LISTING_SYNDICATION_SUCCESS,
  DISABLE_LISTING_SYNDICATION_FAILURE,
  DISABLE_LISTING_SYNDICATION_SUCCESS,
  DISABLE_LISTING_SYNDICATION_SUCCESS_TOAST,
  ENABLE_LISTING_SYNDICATION_FAILURE,
  ENABLE_LISTING_SYNDICATION_SUCCESS,
  ENABLE_LISTING_SYNDICATION_SUCCESS_TOAST,
  ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_DUPLICATED_ADDRESS_TOAST,
  ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_FAILURE_TOAST,
  ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_INVALID_ADDRESS_TOAST,
  GET_LISTING_SYNDICATION_CALL,
  LISTING_SYNDICATION_ERROR_CODE,
  LISTING_SYNDICATION_SOMETHING_WENT_WRONG_FAILURE_TOAST,
  LISTING_SYNDICATION_STATUS,
  TOGGLE_LISTING_SYNDICATION_CALL,
  TOGGLE_LISTING_SYNDICATION_ON_PROPERTY_OVERVIEW_CALL,
  UPDATE_CONTACT_DETAILS_CALL,
  UPDATE_CONTACT_DETAILS_FAILURE,
} from './constants'

import { addToast } from '../../toast/actions'
import { selectListingSyndication } from './selectors'
import { selectPropertyListing } from '../../listing/selectors'
import { createListingLink } from '../../overview/constants'

export function* watchGetListingSyndication() {
  yield takeLatest(GET_LISTING_SYNDICATION_CALL, getListingSyndicationSaga)
}
export function* getListingSyndicationSaga({ payload }) {
  const { propertyId } = payload
  yield put(getListingSyndicationActions.request())
  try {
    const response = yield call(callGetListingSyndicationApi, { propertyId })
    yield put(getListingSyndicationActions.success(response?.result))
  } catch (err) {
    yield put(getListingSyndicationActions.failure(err.data))
  }
}
export const callGetListingSyndicationApi = ({ propertyId }) =>
  apiInstance.get(
    buildPath(GET_LISTING_SYNDICATION_API, {
      propertyId,
    }),
  )

export function* watchToggleListingSyndication() {
  yield takeLatest(TOGGLE_LISTING_SYNDICATION_CALL, toggleListingSyndicationSaga)
}
export function* toggleListingSyndicationSaga({ payload }) {
  const { propertyId } = payload
  const listingSyndication = yield select(selectListingSyndication)
  if (listingSyndication.status === LISTING_SYNDICATION_STATUS.DISABLED) {
    const isValidContactDetails =
      !!listingSyndication?.contactDetails?.firstName &&
      !!listingSyndication?.contactDetails?.lastName
    if (isValidContactDetails) {
      yield put(enableListingSyndicationActions.request())
      try {
        const response = yield call(callEnableListingSyndicationApi, { propertyId })
        yield put(enableListingSyndicationActions.success(response?.result))
        yield call(
          [tracker, 'trackEvent'],
          LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_ON_SYNDICATION_SUCCESS,
          { property_id: propertyId },
        )
      } catch (err) {
        yield put(enableListingSyndicationActions.failure(err))
        yield call(
          [tracker, 'trackEvent'],
          LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_ON_SYNDICATION_FAIL,
          { property_id: propertyId, fail_code: err?.data?.code || 'UNEXPECTED_ERROR' },
        )
      }
    } else {
      yield put(addToast(BLOCK_ENABLE_INVALID_CONTACT_LISTING_SYNDICATION_TOAST))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_ON_SYNDICATION_FAIL,
        { property_id: propertyId, fail_code: 'CONTACT_MISSING' },
      )
    }
  } else if (listingSyndication.status === LISTING_SYNDICATION_STATUS.ENABLED) {
    yield put(disableListingSyndicationActions.request())
    try {
      const response = yield call(callDisableListingSyndicationApi, { propertyId })
      yield put(disableListingSyndicationActions.success(response?.result))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_OFF_SYNDICATION_SUCCESS,
        { property_id: propertyId },
      )
    } catch (err) {
      yield put(disableListingSyndicationActions.failure(err))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_OFF_SYNDICATION_FAIL,
        { property_id: propertyId, fail_code: err?.data?.code || 'UNEXPECTED_ERROR' },
      )
    }
  }
}

export function* watchToggleListingSyndicationOnPropertyOverview() {
  yield takeLatest(
    TOGGLE_LISTING_SYNDICATION_ON_PROPERTY_OVERVIEW_CALL,
    toggleListingSyndicationOnPropertyOverviewSaga,
  )
}
// NOTE: We reused the create listing syndication endpoint to bypass validating contact information
export function* toggleListingSyndicationOnPropertyOverviewSaga({ payload }) {
  const { propertyId } = payload
  const listingSyndication = yield select(selectListingSyndication)
  if (!listingSyndication.listingId) {
    const listing = yield select(selectPropertyListing)
    yield put(push(buildPath(createListingLink(listing), { propertyId })))
    return
  }
  if (listingSyndication.status === LISTING_SYNDICATION_STATUS.DISABLED) {
    yield put(createListingSyndicationActions.request())
    try {
      const response = yield call(callCreateListingSyndicationApi, { propertyId })
      yield put(createListingSyndicationActions.success(response?.result))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_ON_SYNDICATION_SUCCESS,
        { property_id: propertyId },
      )
    } catch (err) {
      yield put(createListingSyndicationActions.failure(err))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_ON_SYNDICATION_FAIL,
        { property_id: propertyId, fail_code: err?.data?.code || 'UNEXPECTED_ERROR' },
      )
    }
  } else if (listingSyndication.status === LISTING_SYNDICATION_STATUS.ENABLED) {
    yield put(disableListingSyndicationActions.request())
    try {
      const response = yield call(callDisableListingSyndicationApi, { propertyId })
      yield put(disableListingSyndicationActions.success(response?.result))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_OFF_SYNDICATION_SUCCESS,
        { property_id: propertyId },
      )
    } catch (err) {
      yield put(disableListingSyndicationActions.failure(err))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.TURN_OFF_SYNDICATION_FAIL,
        { property_id: propertyId, fail_code: err?.data?.code || 'UNEXPECTED_ERROR' },
      )
    }
  }
}

export const callCreateListingSyndicationApi = ({ propertyId }) =>
  apiInstance.post(
    buildPath(CREATE_LISTING_SYNDICATION_API, {
      propertyId,
    }),
  )
export const callEnableListingSyndicationApi = ({ propertyId }) =>
  apiInstance.post(
    buildPath(ENABLE_LISTING_SYNDICATION_API, {
      propertyId,
    }),
  )
export const callDisableListingSyndicationApi = ({ propertyId }) =>
  apiInstance.post(
    buildPath(DISABLE_LISTING_SYNDICATION_API, {
      propertyId,
    }),
  )

export function* watchEnableListingSyndicationSuccess() {
  yield takeLatest(ENABLE_LISTING_SYNDICATION_SUCCESS, enableListingSyndicationSuccessSaga)
}
export function* watchCreateListingSyndicationSuccess() {
  yield takeLatest(CREATE_LISTING_SYNDICATION_SUCCESS, enableListingSyndicationSuccessSaga)
}
export function* enableListingSyndicationSuccessSaga() {
  yield put(addToast(ENABLE_LISTING_SYNDICATION_SUCCESS_TOAST))
}

export function* watchEnableListingSyndicationFailure() {
  yield takeLatest(ENABLE_LISTING_SYNDICATION_FAILURE, enableListingSyndicationFailureSaga)
}
export function* watchCreateListingSyndicationFailure() {
  yield takeLatest(CREATE_LISTING_SYNDICATION_FAILURE, enableListingSyndicationFailureSaga)
}
export function* enableListingSyndicationFailureSaga(error) {
  if (error?.payload?.status >= 500) {
    yield put(addToast(LISTING_SYNDICATION_SOMETHING_WENT_WRONG_FAILURE_TOAST))
  } else {
    if (error?.payload?.status === 422) {
      if (error?.payload?.data?.code === LISTING_SYNDICATION_ERROR_CODE.INVALID_ADDRESS) {
        yield put(invalidListingSyndicationAddress())
        yield put(addToast(ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_INVALID_ADDRESS_TOAST))
        return
      }
      if (error?.payload?.data?.code === LISTING_SYNDICATION_ERROR_CODE.DUPLICATE_ADDRESS) {
        yield put(duplicateListingSyndicationAddress())
        yield put(addToast(ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_DUPLICATED_ADDRESS_TOAST))
        return
      }
    }
    yield put(addToast(ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_FAILURE_TOAST))
  }
}

export function* watchDisableListingSyndicationSuccess() {
  yield takeLatest(DISABLE_LISTING_SYNDICATION_SUCCESS, disableListingSyndicationSuccessSaga)
}
export function* disableListingSyndicationSuccessSaga() {
  yield put(addToast(DISABLE_LISTING_SYNDICATION_SUCCESS_TOAST))
}

export function* watchDisableListingSyndicationFailure() {
  yield takeLatest(DISABLE_LISTING_SYNDICATION_FAILURE, disableListingSyndicationFailureSaga)
}
export function* disableListingSyndicationFailureSaga() {
  yield put(addToast(LISTING_SYNDICATION_SOMETHING_WENT_WRONG_FAILURE_TOAST))
}

export function* watchUpdateContactDetails() {
  yield takeLatest(UPDATE_CONTACT_DETAILS_CALL, updateContactDetailsSaga)
}
export function* updateContactDetailsSaga({ payload }) {
  const { propertyId, contactDetails } = payload
  yield put(updateContactDetailsActions.request())
  try {
    const response = yield call(callUpdateContactDetailsApi, { propertyId, contactDetails })

    yield put(updateContactDetailsActions.success(response?.result))
    yield call(
      [tracker, 'trackEvent'],
      LISTING_SYNDICATION_EVENT.EVENT_NAME.SAVE_SYNDICATION_CONTACT_SUCCESS,
      { property_id: propertyId },
    )
  } catch (err) {
    yield put(updateContactDetailsActions.failure())
    yield call(
      [tracker, 'trackEvent'],
      LISTING_SYNDICATION_EVENT.EVENT_NAME.SAVE_SYNDICATION_CONTACT_FAIL,
      { property_id: propertyId, fail_code: err?.data?.code || 'UNEXPECTED_ERROR' },
    )
  }
}
export const callUpdateContactDetailsApi = ({ propertyId, contactDetails }) =>
  apiInstance.put(
    buildPath(UPDATE_CONTACT_DETAILS_API, {
      propertyId,
    }),
    contactDetails,
  )

export function* watchUpdateContactDetailsFailure() {
  yield takeLatest(UPDATE_CONTACT_DETAILS_FAILURE, updateContactDetailsFailureSaga)
}
export function* updateContactDetailsFailureSaga() {
  yield put(addToast(LISTING_SYNDICATION_SOMETHING_WENT_WRONG_FAILURE_TOAST))
}

export function* rootSaga() {
  yield all([
    watchGetListingSyndication(),
    watchToggleListingSyndication(),
    watchEnableListingSyndicationSuccess(),
    watchEnableListingSyndicationFailure(),
    watchDisableListingSyndicationSuccess(),
    watchDisableListingSyndicationFailure(),
    watchToggleListingSyndicationOnPropertyOverview(),
    watchCreateListingSyndicationSuccess(),
    watchCreateListingSyndicationFailure(),
    watchUpdateContactDetails(),
    watchUpdateContactDetailsFailure(),
  ])
}

export default rootSaga
