import React from 'react'
import getDurationFromNow from 'legacy/helpers/moment-from-now'
import { I } from 'components/typography'
import { docStatus } from '../../../../../legacy/constants/other-docs-consts'
import { Text } from '../../../../../legacy/components/typography'
import { DEFAULT_STATUS_COLOR } from '../constants'
import { DOCUMENT_CANCEL_SOURCE } from './constants'

export const DocumentStatus = ({ doc }) => {
  let color = DEFAULT_STATUS_COLOR
  let text = ''
  const fromNow = getDurationFromNow(doc.updatedAt)
  const lastRequestedTimeElapsed = getDurationFromNow(doc.lastRequestedAt || doc.updatedAt)
  switch (doc.status) {
    case docStatus.requested:
      color = 'yellow'
      text = `Requested ${lastRequestedTimeElapsed}`
      break
    case docStatus.submitted:
      color = 'green'
      text = `Completed ${fromNow}`
      break
    case docStatus.canceled:
      color = 'placeholderGrey'
      text =
        doc.cancelSource === DOCUMENT_CANCEL_SOURCE.INCOME_VERIFICATION
          ? `Replaced by income verification report`
          : `Canceled ${fromNow}`
      break
    default:
      break
  }
  return (
    <>
      {doc.status !== docStatus.created && (
        <I color={color} rightSpace="7px" className="fas fa-circle"></I>
      )}
      <Text {...(doc.status === docStatus.canceled && { color: 'placeholderGrey' })}>{text}</Text>
    </>
  )
}
