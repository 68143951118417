/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Col from 'components/atoms/col'
import Row from 'components/atoms/row'

import styled from 'styled-components'
import CreatePropertyAddressForm from 'components/organisms/address-form'
import { scrollToFirstError } from 'utils/scroll-to-first-error'
import { useGoogleAutoComplete } from 'containers/google-autocomplete/use-google-autocomplete'
import { AddressAutocomplete } from '../address-input'
import { VALUE_CHANGED_EVENT_TYPE } from '../address-input/constants'
import { splitFullAddressPart } from './split-address-part'

const ReferenceTopAddress = styled.div`
  position: absolute;
  top: 10px;
`

export const MODE = {
  AUTO: 'auto',
  MANUAL: 'manual',
}

export const FullAddressForm = props => {
  const {
    setFieldValue,
    touched,
    errors,
    validateForm,
    onHandleAutoFilledUsedTracker,
    sessionToken,
    setSessionToken,
  } = props

  const addressTopRef = useRef(null)
  const { addresses, setAddresses, getAddressDetails, isLoading } = useGoogleAutoComplete(
    sessionToken,
    setSessionToken,
  )
  const [isSelectOption, setIsSelectOption] = useState(false)
  const [fullAddress, setFullAddress] = useState('')
  const [mode, setMode] = useState(MODE.AUTO)
  const prevIsValidatingState = useRef(false)

  useEffect(() => {
    if (prevIsValidatingState.current && !props.isValidating && !props.isValid && !isSelectOption) {
      const isAddressPartsError =
        Object.keys(errors).filter(value =>
          ['street', 'unitNumber', 'city', 'state', 'zipcode'].includes(value),
        ).length > 0
      if (isAddressPartsError) {
        props.setTouched({
          ...touched,
          street: true,
          unitNumber: true,
          city: true,
          state: true,
          zipcode: true,
        })
        setMode(MODE.MANUAL)
        scrollToFirstError(
          errors,
          ['street', 'unitNumber', 'city', 'state', 'zipcode'],
          -40,
          undefined,
          true,
        )
      }
    }
    prevIsValidatingState.current = props.isValidating
  }, [props.isValidating, props.isValid])

  useEffect(() => {
    if (mode === MODE.MANUAL) {
      scrollToFirstError(
        errors,
        ['street', 'unitNumber', 'city', 'state', 'zipcode'],
        -40,
        undefined,
        true,
      )
    }
  }, [mode])

  const options = [
    ...(isLoading
      ? [
          {
            isCustom: true,
            node: <span data-testid="loading-option">Loading...</span>,
            key: 'loading',
            disabled: true,
          },
        ]
      : addresses),
    {
      isCustom: true,
      node: (
        <span data-testid="manual-option">
          Can’t find it? <a href="#">Enter it manually</a>
        </span>
      ),
      key: MODE.MANUAL,
      value: MODE.MANUAL,
    },
  ]

  const splitFullAddressAndSetFormValue = toBeSplitFullAddress => {
    const parts = splitFullAddressPart(toBeSplitFullAddress)

    setFieldValue('street', parts.street)
    setFieldValue('unitNumber', parts.unitNumber)
    setFieldValue('city', parts.city)
    setFieldValue('state', parts.state)
    setFieldValue('zipcode', parts.zipcode)
  }

  const autofillAddress = useCallback(
    selectedOpiton => {
      if (selectedOpiton.key === MODE.MANUAL) {
        if (onHandleAutoFilledUsedTracker) {
          onHandleAutoFilledUsedTracker({ isAutoFillValue: false })
        }
        setMode(MODE.MANUAL)
        return
      }

      if (onHandleAutoFilledUsedTracker) {
        onHandleAutoFilledUsedTracker({ isAutoFillValue: true })
      }

      (async () => {
        await setFieldValue('street', selectedOpiton.streetLine)
        await setFieldValue('unitNumber', selectedOpiton.secondary)
        await setFieldValue('city', selectedOpiton.city)
        await setFieldValue('state', selectedOpiton.state)
        await setFieldValue('zipcode', selectedOpiton.zipcode)
        await validateForm()
      })()
    },
    [setFieldValue],
  )

  const onValueChange = (val, event) => {
    if (val === MODE.MANUAL) {
      setIsSelectOption(false)
      splitFullAddressAndSetFormValue(fullAddress)
      return
    }

    if (event.type === VALUE_CHANGED_EVENT_TYPE.SELECT) {
      setIsSelectOption(true)
    } else if (event.type === VALUE_CHANGED_EVENT_TYPE.CHANGE) {
      setIsSelectOption(false)
      onHandleAutoFilledUsedTracker({ isAutoFillValue: false })
    }

    setFullAddress(() => val)
  }

  const onBlur = () => {
    if (!isSelectOption) {
      splitFullAddressAndSetFormValue(fullAddress)
    }
  }

  return mode === MODE.AUTO ? (
    <form>
      <Row wrap>
        <Col full margin="0 0 5px" mMargin="0 0 15px">
          <ReferenceTopAddress
            id="ref-address"
            data-testid="ref-full-address-div"
            ref={addressTopRef}
          />
          <AddressAutocomplete
            id="dashboardNewPropertyFullAddress"
            data-testid="full-address-form-input"
            className="newPropertyInput"
            name="fullAddress"
            label="Address"
            onValueChange={onValueChange}
            onDebounceChange={val => setAddresses(val)}
            onBlur={onBlur}
            onSelectOption={autofillAddress}
            error={touched.street && errors.street}
            options={options}
            fullAddressMode
            isRequired
            getAddressDetails={getAddressDetails}
          />
        </Col>
      </Row>
    </form>
  ) : (
    <CreatePropertyAddressForm {...props} />
  )
}
