import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'

import { convert } from 'helpers/convert'
import {
  GapContent,
  RentspreeFooterLine,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { breakpoints } from 'styles/settings'
import { RentspreeFooter } from '../screening-reports/share/responsive-doc-components'

const Bold = styled.span`
  font-weight: 600;
`

const GapContentMobileReverse = styled(GapContent)`
  ${breakpoints.mobileReport(`
    flex-direction: column-reverse;
    gap: 20px;
  `)}
`

export const RentSpreeLogo = styled.img`
  height: 25px;
  right: 0;
`
const FooterPanel = styled.div`
  flex: 1;
`

const Footer = ({ transUnion, rentalDetail }) => {
  const generatedOn = get(transUnion, 'generatedOn')
  const expiresOn = get(transUnion, 'expiresOn')
  const submittedAt = get(rentalDetail, 'submitted_at')
  const formatDateWithTimezone = 'MMM DD, YYYY hh:mm A z ZZ'
  return (
    <GapContentMobileReverse>
      <FooterPanel>
        <RentspreeFooter />
      </FooterPanel>
      <FooterPanel>
        <RentspreeFooterLine data-testid="coverpage-submitted-at">
          Submitted application on{' '}
          <Bold>{convert(submittedAt, 'dateOfUTC', formatDateWithTimezone)}</Bold>
        </RentspreeFooterLine>
        <RentspreeFooterLine data-testid="coverpage-generated-on">
          Screening reports generated on{' '}
          <Bold>{convert(generatedOn, 'dateOfUTC', formatDateWithTimezone)}</Bold>
        </RentspreeFooterLine>
        <RentspreeFooterLine data-testid="coverpage-expire-on">
          Screening reports expire on{' '}
          <Bold>{convert(expiresOn, 'dateOfUTC', formatDateWithTimezone)}</Bold>
        </RentspreeFooterLine>
      </FooterPanel>
    </GapContentMobileReverse>
  )
}

export default Footer
