import React from "react"
import { useDispatch } from "react-redux"
import { getSubmissionParticipantWithRentalSubmission } from "../detail/actions"

export const useGetSubmissionParticipantEffect = submissionParticipantId => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(
      getSubmissionParticipantWithRentalSubmission(submissionParticipantId),
    )
  }, [])
}
