import { DOMAIN_NAME } from "../env"

export const STORAGE = {
  USER_TOKEN: "rentspreeUserToken",

  APPLICATION_ABOUT: "rentspreeApplicationAbout",
  APPLICATION_RESIDENCE: "rentspreeApplicationResidence",
  APPLICATION_OCCUPATION: "rentspreeApplicationOccupation",
  APPLICATION_REFERENCES: "rentspreeApplicationReference",
  APPLICATION_MISC: "rentspreeApplicationMisc",

  CREDIT_REPORT_ABOUT: "rentspreeCreditReportAbout",
  CREDIT_REPORT_ID: "rentspreeCreditReportId",

  RENTAL_FORM: "rentspreeRentalForm",
  // USER_REMEMBER_ME: "rentspreeUserRememberMe",

  RENTAL_STEP_INFO: "rentspreeRentalStepInfo",
  RENTAL_STEP_APPLICATION: "rentspreeRentalStepApplication",
  RENTAL_STEP_CREDIT_REPORT: "rentspreeRentalStepCreditReport",
  RENTAL_STEP_SUBMIT: "rentspreeRentalStepSubmit",
  // RENTAL_SKIP_CREDIT_STEP: "rentalSkipCreditStep"

  REQUEST_PROPERTY: "rentspreeRequestProperty",
  ZF_DATA: "rentspreeZfData",
  // ZF_CONTEXT_ID: "rentspreeZfContextId",

  // SELECTED_PROPERTY: "rentspreeSelectedProperty",
  APPLICANTS_EMAIL: "rentspreeApplicantsEmail",
  PROPERTY_CONTENT: "rentspreePropertyContent",
  SUBDOMAIN: "rentspreeSubdomain",
  SSID: "SSID",

  AGENT_DASHBOARD_ITR1: "rentspreeAgentDashboardItr1"
}

// DOMAIN NAME HAS TO BE OMITTED FOR SHARE COOKIE TO WORK ON LOCALHOST
export const COOKIE_OPTION =
  DOMAIN_NAME === "localhost"
    ? { path: "/" }
    : { path: "/", domain: DOMAIN_NAME }
