import styled from 'styled-components'
import React from 'react'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import Span from '@rentspree/component-v2/dist/typography/span'
import propTypes from 'prop-types'
import { COLOR, ANIMATE } from '../../../styles/settings'

export const StyledDiv = styled.div`
  border-radius: 5px;
  background-color: ${COLOR.white};
  border: 2px solid ${COLOR.white};
  padding: 14px;
  display: flex;
  font-family: inherit;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out ${ANIMATE.fast};
  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }
  ${props =>
    props.fixedWidth &&
    `@media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }`}
  ${props =>
    props.checked &&
    `
    background-color: rgba(75, 151, 245, 0.08);
    border: 2px solid #4b97f5;
    box-shadow: none;
  `}
  ${props => props.fixedWidth && `width: calc(50% - 10px);`}
  ${props => props.fixedHeight && `height: 50px`};
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    &:hover{
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }
    
  `}
  ${props =>
    props.validationError &&
    `
    border-color: ${COLOR.negativeRed};
  `}
`

export const Title = styled(S16)`
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  margin-bottom: 2px;
  ${props =>
    props.validationError &&
    `
    color: ${COLOR.negativeRed};
    `}
  ${props =>
    props.disabled &&
    `
    color: ${COLOR.grayLv3};
  `}
`

export const Subtitle = styled(S16)`
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
  font-weight: normal;
  margin: 0;
`
export const Ascent = styled(Span)`
  font-size: 16px;
  font-weight: bold;
  justify-content: flex-end;
  margin-left: auto;
`

const BooleanCard = ({
  title,
  subtitle,
  checked,
  disabled,
  ascent,
  type,
  onClick,
  icon: IconComponent,
  alt,
  dataTestId,
  ...rest
}) => (
  <StyledDiv
    checked={checked}
    onClick={onClick}
    fixedWidth={ascent === undefined}
    fixedHeight={subtitle === undefined}
    disabled={disabled}
    data-testid={dataTestId}
    {...rest}
  >
    {IconComponent && <IconComponent checked={checked} alt={alt} />}
    <Span strong>
      {title && (
        <Title validationError={rest.validationError} disabled={disabled}>
          {title}
        </Title>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Span>
    {ascent && (
      <Ascent strong color={checked ? COLOR.deepBlue : COLOR.placeholderGrey}>
        {ascent}
      </Ascent>
    )}
  </StyledDiv>
)

export default BooleanCard

BooleanCard.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  ascent: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  onClick: propTypes.func,
  checked: propTypes.bool,
  icon: propTypes.func,
}

BooleanCard.defaultProps = {
  checked: false,
}
