import React from "react"
import classnames from "classnames/bind"
import styles from "./hubspot-form.scss"
import { isIOS } from "../helper/ua-parser-js"

const c = classnames.bind(styles)

export default class HubspotForm extends React.Component {
  componentDidMount() {
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }))
    this.addFunctionForWindow()
    const { formId, portalId, id, css, inlineMessage } = this.props
    const script = document.createElement("script")
    const timeout = this.props.submitDelay || 5000
    script.innerHTML = `hbspt.forms.create({  
      css: "${css}", 
      portalId: "${portalId}", 
      formId: "${formId}",
      target: "#${id}",
      onFormSubmitted: function(form){ 
        setTimeout(function() {
           window.handleOnSubmittedHubspot(${this.props.position})
        }, ${timeout})
      },
      ${
        isIOS() && this.props.redirectPath
          ? `redirectUrl: "${this.props.redirectPath}?to=oldDashboard",`
          : ""
      }
      onFormReady: function($form) {
        console.log("form", $form); 
        window.handleOnFormReadyHubspot() 
      },
      ${
        isIOS && this.props.redirectPath
          ? `redirectUrl: "${this.props.redirectPath}?to=oldDashboard",`
          : ""
      }
      ${inlineMessage ? `inlineMessage: "${inlineMessage}"` : ""}
    });`

    document.body.appendChild(script)
  }

  addFunctionForWindow() {
    window.handleOnSubmittedHubspot = this.props.onFormSubmitted
      ? this.props.onFormSubmitted.bind(this)
      : Function
    window.handleOnFormReadyHubspot = this.props.onFormReady
      ? this.props.onFormReady.bind(this)
      : Function
  }

  render() {
    const { id, classForm, classContent } = this.props
    return (
      <div className={c("hubspot-content", classContent)}>
        <div className={c("form", classForm)}>
          <div id={id} className="form-content-hubspot" />
        </div>
      </div>
    )
  }
}
