import { buildPath } from "@rentspree/path"
import { REFERENCES_API } from "constants/route"
import checkStatus from "./handlers/check-status"
import fetch from "../helpers/fetch-utils"

export const ofRequestSend = (rentalId, refChecks) => bearer =>
  fetch(buildPath(REFERENCES_API, { rentalId }), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(refChecks),
  }).then(checkStatus)

export const ofGetReferences = rentalId => bearer =>
  fetch(buildPath(REFERENCES_API, { rentalId }), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
