import React, { useEffect, useMemo } from 'react'
import { compose } from 'redux'
import { useDisableFeature } from '@rentspree/feature-toggle'
import { Tab } from '@rentspree/component-2023.components.organisms.tab'
import { Tabs } from '@rentspree/component-2023.components.organisms.tabs'
import tracker from 'tracker'
import get from 'lodash/get'
import {
  getRentalPaymentNotificationCountOfProperty,
  mergeNotificationWithRentalPaymentNotificationCount,
} from 'utils/rent-payment-notification'
import { withPropertyInnerTabs } from './connect'
import {
  PROPERTY_INNER_TABS_FEATURE_TOGGLE_LIST,
  PROPERTY_INNER_TAB_LEASE_AGREEMENT,
  PROPERTY_INNER_TAB_LISTING_MANAGEMENT,
  PROPERTY_INNER_TAB_OVERVIEW,
  PROPERTY_INNER_TAB_RENT_PAYMENT,
  PROPERTY_INNER_TAB_TENANT_SCREENING,
} from './constants'

export const PropertyInnerTabs = ({
  actions,
  history,
  match,
  notification,
  rentalPaymentNotifications,
  defaultTab = 'propertyInnerTabOverview',
}) => {
  const propertyId = match.params.propertyId
  const [currentTab, setCurrentTab] = React.useState()

  const { isReady: isDisableFeatureReady, disableFeature } = useDisableFeature()

  useEffect(() => {
    const { getNotificationCall } = actions
    getNotificationCall({ propertyId })

    return () => {
      actions.clearNotification()
    }
  }, [propertyId])

  const tabList = useMemo(
    () => [
      PROPERTY_INNER_TAB_OVERVIEW(propertyId),
      PROPERTY_INNER_TAB_TENANT_SCREENING(propertyId),
      PROPERTY_INNER_TAB_LISTING_MANAGEMENT(propertyId),
      PROPERTY_INNER_TAB_LEASE_AGREEMENT(propertyId),
      PROPERTY_INNER_TAB_RENT_PAYMENT(propertyId),
    ],
    [propertyId],
  )

  const handleClickTab = ({ id, path, event }) => {
    setCurrentTab(id)

    if (event) {
      tracker.trackEvent(event.name, event.properties)
    }

    if (path) {
      history.push(path)
    }
  }

  const rentalPaymentNotificationCount = getRentalPaymentNotificationCountOfProperty({
    propertyId,
    rentalPaymentNotifications,
  })
  const newNotification = mergeNotificationWithRentalPaymentNotificationCount({
    notification,
    rentalPaymentNotificationCount,
  })

  return (
    <Tabs value={currentTab ?? defaultTab} variant="scrollable">
      {tabList
        .filter(
          ({ featureId }) =>
            !PROPERTY_INNER_TABS_FEATURE_TOGGLE_LIST.includes(featureId) ||
            !isDisableFeatureReady ||
            !disableFeature ||
            disableFeature.isFeatureEnabled(featureId),
        )
        .map(({ id, text, path, event, notificationKey }) => (
          <Tab
            key={id}
            value={id}
            label={text}
            onClick={() => handleClickTab({ id, path, event })}
            badgeProps={{
              variant: 'dot',
              invisible: !get(newNotification, [notificationKey, 'unopen']),
            }}
          />
        ))}
    </Tabs>
  )
}

export default compose(withPropertyInnerTabs)(PropertyInnerTabs)
