import { takeLatest, put, call, all } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import { SINGLE_APPLICATION, TENANT_SCREENING_WITH_RENTAL_ID } from 'constants/route'
import { apiInstance } from '../../utils/api-interceptor'
import { completeCreditReportAPI, cancelCreditReportAPI } from './actions'
import { TYPES, API_COMPLETE_CREDIT_REPORT, API_CANCEL_CREDIT_REPORT } from './constants'

export const callCompleteCreditReportAPI = async rentalId =>
  new Promise(resolve =>
    setTimeout(
      () => resolve(apiInstance.post(buildPath(API_COMPLETE_CREDIT_REPORT, { rentalId }))),
      1000,
    ),
  )

export const callCancelCreditReportAPI = rentalId =>
  apiInstance.post(buildPath(API_CANCEL_CREDIT_REPORT, { rentalId }))

export function* completeCreditReport({ payload }) {
  const { rentalAppId, propertyId } = payload
  yield put(completeCreditReportAPI.request())
  try {
    let completedCreditReport
    let i = 1
    const MAX_RETRY = 10
    while (i <= MAX_RETRY) {
      const response = yield call(callCompleteCreditReportAPI, rentalAppId)
      if (response?.status === 'completed') {
        completedCreditReport = response
        i = MAX_RETRY + 1
      }
      i += 1
    }
    if (completedCreditReport) {
      yield put(completeCreditReportAPI.success(completedCreditReport))
    } else {
      console.error('complete credit report failed: MAX_RETRY reached')
      yield put(completeCreditReportAPI.failure())
    }
    if (propertyId) {
      yield put(
        push(
          buildPath(SINGLE_APPLICATION, {
            propertyId,
            rentalAppId,
          }),
        ),
      )
    } else {
      yield put(
        push(
          buildPath(TENANT_SCREENING_WITH_RENTAL_ID, {
            rentalAppId,
          }),
        ),
      )
    }
  } catch (err) {
    console.error('complete credit report failed with error ========>', err)
    yield put(completeCreditReportAPI.failure(err))
    if (propertyId) {
      yield put(
        push(
          buildPath(SINGLE_APPLICATION, {
            propertyId,
            rentalAppId,
          }),
        ),
      )
    } else {
      yield put(
        push(
          buildPath(TENANT_SCREENING_WITH_RENTAL_ID, {
            rentalAppId,
          }),
        ),
      )
    }
  }
}

export function* cancelCreditReportPayment({ payload }) {
  const { rentalAppId, propertyId } = payload
  yield put(cancelCreditReportAPI.request())
  try {
    const response = yield call(callCancelCreditReportAPI, rentalAppId)
    yield put(cancelCreditReportAPI.success(response))
    if (propertyId) {
      yield put(
        push(
          buildPath(SINGLE_APPLICATION, {
            propertyId,
            rentalAppId,
          }),
        ),
      )
    } else {
      yield put(
        push(
          buildPath(TENANT_SCREENING_WITH_RENTAL_ID, {
            rentalAppId,
          }),
        ),
      )
    }
  } catch (err) {
    yield put(cancelCreditReportAPI.failure(err))
    if (propertyId) {
      yield put(
        push(
          buildPath(SINGLE_APPLICATION, {
            propertyId,
            rentalAppId,
          }),
        ),
      )
    } else {
      yield put(
        push(
          buildPath(TENANT_SCREENING_WITH_RENTAL_ID, {
            rentalAppId,
          }),
        ),
      )
    }
  }
}

export function* watchCompleteCreditReportPayment() {
  yield takeLatest(TYPES.CALL_COMPLETE_CREDIT_REPORT, completeCreditReport)
}

export function* watchCancelCreditReportPayment() {
  yield takeLatest(TYPES.CALL_CANCEL_CREDIT_REPORT, cancelCreditReportPayment)
}

export function* rootSaga() {
  yield all([watchCompleteCreditReportPayment(), watchCancelCreditReportPayment()])
}

export default rootSaga
