import { buildPath, query } from "@rentspree/path"
import React, { useEffect, useState } from "react"
import { Grid } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import {
  FILES_URL,
  POLLING_DELAY,
  POLLING_MAX_COUNT,
  POLLING_SHIFT_COUNT,
} from "env"
import { LoadingPDF } from "legacy/components/download-pdf"
import mapWordingTrack from "legacy/helpers/map-wording-track"
import tracker from "legacy/tracker"
import { EVENT_REPORT } from "legacy/tracker/const"
import { apiInstance } from "utils/api-interceptor"
import {
  GENERATE_REPORT_FILES,
  POLLING_REPORT_FILES_STATUS,
} from "legacy/constants/route-consts"

import { useInterval } from "./use-interval"

const ReportPollingDownload = props => {
  const { location, match } = props
  const { search } = location
  const { reportType } = match.params
  const [processData, setProcessData] = useState({})
  const [polling, setPolling] = useState({})
  const [isError, setIsError] = useState(false)

  const queryParams = query.parse(search)
  const { rentalSubmissionId, propertyId, appType, envelopeId } = queryParams

  const generateReports = async () => {
    try {
      const response = await apiInstance.post(GENERATE_REPORT_FILES, {
        rentalSubmissionId,
        reportType,
        propertyId,
        appType,
        envelopeId,
      })
      setProcessData(response)
      setPolling({ delay: POLLING_DELAY, count: 0 })
    } catch (err) {
      setIsError(true)
    }
  }

  useEffect(() => {
    if (reportType === "all-report") {
      setIsError(false)
      generateReports()
    }
  }, [reportType, rentalSubmissionId])

  useEffect(() => {
    if (isError || reportType !== "all-report") {
      setTimeout(() => {
        window.location.replace("/not-found")
      }, 500)
    }
  }, [reportType, isError])

  useEffect(() => {
    switch (processData.status) {
      case "error":
        setIsError(true)
        break
      case "finished":
        tracker.trackEvent(EVENT_REPORT.saveReportPDF, {
          rental_id: rentalSubmissionId,
          applicant: decodeURI(queryParams.applicant),
          click_from: queryParams.click_from,
          report_name: mapWordingTrack(reportType),
          page: queryParams.page,
        })
        setTimeout(() => {
          window.location.replace(`${FILES_URL}${processData.url}`)
        }, 500)
        break
      default:
        break
    }
  }, [processData.status])

  useInterval(async () => {
    let response = processData
    try {
      response = await apiInstance.get(
        buildPath(POLLING_REPORT_FILES_STATUS, {
          transactionId: processData.tid,
        }),
      )
      setProcessData(response)
    } catch (err) {
      //
    }

    let newDelay = polling.delay
    if (
      response.status === "finished" ||
      response.status === "error" ||
      polling.count === POLLING_MAX_COUNT - 1
    ) {
      newDelay = null
    } else if (
      polling.count > 0 &&
      (polling.count + 1) % POLLING_SHIFT_COUNT === 0
    ) {
      newDelay += POLLING_DELAY
    }
    setPolling(prevPolling => ({
      delay: newDelay,
      count: prevPolling.count + 1,
    }))
  }, polling.delay)

  useEffect(() => {
    if (polling.count === POLLING_MAX_COUNT) {
      setIsError(true)
    }
  }, [polling.count])

  return (
    <Grid>
      <div>
        <LoadingPDF />
      </div>
    </Grid>
  )
}

export default withRouter(props => <ReportPollingDownload {...props} />)
