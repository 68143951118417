import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  selectApplicant,
  selectCreditReportDetail,
} from "../screening-reports/selectors"
import {
  selectApplicationDetail,
  selectRentalDetail,
  selectIsAppAndCreditFetching,
  selectPdfReport,
  selectIsSharedReport,
  selectShareOption,
} from "../selectors"

const mapStateToProps = createStructuredSelector({
  applicationDetail: selectApplicationDetail,
  rentalDetail: selectRentalDetail,
  isAppAndCreditFetching: selectIsAppAndCreditFetching,
  pdfReport: selectPdfReport,
  creditReportDetail: selectCreditReportDetail,
  applicant: selectApplicant,
  isSharedReport: selectIsSharedReport,
  shareOption: selectShareOption,
})

export const withConnect = connect(mapStateToProps)
