import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"

import { getProperty } from "legacy/actions/property.action"
import { selectProperty } from "containers/listing-management/selectors"
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import { sendApplyLink } from "./actions"
import reducer from "./reducer"
import saga from "./saga"
export const mapStateToProps = () =>
  createStructuredSelector({
    property: selectProperty,
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getProperty,
        sendApplyLink,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "send-apply-link", reducer })
export const withSaga = injectSaga({ key: "send-apply-link", saga })
export const withSendApplyLink = compose(
  withReducer,
  withSaga,
  withConnect,
)
