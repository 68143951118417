import styled from "styled-components"

export const StyledForm = styled.form`
  font-family: Source Sans Pro, sans-serif;
  .react-tagsinput-tag,
  .react-tagsinput-input {
    font-size: 13px;
    margin: 0px 5px 5px 0px;
    padding: 10px 13px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`
