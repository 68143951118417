import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"

import {
  getLinkAccountDataCall,
  createListingScreeningRequestCall,
  setShowCriminalDisclaimerModal,
  submitScreeningRequest,
  createListingScreeningRequestCallWithRedirect,
} from "./actions"
import {
  selectIsCarWithPartnerIDIntegrated,
  selectIsFetchingLinkAccountData,
  selectShowCriminalDisclaimerModal,
  makeSelectPermalink,
  selectIsCreatingListingScreeningRequest,
  selectIsGeneratingPermalink,
} from "./selectors"
import reducer from "./reducer"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  isCarWithPartnerIDIntegrated: selectIsCarWithPartnerIDIntegrated(),
  isFetchingLinkAccountData: selectIsFetchingLinkAccountData(),
  showCriminalDisclaimerModal: selectShowCriminalDisclaimerModal(),
  isCreatingListingScreeningRequest: selectIsCreatingListingScreeningRequest(),
  isGeneratingPermalink: selectIsGeneratingPermalink(),
  permalink: makeSelectPermalink(),
})

export function mapDispatchToProps(dispatch) {
  return {
    screeningRequestActions: bindActionCreators(
      {
        getLinkAccountDataCall,
        createListingScreeningRequestCall,
        setShowCriminalDisclaimerModal,
        submitScreeningRequest,
        createListingScreeningRequestCallWithRedirect,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withScreeningRequestPropsConnect = connect(mapStateToProps)

export const withReducer = injectReducer({ key: "screeningRequest", reducer })
export const withSaga = injectSaga({ key: "screeningRequest", saga })
