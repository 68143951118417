import { parseArguments } from "../helper/helper"

export class SizeProcessingOption {
  width

  height

  enlarge

  extend

  orderOptions = ["width", "height", "enlarge", "extend"]

  defaultValue = {
    width: undefined,
    height: undefined,
    enlarge: false,
    extend: false,
  }

  constructor({ width, height, enlarge, extend }) {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
  }

  update({ width, height, enlarge, extend }) {
    this.width = width
    this.height = height
    this.enlarge = enlarge
    this.extend = extend
    return this
  }

  parseOptions() {
    const argsOrder = this.orderOptions.map(key => this[key])
    const defaultValue = this.orderOptions.map(key => this.defaultValue[key])
    const args = parseArguments(argsOrder, defaultValue)

    return `s:${args}`
  }
}
