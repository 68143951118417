import { buildPath } from '@rentspree/path'
import { useQuery } from '@tanstack/react-query'

import { PROPERTY_API_V2 } from 'constants/route'
import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

const queryFn = () =>
  apiInstanceWithErrorHandler.get(
    buildPath(
      PROPERTY_API_V2,
      {},
      {
        page: 1,
        perPage: 100,
        filter: { status: 'active' },
      },
    ),
  )

/**
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export const useListPropertiesQuery = options =>
  useQuery({
    queryKey: ['properties'],
    queryFn,
    ...options,
  })
