import generateApiCall from "utils/generate-api-call"

export const GET_IMPORTABLE_LISTING_CALLED = "GET_IMPORTABLE_LISTING_CALLED"
export const GET_IMPORTABLE_LISTING_REQUEST = "GET_IMPORTABLE_LISTING_REQUEST"
export const GET_IMPORTABLE_LISTING_FAILED = "GET_IMPORTABLE_LISTING_FAILED"
export const GET_IMPORTABLE_LISTING_SUCCESS = "GET_IMPORTABLE_LISTING_SUCCESS"

export const IMPORT_MLS_LISTING_CALLED = "IMPORT_MLS_LISTING_CALLED"
export const IMPORT_MLS_LISTING_REQUEST = "IMPORT_MLS_LISTING_REQUEST"
export const IMPORT_MLS_LISTING_SUCCESS = "IMPORT_MLS_LISTING_SUCCESS"
export const IMPORT_MLS_LISTING_FAILED = "IMPORT_MLS_LISTING_FAILED"

export const EVENT_SOURCE_IMPORT_MLS = "import_mls"
export const EVENT_SOURCE_PROPERTY_OVERVIEW = "property_overview"
export const EVENT_OPTION_IMPORT_MLS = "import_mls"
export const EVENT_OPTION_CREATE_NEW = "create_new"

export const AUTO_IMPORT_MLS_LISTING_CALLED = "AUTO_IMPORT_MLS_LISTING_CALLED"

export const importMLSListingApiState = generateApiCall([
  IMPORT_MLS_LISTING_REQUEST,
  IMPORT_MLS_LISTING_SUCCESS,
  IMPORT_MLS_LISTING_FAILED,
])

export const getImportableListingApiState = generateApiCall([
  GET_IMPORTABLE_LISTING_REQUEST,
  GET_IMPORTABLE_LISTING_SUCCESS,
  GET_IMPORTABLE_LISTING_FAILED,
])

export const IMPORT_LISTING_STATUS = {
  IMPORTED: "IMPORTED",
  LOADING: "LOADING",
  ERROR: "ERROR",
}
