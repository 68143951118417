import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'
import multiShareSaga from './saga'
import multiShareReducer from './reducer'

export const withMultiShareSaga = injectSaga({
  key: 'multi-share',
  saga: multiShareSaga,
})

export const withMultiShareReducer = injectReducer({
  key: 'multi-share',
  reducer: multiShareReducer,
})
