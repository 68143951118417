export const TOGGLE_SHARE_REPORT_PROMPT_MODAL = 'TOGGLE_SHARE_REPORT_PROMPT_MODAL'
export const TOGGLE_SHARE_REPORT_PROMPT_MODAL_CALL = 'TOGGLE_SHARE_REPORT_PROMPT_MODAL_CALL'
export const RESET_SHARE_REPORT_PROMPT_MODAL = 'RESET_SHARE_REPORT_PROMPT_MODAL'

export const PROMPT_CONTENT = {
  title: 'Before you go, share this report with',
  description: "They'll get an email to view the documents on RentSpree.",
}

export const SHARE_PROMPT_PROPERTY_SKIP_LIST = 'share_prompt_property_skip_list'
