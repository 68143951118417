import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'

import { generateDisplayingTags } from 'components/contacts/templates/contact-dashboard-template/helpers'
import DeleteTagTemplate from 'components/contacts/templates/delete-tag-template'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { deleteTag } from '../tags/actions'
import { selectAllTags } from '../tags/selectors'

import { withConnect, withReducer } from './connect'

const {
  EVENT_NAME: {
    CLICK_DELETE_TAG_ON_MODAL,
    CONFIRM_DELETE_TAG,
    CLOSE_EDIT_TAG_MODAL,
    CLOSE_DELETE_TAG_CONFIRMATION,
    CANCEL_DELETE_TAG,
  },
} = EVENT_PEOPLE_CONCEPT

export const DeleteTagModal = ({ isOpen, isLoading, actions }) => {
  const dispatch = useDispatch()
  const [isConfirmMode, setIsConfirmMode] = React.useState(false)
  const [deletedTagId, setDeleteTagId] = React.useState(null)
  const [deletedTagName, setDeleteTagName] = React.useState('')
  const allTags = useSelector(selectAllTags)

  useEffect(() => {
    if (isOpen === false) {
      setIsConfirmMode(false)
    }
  }, [isOpen])

  const handleClickDeleteTag = tag => {
    tracker.trackEvent(CLICK_DELETE_TAG_ON_MODAL, {
      tag_name: tag?.name,
    })
    setIsConfirmMode(true)
    setDeleteTagId(tag?._id)
    setDeleteTagName(tag?.name)
  }

  const handleConfirmDeleteTag = () => {
    tracker.trackEvent(CONFIRM_DELETE_TAG, {
      tag_name: deletedTagName,
    })
    dispatch(deleteTag({ tagId: deletedTagId }))
  }

  const handleCancel = () => {
    setIsConfirmMode(false)
    actions.closeDeleteTagModal()
  }

  const handleClickClose = () => {
    tracker.trackEvent(CLOSE_EDIT_TAG_MODAL)
    handleCancel()
  }

  const handleClickCloseConfirmation = () => {
    tracker.trackEvent(CLOSE_DELETE_TAG_CONFIRMATION, {
      tag_name: deletedTagName,
    })
    handleCancel()
  }

  const handleClickCancel = () => {
    tracker.trackEvent(CANCEL_DELETE_TAG, {
      tag_name: deletedTagName,
    })
    handleCancel()
  }

  return (
    <div>
      <RentSpreeBaseModal
        isOpen={isOpen}
        overflow="visible"
        onClose={isConfirmMode ? handleClickCloseConfirmation : handleClickClose}
        modalSize="small"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <DeleteTagTemplate
          isConfirmMode={isConfirmMode}
          isLoading={isLoading}
          onClickDeleteTag={handleClickDeleteTag}
          onClickConfirmDeleteTag={handleConfirmDeleteTag}
          onClickCancel={handleClickCancel}
          tags={generateDisplayingTags(allTags)}
        />
      </RentSpreeBaseModal>
    </div>
  )
}
export default compose(withConnect, withReducer)(DeleteTagModal)
