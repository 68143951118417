import B18 from '@rentspree/component-v2/dist/typography/b18'
import S18 from '@rentspree/component-v2/dist/typography/s18'
import styled from 'styled-components'

export const WidgetTitle = styled(S18)`
  margin-top: 5px;
`
export const WidgetSubTitle = styled(B18)`
  margin: 0px;
  font-size: 12px;
  display: ${props => props.minimizePage && 'none'};
`

export const TextButton = styled(S18)`
  cursor: pointer;
  font-size: 12px;
  margin: 0 0 0 18px;
`
