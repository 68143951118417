import { DialogContentText, Box as MuiBox } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@rentspree/component-2023.components.atoms.button'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import Step from '@rentspree/component-2023.components.organisms.step'
import StepLabel from '@rentspree/component-2023.components.organisms.step-label'
import Stepper from '@rentspree/component-2023.components.organisms.stepper'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import React from 'react'
import styled from 'styled-components'

import { useScreenSize } from 'hooks/use-screen-size'
import Typography from '@rentspree/component-2023.components.atoms.typography'

export const OnboardingPartnerAgentTemplateAModal = ({
  isOpen,
  onClose,
  onClickScreenTenants,
  onClickViewSampleReport,
}) => {
  const title = "You're ready to start screening"
  const contentText =
    'Your property has already been added. Copy and share your link with renters to collect applications and screening reports.'

  const { isDesktop } = useScreenSize()

  return isDesktop ? (
    <Dialog
      data-testid="onboarding-partner-agent-template-a"
      open={isOpen}
      onClose={onClose}
      size="large"
    >
      <DialogTitle size="large" handleOnClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogContent>
        <OnboardingStep />
      </DialogContent>
      <DialogActions sx={{ gap: '16px' }} disableSpacing>
        <Button
          data-testid="view-sample-report-button"
          variant="text"
          color="secondary"
          className="underline"
          onClick={onClickViewSampleReport}
        >
          View sample report
        </Button>
        <Button
          data-testid="screen-tenants-button"
          variant="contained"
          color="secondary"
          onClick={onClickScreenTenants}
        >
          Screen tenants
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Sheet
      data-testid="onboarding-partner-agent-template-a"
      open={isOpen}
      onClose={onClose}
      showCloseButton
      actionSection={
        <MuiBox display="flex" flexDirection="column" gap="16px">
          <Button
            data-testid="screen-tenants-button"
            variant="contained"
            fullWidth
            color="secondary"
            onClick={onClickScreenTenants}
          >
            Screen tenants
          </Button>
          <Button
            data-testid="view-sample-report-button"
            variant="text"
            color="secondary"
            fullWidth
            className="underline"
            onClick={onClickViewSampleReport}
          >
            View sample report
          </Button>
        </MuiBox>
      }
    >
      <SheetHeader header={title} />
      <MuiBox padding="16px">
        <Typography variant="body-medium" component="div" marginBottom="16px">
          {contentText}
        </Typography>
        <OnboardingStep />
      </MuiBox>
    </Sheet>
  )
}

const OnboardingStep = () => {
  const { isDesktop } = useScreenSize()

  return (
    <MuiBox
      paddingX="24px"
      paddingY="16px"
      bgcolor="var(--rsp_ref_palette_neutral20_100p)"
      borderRadius="12px"
    >
      <Stepper
        activeStep={0}
        sx={{ height: '100%' }}
        orientation={isDesktop ? 'horizontal' : 'vertical'}
      >
        {['Get application link', 'Share it with potential tenants', 'Receive applications'].map(
          (label, index) => (
            <Step
              // eslint-disable-next-line react/no-array-index-key
              key={`step-${index}`}
              {...(isDesktop && { style: { height: '92px' } })}
            >
              <StyledStepLabel
                noBackground
                isDesktop={isDesktop}
                {...(isDesktop && { labelDirection: 'column' })}
              >
                {label}
              </StyledStepLabel>
            </Step>
          ),
        )}
      </Stepper>
    </MuiBox>
  )
}

const StyledStepLabel = styled(StepLabel)`
  ${({ isDesktop }) =>
    isDesktop &&
    `
      .MuiStepLabel-label {
        max-width: 130px !important;
        text-align: center !important;
        white-space: pre-line;
        text-wrap: balance;
      }
  `}
`
