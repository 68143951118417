import styled, { css } from "styled-components"
import { Link as ReactRouterLink } from "react-router-dom"
import { ANIMATE, COLOR, INLINE_FLEX_CENTER } from "../../styles/settings"
import * as type from "./button-color"

export const btn = css`
  border-radius: 50px;
  cursor: pointer;
  height: 35px;
  padding: 0 20px;
  transition: ${props =>
    props.transitionNone ? "none" : `all ease-in-out ${ANIMATE.fast}`};
  ${INLINE_FLEX_CENTER};

  &.button-lg {
    height: 45px;
    font-size: 15px;
  }
  &.button-md {
    height: 40px;
  }
  &.button-sm {
    height: 25px;
    font-size: 13px;
    padding: 0 12px;
  }
`

export const button = styled.button`
  ${btn};
  font-size: ${props => (props.fontSize ? props.fontSize : "14")}px;
  font-weight: ${props => (props.weight ? props.weight : "bold")};
  ${props => props.primary && type.blueBtn};
  ${props => props.green && type.greenBtn};
  ${props => props.white && type.whiteBtn};
  ${props => props.redBtn && type.redBtn};
  ${props => props.default && type.defaultBtn};
  ${props => props.hoverWhiteText && hoverWhiteText};
  ${props => props.transparentBlue && type.transparentBlueBtn};
  ${props => props.transparentDarkGreen && type.transparentDarkGreenBtn};
  ${props => props.transparent && type.transparentBtn};
  ${props => props.textBtn && type.textBtn};
  ${props => props.onlyMobile && onlyMobile};
  ${props => props.hiddenMobile && hiddenMobile};
  ${props => props.inlineLink && inlineLink};
  ${props => props.underlineTextLink && underlineTextLink};
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};
  ${props =>
    props.squareBtn &&
    css`
      border-radius: 4px;
    `};
  ${props =>
    props.leftSpace &&
    css`
      margin-left: ${props.leftSpace};
    `};
  ${props => props.onlyIconMobile && onlyIconMobile};
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props => props.disabled && disabledBtn};
  ${props => props.validationError && validationError};
  ${props =>
    props.fontFamily &&
    css`
      font-family: ${props.fontFamily};
    `}

  &.z-index {
    position: relative;
    z-index: 13;
  }

  &:disabled {
    cursor: not-allowed;
    border: none;
    background-color: ${COLOR.newGrey};
  }
`

export const ButtonBlue = styled(button)`
  color: white;
  border: none;
`

export const link = styled(button).attrs(() => ({ as: "a" }))``
export const buttonAlike = styled(button).attrs(() => ({ as: "div" }))`
  cursor: initial;
  &:hover {
    background: initial;
    ${props => props.transparentDarkGreen && `color: ${COLOR.darkGreen}`}
  }
`
export const routerLink = styled(button).attrs(() => ({
  as: ReactRouterLink,
}))``

export const btnIcon = styled.i`
  margin-right: ${props => (props.gap ? `${props.gap}px` : "7px")};
  ${props =>
    props.size &&
    css`
      font-size: ${props.size}px;
    `}
`
export const btnIconRight = styled.i`
  margin-left: ${props => (props.gap ? `${props.gap}px` : "7px")};
  ${props =>
    props.size &&
    css`
      font-size: ${props.size}px;
    `}
`

export const btnImgIcon = styled.img`
  margin-right: ${props => (props.gap ? `${props.gap}px` : "7px")};
`

export const loaderWrapper = styled.div`
  margin-right: ${props => (props.gap ? `${props.gap}px` : "7px")};
`
export const hoverWhiteText = css`
  color: ${COLOR.white};
  &:hover,
  &:focus {
    color: ${COLOR.textGrey};
  }
`

export const inlineLink = css`
  padding: 0px;
  font-size: inherit;
  font-weight: normal;
  border: none;
  color: ${COLOR.deepBlue};
  background: transparent;
  height: inherit;

  &:disabled {
    color: ${COLOR.textGrey};
  }
`
export const underlineTextLink = css`
  padding: 0;
  font-size: 0.85em;
  font-weight: normal;
  line-height: 1.3;
  color: inherit;
  border-radius: 0;
  border-bottom: 1px solid;
  height: inherit;
`

export const onlyMobile = css`
  @media (min-width: 992px) {
    display: none;
  }
`

export const hiddenMobile = css`
  @media (max-width: 991px) {
    display: none;
  }
`

export const onlyIconMobile = css`
  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    &:hover:not([disabled]) {
      background-color: ${COLOR.deepBlue};
      > i {
        color: ${COLOR.white};
      }
    }
    > i {
      margin: 0;
      font-size: 1.6em;
    }
    > span {
      display: none;
    }
  }
`

export const validationError = css`
  background-color: ${COLOR.negativeRed} !important;
  border-color: ${COLOR.negativeRed} !important;
  animation: shake ${ANIMATE.normal} cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-fill-mode: forwards;
  transform: translate3d(0, 0, 0);

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-0.5px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(2px, 0, 0);
    }
  }
`

export const disabledBtn = css`
  background: ${COLOR.newGrey};
  border-color: ${COLOR.bgGrey};
  color: ${COLOR.white};
`
