import React from "react"
import styled from "styled-components"
import { COLOR } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
const StyledProBadge = styled.span`
  font-family: Source Sans Pro;
  display: inline-block;
  min-width: 10px;
  padding: 3px 6px;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1;
  color: ${COLOR.white};
  background: ${COLOR.mintGreen};
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 3px;
  margin-left: 5px;
  position: relative;
  bottom: 1px;
`

export const ProBadge = ({ children, ...props }) => (
  <StyledProBadge {...props}>{children}</StyledProBadge>
)
