import React from 'react'
import Button from '@rentspree/component-2023.components.atoms.button'
import Styled from 'styled-components'

const ActionButtonContainer = Styled.div`
  ${props => !props.isMobile && 'border-top: 1px solid #bcbcbc;'}
  ${props => !props.isMobile && 'padding: 20px;'}
  display: flex;
  justify-content: space-between;
`

const ActionButtonSet = ({ isDisableSave, handleSave, handleBack, isMobile }) => (
  <ActionButtonContainer isMobile={isMobile}>
    <Button
      variant="text"
      size="medium"
      color="secondary"
      className="underline"
      onClick={handleBack}
    >
      Back
    </Button>
    <Button
      disabled={isDisableSave}
      variant="contained"
      size="medium"
      color="secondary"
      onClick={handleSave}
    >
      Save
    </Button>
  </ActionButtonContainer>
)

export default ActionButtonSet
