import styled from "styled-components"
import { COLOR, FLEX_CENTER } from "../../styles/settings"

export const StyledMySubscriptionBox = styled.div``
// language=SCSS prefix=dummy{ suffix=}
export const StyledMembership = styled.div`
  margin-bottom: 20px;
`
// language=SCSS prefix=dummy{ suffix=}
export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLOR.lineGrey};
  margin-bottom: 10px;
  p {
    font-size: 14px;
  }
`
export const StyledIconContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .icon {
    > img {
      flex-shrink: 0;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    .icon {
      width: 200px;
      margin: auto;
    }
    .content {
      text-align: center;
      align-items: center;
    }
  }
`

export const StyledEmailBox = styled.div`
  border: 1px solid ${COLOR.lineGrey};
  ${FLEX_CENTER};
  flex-direction: column;
  padding: 15px;
`
