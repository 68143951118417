/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import * as Styled from "./section-style"
import { Link } from "../buttons/buttons"
import { H2, H3, H4, P } from "../typography"
import ProLogo from "../../images/logos/rentspree-logo-pro-white.png"

export const Section = ({ children, ...props }) => (
  <Styled.section {...props}>{children}</Styled.section>
)

export const SectionContainer = ({ children, ...props }) => (
  <Styled.sectionContainer {...props}>{children}</Styled.sectionContainer>
)

export const Title = ({ title, subtitle, align }) => (
  <Styled.title className={`text-${align}`}>
    {title && <h2 className="title">{title}</h2>}
    {subtitle && <p className="subtitle">{subtitle}</p>}
  </Styled.title>
)

export const FlexBox = ({ children, ...props }) => (
  <Styled.flexbox {...props}>{children}</Styled.flexbox>
)

export const FlexCol = ({ children, ...props }) => (
  <Styled.flexCol {...props}>{children}</Styled.flexCol>
)

export const LeftHeadingDiv = ({ children, ...props }) => (
  <Styled.leftHeadingDiv {...props}>{children}</Styled.leftHeadingDiv>
)

export const ImageBanner = ({ children, ...props }) => (
  <Styled.imageBanner {...props}>{children}</Styled.imageBanner>
)

export const BottomLinkDiv = ({ children, ...props }) => (
  <Styled.bottomLinkDiv {...props}>{children}</Styled.bottomLinkDiv>
)

export const SectionLogo = ({ image = ProLogo, ...props }) => (
  <Styled.sectionLogo className="section-logo" {...props}>
    <img src={image} alt="Section Logo" />
  </Styled.sectionLogo>
)

export const SectionHeader = ({ title, subtitle, ...props }) => (
  <Styled.sectionHeader {...props}>
    <H2>{title}</H2>
    <H4 size="1.4em" align="center" color="titleBlack">
      {subtitle}
    </H4>
  </Styled.sectionHeader>
)

export const IconContentRow = ({
  title,
  content,
  onClick,
  icon,
  titleSize,
  titleStrong,
  ...props
}) => (
  <Styled.iconContentRow {...props}>
    <div className="icon">
      <img src={icon.src} alt={icon.alt} />
    </div>
    <div className="content">
      <H3 size={titleSize || false} strong={titleStrong || false}>
        {title}
      </H3>
      <P size="1.2em" lineHeight="1.5">
        {content}
        {onClick && <Link onClick={onClick} inlineLink text="Learn more" />}
      </P>
    </div>
  </Styled.iconContentRow>
)
export const IconContentCol = ({ title, content, icon, ...props }) => (
  <Styled.iconContentCol {...props}>
    <div className="icon">
      <img src={icon.src} alt={icon.alt} />
    </div>
    <div className="content">
      <H3 size="1.4em" strong>
        {title}
      </H3>
      <P lineHeight="1.5">{content}</P>
    </div>
  </Styled.iconContentCol>
)

export const Notification = ({ children, ...props }) => (
  <Styled.notification {...props}>{children}</Styled.notification>
)
