import { Grid } from '@mui/material'
import Avatar from '@rentspree/component-2023.components.atoms.avatar'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import get from 'lodash/get'
import React from 'react'

import { RECIPIENT_INFO_FIELDS } from 'v3/containers/overhaul-rent-payment/constants'

const EMAIL = 'email'

// eslint-disable-next-line no-inline-comments
export const RecipientDetailsCard = ({ recipientInfo }) => {
  const { firstName, lastName, email } = {
    firstName: get(recipientInfo, RECIPIENT_INFO_FIELDS.FIRST_NAME, ''),
    lastName: get(recipientInfo, RECIPIENT_INFO_FIELDS.LAST_NAME, ''),
    email: get(recipientInfo, EMAIL, ''),
  }
  const name = `${firstName} ${lastName}`
  const initials = name
    .split(' ')
    .map(n => n[0])
    .join('')

  return (
    <Card variant="outlined" style={{ margin: '15px 0' }}>
      <CardElement>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          item
          md={12}
          style={{ marginBottom: '15px' }}
        >
          <Typography variant="title">{name}</Typography>
          <Avatar>{initials}</Avatar>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body-medium">{email}</Typography>
        </Grid>
      </CardElement>
    </Card>
  )
}
