import { API_URL } from 'env'
import { COLOR } from 'styles/settings'

export const ROLE_OPTIONS = [
  { label: 'Role', value: 'none' },
  { label: 'Applicant', value: 'applicant' },
  { label: 'Tenant', value: 'tenant' },
  { label: 'Agent', value: 'agent' },
  { label: 'Property Manager', value: 'pm' },
  { label: 'Landlord', value: 'landlord' },
  { label: 'Vendor', value: 'vendor' },
]

export const SOURCE_OPTIONS = [
  { label: 'RentSpree', value: 'rentspree' },
  { label: 'RentSpree Application', value: 'application' },
  { label: 'RentSpree Listing', value: 'property-detail' },
  { label: 'RentSpree Listing Page', value: 'listing-syndication' },
  { label: 'RentSpree Agent Profile', value: 'agent-profile' },
  { label: 'CSV', value: 'csv' },
  { label: 'E-Sign', value: 'e-sign' },
  { label: 'Shared RentSpree Application', value: 'share-report' },
]

export const ROLE_COLOR = {
  none: COLOR.placeholderGrey,
  applicant: COLOR.activeBlue,
  tenant: COLOR.newDeepBlue,
  agent: COLOR.redLight,
  pm: COLOR.yellow,
  landlord: COLOR.darkGreen,
  vendor: COLOR.shallowBlueDark,
}

export const ROLE_WIDTH = {
  none: '60px',
  applicant: '90px',
  tenant: '73px',
  agent: '65px',
  pm: '145px',
  landlord: '85px',
  vendor: '75px',
}

// TODO: move to library constant
export const US_STATES = [
  { label: '', value: '' },
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'DC', value: 'DC' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'PR', value: 'PR' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' },
]

export const WORK_STATUS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Employed',
    value: 'employed',
  },
  {
    label: 'Unemployed',
    value: 'unemployed',
  },
  {
    label: 'Retired',
    value: 'retired',
  },
  {
    label: 'Student',
    value: 'student',
  },
]

export const WORK_STATUS_WIDTH = {
  none: '60px',
  employed: '90px',
  unemployed: '110px',
  retired: '73px',
}

export const SELECT_DROPDOWN_PLACEHOLDER = {
  label: 'Select Option',
  value: '',
}

export const SOURCES = {
  rentspree: 'RentSpree',
  csv: 'csv',
  application: 'RentSpree Application',
  'agent-profile': 'RentSpree Agent Profile',
  'listing-syndication': 'RentSpree Listing Page',
  'property-detail': 'RentSpree Listing',
  'e-sign': 'RentSpree E-Sign',
  'past-application': 'RentSpree',
  'share-report': 'Shared RentSpree Application',
}

export const MIN_SALARY = 0
export const MAX_SALARY = 999999999

export const MAX_PHONE_NUMBER_LENGTH = 20

export const INPUT_TYPE = {
  CONTENT_EDITABLE_INPUT: 'contentEditable',
  SELECT: 'select',
}

export const MAX_NOTES = 200
export const MAX_NOTE_CHARACTERS = 1000

export const COMPANY_NAME_MAX_LENGTH = 150

export const CSV_MAX_SIZE = 2097152
export const ACCEPT = 'text/csv, .csv'
export const MAX_FILES = 1

export const MAX_UPCOMING_DAYS = 30

export const ADDRESS_TYPES = {
  LIVING_ADDRESS: 'livingAddress',
  COMPANY_ADDRESS: 'companyAddress',
}

export const CONTACT_TOAST_SUCCESS = 'copied'

export const CONTACT_CONTACT_API = `${API_URL}/api/v2/people/contacts/:contactId/contact`

export const CONTACT_CONTACT_CALL = 'CONTACT_CONTACT_CALL'
export const CREATE_CONTACT_API = `${API_URL}/api/v2/people/contacts`
export const CREATE_CONTACTS_API = `${API_URL}/api/v2/people/contacts/batch`
export const CREATE_CONTACT_FROM_PUBLIC_PAGE_API = `${API_URL}/api/v2/people/contacts/public-page`
export const SET_SEEN_API = `${API_URL}/api/v2/people/contacts/batch/seen`

export const CREATE_CONTACT_CALL = 'CREATE_CONTACT_CALL'
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST'

export const CONTACT_SOURCES = {
  APPLICATION: 'application',
  PROPERTY_DETAIL: 'property-detail',
}

export const CREATE_CONTACTS_CALL = 'CREATE_CONTACTS_CALL'
export const CREATE_CONTACTS_REQUEST = 'CREATE_CONTACTS_REQUEST'
export const CREATE_CONTACTS_SUCCESS = 'CREATE_CONTACTS_SUCCESS'
export const CREATE_CONTACTS_FAILURE = 'CREATE_CONTACTS_FAILURE'

export const EVENT_MESSAGE_TYPE = {
  SEND_GIFT_URL: 'SEND_GIFT_URL',
}

export const SET_SEEN_CONTACTS_CALL = 'SET_SEEN_CONTACTS_CALL'
export const SET_SEEN_CONTACTS_REQUEST = 'SET_SEEN_CONTACTS_REQUEST'
export const SET_SEEN_CONTACTS_SUCCESS = 'SET_SEEN_CONTACTS_SUCCESS'
export const SET_SEEN_CONTACTS_FAILURE = 'SET_SEEN_CONTACTS_FAILURE'
