import { useMemo } from 'react'

import { createBreadcrumbStepsArray, selectShowBreadcrumbSteps } from './helpers'

function useBreadcrumbSteps(pathname, pageDetails) {
  const { address, fullName, leaseTitle, rentersRequesteeEmail, contactName, contactId } =
    pageDetails

  const desktopBreadcrumbSteps = useMemo(() => {
    const breadcrumbSteps = createBreadcrumbStepsArray(pageDetails)
    return selectShowBreadcrumbSteps(breadcrumbSteps, pathname)
  }, [pathname, address, fullName, leaseTitle, rentersRequesteeEmail, contactName, contactId])

  return { desktopBreadcrumbSteps }
}

export default useBreadcrumbSteps
