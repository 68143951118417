import get from "lodash/get"
import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const getLandlordRefund = state =>
  get(state, "landlordRefund", initialState)

export const selectIsSubmitting = createSelector(
  getLandlordRefund,
  landlordRefund =>
    get(landlordRefund, "isSubmitting", initialState.isSubmitting),
)
