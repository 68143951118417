import styled from "styled-components"

import { COLOR } from "styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const ModalActions = styled.div`
  margin-top: 20px;
  > a,
  button {
    margin: 10px;
  }
  @media (max-width: 991px) {
    margin-top: 10px;
  }
`
export const ModalHeader = styled.div`
  padding: 0 30px;
  @media (max-width: 991px) {
    padding: 0 15px;
  }
`

export const GettingStartedModalActions = styled.div`
  margin: 4px 4px 4px 0;
  display: flex;
  justify-content: flex-end;
  > a,
  button {
    margin: 10px;
    height: 40px;
    padding: 10px 20px;
  }
  @media (max-width: 991px) {
    margin: 18px 54px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    > a,
    button {
      margin: 0 0 10px 0;
      height: 40px;
      padding: 10px;
    }
  }
`

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1;
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1500;
  top: 20px;
  right: 20px;
  width: 15px;
  height: 15px;
  color: #d8d8d8;
  font-size: 17px;
  &:hover {
    cursor: pointer;
    color: ${COLOR.textBlack};
  }
`
