import { date, addMethod } from "yup"
import isNil from "lodash/isNil"
import isUndefined from "lodash/isUndefined"
import moment from "moment/moment"

// extends yup date type
// add method maxEndDate and adjust transform function to handle case year between 0000-0099
function maxEndDate(refs, msg) {
  return this.test({
    name: "maxEndDate",
    exclusive: false,
    message: msg,
    params: {
      reference: isNil(refs) ? "" : refs.path,
    },
    test(value) {
      const refsValue = this.resolve(refs)
      if (isUndefined(refsValue)) return true
      const fromDate = moment(value)
      const endDate = moment(refsValue)
      return fromDate.diff(endDate) >= 0
    },
  })
}
addMethod(date, "maxEndDate", maxEndDate)

const DateFormatAvailable = ["YYYY-MM-DD", "YYYY-MM", "MM/DD/YYYY"]

const invalidDate = new Date("")
const newDateType = () =>
  date().transform((current, originalVal) => {
    const value = moment(originalVal, DateFormatAvailable, true)
    if (value.year() === 0) return invalidDate
    return value.toDate()
  })

export default newDateType
