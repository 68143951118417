import styled, { css } from "styled-components"
import { ANIMATE, COLOR } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const TableCss = css`
  width: 100%;
  thead > tr > th {
    border-bottom: 2px solid ${COLOR.grayFade};
  }
  thead > tr > th,
  tbody > tr > td {
    text-align: center;
    border-top: 1px solid ${COLOR.grayFade};
    padding: 0.75em;
    transition: background ease ${ANIMATE.fast};
    &:nth-child(2) {
      text-align: left;
      max-width: 480px;
    }

    &.text-left {
      text-align: left;
    }
    &.text-right {
      text-align: right;
    }
    &.icon-col {
      width: 40px;
      padding-right: 0;
      vertical-align: middle;
    }
    &.button-center {
      vertical-align: middle;
    }
    &.icon-col-1 {
      width: 3%;
      padding-right: 0;
      vertical-align: middle;
      @media (max-width: 991px) {
        display: table-cell;
      }
    }
    @media (max-width: 991px) {
      display: none;
      position: relative;
      &:nth-child(2) {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  tr {
    position: relative;
    transition: background ease ${ANIMATE.fast};
    &:last-child {
      td {
        border-bottom: 1px solid ${COLOR.grayFade} !important;
      }
    }
  }
`

export const Table = styled.table`
  ${TableCss}
`

export const Tr = styled.tr`
  background: ${props => props.greyRow && COLOR.selectionGrey};
  font-weight: ${props => props.textBold && "bold"};
  cursor: ${props => (props.disabled ? "auto" : "pointer")};

  ${props =>
    props.BgColor &&
    css`
      th,
      td {
        background-color: ${COLOR[props.BgColor]};
      }
    `}

  ${props =>
    props.isBlur &&
    css`
      opacity: 0.5;
      background: ${COLOR.bgGrey};
    `};
  &:hover {
    background: ${props => !props.disabled && COLOR.bgGrey};
  }
`

export const Td = styled.td`
  max-width: ${props => props.maxWidth && props.maxWidth};
  width: ${props => props.width && props.width};
  min-width: ${props => props.minWidth && props.minWidth};
  ${props => props.padding && `padding: ${props.padding}`}
`

export const Th = styled(Td).attrs(() => ({ as: "th" }))`
  cursor: auto;
  background: ${COLOR.white};
`

// language=SCSS prefix=dummy{ suffix=}
export const IconColumn = styled.td`
  > div {
    display: flex;
    align-items: center;
    color: initial;
    @media (max-width: 991px) {
      justify-content: space-between;
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const IconColumnText = styled.div`
  display: flex;
  align-items: center;
  > img {
    margin-right: 10px;
  }
  &.ellipsis-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    > img {
      position: relative;
      bottom: 1px;
    }
  }
`

export const ReportStatusImage = styled.img`
  height: 15px;
  margin: -2px 0px 0px 7px;
  @media (max-width: 767px) {
    margin: -5px -2px 0px 1px;
  }
`
