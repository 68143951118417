import { useExperiment, useFeatureGate } from '@statsig/react-bindings'

export const STATSIG = 'statsig'

export const useStatsigExperiment = (featureFlagKey, statsigFeatureParam) => {
  const experiment = useExperiment(featureFlagKey)
  const isEnabled = experiment.get(statsigFeatureParam)
  return { isEnabled }
}

export const useStatsigFeatureGate = featureFlagKey => {
  const { value } = useFeatureGate(featureFlagKey)
  return { isEnabled: value }
}

export const useFeatureFlag = ({
  launchDarklyFeatureFlagKey,
  statsigFeatureFlagKey,
  statsigFeatureParam,
  featureFlagProvider,
}) => {
  switch (featureFlagProvider) {
    case STATSIG:
      return statsigFeatureParam
        ? useStatsigExperiment(statsigFeatureFlagKey, statsigFeatureParam)
        : useStatsigFeatureGate(statsigFeatureFlagKey)
    default:
      console.error(
        `Unsupported provider for ${launchDarklyFeatureFlagKey} or ${statsigFeatureFlagKey} feature flag ${featureFlagProvider}`,
      )
      return false
  }
}
