import React from "react"
import { scrollToFirstError } from "utils/scroll-to-first-error"

export default class ScrollToError extends React.Component {
  componentDidUpdate(prevProps) {
    // if form was submitting, but now is not submitting because it is invalid
    const { isSubmitting, isValid } = this.props
    if (prevProps.isSubmitting && !isSubmitting && !isValid) {
      const { errors, fieldOrder, offset, containerId } = this.props
      scrollToFirstError(errors, fieldOrder, offset, containerId)
    }
  }

  render() {
    return null
  }
}
