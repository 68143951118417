import styled from 'styled-components'
import { COLOR, ANIMATE, FLEX_CENTER, BORDER_RADIUS } from 'styles/settings'

const PAGINATION_HEIGHT = '40px'

// language=SCSS prefix=dummy{ suffix=}
export const PaginationDiv = styled.div`
  align-items: stretch;
  background: white;
  display: flex;
  height: ${PAGINATION_HEIGHT};
  justify-content: center;
  position: ${({ position }) => position || 'absolute'};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  bottom: 15px;
  width: 100%;
  @media (max-width: 991px) {
    position: ${({ mPosition }) => mPosition || 'relative'};
    top: 5px;
  }
  ${({ padding }) => padding && `padding: ${padding};`}
`
// language=SCSS prefix=dummy{ suffix=}
export const Pager = styled.ul`
  ${FLEX_CENTER};
  height: 100%;
  margin-bottom: 0;
  > li {
    height: 100%;
    border: 1px solid ${COLOR.lineGrey};
    border-left: none;
    &:first-child {
      border-left: 1px solid ${COLOR.lineGrey};
      border-top-left-radius: ${BORDER_RADIUS};
      border-bottom-left-radius: ${BORDER_RADIUS};
    }
    &:last-child {
      border-top-right-radius: ${BORDER_RADIUS};
      border-bottom-right-radius: ${BORDER_RADIUS};
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const PaginationBtn = styled.button`
  background: ${props => (props.disabled ? COLOR.bgDarkGrey : COLOR.selectionGrey)};
  border: none;
  color: ${COLOR.steelBlue};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 0.8em;
  font-weight: bold;
  height: 100%;
  padding: 0 10px;
  text-align: center;
  &.current {
    transition: background ease ${ANIMATE.fast};
    background-color: ${COLOR.steelBlue};
    color: ${COLOR.white};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const PaginationPageBtn = styled(PaginationBtn)`
  color: ${COLOR.textBlack};
  font-size: 1em;
  padding: 0;
  width: ${PAGINATION_HEIGHT};
`
