import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { getProfile } from "legacy/actions/user.action"
import { selectUser, selectAccessToken } from "../user/selectors"

const makeMapStateToProps = () =>
  createStructuredSelector({
    user: selectUser,
    isLogin: selectAccessToken,
  })

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile,
    },
    dispatch,
  )

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)
