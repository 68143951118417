import React from 'react'
import styled from 'styled-components'
import { buildPath } from '@rentspree/path'
import printJS from 'print-js'
import PropTypes from 'prop-types'
import { TopNavbar } from '@rentspree/navbar'
import { Icon } from '@rentspree/component-v2/dist/icon'
import { isAndroid } from 'legacy/components/helper/ua-parser-js'
import { DIGIDOC_GET_FILE } from 'constants/route'
import { ENVELOPE_STATUS } from 'containers/envelope/constants'
import { Header, IconBeforeText, TopBarContainer, HeaderButton } from 'components/envelope'
import EDIT_ICON from 'images/icons/lease-agreement/edit-icon-black.svg'
import COMPLETED_ICON from 'images/icons/lease-agreement/check-pass.svg'
import loadingImg from 'images/gifs/ripple.svg'

import { COLOR } from 'styles/settings'
import { E_SIGN_HEADER_TITLE } from './constants'
import { DOMAIN_NAME, API_URL, USER_API_URL } from '../../../env'

const ButtonContainer = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
  min-height: 50px;
  text-align: center;
  text-align: -webkit-center;
  cursor: pointer;
  &:hover ${HeaderButton} {
    background-color: rgba(0, 0, 0, 0.1);
  }
  ${props =>
    props.Mobile &&
    `
  margin-left: -20px
  `};
`

const IconContainer = styled.div`
  gap: 20px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IconLabel = styled.p`
  color: ${COLOR.darkerGray};
  margin-top: -7px;
  font-size: 12px;
  ${props =>
    props.PC &&
    `
  @media(max-width: 991px) {
    display: none !important;
  }
  `};
  ${props =>
    props.Mobile &&
    `
  @media(min-width: 992px) {
    display: none !important;
  }
  `};
`

export const appendLoadingPDF = () => {
  const loadComponent = document.getElementById('printJS-Modal')
  loadComponent.innerHTML = ''
  const newDiv = document.createElement('div')

  const image = document.createElement('img')
  image.src = loadingImg
  image.alt = 'loading'
  loadComponent.style['justify-content'] = 'center'
  loadComponent.style['align-items'] = 'center'
  loadComponent.style.display = 'flex'
  loadComponent.style['background-color'] = 'rgba(255, 255, 255, 0.5)'

  newDiv.appendChild(image)
  loadComponent.appendChild(newDiv)
}

// create a memo to prevent re-rendering these components every time the state change
const EnvelopeHeaderWithNavBarMemo = React.memo(
  ({
    isLoading,
    viewPage,
    status,
    queryToken,
    envelopeId,
    pdfPath,
    actions,
    headerTitle,
    profile,
    onSaveCallback,
    onPrintCallback,
    pdfBase64 = undefined,
    rentalId,
    isLoadingGeneratePdfFileURL,
  }) => {
    let TEXT = headerTitle
    let ICON = EDIT_ICON
    if (viewPage) {
      switch (status) {
        case ENVELOPE_STATUS.PENDING:
          TEXT = 'Waiting for other signatures.'
          break
        case ENVELOPE_STATUS.COMPLETED:
          TEXT = 'The document is now complete.'
          ICON = COMPLETED_ICON
          break
        default:
          break
      }
    }

    const completed = viewPage && status === ENVELOPE_STATUS.COMPLETED

    const onPrint = fileURL => {
      const url = fileURL || buildPath(DIGIDOC_GET_FILE, { envelopeId }, { token: queryToken })

      if (isAndroid) {
        window.open(url)
      } else {
        printJS({
          printable: pdfBase64 || url,
          type: 'pdf',
          showModal: true,
          onLoadingStart: appendLoadingPDF,
          onError: () => {
            actions.alertPrintAndDownloadNotHaveURL()
          },
          base64: pdfBase64 && true,
        })
      }
      if (typeof onPrintCallback === 'function') {
        onPrintCallback()
      }
    }

    const onSavePDF = () => {
      if (pdfPath) {
        if (rentalId) {
          actions.saveBufferFiles({ pdfPath, _id: envelopeId, rentalId })
        } else {
          actions.saveFiles({ pdfPath, _id: envelopeId, token: queryToken })
        }
      } else {
        actions.alertPrintAndDownloadNotHaveURL()
      }
      if (typeof onSaveCallback === 'function') {
        onSaveCallback()
      }
    }

    const generatePdfFileURLBeforePrint = () => {
      if (rentalId) {
        actions.generatePdfFileURL({ envelopeId, rentalId, callback: onPrint })
      } else {
        onPrint()
      }
    }

    return (
      <>
        <TopBarContainer>
          <TopNavbar
            profile={profile}
            isShowSupport
            isShowNotification
            domain={DOMAIN_NAME}
            notificationBaseURL={API_URL}
            authBaseURL={USER_API_URL}
          />
        </TopBarContainer>
        <Header completed={completed}>
          <div>
            <IconBeforeText src={ICON} alt="IconSign" />
            <span className="text-title">{TEXT}</span>
          </div>
          <IconContainer>
            <ButtonContainer id="printBtn" onClick={generatePdfFileURLBeforePrint}>
              <HeaderButton>
                {isLoadingGeneratePdfFileURL ? (
                  <Icon name="loading" size="md" spin />
                ) : (
                  <Icon id="print-report-header-icon" name="print" size="md" />
                )}
              </HeaderButton>
              {!isLoadingGeneratePdfFileURL && <IconLabel id="print-icon-label">Print</IconLabel>}
            </ButtonContainer>
            <ButtonContainer id="saveBtn" width="52px" onClick={onSavePDF}>
              <HeaderButton>
                {isLoading ? (
                  <Icon name="loading" size="md" spin />
                ) : (
                  <Icon id="save-pdf-header-report-button-mobile-icon" name="download" size="md" />
                )}
              </HeaderButton>
              {!isLoading && <IconLabel id="download-icon-label">Download</IconLabel>}
            </ButtonContainer>
          </IconContainer>
        </Header>
      </>
    )
  },
)

EnvelopeHeaderWithNavBarMemo.defaultProps = {
  headerTitle: E_SIGN_HEADER_TITLE,
}

EnvelopeHeaderWithNavBarMemo.propsType = {
  headerTitle: PropTypes.string,
}

export default EnvelopeHeaderWithNavBarMemo
