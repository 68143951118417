import styled, { css } from "styled-components"
import { COLOR } from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const textInput = styled.span`
  color: ${props => (props.color ? COLOR[props.color] : "inherit")};
  ${props =>
    props.hoverColor &&
    css`
      :hover {
        color: ${COLOR[props.hoverColor]};
      }
    `}
  ${props =>
    props.strong &&
    css`
      font-weight: bold;
    `};
  ${props =>
    props.light &&
    css`
      font-weight: 300;
    `};
  ${props =>
    props.semiBold &&
    css`
      font-weight: 600;
    `};
  ${props =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
  ${props =>
    props.size &&
    css`
      font-size: ${props.size};
    `};
  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `};
  ${props =>
    props.leftSpace &&
    css`
      margin-left: ${props.leftSpace};
    `};
  ${props =>
    props.rightSpace &&
    css`
      margin-right: ${props.rightSpace};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.m0 && "margin: 0px"};
  ${props =>
    props.mMargin &&
    css`
      @media (max-width: 991px) {
        margin: ${props.mMargin};
      }
    `};
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `};
  ${props => props.mCenter && "@media(max-width:991px) {text-align: center}"};
  ${props => props.lineThrough && "text-decoration: line-through"};
  ${props => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${props => props.center && "text-align: center"};
  ${props => props.letterSpacing && `letter-spacing: ${props.letterSpacing}px`};
  ${props => props.cursor && `cursor: ${props.cursor}`};
  ${props =>
    props.flex &&
    css`
      display: flex !important;
      align-items: baseline;
    `};
  ${props =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};
  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};
  @media (max-width: 991px) {
    ${props =>
      props.mSize &&
      css`
        font-size: ${props.mSize};
      `};
    ${props =>
      props.mAlign &&
      css`
        text-align: ${props.mAlign};
      `};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const label = styled.label`
  margin-bottom: 0;
`

export const Span = styled(textInput)``

export const i = styled(textInput).attrs(() => ({ as: "i" }))``
export const I = styled(i)``

export const withP = styled(textInput).attrs(() => ({ as: "p" }))``
export const p = styled(withP)`
  font-size: 16px;
`
export const P = styled(withP)`
  font-size: ${props => (props.size ? props.size : "16px")};
  color: ${props => (props.inherit ? "inherit" : COLOR.textBlack)};
`
export const Small = styled(withP)`
  font-size: ${props => (props.size ? props.size : "14px")};
`
export const Large = styled(withP)`
  font-size: ${props => (props.size ? props.size : "18px")};
`

export const textDiv = styled(textInput).attrs(() => ({ as: "div" }))``

export const h1 = styled(textInput).attrs(() => ({ as: "h1" }))``

export const H1 = styled(h1)`
  font-size: ${props => (props.size ? props.size : "28px")};
  ${props =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `};
  color: ${COLOR.textBlack};
  @media (max-width: 991px) {
    font-size: 24px;
  }
`

export const h2 = styled(h1).attrs(() => ({ as: "h2" }))``
export const h3 = styled(h1).attrs(() => ({ as: "h3" }))`
  line-height: 1.3;
`
export const h4 = styled(h1).attrs(() => ({ as: "h4" }))`
  line-height: 1.4;
  ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom}` : "")}
`
