/* eslint-disable camelcase */
import React, { useState } from "react"
import get from "lodash/get"

import { Landing } from "components/landing"
import { CenterLoading } from "components/organisms/center-loading"
import reportSvg from "legacy/images/credit-report/report.svg"

import { withLandlordRefundConnect } from "./connect"
import TenantScreeningRefundModal from "./tenant-screening-refund-modal"

export const LandlordRefundPage = ({
  rentalDetail,
  actions,
  isSubmitting,
  isFetchingRentalDetail,
}) => {
  const [showModal, setShowModal] = useState(true)

  const rentalId = get(rentalDetail, "_id")

  const submitRefund = () => {
    actions.submitRefund({ rentalId })
  }

  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  if (isFetchingRentalDetail) return <CenterLoading />

  return (
    <>
      <Landing
        title="Report(s) are ready"
        description="Please click the button below to pull the reports from TransUnion."
        buttonName="View Report(s)"
        imgSrc={reportSvg}
        onClick={openModal}
        buttonWidth="205px"
      />
      <TenantScreeningRefundModal
        isOpen={showModal}
        onClose={closeModal}
        onClickConfirm={submitRefund}
        isLoading={isFetchingRentalDetail || isSubmitting}
        remark={rentalDetail?.credit_report?.requestedOption?.remark}
        payerType={rentalDetail?.screeningOption?.payerType}
      />
    </>
  )
}

export default withLandlordRefundConnect(LandlordRefundPage)
