import { buildPath } from "@rentspree/path"
import { takeLatest, all, put, call } from "redux-saga/effects"
import { UPDATE_CURRENT_REPORT_STATUS } from "legacy/constants/action-types"
import { apiInstance } from "utils/api-interceptor"
import { openSweetAlertBaseError } from "utils/sweet-alert-actions"
import { submitRefundApiState } from "./actions"
import { SUBMIT_REFUND_API, SUBMIT_REFUND_CALL } from "./constants"

export const submitRefundApi = ({ rentalId }) =>
  apiInstance.post(buildPath(SUBMIT_REFUND_API, { rentalId }))

export function* submitRefundSaga({ payload }) {
  yield put(submitRefundApiState.request())
  try {
    const response = yield call(submitRefundApi, payload)
    yield put({
      type: UPDATE_CURRENT_REPORT_STATUS,
      payload: response.status,
    })
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(submitRefundApiState.failure(err))
  }
}

export function* watchSubmitRefund() {
  yield takeLatest(SUBMIT_REFUND_CALL, submitRefundSaga)
}

export function* rootSaga() {
  yield all([watchSubmitRefund()])
}

export default rootSaga
