import { REF_CHECK_PRO_OPTIONS } from 'containers/request/step-screening-options/constants'
import { referenceType } from 'legacy/constants/ref-check-consts'
import isEmpty from 'lodash/isEmpty'
import { AGENT_TYPES } from 'containers/tenant-screening/request/constants'
import { SELECT_AGENT_TYPE } from 'constants/route'

export const getProOptionPayload = (documentProOption = {}, refCheckProOption = {}) => {
  const proOptionPayload = {}

  const documentPayload = Object.entries(documentProOption)
    .filter(([_, value]) => value.isSelected)
    .map(([type, value]) => ({ type, ...(value.note ? { note: value.note.trim() } : {}) }))
  const referencePayload = Object.entries(refCheckProOption)
    .filter(([_, isSelected]) => isSelected)
    .map(([type, _]) => ({ type }))
    .map(ref => {
      switch (ref.type) {
        case REF_CHECK_PRO_OPTIONS.FIRST_PERSONAL.key:
          return { type: referenceType.personal, arrayIndex: 0 }
        case REF_CHECK_PRO_OPTIONS.SECOND_PERSONAL.key:
          return { type: referenceType.personal, arrayIndex: 1 }
        default:
          return ref
      }
    })

  if (!isEmpty(documentPayload)) proOptionPayload.documents = documentPayload
  if (!isEmpty(referencePayload)) proOptionPayload.references = referencePayload

  return proOptionPayload
}

export const shouldRedirectToSelectAgentTypePage = ({
  agentType,
  propertyId,
}) => {
  const isAgentTypeValid = Object.values(AGENT_TYPES).includes(agentType)
  if (!propertyId && !isAgentTypeValid) {
    // screening without property in the roleViaTSFlow Epic
    // just redirect to the select agent type if no query param agent_type
    return { shouldRedirect: true, redirectPath: SELECT_AGENT_TYPE }
  }

  return { shouldRedirect: false }
}
