import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import injectSaga from "utils/inject-saga"
import saga from "containers/subscription/saga"
import { KEY_PLAN } from "containers/subscription/constants"
import { getSubscriptions } from "containers/subscription/actions"
import {
  selectScreeningOption,
  selectParticipantType,
  selectShareOption,
  selectIsSharedReport,
  selectAuthorize,
  selectUser,
} from "../selectors"

const mapStateToProps = createStructuredSelector({
  screeningOption: selectScreeningOption,
  participantType: selectParticipantType,
  isSharedReport: selectIsSharedReport,
  shareOption: selectShareOption,
  authorize: selectAuthorize,
  user: selectUser,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubscriptions,
    },
    dispatch,
  )

export const withSaga = injectSaga({ key: KEY_PLAN, saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
