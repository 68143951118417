import React from 'react'
import { APPLICATION_TYPE } from 'constants/application-type'
import {
  CAR_APPLICATION_AVAIABLE_VERSIONS,
  RENTSPREE_APPLICATION_AVAIABLE_VERSIONS,
} from 'components/reports/application/constants'
import { LoadableLraApp, LoadableAppV2 } from './loadable'
export const createRules = (props, defaultApplication) => ({
  // TODO: Swap default application to V2+
  rules: [
    {
      version: RENTSPREE_APPLICATION_AVAIABLE_VERSIONS,
      applicationType: [APPLICATION_TYPE.RENTSPREE],
      application: <LoadableAppV2 {...props} isAvailable />,
    },
    {
      version: CAR_APPLICATION_AVAIABLE_VERSIONS,
      applicationType: [APPLICATION_TYPE.CAR_LRA],
      application: <LoadableLraApp isAvailable {...props} />,
    },
  ],
  defaultApplication,
})

export const selectApplication = ({ rules, defaultApplication }, applicationType, version) => {
  const loadableApplication =
    rules.find(
      applicationSelectRule =>
        applicationSelectRule.version.includes(version) &&
        applicationSelectRule.applicationType.includes(applicationType),
    )?.application || defaultApplication
  return loadableApplication
}
