import { ALERT_PRESET } from 'legacy/libs/sweet-alert/sweet-alert'
import { OPEN_SWEET_ALERT } from 'legacy/constants/action-types'
import {
  GET_REPORTS_AVAILABLE_CALL,
  SHARE_REPORT_CALL,
  TOGGLE_SHARE_REPORT_MODAL_CALL,
  CLOSE_ERROR,
  SET_FORM_VALUES,
  SUBMIT_SHARE_REPORT_FORM,
  UPDATE_DEFAULT_SHARE_OPTION,
  GET_DEFAULT_SHARE_OPTION_CALL,
} from './constants'

export const getReportsAvailableCall = () => ({
  type: GET_REPORTS_AVAILABLE_CALL,
})

export const shareReportCall = payload => ({
  type: SHARE_REPORT_CALL,
  payload,
})

export const toggleShareReportModal = payload => ({
  type: TOGGLE_SHARE_REPORT_MODAL_CALL,
  payload,
})

export const closeError = () => ({
  type: CLOSE_ERROR,
})

export const alertSuccess = payload => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.SUCCESS,
  option: {
    title: 'Success',
    text: `The report has been shared with ${payload.email}.`,
    button: 'Done',
  },
})

export const setFormValues = payload => ({
  type: SET_FORM_VALUES,
  payload,
})

export const submitShareReportForm = payload => ({
  type: SUBMIT_SHARE_REPORT_FORM,
  payload,
})

export const getDefaultShareOption = () => ({
  type: GET_DEFAULT_SHARE_OPTION_CALL,
})

export const updateDefaultShareOption = payload => ({
  type: UPDATE_DEFAULT_SHARE_OPTION,
  payload,
})
