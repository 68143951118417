import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { createTenant } from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'
import { makeSelectCreateTenantStatus } from '../redux/selectors'

export const useCreateTenant = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const status = useSelector(makeSelectCreateTenantStatus())

  return {
    createTenant: props => {
      const { firstName, lastName, email, phone } = props
      dispatch(createTenant({ firstName, lastName, email, phone, onSuccess, onError }))
    },
    status,
  }
}
