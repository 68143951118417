import React, { useEffect, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

import tracker from 'tracker'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import * as PATH from 'constants/route'
import PropertyDetailsTemplate from 'components/templates/property-details'
import generalErrorIcon from 'images/errors/general-error-icon.svg'

import { RequestContainer } from 'legacy/components/request/request-main-style'
import { LoadingOverlay } from 'legacy/components/request/left-content'
import { Landing } from 'components/landing'
import { locationReload } from 'legacy/utils/window'
import get from 'lodash/get'
import { query } from '@rentspree/path'
import { REGISTERED_USER_TYPE } from 'constants/user'
import { withConnect, withSaga, withReducer } from './connect'

export const StepPropertyDetails = ({
  actions,
  match,
  propertyAddressName,
  isFetchingProperty,
  isUpdatingPropertyDetails,
  getPropertyError,
  history,
  profile,
}) => {
  const clickBackToDashboard = () => {
    tracker.trackEvent(EVENT_REQUEST_SCREENING.backToDashboardFromPropertyInformation)
    history.push(PATH.PROPERTY)
  }

  const search = get(history, 'location.search', '')
  const { continueTo, source } = query.parse(search)

  const updatePropertyDetails = async payload => {
    const { propertyId } = match.params
    const { firstName, middleName, lastName, email, mobileNumber, ...propertyDetails } = payload

    return actions.updatePropertyDetailsAndCreateContact({
      propertyDetails,
      propertyId,
      continueTo,
      source,
    })
  }

  useEffect(() => {
    const { propertyId } = match.params || {}
    if (isEmpty(propertyAddressName)) actions.getProperty({ propertyId })
  }, [])

  const isLandlord = useMemo(() => {
    const registeredUserType = get(profile, 'registeredUserType')
    return registeredUserType === REGISTERED_USER_TYPE.LANDLORD
  }, [profile?.registeredUserType])

  if (getPropertyError)
    return (
      <Landing
        propsWrapper={{
          heightCal: 'calc(100vh - 70px)',
          style: { background: 'transparent' },
        }}
        title="Error loading page"
        description="Sorry, we're having trouble loading the page. Please refresh the page and try again."
        buttonName="Refresh"
        imgSrc={generalErrorIcon}
        buttonWidth="114"
        buttonType="google"
        onClick={locationReload}
      />
    )

  return (
    <RequestContainer>
      {isFetchingProperty ? (
        <LoadingOverlay />
      ) : (
        <PropertyDetailsTemplate
          id="propertyDetailsTemplate"
          title={propertyAddressName}
          handleBackToDashboard={clickBackToDashboard}
          onSubmit={updatePropertyDetails}
          isUpdatingPropertyDetails={isUpdatingPropertyDetails}
          isLandlord={isLandlord}
        />
      )}
    </RequestContainer>
  )
}

export default compose(withRouter, withConnect, withReducer, withSaga)(StepPropertyDetails)
