import { withFormik } from "formik"
import styled from "styled-components"
import isEmpty from "lodash/isEmpty"
import React from "react"
import T36 from "@rentspree/component-v2/dist/typography/t36"
import B16 from "@rentspree/component-v2/dist/typography/b16"

import { Div } from "components/layout/main"
import { ButtonSet } from "components/buttons/button-set"
import SubtitleWithHr from "components/molecules/subtitle-with-hr"
import { createPropertySchema } from "components/templates/create-property/schema"
import CreatePropertyAddressForm from "components/organisms/address-form"

export const AlertWrapper = styled.div`
  margin: -50px 0 30px 0;
  @media (max-width: 991px) {
    margin: -30px 0 20px 0;
  }
`

export const StyledT36 = styled(T36)`
  line-height: 45px;
  @media (max-width: 991px) {
    line-height: 32px;
  }
`

export const UpdateZiplogixProperty = ({
  title,
  handleBackToDashboard,
  handleSubmit,
  isUpdatingZiplogixProperty,
  updateZiplogixNote,
  ...props
}) => {
  const { touched, errors } = props
  return (
    <>
      <StyledT36 margin="0 0 30px">{title}</StyledT36>
      <SubtitleWithHr text="Property Address" />
      <CreatePropertyAddressForm {...props} />
      {updateZiplogixNote && (
        <Div
          sourceSansPros
          flex
          flexGrow="1"
          flexDirection="column"
          margin="10px 0 15px 0"
          mMargin="10px 0 15px 0">
          <B16>{updateZiplogixNote}</B16>
        </Div>
      )}
      <ButtonSet
        id="updateZiplogixPropertyButton"
        margin="0"
        minWidth="118"
        continueBtnType="submit"
        continueText="Update"
        btnHeight="40px"
        hidePrevButton
        onClickBackToDashboardBtn={handleBackToDashboard}
        disabledNextButton={isEmpty(touched) || !isEmpty(errors)}
        onSaveAndNext={handleSubmit}
        isLoading={isUpdatingZiplogixProperty}
      />
    </>
  )
}

const UpdateZiplogixPropertyTemplate = withFormik({
  mapPropsToValues: props => createPropertySchema(props).mapper,
  validationSchema: props => createPropertySchema(props).validation,
  mapPropsToTouched: props => createPropertySchema(props).touched,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values)
  },
  enableReinitialize: true,
  validateOnMount: ({ validateOnMount }) => validateOnMount,
  displayName: "UpdateZiplogixPropertyTemplate",
})(UpdateZiplogixProperty)

export default UpdateZiplogixPropertyTemplate
