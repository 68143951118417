import produce from 'immer'
import { ERROR_TYPE } from 'constants/error-type'

import {
  GET_ALL_PROPERTY_LIST_REQUEST,
  GET_ALL_PROPERTY_LIST_SUCCESS,
  GET_ALL_PROPERTY_LIST_FAILED,
  CLEAR_SELECT_PROPERTY_WARNING,
  INIT_SELECT_PROPERTY_WARNING,
  AUTO_SELECT_PROPERTY,
  CLEAR_AUTO_SELECT_PROPERTY
} from './constants'

export const initialState = {
  isFetching: false,
  propertyList: [],
  isError: false,
  isWarning: '',
  propertySelector: '',
  errorType: '',
}

/* eslint-disable default-case, no-param-reassign */
const propertyTypeReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_ALL_PROPERTY_LIST_REQUEST:
        draft.isFetching = true
        break
      case GET_ALL_PROPERTY_LIST_SUCCESS:
        draft.isFetching = false
        draft.propertyList = payload.data
        draft.isError = false
        break
      case GET_ALL_PROPERTY_LIST_FAILED:
        draft.isFetching = false
        draft.isError = true
        break
      case CLEAR_SELECT_PROPERTY_WARNING:
        draft.isWarning = false
        draft.errorType = ''
        break
      case INIT_SELECT_PROPERTY_WARNING:
        draft.isWarning = true
        draft.propertySelector = payload
        draft.errorType = ERROR_TYPE.DUPLICATE_PROPERTY
        break
      case AUTO_SELECT_PROPERTY:
        draft.propertySelector = payload
        draft.errorType = ''
        break
      case CLEAR_AUTO_SELECT_PROPERTY:
        draft.propertySelector = ''
        draft.errorType = ''
        break
      default:
        break
    }
    return draft
  })

export default propertyTypeReducer
