import React from "react"
import { S16 } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"
import { InLineLink, Icon } from "./styled"

export const DownloadAllButton = ({ isFetching, onClick, id, text, fontSize }) =>
  !isFetching ? (
    <InLineLink
      id={id}
      onClick={onClick}
      style={{ minWidth: 120 }}
      data-testid="download-all-button">
      <Icon fontSize="12" className="fas fa-download" />
      <S16 ml="8" margin="0" color={COLOR.activeBlue} size={fontSize}>
        {text}
      </S16>
    </InLineLink>
  ) : (
    <Icon className="fas fa-circle-notch fa-spin" />
  )
