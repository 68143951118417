import { createSelector } from 'reselect'

import { PAYMENT_INCENTIVE_INFORMATION_PAGE_REDUCER_KEY } from './constants'
import { initialState } from './reducers'

export const selectPaymentIncentiveInformationState = state =>
  state?.[PAYMENT_INCENTIVE_INFORMATION_PAGE_REDUCER_KEY] || initialState

export const makeSelectIsIncentiveInformationOpen = () =>
  createSelector(selectPaymentIncentiveInformationState, state => (state || initialState)?.isOpen)

export const makeSelectOpenIncentiveInformationFromPathname = () =>
  createSelector(
    selectPaymentIncentiveInformationState,
    state => (state || initialState)?.fromPathname,
  )

export const makeSelectIsIncentiveInformationAutoOpened = () =>
  createSelector(
    selectPaymentIncentiveInformationState,
    state => (state || initialState)?.isAutoOpened,
  )

export const makeSelectIsIncentiveInformationOpenedFromNavBarClick = () =>
  createSelector(
    selectPaymentIncentiveInformationState,
    state => (state || initialState)?.isOpenedFromNavBarClick,
  )

export const makeSelectIsIncentiveReferralPageOpen = () =>
  createSelector(
    selectPaymentIncentiveInformationState,
    state => (state || initialState)?.isReferralPageOpened,
  )
