import generateApiCall from 'utils/generate-api-call'

import {
  CREATE_ACCOUNT_SESSION_CALL,
  CREATE_ACCOUNT_SESSION_FAILED,
  CREATE_ACCOUNT_SESSION_REQUEST,
  CREATE_ACCOUNT_SESSION_SUCCESS,
} from './constants'

export const createAccountSessionAPI = generateApiCall([
  CREATE_ACCOUNT_SESSION_REQUEST,
  CREATE_ACCOUNT_SESSION_SUCCESS,
  CREATE_ACCOUNT_SESSION_FAILED,
])

export const createAccountSession = () => ({
  type: CREATE_ACCOUNT_SESSION_CALL,
})
