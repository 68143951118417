import styled from 'styled-components'

// language=SCSS prefix=dummy{ suffix=}
export const RequestWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 70px);
  overflow: hidden;
  padding: 50px 0;
  position: relative;
  background: #fff;
  @media (max-width: 991px) {
    padding: 30px 0;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const RequestContainer = styled.div`
  width: ${props => (props.width ? props.width : '500px')};
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const RequestFooter = styled.div`
  display: flex;
  justify-content: ${props => (props.isFooterContentEnd ? 'flex-end' : 'space-between')};
  align-items: center;
  font-family: Source Sans Pro;
`

export const ButtonCancel = styled.div`
  & > button {
    background-color: white;
  }
`

export const IconWarning = styled.i`
  margin-right: 10px;
  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
`
