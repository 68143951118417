import React from 'react'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import B16 from '@rentspree/component-v2/dist/typography/b16'
import { DefaultModal } from 'components/modal'
import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssociationContact } from 'containers/overview/association-contact/selectors'
import { toggleShareReportModal } from 'containers/share-report/actions'
import { compose } from 'redux'
import tracker from 'tracker'
import { generateLandlordFullName } from 'containers/reports/helper'
import { SHARE_REPORT } from 'tracker/const'
import { addToast } from 'containers/toast/actions'
import { selectIsShowModal, selectNavigationUnblock } from './selectors'
import { toggleShareReportPromptModal } from './actions'
import { withReducer, withSaga } from './connect'
import { PROMPT_CONTENT } from './constants'

const PromptButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`
const ShareReportButton = styled(BlueGradientButton)`
  width: 140px;
  border-radius: 36px;
`

const NoThanksButton = styled(Button)`
  width: 140px;
  border-radius: 36px;
`

const ToastContainer = styled.div`
  font-weight: 600;
`

const ToastText = styled.span`
  margin-right: 4px;
`

const ToastLink = styled.a`
  color: inherit;
`

const ToastContent = () => (
  <ToastContainer>
    <ToastText>OK. When you’re ready, view </ToastText>
    <ToastLink
      href="https://support.rentspree.com/en/share-reports"
      target="_blank"
      style={{ color: 'inherit' }}
    >
      <u>A Guide to Sharing</u>
    </ToastLink>
  </ToastContainer>
)

export const ShareReportPrompt = ({ property, rentalAppId, reportPageType }) => {
  const dispatch = useDispatch()
  const isShowModal = useSelector(selectIsShowModal)
  const { firstName, lastName } = useSelector(selectAssociationContact)
  const navigationUnblock = useSelector(selectNavigationUnblock)

  const eventProperties = {
    rental_id: rentalAppId,
  }

  const handleShareReport = () => {
    dispatch(toggleShareReportPromptModal({ toggle: false }))
    dispatch(
      toggleShareReportModal({
        toggle: true,
        clickFrom: reportPageType,
        position: 'auto_prompt_modal',
      }),
    )
    tracker.trackEvent(SHARE_REPORT.startShareViaAutoPrompt, eventProperties)
  }

  const handleClose = () => {
    dispatch(toggleShareReportPromptModal({ toggle: false, navigationUnblock, property }))
    dispatch(
      addToast({
        timeOut: 7000,
        hideIcon: true,
        status: 'success',
        children: <ToastContent />,
      }),
    )
    tracker.trackEvent(SHARE_REPORT.closeShareAutoPrompt, eventProperties)
  }

  const landlordName = generateLandlordFullName(firstName, lastName) || 'Landlord'

  return (
    <DefaultModal
      closeButton
      loadingOverlay
      className="share-report-prompt-modal"
      showModal={isShowModal}
      closeModal={handleClose}
      backdrop="static"
      dialogMaxWidth="480px"
    >
      <>
        <S24 margin="0px 0px 0px 0px">{PROMPT_CONTENT.title}</S24>
        <S24 margin="0px 0px 10px 0px">
          <b>{`${landlordName} ?`}</b>
        </S24>
        <B16 margin="0px 0px 25px 0px">{PROMPT_CONTENT.description}</B16>
        <PromptButtonGroup>
          <NoThanksButton
            google
            semiBold
            fontSize="16px"
            id="share-report-prompt-no-thanks-btn"
            data-testid="share-report-prompt-no-thanks-btn"
            className="left-btn"
            text="No thanks"
            type="button"
            onClick={handleClose}
          />
          <ShareReportButton
            semiBold
            fontSize="16px"
            id="share-report-prompt-share-btn"
            data-testid="share-report-prompt-share-btn"
            className="right-btn"
            text="Share report"
            type="button"
            onClick={handleShareReport}
          />
        </PromptButtonGroup>
      </>
    </DefaultModal>
  )
}

export default compose(withReducer, withSaga)(ShareReportPrompt)
