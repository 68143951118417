import createAction from "legacy/apis/utils/create-action"
import * as api from "legacy/apis/property-list.api"
import {
  GET_PROPERTY_LIST_REQUEST,
  GET_PROPERTY_LIST_SUCCESS,
  GET_PROPERTY_LIST_FAILED,
  CLEAR_PROPERTY_LIST,
  GET_PROPERTY_DROP_DOWN_REQUEST,
  GET_PROPERTY_DROP_DOWN_SUCCESS,
  GET_PROPERTY_DROP_DOWN_FAILED,
} from "./constants"

export const getPropertyList = createAction(
  api.fetchPropertyList,
  [
    GET_PROPERTY_LIST_REQUEST,
    GET_PROPERTY_LIST_SUCCESS,
    GET_PROPERTY_LIST_FAILED,
  ],
  (res = {}) => ({
    data: res.data,
    pagination: res.pagination,
  }),
)

export const getPropertyDropdown = createAction(
  api.fetchPropertyDropdown,
  [
    GET_PROPERTY_DROP_DOWN_REQUEST,
    GET_PROPERTY_DROP_DOWN_SUCCESS,
    GET_PROPERTY_DROP_DOWN_FAILED,
  ],
  res => {
    const data = res.result || {}
    return { propertyDropdown: data.properties }
  },
)

export const clearPropertyList = () => ({ type: CLEAR_PROPERTY_LIST })
