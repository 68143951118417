import React from 'react'
import styled from 'styled-components'
import { InformationPanel, Section, Content } from 'components/reports/screening-reports/share/responsive-doc-style'
import { InformationLine, SubSectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import { breakpoints } from 'styles/settings'

const ContentStyled = styled(Content)`
  margin-bottom: 0px !important;
`

const InformationPanelStyled = styled(InformationPanel)`
  display: flex;
  justify-content: space-between;

  ${breakpoints.mobileReport(`
    flex-direction: column;
  `)}
`

export const Identification = ({
  ssn,
  driversLicenseNumber,
  driversLicenseState,
  driversLicenseExpirationYear,
}) => (
  <Section>
    <SubSectionHeader title="Identifications" />
    <ContentStyled>
      <InformationPanel>
        <InformationLine label="SSN" value={ssn}/>
      </InformationPanel>
    </ContentStyled>
    <Content>
      <InformationPanel>
        <InformationLine label="Drivers License" value={driversLicenseNumber}/>
      </InformationPanel>
      <InformationPanelStyled>
        <InformationLine label="State" value={driversLicenseState}/>
        <InformationLine label="Expiration Year" value={driversLicenseExpirationYear}/>
      </InformationPanelStyled>
    </Content>
  </Section>
)
