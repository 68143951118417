import { takeLatest, put, call, all, select } from 'redux-saga/effects'
import { buildPath, query } from '@rentspree/path'
import { push } from 'connected-react-router'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import {
  SCREENING_NEW_TENANT_ACTION_V2,
  CREATE_LISTING_SCREENING_REQUEST_API,
  GET_APPLY_LINK_API,
} from 'constants/route'
import { selectLocation } from 'containers/agreement/selectors'
import tracker from 'tracker'
import { LRA_INTEGRATION } from 'tracker/const'
import { SCREENING_REQUEST_SOURCE } from 'containers/constants'
import {
  GET_LRA_REQUEST_CALL,
  LRA_REQUEST_ENDPOINT,
  UPDATE_LRA_REQUEST_CALL,
} from 'containers/request/step-update-screening-fee/constants'
import {
  getLraRequestApi,
  updateLraRequestApi,
  createListingScreeningRequestActions,
} from 'containers/request/step-update-screening-fee/actions'
import { apiInstance, apiInstanceWithErrorHandler } from 'utils/api-interceptor'

export const callGetLraRequestByScreeningOptionApi = ({ propertyId, screeningRequestId }) =>
  apiInstance.get(buildPath(LRA_REQUEST_ENDPOINT, { propertyId, screeningRequestId }))

export const callUpdateLraRequestByScreeningOptionApi = ({
  propertyId,
  screeningRequestId,
  screeningFee,
}) =>
  apiInstance.post(buildPath(LRA_REQUEST_ENDPOINT, { propertyId, screeningRequestId }), {
    lraRequest: screeningFee,
  })

export function* getLraRequestByScreeningOptionSaga({ payload }) {
  yield put(getLraRequestApi.request())
  try {
    const response = yield call(callGetLraRequestByScreeningOptionApi, payload)
    yield put(getLraRequestApi.success(response))
  } catch (err) {
    yield put(getLraRequestApi.failure())
  }
}

export function* updateLraRequestByScreeningOptionSaga({ payload }) {
  yield put(updateLraRequestApi.request())
  try {
    const { search } = yield select(selectLocation)
    const { screeningRequestId, screeningMethod, continuePath, source } = query.parse(search)
    const { propertyId, screeningFee } = payload
    const createScreeningRequestForListing = source === SCREENING_REQUEST_SOURCE.LISTING

    const response = yield call(callUpdateLraRequestByScreeningOptionApi, {
      propertyId,
      screeningRequestId,
      screeningFee,
    })
    yield put(updateLraRequestApi.success(response))
    yield call([tracker, 'trackEvent'], LRA_INTEGRATION.COMPLETE_SCREENING_FEE)

    const nextPage =
      continuePath ||
      buildPath(
        SCREENING_NEW_TENANT_ACTION_V2,
        {},
        { propertyId, screeningRequestId, screeningMethod },
      )

    if (!createScreeningRequestForListing) {
      yield put(push(nextPage))
      return
    }
    const { permalink, screeningOption } = yield call(callGetScreeningRequest, {
      screeningRequestId,
    })
    yield call(createListingScreeningRequest, {
      propertyId,
      payload: {
        applicationType: screeningOption.applicationType,
        hasApplication: screeningOption.application,
        hasCreditReport: screeningOption.credit_report,
        payerType: screeningOption.payerType,
        hasCriminalRecord: screeningOption.criminal,
        hasEviction: screeningOption.eviction,
        url: permalink,
      },
    })

    yield put(push(nextPage))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(updateLraRequestApi.failure())
  }
}

export function* watchGetLraRequestByScreeningOptionApiCall() {
  yield takeLatest(GET_LRA_REQUEST_CALL, getLraRequestByScreeningOptionSaga)
}

export function* watchUpdateLraRequestByScreeningOptionApiCall() {
  yield takeLatest(UPDATE_LRA_REQUEST_CALL, updateLraRequestByScreeningOptionSaga)
}

export function* rootSaga() {
  yield all([
    watchGetLraRequestByScreeningOptionApiCall(),
    watchUpdateLraRequestByScreeningOptionApiCall(),
  ])
}

export function* createListingScreeningRequest({ propertyId, payload }) {
  yield put(createListingScreeningRequestActions.request())
  try {
    const response = yield call(callCreateListingScreeningRequestAPI, {
      propertyId,
      payload,
    })
    yield put(createListingScreeningRequestActions.success(response.result))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createListingScreeningRequestActions.failure())
  }
}

export const callCreateListingScreeningRequestAPI = ({ propertyId, payload }) =>
  apiInstanceWithErrorHandler.put(createListingScreeningRequestEndpoint(propertyId), payload)
const createListingScreeningRequestEndpoint = propertyId =>
  buildPath(CREATE_LISTING_SCREENING_REQUEST_API, { propertyId })

const getScreeningRequestEndpoint = screeningRequestId =>
  buildPath(GET_APPLY_LINK_API, { screeningRequestId })

export const callGetScreeningRequest = ({ screeningRequestId }) =>
  apiInstanceWithErrorHandler.get(getScreeningRequestEndpoint(screeningRequestId))
export default rootSaga
