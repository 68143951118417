import { date } from "yup"
import moment from "moment/moment"

// extends yup date type
// adjust transform function to handle case year between 0000-0099  "YYYY-MM-DD"

const invalidDate = new Date("")
export const checkDate = originalVal => {
  const value = moment(originalVal, "YYYY-MM-DD", true) // formik will return date with this format
  if (!value.isValid() || value.year() === 0) return invalidDate
  return value.toDate()
}
const newDateType = () =>
  date().transform((current, originalVal) => checkDate(originalVal))

export default newDateType
