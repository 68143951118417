import generateApiCall from "utils/generate-api-call"
import {
  GET_RENTER_INSURANCE_DETAIL_CALL,
  GET_RENTER_INSURANCE_DETAIL_REQUEST,
  GET_RENTER_INSURANCE_DETAIL_SUCCESS,
  GET_RENTER_INSURANCE_DETAIL_FAILURE,
  CLEAR_RENTER_INSURANCE_DETAIL_CALL,
} from "./constants"

export const getRenterInsuranceDetailCall = payload => ({
  type: GET_RENTER_INSURANCE_DETAIL_CALL,
  payload,
})

export const clearRenterInsuranceDetailCall = () => ({
  type: CLEAR_RENTER_INSURANCE_DETAIL_CALL,
})

export const renterInsuranceDetailApi = generateApiCall([
  GET_RENTER_INSURANCE_DETAIL_REQUEST,
  GET_RENTER_INSURANCE_DETAIL_SUCCESS,
  GET_RENTER_INSURANCE_DETAIL_FAILURE,
])
