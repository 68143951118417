import RentPaymentMainLogo from 'images/rent-payments/main-logo.svg'

export const MODAL_INFO = {
  TITLE: 'Applicant accepted!',
  DESCRIPTION: 'Congrats! We told your new tenant the good news.',
  IMAGE: RentPaymentMainLogo,
  PRIMARY_BUTTON: {
    TEXT: 'Start collecting payments',
  },
}
