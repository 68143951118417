export const OPERATIONS = {
  INIT: "INIT",
  MOVE: "MOVE",
  CHANGE_PAGE: "CHANGE_PAGE",
}

/**
 * @typedef {Object} ComponentDimensions - Dimension values of the component
 * @property {number} height - component height
 * @property {number} width - component width
 * @property {number} left - component left value from parent DOM
 * @property {number} top - component top value from parent DOM
 */

/**
 * @typedef {Object} AbsolutePosition
 * @property {number} left
 * @property {number} top
 */

/**
 * @typedef {Object} Position
 * @property {number} x
 * @property {number} y
 */

/**
 * Calculate the new position of the drag box when it is dragged to the new place
 *
 * @param {string} operation - Operation type
 * @param {Object} coordinates - All coordinates value to be calculated
 * @param {Position} coordinates.destination - Destination coordinate of the drag box
 * @param {ComponentDimensions} coordinates.parentDimensions - Dimension values of the parent component
 * @param {ComponentDimensions} coordinates.dimensions - Dimension values of the drag box
 * @param {*} coordinates.oldParent - Old parent dimension values
 * @returns {AbsolutePosition} New `left` and `top` value of the drag box
 */
export function calculatePosition(operation, coordinates) {
  if (
    operation === OPERATIONS.INIT ||
    operation === OPERATIONS.MOVE ||
    operation === OPERATIONS.CHANGE_PAGE
  ) {
    const { destination, parentDimensions, ratio } = coordinates
    return calculateInitBoxPosition(destination, parentDimensions, ratio)
  }
  return { left: 0, top: 0 }
}

/**
 * Calculate a new destination for a new page
 *
 * @param {Position} destination
 * @param {*} oldParent
 * @param {AbsolutePosition} newParentDimensions
 * @returns {Position} a new position
 */
export function adjustNewPagePosition(
  destination,
  oldParent,
  newParentDimensions,
) {
  const { x, y } = destination
  return {
    x: Math.round(
      oldParent.left +
        x +
        oldParent.parentDimensions.left -
        newParentDimensions.left,
    ),
    y: Math.round(
      oldParent.top +
        y +
        oldParent.parentDimensions.top -
        newParentDimensions.top,
    ),
  }
}

export function adjustBoxPosition(
  destination,
  dropBoxDimensions,
  dragSourceDimensions,
) {
  const { x, y } = destination
  const { height: dropHeight, width: dropWidth } = dropBoxDimensions
  const { height: dragHeight, width: dragWidth } = dragSourceDimensions
  const newDestination = { left: x, top: y }
  // adjust X (horizontal)
  if (newDestination.left < 0) {
    newDestination.left = 0
  } else if (newDestination.left + dragWidth > dropWidth) {
    newDestination.left = dropWidth - dragWidth
  }

  // adjust Y (vertical)
  if (newDestination.top < 0) {
    newDestination.top = 0
  } else if (newDestination.top + dragHeight > dropHeight) {
    newDestination.top = dropHeight - dragHeight
  }
  return newDestination
}
/**
 * Calculate the box position for when dragging the box into the page first time
 *
 * @param {Position} { x: destinationX, y: destinationY }
 * @param {AbsolutePosition} { left = 0, top = 0 }
 * @returns
 */
export function calculateInitBoxPosition(
  { x: destinationX, y: destinationY },
  { left = 0, top = 0 },
  ratio,
) {
  return {
    left: Math.round((destinationX - left) / ratio),
    top: Math.round((destinationY - top) / ratio),
  }
}
