import generateApiCall from "utils/generate-api-call"
import {
  ACCEPT_AGREEMENT_CALL,
  ACCEPT_AGREEMENT_REQUEST,
  ACCEPT_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT_FAILURE,
  GET_ACCEPT_AGREEMENT_CALL,
  GET_ACCEPT_AGREEMENT_REQUEST,
  GET_ACCEPT_AGREEMENT_SUCCESS,
  GET_ACCEPT_AGREEMENT_FAILURE,
  GET_AGREEMENT_CONTENT_CALL,
  GET_AGREEMENT_CONTENT_REQUEST,
  GET_AGREEMENT_CONTENT_SUCCESS,
  GET_AGREEMENT_CONTENT_FAILURE,
  CLEAR_AGREEMENT_ACCEPT_STATUS,
} from "./constants"

export const getAcceptAgreementCall = payload => ({
  type: GET_ACCEPT_AGREEMENT_CALL,
  payload,
})

export const getAcceptAgreementAPIState = generateApiCall([
  GET_ACCEPT_AGREEMENT_REQUEST,
  GET_ACCEPT_AGREEMENT_SUCCESS,
  GET_ACCEPT_AGREEMENT_FAILURE,
])

export const acceptAgreementCall = payload => ({
  type: ACCEPT_AGREEMENT_CALL,
  payload,
})

export const acceptAgreementAPIState = generateApiCall([
  ACCEPT_AGREEMENT_REQUEST,
  ACCEPT_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT_FAILURE,
])

export const getAgreementContentCall = payload => ({
  type: GET_AGREEMENT_CONTENT_CALL,
  payload,
})

export const getAgreementContentAPIState = generateApiCall([
  GET_AGREEMENT_CONTENT_REQUEST,
  GET_AGREEMENT_CONTENT_SUCCESS,
  GET_AGREEMENT_CONTENT_FAILURE,
])

export const clearAgreementAcceptedStatus = () => ({
  type: CLEAR_AGREEMENT_ACCEPT_STATUS,
})
