import PropTypes from "prop-types"
import React from "react"
import S16 from "@rentspree/component-v2/dist/typography/s16"
import styled from "styled-components"
import SwitchButton from "@rentspree/component-v2/dist/button/switch"
import {
  StyledSwitchButtonWithTooltip,
  StyledTooltipContent,
  TooltipDescription,
} from "../switch-button-with-tooltip"

const StyledTooltipWithTitleAndSwitchButton = styled(
  StyledSwitchButtonWithTooltip,
)`
  margin-right: 20px;
  margin-left: 20px;
  @media (max-width: 991px) {
    ${props => props.hideMobile && "display: none;"}
  }
`

export const S14 = styled(S16)`
  margin-right: 10px;
  font-size: 14px;
`

const TooltipWithTitleAndSwitchButton = ({
  checked,
  id,
  hideMobile,
  onChange,
  tooltip,
}) => (
  <StyledTooltipWithTitleAndSwitchButton hideMobile={hideMobile}>
    <S14>{tooltip.title}</S14>
    <SwitchButton id={`${id}SwithBtn`} checked={checked} onChange={onChange} />
    <StyledTooltipContent
      id={`${id}Tooltip`}
      iconSize="14px"
      color="#AAAAAA"
      title=""
      position={tooltip.position}
      bubbleWidth="195px">
      <TooltipDescription>{tooltip.description}</TooltipDescription>
    </StyledTooltipContent>
  </StyledTooltipWithTitleAndSwitchButton>
)

TooltipWithTitleAndSwitchButton.defaultProps = {
  checked: false,
  hideMobile: false,
  tooltip: { position: "TOP" },
}

TooltipWithTitleAndSwitchButton.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  hideMobile: PropTypes.bool,
  onChange: PropTypes.func,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.string,
  }),
}

export default TooltipWithTitleAndSwitchButton
