import filter from "lodash/filter"
import flattenDeep from "lodash/flattenDeep"
import isEmpty from "lodash/isEmpty"
import isNumber from "lodash/isNumber"
import pick from "lodash/pick"
import reduce from "lodash/reduce"
import sortBy from "lodash/sortBy"
import moment from "moment"
import {
  TYPES_COMPONENT,
  HAS_MOMENT_VALIDATION,
  VALIDATION_FORMAT,
} from "containers/drag-n-drop/constants"

export const signRelatedComponentTypes = [
  TYPES_COMPONENT.TEXT_BOX,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.DATE_SIGNS,
]

function isSignRelatedField(fieldType) {
  return signRelatedComponentTypes.includes(fieldType)
}

function isFieldOwnBySigner(field) {
  return field.canEdit
}

function isFieldUnassigned(fieldValue) {
  return isNumber(fieldValue) ? fieldValue < 0 : isEmpty(fieldValue)
}

function isSignRelatedAndOwnBySignerFieldUnassigned(field) {
  return (
    isSignRelatedField(field.type) &&
    isFieldOwnBySigner(field) &&
    isFieldUnassigned(field.value)
  )
}

function isInvalidField(field) {
  if (field.value && HAS_MOMENT_VALIDATION.includes(field.type)) {
    const m = moment(field.value, VALIDATION_FORMAT[field.type], true)
    return !m.isValid()
  }
  return false
}

function sortFieldsByPositionAndPage(fields) {
  return sortBy(fields, ["page", "top", "left"])
}

function selectAllFields(files) {
  const allFieldsInFilesAndPages = files.map(file =>
    file.pages.map(page => page.fields),
  )
  return flattenDeep(allFieldsInFilesAndPages)
}

function reduceError(obj, field) {
  // eslint-disable-next-line no-param-reassign
  obj[field.fieldId] = true
  return obj
}

function parseFieldsToValidationErrorObject(validationFields, signFields) {
  const validationErrors = reduce(validationFields, reduceError, {})
  const signErrors = reduce(signFields, reduceError, {})
  return {
    isValid: isEmpty(signErrors) && isEmpty(validationErrors),
    validationErrors,
    signErrors,
  }
}

export function getValidationErrorObjectFrom(state) {
  const allFields = selectAllFields(state.files)

  const allInvalidFields = filter(allFields, isInvalidField)
  const sortedInvalidFields = sortFieldsByPositionAndPage(allInvalidFields)

  const allUnassignedFields = filter(
    allFields,
    isSignRelatedAndOwnBySignerFieldUnassigned,
  )
  const sortedUnassignedFields = sortFieldsByPositionAndPage(
    allUnassignedFields,
  )
  return parseFieldsToValidationErrorObject(
    sortedInvalidFields,
    sortedUnassignedFields,
  )
}

export function flattenAllFields(files) {
  const flattenFields = []
  const notNilFiles = files || []
  let pageNum = 0
  notNilFiles.forEach((file, fileIndex) => {
    const notNilPages = file?.pages || []
    const isLastFile = fileIndex === notNilFiles.length - 1
    notNilPages.forEach((page, pageIndex) => {
      const isLastPage = pageIndex === notNilPages.length - 1
      flattenFields.push(
        ...page?.fields?.map(field => ({
          ...field,
          page: pageNum,
          isLastPage: isLastFile && isLastPage,
        })),
      )
      pageNum += 1
    })
  })
  return flattenFields
}

export function filterKeysInFields(fields) {
  return fields.map(field =>
    pick(field, ["fieldId", "type", "page", "top", "isLastPage"]),
  )
}

export function getEmptyOrInvalidAssignedFields(state) {
  const allFields = flattenAllFields(state?.files)
  const allEmptyOrInvalidFields = allFields.filter(
    field =>
      isInvalidField(field) ||
      isSignRelatedAndOwnBySignerFieldUnassigned(field),
  )
  const filteredEmptyOrInvalidFields = filterKeysInFields(
    allEmptyOrInvalidFields,
  )
  const sortedFields = sortFieldsByPositionAndPage(filteredEmptyOrInvalidFields)
  return sortedFields
}
