import produce from 'immer'
import {
  SUBMIT_RENTAL_REQUEST,
  SUBMIT_RENTAL_SUCCESS,
  SUBMIT_RENTAL_FAILED,
} from './constants'

export const initialState = {
  isSubmitting: false,
  isSubmitted: false,
}
/* eslint-disable no-param-reassign */
const reviewReportReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SUBMIT_RENTAL_REQUEST:
        draft.isSubmitting = true
        break
      case SUBMIT_RENTAL_SUCCESS:
        draft.isSubmitted = true
        draft.isSubmitting = false
        break
      case SUBMIT_RENTAL_FAILED:
        draft.isSubmitting = false
        break
      default:
        break
    }
    return draft
  })

export default reviewReportReducer
