import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { getOrCreateDraftRentalPayment } from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'
import { selectRentalPaymentState } from '../redux/selectors'

const statusSelector = createSelector(selectRentalPaymentState, state =>
  get(state, 'draftRentalPayment.status'),
)

const dataSelector = createSelector(selectRentalPaymentState, state =>
  get(state, 'draftRentalPayment.data'),
)

export const useGetOrCreateDraftRentalPayment = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const status = useSelector(statusSelector)
  const data = useSelector(dataSelector)

  return {
    status,
    data,
    getOrCreateDraftRentalPayment: payload =>
      new Promise((resolve, reject) => {
        dispatch(getOrCreateDraftRentalPayment({ ...payload, onSuccess: resolve, onError: reject }))
      }),
  }
}
