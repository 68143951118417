import React from "react"

import { Div } from "components/layout/main"
import { P, Small } from "../typography/text-style"

export const Section = ({ children, ...props }) => (
  <Div mb={30} {...props}>
    {children}
  </Div>
)

export const SectionHeader = ({ title, subtitle }) => (
  <Div mb={15}>
    <P strong mb={2}>
      {title}
    </P>
    {subtitle && <Small color="placeholderGrey">{subtitle}</Small>}
  </Div>
)
