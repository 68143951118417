import * as Types from "../../constants/action-types"
import { sweetAlert } from "../../libs/sweet-alert/sweet-alert"

// eslint-disable-next-line no-unused-vars
const sweetAlertMiddleware = store => next => action => {
  switch (action.type) {
    case Types.OPEN_SWEET_ALERT:
      return sweetAlert(action.preset, action.option, action.promise)
    default:
  }
  next(action)
  return null
}

export default sweetAlertMiddleware
