import React from "react"
import isEmpty from "lodash/isEmpty"
import { setLocalItem } from "@rentspree/cookie"
import { MainContent, Div } from "components/layout/main"
import {
  Section,
  SectionContainer,
  SectionHeader,
  FlexBox,
} from "../../components/layout/section"
import { Button } from "../../components/buttons/buttons"
import { PanelBox } from "../../components/panel/panel-box"
import { H3, P, Hr } from "../../components/typography"
import { CreditPaymentBox } from "../../components/pro/credit-payment-box"
import RedeemCouponBox from "../../components/pro/redeem-coupon-box"
import PaymentConnect from "./payment-connect"
import { LoadingOverlay } from "../../components/request/left-content"
import { TrackerInstance as Tracker } from "../../helpers/tracker"
import { FailedToFetch } from "../../components/layout/failed-to-fetch"
import * as ERRORS from "../../constants/error-messages"
import { STORAGE } from "../../../constants/cookie"
import parsePricePayment from "../../helpers/parse-price-payment"

@PaymentConnect
export default class ProPaymentContainer extends React.Component {
  componentDidMount() {
    this.props.actions.getPlans()
  }

  clickToCreditPayment = url => {
    Tracker.trackEvent("clickToCreditPayment")
    if (!isEmpty(this.props.subdomain)) {
      setLocalItem(STORAGE.SUBDOMAIN, this.props.subdomain)
    }
    window.location.href = url
  }

  redeemCoupon = couponCode => {
    Tracker.trackEvent("clickRedeemCoupon")
    this.props.actions.redeemCoupon({ couponCode })
  }

  render() {
    const {
      isFetchingRedeemCoupon,
      subscription,
      errorRedeemCoupon,
      plans,
      isFetchingPlans,
      errorPlans,
      authorize,
    } = this.props.subscriptions
    return (
      <MainContent>
        <Section className="diamondGreyBg">
          <SectionContainer maxWidth="650px">
            <SectionHeader title="Subscribe to RentSpree PRO" />
            <PanelBox header="Plan Detail" boldBorder>
              {isFetchingPlans && <LoadingOverlay className="full" />}
              {errorPlans ? (
                <FailedToFetch
                  noMargin
                  className="-mH100"
                  withSmallHeader
                  text={ERRORS.SUBSCRIPTIONS.UNKNOWN_ERROR.MESSAGE}
                  title={ERRORS.SUBSCRIPTIONS.UNKNOWN_ERROR.TITLE}
                />
              ) : (
                <div>
                  {plans.map(plan => (
                    <CreditPaymentBox
                      key={`${plan.plan_code}`}
                      isSubscribed={!isEmpty(subscription)}>
                      <FlexBox justify="space-between">
                        <H3 margin="15px 0" color="titleBlack">
                          {plan.name}
                        </H3>
                        <FlexBox center className="payment-action">
                          <div className="price">
                            <P size="1.1em" strong margin="0" align="right">
                              {parsePricePayment(plan.unit_amount_in_cents.USD)}{" "}
                              / month
                            </P>
                            {!authorize.isExpires && (
                              <P mb="0" align="right" lineHeight={0.9}>
                                <small>after free trial</small>
                              </P>
                            )}
                          </div>
                          <Div ml={25} center>
                            <Button
                              id="goToPaymentPageLink"
                              primary
                              text={
                                !authorize.isExpires
                                  ? "Start free trial"
                                  : "Go to payment"
                              }
                              minWidth="180px"
                              height="45px"
                              onClick={() =>
                                this.clickToCreditPayment(
                                  plan.url_agent_payment,
                                )
                              }
                            />
                            {!authorize.isExpires && (
                              <P mb="0">
                                <small>Credit card optional for trial.</small>
                              </P>
                            )}
                          </Div>
                        </FlexBox>
                      </FlexBox>
                      <Hr margin="15px -10px" mMargin="30px -5px 20px" />
                    </CreditPaymentBox>
                  ))}
                  <RedeemCouponBox
                    subscription={subscription}
                    isLoading={isFetchingRedeemCoupon}
                    error={errorRedeemCoupon}
                    redeemCoupon={this.redeemCoupon}
                    {...this.props}
                  />
                </div>
              )}
            </PanelBox>
          </SectionContainer>
        </Section>
      </MainContent>
    )
  }
}
