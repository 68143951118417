import React from "react"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import S18 from "@rentspree/component-v2/dist/typography/s18"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import TextPanel from "@rentspree/component-v2/dist/alert"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import Button from "@rentspree/component-v2/dist/button"
import { ButtonGroup } from "components/shared/form"
import { Ul, Li } from "components/atoms/list"
import { AlertPanel } from "components/shared/alert-panel"
import { Div } from "components/layout/main"
import styled from "styled-components"

const ShareReportButton = styled(BlueGradientButton)`
  width: 140px !important;
  border-radius: 36px;
`

const ConfirmationInfo = ({
  recipient,
  address,
  onClickBack,
  onClickShare,
  isLoading,
  isError,
  closeError,
}) => (
  <Div height="100%">
    <S24 margin="0px 0px 0px 0px">Please read before sharing</S24>
    {isError && (
      <AlertPanel
        error
        handleClose={closeError}
        padding="10px 15px"
        margin="15px 0 0 0"
      />
    )}
    <B16 margin="25px 0px 5px 0px">
      <b>Recipient: </b>
      {recipient}
    </B16>
    <B16 margin="0px 0px 30px 0px">
      <b>Address: </b>
      {address}
    </B16>
    <TextPanel
      info
      style={{
        flexDirection: "column",
        alignItems: "start",
        padding: "15px 20px 20px 20px",
        margin: "0 0 30px 0",
      }}>
      <S18 margin="0px 0px 12px 0px" color="#007adf">
        Important! Please read
      </S18>
      <B16>
        <Ul>
          <Li>
            Screening reports (per applicant) may only be shared with{" "}
            <b>one other person you authorize</b>.
          </Li>
          <Li>
            The authorized person will have access to the reports{" "}
            on RentSpree.
          </Li>
          <Li>Sharing cannot be undone after this step.</Li>
        </Ul>
      </B16>
    </TextPanel>
    <ButtonGroup>
      <Button
        google
        small
        semiBold
        fontSize="16px"
        id="share-report-confirm-back-btn"
        className="left-btn"
        text="Back"
        type="button"
        onClick={onClickBack}
        disabled={isLoading}
      />
      <ShareReportButton
        semiBold
        fontSize="16px"
        id="share-report-confirm-share-btn"
        className="right-btn"
        text="Share reports"
        type="button"
        onClick={onClickShare}
        loading={isLoading}
      />
    </ButtonGroup>
  </Div>
)

export default ConfirmationInfo
