/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
export function parseProfileToStatsigUser(profile, subdomain) {
  return {
    userID: profile?._id || 'default-id',
    email: profile.email,
    custom: {
      is_pro: profile?.is_pro,
      isInDesignConceptExperiment: profile?.isInDesignConceptExperiment,
      subdomain,
      userCreatedAt: profile?.createdAt,
      externalIntegration: !!profile?.externalIntegration,
      isLinkedPartner: !!profile?.isLinkedPartner,
      registeredUserType: profile?.registeredUserType,
    },
  }
}
