import { buildPath } from "@rentspree/path"
import { VERIFY_PROPERTY_ADDRESS_API } from "constants/route"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { apiInstance } from "utils/api-interceptor"
import { verifyPropertyAddressState } from "./actions"
import { VERIFY_PROPERTY_ADDRESS_CALL } from "./constants"

export const verifyPropertyAddress = payload =>
  apiInstance.post(buildPath(VERIFY_PROPERTY_ADDRESS_API), payload)

export function* verifyPropertyAddressSaga({ payload }) {
  yield put(verifyPropertyAddressState.request())
  try {
    const response = yield call(verifyPropertyAddress, payload)
    yield put(verifyPropertyAddressState.success(response))
  } catch (error) {
    yield put(verifyPropertyAddressState.failure())
  }
}

export function* watchVerifyPropertyAddress() {
  yield takeLatest(VERIFY_PROPERTY_ADDRESS_CALL, verifyPropertyAddressSaga)
}

export function* rootSaga() {
  yield all([watchVerifyPropertyAddress()])
}

export default rootSaga