export const SUBSCRIPTION_PLAN = {
  FREE: "FREE",
  PRO: "PRO",
}
export const SUBSCRIPTION_PERIOD = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
}

export const PRO_PLAN_VARIANTS = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  MONTHLY_PRO_EARLY_ADOPTER_DISCOUNT: "Monthly (Early Adopter Discount)",
}