import React from 'react'
import get from 'lodash/get'
import { PrintPageBreak } from 'components/reports/screening-reports/share/responsive-doc-style'
import { SocialDetail } from './doc-components/social-detail'
import { Identification } from './doc-components/identification'
import { Residence } from './doc-components/residence'
import { Summary } from './doc-components/summary'
import { CourtAction } from './doc-components/court-action'
import { OffenseCase } from './doc-components/offense-case'
import IdentityHeader from './doc-components/identity-header'
import IdentityPersonalInformation from './doc-components/identity-personal-info'
import PhysicalInformation from './doc-components/physical-info'

const CriminalIdentity = ({ identity, index }) => {
  const citizenship = get(identity, 'Citizenship', '-')
  const ethnicity = get(identity, 'Ethnicity', '-')
  const ssn = get(identity, 'Ssn', '-')
  const driversLicenseNumber = get(identity, 'DriversLicenseNumber', '-')
  const driversLicenseState = get(identity, 'DriversLicenseState', '-')
  const driversLicenseExpirationYear = get(identity, 'DriversLicenseExpirationYear', '-')
  const address1 = get(identity, 'Address1', '-')
  const address2 = get(identity, 'Address2')
  const city = get(identity, 'City', '-')
  const state = get(identity, 'State', '-')
  const postalCode = get(identity, 'PostalCode', '-')
  const county = get(identity, 'County', '-')
  const recordNumber = get(identity, 'CriminalIdNumber', '-')
  const dataset = get(identity, 'StateKey', '-')

  const incidentCount = get(identity, 'IncidentCount', 0)
  const arrestCount = get(identity, 'ArrestCount', 0)
  const bookingCount = get(identity, 'BookingCount', 0)
  const courtActionCount = get(identity, 'CourtActionCount', 0)
  const sentencingCount = get(identity, 'SentencingCount', 0)
  const supervisionCount = get(identity, 'SupervisionCount', 0)

  const criminalCase = get(identity, 'Case', {})
  const offenses = get(criminalCase, 'Offenses') || get(identity, 'Offenses', [])
  const caseNumber = get(criminalCase, 'CaseNumber')
  const disposition = get(offenses[0], 'DispositionDescription')
  const dispositionDate = get(offenses[0], 'DispositionDate')
  const title = get(criminalCase, 'Title')
  const filingAgency = get(criminalCase, 'FilingAgency')
  const filingDate = get(criminalCase, 'FilingDate')
  const type = get(criminalCase, 'Type')
  const completionDate = get(criminalCase, 'CompletionDate')
  const criminalCounty = get(criminalCase, 'County')
  const jurisdiction = get(criminalCase, 'Jurisdiction')
  const statusBeginDate = get(criminalCase, 'StatusBeginDate')
  const statusDate = get(criminalCase, 'StatusDate')
  const statusDescription = get(criminalCase, 'StatusDescription')
  const statusEndDate = get(criminalCase, 'StatusEndDate')
  const chargeDisposition = get(offenses, '[0].Status', '-')

  const identityHeaderProps = {
    index: index + 1,
    state: identity.SourceState,
    category: identity.ProductType,
    criminalId: identity.CriminalIdNumber,
    lastUpdated: identity.DateTimeModified,
  }

  const identityPersonalInfoProps = {
    age: identity.Age,
    url: identity.ImageUrl,
    suffix: identity.Suffix,
    fullName: identity.FullName,
    lastName: identity.LastName,
    firstName: identity.FirstName,
    birthDate: identity.BirthDate,
    middleName: identity.MiddleName,
    birthPlace: identity.BirthPlace,
  }

  const identificationProps = {
    ssn,
    driversLicenseState,
    driversLicenseNumber,
    driversLicenseExpirationYear,
  }

  const residenceProps = { address1, address2, city, state, postalCode, county }

  const physicalDetails = {
    eye: identity.Eye,
    hair: identity.Hair,
    height: identity.Height,
    weight: identity.Weight,
    race: identity.Race,
    sex: identity.Sex,
    bodyBuild: identity.BodyBuild,
  }

  const physicalFeatures = {
    complexion: identity.Complexion,
    scarMarkTattoo: identity.ScarMarkTattoo,
  }

  const summaryProps = {
    arrestCount,
    bookingCount,
    incidentCount,
    sentencingCount,
    courtActionCount,
    supervisionCount,
  }

  const courtAction = {
    type,
    title,
    caseNumber,
    filingDate,
    statusDate,
    disposition,
    filingAgency,
    jurisdiction,
    statusEndDate,
    completionDate,
    dispositionDate,
    statusBeginDate,
    statusDescription,
    county: criminalCounty,
    recordNumber,
    dataset,
    chargeDisposition,
  }

  return (
    <div key={`criminal-record-${index + 1}`}>
      <div>
        <IdentityHeader {...identityHeaderProps} />
        <IdentityPersonalInformation {...identityPersonalInfoProps} />
      </div>
      <SocialDetail citizenship={citizenship} ethnicity={ethnicity} />
      <Identification {...identificationProps} />
      <Residence {...residenceProps} />
      <PhysicalInformation physicalDetails={physicalDetails} physicalFeatures={physicalFeatures} />
      <PrintPageBreak />
      <Summary {...summaryProps} />
      <CourtAction {...courtAction} />
      <PrintPageBreak />
      {offenses.map(offense => (
        <OffenseCase offenseCase={offense} />
      ))}
    </div>
  )
}

export default CriminalIdentity
