import MuiBox from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import Drawer2023 from '@rentspree/component-2023.components.organisms.drawer'
import Sheet, {
  MultipleStepSheetHeader,
} from '@rentspree/component-2023.components.templates.sheet'
import React from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

export const Drawer = ({
  isOpen,
  title,
  subHeader,
  anchor = 'right',
  size = 'medium',
  children,
  closeButtonText = 'Close',
  hideCloseButton = false,
  disableCloseButton = false,
  continueButtonText = 'Continue',
  disableContinueButton = false,
  onContinue,
  onClose,
}) => {
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  const cta = (
    <MuiBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {!hideCloseButton && (
        <Button
          variant="text"
          color="secondary"
          size="small"
          className="underline"
          disabled={disableCloseButton}
          onClick={onClose}
        >
          {closeButtonText}
        </Button>
      )}
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onContinue}
        disabled={disableContinueButton}
      >
        {continueButtonText}
      </Button>
    </MuiBox>
  )

  const drawerActionsProps = {
    primaryButtonText: continueButtonText,
    primaryButtonProps: {
      onClick: onContinue,
      disabled: disableContinueButton,
    },
    ...(hideCloseButton
      ? {}
      : {
          secondaryButtonText: closeButtonText,
          secondaryButtonProps: { onClick: onClose, disabled: disableCloseButton },
        }),
  }

  return isMobile ? (
    <Sheet actionSection={cta} open={isOpen} onClose={onClose}>
      <MuiBox sx={{ height: '100vh' }}>
        <MultipleStepSheetHeader header={title} subHeader={subHeader} />
        <MuiBox sx={{ padding: '0 16px' }}>{children}</MuiBox>
      </MuiBox>
    </Sheet>
  ) : (
    <Drawer2023
      title={title}
      subHeader={subHeader}
      displayCloseButton
      anchor={anchor}
      size={size}
      actionButtonsProps={drawerActionsProps}
      open={isOpen}
      onClose={onClose}
    >
      <MuiBox paddingTop="16px">{children}</MuiBox>
    </Drawer2023>
  )
}
