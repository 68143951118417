import React from "react"
import TitleWithDescription from "components/molecules/title-description"
import styled from "styled-components"
import Scroll from "react-scroll"
import { AlertPanel } from "components/shared/alert-panel"

const scroll = Scroll.animateScroll

export const CenterContentTemplateStyle = styled.div`
  width: 100%;
  padding: ${props => (props.isError ? `40px 15px` : `50px 15px`)};
  font-family: Source Sans Pro;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 991px) {
    ${props =>
      !props.noHeightCal &&
      `height: ${props.heightCal || "calc(100vh - 105px - 60px - 10px)"};`}
  }
  @media screen and (max-width: 767px) {
    padding: 30px 20px;
    display: block;
  }
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`

export const ContentStyle = styled.div`
  min-width: 500px;
  max-width: 500px;
  @media screen and (max-width: 767px) {
    display: contents;
    min-width: 320px;
    max-width: 767px;
  }
`

export const CenterContentTemplate = ({
  title,
  description,
  children,
  isError,
  errorDetail,
  handleCloseError,
  noHeightCal,
  descriptionStyle,
}) => {
  if (isError) {
    scroll.scrollToTop({ smooth: true })
  }
  return (
    <>
      <CenterContentTemplateStyle isError={isError} noHeightCal={noHeightCal}>
        <ContentStyle>
          {isError && (
            <Center>
              <AlertPanel
                error
                handleClose={handleCloseError}
                padding="10px"
                margin="10px 0 0"
                alertDetail={errorDetail}
              />
            </Center>
          )}
          <TitleWithDescription
            title={title}
            description={description}
            descriptionStyle={descriptionStyle}
          />

          {children}
        </ContentStyle>
      </CenterContentTemplateStyle>
    </>
  )
}

export default CenterContentTemplate
