import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { getMyUserInvitation } from 'v3/services/user-invitation'

/**
 * @typedef {object} QueryParams
 * @property {import('v3/services/user-invitation').GetMyInvitationParams=} queryParams the api query param
 * @typedef {import('@tanstack/react-query').UseQueryOptions<Awaited<ReturnType<typeof getMyUserInvitation>>>} UseQueryOptions
 * @typedef {UseQueryOptions & QueryParams} UseGetMyUserInvitationParams
 */

/**
 * useGetMyUserInvitation get my user invitation using my userId.
 *
 * @param {UseGetMyUserInvitationParams} options
 */
export const useGetMyUserInvitation = (options = {}) => {
  const userProfile = useSelector(selectProfile)

  return useQuery({
    queryKey: ['getMyUserInvitation', userProfile?.id, options.queryParams],
    // 30 mins
    staleTime: 1800000,
    queryFn: () => getMyUserInvitation(options.queryParams),
    refetchOnWindowFocus: false,
    ...options,
  })
}
