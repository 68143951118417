import React from "react"
import styled from "styled-components"
import ZeroState from "components/templates/zero-state"
import Button from "@rentspree/component-v2/dist/button"
import { buildPath } from "@rentspree/path"
import { SINGLE_APPLICATION_PAYMENT_PROCESSING } from "constants/route"
import ErrorIcon from "images/errors/general-error-icon.svg"
import { COLOR } from "styles/settings"

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  color: black;
  @media (max-width: 991px) {
    min-height: calc(100vh - 195px);
  }
`

const SubtitleWithCTA = onClick => (
  <>
    We can&apos;t update your information right now. Please try again later or
    contact us at (323) 515-7757 or support team.
    <Button
      center
      small
      semiBold
      google
      text="Try Again"
      height="40px"
      margin="30px auto"
      fontSize="16px"
      color={COLOR.textBlack}
      sourceSansPro
      onClick={onClick}
    />
  </>
)

const PaymentUpdatingLanding = ({ match, history }) => {
  const { propertyId, rentalAppId } = match.params
  return (
    <Wrapper>
      <ZeroState
        icon={ErrorIcon}
        iconMargin={20}
        title="Payment not confirmed"
        subtitleColor={COLOR.newSteelBlue}
        subtitle={SubtitleWithCTA(() =>
          history.push(
            buildPath(SINGLE_APPLICATION_PAYMENT_PROCESSING, {
              propertyId,
              rentalAppId,
              process: "complete",
            }),
          ),
        )}
      />
    </Wrapper>
  )
}

export default PaymentUpdatingLanding
