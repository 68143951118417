import { RECIPIENT_STATUS } from 'containers/envelope/constants'
import { SigningMode } from './constants'

// TODO: this function could be remove if we improve Envelope.Recipients Schema by adding new status = 'SENT'
export const isRecipientReadyToSign = (recipients, recipient, signingOrderMode) => {
  if (
    !signingOrderMode ||
    signingOrderMode === SigningMode.Everyone ||
    recipient.signingOrder <= 1
  ) {
    return true
  }
  const previousSignerOrder = recipient.signingOrder - 1
  const isPreviousSignerRemain = recipients.some(
    r => r.signingOrder === previousSignerOrder && r.status === RECIPIENT_STATUS.PENDING,
  )
  if (!isPreviousSignerRemain) {
    return true
  }
  return false
}
