export const KEY = 'addressVerification'

export const VERIFY_PROPERTY_ADDRESS_CALL = "VERIFY_PROPERTY_ADDRESS_CALL"
export const VERIFY_PROPERTY_ADDRESS_REQUEST = "VERIFY_PROPERTY_ADDRESS_REQUEST"
export const VERIFY_PROPERTY_ADDRESS_SUCCESS = "VERIFY_PROPERTY_ADDRESS_SUCCESS"
export const VERIFY_PROPERTY_ADDRESS_FAILURE = "VERIFY_PROPERTY_ADDRESS_FAILURE"
export const RESET_ADDRESS_VERIFICATION = "RESET_ADDRESS_VERIFICATION"

export const SELECT_OPTION = {
  ENTERED_ADDRESS: 'entered_address',
  STANDARDIZED_ADDRESS: 'standardized_address'
}