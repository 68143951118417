import React from 'react'

import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import { INCORRECT_INFO_TEXT } from 'components/reports/screening-reports/share/constants'
import { addUnitFormat, convert } from 'helpers/convert'
import { COLOR } from 'styles/settings'
import { Icon } from 'legacy/components/reports-v2/share/doc-style'
import { generateAkas } from 'legacy/components/reports-v2/share/function'
import { COMPANY_INFO } from 'legacy/constants/report-consts'
import {
  ReportHeaderContainer,
  ReportLogo,
  DocPanel,
  ReportHeaderInformation,
  ReportInformationArea,
  ReportHeaderType,
  ReportHeaderDetail,
  ApplicantOverviewContainer,
  ApplicantName,
  ContactInformationContainer,
  ContactInformation,
  IncorrectInfo,
  ApplicantTypeContainer,
  InformationPanel,
  InformationAreaContainer,
  Label,
  Value,
  InformationLineContainer,
  Content,
  HighlightLabel,
  SubSectionHeaderContainer,
  SubSectionTitle,
  SectionHeaderContainer,
  SectionTitle,
  ApplicantAka,
  RentspreeFooterContainer,
  RentspreeContactContainer,
  RentspreeFooterLine,
  ReportHeaderInformationContainer,
  ReportHeaderCustomerInformationContainer,
  CustomerInformationHeader,
  CustomerInformationDetail,
  CustomerInformationContainer,
} from './responsive-doc-style'

const rentImg = require('images/logos/rentspree-logo.svg')
const transImg = require('legacy/images/credit-report/TU-logo.png')

export const ReportHeader = ({
  label,
  submittedAt,
  generatedOn,
  expiresOn,
  ssnMessage,
  isTransUnionReport,
  renter,
  landlord,
  reportId,
  CustomLogo,
}) => {
  const formatDateWithTimezone = 'MMM DD, YYYY hh:mm A z ZZ'

  return (
    <ReportHeaderContainer>
      <ReportHeaderInformationContainer>
        {CustomLogo ? (
          <CustomLogo />
        ) : (
          <ReportLogo
            data-testid={isTransUnionReport ? 'tu-logo' : 'rent-img'}
            src={isTransUnionReport ? transImg : rentImg}
          />
        )}
        <ReportHeaderInformation>
          <ReportInformationArea>
            <ReportHeaderType data-testid="report-type">{label}</ReportHeaderType>
            {submittedAt && (
              <ReportHeaderDetail data-testid="submitted-at">
                Submitted on {convert(submittedAt, 'dateOfUTC', formatDateWithTimezone)}
              </ReportHeaderDetail>
            )}
            {generatedOn && (
              <ReportHeaderDetail data-testid="generated-on">
                Generated on {convert(generatedOn, 'dateOfUTC', formatDateWithTimezone)}
              </ReportHeaderDetail>
            )}
            {expiresOn && (
              <ReportHeaderDetail data-testid="expires-on">
                Expires on {convert(expiresOn, 'dateOfUTC', formatDateWithTimezone)}
              </ReportHeaderDetail>
            )}
            {ssnMessage && (
              <ReportHeaderDetail data-testid="ssn-message" color={COLOR.newBlack}>
                SSN Message: {ssnMessage}
              </ReportHeaderDetail>
            )}
            {reportId && (
              <ReportHeaderDetail data-testid="report-id">Report ID: {reportId}</ReportHeaderDetail>
            )}
          </ReportInformationArea>
        </ReportHeaderInformation>
      </ReportHeaderInformationContainer>
      <ReportHeaderCustomerInformation applicantInfo={renter} landlordInfo={landlord} />
    </ReportHeaderContainer>
  )
}

export const ReportHeaderCustomerInformation = ({ applicantInfo, landlordInfo }) => (
  <ReportHeaderCustomerInformationContainer>
    {applicantInfo && (
      <CustomerInformationContainer>
        <CustomerInformationHeader>Information submitted by Applicant:</CustomerInformationHeader>
        <CustomerInformationDetail>{applicantInfo.fullName}</CustomerInformationDetail>
        {applicantInfo?.address?.streetAddressLineOne && (
          <CustomerInformationDetail>
            {applicantInfo?.address?.streetAddressLineOne}
          </CustomerInformationDetail>
        )}
        {applicantInfo?.address?.streetAddressLineTwo && (
          <CustomerInformationDetail>
            {applicantInfo?.address?.streetAddressLineTwo}
          </CustomerInformationDetail>
        )}
        {applicantInfo?.address && (
          <CustomerInformationDetail>
            {applicantInfo?.address?.city}, {applicantInfo?.address?.state}{' '}
            {applicantInfo?.address?.zip}
          </CustomerInformationDetail>
        )}
      </CustomerInformationContainer>
    )}
    {landlordInfo && (
      <CustomerInformationContainer>
        <CustomerInformationHeader>Information requested by:</CustomerInformationHeader>
        <CustomerInformationDetail>{landlordInfo.fullName}</CustomerInformationDetail>
      </CustomerInformationContainer>
    )}
  </ReportHeaderCustomerInformationContainer>
)

export const ApplicantOverview = ({ name, email, phone, akas, withIncorrectInfo }) => (
  <ApplicantOverviewContainer>
    <ApplicantName>{name}</ApplicantName>
    {!isEmpty(akas) && <ApplicantAka>aka. {generateAkas(akas)}</ApplicantAka>}
    <ContactInformationContainer>
      <ContactInformation>
        <Icon className="icon-envelope" />
        {email}
      </ContactInformation>
      <ContactInformation>
        <Icon className="icon-phone" />
        {phone}
      </ContactInformation>
    </ContactInformationContainer>
    {withIncorrectInfo && <IncorrectInfo>{INCORRECT_INFO_TEXT}</IncorrectInfo>}
  </ApplicantOverviewContainer>
)

export const ApplicantType = ({ type }) => (
  <ApplicantTypeContainer>Submitting application as {type}</ApplicantTypeContainer>
)

export const ReportsBody = ({ children }) => <DocPanel>{children}</DocPanel>

export const SectionHeader = ({ title }) => (
  <SectionHeaderContainer>
    <SectionTitle>{title}</SectionTitle>
  </SectionHeaderContainer>
)

export const SubSectionHeader = ({ title }) => (
  <SubSectionHeaderContainer>
    <SubSectionTitle>{title}</SubSectionTitle>
  </SubSectionHeaderContainer>
)

export const InformationLine = ({
  label,
  value,
  isHighlightLabel = false,
  labelWidth = '110px',
  mobileGap,
  boldValue = false,
}) => (
  <InformationLineContainer mobileGap={mobileGap}>
    {isHighlightLabel ? (
      <HighlightLabel width={labelWidth}>{label}</HighlightLabel>
    ) : (
      <Label width={labelWidth}>{label}</Label>
    )}
    <Value isBold={boldValue}>{value}</Value>
  </InformationLineContainer>
)

export const InformationArea = ({
  label,
  value,
  isHighlightLabel = false,
  isAreaOnMobile = false,
  labelWidth,
}) => {
  const adjustedLabelWidth = labelWidth || '100%'
  const mobileWidth = isAreaOnMobile ? '100%' : labelWidth || '110px'
  return (
    <InformationAreaContainer isAreaOnMobile={isAreaOnMobile}>
      {isHighlightLabel ? (
        <HighlightLabel width={adjustedLabelWidth} mobileWidth={mobileWidth}>
          {label}
        </HighlightLabel>
      ) : (
        <Label width={adjustedLabelWidth} mobileWidth={mobileWidth}>
          {label}
        </Label>
      )}
      <Value>{value}</Value>
    </InformationAreaContainer>
  )
}

export const PersonalDetail = ({
  firstName = '',
  lastName = '',
  email,
  relationship,
  address,
  occupation,
  phoneNumber,
  acquaintanceLength,
  hasAcquaintanceLength,
  isSkipEmail = false,
  phoneFormatter = convert,
}) => (
  <Content>
    <InformationPanel>
      <InformationLine label="Name" value={`${firstName} ${lastName}`.trim()} />
      {!isEmpty(phoneNumber) && (
        <InformationLine label="Phone no." value={phoneFormatter(phoneNumber, 'phoneNumber')} />
      )}
      <InformationLine label="Email" value={isSkipEmail || !email ? '-' : email} />
      {!isEmpty(relationship) && (
        <InformationLine label="Relationship" value={relationship || '-'} />
      )}
      {!isEmpty(occupation) && <InformationLine label="Occupation" value={occupation || '-'} />}
      {hasAcquaintanceLength && (
        <InformationLine
          label="Length of Acquaintance"
          value={isNumber(acquaintanceLength) ? addUnitFormat(acquaintanceLength, 'Years') : '-'}
        />
      )}
    </InformationPanel>
    {address && (
      <InformationPanel>
        <InformationArea label="Address" value={address} />
      </InformationPanel>
    )}
  </Content>
)

export const RentspreeFooter = () => {
  const rentspree = COMPANY_INFO.find(company => company.title === 'RentSpree')
  return (
    <RentspreeFooterContainer>
      <ReportLogo data-testid="rent-img" src={rentImg} />
      <RentspreeContactContainer>
        <RentspreeFooterLine>{rentspree?.address}</RentspreeFooterLine>
        <RentspreeFooterLine>{rentspree?.phone}</RentspreeFooterLine>
        <RentspreeFooterLine>{rentspree?.email}</RentspreeFooterLine>
      </RentspreeContactContainer>
    </RentspreeFooterContainer>
  )
}
