import { ButtonIcon } from "@rentspree/component-v2"
import React from "react"
import styled from "styled-components"
import agentProfileIcon from "images/listing-management/agent-profile.svg"
import rightPatternIcon from "images/listing-management/right-pattern.svg"
import leftPatternIcon from "images/listing-management/left-pattern.svg"
import arrowPatternIcon from "images/listing-management/arrow-pattern.svg"
import { Div } from "components/layout/main"
import { MOBILE_WIDTH } from "styles/settings"
import {
  CREATE_AGENT_PROFILE_BUTTON_TEXT,
  SUBTITLE_TEXT,
  PROFILE_BANNER_TEXT,
  SUBTITLE_TEXT_MOBILE,
  X_LARGE_WIDTH,
  AP_BANNER_HEIGHT,
  AP_BANNER_MAX_WIDTH,
} from "./constants"

const StickyWrapper = styled.div`
  position: sticky;
  bottom: 0;
  height: ${AP_BANNER_HEIGHT}px;

  @media (max-width: ${MOBILE_WIDTH}) {
    height: 175px;
    margin-left: 0;
  }
`

const ProfileBannerWrapper = styled.div`
  position: absolute;
  height: ${AP_BANNER_HEIGHT}px;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #e2f0ff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  justify-content: flex-end;

  @media (min-width: ${X_LARGE_WIDTH}px) {
    left: 50%;
    margin-left: ${props => props.largeMarginLeft};
    max-width: ${AP_BANNER_MAX_WIDTH}px;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    height: 175px;
  }
`

const TextWrapper = styled.div`
  margin-left: 55px;

  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 20px 40px 0 17px;
  }
`

const SubtitleText = styled.div`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 5px;

  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`

const SubtitleTextMobile = styled.div`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 5px;

  @media (min-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`

const BannerText = styled.div`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;

  @media (max-width: ${MOBILE_WIDTH}) {
    font-size: 12px;
    line-height: 16px;
  }
`

const CreateButtonWrapper = styled.div`
  margin-right: 80px;
  margin-left: 30px;

  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 20px 17px 20px 17px;
    left: 115px;
    bottom: 20px;
    align-self: flex-start;
  }
`

const CreateButton = styled.button`
  align-items: center;
  width: 189px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 20px;

  @media (max-width: ${MOBILE_WIDTH}) {
    width: 189px;
    height: 40px;
  }
`

const CreateButtonText = styled.text`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
`

const AgentProfileIcon = styled.img`
  position: absolute;
  width: 213.68px;
  height: 150px;
  left: 12.44px;
  top: 16px;
  z-index: 1;

  @media (max-width: ${MOBILE_WIDTH}) {
    width: 129px;
    height: 90px;
    left: -31px;
    top: 43px;
  }
`

const ArrowPatternIcon = styled.img`
  position: absolute;
  left: 222.74px;
  top: 35.65px;
  z-index: 1;

  @media (max-width: ${MOBILE_WIDTH}) {
    left: 69.31px;
    top: 16.78px;
  }
`

const RightPatternIcon = styled.img`
  width: 70.6px;
  height: 22.34px;
  bottom: 1.66px;
  right: 23px;
  position: absolute;
  opacity: 0.32;
  z-index: 1;

  @media (max-width: ${MOBILE_WIDTH}) {
    right: -5.6px;
    bottom: -5.34px;
  }
`
const LeftPatternIcon = styled.img`
  position: absolute;
  left: 48.15px;
  top: 15.47px;

  @media (max-width: ${MOBILE_WIDTH}) {
    visibility: hidden;
  }
`

const RightRectangleIcon = styled.rect`
  position: absolute;
  box-sizing: border-box;
  width: 622.4px;
  height: 719.98px;
  right: -727.5px;
  bottom: -304px;

  background: #e2f0ff;
  opacity: 0.5;
  border: 1px solid #3b99fc;
  border-radius: 14px;
  transform: rotate(45deg);

  @media (max-width: ${MOBILE_WIDTH}) {
    right: -731.8px;
    bottom: -288.68px;
  }
`

const LeftRectangleIcon = styled.rect`
  position: absolute;
  box-sizing: border-box;
  width: 500.96px;
  height: 579.5px;
  left: -379.5px;
  top: -226.8px;

  background: #e2f0ff;
  opacity: 0.5;
  border: 1px solid #3b99fc;
  border-radius: 14px;
  transform: rotate(45deg);

  @media (max-width: ${MOBILE_WIDTH}) {
    left: -529.5px;
    top: -240px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  width: 15px;
  height: 15px;
  right: 14.5px;
  top: 14.5px;
  z-index: 1;
  padding: 0;
  background-color: transparent;
  border-style: none;
`

const IconWrapper = styled.div`
  width: 222.74px;
  margin-right: 50px;

  @media (max-width: ${MOBILE_WIDTH}) {
    margin-right: unset;
  }
`

export const AgentProfileBanner = props => (
  <StickyWrapper ref={props.wrapperRef}>
    <ProfileBannerWrapper
      id="apMainContent"
      largeMarginLeft={props.largeMarginLeft}>
      <IconWrapper>
        <LeftRectangleIcon />
        <LeftPatternIcon src={leftPatternIcon} />
        <ArrowPatternIcon src={arrowPatternIcon} />
        <AgentProfileIcon src={agentProfileIcon} />
      </IconWrapper>
      <Div flex listingWrapper alignItems="center">
        <TextWrapper>
          <SubtitleText>{SUBTITLE_TEXT}</SubtitleText>
          <SubtitleTextMobile>{SUBTITLE_TEXT_MOBILE}</SubtitleTextMobile>
          <BannerText>{PROFILE_BANNER_TEXT}</BannerText>
        </TextWrapper>
        <CreateButtonWrapper>
          <CreateButton
            onClick={props.handleCreateButtonClick}
            id="apCreateButton">
            <CreateButtonText>
              {CREATE_AGENT_PROFILE_BUTTON_TEXT}
            </CreateButtonText>
          </CreateButton>
        </CreateButtonWrapper>
      </Div>
      <CloseButton onClick={props.handleCloseBannerClick} id="apCloseButton">
        <ButtonIcon className="fa fa-times" />
      </CloseButton>
      <RightPatternIcon src={rightPatternIcon} />
      <RightRectangleIcon />
    </ProfileBannerWrapper>
  </StickyWrapper>
)

export default AgentProfileBanner
