import { createSelector } from "reselect"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { initialState } from "./reducer"

const selectRentEstimate = ({ rentEstimate }) => rentEstimate || initialState
const selectCreditReport = ({ creditReport }) => creditReport
const selectUser = ({ user }) => user
const selectProperty = ({ property }) => property

const makeSelectRentEstimatePayment = () =>
  createSelector(
    selectRentEstimate,
    ({ rentEstimatePaymentData }) => rentEstimatePaymentData,
  )
const makeSelectRentEstimate = () =>
  createSelector(
    selectRentEstimate,
    ({ rentEstimateData }) => rentEstimateData,
  )
const makeSelectIsFetchingPaymentRentEstimate = () =>
  createSelector(
    selectRentEstimate,
    ({ isFetchingPaymentRentEstimate }) => isFetchingPaymentRentEstimate,
  )
const makeSelectIsSubmitingRentEstimate = () =>
  createSelector(
    selectRentEstimate,
    ({ isSubmitingRentEstimate }) => isSubmitingRentEstimate,
  )
const makeSelectDisablePaymentBtn = () =>
  createSelector(
    selectCreditReport,
    ({ disabledPaymentBtn }) => disabledPaymentBtn,
  )
const makeSelectRentEstimateError = () =>
  createSelector(
    selectRentEstimate,
    ({ rentEstimateError = null }) => rentEstimateError,
  )
const makeSelectRentEstimatePaymentError = () =>
  createSelector(
    selectRentEstimate,
    ({ rentEstimatePaymentError }) => rentEstimatePaymentError,
  )
const makeSelectPaymentModal = () =>
  createSelector(
    selectRentEstimate,
    ({ paymentModal }) => paymentModal,
  )
const makeSelectModalSuccess = () =>
  createSelector(
    selectRentEstimate,
    ({ modalSuccess }) => modalSuccess,
  )
const makeSelectUserEmail = () =>
  createSelector(
    selectUser,
    ({ profile }) => get(profile, "email"),
  )

const makeSelectPropertyForRentEstimate = () =>
  createSelector(
    selectProperty,
    ({ property = {} }) => {
      if (!isEmpty(property)) {
        const { state, street, city, unitNumber, zip } = property
        return {
          streetAddress: street,
          unitNumber,
          city,
          state,
          zipCode: zip,
        }
      }
      return {}
    },
  )
const makeSelectPropertyFetchSuccess = () =>
  createSelector(
    selectProperty,
    ({ property = {} }) => property.fetchPropertySuccess || false,
  )

const selectSubdomain = ({ subdomain }) => get(subdomain, "subdomain.sub", null)

export {
  selectRentEstimate,
  makeSelectRentEstimatePayment,
  makeSelectIsSubmitingRentEstimate,
  makeSelectIsFetchingPaymentRentEstimate,
  makeSelectDisablePaymentBtn,
  makeSelectRentEstimate,
  makeSelectRentEstimateError,
  makeSelectRentEstimatePaymentError,
  makeSelectPaymentModal,
  makeSelectModalSuccess,
  makeSelectUserEmail,
  makeSelectPropertyForRentEstimate,
  makeSelectPropertyFetchSuccess,
  selectSubdomain,
}
