import { takeLatest, put, call, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { alertPrintAndDownloadNotHaveURL } from "containers/envelope/actions"
import { locationReload } from "legacy/utils/window"
import {
  getAcceptAgreementAPIState,
  acceptAgreementAPIState,
  getAgreementContentAPIState,
} from "./actions"
import { apiInstance } from "../../utils/api-interceptor"
import { openSweetAlertBaseError } from "../../utils/sweet-alert-actions"
import {
  GET_ACCEPT_AGREEMENT_CALL,
  GET_AGREEMENT_ENDPOINT,
  ACCEPT_AGREEMENT_CALL,
  ACCEPT_AGREEMENT_ENDPOINT,
  GET_AGREEMENT_CONTENT_CALL,
  GET_AGREEMENT_CONTENT_ENDPOINT,
} from "./constants"

export const getAgreementAPI = ({ type }) =>
  apiInstance.get(buildPath(GET_AGREEMENT_ENDPOINT, { type }))

export const acceptAgreementAPI = ({ type, version }) =>
  apiInstance.post(buildPath(ACCEPT_AGREEMENT_ENDPOINT, { type }), { version })

export const getAgreementContentAPI = ({ type }) =>
  apiInstance.get(buildPath(GET_AGREEMENT_CONTENT_ENDPOINT, { type }))

export function* getAcceptAgreementSaga({ payload }) {
  yield put(getAcceptAgreementAPIState.request())
  try {
    const response = yield call(getAgreementAPI, payload)
    yield put(getAcceptAgreementAPIState.success(response))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(getAcceptAgreementAPIState.failure(err))
  }
}

export function* acceptAgreementSaga({ payload }) {
  yield put(acceptAgreementAPIState.request())
  try {
    yield call(acceptAgreementAPI, payload)
    yield put(acceptAgreementAPIState.success())
  } catch (err) {
    yield put(alertPrintAndDownloadNotHaveURL(locationReload))
    yield put(acceptAgreementAPIState.failure(err))
  }
}

export function* getAgreementContentSaga({ payload }) {
  yield put(getAgreementContentAPIState.request())
  try {
    const response = yield call(getAgreementContentAPI, payload)
    yield put(getAgreementContentAPIState.success(response))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(getAgreementContentAPIState.failure(err))
  }
}

export function* watchGetAcceptAgreement() {
  yield takeLatest(GET_ACCEPT_AGREEMENT_CALL, getAcceptAgreementSaga)
}

export function* watchCallAcceptAgreement() {
  yield takeLatest(ACCEPT_AGREEMENT_CALL, acceptAgreementSaga)
}

export function* watchGetAgreementContent() {
  yield takeLatest(GET_AGREEMENT_CONTENT_CALL, getAgreementContentSaga)
}

export function* rootSaga() {
  yield all([
    watchGetAcceptAgreement(),
    watchCallAcceptAgreement(),
    watchGetAgreementContent(),
  ])
}

export default rootSaga
