import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import { SelectDropdown } from "@rentspree/component-v2"
import diamondBg from "images/backgrounds/gplay-diamond.png"
import { TitleWithDescription } from "components/titleWithDescriptions"
import { ButtonSetLeaseAgreement } from "components/buttons/button-set"
import { TOPBAR_HEIGHT } from "styles/settings"
import { withFormik } from "formik"
import Row from "components/atoms/row"
import B14 from "@rentspree/component-v2/dist/typography/b14"
import { ERROR_SAVING, PLEASE_CONTACT } from "constants/error-messages"
import panelError from "images/icons/panel-error.svg"
import { AlertPanel } from "components/shared/alert-panel"
import { schema } from "./form-schema"
import { PROPERTY_TYPES, SELECT_DROP_DOWN_PLACEHOLDER } from "./const"

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT}
  ); /* fallback if calc did not work */
  min-height: calc(var(--vh, 1vh) * 100 - ${TOPBAR_HEIGHT});
  position: relative;
  font-family: Source Sans Pro;
  font-size: 14px;
  width: 100%;
  background: linear-gradient(rgba(249, 249, 249, 0.5) 0%, #f9f9f9),
    url(${diamondBg});
`
export const FormWrapper = styled.div`
  width: 500px;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
export const PropertyForm = styled.form``
export const Container = styled.div`
  padding-top: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

export const Wrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    padding: 0 20px;
  }
`

export const InnerForm = props => {
  const {
    property,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    backToDashboard,
    touched,
    errors,
    isError,
    values,
    actions,
    isLoading,
    saveError,
  } = props

  const isBtnDisabled = !values.propertyType

  return (
    <FormWrapper>
      {isError && (
        <AlertPanel
          error
          handleClose={actions.clearPropertyTypeError}
          padding="10px"
          margin="10px 0 0"
          alertDetail={
            <div>
              <Row flexStart>
                <img src={panelError} alt="panel-error" />
                <B14
                  weight="600"
                  color="inherit"
                  margin="0 0 0 10px"
                  lineHeight="20px">
                  {get(saveError, "errorMessage", ERROR_SAVING)}
                </B14>
              </Row>
              <B14 color="inherit" margin="2px 0 0 30px" lineHeight="20px">
                {get(saveError, "secondaryMessage", PLEASE_CONTACT)}
              </B14>
            </div>
          }
        />
      )}
      <PropertyForm onSubmit={handleSubmit}>
        <TitleWithDescription description={property.fullNameAddress} />
        <SelectDropdown
          isRequired
          name="propertyType"
          id="propertyType"
          placeholder={SELECT_DROP_DOWN_PLACEHOLDER}
          value={values.propertyType}
          onChange={val => setFieldValue("propertyType", val)}
          onBlur={() => setFieldTouched("propertyType", true)}
          label="Property Type"
          options={PROPERTY_TYPES}
          error={touched.propertyType && errors.propertyType}
        />
        <ButtonSetLeaseAgreement
          isShowPrev={false}
          mWidth="100%"
          text="Back to dashboard"
          onClickBackToDashboardBtn={backToDashboard}
          hasIconNextBtn
          disabledNextButton={isBtnDisabled}
          continueText="Save & Continue"
          continueBtnType="submit"
          isLoading={isLoading}
        />
      </PropertyForm>
    </FormWrapper>
  )
}

const Form = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: async (values, { props }) => {
    props.savePropertyType(values.propertyType)
  },
  initialValues: { propertyType: "" },
  displayName: "ProperTypeForm",
})(InnerForm)

export default Form
