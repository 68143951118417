import { API_URL, USER_API_URL } from 'env'

export const GET_CONTACT_LIST_CALL = 'GET_CONTACT_LIST_CALL'
export const GET_CONTACT_LIST_REQUEST = 'GET_CONTACT_LIST_REQUEST'
export const GET_CONTACT_LIST_SUCCESS = 'GET_CONTACT_LIST_SUCCESS'
export const GET_CONTACT_LIST_FAILURE = 'GET_CONTACT_LIST_FAILURE'
export const GET_CONTACT_COUNT_CALL = 'GET_CONTACT_COUNT_CALL'
export const GET_CONTACT_COUNT_REQUEST = 'GET_CONTACT_COUNT_REQUEST'
export const GET_CONTACT_COUNT_SUCCESS = 'GET_CONTACT_COUNT_SUCCESS'
export const GET_CONTACT_COUNT_FAILURE = 'GET_CONTACT_COUNT_FAILURE'
export const CONTACT_LIST_CHANGE_PAGE = 'CONTACT_LIST_CHANGE_PAGE'
export const STAR_CONTACT_DASHBOARD = 'STAR_CONTACT_DASHBOARD'

export const CREATE_AND_TAG_CONTACT_DASHBOARD = 'CREATE_AND_TAG_CONTACT_DASHBOARD'
export const TAG_CONTACT_DASHBOARD_REQUEST = 'TAG_CONTACT_DASHBOARD_REQUEST'
export const TAG_CONTACT_DASHBOARD_SUCCESS = 'TAG_CONTACT_DASHBOARD_SUCCESS'
export const TAG_CONTACT_DASHBOARD_FAILURE = 'TAG_CONTACT_DASHBOARD_FAILURE'
export const TAG_CONTACT_DASHBOARD = 'TAG_CONTACT_DASHBOARD'
export const UNTAG_CONTACT_DASHBOARD = 'UNTAG_CONTACT_DASHBOARD'
export const UNTAG_CONTACT_DASHBOARD_REQUEST = 'UNTAG_CONTACT_DASHBOARD_REQUEST'
export const UNTAG_CONTACT_DASHBOARD_SUCCESS = 'UNTAG_CONTACT_DASHBOARD_SUCCESS'
export const UNTAG_CONTACT_DASHBOARD_FAILURE = 'UNTAG_CONTACT_DASHBOARD_FAILURE'

export const MARK_AS_GIFT_SENT = 'MARK_AS_GIFT_SENT'
export const MARK_AS_GIFT_SENT_REQUEST = 'MARK_AS_GIFT_SENT_REQUEST'
export const MARK_AS_GIFT_SENT_SUCCESS = 'MARK_AS_GIFT_SENT_SUCCESS'
export const MARK_AS_GIFT_SENT_FAILURE = 'MARK_AS_GIFT_SENT_FAILURE'
export const UNMARK_AS_GIFT_SENT = 'UNMARK_AS_GIFT_SENT'
export const UNMARK_AS_GIFT_SENT_REQUEST = 'UNMARK_AS_GIFT_SENT_REQUEST'
export const UNMARK_AS_GIFT_SENT_SUCCESS = 'UNMARK_AS_GIFT_SENT_SUCCESS'
export const UNMARK_AS_GIFT_SENT_FAILURE = 'UNMARK_AS_GIFT_SENT_FAILURE'

export const BULK_DELETE = 'BULK_DELETE'
export const BULK_DELETE_REQUEST = 'BULK_DELETE_REQUEST'
export const BULK_DELETE_SUCCESS = 'BULK_DELETE_SUCCESS'
export const BULK_DELETE_FAILURE = 'BULK_DELETE_FAILURE'

export const BULK_ADD_TAG = 'BULK_ADD_TAG'
export const BULK_ADD_TAG_REQUEST = 'BULK_ADD_TAG_REQUEST'
export const BULK_ADD_TAG_SUCCESS = 'BULK_ADD_TAG_SUCCESS'
export const BULK_ADD_TAG_FAILURE = 'BULK_ADD_TAG_FAILURE'
export const SET_BULK_ADD_TAG_STATE = 'SET_BULK_ADD_TAG_STATE'
export const CREATE_AND_BULK_ADD_TAG = 'CREATE_AND_BULK_ADD_TAG'

export const SET_BULK_STATE = 'SET_BULK_STATE'
export const TOGGLE_SELECTED_BULK = 'TOGGLE_SELECTED_BULK'
export const ADD_SELECTED_ALL_BULK = 'ADD_SELECTED_ALL_BULK'
export const DESELECTED_ALL_BULK = 'DESELECTED_ALL_BULK'

export const GET_CONTACT_SETTINGS_CALL = 'GET_CONTACT_SETTINGS_CALL'
export const GET_CONTACT_SETTINGS_REQUEST = 'GET_CONTACT_SETTINGS_REQUEST'
export const GET_CONTACT_SETTINGS_SUCCESS = 'GET_CONTACT_SETTINGS_SUCCESS'
export const GET_CONTACT_SETTINGS_FAILURE = 'GET_CONTACT_SETTINGS_FAILURE'
export const UPDATE_CONTACT_SETTINGS_CALL = 'UPDATE_CONTACT_SETTINGS_CALL'
export const UPDATE_CONTACT_SETTINGS_REQUEST = 'UPDATE_CONTACT_SETTINGS_REQUEST'
export const UPDATE_CONTACT_SETTINGS_SUCCESS = 'UPDATE_CONTACT_SETTINGS_SUCCESS'
export const UPDATE_CONTACT_SETTINGS_FAILURE = 'UPDATE_CONTACT_SETTINGS_FAILURE'

export const GET_CONTACT_LIST_API = `${API_URL}/api/v2/people/contacts`
export const CONTACT_STAR_API = `${API_URL}/api/v2/people/contacts/:contactId/star`
export const GET_CONTACT_COUNT_API = `${API_URL}/api/v2/people/contacts/counts`
export const BULK_DELETE_API = `${API_URL}/api/v2/people/contacts/batch`
export const BULK_ADD_TAG_API = `${API_URL}/api/v2/people/contacts/batch/tag/:tagId`
export const MARK_AS_GIFT_SENT_API = `${API_URL}/api/v2/people/contacts/:contactId/send-gift/:giftType`
export const USER_SETTINGS_API = `${API_URL}/api/v2/people/settings`

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE'
export const SEND_MESSAGE_API = `${USER_API_URL}/api/v2/user/messaging/conversations/send-message`

export const ERROR_MESSAGE = {
  TITLE: 'The contacts are temporarily unavailable',
  MESSAGE:
    'Please wait a few minutes and refresh the page (ctrl+r/cmd+r). If you still cannot access the contacts, contact us at (323) 515-7757 or support@rentspree.com.',
}

export const CONTACT_MATCHING_FILTER_TOAST_CONFIG = contact => ({
  titleMessage: `Found ${contact} contacts related to filter`,
  status: 'success',
  timeOut: 5000,
  width: '350px',
})

export const CREATE_TAG_SUCCESS_TOAST_CONFIG = () => ({
  titleMessage: 'New tag created.',
  status: 'success',
  timeOut: 5000,
  width: '350px',
})

export const BULK_DELETE_SUCCESS_TOAST_CONFIG = () => ({
  titleMessage: 'Contacts deleted',
  status: 'success',
})

export const BULK_DELETE_FAILURE_TOAST_CONFIG = () => ({
  titleMessage: 'Cannot delete contacts, please try again',
  status: 'error',
})

export const BULK_ADD_TAG_SUCCESS_TOAST_CONFIG = () => ({
  titleMessage: 'Added tags to contacts successfully',
  status: 'success',
})

export const BULK_ADD_TAG_FAILURE_TOAST_CONFIG = () => ({
  titleMessage: 'Cannot add tag, please try again',
  status: 'error',
})

export const MARK_AS_GIFT_SENT_SUCCESS_TOAST_CONFIG = () => ({
  titleMessage: 'Marked as gift sent!',
  status: 'success',
})

export const MARK_AS_GIFT_SENT_FAILURE_TOAST_CONFIG = () => ({
  titleMessage: 'Cannot mark as gift sent, please try again',
  status: 'error',
})

export const UNMARK_AS_GIFT_SENT_SUCCESS_TOAST_CONFIG = () => ({
  titleMessage: 'Unmarked as gift sent!',
  status: 'success',
})

export const UNMARK_AS_GIFT_SENT_FAILURE_TOAST_CONFIG = () => ({
  titleMessage: 'Cannot unmark as gift sent, please try again',
  status: 'error',
})

export const GET_SETTINGS_FAILURE_TOAST_CONFIG = () => ({
  titleMessage: 'Cannot retrieve your settings',
  status: 'warning',
})

export const UPDATE_SETTINGS_FAILURE_TOAST_CONFIG = () => ({
  titleMessage: 'Failed to save settings',
  status: 'warning',
})

export const SEND_MESSAGE_SUCCESS_TOAST_CONFIG = () => ({
  titleMessage: 'Send message successfully',
  status: 'success',
})

export const SEND_MESSAGE_FAILED_TOAST_CONFIG = () => ({
  titleMessage: 'Cannot send message',
  status: 'error',
})

export const ACTION_TOAST_FAILURE = 'Error occurred, please try again'

export const BULK_DELETE_MODEL_TITLE = 'Delete Contact(s)'
export const BULK_DELETE_MODEL_DESCRIPTION =
  "Deleted contact(s) can't be recovered. Are you sure you want to continue?"

export const BULK_DELETE_SELECT_STATE = 'BULK_DELETE_SELECT_STATE'
export const BULK_DELETE_CONFIRM_STATE = 'BULK_DELETE_CONFIRM_STATE'

export const BULK_ADD_TAG_SELECT_STATE = 'BULK_ADD_TAG_SELECT_STATE'

export const BULK_REQUEST_APPLICATION_SELECT_STATE = 'BULK_REQUEST_APPLICATION_SELECT_STATE'

export const APPLY_SORT_CONTACT = 'APPLY_SORT_CONTACT'
export const UNSET_IS_LOADING_DONE = 'UNSET_IS_LOADING_DONE'

export const UPCOMING_EVENT_TYPES = {
  BIRTHDAY: 'birthday',
  MOVE_IN_ANNIVERSARY: 'move-in-anniversary',
  MOVING_IN: 'moving-in',
}

export const SETTING_KEYS = {
  DEFAULT_MOVE_IN_MESSAGE: 'defaultMoveInMessage',
  DEFAULT_BIRTHDAY_MESSAGE: 'defaultBirthdayMessage',
  DEFAULT_MOVING_IN_MESSAGE: 'defaultMovingInMessage',
}
