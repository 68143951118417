import { buildPath } from '@rentspree/path'
import { apiInstance } from 'utils/api-interceptor'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { GET_MULTI_SHARE_STATUS_CALL, GET_MULTI_SHARE_STATUS_API_PATH } from './constants'
import { getMultiShareStatusApiState } from './actions'

export const getMultiShareStatusApi = async () => {
  const multiShareStatus = await apiInstance.get(buildPath(GET_MULTI_SHARE_STATUS_API_PATH))
  return multiShareStatus
}

export function* getMultiShareStatusSaga() {
  try {
    yield put(getMultiShareStatusApiState.request())
    const status = yield call(getMultiShareStatusApi)
    yield put(getMultiShareStatusApiState.success(status))
  } catch (error) {
    yield put(getMultiShareStatusApiState.failure(error.status))
  }
}

export function* watchGetMultiShareStatus() {
  yield takeLatest(GET_MULTI_SHARE_STATUS_CALL, getMultiShareStatusSaga)
}

export default function* rootSaga() {
  yield all([watchGetMultiShareStatus()])
}
