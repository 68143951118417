/* eslint-disable import/no-cycle */
import { loadAccessToken, saveAccessTokenLogin } from "./access-token"
import { loginRedirectHandler } from "../../helpers/sso-handler"
import * as Types from "../../constants/action-types"

const persistState = store => next => action => {
  const state = store.getState()
  if (action.type === Types.REMOVE_ACCESS_TOKEN) {
    const { ssoHandler } = state
    if (ssoHandler && ssoHandler.urlParams && ssoHandler.urlParams.userType) {
      const { userType } = ssoHandler.urlParams
      loginRedirectHandler(userType)
    } else {
      // passed user type as undefined for redirecting to root path
      loginRedirectHandler()
    }
  } else if (action.type === Types.LOGIN_SUCCESS) {
    if (action.access_token) {
      saveAccessTokenLogin(action)
    } else {
      saveAccessTokenLogin(action.result)
    }
  }
  next(action)
}

export { loadAccessToken }
export default persistState
