import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Tooltip from "@rentspree/component-v2/dist/tooltip"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import SwitchButton from "@rentspree/component-v2/dist/button/switch"
import { COLOR } from "../../../styles/settings"

export const StyledSwitchButtonWithTooltip = styled.div`
  display: flex;
  align-items: center;
  span {
    padding: 0;
  }
  i {
    margin-left: 10px;
  }
  @media (max-width: 991px) {
    i {
      font-size: 18px;
    }
  }
`
export const StyledTooltipContent = styled(Tooltip)`
  .tooltiptext {
    background: ${COLOR.textBlack};
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    &::before {
      background: ${COLOR.textBlack};
    }
    top: 6px;
    left: 22px;
  }
`
export const TooltipDescription = styled(B16)`
  color: ${COLOR.white};
  font-size: 12px;
  @media (max-width: 991px) {
    color: initial;
    font-size: 16px;
  }
  font-family: Source Sans Pro !important;
  margin-bottom: 8px;
`

export const TooltipMobileTitle = styled(B16)`
  display: none;
  @media (max-width: 991px) {
    display: block;
    line-height: 32px !important;
    font-size: 24px !important;
    margin-bottom: 10px;
  }
`

const SwitchButtonWithTooltip = ({ tooltip, checked, onChange, id }) => (
  <StyledSwitchButtonWithTooltip id={id}>
    <SwitchButton checked={checked} onChange={onChange} id="switchButton" />
    <StyledTooltipContent
      id="tooltip"
      iconSize="14px"
      color="#AAAAAA"
      title={tooltip.title}
      position={tooltip.position}
      bubbleWidth="195px">
      <TooltipMobileTitle weight="600">
        {tooltip.mobileTitle}
      </TooltipMobileTitle>
      <TooltipDescription>{tooltip.description}</TooltipDescription>
    </StyledTooltipContent>
  </StyledSwitchButtonWithTooltip>
)

SwitchButtonWithTooltip.defaultProps = {
  tooltip: { title: "", description: "", position: "TOP", mobileTitle: "" },
}

SwitchButtonWithTooltip.propTypes = {
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default SwitchButtonWithTooltip
