import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ImportCsvWidget from 'components/contacts/organisms/widget'
import { CONTACTS } from 'constants/route'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'
import { refreshHistory } from 'utils/history'

import {
  closeImportWidget,
  triggerMinimizeWidget,
  cancelBulkCreateContact,
  bulkCreateContact,
  confirmCancelBulkCreateContact,
} from './actions'
import { IMPORT_PROGRESS_STATUS } from './constants'
import { selectCreateProgress, selectProgressPercentage } from './selectors'

const { SUCCESS, PARTIALLY_SUCCESS } = IMPORT_PROGRESS_STATUS
const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT
const { CSV_COMPLETE, CSV_CANCEL } = EVENT_PROPERTY.CSV_WIDGET_STATUS

export const beforeunload = e => {
  e.preventDefault()
  e.returnValue = ''
}

const Widget = () => {
  const dispatch = useDispatch()
  const createProgress = useSelector(selectCreateProgress)
  const progressPercentage = useSelector(selectProgressPercentage)

  React.useEffect(() => {
    if (createProgress?.isOpen) {
      window.addEventListener('beforeunload', beforeunload)
    }
    if ([SUCCESS, PARTIALLY_SUCCESS].includes(createProgress?.status)) {
      tracker.trackEvent(EVENT_NAME.IMPORT_SUCCESSFULLY, {
        csv_widget_status: createProgress.status === SUCCESS ? CSV_COMPLETE : CSV_CANCEL,
        total_import_csv: createProgress?.current,
      })

      window.removeEventListener('beforeunload', beforeunload)
    }
  }, [createProgress?.isOpen, createProgress?.status])

  const handleClose = () => {
    tracker.trackEvent(EVENT_NAME.CLOSE_WIDGET)

    dispatch(closeImportWidget())
  }

  const navigateToContactDashboard = () => {
    tracker.trackEvent(EVENT_NAME.VIEW_ALL_CONTACTS)

    refreshHistory.push(CONTACTS)
  }

  const handleResize = () => {
    if (createProgress.minimizePage) {
      tracker.trackEvent(EVENT_NAME.MAXIMIZE_WIDGET)
    } else {
      tracker.trackEvent(EVENT_NAME.MINIMIZE_WIDGET)
    }

    dispatch(triggerMinimizeWidget())
  }

  const handleCancel = () => {
    tracker.trackEvent(EVENT_NAME.CANCEL_IMPORT)

    dispatch(cancelBulkCreateContact())
  }

  const handleImport = () => {
    tracker.trackEvent(EVENT_NAME.CONTINUE_IMPORT)

    dispatch(bulkCreateContact())
  }

  const handleConfirmCancel = () => {
    tracker.trackEvent(EVENT_NAME.CONFIRM_CANCEL_IMPORT)

    dispatch(confirmCancelBulkCreateContact())
  }

  if (!createProgress?.isOpen) return <div />

  return (
    <ImportCsvWidget
      {...createProgress}
      percentage={progressPercentage}
      onClose={handleClose}
      onResize={handleResize}
      onCancel={handleCancel}
      onViewContact={navigateToContactDashboard}
      onImport={handleImport}
      onConfirmCancel={handleConfirmCancel}
    />
  )
}

export default Widget
