const PrefixInputInserter = ({ onChange, prefix = "", children, ...props }) => {
  const handleChange = e => {
    const value = e?.target?.value
    const incomingInput = e?.nativeEvent?.data
    if (value.length > 0 && value.charAt(0) !== prefix) {
      e.target.value = `${prefix}${e.target.value}`
    } else if (
      value.length === 1 &&
      value.charAt(0) === prefix &&
      incomingInput !== prefix
    ) {
      e.target.value = ""
    }
    onChange(e)
  }
  return children({ ...props, onChange: handleChange })
}

export default PrefixInputInserter
