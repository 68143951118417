export const TOGGLE_NEW_MESSAGE_MODAL_CALL = "TOGGLE_NEW_MESSAGE_MODAL_CALL"

export const START_CONVERSATION_CALL = "START_CONVERSATION_CALL"
export const START_CONVERSATION_REQUEST = "START_CONVERSATION_REQUEST"
export const START_CONVERSATION_SUCCESS = "START_CONVERSATION_SUCCESS"
export const START_CONVERSATION_FAILURE = "START_CONVERSATION_FAILURE"

export const INITIATE_TALK_JS_CALL = "INITIATE_TALK_JS_CALL"

export const GET_SIGNATURE_REQUEST = "GET_SIGNATURE_REQUEST"
export const GET_SIGNATURE_SUCCESS = "GET_SIGNATURE_SUCCESS"
export const GET_SIGNATURE_FAILURE = "GET_SIGNATURE_FAILURE"

export const INITIATE_TALK_JS_SUCCESS = "INITIATE_TALK_JS_SUCCESS"
export const INITIATE_TALK_JS_FAILURE = "INITIATE_TALK_JS_FAILURE"

export const GET_CONVERSATION_CALL = "GET_CONVERSATION_CALL"
export const GET_CONVERSATION_REQUEST = "GET_CONVERSATION_REQUEST"
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS"
export const GET_CONVERSATION_FAILURE = "GET_CONVERSATION_FAILURE"
export const CLEAR_CONVERSATION = "CLEAR_CONVERSATION"

export const SET_CONVERSATION = "SET_CONVERSATION"
export const START_UNREAD_CHANNEL = "START_UNREAD_CHANNEL"

export const CONVERSATION_API = "/api/v2/user/messaging/conversations"
export const SIGNATURE_API = "/api/v2/user/messaging/signature"

export const TALK_JS_READY = "TALK_JS_READY"

export const CLOSE_MESSAGING_MODAL_ERROR = "CLOSE_MESSAGING_MODAL_ERROR"

export const MESSAGING_INTRODUCE_TEXT = {
  title: "Messages",
  description:
    "Messages is a feature that helps you converse with applicants, landlords, and other agents. Click the button below to send your first message.",
  buttonName: "New Message",
}

export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT"

export const UNREADS_MAX = 99
export const UNREADS_MAX_TEXT = "99+"
