/**
 * toDotNotation returns array of string path in object.
 * Example:
 * // returns ['foo.0.bar', 'foo.1.blah']
 * toDotNotation({foo: [{bar: true}, {blah: true}]})
 *
 * @param {Record<string, string | Record<string, unknown>> | import('formik').FormikErrors<unknown>} jsonToConstructDotNotations object to convert keys to dot notation
 * @param {Object} options
 * @param {string} options.prefix the dot notation path prefix
 * @param {(val: unknown) => boolean} options.exitConditionFn a custom function to stop the recursion
 * @param {string[]} result used in the recursion to pass the stack result to the next recursion
 *
 * @returns {string[]}
 */
export const toDotNotation = (jsonToConstructDotNotations, options = {}, result = []) => {
  const normalizedObject = JSON.parse(JSON.stringify(jsonToConstructDotNotations))
  Object.keys(normalizedObject).forEach(key => {
    if (key.startsWith('_')) {
      return
    }

    const value = jsonToConstructDotNotations[key]
    if (!value) {
      return
    }

    const nextKey = options?.prefix ? `${options.prefix}.${key}` : key
    if (typeof options?.exitConditionFn === 'function' && options.exitConditionFn(value)) {
      result.push(nextKey)
      return
    }

    if (typeof value === 'object') {
      toDotNotation(value, { ...options, prefix: nextKey }, result)
    } else {
      result.push(nextKey)
    }
  })

  return result
}
