import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import {
  makeSelectDoesEnvelopeExist,
  makeSelectEnvelopeName,
  makeSelectEnvelopeUpdatedAt,
  makeSelectActiveIdType,
  makeSelectActiveIdPage,
  makeSelectActiveIdField,
  makeSelectActiveIdStyles,
  makeSelectActiveIdAssignee,
  makeSelectActiveIdUndeletable,
  makeSelectToolbar,
  makeSelectRecipientList,
  selectLoadingUpdateEnvelope,
  makeSelectIsAutosaving,
  makeSelectIsAutosaveError,
  makeSelectIsUsingTemplate,
  selectFieldValidationResult,
  makeSelectEnvelopeDisplayName,
} from "./selectors"
import reducer from "./reducer"
import {
  removeActiveBox,
  triggerAutosaveEnvelope,
  updateEnvelope,
} from "./actions"
import {
  setChildrenBox,
  addChildren,
  setActive,
  resetActive,
  removeChildrenBox,
  setAssigneeToBox,
  setConfigBox,
  changeBoxPage,
} from "../drag-n-drop/actions"
import saga from "./saga"

export const makeMapStateToProps = () =>
  createStructuredSelector({
    haveEnvelope: makeSelectDoesEnvelopeExist(),
    envelopeName: makeSelectEnvelopeName(),
    envelopeDisplayName: makeSelectEnvelopeDisplayName(),
    envelopeUpdatedAt: makeSelectEnvelopeUpdatedAt(),
    activeIdType: makeSelectActiveIdType(),
    activeIdPage: makeSelectActiveIdPage(),
    activeIdField: makeSelectActiveIdField(),
    activeIdStyles: makeSelectActiveIdStyles(),
    activeIdAssignee: makeSelectActiveIdAssignee(),
    activeIdUndeletable: makeSelectActiveIdUndeletable(),
    toolBarList: makeSelectToolbar(),
    recipientsList: makeSelectRecipientList(),
    isLoadingUpdateEnvelope: selectLoadingUpdateEnvelope,
    isAutosaving: makeSelectIsAutosaving(),
    isAutosaveError: makeSelectIsAutosaveError(),
    isUsingTemplate: makeSelectIsUsingTemplate(),
    fieldValidation: selectFieldValidationResult(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        /* envelope actions */
        removeActiveBox,
        triggerAutosaveEnvelope,
        updateEnvelope,
        /* drag-n-drop actions */
        setChildrenBox,
        addChildren,
        setActive,
        resetActive,
        removeChildrenBox,
        setAssigneeToBox,
        setConfigBox,
        changeBoxPage,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "envelope", reducer })
export const withSaga = injectSaga({ key: "envelope", saga })
