import get from 'lodash/get'
import head from 'lodash/head'
import toUpper from 'lodash/toUpper'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { selectPropertyLandlordProfile } from 'containers/listing/selectors'
import { initialState } from './reducer'

export const getAssociationContact = state => get(state, 'associationContact', initialState)

export const getProperty = state => get(state, 'property', initialState)

export const selectAssociationContact = createSelector(
  getAssociationContact,
  selectPropertyLandlordProfile,
  (associationContacts, propertyLandlordProfile) => {
    const contact = head(
      get(associationContacts, 'associationContacts', initialState.associationContacts),
    )
    const landlordProfile = get(propertyLandlordProfile, 'email') ? {} : propertyLandlordProfile
    const isAssociationContact = !isEmpty(contact)
    return { ...(contact || landlordProfile), isAssociationContact }
  },
)

export const selectIsFetchingAssociationContact = createSelector(getAssociationContact, state =>
  get(state, 'isFetching'),
)

export const selectAssociationContactBadge = createSelector(selectAssociationContact, state => {
  const { firstName, lastName, email } = state
  let imagePlaceholder = ''
  let name = ''
  let displayEmail = email

  if (email && firstName && lastName) {
    imagePlaceholder = firstName.substring(0, 1) + lastName.substring(0, 1)
    name = `${firstName} ${lastName}`
  } else if (email && !firstName && !lastName) {
    name = email
    displayEmail = ''
    imagePlaceholder = name.substring(0, 1)
  } else if (email && (firstName || lastName)) {
    name = firstName || lastName
    imagePlaceholder = name.substring(0, 1)
  }

  return {
    imagePlaceholder: toUpper(imagePlaceholder),
    name,
    email: displayEmail,
  }
})

export const selectHasAssociation = createSelector(selectAssociationContact, state => {
  const { email } = state
  return !isEmpty(email)
})
