export const PAID_STATUS = {
  SUBMITTED: "submitted",
  REQUESTED: "requested",
  COMPLETED: "completed",
  OPENED: "opened",
  FAILED: "failed",
}

export const PAYMENT_STATUS = {
  PAYMENT: "payment",
  REFUND_REQUIRED: "partial_refund_required",
  READY: "ready",
  PROCESSING_PAYMENT: "processing_payment",
}

export const ATTACH_REAL_STATUS = {
  ATTACH_REAL_REQUIRE: "attach_real_require",
  ATTACH_REAL_READY: "attach_real_ready",
  ATTACH_REAL_ERROR: "attach_real_error",
}

export const STATUS = {
  ...PAID_STATUS,
  ...PAYMENT_STATUS,
  ...ATTACH_REAL_STATUS,
}
