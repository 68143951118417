import { API_URL } from 'env'
import { SCREENING_OPTION_TYPES } from 'containers/reports/constants'
import { NY_PROPERTY, NY_PROPERTY_AFTER_OPENED } from 'containers/landlord-pay/constants'
import { PAYER } from 'legacy/constants/request-const'

const { APPLICATION, CREDIT_REPORT, CRIMINAL, EVICTION } = SCREENING_OPTION_TYPES

export const FOOTER_MESSAGE = totalPrice =>
  `After clicking "View Report(s)", your applicant will receive a $${totalPrice} refund to original payment method.`

export const SUBMIT_REFUND_CALL = 'SUBMIT_REFUND_CALL'
export const SUBMIT_REFUND_REQUEST = 'SUBMIT_REFUND_REQUEST'
export const SUBMIT_REFUND_SUCCESS = 'SUBMIT_REFUND_SUCCESS'
export const SUBMIT_REFUND_FAILURE = 'SUBMIT_REFUND_FAILURE'

export const SUBMIT_REFUND_API = `${API_URL}/api/v2/rental-submissions/:rentalId/credit-report/partially-refund`

export const optionsType = [APPLICATION, CREDIT_REPORT, CRIMINAL, EVICTION]

export const REFUND_MESSAGE = {
  [PAYER.AGENT]: {
    [NY_PROPERTY]:
      'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. We have refunded the excessed application fees paid accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
    [NY_PROPERTY_AFTER_OPENED]:
      'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. We have refunded the excessed application fees paid accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
    DEFAULT:
      'We have refunded the excessed application fees accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
  },
  [PAYER.RENTER]: {
    [NY_PROPERTY]:
      'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. We have refunded the excessed application fees paid by your applicant(s) accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
    [NY_PROPERTY_AFTER_OPENED]:
      'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. We have refunded the excessed application fees paid by your applicant(s) accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
    DEFAULT:
      'We have refunded the excessed application fees paid by your applicant(s) accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
  },
  [NY_PROPERTY]:
    'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. We have refunded the excessed application fees paid by your applicant(s) accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
  [NY_PROPERTY_AFTER_OPENED]:
    'Due to a recent regulation change in New York, application fees have been capped at $20 and eviction records may not be used as a basis for denying applicants. We have refunded the excessed application fees paid by your applicant(s) accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
  DEFAULT:
    'We have refunded the excessed application fees paid by your applicant(s) accordingly and no further action is needed. Please contact an experienced attorney with any questions about the screening process.',
}
