import startsWith from "lodash/startsWith"
import { TrackerInstance as Tracker } from "./tracker"

export function trackAndRedirect(event, path, history) {
  Tracker.trackEvent(event)
  if (startsWith(path, "/") && history) {
    history.push(path)
  } else {
    window.open(path, "_blank")
  }
}
