import generateApiCall from "utils/generate-api-call"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { OPEN_SWEET_ALERT } from "redux-middleware/sweet-alert/constants"
import { GENERAL_ERROR } from "constants/error-messages"

import {
  GET_ESIGN_TEMPLATE_LIST_CALL,
  GET_ESIGN_TEMPLATE_LIST_REQUEST,
  GET_ESIGN_TEMPLATE_LIST_SUCCESS,
  GET_ESIGN_TEMPLATE_LIST_FAILURE,
  CLEAR_ESIGN_TEMPLATE_LIST_CALL,
  SET_CAR_MODAL_STATE,
  CLOSE_ERROR_IN_MODAL,
  LINKING_ACCOUNT_WITHOUT_SSO_CALL,
  LINKING_ACCOUNT_WITHOUT_SSO_REQUEST,
  LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS,
  LINKING_ACCOUNT_WITHOUT_SSO_FAILED,
} from "./constants"

export const getESignTemplateList = payload => ({
  type: GET_ESIGN_TEMPLATE_LIST_CALL,
  payload,
})

export const linkAccountWithoutSSO = payload => ({
  type: LINKING_ACCOUNT_WITHOUT_SSO_CALL,
  payload,
})

export const setCarModalState = (payload, templateData) => ({
  type: SET_CAR_MODAL_STATE,
  payload,
  templateData,
})

export const clearESignTemplateList = () => ({
  type: CLEAR_ESIGN_TEMPLATE_LIST_CALL,
})

export const closeErrorInModal = () => ({
  type: CLOSE_ERROR_IN_MODAL,
})

export const eSignTemplateListApi = generateApiCall([
  GET_ESIGN_TEMPLATE_LIST_REQUEST,
  GET_ESIGN_TEMPLATE_LIST_SUCCESS,
  GET_ESIGN_TEMPLATE_LIST_FAILURE,
])

export const linkingAccountWithoutSSOApi = generateApiCall([
  LINKING_ACCOUNT_WITHOUT_SSO_REQUEST,
  LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS,
  LINKING_ACCOUNT_WITHOUT_SSO_FAILED,
])

export const alertUnknownError = () => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.ERROR,
  option: {
    title: GENERAL_ERROR.UNKNOWN_ERROR.TITLE,
    text: GENERAL_ERROR.UNKNOWN_ERROR.MESSAGE,
    className: "rentspree-sweetalert",
    closeOnClickOutside: false,
    button: "Try Again",
  },
})
