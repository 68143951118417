import React from "react"
import renderHTML from "react-render-html"
import { generateAddress } from "@rentspree/helper"

import EmailHeader from "components/molecules/email-header"
import ButtonSet from "components/molecules/button-set"
import ScrollTextPanel from "components/atoms/scroll-text-panel"
import { B14WithoutMargin } from "components/atoms/email-body"
import {
  ACCEPT_DENY_TYPE,
  ACCEPT_DENY_EMAIL_SUBJECT,
} from "containers/accept-deny/constants"
import { generateTemplateEmail } from "containers/accept-deny/template-email"

const AcceptDenyEmailPreviewTemplate = ({
  type,
  property,
  rentalDetail,
  profile,
  acceptOnConditionForm,
  denyForm,
  email,
  renterName,
  onClickSend,
  onClickBack,
  isLoading,
}) => {
  let condition
  if (type === ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION) {
    condition = acceptOnConditionForm
  }
  if (type === ACCEPT_DENY_TYPE.DENY) {
    condition = denyForm
  }
  const { html, data } = generateTemplateEmail({
    type,
    property,
    rentalDetail,
    landlord: profile,
    condition,
  })

  const subject = `${ACCEPT_DENY_EMAIL_SUBJECT[type]} ${generateAddress(data)}`
  const to = `${renterName} (${email})`
  return (
    html && (
      <>
        <EmailHeader to={to} subject={subject} />
        <ScrollTextPanel height="100%" mHeight="auto" flex="1">
          <B14WithoutMargin lineHeight="18px" margin="0">
            {renderHTML(html)}
          </B14WithoutMargin>
        </ScrollTextPanel>
        <ButtonSet
          margin="20px 0 0"
          minHeight="40px"
          buttons={
            type === ACCEPT_DENY_TYPE.ACCEPT
              ? [
                  {
                    type: "blueGradientButton",
                    option: {
                      text: "Send",
                      hasIcon: false,
                      onClick: onClickSend,
                      loading: isLoading,
                      width: "130px",
                    },
                    isPullLeft: false,
                  },
                ]
              : [
                  {
                    type: "blueGradientButton",
                    option: {
                      text: "Send",
                      hasIcon: false,
                      onClick: onClickSend,
                      loading: isLoading,
                      width: "130px",
                    },
                    isPullLeft: false,
                  },
                  {
                    type: "button",
                    option: {
                      text: "Back",
                      onClick: onClickBack,
                      width: "100px",
                    },
                    isPullLeft: true,
                  },
                ]
          }
        />
      </>
    )
  )
}

export default AcceptDenyEmailPreviewTemplate
