import React from "react"
import { Icon } from "@rentspree/component-v2"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import { COLOR } from "components/constants"
import { NotifyButton } from "./landing.styled"

export const IconNotifyButton = ({ onNotify, toggle, buttonText }) => (
  <NotifyButton
    id="notifyButton"
    google
    small
    toggle={toggle}
    onClick={onNotify}
    padding="0 25px">
    <Icon
      name={toggle ? "success" : "notify"}
      color={toggle ? COLOR.fontBlue : COLOR.textBlack}
      size="sm"
    />
    <B16
      margin="0 0 0 5px"
      weight="600"
      color={toggle ? COLOR.fontBlue : COLOR.textBlack}>
      {buttonText}
    </B16>
  </NotifyButton>
)
