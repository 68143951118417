import React from "react"
import { COLOR, IMG_FILTER } from "styles/settings"
import { B14, Hr } from "@rentspree/component-v2"
import styled from "styled-components"

import { Icon } from "./icon"
import {
  RECIPIENT_STATUS,
  ENVELOPE_STATUS,
  RECIPIENT_WORD,
} from "../../containers/envelope/constants"
export const ListItems = styled.div`
  display: flex;
  padding: 5px 60px 10px 20px;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 10px 20px;
  }
  .col-left {
    flex-shrink: 0;
    width: 0;
    display: flex;
    flex: 1;
    @media (max-width: 991px) {
      width: auto;
    }
    .title-content {
      font-family: Source Sans Pro;
      width: 85%;
    }
  }
  .col-right {
    flex-grow: 1;
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
`
export const EmailInline = styled(B14)`
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
  max-width: 90%;
`
export const TextInline = styled(B14)`
  display: inline;
  padding-left: 5px;
  margin: 0px;
`
export const Divider = styled(Hr)`
  border: none;
  border-top: solid 1px ${COLOR.borderGrey};
  margin: 0px;
`
export const ResentSection = styled.div`
  cursor: pointer;
  svg {
    margin: -2px;
  }
  :hover {
    svg {
      filter: ${IMG_FILTER.iconHoverDeepBlue};
    }
    p {
      color: ${COLOR.newDeepBlue};
    }
  }
`

const renderStatus = (recipientStatus, envelopeStatus, isReadyToSign) => {
  let display = RECIPIENT_WORD.DEFAULT
  let color = COLOR.titleBlack
  if (isReadyToSign) {
    switch (recipientStatus) {
      case RECIPIENT_STATUS.PENDING:
        if (envelopeStatus === ENVELOPE_STATUS.PENDING) {
          display = RECIPIENT_WORD.WAITING_SIGNATURE
          color = COLOR.yellow
        }
        break
      case RECIPIENT_STATUS.SIGNED:
        display = RECIPIENT_WORD.SIGNED
        color = COLOR.darkGreen
        break
      case RECIPIENT_STATUS.UNSIGNED:
        display = RECIPIENT_WORD.UNSIGNED
        color = COLOR.placeholderGrey
        break
      default:
        break
    }
  }
  return (
    <B14 margin="0px" color={color}>
      {display}
    </B14>
  )
}

const renderResendBtn = (recipient, onResend, envelopeStatus, isReadyToSign) => {
  if (
    RECIPIENT_STATUS.PENDING === recipient.status &&
    envelopeStatus === ENVELOPE_STATUS.PENDING &&
    isReadyToSign
  ) {
    return (
      <ResentSection key="resend" onClick={onResend}>
        <Icon icon="email-icon" />
        <TextInline margin="0px" weight="600" color={COLOR.fontBlue}>
          Resend
        </TextInline>
      </ResentSection>
    )
  }
  return <div />
}

const RecipientsItem = ({ recipient, onResend, envelopeStatus, isReadyToSign }) => (
  <ListItems className="recipientsItem">
    <div className="col-left">
      <div className="title-content">
        {recipient.fullName}
        <EmailInline color={COLOR.placeholderGrey}>
          ({recipient.email})
        </EmailInline>
      </div>
    </div>
    <div className="col-right">
      <div>{renderStatus(recipient.status, envelopeStatus, isReadyToSign)}</div>
      <div>{renderResendBtn(recipient, onResend, envelopeStatus, isReadyToSign)}</div>
    </div>
  </ListItems>
)

export default RecipientsItem
