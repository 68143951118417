import React from 'react'
import { compose } from 'redux'
import OtherDocs from 'legacy/components/reports/other-docs'
import DocumentConnect from 'legacy/containers/document/document-connect'
import { withRouter } from 'react-router-dom'
import withProReportAvailable from '../../with-pro-report-available'
import { SHARED_REPORT_TYPES } from '../../constants'
import { withSaga } from '../connect'

class Document extends React.Component {
  componentDidMount() {
    const { rentalAppId } = this.props.match?.params || {}
    if (!this.props.document.isFetchingDocSets) {
      this.props.actions.getOtherDocs(rentalAppId)
    }
  }

  render() {
    const { isSharedReport, ...rest } = this.props
    return <OtherDocs {...rest} requestDisabled={isSharedReport} />
  }
}

const DocumentContainer = withRouter(DocumentConnect(Document))

export default compose(
  withProReportAvailable(SHARED_REPORT_TYPES.OTHER_DOCS),
  withSaga,
)(DocumentContainer)
