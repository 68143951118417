import styled from 'styled-components'

const MOBILE_WIDTH = '991px'

export const AutocompleteBoxItem = styled.div`
  position: relative;
`

export const AutocompletePaper = styled.div`
  width: 100%;
  max-height: calc(5px + 37px * ${(props) => props.size});
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  overflow: auto;
  z-index: 1;
  @media (max-width: ${MOBILE_WIDTH}) {
    max-height: calc(10px + 42px * ${(props) => props.size});
  }
`

export const AutocompleteItemLayout = styled.div`
  margin-bottom: 0px;
`
