import isEmpty from 'lodash/isEmpty'
import { parsePhoneNumber } from 'libphonenumber-js'
import { SCREENING_REPORT_TYPES } from 'containers/reports/constants'

// NOTE: This helper requires phone number to have country code in order to format correctly
// e.g., Thailand 66804549476, US 16318552000
export const formatNationalPhoneNumber = phoneNumber => {
  try {
    return parsePhoneNumber(`+${phoneNumber}`).formatNational()
  } catch (e) {
    return '-'
  }
}

const { CREDIT_REPORT } = SCREENING_REPORT_TYPES

export const getScreeningReportStatuses = (
  screeningOption,
  requestedScreeningOption,
  inLieuOfCredit,
) => {
  const screeningReportStatuses = {}

  Object.values(SCREENING_REPORT_TYPES).forEach(key => {
    if (
      !isEmpty(requestedScreeningOption) &&
      requestedScreeningOption[key] === true &&
      screeningOption[key] === false
    ) {
      screeningReportStatuses[key] = 'Records restricted by jurisdiction'
    } else if (key === CREDIT_REPORT && screeningOption[CREDIT_REPORT] && inLieuOfCredit) {
      // TODO: Refactor the utils; too many cognitive complexity
      screeningReportStatuses[CREDIT_REPORT] = 'Not Available'
    } else {
      screeningReportStatuses[key] = screeningOption[key] ? 'Attached' : 'Not Requested'
    }
  })
  return screeningReportStatuses
}
