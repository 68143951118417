import groupBy from 'lodash/groupBy'
import moment from 'moment'

import { convert } from 'helpers/convert'

import { CONTACT_DETAIL_COLLAPSE_STATE } from './constants'

export const parseContactDetail = contactDetail => ({
  ...contactDetail,
  moveInDate: contactDetail.moveInDate
    ? convert(contactDetail.moveInDate, 'dateOfUTC', 'YYYY-MM-DD')
    : '',
  birthDate: contactDetail.birthDate
    ? convert(contactDetail.birthDate, 'dateOfUTC', 'YYYY-MM-DD')
    : '',
})

export const groupNoteByMonth = notes =>
  groupBy(notes, note => moment(note.createdAt).format('MMMM YYYY'))

export const groupListByMonth = lists =>
  groupBy(lists, list => moment(list.createdAt).format('MMMM YYYY'))

export const groupListType = lists => groupBy(lists, list => list.transferTemplate.type)

export const getFormatDateInNote = date => {
  const formatDate = moment(date).format('MMMM DD, YYYY [at] hh:mm A [GMT]Z').slice(0, -3)

  const firstPart = formatDate.substring(0, formatDate.length - 2)
  const secondPart = parseInt(formatDate.substring(formatDate.length - 2, formatDate.length), 10)

  return firstPart + secondPart
}

export const getFormatDateinTask = date => moment(date).format('MMMM DD, YYYY [at] h:mm A')

export const getFormatDateInActivity = date => moment(date).format('MMMM DD, YYYY [at] h:mm A')

export const setCollapseState = (key = '', value = true) => {
  if (!key) return
  const collapseState = parseStringToJSON(localStorage.getItem(CONTACT_DETAIL_COLLAPSE_STATE), {})
  localStorage.setItem(
    CONTACT_DETAIL_COLLAPSE_STATE,
    JSON.stringify({
      ...collapseState,
      [key]: value,
    }),
  )
}

export const shouldOpenCollapseState = (key = '') => {
  const collapseState = parseStringToJSON(localStorage.getItem(CONTACT_DETAIL_COLLAPSE_STATE), {})
  if (collapseState[key] === false) return false
  return true
}

export const parseStringToJSON = (jsonStr = '', defaultValue = null) => {
  if (jsonStr === null) return defaultValue
  try {
    const state = JSON.parse(jsonStr)
    return state
  } catch (e) {
    return defaultValue
  }
}
export const formatDate = (date, format) => moment(date).format(format)
