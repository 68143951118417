import React from 'react'

export const PinLocationIcon = () => (
  <div>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.38338 15.6772C2.84281 9.09472 2 8.41915 2 6C2 2.68628 4.68628 0 8 0C11.3137 0 14 2.68628 14 6C14 8.41915 13.1572 9.09472 8.61662 15.6772C8.31866 16.1076 7.68131 16.1076 7.38338 15.6772ZM8 8.5C9.38072 8.5 10.5 7.38072 10.5 6C10.5 4.61928 9.38072 3.5 8 3.5C6.61928 3.5 5.5 4.61928 5.5 6C5.5 7.38072 6.61928 8.5 8 8.5Z"
        fill="#C4C4C4"
      />
    </svg>
  </div>
)
