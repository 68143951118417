import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import { takeLatest, put, call } from 'redux-saga/effects'

import { RENT_PAYMENT_ID_PAGE } from 'constants/route'
import { addToast } from 'containers/toast/actions'

import { RETRY_PAYOUT, retryPayoutAPI } from '../../actions'
import { retryPayoutInstance } from '../../api-instances'
import { RETRY_PAYOUT_FAILED_TOAST } from '../../constants'

export function* retryPayoutSaga({ payload }) {
  yield put(retryPayoutAPI.request())

  const { propertyId, rentPaymentId } = payload

  try {
    yield call(retryPayoutInstance)
    yield put(retryPayoutAPI.success())
    yield put(
      push(
        buildPath(RENT_PAYMENT_ID_PAGE, {
          propertyId,
          rentPaymentId,
        }),
      ),
    )
  } catch (err) {
    yield put(retryPayoutAPI.failure())
    yield put(addToast(RETRY_PAYOUT_FAILED_TOAST))
  }
}

export function* watchRetryPayoutSaga() {
  yield takeLatest(RETRY_PAYOUT, retryPayoutSaga)
}
