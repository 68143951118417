import { combineReducers } from "redux"
import * as Types from "../constants/action-types"

export const urlParams = (state = false, action) => {
  switch (action.type) {
    case Types.SAVE_URL_PARAMS:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  urlParams,
})
