import { createSelector } from "reselect"
import get from "lodash/get"

export const selectSubdomain = ({ subdomain }) => subdomain || {}

export const selectCreditReport = ({ creditReport }) => creditReport || {}

export const selectRentalId = ({ application }) =>
  get(application, "rentalDetail._id")

export const selectPropertyId = ({ application }) =>
  get(application, "rentalDetail.property._id")

export const makeSelectSubdomain = createSelector(
  selectSubdomain,
  ({ subdomain }) => get(subdomain, "sub", null),
)

export const makeSelectCreditReportErrorMessage = createSelector(
  selectCreditReport,
  ({ errorMessage }) => errorMessage,
)

export const makeSelectCreditReportDetail = createSelector(
  selectCreditReport,
  ({ creditReportDetail }) => creditReportDetail,
)

export const makeSelectCreditReportPayment = createSelector(
  selectCreditReport,
  ({ creditPayment }) => creditPayment,
)

export const makeSelectDisablePaymentBtn = createSelector(
  selectCreditReport,
  ({ disabledPaymentBtn }) => disabledPaymentBtn,
)

export const makeSelectIsFetchingCreditReport = createSelector(
  selectCreditReport,
  ({ isFetchingCreditReport }) => isFetchingCreditReport,
)

export const makeSelectIsFetchingPayment = createSelector(
  selectCreditReport,
  ({ isFetchingCreditPayment }) => isFetchingCreditPayment,
)

export const makeSelectIsGetCreditReportFail = createSelector(
  selectCreditReport,
  ({ isGetCreditReportFail }) => isGetCreditReportFail,
)

export const makeSelectIsGetPaymentFail = createSelector(
  selectCreditReport,
  ({ isGetCreditPaymentFail }) => isGetCreditPaymentFail,
)

export const makeSelectIsFetchingFailed = createSelector(
  selectCreditReport,
  ({ isGetCreditReportFail, isGetCreditPaymentFail }) =>
    !!(isGetCreditReportFail || isGetCreditPaymentFail),
)
