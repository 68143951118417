import produce from "immer"
import {
  GET_AGENT_PROFILE_REQUEST,
  GET_AGENT_PROFILE_SUCCESS,
  GET_AGENT_PROFILE_FAILURE,
  UPDATE_AGENT_LICENSES_SUCCESS,
  UPDATE_AGENT_BROKERAGE_NAME,
} from "./constants"

export const initialState = {
  isLoadingGetAgentProfile: false,
  error: null,
  agentProfileData: {},
}

/* eslint-disable default-case, no-param-reassign */
export const agentProfileReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_AGENT_PROFILE_REQUEST:
        draft.isLoadingGetAgentProfile = true
        draft.error = null
        break
      case GET_AGENT_PROFILE_SUCCESS:
        draft.isLoadingGetAgentProfile = false
        draft.error = null
        draft.agentProfileData = payload
        break
      case GET_AGENT_PROFILE_FAILURE:
        draft.isLoadingGetAgentProfile = false
        draft.error = payload
        break
      case UPDATE_AGENT_LICENSES_SUCCESS:
        draft.agentProfileData = payload
        break
      case UPDATE_AGENT_BROKERAGE_NAME:
        draft.agentProfileData.contactInformation.brokerage.name = payload
        break
      default:
        break
    }
    return draft
  })
