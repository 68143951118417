import { useEffect, useState } from 'react'

// how long each text should be displayed
const TEXT_DURATION = 5000

const texts = [
  '1,000+ transactions set up by agents',
  '1 in every 2 landlords get paid earlier',
  '$100M collected yearly',
  '1,000+ new landlords last quarter',
  '3X faster in deposit & rent collection',
]

export const useChatLine = () => {
  /*
   * false = show day count down
   * true = show fomo message
   */
  const [binaryState, setBinaryState] = useState(false)

  /*
   * initial value with -1 to make it start from 0
   * because the set function always + 1 from the current value.
   */
  const [currentFomoIndex, setCurrentFomoIndex] = useState(-1)

  useEffect(() => {
    const interval = setInterval(() => {
      setBinaryState(prev => !prev)
    }, TEXT_DURATION)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (binaryState) {
      setCurrentFomoIndex(prev => {
        return (prev + 1) % texts.length
      })
    }
  }, [binaryState])

  return { binaryState, fomoMessage: texts[currentFomoIndex] }
}
