import produce from "immer"
import cloneDeep from "lodash/cloneDeep"

import { CLEAR_RENTAL_DETAIL } from "legacy/constants/action-types"

import {
  GET_SUBMISSION_PARTICIPANT_REQUEST,
  GET_SUBMISSION_PARTICIPANT_SUCCESS,
  GET_SUBMISSION_PARTICIPANT_FAILURE,
} from "./constants"

// data: {
//   type: string,
//   id: string,
//   property: string,
//   shareOption: {
//     application: boolean,
//     screeningReport: boolean,
//     referenceCheck: boolean,
//     otherDocs: boolean,
//   },
// }

export const initialState = {
  data: {},
  isFetching: false,
  error: null,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    const payloadTemp = cloneDeep(payload)
    delete payloadTemp.rentalSubmission
    switch (type) {
      case GET_SUBMISSION_PARTICIPANT_REQUEST:
        draft.isFetching = true
        draft.error = null
        break
      case GET_SUBMISSION_PARTICIPANT_SUCCESS:
        draft.data = payloadTemp
        draft.isFetching = false
        break
      case GET_SUBMISSION_PARTICIPANT_FAILURE:
        draft.isFetching = false
        draft.error = payloadTemp
        break
      case CLEAR_RENTAL_DETAIL:
        draft.data = {}
        draft.isFetching = false
        draft.isError = null
        break
      default:
        break
    }
    return draft
  })

export default reducer
