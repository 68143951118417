import dayjs from 'dayjs'
import moment from 'moment'

import { NOT_FOUND } from 'constants/route'

export const removeDraftingPaymentStorage = (key, payment) => {
  const storage = localStorage.getItem(key)
  if (storage) {
    try {
      const data = JSON.parse(storage)
      const filteredData = data.filter(pm => pm.property.id !== payment.property.id)
      localStorage.setItem(key, JSON.stringify(filteredData))
    } catch (e) {
      localStorage.removeItem(key)
    }
  }
}

export const saveDraftingPaymentStorage = (key, payment) => {
  const storage = localStorage.getItem(key)
  if (storage) {
    try {
      const data = JSON.parse(storage)
      const filteredData = data.filter(pm => pm.property.id !== payment.property.id)
      localStorage.setItem(key, JSON.stringify([...filteredData, payment]))
    } catch (e) {
      localStorage.setItem(key, JSON.stringify([payment]))
    }
  } else {
    localStorage.setItem(key, JSON.stringify([payment]))
  }
}

export const findDraftingPaymentByPropertyStorage = (key, propertyId) => {
  const storage = localStorage.getItem(key)
  if (storage) {
    try {
      const data = JSON.parse(storage)
      return data.find(pm => pm.property.id === propertyId)
    } catch (e) {
      return null
    }
  }
  return null
}

export const routeNotFound = () => {
  window.location.href = NOT_FOUND
}

export const monthsAbbr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const ordinalSuffix = date => {
  const unit = date % 10
  const specific = date % 100
  let suffix = 'th'
  if (unit === 1 && specific !== 11) suffix = 'st'
  if (unit === 2 && specific !== 12) suffix = 'nd'
  if (unit === 3 && specific !== 13) suffix = 'rd'
  return `${date}${suffix}`
}

export const dueOnDateGenerator = dateNumber => `${ordinalSuffix(dateNumber)} of the month`

export const paymentDateGenerator = inputDate => {
  const millis = Date.parse(inputDate)
  const date = new Date(millis)
  return `${monthsAbbr[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export const parseOneTimeQuotationDueDate = (date, timezone, format) => {
  if (!date || !dayjs(date).isValid() || !timezone) return ''
  const utcDueDate = dayjs.tz(date, timezone).tz('utc').format()
  return moment(utcDueDate).format(format)
}
