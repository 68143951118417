import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { AutocompleteItem } from '../autocomplete-item'
import { AutocompleteBoxItem, AutocompleteItemLayout, AutocompletePaper } from './styles'

const AutocompleteDropdown = forwardRef((props, ref) => {
  const {
    handleSelect,
    numberAddress,
    options,
    isShowIcon,
    dropdownTargetRef,
    dropdownContentRef,
  } = props

  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const handleHover = index => {
    setHighlightedIndex(index)
  }

  const scrollHighlightedOptionIntoView = viewIndex => {
    if (dropdownContentRef?.current && highlightedIndex >= 0) {
      // viewIndex is upper and lower bound of view AutocompleteItemLayout
      const optionElement = dropdownContentRef.current.children[viewIndex]
      if (optionElement) {
        optionElement.scrollIntoView({ block: 'nearest', inline: 'start' })
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleKeyDown: keyStroke => {
      const lastIndex = options.length - 1
      if (keyStroke === 'ArrowUp') {
        setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
        scrollHighlightedOptionIntoView(highlightedIndex - 1)
      } else if (keyStroke === 'ArrowDown') {
        setHighlightedIndex(prevIndex => (prevIndex < lastIndex ? prevIndex + 1 : prevIndex))
        scrollHighlightedOptionIntoView(highlightedIndex + 1)
      } else if (keyStroke === 'Enter') {
        if (highlightedIndex >= 0 && highlightedIndex < options.length) {
          handleSelect(options[highlightedIndex])
        }
      }
    },
  }))

  return (
    <AutocompleteBoxItem
      id="autocomplete-box"
      data-testid="div-autocomplete-box"
      ref={dropdownTargetRef}
    >
      <AutocompletePaper size={numberAddress}>
        <AutocompleteItemLayout ref={dropdownContentRef}>
          {options.map((option, index) => (
            <AutocompleteItem
              id={`autocomplete-item-${option.key}`}
              isHighlight={highlightedIndex === index}
              key={option.key}
              option={option}
              onSelectOption={handleSelect}
              handleHover={() => handleHover(index)}
              isShowIcon={isShowIcon}
            />
          ))}
        </AutocompleteItemLayout>
      </AutocompletePaper>
    </AutocompleteBoxItem>
  )
})

AutocompleteDropdown.displayName = 'AutocompleteDropdown'
export { AutocompleteDropdown }
