import React from "react"
import { withFormik } from "formik"
import isEmpty from "lodash/isEmpty"

import { ButtonSet } from "components/buttons/button-set"

import ScreeningFeeForm from "./form"
import screeningFeeSchema from "./schema"

export const ScreeningFee = ({
  handleBackToScreeningOption,
  handleSubmit,
  isUpdatingScreeningFee,
  ...props
}) => {
  const { errors, values } = props
  const {
    creditReportFee,
    outOfPocketExpense: { amount },
    processingFee,
  } = values

  const isDisabledUpdateButton =
    !creditReportFee || !amount || !processingFee || !isEmpty(errors)

  return (
    <>
      <ScreeningFeeForm
        isUpdatingScreeningFee={isUpdatingScreeningFee}
        {...props}
      />
      <ButtonSet
        id="screeningFeeButtonSet"
        dataTestKey="screening-fee-breakdown-page"
        margin="25px 0 0"
        mMargin="15px 0 0"
        minWidth="100"
        continueBtnType="submit"
        continueText="Save & Next"
        text="Back to screening"
        btnHeight="40px"
        hidePrevButton
        onClickBackToDashboardBtn={handleBackToScreeningOption}
        disabledNextButton={isDisabledUpdateButton}
        onSaveAndNext={handleSubmit}
        isLoading={isUpdatingScreeningFee}
      />
    </>
  )
}

const ScreeningFeeTemplate = withFormik({
  mapPropsToValues: props => screeningFeeSchema(props).mapper,
  validationSchema: props => screeningFeeSchema(props).validation,
  handleSubmit: async (values, { props }) => {
    const {
      creditReportFee,
      outOfPocketExpense: {
        amount: outOfPocketExpenseAmount,
        for: outOfPocketExpenseDescription,
      },
      processingFee,
    } = values

    const screeningFee = {
      creditReportFee: parseFloat(creditReportFee),
      outOfPocketExpense: {
        amount: parseFloat(outOfPocketExpenseAmount),
        for: outOfPocketExpenseDescription || "N/A",
      },
      processingFee: parseFloat(processingFee),
    }

    props.onSubmit(screeningFee)
  },
  enableReinitialize: true,
  displayName: "ScreeningFeeFormTemplate",
})(ScreeningFee)

export default ScreeningFeeTemplate
