import { createStructuredSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getProperty } from 'legacy/actions/property.action'
import { selectProperty } from 'containers/listing-management/selectors'
import { makeSelectSubdomain } from 'containers/explore/selectors'

export const mapStateToProps = () =>
  createStructuredSelector({
    property: selectProperty,
    subdomain: makeSelectSubdomain(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getProperty,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
