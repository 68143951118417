import React from 'react'
import get from 'lodash/get'
import {
  countClosed,
  pluralizeWord,
  groupTradelines,
} from 'legacy/components/reports-v2/share/function'
import { BoxData } from 'legacy/components/reports-v2/share/doc-components'
import iconTradeLines from 'images/icons/credit-report/icon-tradelines.png'
import iconInquires from 'images/icons/credit-report/icon-credit-inquiries.png'
import iconPublicRecord from 'images/icons/credit-report/icon-public-record.png'
import iconCollections from 'images/icons/credit-report/icon-collections.png'
import styled from 'styled-components'
import { COLOR, breakpoints } from 'styles/settings'
import {
  SectionHeader,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  Label,
  Section,
  SubSection,
  Value,
} from 'components/reports/screening-reports/share/responsive-doc-style'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: ${({ rowGap = '10px', columnGap = '20px' }) => `${rowGap} ${columnGap}`};
  ${({ padding }) => padding && `padding: ${padding};`}
  padding-bottom: ${({ paddingBottom = '10px' }) => paddingBottom};

  ${breakpoints.mobileReport(`
    grid-template-columns: auto;
  `)}
`

const DerogatoryScoreValue = styled(Value)`
  font-size: 18px;
  font-weight: 600;

  ${breakpoints.mobileReport(`
    font-size: 14px;
    font-weight: 400;
    flex: 0.2;
  `)}
`
const DerogatoryScoreLabel = styled(Label)`
  width: 240px;
  font-weight: 400;
`

const DerogatoryScoreItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const DerogatoryScoreItem = ({ title, score }) => (
  <DerogatoryScoreItemContainer>
    <DerogatoryScoreLabel>{title}</DerogatoryScoreLabel>
    <DerogatoryScoreValue>{score}</DerogatoryScoreValue>
  </DerogatoryScoreItemContainer>
)

const ProfileSummary = ({ profileSum, tradelines, collections }) => {
  const derogItems = get(profileSum, 'derogItems', {})
  const groupedTradelines = tradelines ? groupTradelines(tradelines) : []
  const activeTradelines = get(groupedTradelines, 'active', []).length
  const closedTradelines = get(groupedTradelines, 'closed', []).length
  const closedCollections = countClosed(collections)
  const openedCollections = collections ? collections.length - closedCollections : 0

  return (
    <Section>
      <SubSection>
        <SectionHeader title="Profile Summary" />
        <SubSectionHeader title="Record Counts" />
        <GridContainer>
          <div>
            <BoxData
              id="tradelines-count-summary"
              header="Tradelines"
              detail={`${activeTradelines} active / ${closedTradelines} closed account(s)`}
              icon={iconTradeLines}
              color={COLOR.grayLight}
            />
          </div>
          <div>
            <BoxData
              id="credit-inquiries-count-summary"
              header="Credit Inquiries"
              detail={pluralizeWord('inquiry', get(profileSum, 'numberOfInquiries', 0))}
              icon={iconInquires}
              color={COLOR.grayLight}
            />
          </div>
          <div>
            <BoxData
              id="collections-count-summary"
              header="Collections"
              detail={`${openedCollections} open / ${closedCollections} closed account(s)`}
              icon={iconCollections}
              color={COLOR.grayLight}
            />
          </div>
          <div>
            <BoxData
              id="public-records-count-summary"
              header="Public Records"
              detail={pluralizeWord('record', get(derogItems, 'publicRecordCount'))}
              icon={iconPublicRecord}
              color={COLOR.grayLight}
              iconMarginRight="5px"
            />
          </div>
        </GridContainer>
      </SubSection>
      <SubSection>
        <SubSectionHeader title="Derogatory Items" />
        <GridContainer rowGap="0" padding="0 0 10px 0">
          <DerogatoryScoreItem
            id="negative-trades-count-summary"
            title="Negative Trades"
            score={`${get(derogItems, 'negTradelineCount') || '0'}`}
          />
          <DerogatoryScoreItem
            id="occurance-count-summary"
            title="Occurrence of Historical Negatives"
            score={`${get(derogItems, 'occuranceHistCount') || '0'}`}
          />
          <DerogatoryScoreItem
            id="historical-negative-tradelines-count-summary"
            title="Tradelines with any Historical Negatives"
            score={`${get(derogItems, 'histNegTradelineCount') || '0'}`}
          />
        </GridContainer>
      </SubSection>
    </Section>
  )
}

export default ProfileSummary
