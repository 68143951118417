import produce from 'immer'
import filter from 'lodash/filter'
import get from 'lodash/get'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'

import { CLEAR_ERROR } from 'containers/errors/constants'
import { checkSum } from 'utils/check-sum'
import { LEASE_AGREEMENTS } from 'constants/error-messages'
import {
  GET_LRA_ENVELOPE_REQUEST,
  GET_LRA_ENVELOPE_SUCCESS,
  GET_LRA_ENVELOPE_FAILURE,
} from 'containers/application/lra/sign-page/constants'
import { findSignerByRecipient } from 'helpers/signing'

import { getPageSplit } from './helpers/page-split'
import { calculatePosition, OPERATIONS } from '../drag-n-drop/helpers/calculate-position'
import {
  TYPES,
  TOOL_BAR_LIST,
  MAP_COMPONENT,
  TYPES_COMPONENT,
  SIGN_TYPE,
} from '../drag-n-drop/constants'
import { DragBox } from '../drag-n-drop/drag-box'
import {
  ENVELOPE_REQUEST,
  ENVELOPE_SUCCESS,
  ENVELOPE_FAILURE,
  UPDATE_ENVELOPE_REQUEST,
  UPDATE_ENVELOPE_SUCCESS,
  UPDATE_ENVELOPE_FAILED,
  SHARE_ENVELOPE_REQUEST,
  SHARE_ENVELOPE_SUCCESS,
  SHARE_ENVELOPE_FAILED,
  DATE_SIGNED_SAVE,
  SIGN_SIGNATURE_SAVE,
  SIGN_SIGNATURE_EDIT,
  RESET_ENVELOPE_REDUCER,
  UPDATE_ENVELOPE_SIGN_REQUEST,
  UPDATE_ENVELOPE_SIGN_SUCCESS,
  UPDATE_ENVELOPE_SIGN_FAILED,
  UPLOAD_SIGNATURE_REQUEST,
  UPLOAD_SIGNATURE_SUCCESS,
  UPLOAD_SIGNATURE_FAILED,
  SAVE_FILES_ENVELOPE_REQUEST,
  SAVE_FILES_ENVELOPE_SUCCESS,
  SAVE_FILES_ENVELOPE_FAILED,
  REMOVE_ACTIVE_BOX,
  UPDATE_ENVELOPE_CONSENT_REQUEST,
  UPDATE_ENVELOPE_CONSENT_SUCCESS,
  UPDATE_ENVELOPE_CONSENT_FAILED,
  AUTOSAVE_ENVELOPE_REQUEST,
  AUTOSAVE_ENVELOPE_SUCCESS,
  AUTOSAVE_ENVELOPE_FAILURE,
  SET_IS_AUTOSAVING,
  SET_RECIPIENT_ID_SELECTED,
  UPDATE_ENVELOPE_TEMPLATE_REQUEST,
  UPDATE_ENVELOPE_TEMPLATE_SUCCESS,
  UPDATE_ENVELOPE_TEMPLATE_FAILED,
  UPDATE_LEASE_AGREEMENT_CONSENT_FAILED,
  UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST,
  UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS,
  SHOW_CONSENT_MODAL,
  CLEAR_LEASE_ERROR,
  DATE_FIELD_FORMAT,
  TIME_FIELD_FORMAT,
  GET_BASE64_FILES_REQUEST,
  GET_BASE64_FILES_SUCCESS,
  GET_BASE64_FILES_FAILURE,
  SAVE_BUFFER_FILES_ENVELOPE_REQUEST,
  SAVE_BUFFER_FILES_ENVELOPE_SUCCESS,
  SAVE_BUFFER_FILES_ENVELOPE_FAILED,
  GENERATE_PDF_FILE_URL_REQUEST,
  GENERATE_PDF_FILE_URL_SUCCESS,
  GENERATE_PDF_FILE_URL_FAILED,
} from './constants'

export const initialState = {
  activeId: {},
  signature: {},
  isLoadingGetEnvelope: false,
  isLoadingUpdateEnvelope: false,
  isLoadingShareEnvelope: false,
  isUploadingSignature: false,
  isLoadingSavingFile: false,
  isLoadingUpdateConsent: false,
  updateConsentSuccess: false,
  isError: false,
  envelopeData: {},
  error: null,
  saveFileLoadings: [],
  toolbar: [],
  isAutosaving: false,
  isAutosaveError: false,
  recipientIdSelected: '',
  isAccepting: false,
  isConsentSuccess: false,
  isShowConsentModal: false,
  uploadSignatureSuccess: false,
  isSignatureEdited: false,
  base64Files: {
    data: null,
    loading: false,
    loaded: false,
    error: false,
  },
  isLoadingGeneratePdfFileURL: false,
}

export const findInsertionIndex = (array, element, compareFunc) => {
  let left = 0
  let right = array.length - 1
  while (left <= right) {
    // eslint-disable-next-line no-bitwise
    const mid = (left + right) >>> 1
    const cmp = compareFunc(element, array[mid])
    if (cmp > 0) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }
  return left
}

export const compareBox = (a, b) => {
  const THRESHOLD = 4
  const HEIGHT = 16

  // TODO
  // for now the sorting is calculated by the lower bound,
  // which p'mick has suggested it should have been the upper bound (top)
  // so the solution is to change from aLowerBound, bLowerBound to a.top, b.top
  const aLowerBound = a.top + get(a.styles, 'height', HEIGHT)
  const bLowerBound = b.top + get(b.styles, 'height', HEIGHT)
  if (Math.abs(aLowerBound - bLowerBound) <= THRESHOLD) {
    return a.left - b.left
  }
  return aLowerBound - bLowerBound
}

export const modifyItemValue = ({ item, recipients }) => {
  const { roleId } = findSignerByRecipient(recipients)
  const { value, assignee, format } = item

  if (!assignee || assignee === 'unassigned') return value

  const isAssignee = roleId === assignee
  switch (format) {
    case DATE_FIELD_FORMAT:
      if (isAssignee) return moment().format('MM/DD/YYYY')
      return value
    case TIME_FIELD_FORMAT:
      if (isAssignee) return moment().format('hh:mm A')
      return value
    default:
      return value
  }
}

export const modifyItemData = (i, j, item, recipients) => {
  const index = findIndex(
    recipients,
    recipient =>
      get(recipient, '_id') === item.assignee || get(recipient, 'roleId') === item.assignee,
  )

  const modifiedItem = {
    ...item,
    page: `${i},${j}`,
    element: DragBox(MAP_COMPONENT[item.type]),
    ...(item.format && {
      value: modifyItemValue({ item, recipients }),
    }),
  }
  if (
    includes(
      [
        TYPES_COMPONENT.TEXT_BOX,
        TYPES_COMPONENT.SIGNATURE_SIGNS,
        TYPES_COMPONENT.INITIAL_SIGNS,
        TYPES_COMPONENT.DATE_SIGNS,
        TYPES_COMPONENT.CHECK_BOX,
      ],
      item.type,
    )
  ) {
    return {
      ...modifiedItem,
      assignee: item.assignee,
      colorIndex: index,
    }
  }
  return modifiedItem
}

export const modifyEnvelopeData = payload => ({
  ...payload,
  files: payload.files.map((file, i) => ({
    ...file,
    pages: file.pages.map((page, j) => ({
      ...page,
      fields: page.fields.map(item => modifyItemData(i, j, item, payload.recipients)),
    })),
  })),
})

export const getPage = (envelopeData, pageNumber) => {
  const { files } = envelopeData
  const { file, page } = getPageSplit(pageNumber)
  return files[file].pages[page]
}

export const removeLoadingEnvelope = (loadingList, envelopeId) =>
  loadingList.filter(envelope => envelope.id !== envelopeId)

/* eslint-disable default-case, no-param-reassign */
const envelopeReducer = (state = initialState, { type, payload, ...rest }) =>
  produce(state, draft => {
    switch (type) {
      case UPDATE_ENVELOPE_CONSENT_REQUEST:
        draft.isLoadingUpdateConsent = true
        draft.error = null
        break
      case UPDATE_ENVELOPE_CONSENT_SUCCESS:
        draft.isLoadingUpdateConsent = false
        draft.updateConsentSuccess = true
        break
      case UPDATE_ENVELOPE_CONSENT_FAILED:
        draft.isLoadingUpdateConsent = false
        draft.updateConsentSuccess = false
        draft.error = {
          errorMessage: LEASE_AGREEMENTS.CONSENT_ERROR.TITLE,
          secondaryMessage: LEASE_AGREEMENTS.CONSENT_ERROR.MESSAGE,
        }
        break
      // Envelope Data reducer
      case SAVE_FILES_ENVELOPE_REQUEST:
        draft.isLoadingSavingFile = true
        draft.saveFileLoadings.push({ id: rest[0] })
        break
      case SAVE_FILES_ENVELOPE_SUCCESS:
      case SAVE_FILES_ENVELOPE_FAILED:
        draft.isLoadingSavingFile = false
        draft.saveFileLoadings = removeLoadingEnvelope(draft.saveFileLoadings, payload.envelopeId)
        break
      case SAVE_BUFFER_FILES_ENVELOPE_REQUEST:
        draft.isLoadingSavingFile = true
        draft.saveFileLoadings.push({ id: rest[0] })
        break
      case SAVE_BUFFER_FILES_ENVELOPE_SUCCESS:
      case SAVE_BUFFER_FILES_ENVELOPE_FAILED:
        draft.isLoadingSavingFile = false
        draft.saveFileLoadings = removeLoadingEnvelope(draft.saveFileLoadings, payload.envelopeId)
        break
      case GET_BASE64_FILES_REQUEST:
        draft.base64Files.data = null
        draft.base64Files.loading = true
        draft.base64Files.loaded = false
        break
      case GET_BASE64_FILES_SUCCESS:
        draft.base64Files.data = payload.fileData
        draft.base64Files.loading = false
        draft.base64Files.loaded = true
        break
      case GET_BASE64_FILES_FAILURE:
        draft.base64Files.loading = false
        draft.base64Files.loaded = false
        draft.base64Files.error = true
        break
      // Get pdf file as url object reduer
      case GENERATE_PDF_FILE_URL_REQUEST:
        draft.isLoadingGeneratePdfFileURL = true
        break
      case GENERATE_PDF_FILE_URL_SUCCESS:
        draft.isLoadingGeneratePdfFileURL = false
        break
      case GENERATE_PDF_FILE_URL_FAILED:
        draft.isLoadingGeneratePdfFileURL = false
        draft.error = payload.error
        break
      // Rental document Consent
      case UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST: {
        draft.isAccepting = true
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS: {
        draft.isAccepting = false
        draft.isConsentSuccess = payload.isConsentSuccess
        draft.isShowConsentModal = payload.isShowConsentModal
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_FAILED: {
        draft.isAccepting = false
        draft.error = {
          errorMessage: LEASE_AGREEMENTS.CONSENT_ERROR.TITLE,
          secondaryMessage: LEASE_AGREEMENTS.CONSENT_ERROR.MESSAGE,
        }
        break
      }
      case SHOW_CONSENT_MODAL: {
        draft.error = null
        draft.isShowConsentModal = payload
        break
      }
      case CLEAR_LEASE_ERROR: {
        draft.error = null
        draft.isConsentSuccess = false
        break
      }
      // #region
      case ENVELOPE_REQUEST:
      case GET_LRA_ENVELOPE_REQUEST:
        draft.isLoadingGetEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case ENVELOPE_SUCCESS:
      case GET_LRA_ENVELOPE_SUCCESS:
        draft.isLoadingGetEnvelope = false
        draft.isError = false
        draft.envelopeData = !isEmpty(payload) ? modifyEnvelopeData(payload) : {}
        break
      case ENVELOPE_FAILURE:
      case GET_LRA_ENVELOPE_FAILURE:
        draft.isLoadingGetEnvelope = false
        draft.isError = true
        break
      case UPDATE_ENVELOPE_REQUEST:
      case UPDATE_ENVELOPE_TEMPLATE_REQUEST:
      case UPDATE_ENVELOPE_SIGN_REQUEST:
        draft.isLoadingUpdateEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case UPDATE_ENVELOPE_SUCCESS:
      case UPDATE_ENVELOPE_TEMPLATE_SUCCESS:
      case UPDATE_ENVELOPE_SIGN_SUCCESS:
        draft.isLoadingUpdateEnvelope = false
        draft.isError = false
        draft.recipientIdSelected = ''
        draft.envelopeData = !isEmpty(payload) ? modifyEnvelopeData(payload) : {}
        break
      case UPDATE_ENVELOPE_TEMPLATE_FAILED:
      case UPDATE_ENVELOPE_FAILED:
      case UPDATE_ENVELOPE_SIGN_FAILED:
        draft.isLoadingUpdateEnvelope = false
        draft.isError = true
        draft.recipientIdSelected = ''
        draft.error = payload
        break
      case SHARE_ENVELOPE_REQUEST:
        draft.isLoadingShareEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case SHARE_ENVELOPE_SUCCESS:
        draft.isLoadingShareEnvelope = false
        draft.isError = false
        break
      case SHARE_ENVELOPE_FAILED:
        draft.isLoadingShareEnvelope = false
        draft.isError = true
        draft.error = payload
        break
      case UPLOAD_SIGNATURE_REQUEST:
        draft.isUploadingSignature = true
        draft.isError = false
        draft.uploadSignatureSuccess = false
        draft.error = null
        break
      case UPLOAD_SIGNATURE_SUCCESS:
        draft.isUploadingSignature = false
        draft.uploadSignatureSuccess = true
        draft.isError = false
        break
      case UPLOAD_SIGNATURE_FAILED:
        draft.isUploadingSignature = false
        draft.isError = true
        draft.error = payload
        break
      case CLEAR_ERROR:
        draft.isError = false
        draft.error = null
        break
      case AUTOSAVE_ENVELOPE_REQUEST:
        draft.isAutosaveError = false
        draft.isAutosaving = true
        break
      case AUTOSAVE_ENVELOPE_SUCCESS:
        draft.isAutosaveError = false
        draft.envelopeData = {
          ...draft.envelopeData,
          updatedAt: payload,
        }
        break
      case AUTOSAVE_ENVELOPE_FAILURE:
        draft.isAutosaveError = true
        draft.isAutosaving = false
        break
      case SET_IS_AUTOSAVING:
        draft.isAutosaving = payload
        break
      // #endregion

      // Drag and Drop component Reducer
      // #region
      case TYPES.ADD_CHILDREN_BOX: {
        const getPageData = getPage(draft.envelopeData, payload.page)
        const insertIndex = findInsertionIndex(getPageData.fields, payload.box, compareBox)
        getPageData.fields.splice(insertIndex, 0, payload.box)
        draft.activeId = {
          ...payload.box,
        }
        draft.toolbar = TOOL_BAR_LIST[payload.box.type]
        break
      }
      case TYPES.CHANGE_BOX_PAGE: {
        // source
        const sourcePage = getPage(draft.envelopeData, payload.sourcePage)

        let removedField

        sourcePage.fields = filter(sourcePage.fields, field => {
          const isKept = field.fieldId !== payload.fieldId
          if (!isKept) removedField = { ...field }
          return isKept
        })

        // destination
        const destinationPage = getPage(draft.envelopeData, payload.destinationPage)

        const newBox = {
          ...removedField,
          ...calculatePosition(OPERATIONS.CHANGE_PAGE, {
            destination: {
              x: payload.x,
              y: payload.y,
            },
            oldParent: {
              parentDimensions: payload.currentParentDimensions,
              ...removedField,
            },
            parentDimensions: {
              ...payload.newDimensions,
              width: payload.width,
              height: payload.height,
            },
            dimensions: payload.currentDimensions,
            ratio: payload.ratio,
          }),
          page: payload.destinationPage,
          parentDimensions: payload.newDimensions,
          recipient: payload.recipient,
        }

        const insertIndex = findInsertionIndex(destinationPage.fields, newBox, compareBox)
        destinationPage.fields.splice(insertIndex, 0, newBox)
        draft.activeId = {
          ...newBox,
        }
        draft.toolbar = TOOL_BAR_LIST[newBox.type]
        break
      }
      case TYPES.SET_ASSIGNEE_BOX: {
        const getPageData = getPage(draft.envelopeData, payload.page)

        // find and set assignee data to the field and activeId
        let indexToChange = 0
        for (; indexToChange < getPageData.fields.length; indexToChange += 1) {
          if (getPageData.fields[indexToChange].fieldId === payload.fieldId) {
            const nextField = {
              ...getPageData.fields[indexToChange],
              assignee: payload.assignee,
              colorIndex: payload.colorIndex,
              recipient: payload.recipient,
            }

            getPageData.fields[indexToChange] = { ...nextField }
            draft.activeId = { ...nextField }
            break
          }
        }

        break
      }
      case TYPES.SET_CONFIG_BOX: {
        const getPageData = getPage(draft.envelopeData, payload.page)

        // find and set style data to the field and activeId
        let indexToChange = 0
        for (; indexToChange < getPageData.fields.length; indexToChange += 1) {
          if (getPageData.fields[indexToChange].fieldId === payload.fieldId) {
            const nextField = {
              ...getPageData.fields[indexToChange],
              styles: {
                ...getPageData.fields[indexToChange].styles,
                ...payload.config,
              },
            }

            getPageData.fields[indexToChange] = { ...nextField }
            draft.activeId = { ...nextField }
            break
          }
        }
        break
      }
      case TYPES.SET_CHILDREN_BOX: {
        const getPageData = getPage(draft.envelopeData, payload.page)

        // find and change box
        let indexToChange = 0
        for (; indexToChange < getPageData.fields.length; indexToChange += 1) {
          if (getPageData.fields[indexToChange].fieldId === payload.box.fieldId) {
            getPageData.fields[indexToChange] = payload.box
            break
          }
        }

        // almost sorted array, so no need to do full sort
        // swap nearby box until in correct places
        // compare with the left side
        for (
          let checkIndex = indexToChange - 1;
          checkIndex >= 0 &&
          compareBox(getPageData.fields[indexToChange], getPageData.fields[checkIndex]) < 0;
          checkIndex -= 1
        ) {
          // swap
          const temp = getPageData.fields[indexToChange]
          getPageData.fields[indexToChange] = getPageData.fields[checkIndex]
          getPageData.fields[checkIndex] = temp

          // update the index accordingly
          indexToChange = checkIndex
        }

        // compare with the right side
        for (
          let checkIndex = indexToChange + 1;
          checkIndex < getPageData.fields.length &&
          compareBox(getPageData.fields[indexToChange], getPageData.fields[checkIndex]) > 0;
          checkIndex += 1
        ) {
          // swap
          const temp = getPageData.fields[indexToChange]
          getPageData.fields[indexToChange] = getPageData.fields[checkIndex]
          getPageData.fields[checkIndex] = temp

          // update the index accordingly
          indexToChange = checkIndex
        }

        draft.activeId = {
          ...payload.box,
        }
        draft.toolbar = TOOL_BAR_LIST[payload.box.type]
        break
      }
      case TYPES.REMOVE_CHILDREN_BOX: {
        const getPageData = getPage(draft.envelopeData, payload.page)
        getPageData.fields = filter(getPageData.fields, item => item.fieldId !== payload.boxKey)
        draft.activeId = {}
        draft.toolbar = []
        break
      }
      case TYPES.SET_ACTIVE_ID: {
        draft.activeId = { ...payload }
        draft.toolbar = TOOL_BAR_LIST[payload.type]
        break
      }
      case TYPES.RESET_ACTIVE:
        draft.activeId = {}
        draft.toolbar = []
        break
      case REMOVE_ACTIVE_BOX: {
        if (!isEmpty(draft.activeId) && !draft.activeId.undeletable) {
          const getPageData = getPage(draft.envelopeData, draft.activeId.page)
          getPageData.fields = filter(
            getPageData.fields,
            item => item.fieldId !== draft.activeId.fieldId,
          )
          draft.activeId = {}
          draft.toolbar = []
        }
        break
      }
      case SIGN_SIGNATURE_SAVE: {
        draft.signature = payload
        const getPageData = getPage(draft.envelopeData, draft.activeId.page)
        const date = moment().format('MM/DD/YYYY')
        getPageData.fields = getPageData.fields.map(item => {
          if (item.fieldId === draft.activeId.fieldId) {
            if (draft.activeId.type === TYPES_COMPONENT.SIGNATURE_SIGNS) {
              const c = checkSum(payload.fullName + draft.activeId.left + draft.activeId.top + date)
              return {
                ...draft.activeId,
                value: payload.fullName,
                checkSum: c,
                signatureType: payload.type,
              }
            }
            if (draft.activeId.type === TYPES_COMPONENT.INITIAL_SIGNS) {
              const c = checkSum(payload.initials + draft.activeId.left + draft.activeId.top + date)
              return {
                ...draft.activeId,
                value: payload.initials,
                signatureType: payload.type,
                checkSum: c,
              }
            }
            return draft.activeId
          }
          return item
        })
        draft.activeId = {}
        draft.toolbar = []
        break
      }
      case RESET_ENVELOPE_REDUCER: {
        draft = initialState
        break
      }
      case DATE_SIGNED_SAVE: {
        const getPageData = getPage(draft.envelopeData, draft.activeId.page)
        getPageData.fields = getPageData.fields.map(item => {
          if (item.fieldId === draft.activeId.fieldId) {
            if (draft.activeId.type === TYPES_COMPONENT.DATE_SIGNS) {
              return {
                ...draft.activeId,
                value: payload.dateSigned,
                timeZone: payload.timeZone,
              }
            }
            return draft.activeId
          }
          return item
        })
        draft.activeId = {}
        draft.toolbar = []
        break
      }
      case SIGN_SIGNATURE_EDIT: {
        draft.signature = payload
        const newFile = draft.envelopeData.files.map(files => ({
          ...files,
          pages: files.pages.map(pages => ({
            ...pages,
            fields: pages.fields.map(item => {
              const isActionType = SIGN_TYPE.includes(item.type)
              if (isActionType) {
                if (item.canEdit) {
                  let value
                  let checkSumData = null
                  const date = moment().format('MM/DD/YYYY')
                  if (item.type === TYPES_COMPONENT.INITIAL_SIGNS) {
                    checkSumData = checkSum(payload.initials + item.left + item.top + date)
                    value = payload.initials
                  } else if (item.type === TYPES_COMPONENT.SIGNATURE_SIGNS) {
                    checkSumData = checkSum(payload.fullName + item.left + item.top + date)
                    value = payload.fullName
                  }
                  return {
                    ...item,
                    value,
                    signatureType: payload.type,
                    checkSum: checkSumData,
                  }
                }
                if (item.value) {
                  return item
                }
              }
              return item
            }),
          })),
        }))
        draft.activeId = {}
        draft.envelopeData.files = newFile
        draft.isSignatureEdited = true
        break
      }
      // #endregion
      case SET_RECIPIENT_ID_SELECTED: {
        draft.recipientIdSelected = payload
        break
      }
    }
    return draft
  })

export default envelopeReducer
