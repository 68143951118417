import generateApiCall from "utils/generate-api-call"
import { TYPES } from "./constants"

export const callCompleteCreditReportPayment = payload => ({
  type: TYPES.CALL_COMPLETE_CREDIT_REPORT,
  payload,
})

export const completeCreditReportAPI = generateApiCall([
  TYPES.COMPLETE_CREDIT_REPORT_REQUESTED,
  TYPES.COMPLETE_CREDIT_REPORT_SUCCESS,
  TYPES.COMPLETE_CREDIT_REPORT_FAILED,
])

export const callCancelCreditReportPayment = payload => ({
  type: TYPES.CALL_CANCEL_CREDIT_REPORT,
  payload,
})

export const cancelCreditReportAPI = generateApiCall([
  TYPES.CANCEL_CREDIT_REPORT_REQUESTED,
  TYPES.CANCEL_CREDIT_REPORT_SUCCESS,
  TYPES.CANCEL_CREDIT_REPORT_FAILED,
])
