import React from 'react'
import { Box } from '@mui/material'
import { QueryClient } from '@tanstack/react-query'
import IdentityVerification from '@rentspree/component-2023.smart-components.identity-verification'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { compose } from 'redux'
import tracker from 'tracker'
import verification from 'images/report/identity-verification/verification.svg'
import { withConnect } from './connect'

export const IdentityVerificationPage = ({ actions, profile, rentalDetail }) => {
  const queryClient = new QueryClient()
  const { refreshProfile } = actions
  return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img
      width={170}
      height={170}
      src={verification}
      alt="required verification"
      style={{ marginTop: '120px', marginBottom: '24px' }}
    />
    <Typography variant="title" sx={{ marginBottom: '8px' }}>{`${rentalDetail?.renter?.firstName}'s application is ready`}</Typography>
    <Typography variant="body-medium" align='center' sx={{ marginBottom: '24px', maxWidth: '520px' }}>To access their screening reports, we’ll just need to verify your identity.</Typography>
    <IdentityVerification
      queryClient={queryClient}
      tracker={tracker}
      preVerificationButtonProps={{
        text: 'Access reports',
        variant: 'contained',
        color: 'secondary'
      }}
      // eslint-disable-next-line no-underscore-dangle
      userId={profile._id}
      onSuccessCallback={() => {
        refreshProfile()
      }}
    />
  </Box>

}

export default compose(withConnect)(IdentityVerificationPage)