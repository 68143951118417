import React from 'react'
import styled from 'styled-components'

import { COLOR, breakpoints } from 'styles/settings'
import {
  SubSectionTitle,
  Value,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { getScreeningReportStatuses } from '../helpers'

const Container = styled.div`
  margin-bottom: 20px;
  ${breakpoints.mobileReport(`
    margin-bottom: 15px;
  `)};
`

const ReportAttachedContainer = styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid ${COLOR.newGrey};
`

const Line = styled.hr`
  width: 100%;
  margin: 20px 0 0 0;
  border-top: 1px solid ${COLOR.newGrey};
`

const ReportAttached = ({ reportName, screeningOptionStatus }) => (
  <ReportAttachedContainer>
    <SubSectionTitle style={{ width: '50%', marginRight: '10px' }}>{reportName}</SubSectionTitle>
    <Value data-testid={`coverpage-${reportName}-attached`}>{screeningOptionStatus}</Value>
  </ReportAttachedContainer>
)

const TransUnionReportSummary = ({
  screeningOption = {},
  requestedScreeningOption,
  inLieuOfCredit,
}) => {
  const screeningReportStatuses = getScreeningReportStatuses(
    screeningOption,
    requestedScreeningOption,
    inLieuOfCredit,
  )
  const {
    credit_report: creditReportOptionStatus,
    criminal: criminalOptionStatus,
    eviction: evictionOptionStatus,
  } = screeningReportStatuses

  return (
    <Container>
      <Line />
      <ReportAttached
        reportName="Credit Score by TURSS"
        screeningOptionStatus={creditReportOptionStatus}
      />
      <ReportAttached
        reportName="Credit Report by TURSS"
        screeningOptionStatus={creditReportOptionStatus}
      />
      <ReportAttached
        reportName="Background Check Records by TURSS"
        screeningOptionStatus={criminalOptionStatus}
      />
      <ReportAttached
        reportName="Eviction Check Records by TURSS"
        screeningOptionStatus={evictionOptionStatus}
      />
    </Container>
  )
}

export default TransUnionReportSummary
