import { takeLatest, put, call } from 'redux-saga/effects'

import {
  FETCH_PROPERTY_BY_ADDRESS,
  fetchPropertyByAddressAPI,
} from 'v3/containers/create-property-form/actions'
import { fetchPropertyByAddressInstance } from 'v3/containers/create-property-form/api-instances'

export function* fetchPropertyByAddressSaga({ payload }) {
  yield put(fetchPropertyByAddressAPI.request())
  try {
    const properties = yield call(fetchPropertyByAddressInstance, payload)
    const isExistingProperty = properties.length > 0
    yield put(fetchPropertyByAddressAPI.success({ isExistingProperty }))
  } catch (error) {
    yield put(fetchPropertyByAddressAPI.failure(error))
  }
}

export function* watchPropertyByAddress() {
  yield takeLatest(FETCH_PROPERTY_BY_ADDRESS, fetchPropertyByAddressSaga)
}
