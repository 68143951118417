import React from 'react'
import styled from 'styled-components'

import { IS_SHOW_CRIMINAL_STATES_RESTRICTION as isShowCriminalStatesRestriction } from 'env'
import { COLOR } from 'styles/settings'
import Info from 'images/icons/contacts/info.svg'
import { LEGAL_RESTRICTIONS_LINK } from 'components/molecules/legal-restriction-text/constants'
import { SOURCE_SANS_PRO_FONT_FAMILY } from 'components/typography'
import { RESTRICTION_TEXT } from 'containers/tenant-screening/request-v2.2/constants'
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  gap: 10px;
  background-color: ${props => props.bgColor};
  border-radius: 10px;
  padding: ${props => props.padding};
  max-width: ${props => props.maxWidth};

  @media (max-width: 991px) {
    padding: ${props => props.mPadding};
  }
`
const LinkStyled = styled.a`
  color: ${COLOR.fontBlue};
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

const SpanStyled = styled.span`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const InfoIconTooltip = styled.img`
  width: 20px;
  height: 20px;
`

const LegalRestrictionText = ({ withIcon, bgColor, maxWidth, padding, mPadding }) => {
  if (!isShowCriminalStatesRestriction) {
    return <></>
  }

  return (
    <Container bgColor={bgColor} maxWidth={maxWidth} padding={padding} mPadding={mPadding}>
      {withIcon && <InfoIconTooltip src={Info} alt="info-icon" />}
      <div>
        <SpanStyled>{RESTRICTION_TEXT}</SpanStyled>
        <LinkStyled data-testid="screening-restrictions-link" id="ScreeningRestrictionsLink" href={LEGAL_RESTRICTIONS_LINK} target="_blank">
          Screening Restrictions
        </LinkStyled>
        <SpanStyled>.</SpanStyled>
      </div>
    </Container>
  )
}

export default LegalRestrictionText

LegalRestrictionText.defaultProps = {
  withIcon: true,
  bgColor: COLOR.bgGrey,
  padding: '15px',
}

LegalRestrictionText.propTypes = {
  withIcon: PropTypes.string,
  bgColor: PropTypes.string,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  mPadding: PropTypes.string,
}
