/* eslint-disable react/no-danger */
import React from "react"
import styled, { css } from "styled-components"
import { S24, B16, Button } from "@rentspree/component-v2"
import diamondBg from "images/backgrounds/gplay-diamond.png"
import { TOPBAR_HEIGHT } from "styles/settings"
import ErrorImg from "images/errors/general-error-icon.svg"
import { CenterContent } from "components/layout/main"

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT}
  ); /* fallback if calc did not work */
  min-height: calc(var(--vh, 1vh) * 100 - ${TOPBAR_HEIGHT});
  position: relative;
  font-family: Source Sans Pro;
  font-size: 14px;
  width: 100%;
  background: linear-gradient(rgba(249, 249, 249, 0.5) 0%, #f9f9f9),
    url(${diamondBg});
  ${props =>
    props.overlay &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    `}
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  text-align: center;
`
export const ErrorImageStyle = styled.img``

export const FailedToFetchFullPage = ({
  title,
  subTitle,
  overlay,
  buttonText = 'Try Again',
  ...props
}) => (
  <MainContainer overlay={overlay}>
    <CenterContent className="-w100" {...props}>
      <Container>
        <ErrorImageStyle src={ErrorImg} />
        <S24 margin="25px 0 10px" size="28px">{title}</S24>
        <B16 margin="0 0 30px" size="14px">
          {subTitle}
        </B16>
        <Button
          google
          text= {buttonText} 
          small
          id="tryAgainButton"
          onClick={() => window.location.reload()}
        />
      </Container>
    </CenterContent>
  </MainContainer>
)

FailedToFetchFullPage.defaultProps = {}

export default FailedToFetchFullPage
