import { Icon } from '@rentspree/component-v2/dist/icon'
import React from 'react'
import styled from 'styled-components'

import HideIcon from 'components/contacts/atoms/hide-icon'
import ProgressBar from 'components/contacts/atoms/progress-bar'
import { WidgetTitle, WidgetSubTitle, TextButton } from 'components/contacts/atoms/widget'
import MinimizeWidget from 'components/contacts/molecules/minimize-widget'
import importContactImg from 'images/contacts/import-contact.svg'
import windowMaximizedImg from 'images/contacts/window-maximize.svg'
import { COLOR } from 'styles/settings'

const FlexWrapper = styled.div`
  display: flex;
`
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
`
const ImportIcon = styled.img`
  margin-right: 12px;
`
const CancelButton = styled(TextButton)`
  visibility: ${props => props?.minimizePage && 'hidden'};
`
const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 15px;
  display: ${props => props?.minimizePage && 'none'};
`

const InProgressWidget = ({ minimizePage, percentage, onResize, onCancel }) => (
  <>
    {minimizePage ? (
      <MinimizeWidget
        title="Importing contacts..."
        suffixIcon={windowMaximizedImg}
        onClickSuffixIcon={onResize}
      />
    ) : (
      <>
        <HeaderWrapper>
          <HideIcon onClick={onResize} />
          <CloseIcon icon="close" color={COLOR.textBlack} onClick={onCancel} />
        </HeaderWrapper>
        <FlexWrapper>
          <ImportIcon className="maximize" src={importContactImg} alt="import-contact" />
          <div>
            <WidgetTitle>Importing contacts...</WidgetTitle>
            <WidgetSubTitle>
              Do not refresh or close this page, but you still can view other contents at the
              meantime.
            </WidgetSubTitle>
          </div>
        </FlexWrapper>
      </>
    )}
    <BottomWrapper>
      <ProgressBar percent={percentage} />
      <CancelButton minimizePage={minimizePage} onClick={onCancel}>
        Cancel
      </CancelButton>
    </BottomWrapper>
  </>
)

export default InProgressWidget
