/* eslint-disable no-underscore-dangle, camelcase, no-console */
import React from 'react'
import { compose } from 'redux'
import { Route, withRouter } from 'react-router-dom'
import { withLastLocation } from 'react-router-last-location'

import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import { buildPath, query } from '@rentspree/path'
import base64 from 'base-64'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import tracker from 'tracker'
import { setLocalItem, removeLocalItem } from '@rentspree/cookie'
import * as PATH from 'constants/route'
import StepPropertyDetails from 'containers/request/step-property-details'
import StepScreeningOptions from 'containers/request/step-screening-options'
import RequestStepOptionsV2_2 from 'containers/tenant-screening/request-v2.2'
import StepUpdateDreLicenseV2_2 from 'containers/tenant-screening/request-v2.2/step-update-dre-license'
import StepUpdateScreeningFeeV2_2 from 'containers/tenant-screening/request-v2.2/step-update-screening-fee'
import { selectScreeningRequestResultPath } from 'containers/request/helpers/select-paths'
import { selectScreeningRequestResultPath as selectScreeningRequestResultPathV2 } from 'containers/tenant-screening/request-v2.2/helper/select-paths'
import withScreenTenantRequirement from 'containers/request/with-screen-tenant-requirement'
import { saveNewListingTrackerWithUserRoleMapper } from 'tracker/tracker-mapper'
import StepUpdateZipLogixProperty from 'containers/request/step-update-ziplogix-property'
import {
  FEATURE,
  SCREENING_REQUEST_SOURCE,
} from 'containers/property/toggle-property-feature/constants'
import {
  withReducer as withCarModalReducer,
  withSaga as withCarModalSaga,
} from 'containers/envelope/select-options-step/connect'

import { SCREENING_REQUEST_SOURCE as SCREENING_REQUEST_SOURCE_CONST } from 'containers/constants'
import { withSubscriptionData } from 'utils/subscription/with-subscription-data'
import { getProOptionPayload } from 'containers/tenant-screening/request/utils'
import withSelectScreeningOptionsPage from 'containers/tenant-screening/with-select-screening-option-page'
import { USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { withUserPreferenceData } from 'utils/tenant-screening/with-user-preference-data'
import CreatePropertyPage from 'containers/request/step-create-property'
import RequestStepUpdateAddressOriginal from './step-update-info/index'
import { withRequireMailingAddress } from './request-require-mailing-address'
import RequestMainConnect from './request-main-connect'
import { STORAGE } from '../../../constants/cookie'
import { withTracker } from '../with-tracker'
import { RequestWrapper } from '../../components/request/request-main-style'
import { PAYER } from '../../constants/request-const'
import { LoadingOverlay } from '../../components/request/left-content'
import * as ERRORS from '../../constants/error-messages'
import { FailedToFetch } from '../../components/layout/failed-to-fetch'
import { handleGoBackByDefault } from '../../../utils/history'
import RequestStepActionsOriginal from './step-actions'

const RequestStepProperty = withRouter(withTracker(withRequireMailingAddress(CreatePropertyPage)))

const RequestStepOptions = compose(
  withRouter,
  withTracker,
  withScreenTenantRequirement,
  withSubscriptionData,
  withUserPreferenceData,
  withSelectScreeningOptionsPage,
)(RequestStepOptionsV2_2, StepScreeningOptions)

const RequestStepActions = withRouter(
  withTracker(withScreenTenantRequirement(RequestStepActionsOriginal)),
)

const RequestStepUpdateAddress = withRouter(withTracker(RequestStepUpdateAddressOriginal))

const UpdateAddressZipLogix = withRouter(withTracker(StepUpdateZipLogixProperty))

@withRouter
@RequestMainConnect
export class RequestApp extends React.Component {
  constructor(props) {
    super(props)
    this.initState()
  }

  componentWillMount() {
    const { portalData } = query.parse(this.props.location.search)
    if (portalData) {
      this.handlePortalData(portalData)
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', () => this.clearStorage())
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isFetching = get(this.props, 'property.isFetching', false)
    const nextIsFetching = get(nextProps, 'property.isFetching', true)
    const queryString = query.parse(this.props.history?.location?.search)
    const source = queryString.source || 'property'
    const property = get(nextProps, 'property.property')
    const isTenantScreeningDisabled =
      !!get(property, ['disabledFeatures', FEATURE.TENANT_SCREENING]) &&
      source === SCREENING_REQUEST_SOURCE.PROPERTY

    const isFetchedAndTenantScreeningIsDisabled =
      isFetching && !nextIsFetching && isTenantScreeningDisabled

    if (isFetchedAndTenantScreeningIsDisabled) {
      this.redirectToPropertyListPage(property._id)
    }

    // Don't update if props is not changed
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.props) {
      if (typeof this.props[key] !== 'function') {
        if (JSON.stringify(this.props[key]) !== JSON.stringify(nextProps[key])) {
          return true
        }
      }
    }

    // Don't update if state is not changed
    if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    const { actions, property } = this.props
    const { property: propertyDetails } = property
    if (!isEmpty(propertyDetails)) {
      actions.clearProperty()
    }
    window.removeEventListener('beforeunload', this.clearStorage())
  }

  redirectToPropertyListPage(propertyId) {
    this.props.history.replace(buildPath(PATH.SINGLE_PROPERTY, { propertyId }))
  }

  initState() {
    this.state = {
      propertyData: null,
      emails: null,
      requestMethod: 'email',
    }
  }

  handlePortalData(portalData) {
    try {
      const decryptedData = this.decryptMd5Property(portalData)
      this.saveRequestProperty(decryptedData.property)
    } catch (error) {
      console.error('Error ->', error.message)
    }
  }

  saveRequestProperty(properties) {
    const property = {
      ...properties,
      subdomain: this.props.subdomain,
    }
    setLocalItem(STORAGE.REQUEST_PROPERTY, property)
    const zipformData = { transid: property.id }
    setLocalItem(STORAGE.ZF_DATA, zipformData)
  }

  decryptMd5Property(property) {
    return JSON.parse(base64.decode(property))
  }

  selectUrl(res, isContinueToPropertyDetails, continueTo, source) {
    if (res?.data) {
      const propertyId = get(res, 'data._id', '')
      if (isContinueToPropertyDetails) {
        return buildPath(
          PATH.REQUEST_UPDATE_PROPERTY_DETAILS,
          {
            propertyId,
          },
          { continueTo, source },
        )
      }

      let redirectPath = PATH.PROPERTY_OVERVIEW_PAGE
      let redirectQueryString
      if (continueTo) {
        redirectPath = redirectPath.replace('/overview', continueTo)
        redirectQueryString = source ? { source } : undefined
      }

      return buildPath(
        redirectPath,
        {
          propertyId,
        },
        redirectQueryString,
      )
    }

    // ValidationError case
    return PATH.REQUEST_UPDATE_INFO
  }

  async createProperty(
    property,
    isContinueToPropertyDetails = false,
    continueTo = undefined,
    source = undefined,
  ) {
    const { actions, history } = this.props
    const { userRoleForProperty, landlordProfile, userInputRole } = property
    const res = await actions.createProperty(property)
    const representing = saveNewListingTrackerWithUserRoleMapper(
      userRoleForProperty || userInputRole,
    )
    let hasLandlordEmail = 'none'
    if (userRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT) {
      hasLandlordEmail = !!landlordProfile && !!landlordProfile.email
    }

    tracker.trackEvent(EVENT_REQUEST_SCREENING.saveNewProperty, {
      representing,
      has_landlord_email: hasLandlordEmail,
    })

    const url = this.selectUrl(res, isContinueToPropertyDetails, continueTo, source)
    return history.push(url)
  }

  async createScreeningRequest({
    propertyId,
    screeningMethod,
    currentPriceScheme,
    isAcceptedBackgroundDisclaimer,
    returnResult = false,
    source = SCREENING_REQUEST_SOURCE_CONST.PROPERTY,
    agentType,
  }) {
    const { actions, history, requestOptions, subdomain, preference, agentLicenses, location } =
      this.props
    const { selectingItems, payer, applicationType } = requestOptions
    const { credit_report, eviction, criminal, application } = currentPriceScheme
    let data = {
      selectedScreeningOption: {
        application:
          includes(selectingItems, 'application') && subdomain !== 'caa' && !application.disabled,
        credit_report: includes(selectingItems, 'credit_report') && !credit_report.disabled,
        eviction: includes(selectingItems, 'eviction') && !eviction.disabled,
        criminal: includes(selectingItems, 'criminal') && !criminal.disabled,
      },
      // TODO (LRA): Move these fields into screening options to comply with the new standard
      applicationType: applicationType || undefined,
      payerType: payer || PAYER.RENTER,
      source,
    }

    if (agentType) {
      data = {
        ...data,
        agentType,
      }
    }

    const selectedDocumentProOption = get(requestOptions, 'selectedDocumentProOption', {})
    const selectedRefCheckProOption = get(requestOptions, 'selectedRefCheckProOption', {})
    const proOption = getProOptionPayload(selectedDocumentProOption, selectedRefCheckProOption)
    if (!isEmpty(proOption)) {
      data = {
        ...data,
        proOption,
      }
    }

    const updateDisclaimerValue = isUndefined(isAcceptedBackgroundDisclaimer)
      ? get(preference, 'isAcceptedBackgroundDisclaimer', false)
      : isAcceptedBackgroundDisclaimer
    actions.updateUserPreference({
      defaultScreeningOption: data.selectedScreeningOption,
      payerType: data.payerType,
      isAcceptedBackgroundDisclaimer: updateDisclaimerValue,
      applicationType: data.applicationType,
    })

    const res = await actions.createScreeningRequest(propertyId, data)

    if (res?.data) {
      // TODO: Handle screeningMethod from `Screen new tenants` button
      // to dynamic for link and handout methods not only email method
      const screeningMethodSearch = screeningMethod ? { screeningMethod } : {}
      const { _id: screeningRequestId } = res.data || {}
      const nextPage = selectScreeningRequestResultPath({
        requestOptions,
        licenses: agentLicenses,
        propertyId,
        screeningRequestId,
        otherQueryString: {
          ...screeningMethodSearch,
          ...query.parse(location.search),
        },
      })

      if (returnResult) return { nextPage, apiPayload: data, apiResponse: res.data }

      history.push(nextPage)
    } else {
      actions.openSweetAlertBaseError()
    }

    return false
  }

  clearStorage() {
    removeLocalItem(STORAGE.REQUEST_PROPERTY)
    removeLocalItem(STORAGE.ZF_DATA)
  }

  handleBackToDashboard = () => {
    const { history, lastLocation } = this.props
    this.clearStorage()
    this.initState()
    handleGoBackByDefault(lastLocation, history)
  }

  getNextPath = ({
    requestOptions,
    propertyId,
    screeningMethod,
    screeningRequestId,
    agentLicenses = [],
  }) => {
    const { history, location } = this.props
    const screeningMethodSearch = screeningMethod ? { screeningMethod } : {}
    const nextPathParam = {
      requestOptions: {
        applicationType: requestOptions.applicationType,
        payer: requestOptions.payerType,
      },
      licenses: agentLicenses,
      propertyId,
      screeningRequestId,
      otherQueryString: {
        ...screeningMethodSearch,
        ...query.parse(location.search),
        propertyId,
      },
      emails: history.location?.state?.emails || [],
    }
    const nextPath = selectScreeningRequestResultPathV2(nextPathParam)
    return nextPath
  }

  isLoading() {
    return this.props.isFetchingProfile
  }

  render() {
    const props = {
      handleBackToDashboard: this.handleBackToDashboard,
      saveRequestProperty: this.saveRequestProperty.bind(this),
      createProperty: this.createProperty.bind(this),
      subdomain: this.props.subdomain,
      initState: this.initState.bind(this),
      createScreeningRequest: this.createScreeningRequest.bind(this),
      getNextPath: this.getNextPath,
      ...this.props,
    }

    if (this.isLoading()) {
      return (
        <RequestWrapper>
          <LoadingOverlay />
        </RequestWrapper>
      )
    }

    if (isEmpty(this.props.profile)) {
      return (
        <FailedToFetch
          title={ERRORS.REQUEST.GET_SCREENING_PLAN.TITLE}
          text={ERRORS.REQUEST.GET_SCREENING_PLAN.MESSAGE}
        />
      )
    }

    return (
      <RequestWrapper>
        <Route exact path={PATH.REQUEST} render={() => <RequestStepProperty {...props} />} />
        <Route
          exact
          path={PATH.PERMALINK_ACTIONS_ID}
          render={() => <RequestStepOptions {...query.parse(window.location.search)} {...props} />} // FIX ME: later when clean up screening request v1. Should remove query passing to props, it's prone to props injection via URL
        />
        {/* // TODO: Clean up this route after clean up PREMIUM SCREENING FF */}
        {/* This route use for step action page in CAA subdomain */}
        <Route
          exact
          path={PATH.PERMALINK_ACTIONS_SCREENING}
          render={() => <RequestStepActions {...props} isLegacyVersion />}
        />

        <Route
          exact
          path={PATH.REQUEST_UPDATE_INFO}
          render={() => <RequestStepUpdateAddress {...props} />}
        />
        <Route
          exact
          path={PATH.REQUEST_UPDATE_PROPERTY}
          render={() => <UpdateAddressZipLogix {...props} />}
        />
        <Route
          exact
          path={PATH.REQUEST_UPDATE_PROPERTY_DETAILS}
          render={() => <StepPropertyDetails {...props} />}
        />
        <Route
          exact
          path={PATH.REQUEST_UPDATE_DRE_LICENSE}
          render={() => (
            <StepUpdateDreLicenseV2_2
              agentLicenses={props.agentLicenses}
              handleBackToDashboard={props.handleBackToDashboard}
              getNextPath={props.getNextPath}
            />
          )}
        />
        <Route
          exact
          path={PATH.REQUEST_UPDATE_SCREENING_FEE}
          render={() => (
            <StepUpdateScreeningFeeV2_2
              agentLicenses={props.agentLicenses}
              handleBackToDashboard={props.handleBackToDashboard}
              getNextPath={props.getNextPath}
            />
          )}
        />
      </RequestWrapper>
    )
  }
}

export default compose(withLastLocation, withCarModalReducer, withCarModalSaga)(RequestApp)
