import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'
import { selectRentalDetail } from 'containers/reports/selectors'
import { selectFullNameAddress, selectStreet } from 'containers/listing/selectors'
import { selectTitle } from 'containers/lease-agreement-details/selectors'
import { makeSelectRequesteeEmail } from 'containers/renters-insurance-details/selectors'
import { selectContactName, selectContactId } from 'containers/contacts/detail/selectors'

const mapStateToProps = createStructuredSelector({
  rentalDetail: selectRentalDetail,
  address: selectFullNameAddress,
  street: selectStreet,
  leaseTitle: selectTitle(),
  rentersRequesteeEmail: makeSelectRequesteeEmail(),
  contactName: selectContactName,
  contactId: selectContactId,
})

export const withBreadcrumb = compose(withRouter, connect(mapStateToProps))
