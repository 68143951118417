import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { GET_RENTAL_PAYMENT_BY_ID_API } from './constants'

export const getRentalPaymentByIdInstance = ({ rentalPaymentId }) =>
  apiInstanceWithErrorHandler.get(
    buildPath(GET_RENTAL_PAYMENT_BY_ID_API, { rentalPaymentId }, { role: 'payee' }),
  )
