import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { initialState } from './reducer'
import { getProOptionPayload } from './utils'

export const getTenantScreeningRequest = state => get(state, 'tenantScreeningRequest', initialState)

export const selectIsFetching = createSelector(getTenantScreeningRequest, tenantScreeningRequest =>
  get(tenantScreeningRequest, 'isFetching', initialState.isFetching),
)

export const selectIsSubmitting = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(
      tenantScreeningRequest,
      'requestOption.isSubmitting',
      initialState.requestOption.isSubmitting,
    ),
)

export const selectPriceScheme = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    const screeningPlans = get(
      tenantScreeningRequest,
      'screeningPlans',
      initialState.screeningPlans,
    )
    const requestOption = get(tenantScreeningRequest, 'requestOption', initialState.requestOption)

    if (isEmpty(requestOption.payload.payerType)) return {} // in case the user does not have defaultScreeningOption and there are 2 screening plans

    const userSelectScreeningPlans = screeningPlans.find(plan =>
      ['all', requestOption.payload.payerType].includes(plan.payerType),
    )

    return {
      application: {
        ...userSelectScreeningPlans.application,
        isSelected: requestOption.payload.application,
      },
      credit_report: {
        ...userSelectScreeningPlans.credit_report,
        isSelected: requestOption.payload.credit_report,
      },
      criminal: {
        ...userSelectScreeningPlans.criminal,
        isSelected: requestOption.payload.criminal,
      },
      eviction: {
        ...userSelectScreeningPlans.eviction,
        isSelected: requestOption.payload.eviction,
      },
    }
  },
)

export const selectSubdomain = createSelector(getTenantScreeningRequest, tenantScreeningRequest =>
  get(
    tenantScreeningRequest,
    'screeningPlans[0].subdomain',
    initialState.screeningPlans[0].subdomain,
  ),
)

export const selectPayerType = createSelector(getTenantScreeningRequest, tenantScreeningRequest =>
  get(
    tenantScreeningRequest,
    'requestOption.payload.payerType',
    initialState.requestOption.payload.payerType,
  ),
)

export const selectSelectedScreeningOption = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    // eslint-disable-next-line camelcase
    const { application, credit_report, criminal, eviction } = get(
      tenantScreeningRequest,
      'requestOption.payload',
      initialState.requestOption.payload,
    )
    return {
      application,
      credit_report,
      criminal,
      eviction,
    }
  },
)

export const selectCreateScreeningRequestPayload = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    let payload = get(tenantScreeningRequest, 'requestOption.payload', {})
    const proOption = getProOptionPayload(
      get(tenantScreeningRequest, 'requestOption.documentProOption', {}),
      get(tenantScreeningRequest, 'requestOption.refCheckProOption', {}),
    )
    if (!isEmpty(proOption)) payload = { ...payload, proOption }
    return payload
  },
)

export const selectIsAcceptedBackgroundDisclaimer = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(
      tenantScreeningRequest,
      'userPreference.isAcceptedBackgroundDisclaimer',
      initialState.userPreference.isAcceptedBackgroundDisclaimer,
    ),
)

export const selectRequestOptions = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    // eslint-disable-next-line camelcase
    const { application, credit_report, criminal, eviction } = get(
      tenantScreeningRequest,
      'requestOption.payload',
      {},
    )
    const payer = get(tenantScreeningRequest, 'requestOption.payload.payerType')
    const isInitial = get(tenantScreeningRequest, 'requestOption.isInitial')
    return {
      payer,
      isInitial,
      selectingItems: {
        application,
        credit_report,
        criminal,
        eviction,
      },
    }
  },
)

export const selectDocumentProOption = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'requestOption.documentProOption', {}),
)

export const selectRefCheckProOption = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'requestOption.refCheckProOption', {}),
)

export const selectIsFirstTimeUser = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'isFirstTimeUser', null),
)
