import styled from "styled-components"

// language=SCSS prefix=dummy{ suffix=}
export const failedMessageDiv = styled.div`
  max-width: 580px;
  padding: 15px;

  p {
    margin: 0 0 15px;
  }
`

export const wrapperFailedShouldDo = styled.div`
  font-size: 1.1em;
  max-width: 500px;
  padding: 20px;
`

export const failedShouldIDoTitle = styled.div`
  img {
    margin-bottom: 20px;
  }
  padding: 10px;
`

export const failedShouldIDo = styled.div`
  max-width: 700px;
  h3 {
    font-weight: 200;
  }
  ul {
    list-style: inherit;
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
  }
`
