export const IDV_STATUS = {
  EMAIL_VERIFICATION: 'email_verification',
  VERIFICATION: 'verification',
  VERIFICATION_FAIL: 'verification_fail',
  NONE: 'none',
}

export const UTM_SOURCE = {
  HUBSPOT_EMAIL: 'hs_email',
}

export const UTM_CAMPAIGN = {
  DOCUMENT_REQUEST: 'document_request',
  REFERENCE_CHECKS: 'reference_checks',
}
