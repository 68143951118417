import produce from 'immer'

import {
  OPEN_CREATE_CONTACT_MODAL,
  CLOSE_CREATE_CONTACT_MODAL,
  CREATE_CONTACT_FROM_MODAL_REQUEST,
  CREATE_CONTACT_FROM_MODAL_SUCCESS,
  CREATE_CONTACT_FROM_MODAL_FAILURE,
} from './constants'

export const initialState = {
  isOpen: false,
  isSubmitting: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_CREATE_CONTACT_MODAL:
        draft.isOpen = true
        draft.isSubmitting = false
        break
      case CLOSE_CREATE_CONTACT_MODAL:
        draft = initialState
        break
      case CREATE_CONTACT_FROM_MODAL_REQUEST:
        draft.isSubmitting = true
        break
      case CREATE_CONTACT_FROM_MODAL_SUCCESS:
      case CREATE_CONTACT_FROM_MODAL_FAILURE:
        draft.isSubmitting = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
