import {
  CLOSE_ADD_YOUR_INFORMATION_MODAL,
  CLOSE_CREATE_PROPERTY_MODAL,
  CLOSE_CREATE_RENTER_INFO_MODAL,
  CLOSE_MARK_AS_PAID_MODAL,
  OPEN_ADD_YOUR_INFORMATION_MODAL,
  OPEN_CREATE_PROPERTY_MODAL,
  OPEN_CREATE_RENTER_INFO_MODAL,
  OPEN_MARK_AS_PAID_MODAL,
} from './constants'

export const openAddYourInformationModal = ({ returnUrl }) => ({
  type: OPEN_ADD_YOUR_INFORMATION_MODAL,
  payload: { returnUrl },
})

export const closeAddYourInformationModal = () => ({
  type: CLOSE_ADD_YOUR_INFORMATION_MODAL,
})

export const openMarkAsPaidModal = ({ paymentRequestId }) => ({
  type: OPEN_MARK_AS_PAID_MODAL,
  payload: { paymentRequestId },
})

export const closeMarkAsPaidModal = () => ({
  type: CLOSE_MARK_AS_PAID_MODAL,
})

export const openCreatePropertyModal = () => ({
  type: OPEN_CREATE_PROPERTY_MODAL,
})

export const closeCreatePropertyModal = () => ({
  type: CLOSE_CREATE_PROPERTY_MODAL,
})

export const openCreateRenterInfoModal = () => ({
  type: OPEN_CREATE_RENTER_INFO_MODAL,
})

export const closeCreateRenterInfoModal = () => ({
  type: CLOSE_CREATE_RENTER_INFO_MODAL,
})
