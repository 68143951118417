import { call, put, takeLatest } from 'redux-saga/effects'

import { TOAST_MESSAGE } from 'components/rent-payment/payment-card/constants'
import { RENT_PAYMENT_ERROR } from 'constants/error-messages'
import { addToast } from 'containers/toast/actions'
import { OPEN_SWEET_ALERT } from 'legacy/constants/action-types'
import { ALERT_PRESET } from 'redux-middleware/sweet-alert'

import { SEND_INVITATION_EMAIL, sendInvitationEmailAPI } from '../../actions'
import { sendInvitationEmailInstance } from '../../api-instances'

export function* popupToast() {
  const toastConfig = {
    titleMessage: TOAST_MESSAGE.SUCCESS,
    status: 'success',
    timeOut: 10000,
  }
  yield put(addToast(toastConfig))
}

export function* sendInvitationEmailSaga({ payload }) {
  const { rentalPaymentId, onSuccess } = payload
  yield put(sendInvitationEmailAPI.request())
  try {
    const response = yield call(sendInvitationEmailInstance, rentalPaymentId)
    yield put(sendInvitationEmailAPI.success(response))
    yield call(onSuccess)
    yield call(popupToast)
  } catch (err) {
    yield put(sendInvitationEmailAPI.failure(err))
    // yield put(closeModalResendEmail())
    yield put({
      type: OPEN_SWEET_ALERT,
      preset: ALERT_PRESET.ERROR,
      option: {
        title: RENT_PAYMENT_ERROR.RESEND_ERROR.TITLE,
        text: RENT_PAYMENT_ERROR.RESEND_ERROR.MESSAGE,
        button: 'Close',
      },
    })
  }
}

export function* watchSendInvitationEmailSaga() {
  yield takeLatest(SEND_INVITATION_EMAIL, sendInvitationEmailSaga)
}
