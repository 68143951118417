import * as Types from "legacy/constants/action-types"

export const GET_USER_SUBSCRIPTION_API_URL = "/api/v2/subscriptions/users/plans"

export const GET_SUBSCRIPTION_CALL = "GET_SUBSCRIPTION_CALL"
export const { GET_SUBSCRIPTIONS_AUTH_REQUEST } = Types
export const { GET_SUBSCRIPTIONS_AUTH_SUCCESS } = Types
export const { GET_SUBSCRIPTIONS_AUTH_FAILED } = Types

export const KEY_PLAN = "subscriptionsPlan"
