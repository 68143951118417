import { combineReducers } from "redux"
import indexOf from "lodash/indexOf"

function loadSubDomain() {
  const full = window.location.host
  const parts = full.split(".")

  if (indexOf(["www", "rentspree", "localhost:3000"], parts[0]) === -1) {
    return {
      sub: parts[0] || null,
      domain: parts[1] || null,
    }
  }
  return null
}

const subdomain = (state = loadSubDomain()) => state

export default combineReducers({
  subdomain,
})

export const getSub = state => (state.subdomain ? state.subdomain.sub : null)
