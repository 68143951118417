import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_API } from './constants'

export const getOrCreateDraftRentalPaymentInstance = ({ propertyId, renterInfoId }) =>
  apiInstanceWithErrorHandler.post(buildPath(GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_API), {
    propertyId,
    renterInfoId,
  })
