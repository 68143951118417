import {
  takeLatest,
  takeEvery,
  put,
  call,
  all,
  select,
} from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import { apiInstance } from "utils/api-interceptor"
import { selectDataByParentId, selectCurrentPopper } from "./selectors"
import { API_NOTICES, TYPES } from "./const"
import {
  getNoticesRequest,
  getNoticesSuccess,
  getNoticesFailed,
  updateNoticesRequest,
  updateNoticesSuccess,
  updateNoticesFailed,
  showPopper,
  onNext,
  skipNotice,
} from "./actions"

export const getNoticesAPI = query =>
  apiInstance.get(buildPath(API_NOTICES, null, query))

export const updateNoticesAPI = data => apiInstance.post(API_NOTICES, data)

export function* updateNotices({ payload: { group, _id } }) {
  yield put(updateNoticesRequest())
  try {
    yield call(updateNoticesAPI, [group])
    yield put(updateNoticesSuccess(_id))
  } catch (err) {
    yield put(updateNoticesFailed())
  }
}
export function* showCurrentPopper() {
  const seletedItem = yield select(selectCurrentPopper)
  if (seletedItem) {
    const { parentId } = seletedItem
    yield put(showPopper(parentId))
  } else {
    yield put(showPopper(""))
  }
}

export function* getNotices({ payload }) {
  let response
  yield put(getNoticesRequest())
  try {
    response = yield call(getNoticesAPI, payload)
    yield put(getNoticesSuccess(response))
    // set active notice
    if (!isEmpty(response)) {
      yield call(showCurrentPopper)
    }
  } catch (err) {
    yield put(getNoticesFailed())
  }
}

export function* getNextNotices(action = {}) {
  yield put(showPopper(""))
  if (!isNil(action.payload)) {
    const selectSkip = yield select(selectDataByParentId, {
      parentId: action.payload,
    })
    yield put(skipNotice(selectSkip.group))
  } else {
    yield put(onNext())
  }
  yield call(showCurrentPopper)
}

export function* watchNotices() {
  yield takeEvery(TYPES.CALL_GET_NOTICE, getNotices)
}

export function* watchUpdateNotice() {
  yield takeLatest(TYPES.CALL_UPDATE_NOTICE, updateNotices)
}

export function* watchNextNotice() {
  yield takeLatest(TYPES.NEXT_ACTIVE_NOTICE, getNextNotices)
}

export default function* rootSaga() {
  yield all([watchNotices(), watchNextNotice(), watchUpdateNotice()])
}
