import { API_URL } from "env"
export const API_GET_PROPERTY = "/api/v2/agent/properties/:propertyId"
export const API_UPDATE_PROPERTY_DETAILS =
  "/api/v2/agent/properties/:propertyId/details"
export const API_UPDATE_USER_ROLE_FOR_PROPERTY = "/api/v2/properties/:propertyId/user-role-for-property"

export const UPDATE_PROPERTY_DETAILS_CALL = "UPDATE_PROPERTY_DETAILS_CALL"
export const UPDATE_PROPERTY_DETAILS_REQUEST = "UPDATE_PROPERTY_DETAILS_REQUEST"
export const UPDATE_PROPERTY_DETAILS_SUCCESS = "UPDATE_PROPERTY_DETAILS_SUCCESS"
export const UPDATE_PROPERTY_DETAILS_FAILED = "UPDATE_PROPERTY_DETAILS_FAILED"

export const UPDATE_PROPERTY_DETAILS_AND_CREATE_CONTACT_CALL =
  "UPDATE_PROPERTY_DETAILS_AND_CREATE_CONTACT_CALL"

export const GET_PROPERTY_CALL = "GET_PROPERTY_CALL"
export const GET_PROPERTY_CALL_REQUEST = "GET_PROPERTY_CALL_REQUEST"
export const GET_PROPERTY_CALL_SUCCESS = "GET_PROPERTY_CALL_SUCCESS"
export const GET_PROPERTY_CALL_FAILED = "GET_PROPERTY_CALL_FAILED"

export const CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_CALL =
  "CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_CALL"
export const CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_REQUEST =
  "CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_REQUEST"
export const CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_SUCCESS =
  "CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_SUCCESS"
export const CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_FAILED =
  "CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_FAILED"

export const CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_API = `${API_URL}/api/v2/people/contacts/public-page`
