import { ERROR_SAVING, PLEASE_CONTACT } from 'constants/error-messages'

// TODO: remove "IA" postfix after deprecate the old rent payment reducer
export const KEY = {
  RENTAL_PAYMENT_SETUP: 'rentalPaymentSetup',
  RENTAL_PAYMENT: 'rentalPaymentIA',
  RENT_PAYMENT_MODAL_STATE: 'rentPaymentModalStateIA',
  PAYMENT_ACCOUNT: 'paymentAccountIA',
}

export const API_STATUS = {
  NONE: 'none',
  FETCHING: 'fetching',
  CHANGING_PAGE: 'changing_page',
  UPDATING: 'updating',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const DELETE_RENTAL_PAYMENT_MESSAGE = {
  SUCCESS: {
    bodyMessage: 'Payment deleted.',
    status: 'success',
  },
  ERROR: {
    title: 'Payment Cannot Delete',
    text: 'We’re experiencing some technical issues. Please click try again button below.',
  },
}

export const EDIT_PAYMENT_TOAST = {
  SUCCESS: {
    bodyMessage: 'Payment edited.',
    status: 'success',
  },
  ERROR: {
    title: 'Payment cannot edit',
    text: 'We’re experiencing some technical issues. Please click try again button below.',
  },
}

export const GET_RENTAL_PAYMENT_MESSAGE = {
  ERROR: {
    title: 'Access denied',
    text: 'It looks like you don’t have permission to view this data. This may be due to recent updates or changes to your account. Contact our support team if you need assistance.',
    button: 'Go back',
  },
}

export const PAYMENT_CATEGORIES = {
  RENT: 'Rent',
  SECURITY_DEPOSIT: 'Security deposit',
  PRORATED_RENT: 'Prorated rent',
  LATE_FEE: 'Late fee',
  MOVE_IN_FEE: 'Move-in fee',
  UTILITIES: 'Utilities',
  PET_FEE: 'Pet fee',
  PARKING: 'Parking',
  OTHER: 'Other',
}

export const PAYMENT_INVITATION_SENT_TOAST = {
  SUCCESS: {
    bodyMessage: 'Payment invitation sent to your tenant.',
    status: 'success',
  },
}

export const RENTAL_PAYMENT_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  ENDED: 'ended',
}

export const MARK_AS_PAID_PAYMENT_METHOD = {
  CHECK: 'Check',
  CASH: 'Cash',
  VENMO: 'Venmo',
  ZELLE: 'Zelle',
  PAYPAL: 'PayPal',
  OTHER: 'Other',
}

export const MARK_AS_PAID_TOAST = {
  SUCCESS: {
    bodyMessage: 'Payment status updated to Paid.',
    status: 'success',
  },
  ERROR: {
    bodyMessage: 'Something went wrong, please try again.',
    status: 'error',
  },
}

export const RETRY_PAYOUT_FAILED_TOAST = {
  titleMessage: ERROR_SAVING,
  bodyMessage: PLEASE_CONTACT,
  status: 'error',
  width: '500px',
  timeOut: 10000,
}

export const PAYOUT_METHOD_STATUS = {
  PENDING: 'pending',
  VERIFIED: 'verified',
  FAILED: 'failed',
}

export const UPDATE_YOUR_INFORMATION_TOAST = {
  ERROR: {
    bodyMessage: 'Something went wrong, please try again.',
    status: 'error',
  },
}

export const TOAST_MESSAGE = {
  SUCCESS: 'Payment requested! We’ve notified your tenant.',
}

export const HTTP_STATUS = {
  FORBIDDEN: 403,
}
