import { takeLatest, put, call, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "../../utils/api-interceptor"
import { renterInsuranceApi } from "./actions"

import {
  GET_RENTER_INSURANCE_LIST_CALL,
  API_GET_RENTER_INSURANCE_LIST,
} from "./constants"

export const callAPI = ({ propertyId, options }) =>
  apiInstance.get(
    buildPath(API_GET_RENTER_INSURANCE_LIST, { propertyId }, { options }),
  )

export function* getRenterInsuranceListSaga({ payload }) {
  yield put(renterInsuranceApi.request())
  try {
    const response = yield call(callAPI, payload)
    yield put(renterInsuranceApi.success(response))
  } catch (err) {
    yield put(renterInsuranceApi.failure())
  }
}

export function* watchGetRenterInsuranceListApiCall() {
  yield takeLatest(GET_RENTER_INSURANCE_LIST_CALL, getRenterInsuranceListSaga)
}

export function* rootSaga() {
  yield all([watchGetRenterInsuranceListApiCall()])
}

export default rootSaga
