export const USER_TYPE = {
  RENTER: 'renter',
  LANDLORD: 'landlord',
}

export const ADMIN_USER_TYPES = { ADMIN: 'admin', SUPER_ADMIN: 'super_admin' }

export const REGISTERED_USER_TYPE = {
  LANDLORD: 'landlord',
  AGENT: 'agent',
  PROPERTY_MANAGEMENT: 'property_management',
  RENTER: 'renter',
}

export const LICENSE_TYPES = {
  DRE: 'DRE',
}

export const PAYER_TYPES = {
  AGENT: 'landlord',
  RENTER: 'renter',
}
