import get from 'lodash/get'

import { RENT_PAYMENT_STEP_SELECTOR } from './constants'

/**
 * Generate current step for Rent Payment stepper by location
 * @param {string} pathname Path name string from location.pathname
 * @returns mapped current step of RENT_PAYMENT_STEP_SELECTOR
 */
export const generateCurrentStep = (pathname = '') => {
  const path = pathname || ''
  const [pathKey] = path.split('/').slice(-1)
  const currentStep = get(RENT_PAYMENT_STEP_SELECTOR, pathKey, '')

  return currentStep
}
