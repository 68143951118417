import React from "react"
import { MainContent } from "components/layout/main"
import {
  Section,
  SectionContainer,
  SectionLogo,
  FlexCol,
} from "../../components/layout/section"
import { H1 } from "../../components/typography"
import { ReqDocsStep } from "../../components/static-page/how-to-req-docs"
import { TUTORIALS_STEPS } from "./request-docs-const"
import * as PATH from "../../constants/route-consts"

const HowToRequestDocsPage = () => (
  <MainContent className="-w100">
    <Section className="splash requestDocs smallHeader">
      <SectionLogo />
      <FlexCol className="splash-content" noMargin mCenter>
        <H1 align="center" size="1.4em" strong margin="0">
          Renter Doc Upload Tutorial
        </H1>
      </FlexCol>
    </Section>
    <Section
      className="diamondGreyBg videoSection"
      padding="30px 0 0"
      mPadding="0">
      <SectionContainer display="block">
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            title="Dashboard 2.0"
            src={PATH.HOW_TO_REQUEST_DOC_VIDEO}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </SectionContainer>
    </Section>
    <Section>
      <SectionContainer>
        {TUTORIALS_STEPS.map((step, index) => (
          <ReqDocsStep key={step.title} index={index + 1} step={step} />
        ))}
      </SectionContainer>
    </Section>
  </MainContent>
)

export default HowToRequestDocsPage
