import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import injectReducer from "utils/inject-reducer"
import injectSaga from "utils/inject-saga"
import {
  makeSelectProducts,
  makeSelectUserSubscription,
  makeSelectSelectedPriceName,
} from "./selectors"
import {
  getSubscriptionProduct,
  getUserSubscription,
  setSelectedPriceName,
} from "./actions"
import saga from "./saga"
import { subscriptionReducer } from "./reducers"
import { KEY } from "./constants"

export const mapStateToProps = createStructuredSelector({
  products: makeSelectProducts(),
  subscription: makeSelectUserSubscription(),
  selectedPriceName: makeSelectSelectedPriceName(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getSubscriptionProduct,
        getUserSubscription,
        setSelectedPriceName,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({
  key: KEY,
  reducer: subscriptionReducer,
})

export const withSaga = injectSaga({
  key: KEY,
  saga,
})
