import styled from 'styled-components'
import { breakpoints, COLOR } from 'styles/settings'

export const RecordWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 20px;
  width: 100%;
  margin-top: 20px;
  ${breakpoints.mobileReport(`
      margin-top: 10px;
  `)}
`

export const RecordContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc((100% - 32px) / 3);
  height: 90px;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;

  border-radius: 5px;
  background: ${COLOR.grayLight} !important;

  ${props =>
    props.active &&
    `
    background: none !important;
    border: 1px solid ${COLOR.grayLv3};
  `}

  ${breakpoints.mobileReport(`
      width: calc((100% - 16px)/2);
  `)}
`

export const RecordNumberStyled = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
`
