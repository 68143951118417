import React from 'react'
import { Route, Switch } from 'react-router-dom'

import * as PATH from 'constants/route'

import OverhaulBankSetupPage from './pages/loadable'

export const OverhaulBankSetupRouter = props => {
  return (
    <Switch>
      <Route path={PATH.OVERHAUL_RENT_PAYMENT} component={OverhaulBankSetupPage} {...props} />
    </Switch>
  )
}
