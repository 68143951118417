import produce from 'immer'
import get from 'lodash/get'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'

import {
  GET_SCREENING_PLANS_REQUEST,
  GET_SCREENING_PLANS_SUCCESS,
  GET_SCREENING_PLANS_FAILURE,
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
  SELECT_APPLICATION,
  SELECT_CREDIT_REPORT,
  SELECT_CRIMINAL,
  SELECT_EVICTION,
  SELECT_PAYER_TYPE,
  CREATE_SCREENING_REQUEST_REQUEST,
  CREATE_SCREENING_REQUEST_SUCCESS,
  CREATE_SCREENING_REQUEST_FAILURE,
  ACCEPT_BACKGROUND_DISCLAIMER,
  UPDATE_DOCUMENT_PRO_OPTION,
  UPDATE_REF_CHECK_PRO_OPTION,
  CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS,
} from './constants'

export const initialState = {
  screeningPlans: [
    {
      payerType: 'all',
      subdomain: 'rentspree',
      application: {
        price: 0,
        disabled: false,
      },
      credit_report: {
        price: 0,
        disabled: false,
      },
      eviction: {
        price: 0,
        disabled: false,
      },
      criminal: {
        price: 0,
        disabled: false,
      },
    },
  ],
  requestOption: {
    payload: {
      payerType: '',
      application: false,
      credit_report: false,
      eviction: false,
      criminal: false,
    },
    documentProOption: {},
    refCheckProOption: {},
    isInitial: true,
    isSubmitting: false,
  },
  userPreference: {
    defaultScreeningOption: {},
    agentDashBoardV: null,
    isAcceptedBackgroundDisclaimer: false,
    payerType: '',
    applicationType: 'rentspree',
  },
  isFetching: true,
  error: null,
  isFirstTimeUser: null
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case GET_SCREENING_PLANS_REQUEST:
      case GET_USER_PREFERENCE_REQUEST:
        draft.isFetching = true
        draft.error = null
        break
      case GET_SCREENING_PLANS_SUCCESS:
        draft.screeningPlans = payload
        draft.isFetching = false
        break
      case GET_USER_PREFERENCE_SUCCESS:
        if(isEmpty(payload.defaultScreeningOption)){
          draft.isFirstTimeUser = true
        }
        else{
          draft.isFirstTimeUser = false
        }
        draft.userPreference = payload
        draft.requestOption.payload.application = get(
          payload,
          'defaultScreeningOption.application',
          false,
        )
        draft.requestOption.payload.credit_report = get(
          payload,
          'defaultScreeningOption.credit_report',
          false,
        )
        draft.requestOption.payload.criminal = get(
          payload,
          'defaultScreeningOption.criminal',
          false,
        )
        draft.requestOption.payload.eviction = get(
          payload,
          'defaultScreeningOption.eviction',
          false,
        )
        draft.requestOption.payload.payerType = get(payload, 'payerType', '')
        draft.isFetching = false
        break
      case SELECT_APPLICATION:
        draft.requestOption.payload.application = payload
        break
      case SELECT_CREDIT_REPORT:
        draft.requestOption.payload.credit_report = payload
        break
      case SELECT_CRIMINAL:
        draft.requestOption.payload.criminal = payload
        break
      case SELECT_EVICTION:
        draft.requestOption.payload.eviction = payload
        break
      case SELECT_PAYER_TYPE:
        draft.requestOption.payload.payerType = payload
        break
      case CREATE_SCREENING_REQUEST_REQUEST: {
        const screeningPlan = head(state.screeningPlans)
        draft.requestOption.payload.application =
          !screeningPlan.application.disabled &&
          get(state, 'requestOption.payload.application', false)
        draft.requestOption.payload.credit_report =
          !screeningPlan.credit_report.disabled &&
          get(state, 'requestOption.payload.credit_report', false)
        draft.requestOption.payload.criminal =
          !screeningPlan.criminal.disabled && get(state, 'requestOption.payload.criminal', false)
        draft.requestOption.payload.eviction =
          !screeningPlan.eviction.disabled && get(state, 'requestOption.payload.eviction', false)
        draft.requestOption.isSubmitting = true
        draft.requestOption.isInitial = false
        break
      }
      case CREATE_SCREENING_REQUEST_SUCCESS:
        draft.requestOption.isSubmitting = false
        draft.userPreference = payload
        draft.isFetching = false
        draft.isFirstTimeUser = false
        break
      case GET_SCREENING_PLANS_FAILURE:
      case GET_USER_PREFERENCE_FAILURE:
        draft.isFetching = false
        draft.error = payload
        break
      case CREATE_SCREENING_REQUEST_FAILURE:
        draft.requestOption.isSubmitting = false
        draft.error = payload
        break
      case ACCEPT_BACKGROUND_DISCLAIMER:
        draft.userPreference.isAcceptedBackgroundDisclaimer = payload
        break
      case UPDATE_DOCUMENT_PRO_OPTION:
        draft.requestOption.documentProOption[get(payload, 'documentKey')] = isEmpty(
          draft.requestOption.documentProOption[get(payload, 'documentKey')],
        )
          ? { ...get(payload, 'change') }
          : {
              ...draft.requestOption.documentProOption[get(payload, 'documentKey')],
              ...get(payload, 'change'),
            }
        break
      case UPDATE_REF_CHECK_PRO_OPTION:
        draft.requestOption.refCheckProOption[get(payload, 'refCheckKey')] = get(
          payload,
          'isSelected',
          false,
        )
        break
      case CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS:
        draft.requestOption.refCheckProOption = {}
        draft.requestOption.documentProOption = {}
        break
      default:
        break
    }
    return draft
  })

export default reducer
