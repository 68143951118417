import React from 'react'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import Button from '@rentspree/component-2023.components.atoms.button'
import { DialogContent, DialogActions } from '@mui/material'
import { Loading } from 'components/layout/main'
import styled from 'styled-components'

const Overlay = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${({ zIndex }) => zIndex || '1'};
`

export const LoadingOverlay = ({ zIndex }) => (
  <Overlay zIndex={zIndex}>
    <Loading />
  </Overlay>
)

export const ShareReportDialog = ({
  isOpen,
  onClickClose,
  onClickBack,
  onClickShare,
  onClickSave,
  isShareButtonDisabled = true,
  isSaveButtonDisabled = true,
  children,
  title,
  isLoading,
}) => (
  <Dialog size="large" open={isOpen}>
    {isLoading && <LoadingOverlay />}
    <DialogTitle size="large" handleOnBack={onClickBack} handleOnClose={onClickClose}>
      {title}
    </DialogTitle>
    <DialogContent sx={{ margin: '0 8px 0 8px' }}>{children}</DialogContent>
    <DialogActions>
      {onClickBack && (
        <Button
          data-testid="share-report-option-back-btn"
          size="medium"
          onClick={onClickBack}
          className="underline"
          color="secondary"
          variant="text"
        >
          Back
        </Button>
      )}
      {onClickSave && (
        <Button
          data-testid="share-report-option-save-btn"
          size="medium"
          onClick={onClickSave}
          disabled={isSaveButtonDisabled}
          color="secondary"
          variant="contained"
        >
          Save
        </Button>
      )}
      {onClickShare && (
        <Button
          data-testid="share-report-share-btn"
          size="medium"
          onClick={onClickShare}
          disabled={isShareButtonDisabled}
          color="secondary"
          variant="contained"
        >
          Share
        </Button>
      )}
    </DialogActions>
  </Dialog>
)
