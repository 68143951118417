import { createSelector } from 'reselect'

import { selectRentalDetail } from '../selectors'
import { REPORTS } from './constants'

const getReviewReport = state => state.reviewReport || {}

export const selectIsSubmitting = createSelector(getReviewReport, state => state.isSubmitting)
export const selectIsSubmitted = createSelector(getReviewReport, state => state.isSubmitted)
export const makeReportOrder = createSelector(selectRentalDetail, state => {
    const result = {}
    const screeningOption = state.screeningOption || {}
    const filteredReports = REPORTS.filter(report => screeningOption[report.title])
    filteredReports.map((report, index) => {
      result[report.key] = index + 1
      return report
    })
    return result
})