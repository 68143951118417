import produce from "immer"
import uniqBy from "lodash/uniqBy"
import { TYPES } from "./const"

export const initialState = {
  showNoticeById: "",
  data: [],
  trigger: 0,
}

/* eslint-disable no-param-reassign */
const popperNoticeReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case TYPES.ON_NEXT:
        draft.data.splice(0, 1)
        break
      case TYPES.SHOW_NOTICE:
        draft = {
          ...draft,
          showNoticeById: payload,
        }
        break
      case TYPES.GET_NOTICE_SUCCESS: {
        draft = {
          ...draft,
          data: uniqBy([...draft.data, ...payload], "_id"),
        }
        break
      }
      case TYPES.SKIP_NOTICE_GROUP: {
        draft = {
          ...draft,
          data: draft.data.filter(({ group }) => group !== payload),
        }
        break
      }
      case TYPES.FORCE_TRIGGER: {
        draft = {
          ...draft,
          trigger: draft.trigger + 1,
        }
        break
      }
      default:
        break
    }
    return draft
  })

export default popperNoticeReducer
