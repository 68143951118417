import styled from 'styled-components'

import { HEADER_COLOR } from 'containers/accept-deny/constants'
import {
  COLOR,
  TOPBAR_HEIGHT,
  BORDER_RADIUS,
  MOBILE_BREADCRUMBS_HEIGHT,
  MOBILE_TAB_HEIGHT,
  MOBILE_SIDEBAR_HEIGHT,
} from '../../styles/settings'

export const MobileTabsWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: ${props => (props.shouldDisplay ? 'block' : 'none')};
  }
`

export const MobileBreadcrumb = styled.div`
  background: ${COLOR.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  height: ${MOBILE_BREADCRUMBS_HEIGHT};
  position: relative;
  z-index: 1;

  .breadcrumb-item {
    width: 50%;
    margin: 0 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.with-link {
      cursor: pointer;

      &:hover {
        color: ${COLOR.blue};
      }
    }
  }
`

export const MobileTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MOBILE_TAB_HEIGHT};
  background: ${COLOR.white};
  border-bottom: 1px solid ${COLOR.newGrey};
  padding: 0 20px;
`

export const MobileTabItem = styled.div`
  text-align: center;
  cursor: pointer;
`

export const MobileReport = styled.div`
  height: calc(
    100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT} - ${MOBILE_BREADCRUMBS_HEIGHT} + 10px
  );
  background-color: ${COLOR.white};
`

export const MobileReportItem = styled.li`
  flex-grow: 1;
  border-radius: ${BORDER_RADIUS};
  margin-bottom: 10px;
  display: ${props => props.hiddenMobile && 'none'};
  border: 1px solid ${COLOR.lineGrey};

  > a {
    color: ${COLOR.textBlack};
    display: block;
    border-radius: 5px;
    border-radius: ${BORDER_RADIUS};
    padding: 0px;
    text-align: left;
    border-left: ${props =>
      props.color &&
      (props.reportExpired || props.reportNotAvailable
        ? `5px solid ${COLOR.lineGrey}`
        : `5px solid ${props.color}`)};
    color: ${props =>
      props.reportExpired || props.reportNotAvailable ? COLOR.lineGrey : props.color};
    .subText {
      display: block;
      color: ${COLOR.textGrey};
      font-size: 0.8em;
      font-weight: normal;
    }
  }

  &.blueBg {
    border: none;
    > a {
      border-left: none;
      padding: 0px;
      background-color: ${COLOR.steelBlue};
      color: ${COLOR.white};
      font-size: 1em;

      &.active,
      &:hover {
        background-color: ${({ result }) => HEADER_COLOR[result] || COLOR.darkSteelBlue};
      }
    }
  }
`

export const MobileSummaryContainer = styled.div`
  width: 100%;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT} - ${MOBILE_BREADCRUMBS_HEIGHT} -
      ${MOBILE_TAB_HEIGHT}
  );
  background-color: ${COLOR.white};
`

export const MobileSummary = styled.div`
  padding: 15px 10px 60px;
  background-color: ${COLOR.white};
  @media (max-width: 991px) {
    padding-bottom: 15px;
  }
`

export const MobileReportFooter = styled.div`
  position: fixed;
  bottom: ${props => (props.bottom ? props.bottom : '0')};
  left: 0;
  width: 100%;
  display: flex;
  justify-content: ${({ isDuoButton }) => (isDuoButton ? 'space-between' : 'space-around')};
  padding: 12px 0px;
  z-index: ${props => (props.zIndex ? props.zIndex : '4')};
  ${props => props.padding && `padding: ${props.padding};`}
  @media (min-width: 992px) {
    display: none;
  }
`

export const DropdownContent = styled.div`
  height: 100vh;
  background: white;
  z-index: 1;
  top: 50px;
  width: 100vw;
  position: absolute;
`

export const DropdownItem = styled.div`
  padding: 13px 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: Source Sans Pro, Lato, sans-serif;
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  color: ${COLOR.textBlack};

  > :first-child {
    margin-right: 8px;
  }
`

export const MobileTabItemText = styled.span`
  font-family: Source Sans Pro, Lato, sans-serif;

  ${props =>
    props.active
      ? `
    border-bottom: 3px solid ${COLOR.deepBlue};
    color: ${COLOR.deepBlue};
    padding-bottom: 13px;
  `
      : `
    color: ${COLOR.textBlack};
  `}
`

export const proBadgeStyle = {
  fontStyle: 'italic',
  fontSize: '12px',
  fontFamily: 'Source Sans Pro, Lato, sans-serif',
}
