import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import ButtonOverlayPDF from 'legacy/components/reports/share/button-pdf'
import { CLICK_REPORT_FROM } from 'containers/reports/constants'
import { NEXT_ACTION_TYPES } from 'containers/tenant-screening/assign-property/constants'
import { withConnect } from './connect'
import { ReportPageContext } from '../context'

const { OVERLAY_BUTTON } = CLICK_REPORT_FROM

export const BtnOverlay = ({
  pdfReport,
  match,
  type,
  isShareAvailable,
  toggleShareReportModal,
  savePDF,
  printReport,
  hasESignFooter,
  rentalDetail,
  submissionParticipantId,
  openAssignPropertyModal,
}) => {
  const { isPrintPage, propertyId } = React.useContext(ReportPageContext)
  const getTypeFromProps = () => type || match.params.type
  const reportActionData = {
    type: getTypeFromProps(),
    isPrintPage,
    clickFrom: OVERLAY_BUTTON,
    propertyId,
  }

  const handleClickShare = () => {
    const shareReportProps = {
      toggle: true,
      clickFrom: getTypeFromProps(),
      position: OVERLAY_BUTTON,
    }
    const { property } = rentalDetail

    if (property) {
      toggleShareReportModal({ ...shareReportProps })
    } else {
      openAssignPropertyModal({
        participantId: submissionParticipantId,
        nextAction: NEXT_ACTION_TYPES.SHARE,
        nextActionProps: shareReportProps,
      })
    }
  }

  const downloadPdfMobile = () =>
    savePDF({
      ...reportActionData,
      device: 'mobile',
    })

  const downloadPdfDesktop = () => savePDF(reportActionData)

  const onClickPrintReport = event => {
    event.preventDefault()
    event.stopPropagation()
    printReport({
      ...reportActionData,
      autoPrint: true,
    })
  }

  return (
    <ButtonOverlayPDF
      onPrint={onClickPrintReport}
      onClick={downloadPdfDesktop}
      onMobileClick={downloadPdfMobile}
      isPrintPage={isPrintPage}
      isFetching={pdfReport.isFetching}
      onClickShare={handleClickShare}
      isShareAvailable={isShareAvailable}
      hasESignFooter={hasESignFooter}
    />
  )
}

export default compose(withRouter, withConnect)(BtnOverlay)
