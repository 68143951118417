import isEmpty from "lodash/isEmpty"
import replace from "lodash/replace"
import trim from "lodash/trim"

export const replaceSpace = v => v && replace(trim(v), /\s+/g, " ")

export const parseUserProfile = data => {
  if (isEmpty(data)) return {}
  const { firstName, lastName, phone, street, city, state, zip, unitNumber } =
    data || {}
  return {
    firstName: replaceSpace(firstName),
    lastName: replaceSpace(lastName),
    phone,
    address: {
      street: replaceSpace(street),
      city,
      state,
      zip,
      ...(unitNumber && { unitNumber }),
    },
  }
}
