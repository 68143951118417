import { takeLatest, put, call, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import tracker from "tracker"
import { EVENT_PROPERTY, RENTER_INSURANCE } from "tracker/const"
import { PLEASE_CONTACT, ERROR_SAVING } from "constants/error-messages"
import { apiInstance } from "../../utils/api-interceptor"
import {
  updatePropertyTypeApi,
  continueToRenterInsuranceRequest,
} from "./actions"
import { API_UPDATE_PROPERTY_TYPE, UPDATE_PROPERTY_TYPE_CALL } from "./const"

export const callAPI = ({ propertyId, payload }) =>
  apiInstance.put(buildPath(API_UPDATE_PROPERTY_TYPE, { propertyId }), payload)
export function* trackingSelectPropertyTypeSaga(propertyType) {
  yield call(
    [tracker, "trackEvent"],
    EVENT_PROPERTY.EVENT_NAME.SELECT_PROPERTY_TYPE,
    {
      source: RENTER_INSURANCE.EVENT_PROPERTY.REQUEST_RENTERS_INSURANCE,
      property_type: propertyType,
    },
  )
}
export function* updatePropertyTypeSaga({ propertyId, payload, location }) {
  yield put(updatePropertyTypeApi.request())
  try {
    const response = yield call(callAPI, { propertyId, payload })
    yield put(updatePropertyTypeApi.success(response))
    yield call(trackingSelectPropertyTypeSaga, payload.propertyType)
    yield put(continueToRenterInsuranceRequest(propertyId, location))
  } catch (err) {
    yield put(
      updatePropertyTypeApi.failure({
        error: { errorMessage: ERROR_SAVING, secondaryMessage: PLEASE_CONTACT },
      }),
    )
  }
}

export function* watchUpdatePropertyTypeApiCall() {
  yield takeLatest(UPDATE_PROPERTY_TYPE_CALL, updatePropertyTypeSaga)
}

export function* rootSaga() {
  yield all([watchUpdatePropertyTypeApiCall()])
}

export default rootSaga
