import { buildPath } from '@rentspree/path'

import { apiInstance } from 'utils/api-interceptor'

import { EDIT_ONE_TIME_PAYMENT_API } from './constants'

export const editOneTimePaymentInstance = ({
  rentalPaymentId,
  quotationId,
  category,
  description,
  amount,
  dueDate,
  feeCollection,
}) =>
  apiInstance.put(
    buildPath(EDIT_ONE_TIME_PAYMENT_API, {
      rentalPaymentId,
      quotationId,
    }),
    {
      category,
      amount: parseInt(amount, 10),
      dueDate,
      description,
      feeCollection,
    },
  )
