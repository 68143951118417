import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { checkShowErrorBadge } from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'
import { makeSelectShowErrorBadgeResult, makeSelectShowErrorBadgeStatus } from '../redux/selectors'

export const useCheckShowErrorBadge = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const data = useSelector(makeSelectShowErrorBadgeResult())
  const status = useSelector(makeSelectShowErrorBadgeStatus())

  React.useEffect(() => {
    dispatch(checkShowErrorBadge())
  }, [])

  return {
    fetchData: () => dispatch(checkShowErrorBadge()),
    data,
    status,
  }
}
