import React from "react"
import * as Styled from "./tabs-style"

export const TabWrapper = ({ children, ...props }) => (
  <Styled.TabWrapper {...props}>{children}</Styled.TabWrapper>
)

export const Tabs = ({ children, ...props }) => (
  <Styled.Tabs {...props}>
    <Styled.TabList>{children}</Styled.TabList>
  </Styled.Tabs>
)

export const TabItem = ({ children, ...props }) => (
  <Styled.TabItem {...props}>{children}</Styled.TabItem>
)
