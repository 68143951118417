import React from 'react'
import styled from 'styled-components'

import { B12 } from 'components/typography'
import TextLink from 'components/atoms/text-link'
import { COLOR } from 'components/constants'

const REPORT_REVIEWING_DESKTOP_WIDTH = 762

export const ReportReviewingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${COLOR.white};
  padding: 60px 0px 40px 0px;

  @media (max-width: 991px) {
    padding: 38px 20px 34px 20px;
    width: 100%;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  width: ${REPORT_REVIEWING_DESKTOP_WIDTH}px;

  @media (max-width: 760px) {
    width: 100%;
  }
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  width: ${REPORT_REVIEWING_DESKTOP_WIDTH}px;
  justify-content: flex-end;

  @media (max-width: 760px) {
    width: 100%;
  }
`
export const CheckboxWrapper = styled.div`
  display: flex;
  margin: 24px auto 28px;
  width: ${REPORT_REVIEWING_DESKTOP_WIDTH}px;
  height: 24px;

  @media (max-width: 760px) {
    width: 100%;
    margin-bottom: 0px;
  }
`

export const TermAndConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 14px;

  @media (max-width: 760px) {
    margin: 4px 0 4px 0;
  }
`

export const TermAndConditionLabel = ({ infoText, link, termsText, errorText, isError, dataTestKey }) => (
  <TermAndConditionWrapper data-testid={`${dataTestKey}-term-and-condition-wrapper`}>
    <B12 margin="0" fontFamily="Poppins" data-testid={`${dataTestKey}-term-and-condition-label`}>
      {infoText}{' '}
      <TextLink
        data-testid={`${dataTestKey}-term-and-condition-text-link`}
        onClick={() => window.open(link, '_blank')}
        color={COLOR.thinBlue}
        hovercolor={COLOR.thinBlue}
        underline
      >
        {termsText}
      </TextLink>
    </B12>
    {isError && (
      <B12 margin="0" fontFamily="Poppins" color={COLOR.negativeRed} data-testid={`${dataTestKey}-term-and-condition-error-label`}>
        {errorText}
      </B12>
    )}
  </TermAndConditionWrapper>
)

export const StickyFooterWrapper = styled.div`
  position: ${props => (props.shouldPositionFixed ? "fixed" : "sticky")};
  padding: 5px 20px 0px;
  bottom: 0;
  width: 100%;
  height: 150px;
  background-color: ${COLOR.white};
  z-index: 1;
`
