import { useLayoutEffect, useState } from 'react'

/**
 * Custom hook that tracks the state of a media query using the [`Match Media API`](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia).
 * @param {string} query - The media query to track.
 * @returns {boolean} The current state of the media query (true if the query matches, false otherwise).
 * @example
 * ```jsx
 * const isMobile = useMediaQuery('(max-width: 600px)');
 * // Use `isMobile` to conditionally apply styles or logic based on the screen size.
 * ```
 */
export function useMediaQuery(query) {
  const getMatches = () => {
    return window.matchMedia(query).matches
  }

  const [matches, setMatches] = useState(getMatches(query))

  // Handles the change event of the media query.
  function handleChange() {
    setMatches(getMatches(query))
  }

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Use deprecated `addListener` and `removeListener` to support Safari < 14
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener('change', handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener('change', handleChange)
      }
    }
  }, [query])

  return matches
}
