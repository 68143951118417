import get from "lodash/get"
import { createSelector } from "reselect"
import { initialState } from "./reducer"

export const getAgreementState = state => get(state, "agreement", initialState)

export const selectAgreement = () =>
  createSelector(
    getAgreementState,
    state => state,
  )
export const selectAgreementResponse = () =>
  createSelector(
    getAgreementState,
    state => get(state, "isAccepted"),
  )

export const selectAgreementFetching = () =>
  createSelector(
    getAgreementState,
    state => get(state, "isFetchingIsAccepted"),
  )

export const selectAgreementError = () =>
  createSelector(
    getAgreementState,
    state => get(state, "errors"),
  )

export const selectLocation = state => get(state, "router.location")
