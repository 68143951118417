import produce from "immer"
import get from "lodash/get"

import {
  UPDATE_LISTING_FAILED,
  UPDATE_LISTING_SUCCESS,
  START_UPDATE_LISTING,
  START_GET_PROPERTY,
  GET_PROPERTY_INFORMATION_FAILED,
  GET_PROPERTY_INFORMATION_SUCCESS,
  CLOSE_ERROR_PANEL,
  CLOSE_SUCCESSFUL_PANEL,
  LISTING_STATUS,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILED,
  START_UPLOAD_PHOTO,
  REMOVE_PHOTO,
  SORT_PHOTO,
  PUBLISH_LISTING_START,
  PUBLISH_LISTING_SUCCESS,
  PUBLISH_LISTING_FAILED,
  SET_ERROR_MESSAGE,
  QUEUE_PHOTO,
  UPDATE_QUEUE_PHOTO,
  UPDATE_IS_IMAGE_UPLOADING,
  START_GENERATE_DESCRIPTION,
  GENERATE_DESCRIPTION_SUCCESS,
  GENERATE_DESCRIPTION_FAILED,
  UPDATE_UPLOAD_PHOTO_PROGRESS,
  RATE_GENERATED_DESCRIPTION_SUCCESS,
  START_RATE_GENERATED_DESCRIPTION,
  RATE_GENERATED_DESCRIPTION_FAILED,
  RESET_GENERATED_DESCRIPTION,
  UPDATE_GENERATED_DESCRIPTION,
  SHOW_IDV_MODAL,
  CLOSE_IDV_MODAL,
  SHOW_IDV_FAILURE,
  CLOSE_IDV_FAILURE,
} from "./const"

export const initialState = {
  property: {
    propertyId: undefined,
    propertyDetails: {
      propertyType: undefined,
      bedrooms: undefined,
      bathrooms: undefined,
      monthlyRent: undefined,
      squareFeet: undefined,
      yearBuilt: undefined,
      description: undefined,
      fullNameAddress: undefined,
    },
    listing: {
      status: LISTING_STATUS.DRAFT,
      slug: undefined,
      lat: undefined,
      lng: undefined,
      limit: {
        generateDescription: { count: 0, hasLimitExceeded: false },
      },
      listedBy: undefined,
      leasingInformation: {
        dateAvailable: "",
        termAmountOfMonths: "",
        term: "",
        rentAmount: "",
        securityDeposit: "",
        isInsuranceRequired: "",
        isPetAllowed: "",
        isSmokingAllowed: "",
        allowedPets: "",
      },
      photos: [],
      utilitiesAndAmenities: {
        paidAmenities: {
          gas: false,
          electricity: false,
          heat: false,
          water: false,
          cable: false,
          internet: false,
          trashService: false,
          yardMaintenance: false,
          others: [],
        },
        sharedAmenities: {
          wheelchairAccessible: false,
          bikeParking: false,
          pool: false,
          fitnessCenter: false,
          onSiteLaundry: false,
          controlledAccess: false,
          sharedOutdoorSpace: false,
          others: [],
        },
        hvacAmenities: {
          central: false,
          wallUnit: false,
          windowUnit: false,
          ceilingFan: false,
          firePlace: false,
          ductless: false,
          baseBoard: false,
          radiator: false,
          others: [],
        },
        unitAmenities: {
          wheelchairAccessible: false,
          securitySystem: false,
          furnished: false,
          laundryHookUp: false,
          privatePool: false,
          microwave: false,
          dishwasher: false,
          washer: false,
          dryer: false,
          garbageDisposal: false,
          privateOutdoorSpace: false,
          refrigerator: false,
          others: [],
        },
        hasDedicatedParkingSpot: "",
      },
    },
  },
  isLoading: false,
  shouldShowPublishSuccess: false,
  shouldShowErrorPanel: false,
  shouldShowSuccessfulPanel: false,
  shouldShowGeneralError: false,
  errorMessage: "",
  isUpdatingListing: false,
  isUploadingPhoto: false,
  fullNameAddress: "",
  generateDescriptionResponse: {
    values: [],
    isLoading: false,
    shouldShowError: false,
    count: 0,
    hasLimitExceeded: false,
  },
  isRatingGeneratedDescription: false,
  showIdvModal: false,
  showIdvFailure: false
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const photos = get(draft, "property.listing.photos") || []

    switch (type) {
      case GET_PROPERTY_INFORMATION_SUCCESS:
        draft.property = {
          // eslint-disable-next-line no-underscore-dangle
          propertyId: payload._id,
          propertyDetails: {
            propertyType: payload.propertyType,
            bedrooms: payload.bedrooms,
            bathrooms: payload.bathrooms,
            monthlyRent: payload.monthlyRent,
            squareFeet: payload.squareFeet,
            yearBuilt: payload.yearBuilt,
            description: payload.description,
          },
          listing: payload.listing?.photos
            ? payload.listing
            : { ...payload.listing, photos: [] } ||
              initialState.property.listing,
        }
        draft.isUploadingPhoto = false
        draft.isUpdatingListing = false
        draft.isLoading = false
        draft.shouldShowPublishSuccess = false
        draft.shouldShowSuccessfulPanel = false
        draft.fullNameAddress = payload.fullNameAddress
        draft.disabledFeatures = payload.disabledFeatures
        draft.generateDescriptionResponse.count = payload.listing?.limit?.generateDescription?.count
        draft.generateDescriptionResponse.hasLimitExceeded = payload.listing?.limit?.generateDescription?.hasLimitExceeded
        break
      case UPDATE_LISTING_SUCCESS:
        draft.property = {
          // eslint-disable-next-line no-underscore-dangle
          propertyId: payload._id,
          propertyDetails: {
            propertyType: payload.propertyType,
            bedrooms: payload.bedrooms,
            bathrooms: payload.bathrooms,
            monthlyRent: payload.monthlyRent,
            squareFeet: payload.squareFeet,
            yearBuilt: payload.yearBuilt,
            description: payload.description,
          },
          listing: payload.listing || initialState.property.listing,
        }
        draft.isUpdatingListing = false
        draft.shouldShowSuccessfulPanel = true
        draft.shouldShowErrorPanel = false
        draft.generateDescriptionResponse.count = payload.listing?.limit?.generateDescription?.count
        draft.generateDescriptionResponse.hasLimitExceeded = payload.listing?.limit?.generateDescription?.hasLimitExceeded
        break
      case START_GET_PROPERTY:
        draft.isLoading = true
        break
      case START_UPDATE_LISTING:
        draft.isUpdatingListing = true
        break
      case GET_PROPERTY_INFORMATION_FAILED:
        draft.shouldShowGeneralError = true
        draft.isUploadingPhoto = false
        draft.isUpdatingListing = false
        draft.isLoading = false
        break
      case UPDATE_LISTING_FAILED:
        draft.shouldShowErrorPanel = true
        draft.isLoading = false
        draft.isUpdatingListing = false
        draft.shouldShowSuccessfulPanel = false
        break
      case CLOSE_ERROR_PANEL:
        draft.shouldShowErrorPanel = false
        draft.errorMessage = ""
        break
      case CLOSE_SUCCESSFUL_PANEL:
        draft.shouldShowSuccessfulPanel = false
        break
      case START_UPLOAD_PHOTO:
        draft.isUploadingPhoto = true
        draft.property.listing.photos = [
          ...photos,
          { ...payload, queue: false },
        ]
        break
      case QUEUE_PHOTO:
        draft.property.listing.photos = [
          ...photos,
          { ...payload, loadingProgress: true, queue: true },
        ]
        break
      case UPDATE_QUEUE_PHOTO:
        draft.isUploadingPhoto = true
        draft.property.listing.photos = photos.map(item => {
          if (item.id !== payload.id) return item

          return {
            ...payload,
            queue: false,
          }
        })
        break
      case UPLOAD_PHOTO_FAILED:
        draft.property.listing.photos = photos.map(item => {
          if (item.id !== payload.fileDetail?.id) return item

          return {
            ...item,
            loadingProgress: false,
            error: true,
          }
        })
        draft.isUploadingPhoto = draft.property.listing.photos.reduce(
          (prev, current) => !!(prev || current.loadingProgress),
          false,
        )
        break
      case UPLOAD_PHOTO_SUCCESS:
        draft.property.listing.photos = photos.map(item => {
          if (item.id !== payload.photo.id) return item

          return {
            ...item,
            loadingProgress: false,
            preview: payload.url,
          }
        })
        draft.isUploadingPhoto = draft.property.listing.photos.reduce(
          (prev, current) => !!(prev || current.loadingProgress),
          false,
        )
        break
      case UPDATE_UPLOAD_PHOTO_PROGRESS:
        draft.property.listing.photos = photos.map(item => {
          if (item.id !== payload.photo.id) return item

          return {
            ...item,
            progress: payload.photo.progress,
          }
        })
        break
      case REMOVE_PHOTO:
        draft.property.listing.photos = photos.filter(item => {
          if (typeof item === "string") {
            return item !== payload.item.preview
          }

          return item.id !== payload.item.id
        })
        break
      case UPDATE_IS_IMAGE_UPLOADING:
        draft.isUploadingPhoto = draft.property.listing.photos.reduce(
          (prev, current) => !!(prev || current.loadingProgress),
          false,
        )
        break
      case SORT_PHOTO:
        draft.property.listing.photos = payload.items
        break
      case PUBLISH_LISTING_START:
        draft.isUpdatingListing = true
        break
      case PUBLISH_LISTING_SUCCESS:
        draft.isUpdatingListing = false
        draft.shouldShowPublishSuccess = true
        break
      case PUBLISH_LISTING_FAILED:
        draft.isUpdatingListing = false
        draft.shouldShowErrorPanel = true
        draft.shouldShowPublishSuccess = false
        break
      case SET_ERROR_MESSAGE:
        draft.errorMessage = payload
        break
      case START_GENERATE_DESCRIPTION:
        draft.generateDescriptionResponse.isLoading = true
        draft.generateDescriptionResponse.shouldShowError = false
        break
      case GENERATE_DESCRIPTION_SUCCESS:
        draft.generateDescriptionResponse.isLoading = false
        draft.generateDescriptionResponse.values = [...draft.generateDescriptionResponse.values, payload.value]
        draft.generateDescriptionResponse.count = payload.count
        draft.generateDescriptionResponse.hasLimitExceeded = payload.hasLimitExceeded
        break
      case GENERATE_DESCRIPTION_FAILED:
        draft.generateDescriptionResponse.isLoading = false
        draft.generateDescriptionResponse.shouldShowError = true
        break
      case RESET_GENERATED_DESCRIPTION:
        draft.isRatingGeneratedDescription = initialState.isRatingGeneratedDescription
        draft.generateDescriptionResponse = initialState.generateDescriptionResponse
        break
      case START_RATE_GENERATED_DESCRIPTION:
        draft.isRatingGeneratedDescription = true
        break
      case RATE_GENERATED_DESCRIPTION_SUCCESS:
        draft.generateDescriptionResponse.values[payload.currentPage - 1].hasRated = true
        draft.generateDescriptionResponse.values[payload.currentPage - 1].rating = payload.rating
        draft.isRatingGeneratedDescription = false
        break
      case RATE_GENERATED_DESCRIPTION_FAILED:
        draft.isRatingGeneratedDescription = false
        break
      case UPDATE_GENERATED_DESCRIPTION:
        draft.generateDescriptionResponse.values[payload.currentPage - 1].description = payload.description
        break
      case SHOW_IDV_MODAL:
        draft.showIdvModal = true
        break
      case CLOSE_IDV_MODAL:
        draft.showIdvModal = false
        break
      case SHOW_IDV_FAILURE:
        draft.showIdvFailure = true
        break
      case CLOSE_IDV_FAILURE:
        draft.showIdvFailure = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
