import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import React from 'react'
import styled from 'styled-components'
import { DownloadAllButton } from '../../buttons'

const Th = styled.th`
  font-size: 14px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
`

const DocumentTableHeader = ({
  requestDisabled,
  onCheckAll,
  selectedDocs,
  onDownloadDoc,
  isFetchingFullDocZip,
  docsList,
}) => (
  <thead>
    <tr>
      <Th className="header-checkbox-cell">
        <Checkbox
          id="header_checkbox"
          simple
          bgSimpleTransparent
          onClick={onCheckAll}
          isSelected={selectedDocs.length === docsList.length}
          disabled={requestDisabled}
        />
      </Th>
      <Th>Documents</Th>
      <Th className="text-left hidden-xs hidden-sm" data-testid="status-header-column">
        Status
      </Th>
      <Th className="button-center" data-testid="cta-header-column">
        <DownloadAllButton
          id="downloadAllDocsBtn"
          text="Download All"
          onClick={() =>
            onDownloadDoc({
              type: 'full',
              _id: 'fullDocument',
            })
          }
          isFetching={isFetchingFullDocZip}
          fontSize="14px"
        />
      </Th>
    </tr>
  </thead>
)

export default DocumentTableHeader
