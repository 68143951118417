import { object } from 'yup'

import { propertyDetailsValidation, validateEmail } from 'constants/validations'
import { validateContactNumber, validateLength } from 'legacy/constants/validations'

const propertyDetailsSchema = ({
  propertyType,
  bedrooms,
  bathrooms,
  monthlyRent,
  squareFeet,
  yearBuilt,
  firstName,
  lastName,
  middleName,
  email,
  mobileNumber,
}) => ({
  mapper: {
    propertyType,
    bedrooms,
    bathrooms,
    monthlyRent,
    squareFeet,
    yearBuilt,
    firstName,
    lastName,
    middleName,
    email,
    mobileNumber,
  },
  validation: object({
    propertyType: propertyDetailsValidation('propertyType'),
    bedrooms: propertyDetailsValidation('bedrooms'),
    bathrooms: propertyDetailsValidation('bathrooms'),
    monthlyRent: propertyDetailsValidation('monthlyRent'),
    squareFeet: propertyDetailsValidation('squareFeet'),
    yearBuilt: propertyDetailsValidation('yearBuilt'),
    firstName: validateLength(false, 1, 50),
    lastName: validateLength(false, 1, 50),
    middleName: validateLength(false, 1, 50),
    email: validateEmail(false),
    mobileNumber: validateContactNumber(false),
  }),
})

export default propertyDetailsSchema
