import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { toggleShareReportModal } from 'containers/share-report/actions'
import { openAssignPropertyModal } from 'containers/tenant-screening/assign-property/actions'
import { printReport, savePDF } from '../actions'
import {
  makeSelectIsShareAvailable,
  selectParticipantId,
  selectPdfReport,
  selectRentalDetail,
} from '../selectors'

const mapStateToProps = createStructuredSelector({
  isShareAvailable: makeSelectIsShareAvailable(),
  pdfReport: selectPdfReport,
  submissionParticipantId: selectParticipantId,
  rentalDetail: selectRentalDetail,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      printReport,
      savePDF,
      toggleShareReportModal,
      openAssignPropertyModal,
    },
    dispatch,
  )

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
