import Handlebars from 'handlebars'

import { IDENTITY_VERIFICATION_STATUS } from 'v3/containers/identity-verification/constants'

export const WELCOME_LANDLORD_STEP_KEY = {
  PAYMENT_REQUEST_SENT: 'payment-request-sent',
  VERIFY_IDENTITY: 'verify-identity',
  ADD_BANK_ACCOUNT: 'add-bank-account',
  COLLECT_MONTHLY_RENT: 'collect-monthly-rent',
}

export const WELCOME_LANDLORD_STEPS = [
  {
    key: WELCOME_LANDLORD_STEP_KEY.PAYMENT_REQUEST_SENT,
    title: 'Payment request sent',
    continueButtonText: 'Continue',
    backButtonText: 'Later',
    labelMaxWidth: '111px',
  },
  {
    title: 'Verify identity',
    key: WELCOME_LANDLORD_STEP_KEY.VERIFY_IDENTITY,
    continueButtonText: 'Verify identity',
    reverifyContinueButtonText: 'Reverify identity',
    backButtonText: 'Later',
    labelMaxWidth: '74px',
  },
  {
    title: 'Add bank account',
    key: WELCOME_LANDLORD_STEP_KEY.ADD_BANK_ACCOUNT,
    continueButtonText: 'Add bank account',
    verifyBankContinueButton: 'Verify bank account',
    backButtonText: 'Later',
    labelMaxWidth: '89px',
  },
  {
    title: 'Collect monthly rent',
    key: WELCOME_LANDLORD_STEP_KEY.COLLECT_MONTHLY_RENT,
    continueButtonText: 'Collect rent',
    backButtonText: 'Later',
    labelMaxWidth: '102px',
  },
]

export const WELCOME_LANDLORD_MODAL_CONTENT = {
  title: 'Finish setting up your account',
  description: Handlebars.compile(
    `Finish setting up your account to collect your payment of {{amount}} on time.`,
  ),
}

export const OVERHAUL_PAYMENT_SUCCESS_CONTENT = {
  TITLE: 'Payment request sent!',
  DESCRIPTION:
    'Great news! We’ve notified your tenant. Connect your bank account for a faster payout.',
  RECEIVING_ACCOUNT_ADDED_DESCRIPTION: 'Great news! We’ve notified your tenant.',
  BACK_BUTTON_TEXT: "I'll do this later",
  CONFIRM_BUTTON_TEXT: 'Connect bank account',
}

export const COLLECT_RENT_AMOUNT_STATUSES = ['upcoming', 'due', 'processing']

// Prevent fetch payee duration (ms)
export const OVERRIDE_PAYEE_IDV_DURATION = 180000

export const RENT_PAYMENT_OVERRIDE_PAYEE_IDV_UNTIL_TIMESTAMP = 'rp.overridePayeeIdvUntilTimestamp'

export const DEFAULT_OVERRIDE_PAYEE_IDV = {
  status: IDENTITY_VERIFICATION_STATUS.VERIFYING,
}

export const OVERRIDE_PAYEE_IDV_MAPPER = {
  [IDENTITY_VERIFICATION_STATUS.FAILED]: [IDENTITY_VERIFICATION_STATUS.VERIFYING],
}

export const VERIFY_RECEIVING_ACCOUNT_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
}
