import { GET_RENTAL_PAYMENT_BY_ID_API } from 'containers/rent-payment/constants'

export const KEY = 'rentPaymentConfirmationStep'
export const TITLE_OF_PAGE = 'Confirm Payment Setup'
export const DESCRIPTION = address => `${address}`
export const BUTTON_TYPE_SUBMIT = 'Submit'
export const BUTTON_TEXT_CONTINUE = 'Confirm & Save'
export const BUTTON_TEXT_CONFIRM = 'Confirm'

export const PAYMENT_DETAIL_SECTION_TEXT = 'Payment details'
export const MONTHLY_PAYMENT_DETAIL_SECTION_TITLE = 'Monthly'
export const ONE_TIME_PAYMENT_DETAIL_SECTION_TITLE = 'One-time'
export const TENANT_SECTION_TITLE = 'Tenant'
export const RECEIVING_SECTION_TITLE = 'Your Receiving Account'

export const SAVE_CONFIRMATION_INFO_CALL = 'SAVE_CONFIRMATION_INFO_CALL'
export const SAVE_CONFIRMATION_INFO_REQUEST = 'SAVE_CONFIRMATION_INFO_REQUEST'
export const SAVE_CONFIRMATION_INFO_SUCCESS = 'SAVE_CONFIRMATION_INFO_SUCCESS'
export const SAVE_CONFIRMATION_INFO_FAILED = 'SAVE_CONFIRMATION_INFO_FAILED'
export const CLEAR_CONFIRMATION_INFO_STATUS = 'CLEAR_CONFIRMATION_INFO_STATUS'

export const SAVING_CONFIRMATION_INFO_API = `${GET_RENTAL_PAYMENT_BY_ID_API}/confirm`

export const TOAST_MESSAGE = {
  SUCCESS: 'Payment invitation sent to your tenant.',
}
