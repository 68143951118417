import {
  CONTACT_CONTACT_CALL,
  CREATE_CONTACT_CALL,
  CREATE_CONTACTS_CALL,
  SET_SEEN_CONTACTS_CALL,
} from './constants'

export const contactContact = (contactId, toastOptions) => ({
  type: CONTACT_CONTACT_CALL,
  payload: {
    contactId,
    toastOptions,
  },
})

export const createContact = ({ contact, automated, source }) => ({
  type: CREATE_CONTACT_CALL,
  payload: {
    contact,
    automated,
    source,
  },
})

export const createContacts = contacts => ({
  type: CREATE_CONTACTS_CALL,
  payload: {
    contacts,
  },
})

export const setSeenContacts = contactIds => ({
  type: SET_SEEN_CONTACTS_CALL,
  payload: {
    contactIds,
  },
})
