import { debounce, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import TextField from '@rentspree/component-2023.components.atoms.text-input'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import isNaN from 'lodash/isNaN'
import React, { useCallback, useEffect, useState } from 'react'

import { DatePickerInput } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/one-time/date-picker-input'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

const inputStyles = { fontSize: '1.5rem', marginBottom: '15px' }

export const EditPayment = ({ payment, setPayment }) => {
  const [errors, setErrors] = useState({})
  const isRecurring = payment?.type === 'recurring'
  const setPaymentComponent = useCallback(
    debounce(nextFields => {
      setPayment({
        ...payment,
        ...nextFields,
      })
    }, 200),
  )

  useEffect(() => {
    let term = null
    if (isRecurring) {
      term = payment?.endDate == null ? 'perpetual' : 'fixed'
    }
    setPaymentComponent({ term })
  }, [payment?.startDate, payment?.endDate])

  useEffect(() => {
    const { startDate = null, endDate = null, dueDate = null } = payment || {}

    // Initialize a local dateErrors object to manage date-related errors
    const dateErrors = {
      startDate: null,
      endDate: null,
      dueDate: null,
    }

    if (isRecurring) {
      if (startDate && dayjs(startDate).isBefore(dayjs(), 'day')) {
        dateErrors.startDate = 'Please choose a starting date today or later'
      }
      if (endDate) {
        if (dayjs(endDate).isBefore(dayjs(), 'day')) {
          dateErrors.endDate = 'Please choose an end date later than today'
        } else if (startDate && dayjs(endDate).isBefore(dayjs(startDate), 'day')) {
          dateErrors.endDate = 'Please choose an end date later than the start date'
        }
      }
    } else if (dueDate && dayjs(dueDate).isBefore(dayjs(), 'day')) {
      dateErrors.dueDate = 'Please choose a due date today or later'
    }

    setErrors(prevErrors => ({ ...prevErrors, ...dateErrors }))
  }, [payment?.endDate, payment?.startDate, payment?.dueDate])

  const [nameTouched, setNameTouched] = useState(false)

  useEffect(() => {
    let nameError = null

    if (isRecurring && nameTouched && (!payment?.name || payment?.name.trim() === '')) {
      nameError = 'Please enter a fee name'
    }

    setErrors(prevErrors => ({ ...prevErrors, name: nameError }))
  }, [payment?.name, isRecurring, nameTouched])

  const [amountTouched, setAmountTouched] = useState(false)

  useEffect(() => {
    let amountError = null

    if (
      amountTouched &&
      (payment?.amount === null || payment?.amount <= 0 || isNaN(payment?.amount))
    ) {
      amountError = 'Please enter a valid amount'
    }

    setErrors(prevErrors => ({ ...prevErrors, amount: amountError }))
  }, [payment?.amount, amountTouched])

  const startDate = payment?.startDate ? dayjs(payment?.startDate).format('YYYY-MM-DD') : null
  const endDate = payment?.endDate ? dayjs(payment?.endDate).format('YYYY-MM-DD') : null
  const dueDate = payment?.dueDate ? dayjs(payment?.dueDate).format('YYYY-MM-DD') : null

  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: '30px' }}>
        Edit payment
      </Typography>
      <Typography sx={inputStyles} variant="p">
        Which fee?
      </Typography>
      <TextField
        label="Fee type"
        sx={inputStyles}
        defaultValue={payment?.type || ''}
        required
        disabled
      />

      {isRecurring && (
        <TextField
          label="Fee name"
          sx={inputStyles}
          defaultValue={payment?.name || ''}
          onChange={e => {
            setPaymentComponent({ name: e.target.value })
          }}
          onBlur={() => setNameTouched(true)}
          error={Boolean(errors?.name)}
          required
        />
      )}

      <Typography sx={inputStyles} variant="p">
        How often?
      </Typography>
      <RadioGroup sx={{ marginLeft: '5px', marginBottom: '15px' }}>
        <FormControlLabel
          disabled
          value="recurring"
          checked={payment?.type === 'recurring'}
          control={<Radio sx={{ padding: '4px 9px' }} size="medium" />}
          label={<Typography variant="h5">Monthly</Typography>}
        />
        <FormControlLabel
          disabled
          value="oneTime"
          checked={payment?.type !== 'recurring'}
          control={<Radio sx={{ padding: '4px 9px' }} size="medium" />}
          label={<Typography variant="h5">One-time</Typography>}
        />
      </RadioGroup>

      <Typography sx={inputStyles} variant="p">
        How much?
      </Typography>
      {/* TODO: ui.rentspree.com TextInput isn't doing floating labels like mocks want */}
      <TextField
        label="Amount"
        startAdornment="$"
        onChange={e => {
          const nextVal = parseFloat(parseFloat(e.target.value).toFixed(2))
          setPaymentComponent({ amount: nextVal })
        }}
        sx={inputStyles}
        defaultValue={parseFloat(payment?.amount || '0.00').toFixed(2)}
        required
        onBlur={() => setAmountTouched(true)}
        error={Boolean(errors?.amount)}
      />

      <Typography sx={inputStyles} variant="p">
        {isRecurring ? 'For how long?' : "When's the due date?"}
      </Typography>
      <DatePickerInput
        label={isRecurring ? 'Starts on' : 'Due on'}
        sx={inputStyles}
        name={isRecurring ? 'startDate' : 'dueDate'}
        defaultValue={isRecurring ? startDate : dueDate}
        minDate={dayjs().date()}
        error={isRecurring ? Boolean(errors?.startDate) : Boolean(errors?.dueDate)}
        onChange={e => {
          if (isRecurring) {
            setPaymentComponent({
              startDate: e.target.value || null,
              dueOn: dayjs(e.target.value).get('date') || null,
            })
          } else {
            setPaymentComponent({ dueDate: e.target.value || null })
          }
        }}
        onBlur={e => {
          const date = e.target.value
          if (isRecurring) {
            setPaymentComponent({ startDate: date || null })
          } else {
            setPaymentComponent({ dueDate: date || null })
          }
        }}
        required
      />
      {isRecurring && (
        <DatePickerInput
          label="Ends on"
          sx={inputStyles}
          helperText="If no end date, payments will continue month-to-month"
          name="endDate"
          defaultValue={endDate}
          minDate={payment?.startDate}
          onChange={e => {
            setPaymentComponent({ endDate: e.target.value || null })
          }}
          onBlur={e => {
            const date = e.target.value
            setPaymentComponent({ endDate: date || null })
          }}
          error={Boolean(errors?.endDate)}
        />
      )}

      <TextField
        label="Note"
        helperText="We'll show this info to the tenant"
        defaultValue={payment?.description}
        onChange={e => setPaymentComponent({ description: e.target.value })}
        sx={inputStyles}
        multiline
      />
    </>
  )
}
