// eslint-disable-next-line filenames/match-regex
import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import * as CreditActions from "legacy/actions/credit-report.action"
import * as PropertyAction from "legacy/actions/property.action"
import injectSaga from "../../utils/inject-saga"
import injectReducer from "../../utils/inject-reducer"
import saga from "./saga"
import {
  createRentEstimate,
  submitPaymentRentEstimate,
  clearPaymentError,
  closeRentRangeError,
  handlePaymentModal,
  handleModalSuccess,
  setDefaultRentEstimatePaymentError,
} from "./actions"
import {
  makeSelectIsFetchingPaymentRentEstimate,
  makeSelectRentEstimatePayment,
  makeSelectDisablePaymentBtn,
  makeSelectRentEstimateError,
  makeSelectRentEstimatePaymentError,
  makeSelectPaymentModal,
  makeSelectModalSuccess,
  makeSelectUserEmail,
  makeSelectRentEstimate,
  makeSelectIsSubmitingRentEstimate,
  makeSelectPropertyForRentEstimate,
  makeSelectPropertyFetchSuccess,
} from "./selector"
import reducer from "./reducer"

export const mapStateToProps = () =>
  createStructuredSelector({
    isSubmitingRentEstimate: makeSelectIsSubmitingRentEstimate(),
    isFetchingPaymentRentEstimate: makeSelectIsFetchingPaymentRentEstimate(),
    paymentDetail: makeSelectRentEstimatePayment(),
    disabledPaymentBtn: makeSelectDisablePaymentBtn(),
    rentEstimateData: makeSelectRentEstimate(),
    rentEstimateError: makeSelectRentEstimateError(),
    creditReportError: makeSelectRentEstimatePaymentError(),
    paymentModal: makeSelectPaymentModal(),
    modalSuccess: makeSelectModalSuccess(),
    agentEmail: makeSelectUserEmail(),
    estimateProperty: makeSelectPropertyForRentEstimate(),
    isFetchPropertySuccess: makeSelectPropertyFetchSuccess(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createRentEstimate,
        ...CreditActions,
        submitPaymentRentEstimate,
        clearPaymentError,
        closeRentRangeError,
        handlePaymentModal,
        handleModalSuccess,
        ...PropertyAction,
        setDefaultRentEstimatePaymentError,
      },
      dispatch,
    ),
  }
}

export const withSaga = injectSaga({ key: "rentEstimate", saga })
export const withReducer = injectReducer({ key: "rentEstimate", reducer })

export const withRentEstimate = compose(
  withSaga,
  withReducer,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
