import { takeLatest, all, put, call } from 'redux-saga/effects'
import { apiInstance } from 'utils/api-interceptor'
import { GET_TENANT_SCREENING_NOTIFICATION_API } from 'containers/tenant-screening/list/constants'

import { buildPath } from '@rentspree/path'
import {
  getTenantScreeningNotificationApiState,
  getUnseenRentalPaymentNotificationsApiState,
  getTaskNotificationApiState,
} from './actions'
import {
  GET_TENANT_SCREENING_NOTIFICATION_CALL,
  GET_TASK_NOTIFICATION_CALL,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_CALL,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_API,
} from './constants'
import { TASK_GET_COUNT_TASK_API } from '../tasks/list/constants'

export const getTenantScreeningNotificationApi = () =>
  apiInstance.get(GET_TENANT_SCREENING_NOTIFICATION_API)

export function* getTenantScreeningNotificationSaga() {
  yield put(getTenantScreeningNotificationApiState.request())
  try {
    const response = yield call(getTenantScreeningNotificationApi)
    yield put(getTenantScreeningNotificationApiState.success(response))
  } catch (err) {
    yield put(getTenantScreeningNotificationApiState.failure(err))
  }
}

export function* watchGetTenantScreeningNotification() {
  yield takeLatest(GET_TENANT_SCREENING_NOTIFICATION_CALL, getTenantScreeningNotificationSaga)
}

export const getUnseenRentalPaymentNotificationsInstance = () =>
  apiInstance.get(buildPath(GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_API))

export function* getUnseenRentalPaymentNotificationsSaga() {
  yield put(getUnseenRentalPaymentNotificationsApiState.request())
  try {
    const response = yield call(getUnseenRentalPaymentNotificationsInstance)
    yield put(getUnseenRentalPaymentNotificationsApiState.success(response))
  } catch (err) {
    yield put(getUnseenRentalPaymentNotificationsApiState.failure(err))
  }
}

export function* watchGetUnseenRentalPaymentNotificationsSaga() {
  yield takeLatest(
    GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_CALL,
    getUnseenRentalPaymentNotificationsSaga,
  )
}

export const getTaskNotificationApi = () => apiInstance.get(TASK_GET_COUNT_TASK_API)

export function* getTaskNotificationSaga() {
  yield put(getTaskNotificationApiState.request())
  try {
    const response = yield call(getTaskNotificationApi)
    yield put(getTaskNotificationApiState.success(response))
  } catch (err) {
    yield put(getTaskNotificationApiState.failure(err))
  }
}

export function* watchGetTaskNotification() {
  yield takeLatest(GET_TASK_NOTIFICATION_CALL, getTaskNotificationSaga)
}

export function* rootSaga() {
  yield all([
    watchGetTenantScreeningNotification(),
    watchGetUnseenRentalPaymentNotificationsSaga(),
    watchGetTaskNotification(),
  ])
}

export default rootSaga
