import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { getPayee } from '../redux/actions'
import { KEY } from '../redux/constants'
import { paymentAccountReducer } from '../redux/reducers'
import { paymentAccountRootSaga } from '../redux/sagas'
import { makeSelectPayeeData, makeSelectPayeeStatus } from '../redux/selectors'

export const usePayee = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.PAYMENT_ACCOUNT, saga: paymentAccountRootSaga })
  useInjectReducer({ key: KEY.PAYMENT_ACCOUNT, reducer: paymentAccountReducer })

  const data = useSelector(makeSelectPayeeData())
  const status = useSelector(makeSelectPayeeStatus())

  React.useEffect(() => {
    dispatch(getPayee())
  }, [])

  return {
    fetchData: () => dispatch(getPayee()),
    data,
    status,
  }
}
