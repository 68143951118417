import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import {
  selectRentalCreditReport,
  selectIsReportExpired,
  selectIsSubmissionExpired,
  selectScreeningOption,
  selectIsSharedReport,
  selectShareOption,
} from "../../selectors"
import { selectPdfFile } from "../../other-doc/selectors"

const mapStateToProps = createStructuredSelector({
  creditReport: selectRentalCreditReport,
  screeningOption: selectScreeningOption,
  shareOption: selectShareOption,
  isSharedReport: selectIsSharedReport,
  isReportExpired: selectIsReportExpired,
  isSubmissionExpired: selectIsSubmissionExpired,
  pdfFile: selectPdfFile,
})

export const withConnect = connect(mapStateToProps)
