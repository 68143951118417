import styled from "styled-components"
import { B14 } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"
import React from "react"

export const CenterContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => props.direction || "column"};
`
export const ExtraHeader = styled(B14)`
  margin-bottom: 0;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  line-height: 26px;
  color: ${COLOR.placeholderGrey};
  margin-bottom: 20px;
`

export const EmptyIcon = styled.img`
  ${props =>
    props.marginBottom && `margin-bottom: ${props.marginBottom}px !important;`}
  @media (max-width: 991px) {
    display: none;
  }
`

export const Title = styled.div`
  font-family: ${props => props.fontFamily || "Source Sans Pro"};
  font-weight: ${props => props.fontWeight || "600"};
  font-size: ${props => props.fontSize || "28px"};
  line-height: ${props => props.lineHeight || "36px"};
  text-align: center;
  color: ${props => props.color || COLOR.textBlack};
  margin-bottom: 10px;
`

export const Subtitle = styled(B14)`
  margin-bottom: 0;
  line-height: ${props => props.lineHeight || "20px"};
  text-align: center;
  color: ${props => props.color || COLOR.cyanBlue};
`

export const ZeroState = props => (
  <CenterContent direction={props.direction} spaceY={props.spaceY}>
    <ExtraHeader>{props.extraHeader}</ExtraHeader>
    {props.icon && (
      <EmptyIcon src={props.icon} marginBottom={props.iconMargin} />
    )}
    <Title
      fontFamily={props.titleFontFamily}
      fontWeight={props.titleFontWeight}
      fontSize={props.titleFontSize}
      lineHeight={props.titleLineHeight}
      color={props.titleColor}>
      {props.title}
    </Title>
    <Subtitle lineHeight={props.subtitleLineHeight} color={props.subtitleColor}>
      {props.subtitle}
    </Subtitle>
  </CenterContent>
)

export default ZeroState
