import React from "react"
import { Div, WhiteWrapper } from "components/layout/main"
import { Landing } from "components/landing"
import Browser from "components/landing/browser"

export const UnSupportPage = () => (
  <Div sourceSansPros flex flexGrow="1" flexDirection="column">
    <WhiteWrapper mMargin="0">
      <Landing
        noHeightCal
        title="Browser not supported"
        description="Please use one of the following supported browsers to access E-Sign Documents on RentSpree.">
        <Browser />
      </Landing>
    </WhiteWrapper>
  </Div>
)

export default UnSupportPage
