import { takeLatest, put, call } from 'redux-saga/effects'

import {
  FETCH_VERIFY_PROPERTY_ADDRESS,
  fetchPropertyByAddress,
  fetchVerifyPropertyAddressAPI,
} from 'v3/containers/create-property-form/actions'
import { fetchVerifyPropertyAddressInstance } from 'v3/containers/create-property-form/api-instances'

export function* fetchVerifyPropertyAddressSaga({ payload }) {
  yield put(fetchVerifyPropertyAddressAPI.request())
  try {
    const response = yield call(fetchVerifyPropertyAddressInstance, { address: payload.address })
    const { standardizedAddress } = response?.result
    yield put(
      fetchPropertyByAddress({
        address: standardizedAddress
          ? { ...standardizedAddress, unitNumber: standardizedAddress?.unitNumber || undefined }
          : payload.address,
      }),
    )
    yield put(fetchVerifyPropertyAddressAPI.success({ addressVerification: response.result }))
  } catch (error) {
    yield put(fetchVerifyPropertyAddressAPI.failure(error))
  }
}

export function* watchVerifyPropertyAddress() {
  yield takeLatest(FETCH_VERIFY_PROPERTY_ADDRESS, fetchVerifyPropertyAddressSaga)
}
