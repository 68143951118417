import React from 'react'
import Styled from 'styled-components'
import { DOCUMENT_OPTIONS } from 'containers/tenant-screening/request-v2.2/constants'
import values from 'lodash/values'
import DocumentForm from 'components/tenant-screening-request-step-option-v2.2/atoms/custom-document/document-form'

const Banner = Styled.div`
  margin: 0px 0px 16px 0px;
  border-radius: 7px;
  border-style: none;
  background-color: #F2F2F7;
  padding: 20px;
  font-size: 14px;
  font-family: Inter;
`

const Content = Styled.div`
   ${props => props.isMobile && 'padding: 16px'};
`

const CustomDocumentForm = ({ isMobile, selectedDocuments, handleUpdateCustomDocumentForm }) => (
  <Content isMobile={isMobile}>
    <Banner>Document submissions may vary if applicants complete income verification.</Banner>
    <>
      {values(DOCUMENT_OPTIONS).map(option => {
        const isSelected = selectedDocuments.map(d => d.type).includes(option.key)
        const document = {
          ...option,
          ...(isSelected ? selectedDocuments.find(d => d.type === option.key) : {}),
        }
        return (
          <DocumentForm
            isSelected={isSelected}
            note={document.note}
            document={document}
            handleUpdateCustomDocumentForm={handleUpdateCustomDocumentForm}
          />
        )
      })}
    </>
  </Content>
)

export default CustomDocumentForm
