import styled from "styled-components"
import { COLOR } from "components/constants"
import { Button } from "@rentspree/component-v2"

export const NotifyButton = styled(Button)`
  font-size: 16px;
  color: ${COLOR.textBlack};
  background-color: white;
  height: 40px;
  ${props =>
    props.toggle &&
    `
    color: ${COLOR.fontBlue};
    border-color: ${COLOR.fontBlue};
  `};
  &:not(:hover) {
    background-color: white;
  }
  @media (max-width: 991px) {
    height: 40px;
    background-color: white;
    &:focus {
      background-color: white;
    }
  }
`
