import {
  SEND_APPLY_LINK,
  SEND_APPLY_LINK_START,
  SEND_APPLY_LINK_SUCCESS,
  SEND_APPLY_LINK_FAILED,
} from "./const"

export const sendApplyLink = payload => ({
  type: SEND_APPLY_LINK,
  payload,
})

export const sendApplyLinkStart = () => ({
  type: SEND_APPLY_LINK_START,
})

export const sendApplyLinkSuccess = () => ({
  type: SEND_APPLY_LINK_SUCCESS,
})

export const sendApplyLinkFailed = () => ({
  type: SEND_APPLY_LINK_FAILED,
})
