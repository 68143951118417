import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { FailedToFetchAndShouldIDo } from 'legacy/components/layout/failed-to-fetch'
import * as ERRORS from 'legacy/constants/error-messages'
import { withConnect } from './connect'

export const withScreeningReportError =
  WrappedComponent =>
  ({ errorCreditReport, ...rest }) => {
    if (errorCreditReport) {
      return (
        <FailedToFetchAndShouldIDo
          withBreadcrumb
          withReportTabs
          noMargin
          title={ERRORS.APPLICATION_DETAIL.CREDIT_REPORT.TITLE}
          img={ERRORS.APPLICATION_DETAIL.CREDIT_REPORT.IMAGE}
          shouldIDo={ERRORS.APPLICATION_DETAIL.CREDIT_REPORT.SHOULD_I_DO}
        />
      )
    }
    return <WrappedComponent {...rest} />
  }

export default compose(withRouter, withConnect, withScreeningReportError)
