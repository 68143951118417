import flatten from 'lodash/flatten'

export const getCurrentPlan = ({ products, userProduct }) => {
  let currentPlanName
  for (let i = 0; i < products.length; i += 1) {
    const { variants, name: planName } = products[i]
    const userCurrentProduct = variants.find(
      variant => variant.stripePriceId === userProduct.productId,
    )
    if (userCurrentProduct) {
      currentPlanName = planName
      break
    }
  }
  return currentPlanName
}

export const getSubscriptionProductVariant = (subscriptionProducts = [], { name }) => {
  const variants = flatten(subscriptionProducts.map(product => product.variants)) || []
  return variants.find(variant => variant.name === name)
}
