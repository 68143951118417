import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'
import {
  selectRenter,
  selectProperty,
  selectScreeningOption,
  selectRenterEmail,
  selectRentalDetail,
} from 'containers/application/selectors'
import { selectIncomeVerificationStatus } from 'containers/reports/screening-reports/income-verification/selectors'
import reducer from './reducer'
import saga from './saga'
import {
  getReportsAvailableCall,
  shareReportCall,
  toggleShareReportModal,
  closeError,
  setFormValues,
  submitShareReportForm,
  getDefaultShareOption,
} from './actions'
import {
  selectReportAvailable,
  selectIsLoading,
  selectIsError,
  selectIsShowShareModal,
  selectIsCreditReportExpired,
  selectIsIDMAExpired,
  selectExpiredDate,
  selectEmail,
  selectApplicationType,
  selectFormValues,
  selectDefaultShareOption,
} from './selectors'

const mapStateToProps = createStructuredSelector({
  renter: selectRenter,
  renterDetail: selectRentalDetail,
  renterEmail: selectRenterEmail,
  property: selectProperty,
  screeningOption: selectScreeningOption,
  reportAvailable: selectReportAvailable,
  isLoading: selectIsLoading,
  isError: selectIsError,
  isShowShareModal: selectIsShowShareModal,
  isExpired: selectIsCreditReportExpired,
  isIDMAExpired: selectIsIDMAExpired,
  expiredDate: selectExpiredDate,
  userEmail: selectEmail,
  applicationType: selectApplicationType,
  formValuesV2: selectFormValues,
  incomeVerificationStatus: selectIncomeVerificationStatus,
  defaultShareOption: selectDefaultShareOption,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReportsAvailable: getReportsAvailableCall,
      getDefaultShareOption,
      shareReport: shareReportCall,
      toggleShareReportModal,
      closeError,
      setFormValuesV2: setFormValues,
      submitShareReportForm,
    },
    dispatch,
  )

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({
  key: 'shareReport',
  reducer,
})

export const withSaga = injectSaga({ key: 'shareReport', saga })
