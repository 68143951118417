import styled from "styled-components"
import { Tooltip } from "react-bootstrap"
import { COLOR, BOX_SHADOW_3 } from "legacy/styles/settings"

export const CollapseButton = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background: white;
  position: absolute;
  right: -6px;
  top: calc(50vh - 50px);
  z-index: 1001;
  cursor: pointer;
  box-shadow: ${BOX_SHADOW_3};
  opacity: ${props => (props.isCollapse ? "1" : "0")};
  transition: opacity, transform 0.2s;
  transform: rotate(${props => (props.isCollapse ? "0" : "180")}deg);
  &:hover {
    background: ${COLOR.fontBlue};
    img {
      filter: brightness(200);
    }
  }
`

export const CollapseBar = styled.div`
  background: transparent;
  width: 4px;
  position: absolute;
  z-index: 1000;
  height: 100%;
  right: 3px;
  cursor: col-resize;
  transition: background 0.2s;
  &:hover {
    background: ${COLOR.fontBlue};
  }
`
export const CollapseBarArea = styled.div`
  background: transparent;
  width: 10px;
  position: absolute;
  z-index: 1000;
  right: -5px;
  cursor: col-resize;
  height: 100%;
  top: 0;
  &:hover {
    ${CollapseBar} {
      background: ${COLOR.fontBlue};
    }
    ${CollapseButton} {
      opacity: 1;
    }
  }
`

export const ArrowCollapse = styled.img`
  height: 13px;
  position: absolute;
  top: 4px;
  right: 6px;
`

export const TooltipComponent = styled(Tooltip)`
  font-family: "Source Sans Pro";
  .tooltip-arrow {
    ${props =>
      props.placement &&
      `border-${props.placement}-color: ${COLOR.textBlack} !important;`}
  }
  .tooltip-inner {
    border-radius: 5px;
    background: ${COLOR.textBlack};
    padding: 5px 10px;
    ${props => props.width && `width: ${props.width};`}
    ${props => props.textAlign && `text-align: ${props.textAlign};`}
  }
`
