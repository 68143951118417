import { buildPath } from '@rentspree/path'
import { takeLatest, all, call, put } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'
import { apiInstance } from 'utils/api-interceptor'
import generateApiCall from 'utils/generate-api-call'

import {
  CREATE_CONTACT_API,
  CREATE_CONTACT_FROM_PUBLIC_PAGE_API,
  CREATE_CONTACT_CALL,
  CREATE_CONTACTS_REQUEST,
  CREATE_CONTACTS_SUCCESS,
  CREATE_CONTACTS_FAILURE,
  CREATE_CONTACTS_CALL,
  CREATE_CONTACTS_API,
  SET_SEEN_CONTACTS_REQUEST,
  SET_SEEN_CONTACTS_SUCCESS,
  SET_SEEN_CONTACTS_FAILURE,
  SET_SEEN_CONTACTS_CALL,
  SET_SEEN_API,
  CONTACT_CONTACT_API,
  CONTACT_CONTACT_CALL,
  CONTACT_TOAST_SUCCESS,
} from './constants'
import { parseContact } from './helpers'

export const contactContactApi = contactId =>
  apiInstance.post(
    buildPath(CONTACT_CONTACT_API, {
      contactId,
    }),
  )

export function* contactContactSaga({ payload }) {
  const { toastOptions } = payload
  if (toastOptions) {
    yield put(
      addToast({
        bodyMessage: `${toastOptions.label} ${CONTACT_TOAST_SUCCESS}`,
        status: 'success',
      }),
    )
  }
  yield call(contactContactApi, payload.contactId)
}

export const createContactApi = ({ contact, automated, source }) =>
  apiInstance.post(buildPath(CREATE_CONTACT_API), {
    ...parseContact(contact),
    automated,
    source,
  })

export const createContactFromPublicPageApi = ({ contact, automated }) =>
  apiInstance.post(buildPath(CREATE_CONTACT_FROM_PUBLIC_PAGE_API), {
    ...parseContact(contact),
    automated,
  })

export const createContactsApi = contacts =>
  apiInstance.post(buildPath(CREATE_CONTACTS_API), { contacts })

export const createContactsApiState = generateApiCall([
  CREATE_CONTACTS_REQUEST,
  CREATE_CONTACTS_SUCCESS,
  CREATE_CONTACTS_FAILURE,
])

export function* createContactSaga({ payload }) {
  const { fromPublicPage, ...data } = payload
  if (fromPublicPage) {
    yield call(createContactFromPublicPageApi, data)
  } else {
    yield call(createContactApi, data)
  }
}

export function* createContactsSaga({ payload }) {
  yield put(createContactsApiState.request())
  try {
    const response = yield call(createContactsApi, payload.contacts)
    yield put(createContactsApiState.success(response))
  } catch (err) {
    yield put(createContactsApiState.failure(err))
  }
}

export const setSeenContactsApi = contactIds => apiInstance.put(SET_SEEN_API, { contactIds })

export const setSeenContactsApiState = generateApiCall([
  SET_SEEN_CONTACTS_REQUEST,
  SET_SEEN_CONTACTS_SUCCESS,
  SET_SEEN_CONTACTS_FAILURE,
])

export function* setSeenContactsSaga({ payload }) {
  yield put(setSeenContactsApiState.request())
  try {
    const response = yield call(setSeenContactsApi, payload.contactIds)
    yield put(setSeenContactsApiState.success(response))
  } catch (err) {
    yield put(setSeenContactsApiState.failure(err))
  }
}

export function* watchCreateContact() {
  yield takeLatest(CREATE_CONTACT_CALL, createContactSaga)
}

export function* watchContactContact() {
  yield takeLatest(CONTACT_CONTACT_CALL, contactContactSaga)
}

export function* watchCreateContacts() {
  yield takeLatest(CREATE_CONTACTS_CALL, createContactsSaga)
}

export function* watchSetSeenContacts() {
  yield takeLatest(SET_SEEN_CONTACTS_CALL, setSeenContactsSaga)
}

export function* rootSaga() {
  yield all([
    watchCreateContact(),
    watchCreateContacts(),
    watchSetSeenContacts(),
    watchContactContact(),
  ])
}

export default rootSaga
