import React from "react"
import { compose } from "redux"
import { Route, Switch } from "react-router-dom"
import { CenterContent, Loading } from "components/layout/main"
import * as PATH from "constants/route"
import { WrapperWithOutDiamond } from "components/templates/diamond-wrapper-template-styled"
import FailedToFetchFullPage from "components/templates/failed-to-fetch-full-page"
import FullModalCreateProperty from "containers/properties/full-page-modal-create/loadable"
import SelectPropertyContainer from "./select-property/loadable"
import CreateNewProperty from "./create-property/loadable"
import { withConnect, withReducer, withSaga } from "./connect"
import { FETCH_ERROR } from "./const"
export const GetPropertyListContainer = ({
  actions,
  isLoading,
  isError,
  history,
  location,
}) => {
  React.useEffect(() => {
    actions.getPropertyList({
      search: history.location.search,
      state: location.state,
      redirectRoute: PATH.RENTER_INSURANCE_CREATE_PROPERTY,
    })
  }, [])

  if (isLoading)
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )

  if (isError)
    return (
      <FailedToFetchFullPage
        noMargin
        subTitle={FETCH_ERROR.subTitle}
        title={FETCH_ERROR.title}
      />
    )

  return (
    <Switch>
      <WrapperWithOutDiamond>
        <Route
          exact
          path={PATH.RENTER_INSURANCE_SELECT_PROPERTY}
          component={SelectPropertyContainer}
        />
        <Route
          exact
          path={PATH.RENTER_INSURANCE_CREATE_PROPERTY}
          component={CreateNewProperty}
        />
        <FullModalCreateProperty withPropertyType />
      </WrapperWithOutDiamond>
    </Switch>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(GetPropertyListContainer)
