import React from 'react'
import PropTypes from 'prop-types'

import { T36, B16 } from '@rentspree/component-v2'

import { Div } from 'components/layout/main'
import styled from 'styled-components'

const StyledT36 = styled(T36)`
  line-height: ${props => props.lineHeight};
  width: ${props => props.titleWidth};
  @media (max-width: 991px) {
    width: ${props => props.mTitleWidth};
    line-height: ${props => props.mLineHeight};
  }
`

export const TitleWithSubtitle = ({
  title,
  titleWidth,
  mTitleWidth,
  titleSize,
  fontWeight,
  subtitle,
  subTitleSize,
  titleLineHeight,
  mTitleLineHeight,
  mb,
  titleMargin,
  mTitleMargin,
  tMargin,
  subTitleLineHeight,
  mSubTitleLineHeight,
  dataTestKey,
}) => (
  <Div mb={mb} sourceSansPros>
    <StyledT36
      titleWidth={titleWidth}
      size={titleSize}
      weight={fontWeight}
      lineHeight={titleLineHeight}
      mLineHeight={mTitleLineHeight}
      margin={titleMargin}
      mMargin={mTitleMargin}
      tMargin={tMargin}
      mTitleWidth={mTitleWidth}
      data-testid={dataTestKey ? `${dataTestKey}-title` : undefined}
    >
      {title}
    </StyledT36>
    {subtitle && (
      <B16 size={subTitleSize} lineHeight={subTitleLineHeight} mLineHeight={mSubTitleLineHeight} data-testid={dataTestKey ? `${dataTestKey}-subtitle` : undefined}>
        {subtitle}
      </B16>
    )}
  </Div>
)

TitleWithSubtitle.defaultProps = {
  titleSize: '36px',
  fontWeight: '600',
  subTitleSize: '16px',
  mb: '20',
  titleMargin: '0 0 10px',
  mTitleMargin: '0 0 10px',
  tMargin: '0 0 10px',
  titleLineHeight: '45px',
}

TitleWithSubtitle.propTypes = {
  title: PropTypes.string,
  titleWidth: PropTypes.string,
  mTitleWidth: PropTypes.string,
  titleSize: PropTypes.string,
  fontWeight: PropTypes.string,
  subtitle: PropTypes.string,
  subTitleSize: PropTypes.string,
  mb: PropTypes.string,
  titleMargin: PropTypes.string,
  mTitleMargin: PropTypes.string,
  tMargin: PropTypes.string,
  titleLineHeight: PropTypes.string,
  mTitleLineHeight: PropTypes.string,
  subTitleLineHeight: PropTypes.string,
  mSubTitleLineHeight: PropTypes.string,
}

export default TitleWithSubtitle
