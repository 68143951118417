import StepProgress from '@rentspree/component-v2/dist/step-progress'
import get from 'lodash/get'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { RENT_PAYMENT_PROGRESS } from './constants'
import { generateCurrentStep } from './generate-current-step'

export const FullWidthContainer = styled.div`
  width: 100%;
`

export const withStepper =
  WrappedComponent =>
  ({ location, ...rest }) => {
    const pathName = get(location, 'pathname', '') || ''
    const currentStep = useMemo(() => generateCurrentStep(pathName), [pathName])

    return (
      <>
        <FullWidthContainer>
          <StepProgress
            steps={RENT_PAYMENT_PROGRESS}
            currentStep={currentStep}
            marginMobile="0px"
            margin="auto"
            stepPadding="15px 10px 10px"
            desktopMaxWidth="920px"
            textMobileMB="0px"
          />
        </FullWidthContainer>
        <WrappedComponent location={location} {...rest} />
      </>
    )
  }
