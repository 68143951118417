import React from 'react'
import { useSelector } from 'react-redux'
import { FeatureFlagProvider } from 'utils/feature-flag/provider'
import isEmpty from 'lodash/isEmpty'

import { selectProfile } from 'containers/user/selectors'
import { parseProfileToStatsigUser } from 'helpers/statsig-user-profile-parser'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { querySubscriptionData } from 'utils/subscription/query-subscription-data'
import { queryUserPreferenceData } from 'utils/query-user-preference-data'
import { selectSubdomain } from 'containers/wrapper/selectors'
import { CenterContent, Loading } from 'components/layout/main'
import { useStatsigFeatureGate } from 'hooks/use-statsig-feature-flag'

export const withStatsigProvider = Component => props => {
  const profile = useSelector(selectProfile)
  const subdomain = useSelector(selectSubdomain) || 'rentspree'
  const userSubscriptionData = querySubscriptionData()
  const userPreferenceData = queryUserPreferenceData()
  const isProUser = userSubscriptionData.data?.userPlan === SUBSCRIPTION_PLAN.PRO
  const isInDesignConceptExperiment = !!(
    isEmpty(userPreferenceData?.data?.defaultScreeningOption) ||
    userPreferenceData?.data?.isInDesignConceptExperiment
  )

  if (!userSubscriptionData.isFetched) {
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )
  }

  return (
    <FeatureFlagProvider
      statsigUser={parseProfileToStatsigUser(
        {
          ...profile,
          is_pro: isProUser,
          isInDesignConceptExperiment,
        },
        subdomain,
      )}
    >
      <Component {...props} />
    </FeatureFlagProvider>
  )
}

export const withStatsigFeatureGate =
  (featureGateName) =>
    WrappedComponent =>
      props => {
        const { isEnabled } = useStatsigFeatureGate(featureGateName)
        return (
          <WrappedComponent
            {...props}
            isFeatureEnabled={{ ...props.isFeatureEnabled, [featureGateName]: isEnabled }}
          />
        )
      }
