import { createSelector } from "reselect"
import get from "lodash/get"

const getApplication = state => get(state, "application", {})

const selectApplication = createSelector(
  getApplication,
  state => state,
)

export const selectRentalDetail = createSelector(
  selectApplication,
  state => get(state, "rentalDetail", {}),
)

export const selectRenter = createSelector(
  selectRentalDetail,
  state => get(state, "renter", {}),
)

export const selectRenterEmail = createSelector(
  selectRentalDetail,
  state => get(state, "email", {}),
)

export const selectProperty = createSelector(
  selectRentalDetail,
  state => get(state, "property", {}),
)

export const selectScreeningOption = createSelector(
  selectRentalDetail,
  state => get(state, "screeningOption", {}),
)
