import React from 'react'
import ConfirmModal from 'containers/request/step-create-property/confirm-modal'

const InvalidAddressConfirmModal = ({
  isOpen,
  onClose,
  handleSubmitProperty,
  handleEditInvalidAddress,
  generatedAddress,
}) => {
  const descText = <>We couldn’t verify the address you entered. Check for any errors or missing information.<br/>The address cannot be changed once the property has been created.</>
  return (
    <ConfirmModal
      id="invalidAddressConfirmPropertyAddress"
      title="Confirm Property Address"
      descText={descText}
      confirmTxt="Edit Address"
      cancelTxt="Continue with entered address"
      isOpen={isOpen}
      onClose={onClose}
      handleClickCancelBtn={handleSubmitProperty}
      handleClickBtn={handleEditInvalidAddress}
      content={generatedAddress}
      buttonDirection="column"
    />
  )
}

export default InvalidAddressConfirmModal

