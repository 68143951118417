import renterInsuranceLandingImage from "images/icons/renter-insurance/landing.svg"

export const RENTER_INSURANCE_LANDING = {
  icon: renterInsuranceLandingImage,
  title: "Renters Insurance",
  description:
    "Rest easy knowing your tenants are insured with renters insurance through RentSpree.",
  btnName: "Request Insurance",
}
export const RENTER_INSURANCE_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  NOTIFIED: "notified",
  PROCESSING: "processing",
}
export const RENTER_INSURANCE_LIST = {
  defaultStatus: "All",
  ERROR: {
    TITLE: "The renters insurance are temporarily unavailable",
    MESSAGE:
      "Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the leases, contact us at (323) 515-7757 or support@rentspree.com.",
  },
  icon: "menu-insurance",
  options: [
    {
      label: "All",
      value: "all",
      icon: "menu-insurance",
      iconColor: "black",
    },
    {
      label: "Ready",
      value: "ready",
      icon: "menu-insurance",
      iconColor: "#67A033",
    },
    {
      label: "Notify",
      value: "notify",
      icon: "menu-insurance",
      iconColor: "#D3B84D",
    },
  ],
  placeholder: "Search Insurance",
  buttonText: "Notify Renters",
  defaultQuery: { page: 1, perPage: 8, filter: {}, sort: {} },
}
