import { createSelector } from "reselect"
import find from "lodash/find"
import head from "lodash/head"
import moment from "moment"
import { initialState } from "./reducer"

export const selectPopper = ({ popper }) => popper || initialState

export const makeSelectPopper = () =>
  createSelector(
    selectPopper,
    ({ showNoticeById }) => showNoticeById,
  )

export const selectTrigger = () =>
  createSelector(
    selectPopper,
    ({ trigger }) => trigger,
  )

export const selectActiveData = () =>
  createSelector(
    selectPopper,
    ({ showNoticeById, data }) => find(data, { parentId: showNoticeById }),
  )
export const selectCurrentPopper = state => {
  const { data } = selectPopper(state)
  return head(data)
}
export const selectIsNew = () =>
  createSelector(
    selectPopper,
    ({ showNoticeById, data }) => {
      const notice = find(data, { parentId: showNoticeById })
      if (!notice) {
        return false
      }
      return moment().isBefore(moment(notice.created_at).add(30, "days"))
    },
  )

export const selectDataByParentId = (state, { parentId }) => {
  const { data } = selectPopper(state)
  return find(data, { parentId }, {})
}
