import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { PROPERTY_BY_ADDRESS_API } from './constants'

export const fetchPropertyByAddressInstance = ({ address }) =>
  apiInstanceWithErrorHandler.get(
    buildPath(PROPERTY_BY_ADDRESS_API, null, {
      street: address.street,
      unit: address.unitNumber,
      city: address.city,
      state: address.state,
      zip: address.zip,
    }),
  )
