import React from "react"
import GlobalStyle from "@rentspree/component-v2/dist/style/global"
import BaseStyle from "./base"
import GenericStyle from "./generic"
import TrumpsStyle from "./trumps"
import SweetAlertStyle from "./sweetalert"

export const withGlobalStyle = WrappedComponent => props => (
  <>
    <BaseStyle />
    <GenericStyle />
    <TrumpsStyle />
    <SweetAlertStyle />
    <WrappedComponent {...props} />
    <GlobalStyle />
  </>
)
