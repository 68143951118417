/* eslint-disable jsx-a11y/anchor-is-valid */
import InfoIcon from '@mui/icons-material/Info'
import Link from '@rentspree/component-2023.components.atoms.link'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React from 'react'

import { ErrorContentCol, ErrorContentWrapper } from './styled-index'

export const ERROR = {
  TITLE: 'Sorry, this section failed to load',
  FIRST_DESCRIPTION: 'Please',
  DESCRIPTION_CTA: 'refresh',
  SECOND_DESCRIPTION: 'If the problem continues, try again later.',
}

export const ErrorContent = ({ onClickCTA, margin, mMargin }) => {
  const handleOnClick = event => {
    event.stopPropagation()
    onClickCTA()
  }

  return (
    <ErrorContentWrapper
      variant="outlined"
      onClick={event => event.stopPropagation()}
      margin={margin}
      mMargin={mMargin}
    >
      <InfoIcon data-testid="info-icon" color="error" sx={{ width: '24px', height: '24px' }} />
      <ErrorContentCol>
        <Typography variant="body-medium" sx={{ fontWeight: 500 }}>
          {ERROR.TITLE}
        </Typography>
        <Typography variant="body-medium" data-testid="error-content-text-with-cta">
          {ERROR.FIRST_DESCRIPTION}{' '}
          <Link color="black" data-testid="error-content-cta" href="#" onClick={handleOnClick}>
            {ERROR.DESCRIPTION_CTA}
          </Link>
          {'. '}
          {ERROR.SECOND_DESCRIPTION}
        </Typography>
      </ErrorContentCol>
    </ErrorContentWrapper>
  )
}
