/* eslint-disable import/no-cycle */
import { buildPath } from "@rentspree/path"

import { USER_ME_API, USER_PREFERENCE_API, USER_INTEGRATION_API } from "constants/route"
import checkStatus from "./handlers/check-status"
import fetch from "../helpers/fetch-utils"


export const detail = () => bearer =>
  fetch(USER_ME_API, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)

export const getUserIntegrations = () => bearer =>
  fetch(USER_INTEGRATION_API, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)

export const updateUserProfileApi = data => bearer =>
  fetch(USER_ME_API, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearer.accessToken}`,
    },
    body: JSON.stringify(data),
  }).then(checkStatus)

export const updateFlyerCustomTextApi = text => bearer => {
  const data = { flyerCustomText: text }
  return fetch(buildPath(USER_ME_API, null), {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(checkStatus)
}

export const updateUserPreferenceApi = preference => bearer =>
  fetch(USER_PREFERENCE_API, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(preference),
  }).then(checkStatus)

export const getUserPreferenceApi = () => bearer =>
  fetch(USER_PREFERENCE_API, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
  }).then(checkStatus)
