import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import values from 'lodash/values'
import { S16 } from '@rentspree/component-v2'

import { BooleanAccordionCard } from 'components/molecules/boolean-accordion-card'
import { Section } from 'legacy/components/request/heading'
import ComponentList from 'components/atoms/component-list'
import { ProBadge } from 'components/badge'
import { DocumentProOptionItem } from 'components/molecules/document-pro-option-item'
import {
  DOCUMENT_PRO_OPTIONS,
  REF_CHECK_PRO_OPTIONS,
} from 'containers/request/step-screening-options/constants'
import { COLOR } from 'styles/settings'
import { RefCheckProOptionItem } from 'components/molecules/ref-check-pro-option-item'
import tracker from 'tracker'
import { EVENT_OTHER_DOCS } from 'legacy/tracker/const'

const ProOptionWrapper = styled(Section)`
  margin-top: 30px;
`

const ProOptionSubtitle = styled.div`
  margin-bottom: 15px;
`

const ProOptionList = styled.div`
  background-color: ${COLOR.white};
  border-radius: 5px;
`

export const ProOptionForm = ({
  documentOption = {},
  refCheckOption = {},
  onDocumentOptionChange,
  onRefCheckOptionChange,
}) => {
  const [hasSelectedDocument, setHasSelectedDocument] = useState(false)
  const [hasSelectedRefCheck, setHasSelectedRefCheck] = useState(false)
  const [isDocumentAccordionCollapsed, setIsDocumentAccordionCollapsed] = useState(true)
  const [isRefCheckAccordionCollapsed, setIsRefCheckAccordionCollapsed] = useState(true)

  useEffect(() => {
    setHasSelectedDocument(Object.entries(documentOption).some(([_, value]) => value.isSelected))
    setHasSelectedRefCheck(Object.entries(refCheckOption).some(([_, isSelected]) => isSelected))
  }, [documentOption, refCheckOption])

  useEffect(() => {
    setIsDocumentAccordionCollapsed(
      !Object.entries(documentOption).some(([_, value]) => value.isSelected),
    )
    setIsRefCheckAccordionCollapsed(
      !Object.entries(refCheckOption).some(([_, isSelected]) => isSelected),
    )
  }, [])

  return (
    <ProOptionWrapper id="ProOptionSection">
      <S16 mb={2} mt={0}>
        {' '}
        Additional Verifications{' '}
      </S16>
      <ProOptionSubtitle>Exclusive with your RentSpree PRO subscription.</ProOptionSubtitle>
      <ComponentList bottomGap={10}>
        <BooleanAccordionCard
          margin="0 0 10px 0"
          title={
            <>
              Documents{' '}
              <ProBadge fontSize="11px" fontStyle="italic" fontFamily="Source Sans Pro">
                PRO
              </ProBadge>
            </>
          }
          subtitle="Request proof of income, bank statements, ID copies, and more."
          isCollapsed={isDocumentAccordionCollapsed}
          isActive={hasSelectedDocument}
          onOpenCallback={() => {
            const { eventName } = EVENT_OTHER_DOCS.CLICK_ACCORDION_OTHER_DOC
            tracker.trackEvent(eventName)
          }}
        >
          <ProOptionList>
            {values(DOCUMENT_PRO_OPTIONS).map(documentType => (
              <DocumentProOptionItem
                key={documentType.key}
                documentKey={documentType.key}
                documentName={documentType.name}
                onChange={onDocumentOptionChange}
                isSelected={documentOption[documentType.key]?.isSelected}
                note={documentOption[documentType.key]?.note}
              />
            ))}
          </ProOptionList>
        </BooleanAccordionCard>
        <BooleanAccordionCard
          title={
            <>
              Reference Checks{' '}
              <ProBadge fontSize="11px" fontStyle="italic" fontFamily="Source Sans Pro">
                PRO
              </ProBadge>
            </>
          }
          subtitle="Get applicant information automatically verified from their references."
          isCollapsed={isRefCheckAccordionCollapsed}
          isActive={hasSelectedRefCheck}
          onOpenCallback={() => {
            const { eventName } = EVENT_OTHER_DOCS.CLICK_ACCORDION_REF_CHECK
            tracker.trackEvent(eventName)
          }}
        >
          <ProOptionList>
            {values(REF_CHECK_PRO_OPTIONS).map(refCheckType => (
              <RefCheckProOptionItem
                key={refCheckType.key}
                refCheckKey={refCheckType.key}
                refCheckName={refCheckType.name}
                onChange={onRefCheckOptionChange}
                isSelected={refCheckOption[refCheckType.key]}
              />
            ))}
          </ProOptionList>
        </BooleanAccordionCard>
      </ComponentList>
    </ProOptionWrapper>
  )
}
