import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getUserPlan } from 'utils/get-user-plan'

export const withSubscriptionData = WrappedComponent => props => {
  const userSubscriptionData = useQuery({
    queryKey: ['subscription_plan_v2'],
    queryFn: getUserPlan,
  })

  return (
    <WrappedComponent
      {...props}
      userPlan={userSubscriptionData.data?.userPlan}
      isFetchingUserPlan={!userSubscriptionData.isFetched}
    />
  )
}
