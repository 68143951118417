import { takeLatest, put, call, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "utils/api-interceptor"
import {
  RENTER_INSURANCE_API_WITH_ID,
  GET_RENTER_INSURANCE_DETAIL_CALL,
} from "./constants"
import { renterInsuranceDetailApi } from "./actions"

export const getRenterInsuranceInstanceDetail = ({
  propertyId,
  rentersInsuranceId,
}) =>
  apiInstance.get(
    buildPath(RENTER_INSURANCE_API_WITH_ID, { propertyId, rentersInsuranceId }),
  )

export function* getRenterInsuranceDetailSaga({
  payload: { propertyId, rentersInsuranceId },
}) {
  yield put(renterInsuranceDetailApi.request())
  try {
    const result = yield call(getRenterInsuranceInstanceDetail, {
      propertyId,
      rentersInsuranceId,
    })
    yield put(renterInsuranceDetailApi.success(result))
  } catch (err) {
    yield put(renterInsuranceDetailApi.failure(err))
  }
}

export function* watchGetRenterInsurance() {
  yield takeLatest(
    GET_RENTER_INSURANCE_DETAIL_CALL,
    getRenterInsuranceDetailSaga,
  )
}

export function* rootSaga() {
  yield all([watchGetRenterInsurance()])
}

export default rootSaga
