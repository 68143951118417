/* eslint-disable no-return-assign */
import {
  ENVELOPE_STATUS,
  ENVELOPE_UPDATE_STEP,
} from "containers/envelope/constants"
import { REPRESENTING_PROPERTIES, USER_ROLE_FOR_PROPERTY, USER_INPUT_ROLE } from "containers/request/step-create-property/constants"
import { PRO_PLAN_VARIANTS } from "legacy/containers/pro/landing/constants"

import {
  LEASE_AGREEMENTS,
  ENVELOPE_UPLOAD_FILES,
  LEASE_DOC_TEMPLATE_EVENT,
  DOC_STATUS_PROPERTIES,
} from "./const"

const {
  RENTER_AGENT_PROPERTY,
  LISTING_AGENT_PROPERTY,
  LANDLORD_PROPERTY,
  PROPERTY_MANAGER,
  OTHER
} = REPRESENTING_PROPERTIES

/**
 * This function will return the event name and properties for the update envelope flow which will use `updateStep`
 * to determine which event name and properties that will return
 * @param {String} updateStep step of the update envelope should be one of `uploadFiles`, `recipients` and `signComponents`
 * @param {Object} envelope envelope object
 */
export const updateEnvelopeTrackerMapper = (updateStep, envelope) => {
  switch (updateStep) {
    case ENVELOPE_UPDATE_STEP.RECIPIENTS_STEP:
      return {
        eventName: LEASE_AGREEMENTS.FINISH_ADD_RECIPIENTS,
        properties: {
          recipient_count: envelope.recipients.length,
          template: envelope.templateId
            ? LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.LR_FORM
            : LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.CUSTOM,
        },
      }
    case ENVELOPE_UPDATE_STEP.UPLOAD_FILES_STEP:
      return {
        eventName: ENVELOPE_UPLOAD_FILES.FINISH_UPDATE_FILES,
        properties: {
          file_count: envelope.files.length,
        },
      }
    case ENVELOPE_UPDATE_STEP.SIGN_COMPONENTS_STEP:
      return {
        eventName: LEASE_AGREEMENTS.FINISH_PREPARE_ENVELOPE,
        properties: {
          file_count: envelope.files.length,
          recipient_count: envelope.recipients.length,
          component_count: envelope.files.reduce(
            (acc, v) =>
              acc + v.pages.reduce((acc2, v2) => acc2 + v2.fields.length, 0),
            0,
          ),
        },
      }
    default:
      return {}
  }
}

export const saveNewListingTrackerPropertyMapper = (
  isContinueToPropertyDetails,
  userType,
) => {
  if (!isContinueToPropertyDetails) return RENTER_AGENT_PROPERTY
  const representingMapper = {
    landlord: LANDLORD_PROPERTY,
    property_management: LANDLORD_PROPERTY,
    agent: LISTING_AGENT_PROPERTY,
    renter: LISTING_AGENT_PROPERTY,
  }
  return representingMapper[userType]
}

export const saveNewListingTrackerWithUserRoleMapper = (
  userRole,
) => {
  if (String(userRole).startsWith(USER_INPUT_ROLE.OTHER)) {
    return OTHER
  }
  const userRoleMapper = {
    [USER_ROLE_FOR_PROPERTY.LANDLORD]: LANDLORD_PROPERTY,
    [USER_ROLE_FOR_PROPERTY.LISTING_AGENT]: LISTING_AGENT_PROPERTY,
    [USER_ROLE_FOR_PROPERTY.RENTER_AGENT]: RENTER_AGENT_PROPERTY,
    [USER_INPUT_ROLE.PROPERTY_MANAGER]: PROPERTY_MANAGER,
  }
  return userRoleMapper[userRole]
}

/**
 * This function will return the document status given the page of which it was viewed,
 *  and the envelope's status
 * @param {Boolean} isViewPage whether or not the document is viewed in the view page
 * @param {String} status the current status of the envelope
 * @return {Object} an object containing the doc_status of the document for Amplitude to track
 */
export const leaseDocumentStatusTrackerMapper = (isViewPage, status) => {
  const isCompleted = isViewPage && status === ENVELOPE_STATUS.COMPLETED
  if (!isViewPage) {
    return DOC_STATUS_PROPERTIES.SIGNING
  }
  if (!isCompleted) {
    return DOC_STATUS_PROPERTIES.REVIEW
  }
  return DOC_STATUS_PROPERTIES.COMPLETED
}

export const generateTrackingValueByGiftName = name => {
  const nameLowerCase = name.toLocaleLowerCase()
  const reformatSpace = nameLowerCase.replaceAll(" ", "_")
  const regex = /[+&]/g
  const trackingValue = reformatSpace.replace(regex, "n")
  return trackingValue
}

export const getSubscriptionPeriodFromPriceName = priceName => {
  if (priceName.toLowerCase() === PRO_PLAN_VARIANTS.YEARLY.toLowerCase()) {
    return PRO_PLAN_VARIANTS.YEARLY.toLowerCase()
  }
  return PRO_PLAN_VARIANTS.MONTHLY.toLowerCase()
}
