import styled from 'styled-components'
import { COLOR, breakpoints } from 'styles/settings'
import {
  DocPanel,
  ReportLogo as SharedReportLogo,
  ReportWrapper as SharedReportWrapper,
} from '../share/responsive-doc-style'

export const IncomeVerificationWrapper = styled.div`
  padding-bottom: 20px;
  @media (max-width: 991px) {
    margin: 0 20px 0 20px;
  }
  color: ${COLOR.textBlack};
`

export const ReportTitle = styled.h1`
  font-family: 'Merriweather', serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  ${breakpoints.mobileReport(`
    font-size: 24px;
    line-height: 32px;
  `)}
`

export const SectionTitle = styled.div`
  background-color: ${COLOR.bgGrey};
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  align-content: center;
  padding-left: 10px;
  margin-bottom: 10px;
  ${breakpoints.mobileReport(`
    white-space: nowrap;
    overflow-x: scroll;
  `)}
`

export const SectionTitleNoBg = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  align-content: center;
  padding-left: 10px;
  margin-bottom: 20px;
`

export const TableRow = styled.tr`
  display: table-row;
  height: 42px;
  width: 100%;
  ${props => props.borderBottom && `border-bottom: 0.5px solid ${COLOR.borderGray};`}
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
`

export const TableHeaderRow = styled(TableRow)`
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`

export const TableWrapper = styled.div`
  ${breakpoints.mobileReport(`
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
  `)}
`

export const ReportTable = styled.table`
  display: table;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`

export const TableData = styled.td`
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
  display: table-cell;
  padding-left: 10px;
  align-content: center;
  white-space: break-spaces;
  ${breakpoints.mobileReport(`white-space: nowrap;`)}
`

export const ReportLogo = styled(SharedReportLogo)`
  height: 54px;
  width: 76px;
  ${breakpoints.mobileReport(`
    height: 54px;
    width: 76px;
  `)}
`

export const ReportFooterStyle = styled.div`
  position: relative;
  padding-left: 0px 10px;
  width: 100%;
  margin-top: 60px;
  height: 40px;
`

export const ReportProviderMessageStyle = styled.p`
  position: absolute;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
`

export const ReportWrapper = styled(SharedReportWrapper)`
  & ${DocPanel} {
    padding: 22px 48px;
    ${breakpoints.mobileReport(`
      padding: 22px 16px;
      `)}
  }
`
