import * as Types from "../constants/action-types"

export const toggleVideoTutorialModal = data => ({
  type: Types.SHOW_VIDEO_TUTORIAL_MODAL,
  data,
})

export const toggleVideoViewProcessModal = data => ({
  type: Types.SHOW_VIDEO_VIEW_PROCESS_MODAL,
  data,
})

export const toggleProRestrictionsModal = data => ({
  type: Types.SHOW_PRO_RESTRICTIONS_MODAL,
  data,
})

export const toggleOpenCreditModal = (
  isShow,
  currentApp = null,
  creditReportDetail = null,
) => ({
  type: Types.SHOW_OPEN_CREDIT_REPORT_MODAL,
  isShow,
  currentApp,
  creditReportDetail,
})

export const toggleDeactivatePropertyModal = (isShow, propertyId = null) => ({
  type: Types.SHOW_DEACTIVATE_PROPERTY_MODAL,
  isShow,
  propertyId,
})

export const toggleReactivatePropertyModal = (isShow, propertyId = null) => ({
  type: Types.SHOW_REACTIVATE_PROPERTY_MODAL,
  isShow,
  propertyId,
})

export const toggleLRALearnMoreModal = data => ({
  type: Types.SHOW_LRA_LEARN_MORE_MODAL,
  data,
})

// TODO: refactor to saga logic
export const togglePostLoginAdvertiseModal = isShow => ({
  type: Types.SHOW_POST_LOG_IN_ADVERTISE_MODAL,
  isShow,
})
