import { createSelector } from 'reselect'
import get from 'lodash/get'
import { initialState } from './reducer'

const getShareReportPromptState = state => get(state, 'shareReportPrompt', initialState)

export const selectIsShowModal = createSelector(getShareReportPromptState, state =>
  get(state, 'isShowModal'),
)

export const selectNavigationUnblock = createSelector(getShareReportPromptState, state =>
  get(state, 'navigationUnblock'),
)

export const selectIsAlreadyShow = createSelector(getShareReportPromptState, state =>
  get(state, 'isAlreadyShow'),
)