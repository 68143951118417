import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Divider, FormControl, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material'
import { Chip } from '@rentspree/component-2023.components.atoms.chip'
import { Radio } from '@rentspree/component-2023.components.atoms.radio'
import { RadioGroup } from '@rentspree/component-2023.components.atoms.radio-group'
import { Select } from '@rentspree/component-2023.components.atoms.select'
import { TextInput } from '@rentspree/component-2023.components.atoms.text-input'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import React, { useContext, useEffect, useState } from 'react'
import { isDecimal } from 'validator'

import { DatePickerInput } from 'v3/components/date-picker-input/index'
import { PaymentsCard } from 'v3/containers/overhaul-rent-payment/components/cards/payments-card'
import { PaymentsDrawer } from 'v3/containers/overhaul-rent-payment/components/drawer/drawer'
import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import {
  ButtonLayoutEnum,
  DEPOSIT_AND_FEES,
  ONE_TIME_PAYMENTS,
  MULTIPLE_SELECTION_PAYMENTS,
  PAYMENT_TYPES,
  RENT_PAYMENTS,
} from 'v3/containers/overhaul-rent-payment/constants'
import { PageIndexesContext } from 'v3/containers/overhaul-rent-payment/context'
import { validatePayment } from 'v3/containers/overhaul-rent-payment/pages/payment-details/utils'
import {
  capitilize,
  findPaymentCategory,
  utcDate,
} from 'v3/containers/overhaul-rent-payment/pages/utils'
import { ABOUT_PAYMENT } from 'v3/containers/overhaul-rent-payment/text-constants'
import { MONTH_TO_MONTH_LABEL } from 'v3/containers/rent-payment/constants'
import { generateQuotationByType } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/utils'

dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
const NOW = dayjs()
const { RECURRING, ONE_TIME } = PAYMENT_TYPES
const initialCurrentFee = {
  category: '',
  customCategory: '',
  type: '',
  amount: '',
  startDate: '',
  dueDate: '',
  dueOn: '',
  endDate: '',
  description: '',
}
const typographyStyle = { fontSize: '1.5rem', marginBottom: '15px' }

/*
 * Params are passed from payment-details/page.js
 * See <CurrentPage ... /> at the bottom of file
 *
 * @param {Object[]} payments - payments list from PaymentInfoContext
 * @param {function} setPayments - update payments list in PaymentInfoContext
 * @param {function} setDrawerOpen - update drawerOpen state in PageIndexesContext
 */
export const DepositAndFees = ({
  payments,
  setPayments,
  setDrawerOpen,
  _pageIndexes,
  _setDrawerOpen,
  _selectionOptions,
  _paymentSelections,
  _setPaymentSelections,
}) => {
  const [currentFee, setCurrentFee] = useState(initialCurrentFee)
  const { payment: feePayment, index: feeIndex } = currentFee
  const [errors, setErrors] = useState({})
  const [pageIndexes, setPageIndexes] = useContext(PageIndexesContext)
  const feePayments = payments.filter(p => !RENT_PAYMENTS.includes(p?.category))

  const onlyOneTimePayment = ONE_TIME_PAYMENTS.includes(feePayment?.category)

  /*
   * Filter out one time payments that are already in the fees list
   * MULTIPLE_SELECTION_PAYMENTS (Utilities, Others) can be added multiple times
   */
  const filteredOneTimePayments = ONE_TIME_PAYMENTS.filter(
    payment => !feePayments.some(fp => fp?.category === payment),
  )
  const combinedPayments = [...filteredOneTimePayments, ...MULTIPLE_SELECTION_PAYMENTS]

  useEffect(() => {
    const type = onlyOneTimePayment ? ONE_TIME : feePayment?.type
    setCurrentFee({
      ...currentFee,
      payment: { ...feePayment, type },
    })
  }, [currentFee.category])

  const setFeePayment = paymentData => {
    setCurrentFee({
      ...currentFee,
      payment: { ...feePayment, ...paymentData },
    })
  }

  const handleCostOnBlur = e => {
    let errorMessage
    let val = e.target.value

    if (isDecimal(val)) {
      val = parseFloat(val).toFixed(2)
    } else {
      errorMessage = ABOUT_PAYMENT.ERROR_AMOUNT
    }

    setFeePayment({ amount: val })
    setErrors({ ...errors, amount: errorMessage })
  }

  const handleEditPayment = ({ isOpen = true, payment, paymentIndex }) => {
    /*
     * 'beginningDate' allows for editing a payment to switch between one-time & recurring types,
     * and the start/due date to stay synced for hte user experience
     */
    const beginningDate = payment?.startDate || payment?.dueDate
    // since some fees are oneTime only, set a type default to oneTime to match to start off
    const type = payment?.type || ONE_TIME
    setCurrentFee({
      payment: { ...payment, startDate: beginningDate, dueDate: beginningDate, type },
      index: paymentIndex,
    })
    setPageIndexes({ drawerOpen: isOpen })
  }

  const handleAddFee = category => {
    const [paymentIndex, payment] = findPaymentCategory(payments, category)
    payment.customCategory = ''
    handleEditPayment({ isOpen: true, payment, paymentIndex })
  }

  const handleResetFee = () => {
    setCurrentFee(initialCurrentFee)
    setDrawerOpen(false)
  }

  const handleOnSave = () => {
    const endDateDisabled = ['oneTime', undefined].includes(feePayment?.type)
    const nextPayments = [...payments]
    const dueDate = feePayment?.dueDate || feePayment?.startDate

    const inputs = {
      category: feePayment?.category,
      customCategory: capitilize(feePayment?.customCategory),
      monthlyDescription: feePayment?.description,
      oneTimeDescription: feePayment?.description,
      amount: feePayment?.amount,
      paymentType: feePayment?.type,
      firstPayment: feePayment?.startDate,
      lastPayment:
        !endDateDisabled && feePayment?.endDate ? feePayment?.endDate : MONTH_TO_MONTH_LABEL,
      dueOn: dueDate ? utcDate(dueDate).date() : null,
      dueDate,
      selectedFeeCollection: {},
    }

    const quotation = generateQuotationByType(inputs)

    if (feePayment?.type === 'recurring') {
      quotation.repeat = 'monthly'
    }

    if (!validatePayment(quotation)) {
      // TODO: Handle errors
      return
    }

    if (feeIndex < 0) {
      nextPayments.push(quotation)
    } else {
      nextPayments[feeIndex] = quotation
    }

    setPayments(nextPayments)
    handleResetFee()
  }

  return (
    <Grid container gap={4}>
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        <Typography variant="title" align="left">
          What deposits and fees are you collecting?
        </Typography>
      </Grid>
      <Grid container gap={2}>
        {combinedPayments.map(payment => (
          <Chip
            key={payment}
            startIcon={<AddIcon />}
            label={payment}
            onClick={() => handleAddFee(payment)}
            sx={{
              fontSize: '1rem',
            }}
          />
        ))}
      </Grid>
      <Grid item sx={{ width: '100%', margin: 'auto' }}>
        {payments.map((payment, index) => {
          let component
          if (!RENT_PAYMENTS.includes(payment?.category)) {
            component = (
              <PaymentsCard
                key={`${payment?.category}`}
                payment={payment}
                paymentIndex={index}
                setDrawerOpen={handleEditPayment}
              />
            )
          }
          return component
        })}
      </Grid>
      <PaymentsDrawer drawerOpen={pageIndexes.drawerOpen}>
        <Grid container sx={{ justifyContent: 'space-between', marginBottom: '15px' }}>
          <Typography variant="h4">Tell us about fees</Typography>
          <CloseIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={handleResetFee} />
        </Grid>
        <Divider sx={{ marginBottom: '30px', width: '100%' }} />
        <FormControl defaultValue="Other" sx={{ marginBottom: '15px' }} required>
          <Typography sx={typographyStyle} variant="body1">
            Which fee?
          </Typography>
          <Select
            label="Fee type"
            value={feePayment?.category}
            onChange={e => setFeePayment({ category: e.target.value })}
            data-testid="fee-type-select-component"
            MenuProps={{
              style: { zIndex: 5050 },
            }}
            required
          >
            {DEPOSIT_AND_FEES.map(label => (
              <MenuItem
                value={label}
                data-testid="deposit-and-fee-option"
                key={`deposit-and-fee-option-${label}`}
              >
                <Typography variant="body-medium">{label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!onlyOneTimePayment && (
          <>
            <FormControl>
              <TextInput
                id="fee-customCategory"
                placeholder="Fee name"
                label="Fee name"
                variant="outlined"
                size="normal"
                value={feePayment?.customCategory}
                onChange={e => setFeePayment({ customCategory: e.target.value })}
                sx={{ marginBottom: '15px' }}
                required
              />
            </FormControl>
            <FormControl>
              <Typography sx={typographyStyle} variant="body1">
                How often?
              </Typography>
              <RadioGroup
                value={feePayment?.type || ''}
                onChange={e => setFeePayment({ type: e.target.value })}
                sx={{ marginBottom: '15px' }}
              >
                <FormControlLabel value={RECURRING} control={<Radio />} label="Monthly" />
                <FormControlLabel value={ONE_TIME} control={<Radio />} label="One-time" />
              </RadioGroup>
            </FormControl>
          </>
        )}
        <Typography sx={typographyStyle} variant="body1">
          How much?
        </Typography>
        <FormControl>
          <TextInput
            id="how-much"
            type="number"
            startAdornment="$"
            label="Amount"
            placeholder="Amount"
            variant="outlined"
            size="normal"
            sx={{ marginBottom: '15px' }}
            value={feePayment?.amount}
            onChange={e => setFeePayment({ amount: e.target.value })}
            onBlur={handleCostOnBlur}
            required
          />
        </FormControl>
        {feePayment?.type === ONE_TIME ? (
          <FormControl>
            <Typography sx={typographyStyle} variant="body1">
              When&#39;s the due date?
            </Typography>
            <DatePickerInput
              label="Due on"
              sx={typographyStyle}
              name="startDate"
              value={feePayment?.dueDate}
              onDateChange={date => {
                const nextDueOn = date ? utcDate(date).date() : null
                setFeePayment({ startDate: date, dueDate: date, dueOn: nextDueOn })
              }}
              onBlur={e => {
                const date = e.target.value
                const nextDueOn = date ? utcDate(date).date() : null
                setFeePayment({ startDate: date || null, dueOn: nextDueOn })
              }}
              minDate={NOW}
              error={errors?.dueDate !== undefined}
              helperText={errors?.dueDate === undefined ? errors.dueDate : undefined}
              required
            />
          </FormControl>
        ) : (
          <>
            <FormControl>
              <Typography sx={typographyStyle} variant="body1">
                For how long?
              </Typography>
              <DatePickerInput
                label="Starts on"
                sx={typographyStyle}
                name="startDate"
                value={feePayment?.startDate}
                onDateChange={date => {
                  const nextDueOn = date ? utcDate(date).date() : null
                  setFeePayment({ startDate: date, dueDate: date, dueOn: nextDueOn })
                }}
                onBlur={e => {
                  const date = e.target.value
                  const nextDueOn = date ? utcDate(date).date() : null
                  setFeePayment({ startDate: date || null, dueOn: nextDueOn })
                }}
                minDate={NOW}
                error={errors?.startDate !== undefined}
                helperText={errors?.startDate === undefined ? errors.startDate : undefined}
                required
              />
            </FormControl>
            <FormControl>
              <DatePickerInput
                label="Ends on"
                sx={typographyStyle}
                name="endDate"
                value={feePayment?.endDate}
                onDateChange={date => {
                  setFeePayment({ endDate: date || null })
                }}
                onBlur={e => {
                  const date = e.target.value
                  setFeePayment({ endDate: date || null })
                }}
                minDate={dayjs(feePayment?.startDate)}
                error={errors?.endDate !== undefined}
                helperText={
                  errors?.endDate === undefined
                    ? errors.endDate
                    : 'If no end date, Payments will continue month-to-month'
                }
                required
              />
            </FormControl>
          </>
        )}
        <FormControl sx={{ marginBottom: '15px' }}>
          <TextInput
            id="optional-note"
            label="Note"
            placeholder="Note (optional)"
            variant="outlined"
            defaultValue=""
            size="normal"
            value={feePayment?.description}
            onChange={e => setFeePayment({ description: e.target.value })}
            helperText="We'll show this info to the tenant"
          />
        </FormControl>
        <DrawerFooter
          buttonLayout={ButtonLayoutEnum.NEXT_BACK}
          textOverrides={{ back: 'Cancel', next: 'Save' }}
          onBack={handleResetFee}
          onNext={handleOnSave}
        />
      </PaymentsDrawer>
    </Grid>
  )
}
