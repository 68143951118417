import React, { useState } from 'react'
import skipPaymentSvg from 'images/skip-payment.svg'
import { Landing } from 'components/landing'
import { OpenCreditReportModal } from 'legacy/components/modal/open-credit-modal'
import { withLandlordPayConnect } from './connect'

export const LandlordSkipPaymentPage = ({ actions, rentalDetail }) => {
  const [isShowReportModal, setIsShowReportModal] = useState(false)

  const closeModalOpenCreditReport = () => {
    setIsShowReportModal(false)
  }

  const onViewReport = () => {
    setIsShowReportModal(true)
    actions.toggleOpenCreditModal(true, rentalDetail || {})
  }

  return (
    <>
      <Landing
        title="See Their Application for Free"
        description="You can view this renter’s application package since they already paid an application fee."
        buttonName="View"
        imgSrc={skipPaymentSvg}
        onClick={onViewReport}
        buttonWidth="205px"
        titleMaxWidth="380"
        imgWidth="150px"
        imgHeight="150px"
        titleMarginTop="30"
        titleFontSize="24"
        titleMobileFontSize="24"
        descFontSize="16"
        descMobileFontSize="16"
        buttonID="skip-payment-button"
        heightCal="calc( 100vh - 46px - 60px - 10px )"
        imgMobileDisplay="block"
      />
      {isShowReportModal && (
        <OpenCreditReportModal
          data-testid="landlord-skip-payment-modal"
          id="openCreditReportModal"
          className="rentspreeModal openCreditReport"
          showModal
          closeModal={closeModalOpenCreditReport}
          closeButton
          backdrop="static"
        />
      )}
    </>
  )
}

export default withLandlordPayConnect(LandlordSkipPaymentPage)
