import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import * as SSOHandlerAction from "../../actions/sso-handler-action"

export const ssoHandlerConnect = ComposedComponent =>
  connect(
    state => ({
      isLogin: !!state.persistState.auth.accessToken,
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          ...SSOHandlerAction,
        },
        dispatch,
      ),
    }),
  )(ComposedComponent)
