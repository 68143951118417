import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'

import CreateContactFormTemplate from 'components/contacts/templates/create-contact-form-template'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { selectSortedAllTags } from '../tags/selectors'

import { createTagContactModal } from './actions'
import { withConnect, withReducer, withSaga } from './connect'

const {
  EVENT_NAME: { CLICK_TO_ADD_TAG_TO_CONTACT, CLOSE_CREATED_CONTACT_MODAL },
  EVENT_PROPERTY: {
    CLICK_FROM: { CONTACTS_CREATE_MODAL },
    CATEGORY: { PEOPLE_CONCEPT },
  },
} = EVENT_PEOPLE_CONCEPT
export const CreateContactModal = ({ isOpen, isSubmitting, actions }) => {
  const dispatch = useDispatch()
  const allTags = useSelector(selectSortedAllTags)
  const [inputEmail, inputEmailChange] = React.useState('')
  const handleCreateTagContact = name => dispatch(createTagContactModal(name))
  const handleOnFocusTagInput = () =>
    tracker.trackEvent(CLICK_TO_ADD_TAG_TO_CONTACT, {
      click_from: CONTACTS_CREATE_MODAL,
      contact_email: inputEmail,
      category: PEOPLE_CONCEPT,
    })

  const handleCloseCreateContactModal = () => {
    // eslint-disable-next-line no-unused-expressions
    actions?.closeCreateContactModal()
    tracker.trackEvent(CLOSE_CREATED_CONTACT_MODAL)
  }
  return (
    <div>
      <RentSpreeBaseModal
        isOpen={isOpen}
        overflow="visible"
        onClose={handleCloseCreateContactModal}
        modalSize="medium"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <CreateContactFormTemplate
          onCreateContact={actions?.createContactFromModal}
          onCloseCreateContactModal={handleCloseCreateContactModal}
          isSubmittingForm={isSubmitting}
          allTags={allTags}
          onCreateTagContact={handleCreateTagContact}
          onFocusTagInput={handleOnFocusTagInput}
          inputEmailChange={inputEmailChange}
        />
      </RentSpreeBaseModal>
    </div>
  )
}
export default compose(withConnect, withSaga, withReducer)(CreateContactModal)
