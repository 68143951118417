import { combineReducers } from 'redux'
import moment from 'moment'
import { TYPES as REPORTS_TYPES } from 'containers/reports/constants'

const { SAVE_PDF_REQUEST, SAVE_PDF_SUCCESS, SAVE_PDF_FAILURE } = REPORTS_TYPES

const reportDownloadData = (state = null, { type, payload }) => {
  switch (type) {
    case SAVE_PDF_REQUEST:
      return null
    case SAVE_PDF_SUCCESS:
      return payload.fileData || null
    default:
      return state
  }
}

const reportFileName = (state = null, { type, payload }) => {
  switch (type) {
    case SAVE_PDF_REQUEST:
    case SAVE_PDF_FAILURE:
      return null
    case SAVE_PDF_SUCCESS:
      return payload.fileName || `report-${moment().format('x')}.pdf`
    default:
      return state
  }
}

const isFetching = (state = false, { type }) => {
  switch (type) {
    case SAVE_PDF_REQUEST:
      return true
    case SAVE_PDF_SUCCESS:
    case SAVE_PDF_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  reportDownloadData,
  isFetching,
  reportFileName,
})
