import React from "react"
import * as Styled from "./buttons-styled"
import { I } from "../typography/text"

export const Button = ({ text, children, icon, iconRight, gap, ...rest }) => (
  <Styled.button {...rest}>
    {icon && <Styled.btnIcon className={icon} gap={gap} />}
    {text && text}
    {children && children}
    {iconRight && <Styled.btnIconRight className={iconRight} gap={gap} />}
  </Styled.button>
)
export const Link = ({
  text,
  children,
  primary,
  white,
  minWidth,
  hiddenMobile,
  inlineLink,
  ...rest
}) => (
  <Styled.link
    {...rest}
    primary={primary}
    white={white}
    minWidth={minWidth}
    hiddenMobile={hiddenMobile}
    inlineLink={inlineLink}>
    {text && text}
    {children && children}
  </Styled.link>
)

export const RouterLink = ({
  text,
  children,
  transparent,
  hiddenMobile,
  ...rest
}) => (
  <Styled.routerLink
    {...rest}
    transparent={transparent}
    hiddenMobile={hiddenMobile}>
    {text && text}
    {children && children}
  </Styled.routerLink>
)

export const ButtonAlike = ({ text, children, ...rest }) => (
  <Styled.buttonAlike {...rest}>
    {text && text}
    {children && children}
  </Styled.buttonAlike>
)

export const BtnIcon = ({ ...props }) => <Styled.btnIcon {...props} />
export const BtnImgIcon = ({ ...props }) => <Styled.btnImgIcon {...props} />
