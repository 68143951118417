export const PROPERTY_TYPES = [
  {
    value: "SINGLE_FAMILY_HOME",
    label: "Single-family home",
  },
  {
    value: "MULTI_FAMILY_HOME",
    label: "Multi-family home (Duplex/Triplex/Quadruplex)",
  },
  {
    value: "CONDOMINIUM",
    label: "Condominium",
  },
  {
    value: "APARTMENT",
    label: "Apartment",
  },
  {
    value: "MANUFACTURED_HOME",
    label: "Mobile/Manufactured home",
  },
  {
    value: "TOWNHOUSE",
    label: "Townhouse",
  },
  {
    value: "STUDENT_HOUSING",
    label: "Dorm/Room/Student housing",
  },
  {
    value: "OTHERS",
    label: "Other",
  },
]
export const SINGLE_FAMILY_HOME = "SINGLE_FAMILY_HOME"
export const NOT_AVAILABLE_TYPE = "N/A"
export const API_UPDATE_PROPERTY_TYPE =
  "/api/v2/properties/:propertyId/propertytype"

export const UPDATE_PROPERTY_TYPE_CALL = "UPDATE_PROPERTY_TYPE_CALL"
export const UPDATE_PROPERTY_TYPE_REQUEST = "UPDATE_PROPERTY_TYPE_REQUEST"
export const UPDATE_PROPERTY_TYPE_SUCCESS = "UPDATE_PROPERTY_TYPE_SUCCESS"
export const UPDATE_PROPERTY_TYPE_FAILED = "UPDATE_PROPERTY_TYPE_FAILED"
export const CLEAR_PROPERTY_TYPE_ERROR = "CLEAR_PROPERTY_TYPE_ERROR"

export const SELECT_DROP_DOWN_PLACEHOLDER = {
  label: "Select",
  value: "",
}

export const PROPERTY_UPDATE_CONFIRMATION = {
  TITLE: "Please Confirm",
  DESC: (fullNameAddress, propertyType) =>
    `The property type cannot be edited after being selected. Please confirm that ${fullNameAddress} is a(n) ${propertyType}. If this is not correct, please change before moving to the next step. `,
  CONFIRM: "Send",
}
