// import { API_URL } from "env"

export const GET_TENANT_SCREENING_NOTIFICATION_CALL =
  "GET_TENANT_SCREENING_NOTIFICATION_CALL"
export const GET_TENANT_SCREENING_NOTIFICATION_REQUEST =
  "GET_TENANT_SCREENING_NOTIFICATION_REQUEST"
export const GET_TENANT_SCREENING_NOTIFICATION_SUCCESS =
  "GET_TENANT_SCREENING_NOTIFICATION_SUCCESS"
export const GET_TENANT_SCREENING_NOTIFICATION_FAILURE =
  "GET_TENANT_SCREENING_NOTIFICATION_FAILURE"

export const GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_CALL =
  "GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_CALL"
export const GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_REQUEST =
  "GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_REQUEST"
export const GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_SUCCESS =
  "GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_SUCCESS"
export const GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_FAILURE =
  "GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_FAILURE"

export const GET_TASK_NOTIFICATION_CALL = "GET_TASK_NOTIFICATION_CALL"
export const GET_TASK_NOTIFICATION_REQUEST = "GET_TASK_NOTIFICATION_REQUEST"
export const GET_TASK_NOTIFICATION_SUCCESS = "GET_TASK_NOTIFICATION_SUCCESS"
export const GET_TASK_NOTIFICATION_FAILURE = "GET_TASK_NOTIFICATION_FAILURE"

const API_PREFIX = `/api/v2`
export const GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_API = `${API_PREFIX}/rental-payments/notifications`
