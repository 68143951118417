import produce from "immer"
import {
  GET_SUBSCRIPTION_PRODUCT_CALL,
  GET_SUBSCRIPTION_PRODUCT_REQUEST,
  GET_SUBSCRIPTION_PRODUCT_SUCCESS,
  GET_SUBSCRIPTION_PRODUCT_FAILURE,
  GET_USER_SUBSCRIPTION_CALL,
  GET_USER_SUBSCRIPTION_REQUEST,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_FAILURE,
  PRO_PLAN_VARIANTS,
  SET_SELECTED_PRICE_NAME,
} from "./constants"

export const initialState = {
  products: { data: [], loading: false, loaded: false, error: false },
  subscription: {
    data: {},
    loading: false,
    loaded: false,
    error: false,
  },
  selectedPriceName: PRO_PLAN_VARIANTS.MONTHLY,
}

/* eslint-disable no-param-reassign */
const subscriptionReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_SUBSCRIPTION_PRODUCT_REQUEST:
      case GET_SUBSCRIPTION_PRODUCT_CALL:
        draft.products = {
          data: [],
          loaded: false,
          loading: true,
          error: false,
        }
        break
      case GET_SUBSCRIPTION_PRODUCT_SUCCESS:
        draft.products = {
          data: payload.products,
          loaded: true,
          loading: false,
          error: false,
        }
        break
      case GET_SUBSCRIPTION_PRODUCT_FAILURE:
        draft.products = {
          data: [],
          loaded: false,
          loading: false,
          error: true,
        }
        break

      case GET_USER_SUBSCRIPTION_REQUEST:
      case GET_USER_SUBSCRIPTION_CALL:
        draft.subscription = {
          data: {},
          loaded: false,
          loading: true,
          error: false,
        }
        break
      case GET_USER_SUBSCRIPTION_SUCCESS:
        draft.subscription = {
          data: payload.result,
          loaded: true,
          loading: false,
          error: false,
        }
        break
      case GET_USER_SUBSCRIPTION_FAILURE:
        draft.subscription = {
          data: {},
          loaded: false,
          loading: false,
          error: true,
        }
        break
      case SET_SELECTED_PRICE_NAME:
        draft.selectedPriceName = payload
        break
      default:
        return state
    }
    return draft
  })

export { subscriptionReducer }
