/* eslint-disable no-underscore-dangle */
import { takeLatest, call, put, all, select, spawn } from 'redux-saga/effects'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { buildPath } from '@rentspree/path'
import tracker from 'tracker'
import { SHARE_REPORT, SHARE_REPORT_PROPERTIES } from 'tracker/const'
import mapWordingTrack from 'legacy/helpers/map-wording-track'
import { getSubmissionParticipantByEmailCall } from 'containers/reports/actions'
import { createAssociationContact } from 'containers/overview/association-contact/actions'
import {
  ASSOCIATION_CONTACT_ROLE,
  ASSOCIATION_CONTACT_SOURCE,
} from 'containers/overview/association-contact/constants'
import { selectAssociationContact } from 'containers/overview/association-contact/selectors'
import { USER_PREFERENCE_API_V2 } from 'constants/route'
import { apiInstance } from '../../utils/api-interceptor'
import { alertSuccess, setFormValues, shareReportCall } from './actions'
import {
  GET_REPORTS_AVAILABLE_CALL,
  SHARE_REPORT_CALL,
  TOGGLE_SHARE_REPORT_MODAL_CALL,
  TOGGLE_SHARE_REPORT_MODAL,
  SHARE_REPORT_API,
  GET_SCREENING_AVAILABLE_API,
  getReportsAvailableApiCall,
  shareReportApiCall,
  SUBMIT_SHARE_REPORT_FORM,
  UPDATE_USER_PREFERENCE_API,
  getUserPreferenceApiCall,
  GET_DEFAULT_SHARE_OPTION_CALL,
} from './constants'
import { selectProperty, selectRentalDetail } from '../application/selectors'
import { selectFormValues } from './selectors'

export const getReportsAvailableAPI = rentalId =>
  apiInstance.get(buildPath(GET_SCREENING_AVAILABLE_API, { rentalId }))

export const shareReportAPI = (rentalId, data) =>
  apiInstance.post(buildPath(SHARE_REPORT_API, { rentalId }), data)

export const getUserPreferenceAPI = () => apiInstance.get(USER_PREFERENCE_API_V2)

export const updateUserPreferenceApi = preference =>
  apiInstance.put(UPDATE_USER_PREFERENCE_API, preference)

export function* getReportsAvailable() {
  const rental = yield select(selectRentalDetail)
  yield put(getReportsAvailableApiCall.request())
  try {
    const response = yield call(getReportsAvailableAPI, get(rental, '_id'))
    yield put(getReportsAvailableApiCall.success(response))
  } catch (err) {
    yield put(getReportsAvailableApiCall.failure())
  }
}

export function* getDefaultShareOption() {
  yield put(getUserPreferenceApiCall.request())
  try {
    const response = yield call(getUserPreferenceAPI)
    const { result } = response
    yield put(getUserPreferenceApiCall.success(result?.defaultShareReportOption))
  } catch (err) {
    yield put(getUserPreferenceApiCall.failure())
  }
}

export const parseShareOption = ({
  application,
  screeningReport,
  otherDocs,
  referenceCheck,
  incomeVerificationReport,
  addLandlordToProperty,
}) => ({
  applications: application,
  credit_report: screeningReport,
  documents: otherDocs,
  reference_checks: referenceCheck,
  Income_verification: incomeVerificationReport,
  ll_added_to_property: addLandlordToProperty,
})

export function* shareReport({ payload }) {
  const rental = yield select(selectRentalDetail)
  yield put(shareReportApiCall.request())
  try {
    yield call(shareReportAPI, get(rental, '_id'), payload)
    yield put(shareReportApiCall.success())
    yield put(getSubmissionParticipantByEmailCall(get(rental, '_id'), payload.email))
    if (payload.addLandlordToProperty) {
      yield put(
        createAssociationContact({
          email: payload.email,
          propertyId: payload.propertyId,
          role: ASSOCIATION_CONTACT_ROLE.LANDLORD,
          source: ASSOCIATION_CONTACT_SOURCE.SHARE_REPORT,
        }),
      )
    }
    yield put(alertSuccess({ email: payload.email }))
    const isMessageEmpty = yield call(isEmpty, payload.agentMessage)
    const parsedShareOption = yield call(parseShareOption, {
      ...payload.shareOption,
      addLandlordToProperty: payload.addLandlordToProperty,
    })
    yield call([tracker, 'trackEvent'], SHARE_REPORT.shareSuccess, {
      text_message: !isMessageEmpty,
      [SHARE_REPORT_PROPERTIES.rentalId]: rental?._id,
      ...parsedShareOption,
    })
  } catch (err) {
    yield put(shareReportApiCall.failure())
    yield call([tracker, 'trackEvent'], SHARE_REPORT.shareFail)
  }
}

export function* toggleModal({ payload: { toggle, clickFrom, position } }) {
  const rental = yield select(selectRentalDetail)
  yield put({ type: TOGGLE_SHARE_REPORT_MODAL, payload: toggle })
  if (toggle) {
    const mappedClickFrom = yield call(mapWordingTrack, clickFrom)
    yield call([tracker, 'trackEvent'], SHARE_REPORT.startShare, {
      [SHARE_REPORT_PROPERTIES.clickFrom]: mappedClickFrom,
      [SHARE_REPORT_PROPERTIES.position]: position,
      [SHARE_REPORT_PROPERTIES.rentalId]: rental?._id,
    })
  }
}

export function* updateDefaultShareOptionSaga({ shareOption }) {
  yield call(updateUserPreferenceApi, { defaultShareReportOption: shareOption })
}

export function* submitShareReportSaga({ payload: formValues }) {
  const property = yield select(selectProperty)
  const { _id: propertyId } = property
  const shareOption = {
    application: formValues.application,
    screeningReport: formValues.screeningReport,
    incomeVerificationReport: formValues.incomeVerificationReport,
    otherDocs: formValues.otherDocs,
    referenceCheck: formValues.referenceCheck,
  }
  const { email, agentMessage, addLandlordToProperty } = formValues
  const landlordInformation = yield select(selectAssociationContact)

  const landlord = {
    firstName: get(landlordInformation, 'firstName', ''),
    lastName: get(landlordInformation, 'lastName', ''),
  }

  yield spawn(updateDefaultShareOptionSaga, { shareOption })
  yield put(
    shareReportCall({
      email,
      agentMessage,
      shareOption,
      landlord: formValues.shareWithLandlord && !isEmpty(landlord.firstName) ? landlord : undefined,
      propertyId,
      addLandlordToProperty,
    }),
  )
}

export function* watchGetDefaultShareOption() {
  yield takeLatest(GET_DEFAULT_SHARE_OPTION_CALL, getDefaultShareOption)
}

export function* watchGetReportsAvailable() {
  yield takeLatest(GET_REPORTS_AVAILABLE_CALL, getReportsAvailable)
}

export function* watchShareReport() {
  yield takeLatest(SHARE_REPORT_CALL, shareReport)
}

export function* watchSubmitShareReport() {
  yield takeLatest(SUBMIT_SHARE_REPORT_FORM, submitShareReportSaga)
}

export function* watchToggleShareReportModal() {
  yield takeLatest(TOGGLE_SHARE_REPORT_MODAL_CALL, toggleModal)
}

function* rootSaga() {
  yield all([
    watchShareReport(),
    watchGetReportsAvailable(),
    watchToggleShareReportModal(),
    watchSubmitShareReport(),
    watchGetDefaultShareOption(),
  ])
}

export default rootSaga
