import React from 'react'
import styled from 'styled-components'
import { COLOR, breakpoints } from 'styles/settings'
import { ApplicantName } from '../screening-reports/share/responsive-doc-style'

const HeaderContainer = styled.div`
  text-align: center;
`

const rentImg = require('images/logos/rentspree-logo.svg')

const RentSpreeLogo = styled.img`
  height: 50px;
  width: 150px;
  margin: 20px 0 20px 0px;
  ${breakpoints.mobileReport(`
    height: 28px;
    margin: 10px 0 30px 0px;
  `)}
`

export const FontSubHeader = styled.p`
  font-size: 24px;
  font-family: 'Source Sans Pro';
  background: #4a4a4a !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: 600;
  margin: 20px 0 0 0;
  padding-bottom: 15px;
  border-bottom: 1px solid ${COLOR.newGrey};
  ${breakpoints.mobileReport(`
      font-size: 18px;
      padding-bottom: 8px;
      margin: 32px 0 0 0;
  `)}
`
const Header = ({ fullName, applicantType }) => (
  <HeaderContainer>
    <RentSpreeLogo src={rentImg} />
    <ApplicantName data-testid="coverpage-name">{fullName}</ApplicantName>
    <FontSubHeader data-testid="coverpage-applicant-type">
      {applicantType === 'tenant' ? 'Tenant' : 'Guarantor / Co-signer'} Summary
    </FontSubHeader>
  </HeaderContainer>
)

export default Header
