import { API_URL, CDN_URL } from 'env'

export const GET_TENANT_SCREENING_CALL = 'GET_TENANT_SCREENING_CALL'
export const GET_TENANT_SCREENING_REQUEST = 'GET_TENANT_SCREENING_REQUEST'
export const GET_TENANT_SCREENING_SUCCESS = 'GET_TENANT_SCREENING_SUCCESS'
export const GET_TENANT_SCREENING_FAILURE = 'GET_TENANT_SCREENING_FAILURE'

export const GET_TENANT_SCREENING_API = `${API_URL}/api/v2/agent/submission-participants`

export const TABLE_HEADER = [
  { text: 'Applicant Name', value: 'applicant', class: 'text-left' },
  { text: 'Property', value: 'property', class: 'text-left' },
  { text: '' },
  { text: 'Application', value: 'application' },
  { text: 'TU Reports', value: 'tu_reports' },
  { text: 'Status', value: 'status', class: 'text-left' },
  { text: 'Last  Update', value: 'updated_at', class: 'text-right' },
]

export const CLEAR_TENANT_SCREENING = 'CLEAR_TENANT_SCREENING'

export const GET_TENANT_SCREENING_NOTIFICATION_API = `${GET_TENANT_SCREENING_API}/notification`

export const SEEN_GROUP_AGENT_INITIATE_PAYMENT_STICKER = 'agent_initiate_payment_sticker'
export const STICKER_CONTENT = {
  TITLE: 'Streamline deposit collection',
  DESCRIPTION: 'Easily coordinate online payment of deposit and fees for the landlord and get status updates along the way.',
  IMAGE_URL: `${CDN_URL}/static-files/onboarding/hands-using-phone.svg`,
  IMAGE_ALT: "hands-using-phone",
  CTA_TEXT: "See how it works",
}
