import get from "lodash/get"
import { APP_TYPES, APP_STATUS } from "containers/application/const"

export const isSignatureRequired = (profile, rentalDetail, application) => {
  const isOwner = rentalDetail.landlord_id === profile.id
  const isApplicationTypeLRA =
    get(rentalDetail, "screeningOption.applicationType") === APP_TYPES.CAR_LRA
  const isApplicationSubmitted =
    application.status === APP_STATUS.submitted.value

  return isOwner && isApplicationTypeLRA && isApplicationSubmitted
}
