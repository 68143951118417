import React from "react"
import styled from "styled-components"
import { ModalDefault, ModalActions, ModalHeader } from "./modal-default"
import { Button } from "../buttons/buttons"
import { TextDiv } from "../typography"

import carLogo from "../../images/icons/paper-car-logo.png"

export const StyleImg = styled.img`
  width: 136px;
  height: auto;
  margin-bottom: 20px;
`

export const StyledText = styled(TextDiv)`
  color: #4a4a4a;
`

export const LraLearnMoreModal = props => (
  <ModalDefault
    {...props}
    header={
      <ModalHeader>
        <StyledText margin="0px" size="16px">
          C.A.R. Rental Application + RentSpree
        </StyledText>
        <StyledText size="24px" strong align="center" margin="5px 0px">
          The perfect combination
        </StyledText>
      </ModalHeader>
    }
    className="rentspreeModal extraSmallModal">
    <StyleImg src={carLogo} />
    <StyledText size="14px" align="center" lineHeight="1.5" margin="0px 10px">
      RentSpree now can collect rental applications using the authorized C.A.R.
      rental application form.
    </StyledText>
    <ModalActions>
      <Button
        default
        id="cancelLraLearnMoreBtn"
        text="Cancel"
        padding="0px 25px"
        onClick={props.closeModal}
      />
      <Button
        primary
        id="LraLearnHowBtn"
        text="Learn How"
        padding="0px 25px"
        onClick={props.clickLearnHow}
      />
    </ModalActions>
  </ModalDefault>
)
