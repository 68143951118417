import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import reducer from './reducer'
import saga from './saga'

export const withSaga = injectSaga({
  key: 'contacts.addToContact',
  saga,
})

export const withReducer = injectReducer({
  key: 'contacts.addToContact',
  reducer,
})
