import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"

import {
  getApplicationDetail,
  getFormAvailable,
} from "legacy/actions/application.action"
import reducer from "./reducer"
import saga from "./saga"
import { printReport, savePDF } from "../actions"
import {
  selectApplicationDetail,
  selectRentalDetail,
  selectPdfReport,
  selectErrorApplication,
  selectIsFetchingAppDetail,
  selectIsFetchingFormAvailable,
} from "../selectors"
import { renderPDFSuccess } from "./actions"
import { makeSelectIsLoadingGetPdf, makeSelectPdfFile } from "./selectors"

const mapStateToProps = createStructuredSelector({
  applicationDetail: selectApplicationDetail,
  rentalDetail: selectRentalDetail,
  pdfReport: selectPdfReport,
  pdfFile: makeSelectPdfFile(),
  errorApplication: selectErrorApplication,
  isFetchingAppDetail: selectIsFetchingAppDetail,
  isFetchingFormAvailable: selectIsFetchingFormAvailable,
  isFetchingPDFFile: makeSelectIsLoadingGetPdf(),
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getApplicationDetail,
      getFormAvailable,
      printReport,
      savePDF,
      onShowPdfSuccess: renderPDFSuccess,
    },
    dispatch,
  )

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "reportApplication", reducer })
export const withSaga = injectSaga({ key: "reportApplication", saga })
