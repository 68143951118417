import generateApiCall from "utils/generate-api-call"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { OPEN_SWEET_ALERT } from "redux-middleware/sweet-alert/constants"
import { store } from "../../store"
import {
  LEASE_AGREEMENT_DETAIL_CALL,
  LEASE_AGREEMENT_DETAIL_REQUEST,
  LEASE_AGREEMENT_DETAIL_SUCCESS,
  LEASE_AGREEMENT_DETAIL_FAILURE,
  CLEAR_LEASE_AGREEMENT_DETAIL,
  OPEN_VOID_MODAL,
  CLOSE_VOID_MODAL,
  VOID_ENVELOPE_FAILED,
  VOID_ENVELOPE_SUCCESS,
  VOID_ENVELOPE_REQUEST,
  UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST,
  UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS,
  UPDATE_LEASE_AGREEMENT_CONSENT_FAILED,
  VOID_ENVELOPE_CALL,
  DELETE_ENVELOPE_CALL,
  DELETE_ENVELOPE_REQUEST,
  DELETE_ENVELOPE_SUCCESS,
  DELETE_ENVELOPE_FAILED,
  CLEAR_VOID_STATUS,
  CLEAR_LEASE_ERROR,
  RESEND_ENVELOPE_CALL,
  RESEND_ENVELOPE_REQUEST,
  RESEND_ENVELOPE_SUCCESS,
  RESEND_ENVELOPE_FAILED,
  UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
  CREATE_CUSTOM_TEMPLATE
} from "./constants"

export const leaseAgreementDetailCall = payload => ({
  type: LEASE_AGREEMENT_DETAIL_CALL,
  payload,
})

export const clearLeaseAgreementDetail = () => ({
  type: CLEAR_LEASE_AGREEMENT_DETAIL,
})

export const updateLeaseAgreementConsent = payload => ({
  type: UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
  payload,
})

export const leaseAgreementDetailApi = generateApiCall([
  LEASE_AGREEMENT_DETAIL_REQUEST,
  LEASE_AGREEMENT_DETAIL_SUCCESS,
  LEASE_AGREEMENT_DETAIL_FAILURE,
])

export const updateConsentLeaseAgreementApi = generateApiCall([
  UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST,
  UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS,
  UPDATE_LEASE_AGREEMENT_CONSENT_FAILED,
])

export const openVoidModal = () => ({
  type: OPEN_VOID_MODAL,
})

export const closeVoidModal = () => ({
  type: CLOSE_VOID_MODAL,
})

export const voidEnvelopeApi = generateApiCall([
  VOID_ENVELOPE_REQUEST,
  VOID_ENVELOPE_SUCCESS,
  VOID_ENVELOPE_FAILED,
])

export const deleteEnvelopeApi = generateApiCall([
  DELETE_ENVELOPE_REQUEST,
  DELETE_ENVELOPE_SUCCESS,
  DELETE_ENVELOPE_FAILED,
])

export const resendEnvelopeApi = generateApiCall([
  RESEND_ENVELOPE_REQUEST,
  RESEND_ENVELOPE_SUCCESS,
  RESEND_ENVELOPE_FAILED,
])

export const resendEnvelopeCall = payload => ({
  type: RESEND_ENVELOPE_CALL,
  payload,
})
export const voidEnvelopeCall = payload => ({
  type: VOID_ENVELOPE_CALL,
  payload,
})

export const deleteEnvelopeCall = payload => ({
  type: DELETE_ENVELOPE_CALL,
  payload
})

export const clearVoidStatus = () => ({
  type: CLEAR_VOID_STATUS,
})

export const promiseCallback = payload => () => {
  store.dispatch(leaseAgreementDetailCall(payload))
}

export const alertSuccess = (callback, option) => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.SUCCESS,
  option,
  promise: callback,
})

export const alertError = option => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.ERROR,
  option,
})

export const clearLeaseError = () => ({ type: CLEAR_LEASE_ERROR })

export const createCustomTemplate = payload => ({
  type: CREATE_CUSTOM_TEMPLATE,
  payload
})
