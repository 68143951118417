import generateApiCall from 'utils/generate-api-call'

import {
  SEARCH_RENTER_INFO,
  SEARCH_RENTER_INFO_FAILED,
  SEARCH_RENTER_INFO_REQUESTED,
  SEARCH_RENTER_INFO_SUCCEEDED,
} from './constants'

export const searchRenterInfo = payload => ({
  type: SEARCH_RENTER_INFO,
  payload,
})

export const searchRenterInfoAPI = generateApiCall([
  SEARCH_RENTER_INFO_REQUESTED,
  SEARCH_RENTER_INFO_SUCCEEDED,
  SEARCH_RENTER_INFO_FAILED,
])
