import md5 from "md5"

export default email => {
  if (email) {
    return `https://s3-us-west-1.amazonaws.com/rentspree/profile_images/${md5(
      email,
    )}.png`
  }
  return ""
}
