import React from "react"
import LoadingOverlay from "components/contacts/molecules/loading-overlay"
import { buildPath, parse } from "@rentspree/path"
import * as PATH from "constants/route"
import { LISTING_IMPORT_STATUS } from "containers/constants"
import { USER_LAST_ACTION } from "constants/user-last-action"
import { useLastLocation } from "react-router-last-location"
import tracker from "tracker"

import { MLS_ADOPTION_EVENT } from "tracker/const"
import isEmpty from "lodash/isEmpty"
import { withImportListingConnect } from "../connect"
export const AutoImportListing = ({
  property,
  history,
  actions,
  isFetchingProperty,
  match,
}) => {
  const lastLocation = useLastLocation()
  const { params } = match

  React.useEffect(() => {
    actions.getProperty(params?.propertyId)
    return () => actions.clearProperty()
  }, [])

  React.useEffect(() => {
    if (!isFetchingProperty && !isEmpty(property)) {
      const { importStatus } = property?.listing || {}
      const skipImport = [
        LISTING_IMPORT_STATUS.IMPORTED,
        LISTING_IMPORT_STATUS.ERROR,
      ].includes(importStatus)

      if (skipImport) {
        history.replace(
          buildPath(
            PATH.CREATE_LISTING_INFORMATION_STEP,
            { propertyId: params?.propertyId },
            parse(history.location.search),
          ),
          {
            userLastAction: USER_LAST_ACTION.REDIRECTED,
            fromURL: lastLocation?.pathname,
          },
        )
      } else {
        tracker.trackEvent(MLS_ADOPTION_EVENT.EVENT_NAME.CLICK_CLAIM_LISTING)
        actions.autoImportMLSListing(params?.propertyId)
      }
    }
  }, [isFetchingProperty])

  return <LoadingOverlay />
}
export default withImportListingConnect(AutoImportListing)
