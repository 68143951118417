import React, { useEffect } from 'react'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'
import ReportNotReady from 'images/report/income-verification/report-not-ready.svg'
import Button from '@rentspree/component-2023.components.atoms.button'
import MasterCardLogoImg from 'images/logos/mastercard-logo.svg'
import tracker from 'tracker'
import { INCOME_VERIFICATION_EVENT } from 'tracker/const'
import { ReportWrapper } from '../../share/responsive-doc-style'
import { ApplicantDetail } from '..'

import { ReportHeader, ReportsBody } from '../../share/responsive-doc-components'
import { ReportLogo, ReportTitle } from '../styles'

const Container = styled.div`
  height: 80vh;
  padding-top: 100px;
  text-align: center;
  color: ${COLOR.textBlack};
`

const ImageWrapper = styled.div`
  margin-bottom: 30px;
`

const TiTle = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`

const SubTitleBox = styled.div`
  margin: 15px 0;
`

const SubTitle = styled.div`
  white-space: pre-line;
  font-size: 16px;
  a {
    color: ${COLOR.primary};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

const Logo = () => <ReportLogo data-testid="mastercard-logo" src={MasterCardLogoImg} />

const mappingReportState = name => {
  const foundKey = Object.keys(INCOME_VERIFICATION_EVENT.INCOME_REPORT_STATE).find(
    reportKey => INCOME_VERIFICATION_EVENT.INCOME_REPORT_STATE[reportKey] === name,
  )
  return foundKey ? INCOME_VERIFICATION_EVENT.INCOME_REPORT_STATE[foundKey] : null
}

const IncomeVerificationErrorPage = ({
  pageContent,
  onCtaClick,
  applicantDetail,
  isFromAllTab,
  onLinkClick,
}) => {
  useEffect(() => {
    tracker.trackEvent(INCOME_VERIFICATION_EVENT.EVENT_NAME.VIEW_INCOME_REPORT_STATE, {
      income_report_state: mappingReportState(pageContent.name),
      view_from: isFromAllTab
        ? INCOME_VERIFICATION_EVENT.VIEW_FROM.ALL_REPORT_TAB
        : INCOME_VERIFICATION_EVENT.VIEW_FROM.INCOME_VERIFICATION_TAB,
    })
  }, [])

  const renderSubTitle = () => {
    const parts = pageContent.subTitle.split(/(\[.*?\]\(.*?\))/) // Splitting at the markdown-like link

    return parts.map((part, index) => {
      const match = part.match(/\[(.*?)\]\((.*?)\)/)
      if (match) {
        const [_, text, url] = match
        return (
          <a key={index} href={url} onClick={onLinkClick}>
            {text}
          </a>
        )
      }
      return part
    })
  }

  return (
    <ReportWrapper>
      {applicantDetail ? (
        <ReportsBody>
          <ReportHeader label="Income Verification" CustomLogo={Logo} />
          <ReportTitle>Income verification</ReportTitle>
          <ApplicantDetail {...applicantDetail} />
          <Container>
            <ImageWrapper>
              <img src={ReportNotReady} alt="report not ready" />
            </ImageWrapper>
            <TiTle>{pageContent.title}</TiTle>
            <SubTitleBox>
              <SubTitle>{renderSubTitle()}</SubTitle>
            </SubTitleBox>
            {pageContent.ctaText && (
              <Button variant="text" size="small" onClick={onCtaClick}>
                {pageContent.ctaText}
              </Button>
            )}
          </Container>
        </ReportsBody>
      ) : (
        <Container>
          <ImageWrapper>
            <img src={ReportNotReady} alt="report not ready" />
          </ImageWrapper>
          <TiTle>{pageContent.title}</TiTle>
          <SubTitleBox>
            <SubTitle>{renderSubTitle()}</SubTitle>
          </SubTitleBox>
          {pageContent.ctaText && (
            <Button variant="text" size="small" onClick={onCtaClick}>
              {pageContent.ctaText}
            </Button>
          )}
        </Container>
      )}
    </ReportWrapper>
  )
}

export default IncomeVerificationErrorPage
