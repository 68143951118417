import {
  GET_CREDIT_PAYMENT_CALLED,
  GET_CREDIT_PAYMENT_REQUEST,
  GET_CREDIT_PAYMENT_SUCCESS,
  GET_CREDIT_PAYMENT_FAILED,
  SUBMIT_CREDIT_PAYMENT_CALLED,
  SUBMIT_CREDIT_PAYMENT_REQUEST,
  SUBMIT_CREDIT_PAYMENT_SUCCESS,
  SUBMIT_CREDIT_PAYMENT_FAILED,
} from "./constants"

export const getPayment = payload => ({
  type: GET_CREDIT_PAYMENT_CALLED,
  payload,
})

export const getPaymentRequest = payload => ({
  type: GET_CREDIT_PAYMENT_REQUEST,
  payload,
})

export const getPaymentSuccess = data => ({
  type: GET_CREDIT_PAYMENT_SUCCESS,
  data,
})

export const getPaymentFailed = payload => ({
  type: GET_CREDIT_PAYMENT_FAILED,
  payload,
})

export const submitPayment = payload => ({
  type: SUBMIT_CREDIT_PAYMENT_CALLED,
  payload,
})

export const submitPaymentRequest = payload => ({
  type: SUBMIT_CREDIT_PAYMENT_REQUEST,
  payload,
})

export const submitPaymentSuccess = data => ({
  type: SUBMIT_CREDIT_PAYMENT_SUCCESS,
  data,
})

export const submitPaymentFailed = payload => ({
  type: SUBMIT_CREDIT_PAYMENT_FAILED,
  payload,
})
