/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import startsWith from "lodash/startsWith"
import { MainContent } from "components/layout/main"
import { SUBSCRIPTION_PAYMENT, SUPPORT_HELP_REQUEST_DOCS_URL } from 'constants/route'
import {
  Section,
  SectionContainer,
  SectionHeader,
  SectionLogo,
  FlexCol,
  FlexBox,
} from "../../components/layout/section"
import { H1, H3, H4, Text, P } from "../../components/typography"
import { Link } from "../../components/buttons/buttons"
import { TrackerInstance as Tracker } from "../../helpers/tracker"
import { docPageList } from "../../constants/other-docs-consts"
import { UPLOAD_DOCUMENT_VIDEO } from "../../constants/route-consts"
import { IconTextBox } from "../../components/static-page/icon-text-box"
import FaqAccordion from "../../components/panel/faq-accordion"
import ModalConnect from "./getting-started-connect"

@ModalConnect
export default class RequestDocsPage extends React.Component {
  handleTrackerAndRoute(event, path) {
    Tracker.trackEvent(event)
    if (startsWith(path, "/")) {
      this.props.history.push(path)
    } else {
      window.open(path, "_blank")
    }
  }

  openRestrictionsModal() {
    Tracker.trackEvent("clickArcsProRestrictionsModal")
    this.props.actions.toggleProRestrictionsModal(true)
  }

  render() {
    const { authorize } = this.props.subscriptions
    return (
      <MainContent className="-w100">
        <Section className="splash requestDocs">
          <SectionLogo />
          {authorize.isExpires ? (
            <FlexCol className="splash-content" col="2" noMargin mCenter>
              <H1 size="2.6em" margin="0 0 15px" strong>
                We&apos;re sad to see you go
              </H1>
              <H3 size="1.6em" light margin="0" mCenter>
                You have lost access to all RentSpree PRO features.
              </H3>
              <Link
                id="proTryItFreeLink"
                onClick={() =>
                  this.handleTrackerAndRoute(
                    "clickReactivate",
                    SUBSCRIPTION_PAYMENT,
                  )
                }
                primary
                width="230px"
                padding="10px"
                margin="20px 0 10px">
                <Text size="1.2em">Reactivate Now</Text>
              </Link>
              <H4 mCenter mMargin="15px auto">
                Only $9.99/month
              </H4>
            </FlexCol>
          ) : (
            <FlexCol className="splash-content" col={2} noMargin mCenter>
              <H1 size="2.6em" strong maxWidth="400px" margin="0.67em 0 0">
                Access documents at your fingertips
              </H1>
              <Link
                id="proTryItFreeLink"
                onClick={() =>
                  this.handleTrackerAndRoute(
                    "clickReqDocsTryItFree",
                    SUBSCRIPTION_PAYMENT,
                  )
                }
                primary
                width="230px"
                padding="10px"
                margin="20px 0 10px">
                <Text size="1.2em">TRY IT FREE</Text>
              </Link>
              <H4 mCenter mMargin="15px auto">
                Get unlimited access for 1 month.
                <Link
                  id="proHaveCouponCodeLink"
                  onClick={() =>
                    this.handleTrackerAndRoute(
                      "clickReqDocsHaveCouponCode",
                      SUBSCRIPTION_PAYMENT,
                    )
                  }
                  hoverWhiteText
                  underlineTextLink
                  leftSpace="7px">
                  Have a coupon code?
                </Link>
              </H4>
              <P size="15px">
                <Link
                  id="proOpenRestrictionsModalLink"
                  onClick={() => this.openRestrictionsModal()}
                  inlineLink
                  text="Restrictions apply. Learn more here."
                />
              </P>
            </FlexCol>
          )}
        </Section>

        <Section padding="40px 15px 60px" className="diamondBg">
          <SectionContainer>
            <SectionHeader
              title="Turn questions into answers"
              subtitle="Verify application information by accessing renter documents"
            />
            <FlexBox>
              {docPageList.map(doc => (
                <FlexCol key={doc.name} col={3} tCol={3} mCol={1}>
                  <IconTextBox
                    title={doc.name}
                    subtitle={doc.subtitle}
                    icon={doc.icon}
                  />
                </FlexCol>
              ))}
            </FlexBox>
          </SectionContainer>
        </Section>

        <Section
          padding="0px 15px 60px"
          mPadding="0 0 30px"
          className="whiteBg videoSection">
          <SectionContainer display="block">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                title="Dashboard 2.0"
                src={UPLOAD_DOCUMENT_VIDEO}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </SectionContainer>
        </Section>

        <Section padding="40px 15px 30px">
          <SectionContainer>
            <SectionHeader title="Frequently Asked Questions" />

            <FaqAccordion name="requestDocsFaq" />

            <P size="1.2em">
              <Link
                id="goToSupportRequestDocsLink"
                inlineLink
                onClick={() =>
                  this.handleTrackerAndRoute(
                    "clickVisitHelpCenter",
                    SUPPORT_HELP_REQUEST_DOCS_URL,
                  )
                }
                text="Have other questions? Visit RentSpree's Help Center"
              />
            </P>
          </SectionContainer>
        </Section>
      </MainContent>
    )
  }
}
