import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectSaga from 'utils/inject-saga'
import injectReducer from 'utils/inject-reducer'

import {
  makeSelectActiveId,
  makeSelectRecipientList,
  selectFieldValidationResult,
  selectSignatureData,
  makeSelectShowLeavePopup,
  selectIsUploadingSignature,
  selectLoadingSavingFile,
  selectIsAcceptedConsent,
  selectLoadingUpdateConsent,
  selectUpdateConsentSuccess,
  selectEnvelopeError,
  makeSelectDoesEnvelopeExist,
  makeSelectEnvelopePDFPath,
  selectEnvelopeData,
  makeSelectUploadSignatureSuccess,
  makeSelectIsSignatureEdited,
  selectIsLoadingGeneratePdfFileURL,
} from 'containers/envelope/selectors'
import {
  dateSignedSave,
  alertPrintAndDownloadNotHaveURL,
  saveFiles,
  saveBufferFiles,
  updateEnvelopeConsent,
  editSignSignature,
  saveSignSignature,
  uploadSaveSignSignature,
  uploadEditSignSignature,
  triggerAutosaveEnvelope,
  resetEnvelopeReducer,
  generatePdfFileURL,
} from 'containers/envelope/actions'
import {
  setChildrenBox,
  setActive,
  resetActive,
  addChildren,
  setAssigneeToBox,
  setConfigBox,
  changeBoxPage,
} from 'containers/drag-n-drop/actions'
import { clearError } from 'containers/errors/actions'
import { selectProfile, selectIsFetchingUser } from 'containers/user/selectors'
import { getProfile } from 'legacy/actions/user.action'

import {
  selectEnvelopeId,
  selectIsFetchingApplication,
  selectIsFetchingEnvelope,
  selectIsFetchingUpdateEnvelope,
} from './selectors'
import { getEnvelopeCall, updateEnvelopeSign } from './actions'
import reducer from './reducer'
import saga from './saga'

export const mapStateToProps = createStructuredSelector({
  envelopeId: selectEnvelopeId(),
  isFetchingApplication: selectIsFetchingApplication(),
  isFetchingEnvelope: selectIsFetchingEnvelope(),
  envelope: selectEnvelopeData(),
  haveEnvelope: makeSelectDoesEnvelopeExist(),
  pdfPath: makeSelectEnvelopePDFPath(),
  isLoadingSavingFile: selectLoadingSavingFile,
  recipientsList: makeSelectRecipientList(),
  fieldValidation: selectFieldValidationResult(),
  activeId: makeSelectActiveId(),
  isUpdateEnvelope: selectIsFetchingUpdateEnvelope(),
  signatureData: selectSignatureData(),
  shouldHandleUnload: makeSelectShowLeavePopup(),
  isUploadingSignature: selectIsUploadingSignature,
  isAcceptedConsent: selectIsAcceptedConsent(),
  isLoadingUpdateConsent: selectLoadingUpdateConsent,
  updateConsentSuccess: selectUpdateConsentSuccess,
  error: selectEnvelopeError,
  profile: selectProfile,
  isFetchingUser: selectIsFetchingUser(),
  uploadSignatureSuccess: makeSelectUploadSignatureSuccess(),
  isSignatureEdited: makeSelectIsSignatureEdited(),
  isLoadingGeneratePdfFileURL: selectIsLoadingGeneratePdfFileURL,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getEnvelopeCall,
        updateEnvelopeSign,
        dateSignedSave,
        alertPrintAndDownloadNotHaveURL,
        saveFiles,
        saveBufferFiles,
        updateEnvelopeConsent,
        editSignSignature,
        saveSignSignature,
        uploadSaveSignSignature,
        uploadEditSignSignature,
        clearError,
        triggerAutosaveEnvelope,
        /* drag-n-drop actions */
        setChildrenBox,
        setActive,
        resetActive,
        addChildren,
        setAssigneeToBox,
        setConfigBox,
        changeBoxPage,
        getProfile,
        resetEnvelopeReducer,
        generatePdfFileURL,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({ key: 'lraSign', reducer })
export const withSaga = injectSaga({ key: 'lraSign', saga })

export const withLraSignReducerAndSaga = compose(withReducer, withSaga)
