import { useEffect } from "react"
import { PROJECT } from "containers/badge/constants"
import { callGetNewBadge } from "containers/badge/actions"
import { useDispatch, useSelector } from "react-redux"
import { useInjectSaga } from "utils/inject-saga"
import { selectAccessToken } from "containers/user/selectors"
import { useInjectReducer } from "utils/inject-reducer"
import reducer from "containers/badge/reducer"
import saga from "containers/badge/saga"

const useBadge = () => {
  const dispatch = useDispatch()
  useInjectSaga({ key: "badge", saga })
  useInjectReducer({ key: "badge", reducer })
  const accessToken = useSelector(selectAccessToken)
  useEffect(() => {
    if (accessToken) dispatch(callGetNewBadge({ project: PROJECT }))
  }, [])
  return null
}

export { useBadge }
