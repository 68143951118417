import { buildPath } from '@rentspree/path'

import { PROPERTY, SINGLE_PROPERTY } from 'constants/route'

/**
 * @typedef {Object} AgentInitPaymentSuccessViewModelResult
 * @property {() => void} onPrimaryButtonClick
 * @property {'new' | 'existing' | 'unknown'} landlordType
 */

/**
 * @returns {AgentInitPaymentSuccessViewModelResult}
 */
export const useViewModel = props => {
  const { history, location } = props
  const { propertyId, landlordType } = location.state ?? {}

  // Go to property button
  const onPrimaryButtonClick = () => {
    const url = propertyId ? buildPath(SINGLE_PROPERTY, { propertyId }) : buildPath(PROPERTY)
    history.push(url)
  }

  return {
    onPrimaryButtonClick,
    landlordType: landlordType ?? 'unknown',
  }
}
