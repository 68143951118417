import { object } from 'yup'

import {
  validateLength,
  validateZipCode,
  validateState,
  validateEmail,
  validateContactNumber,
} from 'legacy/constants/validations'

export const schema = () => ({
  mapper: {
    address: {
      street: '',
      city: '',
      state: '',
      zipcode: '',
    },
    email: '',
    firstName: '',
    hasAddress: '',
    lastName: '',
    middleName: '',
    mobileNumber: '',
    role: '',
    isNewTag: false,
  },
  validation: object().shape({
    address: object().shape({
      street: validateLength(false, 1, 100),
      city: validateLength(false, 1, 50),
      state: validateState(false),
      zipcode: validateZipCode(false),
    }),
    email: validateEmail(true),
    firstName: validateLength(false, 1, 50),
    lastName: validateLength(false, 1, 50),
    middleName: validateLength(false, 1, 50),
    mobileNumber: validateContactNumber(false),
  }),
})
