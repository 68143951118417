import { API_URL } from '../../../env'

export const DOCUMENTS_API = `${API_URL}/api/v2/people/contacts/:contactId/documents`

export const GET_DOCUMENTS_CALL = 'GET_DOCUMENTS_CALL'
export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST'
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS'
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE'

export const DOCUMENT_TYPE = {
  APPLICATION: 'application',
  REPORT: 'report',
  ESIGN: 'esign',
  REFERENCE_CHECK: 'reference_check',
  OTHER_DOCS: 'other_docs',
}

export const REFERENCE_CHECK_SUB_TYPE = {
  CURRENT_EMPLOYER: 'current_employer',
  FORMER_EMPLOYER: 'former_employer',
  CURRENT_LANDLORD: 'current_landlord',
  PREVIOUS_LANDLORD: 'previous_landlord',
  PERSONAL_REFERENCE1: 'personal_reference1',
  PERSONAL_REFERENCE2: 'personal_reference2',
}

export const OTHER_DOCS_SUB_TYPE = {
  PROOF_OF_INCOME: 'proof_of_income',
  BANK_STATEMENTS: 'bank_statements',
  TAX_RETURNS: 'tax_returns',
  W2S: 'w2s',
  GOVERNMENT_ISSUED_ID: 'government_issued_id',
  A_PHOTO_OF_EACH_PET: 'a_photo_of_each_pet',
  A_PET_APPLICATION: 'a_pet_application',
}
