import React from 'react'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'
import { B14, Hr } from '@rentspree/component-v2'
import DropdownMenuWithCircleHover from '@rentspree/component-v2/dist/menu-dropdown/dropdown-menu-with-circle-hover'
import AccordionCard from 'components/accordion'
import moment from 'moment'
import { ENVELOPE_STATUS } from 'containers/envelope/constants'
import RecipientsItem from './recipients-item'
import { ENVELOPE_STATUS as ENVELOPE_STATUS_MAPPING } from './constants'
import MORE_ICON from '../../images/icons/lease-agreement/more-icon.svg'

export const MinimalDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 20px;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 0px 20px;
    align-items: normal;
  }
`
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 0;
  @media (max-width: 991px) {
    width: auto;
  }
`
export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

export const CollapseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
`

export const CollapseIcon = styled.i`
  font-size: 22px;
  height: fit-content;
`

export const NameAndStatusContainer = styled.div`
  font-family: Source Sans Pro;
  width: 85%;
`

export const MinimalDetail = styled.div`
  text-align: end;
  @media (max-width: 991px) {
    &:first-child {
      text-align: start;
    }
  }
`
export const ContentContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${COLOR.borderGrey};
  display: flex;
  @media (max-width: 991px) {
    border-top: none;
    flex-direction: column;
    margin-top: 20px;
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 0;
  @media (max-width: 991px) {
    margin-top: 20px;
    width: auto;
  }
  .recipientsItem {
    border: none;
    border-bottom: solid 1px ${COLOR.borderGrey};
    margin: 0px;
    :last-child {
      border: none;
    }
  }
`

export const ListItems = styled(B14)`
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
  margin-bottom: 5px;
`

export const TitleText = styled(B14)`
  margin: 0px 0px 3px 0px;
  color: ${COLOR.textBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  @media (max-width: 991px) {
    margin: 0px;
  }
`
export const ContainerBlank = styled.div`
  display: flex;
  padding: 5px 60px 10px 20px;
`
export const EmailInline = styled(B14)`
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`

export const StatusText = styled(B14)`
  margin: 0;
  ${props => props.color && `color: ${props.color};`};
`

export const Divider = styled(Hr)`
  display: none;
  border: none;
  border-top: solid 1px ${COLOR.borderGrey};
  @media (max-width: 991px) {
    display: block;
  }
`

const generateDropdownMenuItem = onEdit => {
  // Currently Lease Record display for continue to lease builder only
  const menuItems = [
    {
      label: 'Finish setting up',
      onClick: onEdit,
    },
  ]

  return menuItems
}

// NOTE: This is temporary component to disguise
// Lease Agreement Record display like Envelope in Lease Detail Page

export const LeaseRecordDetailCard = ({ leaseAgreementRecord, onEdit }) => {
  const { _id, templateName, recipients = [], updatedAt } = leaseAgreementRecord

  const menuItems = generateDropdownMenuItem(onEdit)
  return (
    <AccordionCard
      id={_id}
      iconClass={`collapse-icon-envelope-${_id}`}
      collapseRotate={270}
      defaultRotate={90}
      mPadding="15px 0px"
      margin="10px 0 0 0"
      headerInfo={
        <MinimalDetailContainer data-testid="lease-record-detail-card">
          <LeftContainer>
            <CollapseIconContainer>
              <CollapseIcon className={`fas fa-chevron-right collapse-icon-envelope-${_id}`} />
            </CollapseIconContainer>
            <NameAndStatusContainer>
              <TitleText>{templateName || '-'}</TitleText>
              <StatusText
                margin="0"
                color={ENVELOPE_STATUS_MAPPING[ENVELOPE_STATUS.INPROGRESS].color}
              >
                {ENVELOPE_STATUS_MAPPING[ENVELOPE_STATUS.INPROGRESS].text}
              </StatusText>
            </NameAndStatusContainer>
          </LeftContainer>
          <RightContainer>
            <MinimalDetail>
              <TitleText>Recipients</TitleText>
              <B14 margin="0">{recipients.length}</B14>
            </MinimalDetail>
            <MinimalDetail>
              <TitleText>Last Activity</TitleText>
              <B14 margin="0">{moment(updatedAt).fromNow()}</B14>
            </MinimalDetail>
          </RightContainer>
          <DropdownMenuWithCircleHover
            ToggleComponent={() => <img src={MORE_ICON} alt="more-icon" />}
            menuItems={menuItems}
            margin="0 0 0 80px"
            customMobileStyle="position: absolute; margin: 0px; right: 32px;"
          />
        </MinimalDetailContainer>
      }
      contentInfo={
        <ContentContainer>
          <Divider />
          <ListContainer>
            <B14 weight="600" margin="0px" padding="10px 20px" color={COLOR.textBlack}>
              Recipients
            </B14>
            {recipients.map((recipient, idx) => (
              <RecipientsItem
                key={`${recipient.fullName}-${idx}`}
                envelopeStatus={ENVELOPE_STATUS.INPROGRESS}
                recipient={recipient}
              />
            ))}
          </ListContainer>
        </ContentContainer>
      }
    />
  )
}
