import TextInput from '@rentspree/component-v2/dist/input/text'
import React from 'react'
import { Row as BootstrapRow, Col as BootstrapCol } from 'react-bootstrap'
import styled from 'styled-components'

const Col = styled(BootstrapCol)`
  padding: 0px 5px;
`

const Row = styled(BootstrapRow)`
  padding: 0px 10px;
`

export const AssociationContactForm = props => {
  const {
    values = {},
    handleChange,
    handleBlur,
    errors = {},
    touched = {},
    inputEmailChange,
    isCreating,
  } = props
  const handleEmailChange = event => {
    handleChange(event)
    inputEmailChange(event.target?.value)
  }

  /* TODO: Need to refactor variable */
  return (
    <>
      <Row>
        <Col md={4}>
          <TextInput
            id="create-association-contact-first-name-input"
            name="firstName"
            label="First Name"
            value={values?.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.firstName && errors?.firstName}
          />
        </Col>
        <Col md={4}>
          <TextInput
            id="create-association-contact-middle-name-input"
            name="middleName"
            label="Middle Name"
            value={values?.middleName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.middleName && errors?.middleName}
          />
        </Col>
        <Col md={4}>
          <TextInput
            id="create-association-contact-last-name-input"
            name="lastName"
            label="Last Name"
            value={values?.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.lastName && errors?.lastName}
            showLabelWhenDisabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextInput
            id="create-association-contact-email-input"
            name="email"
            label="Email"
            value={values?.email}
            onChange={handleEmailChange}
            onBlur={handleBlur}
            error={touched?.email && errors?.email}
            isRequired
            disabled={!isCreating}
            showLabelWhenDisabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextInput
            id="create-association-contact-mobile-number-input"
            name="mobileNumber"
            label="Mobile"
            value={values?.mobileNumber}
            onBlur={handleBlur}
            error={touched?.mobileNumber && errors?.mobileNumber}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </>
  )
}

export default AssociationContactForm
