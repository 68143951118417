import {USER_PREFERENCE_API_V2} from "constants/route"
import * as sentry from "@sentry/browser"
import { apiInstance } from "./api-interceptor"

export const getUserPreference = async () => {
  try {
    const { result } = await apiInstance.get(USER_PREFERENCE_API_V2)
    return result
  } catch (error) {
    sentry.captureException(error)
    return undefined
  }
}
