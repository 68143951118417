import { compose } from "redux"
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"

import reducer from "./reducer"
import saga from "./saga"

export const withAgreementReducer = injectReducer({ key: "agreement", reducer })
export const withAgreementSaga = injectSaga({ key: "agreement", saga })

export const withAgreementReducerAndSaga = compose(
  withAgreementReducer,
  withAgreementSaga,
)
