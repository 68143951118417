export class SourceUrl {
  imageUrl

  imageUrlEncode

  constructor(imageUrl) {
    this.imageUrl = imageUrl
    this.imageUrlEncode = Buffer.from(imageUrl).toString("base64")
  }

  getImageSource(encoded = false, extension) {
    if (encoded) {
      return [this.imageUrlEncode, extension].filter(text => text).join(".")
    }
    return [`plain/${this.imageUrl}`, extension].filter(text => text).join("@")
  }
}
