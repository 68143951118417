import { buildPath } from '@rentspree/path'
import * as sentry from '@sentry/browser'

import { apiInstance } from 'utils/api-interceptor'

export const GET_MY_USER_INVITATION_PATH = '/api/v2/users/user-invitations/me'

/**
 * @typedef {object} GetMyInvitationParams
 *
 * @property {1 | -1 | undefined} queryParams.sort if there are multiple invitations, -1 will get the latest one and 1 will get the oldest one
 */

/**
 * getMyUserInvitation get my user invitation using my userId.
 *
 * @param {GetMyInvitationParams=} queryParams the optional API query param
 * @returns {Promise<{result: {inviterUserId: string, inviteeEmail: string, invitedFeature: string, registeredUserId: string, inviter: {firstName: string, lastName: string}}}>}
 */
export const getMyUserInvitation = async queryParams => {
  try {
    const result = await apiInstance.get(buildPath(GET_MY_USER_INVITATION_PATH, {}, queryParams))
    return result
  } catch (error) {
    sentry.captureException(error)
    return {}
  }
}
