import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton } from '@rentspree/component-2023.components.atoms.icon-button'
import classNames from 'classnames'
import React from 'react'
import './style.scss'

export const CollapseIconButton = ({ expand, ...props }) => {
  return (
    <IconButton color="black" {...props}>
      <ExpandMoreIcon className={classNames('collapse-icon', expand ? 'expanded' : 'collapsed')} />
    </IconButton>
  )
}
