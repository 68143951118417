import produce from 'immer'

import {
  SETUP_CREATE_PROPERTY,
  OPEN_CREATE_PROPERTY,
  CLOSE_CREATE_PROPERTY,
  GO_BACK_STEP_CREATE_PROPERTY,
  GO_NEXT_STEP_CREATE_PROPERTY,
  RESET_CREATE_PROPERTY,
  FETCH_CREATE_PROPERTY_SUCCESS,
  FETCH_VERIFY_PROPERTY_ADDRESS_REQUEST,
  FETCH_VERIFY_PROPERTY_ADDRESS_SUCCESS,
  FETCH_VERIFY_PROPERTY_ADDRESS_FAILURE,
  RESET_ADDRESS_VERIFICATION,
  FETCH_PROPERTY_BY_ADDRESS_REQUEST,
  FETCH_PROPERTY_BY_ADDRESS_SUCCESS,
  RESET_PROPERTY_BY_ADDRESS,
  FETCH_CREATE_PROPERTY_REQUEST,
} from 'v3/containers/create-property-form/actions'
import { PAGE_NAME } from 'v3/containers/create-property-form/constants'

export const initialState = {
  isOpen: false,
  isLandlordInfoRequired: true,
  currentPageIndex: 0,
  pages: [PAGE_NAME.PROPERTY_ADDRESS, PAGE_NAME.ROLE],
  location: undefined,
  onSuccessCallback: undefined,
  addressVerification: {
    isVerifyingPropertyAddress: false,
    addressVerification: {
      isVerified: undefined,
      standardizedAddress: undefined,
      inputAddress: undefined,
    },
  },
  propertyByAddress: {
    isGettingPropertyByAddress: false,
    isExistingProperty: undefined,
  },
  createProperty: {
    isCreatingProperty: false,
    createdProperty: undefined,
  },
  preFillFormValues: {
    street: '',
    unitNumber: '',
    city: '',
    state: '',
    zip: '',
    userRoleForProperty: '',
    landlordProfile: {
      firstName: '',
      lastName: '',
      email: '',
    },
  },
}

/* eslint-disable no-param-reassign */
// eslint-disable-next-line default-param-last
export const createPropertyStateReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case SETUP_CREATE_PROPERTY:
        draft = { ...draft, ...payload.options }
        break
      case OPEN_CREATE_PROPERTY:
        draft.isOpen = true
        break
      case CLOSE_CREATE_PROPERTY:
        draft.isOpen = false
        break
      case GO_NEXT_STEP_CREATE_PROPERTY:
        draft.currentPageIndex += 1
        break
      case GO_BACK_STEP_CREATE_PROPERTY:
        draft.currentPageIndex -= 1
        break
      case RESET_CREATE_PROPERTY:
        draft = { ...initialState }
        break
      // address verification
      case FETCH_VERIFY_PROPERTY_ADDRESS_REQUEST:
        draft.addressVerification.isVerifyingPropertyAddress = true
        break
      case FETCH_VERIFY_PROPERTY_ADDRESS_SUCCESS:
        draft.addressVerification.addressVerification = payload.addressVerification
        draft.addressVerification.isVerifyingPropertyAddress = false
        break
      case FETCH_VERIFY_PROPERTY_ADDRESS_FAILURE:
        draft.addressVerification.isVerifyingPropertyAddress = false
        break
      case RESET_ADDRESS_VERIFICATION:
        draft.addressVerification = initialState.addressVerification
        break
      // property by address
      case FETCH_PROPERTY_BY_ADDRESS_REQUEST:
        draft.propertyByAddress.isGettingPropertyByAddress = true
        break
      case FETCH_PROPERTY_BY_ADDRESS_SUCCESS:
        draft.propertyByAddress.isGettingPropertyByAddress = false
        draft.propertyByAddress.isExistingProperty = payload.isExistingProperty
        break
      case RESET_PROPERTY_BY_ADDRESS:
        draft.propertyByAddress.isGettingPropertyByAddress = initialState.isGettingPropertyByAddress
        draft.propertyByAddress.isExistingProperty = initialState.isExistingProperty
        break
      case FETCH_CREATE_PROPERTY_REQUEST:
        draft.createProperty.isCreatingProperty = true
        break
      case FETCH_CREATE_PROPERTY_SUCCESS:
        draft.createProperty.isCreatingProperty = false
        draft.createProperty.createdProperty = payload.createdProperty
        break
      default:
        draft = state
        break
    }
    return draft
  })
