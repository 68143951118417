import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { USER_ROLE } from '../../constants'
import { getRentalPaymentList } from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'
import {
  makeSelectRentalPaymentListData,
  makeSelectRentalPaymentListStatus,
} from '../redux/selectors'

export const RENT_PAYMENT_DEFAULT_QUERY_OPTIONS = {
  page: 1,
  perPage: 8,
  role: USER_ROLE.PAYEE,
  filter: {},
}

export const useRentalPaymentList = (initialOptions, propertyId) => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const data = useSelector(makeSelectRentalPaymentListData())
  const status = useSelector(makeSelectRentalPaymentListStatus())

  React.useEffect(() => {
    dispatch(
      getRentalPaymentList({
        ...RENT_PAYMENT_DEFAULT_QUERY_OPTIONS,
        ...initialOptions,
        propertyId,
      }),
    )
  }, [])

  return {
    fetchData: ({ changePage = true, ...options }) => {
      dispatch(
        getRentalPaymentList({
          ...RENT_PAYMENT_DEFAULT_QUERY_OPTIONS,
          ...options,
          changePage,
          propertyId,
        }),
      )
    },
    data,
    status,
  }
}
