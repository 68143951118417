import React from "react"
import styled from "styled-components"

const InfoTextDiv = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 22px;
  
  @media (max-width: 991px){
    margin-top: 0px;
  }
`

export const InfoText = ({ text= "" }) => <InfoTextDiv>{text}</InfoTextDiv>