import LightbulbIcon from '@mui/icons-material/Lightbulb'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import InfoPanel from '@rentspree/component-2023.components.organisms.info-panel'
import React from 'react'

import useMobile from 'hooks/use-mobile'
import { COMPONENT_SIZE_M_MIN_WIDTH } from 'v3/constants/breakpoint'
import { IMG_GIRL_RAISE_HAND, IMG_PLANE } from 'v3/constants/images'

import './styles.scss'

export const ValuePropositionPanel = ({ valuePropositionMessage }) => {
  const isMSize = useMobile(COMPONENT_SIZE_M_MIN_WIDTH)

  return (
    <div className="agt-init-payment-tip-container">
      {isMSize ? (
        <InfoPanel severity="primary" customIcon={<LightbulbIcon />}>
          {valuePropositionMessage}
        </InfoPanel>
      ) : (
        <div className="agt-init-payment-tip">
          <img src={IMG_GIRL_RAISE_HAND} alt="img-girl-raise-hand" />
          <Typography variant="body-medium">{valuePropositionMessage}</Typography>
          <img src={IMG_PLANE} alt="img-plane" />
        </div>
      )}
    </div>
  )
}
