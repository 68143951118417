import React from 'react'
import isEmpty from 'lodash/isEmpty'
import range from 'lodash/range'
import * as Styled from './pagination-style'

export default class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pager: {},
    }
  }

  componentWillMount() {
    this.setPager()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pagination !== this.props.pagination) {
      this.setPager()
    }
  }

  setPager(toPage = null) {
    if (!isEmpty(this.props.pagination)) {
      const { total, perPage, maxPage } = this.props.pagination
      if (!total) {
        this.setState({ pager: {} })
        return
      }
      const page = toPage || this.props.pagination.page
      const pager = this.getPager(total, page, perPage, maxPage)
      this.setState({ pager })
    }
  }

  getPager(totalItems, currentPageParam, perPageParam, maxPageParam) {
    const currentPage = currentPageParam || 1
    const perPage = perPageParam || 10
    const maxPage = maxPageParam || Math.ceil(totalItems / perPage)
    let startPage = 1
    let endPage = 1
    if (maxPage <= 5) {
      startPage = 1
      endPage = maxPage
    } else if (currentPage <= 3) {
      startPage = 1
      endPage = 5
    } else if (currentPage + 2 >= maxPage) {
      startPage = maxPage - 4
      endPage = maxPage
    } else {
      startPage = currentPage - 2
      endPage = currentPage + 2
    }

    const startIndex = (currentPage - 1) * perPage
    const endIndex = Math.min(startIndex + perPage - 1, totalItems - 1)
    const pages = range(startPage, endPage + 1)
    const pagination = {
      totalItems,
      currentPage,
      perPage,
      maxPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    }
    return pagination
  }

  changePage(toPage) {
    this.setPager(toPage)
    this.props.handleChangePage(toPage)
  }

  render() {
    const { isFetching } = this.props
    const { pager } = this.state
    if (!pager.pages || pager.pages.length <= 1) {
      return null
    }

    return (
      <Styled.PaginationDiv>
        <Styled.Pager className="list-unstyled">
          <li>
            <Styled.PaginationBtn
              onClick={() => this.changePage(pager.currentPage - 1)}
              disabled={isFetching || pager.currentPage === 1}
            >
              <i className="icon-arrow-left" />
            </Styled.PaginationBtn>
          </li>
          {pager.pages.map(page => (
            <li key={page}>
              <Styled.PaginationPageBtn
                onClick={() => this.changePage(page)}
                disabled={isFetching || pager.currentPage === page}
                className={pager.currentPage === page && 'current'}
              >
                {page}
              </Styled.PaginationPageBtn>
            </li>
          ))}
          <li>
            <Styled.PaginationBtn
              onClick={() => this.changePage(pager.currentPage + 1)}
              disabled={isFetching || pager.currentPage === pager.maxPage}
              longBtn
            >
              <i className="icon-arrow-right" />
            </Styled.PaginationBtn>
          </li>
        </Styled.Pager>
      </Styled.PaginationDiv>
    )
  }
}
