import {
  validateOneTimePayment,
  validateRecurringPayment,
} from 'v3/containers/overhaul-rent-payment/pages/payment-details/validators'

export const validatePayment = payment => {
  /*
   * Allows the caller to indiscriminately pass in a payment, and
   * for the (required) type field to decide which validation method
   * it should be run through
   *
   * This is in a separate utils.js file for test mocking, it's hard to mock function
   * references within the same module.
   */
  const { type } = payment
  let isValid = false

  const typeValidationMap = {
    recurring: validateRecurringPayment,
    oneTime: validateOneTimePayment,
  }

  const validationMethod = typeValidationMap?.[type]

  if (validationMethod) {
    isValid = validationMethod(payment)
  }

  return isValid
}
