import React from 'react'
import B16 from '@rentspree/component-v2/dist/typography/b16'

import RefundModal from 'components/templates/refund-modal'

import { REFUND_MESSAGE } from './constants'

const TenantScreeningRefundModal = ({
  isOpen,
  onClose,
  onClickConfirm,
  isLoading,
  remark,
  payerType,
}) => {
  const confirmText = 'Ok, I understand'

  return (
    <RefundModal
      isOpen={isOpen}
      onClickConfirm={onClickConfirm}
      isLoading={isLoading}
      title="Notice"
      confirmText={confirmText}
      onClose={onClose}
      modalSize="small"
    >
      <B16 mt="15" weight={400}>
        {payerType
          ? REFUND_MESSAGE[payerType][remark] || REFUND_MESSAGE[payerType].DEFAULT
          : REFUND_MESSAGE[remark] || REFUND_MESSAGE.DEFAULT}
      </B16>
    </RefundModal>
  )
}

export default TenantScreeningRefundModal
