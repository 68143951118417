/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react"
import get from "lodash/get"
import classnames from "classnames/bind"
import styles from "legacy/components/request/left-content/left-content.scss"

const c = classnames.bind(styles)

const ERROR = {
  INCORRECT_ADDRESS: {
    MSG_1: "Incorrect property address. Please",
    MSG_2:
      "to re-enter the address or change the address on zipForm and try again.",
  },
  NO_PROPERTY: {
    MSG_1: "No property address associated with this transaction. Please",
    MSG_2:
      "to re-enter the address or change the address on zipForm and refresh the screen.",
  },
}

export const Errors = ({ onClick, type }) => (
  <p className={c("errorText")}>
    {get(ERROR, [type, "MSG_1"])}&nbsp;
    <a
      id="dashboardAddNewPropertyBtn"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={-1}>
      click here
    </a>
    &nbsp;{get(ERROR, [type, "MSG_2"])}
  </p>
)
