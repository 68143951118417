import styled from "styled-components"
import { COLOR } from "styles/settings"

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${COLOR.bgGrey};
  #rentalInsuranceListFilter {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      display: none;
    }
  }
`
