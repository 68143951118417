import activeHomeIcon from "images/icons/property/small-green-home.png"
import allHomeIcon from "images/icons/property/small-black-home.png"
import archivedHomeIcon from "images/icons/property/small-grey-home.png"

export const GET_PROPERTY_LIST_REQUEST = "GET_PROPERTY_LIST_REQUEST"
export const GET_PROPERTY_LIST_SUCCESS = "GET_PROPERTY_LIST_SUCCESS"
export const GET_PROPERTY_LIST_FAILED = "GET_PROPERTY_LIST_FAILED"
export const CLEAR_PROPERTY_LIST = "CLEAR_PROPERTY_LIST"
export const GET_PROPERTY_DROP_DOWN_REQUEST = "GET_PROPERTY_DROP_DOWN_REQUEST"
export const GET_PROPERTY_DROP_DOWN_SUCCESS = "GET_PROPERTY_DROP_DOWN_SUCCESS"
export const GET_PROPERTY_DROP_DOWN_FAILED = "GET_PROPERTY_DROP_DOWN_FAILED"

// TODO: replace static image to Icon from component V2 once the icons are ready for application list
export const FILTER_PROPERTY_LIST_OPTIONS = [
  {
    label: "All Listings",
    value: "all",
    icon: allHomeIcon,
  },
  {
    label: "Active Listings",
    value: "active",
    icon: activeHomeIcon,
  },
  {
    label: "Archived Listings",
    value: "archived",
    icon: archivedHomeIcon,
  },
]

export const FILTER_PROPERTY = {
  ALL: "all",
  ACTIVE: "active",
  ARCHIVED: "archived",
}
