import { matchPath } from "react-router-dom"
import { MOBILE_WIDTH } from "styles/settings"
import toNumber from "lodash/toNumber"

export const isGotoNewLocation = ({
  pathname,
  destinationPath,
  lastLocationPath,
}) =>
  !isGotoCurrentLocation(pathname, destinationPath) &&
  !isGotoLastLocation(lastLocationPath, destinationPath)

const isGotoCurrentLocation = (pathname, destinationPath) =>
  matchPath(pathname, destinationPath)?.isExact

const isGotoLastLocation = (lastLocationPath, destinationPath) =>
  matchPath(lastLocationPath, destinationPath)?.isExact

export const makeResizeListener = () => () => {
  try {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  } catch (err) {
    console.error(err)
  }
}

export const isDesktopDimension = ({ width }) => {
  const mobileWidth = MOBILE_WIDTH.replace("px", "") || "0"
  const isDesktop = width > toNumber(mobileWidth)
  return isDesktop
}
