import React from "react"
import * as Styled from "./text-style"

export const H1 = ({ children, ...props }) => (
  <Styled.h1 {...props}>{children}</Styled.h1>
)

export const H2 = ({ children, ...props }) => (
  <Styled.h2 {...props}>{children}</Styled.h2>
)

export const H3 = ({ children, ...props }) => (
  <Styled.h3 {...props}>{children}</Styled.h3>
)

export const H4 = ({ children, ...props }) => (
  <Styled.h4 {...props}>{children}</Styled.h4>
)
