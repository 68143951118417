export const RENTAL_PAYMENT_API = '/api/v2/rental-payments'
export const PAYMENT_ACCOUNTS_API = '/api/v2/payment-accounts'

export const IDENTITY_VERIFICATION_API = `${PAYMENT_ACCOUNTS_API}/payees/identity-verification`

export const FETCH_RENTAL_PAYMENTS_API = RENTAL_PAYMENT_API

export const CALCULATE_RISK_IDENTITY_VERIFICATION_API = `${IDENTITY_VERIFICATION_API}/calculate-risk`

export const GET_PAYEE_RISK_LEVEL_API = `${PAYMENT_ACCOUNTS_API}/payees/risk-level`
