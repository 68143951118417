import { createSelector } from 'reselect'
import get from 'lodash/get'
import { getDate } from 'legacy/helpers/expired-credit-report'
import { initialState } from './reducer'
import { STATUS } from '../../legacy/constants/credit-report-consts'

const getRentalDetail = state => get(state, 'application.rentalDetail', {})
const getApplicationDetail = state => get(state, 'application.applicationDetail', {})
const getShareReport = state => get(state, 'shareReport', initialState)
const getUser = state => get(state, 'user', {})

const selectShareReport = createSelector(getShareReport, state => state)

const selectUser = createSelector(getUser, state => state)

export const selectProfile = createSelector(selectUser, state => get(state, 'profile', {}))

export const selectEmail = createSelector(selectProfile, state => get(state, 'email', {}))

export const selectReportAvailable = createSelector(selectShareReport, state =>
  get(state, 'reportAvailable'),
)

export const selectDefaultShareOption = createSelector(selectShareReport, state =>
  get(state, 'defaultShareOption', {}),
)

export const selectIsLoading = createSelector(selectShareReport, state => get(state, 'isLoading'))

export const selectIsError = createSelector(selectShareReport, state => get(state, 'isError'))

export const selectIsShowShareModal = createSelector(selectShareReport, state =>
  get(state, 'isShowShareModal'),
)

// TODO: should use shared selectors
export const selectIsCreditReportExpired = createSelector(getRentalDetail, state =>
  get(state, 'credit_report.isExpired'),
)

export const selectIsIDMAExpired = createSelector(
  getRentalDetail,
  state =>
    get(state, 'credit_report.isIDMAExpired') &&
    get(state, 'credit_report.status', '') !== STATUS.OPENED,
)

export const selectExpiredDate = createSelector(getRentalDetail, state =>
  getDate(get(state, 'credit_report.generated_at')),
)

export const selectApplicationType = createSelector(getApplicationDetail, state =>
  get(state, 'type'),
)

export const selectFormValues = createSelector(selectShareReport, state => get(state, 'formValues'))
