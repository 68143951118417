import { takeLatest, all, put, select, call } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'

import tracker from 'tracker'
import { EVENT_ACCEPT_DENY, RENT_PAYMENT, LEASE_AGREEMENTS, START_FROM } from 'tracker/const'

import { RENT_PAYMENT_REQUEST_PAGE, LEASE_AGREEMENT_REQUEST } from 'constants/route'
import { acceptAgreementAPI, getAgreementContentAPI } from 'containers/agreement/saga'
import { apiInstance } from 'utils/api-interceptor'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import { addToast } from 'containers/toast/actions'
import {
  NEXT_ACTION_TYPES,
  OPEN_ASSIGN_PROPERTY_MODAL,
} from 'containers/tenant-screening/assign-property/constants'
import { selectAcceptDeny, selectBaseEventProperty } from './selectors'
import {
  acceptDenyApiState,
  clearAcceptDenyModal,
  agreeDisclaimerApiState,
  getDisclaimerContentApiState,
  showSecurityDepositModal,
} from './actions'
import {
  ACCEPT_DENY_API,
  ACCEPT_DENY_TYPE,
  ACCEPT_DENY_CALL,
  GET_DISCLAIMER_CONTENT_CALL,
  AGREE_DISCLAIMER_CALL,
  OPEN_ACCEPT_DENY_MODAL,
  CHANGE_ACCEPT_DENY_TYPE,
  COMPLETE_ACCEPT_ON_CONDITION_FORM,
  COMPLETE_DENY_FORM,
  AGREE_DISCLAIMER_SUCCESS,
  ACCEPT_DENY_AGREEMENT_TYPE,
  ACCEPT_DENY_TOAST_MESSAGE,
  CONTINUE_TO_ESIGN,
  CONTINUE_TO_RENT_PAYMENT,
} from './constants'

const {
  openAcceptDenyModal,
  clickAcceptMenu,
  clickAcceptOnConditionMenu,
  clickDenyMenu,
  submitAcceptCondition,
  submitDenyReason,
  sendWelcomeEmail,
  sendConditionEmail,
  sendDenialEmail,
  agreeDisclaimer,
} = EVENT_ACCEPT_DENY

export const submitAcceptDenyApi = ({ rentalSubmissionId, body }) =>
  apiInstance.post(buildPath(ACCEPT_DENY_API, { rentalSubmissionId }), body)

const generateAcceptDenyBody = (type, acceptOnConditionForm, denyForm) => {
  const { ACCEPT, ACCEPT_ON_CONDITION, DENY } = ACCEPT_DENY_TYPE
  const types = {
    [ACCEPT]: {},
    [ACCEPT_ON_CONDITION]: acceptOnConditionForm,
    [DENY]: denyForm,
  }
  return { result: type, condition: types[type] }
}

const generateAcceptDenySuccessAction = ({ type, propertyId, tenantEmail, showToastOnly }) => {
  if (!showToastOnly) {
    if (type === ACCEPT_DENY_TYPE.ACCEPT || type === ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION) {
      return showSecurityDepositModal({
        propertyId,
        tenantEmail,
        securityDepositClickFrom: type,
      })
    }
  }

  return addToast({
    bodyMessage: ACCEPT_DENY_TOAST_MESSAGE[type],
    status: 'success',
  })
}

export function* acceptDenySaga({ payload }) {
  yield put(acceptDenyApiState.request())
  const { rentalSubmission, type, acceptOnConditionForm, denyForm, propertyId } = yield select(
    selectAcceptDeny,
  )
  const body = generateAcceptDenyBody(type, acceptOnConditionForm, denyForm)
  try {
    const response = yield call(submitAcceptDenyApi, {
      rentalSubmissionId: rentalSubmission.id,
      body,
    })
    yield put(acceptDenyApiState.success(response))
    yield put(clearAcceptDenyModal())
    yield put(
      generateAcceptDenySuccessAction({
        type,
        propertyId,
        tenantEmail: rentalSubmission.email,
        showToastOnly: !payload.isRentPaymentFeatureEnabled,
      }),
    )
  } catch (error) {
    yield put(acceptDenyApiState.failure(error.status))
    yield put(
      addToast({
        bodyMessage: ACCEPT_DENY_TOAST_MESSAGE.ERROR,
        status: 'error',
      }),
    )
  }
}

export function* getDisclaimerContentSaga() {
  yield put(getDisclaimerContentApiState.request())
  try {
    const response = yield call(getAgreementContentAPI, {
      type: ACCEPT_DENY_AGREEMENT_TYPE,
    })
    yield put(getDisclaimerContentApiState.success(response))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(getDisclaimerContentApiState.failure(err))
  }
}

export function* agreeDisclaimerSaga({ payload }) {
  yield put(agreeDisclaimerApiState.request())
  try {
    yield call(acceptAgreementAPI, payload)
    yield put(agreeDisclaimerApiState.success())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(agreeDisclaimerApiState.failure(err))
  }
}

export function* trackingEventSaga({ payload, type }) {
  let eventName
  let eventProperty = yield select(selectBaseEventProperty)
  switch (type) {
    case OPEN_ASSIGN_PROPERTY_MODAL: {
      if (
        [NEXT_ACTION_TYPES.ACCEPT_DENY, NEXT_ACTION_TYPES.ACCEPT_DENY_FROM_TABLE].includes(
          payload.nextAction,
        )
      ) {
        eventProperty = {
          rental_id: payload.nextActionProps.rentalSubmission.id,
          applicant: payload.nextActionProps.rentalSubmission.email,
          property_id: null,
          click_from: payload.nextActionProps.clickFrom,
          screening_without_property: true,
          click_from_widget: payload.nextActionProps.clickFromWidget,
        }

        if (payload.nextActionProps.isSharedReport) {
          eventProperty.is_shared_report = true
        }
        if (payload.nextActionProps.screeningResult) {
          eventProperty.accept_deny_type = payload.nextActionProps.screeningResult
        } else {
          eventProperty.is_first_time = true
        }
        eventName = openAcceptDenyModal
      }
      break
    }
    case OPEN_ACCEPT_DENY_MODAL: {
      if (payload.clickFrom !== EVENT_ACCEPT_DENY.CLICK_FROM.ASSIGN_PROPERTY) {
        eventProperty = {
          rental_id: payload.rentalSubmission.id,
          applicant: payload.rentalSubmission.email,
          property_id: payload.propertyId,
          click_from: payload.clickFrom,
          screening_without_property: false,
          click_from_widget: payload.clickFromWidget,
        }

        if (payload.isSharedReport) {
          eventProperty.is_shared_report = true
        }
        if (payload.screeningResult) {
          eventProperty.accept_deny_type = payload.screeningResult
        } else {
          eventProperty.is_first_time = true
        }
        eventName = payload.dropdownButtonEventName || openAcceptDenyModal
      }
      break
    }
    case CHANGE_ACCEPT_DENY_TYPE: {
      const clickMenuEvents = {
        [ACCEPT_DENY_TYPE.ACCEPT]: clickAcceptMenu,
        [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]: clickAcceptOnConditionMenu,
        [ACCEPT_DENY_TYPE.DENY]: clickDenyMenu,
      }
      eventName = clickMenuEvents[payload.acceptDenyType]
      break
    }
    case COMPLETE_ACCEPT_ON_CONDITION_FORM: {
      eventName = submitAcceptCondition
      break
    }
    case COMPLETE_DENY_FORM: {
      eventName = submitDenyReason
      break
    }
    case ACCEPT_DENY_CALL: {
      const { type: acceptDenyType } = yield select(selectAcceptDeny)
      const sendEmailEvents = {
        [ACCEPT_DENY_TYPE.ACCEPT]: sendWelcomeEmail,
        [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]: sendConditionEmail,
        [ACCEPT_DENY_TYPE.DENY]: sendDenialEmail,
      }
      eventName = sendEmailEvents[acceptDenyType]
      break
    }
    case AGREE_DISCLAIMER_SUCCESS: {
      const { type: acceptDenyType } = yield select(selectAcceptDeny)
      eventName = agreeDisclaimer
      eventProperty.accept_deny_type = acceptDenyType
      break
    }
    default:
  }
  if (eventName) {
    yield call([tracker, 'trackEvent'], eventName, eventProperty)
  }
}

export function* continueToRentPaymentSaga({ payload = {} }) {
  yield put(push(`${RENT_PAYMENT_REQUEST_PAGE}${payload.queryString || ''}`))

  yield call([tracker, 'trackEvent'], RENT_PAYMENT.EVENT_NAME.START_COLLECTING_RENT, {
    start_from: START_FROM.ACCEPT_EMAIL_SENT_MODAL,
  })
}

export function* continueToESignSaga({ payload: propertyId }) {
  const continuePath = yield call(buildPath, LEASE_AGREEMENT_REQUEST, {
    propertyId,
  })

  yield put(push(continuePath))

  yield call([tracker, 'trackEvent'], LEASE_AGREEMENTS.CREATE_LEASE, {
    click_from: START_FROM.ACCEPT_EMAIL_SENT_MODAL,
  })
}

export function* watchAcceptDenyCall() {
  yield takeLatest(ACCEPT_DENY_CALL, acceptDenySaga)
}

export function* watchGetDisclaimerContentCall() {
  yield takeLatest(GET_DISCLAIMER_CONTENT_CALL, getDisclaimerContentSaga)
}

export function* watchAgreeDisclaimerCall() {
  yield takeLatest(AGREE_DISCLAIMER_CALL, agreeDisclaimerSaga)
}

export function* watchTrackingEvent() {
  yield takeLatest(
    [
      OPEN_ASSIGN_PROPERTY_MODAL,
      OPEN_ACCEPT_DENY_MODAL,
      CHANGE_ACCEPT_DENY_TYPE,
      COMPLETE_ACCEPT_ON_CONDITION_FORM,
      COMPLETE_DENY_FORM,
      ACCEPT_DENY_CALL,
      AGREE_DISCLAIMER_SUCCESS,
    ],
    trackingEventSaga,
  )
}

export function* watchContinueToRentPayment() {
  yield takeLatest(CONTINUE_TO_RENT_PAYMENT, continueToRentPaymentSaga)
}

export function* watchContinueToESign() {
  yield takeLatest(CONTINUE_TO_ESIGN, continueToESignSaga)
}

export function* rootSaga() {
  yield all([
    watchAcceptDenyCall(),
    watchGetDisclaimerContentCall(),
    watchAgreeDisclaimerCall(),
    watchTrackingEvent(),
    watchContinueToRentPayment(),
    watchContinueToESign(),
  ])
}

export default rootSaga
