export const CLEAR_LISTING_SYNDICATION = 'CLEAR_LISTING_SYNDICATION'

export const GET_LISTING_SYNDICATION_CALL = 'GET_LISTING_SYNDICATION_CALL'
export const GET_LISTING_SYNDICATION_REQUEST = 'GET_LISTING_SYNDICATION_REQUEST'
export const GET_LISTING_SYNDICATION_SUCCESS = 'GET_LISTING_SYNDICATION_SUCCESS'
export const GET_LISTING_SYNDICATION_FAILURE = 'GET_LISTING_SYNDICATION_FAILURE'

export const TOGGLE_LISTING_SYNDICATION_CALL = 'TOGGLE_LISTING_SYNDICATION_CALL'
export const TOGGLE_LISTING_SYNDICATION_ON_PROPERTY_OVERVIEW_CALL =
  'TOGGLE_LISTING_SYNDICATION_ON_PROPERTY_OVERVIEW_CALL'
export const BLOCK_ENABLE_INVALID_CONTACT_LISTING_SYNDICATION =
  'BLOCK_ENABLE_INVALID_CONTACT_LISTING_SYNDICATION'
export const CREATE_LISTING_SYNDICATION_REQUEST = 'CREATE_LISTING_SYNDICATION_REQUEST'
export const CREATE_LISTING_SYNDICATION_SUCCESS = 'CREATE_LISTING_SYNDICATION_SUCCESS'
export const CREATE_LISTING_SYNDICATION_FAILURE = 'CREATE_LISTING_SYNDICATION_FAILURE'
export const ENABLE_LISTING_SYNDICATION_REQUEST = 'ENABLE_LISTING_SYNDICATION_REQUEST'
export const ENABLE_LISTING_SYNDICATION_SUCCESS = 'ENABLE_LISTING_SYNDICATION_SUCCESS'
export const ENABLE_LISTING_SYNDICATION_FAILURE = 'ENABLE_LISTING_SYNDICATION_FAILURE'
export const DISABLE_LISTING_SYNDICATION_REQUEST = 'DISABLE_LISTING_SYNDICATION_REQUEST'
export const DISABLE_LISTING_SYNDICATION_SUCCESS = 'DISABLE_LISTING_SYNDICATION_SUCCESS'
export const DISABLE_LISTING_SYNDICATION_FAILURE = 'DISABLE_LISTING_SYNDICATION_FAILURE'

export const INVALID_LISTING_SYNDICATION_ADDRESS = 'INVALID_LISTING_SYNDICATION_ADDRESS'

export const DUPLICATE_LISTING_SYNDICATION_ADDRESS = 'DUPLICATE_LISTING_SYNDICATION_ADDRESS'

export const UPDATE_CONTACT_DETAILS_CALL = 'UPDATE_CONTACT_DETAILS_CALL'
export const UPDATE_CONTACT_DETAILS_REQUEST = 'UPDATE_CONTACT_DETAILS_REQUEST'
export const UPDATE_CONTACT_DETAILS_SUCCESS = 'UPDATE_CONTACT_DETAILS_SUCCESS'
export const UPDATE_CONTACT_DETAILS_FAILURE = 'UPDATE_CONTACT_DETAILS_FAILURE'

export const LISTING_SYNDICATION_STATUS = {
  DISABLED: 'disabled',
  ENABLED: 'enabled',
}

export const LISTING_SYNDICATION_ERROR_CODE = {
  INVALID_ADDRESS: 'ADDRESS_INVALID_ERROR',
  DUPLICATE_ADDRESS: 'ADDRESS_DUPLICATE_ERROR',
}

export const LISTING_SYNDICATION_INFO_PANEL = {
  INVALID_ADDRESS: {
    TITLE: 'Invalid Property Address',
    BODY: 'We are unable to syndicate an invalid property address. Sorry, editing the address is not possible at this time.',
  },
  DUPLICATE_ADDRESS: {
    TITLE: 'Duplicated Property Address',
    BODY: 'We are unable to syndicate a duplicated property address. Sorry, editing the address is not possible at this time.',
  },
}

export const BLOCK_ENABLE_INVALID_CONTACT_LISTING_SYNDICATION_TOAST = {
  status: 'warning',
  titleMessage: 'Make sure you input your name before publishing the listing',
  isPersistent: true,
}

export const ENABLE_LISTING_SYNDICATION_SUCCESS_TOAST = {
  status: 'success',
  titleMessage: 'Listing shared to partner sites.',
  bodyMessage: 'It could take a few hours to become public.',
  timeOut: 5000,
}
export const ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_INVALID_ADDRESS_TOAST = {
  status: 'error',
  titleMessage: 'Unable to publish listing.',
  bodyMessage: 'We couldn’t verify this property address.',
  timeOut: 5000,
}
export const ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_DUPLICATED_ADDRESS_TOAST = {
  status: 'error',
  titleMessage: 'Unable to publish listing.',
  bodyMessage: 'This property address is already in use.',
  timeOut: 5000,
}
export const ENABLE_LISTING_SYNDICATION_UNABLE_TO_PUBLISH_FAILURE_TOAST = {
  status: 'error',
  titleMessage: 'Unable to publish listing. Please try again.',
  timeOut: 5000,
}

export const DISABLE_LISTING_SYNDICATION_SUCCESS_TOAST = {
  status: 'success',
  titleMessage: 'Listing removed from partner sites.',
  bodyMessage: 'The listing may show for a few hours until the site updates.',
  timeOut: 5000,
}

export const LISTING_SYNDICATION_SOMETHING_WENT_WRONG_FAILURE_TOAST = {
  status: 'error',
  titleMessage: 'Something went wrong. Please try again.',
  timeOut: 5000,
}

export const SET_IS_POTENTIAL_FRAUD = 'SET_IS_POTENTIAL_FRAUD'
