import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import {
  selectIsFetchingRentalDetail,
  selectRentalDetail,
} from "containers/reports/selectors"
import { submitRefund } from "./actions"
import { selectIsSubmitting } from "./selectors"
import saga from "./saga"
import reducer from "./reducer"

export const mapStateToProps = () =>
  createStructuredSelector({
    isFetchingRentalDetail: selectIsFetchingRentalDetail,
    isSubmitting: selectIsSubmitting,
    rentalDetail: selectRentalDetail,
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ submitRefund }, dispatch),
  }
}

export const withSaga = injectSaga({ key: "landlordRefundSaga", saga })
export const withReducer = injectReducer({ key: "landlordRefund", reducer })

export const withLandlordRefundConnect = compose(
  withSaga,
  withReducer,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
