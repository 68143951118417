import styled from "styled-components"
import { COLOR } from "../../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const StatusCircle = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin: 0 8px 2px 0;
  background-color: ${props => (props.color ? COLOR[props.color] : "white")};
`

export const StatusSampleImg = styled.div`
  position: absolute;
  z-index: 1;
  > img {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 991px) {
    position: relative;
  }
`
