import React from 'react'
import last from 'lodash/last'
import { withFormik } from 'formik'
import { TagsInput } from '@rentspree/component-v2'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import { requestReportEmailSchema } from './schema'
import { removeSpaceCommaFromArray } from '../../../../helpers/validator-helper'
import { StyledForm, ButtonContainer } from './styled'

export class Form extends React.Component {
  componentWillMount() {
    // if the error exists set error into formik form
    if (this.props.emailErrors) {
      this.props.setFieldError('email', this.props.emailErrors)
      // set error state to undefined for solving stuck set state loop
      this.props.handleTagsInputErrors(undefined)
    }
  }

  componentWillUnmount() {
    // set latest error for remembering the error
    this.props.handleTagsInputErrors(this.props.errors.email)
  }

  render() {
    const { handleSubmit, errors, setFieldValue, handleTagsInputChange, emailValues } = this.props
    return (
      <StyledForm onSubmit={handleSubmit}>
        <TagsInput
          name="renterEmail"
          id="dashboardEmailListTagsInput"
          tags={emailValues || []}
          onChange={tagsEmail => {
            const email = removeSpaceCommaFromArray(tagsEmail)
            setFieldValue('email', email)
            handleTagsInputChange(email)
          }}
          label="Send multiple requests by separating each email with a comma."
          error={last(errors.email) || errors.email}
          wrapperStyle={{
            height: '140px',
            overflowY: 'auto',
          }}
          isRequired
        />
        <ButtonContainer>
          <BlueGradientButton
            id="dashboardEmailsListSubmitBtn"
            type="submit"
            small
            fontWeight="600"
            minWidth="150"
            mWidth="100%"
            height="35px"
            lineHeight="35px"
          >
            Send Requests
          </BlueGradientButton>
        </ButtonContainer>
      </StyledForm>
    )
  }
}

export const RequestReportEmailForm = withFormik({
  mapPropsToValues: props => requestReportEmailSchema(props).mapper,
  validationSchema: props => requestReportEmailSchema(props).validation,
  handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: 'RequestReportEmailForm',
})(Form)
