import generateApiCall from 'utils/generate-api-call'

import {
  UPDATE_ACCOUNT_INFORMATION,
  UPDATE_ACCOUNT_INFORMATION_FAILED,
  UPDATE_ACCOUNT_INFORMATION_REQUEST,
  UPDATE_ACCOUNT_INFORMATION_SUCCESS,
} from './constants'

export const updateAccountInformationAPI = generateApiCall([
  UPDATE_ACCOUNT_INFORMATION_REQUEST,
  UPDATE_ACCOUNT_INFORMATION_SUCCESS,
  UPDATE_ACCOUNT_INFORMATION_FAILED,
])

export const updateAccountInformation = payload => ({
  type: UPDATE_ACCOUNT_INFORMATION,
  payload,
})
