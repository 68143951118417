import { buildPath } from '@rentspree/path'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { UPDATE_DRAFT_QUOTATIONS_API } from './constants'

export const updateDraftQuotationsInstance = ({ rentalPaymentId, quotations }) =>
  apiInstanceWithErrorHandler.put(
    buildPath(UPDATE_DRAFT_QUOTATIONS_API, { rentalPaymentId }),
    quotations,
  )
