import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { getMySeenAnnouncement } from 'v3/services/announcement'

/**
 * useGetSeenAnnouncements a hook that will call to getMySeenAnnouncement api
 *
 * @param {import('@tanstack/react-query').UseQueryOptions<Awaited<ReturnType<typeof getMySeenAnnouncement>>} options configurations for useQuery
 */
export const useQuerySeenAnnouncements = options => {
  const userProfile = useSelector(selectProfile)

  return useQuery({
    queryKey: ['getMySeenAnnouncement', userProfile?.id],
    queryFn: getMySeenAnnouncement,
    // TODO: To allow the longer time for 2 minutes before recall the APIs instead of 1 second
    staleTime: 120000,
    refetchOnWindowFocus: false,
    ...options,
  })
}
