export const USER_ROLE_FOR_PROPERTY = {
  LANDLORD: 'landlord',
  LISTING_AGENT: 'listing_agent',
  RENTER_AGENT: 'renter_agent',
  PROPERTY_MANAGER: 'property_manager',
}

export const USER_ROLE_FOR_PROPERTY_LABEL = [
  { id: 'landlord', value: USER_ROLE_FOR_PROPERTY.LANDLORD, label: 'Landlord' },
  { id: 'listing-agent', value: USER_ROLE_FOR_PROPERTY.LISTING_AGENT, label: 'Listing agent' },
  { id: 'renter-agent', value: USER_ROLE_FOR_PROPERTY.RENTER_AGENT, label: 'Renter’s agent' },
  {
    id: 'property-manager',
    value: USER_ROLE_FOR_PROPERTY.PROPERTY_MANAGER,
    label: 'Property manager',
  },
]
