export const DASHBOARD_OVERVIEW = '/dashboard/overview'
export const SUBMIT_RENTAL_CALL = 'SUBMIT_RENTAL_CALL'
export const SUBMIT_RENTAL_REQUEST = 'SUBMIT_RENTAL_REQUEST'
export const SUBMIT_RENTAL_SUCCESS = 'SUBMIT_RENTAL_SUCCESS'
export const SUBMIT_RENTAL_FAILED = 'SUBMIT_RENTAL_FAILED'
export const SUBMIT_RENTAL_API_URL = '/api/v2/rental-submissions/:rentalId/submit'

export const MOBILE_THRESHOLD = 768

export const TRACKING_EVENT_METHOD_NAME = 'trackEvent'

export const TU_CONTACT = {
  PHONE: '(833) 458-6338',
}

export const REPORT_REVIEWING_HEADER_TEXT = {
  TITLE: 'Confirm Your Application Details',
  TITLE_MOBILE: 'Confirm Your Application',
  SUBTITLE: 'Way to go! You’re almost to the finish line.',
}

export const REPORT_REVIEWING_TERMS = {
  INFO_TEXT_SUBMIT: 'By clicking “Submit” I acknowledge that I have read and agreed to the',
  INFO_TEXT_NEXT: 'By clicking “Next” I acknowledge that I have read and agreed to the',
  TERMS_TEXT: 'Terms and Conditions.',
  ERROR_TEXT: 'Agree to the terms above to submit your application.',
  LINK: 'https://www.rentspree.com/terms-of-use',
}

export const REPORT_ACCORDION_HEADER_TEXT = {
  CREDIT: {
    ORDER: '1',
    TITLE: 'Credit Report & Score',
    SUB_TITLE: `If you find an error below, call TransUnion at ${TU_CONTACT.PHONE}.`,
    SUB_TITLE_MOBILE: `If you find an error, call TransUnion at ${TU_CONTACT.PHONE}.`,
    CONFIRM_TEXT: 'Confirmed',
    CONFIRM_BTN_TEXT: 'Confirm',
  },
  CRIMINAL: {
    ORDER: '2',
    TITLE: 'Background Check',
    SUB_TITLE: `If you find an error below, call TransUnion at ${TU_CONTACT.PHONE}.`,
    SUB_TITLE_MOBILE: `If you find an error, call TransUnion at ${TU_CONTACT.PHONE}.`,
    CONFIRM_TEXT: 'Confirmed',
    CONFIRM_BTN_TEXT: 'Confirm',
  },
  EVICTION: {
    ORDER: '3',
    TITLE: 'Eviction Related Proceedings',
    SUB_TITLE: `If you find an error below, call TransUnion at ${TU_CONTACT.PHONE}.`,
    SUB_TITLE_MOBILE: `If you find an error, call TransUnion at ${TU_CONTACT.PHONE}.`,
    CONFIRM_TEXT: 'Confirmed',
    CONFIRM_BTN_TEXT: 'Confirm',
  },
}

export const REPORTS = [
  {
    title: 'credit_report',
    key: 'credit',
  },
  {
    title: 'criminal',
    key: 'criminal',
  },
  {
    title: 'eviction',
    key: 'eviction',
  },
]

export const CREDIT_REPORT = 'credit_report'
export const CRIMINAL = 'criminal'
export const EVICTION = 'eviction'
export const SUBMIT = 'submit'

export const CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT = 10000
export const DOCUMENT_UPLOAD_PATH = '/apply/:rentalAppId/documents'
export const INCOME_VERIFICATION_PATH = '/apply/:rentalAppId/income-verification'
