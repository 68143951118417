import {
  GET_CREDIT_REPORT_REQUEST,
  GET_CREDIT_REPORT_SUCCESS,
  GET_CREDIT_REPORT_FAILED,
} from "legacy/constants/action-types"
import get from "lodash/get"
import { getCreditReport as getCreditReportApi } from "legacy/apis/credit-report.api"
import createAction from "legacy/apis/utils/create-action"
import {
  FETCHED_CREDIT_REPORT_IMAGE,
  FETCHED_CRIMINAL_IMAGE,
  FETCHED_EVICTION_IMAGE,
} from "./constants"

export const fetchCreditReport = createAction(
  getCreditReportApi,
  [
    GET_CREDIT_REPORT_REQUEST,
    GET_CREDIT_REPORT_SUCCESS,
    GET_CREDIT_REPORT_FAILED,
  ],
  (res = {}) => ({ creditReport: res }),
)

export const fetchedCreditReportImage = () => ({
  type: FETCHED_CREDIT_REPORT_IMAGE,
})

export const fetchedCriminalImage = () => ({
  type: FETCHED_CRIMINAL_IMAGE,
})

export const fetchedEvictionImage = () => ({
  type: FETCHED_EVICTION_IMAGE,
})

// TODO: refactor to saga logic
export const getCreditReport = (...args) => (dispatch, getState) => {
  const store = getState()
  const isFetchingCreditReport = get(
    store,
    "creditReport.isFetchingCreditReport",
  )
  if (isFetchingCreditReport) return null
  return fetchCreditReport(...args)(dispatch, getState)
}
