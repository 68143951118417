import styled from "styled-components"
import {
  TOPBAR_HEIGHT,
  MOBILE_BREADCRUMBS_HEIGHT,
  MOBILE_SIDEBAR_HEIGHT,
  MOBILE_TAB_HEIGHT,
  MOBILE_ACCEPT_DENY_HEIGHT,
} from "../../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const StyledHsForm = styled.div`
  max-width: 400px;
  margin: 30px auto;
  @media (max-width: 991px) {
    ${props =>
      props.withAcceptDeny &&
      `margin: calc(${MOBILE_ACCEPT_DENY_HEIGHT} + 5px) auto;`}
  }
`
export const StyledWrap = styled.div`
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT} -
      ${MOBILE_BREADCRUMBS_HEIGHT} - ${MOBILE_TAB_HEIGHT}
  );
  background: white;
  padding: 20px;
`
export const FormTitle = styled.h3`
  margin: 10px 0 15px;
  font-size: 1.4em;
  line-height: 1.4;
`

export const FormDesc = styled.p`
  line-height: 1.5;
  margin-bottom: 15px;
  font-size: 16px;
`

export const FormIcon = styled.img`
  max-width: 150px;
`
