import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import tracker from 'tracker'
import T36 from '@rentspree/component-v2/dist/typography/t36'
import BooleanCard from 'components/molecules/boolean-card'
import styled from 'styled-components'
import RadioImage from 'components/atoms/radio-image'
import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import { buildPath } from '@rentspree/path'
import {
  PERMALINK_ACTIONS_ID,
  SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT,
  SCREENING_NEW_TENANT
} from 'constants/route'

import withScreenTenantRequirement from 'containers/request/with-screen-tenant-requirement'
import {
  DiamondWrapper,
  DiamondContainer,
} from 'components/templates/diamond-wrapper-template-style'
import { RequestFooter } from 'legacy/components/request/request-main-style'
import { EVENT_TENANT_SCREENING } from 'tracker/const'
import SelectAgentTypeConnect from './connect'
import { withSaga } from '../../assign-property/connect'
import { AGENT_TYPES, TENANT_SCREENING_FLOW_ORIGIN } from '../constants'

const RoleSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  margin-bottom: 50px;
`

export const RoleSelectRadioButton = styled(BooleanCard)`
  width: 100%;
`

const StyledT36 = styled(T36)`
  @media (max-width: 767px) {
    line-height: 32px;
  }
  line-height: 45px;
`

export const SelectAgentType = props => {
  const { history, match } = props
  const propertyId = get(match, 'params.propertyId')
  const [agentType, setAgentType] = React.useState('')
  const isListingAgent = agentType === AGENT_TYPES.LISTING_AGENT
  const isRenterAgent = agentType === AGENT_TYPES.RENTER_AGENT

  const handleClickNext = () => {
    let propertyRepType = ''
    if (isListingAgent) {
      propertyRepType =
        EVENT_TENANT_SCREENING.EVENT_PROPERTY.PROPERTY_REPRESENTATIVE_TYPE.LANDLORD_OR_LISTING
    } else if (isRenterAgent) {
      propertyRepType = EVENT_TENANT_SCREENING.EVENT_PROPERTY.PROPERTY_REPRESENTATIVE_TYPE.RENTER
    }

    const determineRedirectPath = () => {
      if (propertyId) {
        return buildPath(PERMALINK_ACTIONS_ID, { propertyId }, { origin: TENANT_SCREENING_FLOW_ORIGIN.SELECT_AGENT })
      }
      return buildPath(SCREENING_NEW_TENANT, null, {
        agentType,
        origin: TENANT_SCREENING_FLOW_ORIGIN.SELECT_AGENT,
      })
    }
    const redirectPath = determineRedirectPath()

    tracker.trackEvent(EVENT_TENANT_SCREENING.EVENT_NAME.SELECT_AGENT_TYPE, {
      click_from:
        EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.CREATE_SCREENING_REQUEST_ROLE_SELECTION,
      property_rep_type: propertyRepType,
    })
    history.push(redirectPath)
  }

  const handleClickCancel = () => {
    history.push(SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT)
  }

  return (
    <DiamondWrapper>
      <DiamondContainer>
        <StyledT36 margin="0 0 30px">Who are you representing for this screening?</StyledT36>
        <RoleSelectContainer>
          <RoleSelectRadioButton
            title="Landlord or property"
            data-testid="select-agent-role-page-listing-agent-radio-button"
            icon={RadioImage}
            onClick={() => setAgentType(AGENT_TYPES.LISTING_AGENT)}
            checked={isListingAgent}
          />
          <RoleSelectRadioButton
            title="Applicants"
            data-testid="select-agent-role-page-renter-agent-radio-button"
            icon={RadioImage}
            onClick={() => setAgentType(AGENT_TYPES.RENTER_AGENT)}
            checked={isRenterAgent}
          />
        </RoleSelectContainer>
        <RequestFooter>
          <Button
            id="RequestActionsBackButton"
            data-testid="select-agent-role-page-cancel-button"
            google
            small
            semiBold
            size="16px"
            text="Back"
            type="button"
            minWidth="100px"
            onClick={handleClickCancel}
          />
          <BlueGradientButton
            data-testid="select-agent-role-page-next-button"
            disabled={!agentType}
            hasIcon
            small
            height="40px"
            text="Next"
            id="nextButton"
            semiBold
            mWidth="100px"
            size="16px"
            minWidth="100px"
            tWidth="100px"
            onClick={handleClickNext}
          />
        </RequestFooter>
      </DiamondContainer>
    </DiamondWrapper>
  )
}

export default compose(
  withRouter,
  withSaga,
  SelectAgentTypeConnect,
  withScreenTenantRequirement,
)(SelectAgentType)
