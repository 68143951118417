import React from 'react'

import { StatsigProvider } from '@statsig/react-bindings'
import { CenterLoading } from 'components/organisms/center-loading'
import { STATSIG_API, STATSIG_ENVIRONMENT, STATSIG_SDK_KEY } from 'env'

export const FeatureFlagProvider = ({ statsigUser, children }) => <StatsigProvider 
    loadingComponent = {<CenterLoading/>}
    sdkKey={STATSIG_SDK_KEY}
    user={statsigUser}
    options={{
      networkConfig: {
        api: STATSIG_API ?? '',
      },
      environment: {
        tier: STATSIG_ENVIRONMENT,
      },
    }}
  >
    {children}
  </StatsigProvider>
