import { FormControl } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { string, object } from 'yup'

import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

export const SelfDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL } = RECIPIENT_DETAILS.SELF_DETAILS

  const validationSchema = object({
    firstName: string().min(1).required(),
    lastName: string().min(1).required(),
  })

  const formik = useFormik({
    initialValues: {
      firstName: renterInfo?.firstName ?? null,
      lastName: renterInfo?.lastName ?? null,
    },
    validateOnMount: true,

    validationSchema,
  })

  useEffect(() => {
    const { firstName, lastName } = formik.values
    setRenterInfoPiece({ firstName, lastName, email: null })
  }, [formik.values.firstName, formik.values.lastName])

  return (
    <>
      <TitleText>{TITLE}</TitleText>
      {/* TODO: skip if details can be found elsewhere? */}
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth defaultValue="" required>
          <StyledTextField
            id="firstName"
            label={FIRST_NAME_LABEL}
            variant="outlined"
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            {...formik.getFieldProps('firstName')}
            size="normal"
          />
          <StyledTextField
            id="lastName"
            label={LAST_NAME_LABEL}
            variant="outlined"
            defaultValue={renterInfo?.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            {...formik.getFieldProps('lastName')}
            size="normal"
          />
        </FormControl>
        <DrawerFooter
          buttonLayout={buttonLayoutChoices.NEXT_BACK}
          textOverrides={{ back: 'Cancel', next: 'Save' }}
          nextEnabled={formik.isValid}
        />
      </form>
    </>
  )
}
