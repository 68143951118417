import generateApiCall from 'utils/generate-api-call'
import {
  GENERATE_INCOME_VERIFICATION_REPORT,
  GENERATE_INCOME_VERIFICATION_REPORT_FAILURE,
  GENERATE_INCOME_VERIFICATION_REPORT_REQUEST,
  GENERATE_INCOME_VERIFICATION_REPORT_SUCCESS,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_FAILURE,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_REQUEST,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_SUCCESS,
  CLEAR_INCOME_VERIFICATION,
  GET_INCOME_VERIFICATION_REPORT,
  GET_INCOME_VERIFICATION_REPORT_REQUEST,
  GET_INCOME_VERIFICATION_REPORT_SUCCESS,
  GET_INCOME_VERIFICATION_REPORT_FAILURE,
} from './constants'

export const generateIncomeVerificationReport = rentalSubmissionId => ({
  type: GENERATE_INCOME_VERIFICATION_REPORT,
  payload: { rentalSubmissionId },
})

export const legacyGenerateIncomeVerificationReport = payload => dispatch => {
  dispatch(generateIncomeVerificationReport(payload))
}

export const getIncomeVerificationByRentalSubmission = rentalSubmissionId => ({
  type: GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION,
  payload: { rentalSubmissionId },
})

export const getIncomeVerificationReport = rentalSubmissionId => ({
  type: GET_INCOME_VERIFICATION_REPORT,
  payload: { rentalSubmissionId },
})

export const generateIncomeVerificationReportApiState = generateApiCall([
  GENERATE_INCOME_VERIFICATION_REPORT_REQUEST,
  GENERATE_INCOME_VERIFICATION_REPORT_SUCCESS,
  GENERATE_INCOME_VERIFICATION_REPORT_FAILURE,
])

export const getIncomeVerificationByRentalSubmissionApiState = generateApiCall([
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_REQUEST,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_SUCCESS,
  GET_INCOME_VERIFICATION_BY_RENTAL_SUBMISSION_FAILURE,
])

export const getIncomeVerificationReportApiState = generateApiCall([
  GET_INCOME_VERIFICATION_REPORT_REQUEST,
  GET_INCOME_VERIFICATION_REPORT_SUCCESS,
  GET_INCOME_VERIFICATION_REPORT_FAILURE,
])

export const clearIncomeVerification = () => ({
  type: CLEAR_INCOME_VERIFICATION,
})
