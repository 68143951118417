import produce from "immer"
import {
  GET_LINK_ACCOUNT_DATA_REQUESTED,
  GET_LINK_ACCOUNT_DATA_SUCCESS,
  GET_LINK_ACCOUNT_DATA_FAILED,
  SET_SHOW_CRIMINAL_DISCLAIMER_MODAL,
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
  GENERATE_PERMALINK_FAILED,
  GENERATE_PERMALINK_SUCCESS,
  GENERATE_PERMALINK_REQUESTED,
} from "./constants"

export const initialState = {
  linkAccountData: {},
  permalink: undefined,
  isFetchingLinkAccountData: true,
  isCreatingListingScreeningRequest: false,
  isGeneratingPermalink: false,
  showCriminalDisclaimerModal: false,
}

/* eslint-disable no-param-reassign */
const stepRequestScreening = (
  state = initialState,
  { type, payload: response },
) =>
  produce(state, draft => {
    switch (type) {
      case GET_LINK_ACCOUNT_DATA_REQUESTED:
        draft.isFetchingLinkAccountData = true
        break
      case GET_LINK_ACCOUNT_DATA_SUCCESS:
        draft.linkAccountData = response
        draft.isFetchingLinkAccountData = false
        break
      case GET_LINK_ACCOUNT_DATA_FAILED:
        draft.isFetchingLinkAccountData = false
        break
      case SET_SHOW_CRIMINAL_DISCLAIMER_MODAL:
        draft.showCriminalDisclaimerModal = response
        break
      case CREATE_LISTING_SCREENING_REQUEST_REQUESTED:
        draft.isCreatingListingScreeningRequest = true
        break
      case CREATE_LISTING_SCREENING_REQUEST_SUCCESS:
      case CREATE_LISTING_SCREENING_REQUEST_FAILED:
        draft.isCreatingListingScreeningRequest = false
        break
      case GENERATE_PERMALINK_REQUESTED:
        draft.isGeneratingPermalink = true
        break
      case GENERATE_PERMALINK_SUCCESS:
        draft.isGeneratingPermalink = false
        draft.permalink = response
        break
      case GENERATE_PERMALINK_FAILED:
        draft.isGeneratingPermalink = false
        break

      default:
        break
    }
    return draft
  })

export default stepRequestScreening
