import React from "react"
import PropTypes from "prop-types"
import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import Button from "@rentspree/component-v2/dist/button"
import {
  BlueGradientButton,
  RedGradientButton,
} from "@rentspree/component-v2/dist/button/gradient"
import styled from "styled-components"
import { CONFIRM_TYPE } from "./constants"

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  button {
    &:not(:last-child) {
      margin-left: 10px;
    }
  }
`
export const Heading = styled(S24)`
  margin-top: 0;
  font-family: Source Sans Pro !important;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const ConfirmationModal = ({
  confirmType,
  title,
  children,
  onClickConfirm,
  isConfirmButtonDisabled,
  buttonsJustifyContent,
  confirmTxt = "Confirm",
  cancelText = "Cancel",
  ...props
}) => {
  const { isLoading, onClose } = props
  const modalProps = {
    ...props,
    onClose: props.onCloseModal || props.onClose,
  }
  const confirmButtonProps = {
    small: true,
    semiBold: true,
    height: "35px",
    minWidth: "99",
    text: confirmTxt,
    id: "confirmBtn",
    fontSize: "14px",
    sourceSansPro: true,
    onClick: onClickConfirm,
    disabled: isConfirmButtonDisabled,
    loading: isLoading,
  }

  return (
    <RentSpreeBaseModal {...modalProps}>
      {title && <Heading>{title}</Heading>}
      {children}
      <ButtonsContainer justifyContent={buttonsJustifyContent}>
        {confirmType === CONFIRM_TYPE.DELETE ? (
          <RedGradientButton {...confirmButtonProps} />
        ) : (
          <BlueGradientButton {...confirmButtonProps} />
        )}
        <Button
          google
          small
          semiBold
          height="35px"
          fontSize="14px"
          id="cancelBtn"
          text={cancelText}
          type="button"
          sourceSansPro
          disabled={isLoading}
          onClick={onClose}
        />
      </ButtonsContainer>
    </RentSpreeBaseModal>
  )
}

ConfirmationModal.propTypes = {
  /* type of modal that affect to confirm-button color should be empty or "delete" */
  confirmType: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element,
  onClickConfirm: PropTypes.func,
  isConfirmButtonDisabled: PropTypes.string,
  confirmTxt: PropTypes.string,
  cancelText: PropTypes.string,
  /* modalSize is size of modal should be one of ["small", "medium", "large"] */
  modalSize: PropTypes.string,
  /* indicator for call close modal function when click on overlay */
  shouldCloseOnOverlayClick: PropTypes.bool,
  /* indicator for show close button on the top right */
  closeButton: PropTypes.bool,
  /* indicator for close modal when press ESC */
  shouldCloseOnEsc: PropTypes.bool,
  /* id of the root of your app. The modal need the root id to mount itself to the root component */
  rootId: PropTypes.string,
  /* padding style of the modal on mobile view */
  mPadding: PropTypes.string,
  /* id of the modal */
  id: PropTypes.string,
  /* indicator to show modal */
  isOpen: PropTypes.bool,
  /* function that will get call after the modal is open */
  onAfterOpen: PropTypes.func,
  /* function that will get call when close modal */
  onClose: PropTypes.func,
  /* function that will get call when close modal, if not exists will get call onClose instead */
  onCloseModal: PropTypes.func,
  /* function that will get call after the modal is close */
  onAfterClose: PropTypes.func,
}

export default ConfirmationModal
