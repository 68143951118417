import { TYPES } from "./const"

export const onNext = () => ({
  type: TYPES.ON_NEXT,
})
export const showPopper = data => ({
  type: TYPES.SHOW_NOTICE,
  payload: data,
})

export const getNotices = data => ({
  type: TYPES.CALL_GET_NOTICE,
  payload: data,
})

export const getNoticesRequest = () => ({
  type: TYPES.GET_NOTICE_REQUEST,
})

export const getNoticesSuccess = data => ({
  type: TYPES.GET_NOTICE_SUCCESS,
  payload: data,
})

export const getNoticesFailed = () => ({
  type: TYPES.GET_NOTICE_FAILED,
})
export const updateNotice = data => ({
  type: TYPES.CALL_UPDATE_NOTICE,
  payload: data,
})
export const nextSuccess = () => ({
  type: TYPES.NEXT_ACTIVE_NOTICE_SUCCESS,
})

export const findNextNotice = data => ({
  type: TYPES.NEXT_ACTIVE_NOTICE,
  payload: data,
})

export const updateNoticesRequest = () => ({
  type: TYPES.UPDATE_NOTICE_REQUEST,
})

export const updateNoticesSuccess = data => ({
  type: TYPES.UPDATE_NOTICE_SUCCESS,
  payload: data,
})

export const updateNoticesFailed = () => ({
  type: TYPES.UPDATE_NOTICE_FAILED,
})

export const skipNotice = data => ({
  type: TYPES.SKIP_NOTICE_GROUP,
  payload: data,
})

export const forceTrigger = () => ({
  type: TYPES.FORCE_TRIGGER,
})
