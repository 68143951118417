import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from 'styles/settings'
import { Checkbox } from 'components/atoms/checkbox'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0 !important;
  border-bottom: 1px solid ${COLOR.grayLv4};
  color: ${COLOR.textBlack};
`

const LeftContainer = styled.div`
  width: 48px;
  padding: 15px;
  p {
    padding-left: 18px !important;
  }
`

const RightContainer = styled.div`
  display: block;
  flex-grow: 1;
  padding: 15px 0;
`

const CheckboxContainer = styled.div`
  span {
    top: 3px !important;
  }
`

const Title = styled.p`
  font-size: 16px;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
`

export const RefCheckProOptionItem = ({
  refCheckName,
  refCheckKey,
  isSelected = false,
  onChange = () => {},
}) => (
  <Container>
    <LeftContainer>
      <CheckboxContainer>
        <Checkbox
          value={isSelected}
          onClick={() => {
            onChange(refCheckKey, !isSelected)
          }}
        />
      </CheckboxContainer>
    </LeftContainer>
    <RightContainer>
      <Title>{refCheckName}</Title>
    </RightContainer>
  </Container>
)

RefCheckProOptionItem.propTypes = {
  refCheckName: PropTypes.string.isRequired,
  refCheckKey: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
}

RefCheckProOptionItem.defaultProps = {
  isSelected: false,
}
