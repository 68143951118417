import React from "react"
import debounce from "lodash/debounce"
import delay from "lodash/delay"
import upperCase from "lodash/upperCase"
import { useSelector } from "react-redux"

import tracker from "tracker"
import { SIGN_FLAG_EVENTS } from "tracker/const"
import { scrollToFirstError } from "utils/scroll-to-first-error"
import { SignFlagWrapper } from "components/envelope"
import { SignFlag } from "components/molecules/sign-flag"

import { makeSelectAllEmptyOrInvalidFields } from "../selectors"
import { DOCUMENT_LIST_ID } from "../constants"
import { TYPES_COMPONENT } from "../../drag-n-drop/constants"
import {
  START_POSITION_HEIGHT,
  VERTICAL_CENTER_POSITION,
  SIGN_FLAG_VERTICAL_OFFSET,
  SIGN_FLAG_STATE,
  SIGN_FLAG_PROPS,
  SIGN_ฺCOMPONENT_HEIGHT,
} from "./constants"

export const calculateNextState = componentType => {
  switch (componentType) {
    case TYPES_COMPONENT.INITIAL_SIGNS:
      return SIGN_FLAG_STATE.INITIAL
    case TYPES_COMPONENT.SIGNATURE_SIGNS:
      return SIGN_FLAG_STATE.SIGN
    case TYPES_COMPONENT.TEXT_BOX:
    case TYPES_COMPONENT.DATE_SIGNS:
      return SIGN_FLAG_STATE.FILL_IN
    default:
      return SIGN_FLAG_STATE.START
  }
}

const trackSignFlagEvents = (state, template) => {
  switch (upperCase(state)) {
    case SIGN_FLAG_STATE.START:
      tracker.trackEvent(SIGN_FLAG_EVENTS.CLICK_START_SIGN_FLAG, {
        template,
      })
      break
    case SIGN_FLAG_STATE.NEXT:
      tracker.trackEvent(SIGN_FLAG_EVENTS.CLICK_NEXT_SIGN_FLAG, {
        template,
      })
      break
    default:
  }
}

const SignFlagContainer = React.forwardRef(
  ({ template, showSignError, setShowSignError }, ref) => {
    const allErrors = useSelector(makeSelectAllEmptyOrInvalidFields())
    const [currentIndex, setCurrentIndex] = React.useState(-1)
    const [signFlagState, setSignFlagState] = React.useState(
      SIGN_FLAG_STATE.START,
    )
    const [ignoreNextScroll, setIgnoreNextScroll] = React.useState(false)
    const [isScrolling, setIsScrolling] = React.useState(false)
    const [signFlagVerticalOffset, setSignFlagVerticalOffset] = React.useState(
      START_POSITION_HEIGHT,
    )

    const signFlagProps = SIGN_FLAG_PROPS[signFlagState]
    const isCompletedAllSigned = allErrors.length === 0

    React.useEffect(() => {
      const scrollEndDebounce = debounce(() => {
        setIsScrolling(false)
      }, 300)
      const scrollListener = () => {
        if (!isScrolling) setIsScrolling(true)
        scrollEndDebounce()
      }
      window.addEventListener("scroll", scrollListener, true)
      return () => window.removeEventListener("scroll", scrollListener, true)
    }, [])

    React.useEffect(() => {
      if (
        !ignoreNextScroll &&
        isScrolling &&
        signFlagState !== SIGN_FLAG_STATE.START
      ) {
        setSignFlagState(SIGN_FLAG_STATE.NEXT)
      } else if (!isScrolling) {
        setIgnoreNextScroll(false)
      }
    }, [isScrolling])

    React.useEffect(() => {
      if (
        currentIndex >= 0 &&
        currentIndex < allErrors.length &&
        !isCompletedAllSigned
      ) {
        const nextField = allErrors[currentIndex]
        scrollToFirstError(
          { [nextField.fieldId]: true },
          [nextField.fieldId],
          SIGN_FLAG_VERTICAL_OFFSET,
          DOCUMENT_LIST_ID,
        )
        if (nextField.isLastPage) {
          // delay because we must wait until finish scrolling to get the position
          delay(() => getLastPageVerticalOffset(nextField.fieldId), 500)
        } else {
          setSignFlagVerticalOffset(
            `${VERTICAL_CENTER_POSITION - SIGN_ฺCOMPONENT_HEIGHT / 2}px`,
          )
        }
        setSignFlagState(calculateNextState(nextField.type))
      }
    }, [currentIndex, ignoreNextScroll])

    const getLastPageVerticalOffset = fieldId => {
      const lastSign = document.getElementById(fieldId)
      const topSignPosition = lastSign.getBoundingClientRect().top
      setSignFlagVerticalOffset(
        `${topSignPosition - SIGN_ฺCOMPONENT_HEIGHT / 2}px`,
      )
    }

    const handleOnClick = async e => {
      const isUserEvent = e.isTrusted
      let nextIndex = 0
      if (isUserEvent) {
        if (!showSignError) setShowSignError(true)
        trackSignFlagEvents(signFlagProps.text, template)
        nextIndex = (currentIndex + 1) % allErrors.length
      }
      setIgnoreNextScroll(true) // because we don't want to change button to NEXT state
      setCurrentIndex(nextIndex)
    }

    if (isCompletedAllSigned) return <></>

    return (
      <SignFlagWrapper
        ref={ref}
        onClick={handleOnClick}
        top={signFlagVerticalOffset}>
        <SignFlag
          small
          buttonType={signFlagProps.buttonType}
          text={signFlagProps.text}
        />
      </SignFlagWrapper>
    )
  },
)

export default SignFlagContainer
