import produce from 'immer'
import { GET_MULTI_SHARE_STATUS_SUCCESS } from './constants'

export const initialState = {
  isMultiShareActive: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_MULTI_SHARE_STATUS_SUCCESS: {
        draft = payload
        break
      }
      default:
        break
    }
    return draft
  })

export default reducer
