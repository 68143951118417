import generateApiCall from 'utils/generate-api-call'
import createAction from 'legacy/apis/utils/create-action'
import { pdfAPI } from 'legacy/apis/rental-submission.api'
import { OPEN_SWEET_ALERT } from 'legacy/constants/action-types'
import { ALERT_PRESET } from 'legacy/libs/sweet-alert/sweet-alert'
import { OTHER_DOCUMENTS as OTHER_DOCUMENTS_MESSAGE } from 'legacy/constants/error-messages'
import {
  GET_DOC_PDF_REQUEST,
  GET_DOC_PDF_SUCCESS,
  GET_DOC_PDF_FAILED,
  SHOW_DOC_PDF_SUCCESS,
  CLEAR_SHOW_DOC_PDF,
  FETCH_DOWNLOAD_TOKEN_CALL,
  FETCH_DOWNLOAD_TOKEN_REQUEST,
  FETCH_DOWNLOAD_TOKEN_SUCCESS,
  FETCH_DOWNLOAD_TOKEN_FAILURE,
  GET_OTHER_DOCS_CALL,
  GET_OTHER_DOCS_SUCCESS,
  GET_OTHER_DOCS_REQUEST,
  GET_OTHER_DOCS_FAILED,
  REQUEST_OTHER_DOC_REQUEST,
  REQUEST_OTHER_DOC_SUCCESS,
  REQUEST_OTHER_DOC_FAILURE,
  REQUEST_OTHER_DOC_CALL,
  CANCEL_DOCUMENT_REQUEST_CALL,
  CANCEL_DOCUMENT_REQUEST_REQUEST,
  CANCEL_DOCUMENT_REQUEST_SUCCESS,
  CANCEL_DOCUMENT_REQUEST_FAILED,
  DOWNLOAD_DOCUMENT_FILE_CALL,
  DOWNLOAD_DOCUMENT_FILE_REQUEST,
  DOWNLOAD_DOCUMENT_FILE_SUCCESS,
  DOWNLOAD_DOCUMENT_FILE_FAILED,
} from './constants'

export const pdfDocs = createAction(
  pdfAPI,
  [GET_DOC_PDF_REQUEST, GET_DOC_PDF_SUCCESS, GET_DOC_PDF_FAILED],
  (res = {}) => res,
)

export const onShowPdfSuccess = () => ({
  type: SHOW_DOC_PDF_SUCCESS,
})

export const clearShowPdf = () => ({
  type: CLEAR_SHOW_DOC_PDF,
})

export const requestOtherDocsCall = (payload = {}) => ({
  type: REQUEST_OTHER_DOC_CALL,
  payload,
})

export const requestOtherDocs = generateApiCall([
  REQUEST_OTHER_DOC_REQUEST,
  REQUEST_OTHER_DOC_SUCCESS,
  REQUEST_OTHER_DOC_FAILURE,
])

export const downloadOtherDocsCall = payload => ({
  type: FETCH_DOWNLOAD_TOKEN_CALL,
  payload,
})

export const downloadOtherDocs = generateApiCall([
  FETCH_DOWNLOAD_TOKEN_REQUEST,
  FETCH_DOWNLOAD_TOKEN_SUCCESS,
  FETCH_DOWNLOAD_TOKEN_FAILURE,
])

export const downloadDocumentFileCall = payload => ({
  type: DOWNLOAD_DOCUMENT_FILE_CALL,
  payload,
})

export const downloadDocumentFileAction = generateApiCall([
  DOWNLOAD_DOCUMENT_FILE_REQUEST,
  DOWNLOAD_DOCUMENT_FILE_SUCCESS,
  DOWNLOAD_DOCUMENT_FILE_FAILED,
])

export const alertSuccess = () => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.SUCCESS,
  option: {
    title: OTHER_DOCUMENTS_MESSAGE.REQUEST_SUCCESS.TITLE,
    text: OTHER_DOCUMENTS_MESSAGE.REQUEST_SUCCESS.MESSAGE,
    button: 'OK',
  },
})

export const alertError = (errorMessage = {}) => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.ERROR,
  option: {
    title: errorMessage.title,
    content: {
      element: 'div',
      attributes: {
        innerHTML: errorMessage.message,
      },
    },
    button: 'OK',
  },
})

export const getDocSets = generateApiCall([
  GET_OTHER_DOCS_REQUEST,
  GET_OTHER_DOCS_SUCCESS,
  GET_OTHER_DOCS_FAILED,
])

export const getOtherDocsCall = rentalId => ({
  type: GET_OTHER_DOCS_CALL,
  payload: {
    rentalId,
  },
})

export const cancelDocumentRequestCall = payload => ({
  type: CANCEL_DOCUMENT_REQUEST_CALL,
  payload,
})

export const cancelDocumentRequest = generateApiCall([
  CANCEL_DOCUMENT_REQUEST_REQUEST,
  CANCEL_DOCUMENT_REQUEST_SUCCESS,
  CANCEL_DOCUMENT_REQUEST_FAILED,
])
