import { takeLatest, put, call } from 'redux-saga/effects'

import { PAYMENT_REQUEST_STATUS } from 'v3/containers/rent-payment/constants'

import { checkShowErrorBadgeAPI, CHECK_SHOW_ERROR_BADGE } from '../../actions'
import { checkRentalPaymentExistsInstance } from '../../api-instances'

export function* checkShowErrorBadge() {
  yield put(checkShowErrorBadgeAPI.request())
  try {
    const response = yield call(checkRentalPaymentExistsInstance, {
      paymentStatuses: `${PAYMENT_REQUEST_STATUS.DEPOSIT_FAILED},${PAYMENT_REQUEST_STATUS.PAYMENT_FAILED},${PAYMENT_REQUEST_STATUS.DUE}`,
    })
    yield put(checkShowErrorBadgeAPI.success(response))
  } catch (err) {
    yield put(checkShowErrorBadgeAPI.failure(err))
  }
}

export function* watchCheckShowErrorBadgeSaga() {
  yield takeLatest(CHECK_SHOW_ERROR_BADGE, checkShowErrorBadge)
}
