import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getProperty, clearProperty, clearDisabledFeatures } from 'legacy/actions/property.action'
import injectSaga from 'utils/inject-saga'
import injectReducer from 'utils/inject-reducer'
import { createStructuredSelector } from 'reselect'

import { selectIsPotentialFraudUser } from 'containers/user/selectors'
import reducer from './reducer'
import { selectIsFetching, selectProperty, selectListingScreeningRequest, selectExistScreeningRequest } from './selectors'
import saga from './saga'
import { toggleAcceptingStatus, getApplyLink } from './actions'
import listingSyndicationSaga from './listing-syndication/saga'
import {
  getListingSyndication,
  clearListingSyndication,
  updateContactDetails,
} from './listing-syndication/actions'
import { selectIsFetchingListingSyndication, selectIsSyndicatingListing } from './listing-syndication/selectors'
import { addToast } from '../toast/actions'

export const mapStateToProps = createStructuredSelector({
  property: selectProperty,
  screeningRequest: selectListingScreeningRequest,
  existScreeningRequest: selectExistScreeningRequest,
  isFetching: selectIsFetching,
  isSyndicatingListing: selectIsSyndicatingListing,
  isFetchingListingSyndication: selectIsFetchingListingSyndication,
  isPotentialFraudUser: selectIsPotentialFraudUser
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getProperty,
      clearProperty,
      toggleAcceptingStatus,
      addToast,
      getApplyLink,
      getListingSyndication,
      clearListingSyndication,
      updateContactDetails,
      clearDisabledFeatures,
    },
    dispatch,
  ),
})

export const withSaga = injectSaga({ key: 'listingScreeningRequest', saga })
export const withListingSyndicationSaga = injectSaga({
  key: 'listingSyndication',
  saga: listingSyndicationSaga,
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({ key: 'listingManagement', reducer })
