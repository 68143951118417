import React, { useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import tracker from 'tracker'
import { query, buildPath } from '@rentspree/path'
import { generateAddress } from '@rentspree/helper'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import Button from '@rentspree/component-v2/dist/button'

import { EVENT_TENANT_SCREENING } from 'tracker/const'
import {
  PERMALINK_ACTIONS_ID,
  SELECT_AGENT_TYPE,
  SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT,
  SINGLE_PROPERTY_APPLICATION_LIST,
  TENANT_SCREENING,
} from 'constants/route'
import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'

import { SCREEN_NEW_TENANT } from 'legacy/constants/request-const'

import { SCREENING_METHODS } from 'legacy/constants/screening-plan-consts'
import { TENANT_SCREENING_FLOW_ORIGIN } from 'containers/tenant-screening/request/constants'
import { TrackerInstance as Tracker } from '../../helpers/tracker'
import { LoadingOverlay } from '../../components/request/left-content/index'
import { ScreeningMethod } from '../../components/request/screening-method'
import { RequestContainer, RequestFooter } from '../../components/request/request-main-style'

const {
  EVENT_NAME: { CLICK_GO_TO_LISTING_FROM_SCREENING_PAGE },
} = EVENT_TENANT_SCREENING

export const StepRequestApplication = ({ ...props }) => {
  const {
    actions,
    location,
    requestOptions,
    history,
    isLegacyVersion,
    property = {},
    rentalSubmission,
  } = props
  const [isActionDone, setIsActionDone] = useState(false)
  const propertyAddress = useMemo(
    () => generateAddress(property.property),
    [JSON.stringify(property?.property)],
  )

  const redirect = () => {
    const queryParams = query.parse(location.search)
    const propertyId = queryParams.propertyId

    // TODO: Clean up this condition after clean up PREMIUM SCREENING FF
    if (isLegacyVersion) {
      const stepOptionPath = location.pathname.replace('/actions', '')
      history.push(stepOptionPath)
      return
    }

    if (queryParams.origin === TENANT_SCREENING_FLOW_ORIGIN.SELECT_AGENT) {
      history.push(SELECT_AGENT_TYPE)
      return
    }

    if (!propertyId) {
      history.push(buildPath(SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT))
      return
    }

    if (queryParams.origin === TENANT_SCREENING_FLOW_ORIGIN.SELECT_PROPERTY) {
      history.push(buildPath(PERMALINK_ACTIONS_ID, { propertyId }))
      return
    }

    const stepOptionPath = buildPath(PERMALINK_ACTIONS_ID, { propertyId })

    history.push(stepOptionPath)
  }

  useEffect(() => {
    const queryParams = query.parse(location.search)
    const isFromTenantScreening = isEmpty(queryParams.propertyId) && requestOptions.isInitial

    const shouldRedirect =
      !requestOptions.payer ||
      (isEmpty(requestOptions.selectingItems) &&
        (isEmpty(requestOptions.bundleType) || isEmpty(requestOptions.applicationType))) ||
      isFromTenantScreening

    if (shouldRedirect) {
      redirect()
      return () => {
        actions.clearIsSendEmail()
      }
    }

    actions.getPropertyPermalink(queryParams)
    return () => {
      actions.clearIsSendEmail()
    }
  }, [])

  const isLoading = () =>
    rentalSubmission.isFetchingRequested ||
    rentalSubmission.isFetchingTextMessageRequested ||
    rentalSubmission.isSendingEmailOnAgent ||
    property.isFetching

  const handleGoBack = () => {
    Tracker.trackEvent('clickPreviousInRequestActionsPage')
    history.goBack()
  }

  const handleGoToDashboard = () => {
    Tracker.trackEvent('clickGoToDashboardInRequestActionsPage')
    const propertyId = get(property, 'property._id')
    actions.clearRequestOptions()

    // clear pro option
    if (propertyId) actions.clearScreeningWithPropertyProOptions()
    else actions.clearScreeningWithoutPropertyProOptions()

    const path = propertyId
      ? buildPath(
          SINGLE_PROPERTY_APPLICATION_LIST,
          {
            propertyId,
          },
          {
            prvSource: SCREEN_NEW_TENANT,
          },
        )
      : TENANT_SCREENING
    if (propertyId) {
      tracker.trackEvent(CLICK_GO_TO_LISTING_FROM_SCREENING_PAGE)
    }
    history.replace(path)
  }

  const handleAction = () => {
    setIsActionDone(true)
  }

  return (
    <RequestContainer>
      {isLoading() && <LoadingOverlay />}
      <TitleWithSubtitle
        title="How Will Applicants Apply?"
        subtitle={propertyAddress ? `For ${propertyAddress}` : ''}
        dataTestKey="step-actions"
      />
      <ScreeningMethod
        {...props}
        handleAction={handleAction}
        {...query.parse(location.search)}
        screeningMethod={location?.state?.emails?.length && SCREENING_METHODS.EMAIL}
      />
      <RequestFooter>
        <Button
          id="RequestActionsBackButton"
          google
          small
          semiBold
          size="16px"
          leftIcon
          text="Back"
          type="button"
          minWidth="110"
          onClick={handleGoBack}
        />

        <BlueGradientButton
          id="RequestActionsDoneButton"
          size="16px"
          semiBold
          small
          hasIcon
          disabled={!isActionDone}
          onClick={handleGoToDashboard}
        >
          Done
        </BlueGradientButton>
      </RequestFooter>
    </RequestContainer>
  )
}

export default StepRequestApplication
