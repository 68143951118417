import { RENT_ESTIMATE } from "constants/error-messages"
import get from "lodash/get"
export const mapRentRangeError = error => {
  switch (error.code) {
    case "SDP_DH_001":
    case "SDP_DH_012":
    case "SDP_DH_015":
      return {
        message: get(error, "message", RENT_ESTIMATE.DEFAULT),
        contact: RENT_ESTIMATE.CONTACT,
        code: error.code,
      }
    case "SDP_DH_022":
      return {
        message: RENT_ESTIMATE.SDP_DH_022,
        contact: RENT_ESTIMATE.CONTACT,
        code: error.code,
      }
    case "SDP_R_003":
    case "SDP_ORG_KEY_001":
    default:
      return {
        message: RENT_ESTIMATE.DEFAULT,
        contact: RENT_ESTIMATE.CONTACT,
        code: error.code,
      }
  }
}
