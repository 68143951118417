import { useSelector } from 'react-redux'
import React, { useState, useEffect, useRef } from 'react'
import clone from 'lodash/clone'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import capitalize from 'lodash/capitalize'
import remove from 'lodash/remove'
import { useAbility } from '@casl/react'
import { buildPath } from '@rentspree/path'
import { WhiteWrapper, Loading, CenterContent } from 'components/layout/main'
import { AbilityContext } from 'containers/reports/with-pro-report-available/ability-context'
import {
  PREMIUM_CAN_ACTION,
  PREMIUM_CAN_SUBJECT,
  PRO_CAN_ACTION,
  PRO_CAN_SUBJECT,
  TRIAL_CAN_ACTION,
  TRIAL_CAN_SUBJECT,
} from 'containers/reports/with-pro-report-available/constants'
import { FeatureBlockModal, FEATURE_BLOCK_NAME } from '@rentspree/component-v2'
import {
  DASHBOARD,
  DOCUMENT_FULL,
  DOCUMENTS_ABSOLUTE_PATH,
  TENANT_SCREENING_WITH_RENTAL_ID,
} from 'constants/route'
import { PRO_PLAN_VARIANTS } from 'legacy/containers/pro/landing/constants'
import { getSubscriptionProductVariant } from 'legacy/containers/pro/landing/utils'
import { makeSelectProducts } from 'legacy/containers/pro/landing/selectors'
import CancelDocumentRequestModal from 'components/templates/tenant-screening/reports/other-docs/organisms/cancel-document-request-modal'
import { EVENT_TENANT_SCREENING } from 'tracker/const'
import { defaultList, docStatus, docType } from '../../constants/other-docs-consts'
import { FailedToFetch } from '../layout/failed-to-fetch'
import * as MESSAGES from '../../constants/error-messages'
import { isIOS } from '../helper/ua-parser-js'
import { TrackerInstance as Tracker } from '../../helpers/tracker'
import tracker from '../../tracker'
import {
  EVENT_RENTSPREE_PRO,
  EVENT_FEATURE_BLOCK_MODAL,
  RENTSPREE_PRO_FEATURE_NAME,
  SUBSCRIPTION_PERIOD,
  FEATURE_BLOCK_ACTION,
  OTHER_DOCS,
  EVENT_OTHER_DOCS,
} from '../../tracker/const'
import { DocumentRequestTemplate } from '../../../components/templates/tenant-screening/reports/other-docs'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function OtherDocs(props) {
  const products = useSelector(makeSelectProducts())
  const [selectedDocs, setSelectedDocs] = useState([])
  const [downloadingDocs, setDownloadingDocs] = useState([])
  const [docsList, setDocsList] = useState(clone(defaultList))
  const [showFeatureBlockModal, setShowFeatureBlockModal] = useState(false)
  const [hasStartTrial, setHasStartTrial] = useState(false)
  const [displayErrorText, setDisplayErrorText] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [showCancelDocumentRequestModal, setShowCancelDocumentRequestModal] = useState(false)

  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const ability = useAbility(AbilityContext)

  const prevOtherDocsList = usePrevious(docsList)

  const monthlyPrice = getSubscriptionProductVariant(products?.data, {
    name: PRO_PLAN_VARIANTS.MONTHLY,
  })?.price
  const totalPrice = getSubscriptionProductVariant(products?.data, {
    name: PRO_PLAN_VARIANTS.MONTHLY,
  })?.price

  useEffect(() => {
    const { document } = props
    setOtherDocsList(document)
  }, [])

  useEffect(() => {
    const documentList = props.document.otherDocs
    if (!isEqual(documentList, prevOtherDocsList)) {
      const { document } = props
      setOtherDocsList(document)
    }
  }, [props.document])

  useEffect(() => {
    const resizeHandler = () => {
      setInnerWidth(window.innerWidth)
    }
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const setOtherDocsList = document => {
    setDocsList(document?.otherDocs)
  }

  const onCheckAll = () => {
    const newArr = []
    if (selectedDocs.length !== docsList.length) {
      docsList.map(doc => {
        newArr.push({ type: doc.type, note: doc.note })
        return newArr
      })
      trackEventCheck('all')
    }
    setSelectedDocs(newArr)
    setDisplayErrorText(false)
  }

  const onClickRow = (doc, e) => {
    const { requestDisabled } = props
    if (requestDisabled) {
      return
    }
    e.preventDefault()
    const newArr = [...selectedDocs]
    if (findIndex(selectedDocs, { type: doc.type }) < 0) {
      newArr.push({ type: doc.type, note: doc.note })
      trackEventCheck(doc.type)
    } else {
      remove(newArr, selectedDoc => selectedDoc.type === doc.type)
    }
    setSelectedDocs(newArr)
    setDisplayErrorText(false)
  }

  const selectedOtherDocs = () =>
    selectedDocs.map(doc => {
      if (doc.type === 'custom') {
        return { type: 'custom', name: doc.name }
      }
      return doc
    })

  const handleNoteInputChange = (documentType, noteValue) => {
    const allSelectedDocuments = [...selectedDocs]
    const document = allSelectedDocuments.find(doc => doc.type === documentType)
    document.note = noteValue
    setSelectedDocs(allSelectedDocuments)
  }

  const handleClickCloseNote = documentType => {
    const allSelectedDocuments = [...selectedDocs]
    const document = allSelectedDocuments.find(doc => doc.type === documentType)
    if (document && document.note) {
      delete document.note
    }

    setSelectedDocs(allSelectedDocuments)
  }

  const handleCancelDocumentRequest = message => {
    if (!selectedDocument) return

    const documentSet = props.document.documentSet
    props.actions.cancelDocumentRequest({
      message,
      documentId: selectedDocument.id,
      documentSetId: documentSet.id,
    })
    handleCloseCancelDocumentRequestModal()

    const { rentalAppId } = props.match.params
    const isPro = ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties } = EVENT_OTHER_DOCS.CLICK_CONFIRM_CANCEL_REQUEST
    tracker.trackEvent(
      eventName,
      properties({ rentalId: rentalAppId, documentType: selectedDocument.type, isPro }),
    )
  }

  const handleUpdateRequest = (document, event) => {
    const isSelected = selectedDocs.some(selectedDoc => selectedDoc.type === document.type)
    if (!isSelected) {
      onClickRow(document, event)
    }

    const { rentalAppId } = props.match.params
    const isPro = ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties } = EVENT_OTHER_DOCS.CLICK_UPDATE_REQUEST
    tracker.trackEvent(
      eventName,
      properties({ rentalId: rentalAppId, documentType: document.type, isPro }),
    )
  }

  const handleOnClickCancelDocumentRequest = document => {
    setSelectedDocument(document)
    setShowCancelDocumentRequestModal(true)

    const { rentalAppId } = props.match.params
    const isPro = ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties } = EVENT_OTHER_DOCS.CLICK_CANCEL_REQUEST
    tracker.trackEvent(
      eventName,
      properties({ rentalId: rentalAppId, documentType: document.type, isPro }),
    )
  }

  const handleCloseCancelDocumentRequestModal = () => {
    setShowCancelDocumentRequestModal(false)
    setSelectedDocument(null)
  }

  const onRequestDocs = async () => {
    const documents = selectedOtherDocs()
    Tracker.trackEvent('sendRequestedOtherDoc')

    if (isEmpty(documents)) {
      setDisplayErrorText(true)
      return
    }

    trackSendRequestRentSpreePro()

    props.actions.requestOtherDocs({
      clearSelectedDocs,
      documents,
    })
  }

  const clearSelectedDocs = () => {
    setSelectedDocs([])
  }

  const clearDownloadingDocs = documentId => {
    const downloadingDocsClone = downloadingDocs
    remove(downloadingDocsClone, id => id === documentId)
    setDownloadingDocs(downloadingDocsClone)
  }

  const handleViewAllDocumentFiles = document => {
    const { rentalAppId } = props.match.params
    const isPro = ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties } = EVENT_OTHER_DOCS.CLICK_VIEW_ALL_DOCUMENTS
    tracker.trackEvent(
      eventName,
      properties({ rentalId: rentalAppId, documentType: document.type, isPro }),
    )

    window.open(`${DASHBOARD}/document/${document.id}`, '_blank')
  }

  const handleViewDocumentFile = (file, document) => {
    const { rentalAppId } = props.match.params
    const isPro = ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties } = EVENT_OTHER_DOCS.CLICK_VIEW_DOCUMENT
    tracker.trackEvent(
      eventName,
      properties({ rentalId: rentalAppId, documentType: document.type, isPro }),
    )

    window.open(`${DASHBOARD}/document/${document.id}/file/${file.id}`, '_blank')
  }

  const handleDownloadDocumentFile = (file, document) => {
    const { actions, application } = props
    const rentalDetail = get(application, 'rentalDetail', {})
    const renterDetail = get(rentalDetail, 'renter', {})
    let renter = {
      firstName: capitalize(get(renterDetail, 'firstName', '')),
      lastName: capitalize(get(renterDetail, 'lastName', '')),
    }
    if (application && application.applicationDetail && application.applicationDetail.aboutMe) {
      renter = application.applicationDetail.aboutMe
    }

    actions.downloadDocumentFile({
      renter,
      fileId: file.id,
      documentId: document.id,
    })

    trackEventDownload(document.type, rentalDetail)
  }

  const onDownloadDoc = async document => {
    const { actions, application } = props
    const { rentalAppId } = props.match.params
    const rentalDetail = get(application, 'rentalDetail', {})
    const renterDetail = get(rentalDetail, 'renter', {})
    const { firstName, lastName } = renterDetail
    let renter = {
      firstName: capitalize(firstName) || '',
      lastName: capitalize(lastName) || '',
    }
    if (application && application.applicationDetail && application.applicationDetail.aboutMe) {
      renter = application.applicationDetail.aboutMe
    }
    trackEventDownload(document.type, rentalDetail)
    const documentId = get(document, '_id')
    if (isIOS()) {
      // open new download window
      const uri = buildPath(
        DOCUMENT_FULL,
        {
          rentalAppId,
          documentId,
        },
        { document, renter },
      )
      window.open(uri)
    } else if (document.type === docType.full) {
      await actions.downloadFullDocument(rentalAppId, renter)
    } else {
      const newArr = [...downloadingDocs]
      newArr.push(documentId)
      setDownloadingDocs(newArr)
      actions.getDownloadToken({
        rentalAppId,
        document,
        renter,
        clearDownloadingDocs,
      })
    }
  }

  const trackEventCheck = type => {
    switch (type) {
      case docType.paystubs:
        Tracker.trackEvent('checkPayStub')
        break
      case docType.bank:
        Tracker.trackEvent('checkBankStatements')
        break
      case docType.tax:
        Tracker.trackEvent('checkTaxReturns')
        break
      case docType.w2s:
        Tracker.trackEvent('checkW2s')
        break
      case docType.passport:
        Tracker.trackEvent('checkDriverLicenseOrPassport')
        break
      case docType.pets:
        Tracker.trackEvent('checkPetPhotos')
        break
      case docType.petApp:
        Tracker.trackEvent('checkPetApplication')
        break
      case 'all':
        Tracker.trackEvent('checkAllDocs')
        break
      default:
        break
    }
  }

  const trackEventDownload = (documentType, rental) => {
    const { email, _id: rentalId } = rental
    const { eventName, properties } = EVENT_OTHER_DOCS.DOWNLOAD_OTHER_DOCS
    if (documentType !== docType.full) {
      tracker.trackEvent(
        eventName,
        properties({
          email,
          rentalId,
          documentType,
        }),
      )
    }
    switch (documentType) {
      case docType.paystubs:
        Tracker.trackEvent('downloadPayStub')
        break
      case docType.bank:
        Tracker.trackEvent('downloadBankStatements')
        break
      case docType.tax:
        Tracker.trackEvent('downloadTaxReturns')
        break
      case docType.w2s:
        Tracker.trackEvent('downloadW2s')
        break
      case docType.passport:
        Tracker.trackEvent('downloadLicenseOrPassport')
        break
      case docType.pets:
        Tracker.trackEvent('downloadPetPhotos')
        break
      case docType.petApp:
        Tracker.trackEvent('downloadPetApplication')
        break
      case docType.full:
        tracker.trackEvent(
          EVENT_OTHER_DOCS.DOWNLOAD_FULL_DOCS.eventName,
          EVENT_OTHER_DOCS.DOWNLOAD_FULL_DOCS.properties({
            email,
            rentalId,
          }),
        )
        break
      default:
        break
    }
  }

  const hasStartedTrial = () => !ability.can(TRIAL_CAN_ACTION, TRIAL_CAN_SUBJECT)

  const shouldShowFeatureBlockModal = shouldShow => {
    if (shouldShow) {
      trackSendRequestRentSpreePro()
    }
    setShowFeatureBlockModal(shouldShow)
    setHasStartTrial(hasStartedTrial())
  }

  const trackSendRequestRentSpreePro = () => {
    const isPro = ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties, mapping } = EVENT_RENTSPREE_PRO.OTHER_DOCS
    const { CLICK_FROM } = OTHER_DOCS.EVENT_PROPERTY
    const { PREMIUM, STANDARD } = EVENT_TENANT_SCREENING.EVENT_PROPERTY.BUNDLE_TYPE
    const { rentalAppId } = props.match.params
    const { application, document, screeningOption } = props
    const rentalDetail = get(application, 'rentalDetail', {})
    const documents = get(document, 'otherDocs', {})
    const { email } = rentalDetail
    const selectedDocuments = selectedOtherDocs()

    map(selectedDocuments, item => {
      const { type, note } = item
      const fullDocument = documents.find(doc => doc.type === type)
      const selectType = get(mapping, [type], '')
      tracker.trackEvent(
        eventName.request,
        properties({
          email,
          rentalAppId,
          type: selectType,
          isPro,
          isResend: fullDocument?.status !== docStatus.created,
          hasInstruction: !!note,
          clickFrom: CLICK_FROM.VIEW_REPORT,
          bundle: screeningOption.income ? PREMIUM : STANDARD,
        }),
      )
    })
  }

  const withSubscriptionCheck = func => {
    if (
      ability.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT) ||
      ability.can(PREMIUM_CAN_ACTION, PREMIUM_CAN_SUBJECT)
    ) {
      return func
    }
    return () => shouldShowFeatureBlockModal(true)
  }

  const getActionNeeded = () => (hasStartedTrial() ? 'upgrade' : 'trial')

  const trackModalEvent = type => {
    let eventType
    const eventProperties = {
      action: getActionNeeded(),
      feature: RENTSPREE_PRO_FEATURE_NAME.OTHER_DOCS,
    }
    switch (type) {
      case FEATURE_BLOCK_ACTION.OPEN:
        eventType = EVENT_FEATURE_BLOCK_MODAL.VIEW_FEATURE_BLOCK_MODAL
        break
      case FEATURE_BLOCK_ACTION.CLOSE:
        eventType = EVENT_FEATURE_BLOCK_MODAL.CLOSE_FEATURE_BLOCK_MODAL
        break
      case FEATURE_BLOCK_ACTION.LEARN_MORE:
        eventType = EVENT_FEATURE_BLOCK_MODAL.CLICK_LEARN_MORE_ON_MODAL
        break
      case FEATURE_BLOCK_ACTION.CHECKOUT:
        eventType = EVENT_FEATURE_BLOCK_MODAL.GO_TO_CHECK_OUT_PAGE
        eventProperties.subscriptionPeriod = SUBSCRIPTION_PERIOD.MONTHLY
        break
      default:
        return
    }
    tracker.trackEvent(eventType.eventName, eventType.properties(eventProperties))
  }

  const getDocumentKebabMenuItems = document => {
    const baseItems = []
    if (!isEmpty(document.files)) {
      baseItems.push({
        label: 'View All',
        onClick: () => handleViewAllDocumentFiles(document),
      })
      baseItems.push({
        label: 'Download All',
        onClick: () => onDownloadDoc(document),
      })
    }
    switch (document.status) {
      case docStatus.requested:
        return [
          ...baseItems,
          {
            label: 'Update Request',
            onClick: event => handleUpdateRequest(document, event),
          },
          {
            label: 'Cancel Request',
            onClick: () => handleOnClickCancelDocumentRequest(document),
          },
        ]
      case docStatus.submitted:
        return [...baseItems]
      default:
        return []
    }
  }

  const fileMenuItems = [
    {
      label: 'View',
      onClick: (file, document) => handleViewDocumentFile(file, document),
    },
    {
      label: 'Download',
      onClick: (file, document) => handleDownloadDocumentFile(file, document),
    },
  ]

  const { document, requestDisabled } = props
  const { rentalAppId, propertyId } = props.match.params
  const submittedDocs = filter(docsList, doc => doc.status === 'submitted')
  const isSubmitDisabled = find(
    selectedDocs,
    doc => !isEmpty(doc.error) || (doc.type === docType.custom && isEmpty(doc.name)),
  )
  const redirectUrl = propertyId
    ? buildPath(DOCUMENTS_ABSOLUTE_PATH, {
        propertyId,
        rentalAppId,
      })
    : buildPath(`${TENANT_SCREENING_WITH_RENTAL_ID}/documents`, { rentalAppId })

  return document.errorOtherDocs ? (
    <FailedToFetch
      withBreadcrumb
      withFilter
      title={MESSAGES.APPLICATION_DETAIL.DOCUMENTS.FETCH_DOCUMENTS.TITLE}
      text={MESSAGES.APPLICATION_DETAIL.DOCUMENTS.FETCH_DOCUMENTS.MESSAGE}
    />
  ) : (
    <WhiteWrapper withReportTabs withMTab noMargin>
      {document.isFetchingDocSets && (
        <CenterContent overlay withBreadcrumb withFilter>
          <Loading />
        </CenterContent>
      )}
      <FeatureBlockModal
        id={`${FEATURE_BLOCK_NAME.OTHER_DOCS}FeatureBlockModal`}
        feature={FEATURE_BLOCK_NAME.OTHER_DOCS}
        isOpen={showFeatureBlockModal}
        hasStartTrial={hasStartTrial}
        onClose={() => shouldShowFeatureBlockModal(false)}
        redirectUrl={redirectUrl}
        onAfterOpen={() => trackModalEvent(FEATURE_BLOCK_ACTION.OPEN)}
        onMoreInfoClick={() => trackModalEvent(FEATURE_BLOCK_ACTION.LEARN_MORE)}
        onAfterClose={() => trackModalEvent(FEATURE_BLOCK_ACTION.CLOSE)}
        onCheckoutClick={() => trackModalEvent(FEATURE_BLOCK_ACTION.CHECKOUT)}
        fullPrice={monthlyPrice}
        totalPrice={totalPrice}
      />

      <CancelDocumentRequestModal
        isOpen={showCancelDocumentRequestModal}
        onClickConfirm={handleCancelDocumentRequest}
        onClose={handleCloseCancelDocumentRequestModal}
      />

      <DocumentRequestTemplate
        docsList={docsList}
        selectedDocs={selectedDocs}
        requestDisabled={requestDisabled}
        downloadingDocs={downloadingDocs}
        isSubmitDisabled={isSubmitDisabled}
        onClickSendRequest={withSubscriptionCheck}
        onNoteInputChange={handleNoteInputChange}
        onClickCloseNote={handleClickCloseNote}
        onRequestDocs={onRequestDocs}
        onCheckAll={onCheckAll}
        onDownloadDoc={onDownloadDoc}
        submittedDocs={submittedDocs}
        document={document?.isFetchingFullDocZip}
        onClickRow={onClickRow}
        showError={displayErrorText}
        isMobile={innerWidth < 991}
        getDocumentKebabMenuItems={getDocumentKebabMenuItems}
        fileMenuItems={fileMenuItems}
      />
    </WhiteWrapper>
  )
}

export default OtherDocs
