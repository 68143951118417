import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import TagInput from 'components/contacts/atoms/tag-input'
import { TAG_INPUT_TYPE } from 'components/contacts/atoms/tag-input/constants'
import { COLOR, breakpoints } from 'styles/settings'

import DropdownMenus from './dropdown-menus'

const Container = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || '200px'};
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  ${({ showFullWidth }) => showFullWidth && 'position: unset;'}
  width: ${({ width }) => width || '100%'};

  .tooltip-container {
    position: relative;
  }

  .tooltip-container .arrow-down {
    width: 0;
    height: 0;
    position: absolute;
    left: 29.5%;
    bottom: 0;
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    border-top: 21px solid ${COLOR.grayLv2};
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  /* Tooltip text */
  .tooltip-text {
    /* Position the tooltip text */
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;

    width: 120px;
    padding: 16px 20px;
    margin-bottom: 19px;
    background-color: ${COLOR.grayLv2};
    border-radius: 5px;

    color: ${COLOR.white};
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    transition: 0.3s;
  }

  &:hover {
    .tooltip-text,
    .arrow-down {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const DropdownContainer = styled(DropdownMenus)`
  ${props =>
    props.inputType === TAG_INPUT_TYPE.RECTANGLE && `width: calc(${props.maxWidth} - 10px);`}
  max-width: ${({ maxWidth }) => maxWidth || '200px'};
  margin-top: 5px;
  &::-webkit-scrollbar {
    ${({ hideScrollbar }) => hideScrollbar && `display: none;`}
  }
  ${({ showFullWidth }) =>
    showFullWidth &&
    `width: calc(100% - 10px);
  max-width: unset;
  ${breakpoints.mobile(`
    width: calc(100% - 40px);
    margin-left: 20px;
    max-width: unset;
  `)}`}
`
const TagsDropdown = ({
  isLoading,
  width,
  maxWidth,
  inputWidth,
  margin,
  mMargin,
  fontSize,
  sortedAllTags = [],
  onCreateAndTagContact,
  onTagContact,
  className,
  showAddButton,
  height,
  inputType,
  onFocus,
  disabledButton,
  openDropdownOnFocus,
  showTooltip,
  hideScrollbar,
  showFullWidth,
}) => {
  const [isShowInput, setIsShowInput] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [isFocused, setIsFocused] = React.useState(false)

  const onCloseInput = () => {
    setIsShowInput(false)
    setIsFocused(false)
    setInputValue('')
  }
  const handleInputBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onCloseInput()
    }
  }

  const handleCreateAndTagContact = event => {
    if (inputValue) {
      onCreateAndTagContact(inputValue)
      onCloseInput()
    }
    if (event) event.stopPropagation()
  }

  const handleTagContact = tag => {
    onTagContact(tag)
    onCloseInput()
  }

  const handleInputFocus = event => {
    setIsShowInput(true)
    setIsFocused(true)
    event.stopPropagation()
  }

  const handleOnFocus = event => {
    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }

  const handleTagsDisplay = () => {
    const startWith = sortedAllTags.filter(tag =>
      tag.name?.toLowerCase().startsWith(inputValue.toLowerCase()),
    )
    const includes = sortedAllTags.filter(tag =>
      tag.name?.toLowerCase().includes(inputValue.toLowerCase()),
    )

    const mergeArray = [...startWith, ...includes]
    const nameArray = mergeArray.map(({ name }) => name)
    return [...startWith, ...includes].filter(
      (value, index) => nameArray.indexOf(value.name) === index,
    )
  }

  return (
    <Container
      maxWidth={maxWidth}
      className={className}
      onBlur={handleInputBlur}
      margin={margin}
      mMargin={mMargin}
      fontSize={fontSize}
      width={width}
      showFullWidth={showFullWidth}
    >
      {showTooltip && (
        <div className="tooltip-container">
          <span className="tooltip-text">Create a tag</span>
          <div className="arrow-down" />
        </div>
      )}
      <TagInput
        inputType={inputType}
        height={height}
        width={inputWidth}
        isLoading={isLoading}
        isShowInput={isShowInput || !showAddButton}
        onChangeValue={setInputValue}
        onFocusInput={handleInputFocus}
        onEnter={handleCreateAndTagContact}
        onFocus={handleOnFocus}
        disabledButton={disabledButton}
      />
      {(inputValue || (openDropdownOnFocus && isFocused)) && (
        <DropdownContainer
          inputType={inputType}
          maxWidth={maxWidth}
          isShowInput={isShowInput}
          inputValue={inputValue}
          tagsDisplay={handleTagsDisplay()}
          onCreateAndTagContact={handleCreateAndTagContact}
          onTagContact={handleTagContact}
          hideScrollbar={hideScrollbar}
          showFullWidth={showFullWidth}
        />
      )}
    </Container>
  )
}

export default TagsDropdown

TagsDropdown.defaultProps = {
  showAddButton: true,
  inputType: TAG_INPUT_TYPE.ROUND,
}

TagsDropdown.propTypes = {
  showAddButton: PropTypes.bool,
  inputType: PropTypes.string,
  onFocus: PropTypes.func,
  disabledButton: PropTypes.bool,
}
