import { API_URL } from "../../../env"

export const GET_SUBMISSION_PARTICIPANT_CALL =
  "GET_TENANT_SCREENING_DETAIL_CALL"

export const GET_SUBMISSION_PARTICIPANT_REQUEST =
  "GET_TENANT_SCREENING_DETAIL_REQUEST"
export const GET_SUBMISSION_PARTICIPANT_SUCCESS =
  "GET_TENANT_SCREENING_DETAIL_SUCCESS"
export const GET_SUBMISSION_PARTICIPANT_FAILURE =
  "GET_TENANT_SCREENING_DETAIL_FAILURE"

export const GET_SUBMISSION_PARTICIPANT_API = `${API_URL}/api/v2/agent/submission-participants/:submissionParticipantId/rental-submission`
