import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectIsSharedReport } from 'containers/reports/selectors'
import {
  downloadOtherDocsCall,
  getOtherDocsCall,
  requestOtherDocsCall,
  cancelDocumentRequestCall,
  downloadDocumentFileCall,
} from 'containers/reports/other-doc/actions'
import { selectAuthorize } from 'containers/reports/other-doc/selectors'
import * as DocumentActions from '../../actions/document.action'
import { makeSelectProducts } from '../pro/landing/selectors'

const DocumentConnect = ComposedComponent =>
  connect(
    state => ({
      document: state.document,
      application: state.application,
      property: state.property && state.property.property,
      isLogin: !!state.persistState.auth.accessToken,
      isSharedReport: selectIsSharedReport(state),
      authorize: selectAuthorize(state),
      products: makeSelectProducts()(),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          ...DocumentActions,
          requestOtherDocs: requestOtherDocsCall,
          getDownloadToken: downloadOtherDocsCall,
          getOtherDocs: getOtherDocsCall,
          cancelDocumentRequest: cancelDocumentRequestCall,
          downloadDocumentFile: downloadDocumentFileCall,
        },
        dispatch,
      ),
    }),
  )(ComposedComponent)

export default DocumentConnect
