import React from 'react'
import {
  PUBLIC_RECORD_TYPE_CODES,
  INDUSTRY_CODES,
  COURT_TYPE_CODES,
  ACCOUNT_DESIGNATOR,
} from 'legacy/constants/credit-report-code'
import { generateLocation } from 'legacy/components/reports-v2/share/function'
import { convert } from 'legacy/components/helper/convert'
import {
  InformationPanel,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  SectionHeader,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import { CreditDetailContainer, CreditDetailInformationLine } from './shared'

const PublicRecord = ({ publicRecords }) => (
  <Section>
    <SectionHeader title="Public Records" />
    {publicRecords.map((record, i) => (
      <SubSection key={`${i.toString()}-${record.referenceNumber}`}>
        <SubSectionHeader
          title={PUBLIC_RECORD_TYPE_CODES[record.publicRecordType] || record.publicRecordType}
        />
        <CreditDetailContainer>
          <InformationPanel>
            <CreditDetailInformationLine
              label="Date Reported"
              value={convert(record.dateReported, 'date', 'MMM DD, YYYY')}
            />
            <CreditDetailInformationLine
              label="Type"
              value={PUBLIC_RECORD_TYPE_CODES[record.recordCode] || record.recordCode}
              stripe={false}
            />
            <CreditDetailInformationLine
              label="Kind of Business"
              value={INDUSTRY_CODES[record.industryCode] || record.industryCode}
            />
            <CreditDetailInformationLine label="Attorney" value={record.legalDesignator} />
            <CreditDetailInformationLine
              label="Court Type"
              value={COURT_TYPE_CODES[record.courtType] || record.courtType}
            />
            <CreditDetailInformationLine label="Court Location" value={generateLocation(record)} />
            <CreditDetailInformationLine
              label="Date Settled"
              value={convert(record.dateSettled, 'date', 'MMM DD, YYYY')}
            />
          </InformationPanel>
          <InformationPanel>
            <CreditDetailInformationLine
              label="Account Designator"
              value={ACCOUNT_DESIGNATOR[record.accountDesignator] || record.accountDesignator}
            />
            <CreditDetailInformationLine
              label="Liability Amount"
              value={convert(record.liabilitiesAmount)}
            />
            <CreditDetailInformationLine label="Asset Amount" value={convert(record.assetAmount)} />
            <CreditDetailInformationLine label="Plaintiff" value={convert(record.plaintiff)} />
            <CreditDetailInformationLine label="Member Code" value={convert(record.memberCode)} />
            <CreditDetailInformationLine
              label="Case Number"
              value={convert(record.referenceNumber)}
            />
          </InformationPanel>
        </CreditDetailContainer>
      </SubSection>
    ))}
  </Section>
)

export default PublicRecord
