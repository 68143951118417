import React from "react"
import styled from "styled-components"
import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import Hr from "@rentspree/component-v2/dist/hr"

import RadioBoxGroup from "components/molecules/radio-box-group"
import { ACCEPT_DENY_TYPE } from "containers/accept-deny/constants"

const Container = styled.div`
  @media (max-width: 991px) {
    height: 100%;
    overflow-y: auto;
    margin-right: -20px;
    padding-right: 20px;
    margin-left: -20px;
    padding-left: 20px;
  }
  @media (min-width: 992px) {
    height: 658px;
    display: flex;
    flex-direction: column;
  }
`

const ChildrenContainer = styled.div`
  @media (min-width: 992px) {
    flex-grow: 1;
    overflow-y: auto;
    margin-right: -25px;
    padding-right: 25px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledS24 = styled(S24)`
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Source Sans Pro;
`
const StyledHr = styled(Hr)`
  @media (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: 20px;
`

const RadioContainer = styled.div`
  margin-bottom: 20px;
  @media (min-width: 992px) {
    min-height: 50px;
  }
`

const AcceptDenyModal = ({
  isOpen,
  onClose,
  title,
  onChangeRadio,
  radioValue,
  children,
  componentKey,
}) => (
  <RentSpreeBaseModal isOpen={isOpen} onClose={onClose} modalSize="large">
    <Container key={componentKey}>
      <StyledS24>{title}</StyledS24>
      <RadioContainer>
        <RadioBoxGroup
          choices={[
            {
              label: "Accept",
              id: "accept-radio-button",
              value: ACCEPT_DENY_TYPE.ACCEPT,
            },
            {
              label: "Accept on condition",
              id: "accept-on-condition-radio-button",
              value: ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION,
            },
            {
              label: "Deny",
              id: "deny-radio-button",
              value: ACCEPT_DENY_TYPE.DENY,
            },
          ]}
          onChange={onChangeRadio}
          value={radioValue}
          radioName="accept-deny-modal"
        />
      </RadioContainer>
      <StyledHr />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  </RentSpreeBaseModal>
)
export default AcceptDenyModal
