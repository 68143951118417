import S24 from '@rentspree/component-v2/dist/typography/s24'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR, breakpoints } from 'styles/settings'

import DeleteTagConfirmationBox from '../../organisms/delete-tag-box'
import DeleteTagList from '../../organisms/delete-tag-list'

const Wrapper = styled.div`
  font-family: Source Sans Pro, sans-serif;
  max-height: 313px;
  overflow: hidden;
  ${breakpoints.mobile(`
    overflow: visible;
  `)};
`
export const Content = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
`

const DeleteTagTemplate = ({
  tags = [],
  isConfirmMode,
  isLoading,
  onClickDeleteTag,
  onClickConfirmDeleteTag,
  onClickCancel,
}) => (
  <Wrapper>
    <S24 size="28px" margin="10px 0 20px 0">
      Delete Tag
    </S24>
    <Content>
      {isConfirmMode ? (
        <DeleteTagConfirmationBox
          isLoading={isLoading}
          onClickCancel={onClickCancel}
          onClickConfirmDeleteTag={onClickConfirmDeleteTag}
        />
      ) : (
        <DeleteTagList tags={tags} onClickDeleteTag={onClickDeleteTag} iconColor={COLOR.textGrey} />
      )}
    </Content>
  </Wrapper>
)

DeleteTagTemplate.defaultProps = {
  tags: [],
  isConfirmMode: false,
  isLoading: false,
}

DeleteTagTemplate.propsType = {
  tags: PropTypes.array,
  isConfirmMode: PropTypes.bool,
  onClickDeleteTag: PropTypes.func.isRequired,
  onClickConfirmDeleteTag: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
}

export default DeleteTagTemplate
