import {createSelector} from 'reselect'
import get from 'lodash/get'
import {BUSINESS_TYPE} from 'components/templates/verify-tax-information-form/constants'
import {KEY, PAYEE_IDV_STATUS} from '../constants'
import {payeeInitialState} from '../reducers/payee.reducer'

export const selectPayeeState = state => get(state, KEY.PAYEE, payeeInitialState)

// providerAccountInformation
export const makeSelectPayeeProviderInformation = () =>
  createSelector(selectPayeeState, state => get(state, 'providerAccountInformation'))

export const makeSelectPayeeProviderAccountInformationData = () =>
  createSelector(makeSelectPayeeProviderInformation(), state => get(state, 'data'))

export const makeSelectPayeeProviderAccountInformationStatus = () =>
  createSelector(makeSelectPayeeProviderInformation(), state => get(state, 'status'))

// updateIdentityVerification
export const makeSelectUpdateIdentityVerification = () =>
  createSelector(selectPayeeState, state => get(state, 'updateIdentityVerification'))

export const makeSelectUpdateIdentityVerificationStatus = () =>
  createSelector(makeSelectUpdateIdentityVerification(), state => get(state, 'status'))

export const makeSelectUpdateIdentityVerificationErrors = () =>
  createSelector(makeSelectUpdateIdentityVerification(), state => get(state, 'errors'))

// identityVerificationSession
export const makeSelectIdentityVerificationSession = () =>
  createSelector(selectPayeeState, state => get(state, 'identityVerificationSession'))

export const makeSelectIdentityVerificationSessionData = () =>
  createSelector(makeSelectIdentityVerificationSession(), state => get(state, 'data'))

export const makeSelectIdentityVerificationSessionStatus = () =>
  createSelector(makeSelectIdentityVerificationSession(), state => get(state, 'status'))

export const makeSelectIdentityVerificationSessionErrors = () =>
  createSelector(makeSelectIdentityVerificationSession(), state => get(state, 'errors'))

// identityVerificationDetails
export const makeSelectIdentityVerificationDetails = () =>
  createSelector(selectPayeeState, state => get(state, 'identityVerificationDetails'))

export const makeSelectIdentityVerificationDetailsData = () =>
  createSelector(makeSelectIdentityVerificationDetails(), state => get(state, 'data'))

export const makeSelectIdentityVerificationDetailsStatus = () =>
  createSelector(makeSelectIdentityVerificationDetails(), state => get(state, 'status'))

// information
export const makeSelectPayeeInformation = () =>
  createSelector(selectPayeeState, state => get(state, 'information'))

export const makeSelectPayeeInformationData = () =>
  createSelector(makeSelectPayeeInformation(), state => get(state, 'data'))

export const makeSelectPayeeInformationStatus = () =>
  createSelector(makeSelectPayeeInformation(), state => get(state, 'status'))

export const makeSelectPayeeBusinessType = () =>
  createSelector(makeSelectPayeeInformationData(), state =>
    get(state, 'businessType', BUSINESS_TYPE.UNKNOWN),
  )

export const makeSelectPayeeIdentityVerification = () =>
  createSelector(makeSelectPayeeInformationData(), state => get(state, 'identityVerification', {}))

export const makeSelectPayeeIdentityVerificationStatus = () =>
  createSelector(makeSelectPayeeIdentityVerification(), state =>
    get(state, 'status', PAYEE_IDV_STATUS.ONBOARDING),
  )

export const makeSelectPayeeIdentityVerificationErrors = () =>
  createSelector(makeSelectPayeeIdentityVerification(), state => get(state, 'errors', []))

// risk level
export const makeSelectPayeeRiskLevel = () =>
  createSelector(selectPayeeState, state => get(state, 'riskLevel'))

export const makeSelectPayeeRiskLevelData = () =>
  createSelector(makeSelectPayeeRiskLevel(), state => get(state, 'data'))

export const makeSelectPayeeRiskLevelStatus = () =>
  createSelector(makeSelectPayeeRiskLevel(), state => get(state, 'status'))

// IDV update link
export const makeSelectPayeeIDVUpdateLink = () =>
  createSelector(selectPayeeState, state => get(state, 'idvUpdateLink'))

export const makeSelectPayeeIDVUpdateLinkData = () =>
  createSelector(makeSelectPayeeIDVUpdateLink(), state => get(state, 'data'))

export const makeSelectPayeeIDVUpdateLinkStatus = () =>
  createSelector(makeSelectPayeeIDVUpdateLink(), state => get(state, 'status'))
