import { put, call, all, takeLatest } from "redux-saga/effects"
import { apiInstance } from "utils/api-interceptor"
import { PARTNER_EVENT_TRACKER_API } from "constants/route"
import { partnerEventTrackerApi } from "./actions"
import { PARTNER_EVENT_TRACKER_CALL } from "./constants"


export const partnerEventTrackerApiInstance = ({
  eventName,
  subdomain,
  payload,
}) =>
  apiInstance.post(PARTNER_EVENT_TRACKER_API, {
    eventName,
    subdomain,
    payload,
  })

export function* trackEvent({ payload }) {
  yield put(partnerEventTrackerApi.request())
  try {
    const response = yield call(partnerEventTrackerApiInstance, payload)
    yield put(partnerEventTrackerApi.success(response))
  } catch (err) {
    yield put(partnerEventTrackerApi.failure())
  }
}

export function* watchTrackEventCall() {
  yield takeLatest(PARTNER_EVENT_TRACKER_CALL, trackEvent)
}

export function* rootSaga() {
  yield all([watchTrackEventCall()])
}

export default rootSaga
