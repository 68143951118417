export const mergeNotificationWithRentalPaymentNotificationCount = ({
  notification,
  rentalPaymentNotificationCount,
  isRentPaymentExists = false,
}) => ({
  ...notification,
  rentalPayments: notification.rentalPayments
    ? {
        ...notification.rentalPayments,
        unopen: rentalPaymentNotificationCount,
      }
    : {
        all: isRentPaymentExists ? 1 : 0,
        unopen: rentalPaymentNotificationCount,
      },
})

export const getRentalPaymentNotificationCountOfProperty = ({
  propertyId,
  rentalPaymentNotifications = [],
}) =>
  rentalPaymentNotifications.length
    ? rentalPaymentNotifications.filter(notification => notification.propertyId === propertyId)
        .length
    : 0
