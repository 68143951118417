import { takeLatest, put, call, all } from "redux-saga/effects"
import isEmpty from "lodash/isEmpty"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "utils/api-interceptor"
import { API_GET_STARTED, TYPES, GROUP_GET_STARTED } from "./const"
import { dispatchAction } from "./actions"

export const fetchGetStartedAPI = query =>
  apiInstance.get(buildPath(API_GET_STARTED, null, query))

export const updateGetStartedAPI = data =>
  apiInstance.post(API_GET_STARTED, data)

export function* updateGetStarted({ group }) {
  yield call(updateGetStartedAPI, [group])
}

export function* fetchGetStart({ payload }) {
  let response
  yield put(dispatchAction(TYPES.FETCH_GET_STARTED_REQUEST))
  try {
    response = yield call(fetchGetStartedAPI, payload)
    yield put(dispatchAction(TYPES.FETCH_GET_STARTED_SUCCESS, response))
    if (!isEmpty(response)) {
      yield call(updateGetStarted, { group: GROUP_GET_STARTED })
    }
  } catch (err) {
    yield put(dispatchAction(TYPES.FETCH_GET_STARTED_FAILED))
  }
}
export function* watchFetchGetStarted() {
  yield takeLatest(TYPES.FETCH_GET_STARTED, fetchGetStart)
}

export default function* rootSaga() {
  yield all([watchFetchGetStarted()])
}
