import { B16 } from '@rentspree/component-v2'
import SelectDropdown from '@rentspree/component-v2/dist/dropdown/select'
import USStateDropdown from '@rentspree/component-v2/dist/dropdown/us-state'
import FormattedInput from '@rentspree/component-v2/dist/input/formatted'
import RadioGroupWithConditionalFields from '@rentspree/component-v2/dist/input/radio-conditional-field'
import TextInput from '@rentspree/component-v2/dist/input/text'
import S18 from '@rentspree/component-v2/dist/typography/s18'
import get from 'lodash/get'
import React from 'react'
import { Row as BootstrapRow, Col as BootstrapCol } from 'react-bootstrap'
import styled from 'styled-components'

import Tag from 'components/contacts/atoms/tag'
import { TAG_INPUT_TYPE } from 'components/contacts/atoms/tag-input/constants'
import TagsDropdown from 'components/contacts/molecules/tags-dropdown'
import { ROLE_OPTIONS } from 'containers/contacts/constants'
import ScrollToError from 'legacy/components/scroll/scroll-to-error'
import { COLOR } from 'styles/settings'

import { HAS_ADDRESS_CHOICES } from './constants'

const Col = styled(BootstrapCol)`
  padding: 0px 5px;
`

const Row = styled(BootstrapRow)`
  padding: 0px 10px;
`

const RadioGroupContainer = styled.div`
  div.hasAddressRadioGroup > div {
    display: flex;
    justify-content: space-between;
  }
  div.row {
    display: block !important;
  }
  .radioIcon {
    margin-left: 0px;
  }
`

const TagWrapper = styled.div`
  border: 1px solid;
  border-color: ${COLOR.newGrey};
  padding: 8px 15px;
`

export const ContactForm = props => {
  const {
    values = {},
    handleChange,
    handleBlur,
    errors = {},
    touched = {},
    setFieldValue,
    setFieldTouched,
    allTags,
    onCreateTagContact,
    onFocusTagInput,
    inputEmailChange,
  } = props

  const [createdTag, setCreatedTag] = React.useState()

  const handleCreateAndTagContact = tagName => {
    setFieldValue('isNewTag', true)
    setCreatedTag(tagName)
    onCreateTagContact(tagName)
  }

  const handleEmailChange = event => {
    handleChange(event)
    inputEmailChange(event.target?.value)
  }

  if (createdTag) {
    const selectedTag = allTags.find(tag => tag.name === createdTag)
    if (selectedTag) {
      setFieldValue('tags', [selectedTag])
      setCreatedTag(null)
    }
  }

  return (
    <>
      <ScrollToError containerError {...props} />
      <Row>
        <Col md={4}>
          <TextInput
            id="create-contact-first-name-input"
            name="firstName"
            label="First Name"
            value={values?.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.firstName && errors?.firstName}
          />
        </Col>
        <Col md={4}>
          <TextInput
            id="create-contact-middle-name-input"
            name="middleName"
            label="Middle Name"
            value={values?.middleName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.middleName && errors?.middleName}
          />
        </Col>
        <Col md={4}>
          <TextInput
            id="create-contact-last-name-input"
            name="lastName"
            label="Last Name"
            value={values?.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.lastName && errors?.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextInput
            id="create-contact-email-input"
            name="email"
            label="Email"
            value={values?.email}
            onChange={handleEmailChange}
            onBlur={handleBlur}
            error={touched?.email && errors?.email}
            isRequired
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextInput
            id="create-contact-mobile-number-input"
            name="mobileNumber"
            label="Mobile"
            value={values?.mobileNumber}
            onBlur={handleBlur}
            error={touched?.mobileNumber && errors?.mobileNumber}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <S18 margin="10px 0px">Did this person provide an address?</S18>
      <RadioGroupContainer>
        <RadioGroupWithConditionalFields
          groupClassName="hasAddressRadioGroup"
          choices={HAS_ADDRESS_CHOICES}
          handleChange={handleChange}
          handleBlur={handleBlur}
          name="hasAddress"
          id="create-contact-has-address-radio"
          hiddenFieldNames="address"
          setFieldValue={setFieldValue}
          values={values}
          error={touched?.hasAddress && errors?.hasAddress}
        >
          <Row>
            <Col md={12}>
              <TextInput
                id="create-contact-street-input"
                type="text"
                name="address.street"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.street}
                label="Street Address"
                error={touched?.address?.street && errors?.address?.street}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextInput
                id="create-contact-city-input"
                type="text"
                name="address.city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.city}
                label="City"
                error={touched?.address?.city && errors?.address?.city}
              />
            </Col>
            <Col md={4}>
              <USStateDropdown
                id="create-contact-state-dropdown"
                name="address.state"
                label="State"
                value={values?.address?.state}
                error={touched?.address?.state && errors?.address?.state}
                onChange={val => setFieldValue('address.state', val)}
                onBlur={() => setFieldTouched('address.state', true)}
              />
            </Col>
            <Col md={4}>
              <FormattedInput
                id="create-contact-zip-input"
                formatType="zipcode"
                type="text"
                name="address.zipcode"
                onValueChange={vals => {
                  setFieldValue('address.zipcode', vals.value)
                }}
                onBlur={handleBlur}
                value={values?.address?.zipcode}
                label="Zipcode"
                error={touched?.address?.zipcode && errors?.address?.zipcode}
              />
            </Col>
          </Row>
        </RadioGroupWithConditionalFields>
      </RadioGroupContainer>
      <S18 margin="10px 0px">Category</S18>
      <Row>
        <Col md={6}>
          <SelectDropdown
            label="Select Role"
            name="role"
            options={ROLE_OPTIONS}
            value={values?.role}
            onChange={val => setFieldValue('role', val)}
            onBlur={() => setFieldTouched('role', true)}
            error={touched?.role && errors?.role}
          />
        </Col>
        <Col md={6}>
          <B16 margin="0 0 10px">Add Tag</B16>
          {get(values, 'tags.0') ? (
            <TagWrapper>
              <Tag {...get(values, 'tags.0')} onRemove={() => setFieldValue('tags', [])} />
            </TagWrapper>
          ) : (
            <TagsDropdown
              showAddButton={false}
              inputType={TAG_INPUT_TYPE.RECTANGLE}
              height="40px"
              maxWidth="100%"
              margin="0 0 5px 0px"
              mMargin="0 0 5px 0px"
              sortedAllTags={allTags}
              onTagContact={tag => setFieldValue('tags', [tag])}
              onCreateAndTagContact={handleCreateAndTagContact}
              onFocus={onFocusTagInput}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export default ContactForm
