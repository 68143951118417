import React from "react"
import tracker from "tracker"
import { Landing } from "components/landing"
import verificationPendingImg from "images/verify-pending.svg"
import { ID_VERIFICATION } from "tracker/const"
import { TENANT_SCREENING } from "constants/route"

const {
  acknowledgeVerificationPending,
  backToDashboardFromVerificationPending,
} = ID_VERIFICATION

const VerificationFailed = ({ heightCal, history }) => {
  React.useEffect(() => {
    tracker.trackEvent(acknowledgeVerificationPending)
  }, [])

  const handleClickBackToDashboard = () => {
    tracker.trackEvent(backToDashboardFromVerificationPending)
    history.push(TENANT_SCREENING)
  }

  return (
    <Landing
      imgMobileDisplay="block"
      imgWidth="208px"
      imgHeight="132px"
      propsWrapper={{
        heightCal,
        withBreadcrumb: false,
      }}
      title="Verification pending"
      titleMarginTop="31"
      titleFontFamily="Inter"
      description="Please contact (323) 515-7757 or support@rentspree.com to manually verify."
      descMaxWidth="427"
      descFontFamily="Inter"
      descFontSize="16"
      descMarginTop="16"
      descMarginBottom="24"
      imgSrc={verificationPendingImg}
      isContainedButton
      buttonId="identity-pending-page"
      buttonName="Back to Dashboard"
      buttonWidth="103"
      buttonType="outlined"
      buttonMobileFullWidth
      buttonStyle={{ background: "white" }}
      onClick={handleClickBackToDashboard}
    />
  )
}

export default VerificationFailed
