import {
  ErrorMessage,
  RedText,
  InputGroup,
  StyledLabel,
} from '@rentspree/component-v2/dist/shared-components'
import React from 'react'

import { StyledDateInput } from './form-styled'

export const DatePickerInput = ({
  label,
  isRequired,
  disabled,
  error,
  labelStyle,
  inputStyle,
  textColor,
  errorMessageStyle,
  mb,
  labelChildren,
  showLabelWhenDisabled = false,
  inputRef,
  ...rest
}) => (
  <InputGroup mb={mb}>
    <StyledLabel
      textColor={textColor}
      labelStyle={labelStyle}
      disabled={disabled && !showLabelWhenDisabled}
    >
      {label}
      {isRequired && <RedText disabled={disabled && !showLabelWhenDisabled}> *</RedText>}
      {labelChildren}
    </StyledLabel>
    <StyledDateInput
      error={error}
      style={inputStyle}
      disabled={disabled}
      ref={inputRef}
      {...rest}
    />
    {error && <ErrorMessage errorMessageStyle={errorMessageStyle}>{error}</ErrorMessage>}
  </InputGroup>
)
