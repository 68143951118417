import generateApiCall from 'utils/generate-api-call'

import {
  GET_RENTAL_PAYMENT_LIST,
  GET_RENTAL_PAYMENT_LIST_REQUESTED,
  GET_RENTAL_PAYMENT_LIST_SUCCEEDED,
  GET_RENTAL_PAYMENT_LIST_FAILED,
  CHANGE_PAGE_RENTAL_PAYMENT_LIST_REQUESTED,
} from './constants'

export const getRentalPaymentList = payload => ({
  type: GET_RENTAL_PAYMENT_LIST,
  payload,
})

export const getRentalPaymentListAPI = generateApiCall([
  GET_RENTAL_PAYMENT_LIST_REQUESTED,
  GET_RENTAL_PAYMENT_LIST_SUCCEEDED,
  GET_RENTAL_PAYMENT_LIST_FAILED,
])
export const changePageRentalPaymentListAPI = generateApiCall([
  CHANGE_PAGE_RENTAL_PAYMENT_LIST_REQUESTED,
  GET_RENTAL_PAYMENT_LIST_SUCCEEDED,
  GET_RENTAL_PAYMENT_LIST_FAILED,
])
