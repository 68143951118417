import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { TextInput } from '@rentspree/component-2023.components.atoms.text-input'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import React, { useEffect, useState } from 'react'
import { isDecimal } from 'validator'

import { DatePickerInput } from 'v3/components/date-picker-input/index'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { validatePayment } from 'v3/containers/overhaul-rent-payment/pages/payment-details/utils'
import { utcDate } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { ABOUT_PAYMENT } from 'v3/containers/overhaul-rent-payment/text-constants'
import { MONTH_TO_MONTH_LABEL } from 'v3/containers/rent-payment/constants'
import { generateQuotationByType } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/utils'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

/*
 * out of render method to enable future consolidation refactor;
 * we don't have a "styles constants" file atm
 */
const inputStyles = { fontSize: '1.5rem', marginBottom: '15px' }

export const TellUsAboutPayment = ({ payment, setPayment }) => {
  const [localPayment, setLocalPayment] = useState({
    ...payment,
    amount: parseFloat(payment?.amount || 0).toFixed(2),
    startDate: payment?.type === 'oneTime' ? payment?.dueDate : payment?.startDate,
  })
  const setLocalPaymentField = updates => {
    setLocalPayment({
      ...localPayment,
      ...updates,
    })
  }
  const [errors, setErrors] = useState({})

  const endDateDisabled = ['oneTime', undefined].includes(localPayment?.type)

  useEffect(() => {
    // this is a cross-field dependent value, so we'll watch them for changes ot update vs baking that into each field's setting logic in both places
    let term = null
    if (localPayment !== undefined && localPayment?.type === 'recurring') {
      term = !localPayment?.endDate ? 'perpetual' : 'fixed'
    }
    setLocalPaymentField({ term })
  }, [localPayment?.type, localPayment?.endDate])

  const localPaymentJSON = JSON.stringify(localPayment)
  useEffect(() => {
    const dueDate = localPayment?.dueDate || localPayment?.startDate
    const inputs = {
      category: localPayment?.category,
      monthlyDescription: localPayment?.description,
      oneTimeDescription: localPayment?.description,
      amount: localPayment?.amount,
      paymentType: localPayment?.type,
      firstPayment: localPayment?.startDate,
      lastPayment:
        !endDateDisabled && localPayment?.endDate ? localPayment?.endDate : MONTH_TO_MONTH_LABEL,
      // TODO: update with Late Fee when implemented?
      dueOn: dueDate ? utcDate(dueDate).date() : null,
      dueDate,
      // deliberately empty, let it be set via Payments Settings
      selectedFeeCollection: {},
    }
    const quotation = generateQuotationByType(inputs)
    // repeats seem required, but 'generateQuotationByType()' doesn't add any?
    if (localPayment?.type === 'recurring') {
      quotation.repeat = 'monthly'
    }
    const paymentIsValid = validatePayment(quotation)
    if (paymentIsValid) {
      setPayment(quotation)
    }
  }, [localPaymentJSON])

  useEffect(() => {
    // unify null & undefined states via default value
    const { startDate = null, endDate = null } = localPayment
    let startDateError
    let endDateError

    if (startDate !== null && utcDate(startDate).isBefore(utcDate(), 'day')) {
      startDateError = 'Please choose a starting date today or later'
    }
    if (!endDateDisabled && endDate !== null) {
      if (utcDate(endDate).isBefore(utcDate(), 'day')) {
        endDateError = 'Please choose an end date later than today'
      } else if (startDate !== null && utcDate(endDate).isBefore(utcDate(startDate), 'day')) {
        endDateError = 'Please choose an end date later than the start date'
      }
    }

    setErrors({ startDate: startDateError, endDate: endDateError })
  }, [localPayment?.type, localPayment?.endDate, localPayment?.startDate])

  // normalize API payments that may have ISO formatted date strings
  const startDate = localPayment?.startDate
    ? utcDate(localPayment?.startDate).format('YYYY-MM-DD')
    : null
  const endDate = localPayment?.endDate ? utcDate(localPayment?.endDate).format('YYYY-MM-DD') : null

  return (
    <>
      <TitleText sx={{ marginBottom: '30px' }}>{ABOUT_PAYMENT.RENT_TITLE}</TitleText>

      <Typography sx={{ fontSize: '1.5rem', marginBottom: '12px' }} variant="p">
        {ABOUT_PAYMENT.HOW_OFTEN}
      </Typography>
      <RadioGroup
        value={localPayment?.type || ''}
        onChange={e => {
          const howOftenFields = { type: e.target.value, repeat: undefined }
          if (e.target.value === 'recurring') {
            howOftenFields.repeat = 'monthly'
          }
          setLocalPaymentField(howOftenFields)
        }}
        sx={{ marginLeft: '5px', marginBottom: '15px' }}
        required
      >
        {['recurring', 'oneTime'].map(type => (
          <FormControlLabel
            key={type}
            value={type}
            control={
              <Radio
                sx={{ padding: '4px 9px' }}
                inputProps={{
                  'data-testid': `payment-type-${type.toLowerCase()}`,
                }}
              />
            }
            label={
              <Typography variant="h5">
                {type === 'recurring' ? ABOUT_PAYMENT.MONTHLY : ABOUT_PAYMENT.ONE_TIME}
              </Typography>
            }
          />
        ))}
      </RadioGroup>

      <Typography sx={inputStyles} variant="p">
        {ABOUT_PAYMENT.HOW_MUCH}
      </Typography>
      {/* TODO: ui.rentspree.com TextInput isn't doing floating labels like mocks want */}
      <TextInput
        label="Amount"
        startAdornment="$"
        sx={inputStyles}
        error={errors?.amount !== undefined}
        helperText={errors?.amount}
        inputProps={{
          'data-testid': `payment-amount`,
        }}
        value={localPayment?.amount}
        onChange={e => setLocalPaymentField({ amount: e.target.value })}
        onBlur={e => {
          let errorMessage
          let val = e.target.value
          if (isDecimal(val)) {
            val = parseFloat(val).toFixed(2)
          } else {
            errorMessage = ABOUT_PAYMENT.ERROR_AMOUNT
          }

          setLocalPaymentField({ amount: val })
          setErrors({ ...errors, amount: errorMessage })
        }}
        required
      />

      <Typography sx={inputStyles} variant="p">
        {ABOUT_PAYMENT.HOW_LONG}
      </Typography>
      <DatePickerInput
        label={ABOUT_PAYMENT.START_DATE_HELPER_TEXT}
        sx={inputStyles}
        name="startDate"
        inputProps={{
          'data-testid': `payment-start-date-picker`,
        }}
        value={startDate}
        minDate={dayjs()}
        error={Boolean(errors?.startDate)}
        helperText={typeof errors?.startDate === 'string' ? errors.startDate : undefined}
        onDateChange={date => {
          const nextDueOn = date ? utcDate(date).date() : null
          setLocalPaymentField({ startDate: date, dueOn: nextDueOn })
        }}
        onBlur={e => {
          // the input field triggers separately from the DateInput's onDateChange handler
          const date = e.target.value
          const nextDueOn = date ? utcDate(date).date() : null
          setLocalPaymentField({ startDate: date || null, dueOn: nextDueOn })
        }}
        required
      />
      <DatePickerInput
        sx={inputStyles}
        label={ABOUT_PAYMENT.END_DATE_HELPER_TEXT}
        name="endDate"
        inputProps={{
          'data-testid': `payment-end-date-picker`,
        }}
        minDate={dayjs(localPayment?.startDate)}
        value={!endDateDisabled ? endDate : null}
        error={Boolean(errors?.endDate) && !endDateDisabled}
        helperText={
          errors?.endDate === undefined ? ABOUT_PAYMENT.HELPER_TEXT_END_DATE : errors.endDate
        }
        onDateChange={date => {
          setLocalPaymentField({ endDate: date || null })
        }}
        onBlur={e => {
          // the input field triggers separately from the DateInput's onDateChange handler
          const date = e.target.value
          setLocalPaymentField({ endDate: date || null })
        }}
        disabled={endDateDisabled}
      />

      <TextInput
        label="Note"
        inputProps={{
          'data-testid': `payment-description-input`,
        }}
        helperText={ABOUT_PAYMENT.HELPER_TEXT_NOTE}
        value={localPayment?.description}
        onChange={e => setLocalPaymentField({ description: e.target.value })}
        sx={inputStyles}
        multiline
      />
    </>
  )
}
