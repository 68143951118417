import { GET_PAYEE_RISK_LEVEL, getPayeeRiskLevelAPI } from 'containers/rent-payment/actions'
import { getPayeeRiskLevelInstance } from 'containers/rent-payment/api-instances'
import { call, put, takeLatest } from 'redux-saga/effects'

export function* getPayeeRiskLevelSaga() {
  yield put(getPayeeRiskLevelAPI.request())
  try {
    const { riskLevel } = yield call(getPayeeRiskLevelInstance)
    yield put(getPayeeRiskLevelAPI.success(riskLevel))
  } catch (err) {
    yield put(getPayeeRiskLevelAPI.failure(err))
  }
}

export function* watchGetPayeeRiskLevelSaga() {
  yield takeLatest(GET_PAYEE_RISK_LEVEL, getPayeeRiskLevelSaga)
}
