import { API_URL } from 'env'

export const TASK_API = `${API_URL}/api/v2/people/tasks/:taskId`
export const GET_ALL_TASKS_API = `${API_URL}/api/v2/people/contacts/:contactId/tasks`
export const MARK_TASK_API = `${API_URL}/api/v2/people/tasks/:taskId/mark`
export const SET_SEEN_API = `${API_URL}/api/v2/people/tasks/batch/seen`

export const TASK_TASK_API = `${API_URL}/api/v2/tasks/tasks/:taskId`
export const TASK_GET_ALL_TASKS_API = `${API_URL}/api/v2/tasks/tasks`
export const TASK_MARK_TASK_API = `${API_URL}/api/v2/tasks/tasks/:taskId/mark`
export const TASK_SET_SEEN_API = `${API_URL}/api/v2/tasks/tasks/batch/seen`

export const DEFAULT_LIMIT = 10
export const DEFAULT_SORT = -1
export const TASK_ERROR_TOAST_MESSAGE = 'Error occurred, please try again'

export const TOOLTIP_MOBILE_TITLE = 'Task Suggestion'

const SCREENING_REQUEST_ACCEPTED_TEMPLATE_NAME = 'screening_request_accepted'
const APPLICATION_SUBMITTED_TEMPLATE_NAME = 'application_submitted'
const SHARE_TO_LANDLORD_TEMPLATE_NAME = 'share_to_landlord'
const SUBMITTED_TOOLTIP_MESSAGE = 'Task suggested because you viewed the Tenant Screening report.'
const ACCEPTED_TOOLTIP_MESSAGE = 'Task suggested because you sent an approval letter.'
const SHARE_TO_LANDLORD_TOOLTIP_MESSAGE =
  'Task suggested because you viewed the Tenant Screening report.'

export const TOOLTIP_MESSAGE = {
  [SCREENING_REQUEST_ACCEPTED_TEMPLATE_NAME]: ACCEPTED_TOOLTIP_MESSAGE,
  [APPLICATION_SUBMITTED_TEMPLATE_NAME]: SUBMITTED_TOOLTIP_MESSAGE,
  [SHARE_TO_LANDLORD_TEMPLATE_NAME]: SHARE_TO_LANDLORD_TOOLTIP_MESSAGE,
}

export const FETCHING_TYPE = {
  RECENTLY: 'recently',
  MONTHLY: 'monthly',
}

export const DISPLAY_TYPE = {
  LAZYLOADED: 'lazyloaded',
  PAGINATED: 'paginated',
}

export const GET_TASKS_BY_CONTACT_ID = 'GET_TASKS_BY_CONTACT_ID'
export const GET_TASKS_BY_CONTACT_ID_REQUEST = 'GET_TASKS_BY_CONTACT_ID_REQUEST'
export const GET_TASKS_BY_CONTACT_ID_SUCCESS = 'GET_TASKS_BY_CONTACT_ID_SUCCESS'
export const GET_TASKS_BY_CONTACT_ID_FAILURE = 'GET_TASKS_BY_CONTACT_ID_FAILURE'

export const GET_UPCOMING_TASKS_BY_CONTACT_ID = 'GET_UPCOMING_TASKS_BY_CONTACT_ID'
export const GET_UPCOMING_TASKS_BY_CONTACT_ID_REQUEST = 'GET_UPCOMING_TASKS_BY_CONTACT_ID_REQUEST'
export const GET_UPCOMING_TASKS_BY_CONTACT_ID_SUCCESS = 'GET_UPCOMING_TASKS_BY_CONTACT_ID_SUCCESS'
export const GET_UPCOMING_TASKS_BY_CONTACT_ID_FAILURE = 'GET_UPCOMING_TASKS_BY_CONTACT_ID_FAILURE'

export const CLEAR_ALL_TASK = 'CLEAR_ALL_TASK'
export const CLEAR_TASKS_BY_CONTACT_ID = 'CLEAR_TASKS_BY_CONTACT_ID'

export const DELETE_TASK_CALL = 'DELETE_TASK_CALL'
export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE'
export const DELETE_TASK_SUCCESS_TOAST_MESSAGE = 'Task Deleted'

export const MARK_TASK_CALL = 'MARK_TASK_CALL'
export const MARK_TASK_REQUEST = 'MARK_TASK_REQUEST'
export const MARK_TASK_SUCCESS = 'MARK_TASK_SUCCESS'
export const MARK_TASK_FAILURE = 'MARK_TASK_FAILURE'

export const UPDATE_TASK_FROM_CONTACT_CALL = 'UPDATE_TASK_FROM_CONTACT_CALL'
export const UPDATE_TASK_FROM_CONTACT_REQUEST = 'UPDATE_TASK_FROM_CONTACT_REQUEST'
export const UPDATE_TASK_FROM_CONTACT_SUCCESS = 'UPDATE_TASK_FROM_CONTACT_SUCCESS'
export const UPDATE_TASK_FROM_CONTACT_FAILURE = 'UPDATE_TASK_FROM_CONTACT_FAILURE'

export const MARK_TASK_TOAST_SUCCESS = 'Task Done'
export const MARK_TASK_TOAST_FAILURE = 'Error occurred, please try again'
export const UPDATE_TASK_SUCCESS_MESSAGE = 'Task updated'

export const CONTACT_SET_SEEN_TASKS_CALL = 'CONTACT_SET_SEEN_TASKS_CALL'
export const CONTACT_SET_SEEN_TASKS_REQUEST = 'CONTACT_SET_SEEN_TASKS_REQUEST'
export const CONTACT_SET_SEEN_TASKS_SUCCESS = 'CONTACT_SET_SEEN_TASKS_SUCCESS'
export const CONTACT_SET_SEEN_TASKS_FAILURE = 'CONTACT_SET_SEEN_TASKS_FAILURE'

export const TEMPLATES = {
  EDOC: 'edoc',
  TENANT_SCREENING: 'tenant_screening',
  CONTACT: 'contact',
  MANUAL: 'manual',
  BIRTHDAY_ANNIVERSARY: 'birthday_anniversary',
  MOVE_IN_FIRST_TIME: 'move_in_first_time',
  MOVE_IN_ANNIVERSARY: 'move_in_anniversary',
  MOVING_IN: 'moving_in',
  SCREENING_REQUEST_ACCEPTED: 'screening_request_accepted',
  APPLICATION_SUBMITTED: 'application_submitted',
  SHARE_TO_LANDLORD: 'share_to_landlord',
}
