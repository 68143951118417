import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import {
  makeSelectRentersInsuranceDetail,
  makeSelectIsFetching,
  makeSelectIsError,
} from "./selectors"
import reducer from "./reducer"
import * as actions from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  rentersInsuranceDetail: makeSelectRentersInsuranceDetail(),
  isFetching: makeSelectIsFetching(),
  isError: makeSelectIsError(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({
  key: "rentersInsuranceDetail",
  reducer,
})
export const withSaga = injectSaga({ key: "rentersInsuranceDetail", saga })
