import injectReducer from "utils/inject-reducer"
import injectSaga from "utils/inject-saga"
import saga from "./saga"
import reducer from "./reducer"

export const withSaga = injectSaga({ key: "submissionParticipant", saga })
export const withReducer = injectReducer({
  key: "submissionParticipant",
  reducer,
})
