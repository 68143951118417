import generateApiCall from 'utils/generate-api-call'
import {
  CREATE_AND_ASSIGN_PROPERTY_CALL,
  CREATE_AND_ASSIGN_PROPERTY_REQUEST,
  CREATE_AND_ASSIGN_PROPERTY_SUCCESS,
  CREATE_AND_ASSIGN_PROPERTY_FAILURE,
  CREATE_AND_SELECT_PROPERTY_CALL,
  CREATE_AND_SELECT_PROPERTY_REQUEST,
  CREATE_AND_SELECT_PROPERTY_SUCCESS,
  CREATE_AND_SELECT_PROPERTY_FAILURE,
  ASSIGN_PROPERTY_CALL,
  ASSIGN_PROPERTY_REQUEST,
  ASSIGN_PROPERTY_SUCCESS,
  ASSIGN_PROPERTY_FAILURE,
  GET_ASSIGN_PROPERTY_LIST_CALL,
  AUTO_SELECT_PROPERTY,
  SELECT_PROPERTY,
  APPEND_ASSIGN_PROPERTY_LIST,
  OPEN_CREATE_PROPERTY_MODAL,
  CLOSE_CREATE_PROPERTY_MODAL,
  CLEAR_CREATE_PROPERTY_MODAL_ERROR,
  GET_ASSIGN_PROPERTY_LIST_REQUEST,
  GET_ASSIGN_PROPERTY_LIST_SUCCESS,
  GET_ASSIGN_PROPERTY_LIST_FAILURE,
  OPEN_EXISTING_SUBMISSION_ERROR_MODAL,
  OPEN_ASSIGN_PROPERTY_MODAL,
  CLOSE_ASSIGN_PROPERTY_MODAL,
} from './constants'

export const createAndAssignProperty = ({ property, submissionParticipantId }) => ({
  type: CREATE_AND_ASSIGN_PROPERTY_CALL,
  payload: {
    property,
    submissionParticipantId,
  },
})

export const createAndAssignPropertyApiState = generateApiCall([
  CREATE_AND_ASSIGN_PROPERTY_REQUEST,
  CREATE_AND_ASSIGN_PROPERTY_SUCCESS,
  CREATE_AND_ASSIGN_PROPERTY_FAILURE,
])

export const createAndSelectProperty = ({ property }) => ({
  type: CREATE_AND_SELECT_PROPERTY_CALL,
  payload: {
    property,
  },
})

export const createAndSelectPropertyApiState = generateApiCall([
  CREATE_AND_SELECT_PROPERTY_REQUEST,
  CREATE_AND_SELECT_PROPERTY_SUCCESS,
  CREATE_AND_SELECT_PROPERTY_FAILURE,
])

export const assignProperty = ({
  propertyId,
  propertyAddress,
  submissionParticipantId,
  nextAction,
  nextActionProps,
  queryOptions,
  isAssignToCreatedProperty,
}) => ({
  type: ASSIGN_PROPERTY_CALL,
  payload: {
    propertyId,
    propertyAddress,
    submissionParticipantId,
    nextAction,
    nextActionProps,
    queryOptions,
    isAssignToCreatedProperty,
  },
})

export const assignPropertyApiState = generateApiCall([
  ASSIGN_PROPERTY_REQUEST,
  ASSIGN_PROPERTY_SUCCESS,
  ASSIGN_PROPERTY_FAILURE,
])

export const getAssignPropertyList = () => ({
  type: GET_ASSIGN_PROPERTY_LIST_CALL,
})

export const getAssignPropertyListApiState = generateApiCall([
  GET_ASSIGN_PROPERTY_LIST_REQUEST,
  GET_ASSIGN_PROPERTY_LIST_SUCCESS,
  GET_ASSIGN_PROPERTY_LIST_FAILURE,
])

export const autoSelectProperty = payload => ({
  type: AUTO_SELECT_PROPERTY,
  payload,
})

export const selectProperty = payload => ({
  type: SELECT_PROPERTY,
  payload,
})

export const appendAssignPropertyList = payload => ({
  type: APPEND_ASSIGN_PROPERTY_LIST,
  payload,
})

export const openCreatePropertyModal = () => ({
  type: OPEN_CREATE_PROPERTY_MODAL,
})

export const closeCreatePropertyModal = () => ({
  type: CLOSE_CREATE_PROPERTY_MODAL,
})

export const closeAssignPropertyModal = () => ({
  type: CLOSE_ASSIGN_PROPERTY_MODAL,
})

export const clearCreatePropertyModalError = () => ({
  type: CLEAR_CREATE_PROPERTY_MODAL_ERROR,
})

export const openExistingSubmissionErrorModal = (isOpen, errorPayload) => ({
  type: OPEN_EXISTING_SUBMISSION_ERROR_MODAL,
  payload: {
    isOpen,
    errorPayload,
  },
})

export const openAssignPropertyModal = ({ participantId, nextAction, nextActionProps }) => ({
  type: OPEN_ASSIGN_PROPERTY_MODAL,
  payload: {
    participantId,
    nextAction,
    nextActionProps,
  },
})
