import { object } from 'yup'

import {
  validatePhone,
  validateEmailForRentPayment,
  validateLength,
} from 'legacy/constants/validations'

export const validationSchema = ({ ownerEmail }) =>
  object().shape({
    firstName: validateLength(),
    lastName: validateLength(),
    email: validateEmailForRentPayment(true, ownerEmail),
    phone: validatePhone(false),
  })

export const mapPropsToValues = ({ firstName = '', lastName = '', phone = '', email = '' }) => ({
  firstName,
  lastName,
  email,
  phone,
})

export const handleSubmit = (values, { props, setSubmitting, setErrors }) => {
  props.onSubmit(values, { setErrors })
  setSubmitting(false)
}

export const schema = {
  validationSchema,
  mapPropsToValues,
  handleSubmit,
  enableReinitialize: true,
  displayName: 'CreateTenantForm',
}
