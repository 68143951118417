import generateApiCall from 'utils/generate-api-call'

import {
  FETCH_VERIFY_PROPERTY_ADDRESS,
  FETCH_VERIFY_PROPERTY_ADDRESS_REQUEST,
  FETCH_VERIFY_PROPERTY_ADDRESS_SUCCESS,
  FETCH_VERIFY_PROPERTY_ADDRESS_FAILURE,
  RESET_ADDRESS_VERIFICATION,
} from './constants'

export const fetchVerifyPropertyAddressAPI = generateApiCall([
  FETCH_VERIFY_PROPERTY_ADDRESS_REQUEST,
  FETCH_VERIFY_PROPERTY_ADDRESS_SUCCESS,
  FETCH_VERIFY_PROPERTY_ADDRESS_FAILURE,
])

export const fetchVerifyPropertyAddress = payload => ({
  type: FETCH_VERIFY_PROPERTY_ADDRESS,
  payload,
})

export const resetAddressVerification = () => ({
  type: RESET_ADDRESS_VERIFICATION,
})
