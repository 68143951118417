import get from 'lodash/get'
import { createSelector } from 'reselect'

import { API_STATUS, KEY, PAYOUT_METHOD_STATUS } from '../constants'
import { paymentAccountInitialState } from '../reducers'

export const selectPaymentAccountState = state =>
  get(state, KEY.PAYMENT_ACCOUNT, paymentAccountInitialState)

// rental payment list
export const makeSelectPayoutMethodList = () =>
  createSelector(selectPaymentAccountState, state => get(state, 'payoutMethodList', {}))

export const makeSelectPayoutMethodListData = () =>
  createSelector(makeSelectPayoutMethodList(), state => get(state, 'data.data', []))

export const makeSelectPayoutMethodListStatus = () =>
  createSelector(makeSelectPayoutMethodList(), state => get(state, 'status', API_STATUS.NONE))

export const makeSelectPendingPayoutMethod = () =>
  createSelector(makeSelectPayoutMethodListData(), state =>
    state?.find(payoutMethod => payoutMethod.verification?.status === PAYOUT_METHOD_STATUS.PENDING),
  )

export const makeSelectVerifiedPayoutMethod = () =>
  createSelector(makeSelectPayoutMethodListData(), state =>
    state?.find(
      payoutMethod => payoutMethod.verification?.status === PAYOUT_METHOD_STATUS.VERIFIED,
    ),
  )

export const makeSelectFailedPayoutMethod = () =>
  createSelector(makeSelectPayoutMethodListData(), state =>
    state?.find(payoutMethod => payoutMethod.verification?.status === PAYOUT_METHOD_STATUS.FAILED),
  )

export const makeSelectSendInvitationEmail = () =>
  createSelector(selectPaymentAccountState, state => get(state, 'sendInvitationEmail', {}))

export const makeSelectSendInvitationEmailStatus = () =>
  createSelector(makeSelectSendInvitationEmail(), state => get(state, 'status', API_STATUS.NONE))

// payee idv update link
export const makeSelectPayeeIdvUpdateLink = () =>
  createSelector(selectPaymentAccountState, state => get(state, 'payeeIdvUpdateLink'))

export const makeSelectPayeeIdvUpdateLinkData = () =>
  createSelector(makeSelectPayeeIdvUpdateLink(), state => get(state, 'data'))

export const makeSelectPayeeIdvUpdateLinkStatus = () =>
  createSelector(makeSelectPayeeIdvUpdateLink(), state => get(state, 'status'))

export const makeSelectPayee = () =>
  createSelector(selectPaymentAccountState, state => get(state, 'payee', {}))

export const makeSelectPayeeData = () =>
  createSelector(makeSelectPayee(), state => get(state, 'data', {}))

export const makeSelectPayeeStatus = () =>
  createSelector(makeSelectPayee(), state => get(state, 'status', API_STATUS.NONE))

export const makeSelectProviderAccountInfo = () =>
  createSelector(selectPaymentAccountState, state =>
    get(state, 'payeeProviderAccountInformation', {}),
  )

export const makeSelectProviderAccountInfoData = () =>
  createSelector(makeSelectProviderAccountInfo(), state => get(state, 'data', {}))

export const makeSelectProviderAccountInfoStatus = () =>
  createSelector(makeSelectProviderAccountInfo(), state => get(state, 'status', API_STATUS.NONE))
