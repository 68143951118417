import styled from "styled-components"
import { COLOR } from "styles/settings"

export * from "./text"

export const Hr = styled.hr`
  color: ${COLOR.lineGrey};
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    ${props => props.mMargin && `margin: ${props.mMargin}`};
  }
`
