/* eslint-disable react/no-danger */
import React from "react"
import styled from "styled-components"
import {
  S24,
  B16,
  BlueGradientButton,
  ErrorAlert,
  Button,
  RedGradientButton,
} from "@rentspree/component-v2"
export const LayoutModalButtonRight = styled.div`
  font-family: "Source Sans Pro";
  button {
    float: right;
    margin: 0px 5px;
  }
`
export const BodyWrapper = styled.div`
  padding: 5px 0px 30px 0px;
  b {
    font-weight: 600;
  }
`
const ConfirmModalBody = ({
  title,
  error,
  handleClickBtn,
  confirmTxt,
  cancelTxt,
  descText,
  isLoading,
  onClearError,
  onClose,
  redBtn,
}) => {
  const id = redBtn ? "redBtnModal" : "smBtn"
  const GradientButton = redBtn ? RedGradientButton : BlueGradientButton
  return (
    <LayoutModalButtonRight>
      <S24 margin="0px 0px 10px 0px" mMargin="0px 15px 10px 0px" mSize="24px">
        {title}
      </S24>
      {error && (
        <ErrorAlert
          errorMessage={error.errorMessage}
          secondaryMessage={error.secondaryMessage}
          onClose={onClearError}
          margin="10px 0"
        />
      )}

      <BodyWrapper>
        <B16 margin="0px" mMargin="0px">
          <span>
            <div dangerouslySetInnerHTML={{ __html: descText }} />
          </span>
        </B16>
      </BodyWrapper>

      <GradientButton
        text={confirmTxt}
        onClick={handleClickBtn}
        loading={isLoading}
        small
        semiBold
        height="35px"
        id={id}
        fontSize="14px"
      />

      <Button
        google
        small
        semiBold
        height="35px"
        fontSize="14px"
        id="CancelVoidBtn"
        text={cancelTxt || "Cancel"}
        type="button"
        disabled={isLoading}
        onClick={onClose}
      />
    </LayoutModalButtonRight>
  )
}
ConfirmModalBody.defaultProps = {
  title: "String",
  error: false,
  confirmTxt: "next",
  descText: "this is a  desc text",
  handleClickBtn: () => {},
  isLoading: false,
  onClearError: () => {},
  terms: {},
}

export default ConfirmModalBody
