import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectReducer from "utils/inject-reducer"
import injectSaga from "utils/inject-saga"

import { selectProperty } from "containers/listing/selectors"
import { selectProfile } from "containers/user/selectors"
import saga from "./saga"
import {
  selectAcceptDeny,
  selectIsShowEmailPreview,
  selectIsOpen,
  selectIsShowDisclaimer,
  selectIsShowForm,
  selectRenterName,
  selectBaseEventProperty,
  selectAcceptDenyPropertyAddress,
} from "./selectors"
import {
  clearAcceptDenyModal,
  changeAcceptDenyType,
  acceptDenyCall,
  completeAcceptOnConditionForm,
  completeDenyForm,
  backToForm,
  getDisclaimerContentCall,
  agreeDisclaimerCall,
} from "./actions"
import reducer from "./reducer"

const mapStateToProps = createStructuredSelector({
  acceptDeny: selectAcceptDeny,
  isOpen: selectIsOpen,
  isShowEmailPreview: selectIsShowEmailPreview,
  isShowDisclaimer: selectIsShowDisclaimer,
  isShowForm: selectIsShowForm,
  property: selectAcceptDenyPropertyAddress,
  propertyByListing: selectProperty,
  profile: selectProfile,
  renterName: selectRenterName,
  eventProperty: selectBaseEventProperty,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      clearAcceptDenyModal,
      changeAcceptDenyType,
      getDisclaimerContentCall,
      acceptDenyCall,
      completeAcceptOnConditionForm,
      completeDenyForm,
      backToForm,
      agreeDisclaimerCall,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "acceptDenyModal", saga })
export const withReducer = injectReducer({
  key: "acceptDeny",
  reducer,
})
