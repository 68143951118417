import React from 'react'
import { convert } from 'helpers/convert'
import {
  Content,
  InformationPanel,
  LineBreak,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  InformationLine,
  SectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'

export const OffenseCase = ({ offenseCase }) => {
  const EMPTY_LINE = 'EMPTY_LINE'
  const formatDate = 'MMM DD, YYYY'

  const displayLocalDate = date => convert(date, 'dateOfLocal', formatDate)

  const dataRows = [
    { title: 'Sequence', value: offenseCase.Sequence },
    { title: 'Offense Date', value: displayLocalDate(offenseCase.OffenseDate) },
    { title: 'Number of Counts', value: offenseCase.NumberOfCounts },
    { title: 'Charge Date', value: displayLocalDate(offenseCase.ChargeDate) },
    { title: EMPTY_LINE },

    { title: 'Status Date', value: displayLocalDate(offenseCase.StatusDate) },
    { title: 'Description', value: offenseCase.Description },
    { title: EMPTY_LINE },

    { title: 'Statute', value: offenseCase.Statute },
    { title: 'Plea Date', value: displayLocalDate(offenseCase.PleaDate) },
    { title: 'Charge Modifier', value: offenseCase.ChargeModifier },
    { title: EMPTY_LINE },

    { title: 'Type', value: offenseCase.Type },
    { title: 'Charge Degree', value: offenseCase.Degree },
    { title: 'Level', value: offenseCase.Level },
    { title: EMPTY_LINE },

    {
      title: 'Offense Type Description',
      value: offenseCase.OffenseTypeDescription,
    },
    { title: 'Plea', value: offenseCase.Plea },
    { title: EMPTY_LINE },

    {
      title: 'Disposition Description',
      value: offenseCase.DispositionDescription,
    },
    {
      title: 'Disposition Date',
      value: displayLocalDate(offenseCase.DispositionDate),
    },
    {
      title: 'Disposition Status',
      value: offenseCase.DispositionStatus,
    },
    {
      title: 'Disposition Status Date',
      value: displayLocalDate(offenseCase.DispositionStatusDate),
    },
    {
      title: 'Disposition Conviction Date',
      value: displayLocalDate(offenseCase.DispositionConvictionDate),
    },
    {
      title: 'Disposition Country',
      value: offenseCase.DispositionCounty,
    },
    { title: EMPTY_LINE },

    { title: 'Sentence Term', value: offenseCase.SentenceTerm },
    { title: 'Parole Term', value: offenseCase.ParoleTerm },
    { title: 'Arrest Date', value: displayLocalDate(offenseCase.ArrestDate) },
    { title: 'Parole Date', value: displayLocalDate(offenseCase.ParoleDate) },
    {
      title: 'Admitted Date',
      value: displayLocalDate(offenseCase.AdmittedDate),
    },
    { title: EMPTY_LINE },

    { title: 'NCIC Code', value: offenseCase.NcicCode },
    { title: EMPTY_LINE },

    {
      title: 'Minimum Sentence Term',
      value: offenseCase.MinSentenceTerm,
    },
    {
      title: 'Maximum Sentence Term',
      value: offenseCase.MaxSentenceTerm,
    },
    { title: EMPTY_LINE },
    { title: 'Probation Term', value: offenseCase.ProbationTerm },
    {
      title: 'Sentence Date',
      value: displayLocalDate(offenseCase.SentenceDate),
    },
    {
      title: 'Probation Date',
      value: displayLocalDate(offenseCase.ProbationDate),
    },
    { title: 'Release Date', value: displayLocalDate(offenseCase.ReleaseDate) },
    { title: EMPTY_LINE },
    { title: 'Fines', value: offenseCase.Fines },
    { title: 'Court Costs', value: offenseCase.CourtCosts },
    { title: 'Offense County', value: offenseCase.OffenseCounty },
  ]

  return (
    <Section>
      <SectionHeader title="Offense Case" />
      <SubSection>
        <Content>
          <InformationPanel>
            {dataRows.map((value, index) => {
              if (value.title === EMPTY_LINE) {
                return <LineBreak key={`${value.title}-${index}`} />
              }

              return (
                <InformationLine
                  key={value.title}
                  label={value.title}
                  value={value.value || '-'}
                  labelWidth="calc((100% - 20px) / 3)"
                />
              )
            })}
          </InformationPanel>
        </Content>
      </SubSection>
    </Section>
  )
}
