import React from 'react'
import styled, { css } from 'styled-components'

import Tooltip from '@rentspree/component-v2/dist/tooltip'
import B16 from '@rentspree/component-v2/dist/typography/b16'
import { COLOR } from '../../../styles/settings'

export const StyledTooltipContent = styled(Tooltip)`
  ${props =>
    props.pMargin &&
    css`
      p {
        margin-bottom: 8px;
      }
    `}

  .tooltip-mobile-container {
    z-index: 2000;
  }
  .tooltiptext {
    ${props => props.textAlign && `text-align: ${props.textAlign};`}
    background: ${COLOR.textBlack};
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    ${props => props.padding && `padding: ${props.padding};`}
    &::before {
      background: ${COLOR.textBlack};
    }
    top: 6px;
    left: 22px;
    ${props => props.boxPadding && `left: ${22 - props.boxPadding}px`};
    ${props =>
      props.tooltipOffset &&
      css`
        top: ${props.tooltipOffset.top || 'unset'};
        right: ${props.tooltipOffset.right || 'unset'};
        left: ${props.tooltipOffset.left || 'unset'};
        bottom: ${props.tooltipOffset.bottom || 'unset'};
      `}
    ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  }

  #tooltipBox {
    ${props => props.boxPadding && `padding: ${props.boxPadding}px`};
    ${props =>
      props.noIcon &&
      css`
        i {
          width: 0;
        }
      `}
    ${props =>
      props.iconWidth &&
      css`
        i {
          width: ${props.iconWidth};
        }
      `}
  }

  @media (max-width: 991px) {
    #tooltipBox {
      ${props => props.mBoxPadding && `padding: ${props.mBoxPadding}`};
    }
  }
`
export const TooltipDescription = styled(B16)`
  color: ${COLOR.white};
  font-size: 12px;
  margin-bottom: 0px !important;
  @media (max-width: 991px) {
    color: ${props => (props.mColor ? props.mColor : 'initial')};
    line-height: 22px !important;
    font-size: 16px !important;
  }
  font-family: Source Sans Pro !important;
`

export const TooltipMobileTitle = styled(B16)`
  display: none;
  @media (max-width: 991px) {
    display: block;
    line-height: 32px !important;
    font-size: 24px !important;
    margin-bottom: 10px;
  }
`
const ToolsTip = ({
  iconName,
  iconSize,
  iconMargin,
  iconMarginMobile,
  noIcon,
  iconWidth,
  title,
  titleSize,
  mTitleSize,
  color,
  colorTitle,
  position,
  positionCustom,
  tooltipOffset,
  mobileTitle,
  description,
  pMargin,
  width,
  mobileModalPosition = { top: '50px' },
  boxPadding,
  mBoxPadding,
  mColor,
  disableMobile,
  textAlign,
  padding,
  borderRadius,
}) => (
  <StyledTooltipContent
    id="tooltip"
    iconName={iconName}
    iconSize={iconSize}
    iconMargin={iconMargin}
    iconMarginMoblie={iconMarginMobile}
    noIcon={noIcon}
    iconWidth={iconWidth}
    color={color}
    margin="0px"
    pMargin={pMargin}
    textAlign={textAlign}
    title={title}
    titleSize={titleSize}
    mTitleSize={mTitleSize}
    colorTitle={colorTitle}
    position={position}
    positionCustom={positionCustom}
    tooltipOffset={tooltipOffset}
    bubbleWidth={width}
    boxPadding={boxPadding}
    disableMobile={disableMobile}
    mBoxPadding={mBoxPadding}
    mobileModalPosition={mobileModalPosition}
    padding={padding}
    borderRadius={borderRadius}
  >
    <TooltipMobileTitle weight="600">{mobileTitle}</TooltipMobileTitle>
    <TooltipDescription mColor={mColor}>{description}</TooltipDescription>
  </StyledTooltipContent>
)
ToolsTip.defaultProps = {
  color: '#AAAAAB',
  iconSize: '14px',
  iconMargin: '0',
  iconMarginMobile: '0',
  title: '',
  description: '',
  position: 'TOP',
  mobileTitle: '',
  width: '195px',
}

export default ToolsTip
