import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"

import {
  selectProperty,
  selectIsPropertyFetching,
} from "containers/listing/selectors"
import { getProperty, clearProperty } from "legacy/actions/property.action"

import {
  importMLSListing,
  getImportableListing,
  autoImportMLSListing,
} from "./actions"
import injectReducer from "../../utils/inject-reducer"
import injectSaga from "../../utils/inject-saga"
import reducer from "./reducer"
import saga from "./saga"
import { selectIsFetched, selectIsMLSListingImportable } from "./selectors"

export const mapStateToProps = () =>
  createStructuredSelector({
    isFetched: selectIsFetched,
    MLSListing: selectIsMLSListingImportable,
    property: selectProperty,
    isFetchingProperty: selectIsPropertyFetching,
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        importMLSListing,
        getImportableListing,
        getProperty,
        clearProperty,
        autoImportMLSListing,
      },
      dispatch,
    ),
  }
}

export const withSaga = injectSaga({ key: "importListing", saga })
export const withReducer = injectReducer({ key: "importListing", reducer })

export const withImportListingConnect = compose(
  withSaga,
  withReducer,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
