/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getPayee } from '../redux/actions'

export const usePayeeInterval = ({
  initialInterval,
  computeNextInterval = currentInterval => currentInterval,
  isPaused,
}) => {
  const dispatch = useDispatch()
  const interval = useRef(null)
  const [intervalTime, setIntervalTime] = useState(initialInterval)

  const fetchData = () => {
    dispatch(getPayee())
    setIntervalTime(prevIntervalTime => computeNextInterval(prevIntervalTime))
  }

  const clearFetchInterval = () => {
    if (interval.current) {
      clearInterval(interval.current)
      interval.current = null
    }
  }

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }

    if (!isPaused) {
      interval.current = setInterval(fetchData, intervalTime)
    }

    return () => {
      clearFetchInterval()
    }
  }, [intervalTime, isPaused])

  return {
    clearFetchInterval,
  }
}
