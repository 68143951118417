/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import classnames from 'classnames/bind'
import { PanelGroup, Panel, Popover, OverlayTrigger } from 'react-bootstrap'
import { RadioButton } from '@rentspree/component-v2'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import CopyToClipboard from 'react-copy-to-clipboard'
import withQuery from 'with-query'
import { CONTACT_SOURCES } from 'containers/contacts/constants'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import styles from '../left-content/left-content.scss'
import { SCREENING_METHODS } from '../../../constants/screening-plan-consts'
import { CustomMessageForm } from './flyer-custom-message/form'
import { RequestReportEmailForm } from './request-report/request-report-email-form'
import { RequestReportPhoneNumberForm } from './request-report/request-report-phone-form'
import { ButtonContainer } from './request-report/styled'

import { PRINT_PDF_URL } from '../../../../env'
import { TrackerInstance as Tracker } from '../../../helpers/tracker'
import { removeSpaceCommaFromString } from '../../../helpers/validator-helper'
import tracker from '../../../tracker'

const c = classnames.bind(styles)
const flyerImg = require('images/icons/request/flyer-tooltip.png')

const iconRequestEmail = require('../../../images/icons/request/icon-request-email.png')
const iconRequestLink = require('../../../images/icons/request/icon-request-link.png')
const iconRequestFlyer = require('../../../images/icons/request/icon-request-flyer.png')

const tooltip = (
  <Popover id="flyerPopover" className={c('flyerPopover')}>
    <img src={flyerImg} alt="Flyer tooltip" />
  </Popover>
)

const requestMethodRadioChoices = [
  {
    label: 'Email',
    id: 'directRequestRadio-email',
    value: 'email',
    small: true,
  },
  {
    label: 'Text Message',
    id: 'directRequestRadio-textMessage',
    value: 'textMessage',
    small: true,
  },
]
export class ScreeningMethod extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailValues: props.location?.state?.emails || [],
      emailErrors: undefined,
      phoneValues: [],
      phoneErrors: undefined,
      activePanel: 0,
      directRequestMethod: 'email',
      copied: false,
      flyerCustomText: '',
      savedCustomText: false,
    }
  }

  componentDidMount() {
    this.autoOpenPanel(this.props.screeningMethod)
  }

  onPrint = async e => {
    tracker.trackEvent(EVENT_REQUEST_SCREENING.SHARE_APPLY_LINK, {
      sharing_method: EVENT_REQUEST_SCREENING.EVENT_PROPERTY.SHARING_METHOD.HANDOUT,
    })
    e.preventDefault()
    this.props.handleAction()
    const win = window.open('about:blank', '_blank')
    await this.props.actions.updateFlyerApi(this.state.flyerCustomText)
    const { subdomain, accessToken, screeningRequestId } = this.props
    const Sub = subdomain || 'rentspree'
    const url = withQuery(`${PRINT_PDF_URL}/flyer/${screeningRequestId}`, {
      accessToken,
      subdomain: Sub,
    })
    win.location.href = url
    return url
  }

  setActivePanel = num => {
    const selectPanel = Number(num)
    const { activePanel } = this.state
    this.setState({
      activePanel: activePanel === selectPanel ? 0 : selectPanel,
    })
  }

  setDirectRequestMethod = event => {
    this.props.actions.clearIsSendEmail()
    this.setState({ directRequestMethod: event.target.value })
  }

  autoOpenPanel(screenOption) {
    switch (screenOption) {
      case SCREENING_METHODS.EMAIL:
        this.setActivePanel(1)
        break
      case SCREENING_METHODS.LINK:
        this.setActivePanel(2)
        break
      case SCREENING_METHODS.FLYER:
        this.setActivePanel(3)
        break
      default:
        this.setActivePanel(1)
        break
    }
  }

  handleSaveFlyerCustomText = async text => {
    await this.setState({ savedCustomText: true, flyerCustomText: text })
    await this.props.actions.updateFlyerApi(this.state.flyerCustomText)
    tracker.trackEvent(EVENT_REQUEST_SCREENING.updateHandoutMessage)

    setTimeout(() => {
      this.setState({ savedCustomText: false })
    }, 1000)
  }

  handleSendRequests = values => {
    const { actions, screeningRequestId } = this.props
    const { directRequestMethod } = this.state

    const data = {}

    if (directRequestMethod === 'textMessage') {
      // Send sms
      Tracker.trackEvent('clickSendTextMessageRequest')
      const unformattedPhone = values.phoneNumber.map(phone => phone.replace(/\D+/g, ''))
      data.renterPhoneNumbers = unformattedPhone
      actions.sendTextMessage(screeningRequestId, data)
      this.setState({
        phoneValues: [],
        phoneErrors: undefined,
      })
    } else {
      // Send email
      Tracker.trackEvent('clickSendEmailRequest')
      const lowercaseEmails = values.email.map(email =>
        removeSpaceCommaFromString(email.toLowerCase()),
      )
      data.renterEmails = lowercaseEmails
      actions.sendEmails(screeningRequestId, data)

      this.setState({
        emailValues: [],
        emailErrors: undefined,
      })

      // Create contacts
      actions.createContacts(
        data.renterEmails.map(email => ({
          email,
          source: CONTACT_SOURCES.APPLICATION,
          automated: true,
        })),
      )
    }
    // setState to show back button
    this.props.handleAction()
  }

  sendMoreEmails = () => {
    Tracker.trackEvent('clickSendMoreRequest')
    this.setState({ emailValues: [] })
    this.props.actions.sendMoreEmails()
  }

  sendMoreTextMessage = () => {
    Tracker.trackEvent('clickSendMoreTextMessageRequest')
    this.setState({ phoneValues: [] })
    this.props.actions.sendMoreTextMessage()
  }

  // Copy permalink
  handleOnCopyLink = () => {
    this.setState({ copied: true })
    this.props.handleAction()
    tracker.trackEvent(EVENT_REQUEST_SCREENING.SHARE_APPLY_LINK, {
      sharing_method: EVENT_REQUEST_SCREENING.EVENT_PROPERTY.SHARING_METHOD.COPY_LINK,
    })
  }

  handleTagsInputChange = (values, isTagsInputEmail = true) => {
    if (isTagsInputEmail) this.setState({ emailValues: values })
    else this.setState({ phoneValues: values })
  }

  handleTagsInputErrors = (errors, isTagsInputEmail = true) => {
    if (isTagsInputEmail) this.setState({ emailErrors: errors })
    else this.setState({ phoneErrors: errors })
  }

  render() {
    const { activePanel, copied, savedCustomText } = this.state
    const { property, rentalSubmission, profile } = this.props
    const { shortenLink, permalink } = property.property
    return (
      <div className={c('innerLeftContent')}>
        <PanelGroup
          accordion
          id="accordion-controlled-example"
          activeKey={this.state.activePanel.toString()}
          onSelect={this.setActivePanel}
          className={c('accordionRequestMethod')}
        >
          <Panel className={c('accordionPanel', activePanel === 1 ? 'active' : '')} eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                <div
                  className={c('panelHeader', 'requestEmailBox')}
                  id="dashboardEmailActionPanelHeader"
                >
                  <img className={c('boxIcon')} src={iconRequestEmail} alt="icon-request-email" />
                  <div className={c('innerBoxTitle')}>
                    <h3 className={c('boxTitle', activePanel === 1 ? 'expandedBoxTitle' : '')}>
                      <strong>Request applications by email or text message</strong>
                    </h3>
                    <p className={c('boxSubtitle')}>Applicants will receive an ApplyLink&trade;</p>
                    <i className={c('panelToggle', 'icon-arrow-down')} />
                  </div>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <div className={c('panelBody')}>
                <div className={c('directRequestMethod')}>
                  <span>Request by</span>
                  {requestMethodRadioChoices.map(choice => (
                    <RadioButton
                      inline
                      name="directRequestRadio"
                      choice={choice}
                      className={c('directRequestMethodRadio')}
                      onChange={this.setDirectRequestMethod}
                      iconMargin="0px 4px 0px 0px"
                      margin="0px 5px 0px 10px"
                      checked={this.state.directRequestMethod === choice.value}
                    />
                  ))}
                </div>
                {this.state.directRequestMethod === 'email' ? (
                  <div id="dashboardEmailsListTags">
                    {!rentalSubmission.isEmailsSend ? (
                      <div className={c('emailTagsWrap')}>
                        <RequestReportEmailForm
                          onSubmit={this.handleSendRequests}
                          handleTagsInputChange={this.handleTagsInputChange}
                          handleTagsInputErrors={this.handleTagsInputErrors}
                          emailValues={this.state.emailValues}
                          emailErrors={this.state.emailErrors}
                        />
                      </div>
                    ) : (
                      <div className={c('emailSentSuccessDiv')}>
                        <p className={c('emailSentSuccessDesc')}>
                          <strong>The requests have been sent. </strong>
                          You&apos;ll receive an email notification when an application is
                          submitted.
                        </p>
                        <BlueGradientButton
                          id="dashboardSendMoreEmailsBtn"
                          type="button"
                          sourceSansPro
                          small
                          fontWeight="600"
                          minWidth="160"
                          height="35px"
                          lineHeight="35px"
                          onClick={this.sendMoreEmails}
                        >
                          Send More Requests
                        </BlueGradientButton>
                      </div>
                    )}
                  </div>
                ) : (
                  <div id="dashboardTextMessageListTags">
                    {!rentalSubmission.isTextMessageSend ? (
                      <div className={c('emailTagsWrap')}>
                        <RequestReportPhoneNumberForm
                          onSubmit={this.handleSendRequests}
                          handleTagsInputChange={this.handleTagsInputChange}
                          handleTagsInputErrors={this.handleTagsInputErrors}
                          phoneValues={this.state.phoneValues}
                          phoneErrors={this.state.phoneErrors}
                        />
                      </div>
                    ) : (
                      <div className={c('emailSentSuccessDiv')}>
                        <p className={c('emailSentSuccessDesc')}>
                          <strong>The requests have been sent. </strong>
                          You&apos;ll receive an email notification when an application is
                          submitted.
                        </p>
                        <BlueGradientButton
                          id="dashboardSendMoreEmailsBtn"
                          // className={c("btn", "sendMoreEmailBtn")}
                          type="button"
                          sourceSansPro
                          small
                          fontWeight="600"
                          minWidth="160"
                          height="35px"
                          lineHeight="35px"
                          onClick={this.sendMoreTextMessage}
                        >
                          Send More Requests
                        </BlueGradientButton>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Panel.Body>
          </Panel>
          <Panel
            key="panel-copy"
            className={c('accordionPanel', activePanel === 2 ? 'active' : '')}
            eventKey="2"
          >
            <Panel.Heading>
              <Panel.Title toggle>
                <div
                  className={c('panelHeader', 'directLinkBox')}
                  id="dashboardCopyLinkPanelHeader"
                >
                  <img className={c('boxIcon')} src={iconRequestLink} alt="icon-request-link" />
                  <div className={c('innerBoxTitle')}>
                    <h3 className={c('boxTitle', activePanel === 2 ? 'expandedBoxTitle' : '')}>
                      <strong>Share your application link</strong>
                    </h3>
                    <p className={c('boxSubtitle')}>
                      Post an ApplyLink&trade; to your website or social media.
                    </p>
                    <i className={c('panelToggle', 'icon-arrow-down')} />
                  </div>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <div className={c('panelBody')}>
                <div className={c('successGeneratedLink')}>
                  <input
                    id="dashboardPanelPermalinkInput"
                    type="text"
                    disabled
                    className={c('successLinkInput', 'textInput')}
                    value={property.isFetching ? '' : shortenLink || permalink}
                  />

                  <CopyToClipboard
                    text={property.isFetching ? '' : shortenLink || permalink}
                    onCopy={this.handleOnCopyLink}
                  >
                    <BlueGradientButton
                      className={c('copyLinkBtn', `${copied && 'copied'}`)}
                      id="dashboardCopyLinkBtn"
                      small
                      sourceSansPro
                      fontWeight="600"
                      minWidth="95"
                      height="35px"
                      lineHeight="35px"
                    >
                      {!copied ? 'Copy' : 'Copied'}
                    </BlueGradientButton>
                  </CopyToClipboard>
                </div>
              </div>
            </Panel.Body>
          </Panel>
          <Panel
            key="panel-flyer"
            className={c('accordionPanel', activePanel === 3 ? 'active' : '')}
            eventKey="3"
          >
            <Panel.Heading>
              <Panel.Title toggle>
                <div className={c('panelHeader')} id="dashboardPrintFlyerPanelHeader">
                  <img className={c('boxIcon')} src={iconRequestFlyer} alt="icon-request-flyer" />
                  <div className={c('innerBoxTitle')}>
                    <h3 className={c('boxTitle', activePanel === 3 ? 'expandedBoxTitle' : '')}>
                      <strong>Print application flyers</strong>
                    </h3>
                    <p className={c('boxSubtitle')}> Bring them to showings or open houses.</p>
                    <i className={c('panelToggle', 'icon-arrow-down')} />
                  </div>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <div className={c('panelBody')}>
                <div className={c('FlyerSection')}>
                  <div className={c('printFlyerIcon')}>
                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
                      <img src={flyerImg} alt="Flyer Icon" className={c('boxIcon')} />
                    </OverlayTrigger>
                  </div>
                  <CustomMessageForm
                    saveCustomMessage={this.handleSaveFlyerCustomText}
                    isSavedCustomMessage={this.state.savedCustomText}
                    customMessage={profile.flyerCustomText}
                  />
                </div>
                <ButtonContainer>
                  <BlueGradientButton
                    id="dashboardPanelPrintBtn"
                    type="button"
                    sourceSansPro
                    small
                    fontWeight="600"
                    minWidth="110"
                    // mWidth="100%"
                    height="35px"
                    margin="0 5px"
                    lineHeight="35px"
                    onClick={this.onPrint}
                    disabled={savedCustomText}
                  >
                    Print
                  </BlueGradientButton>
                </ButtonContainer>
              </div>
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </div>
    )
  }
}
