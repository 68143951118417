import React from "react"
import styled, { css } from "styled-components"
import T36 from "@rentspree/component-v2/dist/typography/t36"

export const TitleWrapper = styled.div`
  margin-bottom: ${props => props.marginBottom || "30px"};
  ${props => props.margin && `margin: ${props.margin};`}
  @media (max-width: 991px) {
    ${props => props.mMargin && `margin: ${props.mMargin};`}
  }
`

export const TitleDescription = styled.div`
  font-size: ${props => props.fontSize || "16px"};
  line-height: ${props => props.lineHeight || "22px"};
  ${css`
    ${props => props.descriptionStyle}
  `}
  @media (max-width: 991px) {
    ${css`
      ${props => props.mDescriptionStyle}
    `}
  }
`

export const TitleWithDescription = ({
  title,
  description,
  dFontSize,
  dLineHeight,
  margin,
  mMargin,
  descriptionStyle,
  marginBottom,
  headerMargin,
  headerMobileMargin,
  mDescriptionStyle,
}) => (
  <TitleWrapper marginBottom={marginBottom} margin={margin} mMargin={mMargin}>
    <T36
      mMargin={headerMobileMargin || "0px"}
      margin={headerMargin || "0px"}
      tMargin="0px"
      tSize="36px"
      lineHeight="45px"
      mLineHeight="32px">
      {title}
    </T36>
    <TitleDescription
      fontSize={dFontSize}
      lineHeight={dLineHeight}
      descriptionStyle={descriptionStyle}
      mDescriptionStyle={mDescriptionStyle}>
      {description}
    </TitleDescription>
  </TitleWrapper>
)
export default TitleWithDescription
