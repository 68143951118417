import React from 'react'
import styled from 'styled-components'

import { IS_SHOW_CRIMINAL_STATES_RESTRICTION as isShowCriminalStatesRestriction } from 'env'
import { COLOR } from 'styles/settings'
import { Small, Span } from 'components/typography/text-style'
import tracker from 'tracker'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'

import { LEGAL_RESTRICTIONS_LINK, CRIMINAL_STATES_RESTRICTIONS_LINK } from './constants'

const tuLogo = require('../../../legacy/images/credit-report/tu-logo.svg')

const SmallTULogo = styled.img`
  height: 20px;
  width: 20px;
  margin: 0 10px 0 5px;
`

const LinkStyled = styled.a`
  color: ${COLOR.fontBlue};
  font-size: 16px;
  font-weight: 600;
`

const LineBreak = styled.br`
  display: ${props => (props.desktop ? 'inline' : 'none')};
  @media (max-width: 991px) {
    display: ${props => (props.mobile ? 'inline' : 'none')};
  }
`

export const LegalRestrictionText = () => {
  const handleClickLink = () => {
    tracker.trackEvent(EVENT_REQUEST_SCREENING.viewScreeningRestriction, {
      click_from: EVENT_REQUEST_SCREENING.CLICK_FROM.createScreening,
    })
  }

  return (
    <Small className="have-screening" margin="0" mt="16" flex>
      <Span>Reports by TransUnion Screening</Span>
      <SmallTULogo src={tuLogo} />
      <LineBreak mobile />
      <Span>Learn more in &nbsp;</Span>
      <LinkStyled id="ScreeningRestrictionsLink" onClick={handleClickLink} href={LEGAL_RESTRICTIONS_LINK} target="_blank">
        Screening Restrictions
      </LinkStyled>
      {isShowCriminalStatesRestriction === 'true' && (
      <>
        <LineBreak desktop mobile />
        <Span>Please review the latest TransUnion criminal report coverage updates &nbsp;</Span>
        <LinkStyled href={CRIMINAL_STATES_RESTRICTIONS_LINK} target="_blank">
          here
        </LinkStyled>
      </>
    )}
    </Small>
  )
}

export default LegalRestrictionText
