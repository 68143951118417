import EditIcon from '@mui/icons-material/Edit'
import { Grid, IconButton } from '@mui/material'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import React from 'react'
import styled from 'styled-components'

import { utcDate } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { ordinalSuffix } from 'v3/containers/rent-payment/transactions/utils'

const StyledCard = styled(Card)`
  margin: 15px 0;
`

// eslint-disable-next-line no-inline-comments
export const PaymentsCard = React.memo(({ payment, paymentIndex, setDrawerOpen }) => {
  const { category, customCategory, amount, type, dueOn = '', dueDate = '' } = payment
  const due =
    type === 'recurring'
      ? `Due ${ordinalSuffix(dueOn)} of every month`
      : `Due ${utcDate(dueDate).format('YYYY-MM-DD')}`

  return (
    <StyledCard variant="outlined" sx={{ fontFamily: 'Inter' }}>
      <CardElement>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="title">{customCategory || category}</Typography>
          <IconButton
            aria-label="primary"
            size="large"
            onClick={() => setDrawerOpen({ isOpen: true, payment, paymentIndex })}
          >
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body-medium">${amount}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ color: '#222', opacity: '74%' }}>
          <Typography variant="body-medium">{due}</Typography>
        </Grid>
      </CardElement>
    </StyledCard>
  )
})
