import React from "react"
import B16 from "@rentspree/component-v2/dist/typography/b16"

import { MODAL_STATUS } from "./constants"

const { ARCHIVE, UNARCHIVE } = MODAL_STATUS

const Body = ({ type }) => {
  switch (type) {
    case ARCHIVE:
      return (
        <B16>
          The listing page will remain visible to the public but will be shown
          as unavailable. The listing will move to the Archived Listings section
          of your dashboard.
        </B16>
      )
    case UNARCHIVE:
      return <B16>The listing will be moved to the all listing page.</B16>
    default:
      return <div>something went wrong, please try again</div>
  }
}

export default Body
