import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import { makeSelectIsGetStartedEmpty } from "containers/get-started/selectors"
import injectSaga from "../../utils/inject-saga"
import injectReducer from "../../utils/inject-reducer"
import {
  makeSelectPopper,
  selectActiveData,
  selectIsNew,
  selectTrigger,
} from "./selectors"
import reducer from "./reducer"
import saga from "./saga"
import {
  getNotices,
  showPopper,
  findNextNotice,
  skipNotice,
  updateNotice,
  forceTrigger,
} from "./actions"

export const mapStateToProps = () =>
  createStructuredSelector({
    popper: makeSelectPopper(),
    activeData: selectActiveData(),
    isNew: selectIsNew(),
    trigger: selectTrigger(),
    isGetStartedEmpty: makeSelectIsGetStartedEmpty(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showPopper,
        getNotices,
        findNextNotice,
        skipNotice,
        updateNotice,
        forceTrigger,
      },
      dispatch,
    ),
  }
}

export const withSaga = injectSaga({ key: "popper", saga })
export const withReducer = injectReducer({ key: "popper", reducer })

export const withPopper = compose(
  withReducer,
  withSaga,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
