import React from "react"
import { withFormik } from "formik"
import RadioButtonCard from "@rentspree/component-v2/dist/input/radio-button-card"
import { object } from "yup"

import ButtonSet from "components/molecules/button-set"
import { requiredYup } from "legacy/constants/validations"
import tracker from "tracker"
import { CREATE_LISTING_EVENT } from "tracker/const"
import { EVENT_OPTION_IMPORT_MLS, EVENT_OPTION_CREATE_NEW } from "./constants"

export const Form = ({
  setFieldValue,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  goToBack,
}) => (
  <form onSubmit={handleSubmit}>
    <RadioButtonCard
      onChange={e => setFieldValue("importListing", e?.target?.value)}
      onBlur={handleBlur}
      name="importListing"
      choice={{
        label: "Import Listing from MLS",
        id: "radio-import",
        value: "yes",
        validationError: !!touched.importListing && !!errors.importListing,
      }}
      margin="32px 0 20px"
      error={
        !!touched.importListing &&
        !!errors.importListing &&
        errors.importListing
      }
    />
    <RadioButtonCard
      onChange={e => setFieldValue("importListing", e?.target?.value)}
      onBlur={handleBlur}
      name="importListing"
      choice={{
        label: "Create New Listing Page",
        id: "radio-create",
        value: "no",
        validationError: !!touched.importListing && !!errors.importListing,
      }}
      margin="0 0 60px 0"
      error={
        !!touched.importListing &&
        !!errors.importListing &&
        errors.importListing
      }
    />
    <ButtonSet
      buttons={[
        {
          type: "blueGradientButton",
          option: {
            text: "Next",
            fontSize: "16px",
            type: "submit",
          },
          isPullLeft: false,
        },
        {
          type: "button",
          option: {
            text: "Prev",
            fontSize: "16px",
            type: "button",
            onClick: goToBack,
          },
          isPullLeft: true,
        },
      ]}
    />
  </form>
)

export const importListingHandleSubmit = (
  { importListing },
  { props, setSubmitting },
) => {
  if (importListing === "yes") {
    tracker.trackEvent(
      CREATE_LISTING_EVENT.EVENT_NAME.SELECT_OPTION_ON_IMPORT_LISTING,
      { option: EVENT_OPTION_IMPORT_MLS },
    )
    props.importMLSListing(props.propertyId)
  } else {
    tracker.trackEvent(
      CREATE_LISTING_EVENT.EVENT_NAME.SELECT_OPTION_ON_IMPORT_LISTING,
      { option: EVENT_OPTION_CREATE_NEW },
    )
    props.goToCreateListing()
  }
  setSubmitting(false)
}

export const ImportListingForm = withFormik({
  mapPropsToValues: () => ({
    importListing: undefined,
  }),
  validationSchema: () => object().shape({ importListing: requiredYup(true) }),
  handleSubmit: importListingHandleSubmit,
  displayName: "ImportListingForm",
})(Form)
