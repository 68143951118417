import { PROPERTY_TYPES as propertyTypes } from "containers/property-propertyType/const"

export { BATH_ROOM_CHOICES } from "containers/rent-estimate/const"

export const PROPERTY_TYPES = [
  {
    value: "",
    label: "",
  },
  ...propertyTypes,
]

export const BED_ROOM_CHOICES = [
  {
    value: "",
    label: "",
  },
  {
    value: 0,
    label: "Studio",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10+",
  },
]
