import styled from 'styled-components'

const MOBILE_WIDTH = '991px'
const COLOR = { textBlack: '#333333', bgGrey: '#F1F1F1' }
const breakpoints = {
  mobile: (styles) => `
    @media (max-width: ${MOBILE_WIDTH}) {
      ${styles}
    }
  `,
  mobileContact: (styles) => `
  @media (max-width: 1223px) {
    ${styles}
  }
`,
}

export const Icon = styled.span`
  margin-right: 12px;
  display: flex;
  align-items: center;
`

export const AutocompleteItemDiv = styled.div`
  color: ${COLOR.textBlack};
  font-size: 14px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 15px 5px 15px;
  display: flex;
  ${(props) => props.isHighlight && `background-color: ${COLOR.bgGrey};`}

  &:first-child {
    margin-top: 5px;
  }
  margin-bottom: 5px;
  ${breakpoints.mobile(`
    margin-bottom: 10px;
    &:first-child {
      margin-top: 10px;
    }
  `)}
`

export const AutocompleteItemText = styled.span`
  width: 100%;
`

