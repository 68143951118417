import React from 'react'
import { withFormik } from 'formik'

import { B14, BlueGradientButton, Button, TextInput } from '@rentspree/component-v2'
import styled from 'styled-components'
import { BUTTON_TEXT_SECONDARY, BUTTON_TEXT_PRIMARY, REMARK } from './constants'
import { mapper, validation } from './schema'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  font-family: Source Sans Pro, sans-serif;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px 0 33px;

  @media (max-width: 991px) {
    margin: 0px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 25px 10px 33px;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    margin: 33px 0;
  }
`

export const InnerForm = ({ handleSubmit, handleCloseModal, isSending, ...props }) => {
  const {
    /** Formik props */
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = props

  return (
    <Form data-testid="invite-landlord-form" onSubmit={handleSubmit}>
      <Content>
        <TextInput
          data-testid="invite-landlord-first-name-input"
          name="firstName"
          label="Landlord first name"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && errors.firstName}
          isRequired
        />

        <TextInput
          data-testid="invite-landlord-last-name-input"
          name="lastName"
          label="Landlord last name"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && errors.lastName}
          isRequired
        />

        <TextInput
          data-testid="invite-landlord-email-input"
          name="email"
          label="Landlord email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          isRequired
        />

        <B14 data-testid="invite-landlord-remark" margin="0px">
          {REMARK}
        </B14>
      </Content>

      <ButtonContainer>
        <Button
          data-testid="invite-landlord-secondary-btn"
          type="button"
          text={BUTTON_TEXT_SECONDARY}
          onClick={handleCloseModal}
          minWidth={113}
          google
          small
          semiBold
          fontSize="16px"
          tMargin="16px 0 0 0"
          disabled={isSending}
        />
        <BlueGradientButton
          type="submit"
          data-testid="invite-landlord-primary-btn"
          text={BUTTON_TEXT_PRIMARY}
          minWidth={190}
          small
          semiBold
          fontSize="16px"
          margin="0 0 0 16px"
          tMargin="0"
          disabled={isSending}
        />
      </ButtonContainer>
    </Form>
  )
}

export const InviteLandlordRentPaymentForm = withFormik({
  mapPropsToValues: props => mapper(props),
  validationSchema: () => validation(),
  displayName: 'InviteLandlordRentPaymentForm',
  handleSubmit: (values, { props }) => {
    props.handleSubmit(values)
  },
})(InnerForm)

export default InviteLandlordRentPaymentForm
