import { buildPath } from '@rentspree/path/dist/build-path'

import { apiInstance, apiInstanceWithErrorHandler } from 'utils/api-interceptor'
import {
  MONTH_TO_MONTH_LABEL,
  QUOTATION_TYPE,
  RECURRING_PAYMENT_TERM,
} from 'v3/containers/rent-payment/constants'
import { PAYMENT_CATEGORIES } from 'v3/containers/rent-payment/shared/redux/constants'

export const getMyPaymentSummaryPath = '/api/v2/rental-payments/summary'
export const createAgentInitiatedPaymentPath =
  'api/v2/rental-payments/draft-payments/agent-initiated'

/**
 * getMyPaymentSummary fetches the payment summary from the payment service
 * and return the payment summary according to the given list of statuses.
 *
 * @async
 * @param {string[]} statuses
 * @param {object} timeFilter - time filter option, It will return last 1 year payment by default
 * @param {boolean|undefined} timeFilter.allTime - flag to query all payments
 * @param {Date|null|undefined} timeFilter.startDate - the starting date to query payments
 * @param {Date|null|undefined} timeFilter.endDate - the ending date to query payments
 * @returns {Promise<{[key: string]: {count: number, amount: number}}>} object of the statuses
 */
export const getMyPaymentSummary = async (statuses, timeFilter) => {
  return apiInstance.get(
    buildPath(getMyPaymentSummaryPath, null, { ...timeFilter, statuses: statuses.join(',') }),
  )
}

/**
 * @typedef {object} AgentInitiatedPaymentPayload
 * @property {string} propertyId
 * @property {object} renterInfo
 * @property {string} renterInfo.firstName
 * @property {string} renterInfo.lastName
 * @property {string} renterInfo.email
 * @property {object} landlordInfo
 * @property {string} landlordInfo.email
 * @property {object[]} quotations
 * @property {string} quotations.category
 * @property {string} quotations.type
 * @property {string} quotations.dueDate
 */

/**
 * @typedef {object} AgentInitiatedPaymentResponse
 * @property {string} id
 * @property {string} status
 * @property {object} property
 * @property {string} property.id
 * @property {string} property.street
 * @property {string} property.unitNumber
 * @property {string} property.city
 * @property {string} property.state
 * @property {string} property.zip
 * @property {object} renterInfo
 * @property {string} renterInfo.id
 * @property {string} renterInfo.firstName
 * @property {string} renterInfo.lastName
 * @property {string} renterInfo.email
 * @property {object} landlordInfo
 * @property {string} landlordInfo.email
 * @property {object[]} quotations
 * @property {string} quotations.category
 * @property {string} quotations.type
 * @property {string} quotations.dueDate
 * @property {string} quotations.amount
 * @property {object} quotations.feeCollection
 * @property {string} quotations.feeCollection.bank
 * @property {string} quotations.feeCollection.card
 * @property {string} endedAt
 * @property {string} timezone
 */

/**
 * createAgentInitiatedPayment creates an agent initiated payment
 *
 * @param {AgentInitiatedPaymentPayload} data
 * @returns {Promise<AgentInitiatedPaymentResponse>}
 */
export const createAgentInitiatedPayment = async data => {
  return apiInstanceWithErrorHandler.post(buildPath(createAgentInitiatedPaymentPath), data)
}

/**
 * @typedef {object} AgentInitiatedPaymentFormValue
 * @property {string} selectedProperty
 * @property {string} selectedTenant
 * @property {object} landlordInfo
 * @property {string} landlordInfo.firstName
 * @property {string} landlordInfo.lastName
 * @property {string} landlordInfo.email
 * @property {object} renterInfo
 * @property {string} renterInfo.firstName
 * @property {string} renterInfo.lastName
 * @property {string} renterInfo.email
 * @property {object} securityDeposit
 * @property {number} securityDeposit.amount
 * @property {Date} securityDeposit.dueDate
 * @property {object[]} additionalMoveInFees
 * @property {string} additionalMoveInFees.category
 * @property {string} additionalMoveInFees.customCategory
 * @property {number} additionalMoveInFees.amount
 * @property {Date} additionalMoveInFees.dueDate
 */

/**
 * mapAgentInitiatedPaymentFormValueToRequestBody map form value to request body
 *
 * @param {AgentInitiatedPaymentFormValue} data
 * @returns {AgentInitiatedPaymentPayload}
 */
export const mapAgentInitiatedPaymentFormValueToRequestBody = data => {
  const mappedOneTimes = data.oneTimePayments.map(moveInFee => {
    const category =
      moveInFee.category === PAYMENT_CATEGORIES.OTHER
        ? moveInFee.customCategory
        : moveInFee.category
    return {
      amount: +moveInFee.amount,
      dueDate: moveInFee.dueDate,
      category,
      type: QUOTATION_TYPE.ONE_TIME,
    }
  })
  const mappedRecurrings = data.recurringPayments.map(moveInFee => {
    const category =
      moveInFee.category === PAYMENT_CATEGORIES.OTHER
        ? moveInFee.customCategory
        : moveInFee.category
    let endDate
    let term
    if (moveInFee.endDate === MONTH_TO_MONTH_LABEL) {
      term = RECURRING_PAYMENT_TERM.PERPETUAL
    } else {
      endDate = moveInFee.endDate
      term = RECURRING_PAYMENT_TERM.FIXED
    }
    return {
      amount: +moveInFee.amount,
      startDate: moveInFee.startDate,
      endDate,
      category,
      type: QUOTATION_TYPE.RECURRING,
      repeat: 'monthly',
      dueOn: moveInFee?.dueOn,
      term,
    }
  })

  return {
    propertyId: data.selectedProperty,
    renterInfo: {
      firstName: data.renterInfo.firstName,
      lastName: data.renterInfo.lastName,
      email: data.renterInfo.email,
    },
    landlordInfo: {
      firstName: data.landlordInfo.firstName,
      lastName: data.landlordInfo.lastName,
      email: data.landlordInfo.email,
    },
    quotations: [...mappedOneTimes, ...mappedRecurrings],
  }
}
