import { Icon } from '@rentspree/component-v2/dist/icon'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { breakpoints, COLOR } from 'styles/settings'

import { TAG_COLOR } from './constants'

export const TagContainer = styled.div`
  max-height: 24px;
  min-height: 24px;
  position: relative;
  ${({ maxWidth }) => `max-width: ${maxWidth || '150px'}`};
  display: inline-flex;
  align-items: center;
  ${({ margin }) => margin && `margin: ${margin};`} ${({ mMargin }) =>
    mMargin && breakpoints.mobileContact(`margin: ${mMargin}`)}
  padding: 4px 14px;
  border: none;
  border-radius: 100px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ isSelected, fontColor, fontColorHover }) =>
    isSelected ? fontColorHover : fontColor};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: 600;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    background-color: ${({ backgroundColorHover }) => backgroundColorHover};
    color: ${({ fontColorHover }) => fontColorHover};
    > span {
      > div {
        color: ${({ fontColorHover }) => fontColorHover};
      }
    }
    #removeIcon {
      display: flex;
    }
  }
`

const LabelContainer = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const IconContainer = styled(Icon)`
  margin: ${({ margin }) => margin || '0 5px 0 0'};
  > svg {
    top: 1px !important;
  }
`

export const RemoveIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  display: none;
  background-color: ${({ backgroundTagColor }) => backgroundTagColor};
  font-size: 7px;
  &:hover {
    padding: 3px;
    border-radius: 50%;
    background-color: ${COLOR.textBlack};
    > svg {
      color: ${COLOR.white};
    }
  }
  > svg {
    color: ${({ color }) => color};
    width: 8px !important;
    height: 8px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 3px !important;
  }
`

const Tag = ({
  name,
  color,
  maxWidth,
  margin,
  mMargin,
  fontSize,
  isSelected,
  className,
  iconName,
  iconMargin,
  onClick,
  onRemove,
}) => (
  <TagContainer
    className={className}
    maxWidth={maxWidth}
    margin={margin}
    mMargin={mMargin}
    fontSize={fontSize}
    backgroundColor={TAG_COLOR[color]?.backgroundColor}
    fontColor={TAG_COLOR[color]?.fontColor}
    backgroundColorHover={TAG_COLOR[color]?.backgroundColorHover}
    fontColorHover={TAG_COLOR[color]?.fontColorHover}
    isSelected={isSelected}
    onClick={onClick}
    onRemove={onRemove}
    tabIndex="0"
  >
    <LabelContainer>
      {isSelected && <IconContainer name="success" />}
      {iconName === 'adding' && <IconContainer name="add" margin={iconMargin} />}
      {name}
      {iconName !== 'close' && onRemove && (
        <RemoveIcon
          id="removeIcon"
          name="close"
          color={TAG_COLOR[color]?.fontColor}
          backgroundTagColor={TAG_COLOR[color]?.backgroundColorHover}
          size="sm"
          onClick={onRemove}
        />
      )}
    </LabelContainer>
    {iconName === 'close' && <IconContainer name="close" margin={iconMargin} onClick={onRemove} />}
  </TagContainer>
)

Tag.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  margin: PropTypes.string,
  mMargin: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Tag
