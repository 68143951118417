export const ACCEPT_AGREEMENT_CALL = "ACCEPT_AGREEMENT_CALL"
export const ACCEPT_AGREEMENT_REQUEST = "ACCEPT_AGREEMENT_REQUEST"
export const ACCEPT_AGREEMENT_SUCCESS = "ACCEPT_AGREEMENT_SUCCESS"
export const ACCEPT_AGREEMENT_FAILURE = "ACCEPT_AGREEMENT_FAILURE"

export const GET_ACCEPT_AGREEMENT_CALL = "GET_ACCEPT_AGREEMENT_CALL"
export const GET_ACCEPT_AGREEMENT_REQUEST = "GET_ACCEPT_AGREEMENT_REQUEST"
export const GET_ACCEPT_AGREEMENT_SUCCESS = "GET_ACCEPT_AGREEMENT_SUCCESS"
export const GET_ACCEPT_AGREEMENT_FAILURE = "GET_ACCEPT_AGREEMENT_FAILURE"

export const GET_AGREEMENT_CONTENT_CALL = "GET_AGREEMENT_CONTENT_CALL"
export const GET_AGREEMENT_CONTENT_REQUEST = "GET_AGREEMENT_CONTENT_REQUEST"
export const GET_AGREEMENT_CONTENT_SUCCESS = "GET_AGREEMENT_CONTENT_SUCCESS"
export const GET_AGREEMENT_CONTENT_FAILURE = "GET_AGREEMENT_CONTENT_FAILURE"

export const GET_AGREEMENT_ENDPOINT = "/api/v2/agreements/:type/is-accepted"
export const ACCEPT_AGREEMENT_ENDPOINT = "api/v2/agreements/:type/accept"
export const GET_AGREEMENT_CONTENT_ENDPOINT = "api/v2/agreements/:type"

export const AGREEMENT_TYPE = {
  TU: "tu",
}

export const AGREEMENT_VERSION = {
  TU: "1.0",
}

export const RE_CONSENT_PANEL = {
  TITLE: "The tenant screening service agreement has been updated.",
  BODY:
    "In order to access tenant screening, you must accept the latest agreement.",
}

export const CLEAR_AGREEMENT_ACCEPT_STATUS = "CLEAR_AGREEMENT_ACCEPT_STATUS"
