import get from "lodash/get"
import compact from "lodash/compact"
import map from "lodash/map"
import cleanDeep from "clean-deep"

export const creditReportMapper = creditReport => {
  const scoresFirst = get(creditReport, "scores[0]", {})
  return cleanDeep(
    {
      ...creditReport,
      scores: [
        {
          ...scoresFirst,
          scoreFactor: scoreFactorMapper(scoresFirst),
        },
      ],
      fraudIndicators: fraudIndicatorsMapper(
        get(creditReport, "fraudIndicators", []),
      ),
      tradeLines: get(creditReport, "tradelines", []),
    },
    {
      emptyArrays: false,
      emptyObjects: false,
      emptyStrings: false,
      nullValues: true,
      undefinedValues: true,
    },
  )
}

const scoreFactorMapper = score =>
  compact([
    score.scoreFactor1,
    score.scoreFactor2,
    score.scoreFactor3,
    score.scoreFactor4,
  ])
const fraudIndicatorsMapper = fraudIndicators =>
  map(fraudIndicators, fraudIndicator => get(fraudIndicator, "indicator", ""))
