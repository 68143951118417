import React from 'react'
import { FontReport } from 'legacy/components/reports-v2/share/doc-style'
import { ATTENTION_WORDING } from 'legacy/constants/criminal-report'
import {
  Content,
  InformationPanel,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { SectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import TextLink from 'components/atoms/anchor'

export const Attention = ({ remarks }) => (
  <Section>
    <SectionHeader title="Attention" />
    <SubSection>
      <Content>
        <InformationPanel>
          <FontReport Attention>
            {ATTENTION_WORDING[0]}
            &nbsp;See&nbsp;
            <TextLink
              to="https://support.rentspree.com/en/screening-restrictions-and-limitations"
              underline
            >
              TransUnion screening restrictions
            </TextLink>
            .
          </FontReport>
        </InformationPanel>
      </Content>
    </SubSection>
    <SubSection>
      <Content>
        <InformationPanel>
          <FontReport Attention>{remarks || ATTENTION_WORDING[1]}</FontReport>
        </InformationPanel>
      </Content>
    </SubSection>
    <SubSection>
      <Content>
        <InformationPanel>
          <FontReport Attention Bold>
            {ATTENTION_WORDING[2]}
          </FontReport>
        </InformationPanel>
      </Content>
    </SubSection>
  </Section>
)
