import React from 'react'
import styled from 'styled-components'
import { ANIMATE } from 'styles/settings'

const SortTableTh = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &:hover {
    > i {
      opacity: 1;
    }
  }
  > i {
    transition: opacity ease ${ANIMATE.fast};
    display: ${({ sorting }) => (sorting ? 'block' : 'none')};
    font-size: 0.8em;
    margin-left: 7px;
    position: relative;
    top: 5px;
  }
`

export const SortableHeader = ({ text, sorting, children, ...restProps }) => (
  <SortTableTh sorting={sorting} {...restProps}>
    <span>{text}</span>
    <i className={sorting === 'desc' ? 'icon-arrow-down' : 'icon-arrow-up'} />
    {children}
  </SortTableTh>
)
