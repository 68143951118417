import styled from 'styled-components'
import React, { useEffect } from 'react'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import Span from '@rentspree/component-v2/dist/typography/span'
import propTypes from 'prop-types'
import { Icon } from '@rentspree/component-v2'
import { COLOR, ANIMATE } from '../../../styles/settings'

export const HeaderWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: ${COLOR.white};
  border: 2px solid ${COLOR.white};
  padding: 14px;
  display: flex;
  font-family: inherit;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out ${ANIMATE.fast};
  margin-bottom: 0 !important;
  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: rgba(75, 151, 245, 0.08);
    border: 2px solid #4b97f5;
    box-shadow: none;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    &:hover{
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }
    
  `}
  ${({ validationError }) =>
    validationError &&
    `
    border-color: ${COLOR.negativeRed};
  `}
`

export const AccordionWrapper = styled.div`
  margin: ${({ margin }) => margin || 0} !important;
`

export const Title = styled(S16)`
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  margin-bottom: 2px;
  ${props =>
    props.validationError &&
    `
    color: ${COLOR.negativeRed};
    `}
  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLOR.grayLv3};
  `}
`

export const Subtitle = styled(S16)`
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
  font-weight: normal;
  margin: 0;
`
const Body = styled.div`
  margin: 0;
  margin-top: ${({ isCollapsed }) => (isCollapsed ? '0px' : '8px')};
  overflow: hidden;
  color: white;
  height: ${({ isCollapsed, contentHeight }) => (isCollapsed ? '0px' : `${contentHeight}px`)};
  opacity: ${({ isCollapsed }) => (isCollapsed ? '0' : '1')};
  transition: 0.3s;
  overflow: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'visible')};
`

const BodyContent = styled.div`
  margin: 0;
  overflow: visible;
`

export const IconContainer = styled(Icon)`
  position: absolute;
  top: 33.25px;
  right: 10px;
  ${({ isCollapsed }) => !isCollapsed && 'transform: rotate(180deg);'}
  transition: 0.3s;
`

const Header = styled.div`
  max-width: 90%;
`

export const BooleanAccordionCard = ({
  children,
  isCollapsed,
  margin,
  title,
  subtitle,
  isActive,
  disabled,
  type,
  icon: IconComponent,
  alt,
  onOpenCallback,
  ...rest
}) => {
  const bodyContentRef = React.useRef(null)
  const [bodyHeight, setBodyHeight] = React.useState(0)
  const [isCollapsedState, setIsCollapsedState] = React.useState(isCollapsed)

  useEffect(() => {
    if (!bodyContentRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      setBodyHeight(bodyContentRef.current.scrollHeight)
    })
    resizeObserver.observe(bodyContentRef.current)
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect()
  })

  useEffect(() => {
    setIsCollapsedState(isCollapsed)
  }, [isCollapsed])

  return (
    <AccordionWrapper margin={margin}>
      <HeaderWrapper
        isActive={isActive}
        disabled={disabled}
        className="accordion-header"
        onClick={() => {
          if (onOpenCallback && isCollapsedState) {
            onOpenCallback()
          }
          setIsCollapsedState(state => !state)
        }}
        {...rest}
      >
        {IconComponent && <IconComponent isActive={isActive} alt={alt} />}
        <Header>
          <Span strong>
            {title && (
              <Title validationError={rest.validationError} disabled={disabled}>
                {title}
              </Title>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Span>
          <IconContainer isCollapsed={isCollapsedState} name="chevron-down" />
        </Header>
      </HeaderWrapper>
      <Body
        isCollapsed={isCollapsedState}
        contentHeight={bodyHeight || 0}
        isTransition
        className="accordion-body"
      >
        <BodyContent ref={bodyContentRef}>{children}</BodyContent>
      </Body>
    </AccordionWrapper>
  )
}

BooleanAccordionCard.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.element]),
  isCollapsed: propTypes.bool,
  subtitle: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  onOpenCallback: propTypes.func,
  isActive: propTypes.bool,
  icon: propTypes.func,
}

BooleanAccordionCard.defaultProps = {
  isActive: false,
  isCollapsed: true,
}
