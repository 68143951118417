export const LRA_REQUEST_ENDPOINT =
  "/api/v2/agent/screening-requests/:screeningRequestId/lra-request"

export const UPDATE_LRA_REQUEST_CALL = "UPDATE_LRA_REQUEST_CALL"
export const UPDATE_LRA_REQUEST_REQUEST = "UPDATE_LRA_REQUEST_REQUEST"
export const UPDATE_LRA_REQUEST_SUCCESS = "UPDATE_LRA_REQUEST_SUCCESS"
export const UPDATE_LRA_REQUEST_FAILURE = "UPDATE_LRA_REQUEST_FAILURE"

export const GET_LRA_REQUEST_CALL = "GET_LRA_REQUEST_CALL"
export const GET_LRA_REQUEST_REQUEST = "GET_LRA_REQUEST_REQUEST"
export const GET_LRA_REQUEST_SUCCESS = "GET_LRA_REQUEST_SUCCESS"
export const GET_LRA_REQUEST_FAILURE = "GET_LRA_REQUEST_FAILURE"

export const CREATE_LISTING_SCREENING_REQUEST_CALL =
  "CREATE_LISTING_SCREENING_REQUEST_CALL"
export const CREATE_LISTING_SCREENING_REQUEST_REQUESTED =
  "CREATE_LISTING_SCREENING_REQUEST_REQUESTED"
export const CREATE_LISTING_SCREENING_REQUEST_SUCCESS =
  "CREATE_LISTING_SCREENING_REQUEST_SUCCESS"
export const CREATE_LISTING_SCREENING_REQUEST_FAILED =
  "CREATE_LISTING_SCREENING_REQUEST_FAILED"
