import get from 'lodash/get'
import * as Types from '../constants/action-types'
import createAction from '../apis/utils/create-action'
import * as api from '../apis/application.api'

/**
 * This function is the middleware on getApplicationDetail action
 * for resolving duplicate call API when the request is concurrent
 * @TODO For future should refactor this function to saga logic
 */
export const getApplicationDetail =
  (...args) =>
  (dispatch, getState) => {
    const store = getState()
    const isFetchingAppDetail = get(store, 'application.isFetchingAppDetail')
    if (isFetchingAppDetail) return null
    return fetchApplicationDetail(...args)(dispatch, getState)
  }

export const fetchApplicationDetail = createAction(
  api.getApplicationDetail,
  [Types.GET_APPLICATION_REQUEST, Types.GET_APPLICATION_SUCCESS, Types.GET_APPLICATION_FAILED],
  (res = {}) => ({ application: res }),
)

export const getFormAvailable = createAction(
  api.getFormAvailable,
  [
    Types.GET_FORM_AVAILABLE_REQUEST,
    Types.GET_FORM_AVAILABLE_SUCCESS,
    Types.GET_FORM_AVAILABLE_FAILED,
  ],
  (res = {}) => res,
)

export const fetchingRentSubmissionById = createAction(
  api.getRentalSubmissionById,
  [Types.GET_RENTAL_BY_ID_REQUEST, Types.GET_RENTAL_BY_ID_SUCCESS, Types.GET_RENTAL_BY_ID_FAILED],
  (res = {}) => ({ data: res }),
)

export const getSubmissionParticipant = createAction(
  api.getSubmissionParticipant,
  [
    Types.GET_SUBMISSION_PARTICIPANT_REQUEST,
    Types.GET_SUBMISSION_PARTICIPANT_SUCCESS,
    Types.GET_SUBMISSION_PARTICIPANT_FAILED,
  ],
  (data = {}) => ({ payload: data }),
)

export const agentOpenReportExecution = createAction(
  api.agentOpenReportExecution,
  [
    Types.AGENT_OPEN_REPORT_EXECUTION_REQUEST,
    Types.AGENT_OPEN_REPORT_EXECUTION_SUCCESS,
    Types.AGENT_OPEN_REPORT_EXECUTION_FAILED,
  ],
  (data = {}) => ({ payload: data }),
)

// TODO: refactor to saga logic
export const getRentSubmissionById = (rentalId, propertyId) => async (dispatch, getState) => {
  const state = getState()
  const userType = get(state, 'user.profile.userType')

  if (userType === 'landlord') {
    await agentOpenReportExecution(propertyId, rentalId)(dispatch, getState)
  }

  return fetchingRentSubmissionById(rentalId)(dispatch, getState).then(res => res)
}

export const setReportFetching = () => ({
  type: Types.SET_REPORT_FETCHING,
})

export const clearReportFetching = () => ({
  type: Types.CLEAR_REPORT_FETCHING,
})

export const changeMobileActiveTab = payload => dispatch => {
  dispatch({
    type: Types.CALL_UPDATE_MOBILE_ACTIVE_TAB,
    payload,
  })
}

export const clearRental = () => ({
  type: Types.CLEAR_RENTAL_DETAIL,
})

export const updateCurrentReportStatus = payload => dispatch => {
  dispatch({
    type: Types.UPDATE_CURRENT_REPORT_STATUS,
    payload,
  })
}
