import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import isEmpty from 'lodash/isEmpty'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'

import { CONTACTS } from 'constants/route'
import { addToast } from 'containers/toast/actions'
import { apiInstance } from 'utils/api-interceptor'

import { closeDeleteTagModal } from '../delete-tag-modal/actions'
import { getContactList } from '../list/actions'
import { selectLocation } from '../selectors'

import { appendTag, getAllTagsApiState, deleteTagApiState, getAllTags } from './actions'
import {
  GET_ALL_TAGS_CALL,
  GET_ALL_TAGS_API,
  CREATE_TAG_API,
  TAG_CONTACT_API,
  UNTAG_CONTACT_API,
  DELETE_TAG_CALL,
  DELETE_TAG_API,
  DELETE_TAG_SUCCESS_TOAST_CONFIG,
  DELETE_TAG_FAILURE_TOAST_CONFIG,
} from './constants'

export const getAllTagsApi = () => apiInstance.get(GET_ALL_TAGS_API)

export const createTagApi = name => apiInstance.post(CREATE_TAG_API, { name })

export const tagContactApi = (contactId, tagId) =>
  apiInstance.post(buildPath(TAG_CONTACT_API, { contactId, tagId }))

export const untagContactApi = (contactId, tagId) =>
  apiInstance.delete(buildPath(UNTAG_CONTACT_API, { contactId, tagId }))

export const deleteTagApi = tagId => apiInstance.delete(buildPath(DELETE_TAG_API, { tagId }))

export function* deleteTagSaga({ payload }) {
  yield put(deleteTagApiState.request())
  try {
    const response = yield call(deleteTagApi, payload.tagId)
    yield put(closeDeleteTagModal())

    const currentLocation = yield select(selectLocation)
    if (currentLocation.pathname === CONTACTS && isEmpty(currentLocation.search)) {
      yield put(getContactList({ group: 'all', page: 1 }))
    } else {
      yield put(push({ pathname: CONTACTS }))
    }

    yield put(getAllTags())
    yield put(deleteTagApiState.success(response))
    yield put(addToast(DELETE_TAG_SUCCESS_TOAST_CONFIG))
  } catch (error) {
    yield put(deleteTagApiState.failure())
    yield put(addToast(DELETE_TAG_FAILURE_TOAST_CONFIG))
  }
}

export function* getAllTagsSaga() {
  yield put(getAllTagsApiState.request())
  try {
    const response = yield call(getAllTagsApi)
    yield put(getAllTagsApiState.success(response))
  } catch (error) {
    yield put(getAllTagsApiState.failure())
  }
}

export function* createTagSaga({ payload }) {
  const tag = yield call(createTagApi, payload?.name)
  yield put(appendTag({ tag }))
  return tag
}

export function* watchGetAllTags() {
  yield takeLatest(GET_ALL_TAGS_CALL, getAllTagsSaga)
}

export function* watchDeleteTag() {
  yield takeLatest(DELETE_TAG_CALL, deleteTagSaga)
}

export function* rootSaga() {
  yield all([watchGetAllTags(), watchDeleteTag()])
}

export default rootSaga
