import React from "react"
import styled from "styled-components"
import { buildPath } from "@rentspree/path"
import REPORT_NOREPORT from "legacy/images/icons/report-noreport.svg"
import APP_NOAPP from "legacy/images/icons/app-noapp.svg"
import { RouterLink } from "legacy/components/buttons/buttons"
import { PERMALINK_ACTIONS_ID, SCREENING_NEW_TENANT } from "constants/route"
import {
  REPORT_TYPES,
  PARTICIPANT_TYPE,
  SHARED_REPORT_TYPES,
} from "containers/reports/constants"

export const WrapperCreditReport = styled.div`
  width: 90%;
  max-width: 500px;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1.5;
  text-align: center;
  color: #333;
  img {
    height: 200px;
    width: auto;
    margin-bottom: 20px;
  }
`

const textForOwner = (reportName, linker = "for") =>
  `You haven’t requested ${reportName} ${linker} this applicant. To request ${reportName},`

const textForSharedLandlord = reportName =>
  `${reportName} has not been shared with you for this applicant.`

export const notAvailableReportConfig = {
  [REPORT_TYPES.CREDIT_REPORT]: {
    imageSrc: REPORT_NOREPORT,
    imageAlt: "Credit report not available",
    ownerText: textForOwner("a credit report"),
    sharedText: textForSharedLandlord("The credit report"),
    id: "noCreditReportScreenTenantsButton",
  },
  [REPORT_TYPES.CRIMINAL]: {
    imageSrc: REPORT_NOREPORT,
    imageAlt: "Criminal record not available",
    ownerText: textForOwner("a criminal background check"),
    sharedText: textForSharedLandlord("The background check"),
    id: "noCriminalRecordScreenTenantsButton",
  },
  [REPORT_TYPES.EVICTION]: {
    imageSrc: REPORT_NOREPORT,
    imageAlt: "Eviction record not available",
    ownerText: textForOwner("an eviction related proceedings"),
    sharedText: textForSharedLandlord("An eviction related proceedings"),
    id: "noEvictionRecordScreenTenantsButton",
  },
  [REPORT_TYPES.APPLICATION]: {
    imageSrc: APP_NOAPP,
    imageAlt: "Application not available",
    ownerText: textForOwner("an application", "from"),
    sharedText: textForSharedLandlord("The rental application"),
    id: "noApplicationScreenTenantsButton",
  },
  [SHARED_REPORT_TYPES.REFERENCE_CHECK]: {
    imageSrc: REPORT_NOREPORT,
    imageAlt: "Reference checks not available",
    ownerText: textForOwner("a reference checks"),
    sharedText: textForSharedLandlord("The reference check(s)"),
    id: "noRefChecksScreenTenantsButton",
  },
  [SHARED_REPORT_TYPES.OTHER_DOCS]: {
    imageSrc: REPORT_NOREPORT,
    imageAlt: "Other documents not available",
    ownerText: textForOwner("other documents"),
    sharedText: textForSharedLandlord("The other document(s)"),
    id: "noOtherDocsScreenTenantsButton",
  },
}

// TODO: delete old legacy components in file : legacy/components/reports/index.js
export const ReportNotAvailable = ({
  reportType,
  propertyId,
  participantType,
}) => {
  const notAvailableConfig = notAvailableReportConfig[reportType]
  const isWithProperty = propertyId && propertyId !== "-"
  const permalinkPath = isWithProperty
    ? buildPath(PERMALINK_ACTIONS_ID, {
        propertyId,
      })
    : buildPath(SCREENING_NEW_TENANT)
  if (!notAvailableConfig) return null
  const { imageSrc, imageAlt, ownerText, sharedText, id } = notAvailableConfig
  return (
    <WrapperCreditReport>
      <div>
        <img src={imageSrc} alt={imageAlt} />
      </div>
      {participantType === PARTICIPANT_TYPE.PARTICIPANT ? (
        sharedText
      ) : (
        <>
          {ownerText}
          &nbsp;
          <RouterLink id={id} to={permalinkPath} inlineLink>
            click here.
          </RouterLink>
        </>
      )}
    </WrapperCreditReport>
  )
}
