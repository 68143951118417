import { CDN_URL } from 'env'

export const AGENT_INITIATE_DRAFT_PAYMENT_FOR_LANDLORD_BANNER = {
  TITLE: 'Help your landlord collect the deposit and fees faster',
  BODY: 'Easily coordinate online payment of deposit and fees and get status updates along the way.',
  FIRST_CTA_TEXT: 'Set up now',
  SECOND_CTA_TEXT: 'See how it works',
}

export const AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_SEEN_GROUP =
  'agent_incentive_payment_adoption_banner'

export const AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_BANNER = {
  LEFT_IMAGE_URL: `${CDN_URL}/static-files/shared-assets/hand_money_bag.svg`,
  RIGHT_IMAGE_URL: `${CDN_URL}/static-files/shared-assets/hand_giving.svg`,
  PRIMARY_BUTTON_TEXT: 'Earn $1000',
  SECONDARY_BUTTON_TEXT: 'Your rewards',
}
