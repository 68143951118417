import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'

export const AnchorStyle = styled.a`
  color: ${props => props.color || COLOR.fontBlue};
  :hover {
    color: ${props => props.hoverColor || COLOR.newDeepBlue};
  }
  ${props => props.desktop && 'display: inline;'}
  ${props => props.mobile && 'display: none;'}
  ${props => props.bold && 'font-weight: 600;'}
  ${props => props.underline && 'text-decoration: underline !important;'}
  @media (max-width: 991px) {
    ${props => props.desktop && 'display: none;'}
    ${props => props.mobile && 'display: inline;'}
  }
  @media print {
    color: ${props => props.color || COLOR.fontBlue} !important;
    ${props => props.underline && 'text-decoration: underline !important;'}
  }
`

const Anchor = ({
  target = 'blank',
  color,
  hoverColor,
  to,
  desktop,
  mobile,
  bold,
  children,
  underline,
  onClick,
}) => (
  <AnchorStyle
    target={target}
    color={color}
    hoverColor={hoverColor}
    href={to}
    bold={bold}
    underline={underline}
    desktop={desktop}
    mobile={mobile}
    onClick={onClick}
  >
    {children}
  </AnchorStyle>
)

Anchor.propTypes = {
  target: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  to: PropTypes.string,
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  bold: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default Anchor
