export const GET_RENTER_INSURANCE_DETAIL_CALL =
  "GET_RENTER_INSURANCE_DETAIL_CALL"
export const GET_RENTER_INSURANCE_DETAIL_REQUEST =
  "GET_RENTER_INSURANCE_DETAIL_REQUEST"
export const GET_RENTER_INSURANCE_DETAIL_SUCCESS =
  "GET_RENTER_INSURANCE_DETAIL_SUCCESS"
export const GET_RENTER_INSURANCE_DETAIL_FAILURE =
  "GET_RENTER_INSURANCE_DETAIL_FAILURE"

export const CLEAR_RENTER_INSURANCE_DETAIL_CALL =
  "CLEAR_RENTER_INSURANCE_DETAIL_CALL"

export const RENTER_INSURANCE_API_WITH_ID =
  "/api/v2/properties/:propertyId/insurance/renter/:rentersInsuranceId"

export const ERROR_TITLE = "Trouble loading the page"
export const ERROR_DESCRIPTION =
  "Please wait a few minutes and click try again. If the problem persists, contact (323) 515-7757 or support@rentspree.com."

export const CHANNEL = {
  UPLOAD: "upload",
  ONLINE: "online",
}
