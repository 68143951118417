import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { breakpoints } from 'styles/settings'
import Header from '../../atoms/header'
import Text from '../../atoms/text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 31px;
  padding-left: 20px;
  ${breakpoints.mobile(`
    padding: 15px;
  `)}
`

const headerStyles = {
  desktop: {
    fontSize: '28px',
    lineHeight: '36px', 
    margin: '0 0 3px 0',
  },
  mobile: {
    fontSize: '18px',
    lineHeight: '24px',
    margin: '0 0 10px 0',
  }
}

const descriptionStyles = {
  desktop: {
    fontSize: '16px',
    lineHeight: '22px', 
  },
  mobile: {
    fontSize: '14px',
    lineHeight: '20px', 
  }
}

// TODO: [lnr-2055]: Add unit tests
const HeaderWithDescription = ({ headerText, descriptionText, isMobile }) => (
  <Wrapper>
    <Header text={headerText} { ...(isMobile ? headerStyles.mobile : headerStyles.desktop)} />
    <Text text={descriptionText} { ...(isMobile ? descriptionStyles.mobile : descriptionStyles.desktop)} />
  </Wrapper>
)

HeaderWithDescription.propTypes = {
  headerText: propTypes.string.isRequired,
  descriptionText: propTypes.string.isRequired,
}

export default HeaderWithDescription