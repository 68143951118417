export const SIGNATURE_TYPE = {
  TEXT: "text",
  DRAW: "draw",
}

export const INVALID_MESSAGE = {
  DATE_BOX: "Enter date in format MM/DD/YYYY",
  TIME_BOX: "Enter time in format HH:MM",
}

export const LABEL_STYLE = {
  display: "none",
}
