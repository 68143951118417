import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { CenterContent, Loading } from 'components/layout/main'
import { useTenantScreeningStepUrl } from 'hooks/use-tenant-screening-step-url'
import { TOPBAR_HEIGHT } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_ACTION_DROPDOWN, EVENT_TENANT_SCREENING } from 'tracker/const'
import { OnboardingLandlordTSEmptyState } from 'v3/components/organisms/onboarding-ll-ts-empty-state'
import { useGetMyUserInvitation } from 'v3/hooks/use-get-my-invitations'

import { TenantScreeningLandingComponent } from './landing'

export const TenantScreeningLanding = ({ history }) => {
  const { getFirstScreeningRequestStepUrl } = useTenantScreeningStepUrl()
  const {
    data: userInvitation,
    isFetched: isUserInvitationFetched,
    isLoading: isUserInvitationLoading,
  } = useGetMyUserInvitation()
  const isInvitedUser = !isEmpty(userInvitation?.result)

  const handleClickTenantScreening = () => {
    tracker.trackEvent(EVENT_ACTION_DROPDOWN.SCREEN_FROM_CURRENT_PROPERTY, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.MENU_SCREENING,
    })

    tracker.trackEvent(EVENT_TENANT_SCREENING.EVENT_NAME.START_SCREENING_REQUEST, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.TENANT_SCREENING_MENU,
      has_screening: false,
    })

    history.push(getFirstScreeningRequestStepUrl())
  }


  if (isUserInvitationLoading) {
    return (
      <CenterContent withBreadcrumb withFilter>
        <Loading />
      </CenterContent>
    )
  }

  return isUserInvitationFetched && isInvitedUser ? (
    <OnboardingLandlordTSEmptyState height={`calc(100vh - ${TOPBAR_HEIGHT})`} />
  ) : (
    <TenantScreeningLandingComponent onCTAClick={handleClickTenantScreening} />
  )
}

export default compose(withRouter)(TenantScreeningLanding)
