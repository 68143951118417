import React from "react"
import styled from "styled-components"
import { BlueGradientButton, T36, B16 } from "@rentspree/component-v2"

const ApplyLimitPageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
  height: calc(92vh);
  padding: 0;
`

export const Img = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 70px;
  @media (max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`

export const ApplyLimitPageHeader = styled(T36)`
  line-height: 45px;
  @media (max-width: 991px) {
    ${props => props.mMargin && `margin:${props.mMargin}`};
    line-height: 32px;
  }
`
export const ApplyLimitPageDescDiv = styled.div`
  line-height: 22px;
  width: 500px;
  margin-bottom: 50px;
  @media (max-width: 991px) {
    line-height: 20px;
  }
`

export const ApplyLimitPageDesc = styled(B16)`
  margin: 0;
  @media (max-width: 991px) {
    font-size: 14px;
  }
`

export const ApplyLimitBlueGradientButton = styled(BlueGradientButton)`
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 991px) {
    ${props => props.mPadding && `padding:${props.mPadding}`};
  }
`

export const InformPageTemplate = ({
  iconSrc,
  headerText,
  descriptionLineOne,
  descriptionLineTwo,
  handleClickButton,
  buttonText,
  buttonId,
}) => (
    <ApplyLimitPageWrapper>
    <Img src={iconSrc} />
    <ApplyLimitPageHeader margin="0 0 10px 0" mMargin="0 0 10px 0">
        {headerText}
    </ApplyLimitPageHeader>
    <ApplyLimitPageDescDiv>
        <ApplyLimitPageDesc>
        {descriptionLineOne} <br />
        {descriptionLineTwo}
        </ApplyLimitPageDesc>
    </ApplyLimitPageDescDiv>
    <ApplyLimitBlueGradientButton
        extraLarge
        id={buttonId}
        padding="9px 25px"
        mPadding="9px 25px"
        text={buttonText}
        sourceSansPro
        height="40px"
        fontWeight={600}
        onClick={handleClickButton}
    />
    </ApplyLimitPageWrapper>
)