import generateApiCall from 'utils/generate-api-call'

import {
  GET_PAYEE,
  GET_PAYEE_REQUESTED,
  GET_PAYEE_SUCCEEDED,
  GET_PAYEE_FAILED,
  OVERRIDE_PAYEE_IDV_STATE,
} from './constants'

export const getPayee = payload => ({
  type: GET_PAYEE,
  payload,
})

// Override payee Idv state on FE
export const overridePayeeIdvState = payload => ({
  type: OVERRIDE_PAYEE_IDV_STATE,
  payload,
})

export const getPayeeAPI = generateApiCall([
  GET_PAYEE_REQUESTED,
  GET_PAYEE_SUCCEEDED,
  GET_PAYEE_FAILED,
])
