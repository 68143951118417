import { RESET_SHARE_REPORT_PROMPT_MODAL, TOGGLE_SHARE_REPORT_PROMPT_MODAL_CALL } from './constants'

export const toggleShareReportPromptModal = payload => ({
  type: TOGGLE_SHARE_REPORT_PROMPT_MODAL_CALL,
  payload,
})

export const resetToggleShareReportPromptModal = () => ({
  type: RESET_SHARE_REPORT_PROMPT_MODAL,
})
