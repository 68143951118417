import styled, { css } from 'styled-components'
import {
  COLOR,
  TOPBAR_HEIGHT,
  BREADCRUMBS_HEIGHT,
  FILTER_HEIGHT,
  FLEX_CENTER,
  REPORT_TABS_HEIGHT,
  REPORT_PADDING,
  MOBILE_SIDEBAR_HEIGHT,
  SMALL_HEADER_HEIGHT,
  MOBILE_ACCEPT_DENY_HEIGHT,
  NEWIA_CONTAINER_WIDTH,
  NEWIA_HEADER_HEIGHT_WITH_OUT_TABS,
  NEWIA_HEADER_HEIGHT_WITH_TABS,
} from '../../styles/settings'

// language=SCSS prefix=dummy{ suffix=}
export const bodyContent = styled.div`
  display: flex;
  min-height: calc(100vh - ${TOPBAR_HEIGHT} - 80px});

  @media (max-width: 991px) {
    flex-direction: column;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const mainContent = styled.div`
  background-color: ${props => props.bgColor || COLOR.bgDarkGrey};
  flex-grow: 1;
  ${props => props.relative && 'position: relative'};
  @media (max-width: 991px) {
    min-height: auto;
  }
  &.-w100 {
    width: 100%;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const centerContent = styled.div`
  ${FLEX_CENTER};
  flex-direction: column;
  position: relative;
  padding: 30px 15px;
  ${props => props.minHeight && `min-height: ${props.minHeight};`}
  height: calc(
    100vh - ${TOPBAR_HEIGHT} ${props => (props.noMargin ? '' : '- 10px')}
      ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
      ${props => props.withReportPadding && `- ${REPORT_PADDING}`}
      ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
      ${props => props.extraHeight && `- ${props.extraHeight}px`}
      ${props => props.withSmallHeader && `- ${SMALL_HEADER_HEIGHT}`}
      ${props => props.withNewIALandingPage && `- 200px`}
  );
  &.-w100 {
    width: 100%;
  }
  &.-mH100 {
    min-height: 100%;
  }
  ${props =>
    props.overlay &&
    css`
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
      z-index: 3;
    `};
  @media (max-width: 991px) {
    height: calc(
      100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT}
        ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
        ${props => props.withReportPadding && `- ${REPORT_PADDING}`}
        ${props => (props.noMargin ? '' : '- 10px')}
        ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
        ${props => props.withAcceptDeny && `+ ${MOBILE_ACCEPT_DENY_HEIGHT}`}
    );
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const whiteWrapper = styled.div`
  background-color: white;
  margin: ${props => (props.noMargin ? '0' : '5px')};
  padding-bottom: ${props => props.withPagination && '70px'};
  position: relative;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT} ${props => (props.noMargin ? '' : '- 10px')}
      ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
      ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
      ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
      ${props => props.extraPx && `-  ${props.extraPx}px`}
      ${props => props.withL1Template && '- 80px'}
  );
  ${props =>
    props.flexCenter &&
    css`
      ${FLEX_CENTER};
      flex-direction: column;
    `};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
  @media (max-width: 991px) {
    padding-bottom: ${props => props.withPagination && '55px'};
    padding-bottom: ${props => props.withStickyFooter && '75px'};
    min-height: calc(
      100vh - ${TOPBAR_HEIGHT} ${props => (props.noMargin ? '' : '- 10px')}
        ${props => props.withBreadcrumb && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.mExtraPx && `- ${props.mExtraPx}px`}
        ${props => props.noBreadcrumbMobile && `- ${BREADCRUMBS_HEIGHT}`}
        ${props => props.withReportTabs && `- ${REPORT_TABS_HEIGHT}`}
        ${props => props.withFilter && `- ${FILTER_HEIGHT} - 5px`}
        ${props => props.extraPx && `-  ${props.extraPx}px`}
        ${props => props.addMobileExtraPx && `+  ${props.addMobileExtraPx}px`}
    );
    ${props => props.mMinHeight && `min-height: ${props.mMinHeight}`};
    ${props => props.mMargin && `margin: ${props.mMargin}`};
  }

  ${props =>
    props.overFlow &&
    `
      overflow: auto
    `};
`
// language=SCSS prefix=dummy{ suffix=}
export const filterRow = styled.div`
  height: ${FILTER_HEIGHT};
  margin: 5px 5px 0 5px;
  display: flex;
  ${props => props.flexEnd && `flex-direction:flex-end;`} @media (max-width: 991px) {
    ${props => props.mJustify && `justify-content: ${props.mJustify}`};
  }

  > form {
    display: flex;
    flex-grow: 1;
    @media (max-width: 991px) {
      flex-direction: row-reverse;
    }
  }
`

export const ApplicationDetailWrapper = styled.div`
  ${props => !props.isMobile && `max-width: ${NEWIA_CONTAINER_WIDTH}`};
`

// language=SCSS prefix=dummy{ suffix=}
export const singleAppWrapper = styled.div`
  display: flex;
  position: relative;
  height: ${props =>
    props.isMobile
      ? `calc(100vh - ${TOPBAR_HEIGHT} - ${BREADCRUMBS_HEIGHT})`
      : `${
          props.hasTab
            ? `calc(100vh - ${NEWIA_HEADER_HEIGHT_WITH_TABS})`
            : `calc(100vh - ${NEWIA_HEADER_HEIGHT_WITH_OUT_TABS})`
        }`};

  @media (max-width: 991px) {
    display: block;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const reportWrapper = styled.div`
  background: ${COLOR.white};
  position: relative;
  @media print {
    padding: 0px;
    background: white;
    overflow-x: auto;
  }
`
export const ReportDiv = styled.div`
  padding: ${props => (props.noPadding ? '0' : '20px')} 0;
  padding-bottom: 0;
  background: ${COLOR.white};
  @media print {
    padding: 0;
  }
  &.hideMobile {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }
`

export const containerReport = styled.div`
  @media (max-width: 991px) {
    display: ${props => props.hideMobile && 'none'};
  }
`

export const div = styled.div`
  ${props => props.borderColor && `border: 1px solid ${COLOR[props.borderColor]}`};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`};
  ${props => props.centerWidth && `max-width: ${props.centerWidth}; margin: 0 auto`};
  ${props => props.inlineBlock && 'display: inline-block;'};
  ${props => props.relative && 'position: relative;'};
  ${props => props.absolute && 'position: absolute;'};
  ${props => props.bgColor && `background: ${props.bgColor}`};
  ${props => props.center && 'text-align: center;'};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.m0 && 'margin: 0px'};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.minHeight && `min-height: ${props.minHeight}px`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.flex && 'display: flex;'}
  ${props => props.flexGrow && `flex-grow: ${props.flexGrow};`}
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.lineHeight && `line-height: ${props.lineHeight}px;`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.overflow && `overflow: ${props.overflow};`}
  ${props => props.sourceSansPros && "font-family: 'Source Sans Pro';"}
  ${props => props.inter && 'font-family: Inter;'}
  ${props => props.fontSize && `font-size: ${props.fontSize}px;`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  ${props => props.isHide && 'display:none;'}
  ${props => props.hideDesktop && 'display: none'};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.cursor && `cursor: ${props.cursor}`};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${props => props.color && `color: ${props.color};`}
  ${props => props.wordBreak && `word-break: ${props.wordBreak};`}
  ${props => props.gap && `gap: ${props.gap};`}
  ${props => props.grid && `display: grid;`}
  @media (max-width: 991px) {
    ${props => props.mMargin && `margin: ${props.mMargin};`}
    ${props => props.mPadding && `padding: ${props.mPadding};`}
    ${props => props.hideDesktop && 'display: initial'};
    ${props => props.hideTablet && 'display: initial'};
  }
  ${props =>
    props.listingWrapper &&
    `
    @media (max-width: 991px) {
      flex-direction: column;
    }
  `}
`

export const loading = styled.div`
  ${props =>
    props.center &&
    css`
      width: 100%;
      ${FLEX_CENTER};
      height: 320px;
      background: white;
      z-index: 4;
    `};
`
