// Global variables
// Config switches
// Brand colors, etc
import { css } from 'styled-components'
export const TOPBAR_HEIGHT_BORDERLESS = '69px'
export const TOPBAR_HEIGHT = '70px'
export const SIDEBAR_WIDTH = '175px'
export const SIDEBAR_IPAD_WIDTH = '56px'
export const RIGHT_PANEL_WIDTH = '320px'
export const BREADCRUMBS_HEIGHT = '45px'
export const REPORT_TABS_HEIGHT = '50px'
export const MOBILE_WIDTH = '991px'
export const MOBILE_VERTICAL_WIDTH = '500px'
export const MOBILE_REPORT_WIDTH = '640px'
export const REPORT_PADDING = '60px'
export const FILTER_HEIGHT = '40px'
export const BORDER_RADIUS = '5px'
export const MOBILE_SIDEBAR_HEIGHT = '60px'
export const SMALL_HEADER_HEIGHT = '60px'
export const MOBILE_BREADCRUMBS_HEIGHT = '45px'
export const MOBILE_TAB_HEIGHT = '50px'
export const MOBILE_ACCEPT_DENY_HEIGHT = '45px'
export const GET_STARTED_WIDTH = '375px'
export const STEPPER_HEIGHT = '47px'
export const NEWIA_CONTAINER_WIDTH = '1040px'
export const NEWIA_HEADER_HEIGHT_WITH_TABS = '250px'
export const NEWIA_HEADER_HEIGHT_WITH_OUT_TABS = '180px'

// language=SCSS prefix=dummy{ suffix=}
export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const INLINE_FLEX_CENTER = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
export const ANIMATE = {
  fast: '0.2s',
  normal: '0.5s',
  slow: '1s',
}

export const IMG_FILTER = {
  fontBlue:
    'invert(56%) sepia(27%) saturate(5910%) hue-rotate(191deg) brightness(101%) contrast(98%)',
  newDeepBlue:
    'invert(80%) sepia(59%) saturate(4500%) hue-rotate(193deg) brightness(100%) contrast(100%)',
  iconHoverDeepBlue:
    'invert(49%) sepia(90%) saturate(6186%) hue-rotate(191deg) brightness(96%) contrast(107%)',
  newGrey: 'invert(2%) sepia(0%) saturate(10%) hue-rotate(141deg) brightness(96%) contrast(85%)',
  placeholderGrey:
    'invert(72%) sepia(0%) saturate(0%) hue-rotate(148deg) brightness(96%) contrast(86%)',
}

export const COLOR = {
  darkBlue: 'rgb(50, 120, 179)',
  blue: 'rgb(108, 164, 210)',
  lightBlue: 'rgb(153, 209, 255)',
  steelBlue: '#37495F',
  steelBlueDark: '#303E51',
  darkSteelBlue: 'rgb(39, 50, 66)',
  darkSteelBlue50: 'rgba(39, 50, 66, 0.5)',
  darkDeepBlue: 'rgb(29, 102, 206)',
  deepBlue: 'rgb(61, 138, 247)',
  swallowBlue: '#00B4CD',
  whiteBlue: '#308DCE',
  activeBlue: '#3B99FC',
  linkHoverBlue: '#195DB8',
  quiteLightBlue: '#007ADF',

  darkOrange: 'rgb(184, 76, 26)',
  orange: 'rgb(246, 100, 32)',
  lightOrange: 'rgb(255, 163, 120)',
  newOrange: '#FF8A23',
  darkDeepOrange: '#E07414',

  brown: '#AC932D',

  darkNegativeRed: '#a72f31',
  negativeRed: '#F15555',
  lightRed: 'rgba(255, 233, 233, 0.9)',
  redOrange: '#FFD2D2',
  redPale: '#FFE9E9',
  highlightRed: '#D43737',
  lightSoftRed: 'rgba(241, 85, 85, 0.1)',

  darkYellow: '#A7903F',
  yellow: '#D3B84D',
  lightYellow: '#FFE79F',
  lemonGreen: '#b0c640',

  darkGreen: '#67A033',
  green: '#80C640',
  lightGreen: '#BCF090',
  mintGreen: '#00d5a9',
  cyanGreen: '#1FC06D',

  bgCream: '#f2f1ef',
  cream: 'rgb(220, 214, 176)',
  choc: 'rgb(69, 54, 41)',

  lineGrey: '#D8D8D8',
  bgGrey: '#F1F1F1',
  bgGreyRGB: '241, 241, 241',
  bgDarkGrey: '#EEEEEE',
  textBlack: '#333333',
  textGrey: '#888888',
  textBlue: '#0062B3',
  titleBlack: '#666666',
  titleGrey: '#999999',
  lightTextGrey: '#9a9a9a',
  hyphenGrey: '#c4c4c4',
  borderGrey: ' #e5e5e5',
  hoverGrey: 'rgba(0, 0, 0, 0.1)',
  white: '#FFFFFF',
  whiteContainer: '#FEFEFE',
  whiteHeader: '#FCFCFC',
  black: '#000000',
  blackInput: '#777777',
  grayscale40: '#858585',
  grayscale50: '#707070',
  grayLv1: '#333333',
  grayLv2: '#5F5F5F',
  grayLv3: '#AAAAAA',
  grayLv4: '#E5E5E5',
  grayLv6: '#F1F2F2',
  grayscale70: '#494949',
  darkerGray: '#e1e1e1',
  lineGray: '#d2d2d2',
  selectionGrey: '#F8F8F8',
  placeholderGrey: '#AAAAAA',
  borderGray: '#CECDCD',

  grayFade: '#eceeef',
  lightBlueHEX: '#E2F0FF',
  lightBlueRGB: '226, 240, 255',
  lightBlueRGBA: '226, 240, 255, 0.9',

  // CAA
  newBlack: '#4A4A4A',
  newGrey: '#E5E5E5',

  // new credit-report
  fontBlue: '#3b99fc',
  grayLight: '#f9f9f9',
  fontRed: '#f15555',
  fontOrange: '#ffa65e',
  fontYellowDeep: '#a7903f',
  fontRedDeep: '#a33434',
  fontLink: '#0074C8',

  // Payment Modal
  bordorDetailColor: 'rgba(151, 151, 151, 0.3)',
  payingBackground: '#e2f0ff',

  // new rentspree color
  newDeepBlueGradient: '#2563AD',
  newDeepBlueGradientMid: '#004BB0',
  newDeepBlue: '#007ADF',
  greenCyan: '#00ECBC',
  cyanBlue: '#546A84',
  lightHawaiiBlue: '#00C9E5',
  shallowBlue: '#00B4CD',
  shallowBlueLv5: '#F1FDFF',

  blueHover: '#6CA4D2',
  newSteelBlue: '#405369',

  // accept deny
  acceptGreen: '#517A2B',
  denyRed: '#A03131',

  // email verification
  shallowBluePale: '#E0FBFF',

  // people concept
  steelBluePale: '#E7ECED',
  shallowBlueDark: '#0099AE',
  redLight: '#FF7D7D',
  imageGrey: '#8995A3',
  redLv1: '#D0403A',
  redLv2: '#ED5E50',
  redLv5: '#FEF4F3',

  // rent payment verification
  yellowPale: '#FEF5D9',
  yellowDark: '#AC932D', // same as COLOR.brown
  greenPale: '#E6FFCF',
  yellowLight: '#F2D76B',
  shallowBlueHover: '#007D8E',
  mintGreenHover: '#009F7E',
  mintGreenPale: '#DDFFF8',
  orangePale: '#FFEEDF',
  redHover: '#B61919',
  newYellow: '#FFC63B',
  newYellowHover: '#F4B000',
  purple: '#6984EE',
  purpleLv2: '#8E82FF',
  purpleHover: '#1E42D2',
  purplePale: '#CCD6FF',
  blueLv2: '#004BB0',
  blueLv3: '#195DB8',
  blueLV6: '#E2F0FF',
  blueLv7: '#F3F9FF',
  horizontalLineGray: '#DADADA',
  grayLv7: '#F9F9F9',

  // new tag color
  tagSwallowBlueLv1: '#00B4CD',
  tagSwallowBlueLv2: '#00C9E5',
  tagMintLv2: '#00ECBC',
  tagMintLv3: '#71FFE2',
  tagOrangeLv2: '#EE8A04',
  tagOrangeLv3: '#FFA842',
  tagRedLv2: '#D0403A',
  tagRedLv3: '#ED5E50',
  tagYellowLv2: '#DEB71A',
  tagYellowLv3: '#FBDB49',
  tagPurpleLv1: '#4C36A7',
  tagPurpleLv2: '#8E82FF',

  // action dropdown
  trueBlue: '#3f8cd3',
  amnesiaBlue: '#195db8',
  lightViolet: '#9747ff26',

  blue10: '#EEE6FD',
  blue30: '#B297F7',
  blue90: '#0B2A53',
  outlineRed: '#D22E2C',

  blackText: '#222222',

  // Panel
  panelYellow: '#FFFCE1',

  neutral20: '#F9F9FF',
  newPrimary: '#1f15e1',

  statusSuccess: '#1A962F',
  statusLookGood: '#5E00CD',
  statusNeutral: '#22222280',
  statusCaution: '#D8BB00',
  statusError: '#B61B19',
}

export const colorShades = [
  {
    bgRgb: '226, 240, 255',
    border: COLOR.fontBlue,
    content: COLOR.fontBlue,
  }, // 1
  {
    bgRgb: '254, 245, 217',
    border: COLOR.yellow,
    content: COLOR.yellow,
  }, // 2
  {
    bgRgb: '221, 255, 248',
    border: COLOR.mintGreen,
    content: COLOR.mintGreen,
  }, // 3
  {
    bgRgb: '255, 238, 223',
    border: COLOR.newOrange,
    content: COLOR.newOrange,
  }, // 4
  {
    bgRgb: '224, 251, 255',
    border: COLOR.lightHawaiiBlue,
    content: COLOR.lightHawaiiBlue,
  }, // 5
  {
    bgRgb: '255, 233, 233',
    border: COLOR.fontRed,
    content: COLOR.fontRed,
  }, // 6
  {
    bgRgb: '230, 255, 207',
    border: COLOR.green,
    content: COLOR.green,
  }, // 7
  {
    bgRgb: '241, 241, 241',
    border: COLOR.placeholderGrey,
    content: COLOR.placeholderGrey,
  }, // 8
]

export const BOX_SHADOW_1 = '0 2px 7px 0 rgba(0, 0, 0, 0.1)'
export const BOX_SHADOW_2 = '0 2px 12px 0 rgba(0, 0, 0, 0.2)'
export const BOX_SHADOW_3 = '0px 2px 15px rgba(0, 0, 0, 0.25)'
export const BOX_SHADOW_TOP_1 = '0 -2px 7px 0 rgba(0, 0, 0, 0.1)'
export const DROPDOWN_SHADOW = '0 0 20px 0 rgba(0, 0, 0, 0.15)'

export const BOX_GREY_BORDER = '1px solid #E5E5E5'

export const BACK_DROP = 'rgba(0, 0, 0, 0.41)'

export const FONTAWESOME_HEXCODE_ICONS = {
  SEARCH: '0xf002',
}

export const DIAMOND_LINEAR_GRADIENT = `linear-gradient(rgba(249, 249, 249, 0.5) 0%, ${COLOR.grayLight})`

export const ACTIVE_PROPERTY = '#81C63F'
export const ARCHIVED_PROPERTY = '#D0D0D0'

export const breakpoints = {
  mobileVertical: styles => `
    @media (max-width: ${MOBILE_VERTICAL_WIDTH}) {
      ${styles}
    }
  `,
  mobile: styles => `
    @media (max-width: ${MOBILE_WIDTH}) {
      ${styles}
    }
  `,
  mobileReport: styles => `
  @media screen and (max-width: ${MOBILE_REPORT_WIDTH}) {
    ${styles}
  }
`,
  mobileContact: styles => `
  @media (max-width: 1223px) {
    ${styles}
  }
`,
}

export const NEW_BLUE_LINEAR_GRADIENT = `linear-gradient(90deg, ${COLOR.newDeepBlueGradient} -0.73%, ${COLOR.newDeepBlue} 100.02%)`
