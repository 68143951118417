import React from 'react'

import {
  Content,
  InformationPanel,
  Section,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  InformationLine,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  RecordWrapperStyled,
  RecordContainerStyled,
  RecordNumberStyled,
} from './doc-components/records'

const HalfScreenWidth = 'calc((100% - 20px) / 2)'

const InfomationLineHalfScreen = props => (
  <InformationLine {...props} labelWidth={HalfScreenWidth} value={props.value || '-'} />
)

export const PersonalInformation = ({ firstName, middleName, lastName, suffix }) => (
  <Section>
    <SubSectionHeader title="Personal Info" />
    <Content>
      <InformationPanel>
        <InfomationLineHalfScreen label="First Name" value={firstName} />
        <InfomationLineHalfScreen label="Middle Name" value={middleName} />
        <InfomationLineHalfScreen label="Last Name" value={lastName} />
        <InfomationLineHalfScreen label="Suffix" value={suffix} />
      </InformationPanel>
    </Content>
  </Section>
)

export const Residence = ({
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  city,
  state,
  postalCode,
  country,
}) => (
  <Section>
    <SubSectionHeader title="Residence" />
    <Content>
      <InformationPanel>
        <InfomationLineHalfScreen label="Address" value={addressLine1} />
        {addressLine2 && <InfomationLineHalfScreen label=" " value={addressLine2} />}
        {addressLine3 && <InfomationLineHalfScreen label=" " value={addressLine3} />}
        {addressLine4 && <InfomationLineHalfScreen label=" " value={addressLine4} />}
        <InfomationLineHalfScreen label="City" value={city} />
        <InfomationLineHalfScreen label="State" value={state} />
        <InfomationLineHalfScreen label="Postal Code" value={postalCode} />
        <InfomationLineHalfScreen label="Country" value={country} />
      </InformationPanel>
    </Content>
  </Section>
)

export const Records = ({
  criminalIdentityCount,
  sexOffenderIdentityCount,
  mostWantedIdentityCount,
  ofacIdentityCount,
  otherIdentityCount,
}) => (
  <Section>
    <SubSectionHeader title="Records" />
    <RecordWrapperStyled>
      <RecordContainerStyled active={criminalIdentityCount > 0}>
        Criminal Identity
        <RecordNumberStyled>{criminalIdentityCount}</RecordNumberStyled>
      </RecordContainerStyled>
      <RecordContainerStyled active={sexOffenderIdentityCount > 0}>
        Sex Offender Identity
        <RecordNumberStyled>{sexOffenderIdentityCount}</RecordNumberStyled>
      </RecordContainerStyled>
      <RecordContainerStyled active={mostWantedIdentityCount > 0}>
        Most Wanted Identity
        <RecordNumberStyled>{mostWantedIdentityCount}</RecordNumberStyled>
      </RecordContainerStyled>
      <RecordContainerStyled active={ofacIdentityCount > 0}>
        OFAC Identity
        <RecordNumberStyled>{ofacIdentityCount}</RecordNumberStyled>
      </RecordContainerStyled>
      <RecordContainerStyled active={otherIdentityCount > 0}>
        Other Identity
        <RecordNumberStyled>{otherIdentityCount}</RecordNumberStyled>
      </RecordContainerStyled>
    </RecordWrapperStyled>
  </Section>
)

const RequestedConsumerInformation = ({ requestedConsumer, records }) => {
  const {
    FirstName,
    MiddleName,
    LastName,
    GenerationalSuffix,
    Address: {
      AddressLine1,
      AddressLine2,
      AddressLine3,
      AddressLine4,
      Locality,
      Region,
      PostalCode,
      Country,
    },
  } = requestedConsumer

  const personalInfoProps = {
    firstName: FirstName,
    middleName: MiddleName,
    lastName: LastName,
    suffix: GenerationalSuffix,
  }

  const residenceAddressProps = {
    addressLine1: AddressLine1,
    addressLine2: AddressLine2,
    addressLine3: AddressLine3,
    addressLine4: AddressLine4,
    city: Locality,
    state: Region,
    postalCode: PostalCode,
    country: Country,
  }

  return (
    <>
      <PersonalInformation {...personalInfoProps} />
      <Residence {...residenceAddressProps} />
      <Records {...records} />
    </>
  )
}

export default RequestedConsumerInformation
