import get from "lodash/get"
import { createSelector } from "reselect"
import { initialState } from "./reducers"
import { KEY } from "./constants"

export const getSubscriptionPlanState = state => get(state, KEY, initialState)

export const makeSelectProducts = () =>
  createSelector(
    getSubscriptionPlanState,
    state => get(state, "products"),
  )

export const makeSelectUserSubscription = () =>
  createSelector(
    getSubscriptionPlanState,
    state => get(state, "subscription"),
  )

export const makeSelectSelectedPriceName = () =>
  createSelector(
    getSubscriptionPlanState,
    state => get(state, "selectedPriceName"),
  )
