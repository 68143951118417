import React from 'react'
import classNames from 'classnames/bind'
import isNil from 'lodash/isNil'
import style from './button-pdf.scss'
import { isFirefoxAndroid } from '../../helper/ua-parser-js'

const c = classNames.bind(style)

export default class ButtonOverlayPDF extends React.Component {
  constructor() {
    super()
    this.state = {
      isShow: true,
    }
    this.time = 8000
  }

  componentDidMount() {
    this.hideTimeout()
    document.addEventListener('touchstart', this.handleClick.bind(this))
    document.addEventListener('mousedown', this.handleClick.bind(this))
    document.addEventListener('mousemove', this.handleHover.bind(this))
  }

  componentWillUnmount() {
    if (this.timeoutFunction) clearTimeout(this.timeoutFunction)
    document.removeEventListener('touchstart', this.handleClick.bind(this))
    document.removeEventListener('mousedown', this.handleClick.bind(this))
    document.removeEventListener('mousemove', this.handleHover.bind(this))
  }

  handleHover() {
    // const height = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight

    // if (e && e.offsetY > (height - 300)) {
    this.handleClick()
    // }
  }

  handleClick() {
    if (this.state.isShow === false) {
      this.handleIsShow(true)
      this.hideTimeout()
    }
  }

  hideTimeout() {
    this.timeoutFunction = setTimeout(() => {
      this.handleIsShow(false)
    }, this.time)
  }

  handleIsShow(isShow) {
    this.setState({
      isShow,
    })
  }

  isFirefoxAndroid() {
    if (!isNil(this.props.isFirefoxAndroid)) return this.props.isFirefoxAndroid
    return isFirefoxAndroid
  }

  render() {
    const { isShareAvailable } = this.props
    const isReady = !this.state.isShow && !this.props.isFetching ? '-hide' : ''
    const right = !this.props.isPrintPage || !this.props.isShowRightPanel ? '-right' : ''
    const hideLine = this.isFirefoxAndroid() ? '-hideLine' : ''
    const adjustBottom = this.props.hasESignFooter ? '-bottom' : ''
    const newDesign = '-new-design'

    return (
      <div className={c('wrapper-overlay', newDesign, isReady, right, hideLine, adjustBottom)}>
        {isShareAvailable && (
          <div
            onClick={this.props.onClickShare}
            onKeyDown={this.props.onClickShare}
            className={c('share-report-image')}
            role="button"
            tabIndex={-1}
          />
        )}
        {!this.isFirefoxAndroid() && (
          <i
            className={c('icon-printer', 'icon', '-print')}
            onClick={this.props.onPrint}
            onKeyDown={this.props.onPrint}
            role="button"
            tabIndex={-1}
          />
        )}
        <PDFButton onClick={this.props.onClick} isFetching={this.props.isFetching} />
        <PDFButton
          onClick={this.props.onMobileClick}
          isFetching={this.props.isFetching}
          additionalClass="-mobile"
        />
      </div>
    )
  }
}

export const Loader = ({ className, styleObject }) => (
  <div className={c('loader-wrapper', 'pdf-button', className)} style={styleObject}>
    <i className="fas fa-circle-notch fa-spin" />
  </div>
)

export const PDFButton = ({ onClick, isFetching, additionalClass = null }) =>
  !isFetching ? (
    <i
      className={c('far fa-file-pdf', 'icon', 'pdf-button', additionalClass)}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={-1}
    />
  ) : (
    <Loader className={additionalClass} styleObject={{ lineHeight: '0px' }} />
  )
