import { createSelector } from "reselect"
import get from "lodash/get"

import { selectSubscriptions } from "../selectors"

const getDocument = state => get(state, "document", {})

export const selectDocument = createSelector(
  getDocument,
  state => state,
)

export const selectPdfFile = createSelector(
  selectDocument,
  state => get(state, "pdfFile", {}),
)

export const selectShowPdfSuccess = createSelector(
  selectDocument,
  state => get(state, "showPdfSuccess", false),
)

export const selectAuthorize = createSelector(
  selectSubscriptions,
  state => get(state, "authorize", {}),
)

export const selectIsFetchingPdfFile = createSelector(
  getDocument,
  document => get(document, "isFetchingPdfFile"),
)
