import React from "react"
import styled from "styled-components"
import T36 from "@rentspree/component-v2/dist/typography/t36"

export const TitleWrapper = styled.div`
  margin-bottom: 30px;
  > h1 :first-of-type {
    line-height: 45px;
  }
`
export const TitleDescription = styled.div`
  line-height: 20px;
  font-size: 16px;
`

export const TitleWithDescription = ({ description }) => (
  <TitleWrapper>
    <T36 mMargin="30px 0 3px 0" margin="40px 0 3px 0">
      Select a property type
    </T36>
    <TitleDescription>{description}</TitleDescription>
  </TitleWrapper>
)
