import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import {
  selectRenterInsuranceList,
  selectIsFetchingRenterInsuranceList,
  selectRenterInsurancePagination,
  selectIsErrorRenterInsuranceList,
} from "./selectors"
import reducer from "./reducer"
import * as actions from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  renterInsuranceList: selectRenterInsuranceList,
  isFetching: selectIsFetchingRenterInsuranceList,
  isError: selectIsErrorRenterInsuranceList,
  pagination: selectRenterInsurancePagination,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "renterInsurance", reducer })
export const withSaga = injectSaga({ key: "renterInsurance", saga })
