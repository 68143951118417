import { object } from "yup"
import { validateTuEmail } from "legacy/constants/validations"

export const messagingEmailSchema = ({ email }) => ({
  mapper: () => ({
    email,
  }),
  validation: object().shape({
    email: validateTuEmail(true),
  }),
})
