import { takeLatest, put, call, all } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import { UserApiInstance } from 'utils/api-interceptor'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import { updateAgentLicensesSaga } from 'containers/agent-profile/saga'
import tracker from 'tracker'
import { LRA_INTEGRATION } from 'tracker/const'

import { updateDreLicenseApi } from 'containers/request/step-update-dre-license/actions'
import { UPDATE_DRE_LICENSE_ENDPOINT } from 'containers/request/step-update-dre-license/constants'

import { UPDATE_DRE_LICENSE_CALL } from './constants'

export const callUpdateDreLicenseApi = ({ licenses }) =>
  UserApiInstance.put(buildPath(UPDATE_DRE_LICENSE_ENDPOINT, {}), {
    licenses,
  })

// const shouldUseContinuePath = (payer, continuePath) =>
//   continuePath && payer === PAYER_TYPES.RENTER

export function* updateDreLicenseSaga({ payload }) {
  yield put(updateDreLicenseApi.request())
  try {
    const { licenses, propertyId, screeningRequestId, requestOptions, getNextPath } = payload
    yield call(updateAgentLicensesSaga, licenses?.[0])
    const response = yield call(callUpdateDreLicenseApi, { licenses })
    yield put(updateDreLicenseApi.success(response))
    yield call([tracker, 'trackEvent'], LRA_INTEGRATION.COMPLETE_DRE_LICENSE)

    const nextPath = getNextPath({
      requestOptions,
      propertyId,
      // screeningMethod,
      screeningRequestId,
      agentLicenses: licenses,
    })

    yield put(push(nextPath))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(updateDreLicenseApi.failure())
  }
}

export function* watchUpdateDreLicenseApiCall() {
  yield takeLatest(UPDATE_DRE_LICENSE_CALL, updateDreLicenseSaga)
}

export function* rootSaga() {
  yield all([watchUpdateDreLicenseApiCall()])
}

export default rootSaga
