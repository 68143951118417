/* eslint-disable no-param-reassign */
import Typography from '@rentspree/component-2023.components.atoms.typography'
import AutoComplete from '@rentspree/component-2023.components.organisms.autocomplete'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import styled from 'styled-components'

import { selectProfile } from 'containers/user/selectors'
import { useSnackbar } from 'v3/components/snackbar'
import { CreateTenantForm } from 'v3/containers/rent-payment/setup-page/set-up-for-landlord/create-tenant-form'
import { useCreateTenant } from 'v3/containers/rent-payment/shared/hooks/use-create-tenant'

const selectOwnerEmail = createSelector(selectProfile, state => get(state, 'email', ''))

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TenantForm = props => {
  const {
    options,
    setFieldValue,
    touched,
    errors,
    fetchTenants = () => {},
    setFieldTouched,
    values,
  } = props
  const [showTenantForm, setShowTenantForm] = useState(false)
  const [selectTenant, setSelectTenant] = useState(null)
  const { showSnackbar } = useSnackbar()

  const ownerEmail = useSelector(selectOwnerEmail)

  const { createTenant, status: createTenantApiStatus } = useCreateTenant({
    onSuccess: response => {
      setShowTenantForm(false)
      fetchTenants()
      setSelectTenant({
        label: `${response?.firstName} ${response?.lastName} (${response.email})`,
        value: response?.id,
      })
      showSnackbar({
        message: 'New tenant added',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      })
    },
  })

  useEffect(() => {
    if (values.renterInfo) {
      setSelectTenant({
        label: `${values.renterInfo.firstName} ${values.renterInfo.lastName} (${values.renterInfo.email})`,
        value: values.renterInfo.id,
      })
    }
  }, [values.renterInfo])

  useEffect(() => {
    if (selectTenant) setFieldValue('selectedTenant', selectTenant.value)
  }, [selectTenant])

  return (
    <SectionContainer>
      <Typography variant="title">Tenant</Typography>
      <InputContainer>
        <AutoComplete
          name="selectedTenant"
          inputProps={{
            label: 'Select tenant',
            required: true,
            error: get(touched, 'selectedTenant', false) && get(errors, 'selectedTenant'),
            helperText: get(touched, 'selectedTenant', false) && get(errors, 'selectedTenant'),
            name: 'selectedTenant',
          }}
          options={options}
          visibleItemCount={3}
          ctaProps={{
            text: 'Add new tenant',
            onClick: () => {
              setShowTenantForm(true)
            },
          }}
          value={selectTenant}
          onChange={(e, value) => setSelectTenant(value)}
          onBlur={() => {
            setFieldTouched('selectedTenant')
          }}
          error={get(touched, 'selectedTenant', false) && get(errors, 'selectedTenant')}
        />
      </InputContainer>
      <CreateTenantForm
        isOpen={showTenantForm}
        ownerEmail={ownerEmail}
        onSubmit={createTenant}
        createTenantApiStatus={createTenantApiStatus}
        onClose={() => setShowTenantForm(false)}
      />
    </SectionContainer>
  )
}
