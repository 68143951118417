import React from 'react'
import Styled from 'styled-components'
import { Dialog } from '@rentspree/component-2023.components.atoms.dialog'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@rentspree/component-2023.components.atoms.button'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import Link from '@rentspree/component-2023.components.atoms.link'

import IncomeVerificationAnnouncement from '../../../../images/tenant-screening/income-verification-announcement.svg'

const UnderlineLink = Styled(Link)`
  text-decoration: underline !important;
`

const AnnouncementModal = props => {
  const { isOpen, detailText, onClickCTAGetReport, onClose, onViewSampleReport } = props
  return (
    <Dialog
      PaperProps={{ style: { borderRadius: '12px 12px 12px 12px' } }}
      open={isOpen}
      size='large'
      scroll="body"
      maxWidth="md"
      onClose={onClose}
    >
      <Card id="new-ia-post-launch-modal" sx={{ maxWidth: 552 }} variant="outlined" noPaddingTop>
        <IconButton
          onClick={onClose}
          color="black"
          style={{ position: 'absolute', top: 20, right: 20 }}
        >
          <CloseIcon />
        </IconButton>
        <img
          width="812px"
          height="100%"
          srcSet={IncomeVerificationAnnouncement}
          src={IncomeVerificationAnnouncement}
          alt="IncomeVerificationAnnouncement"
          loading="lazy"
        />

        <CardElement>
          <Typography variant="title-medium" style={{ marginBottom: '10px' }}>
            {detailText.HEADER_TEXT}
          </Typography>
          <br />
          <Typography variant="body-medium">{detailText.SUBHEADER_TEXT}</Typography>
        </CardElement>

        <CardElement>
          {detailText.DESCRIPTIONS.map(element => (
            <li>
              <Typography variant="body-medium">{element}</Typography>
            </li>
          ))}
          <div style={{ marginTop: '10px' }}>
            <UnderlineLink color="black" onClick={onViewSampleReport}>
              {detailText.SAMPLE_REPORT_CTA_TEXT}
            </UnderlineLink>
          </div>
        </CardElement>
        <CardElement>
          <Button
            style={{ display: 'flex', marginLeft: 'auto' }}
            variant="contained"
            color="secondary"
            onClick={onClickCTAGetReport}
          >
            {detailText.CTA_TEXT}
          </Button>
        </CardElement>
      </Card>
    </Dialog>
  )
}

export default AnnouncementModal
