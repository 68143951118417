import React from "react"
import { Link } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"
export const UPDATE_FILES = "UPDATE_FILES"
export const UPDATE_PROGRESS = "UPDATE_PROGRESS"
export const UPLOAD_FILE_CALL = "UPLOAD_FILE_CALL"
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE"
export const REMOVE_FILE = "REMOVE_FILE"
export const REPLACE_FILE = "REPLACE_FILE"
export const REMOVE_ALL_FILES = "REMOVE_ALL_FILES"
// create envelope type
export const CREATE_ENVELOPE_CALL = "CREATE_ENVELOPE_CALL"
export const CREATE_ENVELOPE_REQUEST = "CREATE_ENVELOPE_REQUEST"
export const CREATE_ENVELOPE_SUCCESS = "CREATE_ENVELOPE_SUCCESS"
export const CREATE_ENVELOPE_FAILURE = "CREATE_ENVELOPE_FAILURE"

// update envelope type
export const UPDATE_ENVELOPE_CALL = "UPDATE_ENVELOPE_CALL"
export const UPDATE_ENVELOPE_REQUEST = "UPDATE_ENVELOPE_REQUEST"
export const UPDATE_ENVELOPE_SUCCESS = "UPDATE_ENVELOPE_SUCCESS"
export const UPDATE_ENVELOPE_FAILURE = "UPDATE_ENVELOPE_FAILURE"

export const SET_ERROR = "SET_ERROR"
export const CLEAR_UPLOAD_FILE_ERROR = "CLEAR_UPLOAD_FILE_ERROR"

// Others
export const SORTING_ORDER_FILES = "SORTING_ORDER_FILES"
export const UNMOUNT_UPLOAD_FILES = "UNMOUNT_UPLOAD_FILES"

export const ADOBE_HELP_PASSWORD_LINK =
  "https://helpx.adobe.com/acrobat/using/securing-pdfs-passwords.html"
export const RENTSPREE_REQUEST_SUPPORT_LINK =
  "https://support.rentspree.com/en/kb-tickets/new"

export const ERROR_TYPE = {
  TYPE_AND_SIZE: "TYPE_AND_SIZE",
  ENCRYPTED: "ENCRYPTED",
  FORMS: "FORMS",
  WRONG_TYPE: "WRONG_TYPE",
  LARGE_FILE: "LARGE_FILE",
  "WRONG_TYPE, LARGE_FILE": "WRONG_TYPE, LARGE_FILE",
  INVALID_PDF: "INVALID_PDF",
}

export const ERROR_TRACKING = {
  ENCRYPTED: "encrypted_file",
  FORMS: "editable_file",
  WRONG_TYPE: "wrong_format",
  LARGE_FILE: "large_file",
  "WRONG_TYPE, LARGE_FILE": "wrong_format, large_file",
}

export const ERROR_MESSAGE = {
  [ERROR_TYPE.ENCRYPTED]: (
    <div>
      Encrypted file is not supported.{` `}
      <Link
        target="_blank"
        id="adobeLink"
        weight="600"
        color={COLOR.activeBlue}
        hoverColor={COLOR.newDeepBlue}
        text="How to unencrypt a file"
        href={ADOBE_HELP_PASSWORD_LINK}
      />
    </div>
  ),
  [ERROR_TYPE.FORMS]: (
    <div>
      File containing text field(s) is not supported.{` `}
      <Link
        target="_blank"
        href={RENTSPREE_REQUEST_SUPPORT_LINK}
        id="rspSupportLink"
        weight="600"
        color={COLOR.activeBlue}
        hoverColor={COLOR.newDeepBlue}
        text="Contact support team"
      />
      {` `}for help.
    </div>
  ),
  [ERROR_TYPE.LARGE_FILE]:
    "Your file(s) are too large or an invalid type. Please remove file(s) or re-upload.",
  [ERROR_TYPE.WRONG_TYPE]:
    "Your file(s) are too large or an invalid type. Please remove file(s) or re-upload.",
  "WRONG_TYPE, LARGE_FILE":
    "Your file(s) are too large or an invalid type. Please remove file(s) or re-upload.",
  [ERROR_TYPE.INVALID_PDF]: (
    <div>
      {`The file couldn't be able to be uploaded. Please re-upload or`}
      {` `}
      <Link
        target="_blank"
        href={RENTSPREE_REQUEST_SUPPORT_LINK}
        id="rspSupportLink"
        weight="600"
        color={COLOR.activeBlue}
        hoverColor={COLOR.newDeepBlue}
        text="contact support team"
      />
      {` `}for help.
    </div>
  ),
}
