import generateApiCall from "utils/generate-api-call"
import {
  GET_TENANT_SCREENING_NOTIFICATION_CALL,
  GET_TENANT_SCREENING_NOTIFICATION_REQUEST,
  GET_TENANT_SCREENING_NOTIFICATION_SUCCESS,
  GET_TENANT_SCREENING_NOTIFICATION_FAILURE,
  GET_TASK_NOTIFICATION_CALL,
  GET_TASK_NOTIFICATION_REQUEST,
  GET_TASK_NOTIFICATION_SUCCESS,
  GET_TASK_NOTIFICATION_FAILURE,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_CALL,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_REQUEST,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_SUCCESS,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_FAILURE,
} from "./constants"
import { TYPES } from "./const"

export const toggleCollapse = data => ({
  type: TYPES.TOGGLE_COLLAPSE,
  payload: data,
})

export const getTenantScreeningNotification = (page, filter, search) => ({
  type: GET_TENANT_SCREENING_NOTIFICATION_CALL,
  payload: {
    options: {
      page,
      filter,
      search,
    },
  },
})

export const getTaskNotification = () => ({
  type: GET_TASK_NOTIFICATION_CALL,
})

export const getUnseenRentalPaymentNotifications = () => ({
  type: GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_CALL,
})

export const getTenantScreeningNotificationApiState = generateApiCall([
  GET_TENANT_SCREENING_NOTIFICATION_REQUEST,
  GET_TENANT_SCREENING_NOTIFICATION_SUCCESS,
  GET_TENANT_SCREENING_NOTIFICATION_FAILURE,
])

export const getUnseenRentalPaymentNotificationsApiState = generateApiCall([
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_REQUEST,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_SUCCESS,
  GET_UNSEEN_RENTAL_PAYMENT_NOTIFICATION_FAILURE,
])

export const getTaskNotificationApiState = generateApiCall([
  GET_TASK_NOTIFICATION_REQUEST,
  GET_TASK_NOTIFICATION_SUCCESS,
  GET_TASK_NOTIFICATION_FAILURE,
])
