import { takeLatest, put, call, all } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'
import tracker from 'tracker'
import { EVENT_REQUEST_SCREENING } from 'tracker/const'
import {
  updatePropertyDetailsApi,
  getPropertyApi,
  createContactFromPropertyDetailApiState,
} from './actions'
import {
  API_GET_PROPERTY,
  API_UPDATE_PROPERTY_DETAILS,
  UPDATE_PROPERTY_DETAILS_CALL,
  GET_PROPERTY_CALL,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_CALL,
  CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_API,
  UPDATE_PROPERTY_DETAILS_AND_CREATE_CONTACT_CALL,
} from './const'

// we also have fetchProperty in legacy code at app/legacy/apis/property.api.js
// TODO: deprecate legacy code
export const callGetPropertyApi = ({ propertyId }) =>
  apiInstanceWithErrorHandler.get(buildPath(API_GET_PROPERTY, { propertyId }))

export const callUpdatePropertyDetailsApi = ({ propertyId, propertyDetails, landlordProfile }) =>
  apiInstanceWithErrorHandler.put(buildPath(API_UPDATE_PROPERTY_DETAILS, { propertyId }), {
    propertyDetails,
    landlordProfile,
  })

export function* getPropertySaga({ payload }) {
  yield put(getPropertyApi.request())
  try {
    const response = yield call(callGetPropertyApi, payload)
    yield put(getPropertyApi.success(response))
  } catch (err) {
    yield put(getPropertyApi.failure(err))
  }
}

export function* updatePropertyDetailsSaga({ payload }) {
  yield put(updatePropertyDetailsApi.request())
  try {
    const { propertyId, propertyDetails, continueTo, source, landlordProfile } = payload
    const response = yield call(callUpdatePropertyDetailsApi, {
      propertyId,
      propertyDetails,
      landlordProfile,
    })
    yield put(updatePropertyDetailsApi.success(response))
    const { propertyType } = propertyDetails
    yield call([tracker, 'trackEvent'], EVENT_REQUEST_SCREENING.completePropertyInformation, {
      property_type: propertyType,
    })
    if (continueTo) {
      yield put(
        push(
          buildPath(
            PROPERTY_OVERVIEW_PAGE.replace('/overview', continueTo),
            {
              propertyId,
            },
            { source },
          ),
        ),
      )
    } else {
      yield put(
        push({
          pathname: buildPath(PROPERTY_OVERVIEW_PAGE, { propertyId }),
        }),
      )
    }
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(updatePropertyDetailsApi.failure())
  }
}

export const createContactFromPropertyDetailApi = contact =>
  apiInstanceWithErrorHandler.post(buildPath(CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_API), {
    contact: { ...contact, source: 'property-detail' },
  })

export function* createContactFromPropertyDetailSaga({ payload }) {
  const { contact } = payload
  yield put(createContactFromPropertyDetailApiState.request())
  try {
    const response = yield call(createContactFromPropertyDetailApi, contact)
    yield put(createContactFromPropertyDetailApiState.success(response))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createContactFromPropertyDetailApiState.failure())
  }
}

export function* updatePropertyDetailsAndCreateContactSaga({ payload }) {
  const { propertyId, propertyDetails, continueTo, landlordProfile, source } = payload

  const basePayload = { propertyId, propertyDetails, continueTo, source }
  const updatePropertyDetailsPayload = landlordProfile
    ? { ...basePayload, landlordProfile }
    : basePayload

  try {
    yield call(updatePropertyDetailsSaga, { payload: updatePropertyDetailsPayload })
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(updatePropertyDetailsApi.failure())
    yield put(createContactFromPropertyDetailApiState.failure())
  }
}

export function* watchGetPropertyApiCall() {
  yield takeLatest(GET_PROPERTY_CALL, getPropertySaga)
}

export function* watchUpdatePropertyDetailsApiCall() {
  yield takeLatest(UPDATE_PROPERTY_DETAILS_CALL, updatePropertyDetailsSaga)
}

export function* watchCreateContactFromPropertyDetailApiCall() {
  yield takeLatest(
    CREATE_CONTACT_FROM_PROPERTY_DETAIL_PAGE_CALL,
    createContactFromPropertyDetailSaga,
  )
}
export function* watchUpdatePropertyDetailsAndCreateContactApiCall() {
  yield takeLatest(
    UPDATE_PROPERTY_DETAILS_AND_CREATE_CONTACT_CALL,
    updatePropertyDetailsAndCreateContactSaga,
  )
}

export function* rootSaga() {
  yield all([
    watchUpdatePropertyDetailsApiCall(),
    watchGetPropertyApiCall(),
    watchCreateContactFromPropertyDetailApiCall(),
    watchUpdatePropertyDetailsAndCreateContactApiCall(),
  ])
}

export default rootSaga
