import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { takeLatest, all, put, call, select } from 'redux-saga/effects'

import { CONTACTS } from 'constants/route'
import { addToast } from 'containers/toast/actions'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'
import { apiInstance } from 'utils/api-interceptor'

import { CREATE_CONTACT_API } from '../constants'
import { parseContact } from '../helpers'
import { getContactCount, getContactList } from '../list/actions'
import { selectLocation } from '../selectors'
import { createTagSaga } from '../tags/saga'

import {
  createContactFromModalApiState,
  createTagContactApiState,
  closeCreateContactModal,
} from './actions'
import {
  CREATE_CONTACT_FROM_MODAL_CALL,
  CREATE_CONTACT_SUCCESS_TOAST_CONFIG,
  CREATE_CONTACT_FAILURE_TOAST_CONFIG,
  CREATE_TAG_CONTACT_MODAL,
} from './constants'

const {
  EVENT_NAME: { ADDED_TAG_SUCCESS, CREATE_CONTACT_SUCCESSFULLY },
  EVENT_PROPERTY: {
    SUCCESS_TAG_BY,
    CATEGORY: { PEOPLE_CONCEPT },
  },
} = EVENT_PEOPLE_CONCEPT

export const createContactApi = contact =>
  apiInstance.post(buildPath(CREATE_CONTACT_API), parseContact(contact))

export function* createContactFromModalSaga({ payload }) {
  yield put(createContactFromModalApiState.request())
  try {
    const response = yield call(createContactApi, payload.contact)
    yield put(createContactFromModalApiState.success(response))
    if (payload.contact.tags?.length > 0) {
      yield call([tracker, 'trackEvent'], ADDED_TAG_SUCCESS, {
        tag_name: get(payload.contact, 'tags.0.name', ''),
        success_tag_by: payload.contact?.isNewTag
          ? SUCCESS_TAG_BY.CREATE_NEW_TAG
          : SUCCESS_TAG_BY.CHOOSE_EXISTING_TAG,
        contact_email: payload.contact?.email,
        category: PEOPLE_CONCEPT,
      })
    }
    yield call([tracker, 'trackEvent'], CREATE_CONTACT_SUCCESSFULLY)
    yield put(addToast(CREATE_CONTACT_SUCCESS_TOAST_CONFIG))
    yield put(closeCreateContactModal())

    const currentLocation = yield select(selectLocation)
    if (currentLocation.pathname === CONTACTS && isEmpty(currentLocation.search)) {
      yield put(getContactList({ group: 'all', page: 1 }))
      yield put(getContactCount({}))
    } else {
      yield put(push({ pathname: CONTACTS }))
    }
  } catch (err) {
    yield put(createContactFromModalApiState.failure())
    yield put(addToast(CREATE_CONTACT_FAILURE_TOAST_CONFIG))
  }
}

export function* createTagContactSaga({ payload }) {
  yield put(createTagContactApiState.request())
  try {
    const tag = yield call(createTagSaga, { payload })
    yield put(createTagContactApiState.success(tag))
  } catch (error) {
    yield put(createTagContactApiState.failure())
  }
}

export function* watchCreateContact() {
  yield takeLatest(CREATE_CONTACT_FROM_MODAL_CALL, createContactFromModalSaga)
}

export function* watchCreateTagContact() {
  yield takeLatest(CREATE_TAG_CONTACT_MODAL, createTagContactSaga)
}

export function* rootSaga() {
  yield all([watchCreateContact(), watchCreateTagContact()])
}

export default rootSaga
