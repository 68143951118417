import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SecurityDepositPromotionModal } from 'components/organisms/security-deposit-promotion-modal'
import { useRentalPayment } from 'containers/rent-payment/hooks/use-rental-payment'
import { selectProfile } from 'containers/user/selectors'
import tracker from 'tracker'
import { RENT_PAYMENT as RENT_PAYMENT_EVENT_TRACKING, START_FROM } from 'tracker/const'
import history from 'utils/history'
import * as PATH from 'constants/route'
import { buildPath } from '@rentspree/path'
import { MODAL_INFO } from './constants'
import { closeSecurityDepositModal } from '../actions'
import {
  selectAcceptDenyPropertyId,
  selectRenterEmail,
  selectSecurityDepositClickFrom as selectClickFrom,
  selectShowSecurityDepositModal,
} from '../selectors'
import { ACCEPT_DENY_TYPE } from '../constants'

export const SecurityDepositPromotion = () => {
  const isShowing = useSelector(selectShowSecurityDepositModal)
  const propertyId = useSelector(selectAcceptDenyPropertyId)
  const tenantEmail = useSelector(selectRenterEmail)
  const acceptDenyType = useSelector(selectClickFrom)
  const { latestRentalPayment } = useRentalPayment()
  const isFirstTimeRentalPaymentUser = !latestRentalPayment
  const { userType } = useSelector(selectProfile)

  const dispatch = useDispatch()

  const { EVENT_NAME, EVENT_PROPERTY } = RENT_PAYMENT_EVENT_TRACKING
  const CLICK_FROM_PROPERTY_MAP = {
    [ACCEPT_DENY_TYPE.ACCEPT]: EVENT_PROPERTY.CLICK_FROM.ACCEPT_EMAIL_SENT_MODAL,
    [ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION]:
      EVENT_PROPERTY.CLICK_FROM.ACCEPT_WITH_CONDITION_EMAIL_SENT_MODAL,
  }

  const trackClickSetupRentPaymentEvent = (acceptType = '') => {
    const clickFrom = CLICK_FROM_PROPERTY_MAP[acceptType]
    tracker.trackEvent(EVENT_NAME.CLICK_SETUP_RENT_PAYMENT, {
      is_first_time_user: isFirstTimeRentalPaymentUser,
      user_type: userType,
      ...(clickFrom && { click_from: clickFrom }),
    })
  }

  const closeModal = () => {
    dispatch(closeSecurityDepositModal())
  }

  const handleClickCloseModal = () => {
    closeModal()
  }

  const handleClickRentPayment = () => {
    trackClickSetupRentPaymentEvent(acceptDenyType)
    // NOTE: This event is from continueToRentPaymentSaga. Moved it here since we didn't dispatch action to the saga.
    tracker.trackEvent(RENT_PAYMENT_EVENT_TRACKING.EVENT_NAME.START_COLLECTING_RENT, {
      start_from: START_FROM.ACCEPT_EMAIL_SENT_MODAL,
    })
    history.push(
      buildPath(
        PATH.RENT_PAYMENT_SELECT_PAYMENT_RECEIVER,
        {},
        {
          source: CLICK_FROM_PROPERTY_MAP[acceptDenyType],
          property: propertyId,
          tenantEmail,
        },
      ),
    )
    closeModal()
  }

  const info = MODAL_INFO

  return (
    <SecurityDepositPromotionModal
      isOpen={isShowing}
      onClose={handleClickCloseModal}
      title={info.TITLE}
      description={info.DESCRIPTION}
      image={info.IMAGE}
      primaryButtonText={info.PRIMARY_BUTTON.TEXT}
      onClickPrimaryButton={handleClickRentPayment}
    />
  )
}

export default SecurityDepositPromotion
