/* eslint-disable camelcase */
import React, { useEffect } from 'react'

import { EVENT_PAY_REPORT } from 'tracker/const'
import { Landing } from 'components/landing'
import * as ERRORS from 'constants/error-messages'
import { FailedToFetch } from 'components/layout/failed-to-fetch'
import requiredPaymentSvg from 'legacy/images/credit-report/payment-required.svg'

import { STATE } from 'legacy/constants/state-consts'
import { LegalInstructionPanel } from 'components/shared/alert-panel'
import tracker from '../../tracker'
import { withLandlordPayConnect } from './connect'
import { LEGAL_INSTRUCTION_MESSAGES } from './constants'

const { MA } = LEGAL_INSTRUCTION_MESSAGES

export const LandlordPaymentPage = ({
  rentalDetail,
  creditPayment,
  actions,
  isFetchingFailed,
  subdomain: sub,
}) => {
  const { property } = rentalDetail
  const isMaState = property?.state === STATE.MA
  const isPaymentUrlReady = !!creditPayment?.paymentObject?.url

  const [isShownMaPopup, setShowMaPopup] = React.useState(isMaState)

  const trackViewPaymentModal = () => {
    const { _id, email } = rentalDetail
    const properties = {
      applicant: email,
      rental_id: _id,
    }
    tracker.trackEvent(EVENT_PAY_REPORT.viewPaymentModal, properties)
  }

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (rentalDetail._id) {
      actions.clearPaymentError()
      actions.getPayment({ provider: 'stripe', subdomain: sub })
      trackViewPaymentModal()
    }
    return () => actions.clearCreditPayment()
  }, [])

  const handlePayAttempt = () => {
    window.location.assign(creditPayment.paymentObject.url)
  }

  const onCloseMaPopup = () => setShowMaPopup(false)

  if (isFetchingFailed) {
    return (
      <FailedToFetch
        withBreadcrumb
        withReportTabs
        noMargin
        title={ERRORS.PAYMENT.FETCH_FAILED.TITLE}
        text={ERRORS.PAYMENT.FETCH_FAILED.MESSAGE}
      />
    )
  }

  const LANDING_BUTTON_TEST_ID = `${isPaymentUrlReady ? '' : 'loading-'}landing-button`

  return (
    <>
      {isShownMaPopup && (
        <LegalInstructionPanel
          id="ma-legal-instruction-panel"
          status="info"
          title={MA.title}
          instructionMsg={MA.instructionMessage}
          onClose={onCloseMaPopup}
        />
      )}
      <Landing
        title="Payment Required"
        description="Please click the button below to pay the application fee and pull the reports from TransUnion."
        buttonName="Pay & View Report(s)"
        imgSrc={requiredPaymentSvg}
        buttonID={LANDING_BUTTON_TEST_ID}
        isButtonLoading={!isPaymentUrlReady}
        onClick={handlePayAttempt}
        buttonWidth="205px"
      />
    </>
  )
}

export default withLandlordPayConnect(LandlordPaymentPage)
