import React from "react"
import { compose } from "redux"
import { Grid } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { query } from "@rentspree/path"
import { withSaga } from "containers/reports/other-doc/connect"
import DocumentConnect from "./document-connect"
import { LoadingPDF } from "../../components/download-pdf"
import { docType } from "../../constants/other-docs-consts"

export class DocumentDownload extends React.Component {
  componentDidMount() {
    const { actions, location } = this.props
    const { rentalAppId } = this.props.match.params
    const { document, renter } = query.parse(location.search)
    setTimeout(() => {
      if (document.type === docType.full) {
        actions.downloadFullDocument(rentalAppId, renter)
      } else {
        actions.getDownloadToken({
          rentalAppId,
          document,
          renter,
          clearDownloadingDocs: () => {},
        })
      }
    }, 500)
  }

  render() {
    return (
      <Grid>
        <div>
          <LoadingPDF />
        </div>
      </Grid>
    )
  }
}

export default compose(
  withRouter,
  withSaga,
  DocumentConnect,
)(DocumentDownload)
