import get from 'lodash/get'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

export const selectIncomeVerificationState = state =>
  get(state, 'income-verification', initialState)

export const selectIncomeVerification = createSelector(
  selectIncomeVerificationState,
  state => state.incomeVerification,
)

export const selectIncomeVerificationReportStatus = createSelector(
  selectIncomeVerificationState,
  state => state.reportStatus,
)

export const selectIncomeVerificationReportData = createSelector(
  selectIncomeVerificationState,
  state => state.reportData,
)

export const selectIncomeVerificationStatus = createSelector(
  selectIncomeVerification,
  incomeVerification => incomeVerification?.status,
)

export const selectIncomeVerificationCreatedAt = createSelector(
  selectIncomeVerification,
  incomeVerification => incomeVerification?.createdAt,
)

export const selectGetReportFailStatusCode = createSelector(
  selectIncomeVerificationState,
  state => state.getReportFailStatusCode,
)

export const selectGetIncomeVerificationFailStatusCode = createSelector(
  selectIncomeVerificationState,
  state => state.getIncomeVerificationFailStatusCode,
)

export const selectIsViewedByAdmin = createSelector(
  selectIncomeVerificationState,
  state => state.isViewedByAdmin,
)
