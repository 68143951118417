import { propertyTypeValidation } from "constants/validations"
import { object } from "yup"
export const schema = props => {
  const { propertyType } = props
  return {
    mapper: {
      propertyType: propertyType || "",
    },
    validation: object().shape({ propertyType: propertyTypeValidation() }),
  }
}
