import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const LandlordForm = props => {
  const { errors, touched, handleChange, setFieldTouched, values } = props

  return (
    <SectionContainer>
      <Typography variant="title">Landlord</Typography>
      <InputContainer>
        <TextInput
          name="landlordInfo.firstName"
          required
          label="First name"
          onChange={handleChange}
          onBlur={() => {
            setFieldTouched('landlordInfo.firstName')
          }}
          error={
            get(touched, 'landlordInfo.firstName', false) && get(errors, 'landlordInfo.firstName')
          }
          helperText={
            get(touched, 'landlordInfo.firstName', false) && get(errors, 'landlordInfo.firstName')
          }
          value={values.landlordInfo?.firstName}
          InputLabelProps={{
            shrink: !!values.landlordInfo?.firstName,
          }}
        />
        <TextInput
          name="landlordInfo.lastName"
          required
          label="Last name"
          onChange={handleChange}
          onBlur={() => {
            setFieldTouched('landlordInfo.lastName')
          }}
          error={
            get(touched, 'landlordInfo.lastName', false) && get(errors, 'landlordInfo.lastName')
          }
          helperText={
            get(touched, 'landlordInfo.lastName', false) && get(errors, 'landlordInfo.lastName')
          }
          value={values.landlordInfo?.lastName}
          InputLabelProps={{
            shrink: !!values.landlordInfo?.lastName,
          }}
        />
        <TextInput
          name="landlordInfo.email"
          required
          label="Email"
          onChange={handleChange}
          onBlur={() => {
            setFieldTouched('landlordInfo.email')
          }}
          error={get(touched, 'landlordInfo.email', false) && get(errors, 'landlordInfo.email')}
          helperText={
            get(touched, 'landlordInfo.email', false) && get(errors, 'landlordInfo.email')
          }
          value={values.landlordInfo?.email}
          InputLabelProps={{
            shrink: !!values.landlordInfo?.email,
          }}
        />
      </InputContainer>
    </SectionContainer>
  )
}
