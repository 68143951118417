import styled from "styled-components"

export const Ul = styled.ul`
  list-style: disc;
  padding-left: ${props => props.paddingLeft || "20px"};
  margin: 0;
`

export const Li = styled.li`
  font-size: 16px;
  line-height: 1.5;
`
