import generateApiCall from "utils/generate-api-call"

import {
  GET_LINK_ACCOUNT_DATA_CALL,
  GET_LINK_ACCOUNT_DATA_REQUESTED,
  GET_LINK_ACCOUNT_DATA_SUCCESS,
  GET_LINK_ACCOUNT_DATA_FAILED,
  CREATE_LISTING_SCREENING_REQUEST_CALL,
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
  SUBMIT_SCREENING_REQUEST,
  SET_SHOW_CRIMINAL_DISCLAIMER_MODAL,
  GENERATE_PERMALINK_REQUESTED,
  GENERATE_PERMALINK_SUCCESS,
  GENERATE_PERMALINK_FAILED,
  CREATE_LISTING_SCREENING_REQUEST_CALL_WITH_REDIRECT,
} from "./constants"

export const getLinkAccountDataCall = payload => ({
  type: GET_LINK_ACCOUNT_DATA_CALL,
  payload,
})

export const createListingScreeningRequestCall = payload => ({
  type: CREATE_LISTING_SCREENING_REQUEST_CALL,
  payload,
})

export const submitScreeningRequest = payload => ({
  type: SUBMIT_SCREENING_REQUEST,
  payload,
})

export const setShowCriminalDisclaimerModal = payload => ({
  type: SET_SHOW_CRIMINAL_DISCLAIMER_MODAL,
  payload,
})

export const getLinkAccountDataActions = generateApiCall([
  GET_LINK_ACCOUNT_DATA_REQUESTED,
  GET_LINK_ACCOUNT_DATA_SUCCESS,
  GET_LINK_ACCOUNT_DATA_FAILED,
])

export const createListingScreeningRequestActions = generateApiCall([
  CREATE_LISTING_SCREENING_REQUEST_REQUESTED,
  CREATE_LISTING_SCREENING_REQUEST_SUCCESS,
  CREATE_LISTING_SCREENING_REQUEST_FAILED,
])

export const generatePermalinkRequestActions = generateApiCall([
  GENERATE_PERMALINK_REQUESTED,
  GENERATE_PERMALINK_SUCCESS,
  GENERATE_PERMALINK_FAILED,
])
export const createListingScreeningRequestCallWithRedirect = payload => ({
  type: CREATE_LISTING_SCREENING_REQUEST_CALL_WITH_REDIRECT,
  payload,
})
