export const GET_DOC_PDF_REQUEST = 'GET_DOC_PDF_REQUEST'
export const GET_DOC_PDF_SUCCESS = 'GET_DOC_PDF_SUCCESS'
export const GET_DOC_PDF_FAILED = 'GET_DOC_PDF_FAILED'
export const SHOW_DOC_PDF_SUCCESS = 'SHOW_DOC_PDF_SUCCESS'
export const CLEAR_SHOW_DOC_PDF = 'CLEAR_SHOW_DOC_PDF'

export const REQUEST_OTHER_DOC_CALL = 'REQUEST_OTHER_DOC_CALL'
export const REQUEST_OTHER_DOC_REQUEST = 'REQUEST_OTHER_DOC_REQUEST'
export const REQUEST_OTHER_DOC_SUCCESS = 'REQUEST_OTHER_DOC_SUCCESS'
export const REQUEST_OTHER_DOC_FAILURE = 'REQUEST_OTHER_DOC_FAILURE'

export const FETCH_DOWNLOAD_TOKEN_CALL = 'FETCH_DOWNLOAD_TOKEN_CALL'
export const FETCH_DOWNLOAD_TOKEN_REQUEST = 'FETCH_DOWNLOAD_TOKEN_REQUEST'
export const FETCH_DOWNLOAD_TOKEN_SUCCESS = 'FETCH_DOWNLOAD_TOKEN_SUCCESS'
export const FETCH_DOWNLOAD_TOKEN_FAILURE = 'FETCH_DOWNLOAD_TOKEN_FAILURE'

// OTHER DOCUMENTS
export const GET_OTHER_DOCS_CALL = 'GET_DOCUMENT_SETS_CALL'
export const GET_OTHER_DOCS_REQUEST = 'GET_DOCUMENT_SETS_REQUEST'
export const GET_OTHER_DOCS_SUCCESS = 'GET_DOCUMENT_SETS_SUCCESS'
export const GET_OTHER_DOCS_FAILED = 'GET_DOCUMENT_SETS_FAILED'

export const CANCEL_DOCUMENT_REQUEST_CALL = 'CANCEL_DOCUMENT_REQUEST_CALL'
export const CANCEL_DOCUMENT_REQUEST_REQUEST = 'CANCEL_DOCUMENT_REQUEST_REQUEST'
export const CANCEL_DOCUMENT_REQUEST_SUCCESS = 'CANCEL_DOCUMENT_REQUEST_SUCCESS'
export const CANCEL_DOCUMENT_REQUEST_FAILED = 'CANCEL_DOCUMENT_REQUEST_FAILED'

export const DOWNLOAD_DOCUMENT_FILE_CALL = 'DOWNLOAD_DOCUMENT_FILE_CALL'
export const DOWNLOAD_DOCUMENT_FILE_REQUEST = 'DOWNLOAD_DOCUMENT_FILE_REQUEST'
export const DOWNLOAD_DOCUMENT_FILE_SUCCESS = 'DOWNLOAD_DOCUMENT_FILE_SUCCESS'
export const DOWNLOAD_DOCUMENT_FILE_FAILED = 'DOWNLOAD_DOCUMENT_FILE_FAILED'

// TOASTS
export const REQUEST_DOCS_SUCCESS_TOAST_CONFIG = {
  titleMessage: `Request sent.`,
  status: 'success',
  timeOut: 5000,
  width: '350px',
}
export const REQUEST_DOCS_FAILED_TOAST_CONFIG = {
  titleMessage: `Error occurred, please refresh this page.`,
  status: 'error',
  timeOut: 5000,
  width: '350px',
}
export const CANCEL_DOCUMENT_REQUEST_SUCCESS_TOAST_CONFIG = {
  titleMessage: `Request canceled.`,
  status: 'success',
  timeOut: 5000,
  width: '350px',
}
export const CANCEL_DOCUMENT_REQUEST_FAILED_TOAST_CONFIG = {
  titleMessage: `Error occurred, please refresh this page.`,
  status: 'error',
  timeOut: 5000,
  width: '350px',
}

// DOCUMENT STATUS
export const DOCUMENT_STATUS = {
  REQUESTED: 'requested',
  SUBMITTED: 'submitted',
}

export const DOCUMENT_CANCEL_SOURCE = {
  AGENT: "agent",
  INCOME_VERIFICATION: "income_verification",
}