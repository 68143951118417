/* eslint-disable camelcase */
import { takeLatest, put, call, all, select } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { push } from "connected-react-router"
import { apiInstance } from "utils/api-interceptor"
import { PAYMENT_API_V2, SINGLE_PROPERTY } from "constants/route"
import { clearPaymentError } from "legacy/actions/credit-report.action"
import tracker from "tracker"
import { TrackerInstance as Tracker } from "legacy/helpers/tracker"
import { EVENT_PAY_REPORT } from "tracker/const"
import { selectRentalDetail } from "containers/reports/selectors"
import { openSweetAlert } from "utils/sweet-alert-actions"
import { ALERT_PRESET } from "redux-middleware/sweet-alert"
import { PAYMENT } from "constants/error-messages"
import { locationReload } from "legacy/utils/window"
import { getRentalSubmissionCall } from "containers/reports/actions"
import { selectProfile } from "containers/user/selectors"

import {
  GET_CREDIT_PAYMENT_CALLED,
  SUBMIT_CREDIT_PAYMENT_CALLED,
} from "./constants"
import {
  getPayment as getPaymentCall,
  getPaymentRequest,
  getPaymentSuccess,
  getPaymentFailed,
  submitPaymentRequest,
  submitPaymentSuccess,
  submitPaymentFailed,
} from "./actions"
import { selectRentalId, selectPropertyId } from "./selectors"
import getErrorStripeMessage from "./helper/error-code-mapping"

export const getPaymentAPI = (rentalId, { provider, subdomain }) =>
  apiInstance.get(
    buildPath(PAYMENT_API_V2, { rentalId }, { provider, subdomain }),
  )

export const submitPaymentAPI = (payload, rentalId) =>
  apiInstance.post(buildPath(PAYMENT_API_V2, { rentalId }), payload)

export function* getPayment({ payload }) {
  yield put(getPaymentRequest())
  const rentalId = yield select(selectRentalId)
  try {
    const response = yield call(getPaymentAPI, rentalId, payload)
    yield put(getPaymentSuccess(response))
  } catch (err) {
    yield put(getPaymentFailed(err))
    if (err?.status === 409) {
      const propertyId = yield select(selectPropertyId)
      if (propertyId) {
        yield put(
          push({
            pathname: buildPath(SINGLE_PROPERTY, {
              propertyId,
            }),
          }),
        )
      }
    }
  }
}

export function* submitPayment({ payload }) {
  yield put(clearPaymentError())
  yield put(submitPaymentRequest())
  const rentalId = yield select(selectRentalId)
  const propertyId = yield select(selectPropertyId)
  const { email, _id, credit_report } = yield select(selectRentalDetail)
  const { email: agentEmail } = yield select(selectProfile)
  const eventProperties = { applicant: email, rental_id: _id }
  const { stripe, cardElement, payment } = payload
  const metadata = {
    rentalId,
    email: agentEmail,
    paymentId: payment.paymentId,
    orderId: credit_report?._id,
  }

  // Get payment method from stripe
  let paymentMethod
  try {
    const stripeResponse = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement,
      metadata,
    })
    if (stripeResponse.error) {
      throw stripeResponse.error
    }
    paymentMethod = stripeResponse?.paymentMethod
  } catch (err) {
    console.error("Generate Payment method failed with error ======> ", err)
    yield put(
      submitPaymentFailed({
        message: getErrorStripeMessage({ ...err, errors: err }),
      }),
    )
    yield call(
      [tracker, "trackEvent"],
      EVENT_PAY_REPORT.payReportFail,
      eventProperties,
    )
    return
  }

  // Submit payment data to RentSpree server
  try {
    const response = yield call(
      submitPaymentAPI,
      {
        ...payment,
        paymentObject: {
          ...payment.paymentObject,
          nonce: paymentMethod.id,
        },
      },
      rentalId,
    )
    yield put(submitPaymentSuccess(response))
    yield call(
      [tracker, "trackEvent"],
      EVENT_PAY_REPORT.payReportSuccess,
      eventProperties,
    )
    yield call([Tracker, "trackEvent"], "paymentSuccess")
    yield put(getRentalSubmissionCall({ rentalId, propertyId }))
  } catch (err) {
    console.error("Submit payment error with =======> ", err)
    yield put(
      submitPaymentFailed({ message: getErrorStripeMessage(err?.data) }),
    )
    yield call(
      [tracker, "trackEvent"],
      EVENT_PAY_REPORT.payReportFail,
      eventProperties,
    )
    if (err.status === 409) {
      yield put(getPaymentCall(payment?.method || "stripe"))
    } else {
      yield put(
        openSweetAlert({
          preset: ALERT_PRESET.ERROR,
          option: {
            title: PAYMENT.LANDLORD_PAY_GENERAL_FAILED.TITLE,
            text: PAYMENT.LANDLORD_PAY_GENERAL_FAILED.MESSAGE,
          },
          promise: locationReload,
        }),
      )
    }
  }
}

export function* watchGetCreditReportPayment() {
  yield takeLatest(GET_CREDIT_PAYMENT_CALLED, getPayment)
}

export function* watchSubmitCreditReportPayment() {
  yield takeLatest(SUBMIT_CREDIT_PAYMENT_CALLED, submitPayment)
}

export default function* rootSaga() {
  yield all([watchGetCreditReportPayment(), watchSubmitCreditReportPayment()])
}
