import { createSelector } from 'reselect'
import get from 'lodash/get'

import { CAR_LEASE_ALLOWED_SUBDOMAIN } from 'env'
import { selectProfile, selectUserIntegration } from '../user/selectors'

const getSubdomain = state => get(state, 'subdomain', {})

export const selectSubdomain = createSelector(getSubdomain, state =>
  get(state, 'subdomain.sub', null),
)

// same logic as 'checkCARTemplateAvailable' from digital-document-service
export const selectIsAllowCarLease = createSelector(
  [selectSubdomain, selectProfile, selectUserIntegration],
  (subdomain, profile, userIntegration) => {
    const allowedSubdomainList = CAR_LEASE_ALLOWED_SUBDOMAIN.split(',')
    return (
      profile?.address?.state === 'CA' ||
      userIntegration?.includes('car') ||
      allowedSubdomainList.includes(subdomain)
    )
  },
)
