import { combineReducers } from 'redux'
import get from 'lodash/get'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import { PARTICIPANT_TYPE, SCREENING_OPTION_TYPES } from 'containers/reports/constants'
import { ACCEPT_DENY_SUCCESS } from 'containers/accept-deny/constants'
import {
  GET_SUBMISSION_PARTICIPANT_REQUEST,
  GET_SUBMISSION_PARTICIPANT_SUCCESS,
  GET_SUBMISSION_PARTICIPANT_FAILURE,
} from 'containers/tenant-screening/detail/constants'
import { isSignatureRequired } from 'helpers/is-signature-required'
import * as Types from '../constants/action-types'
import { TYPE_CO_APPLICANT } from '../constants/rental-consts'
import { STATUS as CREDIT_REPORT_STATUS } from '../constants/credit-report-consts'
import { REPORT_STEP } from '../constants/report-consts'

const { OWNER } = PARTICIPANT_TYPE

const { APPLICATION, CREDIT_REPORT } = SCREENING_OPTION_TYPES

const {
  COMPLETED,
  SUBMITTED,
  REQUESTED,
  ATTACH_REAL_REQUIRE,
  ATTACH_REAL_READY,
  PAYMENT,
  REFUND_REQUIRED,
  READY,
  ATTACH_REAL_ERROR,
  PROCESSING_PAYMENT,
} = CREDIT_REPORT_STATUS

export const applicationDetail = (state = {}, action) => {
  switch (action.type) {
    case Types.GET_APPLICATION_SUCCESS:
      return action.application
    case Types.GET_APPLICATION_LIST_REQUEST:
    case Types.CLEAR_PROPERTY:
      return {}
    default:
      return state
  }
}

export const aboutMe = (state = {}, action) => {
  const me = {}
  switch (action.type) {
    case Types.GET_APPLICATION_SUCCESS:
      if (action.application.version === '2.0') {
        return get(action, 'application.aboutMe', {})
      }
      me.dateOfBirth = get(action, 'application.dateOfBirth')
      me.phoneNumber = get(action, 'application.phoneNumber')
      me.driverLicenseNo = get(action, 'application.driverLicense.licenseNum')
      return me
    case Types.GET_APPLICATION_LIST_REQUEST:
    case Types.CLEAR_PROPERTY:
      return {}
    default:
      return state
  }
}

const initRental = {
  screeningOption: {},
  renter: {},
}

export const rentalDetail = (state = initRental, { type, data, payload }) => {
  switch (type) {
    case Types.GET_RENTAL_BY_ID_SUCCESS:
      return data && !isEmpty(data.coApplicants)
        ? {
            ...data,
            occupants: filter(data.coApplicants, v => v.type === TYPE_CO_APPLICANT.OCCUPANT),
            coSigners: filter(data.coApplicants, v => v.type === TYPE_CO_APPLICANT.CO_SIGNER),
          }
        : data
    case Types.CLEAR_RENTAL_DETAIL:
      return initRental
    case Types.UPDATE_CURRENT_REPORT_STATUS:
      return {
        ...state,
        credit_report: { ...state.credit_report, status: payload },
      }
    case ACCEPT_DENY_SUCCESS:
      return {
        ...state,
        screeningResult: get(payload, 'screeningResult', {}),
      }
    case GET_SUBMISSION_PARTICIPANT_SUCCESS:
      return {
        ...state,
        ...payload.rentalSubmission,
      }
    default:
      return state
  }
}

export const isFetchingAppDetail = (state = false, action) => {
  switch (action.type) {
    case Types.SET_REPORT_FETCHING:
    case Types.GET_APPLICATION_REQUEST:
      return true
    case Types.CLEAR_REPORT_FETCHING:
    case Types.GET_APPLICATION_SUCCESS:
    case Types.GET_APPLICATION_FAILED:
      return false
    default:
      return state
  }
}

export const isFetchingRentalDetail = (state = false, action) => {
  switch (action.type) {
    case Types.SET_REPORT_FETCHING:
    case Types.GET_RENTAL_BY_ID_REQUEST:
    case GET_SUBMISSION_PARTICIPANT_REQUEST:
      return true
    case Types.CLEAR_REPORT_FETCHING:
    case Types.GET_RENTAL_BY_ID_SUCCESS:
    case Types.GET_RENTAL_BY_ID_FAILED:
    case GET_SUBMISSION_PARTICIPANT_SUCCESS:
    case GET_SUBMISSION_PARTICIPANT_FAILURE:
      return false
    default:
      return state
  }
}

export const isFetchingRentalByIdStatus = (state = null, action) => {
  switch (action.type) {
    case Types.GET_RENTAL_BY_ID_REQUEST:
      return 'REQUEST'
    case Types.GET_RENTAL_BY_ID_SUCCESS:
      return 'SUCCESS'
    case Types.GET_RENTAL_BY_ID_FAILED:
      return 'FAILED'
    default:
      return state
  }
}

export const isFetchingFormAvailable = (state = false, action) => {
  switch (action.type) {
    case Types.SET_REPORT_FETCHING:
    case Types.GET_FORM_AVAILABLE_REQUEST:
      return true
    case Types.CLEAR_REPORT_FETCHING:
    case Types.GET_FORM_AVAILABLE_SUCCESS:
    case Types.GET_FORM_AVAILABLE_FAILED:
      return false
    default:
      return state
  }
}

const errorRental = (state = null, action) => {
  switch (action.type) {
    case Types.GET_RENTAL_BY_ID_FAILED:
      return {
        ...state,
        message: action.message,
        meta: action.meta,
        isRentalByIdFail: true,
      }
    case Types.CLEAR_PROPERTY:
    case Types.CLEAR_RENTAL_DETAIL:
      return null
    default:
      return state
  }
}

const errorApplication = (state = null, action) => {
  switch (action.type) {
    case Types.GET_APPLICATION_FAILED:
      return { message: 'ERROR!!!! FIX IT!!!' }
    case Types.GET_APPLICATION_REQUEST:
    case Types.GET_APPLICATION_SUCCESS:
    case Types.CLEAR_PROPERTY:
      return null
    default:
      return state
  }
}

const formAvailable = (state = null, action) => {
  switch (action.type) {
    case Types.GET_LRA_FORM_SUCCESS:
      return { type: action.type, ...action.payload }
    case Types.GET_FORM_AVAILABLE_SUCCESS:
      return action
    case Types.GET_FORM_AVAILABLE_FAILED:
      return null
    default:
      return state
  }
}

const mobileActiveTab = (state = null, action) => {
  switch (action.type) {
    case Types.CALL_UPDATE_MOBILE_ACTIVE_TAB:
      return action.payload
    default:
      return state
  }
}

export const isLandlord = state => {
  const { application, user } = state
  return (
    (get(user, 'profile.userType') === 'landlord' &&
      get(application, 'rentalDetail.landlord_id') === get(user, 'profile._id')) ||
    get(user, 'profile.userType') === 'admin' ||
    get(user, 'profile.userType') === 'super_admin'
  )
}

export const isRenterValidator = state => {
  const { application, user } = state
  const userId = get(user, 'profile._id')
  const renterId = get(application, 'rentalDetail.renter_id')
  return userId && renterId && userId.toString() === renterId.toString()
}

export const currentReportStep = state => {
  const { application, user, submissionParticipant, checkWhitelisting } = state
  const rentalSubmission = get(application, 'rentalDetail', {})
  const creditReport = get(rentalSubmission, 'credit_report', {})
  const screeningOption = get(rentalSubmission, 'screeningOption', {})
  const applicationData = rentalSubmission?.application || {}
  const OPENED_REPORT_STATUS = [CREDIT_REPORT_STATUS.OPENED, CREDIT_REPORT_STATUS.FAILED]
  const creditReportStatus = get(creditReport, 'status', '')
  const isRequireVerification = get(user, 'profile.requireVerification', false)
  const userProfile = get(user, 'profile')
  const isVerificationPermitted = userProfile?.isVerificationPermitted || false
  const isCreditReportExpired =
    get(creditReport, 'isExpired', false) || get(creditReport, 'isIDMAExpired', false)
  const isRequest = option => get(screeningOption, option, false)
  const isOwnerRentalSubmission =
    get(submissionParticipant, 'participantData.type', 'none') === OWNER
  const isEmailVerified = get(user, 'profile.isEmailVerified', false)
  const isWhitelisting = get(checkWhitelisting, 'data.isWhitelisting', false)
  const isCompleteWhitelisting = isEmailVerified && isWhitelisting
  const requirePayment = get(creditReport, 'requirePayment', true)
  const isSkipPayment = !requirePayment && creditReportStatus === CREDIT_REPORT_STATUS.COMPLETED
  const isAgentOrLandlord = isLandlord(state)
  const isRenter = isRenterValidator(state)

  if (
    isOwnerRentalSubmission &&
    isRequireVerification &&
    (!isCreditReportExpired || isRequest(APPLICATION)) &&
    !isCompleteWhitelisting
  ) {
    if (isWhitelisting && !isEmailVerified) {
      return REPORT_STEP.EMAIL_VERIFICATION
    }
    return isVerificationPermitted ? REPORT_STEP.VERIFICATION : REPORT_STEP.VERIFICATION_FAIL
  }
  if (isEmpty(screeningOption)) {
    return null
  }
  if (isCreditReportExpired) {
    return REPORT_STEP.EXPIRED
  }
  if (creditReportStatus === REFUND_REQUIRED) {
    return REPORT_STEP.REFUND
  }
  if (isSignatureRequired(userProfile, rentalSubmission, applicationData)) {
    return REPORT_STEP.SIGN
  }
  if (isSkipPayment && isAgentOrLandlord && !isRenter) {
    return REPORT_STEP.SKIP_PAYMENT
  }
  if (creditReportStatus === PAYMENT || creditReportStatus === READY) {
    return REPORT_STEP.PAYMENT
  }
  if (creditReportStatus === PROCESSING_PAYMENT) {
    return REPORT_STEP.PROCESSING_PAYMENT
  }
  if (!isRequest(CREDIT_REPORT)) {
    return REPORT_STEP.NONE
  }

  if (
    includes(
      [COMPLETED, SUBMITTED, REQUESTED, ATTACH_REAL_REQUIRE, ATTACH_REAL_READY],
      creditReportStatus,
    )
  ) {
    return REPORT_STEP.READY
  }
  if (creditReportStatus === ATTACH_REAL_ERROR) {
    return REPORT_STEP.OPEN_ERROR
  }
  if (includes(OPENED_REPORT_STATUS, creditReportStatus)) {
    return REPORT_STEP.OPENED
  }
  return null
}

export default combineReducers({
  applicationDetail,
  rentalDetail,
  isFetchingAppDetail,
  isFetchingRentalDetail,
  errorApplication,
  errorRental,
  aboutMe,
  formAvailable,
  isFetchingFormAvailable,
  mobileActiveTab,
  isFetchingRentalByIdStatus,
})
