/* eslint-disable prefer-promise-reject-errors */
import moment from 'moment'
import { string } from 'yup'

import {
  validateLength,
  validateEmail,
  validateContactNumber,
  requiredYup,
} from 'legacy/constants/validations'

import {
  STATES_FULL_TO_CODE,
  STATES_CODE_TO_FULL,
  FIRST_NAME,
  MIDDLE_NAME,
  LAST_NAME,
  EMAIL,
  MOBILE_NUMBER,
  PHONE_NUMBER,
  OFFICE_NUMBER,
  BIRTH_DATE_TEXT,
  BIRTH_DATE_NUMBER,
  MOVE_IN_DATE_TEXT,
  MOVE_IN_DATE_NUMBER,
  STREET,
  CITY,
  STATE_FULL,
  STATE_CODE,
  ZIPCODE,
} from './constants'

export const validateDateFormat = format => ({
  validate: value => {
    if (moment(value, format, true).isValid()) {
      return Promise.resolve(value)
    }
    return Promise.reject({ reason: 'error' }) // TODO: use ValidationError later
  },
  isValidSync: value => moment(value, format, true).isValid(),
})

export const validateHasKey = object => ({
  validate: value => {
    if (value in object) {
      return Promise.resolve(value)
    }
    return Promise.reject({ reason: 'error' })
  },
  isValidSync: value => value in object,
})

export const validateZipCode = (required = true) => {
  const regex = /^[0-9]{5}$/
  const validate = string().matches(regex)
  return requiredYup(required, validate)
}

// TODO write test

export const VALIDATORS = {
  [FIRST_NAME]: validateLength(false, 1, 50),
  [MIDDLE_NAME]: validateLength(false, 1, 50),
  [LAST_NAME]: validateLength(false, 1, 50),
  [EMAIL]: validateEmail(true),
  [MOBILE_NUMBER]: validateContactNumber(false, 1, 20),
  [PHONE_NUMBER]: validateContactNumber(false, 1, 20),
  [OFFICE_NUMBER]: validateContactNumber(false, 1, 20),
  [BIRTH_DATE_TEXT]: validateDateFormat('MMM DD YYYY'),
  [BIRTH_DATE_NUMBER]: validateDateFormat('MM/DD/YYYY'),
  [MOVE_IN_DATE_TEXT]: validateDateFormat('MMM DD YYYY'),
  [MOVE_IN_DATE_NUMBER]: validateDateFormat('MM/DD/YYYY'),
  [STREET]: validateLength(false, 1, 100),
  [CITY]: validateLength(false, 3, 50),
  [STATE_FULL]: validateHasKey(STATES_FULL_TO_CODE),
  [STATE_CODE]: validateHasKey(STATES_CODE_TO_FULL),
  [ZIPCODE]: validateZipCode(false),
}
