/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import { docStatus } from 'legacy/constants/other-docs-consts'
import React from 'react'
import { Text } from 'components/typography'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'
import { DocumentStatus } from '../../doc-status'
import KebabMenu from '../../molecules/kebab-menu'
import NoteBoxInput from '../../molecules/note-box-input'
import { DocumentFileItem } from '../../molecules/document-file-item'

const CheckboxTd = styled.td`
  padding-left: 5px;
  width: 39px;
`

const NoBorderTr = styled.tr`
  border-bottom: none !important;
`

const StyledTd = styled.td`
  padding-top: 0px !important;
`

const KebabMenuTd = styled.td`
  position: relative !important;
  > div {
    right: 10px;
  }
`

const DocumentFileItemTd = styled.td`
  padding: 0 10px 0 20px !important;
  border-bottom: ${props =>
    props.isLastItem ? 'none !important' : `solid ${COLOR.borderGrey} 1px !important`};
`

const DocumentFileItemTr = styled.tr`
  ${props => !props.isLastItem && `border-bottom: none !important;`}
`

const BoldText = ({ children }) => <Text weight={600}>{children}</Text>

const DocumentTableRowDesktop = ({
  doc,
  selectedDocs,
  requestDisabled,
  onClickRow,
  onClickCloseNote,
  onNoteInputChange,
  getDocumentKebabMenuItems,
  fileMenuItems,
}) => {
  const selectedDocument = selectedDocs.find(selectedDoc => selectedDoc.type === doc.type)
  const isKebabMenuVisible = doc.status !== docStatus.created && doc.status !== docStatus.canceled

  return (
    <>
      <NoBorderTr disabled={requestDisabled}>
        <CheckboxTd
          onClick={e => {
            onClickRow(doc, e)
          }}
          className="first checkbox-cell"
        >
          <Checkbox
            simple
            bgSimpleTransparent
            isSelected={!!selectedDocument}
            disabled={requestDisabled}
          />
        </CheckboxTd>
        <td className="col-xs-10 col-sm-10 col-md-5 first document-type-cell-desktop">
          <div>
            <BoldText>{doc.name}</BoldText>
            {doc.files?.length > 0 && <BoldText>{` (${doc.files?.length})`}</BoldText>}
          </div>
        </td>
        <td className="text-left hidden-xs hidden-sm first">
          <DocumentStatus doc={doc} />
        </td>
        <KebabMenuTd className="first">
          {isKebabMenuVisible && <KebabMenu menuItems={getDocumentKebabMenuItems(doc)} />}
        </KebabMenuTd>
      </NoBorderTr>
      <tr>
        <td onClick={e => onClickRow(doc, e)}></td>
        <StyledTd colSpan={3}>
          <NoteBoxInput
            id="noteBoxInput"
            type="text"
            name="note"
            isShowCloseButton
            isSelectedCheckBox={!!selectedDocument}
            value={selectedDocument ? selectedDocument.note : doc.note}
            docType={doc.type}
            placeholder={doc.notePlaceholder}
            onInputChange={onNoteInputChange}
            onClose={onClickCloseNote}
            onClickButton={e => {
              if (!selectedDocument) {
                onClickRow(doc, e)
              }
            }}
          />
        </StyledTd>
      </tr>
      {doc?.files?.map((file, idx) => {
        const isLastItem = idx === doc.files.length - 1
        return (
          <DocumentFileItemTr isLastItem={isLastItem}>
            <td onClick={e => onClickRow(doc, e)}></td>
            <DocumentFileItemTd colSpan={3} isLastItem={isLastItem}>
              <DocumentFileItem
                fileOriginalName={file.originalName}
                fileMenu={fileMenuItems.map(menuItem => ({
                  label: menuItem.label,
                  onClick: () => menuItem.onClick(file, doc),
                }))}
              />
            </DocumentFileItemTd>
          </DocumentFileItemTr>
        )
      })}
    </>
  )
}

export default DocumentTableRowDesktop
