import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withReducer } from 'containers/property-list/connect'

import { getAccessToken } from 'legacy/reducers/persist-state.reducer'
import { getPropertyPermalink } from 'legacy/actions/property.action'
import {
  clearRequestOptions,
  clearScreeningWithPropertyProOptions,
} from 'legacy/actions/request.action'
import {
  clearIsSendEmail,
  sendEmails,
  sendTextMessage,
  sendMoreEmails,
  sendMoreTextMessage,
} from 'legacy/actions/rental-submission.action'
import { updateFlyerApi } from 'legacy/actions/user.action'
import { createContact, createContacts } from 'containers/contacts/actions'
import { clearScreeningWithoutPropertyProOptions } from '../actions'
import { selectRequestOptions } from '../selectors'

// TODO: merge this with request container in LRA epic
const RequestMainConnect = ComposedComponent =>
  compose(
    connect(
      state => ({
        requestOptions: selectRequestOptions(state),
        // TODO: move permalink and shortenlink out from property
        property: state.property,
        rentalSubmission: state.rentalSubmission,
        profile: state.user.profile,
        accessToken: getAccessToken(state.persistState),
      }),
      dispatch => ({
        actions: bindActionCreators(
          {
            getPropertyPermalink,
            clearRequestOptions,
            clearIsSendEmail,
            sendEmails,
            sendTextMessage,
            sendMoreEmails,
            sendMoreTextMessage,
            updateFlyerApi,
            createContact,
            createContacts,
            clearScreeningWithPropertyProOptions,
            clearScreeningWithoutPropertyProOptions,
          },
          dispatch,
        ),
      }),
    ),
    withReducer,
  )(ComposedComponent)

export default RequestMainConnect
