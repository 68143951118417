import { object } from 'yup'

import { validateLength, validateEmail } from 'legacy/constants/validations'

const defaultValue = { email: '', firstName: '', lastName: '' }

export const mapper = (props = defaultValue) => ({
  firstName: props.firstName,
  lastName: props.lastName,
  email: props.email,
})

export const validation = () =>
  object().shape({
    firstName: validateLength(true, 1, 50),
    lastName: validateLength(true, 1, 50),
    email: validateEmail(true),
  })
