import React from 'react'

import Styled from 'styled-components'

import BooleanCardV2 from 'components/molecules/boolean-card-v2'
import RadioImage from 'components/atoms/radio-image'
import { PAYER_TYPE, PAYER_SECTION_TEXT } from 'containers/tenant-screening/request-v2.2/constants'

const PayerWrapper = Styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  @media (max-width: 991px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`

const PayerBox = props => {
  const { boxControl, requestOptions, handleSelectPayer } = props
  const { payerType } = requestOptions

  return (
    <PayerWrapper>
      <BooleanCardV2
        id="ApplicantPayerBooleanCard"
        data-testid="applicant-payer-boolean-card"
        checked={payerType === PAYER_TYPE.APPLICANTS}
        isHidden={!boxControl.enabledItems.applicants}
        title={PAYER_SECTION_TEXT.applicants.title}
        icon={RadioImage}
        onClick={() => handleSelectPayer(PAYER_TYPE.APPLICANTS)}
      />
      <BooleanCardV2
        id="MePayerBooleanCard"
        data-testid="me-payer-boolean-card"
        checked={payerType === PAYER_TYPE.ME}
        isHidden={!boxControl.enabledItems.me}
        title={PAYER_SECTION_TEXT.me.title}
        icon={RadioImage}
        onClick={() => handleSelectPayer(PAYER_TYPE.ME)}
      />
    </PayerWrapper>
  )
}

export default PayerBox
