import classNames from 'classnames'
import React from 'react'

/**
 * @typedef ChatLineProps
 * @property {"up"|"down"} variant
 * @property {React.ReactNode} children
 */

/**
 * @param {ChatLineProps} props
 */
export const ChatLine = ({ children, variant, ...props }) => {
  return (
    <div
      {...props}
      className={classNames('chat-line', `chat-line-${variant}`, props?.className?.split(' '))}
    >
      {children}
    </div>
  )
}
