import React from "react"
import { string, number, boolean, array, object } from "yup"

import { FormattedMessage as Message } from "react-intl"
import moment from "moment"
import {
  validateLength,
  requestPropertyValidation,
  requiredYup,
} from "legacy/constants/validations"
import message from "legacy/constants/error-form-messages"
import { invalidChars } from "legacy/helpers/validator-helper"
import date from "helpers/yup-date-type"

import { PROPERTY_TYPES } from "containers/property-propertyType/const"
import { MONTH_TO_MONTH_LABEL, QUOTATION_TYPE } from "v3/containers/rent-payment/constants"
import {
  RENT_PAYMENT_VALIDATION_MSG,
  ONE_TIME_RENT_PAYMENT_VALIDATION_MSG,
  PROPERTY_DETAILS_VALIDATION_MSG,
  SCREENING_FEE_VALIDATION_MSG,
  RECURRING_RENT_PAYMENT_VALIDATION_MSG,
} from "./error-messages"

export const rentEstimateValidationMapper = {
  streetAddress: {
    regex: /^[a-zA-Z0-9\s]*$/,
    message: "Must match the following: a-z A-Z 0-9'",
  },
  hasNumber: {
    regex: /[0-9]/,
    message: "Must contain at least 1 number",
  },
  hasLetter: {
    regex: /[a-zA-Z]/,
    message: "Must contain at least 1 letter",
  },
  city: {
    regex: /^[a-zA-Z\s]*$/,
    message: "Must match the following: a-z A-Z",
  },
  unitNumber: {
    regex: /^[a-zA-Z0-9\s]*$/,
    message: "Must match the following: a-z A-Z 0-9",
  },
}

export const rentEstimateValidation = field => {
  switch (field) {
    case "street":
      return validateLength(true, 2, 100)
        .matches(
          rentEstimateValidationMapper.streetAddress.regex,
          rentEstimateValidationMapper.streetAddress.message,
        )
        .matches(
          rentEstimateValidationMapper.hasNumber.regex,
          rentEstimateValidationMapper.hasNumber.message,
        )
        .matches(
          rentEstimateValidationMapper.hasLetter.regex,
          rentEstimateValidationMapper.hasLetter.message,
        )
    case "unitNumber":
      return validateLength(false, 0, 50).matches(
        rentEstimateValidationMapper.unitNumber.regex,
        rentEstimateValidationMapper.unitNumber.message,
      )
    case "city":
      return validateLength(true, 0, 50).matches(
        rentEstimateValidationMapper.city.regex,
        rentEstimateValidationMapper.city.message,
      )
    case "state":
      return requestPropertyValidation("state")
    case "zipCode":
      return requestPropertyValidation("zipcode")
    case "propertyType":
      return requiredYup(
        true,
        string().oneOf([
          "Apartment",
          "Single Family Home",
          "Townhouse",
          "Manufactured Home",
          "Condominium",
          "Multi-Family",
        ]),
      )
    case "bedroom":
      return requiredYup(
        true,
        number().oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
      )
    case "bathroom":
      return requiredYup(
        true,
        number().oneOf([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6]),
      )
    case "area":
      return requiredYup(
        true,
        number()
          .integer()
          .min(100, <Message id="areaMinErr" {...message.area} />),
      )
    case "yearBuilt":
      return requiredYup(
        false,
        number()
          .integer()
          .min(1000, <Message id="yearBuiltMinErr" {...message.yearBuilt} />)
          .max(9999, <Message id="yearBuiltMaxErr" {...message.yearBuilt} />),
      )
    default:
      return requiredYup(false)
  }
}

export const getInvalidCharacterMessage = data => {
  const invalidCharacters = invalidChars(data.value, data.regex)
  return (
    <Message
      {...message.invalidCharacter}
      values={{ invalidCharacter: invalidCharacters[0] }}
    />
  )
}

export const validateNotInThePastDate = (required = true) =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    .min(
      moment()
        .startOf("day")
        .toDate(),
      <Message {...message.notFutureDate} />,
    )

export const validateDate = (required = true) =>
  requiredYup(required, date()).typeError(<Message {...message.invalidDate} />)

export const validateExpireDate = (required = true, startDate) =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    // eslint-disable-next-line func-names
    .test("is-greater", <Message {...message.expireDate} />, function(value) {
      const selectDate = this.parent[startDate]
      return moment(value).isSameOrAfter(moment(selectDate))
    })

export const validateFutureDate = (required = true, dateName) =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    .min(
      moment().startOf("day"),
      <Message {...message.noPastDate} values={{ dateName }} />,
    )

export const leaseAgreementValidation = (
  field,
  isMonthToMonth = true,
  isRequired = true,
) => {
  switch (field) {
    case "leaseTitle":
      return validateLength(isRequired, 2, 60)
    case "moveInDate":
      return validateDate(isRequired)
    case "expiredDate":
      return isMonthToMonth
        ? requiredYup(false)
        : validateExpireDate(isRequired, "moveInDate")
    case "isMonthToMonth":
      return requiredYup(isRequired, boolean())
    case "rentAmount":
      return validateLength(isRequired, 0, 50)
    case "securityDeposit":
      return validateLength(isRequired, 0, 50)
    default:
      return requiredYup(false)
  }
}

export const rentalPaymentValidation = (
  field,
  isRequired = true,
  option = {},
) => {
  switch (field) {
    case "monthlyAmount":
      return requiredYup(
        isRequired,
        number()
          .min(100, RENT_PAYMENT_VALIDATION_MSG.minMonthlyRent)
          .max(5990, RENT_PAYMENT_VALIDATION_MSG.maxMonthlyRent),
      )
    case "isMonthToMonth":
      return requiredYup(isRequired, boolean())
    case "firstPaymentSelector":
      return validateFutureDate(isRequired, "First payment")
    case "lastPaymentSelector":
        return requiredYup(isRequired).test((value) => {
          if (value === MONTH_TO_MONTH_LABEL) {
            return true
          }
          return validateFutureDate(isRequired, "Last payment")
        })
    case "dueOnSelector":
      return validateLength(isRequired, 1, 2)
    case "categorySelector":
      return validateLength(isRequired)
    case "customCategory":
      return option.isCustomCategory
        ? validateLength(isRequired, 1, 30)
        : requiredYup(false)
    case "description":
      return validateLength(false, 0, 90)
    case "dueOnDate":
      return validateNotInThePastDate(true)
    case 'amount':
      return option.type === QUOTATION_TYPE.ONE_TIME
        ? requiredYup(
            isRequired,
            number()
              .min(1, ONE_TIME_RENT_PAYMENT_VALIDATION_MSG.minOneTimeRent)
              .max(10000, ONE_TIME_RENT_PAYMENT_VALIDATION_MSG.maxOneTimeRent),
          )
        : requiredYup(
            isRequired,
            number()
              .min(3, RECURRING_RENT_PAYMENT_VALIDATION_MSG.minOneTimeRent)
              .max(10000, RECURRING_RENT_PAYMENT_VALIDATION_MSG.maxOneTimeRent),
          )
    default:
      return requiredYup(false)
  }
}

export const validateEmail = (required = true, requiredType, customValidateMessage) => {
  const validate = string()
    .email(
      <Message
        {...message.invalidEmail}
        defaultMessage={customValidateMessage || message.invalidEmail.defaultMessage}
      />,
    )
    .max(255, <Message {...message.maxChar} values={{ max: 255 }} />)
  return requiredYup(required, validate, requiredType)
}

export const validateEmails = (required = true) =>
  array().of(
    object()
      .shape({ email: validateEmail(required) })
      .uniqueProperty("email", <Message {...message.duplicateEmails} />),
  )

export const propertyTypeValidation = () =>
  requiredYup(
    true,
    string().oneOf(
      PROPERTY_TYPES.map(item => item.value),
      <Message
        id="propertyTypeErr"
        defaultMessage={`Property Type must be one of the following values: ${PROPERTY_TYPES.map(
          item => item.label,
        ).join(", ")}`}
      />,
    ),
  )

export const propertyDetailsValidation = field => {
  switch (field) {
    case "propertyType":
      return propertyTypeValidation()
    case "bedrooms":
      return rentEstimateValidation("bedroom")
    case "bathrooms":
      return rentEstimateValidation("bathroom")
    case "monthlyRent":
      return requiredYup(
        true,
        number()
          .min(100, PROPERTY_DETAILS_VALIDATION_MSG.monthlyRent)
          .max(1000000, PROPERTY_DETAILS_VALIDATION_MSG.monthlyRent),
      )
    case "squareFeet":
      return requiredYup(
        true,
        number()
          .min(100, PROPERTY_DETAILS_VALIDATION_MSG.squareFeet)
          .max(9999, PROPERTY_DETAILS_VALIDATION_MSG.squareFeet),
      )
    case "yearBuilt":
      return requiredYup(
        true,
        number()
          .min(1000, PROPERTY_DETAILS_VALIDATION_MSG.yearBuilt)
          .max(moment().year(), PROPERTY_DETAILS_VALIDATION_MSG.yearBuilt),
      )
    default:
      return requiredYup(false)
  }
}

export const screeningFeeValidation = field => {
  switch (field) {
    case "creditReportFee":
      return requiredYup(
        true,
        number()
          .min(0, SCREENING_FEE_VALIDATION_MSG.creditReportFee)
          .max(1000000000, SCREENING_FEE_VALIDATION_MSG.creditReportFee),
      )
    case "outOfPocketExpense":
      return requiredYup(
        true,
        number()
          .min(0, SCREENING_FEE_VALIDATION_MSG.outOfPocketExpense)
          .max(1000000000, SCREENING_FEE_VALIDATION_MSG.outOfPocketExpense),
      )
    case "processingFee":
      return requiredYup(
        true,
        number()
          .min(0, SCREENING_FEE_VALIDATION_MSG.processingFee)
          .max(1000000000, SCREENING_FEE_VALIDATION_MSG.processingFee),
      )
    default:
      return requiredYup(false)
  }
}

export const regexValidation = {
  dreLicense: {
    regex: /^#[a-z0-9]+$/i,
    message: "This field only allows letters and numbers",
  },
}
