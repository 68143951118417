import produce from "immer"
import {
  TOGGLE_NEW_MESSAGE_MODAL_CALL,
  START_CONVERSATION_REQUEST,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_FAILURE,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
  INITIATE_TALK_JS_SUCCESS,
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAILURE,
  SET_CONVERSATION,
  CLEAR_CONVERSATION,
  CLOSE_MESSAGING_MODAL_ERROR,
  SET_UNREAD_COUNT,
} from "./constants"
import { paresUnreadText } from "./helper"

export const initialState = {
  isLoading: {
    container: false,
    modal: false,
  },
  isError: {
    container: false,
    modal: false,
  },
  isShowNewMessageModal: false,
  isConversationStarted: false,
  currentConversationId: null,
  isTalkJsReady: false,
  signature: {},
  isUnreadNotificationReady: false,
  unreadCountText: "",
}

/* eslint-disable default-case, no-param-reassign */
const messagingReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case START_CONVERSATION_REQUEST:
        draft.isLoading.modal = true
        draft.isError.modal = false
        draft.isConversationStarted = false
        break
      case START_CONVERSATION_SUCCESS:
        draft.currentConversationId = payload.conversationId
        draft.isShowNewMessageModal = false
        draft.isLoading.modal = false
        draft.isError.modal = false
        draft.isConversationStarted = true
        break
      case START_CONVERSATION_FAILURE:
        draft.isLoading.modal = false
        draft.isError.modal = true
        draft.isConversationStarted = false
        break
      case GET_CONVERSATION_REQUEST:
        draft.isLoading.container = true
        draft.isError.container = false
        break
      case GET_CONVERSATION_SUCCESS:
        draft.currentConversationId = payload.conversationId
        draft.isLoading.container = false
        break
      case GET_CONVERSATION_FAILURE:
        draft.isLoading.container = false
        draft.isError.container = true
        break
      case SET_CONVERSATION:
        draft.currentConversationId = payload
        break
      case CLEAR_CONVERSATION:
        draft.currentConversationId = null
        break
      case INITIATE_TALK_JS_SUCCESS:
        draft.isTalkJsReady = true
        break
      case GET_SIGNATURE_SUCCESS:
        draft.signature = payload
        break
      case GET_SIGNATURE_FAILURE:
        draft.isError.container = true
        break
      case TOGGLE_NEW_MESSAGE_MODAL_CALL:
        draft.isShowNewMessageModal = payload
        break
      case CLOSE_MESSAGING_MODAL_ERROR:
        draft.isError.modal = false
        break
      case SET_UNREAD_COUNT:
        draft.isUnreadNotificationReady = true
        draft.unreadCountText = paresUnreadText(payload)
        break
    }
    return draft
  })

export default messagingReducer
