import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import { withFormik } from "formik"
import TextArea from "@rentspree/component-v2/dist/input/textarea"
import S16 from "@rentspree/component-v2/dist/typography/s16"
import S18 from "@rentspree/component-v2/dist/typography/s18"
import Hr from "@rentspree/component-v2/dist/hr"
import Checkbox from "@rentspree/component-v2/dist/input/checkbox"
import FormattedInput from "@rentspree/component-v2/dist/input/formatted"
import DateInput from "@rentspree/component-v2/dist/input/date"

import CheckboxGroup from "components/shared/form/checkbox-group"
import Row from "components/atoms/row"
import Col from "components/atoms/col"
import { COLOR } from "styles/settings"
import { schema } from "./schema"

const StyledForm = styled.div`
  font-family: Source Sans Pro;
`

const Subtitle = styled(S18)`
  ${({ isRequired }) =>
    isRequired &&
    `
    ::after {
      content: "*";
      color: ${COLOR.fontRed};
    }
  `}
`

const StyledCheckboxGroup = styled(CheckboxGroup)`
  margin-bottom: 15px;
  h4 {
    margin-bottom: 5px;
  }
  .checkbox-group-child {
    > div {
      width: ${({ noWrap }) => (noWrap ? "100%" : "50%")};
      @media (max-width: 991px) {
        width: 100%;
        margin: 5px 0;
      }
      img {
        min-width: 24px;
      }
    }
  }
`

const StyledHr = styled(Hr)`
  margin-bottom: 10px;
`

const Indent = styled.div`
  padding-left: ${({ paddingLeft }) => paddingLeft || "25px"};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  @media (max-width: 991px) {
    ${({ mMargin }) => mMargin && `margin: ${mMargin};`}
    padding-left: ${({ mPaddingLeft }) => mPaddingLeft || "0"};
    padding-right: ${({ mPaddingRight }) => mPaddingRight || "0"};
  }
`

export const ConditionForm = props => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    renterName,
    id,
    hasConditionInput,
  } = props

  return (
    <StyledForm>
      {hasConditionInput && (
        <TextArea
          name="conditionInput"
          height="75px"
          label={`Accept ${
            renterName ? `${renterName}’s` : "this"
          } application with the following added conditions: `}
          id={`${id}ConditionInput`}
          isRequired
          onChange={handleChange}
          value={get(values, "conditionInput")}
          onBlur={handleBlur}
          error={
            get(touched, "conditionInput") && get(errors, "conditionInput")
          }
        />
      )}
      <Subtitle margin="10px 0" mMargin="5px 0 10px" isRequired>
        This decision was made based in whole or in part on (select all that
        apply)
      </Subtitle>
      <StyledHr />
      <Indent paddingLeft="25px">
        <StyledCheckboxGroup label="Income">
          <Checkbox
            name="incomeBelowRequirement"
            id={`${id}IncomeBelowRequirement`}
            label="Is below our minimum requirement"
            fontSize="16px"
            isSelected={get(values, "income.belowRequirement")}
            simple
            onClick={() => {
              setFieldValue(
                "income.belowRequirement",
                !get(values, "income.belowRequirement"),
              )
            }}
          />
          <Checkbox
            name="incomeNotVerified"
            id={`${id}IncomeNotVerified`}
            label="Could not be verified"
            fontSize="16px"
            isSelected={get(values, "income.notVerified")}
            simple
            onClick={() =>
              setFieldValue(
                "income.notVerified",
                !get(values, "income.notVerified"),
              )
            }
          />
        </StyledCheckboxGroup>
        <StyledCheckboxGroup label="Rental Application">
          <Checkbox
            name="rentalApplicationInsufficientInformation"
            id={`${id}RentalApplicationInsufficientInformation`}
            label="Contained insufficient information"
            fontSize="16px"
            simple
            isSelected={get(
              values,
              "rentalApplication.insufficientInformation",
            )}
            onClick={() =>
              setFieldValue(
                "rentalApplication.insufficientInformation",
                !get(values, "rentalApplication.insufficientInformation"),
              )
            }
          />
          <Checkbox
            name="rentalApplicationErroneousInformation"
            id={`${id}RentalApplicationErroneousInformation`}
            label="Contained erroneous information"
            fontSize="16px"
            simple
            isSelected={get(values, "rentalApplication.erroneousInformation")}
            onClick={() =>
              setFieldValue(
                "rentalApplication.erroneousInformation",
                !get(values, "rentalApplication.erroneousInformation"),
              )
            }
          />
          <Checkbox
            name="rentalApplicationNotVerifiedInformation"
            id={`${id}RentalApplicationNotVerifiedInformation`}
            label="Contained information that could not be verified"
            fontSize="16px"
            simple
            isSelected={get(values, "rentalApplication.notVerifiedInformation")}
            onClick={() =>
              setFieldValue(
                "rentalApplication.notVerifiedInformation",
                !get(values, "rentalApplication.notVerifiedInformation"),
              )
            }
          />
        </StyledCheckboxGroup>
        <>
          <S16 margin="0px 0px 10px 0px">
            Another reason unrelated to your background screening reports:
          </S16>
          <TextArea
            name="anotherUnrelatedReasons"
            height="75px"
            id={`${id}AnotherUnrelatedReasons`}
            mb={15}
            onChange={handleChange}
            value={get(values, "anotherUnrelatedReasons")}
            onBlur={handleBlur}
            error={
              get(touched, "anotherUnrelatedReasons") &&
              get(errors, "anotherUnrelatedReasons")
            }
          />
        </>
        <StyledCheckboxGroup
          label="Consumer report(s) and/or score provided by RentSpree/TransUnion"
          noWrap>
          <Checkbox
            name="consumerReportCreditScore"
            id={`${id}ConsumerReportCreditScore`}
            label="Credit Score / Resident Score"
            fontSize="16px"
            simple
            isSelected={get(values, "consumerReport.creditScore")}
            onClick={() =>
              setFieldValue(
                "consumerReport.creditScore",
                !get(values, "consumerReport.creditScore"),
              )
            }
          />
          <Indent paddingLeft="35px" margin="5px 0 0 0">
            <Row wrap>
              <Col half mFull padding="0 10px 0 0" mPadding="0">
                <FormattedInput
                  name="consumerReport.creditScoreDetails.score"
                  label="Your Credit Score / Resident Score"
                  id={`${id}ConsumerReportCreditScoreDetailsScore`}
                  formatType="amount"
                  format="###"
                  allowNegative={false}
                  isRequired
                  disabled={!get(values, "consumerReport.creditScore")}
                  onChange={handleChange}
                  value={get(values, "consumerReport.creditScoreDetails.score")}
                  onBlur={handleBlur}
                  error={
                    get(touched, "consumerReport.creditScoreDetails.score") &&
                    get(errors, "consumerReport.creditScoreDetails.score")
                  }
                />
              </Col>
              <Col half mFull padding="0 0 0 10px" mPadding="0">
                <DateInput
                  name="consumerReport.creditScoreDetails.date"
                  label="Date (mm/dd/yyyy)"
                  id={`${id}ConsumerReportCreditScoreDetailsDate`}
                  formatType="date"
                  disabled={!get(values, "consumerReport.creditScore")}
                  onChange={handleChange}
                  value={get(values, "consumerReport.creditScoreDetails.date")}
                  onBlur={handleBlur}
                  error={
                    get(touched, "consumerReport.creditScoreDetails.date") &&
                    get(errors, "consumerReport.creditScoreDetails.date")
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col full mFull>
                <TextArea
                  name="consumerReport.creditScoreDetails.factors"
                  height="75px"
                  label="Key factors that adversely affected your score:"
                  id={`${id}ConsumerReportCreditScoreDeatailsFactors`}
                  disabled={!get(values, "consumerReport.creditScore")}
                  onChange={handleChange}
                  value={get(
                    values,
                    "consumerReport.creditScoreDetails.factors",
                  )}
                  onBlur={handleBlur}
                  error={
                    get(touched, "consumerReport.creditScoreDetails.factors") &&
                    get(errors, "consumerReport.creditScoreDetails.factors")
                  }
                />
              </Col>
            </Row>
          </Indent>
          <Checkbox
            name="consumerReportInvestigative"
            id={`${id}ConsumerReportInvestigative`}
            label="Investigative consumer report"
            fontSize="16px"
            simple
            isSelected={get(values, "consumerReport.investigative")}
            onClick={() =>
              setFieldValue(
                "consumerReport.investigative",
                !get(values, "consumerReport.investigative"),
              )
            }
          />
        </StyledCheckboxGroup>
        <StyledCheckboxGroup label="Other" noWrap>
          <Checkbox
            name="otherThirdPartyInformation"
            id={`${id}OtherThirdPartyInformation`}
            label="Information obtained from third parties other than a consumer reporting agency"
            fontSize="16px"
            simple
            isSelected={get(values, "other.thirdPartyInformation")}
            onClick={() =>
              setFieldValue(
                "other.thirdPartyInformation",
                !get(values, "other.thirdPartyInformation"),
              )
            }
          />
        </StyledCheckboxGroup>
      </Indent>
    </StyledForm>
  )
}

const AcceptDenyConditionForm = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  enableReinitialize: true,
  displayName: "AcceptDenyConditionForm",
})(ConditionForm)

export default AcceptDenyConditionForm
