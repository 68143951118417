import get from "lodash/get"
import * as Types from "../constants/action-types"
import createAction from "../apis/utils/create-action"
import * as api from "../apis/credit-report.api"
import { ALERT_PRESET } from "../libs/sweet-alert/sweet-alert"
import * as ERROR from "../constants/error-messages"
import tracker from "../tracker"
import { EVENT_PAY_REPORT } from "../tracker/const"
// import * as mock from "./_mock/credit-report.action"

export const getCreditReport = createAction(
  api.getCreditReport,
  [
    Types.GET_CREDIT_REPORT_REQUEST,
    Types.GET_CREDIT_REPORT_SUCCESS,
    Types.GET_CREDIT_REPORT_FAILED,
  ],
  (res = {}) => ({ creditReport: res || {} }),
)
// export const getCreditReport = () => mock.getCreditReport("with-json-credit-report")

export const runCreditReport = createAction(
  api.runCreditReport,
  [
    Types.OPEN_CREDIT_REPORT_REQUEST,
    Types.OPEN_CREDIT_REPORT_SUCCESS,
    Types.OPEN_CREDIT_REPORT_FAILED,
  ],
  data => ({ ...data.result }),
)

export const openCreditReportWithFetch = rentalId => (dispatch, getState) =>
  runCreditReport(rentalId)(dispatch, getState).then(data => {
    if (data.success === true) {
      dispatch({ type: Types.UPDATE_STATUS_CREDIT_REPORT, rentalId })
    }
    return data
  })

export const clearCreditReport = () => ({
  type: Types.CLEAR_CREDIT_REPORT_STATE,
})
export const clearCreditReportData = () => ({
  type: Types.CLEAR_CREDIT_REPORT_DATA,
})
export const clearCreditPayment = () => ({ type: Types.CLEAR_CREDIT_PAYMENT })
export const disablePaymentBtn = data => ({
  type: Types.DISABLED_PAYMENT_BTN,
  data,
})

export const getPayment = createAction(
  api.getPayment,
  [
    Types.GET_CREDIT_PAYMENT_REQUEST,
    Types.GET_CREDIT_PAYMENT_SUCCESS,
    Types.GET_CREDIT_PAYMENT_FAILED,
  ],
  (res = {}) => ({ data: res }),
)

/**
 * args[0]: {Object} creditPayment
 * args[1]: {String} rentalId
 */
export const submitPayment = (...args) => (dispatch, getState) =>
  fetchSubmitPayment(...args)(dispatch, getState).then((res = {}) => {
    const { application } = getState()
    const properties = {
      applicant: get(application, "rentalDetail.email"),
      rental_id: get(application, "rentalDetail._id"),
    }
    if (!res.success) {
      // call get payment
      if (res.status === 409) {
        getPayment(args[1])(dispatch, getState)
      } else {
        dispatch({
          type: Types.OPEN_SWEET_ALERT,
          preset: ALERT_PRESET.ERROR,
          option: {
            title: ERROR.PAYMENT.LANDLORD_PAY_GENERAL_FAILED.TITLE,
            text: ERROR.PAYMENT.LANDLORD_PAY_GENERAL_FAILED.MESSAGE,
          },
          promise: () => {
            window.location.reload()
          },
        })
      }
      tracker.trackEvent(EVENT_PAY_REPORT.payReportFail, properties)
    } else {
      tracker.trackEvent(EVENT_PAY_REPORT.payReportSuccess, properties)
    }
    return res
  })

export const fetchSubmitPayment = createAction(
  api.submitPayment,
  [
    Types.SUBMIT_CREDIT_PAYMENT_REQUEST,
    Types.SUBMIT_CREDIT_PAYMENT_SUCCESS,
    Types.SUBMIT_CREDIT_PAYMENT_FAILED,
  ],
  () => ({ success: true }),
)

export const clearPaymentError = () => ({ type: Types.CLEAR_PAYMENT_ERROR })

export const setDefaultPaymentError = () => ({
  type: Types.SUBMIT_CREDIT_PAYMENT_FAILED,
})
