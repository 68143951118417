import get from 'lodash/get'
import {
  PARTICIPANT_TYPE,
  SCREENING_OPTION_TYPES,
  SHARED_REPORT_TYPES,
} from 'containers/reports/constants'
import {
  REPORT_TAB_ID_APPLICATION,
  REPORT_TAB_ID_CREDIT,
  REPORT_TAB_ID_CRIMINAL,
  REPORT_TAB_ID_EVICTION,
  REPORT_TAB_ID_INCOME_VERIFICATION,
} from './const'

export const isReportAvailable = (rentalSubmission, submissionParticipant, report) => {
  const participantType = get(submissionParticipant, 'type')
  const shareOption = get(submissionParticipant, 'shareOption', {})
  const screeningOption = get(rentalSubmission, 'screeningOption', {})
  const isSharedScreeningReport = get(shareOption, SHARED_REPORT_TYPES.SCREENING_REPORT, false)
  const isSharedIncomeVerificationReport = get(
    shareOption,
    SHARED_REPORT_TYPES.INCOME_VERIFICATION_REPORT,
    false,
  )
  if (participantType === PARTICIPANT_TYPE.PARTICIPANT) {
    switch (report) {
      case SCREENING_OPTION_TYPES.CREDIT_REPORT:
      case SCREENING_OPTION_TYPES.CRIMINAL:
      case SCREENING_OPTION_TYPES.EVICTION:
        // NOTE: Need to map with rentalScreeningOption because screeningReport is the group of credit_report, criminal and eviction
        return isSharedScreeningReport ? screeningOption[report] : false
      case SCREENING_OPTION_TYPES.INCOME:
        return isSharedIncomeVerificationReport ? screeningOption[report] : false
      default:
        return shareOption[report]
    }
  }
  return screeningOption[report]
}

export const getReportNameByTabId = tabId => {
  const reportTab = {
    [REPORT_TAB_ID_APPLICATION]: 'application',
    [REPORT_TAB_ID_CREDIT]: 'credit_report',
    [REPORT_TAB_ID_CRIMINAL]: 'criminal',
    [REPORT_TAB_ID_EVICTION]: 'eviction',
    [REPORT_TAB_ID_INCOME_VERIFICATION]: 'income',
  }
  return get(reportTab, tabId, '')
}
