export const RENT_PAYMENT_PROGRESS = [
  { title: 'Property & tenant', key: 'property-and-tenants' },
  { title: 'Payment details', key: 'payment-detail' },
  { title: 'Submit request', key: 'submit-request' },
]
export const RENT_PAYMENT_STEP_SELECTOR = {
  requests: 'property-and-tenants',
  'payment-details': 'payment-detail',
  monthly: 'payment-detail',
  'one-time': 'payment-detail',
  confirmation: 'submit-request',
}
