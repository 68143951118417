export const BUTTON_TEXT = "Return to Dashboard"

export const HEADER = "Credit Report Limit Reached"

export const AGENT_TEXT = {
    DESCRIPTION_LINE_ONE: "This applicant’s credit report will be available tomorrow.",
    DESCRIPTION_LINE_TWO: "TransUnion generates up to 5 reports per applicant per day."
}

export const RENTER_TEXT = {
    DESCRIPTION_LINE_ONE: "Your credit report will be available tomorrow.",
    DESCRIPTION_LINE_TWO: "TransUnion generates up to 5 reports per day."
}