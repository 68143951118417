import rentPayment from "images/icons/navbar/navbar-rent-payment.svg"
import applications from "images/icons/navbar/navbar-applications.svg"

const RENTER_ROUTES = {
  DASHBOARD: "/dashboard",
  APPLICATIONS: "/overview",
  RENT_PAYMENT: "/overview", // TODO: Change to `/rent-payment`
}

export const RENTER_NAVBAR = {
  APPLICATIONS: {
    id: "navbar-applications",
    className: "hideDeskTop",
    img: applications,
    text: "Applications",
    url: `${RENTER_ROUTES.DASHBOARD}${RENTER_ROUTES.APPLICATIONS}`,
    isDivider: false,
    showMobileIcon: true,
    noIconFilter: true,
  },
  RENT_PAYMENT: {
    id: "navbar-rent-payment",
    className: "hideDeskTop",
    img: rentPayment,
    text: "Rent Payment",
    url: `${RENTER_ROUTES.DASHBOARD}${RENTER_ROUTES.RENT_PAYMENT}`,
    isDivider: false,
    showMobileIcon: true,
    noIconFilter: true,
    featureId: "rent_collection",
  },
}
