import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { selectPropertyById } from 'containers/select-property/selectors'
import { useMutateUpdateUserRoleForProperty } from 'hooks'

export const useUpdateLandlordProfileInPropertyUser = (propertyId, landlordInfo) => {
  const property = useSelector(selectPropertyById(propertyId))
  const mutateLandlordProfile = useMutateUpdateUserRoleForProperty()

  let shouldUpdate = false
  const propertyLandlordProfile = property?.landlordProfile
  if (
    propertyLandlordProfile?.firstName !== landlordInfo?.firstName ||
    propertyLandlordProfile?.lastName !== landlordInfo?.lastName ||
    propertyLandlordProfile?.email !== landlordInfo?.email
  ) {
    shouldUpdate = true
  }
  const updatePropertyDetailsPayload = { ...propertyLandlordProfile, ...landlordInfo }

  return useCallback(async () => {
    if (shouldUpdate) {
      await mutateLandlordProfile.mutateAsync({
        propertyId,
        userRoleForProperty: USER_ROLE_FOR_PROPERTY.LISTING_AGENT,
        landlordProfile: updatePropertyDetailsPayload,
      })
    }
  }, [
    shouldUpdate,
    propertyId,
    updatePropertyDetailsPayload.firstName,
    updatePropertyDetailsPayload.lastName,
    updatePropertyDetailsPayload.email,
  ])
}
