import generateApiCall from 'utils/generate-api-call'
import {
  OPEN_ACCEPT_DENY_MODAL,
  CLEAR_ACCEPT_DENY_MODAL,
  ACCEPT_DENY_CALL,
  ACCEPT_DENY_REQUEST,
  ACCEPT_DENY_SUCCESS,
  ACCEPT_DENY_FAILURE,
  CHANGE_ACCEPT_DENY_TYPE,
  COMPLETE_ACCEPT_ON_CONDITION_FORM,
  GET_DISCLAIMER_CONTENT_CALL,
  GET_DISCLAIMER_CONTENT_REQUEST,
  GET_DISCLAIMER_CONTENT_SUCCESS,
  GET_DISCLAIMER_CONTENT_FAILURE,
  AGREE_DISCLAIMER_CALL,
  AGREE_DISCLAIMER_REQUEST,
  AGREE_DISCLAIMER_SUCCESS,
  AGREE_DISCLAIMER_FAILURE,
  COMPLETE_DENY_FORM,
  BACK_TO_FORM,
  CONTINUE_TO_RENT_PAYMENT,
  CONTINUE_TO_ESIGN,
  SHOW_SECURITY_DEPOSIT_MODAL,
  CLOSE_SECURITY_DEPOSIT_MODAL,
} from './constants'

export const acceptDenyCall = payload => ({
  type: ACCEPT_DENY_CALL,
  payload,
})

export const acceptDenyApiState = generateApiCall([
  ACCEPT_DENY_REQUEST,
  ACCEPT_DENY_SUCCESS,
  ACCEPT_DENY_FAILURE,
])

export const openAcceptDenyModal = ({
  rentalSubmission,
  propertyId,
  propertyAddress,
  isSharedReport,
  acceptDenyType,
  clickFrom,
  dropdownButtonEventName, // do not save to reducer, just only pass through payload for tracking
  screeningResult,
  clickFromWidget,
}) => ({
  type: OPEN_ACCEPT_DENY_MODAL,
  payload: {
    rentalSubmission,
    propertyId,
    propertyAddress,
    isSharedReport,
    acceptDenyType,
    clickFrom,
    dropdownButtonEventName,
    screeningResult,
    clickFromWidget,
  },
})

export const showSecurityDepositModal = ({ propertyId, tenantEmail, securityDepositClickFrom }) => ({
  type: SHOW_SECURITY_DEPOSIT_MODAL,
  payload: { propertyId, tenantEmail, securityDepositClickFrom },
})

export const closeSecurityDepositModal = () => ({
  type: CLOSE_SECURITY_DEPOSIT_MODAL,
})

export const clearAcceptDenyModal = () => ({
  type: CLEAR_ACCEPT_DENY_MODAL,
})

export const changeAcceptDenyType = acceptDenyType => ({
  type: CHANGE_ACCEPT_DENY_TYPE,
  payload: { acceptDenyType },
})

export const completeAcceptOnConditionForm = values => ({
  type: COMPLETE_ACCEPT_ON_CONDITION_FORM,
  payload: { values },
})

export const completeDenyForm = values => ({
  type: COMPLETE_DENY_FORM,
  payload: { values },
})

export const backToForm = () => ({
  type: BACK_TO_FORM,
})

export const getDisclaimerContentCall = () => ({
  type: GET_DISCLAIMER_CONTENT_CALL,
})

export const getDisclaimerContentApiState = generateApiCall([
  GET_DISCLAIMER_CONTENT_REQUEST,
  GET_DISCLAIMER_CONTENT_SUCCESS,
  GET_DISCLAIMER_CONTENT_FAILURE,
])

export const agreeDisclaimerCall = payload => ({
  type: AGREE_DISCLAIMER_CALL,
  payload,
})

export const agreeDisclaimerApiState = generateApiCall([
  AGREE_DISCLAIMER_REQUEST,
  AGREE_DISCLAIMER_SUCCESS,
  AGREE_DISCLAIMER_FAILURE,
])

export const continueToRentPayment = payload => ({
  type: CONTINUE_TO_RENT_PAYMENT,
  payload,
})

export const continueToESign = payload => ({ type: CONTINUE_TO_ESIGN, payload })
