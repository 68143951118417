/* eslint-disable no-alert */
import React from "react"
import { compose } from "redux"
import { buildPath, query } from "@rentspree/path"
import { OverlayTrigger } from "react-bootstrap"
import isNil from "lodash/isNil"
import get from "lodash/get"
import startCase from "lodash/startCase"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import B14 from "@rentspree/component-v2/dist/typography/b14"

import tracker from "tracker"
import { RENTER_INSURANCE } from "tracker/const"
import { TooltipOverlay } from "components/navbar/collapse"
import { Container } from "components/renter-insurance"
import { TransparentStickyFooter } from "components/layout/mobile-sticky-footer"
import { FailedToFetch } from "components/layout/failed-to-fetch"
import Pagination from "components/filter/pagination"
import {
  WhiteWrapper,
  CenterContent,
  Loading,
  FilterRow,
} from "components/layout/main"
import { IconWithNameDesc } from "components/molecules/icon-with-name-desc"
import { TextWithIcon } from "components/molecules/text-with-icon"
import RentSpreeTableKung from "components/templates/rentspree-table"
import { ButtonComposeComponent } from "components/buttons/button-compose"
import {
  RENTER_INSURANCE_LIST,
  RENTER_INSURANCE_STATUS,
} from "components/renter-insurance/constants"
import {
  RENTER_INSURANCE_REQUEST_PAGE,
  RENTER_INSURANCE_DETAIL,
} from "constants/route"
import { addSearchParam } from "utils/pagination"
import { COLOR } from "styles/settings"

import LandingV2 from "components/landing-v2"
import { withConnect, withReducer, withSaga } from "./connect"
import {
  STATUS_TEXT,
  BTN_TEXT,
  RENTER_INSURANCE_CHANNEL,
  MAIN_CONTENT,
  EXTRA_DETAILS,
  EXTRA_TITLE,
} from "./constants"

export const NotifyButton = ({ hideDesktop, onClick, id, shadowLevel2 }) =>
  ButtonComposeComponent({
    text: BTN_TEXT,
    id,
    className: hideDesktop ? "hidden-xs hidden-sm" : "",
    small: true,
    margin: "0px",
    minWidth: "160",
    height: "40px",
    semiBold: true,
    center: true,
    fontSize: "16px",
    sourceSansPro: true,
    onClick,
    shadowLevel2,
  })(BlueGradientButton)

export const mapStatusContent = ({ status, channel } = {}) => {
  switch (status) {
    case RENTER_INSURANCE_STATUS.ACTIVE:
      switch (channel) {
        case RENTER_INSURANCE_CHANNEL.UPLOAD:
          return { color: COLOR.darkGreen, text: STATUS_TEXT.UPLOADED }
        default:
          return { color: COLOR.darkGreen, text: STATUS_TEXT.ACTIVE }
      }
    case RENTER_INSURANCE_STATUS.INACTIVE:
      return { color: COLOR.placeholderGrey, text: STATUS_TEXT.INACTIVE }
    case RENTER_INSURANCE_STATUS.NOTIFIED:
      return { color: COLOR.yellow, text: STATUS_TEXT.NOTIFIED }
    case RENTER_INSURANCE_STATUS.PROCESSING:
      return { color: COLOR.fontBlue, text: STATUS_TEXT.PROCESSING }
    default:
      return { color: COLOR.textBlack, text: startCase(status) }
  }
}

export const renterStartedTooltip = TooltipOverlay({
  id: "renter-started-status-info",
  placement: "top",
  width: "180px",
  textAlign: "left",
  text: (
    <B14 margin="0" color="#fff" size="12px" lineHeight="16px">
      Renter has started. Once purchased, policies may take up to 24 hours to
      appear active.
    </B14>
  ),
})

export const renderHolder = (value, item) => {
  const { color } = mapStatusContent(item)
  return <TextWithIcon icon="menu-insurance" iconColor={color} text={value} />
}

export const renderStatus = (value, item) => {
  const { color, text } = mapStatusContent(item)
  const content = <B14 color={color}>{text}</B14>
  if (value === RENTER_INSURANCE_STATUS.PROCESSING) {
    return (
      <OverlayTrigger placement="top" overlay={renterStartedTooltip}>
        {content}
      </OverlayTrigger>
    )
  }
  return content
}

export const desktopColumns = [
  {
    key: "holder",
    title: "Holder",
    className: "hidden-xs hidden-sm",
    width: "30%",
    render: renderHolder,
  },
  {
    key: "status",
    title: "Status",
    className: "hidden-xs hidden-sm",
    render: renderStatus,
  },
]

export const renderMobileRow = item => {
  const { color, text } = mapStatusContent(item)
  return (
    <IconWithNameDesc
      icon="menu-insurance"
      iconColor={color}
      text={item.holder}
      descText={text}
      descColor={color}
    />
  )
}

export const mobileRow = {
  keys: { text: "holder", descText: "status" },
  icon: "menu-insurance",
  className: "",
  render: renderMobileRow,
}

export const RenterInsurance = ({
  match,
  location,
  actions,
  history,
  isFetching,
  renterInsuranceList,
  pagination,
  isError,
}) => {
  const isShowLanding = renterInsuranceList.length <= 0
  const { propertyId } = match.params
  const { page: pageFromQuery = 1 } = query.parse(location.search)
  const { fromLandingPage = false } = location.state || {}

  const defaultQuery = {
    ...RENTER_INSURANCE_LIST.defaultQuery,
    page: parseInt(pageFromQuery, 10),
  }

  const [queryOptions, setQueryOptions] = React.useState(defaultQuery)
  React.useEffect(() => {
    if (propertyId) {
      actions.getRenterInsuranceListCall({
        propertyId,
        options: queryOptions,
      })
    }
    const eventProperty = fromLandingPage
      ? {
          source:
            RENTER_INSURANCE.EVENT_PROPERTY.RENTERS_INSURANCE_ONBOARDING_MENU,
        }
      : {}
    tracker.trackEvent(RENTER_INSURANCE.EVENT_NAME.ENTER_PAGE, eventProperty)
  }, [])

  React.useEffect(
    () => () => {
      actions.clearRenterInsuranceListCall()
    },
    [],
  )

  const handleGoToRequest = () => {
    tracker.trackEvent(
      RENTER_INSURANCE.EVENT_NAME.START_REQUEST_RENTERS_INSURANCE,
      {
        click_from: RENTER_INSURANCE.EVENT_PROPERTY.RENTERS_INSURANCE_MENU,
      },
    )
    history.push(buildPath(RENTER_INSURANCE_REQUEST_PAGE, { propertyId }))
  }

  const handleGetInsuranceList = (
    page,
    filter = {},
    search,
    sort,
    pushHistory = true,
  ) => {
    const newPage = page || queryOptions.page
    const newFilter = filter.status ? { ...filter } : { ...queryOptions.filter }
    const newSearch = isNil(search) ? queryOptions.search : search
    const newSort = sort || queryOptions.sort
    const newPerPage = queryOptions.perPage
    const newQuery = {
      page: newPage,
      filter: newFilter,
      search: newSearch,
      sort: newSort,
      perPage: newPerPage,
    }
    if (propertyId) {
      actions.getRenterInsuranceListCall({
        propertyId,
        options: newQuery,
      })
    }
    if (pushHistory) {
      addSearchParam({ history, location, queryToAdd: newQuery })
    }
    setQueryOptions(newQuery)
  }

  const handleChangePage = page => {
    handleGetInsuranceList(page)
  }

  const handleGoToDetail = rentersInsurance => {
    const rentersInsuranceId = get(rentersInsurance, "_id")
    const status = get(rentersInsurance, "status")
    if (
      [
        RENTER_INSURANCE_STATUS.ACTIVE,
        RENTER_INSURANCE_STATUS.INACTIVE,
      ].includes(status)
    ) {
      history.push(
        buildPath(RENTER_INSURANCE_DETAIL, { propertyId, rentersInsuranceId }),
      )
    }
  }

  if (isError) {
    return (
      <FailedToFetch
        noMargin
        noBreadcrumbMobile
        text={RENTER_INSURANCE_LIST.ERROR.MESSAGE}
        title={RENTER_INSURANCE_LIST.ERROR.TITLE}
      />
    )
  }

  return (
    <Container id="renterInsuranceContainer">
      {isFetching && (
        <WhiteWrapper withFilter>
          <CenterContent overlay withBreadcrumb withFilter>
            <Loading />
          </CenterContent>
        </WhiteWrapper>
      )}

      {!isFetching && isShowLanding && (
        <WhiteWrapper withFilter>
          <LandingV2
            handleTopOnClick={handleGoToRequest}
            handleBottomOnClick={handleGoToRequest}
            mainContent={MAIN_CONTENT}
            extraTitle={EXTRA_TITLE}
            extraDetails={EXTRA_DETAILS}
            buttonText={BTN_TEXT}
            buttonMaxWidth="128px"
          />
        </WhiteWrapper>
      )}

      {!isFetching && !isShowLanding && (
        <>
          <FilterRow id="rentalInsuranceListFilter">
            <NotifyButton
              hideDesktop
              id="notifyRenterButton"
              onClick={handleGoToRequest}
            />
          </FilterRow>
          <WhiteWrapper withBreadcrumb withStickyFooter withFilter>
            <RentSpreeTableKung
              columns={desktopColumns}
              list={renterInsuranceList}
              rowRenderMobile={mobileRow}
              onClickRow={handleGoToDetail}
            />
            <Pagination
              pagination={pagination}
              handleChangePage={handleChangePage}
            />
            <TransparentStickyFooter>
              <NotifyButton
                shadowLevel2
                id="notifyRenterButtonMobile"
                onClick={handleGoToRequest}
              />
            </TransparentStickyFooter>
          </WhiteWrapper>
        </>
      )}
    </Container>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(RenterInsurance)
