import { useSelector } from "react-redux"
import { useLastLocation } from "react-router-last-location"
import { buildPath, query } from "@rentspree/path"
import { AGREEMENT_TYPE } from "containers/agreement/constants"
import { selectAgreementResponse } from "containers/agreement/selectors"
import { AGREEMENT_PAGE } from "constants/route"
import { isGotoNewLocation } from "./helper"
import { usePathname } from "./use-pathname"

export const useToAgreementPath = () => {
  const lastLocation = useLastLocation()
  const pathname = usePathname()
  const isAcceptTuAgreement = useSelector(selectAgreementResponse())

  if (isAcceptTuAgreement) return null

  return {
    isRedirect: isGotoNewLocation({
      pathname,
      destinationPath: AGREEMENT_PAGE,
      lastLocationPath: lastLocation?.pathname,
    }),
    path: {
      pathname: buildPath(AGREEMENT_PAGE, { type: AGREEMENT_TYPE.TU }),
      search: query.stringify({
        continuePath: pathname,
      }),
    },
  }
}
