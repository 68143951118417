import {
  IMPORT_CSV,
  BULK_CREATE_CONTACT_CALL,
  BULK_CREATE_CONTACT_STARTED,
  BULK_CREATE_CONTACT_SUCCESS,
  BULK_CREATE_CONTACT_CANCELLED,
  BULK_CREATE_CONTACT_CANCEL_COFIRMED,
  SET_CURRENT_CREATED_COUNT,
  CREATE_CHUNK_CONTACT_FAILURE,
  CLOSE_IMPORT_WIDGET,
  TRIGGER_MINIMIZE_WIDGET,
  SET_MAPPED_DATA,
  SET_COLUMN_ERROR,
  SET_HEADER,
  TOGGLE_ROW,
  CLEAR_IMPORT_CSV_PROGRESS,
  SET_FULL_VALIDATION_ERROR,
  SET_FILE_NAME,
} from './constants'

export const importCsv = payload => ({
  type: IMPORT_CSV,
  payload,
})

export const bulkCreateContact = payload => ({
  type: BULK_CREATE_CONTACT_CALL,
  payload,
})

export const bulkCreateContactStarted = payload => ({
  type: BULK_CREATE_CONTACT_STARTED,
  payload,
})

export const bulkCreateContactSuccess = payload => ({
  type: BULK_CREATE_CONTACT_SUCCESS,
  payload,
})

export const cancelBulkCreateContact = payload => ({
  type: BULK_CREATE_CONTACT_CANCELLED,
  payload,
})

export const confirmCancelBulkCreateContact = payload => ({
  type: BULK_CREATE_CONTACT_CANCEL_COFIRMED,
  payload,
})

export const setCurrentCreatedCount = payload => ({
  type: SET_CURRENT_CREATED_COUNT,
  payload,
})

export const createChunkContactFailure = payload => ({
  type: CREATE_CHUNK_CONTACT_FAILURE,
  payload,
})

export const clearImportCsvProgress = () => ({
  type: CLEAR_IMPORT_CSV_PROGRESS,
})

export const closeImportWidget = () => ({
  type: CLOSE_IMPORT_WIDGET,
})

export const triggerMinimizeWidget = () => ({
  type: TRIGGER_MINIMIZE_WIDGET,
})

export const setMappedData = payload => ({
  type: SET_MAPPED_DATA,
  payload,
})

export const setHeader = (value, index) => ({
  type: SET_HEADER,
  payload: {
    value,
    index,
  },
})

export const toggleRow = index => ({
  type: TOGGLE_ROW,
  payload: {
    index,
  },
})

export const setColumnError = (isError, index) => ({
  type: SET_COLUMN_ERROR,
  payload: {
    isError,
    index,
  },
})

export const setFullValidationError = (isError = true) => ({
  type: SET_FULL_VALIDATION_ERROR,
  payload: {
    isError,
  },
})

export const setFileName = payload => ({
  type: SET_FILE_NAME,
  payload,
})
