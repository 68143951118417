/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill'

// Import all the third party stuff
import { datadogRum } from '@datadog/browser-rum'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import FontFaceObserver from 'fontfaceobserver'
import * as Sentry from '@sentry/browser'
import { setOption } from '@rentspree/cookie'
import history from 'utils/history'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { muiTheme } from '@rentspree/component-2023.theme.mui'
// Import root app
// import App from "containers/App"

// Import Language Provider
import LanguageProvider from 'containers/language-provider'

// Load the favicon and the .htaccess file
// import "!file-loader?name=[name].[ext]!./images/favicon.ico"
import 'file-loader?name=.htaccess!./.htaccess' // eslint-disable-line import/extensions

// Import i18n messages
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { translationMessages } from './i18n'

import { COOKIE_OPTION } from './constants/cookie'
import pjson from '../package.json'
import {
  ENVIRONMENT,
  SENTRY_DSN,
  CI_COMMIT_SHA,
  API_URL,
  FILES_URL,
  USER_API_URL,
  DATADOG_SITE,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_TRACE_SAMPLE_RATE,
  DATADOG_SAMPLE_RATE,
  DATADOG_REPLAY_SAMPLE_RATE,
} from './env'
import { store } from './store'
import 'utils/feedback-collection/instance'

import Router from './router'

setOption(COOKIE_OPTION)

// Initiate Sentry
if (ENVIRONMENT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: ENVIRONMENT === 'development' ? CI_COMMIT_SHA : pjson.version,
    environment: ENVIRONMENT,
  })
}

// Initiate DataDog RUM
if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN && DATADOG_SITE) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: pjson.name,
    env: ENVIRONMENT,
    version: pjson.version,
    sessionSampleRate: DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_REPLAY_SAMPLE_RATE,
    traceSampleRate: DATADOG_TRACE_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      { match: API_URL, propagatorTypes: ['b3', 'b3multi'] },
      { match: FILES_URL, propagatorTypes: ['b3', 'b3multi'] },
      { match: USER_API_URL, propagatorTypes: ['b3', 'b3multi'] },
    ],
    excludedActivityUrls: [
      /^https:\/\/api\.amplitude\.com/,
      /^https:\/\/analytics\.google\.com/,
      /^https:\/\/.*\.clarity\.ms\/collect/,
      /^https:\/\/settings\.luckyorange\.net/,
    ],
    enableExperimentalFeatures: ['feature_flags'],
  })
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {})

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
})

const MOUNT_NODE = document.getElementById('app')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const appliedTheme = muiTheme
const theme = createTheme(appliedTheme)

const render = messages => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient} contextSharing>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <ThemeProvider theme={theme}>
            <Router history={history} />
          </ThemeProvider>
        </LanguageProvider>
      </Provider>
    </QueryClientProvider>,
    MOUNT_NODE,
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './router'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() =>
      Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/de.js')]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // get version from index.html
  const axios = require('axios') // eslint-disable-line global-require
  const PATH = require('./legacy/constants/route-consts') // eslint-disable-line global-require
  const versionMeta = document.querySelector("meta[name='version']").getAttribute('content')
  axios
    .get(PATH.VERSION_META_CHECK_API, {
      params: { t: new Date().getTime() },
    })
    .then(res => {
      if (res.status === 200) {
        if (res.data !== versionMeta) {
          Sentry.captureMessage(`Cache version mismatched: ${versionMeta} -> ${res.data}`)
        }
      } else {
        Sentry.captureMessage('Fetch version meta failed')
      }
    })
}
