/* eslint-disable jsx-a11y/anchor-is-valid */
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import MenuItem from '@mui/material/MenuItem'
import Button from '@rentspree/component-2023.components.atoms.button'
import Link from '@rentspree/component-2023.components.atoms.link'
import Select from '@rentspree/component-2023.components.atoms.select'
import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import InfoPanel from '@rentspree/component-2023.components.organisms.info-panel'
import dayjs from 'dayjs'
import { Field } from 'formik'
import { cloneDeep, get } from 'lodash'
import React from 'react'

import { DatePickerInput } from 'v3/components/date-picker-input'
import { MONTH_TO_MONTH_LABEL } from 'v3/containers/rent-payment/constants'

import { PANEL_TITLE_SCHEDULE_PAYMENT } from '../set-up-for-myself/steps/payment-details/constants'
import { getLastPaymentList, ordinalSuffix } from '../set-up-for-myself/steps/payment-details/utils'

import {
  PAYMENT_CATEGORIES,
  QUOTATION_LABELS,
  ADD_ANOTHER_RECURRING_PAYMENT_FORM_BTN_TEXT,
  DEFAULT_RECURRING_CATEGORY_PAYMENT_SECTION_TITLE,
  DEFAULT_ADDITIONAL_RECURRING_PAYMENT_SECTION_TITLE,
} from './constants'

import './styles.scss'

const BaseRecurringPaymentForm = props => {
  const {
    index,
    values,
    errors,
    touched,
    handleRemove,
    setFieldTouched,
    handleChange,
    setFieldValue,
    fixCategoryField,
    isMonthToMonthEnabled,
  } = props

  const getFieldKey = fieldName => `recurringPayments.${index}.${fieldName}`
  const getFieldValue = fieldName => get(values, getFieldKey(fieldName))

  const isShowCustomCategoryField = getFieldValue('category') === PAYMENT_CATEGORIES.OTHER

  const lastPaymentOptions = getFieldValue('startDate')
    ? getLastPaymentList(dayjs(getFieldValue('startDate')).date(), getFieldValue('startDate'))
    : []

  if (isMonthToMonthEnabled) {
    lastPaymentOptions.unshift({ label: MONTH_TO_MONTH_LABEL, value: MONTH_TO_MONTH_LABEL })
  }

  return (
    <>
      <div className="agt-init-payment-form-input-container">
        {!fixCategoryField && (
          <Select
            sx={{ fontSize: '16px' }}
            data-testid={getFieldKey('category')}
            label={QUOTATION_LABELS.CATEGORY}
            name={getFieldKey('category')}
            onChange={handleChange}
            onBlur={() => {
              setFieldTouched(getFieldKey('category'))
            }}
            error={
              get(touched, getFieldKey('category'), false) && get(errors, getFieldKey('category'))
            }
            helperText={
              get(touched, getFieldKey('category'), false) && get(errors, getFieldKey('category'))
            }
            value={getFieldValue('category')}
          >
            {Object.values(PAYMENT_CATEGORIES).map(value => (
              <MenuItem key={value} value={value}>
                <Typography variant="body-medium">{value}</Typography>
              </MenuItem>
            ))}
          </Select>
        )}
        {isShowCustomCategoryField && (
          <TextInput
            data-testid={getFieldKey('customCategory')}
            required
            label={QUOTATION_LABELS.OTHER_CATEGORY_TEXT}
            name={getFieldKey('customCategory')}
            onChange={handleChange}
            onBlur={() => {
              setFieldTouched(getFieldKey('customCategory'))
            }}
            error={
              get(touched, getFieldKey('customCategory'), false) &&
              get(errors, getFieldKey('customCategory'))
            }
            helperText={
              get(touched, getFieldKey('customCategory'), false) &&
              get(errors, getFieldKey('customCategory'))
            }
            value={getFieldValue('customCategory')}
          />
        )}
        <TextInput
          data-testid={getFieldKey('amount')}
          required
          label="Amount"
          name={getFieldKey('amount')}
          startAdornment="$"
          onChange={event => {
            setFieldValue(getFieldKey('amount'), event.target.value)
          }}
          onBlur={() => {
            setFieldTouched(getFieldKey('amount'))
          }}
          numberFormatOptions={{
            thousandSeparator: true,
            decimalScale: 2,
          }}
          error={get(touched, getFieldKey('amount'), false) && get(errors, getFieldKey('amount'))}
          helperText={
            get(touched, getFieldKey('amount'), false) && get(errors, getFieldKey('amount'))
          }
          value={getFieldValue('amount')}
        />
        <Field name={getFieldKey('startDate')}>
          {({ field }) => (
            <DatePickerInput
              {...field}
              value={field.value}
              data-testid={getFieldKey('startDate')}
              label="Start on"
              name={getFieldKey('startDate')}
              onDateChange={value => {
                setFieldValue(getFieldKey('startDate'), value)
                setFieldValue(getFieldKey('dueOn'), dayjs(value).date())
                setFieldValue(getFieldKey('endDate'), '')
              }}
              onBlur={event => {
                setFieldTouched(getFieldKey('startDate'))
                setFieldValue(getFieldKey('dueOn'), dayjs(event?.target?.value).date())
              }}
              error={
                get(touched, getFieldKey('startDate'), false) &&
                get(errors, getFieldKey('startDate'))
              }
              helperText={
                get(touched, getFieldKey('startDate'), false) &&
                get(errors, getFieldKey('startDate'))
              }
              minDate={dayjs()}
              required
            />
          )}
        </Field>
        <Field name={getFieldKey('endDate')}>
          {({ field }) => (
            <Select
              {...field}
              sx={{ fontSize: '16px' }}
              value={field.value}
              data-testid={getFieldKey('endDate')}
              label="End on"
              name={getFieldKey('endDate')}
              onChange={handleChange}
              onBlur={() => {
                setFieldTouched(getFieldKey('endDate'))
              }}
              error={
                get(touched, getFieldKey('endDate'), false) && get(errors, getFieldKey('endDate'))
              }
              helperText={
                get(touched, getFieldKey('endDate'), false) && get(errors, getFieldKey('endDate'))
              }
              required
              disabled={!getFieldValue('startDate')}
            >
              {Object.values(lastPaymentOptions).map(value => (
                <MenuItem key={value.label} value={value.value}>
                  <Typography variant="body-medium">{value.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        </Field>
        {!Number.isNaN(getFieldValue('dueOn')) && getFieldValue('dueOn') && (
          <div className="agt-init-payment-form-due" data-testid={getFieldKey('dueOn-description')}>
            <Typography variant="caption">Due on the </Typography>
            <Typography variant="caption" fontWeight={600}>
              {ordinalSuffix(getFieldValue('dueOn'))} of each month.
            </Typography>
          </div>
        )}
        {getFieldValue('dueOn') > 28 && (
          <InfoPanel severity="secondary">{PANEL_TITLE_SCHEDULE_PAYMENT}</InfoPanel>
        )}
      </div>
      <Button
        variant="text"
        color="secondary"
        onClick={handleRemove}
        className="agt-init-payment-form-remove-button"
        data-testid={getFieldKey('remove-button')}
      >
        <RemoveIcon />
        <Link color="black">Remove</Link>
      </Button>
    </>
  )
}

const RecurringPaymentForm = props => {
  const { values, touched, errors, setValues, setTouched, setErrors, isQuotationLimitExceeded } =
    props

  const recurringPaymentsValues = values?.recurringPayments || []
  const firstRentIndex = recurringPaymentsValues.findIndex(payment => payment.isInitiallyAdded)

  const handleRemove = index => {
    const updatedValue = cloneDeep(recurringPaymentsValues)
    updatedValue.splice(index, 1)
    setValues({
      ...values,
      recurringPayments: updatedValue,
    })

    const updatedError = cloneDeep(get(errors, 'recurringPayments'))
    if (updatedError) {
      updatedError.splice(index, 1)
      setErrors({
        ...errors,
        recurringPayments: updatedError,
      })
    }

    const updatedTouched = cloneDeep(get(touched, 'recurringPayments'))
    if (updatedTouched) {
      updatedTouched.splice(index, 1)
      setTouched({
        ...touched,
        recurringPayments: updatedTouched,
      })
    }
  }

  const handleAdd = () => {
    if (isQuotationLimitExceeded) return
    const updatedValue = cloneDeep(recurringPaymentsValues)
    updatedValue.push({
      category: '',
      customCategory: '',
      amount: '',
      startDate: values?.recurringPayments?.[0]?.startDate || '',
      endDate: values?.recurringPayments?.[0]?.endDate || '',
      dueOn: values?.recurringPayments?.[0]?.dueOn || '',
      repeat: 'monthly',
      key: Date.now(),
    })
    setValues({
      ...values,
      recurringPayments: updatedValue,
    })
  }

  return (
    <>
      {recurringPaymentsValues?.map((item, idx) => (
        <div className="agt-init-payment-form-section-container">
          {item.isInitiallyAdded && (
            <Typography variant="title">
              {DEFAULT_RECURRING_CATEGORY_PAYMENT_SECTION_TITLE}
            </Typography>
          )}
          {((firstRentIndex === -1 && idx === 0) || (firstRentIndex !== -1 && idx === 1)) && (
            <Typography variant="title">
              {DEFAULT_ADDITIONAL_RECURRING_PAYMENT_SECTION_TITLE}
            </Typography>
          )}
          <BaseRecurringPaymentForm
            {...props}
            key={item.key}
            index={idx}
            handleRemove={() => handleRemove(idx)}
            fixCategoryField={item.isInitiallyAdded && PAYMENT_CATEGORIES.RENT}
          />
        </div>
      ))}
      {!isQuotationLimitExceeded && (
        <Button
          className="agt-init-payment-form-add-button"
          variant="text"
          color="secondary"
          onClick={handleAdd}
          data-testid="recurring-payment-add-additional-button"
        >
          <AddIcon />
          <Link color="black">{ADD_ANOTHER_RECURRING_PAYMENT_FORM_BTN_TEXT}</Link>
        </Button>
      )}
    </>
  )
}

export default RecurringPaymentForm
