import React from "react"
import styled from "styled-components"
import { COLOR } from "../../../styles/settings"

const RadioButton = styled.i`
  width: 24px;
  height: 24px;
  font-size: 20px;
  padding: 2px;
  color: ${COLOR.newGrey};
  margin-right: 15px;
  ${props => props.blue && `color: ${COLOR.deepBlue}`};
`

const styledRadioButton = ({ checked }) =>
  checked ? (
    <RadioButton blue className="fas fa-dot-circle" />
  ) : (
    <RadioButton className="far fa-circle" />
  )
export default styledRadioButton
