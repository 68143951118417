import { buildPath } from "@rentspree/path"
import {
  GET_APPLY_LINK_API,
  PROPERTY_API_V2,
  PROPERTY_DETAIL_V2,
  REACTIVATE_PROPERTY_API,
  ZIPLOGIX_PROPERTY_API,
} from "constants/route"
import { propertyAPIInstance } from "./interceptors/property.api.interceptor"
import checkStatus from "./handlers/check-status"
import fetch from "../helpers/fetch-utils"

export const fetchZiplogixProperty = ziplogixPropertyId => bearer => {
  const url = buildPath(
    ZIPLOGIX_PROPERTY_API,
    { transactionId: ziplogixPropertyId },
    { getFullAddress: true },
  )
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const fetchProperty = (propertyId, options = {}) => {
  const url = buildPath(PROPERTY_DETAIL_V2, { propertyId }, options)
  return propertyAPIInstance.get(url)
}

export const fetchPropertyPermalink = (options = {}) => bearer => {
  const { screeningRequestId, ...query } = options
  const url = buildPath(GET_APPLY_LINK_API, { screeningRequestId }, query)
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const createProperty = (property = {}) => bearer => {
  const { id, ...rest } = property
  const data = {
    ...rest,
    transactionId: id,
  }

  return fetch(PROPERTY_API_V2, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(checkStatus)
}

export const reactivatePropertyApi = (propertyId = null) => bearer => {
  const url = buildPath(REACTIVATE_PROPERTY_API, { propertyId })

  return fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const deactivatePropertyApi = (propertyId = null) => bearer => {
  const url = buildPath(REACTIVATE_PROPERTY_API, { propertyId })

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}
