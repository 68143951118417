import produce from "immer"
import { TYPES } from "./constants"

export const initialState = {
  data: [],
}

/* eslint-disable no-param-reassign */
const badgeReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case TYPES.GET_NEW_BADGE_SUCCESS: {
        draft = {
          ...draft,
          data: payload,
        }
        break
      }
      default:
        break
    }
    return draft
  })

export default badgeReducer
