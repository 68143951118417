import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import { buildPath, query } from '@rentspree/path'
import React, { useMemo } from 'react'

import { RENT_PAYMENT_REQUEST_PAGE } from 'constants/route'
import tracker from 'tracker'
import { RENT_PAYMENT as RENT_PAYMENT_EVENTS } from 'tracker/const'
import { useCreatePropertyMutation } from 'v3/containers/rent-payment/apis/property/create-property'
import { useRentPaymentModalState } from 'v3/containers/rent-payment/shared/hooks'

import { TITLE, BUTTON_TYPE_SUBMIT } from './constants'
import { CreatePropertyForm } from './form'

export const CreatePropertyModal = ({ history, location }) => {
  const {
    createPropertyModal: { isOpen, close },
  } = useRentPaymentModalState()

  const createPropertyMutation = useCreatePropertyMutation()

  const queryParams = useMemo(() => query.parse(location.search), [location.search])

  const handleCloseModal = () => {
    close()

    tracker.trackEvent(RENT_PAYMENT_EVENTS.EVENT_NAME.CANCEL_ADD_NEW_PROPERTY, {
      user_type: RENT_PAYMENT_EVENTS.EVENT_PROPERTY.USER_TYPE.LANDLORD,
      click_from: RENT_PAYMENT_EVENTS.EVENT_PROPERTY.CLICK_FROM.RENT_PAYMENT,
    })
  }

  const handleSubmit = async values => {
    const { street, unitNumber, city, state, zipcode, propertyType } = values
    const address = {
      street,
      unitNumber,
      city,
      state,
      zip: zipcode,
      isNewProperty: true,
      propertyType,
    }

    const property = await createPropertyMutation.mutateAsync({ address })

    history.push(
      buildPath(RENT_PAYMENT_REQUEST_PAGE, {}, { ...queryParams, property: property._id }),
    )

    close()

    // 2 duplicated events, to be clarified
    tracker.trackEvent(RENT_PAYMENT_EVENTS.EVENT_NAME.ADD_NEW_PROPERTY)
    tracker.trackEvent(RENT_PAYMENT_EVENTS.EVENT_NAME.ADD_NEW_PROPERTY_SUCCESS, {
      user_type: RENT_PAYMENT_EVENTS.EVENT_PROPERTY.USER_TYPE.LANDLORD,
      click_from: RENT_PAYMENT_EVENTS.EVENT_PROPERTY.CLICK_FROM.RENT_PAYMENT,
    })
  }

  return (
    <RentSpreeBaseModal
      id="rentPaymenCreatePropertyModal"
      rootId="#app"
      modalSize="medium"
      padding="25px"
      isOpen={isOpen}
      onClose={handleCloseModal}
      closeButton={!createPropertyMutation.isLoading}
      shouldCloseOnOverlayClick={false}
    >
      <S24 mSize="24px" margin="0 0 25px" lineHeight="32px">
        {TITLE}
      </S24>
      <CreatePropertyForm
        continueText={BUTTON_TYPE_SUBMIT}
        hideIconNextButton
        hideBackButton
        isCreatingProperty={createPropertyMutation.isLoading}
        onHandleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
      />
    </RentSpreeBaseModal>
  )
}
