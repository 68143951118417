import React from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import TextInput from '@rentspree/component-v2/dist/input/text'
import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import Button from '@rentspree/component-v2/dist/button'
import TextArea from '@rentspree/component-v2/dist/input/textarea'
import { withFormik } from 'formik'

import { InputGroup, CheckboxGroup, ButtonGroup } from 'components/shared/form'
import { ComponentWithHover } from 'components/shared/hover-component'
import { APPLICATION_TYPE } from 'constants/application-type'
import { generateLandlordFullName } from 'containers/reports/helper'
import { shareReportFormSchema } from './schema'
import { REPORT_UNAVAILABLE_MESSAGE } from '../constants'

export const Form = ({
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  errors,
  screeningOption: { credit_report: creditReport },
  reportAvailable = {},
  applicationType,
  landlordInformation = {},
  onClickCancel,
  isExpired,
  isIDMAExpired,
  expiredDate,
  setFormValues,
}) => {
  const isScreeningReportAvailable =
    creditReport && reportAvailable.screeningReport && !isExpired && !isIDMAExpired

  const landlordFullName =
    generateLandlordFullName(
      get(landlordInformation, 'firstName', ''),
      get(landlordInformation, 'lastName', ''),
    ) || 'Landlord'

  React.useEffect(() => {
    setFormValues({
      ...values,
      application: !!reportAvailable.application,
      screeningReport: isScreeningReportAvailable,
      referenceCheck: !!reportAvailable.referenceCheck,
      otherDocs: !!reportAvailable.otherDocs,
    })
  }, [isScreeningReportAvailable, reportAvailable])

  const handleCheckbox = field => () => {
    setFieldValue(field, !values[field])
  }

  const handleShareWithLandlordCheckbox = () => {
    const toggledValue = !values.shareWithLandlord
    setFieldValue('shareWithLandlord', toggledValue)
    if (toggledValue) {
      setFieldValue('email', landlordInformation.email)
    } else {
      setFieldValue('email', '')
    }
  }

  const handleEmailInputChanged = e => {
    if (e.target.value === landlordInformation.email) {
      setFieldValue('shareWithLandlord', true)
    } else {
      setFieldValue('shareWithLandlord', false)
    }
    handleChange(e)
  }

  const getScreeningReportUnavailableMessage = () => {
    if (!creditReport) return REPORT_UNAVAILABLE_MESSAGE.SCREENING_REPORT.NOT_REQUEST
    if (isExpired) return `${REPORT_UNAVAILABLE_MESSAGE.SCREENING_REPORT.EXPIRED} on ${expiredDate}`
    if (isIDMAExpired) return REPORT_UNAVAILABLE_MESSAGE.SCREENING_REPORT.IDMA_EXPIRED
    if (!reportAvailable.screeningReport)
      return REPORT_UNAVAILABLE_MESSAGE.SCREENING_REPORT.ALREADY_SHARED
    return null
  }

  const isSubmitDisabled =
    isEmpty(values.email) ||
    !isEmpty(errors) ||
    !(values.application || values.screeningReport || values.referenceCheck || values.otherDocs)
  let applicationHoverMessage = null
  if (applicationType === APPLICATION_TYPE.CAR_LRA) {
    applicationHoverMessage = REPORT_UNAVAILABLE_MESSAGE.LRA_APPLICATION
  } else if (!reportAvailable.application) {
    applicationHoverMessage = REPORT_UNAVAILABLE_MESSAGE.APPLICATION
  }

  return (
    <form onSubmit={handleSubmit}>
      {landlordInformation.isAssociationContact && (
        <Checkbox
          simple
          id="share-with-landlord-checkbox"
          name="shareWithLandlord"
          label={`Share with ${landlordFullName}`}
          fontSize="16px"
          isSelected={values.shareWithLandlord || values.email === landlordInformation.email}
          onClick={handleShareWithLandlordCheckbox}
          mMargin="0 0 15px 0"
          margin="0 0 15px 0"
        />
      )}
      <InputGroup full bgTransparent marginBottom="25px">
        <TextInput
          id="share-report-form-email"
          name="email"
          label="Recipient's email"
          value={values.email}
          onChange={handleEmailInputChanged}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          labelStyle={{ fontWeight: 600 }}
        />
        {!landlordInformation.isAssociationContact && (
          <Checkbox
            simple
            id="add-ll-to-property-from-share-report-checkbox"
            name="addLandlordToProperty"
            label="Assign as landlord"
            fontSize="16px"
            isSelected={values.addLandlordToProperty}
            onClick={handleCheckbox('addLandlordToProperty')}
          />
        )}
      </InputGroup>
      <CheckboxGroup label="Share the following documents:">
        <InputGroup half left bgTransparent mMarginBottom="10px">
          <ComponentWithHover
            id="share-report-form-application-hover-container"
            width="210px"
            fontSize="12px"
            fontColor="black"
            position={{
              bottom: '-5px',
              right: '172px',
              transform: 'translate(-20px, -120%)',
            }}
            hoverMessage={applicationHoverMessage}
          >
            <Checkbox
              simple
              id="share-report-form-application"
              name="application"
              label="Rental Application"
              fontSize="16px"
              isSelected={values.application}
              onClick={handleCheckbox('application')}
              disabled={!!applicationHoverMessage}
              mMargin="5px 0"
            />
          </ComponentWithHover>
        </InputGroup>
        <InputGroup half right bgTransparent mMarginBottom="10px">
          <ComponentWithHover
            width="210px"
            id="share-report-form-screening-report-hover-container"
            fontSize="12px"
            fontColor="black"
            position={{
              bottom: '-5px',
              right: '172px',
              transform: 'translate(-20px, -120%)',
            }}
            hoverMessage={getScreeningReportUnavailableMessage()}
          >
            <Checkbox
              simple
              id="share-report-form-screening-report"
              name="screeningReport"
              label="Screening Report(s)"
              fontSize="16px"
              isSelected={values.screeningReport}
              onClick={handleCheckbox('screeningReport')}
              disabled={!isScreeningReportAvailable}
              mMargin="5px 0"
            />
          </ComponentWithHover>
        </InputGroup>
        <InputGroup half left bgTransparent mMarginBottom="10px">
          <ComponentWithHover
            width="210px"
            id="share-report-form-other-docs-hover-container"
            fontSize="12px"
            fontColor="black"
            position={{
              bottom: '-5px',
              right: '172px',
              transform: 'translate(-20px, -120%)',
            }}
            hoverMessage={reportAvailable.otherDocs ? null : REPORT_UNAVAILABLE_MESSAGE.OTHER_DOCS}
          >
            <Checkbox
              simple
              id="share-report-form-other-docs"
              name="otherDocs"
              label="Other Document(s)"
              fontSize="16px"
              isSelected={values.otherDocs}
              onClick={handleCheckbox('otherDocs')}
              disabled={!reportAvailable.otherDocs}
              mMargin="5px 0"
            />
          </ComponentWithHover>
        </InputGroup>
        <InputGroup half right bgTransparent mMarginBottom="10px">
          <ComponentWithHover
            width="210px"
            id="share-report-form-ref-check-hover-container"
            fontSize="12px"
            fontColor="black"
            position={{
              bottom: '-5px',
              right: '172px',
              transform: 'translate(-20px, -120%)',
            }}
            hoverMessage={
              reportAvailable.referenceCheck ? null : REPORT_UNAVAILABLE_MESSAGE.REFERENCE_CHECKS
            }
          >
            <Checkbox
              simple
              id="share-report-form-ref-checks"
              name="referenceCheck"
              label="Reference Checks"
              fontSize="16px"
              isSelected={values.referenceCheck}
              onClick={handleCheckbox('referenceCheck')}
              disabled={!reportAvailable.referenceCheck}
              mMargin="5px 0"
            />
          </ComponentWithHover>
        </InputGroup>
      </CheckboxGroup>
      <InputGroup full bgTransparent marginBottom="15px">
        <TextArea
          id="share-report-form-agent-message"
          name="agentMessage"
          label="Add a message to the recipient (optional)"
          value={values.agentMessage}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.agentMessage && errors.agentMessage}
          labelStyle={{ fontWeight: 600 }}
          textAreaStyle={{ resize: 'none' }}
        />
      </InputGroup>

      <ButtonGroup>
        <Button
          google
          small
          semiBold
          fontSize="16px"
          id="share-report-form-cancel-btn"
          className="left-btn"
          text="Cancel"
          type="button"
          onClick={onClickCancel}
        />
        <BlueGradientButton
          small
          semiBold
          fontSize="16px"
          id="share-report-form-submit-btn"
          className="right-btn"
          text="Next"
          type="submit"
          disabled={isSubmitDisabled}
        />
      </ButtonGroup>
    </form>
  )
}

const ShareReportForm = withFormik({
  mapPropsToValues: props => shareReportFormSchema(props).mapper,
  validationSchema: props => shareReportFormSchema(props).validation,
  handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: 'ShareReportForm',
})(Form)

export default ShareReportForm
