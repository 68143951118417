import { useSelector } from "react-redux"
import { useLastLocation } from "react-router-last-location"
import { buildPath } from "@rentspree/path"
import { usePathname, useParams } from "hooks"
import { RENTAL_STATUS } from "legacy/constants/rental-consts"
import { selectRentalDetail } from "containers/reports/selectors"
import { selectError } from "containers/tenant-screening/detail/selectors"
import {
  TENANT_SCREENING_DETAIL,
  TENANT_SCREENING_PENDING,
} from "constants/route"
import { isGotoNewLocation } from "hooks/helper"

const { SUBMITTED, PROPERTY_REQUIRED } = RENTAL_STATUS

export const useToPendingPath = () => {
  const lastLocation = useLastLocation()
  const { status } = useSelector(selectRentalDetail)
  const error = useSelector(selectError)
  const pathname = usePathname()
  const { submissionParticipantId } = useParams(TENANT_SCREENING_DETAIL)
  const isRedirect = isGotoNewLocation({
    pathname,
    destinationPath: TENANT_SCREENING_PENDING,
    lastLocationPath: lastLocation?.pathname,
  })

  if ([SUBMITTED, PROPERTY_REQUIRED].includes(status) || !error) return null

  return {
    isRedirect,
    path: buildPath(TENANT_SCREENING_PENDING, {
      submissionParticipantId,
    }),
  }
}
