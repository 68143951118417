import styled from "styled-components"
import { COLOR } from "styles/settings"

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 25px 30px;
  background: white;
  margin: auto;
  color: ${COLOR.textBlack};
  @media (max-width: 991px) {
    padding: 15px 20px 15px;
    margin: 5px;
  }
  @media (min-width: 992px) {
    width: 980px;
  }
`

export const LeaseDetailsContent = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`

export const LeaseDetailsCol = styled.div`
  min-width: ${props => (props.width ? props.width : "50%")};
  @media (max-width: 991px) {
    min-width: 100%;
  }
`

export const InfoTextItem = styled.div`
  display: flex;
  flex-direction: row;
  p {
    display: flex;
    align-items: flex-end;
    color: ${COLOR.textBlack};
  }
  p:nth-child(1) {
    width: ${props => (props.width ? props.width : "100px")};
    margin-bottom: 5px;
    @media (max-width: 991px) {
      width: 135px;
      margin-bottom: 0;
    }
  }
  p:nth-child(2) {
    width: ${props => (props.width ? props.width : "80px")};
    white-space: nowrap;
    margin-bottom: 5px;
    @media (max-width: 991px) {
      margin-bottom: 0;
    }
  }
`

export const Header = styled.div`
  width: 980px;
  padding: 25px 30px;
  margin: auto;
`
export const GreyWrapper = styled.div`
  background: ${COLOR.grayLight};
  display: flex;

  @media (max-width: 991px) {
    display: none;
  }
`
