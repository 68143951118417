import { useInjectReducer } from 'utils/inject-reducer'

import { PAYMENT_INCENTIVE_INFORMATION_PAGE_REDUCER_KEY } from './constants'
import paymentIncentiveInformationPageReducer from './reducers'

export const usePaymentIncentiveInformationPageReducer = () => {
  return useInjectReducer({
    key: PAYMENT_INCENTIVE_INFORMATION_PAGE_REDUCER_KEY,
    reducer: paymentIncentiveInformationPageReducer,
  })
}
