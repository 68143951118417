import { buildPath } from '@rentspree/path'
import React from 'react'

import { Landing } from 'components/landing'
import { AUTH_WEB_VERIFICATION, AUTH_WEB_VERIFICATION_V2 } from 'env'
import { useQueryStrings } from 'hooks'
import { useStatsigFeatureGate } from 'hooks/use-statsig-feature-flag'
import verifyPersonalImg from 'images/verify-personal-v2.svg'
import locationHref from 'legacy/helpers/location-href'
import tracker from 'tracker'
import { EVENT_REPORT, EVENT_TENANT_SCREENING, ID_VERIFICATION } from 'tracker/const'
import { COMBINED_AGREEMENT_IDV_ADDRESS } from 'utils/feature-flag/constants'

import { UTM_CAMPAIGN, UTM_SOURCE } from './constants'

const { enterPage, clickStart, clickStartIDV } = ID_VERIFICATION

export const Verification = ({ heightCal, source }) => {
  const { utm_source: utmSource, utm_campaign: utmCampaign } = useQueryStrings()

  React.useEffect(() => {
    tracker.trackEvent(enterPage, { ...(source && { source }) })
  }, [])

  const { isEnabled: isCombinedAgreementIdvFeatureEnabled } = useStatsigFeatureGate(
    COMBINED_AGREEMENT_IDV_ADDRESS,
  )

  React.useEffect(() => {
    if (utmSource === UTM_SOURCE.HUBSPOT_EMAIL && isCombinedAgreementIdvFeatureEnabled) {
      let from
      switch (utmCampaign) {
        case UTM_CAMPAIGN.DOCUMENT_REQUEST:
          from = EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.PRO_DOC_UPLOAD
          break
        case UTM_CAMPAIGN.REFERENCE_CHECKS:
          from = EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.PRO_REF_CHECK
          break
        default:
          from = EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.HUBSPOT_EMAIL
      }

      tracker.trackEvent(EVENT_REPORT.clickToViewApplicantInfo, {
        from,
        completed_idv: false,
      })
    }
  }, [utmSource, utmCampaign, isCombinedAgreementIdvFeatureEnabled])

  const handleStartVerification = () => {
    if (isCombinedAgreementIdvFeatureEnabled) {
      tracker.trackEvent(clickStartIDV, { ...(source && { source }) })
      locationHref(
        buildPath(AUTH_WEB_VERIFICATION_V2, null, {
          continue: window.location.href,
        }),
      )
    } else {
      tracker.trackEvent(clickStart, { ...(source && { source }) })
      locationHref(
        buildPath(AUTH_WEB_VERIFICATION, null, {
          continue: window.location.href,
        }),
      )
    }
  }

  return (
    <Landing
      imgMobileDisplay="block"
      title="Identity verification"
      titleMarginTop="48"
      titleFontFamily="Inter"
      imgWidth="184px"
      imgHeight="115px"
      description="To protect applicant information, we’ll verify your identity before you can access information and TransUnion reports."
      description2="You’ll only need to get verified once."
      descMaxWidth="427"
      descFontFamily="Inter"
      descFontSize="16"
      descMarginTop="16"
      descMarginBottom="24"
      buttonId="identity-verification-page"
      buttonName="Start verification"
      buttonWidth="157"
      isContainedButton
      buttonType="contained"
      imgSrc={verifyPersonalImg}
      onClick={handleStartVerification}
      propsWrapper={{
        heightCal,
        withBreadcrumb: false,
        withNewIALandingPage: true,
      }}
    />
  )
}

export default Verification
