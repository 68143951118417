import get from "lodash/get"
import nth from "lodash/nth"
import { colorShades, COLOR } from "styles/settings"

/**
 * Get the color from the color shade of the recipient
 * based on the position recipient in the list
 * @param {number} index index of the color (max 9)
 * @param {string} prop type of the color you want (eg. background, content)
 * @returns {string} color hex or RGB value
 */
export const getColor = (index, prop) =>
  get(nth(colorShades, index % (colorShades.length - 1)), prop, COLOR.white)
