import get from "lodash/get"
import { createSelector } from "reselect"

const getMessaging = state => get(state, "messaging", {})

const selectMessaging = createSelector(
  getMessaging,
  state => state,
)

export const selectIsLoading = createSelector(
  selectMessaging,
  state => get(state, "isLoading", {}),
)

export const selectIsError = createSelector(
  selectMessaging,
  state => get(state, "isError", {}),
)

export const selectIsShowNewMessageModal = createSelector(
  selectMessaging,
  state => get(state, "isShowNewMessageModal"),
)

export const selectIsConversationStarted = createSelector(
  selectMessaging,
  state => get(state, "isConversationStarted"),
)

export const selectCurrentConversationId = createSelector(
  selectMessaging,
  state => get(state, "currentConversationId"),
)

export const selectIsTalkJsReady = createSelector(
  selectMessaging,
  state => get(state, "isTalkJsReady"),
)

export const selectIsUnreadNotificationReady = createSelector(
  selectMessaging,
  state => get(state, "isUnreadNotificationReady"),
)

export const selectSignature = createSelector(
  selectMessaging,
  state => get(state, "signature"),
)

export const selectUnreadCountText = createSelector(
  selectMessaging,
  state => get(state, "unreadCountText"),
)
