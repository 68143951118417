import generateApiCall from 'utils/generate-api-call'

import {
  EDIT_ONE_TIME_PAYMENT,
  EDIT_ONE_TIME_PAYMENT_FAILED,
  EDIT_ONE_TIME_PAYMENT_REQUESTED,
  EDIT_ONE_TIME_PAYMENT_SUCCEEDED,
} from './constants'

export const editOneTimePayment = payload => ({
  type: EDIT_ONE_TIME_PAYMENT,
  payload,
})

export const editOneTimePaymentAPI = generateApiCall([
  EDIT_ONE_TIME_PAYMENT_REQUESTED,
  EDIT_ONE_TIME_PAYMENT_SUCCEEDED,
  EDIT_ONE_TIME_PAYMENT_FAILED,
])
