import { buildPath } from '@rentspree/path'
import { takeLatest, all, put, call } from 'redux-saga/effects'
import { apiInstance } from 'utils/api-interceptor'
import get from 'lodash/get'
import {
  createAssociationContactApiState,
  editAssociationContactApiState,
  getAssociationContactApiState,
} from './actions'
import {
  CREATE_ASSOCIATION_CONTACT_CALL,
  EDIT_ASSOCIATION_CONTACT_CALL,
  GET_ASSOCIATION_CONTACT_CALL,
  ASSOCIATION_CREATE_CONTACT_API,
  ASSOCIATION_PUT_CONTACT_API,
  ASSOCIATION_GET_CONTACT_API,
  ASSOCIATION_CONTACT_ROLE,
  ASSOCIATION_CONTACT_SOURCE,
  CREATE_ASSOCIATION_CONTACT_SUCCESS,
  EDIT_ASSOCIATION_CONTACT_SUCCESS,
} from './constants'

export const createAssociationContactApi = ({
  propertyId,
  role = ASSOCIATION_CONTACT_ROLE.LANDLORD,
  source = ASSOCIATION_CONTACT_SOURCE.PROPERTY_DETAIL,
  ...contact
}) =>
  apiInstance.post(
    buildPath(ASSOCIATION_CREATE_CONTACT_API, {
      propertyId,
    }),
    { ...contact, role, source },
  )

export const getAssociationContactApi = ({ propertyId }) =>
  apiInstance.get(
    buildPath(ASSOCIATION_GET_CONTACT_API, {
      propertyId,
    }),
  )
export const putAssociationContactApi = ({
  propertyId,
  contactId,
  role = ASSOCIATION_CONTACT_ROLE.LANDLORD,
  source = ASSOCIATION_CONTACT_SOURCE.PROPERTY_DETAIL,
  ...contact
}) =>
  apiInstance.put(
    buildPath(ASSOCIATION_PUT_CONTACT_API, {
      propertyId,
      contactId,
    }),
    { ...contact, role, source },
  )
export function* getAssociationContactSaga({ payload }) {
  try {
    yield put(getAssociationContactApiState.request())
    const response = yield call(getAssociationContactApi, payload.contact)
    const contactsWithPropertyId = response.map(contact => ({
      ...contact,
      propertyId: get(payload, 'contact.propertyId'),
    }))
    yield put(getAssociationContactApiState.success(contactsWithPropertyId))
  } catch (err) {
    yield put(getAssociationContactApiState.failure())
  }
}

export function* createAssociationContactSaga({ payload }) {
  try {
    yield put(createAssociationContactApiState.request())
    yield call(createAssociationContactApi, payload.contact)
     // When success, we dispatch success action with payload instead of response because we will use payload to fetch data again, not update the reducer with response as usual
    yield put(createAssociationContactApiState.success(payload))
  } catch (err) {
    yield put(createAssociationContactApiState.failure())
  }
}

export function* editAssociationContactSaga({ payload }) {
  try {
    yield put(editAssociationContactApiState.request())
    yield call(putAssociationContactApi, payload.contact)
    // When success, we dispatch success action with payload instead of response because we will use payload to fetch data again, not update the reducer with response as usual
    yield put(editAssociationContactApiState.success(payload))
  } catch (err) {
    yield put(editAssociationContactApiState.failure())
  }
}

export function* watchGetAssociationContact() {
  yield takeLatest(
    [
      GET_ASSOCIATION_CONTACT_CALL,
      CREATE_ASSOCIATION_CONTACT_SUCCESS,
      EDIT_ASSOCIATION_CONTACT_SUCCESS,
    ],
    getAssociationContactSaga,
  )
}

export function* watchCreateAssociationContact() {
  yield takeLatest(CREATE_ASSOCIATION_CONTACT_CALL, createAssociationContactSaga)
}

export function* watchEditAssociationContact() {
  yield takeLatest(EDIT_ASSOCIATION_CONTACT_CALL, editAssociationContactSaga)
}

export function* rootSaga() {
  yield all([
    watchGetAssociationContact(),
    watchCreateAssociationContact(),
    watchEditAssociationContact(),
  ])
}

export default rootSaga
