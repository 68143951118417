import React from "react"
import styled from "styled-components"
import S16 from "@rentspree/component-v2/dist/typography/s16"
import { RedText } from "@rentspree/component-v2/dist/shared-components"

const CheckboxGroup = styled(({ className, label, children, isRequired }) => (
  <div className={className}>
    <S16 margin="0px 0px 10px 0px">
      {label}
      {isRequired && <RedText> *</RedText>}
    </S16>
    <div className="checkbox-group-child">{children}</div>
  </div>
))`
  width: 100%;
  margin-bottom: ${({ mb = 25 }) => mb}px;
  .checkbox-group-child {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`

export default CheckboxGroup
