import React from 'react'
import { FormWrapper, StatusFilterBox } from 'components/molecules/filter-bar/filter-bar'
import PropTypes from 'prop-types'
import FilterSelect from 'components/atoms/filter-dropdown/filter-select'
import FilterMobile from 'components/atoms/filter-mobile/filter-mobile'
import DebounceInput from 'components/atoms/debounce-input/debounce-input'
import { FONTAWESOME_HEXCODE_ICONS } from 'styles/settings'
import TooltipWithTitleAndSwitchButton from 'components/molecules/tooltip-with-title-and-switch-button'

const FilterBarForPropertyApplicationList = ({
  status,
  options,
  handleFilter,
  placeholder,
  search,
  handleSearch,
  widthFilter,
  handleSwitchBtn,
  switchBtnTooltip,
  switchBtnChecked,
  noIconOnMobileSelected,
}) => (
  <FormWrapper id="statusFilterForm">
    <StatusFilterBox id="statusFilterBox" widthFilter={widthFilter}>
      <FilterMobile
        status={status}
        onChangeStatus={handleFilter}
        options={options}
        noIconOnMobileSelected={noIconOnMobileSelected}
      />
      <FilterSelect
        id="propertyStatusFilterSelect"
        name="property-status-filter"
        status={status}
        options={options}
        onChange={handleFilter}
        iconSmall
      />
    </StatusFilterBox>
    <DebounceInput
      id="searchInput"
      type="text"
      name="search"
      placeholder={placeholder}
      placeholderIcon={FONTAWESOME_HEXCODE_ICONS.SEARCH}
      value={search}
      onChange={handleSearch}
    />
    <TooltipWithTitleAndSwitchButton
      id="filterBarSwitchComponent"
      checked={switchBtnChecked}
      tooltip={switchBtnTooltip}
      onChange={handleSwitchBtn}
      hideMobile
    />
  </FormWrapper>
)

FilterBarForPropertyApplicationList.propTypes = {
  status: PropTypes.string,
  options: PropTypes.array,
  handleFilter: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  handleSearch: PropTypes.func,
  handleSwitchBtn: PropTypes.func,
  switchBtnTooltip: PropTypes.object,
  switchBtnChecked: PropTypes.bool,
}

FilterBarForPropertyApplicationList.defaultProps = {
  options: [],
  handleFilter: () => {},
  handleSearch: () => {},
  handleSwitchBtn: () => {},
}

export default FilterBarForPropertyApplicationList
