import pick from "lodash/pick"
import createAction from "../apis/utils/create-action"
import * as Types from "../constants/action-types"
import * as api from "../apis/property.api"
import * as ERROR from "../constants/error-messages"
import { ALERT_PRESET } from "../libs/sweet-alert/sweet-alert"

export const fetchZiplogixProperty = createAction(
  api.fetchZiplogixProperty,
  [
    Types.GET_ZIPLOGIX_PROPERTY_REQUEST,
    Types.GET_ZIPLOGIX_PROPERTY_SUCCESS,
    Types.GET_ZIPLOGIX_PROPERTY_FAILED,
  ],
  res => {
    const data = res || {}
    return data.result
  },
)

export const getProperty = createAction(
  api.fetchProperty,
  [
    Types.GET_PROPERTY_REQUEST,
    Types.GET_PROPERTY_SUCCESS,
    Types.GET_PROPERTY_FAILED,
  ],
  res => ({ data: res }),
)

export const clearDisabledFeatures = () => ({
  type: Types.CLEAR_DISABLED_FEATURES,
})

export const getPropertyPermalink = createAction(
  api.fetchPropertyPermalink,
  [
    Types.GET_PERMALINK_REQUEST,
    Types.GET_PERMALINK_SUCCESS,
    Types.GET_PERMALINK_FAILED,
  ],
  res => {
    const data = pick(res, ["shortenLink", "permalink"], {}) || {}
    return { data }
  },
)

export const fetchCreateProperty = createAction(
  api.createProperty,
  [
    Types.CREATE_PROPERTY_REQUEST,
    Types.CREATE_PROPERTY_SUCCESS,
    Types.CREATE_PROPERTY_FAILED,
  ],
  data => ({ data }),
)

export const getZiplogixProperty = ziplogixPropertyId => (dispatch, getState) =>
  fetchZiplogixProperty(ziplogixPropertyId)(dispatch, getState).then(res => {
    if (res.success === false) {
      dispatch({
        type: Types.OPEN_SWEET_ALERT,
        preset: ALERT_PRESET.ERROR,
        option: {
          title: ERROR.REQUEST.GET_ZIPLOGIX_PROPERTY.TITLE,
          content: {
            element: "div",
            attributes: {
              innerHTML: ERROR.REQUEST.GET_ZIPLOGIX_PROPERTY.MESSAGE,
            },
          },
        },
      })
    }
    return res
  })

export const createProperty = property => (dispatch, getState) =>
  fetchCreateProperty(property)(dispatch, getState).then(res => {
    if (res.meta && res.meta.name !== "ValidationError") {
      dispatch({
        type: Types.OPEN_SWEET_ALERT,
        preset: ALERT_PRESET.ERROR,
        option: {
          title: ERROR.REQUEST.CREATE_PROPERTY.TITLE,
          content: {
            element: "div",
            attributes: {
              innerHTML: ERROR.REQUEST.CREATE_PROPERTY.MESSAGE,
            },
          },
        },
      })
    }
    return res
  })

export const fetchReactivateProperty = createAction(
  api.reactivatePropertyApi,
  [
    Types.REACTIVATE_PROPERTY_REQUEST,
    Types.REACTIVATE_PROPERTY_SUCCESS,
    Types.REACTIVATE_PROPERTY_FAILED,
  ],
  data => data,
)

export const reactivateProperty = propertyId => (dispatch, getState) => {
  dispatch({ type: Types.SHOW_REACTIVATE_PROPERTY_MODAL, isShow: false })
  return fetchReactivateProperty(propertyId)(dispatch, getState)
    .then(data => data)
    .catch(() => {
      dispatch({
        type: Types.OPEN_SWEET_ALERT,
        preset: ALERT_PRESET.ERROR,
        option: {
          title: ERROR.PROPERTY.REACTIVATE.TITLE,
          text: ERROR.PROPERTY.REACTIVATE.MESSAGE,
        },
      })
    })
}

export const clearProperty = () => ({
  type: Types.CLEAR_PROPERTY,
})
export const clearCreatePropertyNoti = () => ({
  type: Types.CLEAR_CREATE_PROPERTY_NOTI,
})

export const fetchDeactivateProperty = createAction(
  api.deactivatePropertyApi,
  [
    Types.DEACTIVATE_PROPERTY_REQUEST,
    Types.DEACTIVATE_PROPERTY_SUCCESS,
    Types.DEACTIVATE_PROPERTY_FAILED,
  ],
  res => res,
)
export const deactivateProperty = propertyId => (dispatch, getState) => {
  dispatch({ type: Types.SHOW_DEACTIVATE_PROPERTY_MODAL, isShow: false })
  return fetchDeactivateProperty(propertyId)(dispatch, getState)
    .then(data => data)
    .catch(() => {
      dispatch({
        type: Types.OPEN_SWEET_ALERT,
        preset: ALERT_PRESET.ERROR,
        option: {
          title: ERROR.PROPERTY.DEACTIVATE.TITLE,
          text: ERROR.PROPERTY.DEACTIVATE.MESSAGE,
        },
      })
    })
}
