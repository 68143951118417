import generateApiCall from 'utils/generate-api-call'

import {
  MARK_AS_PAID,
  MARK_AS_PAID_FAILED,
  MARK_AS_PAID_REQUESTED,
  MARK_AS_PAID_SUCCEEDED,
  RESET_MARK_AS_PAID_STATUS,
} from './constants'

export const markAsPaid = payload => ({
  type: MARK_AS_PAID,
  payload,
})

export const resetMarkAsPaidStatus = () => ({
  type: RESET_MARK_AS_PAID_STATUS,
})

export const markAsPaidAPI = generateApiCall([
  MARK_AS_PAID_REQUESTED,
  MARK_AS_PAID_SUCCEEDED,
  MARK_AS_PAID_FAILED,
])
