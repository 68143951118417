import { styled, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(() => ({
  margin: '10px 0px!important',
  marginBottom: '16px',

  backgroundColor: 'white',

  '& input': {
    height: '3rem',
    padding: '16px',
    fontSize: '2rem',
    fontFamily: 'Inter',
    borderRadius: '8px',
    border: '1px solid #2222221f',
  },

  '& label': {
    fontSize: '2rem',
    backgroundColor: 'white',
    padding: '0 5px',
    fontFamily: 'Inter',
  },
}))
