import get from "lodash/get"
import * as Sentry from "@sentry/browser"

export const sentUserToSentry = user => {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: get(user, "_id"),
      email: get(user, "email"),
    })
  })
}
