import React from "react"
import { matchPath } from "react-router"
import { APPLICATION_LIST } from "constants/route"
import { withPopper } from "./connect"
import { PAGE } from "./const"

export class Popper extends React.Component {
  componentDidMount() {
    const { actions, page, project, forceTrigger, path } = this.props
    if (forceTrigger) {
      actions.forceTrigger()
    }
    if (path) {
      const shouldGetNotice = this.shouldGetNotice(page, path)
      if (shouldGetNotice) {
        actions.getNotices({ page, project })
      }
    } else {
      actions.getNotices({ page, project })
    }
  }

  shouldGetNotice = (page, path) => {
    const match = matchPath(path, {
      path: APPLICATION_LIST,
    })
    if (match?.params?.propertyId && page === PAGE.SIDEBAR) {
      return false
    }

    return true
  }

  render() {
    return null
  }
}

export default withPopper(Popper)
