import { combineReducers } from "redux"
import * as Types from "../constants/action-types"
import { reducerIsFetching } from "./helper/handle-reducer"

const isEmailsSend = (state = false, action) => {
  switch (action.type) {
    case Types.SEND_APPLICANTS_REQUEST:
    case Types.SEND_APPLICANTS_SUCCESS:
      return true
    case Types.SEND_APPLICANTS_FAILED:
    case Types.INIT_SEND_APPLICANTS:
    case Types.CLEAR_IS_SEND_EMAIL:
      return false
    default:
      return state
  }
}

const isTextMessageSend = (state = false, action) => {
  switch (action.type) {
    case Types.SEND_TEXT_MESSAGE_REQUEST:
    case Types.SEND_TEXT_MESSAGE_SUCCESS:
      return true
    case Types.SEND_TEXT_MESSAGE_FAILED:
    case Types.SEND_MORE_TEXT_MESSAGE:
    case Types.CLEAR_IS_SEND_EMAIL:
      return false
    default:
      return state
  }
}

const isFetchingRequested = (...args) =>
  reducerIsFetching(...args, [
    Types.SEND_APPLICANTS_REQUEST,
    Types.SEND_APPLICANTS_SUCCESS,
    Types.SEND_APPLICANTS_FAILED,
  ])
const isFetchingTextMessageRequested = (...args) =>
  reducerIsFetching(...args, [
    Types.SEND_TEXT_MESSAGE_REQUEST,
    Types.SEND_TEXT_MESSAGE_SUCCESS,
    Types.SEND_TEXT_MESSAGE_FAILED,
  ])

export default combineReducers({
  isEmailsSend,
  isTextMessageSend,
  isFetchingRequested,
  isFetchingTextMessageRequested,
})
