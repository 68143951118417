export const RENTAL_PAYMENT_API = '/api/v2/rental-payments'
export const PAYMENT_ACCOUNTS_API = '/api/v2/payment-accounts'

export const PAYEE_ONBOARDING_API = `${PAYMENT_ACCOUNTS_API}/payees/identity-verification`
export const SELECT_RENTAL_PAYMENT_API = `${RENTAL_PAYMENT_API}/:rentalPaymentId`
export const CHECK_RENTAL_PAYMENT_EXISTS_API = `${RENTAL_PAYMENT_API}/exists`
export const GET_RENTAL_PAYMENT_LIST_API = RENTAL_PAYMENT_API
export const GET_PAYEE_SELF_API = `${PAYMENT_ACCOUNTS_API}/payees/self`
export const GET_RENTAL_PAYMENT_BY_ID_API = SELECT_RENTAL_PAYMENT_API
export const SET_SEEN_NOTIFICATION_BY_ID_API = `${RENTAL_PAYMENT_API}/notifications/:notificationBadgeId`
export const RETRY_PAYOUT_API = `${PAYMENT_ACCOUNTS_API}/payouts/retry`
export const DELETE_RENTAL_PAYMENT_API = `${SELECT_RENTAL_PAYMENT_API}/end`
export const EDIT_ONE_TIME_PAYMENT_API = `${SELECT_RENTAL_PAYMENT_API}/quotations/:quotationId`
export const MARK_AS_PAID_API = `${SELECT_RENTAL_PAYMENT_API}/payment-requests/:paymentRequestId/mark-as-paid`
export const GET_PAYOUT_METHOD_LIST_API = `${PAYMENT_ACCOUNTS_API}/payment-methods`
export const SEND_INVITATION_EMAIL_API = `${SELECT_RENTAL_PAYMENT_API}/resend-invitation`
export const GET_PAYEE_IDV_UPDATE_LINK_API = `${PAYMENT_ACCOUNTS_API}/payees/identity-verification/update-link`
export const GET_PAYEE_PROVIDER_ACCOUNT_INFORMATION_API = `${PAYMENT_ACCOUNTS_API}/payees/provider-account`
export const CONFIRM_DRAFT_RENTAL_PAYMENT_API = `${GET_RENTAL_PAYMENT_BY_ID_API}/confirm`
export const UPDATE_PAYOUT_METHOD_API = `${GET_RENTAL_PAYMENT_BY_ID_API}/payout-method`
export const GET_OR_CREATE_DRAFT_RENTAL_PAYMENT_API = RENTAL_PAYMENT_API
export const GET_OR_CREATE_RENTER_INFO_API = `${RENTAL_PAYMENT_API}/renter-info`
export const SEARCH_RENTER_INFO_API = `${RENTAL_PAYMENT_API}/renter-info/search`
export const UPDATE_DRAFT_QUOTATIONS_API = `${SELECT_RENTAL_PAYMENT_API}/quotations`
export const GET_AVAILABLE_PAYOUT_API = `${RENTAL_PAYMENT_API}/available-payout`
export const GET_PAYMENT_SUMMARY_API = `${RENTAL_PAYMENT_API}/summary`
export const CREATE_TENANT_API = `${RENTAL_PAYMENT_API}/renter-info`
