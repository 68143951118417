import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import TagWithIcon from 'components/contacts/molecules/tag-with-icon'
import DeleteTagIcon from 'images/contacts/delete-tag.svg'
import { COLOR, breakpoints } from 'styles/settings'

const Wrapper = styled.div`
  overflow: auto;
  height: calc(313px - 60px);
  ${breakpoints.mobile(`
    height: 100%;
  `)};
`

const Icon = styled.img`
  width: ${({ width }) => width || '45px'};
  height: auto;
`

export const DeleteTagWrapper = styled.div`
  border-top: 1px solid ${COLOR.newGrey};
  &:last-child {
    border-bottom: 1px solid ${COLOR.newGrey};
  }
`

const DeleteTagList = ({ tags, onClickDeleteTag, iconColor }) => (
  <Wrapper>
    {tags.map(tag => (
      <DeleteTagWrapper key={tag?._id}>
        <TagWithIcon
          tag={tag}
          iconColor={iconColor}
          onClickIcon={onClickDeleteTag}
          customIcon={<Icon src={DeleteTagIcon} width="16px" />}
        />
      </DeleteTagWrapper>
    ))}
  </Wrapper>
)

DeleteTagList.defaultProps = {
  iconColor: COLOR.textGrey,
}

DeleteTagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  onClickDeleteTag: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
}

export default DeleteTagList
