import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import PinLocationImg from 'images/pin-location.svg'
import { TextInput } from '@rentspree/component-v2'
import { COLOR, MOBILE_WIDTH, breakpoints } from 'styles/settings'
import { useDebounce } from 'hooks/use-debounce'
import { VALUE_CHANGED_EVENT_TYPE } from './constants'

const AutocompletePaper = styled.div`
  width: 100%;
  max-height: calc(5px + 37px * ${props => props.size});
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  overflow: auto;
  z-index: 1;
  @media (max-width: ${MOBILE_WIDTH}) {
    max-height: calc(10px + 42px * ${props => props.size});
  }
`
const AutocompleteBoxItem = styled.div`
  position: relative;
`
const AutocompleteItem = styled.div`
  color: ${COLOR.textBlack};
  font-size: 14px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 15px 5px 15px;
  ${props => props.isHighlight && `background-color: ${COLOR.bgGrey};`}

  &:first-child {
    margin-top: 5px;
  }
  margin-bottom: 5px;
  ${breakpoints.mobile(`
    margin-bottom: 10px;
    &:first-child {
      margin-top: 10px;
    }
  `)}
`
const AutocompleteItemText = styled.span`
  width: 100%;
`
const Icon = styled.img`
  margin-right: 12px;
`

const AutocompleteItemLayout = styled.div`
  margin-bottom: 0px;
`

const AutocompleteGroup = ({ option, onSelectOption, isHighlight, handleHover }) => (
  <AutocompleteItem
    id="item-box"
    isHighlight={isHighlight}
    key={option.name}
    onMouseDown={() => onSelectOption(option)}
    onTouchStart={handleHover}
    onMouseEnter={handleHover}
  >
    <Icon src={PinLocationImg} alt="address-icon"></Icon>
    <AutocompleteItemText>{option.displayedAddress}</AutocompleteItemText>
  </AutocompleteItem>
)

const withAddressAutocomplete = WrappedComponent => {
  const AutocompleteInput = forwardRef((props, ref) => {
    const {
      options = [],
      maxLength = 5,
      value = '',
      debouncingTime = 500,
      onDebounceChange,
      onSelectOption,
      onValueChange,
      onHandleAutoFilledUsedTracker,
      fullAddressMode,
      getAddressDetails,
    } = props
    const numberAddress = Math.min(maxLength, options.length)

    const [inputValue, setInputValue] = useState(value)

    useEffect(() => {
      setInputValue(value)
    }, [value])

    const [showDropdown, setShowDropdown] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(-1)

    const [isOptionSelected, setIsOptionSelected] = useState(false)
    const inputRef = useRef(null)
    const dropdownTargetRef = useRef(null)
    const dropdownContentRef = useRef(null)

    const debouncedSearch = useDebounce(input => {
      if (input) onDebounceChange(input)
    }, debouncingTime)

    const handleInput = useCallback(event => {
      const eventTargetValue = event.target?.value
      onValueChange(eventTargetValue, { type: VALUE_CHANGED_EVENT_TYPE.CHANGE })
      setInputValue(eventTargetValue)
      debouncedSearch(eventTargetValue)
      setIsOptionSelected(false)
      handleHover(-1)
    }, [])

    const handleSelect = async option => {
      if (onHandleAutoFilledUsedTracker) {
        onHandleAutoFilledUsedTracker()
      }
      const addressDetails = await getAddressDetails?.(option.placeId) // getAddressDetails is undefined when use Smarty
      const optionDetails = {
        ...option,
        ...addressDetails,
      }
      onSelectOption(optionDetails)
      setShowDropdown(false)
      const selectedOptionValue = optionDetails.isCustom
        ? optionDetails.value
        : optionDetails.streetLine
      if (fullAddressMode) {
        setInputValue(optionDetails.displayedAddress)
      } else {
        setInputValue(selectedOptionValue)
      }
      setIsOptionSelected(true)
      onValueChange(selectedOptionValue, { type: VALUE_CHANGED_EVENT_TYPE.SELECT })
    }

    useEffect(() => {
      const handleClickOutside = event => {
        const isClickOutside =
          (inputRef?.current && !inputRef?.current?.contains(event.target)) &&
          (dropdownTargetRef?.current && !dropdownTargetRef?.current?.contains(event.target))
        if (isClickOutside) setShowDropdown(false)
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const optionsKeys = options.reduce((acc, option) => `${acc},${option.key}`, "")
    useEffect(() => {
      const isDropdownRequired = inputValue?.length && options?.length && !isOptionSelected
      setShowDropdown(!!isDropdownRequired)
    }, [inputValue, optionsKeys, isOptionSelected])

    const handleHover = index => {
      setHighlightedIndex(index)
    }

    const handleKeyDown = e => {
      if (showDropdown && options.length > 0) {
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
          scrollHighlightedOptionIntoView(highlightedIndex - 1)
        } else if (e.key === 'ArrowDown') {
          e.preventDefault()
          setHighlightedIndex(prevIndex =>
            prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex,
          )
          scrollHighlightedOptionIntoView(highlightedIndex + 1)
        } else if (e.key === 'Enter') {
          e.preventDefault()
          if (highlightedIndex >= 0 && highlightedIndex < options.length) {
            handleSelect(options[highlightedIndex])
          }
        }
      }
    }

    useImperativeHandle(ref, () => ({
      getValue: () => inputValue,
      clearValue: () => {
        setInputValue('')
        setShowDropdown(false)
      },
    }))

    const scrollHighlightedOptionIntoView = viewIndex => {
      if (dropdownContentRef.current && highlightedIndex >= 0) {
        const optionElement = dropdownContentRef.current.children[viewIndex] // viewIndex is upper and lower bound of view AutocompleteItemLayout
        if (optionElement) {
          optionElement.scrollIntoView({
            block: 'nearest',
            inline: 'start',
          })
        }
      }
    }

    return (
      <>
        <form autoComplete="off" ref={inputRef}>
          <WrappedComponent
            {...props}
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleInput}
          />
        </form>
        {showDropdown && (
          <AutocompleteBoxItem id="autocomplete-box" ref={dropdownTargetRef}>
            <AutocompletePaper size={numberAddress}>
              <AutocompleteItemLayout ref={dropdownContentRef}>
                {options.map((option, index) => (
                  !option.isCustom ? (
                    <AutocompleteGroup
                      id={`autocomplete-item-${option.key}`}
                      isHighlight={highlightedIndex === index}
                      key={option.key}
                      option={option}
                      onSelectOption={handleSelect}
                      handleHover={() => handleHover(index)}
                    />
                  ) :
                    <AutocompleteItem
                      id={`autocomplete-item-custom-${option.key}`}
                      key={option.key}
                      {
                      ...(option.disabled !== false && {
                        isHighlight: highlightedIndex === index,
                        onMouseDown: () => handleSelect(option),
                        onTouchStart: () => handleHover(index),
                        onMouseEnter: () => handleHover(index)
                      })
                      }
                    >
                      {option.node}
                    </AutocompleteItem>
                ))}
              </AutocompleteItemLayout>
            </AutocompletePaper>
          </AutocompleteBoxItem >
        )}
      </>
    )
  })
  return AutocompleteInput
}

export const AddressAutocomplete = withAddressAutocomplete(TextInput)
