const defaultOptions = {
  block: 'center',
  inline: 'nearest',
  behavior: 'smooth',
}

export const scrollToElement = (querySelector, options = defaultOptions) => {
  const element = document.querySelector(querySelector)
  if (element) {
    element.scrollIntoView(options)
  }
}
