import React from 'react'
import { TopNavbar } from '@rentspree/navbar'
import tracker from 'tracker'
import isEmpty from 'lodash/isEmpty'

import InformIcon from 'images/icons/rentspree-inform-icon.svg'
import { InformPageTemplate } from 'components/templates/inform-page-template'
import { getPartnerLogoDashboardSrc } from 'utils/subdomain'

import { TENANT_SCREENING } from 'constants/route'
import { PARTNER_NAME } from 'constants/partner'
import { USER_TYPE } from 'constants/user'
import { REPORT_GENERATION_LIMIT_EVENT } from 'tracker/const'

import { DASHBOARD_OVERVIEW } from '../reviewing/constants'
import { HEADER, AGENT_TEXT, RENTER_TEXT, BUTTON_TEXT } from './constants'
import { DOMAIN_NAME, API_URL, USER_API_URL } from '../../../env'
import { withConnect } from './connect'

export const GenerateLimitPage = ({ actions, profile, subdomain, history }) => {
  const isRenter = profile?.userType === USER_TYPE.RENTER

  React.useEffect(() => {
    tracker.trackEvent(REPORT_GENERATION_LIMIT_EVENT.EVENT_NAME.ENTER_TU_REPORT_GENERATION_LIMIT)

    if (isEmpty(profile)) actions.getProfile()
  }, [])

  const redirectToDashboard = () => {
    if(isRenter) {
      window.location.assign(DASHBOARD_OVERVIEW)
    }
    else {
      history.push({
        pathname: TENANT_SCREENING,
      })
    }
  }

  return (
    <>
      <TopNavbar
        configApi={{
          baseURL: USER_API_URL,
          domain: DOMAIN_NAME,
          apiURL: API_URL,
        }}
        partnerLogoSrc={subdomain === PARTNER_NAME.RENTALS && getPartnerLogoDashboardSrc(subdomain)}
        profile={profile}
        isShowRenterSupport
        domain={DOMAIN_NAME}
        authBaseURL={USER_API_URL}
        tracker={tracker}
      />
      <InformPageTemplate
        iconSrc={InformIcon}
        headerText={HEADER}
        descriptionLineOne={
          isRenter ? RENTER_TEXT.DESCRIPTION_LINE_ONE : AGENT_TEXT.DESCRIPTION_LINE_ONE
        }
        descriptionLineTwo={
          isRenter ? RENTER_TEXT.DESCRIPTION_LINE_TWO : AGENT_TEXT.DESCRIPTION_LINE_TWO
        }
        handleClickButton={() => redirectToDashboard()}
        buttonText={BUTTON_TEXT}
        buttonId="BackToDashboardBtn"
      />
    </>
  )
}

export default withConnect(GenerateLimitPage)
