// create message error = page -> feature -> text
import { removeLocalItem } from '@rentspree/cookie'
import { ERROR_TYPE } from 'constants/error-type'
import { ALERT_PRESET } from 'redux-middleware/sweet-alert'
import iconErrorCreditReport from '../images/icons/credit-report/report-red.png'
import { STORAGE } from './cookie'
import { TU_CONTACT } from './partner'
export const RENTSPREE_ERROR_CONTACT =
  'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.'
export const RENTSPREE_ERROR_CONTACT_NEW_VERSION =
  'Please try again in a few minutes. If the problem persists, contact support@rentspree.com or (323) 515-7757.'

export const REQUEST = {
  CREATE_PROPERTY: {
    TITLE: "There's a problem with the property address",
    MESSAGE:
      'Please make sure the address is entered in standard format. If the error persists, contact us at support@rentspree.com or (323) 515-7757.',
  },
  SEND_EMAILS: {
    TITLE: 'We’re having trouble sending the request.',
    MESSAGE:
      'Please try again. If the error persists, contact us at support@rentspree.com or (323) 515-7757.',
  },
  SEND_EMAILS_SUCCESS: {
    TITLE: 'Email sent successfully.',
    MESSAGE: 'The email has been sent to the applicant.',
  },
  GET_SCREENING_PLAN: {
    TITLE: 'Sorry. We are having trouble connecting to the RentSpree servers.',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  GET_ZIPLOGIX_PROPERTY: {
    TITLE: "There's a problem connecting to Ziplogix server",
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
}

export const USER = {
  UNAUTHORIZED: {
    TITLE: 'Looks like your account can’t access this page',
    MESSAGE:
      'Please refresh the page and try again, or try logging in with a different email. If you still have trouble, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  UPDATE_PROFILE_FAILED: {
    TITLE: '',
    MESSAGE:
      'Sorry, we were unable to update your information. Please try again and contact support@rentspree.com if the problem persists.',
  },
}

export const OPEN_CREDIT = {
  // TRANSUNION_DELAY will be used after we automate datadog threshold to distinct between intermittent failure vs total outage
  TRANSUNION_DELAY: {
    TITLE: `We're experiencing a delay from TransUnion`,
    MESSAGE: `Please try again in a few minutes.<br/>If the problem persists, <a href="https://support.rentspree.com/en/kb-tickets/new" target="_blank">contact RentSpree</a>.`,
  },
  // for now, we shall redirect all failure to the status page. If the status page show healthy, user can call support number on that page
  TRANSUNION_OUTAGE: {
    TITLE: `We're experiencing a delay from TransUnion`,
    MESSAGE: `Please try again in 30 minutes.<br/>For updates, visit <a href="https://status.rentspree.com" target="_blank">status.rentspree.com</a>.`,
  },
}

export const APPLICATION_DETAIL = {
  RENTAL_FORM: {
    TITLE: 'The rental application is temporarily unavailable',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  APPLICATION: {
    TITLE: 'The rental application is temporarily unavailable',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  CREDIT_REPORT: {
    IMAGE: iconErrorCreditReport,
    TITLE:
      'Sorry, TransUnion is delayed in generating this applicant’s screening reports. Normally the reports are available instantly, but today it’s taking longer than expected.',
    SHOULD_I_DO: [
      "Please wait an hour and <a href='javascript:window.location.reload()'> try again </a>",
      `If the reports are still not available, you can contact TransUnion at ${TU_CONTACT.PHONE} with the applicant’s email address to check the status.`,
      'For further information, call RentSpree at (323) 515-7757.',
    ],
  },
  SAVE_PDF_TIMEOUT: {
    TITLE: "It's taking long than expected to generate the PDF",
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  DOCUMENTS: {
    REQUESTED_SEND: {
      TITLE: "Can't send request document",
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the properties, contact us at (323) 515-7757 or support@rentspree.com.',
    },
    VALIDATION: {
      TITLE: 'Validation error request document',
    },
    FETCH_DOCUMENTS: {
      TITLE: 'The documents section is currently unavailable',
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the documents section, contact us at (323) 515-7757 or support@rentspree.com.',
    },
  },
  REFERENCE_CHECK: {
    REQUESTED_SEND: {
      TITLE: "Can't send request reference",
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the reference checks section, contact us at (323) 515-7757 or support@rentspree.com.',
    },
    FETCH_REFERENCE: {
      TITLE: 'The reference checks section is currently unavailable',
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the reference checks section, contact us at (323) 515-7757 or support@rentspree.com.',
    },
  },
}

export const APPLICATIONS = {
  LISTING: {
    TITLE: 'The applications are temporarily unavailable',
    MESSAGE:
      'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the applications, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const PROPERTIES = {
  LISTING: {
    TITLE: 'The properties are temporarily unavailable',
    MESSAGE:
      'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the properties, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const PROPERTY = {
  REACTIVATE: {
    TITLE: 'Sorry',
    MESSAGE:
      'We are unable to reactivate this property. Please refresh your screen and try again. If the problem persists, contact support@rentspree.com.',
  },
  DEACTIVATE: {
    TITLE: 'Sorry',
    MESSAGE:
      'We are unable to deactivate this property. Please refresh your screen and try again. If the problem persists, contact support@rentspree.com.',
  },
}

export const OTHER_DOCUMENTS = {
  REQUEST_SUCCESS: {
    TITLE: 'Document request successfully sent',
    MESSAGE:
      "You'll receive an email notification when the applicant uploads all requested documents.",
  },
  DELETE_SUCCESS: {
    TITLE: 'Successfully deleted files',
    MESSAGE:
      'All files of this document have been removed and agent can no longer access them. If you wish to send a new file, please re-upload a new one on this document.',
  },
  DELETE_FAILED: {
    TITLE: 'Unable to delete files',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  UPLOAD_FAILED: {
    TITLE: 'Unable to upload files',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  DROP_REJECTED: {
    TITLE: 'Files are too large or invalid type',
    MESSAGE:
      'Please make sure that each file size is less than 10MB and they are one of these types: jpeg, jpg, pdf, csv, xls, xlsx, png, doc, docx',
  },
  DOWNLOAD_DOCUMENT: {
    TITLE: 'Unable to download document',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  MAX_NUMBER_FILES: {
    TITLE: 'Maximum number of files exceeded',
    MESSAGE:
      'You are trying to uploading more than 10 files which exceed our maximum file capacity. If you need to upload all of them, please combine them into less than 10 files and try again.',
  },
}

export const SUBSCRIPTIONS = {
  AUTHORIZED: {
    TITLE: 'Service is temporarily unavailable.',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  UNKNOWN_ERROR: {
    TITLE: 'Service is temporarily unavailable.',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
    button: 'Try Again',
  },
}

export const PAYMENT = {
  FETCH_FAILED: {
    TITLE: 'Sorry. We are having trouble connecting to the RentSpree servers.',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  LANDLORD_PAY_GENERAL_FAILED: {
    TITLE: '',
    MESSAGE:
      'Sorry, we are experiencing an issue with payment processing. Please refresh the page and try again.',
  },
}

export const LRA = {
  FETCH_FAILED: {
    TITLE: 'Please try again.',
    MESSAGE:
      'Sorry, we cannot generate the C.A.R. rental app at this time. Please contact support@rentspree.com if the problem persists.',
  },
}

export const DENY_APPLICATION = {
  SEND_FAIL: {
    TITLE: 'Trouble sending email',
    MESSAGE:
      'Please try again in a few minutes. If the error persists, contact us at support@rentspree.com or (323) 515-7757.',
  },
}

export const PLEASE_CONTACT =
  'If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.'

export const RENT_ESTIMATE = {
  CONTACT: PLEASE_CONTACT,
  PAYMENT_FAILED:
    "Your payment didn't go through. Please check your payment information, use a different payment method, or try again later.",
  DEFAULT: "Sorry, we're having an internal problem. Please try again.",
  SDP_DH_022:
    'We are unable to generate a Rental AVM for this property due to insufficient availability of AVM data',
}

export const LEASE_AGREEMENTS = {
  LISTING: {
    TITLE: 'The leases are temporarily unavailable',
    MESSAGE:
      'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the leases, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  CONSENT_ERROR: {
    TITLE: 'Something went wrong',
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  RESEND_ERROR: {
    TITLE: 'Error sending the request',
    MESSAGE: RENTSPREE_ERROR_CONTACT_NEW_VERSION,
  },
  SIGN_ERROR: {
    TITLE: 'Error signing the document',
    MESSAGE: RENTSPREE_ERROR_CONTACT_NEW_VERSION,
  },
}

export const GENERAL_ERROR = {
  UNKNOWN_ERROR: {
    TITLE: "We're sorry.",
    MESSAGE:
      'We’re experiencing some technical issues. Please click the try again button below. If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.',
  },
}
export const API_ERRORS = {
  401: {
    option: {
      title: USER.UNAUTHORIZED.TITLE,
      text: USER.UNAUTHORIZED.MESSAGE,
      button: ALERT_PRESET.UNAUTHORIZED.button.text,
      closeOnClickOutside: false,
      closeOnEsc: false,
    },
    callbackWithOutRedirect: val => {
      if (val) {
        removeLocalItem(STORAGE.USER_TOKEN)
        window.location.reload()
      }
    },
    callback: val => {
      if (val) {
        removeLocalItem(STORAGE.USER_TOKEN)
        window.location.assign('/account/login')
      }
    },
  },
  500: {
    option: {
      title: "We're sorry.",
      text: GENERAL_ERROR.UNKNOWN_ERROR.MESSAGE,
      button: 'Refresh',
      closeOnClickOutside: false,
      closeOnEsc: false,
    },
    callback: val => {
      if (val) window.location.reload()
    },
  },
}

export const MESSAGING = {
  API_FAILED: {
    TITLE: 'The messaging function is temporarily unavailable',
    MESSAGE:
      'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the function, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const RENT_PAYMENT_VALIDATION_MSG = {
  minMonthlyRent: 'Monthly amount must be between $100 and $5,990',
  maxMonthlyRent: 'Monthly amount must be between $100 and $5,990',
}

export const ONE_TIME_RENT_PAYMENT_VALIDATION_MSG = {
  minOneTimeRent: 'Amount must be between $1 and $10,000',
  maxOneTimeRent: 'Amount must be between $1 and $10,000',
}

export const RECURRING_RENT_PAYMENT_VALIDATION_MSG = {
  minOneTimeRent: 'Amount must be between $3 and $10,000',
  maxOneTimeRent: 'Amount must be between $3 and $10,000',
}

export const PROPERTY_DETAILS_VALIDATION_MSG = {
  monthlyRent: 'Price must be between $100 and $1,000,000',
  squareFeet: 'Square feet must be between 100 and 9999',
  yearBuilt: 'Year must be between 1000 - present',
}

export const LISTING_INFORMATION_VALIDATION_MSG = {
  maxDescription: 'Exceed maximum character length',
  minDescription: 'Description must be more than 50 character length',
  video: 'This URL is not supported, please try a different web service provider',
}

export const SCREENING_FEE_VALIDATION_MSG = {
  creditReportFee: 'Price must be between $0 and $1,000,000,000',
  outOfPocketExpense: 'Price must be between $0 and $1,000,000,000',
  processingFee: 'Price must be between $0 and $1,000,000,000',
}

export const DUPLICATE_PROPERTY = {
  title: 'The property address you tried to create already exists.',
}

export const ERROR_PANEL_MESSAGE = {
  [ERROR_TYPE.SAVING]: "We've encountered an error saving your info. Please try again.",
  [ERROR_TYPE.DUPLICATE_PROPERTY]: 'The property address you tried to create already exists.',
  [ERROR_TYPE.REDIRECT_TO_STRIPE]:
    'Cannot connect you to identity verification page at this time. Please try again.',
  [ERROR_TYPE.START_DATE_IS_PAST]: 'The payment’s start date cannot be in the past.',
  [ERROR_TYPE.CREATE_TENANT_FAILED]: 'Cannot Save Tenant',
  [ERROR_TYPE.CREATE_PROPERTY_FAILED]: 'Cannot Save Property',
}

export const ERROR_SAVING = 'We’ve encountered an error saving your info. Please try again.'

export const RENT_PAYMENT_ERROR = {
  GENERAL_ERROR: {
    TITLE: "We're sorry.",
    MESSAGE:
      'We’re experiencing some technical issues. Please click the try again button below. If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.',
  },
  RESEND_ERROR: {
    TITLE: 'Error sending email. Please try again.',
    MESSAGE:
      'If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.',
  },
  END_PAYMENT: {
    TITLE: 'Error ending your payment. Please try again.',
    MESSAGE:
      'If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.',
    TITLE_422: 'Cannot End Payment',
    MESSAGE_422: 'You can end payment when there is no transaction being processed.',
  },
  SAVING: "We've encountered an error saving your info. Please try again.",
}

export const REPORT_GENERATION_LIMIT_ERROR_CODE = 'DailyGenerateReportLimitError'
