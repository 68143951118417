import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import {
  selectIsLoading,
  selectIsError,
  selectIsTalkJsReady,
  selectIsShowNewMessageModal,
  selectIsConversationStarted,
  selectCurrentConversationId,
  selectIsUnreadNotificationReady,
} from "./selectors"
import reducer from "./reducer"
import {
  toggleNewMessageModal,
  closeModalError,
  startConversation,
  getConversation,
  setConversation,
  clearConversation,
} from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  isTalkJsReady: selectIsTalkJsReady,
  isUnreadNotificationReady: selectIsUnreadNotificationReady,
  isLoading: selectIsLoading,
  isError: selectIsError,
  isShowNewMessageModal: selectIsShowNewMessageModal,
  isConversationStarted: selectIsConversationStarted,
  currentConversationId: selectCurrentConversationId,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        toggleNewMessageModal,
        closeModalError,
        startConversation,
        getConversation,
        setConversation,
        clearConversation,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "messaging", reducer })
export const withSaga = injectSaga({ key: "messaging", saga })
