import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import 'moment-timezone'
import moment from 'moment'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

export const ordinalSuffix = date => {
  const unit = date % 10
  const specific = date % 100
  let suffix = 'th'
  if (unit === 1 && specific !== 11) suffix = 'st'
  if (unit === 2 && specific !== 12) suffix = 'nd'
  if (unit === 3 && specific !== 13) suffix = 'rd'
  return `${date}${suffix}`
}

export const dateGeneratorWithSuffix = ({ dateNumber, suffix }) =>
  `${ordinalSuffix(dateNumber)} ${suffix}`

export const dueOnDateGenerator = dateNumber =>
  dateGeneratorWithSuffix({ dateNumber, suffix: 'of each month' })

export const dueDayGenerator = dateNumber =>
  dateGeneratorWithSuffix({ dateNumber, suffix: 'of the month' })

export const parseOneTimeQuotationDueDateWithoutTZ = (date, format) => {
  const timezone = moment.tz.guess()
  if (!date || !dayjs(date).isValid()) return ''
  const utcDueDate = dayjs.tz(date, timezone).tz('utc').format()
  return moment(utcDueDate).format(format)
}

export const parsePaymentRequestDate = (date, timezone) =>
  dayjs(date).tz(timezone).format('MMM D, YYYY')
