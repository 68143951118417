import React from 'react'

import { CenterContent, Loading } from 'components/layout/main'
import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'

export const ReportLoading = () => (
  <ReportWrapper>
    <CenterContent noMargin withBreadcrumb withReportTabs>
      <Loading />
    </CenterContent>
  </ReportWrapper>
)
