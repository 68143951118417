import { TYPES } from "./const"

export const fetchGetStartedNotice = data => ({
  type: TYPES.FETCH_GET_STARTED,
  payload: data,
})

export const skipGetStartedModal = () => ({
  type: TYPES.SKIP_GET_STARTED_MODAL,
})

export const dispatchAction = (type, payload = {}) => ({
  type,
  payload,
})
