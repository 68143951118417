import get from "lodash/get"
import { object } from "yup"
import { updateInfoValidation } from "legacy/constants/validations"

export const schema = props => {
  const profile = get(props, "profile", {})
  const address = get(profile, "address", {})
  const { phone, firstName, lastName } = profile
  const { street, unitNumber, city, zip, state } = address
  return {
    mapper: {
      phone,
      firstName,
      lastName,
      street,
      unitNumber,
      city,
      state,
      zip,
    },
    validation: object().shape({
      firstName: updateInfoValidation("firstName"),
      lastName: updateInfoValidation("lastName"),
      phone: updateInfoValidation("phone"),
      street: updateInfoValidation("street"),
      unitNumber: updateInfoValidation("unitNumber"),
      city: updateInfoValidation("city"),
      state: updateInfoValidation("state"),
      zip: updateInfoValidation("zip"),
    }),
  }
}
