import get from 'lodash/get'
import { createSelector } from 'reselect'
import { LISTING_SYNDICATION_INFO_PANEL, LISTING_SYNDICATION_STATUS } from './constants'

const getListingManagement = state => get(state, 'listingManagement', {})

export const selectListingSyndication = createSelector(getListingManagement, state =>
  get(state, 'listingSyndication'),
)
export const selectMappedListingSyndication = createSelector(
  [
    state => get(state, 'listingManagement.listingSyndication'),
    state => get(state, 'listingManagement.isUpdatingContactDetails', false),
    state => get(state, 'listingManagement.isPotentialFraud', false),
  ],
  (listingSyndication, isUpdatingContactDetails, isPotentialFraud) => {
    if (!listingSyndication) {
      return {
        shouldNotDisplay: true,
        isEnabled: false,
        contactDetails: {
          isUpdatingContactDetails: false,
        },
        isBlockedSyndication: false,
        blockedSyndicationInfo: {},
      }
    }

    const isArchived = !!listingSyndication.isArchived
    const isBlockedListing = !!listingSyndication.isBlocked
    const hasSyndicationBeenEnabled = !!listingSyndication.enabledAt
    const isEnabled = listingSyndication.status === LISTING_SYNDICATION_STATUS.ENABLED
    const contactDetails = {
      ...(listingSyndication.contactDetails || {}),
      isUpdatingContactDetails,
    }
    const isBlockedSyndication =
      listingSyndication.isAddressInvalid || listingSyndication.isAddressDuplicated
    const blockedSyndicationInfo = {}
    if (listingSyndication.isAddressInvalid) {
      blockedSyndicationInfo.titleMessage = LISTING_SYNDICATION_INFO_PANEL.INVALID_ADDRESS.TITLE
      blockedSyndicationInfo.bodyMessage = LISTING_SYNDICATION_INFO_PANEL.INVALID_ADDRESS.BODY
    } else if (listingSyndication.isAddressDuplicated) {
      blockedSyndicationInfo.titleMessage = LISTING_SYNDICATION_INFO_PANEL.DUPLICATE_ADDRESS.TITLE
      blockedSyndicationInfo.bodyMessage = LISTING_SYNDICATION_INFO_PANEL.DUPLICATE_ADDRESS.BODY
    }

    return {
      shouldNotDisplay: isBlockedListing || isArchived || (isPotentialFraud && !hasSyndicationBeenEnabled),
      isEnabled,
      contactDetails,
      isBlockedSyndication,
      blockedSyndicationInfo,
    }
  },
)

export const selectIsFetchingListingSyndication = createSelector(getListingManagement, state =>
  get(state, 'isFetchingListingSyndication', false),
)
export const selectIsSyndicatingListing = createSelector(getListingManagement, state =>
  get(state, 'isSyndicatingListing', false),
)
export const selectIsUpdatingContactDetails = createSelector(getListingManagement, state =>
  get(state, 'isUpdatingContactDetails', false),
)
