import generateApiCall from "utils/generate-api-call"
import { store } from "store"
import { buildPath, query } from "@rentspree/path"
import { replace } from "connected-react-router"
import { RENTER_INSURANCE_REQUEST_PAGE } from "constants/route"
import {
  UPDATE_PROPERTY_TYPE_CALL,
  UPDATE_PROPERTY_TYPE_REQUEST,
  UPDATE_PROPERTY_TYPE_SUCCESS,
  UPDATE_PROPERTY_TYPE_FAILED,
  CLEAR_PROPERTY_TYPE_ERROR,
} from "./const"

export const updatePropertyType = (payload, propertyId, location) => ({
  type: UPDATE_PROPERTY_TYPE_CALL,
  payload,
  propertyId,
  location,
})
export const updatePropertyTypeApi = generateApiCall([
  UPDATE_PROPERTY_TYPE_REQUEST,
  UPDATE_PROPERTY_TYPE_SUCCESS,
  UPDATE_PROPERTY_TYPE_FAILED,
])

export const clearPropertyTypeError = () => ({
  type: CLEAR_PROPERTY_TYPE_ERROR,
})

export const continueToRenterInsuranceRequest = (
  propertyId,
  location,
) => () => {
  store.dispatch(
    replace(
      buildPath(
        RENTER_INSURANCE_REQUEST_PAGE,
        { propertyId },
        query.parse(location.search),
      ),
    ),
  )
}
