import get from "lodash/get"
import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const getLraSignState = state => get(state, "lraSign", initialState)

export const selectApplication = () =>
  createSelector(
    getLraSignState,
    state => get(state, "application"),
  )

export const selectEnvelopeId = () =>
  createSelector(
    getLraSignState,
    state => get(state, "envelopeId"),
  )

export const selectIsFetchingApplication = () =>
  createSelector(
    getLraSignState,
    state => get(state, "isFetchingApplication"),
  )

export const selectIsFetchingEnvelope = () =>
  createSelector(
    getLraSignState,
    state => get(state, "isFetchingEnvelope"),
  )

export const selectIsFetchingUpdateEnvelope = () =>
  createSelector(
    getLraSignState,
    state => get(state, "isFetchingUpdateEnvelope"),
  )
