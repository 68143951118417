import React from 'react'
import styled from 'styled-components'

import { COLOR, breakpoints } from 'styles/settings'
import {
  InformationLineContainer,
  Label,
  Value,
} from 'components/reports/screening-reports/share/responsive-doc-style'

export const BoxContainer = styled.div`
  width: 100%;
  ${({ height }) => `height: ${height};`}
  ${({ minHeight }) => `min-height: ${minHeight};`}
  ${({ padding }) => `padding: ${padding};`}
  ${({ margin }) => `margin: ${margin};`}
  background-color: ${COLOR.grayLight};
  border: 0.5px solid ${COLOR.grayLight};
  border-radius: 5px;

  ${({ flex }) =>
    flex &&
    `
    display: flex;
    ${flex?.justifyContent && `justify-content: ${flex?.justifyContent};`}
    ${flex?.alignItems && `align-items: ${flex.alignItems};`}
  `}

  ${({ isActive }) =>
    isActive &&
    `
    border: 0.5px solid ${COLOR.placeholderGrey};
    background: none;
  `}

  ${breakpoints.mobileReport(`
    height: unset;
  `)}

  @media print {
    background-color: ${({ isActive }) => (isActive ? 'none' : COLOR.grayLight)} !important;
  }
`

const CountBoxHeader = styled.div`
  font-size: 14px;

  ${breakpoints.mobileReport(`
    line-height: 18px;
  `)}
`

const CountBoxNumber = styled.div`
  font-size: 28px;
  font-weight: 600;

  ${breakpoints.mobileReport(`
    font-size: 20px;
    line-height: 25px;
    margin-top: 16px;
  `)}
`

export const CountBox = ({ isActive, header, score }) => (
  <BoxContainer isActive={isActive} height="80px" padding="10px 15px">
    <CountBoxHeader>{header}</CountBoxHeader>
    <CountBoxNumber>{score}</CountBoxNumber>
  </BoxContainer>
)

const AccountHeaderPrimaryText = styled.div`
  color: ${({ color }) => color || COLOR.textBlack};
  font-size: 18px;
  font-weight: 600;
  ${({ textAlign }) => `text-align: ${textAlign};`}

  ${breakpoints.mobileReport(`
    font-size: 14px;
  `)}
`
const AccountStatus = styled.div`
  color: ${COLOR.textBlack};
  font-size: 14px;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`
const AccountDetail = styled.div`
  color: ${COLOR.textBlack};
  font-size: 14px;
  text-align: right;
`

export const AccountHeader = ({ isActive, title, status, balance, detail }) => (
  <BoxContainer
    isActive={isActive}
    flex={{ justifyContent: 'space-between', alignItems: 'center' }}
    padding="15px 20px"
  >
    <div>
      <AccountHeaderPrimaryText>{title}</AccountHeaderPrimaryText>
      <AccountStatus>{status}</AccountStatus>
    </div>
    <div>
      <AccountHeaderPrimaryText color={COLOR.fontBlue} textAlign="right">
        {balance}
      </AccountHeaderPrimaryText>
      <AccountDetail>{detail}</AccountDetail>
    </div>
  </BoxContainer>
)

export const CreditDetailContainer = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px 20px;

  ${breakpoints.mobileReport(`
    flex-direction: column;
  `)}
`

export const TotalCountBoxesContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;

  ${breakpoints.mobileReport(`
    column-gap: 15px;
  `)}
`

const CreditDetailInformationLineContainer = styled(InformationLineContainer)`
  padding: 0 10px;

  ${breakpoints.mobileReport(`
    padding: unset;
  `)}
`

const CreditDetailLabel = styled(Label)`
  flex: 1;
`

const CreditDetailValue = styled(Value)`
  flex: 1;
  text-align: right;
  font-size: 14px;
  min-height: 25px;
  width: 100%;

  ${breakpoints.mobileReport(`
    min-height: 22px;
    text-align: unset;
  `)}
`

export const CreditDetailInformationLine = ({ label, value, defaultValue = '-' }) => (
  <CreditDetailInformationLineContainer>
    <CreditDetailLabel>{label}</CreditDetailLabel>
    <CreditDetailValue>{value || defaultValue}</CreditDetailValue>
  </CreditDetailInformationLineContainer>
)

const BalanceDetailInformationLineContainer = styled(InformationLineContainer)`
  display: flex;
  gap: 10px 20px;
  line-height: 26px;
`

const BalanceDetailLabel = styled(Label)`
  font-size: 14px;
  line-height: 26px;
  flex: 1;

  ${breakpoints.mobileReport(`
    font-size: 12px;
    line-height: 22px;
  `)}
`
const BalanceDetailValue = styled(Value)`
  font-size: 14px;
  line-height: 26px;
  text-align: right;
  flex: 1;

  ${breakpoints.mobileReport(`
    text-align: unset;
    padding-left: 15px;
    line-height: 22px;
  `)}
`

export const BalanceDetailInformationLine = ({ label, value }) => (
  <BalanceDetailInformationLineContainer>
    <BalanceDetailLabel>{label}</BalanceDetailLabel>
    <BalanceDetailValue>{value}</BalanceDetailValue>
  </BalanceDetailInformationLineContainer>
)

export const TableContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const TableHeader = styled.th`
  height: 29px;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.14;
  letter-spacing: normal;
  color: ${COLOR.newBlack};
  padding: 0px 8px;
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  ${props => props.margin && `margin: ${props.margin}`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.minWidth && `min-width: ${props.minWidth};`}
`

export const TableCell = styled.td`
  font-size: 14px;
  line-height: 1.79;
  color: ${COLOR.newBlack};
  padding: 0px 8px;
  padding: ${({ padding }) => padding || '0px 8px'};
  word-break: break-word;
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ width }) => width && `width: ${width};`}
  &:empty {
    height: 25px;
  }
  ${props =>
    props.AlignRight &&
    `
    text-align: right;
  `}
`

export const TableRow = styled.tr`
  display: flex;
  width: 100%;
  ${({ borderBottom }) => borderBottom && `border-bottom: 0.5px solid ${COLOR.borderGray};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ height }) => height && `height: ${height};`}
`
