import UAParser from "ua-parser-js"

export const parser = new UAParser()
const { browser, os } = parser.getResult()

const isFirefox = browser.name === "Firefox"
const isChrome = browser.name === "Chrome"
const isChromeHeadless = browser.name === "Chrome Headless"
export const isSafari = browser.name === "Safari"
export const isMobileSafari = browser.name === "Mobile Safari"
export const isAndroid = os.name === "Android"
export const isIE = browser.name === "IE"
export const isEdge = browser.name === "Edge"
export const isIOS = () => os.name === "iOS"
export const isDeviceSafari = isSafari || isMobileSafari

export const isFirefoxAndroid = isFirefox && isAndroid
export const isChromeOrSafari =
  isChrome || isSafari || isMobileSafari || isChromeHeadless
export const isOtherChromeSupport =
  isEdge || isDeviceSafari || isIE || isFirefox
