import React from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { Popper } from "react-popper"
import parse from "html-react-parser"
import { B14, B16 } from "@rentspree/component-v2"

import { ProBadge } from "../badge"

import { COLOR } from "../../styles/settings"

export const Wrapper = styled.div`
  font-family: "Source Sans Pro";
  @media (max-width: 991px) {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  top: 6px !important;
  flex-direction: column;
  margin: 1rem;
  padding: 20px;
  transition: opacity 0.3s;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(50, 53, 54, 0.3);
  background-color: ${COLOR.white};
  width: 260px;
`

const PopperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Source Sans Pro";
  #titleNotice {
    margin-bottom: ${props => props.marginBottom || "10px"};
  }
`

const PopperBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  p {
    margin-bottom: 0px;
    cursor: pointer;
    &:hover {
      color: ${COLOR.newDeepBlue};
    }
  }
  ${props =>
    props.showSkip &&
    `
    justify-content: space-between;
  `}
  > #skipButton {
    display: none;
    ${props =>
      props.showSkip &&
      `
    display: block;
  `}
    &:hover {
      color: ${COLOR.titleGrey};
    }
  }
`

const PopperArrow = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  &[data-placement*="bottom"] {
    top: 0;
    left: 0;
    margin-top: -0.95rem;
    width: 1em;
    height: 1em;
    &::before {
      border-width: 0 0.8em 1em 0.8em;
      border-color: transparent transparent ${COLOR.white} transparent;
    }
  }
  &[data-placement*="top"] {
    bottom: 0;
    left: 0;
    margin-bottom: -0.95rem;
    width: 1em;
    height: 1em;
    &::before {
      border-width: 1em 0.8em 0 0.8em;
      border-color: ${COLOR.white} transparent transparent transparent;
    }
  }
  &[data-placement*="right"] {
    left: 0;
    margin-left: -0.95rem;
    height: 2em;
    width: 1em;
    &::before {
      border-width: 0.8rem 1rem 0.8rem 0;
      border-color: transparent ${COLOR.white} transparent transparent;
    }
  }
  &[data-placement*="left"] {
    right: 0;
    margin-right: -0.95rem;
    height: 1em;
    width: 1em;
    &::before {
      border-width: 0.8em 0 0.8em 1em;
      border-color: transparent transparent transparent ${COLOR.white};
    }
  }
  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`

const PopperBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1400;
  background-color: ${COLOR.black};
  opacity: 0.5;
`

const DescriptionContainer = styled.div`
  margin-bottom: 3px;
`

const PopperTooltips = ({
  referenceElement,
  title,
  description,
  htmlDescription,
  bottomBtnText,
  placementArrow,
  onClick,
  onDoneClick,
  group = {},
  onSkip,
  isLastStepGroup,
  offset = "",
  onClickButtonLink,
  buttonText,
}) => {
  const isShowProBadge = title && title.includes("[PRO]")
  const isShowSkip = group.isGroup && !isLastStepGroup
  const isShowButtonLink = buttonText && onClickButtonLink
  return (
    <Wrapper>
      <Popper
        referenceElement={referenceElement}
        placement={placementArrow}
        modifiers={{
          offset: {
            enabled: true,
            offset,
          },
          preventOverflow: {
            escapeWithReference: true,
          },
        }}>
        {({ ref, style, placement, arrowProps }) => (
          <Container ref={ref} style={style} data-placement={placement}>
            <PopperTitle marginBottom={htmlDescription && "5px"}>
              <B16
                id="titleNotice"
                color={COLOR.textBlack}
                size="18px"
                weight="600">
                {isShowProBadge ? title.replace("[PRO]", "") : title}
                {isShowProBadge && (
                  <ProBadge
                    fontSize="11px"
                    fontStyle="italic"
                    fontFamily="Source Sans Pro">
                    PRO
                  </ProBadge>
                )}
              </B16>
              {group.isGroup && (
                <B14 id="stepNotice" color={COLOR.textBlack}>
                  {group.current}/{group.max}
                </B14>
              )}
            </PopperTitle>
            {htmlDescription ? (
              <DescriptionContainer>
                {parse(htmlDescription)}
              </DescriptionContainer>
            ) : (
              <B14 color="black">{description}</B14>
            )}
            <PopperBottom
              showSkip={isShowSkip}
              isShowButtonLink={isShowButtonLink}>
              <B16
                onClick={onSkip}
                id="skipButton"
                color={COLOR.placeholderGrey}>
                Skip
              </B16>
              <B16
                color={
                  isShowButtonLink ? COLOR.placeholderGrey : COLOR.fontBlue
                }
                weight="600"
                onClick={isLastStepGroup ? onDoneClick : onClick}
                id="bottomBtn">
                {bottomBtnText}
              </B16>
              {isShowButtonLink && (
                <B16
                  margin="0 0 0 20px"
                  color={COLOR.fontBlue}
                  weight="600"
                  onClick={onClickButtonLink}
                  id="buttonLink">
                  {buttonText}
                </B16>
              )}
            </PopperBottom>
            <PopperArrow
              ref={arrowProps.ref}
              data-placement={placement}
              style={arrowProps.style}
            />
          </Container>
        )}
      </Popper>
      <PopperBackdrop />
    </Wrapper>
  )
}

export default withRouter(PopperTooltips)
