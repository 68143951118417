import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Hr from '@rentspree/component-v2/dist/hr'
import S24 from '@rentspree/component-v2/dist/typography/s24'

import Col from 'components/atoms/col'
import { TextWithIconButton } from 'components/molecules/text-with-icon-button'
import { COLOR } from 'styles/settings'

export const TopicContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${props => (props.topicMargin ? props.topicMargin : '20px 0 0 0')};
`

export const TopicHeaderWithUnderline = ({
  title,
  icon,
  iconHover,
  onClick,
  hrMargin,
  buttonId,
  buttonText,
  topicMargin,
  disabled,
}) => (
  <Col>
    <TopicContainer topicMargin={topicMargin}>
      <S24 margin="0px" tSize="20px" color={COLOR.newBlack} lineHeight="32px" tLineHeight="26px">
        {title}
      </S24>
      {buttonText && (
        <TextWithIconButton
          id={buttonId}
          text={buttonText}
          icon={icon}
          iconHover={iconHover}
          onClick={onClick}
          disabled={disabled}
        />
      )}
    </TopicContainer>
    <Hr thickness="1px" margin={hrMargin || '8px 0 10px'} color={COLOR.borderGrey} />
  </Col>
)

TopicHeaderWithUnderline.propTypes = {
  /** wording of topic */
  title: PropTypes.string,
  /** icon in front of text */
  icon: PropTypes.string,
  /** onClick button */
  onClick: PropTypes.func,
  /** icon when hover */
  iconHover: PropTypes.string,
  /** Passing the hr margin */
  hrMargin: PropTypes.string,
  /** button id */
  buttonId: PropTypes.string,
  /** button wording */
  buttonText: PropTypes.string,
  /** topic margin */
  topicMargin: PropTypes.string,
}

export default TopicHeaderWithUnderline
