import get from 'lodash/get'
import { createSelector } from 'reselect'

import { KEY } from '../constants'
import { rentPaymentModalInitialState } from '../reducers'

export const selectRentPaymentModalState = state =>
  get(state, KEY.RENT_PAYMENT_MODAL_STATE, rentPaymentModalInitialState)

export const makeSelectRentPaymentModalState = () =>
  createSelector(selectRentPaymentModalState, state => state)

export const makeSelectUpdateYourInformationModalState = () =>
  createSelector(makeSelectRentPaymentModalState(), state =>
    get(state, 'updateYourInformationModalState'),
  )

export const makeSelectMarkAsPaidModalState = () =>
  createSelector(makeSelectRentPaymentModalState(), state => get(state, 'markAsPaid'))

export const selectCreatePropertyModalState = createSelector(
  makeSelectRentPaymentModalState(),
  state => get(state, 'createProperty'),
)

export const selectCreateRenterInfoModalState = createSelector(
  makeSelectRentPaymentModalState(),
  state => get(state, 'createRenterInfo'),
)
