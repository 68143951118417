import React from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import tracker from 'tracker'

import { InviteLandlordRentPaymentModalComponent } from 'components/organisms/invite-landlord-rent-payment'
import { selectProfile } from 'containers/user/selectors'
import { RENT_PAYMENT } from 'tracker/const'
import { withReducer, withSaga } from './connect'
import {
  closeInviteLandlordRentPaymentModal,
  getLandlordInformationCall,
  inviteAfterApplicantAccepted,
} from './actions'
import {
  selectIsOpen,
  selectPropertyId,
  selectLandlordInformation,
  selectIsSubmitting,
} from './selectors'

export const InviteLandlordRentPaymentModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsOpen)
  const isSubmitting = useSelector(selectIsSubmitting)
  const propertyId = useSelector(selectPropertyId)
  const landlordInformation = useSelector(selectLandlordInformation)
  const { userType } = useSelector(selectProfile)

  React.useEffect(() => {
    if (isOpen && propertyId) {
      dispatch(getLandlordInformationCall({ propertyId }))
    }
  }, [isOpen])

  const handleCloseModal = () => {
    dispatch(closeInviteLandlordRentPaymentModal())
  }

  const handleSubmitForm = ({ firstName, lastName, email }) => {
    tracker.trackEvent(RENT_PAYMENT.EVENT_NAME.CLICK_SEND_INVITE_LANDLORD, { user_type: userType })
    dispatch(
      inviteAfterApplicantAccepted({
        landlordFirstName: firstName,
        landlordLastName: lastName,
        landlordEmail: email,
      }),
    )
  }

  return (
    <InviteLandlordRentPaymentModalComponent
      isOpen={isOpen}
      isLoading={landlordInformation?.isFetching}
      isSubmitting={isSubmitting}
      email={landlordInformation?.email}
      firstName={landlordInformation?.firstName}
      lastName={landlordInformation?.lastName}
      onClose={handleCloseModal}
      onSubmit={handleSubmitForm}
    />
  )
}
export default compose(withReducer, withSaga)(InviteLandlordRentPaymentModal)
