import generateApiCall from 'utils/generate-api-call'

import {
  SEND_INVITATION_EMAIL,
  SEND_INVITATION_EMAIL_REQUESTED,
  SEND_INVITATION_EMAIL_SUCCEEDED,
  SEND_INVITATION_EMAIL_FAILED,
} from './constants'

export const sendInvitationEmail = payload => ({
  type: SEND_INVITATION_EMAIL,
  payload,
})

export const sendInvitationEmailAPI = generateApiCall([
  SEND_INVITATION_EMAIL_REQUESTED,
  SEND_INVITATION_EMAIL_SUCCEEDED,
  SEND_INVITATION_EMAIL_FAILED,
])
