import { createSelector } from "reselect"
import { initialState } from "./reducer"

export const selectImportListing = ({ importListing }) =>
  importListing || initialState

export const selectIsFetched = createSelector(
  selectImportListing,
  ({ isFetched }) => isFetched,
)

export const selectIsMLSListingImportable = createSelector(
  selectImportListing,
  ({ MLSListing }) => MLSListing,
)
