import { Checkbox, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tag from '@rentspree/component-2023.components.atoms.tag'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import { query } from '@rentspree/path'
import React from 'react'

import history from 'utils/history'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import {
  INCENTIVE_V5,
  rentField,
  selectionOptions,
  INCENTIVE_V5_QUERY_PARAM_CAMPAIGN_NAME,
} from 'v3/containers/overhaul-rent-payment/constants'
import { PAYMENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

const SelectionCard = styled(Card)(() => ({
  width: '90%',
  maxWidth: '500px',
  margin: '15px auto',
  padding: '10px 0!important',
  display: 'flex',
  flexDirection: 'row!important',
  justifyContent: 'space-between',
  border: 'ipx solid #2222221F',
}))

export const LandingPageComponent = ({ paymentSelections, setPaymentSelections }) => {
  const queryString = query.parse(history?.location?.search)
  const isFromIncentiveV5Campaign =
    queryString?.campaignName === INCENTIVE_V5_QUERY_PARAM_CAMPAIGN_NAME

  return (
    <>
      <TitleText>{PAYMENT_DETAILS.LANDING.TITLE}</TitleText>
      {Object.keys(selectionOptions).map(key => {
        const val = selectionOptions[key]
        const selected = paymentSelections?.[key] || false
        const showIncentiveV5Tag = val === selectionOptions[rentField] && isFromIncentiveV5Campaign
        return (
          <SelectionCard
            flex
            key={key}
            sx={{ margin: '15px 0px' }}
            variant="outlined"
            size="normal"
            onClick={() => {
              setPaymentSelections({
                ...paymentSelections,
                [key]: !selected,
              })
            }}
          >
            <CardElement
              sx={{
                alignContent: 'center',
                justifyContent: 'left',
                flex: 'grow',

                // remove padding for mobile proper ui, there's still gap in-use
                paddingRight: '0 !important',
              }}
            >
              <Typography variant="h5" sx={{ fontSize: '2rem', textAlign: 'left' }}>
                {val}
                {showIncentiveV5Tag && (
                  <Tag label={INCENTIVE_V5.TAG} color="purple" sx={{ marginLeft: '8px' }} />
                )}
              </Typography>
            </CardElement>
            <CardElement sx={{ width: 'fit-content' }}>
              <Checkbox
                inputProps={{
                  'data-testid': `select-payment-type-${key.toLowerCase()}`,
                }}
                size="large"
                checked={selected}
                sx={{
                  '&.Mui-checked svg': {
                    fill: 'black',
                  },
                }}
              />
            </CardElement>
          </SelectionCard>
        )
      })}
      {isFromIncentiveV5Campaign && (
        <Typography variant="body-medium">{INCENTIVE_V5.REMARK}</Typography>
      )}
    </>
  )
}
