import produce from "immer"
import {
  GET_LRA_REQUEST_REQUEST,
  GET_LRA_REQUEST_SUCCESS,
  GET_LRA_REQUEST_FAILURE,
  UPDATE_LRA_REQUEST_REQUEST,
  UPDATE_LRA_REQUEST_SUCCESS,
  UPDATE_LRA_REQUEST_FAILURE,
} from "./constants"

export const initialState = {
  screeningFeeData: {
    creditReportFee: "",
    outOfPocketExpense: {
      for: "",
      amount: "",
    },
    processingFee: "",
  },
  isFetchingLraRequest: false,
  isUpdatingLraRequest: false,
}

/* eslint-disable default-case, no-param-reassign */
const stepUpdateScreeningFeeReducer = (
  state = initialState,
  { type, payload },
) =>
  produce(state, draft => {
    switch (type) {
      case GET_LRA_REQUEST_REQUEST:
        draft.isFetchingLraRequest = true
        break
      case GET_LRA_REQUEST_SUCCESS:
        draft.screeningFeeData = payload
        draft.isFetchingLraRequest = false
        break
      case GET_LRA_REQUEST_FAILURE:
        draft.isFetchingLraRequest = false
        break
      case UPDATE_LRA_REQUEST_REQUEST:
        draft.isUpdatingLraRequest = true
        break
      case UPDATE_LRA_REQUEST_SUCCESS:
        draft.screeningFeeData = payload
        draft.isUpdatingLraRequest = false
        break
      case UPDATE_LRA_REQUEST_FAILURE:
        draft.isUpdatingLraRequest = false
        break
    }
    return draft
  })

export default stepUpdateScreeningFeeReducer
