import { API_URL } from 'env'

export const CREATE_AND_ASSIGN_PROPERTY_CALL = 'CREATE_AND_ASSIGN_PROPERTY_CALL'
export const CREATE_AND_ASSIGN_PROPERTY_REQUEST = 'CREATE_AND_ASSIGN_PROPERTY_REQUEST'
export const CREATE_AND_ASSIGN_PROPERTY_SUCCESS = 'CREATE_AND_ASSIGN_PROPERTY_SUCCESS'
export const CREATE_AND_ASSIGN_PROPERTY_FAILURE = 'CREATE_AND_ASSIGN_PROPERTY_FAILURE'

export const CREATE_AND_SELECT_PROPERTY_CALL = 'CREATE_AND_SELECT_PROPERTY_CALL'
export const CREATE_AND_SELECT_PROPERTY_REQUEST = 'CREATE_AND_SELECT_PROPERTY_REQUEST'
export const CREATE_AND_SELECT_PROPERTY_SUCCESS = 'CREATE_AND_SELECT_PROPERTY_SUCCESS'
export const CREATE_AND_SELECT_PROPERTY_FAILURE = 'CREATE_AND_SELECT_PROPERTY_FAILURE'

export const ASSIGN_PROPERTY_CALL = 'ASSIGN_PROPERTY_CALL'
export const ASSIGN_PROPERTY_REQUEST = 'ASSIGN_PROPERTY_REQUEST'
export const ASSIGN_PROPERTY_SUCCESS = 'ASSIGN_PROPERTY_SUCCESS'
export const ASSIGN_PROPERTY_FAILURE = 'ASSIGN_PROPERTY_FAILURE'

export const GET_ASSIGN_PROPERTY_LIST_CALL = 'GET_ASSIGN_PROPERTY_LIST_CALL'
export const GET_ASSIGN_PROPERTY_LIST_REQUEST = 'GET_ASSIGN_PROPERTY_LIST_REQUEST'
export const GET_ASSIGN_PROPERTY_LIST_SUCCESS = 'GET_ASSIGN_PROPERTY_LIST_SUCCESS'
export const GET_ASSIGN_PROPERTY_LIST_FAILURE = 'GET_ASSIGN_PROPERTY_LIST_FAILURE'

export const OPEN_CREATE_PROPERTY_MODAL = 'OPEN_CREATE_PROPERTY_MODAL'
export const CLOSE_CREATE_PROPERTY_MODAL = 'CLOSE_CREATE_PROPERTY_MODAL'
export const CLEAR_CREATE_PROPERTY_MODAL_ERROR = 'CLEAR_CREATE_PROPERTY_MODAL_ERROR'

export const OPEN_ASSIGN_PROPERTY_MODAL = 'OPEN_ASSIGN_PROPERTY_MODAL'
export const CLOSE_ASSIGN_PROPERTY_MODAL = 'CLOSE_ASSIGN_PROPERTY_MODAL'

export const OPEN_EXISTING_SUBMISSION_ERROR_MODAL = 'OPEN_EXISTING_SUBMISSION_ERROR_MODAL'

export const AUTO_SELECT_PROPERTY = 'AUTO_SELECT_PROPERTY'
export const SELECT_PROPERTY = 'SELECT_PROPERTY'
export const APPEND_ASSIGN_PROPERTY_LIST = 'APPEND_ASSIGN_PROPERTY_LIST'

export const CREATE_PROPERTY_API = `${API_URL}/api/v2/agent/properties`
export const ASSIGN_PROPERTY_API = `${API_URL}/api/v2/agent/submission-participants/:submissionParticipantId/rental-submission/assign-property`

export const PROPERTY_CREATED_TOAST_CONFIG = {
  titleMessage: 'Property created successfully.',
  status: 'success',
  timeOut: 5000,
  width: '500px',
}

export const DUPLICATED_PROPERTY_TOAST_CONFIG = {
  titleMessage: 'The property address you tried to create already exists.',
  status: 'warning',
  timeOut: 5000,
  width: '500px',
}

export const NEXT_ACTION_TYPES = {
  ACCEPT_DENY_FROM_TABLE: 'accept/deny from tenant screening list',
  ACCEPT_DENY: 'accept/deny',
  SHARE: 'share',
  EXPORT_CAR_FORM: 'export car form',
}

export const CREATE_PROPERTY_MODAL_TITLE = 'Enter the property address'
