import get from 'lodash/get'
import { generateAddress } from '@rentspree/helper'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getAssignProperty = state => get(state, 'assignProperty', initialState)

export const selectIsSubmitting = createSelector(getAssignProperty, assignProperty =>
  get(assignProperty, 'isSubmitting', initialState.isSubmitting),
)

export const selectCreatePropertyModal = createSelector(getAssignProperty, assignProperty =>
  get(assignProperty, 'createPropertyModal', initialState.createPropertyModal),
)

export const getAllPropertyList = state =>
  get(state, ['assignProperty', 'propertyList'], initialState.propertyList)

export const selectIsLoading = createSelector(getAllPropertyList, state =>
  get(state, 'isFetching', initialState.propertyList.isFetching),
)

export const selectSelectedPropertyId = createSelector(getAllPropertyList, state =>
  get(state, 'selectedPropertyId', initialState.propertyList.selectedPropertyId),
)

export const selectPropertyList = createSelector(getAllPropertyList, state =>
  get(state, 'data', initialState.propertyList.data).map(property => ({
    data: property,
    label: generateAddress(property),
    value: get(property, '_id', ''),
  })),
)

export const selectError = createSelector(getAllPropertyList, state => get(state, 'error', null))

export const selectExistingSubmissionErrorModal = createSelector(
  getAssignProperty,
  assignProperty =>
    get(assignProperty, 'existingSubmissionErrorModal', initialState.existingSubmissionErrorModal),
)

export const selectAssignPropertyModal = createSelector(getAssignProperty, assignProperty =>
  get(assignProperty, 'assignPropertyModal'),
)
