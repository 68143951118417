import produce from "immer"
import {
  GET_IS_WHITELISTING_REQUEST,
  GET_IS_WHITELISTING_SUCCESS,
  GET_IS_WHITELISTING_FAILURE,
} from "./constants"

export const initialState = {
  data: {},
  isLoading: false,
  isError: false,
}

/* eslint-disable default-case, no-param-reassign */
const checkWhitelistingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_IS_WHITELISTING_REQUEST:
        draft.isLoading = true
        draft.isError = false
        break
      case GET_IS_WHITELISTING_SUCCESS:
        draft.data = action.payload
        draft.isLoading = false
        draft.isError = false
        break
      case GET_IS_WHITELISTING_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
    }
    return draft
  })

export default checkWhitelistingReducer
