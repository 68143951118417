import { connect } from 'react-redux'
import { compose } from 'redux'

import { withReducer } from 'containers/tenant-screening/assign-property/connect'
import { createStructuredSelector } from 'reselect'

import { selectProfile } from 'containers/user/selectors'

const SelectAgentTypeConnect = ComposedComponent =>
  compose(
    connect(
      createStructuredSelector({
        profile: selectProfile,
      }),
    ),
    withReducer,
  )(ComposedComponent)

export default SelectAgentTypeConnect
