import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { SearchDropdown } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"
import { TextWithIcon } from "components/molecules/text-with-icon"
import { getIconStatus } from "../filter-mobile/filter-mobile"

// TODO: replace static image to Icon from component V2 once the icons are ready for application list
export const OptionIcon = styled.img`
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: -2px;
  width: ${props => (props.small ? "16px" : "23px")};
  height: ${props => (props.small ? "16px" : "23px")};
`

export const optionComponent = (iconSmall, options) => props => {
  const { data, children } = props
  return (
    <SearchDropdown.Option {...props}>
      <OptionIcon
        small={iconSmall}
        src={getIconStatus(data.value, options)}
        alt={data.value}
      />
      {children}
    </SearchDropdown.Option>
  )
}
export const optionComponentV2 = () => props => {
  const { data, children } = props
  return (
    <SearchDropdown.Option {...props}>
      <TextWithIcon
        icon={data.icon}
        iconColor={data.iconColor}
        text={children}
      />
    </SearchDropdown.Option>
  )
}

export const valueComponent = (iconSmall, options) => props => {
  const { data, children } = props
  return (
    <SearchDropdown.SingleValue {...props}>
      <span className="Select-value-label">
        <OptionIcon
          small={iconSmall}
          src={getIconStatus(data.value, options)}
          alt={data.value}
        />
        {children}
      </span>
    </SearchDropdown.SingleValue>
  )
}

export const valueComponentV2 = () => props => {
  const { data, children } = props
  return (
    <SearchDropdown.SingleValue {...props}>
      <span className="Select-value-label">
        <TextWithIcon
          icon={data.icon}
          iconColor={data.iconColor}
          text={children}
        />
      </span>
    </SearchDropdown.SingleValue>
  )
}

const FilterSelect = ({
  id,
  name,
  options,
  status,
  onChange,
  iconSmall,
  newIconOption,
}) => (
  <SearchDropdown
    id={id}
    name={name}
    className="filter-select"
    options={options}
    components={{
      Option: newIconOption
        ? optionComponentV2(options)
        : optionComponent(iconSmall, options),
      SingleValue: newIconOption
        ? valueComponentV2()
        : valueComponent(iconSmall, options),
    }}
    isSearchable={false}
    onChange={onChange}
    value={options.filter(({ value }) => value === status)[0]}
    noLabel
    styles={{
      control: base => ({
        ...base,
        border: `1px solid ${COLOR.newGrey} `,
        boxShadow: "none",
        "&:hover": {
          border: `1px solid ${COLOR.newGrey} `,
        },
        borderRadius: "0px",
        height: "40px",
      }),
      indicatorSeparator: () => {
        "none"
      },
      singleValue: base => ({
        ...base,
        display: "flex",
        alignItems: "center",
        height: "100%",
        color: COLOR.textBlack,
        padding: "0px 0px 0px 5px",
        fontSize: "16px",
      }),
      valueContainer: base => ({
        ...base,
        height: "40px",
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: COLOR.placeholderGrey,
        "&:hover": {
          color: COLOR.blueHover,
        },
        "&:active": {
          color: COLOR.blueHover,
        },
      }),
      option: (base, state) => {
        let backgroundColor = "white"
        if (state.isSelected) backgroundColor = "rgba(153, 209, 255, 0.75)"
        if (state.isFocused && !state.isSelected)
          backgroundColor = "rgba(153, 209, 255, 0.25)"
        return {
          padding: "10px 15px",
          color: COLOR.textBlack,
          cursor: "pointer",
          backgroundColor,
        }
      },
      menu: base => ({
        ...base,
        border: `1px solid ${COLOR.lineGrey}`,
        marginTop: "0px",
        boxShadow: "none",
        maxHeight: "200px",
        overflowY: "hidden",
      }),
      menuList: base => ({
        ...base,
        paddingBottom: "0px",
        paddingTop: "0px",
        maxHeight: "200px",
        fontSize: "16px",
      }),
    }}
  />
)

FilterSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.string,
  options: PropTypes.array,
  iconSmall: PropTypes.string,
}

FilterSelect.defaultProps = {
  id: "",
  onChange: () => { },
  status: "",
  options: [],
  iconSmall: "",
}

export default FilterSelect
