import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import OnImagesLoaded from 'react-on-images-loaded'

import { ReportLoading } from 'components/reports/loading'
import CreditReportComponent from 'components/reports/screening-reports/shareable/credit-report'

import { hasInLieuOfCredit } from 'containers/reports/helper'
import { REPORT_TYPES } from '../../constants'
import { withConnect } from '../connect'
import withReportAvailable from '../../with-report-available'
import withExpired from '../with-expired'
import withScreeningReportError from '../with-error'
import { shouldGetCreditReport } from '../helper'

// TODO: lnr-2991 clean up duplicated container
export const ShareableCreditReport = ({
  getCreditReport,
  fetchedCreditReportImage,
  creditReportDetail,
  isFetchingCreditReport,
  applicant,
  externalRenterDetail,
  externalLandlordDetail,
  generatedOn,
  expiresOn,
  rentalDetail,
  showHeaderComponent = true,
  customBorder,
  customMargin,
  appDetail,
}) => {
  React.useEffect(() => {
    if (shouldGetCreditReport(rentalDetail, creditReportDetail)) {
      getCreditReport(get(rentalDetail, '_id'))
    }
  }, [rentalDetail])
  const creditReport = get(applicant, 'CreditReport[0].reportData')
  const isFrozenFileOfCreditReport = creditReport?.applicants[0]?.status?.frozenFile || false
  if (isFetchingCreditReport) {
    return <ReportLoading />
  }
  const shouldRenderInLieuOfCreditUnavailable = hasInLieuOfCredit(appDetail)

  return (
    <OnImagesLoaded
      onLoaded={fetchedCreditReportImage}
      onTimeout={fetchedCreditReportImage}
      timeout={30000}
    >
      <CreditReportComponent
        expiresOn={expiresOn}
        generatedOn={generatedOn}
        creditReport={creditReport}
        renter={externalRenterDetail}
        landlord={externalLandlordDetail}
        isFrozenFileOfCreditReport={isFrozenFileOfCreditReport}
        showHeaderComponent={showHeaderComponent}
        customBorder={customBorder}
        customMargin={customMargin}
        shouldRenderInLieuOfCreditUnavailable={shouldRenderInLieuOfCreditUnavailable}
      />
    </OnImagesLoaded>
  )
}

export default compose(
  withReportAvailable(REPORT_TYPES.CREDIT_REPORT),
  withScreeningReportError,
  withExpired,
  withConnect,
)(ShareableCreditReport)
