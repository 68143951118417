import { createSelector } from "reselect"
import get from "lodash/get"
import { initialState } from "./reducer"

const getPropertyList = state => get(state, "propertyList", initialState)

export const makeSelectPropertyList = () =>
  createSelector(
    getPropertyList,
    state => state,
  )

export const makeSelectList = () =>
  createSelector(
    makeSelectPropertyList(),
    ({ list }) => list,
  )

export const makeSelectPagination = () =>
  createSelector(
    makeSelectPropertyList(),
    ({ pagination }) => pagination,
  )

export const makeSelectIsFetching = () =>
  createSelector(
    makeSelectPropertyList(),
    ({ isFetching }) => isFetching,
  )

export const makeSelectError = () =>
  createSelector(
    makeSelectPropertyList(),
    ({ error }) => error,
  )
