import { takeLatest, all, put, call } from 'redux-saga/effects'
import get from 'lodash/get'
import { push } from 'connected-react-router'
import { buildPath } from '@rentspree/path'
import { apiInstance } from 'utils/api-interceptor'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import { FILTER_PROPERTY } from 'containers/property-list/constants'
import { SINGLE_APPLICATION, PROPERTY_API_V2 } from 'constants/route'
import { addToast } from 'containers/toast/actions'
import tracker from 'tracker'
import { EVENT_ACCEPT_DENY, EVENT_TENANT_SCREENING } from 'tracker/const'
import { openAcceptDenyModal } from 'containers/accept-deny/actions'
import { getRentalSubmissionCall } from 'containers/reports/actions'
import { toggleShareReportModal } from 'containers/share-report/actions'
import { USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { saveNewListingTrackerWithUserRoleMapper } from 'tracker/tracker-mapper'
import {
  createAndAssignPropertyApiState,
  createAndSelectPropertyApiState,
  assignPropertyApiState,
  getAssignPropertyListApiState,
  autoSelectProperty,
  appendAssignPropertyList,
  closeCreatePropertyModal,
  openExistingSubmissionErrorModal,
  closeAssignPropertyModal,
} from './actions'
import {
  CREATE_AND_ASSIGN_PROPERTY_CALL,
  CREATE_AND_SELECT_PROPERTY_CALL,
  ASSIGN_PROPERTY_API,
  CREATE_PROPERTY_API,
  ASSIGN_PROPERTY_CALL,
  GET_ASSIGN_PROPERTY_LIST_CALL,
  PROPERTY_CREATED_TOAST_CONFIG,
  DUPLICATED_PROPERTY_TOAST_CONFIG,
  NEXT_ACTION_TYPES,
} from './constants'
import { getSubmissionParticipantList } from '../list/actions'

export const transformProperty = property => ({
  ...property,
  zip: property.zipcode,
})

export const DEFAULT_QUERY = {
  page: 1,
  perPage: 50,
  filter: { status: FILTER_PROPERTY.ACTIVE },
}

export const createPropertyApi = property =>
  apiInstance.post(buildPath(CREATE_PROPERTY_API), transformProperty(property))

export const assignPropertyApi = ({ submissionParticipantId, propertyId }) =>
  apiInstance.put(
    buildPath(ASSIGN_PROPERTY_API, {
      submissionParticipantId,
    }),
    { propertyId },
  )

export const trackCreateProperty = ({ isNew, representing, hasLandlordEmail }) =>
  tracker.trackEvent(EVENT_TENANT_SCREENING.EVENT_NAME.CREATE_NEW_PROPERTY, {
    [EVENT_TENANT_SCREENING.EVENT_PROPERTY.IS_DUPLICATE_ADDRESS]: !isNew,
    [EVENT_TENANT_SCREENING.EVENT_PROPERTY.REPRESENTING]: representing,
    [EVENT_TENANT_SCREENING.EVENT_PROPERTY.HAS_LANDLORD_EMAIL]: hasLandlordEmail,
  })

export const trackAssignProperty = ({ payload, response }) => {
  if (payload?.nextAction) {
    tracker.trackEvent(EVENT_TENANT_SCREENING.EVENT_NAME.ASSIGN_PROPERTY, {
      rental_id: get(response, 'rentalSubmission._id'),
      applicant: response?.rentalSubmission.email,
      property_id: response?.rentalSubmission.property,
      click_from: payload.nextAction,
      screening_without_property: true,
      new_property: payload.isAssignToCreatedProperty,
    })
  } else {
    tracker.trackEvent(EVENT_TENANT_SCREENING.EVENT_NAME.SELECT_PROPERTY)
  }
}

export function* updateAfterAssignProperty({ payload, response }) {
  if (payload.nextAction === NEXT_ACTION_TYPES.ACCEPT_DENY_FROM_TABLE) {
    yield put(
      getSubmissionParticipantList(
        payload.queryOptions.page,
        payload.queryOptions.filter,
        payload.queryOptions.search,
      ),
    )
  } else {
    yield put(
      getRentalSubmissionCall({
        propertyId: get(response, 'rentalSubmission.property'),
        rentalId: get(response, 'rentalSubmission._id'),
      }),
    )
  }
}

export function* assignPropertySaga({ payload }) {
  yield put(assignPropertyApiState.request())
  try {
    const response = yield call(assignPropertyApi, {
      submissionParticipantId: payload.submissionParticipantId,
      propertyId: payload.propertyId,
    })
    yield call(trackAssignProperty, { payload, response })
    yield put(assignPropertyApiState.success(response))
    if (payload.nextAction) {
      yield call(updateAfterAssignProperty, { payload, response })
      yield put(closeAssignPropertyModal())
      if (
        [NEXT_ACTION_TYPES.ACCEPT_DENY, NEXT_ACTION_TYPES.ACCEPT_DENY_FROM_TABLE].includes(
          payload.nextAction,
        )
      ) {
        yield put(
          openAcceptDenyModal({
            ...payload.nextActionProps,
            propertyId: payload.propertyId,
            propertyAddress: payload.propertyAddress,
            clickFrom: EVENT_ACCEPT_DENY.CLICK_FROM.ASSIGN_PROPERTY,
          }),
        )
      } else if (payload.nextAction === NEXT_ACTION_TYPES.SHARE) {
        yield put(toggleShareReportModal(payload.nextActionProps))
      } else if (payload.nextAction === NEXT_ACTION_TYPES.EXPORT_CAR_FORM) {
        yield put(
          push({
            pathname: buildPath(SINGLE_APPLICATION, {
              propertyId: response?.rentalSubmission.property,
              rentalAppId: get(response, 'rentalSubmission._id'),
            }),
          }),
        )
      }
    } else {
      yield put(
        push({
          pathname: buildPath(SINGLE_APPLICATION, {
            propertyId: response?.rentalSubmission.property,
            rentalAppId: get(response, 'rentalSubmission._id'),
          }),
        }),
      )
    }
  } catch (err) {
    if (err?.data?.code === 'duplicated_rental_submission') {
      yield put(closeAssignPropertyModal())
      yield put(openExistingSubmissionErrorModal(true, err?.data?.errors))
    } else {
      yield put(openSweetAlertBaseError(err.status))
    }
    yield put(assignPropertyApiState.failure(err))
  }
}

export function* createAndAssignPropertySaga({ payload }) {
  yield put(createAndAssignPropertyApiState.request())
  try {
    const createdProperty = yield call(createPropertyApi, payload.property)
    yield call(trackCreateProperty, { isNew: createdProperty.isNew })
    const response = yield call(assignPropertyApi, {
      submissionParticipantId: payload.submissionParticipantId,
      propertyId: get(createdProperty, '_id'),
    })
    yield put(createAndAssignPropertyApiState.success(response))
    yield put(
      push({
        pathname: buildPath(SINGLE_APPLICATION, {
          propertyId: get(createdProperty, '_id'),
          rentalAppId: get(response, 'rentalSubmission._id'),
        }),
      }),
    )
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createAndAssignPropertyApiState.failure(err))
  }
}

export function* createAndSelectPropertySaga({ payload }) {
  yield put(createAndSelectPropertyApiState.request())
  const { property } = payload
  try {
    const createdProperty = yield call(createPropertyApi, property)
    if (createdProperty.isNew) {
      yield put(appendAssignPropertyList(createdProperty))
      yield put(addToast(PROPERTY_CREATED_TOAST_CONFIG))
    } else {
      yield put(addToast(DUPLICATED_PROPERTY_TOAST_CONFIG))
    }
    const { userRoleForProperty = "", landlordProfile = {} } = property
    const representing = saveNewListingTrackerWithUserRoleMapper(
      userRoleForProperty,
    )
    let hasLandlordEmail = 'none'
    if (userRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT) {
      hasLandlordEmail = !!landlordProfile && !!landlordProfile.email
    }
    yield call(trackCreateProperty, { isNew: createdProperty.isNew, representing, hasLandlordEmail })
    yield put(autoSelectProperty(get(createdProperty, '_id')))
    yield put(createAndSelectPropertyApiState.success({ propertyId: get(createdProperty, '_id') }))
    yield put(closeCreatePropertyModal())
  } catch (error) {
    yield put(createAndSelectPropertyApiState.failure())
  }
}

export const callGetAllPropertyAPI = () =>
  apiInstance.get(buildPath(PROPERTY_API_V2, {}, DEFAULT_QUERY))

export function* getAllPropertySaga() {
  yield put(getAssignPropertyListApiState.request())
  try {
    const response = yield call(callGetAllPropertyAPI)
    yield put(getAssignPropertyListApiState.success(response))
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(getAssignPropertyListApiState.failure(err))
  }
}

export function* watchAssignProperty() {
  yield takeLatest(ASSIGN_PROPERTY_CALL, assignPropertySaga)
}

export function* watchCreateAndAssignProperty() {
  yield takeLatest(CREATE_AND_ASSIGN_PROPERTY_CALL, createAndAssignPropertySaga)
}

export function* watchCreateAndSelectProperty() {
  yield takeLatest(CREATE_AND_SELECT_PROPERTY_CALL, createAndSelectPropertySaga)
}

export function* watchGetAllPropertyApiCall() {
  yield takeLatest(GET_ASSIGN_PROPERTY_LIST_CALL, getAllPropertySaga)
}

export function* rootSaga() {
  yield all([
    watchCreateAndAssignProperty(),
    watchCreateAndSelectProperty(),
    watchAssignProperty(),
    watchGetAllPropertyApiCall(),
  ])
}

export default rootSaga
