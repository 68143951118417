/* eslint quote-props: "off" */

export const SCORE_FACTOR_CODES = {
  "00": "No adverse factor",
  "01": "Amount owed on accounts is too high",
  "02": "Level of delinquency on accounts",
  "03": "Too few bank revolving accounts",
  "04": "Too many bank or national revolving accounts",
  "05": "Too many accounts with balances",
  "06": "Too many consumer finance company accounts",
  "07": "Account payment history is too new to rate",
  "08": "Too many inquiries last months",
  "09": "Too many accounts recently opened",
  "10":
    "Proportion of balances to credit limits is too high on bank revolving or other revolving accounts",
  "11": "Amount owed on revolving accounts is too high",
  "12": "Length of time revolving accounts have been established",
  "13": "Time since delinquency is too recent or unknown",
  "14": "Length of time accounts have been established",
  "15": "Lack of recent bank revolving information",
  "16": "Lack: of recent revolving account information",
  "17": "No recent non-mortgage balance information",
  "18": "Number of accounts with delinquency",
  "19": "Too few accounts currently paid as agreed",
  "20":
    "Length of time since derogatory public record or collection is too short",
  "21": "Amount past due on accounts",
  "22": "Account(s) not paid as agreed and/or legal item filed",
  "23": "Number of bank or national revolving accounts with balances",
  "24": "No recent revolving balances",
  "25": "Length of time installment loans have been established",
  "26": "Number of revolving accounts",
  "27": "Insufficient number of satisfactory accounts",
  "28": "Number of established accounts",
  "29": "No recent bankcard balances",
  "30": "Time since most recent account opening is too short",
  "31": "Too few accounts with recent payment information",
  "32": "Lack of recent installment loan information",
  "33": "Proportion of loan balances to loan amounts is too high",
  "34": "Amount owed on delinquent accounts",
  "36": "Length of time open installment loans have been established",
  "37":
    "Number of finance company accounts established relative to length of finance history",
  "38": "Serious delinquency, and derogatory public record or collection filed",
  "39": "Serious delinquency",
  "40": "Derogatory public record or collection filed",
  "41": "No recent retail balances",
  "42":
    "Length of time since most recent consumer finance co. account established",
  "50": "Lack of recent retail account information",
  "56": "Amount owed on retail accounts",
  "98": "Lack of recent auto finance loan information",
  "99": "Lack of recent consumer finance company account information",
}

export const INDUSTRY_CODES = {
  A: "Automotive",
  B: "Banks",
  C: "Clothing",
  D: "Department stores",
  E: "Employment",
  F: "Finance, personal",
  G: "Groceries",
  H: "Home furnishing",
  I: "Insurance",
  J: "Jewelry, cameras, computers",
  K: "Contractors",
  L: "Building materials",
  M: "Medical and related health",
  N: "Credit card, travel/entertainment ",
  O: "Oil companies",
  P: "Personal services other than medical",
  Q: "Finance other than personal",
  R: "Real estate, public accommodations",
  S: "Sporting goods",
  T: "Farm/garden supplies",
  U: "Utilities and fuel",
  V: "Government",
  W: "Wholesale",
  X: "Advertising",
  Y: "Collection services",
  Z: "Miscellaneous",
}

export const ACCOUNT_RAILING_CODES = {
  "00": "No rating",
  "01": "Paid or paying as agreed",
  "02": "30 days past due",
  "03": "60 days past due",
  "04": "90 days past due",
  "05": "120 days past due",
  "07": "Wage earner or similar plan",
  "08": "Repossession",
  "8A": "Voluntary surrender",
  "8D": "Legal repossession",
  "8P": "Payment after repossession",
  "8R": "Repossession redeemed",
  "09": "Charged off as bad debt",
  "9B": "Collection account",
  "9P": "Payment after charge off/collection",
  SL: "Slow pay",
  UC: "Unclassified",
  UR: "Unrated",
}

export const ACCOUNT_TYPE = {
  I: "Installment",
  M: "Mortgage",
  O: "Open",
  C: "Credit / Line of Credit",
  R: "Revolving",
}

export const REMARK_CODES = {
  AAP: "Loan assumed by another party",
  ACQ: "Acquired from another lender",
  ACR: "Account closed due to refinance",
  ACT: "Account closed due to transfer",
  AFR: "Account acquired by RTC/FDIC/NCUA",
  AID: "Account information disputed by consumer",
  AJP: "Adjustment pending",
  AMD: "Active military duty",
  AND: "Affected by natural/declared disaster",
  BAL: "Balloon payment",
  BCD: "Bankruptcy/dispute of account information/account closed by consumer",
  BKC: "Bankruptcy/account closed by consumer",
  BKD: "Bankruptcy/dispute of account information",
  BKL: "Included in bankruptcy",
  BKW: "Bankruptcy withdrawn",
  BRC:
    "Bankruptcy/dispute resolved/consumer disagrees/account closed by consumer",
  BRR: "Bankruptcy/dispute resolved/consumer disagrees",
  CAD: "Dispute of account information/closed by consumer",
  CBC: "Account closed by consumer",
  CBD: "Dispute resolved; consumer disagrees/account closed by consumer",
  CBG: "Account closed by credit grantor",
  CBL: "Chapter 7 bankruptcy",
  CBR: "Chapter 11 bankruptcy",
  CBT: "Chapter 12 bankruptcy",
  CCD: "Account closed by consumer/Chapter 7",
  CDC: "Chap. 7/dispute of account information/account closed by consumer",
  CDD: "Account closed by consumer/Chapter 11",
  CDL: "Chap. 7/dispute of account information",
  CDR: "Chap. 11/dispute of account information",
  CDT: "Chap. 12/dispute of account information",
  CED: "Account closed by consumer/Chapter 12",
  CFD: "Account in dispute/closed by consumer",
  CLA: "Placed for collection",
  CLB: "Contingent liability—corporate defaults",
  CLC: "Account closed",
  CLO: "Closed",
  CLR: "Credit line reduced due to collateral depreciation",
  CLS: "Credit line suspended",
  CPB: "Customer pays balance in full each month",
  CRB: "Collateral released by creditor/balance owing",
  CRC: "Chap. 11/dispute of account information/account closed by consumer",
  CRD: "Chap. 7/dispute resolved/consumer disagrees/account closed by consumer",
  CRL: "Chap. 7/dispute resolved/consumer disagrees",
  CRR:
    "Chap. 11/dispute resolved/consumer disagrees/account closed by consumer",
  CRT:
    "Chap. 12/dispute resolved/consumer disagrees/account closed by consumer",
  CRV: "Chap. 11/dispute resolved/consumer disagrees",
  CTC: "Chap. 12/dispute of account information/account closed by consumer",
  CTR: "Account closed—transfer or refinance",
  CTS: "Contact subscriber",
  CTV: "Chap. 12/dispute resolved/consumer disagrees",
  DEC: "Deceased",
  DLU: "Deed in lieu",
  DM: "Bankruptcy dismissed",
  DRC: "Dispute resolved—customer disagrees",
  DRG: "Dispute resolved reported by grantor",
  ER: "Election of remedy",
  ETB: "Early termination/balance owing",
  ETD: "Early termination by default",
  ETI: "Early termination/insurance loss",
  ETO: "Early termination/obligation satisfied",
  ETS: "Early termination/status pending",
  FCL: "Foreclosure",
  FOR: "Account in forbearance",
  FPD: "Account paid, foreclosure started",
  FPI: "Foreclosure initiated",
  FRD: "Foreclosure, collateral sold",
  FTB: "Full termination/balance owing",
  FTO: "Full termination/obligation satisfied",
  FTS: "Full termination/status pending",
  INA: "Inactive account",
  INP: "Debt being paid through insurance",
  INS: "Paid by insurance",
  IRB: "Involuntary repossession/balance owing",
  IRE: "Involuntary repossession",
  IRO: "Involuntary repossession/obligation satisfied",
  JUG: "Judgment granted",
  LA: "Lease Assumption",
  LMD: "Loan modified under federal government plan",
  LMN: "Loan modified non-government",
  LNA: "Credit line no longer available - in repayment phase",
  MCC: "Managed by debt counseling service",
  MOV: "No forwarding address",
  ND: "No dispute",
  NIR: "Student loan not in repayment",
  NPA: "Now paying",
  PAL: "Purchased by another lender",
  PCL: "Paid collection",
  PDD: "Paid by dealer",
  PDE: "Payment deferred",
  PDI: "Principal deferred/interest payment only",
  PFC: "Account paid from collateral",
  PLL: "Prepaid lease",
  PLP: "Profit and loss now paying",
  PNR: "First payment never received",
  PPA: "Paying partial payment agreement",
  PPD: "Paid by comaker",
  PPL: "Paid profit and loss",
  PRD: "Payroll deduction",
  PRL: "Profit and loss write-off",
  PWG: "Account payment, wage garnish",
  REA: "Reaffirmation of debt",
  REP: "Substitute/Replacement account",
  RFN: "Refinanced",
  RMM: "Rent paid before day six",
  RNN: "Rent paid on day six or before day 15",
  ROO: "Rent paid on or after day 15",
  RPD: "Paid repossession",
  RPO: "Repossession",
  RPP: "Rent paid, but required demand letter",
  RQQ: "Eviction-non legal action",
  RRE: "Repossession; redeemed",
  RRR: "Eviction",
  RSS: "Rent unpaid-renter skipped, and did not fulfill remaining lease term",
  RVN: "Voluntary surrender",
  RVR: "Voluntary surrender redeemed",
  SCD: "Credit line suspended due to collateral depreciation",
  SET: "Settled—less than full balance",
  SGL: "Claim filed with government",
  SIL: "Simple interest loan",
  SLP: "Student loan perm assign government",
  SPL: "Single payment loan",
  STL: "Credit card lost or stolen",
  TRF: "Transfer",
  TRL: "Transferred to another lender",
  TTR: "Transferred to recovery",
  WCD: "Chap. 13/dispute of account information/account closed by consumer",
  WEP: "Chap. 13 bankruptcy",
  WPC: "Chap. 13/account closed by consumer",
  WPD: "Chap. 13/dispute of account information",
  WRC:
    "Chap. 13/dispute resolved/consumer disagrees/account closed by consumer",
  WRR: "Chap. 13/dispute resolved/consumer disagrees",
}

// Duplicate message of fraud indicator
const nonResidentialAddressMessage =
  "Address submitted is a non-residential address."
const suspiciousMessage = "Address submitted has been reported as suspicious."
const addressAssociatedWithFraudulentMessage =
  "Applicant's prior address has been associated with fraudulent activity."
const SSNAssociatedWithFraudulentMessage =
  "This name and SSN have been reported as being associated with fraudulent use."

export const FRAUD_INDICATOR_CODES = {
  "0001": "Address submitted is a mail receiving service.",
  "0002": "Address submitted is a hotel/motel or a temporary residence.",
  "0003": nonResidentialAddressMessage,
  "0005": nonResidentialAddressMessage,
  "0007": nonResidentialAddressMessage,
  "0009": nonResidentialAddressMessage,
  "0010": nonResidentialAddressMessage,
  "0500": nonResidentialAddressMessage,
  "0501": nonResidentialAddressMessage,
  "0502": nonResidentialAddressMessage,
  "0503": nonResidentialAddressMessage,
  "1000": nonResidentialAddressMessage,
  "1500": nonResidentialAddressMessage,
  "0004": "Address submitted is a camp site.",
  "0006": "Address submitted is a check-cashing facility.",
  "0008": "Address submitted is a storage facility",
  "0021": "SSN Mismatch – Input does not match file",
  "1001": "Address submitted is a U.S. Post Office street address.",
  "1501": suspiciousMessage,
  "1502": suspiciousMessage,
  "3000": suspiciousMessage,
  "1503": addressAssociatedWithFraudulentMessage,
  "1504": addressAssociatedWithFraudulentMessage,
  "2001": addressAssociatedWithFraudulentMessage,
  "2501":
    "Address submitted has been used more than once within the last 90 days on different inquiries.",
  "2502": "Address submitted has been reported more than once.",
  "2999": "Address submitted is a multi-unit building.",
  "3001": SSNAssociatedWithFraudulentMessage,
  "3003": SSNAssociatedWithFraudulentMessage,
  "3501": SSNAssociatedWithFraudulentMessage,
  "4001":
    "There is a possibility of fraud - the SSN submitted is associated with a person reported as deceased.",
  "4501":
    "SSN may be invalid - it was either very recently or never issued by the Social Security Administration.",
  "4502":
    "Name and SSN have been reported as being associated with fraudulent use.",
  "5501":
    "SSN have been used more than once with the last 90 days on different inquiries.",
  "5503":
    "SSN has been issued by the Social Security Administration within the past five years.",
  "5999": "Input/file SSN requires further investigation",
  "9001":
    "Input address(es), SSN and/or telephone number reported together in suspected misuse. ",
  "9002":
    "Input/file addresses, SSN, or telephone number reported by more than one source.",
  "9003":
    "Consumer statement on file relates to true name fraud or credit fraud.",
  "9004": "Active military duty alert on file.",
  "9005": "Initial fraud alert on file.",
  "9006": "Extended fraud victim alert on file.",
  C11: "Current address mismatch - input does not match file.",
  C32: "Current Address Zip Code Invalid",
  P11: "Previous address mismatch - input does not match file.0",
  "21": "SSN Mismatch - Input does not match file ",
  "41": "Input (Current/Previous) Address is Commercial",
}

export const PUBLIC_RECORD_TYPE_CODES = {
  AM: "Attachment",
  CB: "Civil judgment in bankruptcy",
  CJ: "Civil judgment",
  CP: "Child support",
  CS: "Civil suit filed",
  DF: "Dismissed foreclosure",
  DS: "Dismissal of court suit",
  FC: "Foreclosure",
  FD: "Forcible detainer",
  FF: "Forcible detainer dismissed",
  FT: "Federal tax lien",
  GN: "Garnishment",
  HA: "Homeowner’s association assessment lien",
  HF: "Hospital lien satisfied",
  HL: "Hospital lien",
  JL: "Judicial lien",
  JM: "Judgment dismissed",
  LR: "A lien attached to real property",
  ML: "Mechanics lien",
  PC: "Paid civil judgment",
  PF: "Paid federal tax lien",
  PG: "Paving assessment lien",
  PL: "Paid tax lien",
  PQ: "Paving assessment lien satisfied",
  PT: "Puerto Rico tax lien",
  PV: "Judgment paid, vacated",
  RL: "Release of tax lien",
  RM: "Release of mechanic’s lien",
  RS: "Real estate attachment satisfied",
  SF: "Satisfied foreclosure",
  SL: "State tax lien",
  TB: "Tax lien relieved in bankruptcy",
  TC: "Trusteeship canceled",
  TL: "Tax lien",
  TP: "Trusteeship paid/state amortization satisfied",
  TR: "Trusteeship paid/state amortization",
  TX: "Tax lien revived",
  WS: "Water and sewer lien",
  "1D": "Chapter 11 bankruptcy dismissed/closed",
  "1F": "Chapter 11 bankruptcy filing",
  "1V": "Chapter 11 bankruptcy voluntary dismissal",
  "1X": "Chapter 11 bankruptcy discharged",
  "2D": "Chapter 12 bankruptcy dismissed/closed",
  "2F": "Chapter 12 bankruptcy filing",
  "2V": "Chapter 12 bankruptcy voluntary dismissal",
  "2X": "Chapter 12 bankruptcy discharged",
  "3D": "Chapter 13 bankruptcy dismissed/closed",
  "3F": "Chapter 13 bankruptcy filing",
  "3V": "Chapter 13 bankruptcy voluntary dismissal",
  "3X": "Chapter 13 bankruptcy discharged",
  "7D": "Chapter 7 bankruptcy dismissed/closed",
  "7F": "Chapter 7 bankruptcy filing",
  "7V": "Chapter 7 bankruptcy voluntary dismissal",
  "7X": "Chapter 7 bankruptcy discharged",
}

export const COURT_TYPE_CODES = {
  AS: "Associate Court",
  BK: "U.S. Bankruptcy Court",
  CA: "County Auditor",
  CC: "County Clerk",
  CH: "Chancery Court",
  CI: "Circuit Court",
  CL: "County Court at Law ",
  CN: "Conciliation Court",
  CO: "Common Claims",
  CP: "Common Pleas",
  CR: "County Recorder",
  CT: "County Court",
  CY: "City Court",
  DC: "District Count",
  DO: "Domestic Court",
  DS: "District Judge System",
  FE: "Federal District",
  GS: "General Sessions",
  IC: "Inferior Court",
  JU: "Justice of the Peace",
  MA: "Magistrate Court",
  MU: "Municipal Court",
  M1: "Magisterial Court, Type 1",
  M2: "Magisterial Court, Type 2",
  M3: "Magisterial Court, Type 3",
  M4: "Magisterial Court, Type 4",
  PC: "Parish Court",
  PR: "Probate Court",
  RD: "Recorder of Deeds",
  SC: "Small Claims",
  ST: "State Court",
  SU: "Superior Court",
}

export const LOAN_TYPE_CODES = {
  AF: "Appliance/Furniture",
  AG: "Collection Agency/Attorney",
  AL: "Auto Lease",
  AP: "Airplane",
  AR: "Auto Loan—Refinanced",
  AT: "Auto Loan—Equity Transfer",
  AU: "Automobile",
  AX: "Agricultural Loan",
  BC: "Business Credit Card",
  BL: "Revolving Business Lines",
  BT: "Boat",
  BU: "Business",
  CA: "Camper",
  CB: "Combined Credit Plan",
  CC: "Credit Card",
  CE: "Commercial Line of Credit",
  CG: "Commercial Credit Obligation",
  CH: "Charge Account",
  CI: "Commercial Installment Loan",
  CO: "Consolidation",
  CP: "Child Support",
  CR: "Cond. Sales Contract; Refinance",
  CS: "Conditional Sales Contract",
  CU: "Telecommunications/Cellular",
  GF: "Government Fines",
  GG: "Government Grant",
  GH: "Fraud Check Req. & Govt Rpt",
  GO: "Government Overpayment",
  GS: "Government Secured",
  GU: "Govt. Unsecured Guar/Dir Ln",
  HE: "Home Equity Loan",
  HG: "Household Goods",
  HI: "Home Improvement",
  HK: "High Risk Fraud Match Received ",
  IE: "ID Report for Employment",
  IS: "Installment Sales Contract",
  LC: "Line of Credit",
  LE: "Lease",
  LI: "Lender-placed Insurance",
  LN: "Construction Loan",
  LS: "Credit Line Secured",
  MB: "Manufactured Housing",
  MD: "Medical Debt",
  MH: "Medical/Health Care",
  MT: "Motor Home",
  NT: "Note Loan",
  PS: "Partly Secured",
  RA: "Rental Agreement",
  RC: "Returned Check",
  RD: "Recreational Merchandise",
  RE: "Real Estate",
  RL: "Real Estate—Junior Liens",
  RM: "Real Estate Mortgage",
  RV: "Recreational Vehicle",
  SA: "Summary of Accounts—Same Status",
  SC: "Secured Credit Card",
  SE: "Secured",
  SF: "Secondary Use of a Credit Report for Auto Financing",
  SH: "Secured by Household Goods",
  SI: "Secured Home Improvement",
  SK: "Skip",
  SM: "Second Mortgage",
  SO: "Secured by Household Goods & Collateral",
  SR: "Secondary Use of a Credit Report",
  ST: "Student Loan",
  SU: "Spouse Support",
  SX: "Secondary Use of a Credit Report for Other Financing",
  TC: "SSN Search/ID Search Inquiry",
  TS: "Time Shared Loan",
  UC: "Utility Company",
  UK: "Unknown",
  US: "Unsecured",
  VA: "V.A. Loan",
  VM: "V.A. Real Estate Mortgage",
  WT: "Individual Monitoring Report Inquiry",
}

export const STATE_CODE = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

export const ACCOUNT_DESIGNATOR = {
  A: "Authorized user on account",
  C: "Joint contractual liability on account ",
  I: "Individual account",
  M: "Primary borrower on account ",
  P: "Participant on account ",
  S: "Co-signer on account",
  T: "Account relationship terminated ",
  U: "Undesignated account",
  X: "Consumer deceased",
}

export const REJECT_MESSAGE = {
  "2": "File not scored because subject is deceased",
  "3": "File not scored because subject does not have sufficient credit",
  "4": "Score out of range",
  "5": "Requirements not met",
  C: "Cascading model",
  M: "Missing required risk models",
}

export const CONSUMER_STATEMENT = {
  "01": "01 - Regular consumer statement",
  "02": "02 - Statement added because consumer was a victim of true-name fraud",
}

export const CONSUMER_RIGHTS_STATEMENT = {
  "001": "001 - Consumer Bill of Rights statement",
  "002": "002 - PEER addendum",
  "003": "003 - FCRA statement",
  "004": "004 - Decision Systems statement",
}
