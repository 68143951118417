import React from "react"
import * as PATH from "constants/route"
import { buildPath } from "@rentspree/path"
import get from "lodash/get"

import { B16 } from "@rentspree/component-v2"
import ImgContent from "images/application-screening.svg"
import tracker from "tracker"
import { LISTING_MANAGEMENT_EVENT } from "tracker/const"
import { withConnect } from "./connect"
import ConfirmModal from "./confirm-modal"
import { QUERY_STRING } from "./const"
import {
  StyledT36,
  StyledS24,
  Wrapper,
  ContentWrapper,
  Img,
  TextButton,
  ButtonWrapper,
  ActionButton,
  HomeIcon,
  HomeInnerText,
} from "./styled"

const { EVENT_NAME, EVENT_PROPERTY, EVENT_VALUE } = LISTING_MANAGEMENT_EVENT
const {
  SKIP_TENANT_SCREENING_OPTIONS,
  DONT_SKIP_SCREENING_OPTIONS,
  TENANT_SCREENING_SELECT,
} = EVENT_NAME
const { CLICK_FROM } = EVENT_PROPERTY
const { APPLICATION_SCREENING, SKIP_SCREENING } = EVENT_VALUE

export const ListingRequestCreateScreeningOptions = props => {
  const propertyId = get(props, "match.params.propertyId", "")
  const { featuresEnabled, history, location, actions } = props
  const [showModalState, setShowModalState] = React.useState(false)

  const queryStringSource = new URLSearchParams(location.search).get("source")
  const queryStringEmail = new URLSearchParams(location.search).get(
    "recipientEmail",
  )
  const isSourceEmail = queryStringSource === QUERY_STRING.QUERY_STRING_SOURCE_VALUE

  const continuePath = isSourceEmail
    ? buildPath(
        PATH.SHARE_APPLY_LINK,
        { propertyId },
        {
          source: queryStringSource,
          recipientEmail: queryStringEmail,
        },
      )
    : buildPath(PATH.SHARE_LISTING_STEP, {
        propertyId,
      })

  const continuePathWithOtherSource = isSourceEmail
    ? buildPath(
        PATH.SHARE_APPLY_LINK,
        { propertyId },
        {
          source: QUERY_STRING.LISTING_REQUEST_CREATE_SCREENING_OPTIONS,
          recipientEmail: queryStringEmail,
        },
      )
    : buildPath(PATH.SHARE_LISTING_STEP, {
        propertyId,
      })

  const createScreeningRequestLink = buildPath(
    PATH.PERMALINK_ACTIONS_ID,
    { propertyId },
    {
      continuePath: continuePathWithOtherSource,
      source: "listing",
    },
  )

  const propertiesPath = buildPath(PATH.PROPERTY)

  React.useEffect(() => {
    async function redirectWhenNotfound() {
      const response = await actions.getProperty(propertyId)
      if (
        response?.success === false &&
        response?.status === 404 &&
        response?.meta?.code === "not_found"
      ) {
        history.push(propertiesPath)
      }
    }

    redirectWhenNotfound()
  }, [])

  React.useEffect(() => {
    if (featuresEnabled.listingApplication) {
      history.push(continuePath)
    }
  }, [featuresEnabled.listingApplication])

  const handleClickSkipAnywayModal = () => {
    tracker.trackEvent(SKIP_TENANT_SCREENING_OPTIONS, {
      [CLICK_FROM]: SKIP_SCREENING,
    })
    history.push(continuePath)
  }

  const handleClickDoNotSkipModal = () => {
    tracker.trackEvent(DONT_SKIP_SCREENING_OPTIONS)
    setShowModalState(false)
  }

  const handleClickSkipNow = () => {
    tracker.trackEvent(SKIP_TENANT_SCREENING_OPTIONS, {
      [CLICK_FROM]: APPLICATION_SCREENING,
    })
    setShowModalState(true)
  }
  const handleClickBackToListingManagement = () => {
    history.push(buildPath(PATH.LISTING_MANAGE, { propertyId }))
  }

  const onClickTurnOn = () => {
    tracker.trackEvent(TENANT_SCREENING_SELECT, {
      [CLICK_FROM]: APPLICATION_SCREENING,
    })
    history.push(createScreeningRequestLink)
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <StyledT36>Applications & Screening</StyledT36>
        <Img
          alt="An illustration showing application closed button changed to accept applicants on listing page"
          src={ImgContent}
        />
        <StyledS24>
          {isSourceEmail
            ? "Turn on tenant screening to send ApplyLink™"
            : "Turn on tenant screening options?"}
        </StyledS24>
        <B16>
          Turn on tenant screening options to accept applications through our
          online rental platform.
        </B16>
        <ButtonWrapper>
          <TextButton
            id="skipNowBtn"
            onClick={
              isSourceEmail
                ? handleClickBackToListingManagement
                : handleClickSkipNow
            }>
            {isSourceEmail ? (
              <HomeIcon className="fas fa-home">
                <HomeInnerText className="fa-icon-innter-text">
                  &nbsp;Back to Listing
                </HomeInnerText>
              </HomeIcon>
            ) : (
              "Skip for now"
            )}
          </TextButton>

          <ActionButton
            id="turnOnBtn"
            type="button"
            text="Turn On"
            hasIcon
            onClick={onClickTurnOn}
          />
        </ButtonWrapper>
      </ContentWrapper>
      <ConfirmModal
        title="Skip screening options?"
        content={
          <B16>
            Screening options for this listing will be off and not accept any
            applicants.
            <br />
            <br />
            You can always turn it on later in
            <b>“Listing”</b>
            under <b>tenant screening options.</b>
          </B16>
        }
        confirmTxt="Don’t Skip"
        cancelTxt="Skip Anyway"
        handleClickCancelBtn={handleClickSkipAnywayModal}
        handleClickBtn={handleClickDoNotSkipModal}
        onClose={handleClickDoNotSkipModal}
        isOpen={showModalState}
      />
    </Wrapper>
  )
}
export default withConnect(ListingRequestCreateScreeningOptions)
