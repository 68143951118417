import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

const mutationFn = ({ address }) =>
  apiInstanceWithErrorHandler.post('/api/v2/agent/properties', {
    ...address,
    shouldCreateTUProperty: false,
  })

/**
 * @param {import('@tanstack/react-query').UseMutationOptions} options
 */
export const useCreatePropertyMutation = options => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['properties'] })
    },
    ...options,
  })
}
