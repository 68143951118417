// Overrides, helpers, utilities
// Only affect one piece of the DOM at a time
// Usually carry !important

// .one-half {
//   width: 50% !important;
// }
import { createGlobalStyle } from "styled-components"

// language=SCSS prefix=dummy{ suffix=}
export default createGlobalStyle`
  background: red;
  @media (min-width: 1440px) {
    .container {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media (min-width: 1200px) {
    width: 1140px;
    max-width: 100%;
  }
  
  .btn {
    border-radius: 50px;
  }
`
