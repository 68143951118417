import { createSelector } from "reselect"
import get from "lodash/get"
import { initialState } from './reducer'
import { KEY } from './constants'
const getAddressVerification = state => get(state, KEY, initialState)

export const selectIsVerifyingPropertyAddress = createSelector(
  getAddressVerification,
  state => state?.isVerifyingPropertyAddress || initialState.isVerifyingPropertyAddress,
)

export const selectAddressVerification = createSelector(
  getAddressVerification,
  state => state?.addressVerification || initialState.addressVerification,
)