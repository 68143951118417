import React, { useEffect } from 'react'
import { S14 } from 'components/typography'
import styled from 'styled-components'
import ExpiredImg from 'images/report/income-verification/report-expired.svg'
import FinicityIcon from 'images/report/income-verification/finicity-icon.svg'
import { COLOR } from 'styles/settings'
import tracker from 'tracker'
import { INCOME_VERIFICATION_EVENT } from 'tracker/const'
import { ReportWrapper } from '../../share/responsive-doc-style'

const Container = styled.div`
  height: 80vh;
  text-align: center;
  color: ${COLOR.textBlack};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 60px 50px;
  align-items: center;
`

const SubTitleBox = styled.div`
  margin: 50px 0;
`

const SubTitle = styled.div`
  white-space: pre-line;
  font-size: 16px;
`

const IncomeVerificationExpiredPage = ({ expiredDate, isFromAllTab }) => {
  useEffect(() => {
    tracker.trackEvent(INCOME_VERIFICATION_EVENT.EVENT_NAME.VIEW_INCOME_REPORT_STATE, {
      income_report_state: INCOME_VERIFICATION_EVENT.INCOME_REPORT_STATE.REPORT_EXPIRED,
      view_from: isFromAllTab
        ? INCOME_VERIFICATION_EVENT.VIEW_FROM.ALL_REPORT_TAB
        : INCOME_VERIFICATION_EVENT.VIEW_FROM.INCOME_VERIFICATION_TAB,
    })
  }, [])
  return (
    <ReportWrapper>
      <Container>
        <HeaderWrapper>
          <img src={FinicityIcon} alt="Finicity icon"></img>
          <S14 margin={0}>Income verification</S14>
        </HeaderWrapper>
        <div>
          <div>
            <img src={ExpiredImg} alt="Report expired" />
          </div>
          <SubTitleBox>
            <SubTitle>Income verification report expired on {expiredDate}.</SubTitle>
            <SubTitle>
              To maintain accuracy, income verification is only valid for 30 days.
            </SubTitle>
          </SubTitleBox>
        </div>
      </Container>
    </ReportWrapper>
  )
}

export default IncomeVerificationExpiredPage
