/* eslint-disable default-param-last */
import produce from 'immer'

import { BUSINESS_TYPE } from 'v3/containers/identity-verification/constants'

import {
  RESET_ACCOUNT_SESSION,
  CREATE_ACCOUNT_SESSION_FAILED,
  CREATE_ACCOUNT_SESSION_REQUEST,
  CREATE_ACCOUNT_SESSION_SUCCESS,
  FETCH_ACCOUNT_INFORMATION_FAILED,
  FETCH_ACCOUNT_INFORMATION_SUCCESS,
  FETCH_ACCOUNT_INFORMATION_REQUEST,
  RESET_UPDATE_ACCOUNT_INFORMATION,
  UPDATE_ACCOUNT_INFORMATION_FAILED,
  UPDATE_ACCOUNT_INFORMATION_SUCCESS,
  UPDATE_ACCOUNT_INFORMATION_REQUEST,
  RESET_ACCOUNT_INFORMATION,
} from '../actions'

import { API_STATUS } from './constants'

export const identityVerificationInitialState = {
  accountSession: {
    status: API_STATUS.NONE,
    data: undefined,
    errors: [],
  },
  accountInformation: {
    data: {
      defaultPaymentMethodId: '',
      identityVerification: {},
      businessType: BUSINESS_TYPE.UNKNOWN,
    },
    status: API_STATUS.NONE,
  },
  updateAccountInformation: {
    status: API_STATUS.NONE,
  },
}

/* eslint-disable no-param-reassign */
export const identityVerificationReducer = (
  state = identityVerificationInitialState,
  { type, payload },
) =>
  produce(state, draft => {
    switch (type) {
      case CREATE_ACCOUNT_SESSION_REQUEST:
        draft.accountSession.status = API_STATUS.FETCHING
        draft.accountSession.errors = []
        break
      case CREATE_ACCOUNT_SESSION_SUCCESS:
        draft.accountSession.status = API_STATUS.SUCCESS
        draft.accountSession.data = payload
        break
      case CREATE_ACCOUNT_SESSION_FAILED:
        draft.accountSession.status = API_STATUS.ERROR
        draft.accountSession.errors = payload?.data?.errors
        break
      case RESET_ACCOUNT_SESSION:
        draft.accountSession.status = API_STATUS.NONE
        draft.accountSession.data = undefined
        draft.accountSession.errors = []
        break

      case FETCH_ACCOUNT_INFORMATION_REQUEST:
        draft.accountInformation.status = API_STATUS.FETCHING
        break
      case FETCH_ACCOUNT_INFORMATION_SUCCESS:
        draft.accountInformation.status = API_STATUS.SUCCESS
        draft.accountInformation.data = payload
        break
      case FETCH_ACCOUNT_INFORMATION_FAILED:
        draft.accountInformation.status = API_STATUS.ERROR
        break
      case RESET_ACCOUNT_INFORMATION:
        draft.accountInformation.status = API_STATUS.NONE
        draft.accountInformation.data = identityVerificationInitialState.accountInformation.data
        break

      case UPDATE_ACCOUNT_INFORMATION_REQUEST:
        draft.updateAccountInformation.status = API_STATUS.UPDATING
        draft.updateAccountInformation.error = undefined
        break
      case UPDATE_ACCOUNT_INFORMATION_SUCCESS:
        draft.updateAccountInformation.status = API_STATUS.SUCCESS
        break
      case UPDATE_ACCOUNT_INFORMATION_FAILED:
        draft.updateAccountInformation.status = API_STATUS.ERROR
        draft.updateAccountInformation.error = payload
        break
      case RESET_UPDATE_ACCOUNT_INFORMATION:
        draft.updateAccountInformation.status = API_STATUS.NONE
        draft.updateAccountInformation.error = undefined
        break
      default:
        break
    }
    return draft
  })
