export default word => {
  switch (word) {
    case "credit-report":
    case "creditReport":
      return "credit_report"
    case "reference-checks":
      return "ref_check"
    case "criminal":
      return "background"
    case "application-report":
      return "application"
    case "criminal-report":
    case "criminalReport":
      return "background"
    case "eviction-report":
    case "evictionReport":
      return "eviction"
    case "all-report":
      return "all"
    case "reference-report":
    case "referenceReport":
      return "ref_check"
    default:
      return word
  }
}
