import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import React from 'react'
import styled from 'styled-components'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    font-family: Inter;
    border-radius: 12px;
  }
`

export const StyledDialogActions = styled(DialogActions)`
  gap: 16px;
`

export const StyledSheet = styled(Sheet)`
  font-family: Inter;
  .closeButtonWithOutPuller {
    ${props => !props?.showCloseButton && `display: none`};
  }
`

export const SheetContent = styled.div`
  padding: 16px;
`

export const SheetFooter = styled.div`
  padding: 16px;
  display: flex;
  justify-content: end;
  gap: 16px;
`

export const Modal = ({
  title,
  width,
  children,
  footer,
  isOpen,
  onClose = () => {},
  size = 'large',
  showCloseButton = false,
  ...restProps
}) => {
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  return isMobile ? (
    <StyledSheet open={isOpen} onClose={onClose} showCloseButton={showCloseButton} {...restProps}>
      <SheetHeader header={title} />
      <SheetContent>{children}</SheetContent>
      <SheetFooter>{footer}</SheetFooter>
    </StyledSheet>
  ) : (
    <StyledDialog width={width} open={isOpen} onClose={onClose} size={size} {...restProps}>
      <DialogTitle
        handleOnClose={showCloseButton ? onClose : null}
        sx={{ paddingLeft: '32px', paddingTop: '28px', paddingBottom: '10px' }}
      >
        <Typography variant="title-medium">{title}</Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <StyledDialogActions>{footer}</StyledDialogActions>
    </StyledDialog>
  )
}
