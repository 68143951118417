import axios from "axios"
import { axiosAfterLoginRequest, axiosResponse } from "@rentspree/axios"
import * as Sentry from "@sentry/browser"
import { setLocalItem, getLocalItem, setOption } from "@rentspree/cookie"
import { v4 as uuidv4 } from "uuid"
import {
  API_URL,
  FILES_URL,
  USER_API_URL,
  AGENT_REVIEW_API_BASE_URL,
} from "../env"
import {
  handleAuthorization,
  handleApiErrors,
  handleESignAPIErrors,
} from "./handle-api-errors"
import { STORAGE, COOKIE_OPTION } from "../constants/cookie"
const apiInstance = axios.create({
  baseURL: API_URL,
})

const apiInstanceWithErrorHandler = axios.create({
  baseURL: API_URL,
})

const apiEnvelopeInstance = axios.create({
  baseURL: API_URL,
})

const UserApiInstance = axios.create({
  baseURL: USER_API_URL,
})

const fileApiInstance = axios.create({
  baseURL: FILES_URL,
})

const agentReviewApiInstance = axios.create({
  baseURL: `${API_URL}${AGENT_REVIEW_API_BASE_URL}`,
})

const request = axiosAfterLoginRequest(
  { accessTokenKey: "access_token" },
  {
    headers: {
      post: {
        "Content-Type": "application/json",
      },
    },
  },
)

const logErrorWithSentry = error => {
  Sentry.captureException(error)
  return Promise.reject(error)
}

const injectHandleResponseApiInstance = ({
  instance,
  handleResolve = res => res,
  handleReject,
}) => {
  instance.interceptors.response.use(handleResolve, handleReject)
}

const injectErrorWithSentryApiInstance = instance =>
  injectHandleResponseApiInstance({
    instance,
    handleReject: logErrorWithSentry,
  })

const envelopeRequest = () => {
  let SSID
  SSID = getLocalItem(STORAGE.SSID)
  if (!SSID) {
    SSID = uuidv4()
    setOption(COOKIE_OPTION)
    setLocalItem(STORAGE.SSID, SSID)
  }
  return axiosAfterLoginRequest(
    { accessTokenKey: "access_token" },
    {
      headers: {
        post: {
          "Content-Type": "application/json",
        },
        ssid: SSID,
      },
    },
  )
}
apiInstance.interceptors.request.use(request)
UserApiInstance.interceptors.request.use(request)
fileApiInstance.interceptors.request.use(request)

apiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

apiEnvelopeInstance.interceptors.request.use(envelopeRequest())

apiEnvelopeInstance.interceptors.response.use(
  r => r,
  error => handleESignAPIErrors(error),
)

apiInstanceWithErrorHandler.interceptors.request.use(request)

apiInstanceWithErrorHandler.interceptors.response.use(
  axiosResponse.response,
  error => handleAuthorization(error),
)

apiInstanceWithErrorHandler.interceptors.response.use(
  r => r,
  error => handleApiErrors(error),
)

agentReviewApiInstance.interceptors.request.use(request)

agentReviewApiInstance.interceptors.response.use(
  axiosResponse.response,
  error => handleAuthorization(error),
)

apiEnvelopeInstance.interceptors.response.use(axiosResponse.response)
UserApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)
fileApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

export {
  apiInstance,
  apiInstanceWithErrorHandler,
  UserApiInstance,
  injectHandleResponseApiInstance,
  injectErrorWithSentryApiInstance,
  apiEnvelopeInstance,
  fileApiInstance,
  agentReviewApiInstance,
}
