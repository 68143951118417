import React from "react"
import { B16, ButtonIcon, T36, Span } from "@rentspree/component-v2"
import { TextWithIcon } from "components/molecules/text-with-icon"
import {
  Wrapper,
  InnerWrapper,
  SharingWrapper,
  SubtitleWrapper,
  SendRequestSpan,
  EmailSpan,
  ActionButton,
  HomeIcon,
  HomeInnerText,
  TextButton,
  ButtonWrapper,
  PrevButton,
} from "./styled"

export const ShareListingApplyLinkTemplate = ({
  propertyAddress,
  goToManageListing,
  onClickSendApplyLink,
  onClickPrev,
  renterEmail,
  isLoading,
  isSourceEmail,
}) => (
  <Wrapper>
    <InnerWrapper>
      <T36 margin="50px 0" mMargin="30px 0">
        Screen new tenant
      </T36>
      <SubtitleWrapper margin="0 0 30px 0">
        <SendRequestSpan>Send request to:</SendRequestSpan>
        <EmailSpan>{renterEmail}</EmailSpan>
      </SubtitleWrapper>
      <B16 margin="0 0 20px 0">They will receive ApplyLink™ to apply for:</B16>
      <SharingWrapper>
        <TextWithIcon
          className="houseIcon"
          iconHeight="24px"
          size="xl"
          icon="house"
          fontSize="20px"
          text={propertyAddress}
          fontWeight={600}
        />
      </SharingWrapper>
      <ButtonWrapper>
        {isSourceEmail ? (
          <TextButton id="backToManage" margin="0" onClick={goToManageListing}>
            <HomeIcon className="fas fa-home">
              <HomeInnerText className="fa-icon-innter-text">
                &nbsp;Back to Listing
              </HomeInnerText>
            </HomeIcon>
          </TextButton>
        ) : (
          <PrevButton
            id="goToPrevPageBtn"
            semiBold
            google
            small
            type="button"
            size="16px"
            padding="9px 25px 9px 25px"
            disabled={isLoading}
            onClick={onClickPrev}>
            <ButtonIcon className="fas fa-angle-left" size="18px" />
            <Span margin="0 0 0 11px">Prev</Span>
          </PrevButton>
        )}
        <ActionButton
          id="sendApplyLink"
          type="button"
          text="Send ApplyLink™"
          loading={isLoading}
          disabled={isLoading}
          onClick={onClickSendApplyLink}
        />
      </ButtonWrapper>
    </InnerWrapper>
  </Wrapper>
)

export default ShareListingApplyLinkTemplate
