import React, { useEffect } from "react"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import { buildPath, parse } from "@rentspree/path"

import { Div } from "components/layout/main"
import CenterWithError from "components/templates/center-with-error-template"
import { TextWithIcon } from "components/molecules/text-with-icon"
import LoadingOverlay from "components/contacts/molecules/loading-overlay"
import * as PATH from "constants/route"
import { LISTING_IMPORT_STATUS } from "containers/constants"
import { useLastLocation } from "react-router-last-location"
import { USER_LAST_ACTION } from "constants/user-last-action"
import { EVENT_SOURCE_IMPORT_MLS } from "./constants"

import { ImportListingForm } from "./form"
import { withImportListingConnect } from "./connect"
export const ImportListingLanding = ({
  isFetched,
  property,
  isFetchingProperty,
  MLSListing,
  history,
  actions,
  match,
}) => {
  const lastLocation = useLastLocation()

  useEffect(() => {
    const { params } = match
    actions.getProperty(params?.propertyId)
    actions.getImportableListing(params?.propertyId)
    return () => actions.clearProperty()
  }, [])

  useEffect(() => {
    const { allowToImport } = MLSListing

    const { importStatus } = property?.listing || {}
    const skipImport = [
      LISTING_IMPORT_STATUS.IMPORTED,
      LISTING_IMPORT_STATUS.ERROR,
    ].includes(importStatus)

    if ((!allowToImport || skipImport) && isFetched) {
      const { params } = match
      history.replace(
        buildPath(
          PATH.CREATE_LISTING_INFORMATION_STEP,
          { propertyId: params?.propertyId },
          parse(history.location.search),
        ),
        {
          userLastAction: USER_LAST_ACTION.REDIRECTED,
          fromURL: lastLocation.pathname,
        },
      )
    }
  }, [isFetched])

  const goToCreateListing = () => {
    const { params } = match
    history.push(
      buildPath(
        PATH.CREATE_LISTING_INFORMATION_STEP,
        { propertyId: params?.propertyId },
        { ...parse(history.location.search), source: EVENT_SOURCE_IMPORT_MLS },
      ),
      { userLastAction: USER_LAST_ACTION.CREATE_LISTING },
    )
  }

  const goToBack = () => {
    history.goBack()
  }

  if (!isFetched || isFetchingProperty) {
    return <LoadingOverlay />
  }

  return (
    <CenterWithError>
      <Div>
        <S24 margin="0 0 20px">Import Listing from MLS</S24>
        <B16 margin="0 0 32px">
          You can import your listing information from MLS or create a new
          listing page.
        </B16>
        <TextWithIcon
          iconHeight="24px"
          size="xl"
          icon="house"
          fontSize="20px"
          text={property.fullNameAddress}
          fontWeight={600}
        />
        <ImportListingForm
          importMLSListing={actions.importMLSListing}
          goToCreateListing={goToCreateListing}
          propertyId={match?.params?.propertyId}
          goToBack={goToBack}
        />
      </Div>
    </CenterWithError>
  )
}

export default withImportListingConnect(ImportListingLanding)
