import { getIsSSO, getJoinedDate, getIsAutomateTester } from './helpers'

const addStonlyEventListener = (eventName, functionToCallWhenWidgetOpens) => {
  if (window?.StonlyWidget) {
    return window.StonlyWidget('addEventListener', eventName, functionToCallWhenWidgetOpens)
  }
  return undefined
}

const removeStonlyEventListener = (eventName, functionToCallWhenWidgetOpens) => {
  if (window?.StonlyWidget) {
    return window.StonlyWidget('removeEventListener', eventName, functionToCallWhenWidgetOpens)
  }
  return undefined
}


const identify = (userId, props = {}) => {
  if (window?.StonlyWidget && userId) {
    return window.StonlyWidget('identify', userId, props)
  }
  return undefined
}

const identifyUser = userProfile => {
  identify(userProfile.id, {
    is_sso_user: getIsSSO(userProfile),
    joined_date: getJoinedDate(userProfile),
    is_automate_tester: getIsAutomateTester(userProfile),
  })
}

export default { identify, identifyUser, addStonlyEventListener, removeStonlyEventListener }
