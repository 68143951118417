import tracker from "tracker"
import { APPLICATION_TYPE } from "constants/application-type"
import { LRA_INTEGRATION } from "tracker/const"
import isEmpty from "lodash/isEmpty"
import { MIAMI_DADE_ZIP_CODES } from "./constants"
/**
 * This function will call trackEvent if the return from the decorated function is correct
 * @param {Function} decoratedFunction function that should be called before the trackEvent
 * is determined. The function should return an object with property isSelected,
 * and applicationType
 * @param {Args} rest the rest of the argument which the decorated function takes
 */
export const selectLraTrackerDecorator = (decoratedFunction, ...rest) => {
  const { isSelected, applicationType } = decoratedFunction(...rest)
  if (isSelected && applicationType === APPLICATION_TYPE.CAR_LRA) {
    tracker.trackEvent(LRA_INTEGRATION.SELECT_LRA)
  }
}

// TODO: Implement Strategy, Builder, or Template pattern for multiple application
export const validateApplicationTypeRequirement = (
  selectedApplicationType,
  isCarWithPartnerIDIntegrated,
) => {
  if (selectedApplicationType) {
    switch (selectedApplicationType) {
      case APPLICATION_TYPE.CAR_LRA:
        return isCarWithPartnerIDIntegrated
      case APPLICATION_TYPE.RENTSPREE:
        return true
      default:
        return false
    }
  }
  return true
}

export const checkSelectedItemsValidation = ({ selectingItems, payer }) =>
  !payer || isEmpty(selectingItems)

const isZipCodeInCounty = (zipCode, countyCode) =>
  zipCode ? countyCode.includes(`${zipCode}`) : false

export const isMiamiDadeZipCode = zipCode =>
  isZipCodeInCounty(zipCode, MIAMI_DADE_ZIP_CODES)
