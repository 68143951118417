import generateApiCall from 'utils/generate-api-call'

import {
  GET_CONTACT_LIST_CALL,
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,
  GET_CONTACT_COUNT_CALL,
  GET_CONTACT_COUNT_REQUEST,
  GET_CONTACT_COUNT_SUCCESS,
  GET_CONTACT_COUNT_FAILURE,
  CONTACT_LIST_CHANGE_PAGE,
  CREATE_AND_TAG_CONTACT_DASHBOARD,
  TAG_CONTACT_DASHBOARD_REQUEST,
  TAG_CONTACT_DASHBOARD_SUCCESS,
  TAG_CONTACT_DASHBOARD_FAILURE,
  TAG_CONTACT_DASHBOARD,
  UNTAG_CONTACT_DASHBOARD,
  UNTAG_CONTACT_DASHBOARD_REQUEST,
  UNTAG_CONTACT_DASHBOARD_SUCCESS,
  UNTAG_CONTACT_DASHBOARD_FAILURE,
  STAR_CONTACT_DASHBOARD,
  BULK_DELETE,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE,
  BULK_ADD_TAG,
  BULK_ADD_TAG_REQUEST,
  BULK_ADD_TAG_SUCCESS,
  BULK_ADD_TAG_FAILURE,
  CREATE_AND_BULK_ADD_TAG,
  SET_BULK_STATE,
  TOGGLE_SELECTED_BULK,
  ADD_SELECTED_ALL_BULK,
  DESELECTED_ALL_BULK,
  APPLY_SORT_CONTACT,
  UNSET_IS_LOADING_DONE,
  MARK_AS_GIFT_SENT,
  UNMARK_AS_GIFT_SENT,
  MARK_AS_GIFT_SENT_REQUEST,
  MARK_AS_GIFT_SENT_FAILURE,
  MARK_AS_GIFT_SENT_SUCCESS,
  UNMARK_AS_GIFT_SENT_REQUEST,
  UNMARK_AS_GIFT_SENT_FAILURE,
  UNMARK_AS_GIFT_SENT_SUCCESS,
  GET_CONTACT_SETTINGS_CALL,
  GET_CONTACT_SETTINGS_SUCCESS,
  GET_CONTACT_SETTINGS_FAILURE,
  GET_CONTACT_SETTINGS_REQUEST,
  UPDATE_CONTACT_SETTINGS_CALL,
  UPDATE_CONTACT_SETTINGS_REQUEST,
  UPDATE_CONTACT_SETTINGS_SUCCESS,
  UPDATE_CONTACT_SETTINGS_FAILURE,
  SEND_MESSAGE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
} from './constants'

export const getContactList = payload => ({
  type: GET_CONTACT_LIST_CALL,
  payload,
})

export const getContactListApiState = generateApiCall([
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,
])

export const getContactCount = payload => ({
  type: GET_CONTACT_COUNT_CALL,
  payload,
})

export const getContactCountApiState = generateApiCall([
  GET_CONTACT_COUNT_REQUEST,
  GET_CONTACT_COUNT_SUCCESS,
  GET_CONTACT_COUNT_FAILURE,
])

export const getContactSettings = () => ({
  type: GET_CONTACT_SETTINGS_CALL,
})

export const getContactSettingsApiState = generateApiCall([
  GET_CONTACT_SETTINGS_REQUEST,
  GET_CONTACT_SETTINGS_SUCCESS,
  GET_CONTACT_SETTINGS_FAILURE,
])

export const updateContactSettings = payload => ({
  type: UPDATE_CONTACT_SETTINGS_CALL,
  payload,
})

export const updateContactSettingsApiState = generateApiCall([
  UPDATE_CONTACT_SETTINGS_REQUEST,
  UPDATE_CONTACT_SETTINGS_SUCCESS,
  UPDATE_CONTACT_SETTINGS_FAILURE,
])

export const sendMessageApiState = generateApiCall([
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
])

export const sendMessage = ({ recipient, message }) => ({
  type: SEND_MESSAGE,
  payload: {
    message,
    recipient,
  },
})

export const changeContactPage = payload => ({
  type: CONTACT_LIST_CHANGE_PAGE,
  payload,
})

export const createAndTagContact = ({ contactId, name, email }, options) => ({
  type: CREATE_AND_TAG_CONTACT_DASHBOARD,
  payload: {
    contactId,
    name,
    email,
    successCallback: options.successCallback,
  },
})

export const tagContact = ({ contactId, tag, isNewTag = false, email }) => ({
  type: TAG_CONTACT_DASHBOARD,
  payload: {
    contactId,
    tag,
    isNewTag,
    email,
  },
})

export const unTagContact = (contactId, tagId) => ({
  type: UNTAG_CONTACT_DASHBOARD,
  payload: {
    contactId,
    tagId,
  },
})

export const tagContactDashboardApiState = generateApiCall([
  TAG_CONTACT_DASHBOARD_REQUEST,
  TAG_CONTACT_DASHBOARD_SUCCESS,
  TAG_CONTACT_DASHBOARD_FAILURE,
])

export const untagContactDashboardApiState = generateApiCall([
  UNTAG_CONTACT_DASHBOARD_REQUEST,
  UNTAG_CONTACT_DASHBOARD_SUCCESS,
  UNTAG_CONTACT_DASHBOARD_FAILURE,
])

export const starContact = ({ contactId, starredAt, isFallBack = false }) => ({
  type: STAR_CONTACT_DASHBOARD,
  payload: {
    contactId,
    starredAt,
    isFallBack,
  },
})

export const bulkDelete = payload => ({
  type: BULK_DELETE,
  payload,
})

export const bulkDeleteApiState = generateApiCall([
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE,
])

export const setBulkState = state => ({
  type: SET_BULK_STATE,
  payload: state,
})

export const toggleSelectedBulk = contactId => ({
  type: TOGGLE_SELECTED_BULK,
  payload: contactId,
})

export const addSelectedAllBulk = () => ({
  type: ADD_SELECTED_ALL_BULK,
})

export const deselectAllBulk = () => ({
  type: DESELECTED_ALL_BULK,
})

export const bulkAddTag = ({ contactIds, tag, isNewTag = false }) => ({
  type: BULK_ADD_TAG,
  payload: {
    contactIds,
    tag,
    isNewTag,
  },
})

export const createAndBulkAddTag = ({ contactIds, name }) => ({
  type: CREATE_AND_BULK_ADD_TAG,
  payload: {
    contactIds,
    name,
  },
})

export const bulkAddTagApiState = generateApiCall([
  BULK_ADD_TAG_REQUEST,
  BULK_ADD_TAG_SUCCESS,
  BULK_ADD_TAG_FAILURE,
])

export const applySortContact = (sortedKey, sortedVal) => ({
  type: APPLY_SORT_CONTACT,
  payload: {
    sortedKey,
    sortedVal,
  },
})

export const unsetIsLoadingDone = () => ({
  type: UNSET_IS_LOADING_DONE,
})

export const markAsGiftSent = ({ contactId, giftType }) => ({
  type: MARK_AS_GIFT_SENT,
  payload: {
    contactId,
    giftType,
  },
})

export const unmarkAsGiftSent = ({ contactId, giftType }) => ({
  type: UNMARK_AS_GIFT_SENT,
  payload: {
    contactId,
    giftType,
  },
})

export const markAsGiftSentApiState = generateApiCall([
  MARK_AS_GIFT_SENT_REQUEST,
  MARK_AS_GIFT_SENT_SUCCESS,
  MARK_AS_GIFT_SENT_FAILURE,
])

export const unmarkAsGiftSentApiState = generateApiCall([
  UNMARK_AS_GIFT_SENT_REQUEST,
  UNMARK_AS_GIFT_SENT_SUCCESS,
  UNMARK_AS_GIFT_SENT_FAILURE,
])
