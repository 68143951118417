/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames/bind'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import isUndefined from 'lodash/isUndefined'
import Hr from '@rentspree/component-v2/dist/hr'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import SelectDropdown from '@rentspree/component-v2/dist/dropdown/select'
import Link from '@rentspree/component-v2/dist/button/link'
import { withFormik } from 'formik'
import { ZIPLOGIX_TRACKING, CREATE_LISTING_EVENT } from 'tracker/const'

import tracker from 'tracker'
import { Div } from 'components/layout/main'
import { ButtonSet } from 'components/buttons/button-set'
import { USER_ROLE_FOR_PROPERTY, USER_INPUT_ROLE, OTHER_ROLE_NAME_PREFIX } from 'containers/request/step-create-property/constants'

import styles from 'legacy/components/request/left-content/left-content.scss'
import { TrackerInstance as Tracker } from 'legacy/helpers/tracker'

import { COLOR } from 'styles/settings'
import {
  createPropertySchemaWithUserRoleForProperty,
  schemaSelectedValidation,
} from 'components/templates/create-property/schema'
import SubtitleWithHr from 'components/molecules/subtitle-with-hr'
import { BACK_BUTTON_TYPE } from 'constants/back-button-type'

import { UserRoleForPropertyFormWithRadioChoices } from 'components/organisms/user-role-for-property-form/user-role-for-property-form-with-radio-choices'
import { FullAddressForm } from 'components/organisms/full-address-input-form'
import { saveNewListingTrackerWithUserRoleMapper } from 'tracker/tracker-mapper'
import {
  USER_ROLE_FOR_PROPERTY_FORM_TITLE,
  USER_ROLE_FOR_PROPERTY_FORM_SUBTITLE,
  USER_ROLE_FOR_PROPERTY_RADIO_CHOICES,
  OTHER_ROLE_TEXT_INPUT_PLACEHOLDER
} from './const'

const c = classnames.bind(styles)
const WARNING_TEXT = "Don't see the property? "
const NEW_PROPERTY_TEXT = 'Click here to add a new one'

export const StyledP = styled.p`
  font-family: Source Sans Pro, sans-serif;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
`

export const ZiplogixTextLinkWrapper = styled.div`
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 30px;
  height: 50px;
  border: 1px solid ${COLOR.borderGrey};
  background-color: ${COLOR.bgGrey};
  p {
    text-align: left;
    margin-bottom: 0;
    font-size: 14px;
  }
  @media (max-width: 991px) {
    height: 70px;
  }
`

export class CreatePropertyTemplate extends React.Component {
  constructor(props) {
    super(props)
    const { prefillProperty } = props || {}
    this.state = {
      selectedAddress: '',
      addNewProperty: !isEmpty(prefillProperty) && prefillProperty.isNewProperty,
      properties: [],
      isSavePropertyClicked: false,
    }
  }

  componentDidMount() {
    this.createDropdownProperties()
  }

  setAddressValue = (id, option = {}) => {
    const property = this.props.prefillProperty
    const zfParams = this.props.zipformData

    if (property.isNewProperty) return

    if ((zfParams && zfParams.transid === id) || (property && property.id === id)) {
      this.setState({ selectedAddress: option.value })
    }
  }

  // Combine address to Dropdown label
  mapDropdownProperty = (properties = []) =>
    properties.map(address => {
      // eslint-disable-next-line no-param-reassign
      address.zip = address.zip ? address.zip.substring(0, 5) : ''
      let option
      if (
        isEmpty(address.address) ||
        isEmpty(address.city) ||
        isEmpty(address.state) ||
        isEmpty(address.zip)
      ) {
        option = { value: JSON.stringify(address), label: address.name }
      } else {
        option = {
          value: JSON.stringify(address),
          label: address.propertyAddress,
        }
      }
      this.setAddressValue(address.id, option)
      return option
    })

  createDropdownProperties = () => {
    this.setState({
      properties: [{ value: '', label: '' }, ...this.mapDropdownProperty(this.props.properties)],
    })
  }

  handleNextStep = async (e) => {
    e.preventDefault()
    this.setState({
      isSavePropertyClicked: true,
    })
    this.props.setTouched(Object.keys(this.props.values).reduce((acc, k) => ({ ...acc, [k]: true }), {}))

    const { externalIntegration, isAgentOrRenter } = this.props || {}
    const { addNewProperty, selectedAddress, userRoleForProperty } = this.state || {}

    const isAddNewPropertyMethod = !externalIntegration || addNewProperty

    const isLandlordOrListingAgent = userRoleForProperty === USER_ROLE_FOR_PROPERTY.LANDLORD
      || userRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT

    const isContinueToPropertyDetails = !isAgentOrRenter || isLandlordOrListingAgent

    if (isAddNewPropertyMethod) {
      return this.handleNextStepWithNewAddress(isContinueToPropertyDetails)
    }
    const isValid = await schemaSelectedValidation
      .validate({ selectedAddress })
      .then(() => true)
      .catch(() => false)

    return this.handleNextStepWithSelectedAddress(isValid, isContinueToPropertyDetails)
  }

  assignUserRoleForPropertyAndUserInputRole = (address) => {
    const assigned = { ...address }
    const { userRoleForProperty } = this.state || {}
    const { values } = this.props || {}
    const { otherRoleName } = values || {}
    switch (userRoleForProperty) {
      case USER_ROLE_FOR_PROPERTY.LANDLORD:
      case USER_ROLE_FOR_PROPERTY.LISTING_AGENT:
      case USER_ROLE_FOR_PROPERTY.RENTER_AGENT:
        assigned.userRoleForProperty = userRoleForProperty
        assigned.userInputRole = userRoleForProperty
        break
      case USER_INPUT_ROLE.PROPERTY_MANAGER:
        assigned.userInputRole = userRoleForProperty
        break
      case USER_INPUT_ROLE.OTHER:
        assigned.userInputRole = `${OTHER_ROLE_NAME_PREFIX}${otherRoleName}`
        break
      default:
        break
    }
    return assigned
  }

  createLandlordProfile = () => {
    const { values } = this.props || {}
    const { userRoleForProperty } = this.state || {}

    if (userRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT) {
      const { firstName, lastName, email } = values || {}
      return {
        firstName,
        lastName,
        email,
      }
    }

    return {}
  }

  handleNextStepWithNewAddress = async (isContinueToPropertyDetails = false) => {
    const { subdomain, submitProperty, values, validateForm } = this.props || {}
    const { street, unitNumber, city, state, zipcode, firstName, lastName, email, otherRoleName } = values || {}
    const { userRoleForProperty } = this.state || {}
    const objAddress = {
      id: null,
      street,
      unitNumber,
      city,
      state,
      zip: zipcode,
      isNewProperty: true,
      landlordProfile: this.createLandlordProfile(),
    }

    const assignedObjAddress = this.assignUserRoleForPropertyAndUserInputRole(objAddress)
    const validateResult = await validateForm({
      ...assignedObjAddress,
      firstName,
      lastName,
      email,
      otherRoleName,
      zipcode,
      userRoleForProperty,
    })

    if (isEmpty(validateResult) && userRoleForProperty) {
      if (subdomain) set(assignedObjAddress, 'externalIntegration', true)
      if (!isEmpty(street.trim())) {
        this.setState({
          isSavePropertyClicked: false,
        })
        submitProperty(assignedObjAddress, true, isContinueToPropertyDetails)
      }
    }
  }

  // LIST PROPERTY FORM HANDLER
  handleAddressChange = address => {
    this.setState({
      selectedAddress: address,
      addNewProperty: false,
    })
  }

  handleAddNewProperty = () => {
    this.setState({
      selectedAddress: '',
      addNewProperty: true,
    })
    tracker.trackEvent(ZIPLOGIX_TRACKING.SWITCH_CREATE_PROPERTY)
    this.props.initState()
  }

  // NEW PROPERTY FORM HANDLER
  handleNextStepWithSelectedAddress = async (
    isValidSchema,
    isContinueToPropertyDetails = false,
  ) => {
    const { selectedAddress, userRoleForProperty } = this.state
    const { submitProperty, actions } = this.props
    if (!isEmpty(selectedAddress)) {
      const { id } = JSON.parse(selectedAddress)
      const ziplogixProperty = await actions.getZiplogixProperty(id)
      if (!isEmpty(ziplogixProperty) && isUndefined(ziplogixProperty.success) && userRoleForProperty) {
        const unitNumber = get(ziplogixProperty, 'fullAddress.unit_number')
        const zipcode = get(ziplogixProperty, 'property.zip', '').substring(0, 5)

        const objAddress = {
          id,
          street: get(ziplogixProperty, 'property.address'),
          city: get(ziplogixProperty, 'property.city'),
          state: get(ziplogixProperty, 'property.state'),
          isNewProperty: false,
          externalIntegration: true,
          unitNumber,
          subdomain: 'ziplogix',
          ...(isValidSchema ? { zip: zipcode } : { zipcode }),
        }
        objAddress.landlordProfile = this.createLandlordProfile()
        const assignedObjAddress = this.assignUserRoleForPropertyAndUserInputRole(objAddress)
        this.setState({
          isSavePropertyClicked: false,
        })
        submitProperty(assignedObjAddress, isValidSchema, isContinueToPropertyDetails)
      }
    }
  }

  goToDashboard = () => {
    Tracker.trackEvent('clickGoToDashboardInAddNewListingPage')
    this.props.handleBackToDashboard()
  }

  resetLandlordProfile = async () => {
    await Promise.all(
      ['firstName', 'lastName', 'email'].reduce((acc, field) => {
        acc.push(this.props.setFieldValue(field, '', false))
        acc.push(this.props.setFieldTouched(field, false, false))
        this.props.setFieldError(field, undefined)
        return acc
      }, []),
    )
  }

  resetOtherRoleName = async () => {
    const fieldName = 'otherRoleName'
    await this.props.setFieldValue(fieldName, '', false)
    await this.props.setFieldTouched(fieldName, false, false)
    this.props.setFieldError(fieldName, undefined)
  }

  handleUserRoleForPropertyDropdown = async (selectedValue) => {
    this.setState({
      userRoleForProperty: selectedValue,
    })

    await this.resetLandlordProfile()
    await this.resetOtherRoleName()
    await this.props.setFieldValue('userRoleForProperty', selectedValue)

    tracker.trackEvent(CREATE_LISTING_EVENT.PROPERTY_ROLE_SELECTION, {
      role: saveNewListingTrackerWithUserRoleMapper(selectedValue),
      version: "b"
    })
  }

  render() {
    const {
      externalIntegration,
      errors,
      backButtonType = BACK_BUTTON_TYPE.BACK,
      isButtonLoading,
    } = this.props || {}

    const { properties, selectedAddress, addNewProperty, userRoleForProperty, isSavePropertyClicked } = this.state

    const isAddNewPropertyMethod = !externalIntegration || addNewProperty

    return (
      <div className={c('innerLeftContent')}>
        <div className={c('propertyDiv')}>
          <form id="createPropertyFormSubmit" onSubmit={this.handleNextStep}>
            {externalIntegration && (
              <div className={c('selectPropertyDiv', addNewProperty && 'mb-30')}>
                <SubtitleWithHr text="Select the property you represent or own" />
                <Div margin="0 10px 20px 10px" mMargin="0 0 20px 0">
                  <SelectDropdown
                    id="dropDownSelectProperty"
                    options={properties}
                    name="selectedAddress"
                    onChange={val => {
                      this.handleAddressChange(val)
                    }}
                    onClick={() => {
                      tracker.trackEvent(ZIPLOGIX_TRACKING.SWITCH_SELECT_PROPERTY)
                    }}
                    error={!addNewProperty && errors && errors.selectedAddress}
                    value={selectedAddress}
                  />
                  {!addNewProperty && (
                    <ZiplogixTextLinkWrapper>
                      <StyledP>
                        {WARNING_TEXT}
                        <span>
                          <Link
                            weight="600"
                            id="dashboardAddNewPropertyBtn"
                            onClick={this.handleAddNewProperty}
                          >
                            {NEW_PROPERTY_TEXT}
                          </Link>
                        </span>
                      </StyledP>
                    </ZiplogixTextLinkWrapper>
                  )}
                </Div>
              </div>
            )}
            {isAddNewPropertyMethod && (
              <Div sourceSansPros>
                <S24 margin="0 0 8px 0" mMargin=" 0 0 9px 0">
                  Property Address
                </S24>
                <Hr thickness="1px" margin="0 0 20px 0" color={COLOR.borderGrey} />
                <Div margin="0 10px 20px 10px" mMargin="0 0 20px 0">
                  <FullAddressForm {...this.props} />
                </Div>
              </Div>
            )}
            <UserRoleForPropertyFormWithRadioChoices
              title={USER_ROLE_FOR_PROPERTY_FORM_TITLE}
              subtitle={USER_ROLE_FOR_PROPERTY_FORM_SUBTITLE}
              radioChoices={USER_ROLE_FOR_PROPERTY_RADIO_CHOICES}
              selectedUserRoleForProperty={userRoleForProperty}
              handleUserRoleForPropertyDropdown={this.handleUserRoleForPropertyDropdown}
              isSavePropertyClicked={isSavePropertyClicked}
              otherRoleInputPlaceholder={OTHER_ROLE_TEXT_INPUT_PLACEHOLDER}
              {...this.props}
            />
            <ButtonSet
              id="createPropertyButtonSet"
              minWidth="100"
              margin="30px 0 0"
              continueBtnType="submit"
              continueText="Save property"
              btnHeight="40px"
              isLoading={isButtonLoading}
              onClickBackToDashboardBtn={this.goToDashboard}
              onClickPrevBtn={this.props.handleBackToDashboard}
              onBorder={backButtonType === BACK_BUTTON_TYPE.PREV}
              onBorderDesktop={backButtonType === BACK_BUTTON_TYPE.PREV}
              withSystemLayout={backButtonType === BACK_BUTTON_TYPE.PREV}
              hidePrevButton={backButtonType !== BACK_BUTTON_TYPE.PREV}
              hideBackButton={backButtonType !== BACK_BUTTON_TYPE.BACK}
            />
          </form>
        </div>
      </div>
    )
  }
}

const CreatePropertyTemplateForm = withFormik({
  mapPropsToValues: props => createPropertySchemaWithUserRoleForProperty(props).mapper,
  validationSchema: props => createPropertySchemaWithUserRoleForProperty(props).validation,
  mapPropsToTouched: props => createPropertySchemaWithUserRoleForProperty(props).touched,
  enableReinitialize: true,
  validateOnMount: ({ validateOnMount }) => validateOnMount,
  displayName: 'CreatePropertyTemplateForm',
})(CreatePropertyTemplate)

export default CreatePropertyTemplateForm
