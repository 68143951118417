import { buildPath } from "@rentspree/path"
import { all, call, put, takeLatest, select } from "redux-saga/effects"
import tracker from "tracker"

import { apiInstanceWithErrorHandler } from "utils/api-interceptor"
import { openSweetAlertBaseError } from "utils/sweet-alert-actions"
import { selectIsPropertyArchived } from "containers/property/toggle-archive-property/selectors"
import { MODAL_STATUS } from "containers/property/toggle-archive-property/constants"
import { TOGGLE_PROPERTY_FEATURE } from "tracker/const"
import { selectSource } from "./selectors"
import {
  disableFeatureApiCall,
  enableFeatureApiCall,
  clearTogglePropertyFeatureModal,
} from "./actions"
import {
  DISABLE_FEATURE,
  ENABLE_FEATURE,
  ENABLE_FEATURE_API,
  DISABLE_FEATURE_API,
  OPEN_TOGGLE_PROPERTY_FEATURE_MODAL,
  FEATURE_STATUS,
} from "./constants"

const { DISABLED, ENABLE } = FEATURE_STATUS
const { UNARCHIVE } = MODAL_STATUS
const {
  openTogglePropertyFeatureModal,
  confirmTogglePropertyFeature,
} = TOGGLE_PROPERTY_FEATURE

export const enableFeatureAPI = ({ propertyId, data }) =>
  apiInstanceWithErrorHandler.post(
    buildPath(ENABLE_FEATURE_API, { propertyId }),
    data,
  )

export const disableFeatureAPI = ({ propertyId, data }) =>
  apiInstanceWithErrorHandler.post(
    buildPath(DISABLE_FEATURE_API, { propertyId }),
    data,
  )

export function* disableFeatureSaga({ payload }) {
  yield put(disableFeatureApiCall.request())
  try {
    const source = yield select(selectSource)
    yield call([tracker, "trackEvent"], confirmTogglePropertyFeature, {
      status: DISABLED,
      from: source,
      property_id: payload.propertyId,
      by: payload.data.remark,
    })
    const response = yield call(disableFeatureAPI, payload)
    yield put(disableFeatureApiCall.success(response))
    yield put(clearTogglePropertyFeatureModal())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(disableFeatureApiCall.failure(err))
  }
}

export function* enableFeatureSaga({ payload }) {
  yield put(enableFeatureApiCall.request())
  try {
    const isArchived = yield select(selectIsPropertyArchived)
    const source = yield select(selectSource)
    yield call([tracker, "trackEvent"], confirmTogglePropertyFeature, {
      status: isArchived ? UNARCHIVE : ENABLE,
      from: source,
      property_id: payload.propertyId,
    })
    const response = yield call(enableFeatureAPI, payload)
    yield put(enableFeatureApiCall.success(response))
    yield put(clearTogglePropertyFeatureModal())
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(enableFeatureApiCall.failure(err))
  }
}

export function* openTogglePropertyFeatureModalSaga({ payload }) {
  const isArchived = yield select(selectIsPropertyArchived)
  const { type, source } = payload
  let status
  if (type === DISABLED) {
    status = DISABLED
  } else if (isArchived) {
    status = UNARCHIVE
  } else {
    status = ENABLE
  }
  yield call([tracker, "trackEvent"], openTogglePropertyFeatureModal, {
    status,
    from: source,
  })
}

export function* watchDisableFeature() {
  yield takeLatest(DISABLE_FEATURE.CALL, disableFeatureSaga)
}

export function* watchEnableFeature() {
  yield takeLatest(ENABLE_FEATURE.CALL, enableFeatureSaga)
}

export function* watchOpenTogglePropertyFeatureModal() {
  yield takeLatest(
    OPEN_TOGGLE_PROPERTY_FEATURE_MODAL,
    openTogglePropertyFeatureModalSaga,
  )
}

export const watchers = [
  watchDisableFeature(),
  watchEnableFeature(),
  watchOpenTogglePropertyFeatureModal(),
]

export default function* rootSaga() {
  yield all(watchers)
}
