import React from "react"
import * as Styled from "./list-style"

export const Ul = ({ children, ...props }) => (
  <Styled.ul {...props}>{children}</Styled.ul>
)

export const Ol = ({ children, ...props }) => (
  <Styled.ol {...props}>{children}</Styled.ol>
)
