import React from 'react'
import { compose } from 'redux'
import { ReportLoading } from 'components/reports/loading'
import { withRouter } from 'react-router-dom'
import { APPLICATION_TYPE } from 'constants/application-type'
import withReportAvailable from '../with-report-available'
import { REPORT_TYPES, CLICK_REPORT_FROM } from '../constants'
import { LoadableAppV1 } from './loadable'
import withError from './with-error'
import { withConnect } from './connect'
import { ReportPageContext } from '../context'
import { selectApplication, createRules } from './helper'

export const WithVersionApplication = ({
  // action
  getApplicationDetail,
  getFormAvailable,
  printReport,
  savePDF,
  onShowPdfSuccess,
  // state
  applicationDetail,
  rentalDetail,
  isFetchingAppDetail,
  isFetchingFormAvailable,
  isFetchingPDFFile,
  pdfReport,
  pdfFile,
}) => {
  const { isPrintPage, propertyId } = React.useContext(ReportPageContext)

  React.useEffect(() => {
    const rentalAppId = rentalDetail?.application
    const appId = applicationDetail?._id
    const shouldGetApplication = rentalAppId !== appId
    if (shouldGetApplication) {
      const rentalId = rentalDetail?._id
      getApplicationDetail(rentalId)
      getFormAvailable(rentalId)
    }
  }, [rentalDetail?.application, applicationDetail?._id])

  const onPrintAppV1 = React.useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      printReport({
        type: REPORT_TYPES.APPLICATION,
        autoPrint: true,
        isPrintPage,
        clickFrom: CLICK_REPORT_FROM.HEADER,
        propertyId,
      })
    },
    [isPrintPage, propertyId],
  )
  const downloadPDF = React.useCallback(
    isMobile => {
      savePDF({
        type: REPORT_TYPES.APPLICATION,
        isPrintPage,
        clickFrom: CLICK_REPORT_FROM.HEADER,
        ...(isMobile && { device: 'mobile' }),
        propertyId,
      })
    },
    [isPrintPage, propertyId],
  )

  const onClickDownloadV1 = React.useCallback(() => downloadPDF(), [downloadPDF])

  const onMobileClickDownloadV1 = React.useCallback(() => downloadPDF(true), [downloadPDF])

  const { isFetching } = pdfReport
  if (isFetchingAppDetail || isFetchingFormAvailable || isFetchingPDFFile) {
    return <ReportLoading />
  }

  const defaultApplication = (
    <LoadableAppV1
      application={applicationDetail}
      rentalDetail={rentalDetail}
      download={{
        onClick: onClickDownloadV1,
        onMobileClick: onMobileClickDownloadV1,
        isFetching,
      }}
      onPrint={onPrintAppV1}
      isAvailable
    />
  )
  const applicationType =
    rentalDetail?.screeningOption?.applicationType || APPLICATION_TYPE.RENTSPREE
  const applicationProps = {
    application: applicationDetail,
    rentalDetail,
    pdfFile,
    onShowPdfSuccess,
  }
  return selectApplication(
    createRules(applicationProps, defaultApplication),
    applicationType,
    applicationDetail.version,
  )
}

export default compose(
  withReportAvailable(REPORT_TYPES.APPLICATION),
  withError,
  withRouter,
  withConnect,
)(WithVersionApplication)
