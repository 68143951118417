export default async (response, type) => {
  let error
  let contentType = type || "text"
  if (response.status >= 400) {
    error = response.statusText || `status error ${response.status}`
    contentType = "text"
  }
  const data = await handleResponse(response, contentType)
  if (error) {
    // TODO: Handle an error to show modal or refactoring the API that using this function to Saga
    const err = new Error(data.message || error)
    err.errors = data
    err.status = response.status
    if (response.status === 401) {
      err.name = "Unauthorized"
    }
    throw err
  }
  return data
}

export async function handleResponse(response, type) {
  let data
  let parseData
  try {
    switch (type) {
      case "arraybuffer":
        parseData = await response.arrayBuffer()
        data =
          String.fromCharCode.apply(null, new Uint8Array(parseData)) === "[]"
            ? new ArrayBuffer()
            : parseData
        break
      case "text":
      default:
        parseData = await response.text()
        data = JSON.parse(parseData)
        break
    }
  } catch (e) {
    data = parseData || e
  }
  return data
}
