// create message error = page -> feature -> text
import { TU_CONTACT } from 'constants/partner'
import iconErrorCreditReport from '../images/icons/credit-report/report-red.png'

export const REQUEST = {
  CREATE_PROPERTY: {
    TITLE: "There's a problem with the property address",
    MESSAGE:
      'Please make sure the address is entered in standard format. If the error persists, contact us at support@rentspree.com or (323) 515-7757.',
  },
  SEND_EMAILS: {
    TITLE: 'We’re having trouble sending the request.',
    MESSAGE:
      'Please try again. If the error persists, contact us at support@rentspree.com or (323) 515-7757.',
  },
  SEND_EMAILS_SUCCESS: {
    TITLE: 'Email sent successfully.',
    MESSAGE: 'The email has been sent to the applicant.',
  },
  GET_SCREENING_PLAN: {
    TITLE: 'Sorry. We are having trouble connecting to the RentSpree servers.',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  GET_ZIPLOGIX_PROPERTY: {
    TITLE: "There's a problem connecting to Ziplogix server",
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const USER = {
  UNAUTHORIZED: {
    TITLE: 'Looks like your account can’t access this page',
    MESSAGE:
      'Please refresh the page and try again, or try logging in with a different email. If you still have trouble, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  UPDATE_PROFILE_FAILED: {
    TITLE: '',
    MESSAGE:
      'Sorry, we were unable to update your information. Please try again and contact support@rentspree.com if the problem persists.',
  },
}

export const OPEN_CREDIT = {
  // TRANSUNION_DELAY will be used after we automate datadog threshold to distinct between intermittent failure vs total outage
  TRANSUNION_DELAY: {
    TITLE: `We're experiencing a delay from TransUnion`,
    MESSAGE: `Please try again in a few minutes.<br/>If the problem persists, <a href="https://support.rentspree.com/en/kb-tickets/new" target="_blank">contact RentSpree</a>.`,
  },
  // for now, we shall redirect all failure to the status page. If the status page show healthy, user can call support number on that page
  TRANSUNION_OUTAGE: {
    TITLE: `We're experiencing a delay from TransUnion`,
    MESSAGE: `Please try again in 30 minutes.<br/>For updates, visit <a href="https://status.rentspree.com" target="_blank">status.rentspree.com</a>.`,
  },
}

export const APPLICATION_DETAIL = {
  RENTAL_FORM: {
    TITLE: 'The rental application is temporarily unavailable',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  APPLICATION: {
    TITLE: 'The rental application is temporarily unavailable',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  CREDIT_REPORT: {
    IMAGE: iconErrorCreditReport,
    TITLE:
      'Sorry, TransUnion is delayed in generating this applicant’s screening reports. Normally the reports are available instantly, but today it’s taking longer than expected.',
    SHOULD_I_DO: [
      "Please wait an hour and <a href='javascript:window.location.reload()'> try again </a>",
      `If the reports are still not available, you can contact TransUnion at ${TU_CONTACT.PHONE} with the applicant’s email address to check the status.`,
      'For further information, call RentSpree at (323) 515-7757.',
    ],
  },
  SAVE_PDF_TIMEOUT: {
    TITLE: "It's taking long than expected to generate the PDF",
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  DOCUMENTS: {
    REQUESTED_SEND: {
      TITLE: "Can't send request document",
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the properties, contact us at (323) 515-7757 or support@rentspree.com.',
    },
    VALIDATION: {
      TITLE: 'Validation error request document',
    },
    FETCH_DOCUMENTS: {
      TITLE: 'The documents section is currently unavailable',
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the documents section, contact us at (323) 515-7757 or support@rentspree.com.',
    },
  },
  REFERENCE_CHECK: {
    REQUESTED_SEND: {
      TITLE: "Can't send request reference",
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the reference checks section, contact us at (323) 515-7757 or support@rentspree.com.',
    },
    FETCH_REFERENCE: {
      TITLE: 'The reference checks section is currently unavailable',
      MESSAGE:
        'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the reference checks section, contact us at (323) 515-7757 or support@rentspree.com.',
    },
  },
}

export const APPLICATIONS = {
  LISTING: {
    TITLE: 'The applications are temporarily unavailable',
    MESSAGE:
      'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the applications, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const PROPERTIES = {
  LISTING: {
    TITLE: 'The properties are temporarily unavailable',
    MESSAGE:
      'Please wait a few minutes and refresh the page (ctrl+r/cmd+r).  If you still cannot access the properties, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const PROPERTY = {
  REACTIVATE: {
    TITLE: 'Sorry',
    MESSAGE:
      'We are unable to reactivate this property. Please refresh your screen and try again. If the problem persists, contact support@rentspree.com.',
  },
  DEACTIVATE: {
    TITLE: 'Sorry',
    MESSAGE:
      'We are unable to deactivate this property. Please refresh your screen and try again. If the problem persists, contact support@rentspree.com.',
  },
}

export const OTHER_DOCUMENTS = {
  REQUEST_SUCCESS: {
    TITLE: 'Document request successfully sent',
    MESSAGE:
      "You'll receive an email notification when the applicant uploads all requested documents.",
  },
  DELETE_SUCCESS: {
    TITLE: 'Successfully deleted files',
    MESSAGE:
      'All files of this document have been removed and agent can no longer access them. If you wish to send a new file, please re-upload a new one on this document.',
  },
  DELETE_FAILED: {
    TITLE: 'Unable to delete files',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  UPLOAD_FAILED: {
    TITLE: 'Unable to upload files',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  DROP_REJECTED: {
    TITLE: 'Files are too large or invalid type',
    MESSAGE:
      'Please make sure that each file size is less than 10MB and they are one of these types: jpeg, jpg, pdf, csv, xls, xlsx, png, doc, docx',
  },
  DOWNLOAD_DOCUMENT: {
    TITLE: 'Unable to download document',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  MAX_NUMBER_FILES: {
    TITLE: 'Maximum number of files exceeded',
    MESSAGE:
      'You are trying to uploading more than 10 files which exceed our maximum file capacity. If you need to upload all of them, please combine them into less than 10 files and try again.',
  },
}

export const SUBSCRIPTIONS = {
  AUTHORIZED: {
    TITLE: 'Service is temporarily unavailable.',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  UNKNOWN_ERROR: {
    TITLE: 'Service is temporarily unavailable.',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
}

export const PAYMENT = {
  FETCH_FAILED: {
    TITLE: 'Sorry. We are having trouble connecting to the RentSpree servers.',
    MESSAGE:
      'Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com.',
  },
  LANDLORD_PAY_GENERAL_FAILED: {
    TITLE: '',
    MESSAGE:
      'Sorry, we are experiencing an issue with payment processing. Please refresh the page and try again.',
  },
}

export const LRA = {
  FETCH_FAILED: {
    TITLE: 'Please try again.',
    MESSAGE:
      'Sorry, we cannot generate the C.A.R. rental app at this time. Please contact support@rentspree.com if the problem persists.',
  },
}

export const DENY_APPLICATION = {
  SEND_FAIL: {
    TITLE: 'Trouble sending email',
    MESSAGE:
      'Please try again in a few minutes. If the error persists, contact us at support@rentspree.com or (323) 515-7757.',
  },
}
