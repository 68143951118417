import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectReducer from "utils/inject-reducer"
import injectSaga from "utils/inject-saga"
import { selectRenter } from "containers/application/selectors"
import {
  selectCreatePropertyModal,
  selectIsSubmitting,
  selectPropertyList,
  selectIsLoading,
  selectSelectedPropertyId,
  selectError,
  selectExistingSubmissionErrorModal,
} from "./selectors"
import saga from "./saga"
import {
  createAndAssignProperty,
  createAndSelectProperty,
  openCreatePropertyModal,
  closeCreatePropertyModal,
  clearCreatePropertyModalError,
  assignProperty,
  selectProperty,
  getAssignPropertyList,
  openExistingSubmissionErrorModal,
} from "./actions"
import reducer from "./reducer"

const mapStateToProps = createStructuredSelector({
  propertyList: selectPropertyList,
  isLoading: selectIsLoading,
  selectedPropertyId: selectSelectedPropertyId,
  error: selectError,
  isSubmitting: selectIsSubmitting,
  applicant: selectRenter,
  createPropertyModal: selectCreatePropertyModal,
  existingSubmissionErrorModal: selectExistingSubmissionErrorModal,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createAndAssignProperty,
      createAndSelectProperty,
      openCreatePropertyModal,
      closeCreatePropertyModal,
      clearCreatePropertyModalError,
      getAssignPropertyList,
      assignProperty,
      selectProperty,
      openExistingSubmissionErrorModal,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "assignProperty", saga })
export const withReducer = injectReducer({ key: "assignProperty", reducer })
