import { createSelector } from 'reselect'

const getReportApplication = state => state?.reportApplication || {}

export const makeSelectReportApplication = () =>
  createSelector(getReportApplication, state => state)

export const makeSelectPdfFile = () =>
  createSelector(makeSelectReportApplication(), state => state?.pdfFile || null)

export const makeSelectIsLoadingGetPdf = () =>
  createSelector(makeSelectReportApplication(), state => state?.isLoadingGetPdf || false)
