import { Level1Template } from '@rentspree/component-2023.components.templates.level-1-template'
import React, { useRef } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { query } from '@rentspree/path'
import tracker from 'tracker'
import { EVENT_MESSAGING_TOOL } from 'tracker/const'
import isEmpty from 'lodash/isEmpty'

import { MessagingWrapper, ChatBoxWrapper, ChatBoxLoadingWrapper } from 'components/messaging'
import { Loading, Div } from 'components/layout/main'
import { DefaultModal } from 'components/modal'
import { removeQuery } from 'utils/path'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import { MESSAGING as MESSAGING_MSG_ERR } from 'constants/error-messages'
import { CenterLoading } from 'components/organisms/center-loading'
import { feedbackCollectionInstance } from 'utils/feedback-collection/instance'
import {
  RENTSPREE_EXPERIENCE_SITE_AGENT_MESSAGING,
  SITE_AGENT_MESSAGING_NPS,
} from 'utils/feedback-collection/constant'
import { MOBILE_THRESHOLD } from 'utils/constants'
import useMobile from 'hooks/use-mobile'

import { withConnect } from './connect'
import MessagingEmailForm from './form/messaging-form'
import { MessageLandings, NewMessageCTA } from './message-landing'
import './messaging.scss'

export const Messaging = ({
  isTalkJsReady,
  isUnreadNotificationReady,
  actions,
  isLoading,
  isError,
  isShowNewMessageModal,
  isConversationStarted,
  currentConversationId,
  location,
  history,
}) => {
  const { search, pathname } = location
  const inbox = useRef(null)
  const container = useRef(null)
  const [showChatBox, setShowChatBox] = React.useState(false)
  const { conversationId } = query.parse(search)

  const urlParams = new URLSearchParams(search)
  const emailFromContactDetail = urlParams.get('emailFromContactDetail')

  React.useEffect(() => {
    const { setConversation, getConversation, clearConversation } = actions
    if (conversationId) {
      setConversation(conversationId)
      const removedQuery = removeQuery(search, ['conversationId'])
      history.replace(`${pathname}${removedQuery}`)
    } else if (!currentConversationId) getConversation()

    if (!isEmpty(emailFromContactDetail)) {
      actions.startConversation({ recipient: emailFromContactDetail })
    }

    return () => {
      clearConversation()
      if (inbox.current) {
        inbox.current.destroy()
      }
    }
  }, [])

  React.useEffect(() => {
    if (!inbox.current && isTalkJsReady && isUnreadNotificationReady && currentConversationId) {
      inbox.current = window.talkSession.createInbox({
        selected: currentConversationId,
        showChatHeader: true,
        showFeedHeader: false,
      })
      inbox.current.mount(container.current).then(() => setShowChatBox(true))
      inbox.current.on('sendMessage', async () => {
        tracker.trackEvent(EVENT_MESSAGING_TOOL.SEND_MESSAGE)
        await feedbackCollectionInstance.showSurvey(SITE_AGENT_MESSAGING_NPS)
      })
    }
  }, [inbox, isTalkJsReady, isUnreadNotificationReady, currentConversationId])

  React.useEffect(() => {
    if (inbox.current && isConversationStarted && currentConversationId) {
      inbox.current.select(currentConversationId)
    }
  }, [inbox, isConversationStarted, currentConversationId])

  const handleHide = () => {
    if (!isLoading.modal) actions.toggleNewMessageModal(false)
  }

  React.useEffect(() => {
    feedbackCollectionInstance.onSDKReady(async () => {
      await feedbackCollectionInstance.collectEventOfInterest(
        RENTSPREE_EXPERIENCE_SITE_AGENT_MESSAGING,
        true,
      )
    })
  }, [])

  const handleClickNewMessage = eventLocation => {
    tracker.trackButtonClick({
      click_text: 'Send message',
      location: eventLocation,
    })
    actions.toggleNewMessageModal(true)
  }

  const handleSubmit = values => {
    tracker.trackEvent(EVENT_MESSAGING_TOOL.START_CONVERSATION, {
      with: values.email,
    })
    tracker.trackButtonClick({
      click_text: 'Submit message',
    })
    actions.startConversation({ recipient: values.email })
  }

  const isMobile = useMobile(MOBILE_THRESHOLD)

  if (isError.container) {
    const { TITLE, MESSAGE } = MESSAGING_MSG_ERR.API_FAILED
    return (
      <Div width="100%" margin="auto">
        <FailedToFetch noMargin noBreadcrumbMobile title={TITLE} text={MESSAGE} />
      </Div>
    )
  }

  if (!isTalkJsReady || !isUnreadNotificationReady || isLoading.container) {
    return <CenterLoading />
  }

  return (
    <>
      {currentConversationId ? (
        <MessagingWrapper>
          <Level1Template
            title="Messaging"
            cta={<NewMessageCTA onNewMessageClick={handleClickNewMessage} />}
            isMobile={isMobile}
          />
          <ChatBoxLoadingWrapper showChatBox={showChatBox}>
            <Loading />
          </ChatBoxLoadingWrapper>
          <ChatBoxWrapper
            id="messaging-chat-box-container"
            ref={container}
            showChatBox={showChatBox}
          />
        </MessagingWrapper>
      ) : (
        <MessageLandings isMobile={isMobile} onNewMessageClick={handleClickNewMessage} />
      )}
      <DefaultModal
        closeButton
        loadingOverlay
        className="share-report-modal"
        showModal={isShowNewMessageModal}
        closeModal={handleHide}
        dialogMaxWidth="375px"
        bodyPadding="20px 25px"
        minHeight="450px"
      >
        <MessagingEmailForm
          onSubmit={handleSubmit}
          loading={isLoading.modal}
          error={isError.modal}
          closeError={actions.closeModalError}
        />
      </DefaultModal>
    </>
  )
}
export default compose(withRouter, withConnect)(Messaging)
