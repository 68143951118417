import generateApiCall from 'utils/generate-api-call'
import {
  GET_PAYEE_RISK_LEVEL,
  GET_PAYEE_RISK_LEVEL_FAILED,
  GET_PAYEE_RISK_LEVEL_REQUEST,
  GET_PAYEE_RISK_LEVEL_SUCCESS,
} from './constants'

export const getPayeeRiskLevelAPI = generateApiCall([
  GET_PAYEE_RISK_LEVEL_REQUEST,
  GET_PAYEE_RISK_LEVEL_SUCCESS,
  GET_PAYEE_RISK_LEVEL_FAILED,
])

export const getPayeeRiskLevel = payload => ({
  type: GET_PAYEE_RISK_LEVEL,
  payload,
})
