import { TAX_INFORMATION_BASE_PATHNAME } from 'constants/route'

export const TAX_INFORMATION_STEPS = {
  BUSINESS_TYPE: 'Business Type',
  PERSONAL_DETAILS: 'Personal Details',
}

export const TAX_INFORMATION_STEP_PATHNAMES = {
  BUSINESS_TYPE_STEP: `${TAX_INFORMATION_BASE_PATHNAME}/business-type`,
  PERSONAL_DETAILS_STEP: `${TAX_INFORMATION_BASE_PATHNAME}/personal-details`,
}

export const BUSINESS_TYPE = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
  UNKNOWN: 'unknown',
}

export const IDENTITY_VERIFICATION_STATUS = {
  ONBOARDING: 'onboarding',
  VERIFYING: 'verifying',
  VERIFIED: 'verified',
  FAILED: 'failed',
  RESTRICTED: 'restricted',
  RESTRICTED_SOON: 'restrictedSoon',
  PENDING_BANK_ACCOUNT: 'pendingBankAccount',
}
export const TAX_INFORMATION_CONTENT_CONFIG = {
  BUSINESS_TYPE_STEP: {
    TITLE: {
      DESKTOP: 'Business Type',
      MOBILE: 'Identity verification',
    },
    SUBTITLE: {},
    STEPPER_TITLE: 'Identity verification',
  },
  PERSONAL_DETAILS_STEP: {
    TITLE: {
      MOBILE: 'Identity verification',
    },
    SUBTITLE: {},
    STEPPER_TITLE: 'Identity verification',
  },
}
