import React from "react"
import styled from "styled-components"
import S18 from "@rentspree/component-v2/dist/typography/s18"
import RadioButton from "@rentspree/component-v2/dist/input/radio-button"

const Styled = styled.div`
  font-family: Source Sans Pro !important;
  .radioIcon {
    margin-left: 0;
  }
`

const SubtitleWithRadio = ({
  subtitle,
  choices,
  onChange,
  radioName = "radioName",
}) => (
  <Styled>
    <S18>{subtitle}</S18>
    {choices?.map(choice => (
      <RadioButton
        key={choice.id}
        name={radioName}
        choice={choice}
        onChange={onChange}
      />
    ))}
  </Styled>
)

export default SubtitleWithRadio
