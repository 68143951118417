import React from 'react'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import PropTypes from 'prop-types'

import { ButtonSetLeaseAgreement } from 'components/buttons/button-set'
import DesktopViewOnly from 'components/desktop-view'
import SignatureModal from 'components/envelope/modal/signature-modal'
import TermsModalBody from 'components/templates/terms-modal-body'
import HandleBeforeUnload from 'components/handle-before-unload'
import EnvelopeHeaderWithNavBarMemo from 'components/organisms/envelope-header-with-navbar'
import { Container, Wrapper, FooterContainer, DocumentList } from 'components/envelope'
import DropBoxWrapper from 'containers/envelope/pdf-pages/drop-box-wrapper'
import PopperDropdown from 'containers/envelope/sign-page/popper-drop-down'
import SignFlag from 'containers/envelope/sign-flag'

import { E_SIGN_CONSENT_MODAL, SIGNING_BTN } from './constants'

const EnvelopeSigningTemplate = React.forwardRef(
  (
    {
      template,
      activeBox,
      dropdownItems,
      shouldHandleUnload,
      haveEnvelope,
      pdfPath,
      isLoadingSavingFile,
      documentListId,
      recipientsList,
      actions,
      validationErrors,
      showSignError,
      setShowSignError,
      signErrors,
      isLoadingUpdateEnvelope,
      onClickFinish,
      envelopeId,
      queryToken,
      showSignModal,
      signatureData,
      handleCloseModal,
      handleSaveSignature,
      handleEditSignature,
      isUploadingSignature,
      showConsentModal,
      error,
      selectedCheckBox,
      onCheckBoxClick,
      handleClickConsent,
      isLoadingUpdateConsent,
      onSaveCallback,
      onPrintCallback,
      pdfBase64,

      headerTitle,
      signingBtnSaveText,
      signingBtnBackText,
      onClickBackToDashboardBtn,
      hideBackButton,
      hidePrevButton,
      consentTitle,
      consentContent,
      profile,
      rentalId,
      isLoadingGeneratePdfFileURL,
    },
    ref,
  ) => (
    <DndProvider backend={Backend}>
      <PopperDropdown popper={activeBox} dropdownItems={dropdownItems} />
      {shouldHandleUnload && <HandleBeforeUnload />}
      <Container id="envelopeSigningTemplate">
        {haveEnvelope && <DesktopViewOnly />}
        <EnvelopeHeaderWithNavBarMemo
          queryToken={queryToken}
          envelopeId={envelopeId}
          pdfPath={pdfPath}
          actions={actions}
          isLoading={isLoadingSavingFile}
          headerTitle={headerTitle}
          profile={profile}
          onSaveCallback={onSaveCallback}
          onPrintCallback={onPrintCallback}
          pdfBase64={pdfBase64}
          rentalId={rentalId}
          isLoadingGeneratePdfFileURL={isLoadingGeneratePdfFileURL}
        />
        <SignFlag
          ref={ref}
          template={template}
          showSignError={showSignError}
          setShowSignError={setShowSignError}
        />
        <Wrapper>
          <DocumentList center id={documentListId}>
            <DropBoxWrapper
              viewMode
              recipientsList={recipientsList}
              actions={actions}
              validationErrors={validationErrors}
              showSignError={showSignError}
              signErrors={signErrors}
            />
          </DocumentList>
        </Wrapper>
      </Container>
      <FooterContainer>
        <ButtonSetLeaseAgreement
          margin="0 0"
          continueBtnType="submit"
          continueText={signingBtnSaveText}
          text={signingBtnBackText}
          btnHeight="40px"
          hideBackButton={hideBackButton}
          hidePrevButton={hidePrevButton}
          hideIconNextButton
          onSaveAndNext={onClickFinish}
          onClickBackToDashboardBtn={onClickBackToDashboardBtn}
          isLoading={isLoadingUpdateEnvelope}
        />
      </FooterContainer>
      <SignatureModal
        envelopeId={envelopeId}
        userToken={queryToken}
        showSignModal={showSignModal}
        signatureData={signatureData}
        closeModal={handleCloseModal}
        saveSignSignature={handleSaveSignature}
        editSignSignature={handleEditSignature}
        uploadSaveSignSignature={actions.uploadSaveSignSignature}
        uploadEditSignSignature={actions.uploadEditSignSignature}
        isUploadingSignature={isUploadingSignature}
      />
      <RentSpreeBaseModal rootId="#app" isOpen={showConsentModal} closeButton={false}>
        <TermsModalBody
          id="eSignConsentModal"
          title={consentTitle}
          terms={[
            {
              text: consentContent,
            },
          ]}
          error={error}
          selectedCheckBox={selectedCheckBox}
          onCheckBoxClick={onCheckBoxClick}
          handleClickBtn={handleClickConsent}
          isLoading={isLoadingUpdateConsent}
          onClearError={actions.clearError}
        />
      </RentSpreeBaseModal>
    </DndProvider>
  ),
)

EnvelopeSigningTemplate.defaultProps = {
  signingBtnSaveText: SIGNING_BTN.SAVE,
  signingBtnBackText: SIGNING_BTN.BACK,
  hideBackButton: true,
  hidePrevButton: true,
  consentTitle: E_SIGN_CONSENT_MODAL.TITLE,
  consentContent: E_SIGN_CONSENT_MODAL.CONTENT,
}

EnvelopeSigningTemplate.propsType = {
  headerTitle: PropTypes.string,
  signingBtnSaveText: PropTypes.string.isRequired,
  signingBtnBackText: PropTypes.string,
  hideBackButton: PropTypes.bool,
  hidePrevButton: PropTypes.bool,
  consentTitle: PropTypes.string.isRequired,
  consentContent: PropTypes.string.isRequired,
  onClickFinish: PropTypes.func.isRequired,
  onClickBackToDashboardBtn: PropTypes.func,
  onSaveCallback: PropTypes.func,
  onPrintCallback: PropTypes.func,
}

export default EnvelopeSigningTemplate
