import generateApiCall from 'utils/generate-api-call'

import {
  SET_SEEN_NOTIFICATION,
  SET_SEEN_NOTIFICATION_FAILED,
  SET_SEEN_NOTIFICATION_REQUESTED,
  SET_SEEN_NOTIFICATION_SUCCEEDED,
} from './constants'

export const setSeenNotificationById = payload => ({
  type: SET_SEEN_NOTIFICATION,
  payload,
})

export const setSeenNotificationByIdAPI = generateApiCall([
  SET_SEEN_NOTIFICATION_REQUESTED,
  SET_SEEN_NOTIFICATION_SUCCEEDED,
  SET_SEEN_NOTIFICATION_FAILED,
])
