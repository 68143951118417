import React from 'react'

import styled from 'styled-components'
import { COLOR, breakpoints } from 'styles/settings'
import {
  Content,
  InformationPanel,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { SubSectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'

const SummaryPanel = styled(InformationPanel)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 22px;

  div {
    width: calc((100% - 32px) / 3);
  }

  ${breakpoints.mobileReport(`
      row-gap: 10px;
      div {
        width: calc((100% - 16px)/2)
      }
    `)}
`

export const CriminalSummaryWrapper = styled.div`
  width: 220px;
  height: 45px;
  padding: 15px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ active }) => (active ? 'none' : COLOR.grayLight)} !important;
  border: ${({ active }) => (active ? `0.5px solid ${COLOR.grayLv3}` : `0px`)};
  border-radius: 5px;
  box-sizing: border-box;

  ${({ first }) =>
    first &&
    `
    margin-left: 0px;
  `}
  ${({ hiddenBox }) =>
    hiddenBox &&
    `
    visibility: hidden;
  `}

  ${breakpoints.mobileReport(`
    height: 90px;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  `)}
`

export const CriminalSummaryLabel = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: 'Source Sans Pro';
  word-break: break-word;
  word-wrap: break-word;
  max-width: 100%;
  font-weight: 600;
  color: ${COLOR.textBlack};

  ${breakpoints.mobileReport(`
    font-weight: 400;
  `)}
`

export const CriminalSummaryValue = styled.p`
  margin: 0;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  word-break: break-word;
  word-wrap: break-word;
  max-width: 100%;
  font-weight: 600;

  ${breakpoints.mobileReport(`
    font-size: 28px;
  `)}
`

export const CriminalSummaryBox = ({ header, quantity, hiddenBox, First, active = false }) => (
  <CriminalSummaryWrapper hiddenBox={hiddenBox} first={First} active={active}>
    <CriminalSummaryLabel>{header}</CriminalSummaryLabel>
    <CriminalSummaryValue>{quantity}</CriminalSummaryValue>
  </CriminalSummaryWrapper>
)

export const Summary = ({
  incidentCount,
  arrestCount,
  bookingCount,
  courtActionCount,
  sentencingCount,
  supervisionCount,
}) => (
  <Section>
    <SubSection>
      <SubSectionHeader title="Summary" />
      <Content>
        <SummaryPanel>
          <CriminalSummaryBox
            First
            header="Incidents"
            quantity={incidentCount}
            active={incidentCount > 0}
          />
          <CriminalSummaryBox header="Arrests" quantity={arrestCount} active={arrestCount > 0} />
          <CriminalSummaryBox header="Bookings" quantity={bookingCount} active={bookingCount > 0} />
          <CriminalSummaryBox
            First
            header="Court Actions"
            quantity={courtActionCount}
            active={courtActionCount > 0}
          />
          <CriminalSummaryBox
            header="Sentencings"
            quantity={sentencingCount}
            active={sentencingCount > 0}
          />
          <CriminalSummaryBox
            header="Supervisions"
            quantity={supervisionCount}
            active={supervisionCount > 0}
          />
        </SummaryPanel>
      </Content>
    </SubSection>
  </Section>
)
