import generateApiCall from 'utils/generate-api-call'

import {
  SETUP_CREATE_PROPERTY,
  OPEN_CREATE_PROPERTY,
  CLOSE_CREATE_PROPERTY,
  GO_NEXT_STEP_CREATE_PROPERTY,
  GO_BACK_STEP_CREATE_PROPERTY,
  RESET_CREATE_PROPERTY,
  FETCH_CREATE_PROPERTY,
  FETCH_CREATE_PROPERTY_REQUEST,
  FETCH_CREATE_PROPERTY_SUCCESS,
  FETCH_CREATE_PROPERTY_FAILED,
} from './constants'

export const setupCreateProperty = payload => ({
  type: SETUP_CREATE_PROPERTY,
  payload,
})

export const openCreateProperty = () => ({
  type: OPEN_CREATE_PROPERTY,
})

export const closeCreateProperty = () => ({
  type: CLOSE_CREATE_PROPERTY,
})

export const goNextStepCreateProperty = () => ({
  type: GO_NEXT_STEP_CREATE_PROPERTY,
})

export const goBackCreateProperty = () => ({
  type: GO_BACK_STEP_CREATE_PROPERTY,
})

export const resetCreateProperty = () => ({
  type: RESET_CREATE_PROPERTY,
})

export const fetchCreatePropertyAPI = generateApiCall([
  FETCH_CREATE_PROPERTY_REQUEST,
  FETCH_CREATE_PROPERTY_SUCCESS,
  FETCH_CREATE_PROPERTY_FAILED,
])

export const fetchCreateProperty = payload => ({
  type: FETCH_CREATE_PROPERTY,
  payload,
})
