import React from 'react'

import S16 from '@rentspree/component-v2/dist/typography/s16'
import B14 from '@rentspree/component-v2/dist/typography/b14'
import T36 from '@rentspree/component-v2/dist/typography/t36'
import S20 from '@rentspree/component-v2/dist/typography/s20'
import S18 from '@rentspree/component-v2/dist/typography/s18'

import { ProBadge } from 'components/badge'
import { COLOR } from 'styles/settings'
import { Hr } from '../../components/typography'
import {
  OfferWrapper,
  OfferDetail,
  OfferButton,
  StrikeThroughText,
  PriceTagContainer,
  FeatureTitleWrapper,
  ProPackageWrapper,
  FeatureItem,
  FeatureItemWrapper,
  ContentWrapper,
} from './styles'

export const OfferWithButton = ({ totalPrice, fullPrice, onClick, textButton }) => {
  const shouldShowStrikeThroughText = totalPrice !== fullPrice
  return (
    <OfferWrapper>
      <OfferDetail>
        {shouldShowStrikeThroughText && (
          <S16 margin="0" lineHeight="22px">
            Limited time offer
          </S16>
        )}
        <PriceTagContainer>
          {shouldShowStrikeThroughText && fullPrice && (
            <StrikeThroughText>${fullPrice}</StrikeThroughText>
          )}
          <T36
            mSize="36px"
            tSize="36px"
            lineHeight="36px"
            margin="0"
            mMargin="0"
            tMargin="0"
            weight="600"
            color={COLOR.textBlue}
            data-testid="total-price-text"
          >
            ${totalPrice}
          </T36>
          <S16 margin="0" ml="5">
            / month
          </S16>
        </PriceTagContainer>
        <B14 margin="0">Unlimited use. Cancel anytime.</B14>
      </OfferDetail>
      <OfferButton onClick={onClick}>{textButton}</OfferButton>
    </OfferWrapper>
  )
}

export const NewFeaturesList = ({ title, features }) => (
  <div>
    <FeatureTitleWrapper>
      <S20 margin="0" mSize="20px">
        {title}
      </S20>
      <ProBadge
        style={{
          fontSize: '16px',
          fontStyle: 'italic',
          fontFamily: 'Source Sans Pro',
          fontWeight: '600',
        }}
      >
        PRO
      </ProBadge>
    </FeatureTitleWrapper>
    <FeatureItemWrapper>
      {features.map(feature => (
        <FeatureItem>
          <i className="fas fa-check-circle" />
          <S18 margin="0">{feature}</S18>
        </FeatureItem>
      ))}
    </FeatureItemWrapper>
  </div>
)

export const RentSpreeProPackage = ({
  textButton,
  fullPrice,
  totalPrice,
  featureTitle,
  features,
  content,
  onClick,
}) => (
  <ProPackageWrapper>
    <OfferWithButton
      textButton={textButton}
      fullPrice={fullPrice}
      totalPrice={totalPrice}
      onClick={onClick}
    />
    <Hr margin="30px 0 20px" />
    <NewFeaturesList title={featureTitle} features={features} />
    <ContentWrapper>{content}</ContentWrapper>
  </ProPackageWrapper>
)
