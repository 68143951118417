import generateApiCall from 'utils/generate-api-call'

import {
  DELETE_RENTAL_PAYMENT,
  DELETE_RENTAL_PAYMENT_FAILED,
  DELETE_RENTAL_PAYMENT_REQUESTED,
  DELETE_RENTAL_PAYMENT_SUCCEEDED,
} from './constants'

export const deleteRentalPayment = payload => ({
  type: DELETE_RENTAL_PAYMENT,
  payload,
})

export const deleteRentalPaymentAPI = generateApiCall([
  DELETE_RENTAL_PAYMENT_REQUESTED,
  DELETE_RENTAL_PAYMENT_SUCCEEDED,
  DELETE_RENTAL_PAYMENT_FAILED,
])
