import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Button from '@rentspree/component-2023.components.atoms.button'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
`

const ResultImage = styled.img`
  width: 125px;
  height: 96px;
  margin: 0 0 20px;
`

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`

export const SecurityDepositPromotionModal = ({
  isOpen,
  onClose,
  title,
  description,
  image,
  primaryButtonText,
  onClickPrimaryButton,
}) => (
  <RentSpreeBaseModal rootId="#app" isOpen={isOpen} onClose={onClose}>
    <Content>
      <Typography variant='title-medium' margin="0 0 20px">
        {title}
      </Typography>
      <Typography variant='body-medium' margin="0 0 24px">
        {description}
      </Typography>
      <ResultImage data-testid="app-result-modal-image" src={image} />
      <Typography variant='title' margin="0 0 20px">
        What’s next?
      </Typography>
      <Typography variant='body-medium'>
        Collect move-in fees and rent securely.
      </Typography>
      <Typography variant='body-medium' lineHeight="22px" margin="0 0 24px">
        Perfect for agents and landlords.
      </Typography>
      <ButtonContainer>
        <Button
          id="app-result-modal-primary-btn"
          data-testid="app-result-modal-primary-btn"
          onClick={onClickPrimaryButton}
          size="small"
          variant='contained'
        >
          {primaryButtonText}
        </Button>
      </ButtonContainer>
    </Content>
  </RentSpreeBaseModal>
)

SecurityDepositPromotionModal.propsType = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  primaryButtonText: PropTypes.string,
  onClickPrimaryButton: PropTypes.func,
}
