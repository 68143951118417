import { takeLatest, put, call } from 'redux-saga/effects'

import { GET_PAYOUT_METHOD_LIST, getPayoutMethodListAPI } from '../../actions'
import { getPayoutMethodListInstance } from '../../api-instances'

export function* getPayoutMethodListSaga() {
  yield put(getPayoutMethodListAPI.request())
  try {
    const response = yield call(getPayoutMethodListInstance)
    yield put(getPayoutMethodListAPI.success(response))
  } catch (err) {
    yield put(getPayoutMethodListAPI.failure(err))
  }
}

export function* watchGetPayoutMethodListSaga() {
  yield takeLatest(GET_PAYOUT_METHOD_LIST, getPayoutMethodListSaga)
}
