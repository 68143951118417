import React from 'react'
import { Switch, Route, matchPath } from 'react-router-dom'
import {
  TENANT_SCREENING,
  TENANT_SCREENING_LANDING,
  TENANT_SCREENING_PENDING,
  TENANT_SCREENING_DETAIL,
} from 'constants/route'
import { mainContent as MainContent } from 'components/layout/main-style'
import TenantScreeningDetailRoute from './detail/router'
import TenantScreeningList from './list/loadable'
import TenantScreeningLanding from './index'

import { COLOR } from '../../styles/settings'

export const TenantScreeningRoute = () => (
  <MainContent bgColor={COLOR.whiteContainer}>
    <Switch>
      <Route path={TENANT_SCREENING_LANDING}>
        <TenantScreeningLanding />
      </Route>
      <Route
        exact
        path={TENANT_SCREENING}
        render={renderProps => <TenantScreeningList {...renderProps} />}
      />
      <Route path={TENANT_SCREENING_DETAIL} component={TenantScreeningDetailRoute} />
    </Switch>
  </MainContent>
)
