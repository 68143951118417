import values from 'lodash/values'
import entries from 'lodash/entries'
import tracker from 'tracker'
import {
  EVENT_RENTSPREE_PRO,
  EVENT_TENANT_SCREENING,
  EVENT_INCOME_VERIFICATION,
} from 'tracker/const'
import { APP_TYPES } from 'containers/application/const'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { EVENT_ENTER_SELECT_OPTION_PAGE, OTHER_DOCS } from 'legacy/tracker/const'
import { REFERENCE_CHECK_SUB_TYPE } from 'containers/contacts/documents/constants'
import { APPLICATION_TYPE, BUNDLE_TYPE, PAYER_TYPE } from './constants'

const { EVENT_PROPERTY, EVENT_NAME } = EVENT_TENANT_SCREENING
const { EVENT_PROPERTY: EVENT_PROPERTY_INCOME_VERI, EVENT_NAME: EVENT_NAME_INCOME_VERI } =
  EVENT_INCOME_VERIFICATION

const bundleTypeMapper = ({ incomeVerificationOption }) => {
  if (incomeVerificationOption) {
    return EVENT_PROPERTY.BUNDLE_TYPE.PREMIUM
  }
  return EVENT_PROPERTY.BUNDLE_TYPE.STANDARD
}

export const trackSelectApplicationPayer = ({ propertyId, payerType }) => {
  const trackerPayerType = {
    [PAYER_TYPE.ME]: EVENT_PROPERTY.APPLICATION_PAYER.AGENT,
    [PAYER_TYPE.APPLICANTS]: EVENT_PROPERTY.APPLICATION_PAYER.APPLICANTS,
  }

  tracker.trackEvent(EVENT_NAME.SELECT_APPLICATION_PAYER, {
    property_id: propertyId,
    payer: trackerPayerType[payerType],
  })
}

export const trackSelectBundle = ({ propertyId, bundleType }) => {
  const trackerBundleType = {
    [BUNDLE_TYPE.PREMIUM]: EVENT_PROPERTY.BUNDLE_TYPE.PREMIUM,
    [BUNDLE_TYPE.STANDARD]: EVENT_PROPERTY.BUNDLE_TYPE.STANDARD,
    [BUNDLE_TYPE.APPLICATION_ONLY]: EVENT_PROPERTY.BUNDLE_TYPE.APPLICATION_ONLY,
  }

  tracker.trackEvent(EVENT_NAME.SELECT_SCREENING_BUNDLE_TYPE, {
    property_id: propertyId,
    bundle_type: trackerBundleType[bundleType],
  })
}

export const trackSelectApplicationType = ({ propertyId, applicationType }) => {
  const trackerApplicationType = {
    [APPLICATION_TYPE.RENTSPREE]: EVENT_PROPERTY.APPLICATION_TYPE.STANDARD_RENTAL_APPLICATION,
    [APPLICATION_TYPE.CAR_LRA]: EVENT_PROPERTY.APPLICATION_TYPE.CAR_LRA,
    [APPLICATION_TYPE.NO_APPLICATION]: EVENT_PROPERTY.APPLICATION_TYPE.NO_APPLICATION,
  }

  tracker.trackEvent(EVENT_NAME.SELECT_APPLICATION_TYPE, {
    property_id: propertyId,
    application_type: trackerApplicationType[applicationType],
  })
}

export const trackRequestRefChecks = ({ propertyId, requestOptions, userPlan }) => {
  if (!requestOptions.refCheckOption) return

  const { eventName: refCheckEventName } = EVENT_RENTSPREE_PRO.REF_CHECK

  const referenceTypes = values(REFERENCE_CHECK_SUB_TYPE) // those reference types will be autmatically requested
  referenceTypes.forEach(referenceType => {
    tracker.trackEvent(refCheckEventName, {
      property_id: propertyId,
      is_pro: userPlan === SUBSCRIPTION_PLAN.PRO,
      reference: referenceType,
      click_from: OTHER_DOCS.EVENT_PROPERTY.CLICK_FROM.SCREENING_OPTION,
      bundle: bundleTypeMapper(requestOptions),
    })
  })
}

export const trackRequestOtherDocs = ({ requestOptions, propertyId, userPlan }) => {
  const { eventName: otherDocsEventName, mapping: otherDocsMapping } =
    EVENT_RENTSPREE_PRO.OTHER_DOCS

  const { documentOption } = requestOptions

  entries(documentOption).map(([, value = {}]) => {
    tracker.trackEvent(otherDocsEventName.request, {
      property_id: propertyId,
      is_pro: userPlan === SUBSCRIPTION_PLAN.PRO,
      doc: otherDocsMapping[value.type],
      has_instruction: !!value.note,
      click_from: OTHER_DOCS.EVENT_PROPERTY.CLICK_FROM.SCREENING_OPTION,
      bundle: bundleTypeMapper(requestOptions),
    })

    return null
  })
}

export const trackConfirmScreeningOption = ({ propertyId, requestOptions }) => {
  const { applicationType, creditReport, criminal, eviction, incomeVerificationOption } =
    requestOptions
  const hasRentalApp = applicationType !== APPLICATION_TYPE.NO_APPLICATION
  const templates = {
    [APPLICATION_TYPE.RENTSPREE]: APP_TYPES.RENTSPREE,
    [APPLICATION_TYPE.CAR_LRA]: APP_TYPES.CAR_LRA,
  }

  tracker.trackEvent(EVENT_NAME.CONFIRM_SCREENING_OPTION, {
    property_id: propertyId,
    has_rental_app: hasRentalApp,
    has_credit_report: creditReport,
    has_criminal: criminal,
    has_eviction: eviction,
    has_doc_upload: incomeVerificationOption,
    has_ref_check: !!(incomeVerificationOption && hasRentalApp),
    template: templates[applicationType],
    bundle: bundleTypeMapper(requestOptions),
  })
}

export const trackEnterSelectOptionPage = ({ userPlan, isFirstTimeUser }) => {
  const isPro = userPlan === SUBSCRIPTION_PLAN.PRO
  tracker.trackEvent(EVENT_ENTER_SELECT_OPTION_PAGE.eventName, {
    is_pro: isPro,
    is_first_time_user: isFirstTimeUser,
  })
}

export const trackViewIncomeVerificationAnnouncementModal = () => {
  tracker.trackEvent(EVENT_NAME_INCOME_VERI.VIEW_INCOME_VERI_MODAL, {})
}

export const trackingViewSampleIncomeReport = () => {
  tracker.trackEvent(EVENT_NAME_INCOME_VERI.CLICK_INCOME_VERI_MODAL, {
    click_on: EVENT_PROPERTY_INCOME_VERI.VIEW_SAMPLE_INCOME_REPORT,
  })
}

export const trackingClickGetReportCTA = () => {
  tracker.trackEvent(EVENT_NAME_INCOME_VERI.CLICK_INCOME_VERI_MODAL, {
    click_on: EVENT_PROPERTY_INCOME_VERI.GET_REPORT,
  })
}

export const trackingOnCloseModal = () => {
  tracker.trackEvent(EVENT_NAME_INCOME_VERI.CLICK_INCOME_VERI_MODAL, {
    click_on: EVENT_PROPERTY_INCOME_VERI.CLOSE_MODAL,
  })
}
