import React from "react"
import styled from "styled-components"
import { Line } from "rc-progress"
import { COLOR } from "legacy/styles/settings"

import iconLetterBlue from "legacy/images/icons/credit-report/report-blue.svg"
import iconLetterRed from "legacy/images/icons/credit-report/report-red.svg"

export const EmailText = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: ${COLOR.textGrey};
`
export const Content = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`

export const StyledImg = styled.img`
  margin: 0px;
  width: 120px;
  height: auto;
`

export const Header = styled.h3`
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`

export const BodyContent = styled.div`
  padding: 0 10px;
`

export const BtnTryAgain = styled.div`
  display: inline-block;
  background: #cd3044;
  padding: 10px 30px;
  color: white;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const StyledProgress = styled.div`
  color: ${COLOR.whiteBlue};
  font-weight: bold;
  text-align: center;
  width: 100%;
`

const switchPathLetter = type => {
  switch (type) {
    case "letter-red":
      return iconLetterRed
    default:
      return iconLetterBlue
  }
}
export const LetterImage = ({ type }) => (
  <StyledImg src={switchPathLetter(type)} />
)

export const LoadingProgress = ({ progress }) => (
  <StyledProgress>
    {progress}%
    <Line
      percent={progress}
      style={{
        height: 10,
        width: "100%",
      }}
      strokeWidth="3"
      strokeColor={COLOR.whiteBlue}
    />
  </StyledProgress>
)
