/* eslint-disable camelcase */
import React from 'react'

import { Table } from '@rentspree/component-2023.components.organisms.table'
import { TableBody } from '@rentspree/component-2023.components.organisms.table-body'
import { TableCell } from '@rentspree/component-2023.components.organisms.table-cell'
import { TableHead } from '@rentspree/component-2023.components.organisms.table-head'
import { TableRow } from '@rentspree/component-2023.components.organisms.table-row'
import Typography from '@rentspree/component-2023.components.atoms.typography'

import { CenterContent } from 'components/layout/main'
import { NoData } from 'components/property-list/no-property'

import { Box } from '@mui/material'

import './index.scss'
import { buildPath } from '@rentspree/path'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'
import { DropdownWithUseDisableFeature } from 'components/property-list/dropdown-with-use-disable-feature'
import { MODAL_STATUS } from 'containers/property/toggle-archive-property/constants'
import { generateAddress } from '@rentspree/helper'

import { IconColumn, IconColumnText } from 'legacy/components/table/table-style'
import Badge from '@rentspree/component-2023.components.atoms.badge'
import { Button } from 'legacy/components/buttons/buttons'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import { COLOR } from 'styles/settings'
import { TABLE_HEADER } from '../constants'

export const PropertyListTable = ({
  data,
  isLoading,
  isHighlightScreenTenant,
  setIsHighlightScreenTenant,
  // TODO: [TECH-21822] 'actions' prop could be refactor to handler onClickArchiveProperty and onClickUnarchiveProperty
  actions,
  history,
  hasNoQueryOption,
}) => {
  if (!isLoading && data.length === 0 && !hasNoQueryOption) {
    return (
      <CenterContent withFilter withBreadcrumb>
        <NoData text="No properties match your search criteria." />
      </CenterContent>
    )
  }

  // TODO: [TECH-21822] these handler could be refactor, moving outside this component //
  const clickArchiveProperty = propertyId => {
    actions.openArchiveModal({
      type: MODAL_STATUS.ARCHIVE,
      propertyId,
    })
  }

  // TODO: [TECH-21822] these handler could be refactor, moving outside this component //
  const clickUnarchiveProperty = propertyId => {
    actions.openArchiveModal({
      type: MODAL_STATUS.UNARCHIVE,
      propertyId,
    })
  }

  return (
    <Box id="propertyTable" className="property-table" isHideDesktop={data.length === 0}>
      <Table className="property-table-container">
        <TableHead>
          <TableRow id="propertyTableHeader">
            {TABLE_HEADER.map(header => (
              <TableCell key={header.value} sx={{ minWidth: header.minWidth ?? 'unset' }}>
                <Box className="property-table-header-cell" sx={{ justifyContent: header.align }}>
                  {header.text}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="property-table-body-container">
          {data?.map((property, index) => {
            const {
              _id: propertyId,
              archived,
              total_requested,
              total_submitted,
              total_shared,
            } = property
            const isArchived = !!archived

            return (
              <TableRow
                className="property-table-body-row"
                id={`propertyTableRow${index}`}
                data-testid="property-table-row"
                key={index}
                greyRow={isArchived}
                tabIndex="0"
                onClick={() => {
                  const url = buildPath(PROPERTY_OVERVIEW_PAGE, {
                    propertyId,
                  })
                  history.push(url)
                }}
                sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#f5f5f5' } }}
              >
                <TableCell>
                  <AddressColumn property={property} index={index} />
                </TableCell>
                <TableCell align="center">
                  <PropertyRowLabel isArchived={isArchived}>{total_requested}</PropertyRowLabel>
                </TableCell>
                <TableCell align="center">
                  <PropertyRowLabel isArchived={isArchived}>{total_submitted}</PropertyRowLabel>
                </TableCell>
                <TableCell align="center">
                  <PropertyRowLabel isArchived={isArchived}>{total_shared}</PropertyRowLabel>
                </TableCell>
                <TableCell align="right">
                  {isArchived ? (
                    // to replace with new design
                    <Button
                      green
                      text="Unarchive"
                      id={`UnarchiveBtn${index}`}
                      fontSize="12"
                      weight="normal"
                      height="auto"
                      width="auto"
                      padding="5px 12px 6px 12px"
                      onClick={e => {
                        e.stopPropagation() // TODO: legacy-to-do: add type to button to prevent redirect and check the impacts
                        clickUnarchiveProperty(propertyId)
                      }}
                    />
                  ) : (
                    <DropdownWithUseDisableFeature
                      id={`DropdownAction${index}`}
                      property={property}
                      clickArchiveProperty={clickArchiveProperty}
                      history={history}
                      isHighlightScreenTenant={isHighlightScreenTenant && index === 0}
                      setIsHighlightScreenTenant={setIsHighlightScreenTenant}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export const AddressColumn = ({ property, index }) => {
  const address = generateAddress(property)
  const isArchived = !!property.archived
  return (
    <IconColumn id={`propertyItem${index}`}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
        <IconColumnText id={`propertyItem${index}Text`} className="ellipsis-text">
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            <HomeRoundedIcon
              name="listing"
              id={`propertyItem${index}Icon`}
              sx={{ fontSize: '24px', color: isArchived ? COLOR.statusNeutral : 'unset' }}
            />
            <PropertyRowLabel isArchived={isArchived}>{address}</PropertyRowLabel>
          </Box>
        </IconColumnText>
        {property.total_unopen > 0 && (
          <Badge
            badgeContent={property.total_unopen}
            sx={{ marginLeft: '10px' }}
            id={`propertyItem${index}Noti`}
            data-testid={`propertyItem${index}Noti`}
          />
        )}
      </Box>
    </IconColumn>
  )
}

const PropertyRowLabel = ({ isArchived, children }) => (
  <Typography variant="body-medium" color={isArchived ? COLOR.statusNeutral : COLOR.blackText}>
    {children}
  </Typography>
)
