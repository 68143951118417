import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TitleText = styled(Typography)(() => ({
  fontFamily: 'Inter',
  // variant seems to be ignored? Unsure why, forcing fontSize instead
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '36px',
  textAlign: 'left',
  marginTop: '20px',
  marginBottom: '32px',
}))
