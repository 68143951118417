import React from "react"
import { withFormik } from "formik"
import styled from "styled-components"
import { SelectDropdown } from "@rentspree/component-v2"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import Button from "@rentspree/component-v2/dist/button"
import TitleWithDescription from "components/molecules/title-description"
import { ButtonSet } from "components/buttons/button-set"
import { schema } from "./form-schema"

export const FormWrapper = styled.div`
  width: 500px;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
export const Form = ({
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  backToDashboard,
  touched,
  errors,
  values,
  propertyList,
  onCreateProperty,
  allowToCreateProperty,
  dropdownLabel,
  pageTitle,
  continueText,
  createNewPropertyText,
}) => {
  const handleSetFieldValue = value => {
    setFieldValue("propertySelector", value)
  }
  const handleSetFieldTouched = () => {
    setFieldTouched("propertySelector", true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <TitleWithDescription title={pageTitle} />
      <SelectDropdown
        isRequired
        name="propertySelector"
        id="propertySelector"
        placeholder={
          values.propertySelector
            ? false
            : {
                label: "Select",
                value: "",
              }
        }
        value={values.propertySelector}
        onChange={handleSetFieldValue}
        onBlur={handleSetFieldTouched}
        label={dropdownLabel}
        options={propertyList}
        error={touched.propertySelector && errors.propertySelector}
      />
      {allowToCreateProperty && (
        <B16 weight="600">
          Or{" "}
          <Button
            transparent
            blueText
            semiBold
            type="button"
            margin="0"
            text={createNewPropertyText}
            id="tranBtn"
            onClick={onCreateProperty}
          />
        </B16>
      )}
      <ButtonSet
        mWidth="100%"
        text="Back to dashboard"
        onClickBackToDashboardBtn={backToDashboard}
        hasIconNextBtn
        isShowPrev={false}
        continueText={continueText}
        continueBtnType="submit"
      />
    </form>
  )
}

const SelectPropertyForm = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values.propertySelector)
  },
  initialValues: { propertySelector: "" },
  enableReinitialize: true,
  displayName: "SelectPropertyForm",
})(Form)

export default SelectPropertyForm
