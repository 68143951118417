import renterInsuranceLandingImage from "images/icons/renter-insurance/landing.svg"
import peaceImg from "images/renter-insurance/peace_of_mind_landlords.svg"
import protectionImg from "images/renter-insurance/protection.svg"
import qualityImg from "images/renter-insurance/quality_tenants.svg"

export const GET_RENTER_INSURANCE_LIST_CALL = "GET_RENTER_INSURANCE_LIST_CALL"
export const GET_RENTER_INSURANCE_LIST_REQUEST =
  "GET_RENTER_INSURANCE_LIST_REQUEST"
export const GET_RENTER_INSURANCE_LIST_SUCCESS =
  "GET_RENTER_INSURANCE_LIST_SUCCESS"
export const GET_RENTER_INSURANCE_LIST_FAILURE =
  "GET_RENTER_INSURANCE_LIST_FAILURE"

export const CLEAR_RENTER_INSURANCE_LIST_CALL =
  "CLEAR_RENTER_INSURANCE_LIST_CALL"

export const API_GET_RENTER_INSURANCE_LIST =
  "/api/v2/properties/:propertyId/insurance/renter"

export const STATUS_TEXT = {
  PROCESSING: "Renter Started",
  NOTIFIED: "Notified",
  INACTIVE: "Inactive",
  ACTIVE: "Active",
  UPLOADED: "Uploaded",
}

export const RENTER_INSURANCE_CHANNEL = {
  UPLOAD: "upload",
  ONLINE: "online",
}

export const BTN_TEXT = "Get started"

export const MAIN_CONTENT = {
  imgSrc: renterInsuranceLandingImage,
  title: "Renters Insurance",
  description:
    "Notify tenants about Renters Insurance. Tenants can either upload proof of an active policy or purchase a new one through RentSpree.",
}

export const EXTRA_TITLE = "RentSpree makes it easy to get protection"

export const EXTRA_DETAILS = [
  {
    imgSrc: peaceImg,
    title: "Peace of mind for landlords",
    description:
      "Ensure protection against property damage and personal liability. Avoid legal complications while leasing.",
  },
  {
    imgSrc: protectionImg,
    title: "Easy for tenants",
    description:
      "Renters insurance protects tenants & their belongings. Tenants can purchase coverage or submit proof of policy in one-stop.",
  },
  {
    imgSrc: qualityImg,
    title: "Safe for you, safe for everyone",
    description:
      "RentSpree helps secure proof of insurance so you can verify tenants more efficiently.",
  },
]
