import get from 'lodash/get'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  currentReportStep,
  isLandlord,
  isRenterValidator,
} from 'legacy/reducers/application.reducer'
import { RENTAL_STATUS } from 'legacy/constants/rental-consts'

import * as ModalActions from 'legacy/actions/modals.action'
import { getAcceptAgreementCall, clearAgreementAcceptedStatus } from 'containers/agreement/actions'
import injectSaga from 'utils/inject-saga'
import injectReducer from 'utils/inject-reducer'
import reducer from './reducer'
import { getIsWhitelisting } from './actions'
import saga from './saga'

export const mapStateToProps = state => ({
  isRentalSubmissionStatusSubmitted: [
    RENTAL_STATUS.SUBMITTED,
    RENTAL_STATUS.PROPERTY_REQUIRED,
  ].includes(get(state, 'application.rentalDetail.status', '')),
  creditReport: state.creditReport,
  profile: get(state, 'user.profile', {}),
  currentReportStep: currentReportStep(state),
  isLandlord: isLandlord(state),
  isRenter: isRenterValidator(state),
  modals: state.modals,
  rentalDetail: get(state, 'application.rentalDetail', ''),
  creditReportRentalDetail: get(state, 'application.rentalDetail.credit_report', ''),
  agreement: get(state, 'agreement', {}),
  checkWhitelisting: get(state, 'checkWhitelisting.data', {}),
  isLoading: get(state, 'checkWhitelisting.isLoading', false),
  isError: get(state, 'checkWhitelisting.isError', false),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      // the ModalActions cannot be remove
      // the withOpen component will send these props to payment and open report page to trigger modal
      ...ModalActions,
      getAcceptAgreementCall,
      clearAgreementAcceptedStatus,
      getIsWhitelisting,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withReducer = injectReducer({
  key: 'checkWhitelisting',
  reducer,
})

export const withSaga = injectSaga({ key: 'checkWhitelisting', saga })
