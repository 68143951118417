import React from 'react'
import {
  GapContent,
  InformationHalfPanel,
  Section,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { SubSectionHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import { PanelContainer, FontReport, Icon } from './doc-style'
import { COMPANY_INFO } from '../../../constants/report-consts'

const CompanyDetail = ({ icon, text }) => (
  <PanelContainer Row MarginB5>
    <Icon className={icon} />
    <FontReport Detail>{text}</FontReport>
  </PanelContainer>
)
const CompanyInfoFooter = ({ isTUReport }) => (
  <Section>
    <SubSectionHeader />
    <GapContent>
      {COMPANY_INFO.map(
        (company, i) =>
          isTUReport &&
          company.title !== 'RentSpree' && (
            <InformationHalfPanel key={`${company.title}-${i.toString()}`}>
              <PanelContainer MarginB10>
                <FontReport HeaderDetail>{company.title}</FontReport>
              </PanelContainer>
              <CompanyDetail icon="icon-user" text={company.name} />
              <CompanyDetail icon="icon-phone" text={company.phone} />
              <CompanyDetail icon="icon-location-pin" text={company.address} />
            </InformationHalfPanel>
          ),
      )}
    </GapContent>
  </Section>
)

export default CompanyInfoFooter
