import { useMediaQuery, useTheme } from '@mui/material'

export const useScreenSize = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return {
    isDesktop: !isTablet,
    isTablet: isTablet && !isMobile,
    isMobile,
  }
}
