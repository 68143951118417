import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureGate } from '@statsig/react-bindings'

import { CenterContent, WhiteWrapper, Loading, FilterRow } from 'components/layout/main'
import { FilterBar } from 'components/molecules/filter-bar/filter-bar'
import PropertyTable from 'components/property-list/property-table'
import ToggleArchivePropertyModal from 'containers/property/toggle-archive-property'
import Pagination from 'legacy/components/filter/pagination'
import tracker from 'tracker'
import { ARCHIVE_PROPERTY } from 'tracker/const'
import {
  ZERO_STATE_PROPERTIES,
} from 'utils/feature-flag/constants'

import { useOnboardingPartnerAgent } from 'v3/hooks/use-onboarding-partner-agent'
import { FILTER_PROPERTY, FILTER_PROPERTY_LIST_OPTIONS } from '../constants'
import { makeSelectIsFetching, makeSelectList, makeSelectPagination } from '../selectors'
import { PropertyLandingComponent } from '../landing'

// [TECH-22068] TODO: To be deprecated, after clean-up feature-flag
export const OldPropertyList = ({
  getPropertyList,
  filter,
  search,
  history,
  actions,
  hasProperty,
  hasNoQueryOption,
  handleAddNewListing,
}) => {
  // TODO: [TECH-14813] IA Zero State Clean-up remove feature flag
  const { value: isZeroStatePropertyPageEnabled } = useFeatureGate(ZERO_STATE_PROPERTIES)

  const { isHighlightScreenTenant, setIsHighlightScreenTenant } = useOnboardingPartnerAgent()

  const isFetching = useSelector(makeSelectIsFetching())
  const propertyList = useSelector(makeSelectList())
  const pagination = useSelector(makeSelectPagination())

  const shouldDisplayPropertyFilterBar = useMemo(
    () => !isZeroStatePropertyPageEnabled || hasProperty,
    [isZeroStatePropertyPageEnabled, hasProperty],
  )

  const handleSearch = searchValue => {
    getPropertyList(1, {}, true, searchValue)
  }

  const handleFilter = ({ value }) => {
    tracker.trackEvent(ARCHIVE_PROPERTY.selectFilter, { status: value })
    getPropertyList(1, { status: value })
  }

  const filterList = (list, statusValue) => {
    switch (statusValue) {
      case FILTER_PROPERTY.ALL:
        return list
      case FILTER_PROPERTY.ARCHIVED:
        return remove(list, p => isEmpty(p.archived))
      case FILTER_PROPERTY.ACTIVE:
      default:
        return remove(list, p => !isEmpty(p.archived))
    }
  }

  const filteredList = useMemo(
    () => filterList(propertyList, filter.status),
    [propertyList, filter],
  )

  return (
    <div id="propertyListPage">
      {shouldDisplayPropertyFilterBar && (
        <FilterRow mJustify="flex-end" id="propertyListFilter">
          <FilterBar
            handleSearch={handleSearch}
            handleFilter={handleFilter}
            status={filter.status || FILTER_PROPERTY_LIST_OPTIONS[1].value}
            search={search}
            options={FILTER_PROPERTY_LIST_OPTIONS}
            placeholder="Search Listings"
          />
        </FilterRow>
      )}
      <WhiteWrapper
        noBreadcrumbMobile
        withStickyFooter
        withFilter
        withPagination={filteredList.length !== 0}
        withL1Template
        mMinHeight="calc(100vh - 128px - 56px)"
      >
        {isFetching && (
          <CenterContent
            overlay
            noBreadcrumbMobile
            withFilter
            withPagination={filteredList.length !== 0}
          >
            <Loading data-testid="loading" />
          </CenterContent>
        )}
        <PropertyLandingComponent
          hasProperty={hasProperty}
          handleAddNewListing={handleAddNewListing}
          history={history}
        />
        {hasProperty && (
          <div>
            <PropertyTable
              history={history}
              propertyList={propertyList}
              isFetching={isFetching}
              hasNoQueryOption={hasNoQueryOption}
              actions={actions}
              isHighlightScreenTenant={isHighlightScreenTenant}
              setIsHighlightScreenTenant={setIsHighlightScreenTenant}
            />
            <Pagination
              isFetching={isFetching}
              pagination={pagination}
              handleChangePage={getPropertyList}
            />
          </div>
        )}
      </WhiteWrapper>
      <ToggleArchivePropertyModal />
    </div>
  )
}
