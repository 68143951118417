export const FETCH_PAYEE_INFORMATION = 'rentPayment/cmd/fetchPayeeInformation'
export const FETCH_PAYEE_INFORMATION_REQUEST = 'rentPayment/evt/fetchPayeeInformationRequested'
export const FETCH_PAYEE_INFORMATION_SUCCESS = 'rentPayment/evt/fetchPayeeInformationSucceeded'
export const FETCH_PAYEE_INFORMATION_FAILED = 'rentPayment/evt/fetchPayeeInformationFailed'

export const FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION =
  'rentPayment/cmd/fetchPayeeProviderAccountInformation'
export const FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_REQUEST =
  'rentPayment/evt/fetchPayeeProviderAccountInformationRequested'
export const FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_SUCCESS =
  'rentPayment/evt/fetchPayeeProviderAccountInformationSucceeded'
export const FETCH_PAYEE_PROVIDER_ACCOUNT_INFORMATION_FAILED =
  'rentPayment/evt/fetchPayeeProviderAccountInformationFailed'

export const CHECK_RENTAL_PAYMENT_EXISTS = 'rentPayment/cmd/checkRentalPaymentExists'
export const CHECK_RENTAL_PAYMENT_EXISTS_REQUEST =
  'rentPayment/evt/checkRentalPaymentExistsRequested'
export const CHECK_RENTAL_PAYMENT_EXISTS_SUCCESS =
  'rentPayment/evt/checkRentalPaymentExistsSucceeded'
export const CHECK_RENTAL_PAYMENT_EXISTS_FAILED = 'rentPayment/evt/checkRentalPaymentExistsFailed'

export const GET_IDENTITY_VERIFICATION_DETAILS_CALL = 'rentPayment/cmd/getIdentityVerification'
export const GET_IDENTITY_VERIFICATION_DETAILS_REQUEST =
  'rentPayment/evt/getIdentityVerificationDetailsRequested'
export const GET_IDENTITY_VERIFICATION_DETAILS_SUCCESS =
  'rentPayment/evt/getIdentityVerificationDetailsSucceeded'
export const GET_IDENTITY_VERIFICATION_DETAILS_FAILED =
  'rentPayment/evt/getIdentityVerificationDetailsFailed'

export const UPDATE_IDENTITY_VERIFICATION_CALL = 'rentPayment/cmd/updateIdentityVerification'
export const UPDATE_IDENTITY_VERIFICATION_REQUEST =
  'rentPayment/evt/updateIdentityVerificationRequested'
export const UPDATE_IDENTITY_VERIFICATION_SUCCESS =
  'rentPayment/evt/updateIdentityVerificationSucceeded'
export const UPDATE_IDENTITY_VERIFICATION_FAILED =
  'rentPayment/evt/updateIdentityVerificationFailed'
export const RESET_UPDATE_IDENTITY_VERIFICATION = 'rentPayment/cmd/resetUpdateIdentityVerification'

export const FETCH_LATEST_RENTAL_PAYMENT = 'rentPayment/cmd/fetchLatestRentalPayment'
export const FETCH_LATEST_RENTAL_PAYMENT_REQUEST =
  'rentPayment/evt/fetchLatestRentalPaymentRequested'
export const FETCH_LATEST_RENTAL_PAYMENT_SUCCESS =
  'rentPayment/evt/fetchLatestRentalPaymentSucceeded'
export const FETCH_LATEST_RENTAL_PAYMENT_FAILED = 'rentPayment/evt/fetchLatestRentalPaymentFailed'

export const UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_REQUEST =
  'rentPayment/cmd/updatePayoutMethodAllActiveRentalPaymentsRequested'
export const UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_SUCCESS =
  'rentPayment/evt/updatePayoutMethodAllActiveRentalPaymentsSucceeded'
export const UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_FAILED =
  'rentPayment/evt/updatePayoutMethodAllActiveRentalPaymentsFailed'
export const RESET_UPDATE_PAYOUT_METHOD_ALL_ACTIVE_RENTAL_PAYMENTS_STATUS =
  'rentPayment/evt/resetUpdatePayoutMethodAllActiveRentalPaymentsStatus'

export const GET_PAYEE_RISK_LEVEL = 'rentPayments/cmd/getPayeeRiskLevel'
export const GET_PAYEE_RISK_LEVEL_REQUEST = 'rentPayments/evt/getPayeeRiskLevelRequested'
export const GET_PAYEE_RISK_LEVEL_SUCCESS = 'rentPayments/evt/getPayeeRiskLevelSucceeded'
export const GET_PAYEE_RISK_LEVEL_FAILED = 'rentPayments/evt/getPayeeRiskLevelFailed'

export const GET_PAYEE_IDV_UPDATE_LINK = 'rentPayments/cmd/getPayeeIDVUpdateLink'
export const GET_PAYEE_IDV_UPDATE_LINK_REQUEST = 'rentPayments/evt/getPayeeIDVUpdateLinkRequested'
export const GET_PAYEE_IDV_UPDATE_LINK_SUCCESS = 'rentPayments/evt/getPayeeIDVUpdateLinkSucceeded'
export const GET_PAYEE_IDV_UPDATE_LINK_FAILED = 'rentPayments/evt/getPayeeIDVUpdateLinkFailed'
export const RESET_PAYEE_IDV_UPDATE_LINK_STATUS = 'rentPayment/cmd/resetPayeeIDVUpdateLinkStatus'

export const OPEN_ADD_YOUR_INFORMATION_MODAL = 'rentPayments/cmd/openAddYourInformationModal'
export const CLOSE_ADD_YOUR_INFORMATION_MODAL = 'rentPayments/cmd/closeAddYourInformationModal'

export const CREATE_IDENTITY_VERIFICATION_SESSION_CALL =
  'rentPayment/cmd/createIdentityVerificationSession'
export const CREATE_IDENTITY_VERIFICATION_SESSION_REQUEST =
  'rentPayment/evt/createIdentityVerificationSessionRequested'
export const CREATE_IDENTITY_VERIFICATION_SESSION_SUCCESS =
  'rentPayment/evt/createIdentityVerificationSessionSucceeded'
export const CREATE_IDENTITY_VERIFICATION_SESSION_FAILED =
  'rentPayment/evt/createIdentityVerificationSessionFailed'
export const RESET_IDENTITY_VERIFICATION_SESSION =
  'rentPayment/cmd/resetIdentityVerificationSession'
