import React from 'react'
import { useFeatureGate } from '@statsig/react-bindings'
import { STATSIG_LEGACY_SCREENING_GATE } from 'env'

export const withLegacyScreeningFeatureGate = Component => props => {
  const { value: isLegacyScreening } = useFeatureGate(STATSIG_LEGACY_SCREENING_GATE)
  const injectedProps = {
    isLegacyScreening,
  }
  return <Component {...props} {...injectedProps} />
}
