import React from "react"
import * as Styled from "../buttons-styled"

export const ButtonComposeComponent = ({
  text,
  children,
  icon,
  iconRight,
  gap,
  ...props
}) => Component => (
  <Component {...props}>
    {icon && <Styled.btnIcon className={icon} gap={gap} />}
    {text && text}
    {children && children}
    {iconRight && <Styled.btnIconRight className={iconRight} gap={gap} />}
  </Component>
)
