import { generateAddress } from '@rentspree/helper'
import get from 'lodash/get'

export const generatePropertyOptions = properties =>
  properties?.map(property => ({
    label: generateAddress(property),
    value: property._id,
  })) ?? []

export const generateRenterInfoOptions = renterInfoList =>
  renterInfoList?.map(renterInfo => {
    const firstName = get(renterInfo, 'firstName', '')
    const lastName = get(renterInfo, 'lastName', '')
    const email = get(renterInfo, 'email', '')

    return {
      label: `${firstName} ${lastName} (${email})`,
      value: renterInfo.id,
    }
  }) ?? []
