import { buildPath } from "@rentspree/path"
import { API_VIEW_MODAL, PROJECT } from "legacy/constants/request-const"
import fetch from "../helpers/fetch-utils"
import checkStatus from "./handlers/check-status"

export const getUnSeenFeature = group => bearer => {
  const uri = buildPath(API_VIEW_MODAL, {}, { group, project: PROJECT })
  return fetch(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const updateSeenFeature = (groups = []) => bearer => {
  const uri = buildPath(API_VIEW_MODAL)
  return fetch(uri, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(groups),
  }).then(checkStatus)
}
