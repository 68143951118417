export const TYPES = {
  CALL_COMPLETE_CREDIT_REPORT: "CALL_COMPLETE_CREDIT_REPORT",
  COMPLETE_CREDIT_REPORT_REQUESTED: "COMPLETE_CREDIT_REPORT_REQUESTED",
  COMPLETE_CREDIT_REPORT_SUCCESS: "COMPLETE_CREDIT_REPORT_SUCCESS",
  COMPLETE_CREDIT_REPORT_FAILED: "COMPLETE_CREDIT_REPORT_FAILED",
  CALL_CANCEL_CREDIT_REPORT: "CALL_CANCEL_CREDIT_REPORT",
  CANCEL_CREDIT_REPORT_REQUESTED: "CANCEL_CREDIT_REPORT_REQUESTED",
  CANCEL_CREDIT_REPORT_SUCCESS: "CANCEL_CREDIT_REPORT_SUCCESS",
  CANCEL_CREDIT_REPORT_FAILED: "CANCEL_CREDIT_REPORT_FAILED",
}

export const API_COMPLETE_CREDIT_REPORT =
  "/api/v2/rental-submissions/:rentalId/credit-report/complete"

export const API_CANCEL_CREDIT_REPORT =
  "/api/v2/rental-submissions/:rentalId/credit-report/cancel"
