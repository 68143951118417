import React from "react"
import * as PATH from 'constants/route'
import { ModalDefault, ModalActions, ModalHeader } from "./modal-default"
import { Button, Link } from "../buttons/buttons"
import { H3, P, TextDiv } from "../typography"

const ProRestrictionsModal = props => {
  const { closeModal } = props
  return (
    <ModalDefault
      {...props}
      header={
        <ModalHeader>
          <H3 strong align="left">
            Offer restrictions
          </H3>
        </ModalHeader>
      }
      className="rentspreeModal largeModal mFullModal">
      <TextDiv size="0.9em" align="left">
        <P>
          Restrictions apply to certain features and vary by device,
          geographical location of the user, and others.
        </P>
        <br />
        <P>
          Only first-time RentSpree PRO subscribers are eligible for free
          trials, introductory offers or promotional pricing.
        </P>
        <br />
        <P>
          Monthly subscription - one month trial. You’ll be automatically
          charged the price listed unless you cancel during your trial, and then
          every month starting on the first billing date until you cancel your
          subscription. Cancel anytime. No refunds or credits for partial
          billing periods.{" "}
          <Link
            href={PATH.SUPPORT_REFUND_URL}
            inlineLink
            text="Refund policy"
            target="_blank"
          />
        </P>
        <br />
        <P>
          Annual subscription - one month trial. You’ll be automatically charged
          the price listed unless you cancel during your trial, and then every
          year starting on the first billing date until you cancel your
          subscription. Cancel anytime. No refunds or credits for partial
          billing periods.{" "}
          <Link
            href={PATH.SUPPORT_REFUND_URL}
            inlineLink
            text="Refund policy"
            target="_blank"
          />
        </P>
        <br />
        <P>
          By completing your purchase, you verify that you are at least 18 years
          old and agree to these{" "}
          <Link
            href={PATH.SUPPORT_TERMS_URL}
            inlineLink
            text="terms"
            target="_blank"
          />
          .
        </P>
      </TextDiv>
      <ModalActions>
        <Button
          primary
          id="updateDashboardToV2Btn"
          className="button-lg"
          text="Done"
          minWidth="150px"
          onClick={closeModal}
        />
      </ModalActions>
    </ModalDefault>
  )
}

export default ProRestrictionsModal
