import styled from 'styled-components'
import { COLOR } from 'legacy/styles/settings'
// language=SCSS prefix=dummy{ suffix=}
export const mReportPanel = styled.span`
  display: none;
  @media (max-width: 991px) {
    display: block;
    padding: ${props => (props.mPadding ? props.mPadding : '15px 20px')};
    font-size: 1.1em;
    line-height: 1.4em;
  }

  .sub-text {
    color: ${COLOR.textGrey};
    font-size: 0.8em;
    font-weight: normal;
  }
`

export const MobileOnlyLeftPanel = styled.span`
  width: ${props => (props.haveShareButton ? '33.33%' : '50%')};
`

export const MobileOnlyRightPanel = styled.span`
  display: flex;
  gap: 20px;
  padding-right: 20px;
`
export const MobileOnlyTopPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const MobileOnlyBottomPanel = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

export const tabText = styled.span`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`
