import Snackbar from '@rentspree/component-2023.components.atoms.snackbar'
import React, { createContext, useMemo, useState } from 'react'
import styled from 'styled-components'

export const SnackbarContext = createContext()

const CustomSnackbar = styled(Snackbar)`
  --rsp_comp_snackbar_container_color: #6c6c70;
`

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [snackbarProps, setSnackbarProps] = useState()

  const showSnackbar = props => {
    setSnackbarProps(props)
    setOpen(true)
  }

  const hideSnackbar = () => {
    setOpen(false)
  }

  const value = useMemo(() => ({ showSnackbar, hideSnackbar }), [])

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <CustomSnackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ onExited: () => setSnackbarProps() }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={8000}
        {...snackbarProps}
      />
    </SnackbarContext.Provider>
  )
}
