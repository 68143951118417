import { createSelector } from "reselect"
import get from "lodash/get"
import { initialState } from "./reducer"

export const getRentersInsuranceDetail = state =>
  get(state, "rentersInsuranceDetail", initialState)

export const makeSelectRentersInsuranceDetail = () =>
  createSelector(
    getRentersInsuranceDetail,
    state => get(state, "rentersInsuranceDetail", {}),
  )

export const makeSelectIsFetching = () =>
  createSelector(
    getRentersInsuranceDetail,
    state => get(state, "isFetching", false),
  )

export const makeSelectIsError = () =>
  createSelector(
    getRentersInsuranceDetail,
    state => get(state, "isError", false),
  )

export const makeSelectRequesteeEmail = () =>
  createSelector(
    getRentersInsuranceDetail,
    state => get(state, "rentersInsuranceDetail.requestee.email", ""),
  )
