import createAction from "../apis/utils/create-action"
import * as Types from "../constants/action-types"
import * as api from "../apis/application-list.api"

export const getApplicationList = createAction(
  api.getAppList,
  [
    Types.GET_APPLICATION_LIST_REQUEST,
    Types.GET_APPLICATION_LIST_SUCCESS,
    Types.GET_APPLICATION_LIST_FAILED,
  ],
  (res = {}) => ({
    data: res.data || [],
    pagination: res.pagination || {},
  }),
)
