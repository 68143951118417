import indexOf from "lodash/indexOf"
import isNil from "lodash/isNil"

/**
 * this is the function to check if the credit report had been opened or not
 * @param {Object} creditReport the credit report object
 * @returns {Boolean} true if this credit report is already opened
 */
export function isOpen(creditReport) {
  return !!(
    creditReport && indexOf(["opened", "failed"], creditReport.status) >= 0
  )
}

/**
 * This function check if the app is read or not
 * the read parameter will presented from the backend indicate the timestamp of read from database
 * @param {Object} app the application object
 * @returns {Boolean} true if this is already read
 */
export function isRead(app) {
  return !!(app && app.agent && !isNil(app.agent.readTimestamp))
}
