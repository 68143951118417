import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Span from "@rentspree/component-v2/dist/typography/span"
import rentspreeLogo from "images/rentspree-logo-without-text.svg"
import diamondBg from "images/backgrounds/gplay-diamond.png"

export const Wrapper = styled.div`
  z-index: ${props => (props.zIndex ? props.zIndex : 1)};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
`

export const DiamondWrapper = styled.div`
  background: linear-gradient(rgba(249, 249, 249, 0.5) 0%, #f9f9f9),
    url(${diamondBg});
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
`

export const LogoWrapper = styled.div`
  position: relative;
`

export const Shadow = styled.div`
  z-index: -1;
  height: 10px;
  background: #000;
  border-radius: 80%;
  opacity: 0.5;
  animation: shadowAnimation 2s cubic-bezier(0.75, 0, 0.25, 1) infinite;
  @keyframes shadowAnimation {
    0% {
      animation-timing-function: ease-out;
      transform: scale(0.8, 1);
    }
    50% {
      animation-timing-function: ease-in;
      transform: scale(0.25, 0.5);
      opacity: 0.3;
    }
    100% {
      animation-timing-function: linear;
      transform: scale(0.8, 1);
    }
  }
`

export const LogoIcon = styled.img`
  position: relative;
  z-index: 1;
  animation: rotate 2s infinite;
  height: 100px;
  @keyframes rotate {
    0% {
      animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
      transform-style: preserve-3d;
      transform: rotateY(0deg) scale3d(1, 0.85, 1) translateY(15px);
    }
    50% {
      animation-timing-function: cubic-bezier(0.6, 0, 1, 1);
      transform-style: preserve-3d;
      transform: rotateY(360deg) scale3d(1, 1, 1) translateY(-30px);
    }
    100% {
      animation-timing-function: linear;
      transform-style: preserve-3d;
      transform: rotateY(0deg) scale3d(1, 0.85, 1) translateY(15px);
    }
  }
`
export const SpanStyle = styled(Span)`
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
`

const LogoLoading = ({ text, testWidth, textAlign, zIndex }) => (
  <Wrapper zIndex={zIndex}>
    <DiamondWrapper>
      <LogoWrapper className="logo-wrapper">
        <LogoIcon src={rentspreeLogo} alt="RentSpree Logo" />
        <Shadow />
      </LogoWrapper>
      {text && (
        <SpanStyle
          size="24px"
          lineHeight="32px"
          weight="600"
          margin="20px 0 0"
          width={testWidth}
          textAlign={textAlign}>
          {text}
        </SpanStyle>
      )}
    </DiamondWrapper>
  </Wrapper>
)

LogoLoading.propTypes = {
  /** The description text of loading */
  text: PropTypes.string,
  /** The width of text use when you want to > 1 line text */
  testWidth: PropTypes.string,
  /** the align text */
  textAlign: PropTypes.string,
}

export default LogoLoading
