import get from "lodash/get"
import { createSelector } from "reselect"

const getProperty = state => get(state, "property", {})

export const selectArchiveModal = createSelector(
  getProperty,
  state => get(state, "archiveModal", {}),
)

export const selectIsLoading = createSelector(
  selectArchiveModal,
  archiveModal => get(archiveModal, "isLoading", false),
)

export const selectIsOpen = createSelector(
  selectArchiveModal,
  archiveModal => !!get(archiveModal, "type", false),
)

export const selectIsPropertyArchived = createSelector(
  getProperty,
  state => !!get(state, "property.archived", false),
)

export const selectPropertyId = createSelector(
  selectArchiveModal,
  archiveModal => get(archiveModal, "propertyId", undefined),
)

export const selectType = createSelector(
  selectArchiveModal,
  archiveModal => get(archiveModal, "type", ""),
)
