export const REPORT_TAB_ID_ALL = "appDetailTabAll"
export const REPORT_TAB_ID_APPLICATION = "appDetailTabApplication"
export const REPORT_TAB_ID_CREDIT = "appDetailTabCredit"
export const REPORT_TAB_ID_CRIMINAL = "appDetailTabCriminal"
export const REPORT_TAB_ID_EVICTION = "appDetailTabEviction"
export const REPORT_TAB_ID_REF_CHECK = "appDetailTabArc"
export const REPORT_TAB_ID_OTHER = "appDetailTabOther"
export const REPORT_TAB_ID_INCOME_VERIFICATION = 'appDetailTabIncomeVerification'

export const POST_LOG_IN_ADVERTISE_MODAL_GROUP = "post-log-in-advertise-modal"
