import { takeLatest, put, call } from 'redux-saga/effects'

import { GET_PAYMENT_SUMMARY, getPaymentSummaryAPI } from '../../actions'
import { getPaymentSummaryInstance } from '../../api-instances'

export function* getPaymentSummarySaga({ payload }) {
  yield put(getPaymentSummaryAPI.request())
  try {
    const response = yield call(getPaymentSummaryInstance, payload)
    yield put(getPaymentSummaryAPI.success(response))
  } catch (err) {
    yield put(getPaymentSummaryAPI.failure(err))
  }
}

export function* watchGetPaymentSummarySaga() {
  yield takeLatest(GET_PAYMENT_SUMMARY, getPaymentSummarySaga)
}
