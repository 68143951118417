import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"

import {
  clearCreditPayment,
  clearPaymentError,
  setDefaultPaymentError,
  disablePaymentBtn,
} from "legacy/actions/credit-report.action"
import { getRentSubmissionById } from "legacy/actions/application.action"
import { toggleOpenCreditModal } from "legacy/actions/modals.action"
import {
  selectApplicationDetail,
  selectRentalDetail,
  selectIsFetchingRentalDetail,
} from "containers/reports/selectors"

import { getPayment, submitPayment } from "./actions"
import {
  makeSelectSubdomain,
  makeSelectCreditReportErrorMessage,
  makeSelectCreditReportDetail,
  makeSelectCreditReportPayment,
  makeSelectDisablePaymentBtn,
  makeSelectIsFetchingCreditReport,
  makeSelectIsFetchingPayment,
  makeSelectIsFetchingFailed,
} from "./selectors"
import injectSaga from "../../utils/inject-saga"
import saga from "./saga"

export const mapStateToProps = () =>
  createStructuredSelector({
    application: selectApplicationDetail,
    creditReportError: makeSelectCreditReportErrorMessage,
    creditReport: makeSelectCreditReportDetail,
    creditPayment: makeSelectCreditReportPayment,
    disabledPaymentBtn: makeSelectDisablePaymentBtn,
    isFetchingCreditReport: makeSelectIsFetchingCreditReport,
    isFetchingRentalDetail: selectIsFetchingRentalDetail,
    isFetchingCreditPayment: makeSelectIsFetchingPayment,
    isFetchingFailed: makeSelectIsFetchingFailed,
    rentalDetail: selectRentalDetail,
    subdomain: makeSelectSubdomain,
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getPayment,
        submitPayment,
        clearCreditPayment,
        clearPaymentError,
        setDefaultPaymentError,
        disablePaymentBtn,
        getRentSubmissionById,
        toggleOpenCreditModal,
      },
      dispatch,
    ),
  }
}

export const withSaga = injectSaga({ key: "landlordPaySaga", saga })

export const withLandlordPayConnect = compose(
  withSaga,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
