import React from "react"
import moment from "moment"
import styled from "styled-components"
import { COLOR } from "styles/settings"
import {
  StyledMembership,
  InfoRow,
  StyledIconContentRow,
  StyledMySubscriptionBox,
  StyledEmailBox,
} from "./my-subscription-style"
import { H3, H4, P } from "../typography"
import { DATE_FORMAT } from "../../constants/format-consts"
import { Button } from "../buttons/buttons"
import { FlexCol } from "../layout/section"
import { trackAndRedirect } from "../../helpers/track-and-redirect"
const ActionButton = styled(Button)`
  margin: 10px 0 0;
  width: 180px;
  background: ${COLOR.white};

  border: 1px solid ${COLOR.grayLv3};
  border-radius: 17.5px;
`
export const Membership = ({ subscription }) => {
  const list = subscription.lists || [{}]

  return (
    <StyledMySubscriptionBox>
      {list.map((plan, index) => (
        <MembershipDetail
          key={plan.id || index}
          name={plan.name}
          state={plan.state}
          startDate={checkValidRecurlyDate(plan.activated_at)}
          endDate={checkValidRecurlyDate(plan.current_period_ends_at)}
          expiresDate={checkValidRecurlyDate(plan.current_period_ends_at)}
          trialEnd={plan.trial_ends_at}
        />
      ))}
      <ActionButton
        id="goToManageSubscriptionLink"
        text={
          list.length === 1 && list[0].state === "canceled"
            ? "Reactivate"
            : "Manage Subscription"
        }
        onClick={() =>
          trackAndRedirect(
            "clickManageSubscription",
            subscription.url_manage_payment,
          )
        }
      />
    </StyledMySubscriptionBox>
  )
}

export const MembershipDetail = ({
  name,
  state,
  startDate,
  endDate,
  expiresDate,
  trialEnd,
}) => {
  const inTrial = moment(trialEnd).diff(moment()) > 0
  return (
    <StyledMembership>
      <H3 strong margin="10px 0 15px" size="1.4em">
        {name || "-"}
      </H3>
      <InfoRow>
        <P strong>Status:</P>
        <P strong name="status">
          {checkRecurlyState(state, expiresDate, inTrial)}
        </P>
      </InfoRow>
      <InfoRow key="since">
        <P>Member Since:</P>
        <P name="since">{startDate}</P>
      </InfoRow>
      {state === "canceled" || state === "expired" ? (
        <InfoRow key="suspended-on">
          <P color="negativeRed" name="suspend">
            {state === "expired"
              ? `Your access to all PRO features was suspended on ${expiresDate}`
              : `Your access to all PRO features will be suspended on ${expiresDate}`}
          </P>
        </InfoRow>
      ) : (
        <InfoRow key="valid-through">
          {inTrial
            ? [
                <P key="label">Trial Ends</P>,
                <P key="value" name="valid">
                  {checkValidRecurlyDate(trialEnd)}
                </P>,
              ]
            : [
                <P key="label">Next Invoice:</P>,
                <P key="value" name="valid">
                  {endDate}
                </P>,
              ]}
        </InfoRow>
      )}
    </StyledMembership>
  )
}

export function checkRecurlyState(state, expiresDate, inTrial) {
  switch (state) {
    case "active":
      return inTrial ? "In Trial" : "Active"
    case "canceled":
      return `Active until ${expiresDate}`
    case "expired":
      return "Expired"
    default:
      return "-"
  }
}

export function checkValidRecurlyDate(date) {
  if (date) {
    return moment(date).format(DATE_FORMAT)
  }
  return "-"
}

export const IconContentRow = ({ icon, title, content, link, onClick }) => (
  <StyledIconContentRow>
    <FlexCol className="icon" width="20%">
      <img src={icon.src} alt={icon.alt} />
    </FlexCol>
    <FlexCol className="content" width="75%">
      <H4 strong>{title}</H4>
      <P margin="0 0 15px">{content}</P>
      <Button
        id={link.id}
        transparentBlue
        text={link.text}
        onClick={onClick}
        maxWidth="200px"
      />
    </FlexCol>
  </StyledIconContentRow>
)

export const EmailBox = ({ text, email, ...props }) => (
  <StyledEmailBox {...props}>
    <P mb={5}>{text}</P>
    <H4 strong m0>
      {email}
    </H4>
  </StyledEmailBox>
)
