/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import get from 'lodash/get'
import tracker from 'tracker'
import { INCOME_VERIFICATION_EVENT } from 'tracker/const'
import { TENANT_SCREENING } from 'constants/route'
import { withRouter } from 'react-router-dom'
import { generateAddress } from '@rentspree/helper/build/generate-address'
import { MOBILE_TABS } from 'legacy/containers/application/application-detail-const'
import { compose } from 'redux'
import withReportAvailable from 'containers/reports/with-report-available'
import { REPORT_TYPES } from 'containers/reports/constants'
import withConnect from './connect'
import { LoadingHandler } from './handlers/loading-handler'
import { APIResponsesHandler } from './handlers/api-responses-handler'
import { ReportMetaDataHandler } from './handlers/report-metadata-handler'
import { ReportHandler } from './handlers/report-handler'

export const pageRenderHandler = (reportStatus, reportData, status, createdDate, props) => {
  const {
    isFromAllTab,
    history,
    getReportFailStatusCode,
    getIncomeVerificationFailStatusCode,
    rentalDetail,
    getIncomeVerificationByRentalSubmission,
    getIncomeVerificationReport,
    changeMobileActiveTab,
    incomeVerificationData,
    applicationDetail,
    externalRenterDetail,
    user,
    isViewedByAdmin,
  } = props
  const { screeningOption, renter } = rentalDetail
  const { residence } = applicationDetail
  let applicantDetail = {}

  if (screeningOption.credit_report) {
    const { firstName, lastName, address } = externalRenterDetail
    const formattedAddress = {
      ...address,
      streetAddress: [address?.streetAddressLineOne, address?.streetAddressLineTwo]
        .filter(l => l !== undefined && l.length !== 0)
        .join(', '),
    }
    applicantDetail = {
      firstName,
      lastName,
      address: generateAddress(formattedAddress),
    }
  } else {
    const { firstName, lastName } = renter
    const address = get(residence, 'current')
    applicantDetail = {
      firstName,
      lastName,
      address: generateAddress(address),
    }
  }

  const handleRetryButton = () => {
    tracker.trackEvent(INCOME_VERIFICATION_EVENT.EVENT_NAME.CLICK_TRY_AGAIN, {
      click_from: isFromAllTab
        ? INCOME_VERIFICATION_EVENT.CLICK_FROM.ALL_REPORT_TAB
        : INCOME_VERIFICATION_EVENT.CLICK_FROM.INCOME_VERIFICATION_TAB,
    })
    if (rentalDetail.id && rentalDetail.screeningOption?.income) {
      getIncomeVerificationByRentalSubmission(rentalDetail.id)
      getIncomeVerificationReport(rentalDetail.id)
    }
  }

  const handleClickContactSupport = () => {
    tracker.trackEvent(INCOME_VERIFICATION_EVENT.EVENT_NAME.CLICK_CONTACT_SUPPORT, {
      click_from: isFromAllTab
        ? INCOME_VERIFICATION_EVENT.CLICK_FROM.ALL_REPORT_TAB
        : INCOME_VERIFICATION_EVENT.CLICK_FROM.INCOME_VERIFICATION_TAB,
    })
  }

  const trackManageDocumentEvent = clickFrom => {
    const { ALL_REPORT_TAB, INCOME_VERIFICATION_TAB } = INCOME_VERIFICATION_EVENT.CLICK_FROM
    const eventName = INCOME_VERIFICATION_EVENT.EVENT_NAME.CLICK_MANAGE_DOCUMENTS
    tracker.trackEvent(eventName, {
      click_from: clickFrom === 'income-verification' ? INCOME_VERIFICATION_TAB : ALL_REPORT_TAB,
    })
  }

  const handleManageDocumentButton = () => {
    const part = window.location.pathname
    const parts = part.split('/')
    const propertyIndex = parts.indexOf('properties') + 1
    const rentalIndex = parts.indexOf('rental') + 1

    const currentTab = parts[parts.length - 1]
    trackManageDocumentEvent(currentTab)

    if (propertyIndex && parts[propertyIndex]) {
      history.replace(`/properties/${parts[propertyIndex]}/rental/${parts[rentalIndex]}/documents`)
      changeMobileActiveTab(MOBILE_TABS.OTHER_DOCS)
    } else {
      history.replace(`${TENANT_SCREENING}/rental/${parts[rentalIndex]}/documents`)
      changeMobileActiveTab(MOBILE_TABS.OTHER_DOCS)
    }
  }

  return (
    <LoadingHandler isFetchingReport={incomeVerificationData?.isFetchingReport}>
      <APIResponsesHandler
        isFromAllTab={isFromAllTab}
        applicantDetail={applicantDetail}
        reportData={reportData}
        getReportFailStatusCode={getReportFailStatusCode}
        getIncomeVerificationFailStatusCode={getIncomeVerificationFailStatusCode}
        handleClickContactSupport={handleClickContactSupport}
        isViewedByAdmin={isViewedByAdmin}
      >
        <ReportMetaDataHandler
          isFromAllTab={isFromAllTab}
          status={status}
          reportData={reportData}
          createdDate={createdDate}
          applicantDetail={applicantDetail}
          handleManageDocumentButton={handleManageDocumentButton}
        >
          <ReportHandler
            isFromAllTab={isFromAllTab}
            reportStatus={reportStatus}
            reportData={reportData}
            applicantDetail={applicantDetail}
            handleManageDocumentButton={handleManageDocumentButton}
            handleRetryButton={handleRetryButton}
            handleClickContactSupport={handleClickContactSupport}
          />
        </ReportMetaDataHandler>
      </APIResponsesHandler>
    </LoadingHandler>
  )
}

export const IncomeVerificationReport = ({
  isFromAllTab,
  history,
  getReportFailStatusCode,
  getIncomeVerificationFailStatusCode,
  rentalDetail,
  incomeVerificationReportStatus,
  incomeVerificationReportData,
  incomeVerificationStatus,
  incomeVerificationCreatedAt,
  getIncomeVerificationByRentalSubmission,
  getIncomeVerificationReport,
  changeMobileActiveTab,
  incomeVerificationData,
  applicationDetail,
  externalRenterDetail,
  user,
  isViewedByAdmin,
}) => {
  useEffect(() => {
    if (rentalDetail.id && rentalDetail.screeningOption.income) {
      getIncomeVerificationByRentalSubmission(rentalDetail.id)
      getIncomeVerificationReport(rentalDetail.id)
    }
  }, [rentalDetail, user])

  return (
    <>
      {pageRenderHandler(
        incomeVerificationReportStatus,
        incomeVerificationReportData,
        incomeVerificationStatus,
        incomeVerificationCreatedAt,
        {
          isFromAllTab,
          history,
          incomeVerificationData,
          getReportFailStatusCode,
          getIncomeVerificationFailStatusCode,
          rentalDetail,
          getIncomeVerificationByRentalSubmission,
          getIncomeVerificationReport,
          changeMobileActiveTab,
          applicationDetail,
          externalRenterDetail,
          user,
          isViewedByAdmin,
        },
      )}
    </>
  )
}

export default compose(
  withReportAvailable(REPORT_TYPES.INCOME_VERIFICATION),
  withConnect,
  withRouter,
)(IncomeVerificationReport)
