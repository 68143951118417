import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import addressVerificationReducer from './reducer'
import addressVerificationSaga from './saga'
import { KEY } from './constants'


export const withAddressVerificationReducer = injectReducer({
  key: KEY,
  reducer: addressVerificationReducer
})

export const withAddressVerificationSaga = injectSaga({
  key: KEY,
  saga: addressVerificationSaga,
})
