import generateApiCall from 'utils/generate-api-call'

import {
  GET_PAYEE_IDV_UPDATE_LINK,
  GET_PAYEE_IDV_UPDATE_LINK_REQUESTED,
  GET_PAYEE_IDV_UPDATE_LINK_SUCCEEDED,
  GET_PAYEE_IDV_UPDATE_LINK_FAILED,
  RESET_PAYEE_IDV_UPDATE_LINK,
} from './constants'

export const getPayeeIdvUpdateLink = payload => ({
  type: GET_PAYEE_IDV_UPDATE_LINK,
  payload,
})

export const getPayeeIdvUpdateLinkAPI = generateApiCall([
  GET_PAYEE_IDV_UPDATE_LINK_REQUESTED,
  GET_PAYEE_IDV_UPDATE_LINK_SUCCEEDED,
  GET_PAYEE_IDV_UPDATE_LINK_FAILED,
])

export const resetPayeeIdvUpdateLink = () => ({
  type: RESET_PAYEE_IDV_UPDATE_LINK,
})
