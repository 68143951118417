import produce from "immer"
import {
  GET_ESIGN_TEMPLATE_LIST_REQUEST,
  GET_ESIGN_TEMPLATE_LIST_SUCCESS,
  GET_ESIGN_TEMPLATE_LIST_FAILURE,
  CLEAR_ESIGN_TEMPLATE_LIST_CALL,
  SET_CAR_MODAL_STATE,
  CLOSE_ERROR_IN_MODAL,
  LINKING_ACCOUNT_WITHOUT_SSO_REQUEST,
  LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS,
  LINKING_ACCOUNT_WITHOUT_SSO_FAILED,
} from "./constants"

export const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  carModalState: false,
  carLinkingAccountError: {},
  carLinkingAccountSuccess: false,
  carLinkingAccountLoading: false,
  prevTemplateData: null,
}

/* eslint-disable default-case, no-param-reassign */
const eSignTemplateListReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CLEAR_ESIGN_TEMPLATE_LIST_CALL:
        draft.data = []
        draft.isLoading = false
        draft.isError = false
        break
      case GET_ESIGN_TEMPLATE_LIST_REQUEST:
        draft.isLoading = true
        draft.isError = false
        break
      case GET_ESIGN_TEMPLATE_LIST_SUCCESS:
        draft.data = action.payload
        draft.isLoading = false
        draft.isError = false
        break
      case GET_ESIGN_TEMPLATE_LIST_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
      case SET_CAR_MODAL_STATE:
        draft.carLinkingAccountError = {}
        draft.carLinkingAccountSuccess = false
        draft.carLinkingAccountLoading = false
        draft.carModalState = action.payload
        draft.prevTemplateData = action.templateData || null
        break
      case CLOSE_ERROR_IN_MODAL:
        draft.carLinkingAccountError = {}
        break
      case LINKING_ACCOUNT_WITHOUT_SSO_REQUEST:
        draft.carLinkingAccountLoading = true
        break
      case LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS:
        draft.carLinkingAccountLoading = false
        draft.carLinkingAccountSuccess = true
        break
      case LINKING_ACCOUNT_WITHOUT_SSO_FAILED:
        draft.carLinkingAccountLoading = false
        draft.carLinkingAccountError = action.payload
    }
    return draft
  })

export default eSignTemplateListReducer
