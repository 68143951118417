import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectReducer from "utils/inject-reducer"

import { getToasts } from "./selectors"
import { closeToast } from "./actions"
import reducer from "./reducer"

const mapStateToProps = createStructuredSelector({
  toasts: getToasts,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      closeToast,
    },
    dispatch,
  ),
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({
  key: "toasts",
  reducer,
})
