import React, { useEffect, useState } from 'react'
import Styled from 'styled-components'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@rentspree/component-2023.components.atoms.checkbox'
import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import isString from 'lodash/isString'

const DocumentBox = Styled.div`
  padding-bottom: 25px;
`

const CheckBoxStyled = Styled(Checkbox)`
  svg {
    width: 25px;
    height: 25px;
    color: black;
  }
`
const MAX_LENGTH = 250

const DocumentForm = ({ isSelected, note, document, handleUpdateCustomDocumentForm }) => {
  const [isReachedCharLimit, setIsReachedCharLimit] = useState(false)

  useEffect(() => {
    setIsReachedCharLimit(isString(note) && note.length >= MAX_LENGTH)
  }, [note])

  const onCheckboxChange = checked => {
    if (checked) {
      handleUpdateCustomDocumentForm(document.key, { type: document.key, note })
    } else {
      handleUpdateCustomDocumentForm(document.key, null)
    }
  }

  const onInstructionChange = event => {
    const instructionText = event.target?.value
    if (isSelected) {
      if (isString(instructionText)) {
        handleUpdateCustomDocumentForm(document.key, { type: document.key, note: instructionText })
      } else {
        handleUpdateCustomDocumentForm(document.key, { type: document.key, note: '' })
      }
    } else if (isString(instructionText) && instructionText.trim()) {
      handleUpdateCustomDocumentForm(document.key, { type: document.key, note: instructionText })
    } else {
      handleUpdateCustomDocumentForm(document.key, null)
    }
  }

  return (
    <DocumentBox id={document.key}>
      <FormControlLabel
        checked={isSelected}
        label={document.name}
        documentKey={document.key}
        control={<CheckBoxStyled />}
        onChange={(event, checked) => onCheckboxChange(checked)}
      />
      <TextInput
        multiline
        error={isReachedCharLimit}
        helperText={isReachedCharLimit ? `Character limit reached.` : ''}
        validTextCount={MAX_LENGTH}
        label="Instruction"
        value={note || ''}
        inputProps={{ maxLength: MAX_LENGTH }}
        onChange={event => onInstructionChange(event)}
      />
    </DocumentBox>
  )
}

export default DocumentForm
