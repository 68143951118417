/* eslint-disable no-param-reassign */
import Typography from '@rentspree/component-2023.components.atoms.typography'
import AutoComplete from '@rentspree/component-2023.components.organisms.autocomplete'
import CircularProgress from '@rentspree/component-2023.components.organisms.circular-progress'
import { buildPath, query } from '@rentspree/path'
import { useQueryClient } from '@tanstack/react-query'
import { get } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { RENT_PAYMENT_REQUEST_AGENT_INITIATED_PAGE } from 'constants/route'
import history from 'utils/history'
import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks'

export const PropertyForm = props => {
  const { options, location, loading, touched, errors, setFieldValue, setFieldTouched, values } =
    props
  const queryClient = useQueryClient()
  const { open: openPropertyForm, setupCreateProperty } = useCreatePropertyHook()
  const [selectedProperty, setSelectedProperty] = useState(null)

  useEffect(() => {
    setSelectedProperty(values.selectedProperty)
  }, [values.selectedProperty])

  const queryParams = useMemo(() => query.parse(location.search), [location.search])

  const handleOpenPropertyForm = () => {
    const setupOptions = {
      location: 'Set up rent payment',
      onSuccessCallback: ({ property }) => {
        queryClient.invalidateQueries({ queryKey: 'properties' })
        history.push(
          buildPath(
            RENT_PAYMENT_REQUEST_AGENT_INITIATED_PAGE,
            {},
            { ...queryParams, property: property._id },
          ),
        )
      },
    }
    setupCreateProperty(setupOptions)
    openPropertyForm()
  }

  return (
    <>
      <Typography variant="title">Property</Typography>
      <AutoComplete
        sx={{ marginTop: '16px' }}
        name="selectedProperty"
        inputProps={{
          label: 'Select property',
          required: true,
          error: get(touched, 'selectedProperty', false) && get(errors, 'selectedProperty'),
          helperText: get(touched, 'selectedProperty', false) && get(errors, 'selectedProperty'),
          endAdornment: loading ? (
            <CircularProgress sx={{ opacity: 0.5 }} color="black" size="small" />
          ) : null,
          name: 'selectedProperty',
        }}
        onChange={(_e, newValue) => {
          setFieldValue('selectedProperty', newValue)
        }}
        onBlur={() => {
          setFieldTouched('selectedProperty')
        }}
        disabled={loading}
        options={options}
        value={selectedProperty}
        visibleItemCount={3}
        ctaProps={{
          text: 'Add new property',
          onClick: () => {
            handleOpenPropertyForm()
          },
        }}
      />
    </>
  )
}
