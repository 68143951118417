export const BUTTON_TYPE_SUBMIT = 'submit'
export const BUTTON_TEXT_CONTINUE = 'Add'

export const TITLE = 'Enter the property address'

export const OPEN_CREATE_PROPERTIES_MODAL = 'OPEN_CREATE_PROPERTIES_MODAL'
export const CLOSE_CREATE_PROPERTIES_MODAL = 'CLOSE_CREATE_PROPERTIES_MODAL'
export const SHOW_ERROR_CREATE_PROPERTY_FAILED = 'SHOW_ERROR_CREATE_PROPERTY_FAILED,'
export const CLEAR_ERROR_CREATE_PROPERTY_FAILED = 'CLEAR_ERROR_CREATE_PROPERTY_FAILED'

export const CLEAR_CREATE_PROPERTY_ERROR = 'CLEAR_CREATE_PROPERTY_ERROR'

export const KEY = 'createPropertyModal'
