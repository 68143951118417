import React from 'react'
import styled from 'styled-components'

import { Loading } from 'components/layout/main'

const Overlay = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${({ zIndex }) => zIndex || '1'};
`

export const LoadingOverlay = ({ zIndex }) => (
  <Overlay zIndex={zIndex}>
    <Loading />
  </Overlay>
)

export default LoadingOverlay
