export const GET_LINK_ACCOUNT_DATA_CALL = 'GET_LINK_ACCOUNT_DATA_CALL'
export const GET_LINK_ACCOUNT_DATA_REQUESTED = 'GET_LINK_ACCOUNT_DATA_REQUESTED'
export const GET_LINK_ACCOUNT_DATA_SUCCESS = 'GET_LINK_ACCOUNT_DATA_SUCCESS'
export const GET_LINK_ACCOUNT_DATA_FAILED = 'GET_LINK_ACCOUNT_DATA_FAILED'

export const CREATE_LISTING_SCREENING_REQUEST_CALL = 'CREATE_LISTING_SCREENING_REQUEST_CALL'
export const CREATE_LISTING_SCREENING_REQUEST_REQUESTED =
  'CREATE_LISTING_SCREENING_REQUEST_REQUESTED'
export const CREATE_LISTING_SCREENING_REQUEST_SUCCESS = 'CREATE_LISTING_SCREENING_REQUEST_SUCCESS'
export const CREATE_LISTING_SCREENING_REQUEST_FAILED = 'CREATE_LISTING_SCREENING_REQUEST_FAILED'

export const SUBMIT_SCREENING_REQUEST = 'SUBMIT_SCREENING_REQUESTS'

export const SET_SHOW_CRIMINAL_DISCLAIMER_MODAL = 'SET_SHOW_CRIMINAL_DISCLAIMER_MODAL'

export const SCREENING_REQUEST = 'SCREENING_REQUEST'

export const GENERATE_PERMALINK_REQUESTED = 'GENERATE_PERMALINK_REQUESTED'
export const GENERATE_PERMALINK_SUCCESS = 'GENERATE_PERMALINK_SUCCESS'
export const GENERATE_PERMALINK_FAILED = 'GENERATE_PERMALINK_FAILED'
export const CREATE_LISTING_SCREENING_REQUEST_CALL_WITH_REDIRECT =
  'CREATE_LISTING_SCREENING_REQUEST_CALL_WITH_REDIRECT'

export const MIAMI_DADE_ZIP_CODES = [
  '33180',
  '33146',
  '33134',
  '33155',
  '33015',
  '33189',
  '33190',
  '33178',
  '33034',
  '33172',
  '33143',
  '33170',
  '33016',
  '33018',
  '33010',
  '33012',
  '33013',
  '33014',
  '33030',
  '33031',
  '33035',
  '33039',
  '33179',
  '33183',
  '33173',
  '33176',
  '33193',
  '33149',
  '33033',
  '33142',
  '33144',
  '33145',
  '33150',
  '33185',
  '33101',
  '33135',
  '33136',
  '33137',
  '33138',
  '33122',
  '33125',
  '33126',
  '33127',
  '33128',
  '33129',
  '33130',
  '33131',
  '33132',
  '33133',
  '33194',
  '33139',
  '33140',
  '33141',
  '33109',
  '33055',
  '33056',
  '33169',
  '33166',
  '33161',
  '33168',
  '33181',
  '33162',
  '33054',
  '33157',
  '33158',
  '33156',
  '33032',
  '33187',
  '33177',
  '33160',
  '33154',
  '33174',
  '33175',
  '33182',
  '33184',
  '33186',
  '33196',
  '33165',
  '33147',
  '33167',
]

export const DOCUMENT_PRO_OPTIONS = {
  PAYSTUBS: { key: 'paystubs', name: 'Proof of Income' },
  BANK_STATEMENT: { key: 'bank_statement', name: 'Bank Statement' },
  TAX_RETURN: { key: 'tax_return', name: 'Tax Returns' },
  W2S: { key: 'w2s', name: 'W-2s' },
  PASSPORT: { key: 'passport', name: 'Government Issued ID' },
  OTHER: { key: 'other', name: 'Other' },
}

export const REF_CHECK_PRO_OPTIONS = {
  CURRENT_EMPLOYER: { key: 'current_employer', name: 'Current Employer' },
  FORMER_EMPLOYER: { key: 'former_employer', name: 'Former Employer' },
  CURRENT_LANDLORD: { key: 'current_landlord', name: 'Current Landlord' },
  PREVIOUS_LANDLORD: { key: 'previous_landlord', name: 'Former Landlord' },
  FIRST_PERSONAL: { key: 'first_personal', name: 'Personal Reference #1' },
  SECOND_PERSONAL: { key: 'second_personal', name: 'Personal Reference #2' },
}
