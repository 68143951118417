import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { pageOptions } from 'v3/containers/overhaul-rent-payment/constants'
import {
  handlePaymentDetailsProgression,
  PaymentDetailsPage,
} from 'v3/containers/overhaul-rent-payment/pages/payment-details/page'
import {
  handlePropertyAndTenantProgression,
  PropertyAndTenantPage,
} from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/page'
import {
  handleRecipientDetailsProgression,
  RecipientDetailsPage,
} from 'v3/containers/overhaul-rent-payment/pages/recipient-details/page'
import {
  handleReviewProgression,
  ReviewPage,
} from 'v3/containers/overhaul-rent-payment/pages/review/page'

// Maps are order-dependent, so we can "map" this to the Stepper's page index concept dependably
export const pages = new Map([
  [
    'Recipient details',
    {
      Page: RecipientDetailsPage,
      progressionHandler: handleRecipientDetailsProgression,
      pageField: pageOptions.RECIPIENT_DETAILS,
      buttonLayout: buttonLayoutChoices.NEXT,
    },
  ],
  [
    'Property & tenant',
    {
      Page: PropertyAndTenantPage,
      progressionHandler: handlePropertyAndTenantProgression,
      pageField: pageOptions.PROPERTY_AND_TENANT,
      buttonLayout: buttonLayoutChoices.NEXT_BACK,
    },
  ],
  [
    'Payment details',
    {
      Page: PaymentDetailsPage,
      progressionHandler: handlePaymentDetailsProgression,
      pageField: pageOptions.PAYMENT_DETAILS,
      buttonLayout: buttonLayoutChoices.NEXT_BACK,
    },
  ],
  [
    'Review',
    {
      Page: ReviewPage,
      progressionHandler: handleReviewProgression,
      pageField: pageOptions.REVIEW,
      buttonLayout: buttonLayoutChoices.NEXT_BACK,
      textOverrides: { next: 'Send Request' },
    },
  ],
])

export const pageNames = new Array(...pages.keys())
