import React from "react"
import { buildPath } from "@rentspree/path"
import { Route, Switch, Redirect } from "react-router-dom"
import PropertyList from "containers/property-list"
import { NOT_FOUND, PROPERTY, PROPERTY_OVERVIEW_PAGE, SINGLE_PROPERTY } from "constants/route"
import { SINGLE_PROPERTY_WITH_TYPE } from "../../legacy/constants/route-consts"
import ListingRouteContainer from "./property-route"

function PropertyListRouteContainer() {
  return (
    <Switch>
      <Route exact path={PROPERTY} component={PropertyList} />
      <Route
        exact
        path={SINGLE_PROPERTY}
        render={props => (
          <Redirect
            to={{
              pathname: buildPath(PROPERTY_OVERVIEW_PAGE, {
                propertyId: props.match.params.propertyId,
              }),
              search: props.location.search,
            }}
          />
        )}
      />
      <Route
        path={SINGLE_PROPERTY_WITH_TYPE}
        component={ListingRouteContainer}
      />
      <Route
        render={() => {
          window.location.href = NOT_FOUND
        }}
      />
    </Switch>
  )
}

export default PropertyListRouteContainer
