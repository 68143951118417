import produce from "immer"
import {
  ARCHIVE_PROPERTY_CALL,
  ARCHIVE_PROPERTY_SUCCESS,
  ARCHIVE_PROPERTY_FAILED,
  UNARCHIVE_PROPERTY_CALL,
  UNARCHIVE_PROPERTY_SUCCESS,
  UNARCHIVE_PROPERTY_FAILED,
  OPEN_ARCHIVE_MODAL,
  CLEAR_ARCHIVE_MODAL,
} from "./constants"

export const initialState = {
  isLoading: false,
  propertyId: undefined,
  type: "",
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_ARCHIVE_MODAL:
        draft.type = payload.type
        draft.propertyId = payload.propertyId
        break
      case CLEAR_ARCHIVE_MODAL:
        draft = initialState
        break
      case ARCHIVE_PROPERTY_CALL:
      case UNARCHIVE_PROPERTY_CALL:
        draft.isLoading = true
        break
      case ARCHIVE_PROPERTY_SUCCESS:
      case ARCHIVE_PROPERTY_FAILED:
      case UNARCHIVE_PROPERTY_SUCCESS:
      case UNARCHIVE_PROPERTY_FAILED:
        draft.isLoading = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
