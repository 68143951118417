import isArray from "lodash/isArray"
import isString from "lodash/isString"
import replace from "lodash/replace"

export const invalidChars = (string, regex) => {
  const arr = string.split("")
  const invalids = []
  for (let i = 0; i < arr.length; i += 1) {
    if (!regex.test(arr[i])) {
      invalids.push(arr[i])
    }
  }
  return invalids
}

export const removeSpaceCommaFromArray = array => {
  if (!isArray(array)) return array
  return array.map(string => removeSpaceCommaFromString(string))
}

export const removeSpaceCommaFromString = string => {
  if (!isString(string)) return string
  return replace(string, /\s+|,+/g, "")
}
