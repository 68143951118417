import isEmpty from "lodash/isEmpty"

// Validation for request step create property
export function addressValidation(value) {
  let { address, zip } = value
  const { city, state, street } = value
  const Zipcode = value.postal_code

  address = address || street
  zip = zip || Zipcode

  let missingFields = ""
  const validationErrors = []
  if (isEmpty(address)) {
    missingFields += "street, "
  }
  if (isEmpty(city)) {
    missingFields += "city, "
  }
  if (isEmpty(state)) {
    missingFields += "state, "
  } else if (
    !/^(A[LKZR]|C[AOT]|D[EC]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$/.test(
      state.trim(),
    )
  ) {
    validationErrors.push("- State must be valid 2-letter abbreviation")
  }
  if (isEmpty(zip)) {
    missingFields += "postal code, "
  } else if (!/^\d{5}$/.test(zip.trim())) {
    validationErrors.push("- Postal code must be 5-digit number")
  }

  const errorObject = {
    emptyFields: !isEmpty(missingFields)
      ? missingFields.substring(0, missingFields.length - 2)
      : "",
    validationErrors,
  }
  return errorObject
}
