import produce from "immer"
import get from "lodash/get"
import { TYPES } from "./const"
import { TYPES as PAYMENT_PROCESSING_TYPES } from "../rent-estimate-payment-processing/constants"

export const initialState = {
  isFetchingPaymentRentEstimate: false,
  rentEstimatePaymentData: {},
  isSubmitingRentEstimate: false,
  rentEstimateData: {},
  rentEstimatePaymentError: null,
  rentEstimateError: null,
  paymentModal: false,
  modalSuccess: false,
}

/* eslint-disable no-param-reassign */
const rentEstimateReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case TYPES.SUBMIT_RENT_ESTIMATE_REQUEST:
        draft = {
          ...draft,
          isSubmitingRentEstimate: true,
          rentEstimatePaymentError: null,
        }
        break
      case TYPES.CREATE_RENT_ESTIMATE_REQUEST:
        draft = {
          ...draft,
          isFetchingPaymentRentEstimate: true,
          rentEstimatePaymentData: {},
          rentEstimateError: null,
        }
        break
      case TYPES.GET_PAYMENT_RENT_ESTIMATE_SUCCESS:
        draft = {
          ...draft,
          rentEstimatePaymentData: payload,
          paymentModal: true,
        }
        break
      case TYPES.SUBMIT_RENT_ESTIMATE_SUCCESS:
      case PAYMENT_PROCESSING_TYPES.COMPLETE_RENT_ESTIMATE_SUCCESS:
        draft = {
          isFetchingPaymentRentEstimate: false,
          isSubmitingRentEstimate: false,
          rentEstimateData: payload,
          paymentModal: false,
        }
        break
      case TYPES.CREATE_RENT_ESTIMATE_SUCCESS:
        draft = {
          ...draft,
          isFetchingPaymentRentEstimate: false,
          rentEstimateError: null,
          rentEstimateData: payload,
        }
        break
      case TYPES.SUBMIT_RENT_ESTIMATE_FAILED:
        draft = {
          ...draft,
          ...payload,
          isSubmitingRentEstimate: false,
        }
        break
      case TYPES.CREATE_RENT_ESTIMATE_FAILED:
      case PAYMENT_PROCESSING_TYPES.COMPLETE_RENT_ESTIMATE_FAILED:
        draft = {
          ...draft,
          isFetchingPaymentRentEstimate: false,
          rentEstimateError: {
            ...payload,
            message: get(payload, "message", "Can't get rent estimate payment"),
          },
          paymentModal: false,
        }
        break
      case TYPES.CLEAR_RENT_RANGE_ERROR:
        draft = {
          ...draft,
          rentEstimateError: null,
        }
        break
      case TYPES.CLEAR_PAYMENT:
        draft = {
          ...draft,
          rentEstimatePaymentError: null,
        }
        break
      case TYPES.PAYMENT_MODAL:
        draft = {
          ...draft,
          paymentModal: !draft.paymentModal,
        }
        break
      case TYPES.MODAL_SUCCESS:
        draft = {
          ...draft,
          modalSuccess: !draft.modalSuccess,
        }
        break
      default:
        break
    }
    return draft
  })

export default rentEstimateReducer
