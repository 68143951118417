import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { changeMobileActiveTab } from 'legacy/actions/application.action'
import {
  selectCreditReportDetail,
  selectApplicant,
  selectGeneratedOn,
  makeSelectedExpiresOn,
  selectIsFetchingCreditReport,
  makeSelectedExternalRenter,
  makeSelectedExternalLandlord,
} from './selectors'
import { selectApplicationDetail, selectRentalDetail } from '../selectors'
import {
  getCreditReport,
  fetchedCreditReportImage,
  fetchedCriminalImage,
  fetchedEvictionImage,
} from './actions'

const mapStateToProps = createStructuredSelector({
  creditReportDetail: selectCreditReportDetail,
  applicant: selectApplicant,
  externalRenterDetail: makeSelectedExternalRenter,
  externalLandlordDetail: makeSelectedExternalLandlord,
  generatedOn: selectGeneratedOn,
  expiresOn: makeSelectedExpiresOn,
  rentalDetail: selectRentalDetail,
  appDetail: selectApplicationDetail,
  isFetchingCreditReport: selectIsFetchingCreditReport,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCreditReport,
      fetchedCreditReportImage,
      fetchedCriminalImage,
      fetchedEvictionImage,
      changeMobileActiveTab,
    },
    dispatch,
  )

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
