import produce from 'immer'

import { DELETE_TAG_REQUEST, DELETE_TAG_SUCCESS, DELETE_TAG_FAILURE } from '../tags/constants'

import { OPEN_DELETE_TAG_MODAL, CLOSE_DELETE_TAG_MODAL } from './constants'

export const initialState = {
  isOpen: false,
  isLoading: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_DELETE_TAG_MODAL:
        draft.isOpen = true
        draft.isLoading = false
        break
      case CLOSE_DELETE_TAG_MODAL:
        draft.isOpen = false
        break
      case DELETE_TAG_REQUEST:
        draft.isLoading = true
        break
      case DELETE_TAG_SUCCESS:
      case DELETE_TAG_FAILURE:
        draft.isLoading = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
