import { combineReducers } from "redux"
import cloneDeep from "lodash/cloneDeep"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import findIndex from "lodash/findIndex"
import merge from "lodash/merge"
import { APPLICATION_TYPE } from "constants/application-type"

import { RENTSPREE_APPLICATION_AVAIABLE_VERSIONS } from "components/reports/application/constants"
import momentFromNow from "../helpers/moment-from-now"
import {
  defaultRefChecks,
  refCheckStatus,
  refChecks2,
  refChecks25,
} from "../constants/ref-check-consts"
import * as Types from "../constants/action-types"

export const list = (state = [], action) => {
  const defaultRef = cloneDeep(defaultRefChecks)
  switch (action.type) {
    case Types.GET_APPLICATION_SUCCESS: {
      const appVersion = get(action, "application.version")
      const appType = get(action, "application.type")

      if (appType === APPLICATION_TYPE.CAR_LRA) {
        return cloneDeep(refChecks25)
      }
      if (appVersion === "2.0") {
        return cloneDeep(refChecks2)
      } if (RENTSPREE_APPLICATION_AVAIABLE_VERSIONS.includes(appVersion)) {
        return cloneDeep(refChecks25)
      } 
        return [...defaultRef]
    }
    case Types.GET_REFERENCE_CHECK_REQUEST:
      return state
    case Types.GET_REFERENCE_CHECK_SUCCESS:
    case Types.POST_REFERENCE_CHECK_SUCCESS: {
      if (!isEmpty(action.result)) {
        const newRefs = action.result
        return state.map(ref => {
          const index = findIndex(
            newRefs,
            newRef =>
              ref.type === newRef.type && ref.arrayIndex === newRef.arrayIndex,
          )
          const mergedRef = merge(ref, newRefs[index])
          // parse status to text
          if (!isEmpty(refCheckStatus[mergedRef.status])) {
            if (mergedRef.status === refCheckStatus.requested.value) {
              mergedRef.statusText = `${
                refCheckStatus[mergedRef.status].text
              }; last request sent ${momentFromNow(mergedRef.requestedAt)}`
            } else {
              mergedRef.statusText = refCheckStatus[mergedRef.status].text
            }
            mergedRef.longText = refCheckStatus[mergedRef.status].longText
            mergedRef.color = refCheckStatus[mergedRef.status].color
          }
          // set isDisabled to true when status is not requested
          if (
            mergedRef.status &&
            mergedRef.status !== refCheckStatus.requested.value
          ) {
            mergedRef.isDisabled = true
          }
          // set isBlur to true when status is unattainable
          if (
            mergedRef.status &&
            mergedRef.status === refCheckStatus.unattainable.value
          ) {
            mergedRef.isBlur = true
          }
          mergedRef.isChecked = false
          return mergedRef
        })
      }
      return state
    }
    case Types.UPDATE_SELECTED_REFERENCE_CHECK: {
      const { index } = action
      const selectRefCheck = state[index]
      selectRefCheck.isChecked = !selectRefCheck.isChecked
      return [...state]
    }
    case Types.UPDATE_SELECTED_REFERENCE_CHECK_ALL: {
      const { isChecked } = action
      return state.map(ref => ({
        ...ref,
        ...(!ref.isDisabled && { isChecked }),
      }))
    }
    default:
      return state
  }
}

export const isFetching = (state = false, action) => {
  switch (action.type) {
    case Types.GET_REFERENCE_CHECK_REQUEST:
    case Types.POST_REFERENCE_CHECK_REQUEST:
      return true
    case Types.GET_REFERENCE_CHECK_FAILED:
    case Types.POST_REFERENCE_CHECK_FAILED:
    case Types.GET_REFERENCE_CHECK_SUCCESS:
    case Types.POST_REFERENCE_CHECK_SUCCESS:
      return false
    default:
      return state
  }
}

const errorReference = (state = null, action) => {
  switch (action.type) {
    case Types.GET_REFERENCE_CHECK_REQUEST:
    case Types.GET_REFERENCE_CHECK_SUCCESS:
      return null
    case Types.GET_REFERENCE_CHECK_FAILED:
      return { message: "ERROR!!!! FIX IT!!!" }
    default:
      return state
  }
}

export default combineReducers({
  list,
  isFetching,
  errorReference,
})
