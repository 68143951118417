import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import injectSaga from "utils/inject-saga"
import {
  callCompleteCreditReportPayment,
  callCancelCreditReportPayment,
} from "./actions"
import saga from "./saga"

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        callCompleteCreditReportPayment,
        callCancelCreditReportPayment,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  null,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "paymentProcessing", saga })
