import { createStructuredSelector } from "reselect"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import injectSaga from "../../utils/inject-saga"
import injectReducer from "../../utils/inject-reducer"
import reducer from "./reducer"
import saga from "./saga"
import { fetchGetStartedNotice, skipGetStartedModal } from "./actions"
import { selectGetSliders } from "./selectors"

export const mapStateToProps = () =>
  createStructuredSelector({
    sliders: selectGetSliders(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchGetStartedNotice,
        skipGetStartedModal,
      },
      dispatch,
    ),
  }
}

export const withReducer = injectReducer({ key: "getStarted", reducer })
export const withSaga = injectSaga({ key: "getStarted", saga })

export const withGetStarted = compose(
  withReducer,
  withSaga,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
