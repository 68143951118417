import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getUserPreference } from '../get-user-preference'

export const withUserPreferenceData = WrappedComponent => props => {
  const userPreferenceData = useQuery({
    queryKey: ['get_user_preference'],
    queryFn: getUserPreference,
  })

  return (
    <WrappedComponent
      {...props}
      userPreference={userPreferenceData.data}
      isFetchingUserPreference={!userPreferenceData.isFetched}
    />
  )
}
