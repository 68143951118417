import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { getPayoutMethodList } from '../redux/actions'
import { KEY } from '../redux/constants'
import { paymentAccountReducer } from '../redux/reducers'
import { paymentAccountRootSaga } from '../redux/sagas'
import {
  makeSelectFailedPayoutMethod,
  makeSelectPayoutMethodListData,
  makeSelectPayoutMethodListStatus,
  makeSelectPendingPayoutMethod,
  makeSelectVerifiedPayoutMethod,
} from '../redux/selectors'

export const usePayoutMethodList = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.PAYMENT_ACCOUNT, saga: paymentAccountRootSaga })
  useInjectReducer({ key: KEY.PAYMENT_ACCOUNT, reducer: paymentAccountReducer })

  const data = useSelector(makeSelectPayoutMethodListData())
  const status = useSelector(makeSelectPayoutMethodListStatus())

  const payoutMethod = {
    pending: useSelector(makeSelectPendingPayoutMethod()),
    verified: useSelector(makeSelectVerifiedPayoutMethod()),
    failed: useSelector(makeSelectFailedPayoutMethod()),
  }

  React.useEffect(() => {
    dispatch(getPayoutMethodList())
  }, [])

  return {
    fetchData: () => dispatch(getPayoutMethodList()),
    data,
    payoutMethod,
    status,
  }
}
