import styled from "styled-components"

export const CenterContentTemplateStyle = styled.div`
  width: 100%;
  padding: ${props => (props.isError ? `10px 15px` : `50px 15px`)};
  font-family: Source Sans Pro;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 991px) {
    ${props =>
      !props.noHeightCal &&
      `height: ${props.heightCal || "calc(100vh - 105px - 60px - 10px)"};`}
  }
  @media screen and (max-width: 991px) {
    padding: ${props => (props.isError ? `10px 20px;` : `30px 20px`)};
    display: block;
  }
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
    display: block;
  }
`

export const ContentStyle = styled.div`
  min-width: 500px;
  max-width: 500px;
  @media screen and (max-width: 991px) {
    display: contents;
    min-width: 320px;
    max-width: 991px;
  }
`
