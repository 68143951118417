import React from 'react'
import Button from '@rentspree/component-2023.components.atoms.button'
import MuiBox from '@mui/material/Box'
import { Loading } from 'components/layout/main'
import styled from 'styled-components'
import Sheet, {
  MultipleStepSheetHeader,
} from '@rentspree/component-2023.components.templates.sheet'

const Overlay = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${({ zIndex }) => zIndex || '1'};
`

export const LoadingOverlay = ({ zIndex }) => (
  <Overlay zIndex={zIndex}>
    <Loading />
  </Overlay>
)
export const ShareReportSheet = ({
  isOpen,
  onClickClose,
  onClickBack,
  onClickShare,
  onClickSave,
  isShareButtonDisabled = true,
  isSaveButtonDisabled = true,
  children,
  title,
  isLoading,
}) => {
  const cta = (
    <MuiBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {onClickBack && (
        <Button
          data-testid="share-report-option-back-btn"
          size="medium"
          onClick={onClickBack}
          className="underline"
          color="secondary"
          variant="text"
        >
          Back
        </Button>
      )}
      {onClickSave && (
        <Button
          data-testid="share-report-option-save-btn"
          size="medium"
          onClick={onClickSave}
          disabled={isSaveButtonDisabled}
          color="secondary"
          variant="contained"
        >
          Save
        </Button>
      )}
      {onClickShare && (
        <Button
          sx={{ width: '100%' }}
          data-testid="share-report-share-btn"
          size="medium"
          onClick={onClickShare}
          disabled={isShareButtonDisabled}
          color="secondary"
          variant="contained"
        >
          Share
        </Button>
      )}
    </MuiBox>
  )
  return (
    <Sheet
      actionSection={cta}
      open={isOpen}
      onClose={event => {
        if (event.currentTarget.tagName === 'BUTTON') onClickClose()
      }}
    >
      {isLoading && <LoadingOverlay />}
      <MultipleStepSheetHeader handleOnBack={onClickBack} header={title} />
      <MuiBox
        sx={{
          minHeight: '80vh',
          margin: '16px',
        }}
      >
        {children}
      </MuiBox>
    </Sheet>
  )
}
