import React, { useRef, useEffect, useState, useMemo } from 'react'

import { useDebounce } from 'hooks/use-debounce'

import { useGoogleAutoComplete } from 'containers/google-autocomplete/use-google-autocomplete'
import { scrollToTop } from './utils/scroll'
import { AutocompleteDropdown } from '../../autocomplete-dropdown'

export const WithGoogleAutoComplete = WrappedComponent => {
  const AutocompleteInput = props => {
    const {
      onSelectOption,
      onValueChange,
      onHandleAutoFilledUsedTracker,
      disabledAutocomplete,
      debouncingTime = 250,
      maxLength = 5,
      value = '',
      onFocus: parentOnFocus,
      ...rest
    } = props

    const { setAddresses, addresses, getAddressDetails } = useGoogleAutoComplete()
    const [inputValue, setInputValue] = useState(value)
    const [showDropdown, setShowDropdown] = useState(false)
    const [isOptionSelected, setIsOptionSelected] = useState(false)
    const inputRef = useRef(null)
    const dropdownRef = useRef(null)
    const dropdownTargetRef = useRef(null)
    const dropdownContentRef = useRef(null)
    const numberOfShownAddress = useMemo(
      () => Math.min(maxLength, addresses.length),
      [maxLength, addresses.length],
    )

    const debouncedSearch = useDebounce(input => {
      if (input && !disabledAutocomplete) setAddresses(input)
    }, debouncingTime)

    const handleInput = event => {
      const input = event.target?.value
      setInputValue(input)
      debouncedSearch(input)
      setIsOptionSelected(false)
      if (onValueChange) onValueChange(input)
    }

    const handleSelect = async address => {
      const addressDetails = await getAddressDetails(address.placeId)

      setShowDropdown(false)
      setIsOptionSelected(true)
      onSelectOption({ ...addressDetails, entries: 1 })
      setInputValue(addressDetails.streetLine)
      if (onValueChange) onValueChange(addressDetails.streetLine)
      if (onHandleAutoFilledUsedTracker) onHandleAutoFilledUsedTracker()
    }

    const handleOnFocus = event => {
      scrollToTop(inputRef)
      if (parentOnFocus) parentOnFocus(event)
    }

    const handleKeyDown = event => {
      if (dropdownRef.current) {
        dropdownRef.current.handleKeyDown(event.key)
      }
    }

    useEffect(() => {
      const handleClickOutside = event => {
        const { target } = event
        if (target instanceof Node) {
          const isClickOutside =
            !inputRef.current?.contains(target) && !dropdownTargetRef.current?.contains(target)
          if (isClickOutside) setShowDropdown(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    useEffect(() => {
      const isDropdownRequired = inputValue.length && addresses.length && !isOptionSelected
      setShowDropdown(!!isDropdownRequired)
    }, [inputValue, addresses, isOptionSelected])

    return (
      <>
        <div ref={inputRef} />
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <WrappedComponent
            {...rest}
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleInput}
            onFocus={handleOnFocus}
          />
        </form>
        {showDropdown && (
          <AutocompleteDropdown
            ref={dropdownRef}
            dropdownTargetRef={dropdownTargetRef}
            numberAddress={numberOfShownAddress}
            dropdownContentRef={dropdownContentRef}
            options={addresses}
            handleSelect={handleSelect}
            isShowIcon
          />
        )}
      </>
    )
  }

  // eslint-disable-next-line react/display-name
  return props => <AutocompleteInput {...props} />
}
