import moment from "moment"
import get from "lodash/get"

export const checkRead = (authorize = {}, read = true, feature = null) =>
  authorize[feature] && authorize[feature].read === read

export function isLastUpdatedExpired(authorize = {}, user) {
  // Invalidate cache(localStorage) when user id is changed
  // meaning that user's signed in with another account.
  if (user && user.id !== authorize.uid) {
    return true
  }

  const timestamp = get(authorize, "timestamp", null)
  return !timestamp || moment().diff(moment(timestamp).add(8, "m")) > 0
}
