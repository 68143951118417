import React from "react"
import get from "lodash/get"
import isObject from "lodash/isObject"
import { Landing } from "components/landing"
import reportSvg from "legacy/images/credit-report/report.svg"
import { OpenCreditReportModal } from "legacy/components/modal/open-credit-modal"
import { withConnect } from "./connect"

export class OpenRequired extends React.Component {
  constructor(props) {
    super(props)
    this.firstTimeOpenModal = false
    this.state = {
      showModal: false,
    }
  }

  closeModalOpenCreditReport() {
    this.setState({ showModal: false })
  }

  openModal() {
    this.setState({ showModal: true })
    // TODO: refactors function toggleOpenCreditModal
    this.props.actions.toggleOpenCreditModal(
      true,
      get(this.props, "rentalDetail", {}),
    )
  }

  render() {
    if (
      !this.firstTimeOpenModal &&
      isObject(this.props.creditReportRentalDetail)
    ) {
      this.firstTimeOpenModal = true
      this.openModal()
    }
    return (
      <div>
        <Landing
          title="Report(s) are ready"
          description="Please click the button below to pull the reports from TransUnion."
          buttonName="Open Reports"
          imgSrc={reportSvg}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={this.openModal.bind(this)}
        />
        {this.state.showModal && (
          <OpenCreditReportModal
            id="openCreditReportModal"
            className="rentspreeModal openCreditReport"
            showModal
            // eslint-disable-next-line react/jsx-no-bind
            closeModal={this.closeModalOpenCreditReport.bind(this)}
            closeButton
            backdrop="static"
          />
        )}
      </div>
    )
  }
}

export default withConnect(OpenRequired)
