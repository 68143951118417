import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { makeSelectIsReportsReady } from "containers/print-report-page/selectors"
import { getApplicationDetail } from "legacy/actions/application.action"
import { createStructuredSelector } from "reselect"
import injectSaga from "utils/inject-saga"
import { makeSelectCreditReportDetail } from "containers/landlord-pay/selectors"
import { selectMultiShare } from "containers/multi-share/selector"
import {
  selectRentalDetail,
  selectIsFetchingAppDetail,
  makeSelectIsShareAvailable,
  selectIsRenter,
} from "../selectors"
import { trackEvent } from "./actions"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  rentalDetail: selectRentalDetail,
  isFetchingAppDetail: selectIsFetchingAppDetail,
  creditReport: makeSelectCreditReportDetail,
  isReportsReady: makeSelectIsReportsReady(),
  isShareAvailable: makeSelectIsShareAvailable(),
  multiShare: selectMultiShare,
  isRenter: selectIsRenter,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getApplicationDetail,
      trackEvent,
    },
    dispatch,
  )

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withSaga = injectSaga({ key: "trackEvent", saga })
