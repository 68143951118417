import React from "react"
import { compose } from "redux"
import { CenterContent, Loading } from "components/layout/main"
import { redirectToLogin } from "legacy/helpers/location-href"
import { removeAccessToken } from "legacy/store/middleware/access-token"
import { withConnect } from "./connect"

export const auth = RenderComponent => ({
  isLogin,
  getProfile,
  user,
  ...rest
}) => {
  React.useEffect(() => {
    if (isLogin) {
      getProfile()
    } else {
      removeAccessToken()
      redirectToLogin(true)
    }
  }, [])

  if (!isLogin || !user.isFetched) {
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )
  }
  return <RenderComponent {...rest} />
}

export default compose(
  withConnect,
  auth,
)
