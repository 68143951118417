import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
export const getIsSSO = user => {
  const ssoObject = get(user, 'ssoIntegration', {})
  return !isEmpty(ssoObject)
}

export const getJoinedDate = user => {
  const joinedDate = get(user, 'createdAt', undefined)
  if (joinedDate) return new Date(joinedDate)
  return joinedDate
}

export const getIsAutomateTester = user => {
  const email = get(user, 'email')
  const emailPattern = [
    /^[a-zA-Z0-9._%+-]+(\+[a-zA-Z0-9._%+-]+)?automationtest@rentspree\.com$/,
    /^(automationtest\+[\s\S]*|automationtest\+\[[\s\S]*\])@rentspree\.com$/,
    /^(savitri\+[\s\S]*|savitri\+\[[\s\S]*\])@rentspree\.com$/,
  ]
  return emailPattern.some(v => v.test(email))
}
