import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'

import { searchRenterInfo } from '../redux/actions'
import { KEY } from '../redux/constants'
import { rentalPaymentReducer } from '../redux/reducers'
import { rentalPaymentRootSaga } from '../redux/sagas'
import { selectRentalPaymentState } from '../redux/selectors'

const statusSelector = createSelector(selectRentalPaymentState, state =>
  get(state, 'searchRenterInfo.status'),
)

const dataSelector = createSelector(selectRentalPaymentState, state =>
  get(state, 'searchRenterInfo.data'),
)

export const useSearchRenterInfo = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.RENTAL_PAYMENT, saga: rentalPaymentRootSaga })
  useInjectReducer({ key: KEY.RENTAL_PAYMENT, reducer: rentalPaymentReducer })

  const status = useSelector(statusSelector)
  const data = useSelector(dataSelector)

  return {
    searchRenterInfo: payload => dispatch(searchRenterInfo({ ...payload, onSuccess, onError })),
    status,
    data,
  }
}
