import styled from "styled-components"
import {
  BOX_SHADOW_1,
  BOX_SHADOW_2,
  COLOR,
  ANIMATE,
} from "../../styles/settings"

// language=SCSS prefix=dummy{ suffix=}
export const payerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
    :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const payerBox = styled.div`
  width: calc(50% - 10px);
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${COLOR.newGrey};
  padding: 0px 15px;
  background-color: white;
  box-shadow: ${BOX_SHADOW_1};
  cursor: pointer;
  &:hover {
    box-shadow: ${BOX_SHADOW_2};
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }
  ${props =>
    props.isSelected &&
    `
    background-color: rgba(75, 151, 245, 0.08);
    border: 2px solid #4b97f5;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `};
  ${props =>
    props.validationError &&
    `
    border: 2px solid ${COLOR.negativeRed};
    > span {
      color: ${COLOR.negativeRed};
    }
  `}
`
// language=SCSS prefix=dummy{ suffix=}
export const icon = styled.i`
  width: 24px;
  height: 24px;
  font-size: 20px;
  padding: 2px;
  color: ${COLOR.newGrey};
  margin-right: 15px;
  ${props => props.blue && `color: ${COLOR.deepBlue}`};
`
// language=SCSS prefix=dummy{ suffix=}
export const whatInclude = styled.div`
  border-radius: 5px;
  background-color: ${COLOR.newGrey};
  padding: 10px 20px;
`
// language=SCSS prefix=dummy{ suffix=}
export const reportItem = styled.div`
  border-bottom: 1px solid ${COLOR.newGrey};
  height: 50px;
  display: flex;
  align-items: center;
`
// language=SCSS prefix=dummy{ suffix=}
export const whatToCollect = styled.div`
  .have-screening {
    text-align: right;
    display: ${props => (props.isShowPrice ? "block" : "none")};
  }
  .no-screening {
    text-align: right;
    display: ${props => (props.isShowPrice ? "none" : "block")};
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const collectItem = styled.div`
  border-radius: 5px;
  background-color: ${COLOR.white};
  border: 2px solid ${COLOR.white};
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out ${ANIMATE.fast};
  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }
  :not(:last-child) {
    margin-bottom: 10px;
  }
  .item-child {
    .text {
      margin: 0;
    }
    .subtext {
      font-size: 0.9em;
      font-weight: normal;
      margin: 0;
    }
  }
  .item-child-right {
    justify-content: flex-end;
    margin-left: auto;
  }
  ${props =>
    props.isSelected &&
    `
    background-color: rgba(75, 151, 245, 0.08);
    border: 2px solid #4b97f5;
    box-shadow: none;
  `}
  ${props =>
    props.disabled &&
    `
    opacity: 0.15;
    pointer-events: none;
    cursor: auto;
    
  `}
  ${props =>
    props.validationError &&
    `
    border-color: ${COLOR.negativeRed};
    .item-child {
      .text {
        color: ${COLOR.negativeRed};
      }
    }
  `}
`
// language=SCSS prefix=dummy{ suffix=}
export const imgIcon = styled.img`
  width: auto;
  height: 24px;
  margin-right: 15px;
`
