import get from "lodash/get"
import find from "lodash/find"
import isEmpty from "lodash/isEmpty"
import filter from "lodash/filter"
import { createSelector } from "reselect"
import cloneDeep from "lodash/cloneDeep"
import { initialState } from "./reducer"
import { mapSideBarIdToNavBarId } from "../navbar/const"
import { PAGE } from "../popper/const"
import { pickStyledBadge } from "./constants"
export const selectBadge = ({ badge }) => badge || initialState
export const selectActiveId = (state, ownProps) => get(ownProps, "activeId")

export const makeSelectActiveBadge = () =>
  createSelector(
    [selectBadge, selectActiveId],
    ({ data }, activeId) => !isEmpty(find(data, { parentId: activeId }, {})),
  )

export const makeSelectBadgeStyled = () =>
  createSelector(
    [selectBadge, selectActiveId],
    ({ data }, activeId) => {
      const badgeType = get(
        find(data, { parentId: activeId }, {}),
        "badgeType",
        "",
      )
      return pickStyledBadge(badgeType)
    },
  )

/* eslint-disable no-param-reassign */
export const makeSelectDataBadgeMobile = () =>
  createSelector(
    selectBadge,
    ({ data }) => {
      const cloneData = cloneDeep(data)
      const modifyData = filter(cloneData, { page: PAGE.SIDEBAR })
      return modifyData.map(item => {
        item.parentId = mapSideBarIdToNavBarId[item.parentId] || item.parentId
        return item
      })
    },
  )

export const makeSelectBadgeParentIds = () =>
  createSelector(
    selectBadge,
    ({ data }) => Array.isArray(data) ? data.map(badge => badge.parentId) : [],
  )
