import { COLOR } from 'styles/settings'

export const TAG_COLOR = {
  shallow_blue: {
    backgroundColor: COLOR.tagSwallowBlueLv2,
    fontColor: COLOR.textBlack,
    backgroundColorHover: COLOR.tagSwallowBlueLv1,
    fontColorHover: COLOR.textBlack,
  },
  mint_green_dark: {
    backgroundColor: COLOR.tagMintLv3,
    fontColor: COLOR.textBlack,
    backgroundColorHover: COLOR.tagMintLv2,
    fontColorHover: COLOR.textBlack,
  },
  orange: {
    backgroundColor: COLOR.tagOrangeLv3,
    fontColor: COLOR.textBlack,
    backgroundColorHover: COLOR.tagOrangeLv2,
    fontColorHover: COLOR.textBlack,
  },
  red: {
    backgroundColor: COLOR.tagRedLv3,
    fontColor: COLOR.white,
    backgroundColorHover: COLOR.tagRedLv2,
    fontColorHover: COLOR.white,
  },
  new_yellow: {
    backgroundColor: COLOR.tagYellowLv3,
    fontColor: COLOR.textBlack,
    backgroundColorHover: COLOR.tagYellowLv2,
    fontColorHover: COLOR.textBlack,
  },
  purple: {
    backgroundColor: COLOR.tagPurpleLv2,
    fontColor: COLOR.white,
    backgroundColorHover: COLOR.tagPurpleLv1,
    fontColorHover: COLOR.white,
  },
}
