import React from "react"
import { BadgeOverlap } from "@rentspree/component-v2"

import { COLOR } from "styles/settings"
import { BetaBadge, NewBadge } from "components/badge"
import { withBadgeActive } from "./badge-connect"
import { BADGE_COMPONENT_TYPES } from "./constants"

const redDotProps = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  content: " ",
  transform: "translate(50%,-35%)",
  background: COLOR.fontRed,
  showBadge: true,
  styles: "@media (min-width: 991px) { display:none; }",
}

export const Badge = props => {
  const {
    children,
    isActive,
    isRedDot,
    badgeStyled,
    badgeComponentType = BADGE_COMPONENT_TYPES.OVERLAP_BADGE,
  } = props
  const redDotPropsAssign = (isRedDot && redDotProps) || {}
  switch (badgeComponentType) {
    case BADGE_COMPONENT_TYPES.BETA_BADGE:
      return (
        isActive && (
          <BetaBadge
            {...props}
            showBadge={isActive}
            backgroundColor={badgeStyled.background}>
            {badgeStyled.content}
          </BetaBadge>
        )
      )
    case BADGE_COMPONENT_TYPES.NEW_BADGE:
      return (
        isActive && (
          <NewBadge {...props} showBadge={isActive}>
            {badgeStyled.content}
          </NewBadge>
        )
      )
    default:
      return (
        <BadgeOverlap
          showBadge={isActive}
          {...redDotPropsAssign}
          {...props}
          {...badgeStyled}>
          {children}
        </BadgeOverlap>
      )
  }
}

export default withBadgeActive(Badge)
