import React from 'react'
import { Link } from 'react-router-dom'
import { CenterContent } from 'components/layout/main'
import { StyledHsForm, FormDesc, FormIcon } from '../reports/share/coming-soon-style'
import { Button } from '../buttons/buttons'
import { H3 } from '../typography'
import { REQUIRE_DOCUMENT_CONTENT } from '../../constants/feature-pro-consts'

export const RequireSubscriptions = ({
  content = REQUIRE_DOCUMENT_CONTENT,
  withAcceptDeny,
  onClickLink = () => {},
}) => (
  <CenterContent
    style={{ backgroundColor: '#fff' }}
    withBreadcrumb
    withReportTabs
    noMargin
    minHeight={content === REQUIRE_DOCUMENT_CONTENT ? '500px' : '575px'}
    withAcceptDeny={withAcceptDeny}
  >
    <StyledHsForm className="text-center" id="hsRequestDocForm" withAcceptDeny={withAcceptDeny}>
      <FormIcon src={content.icon} alt="request doc" />
      <H3 strong>{content.title}</H3>
      <FormDesc>{content.desc}</FormDesc>
      {content.link && (
        <Link to={content.link} onClick={onClickLink}>
          <Button
            className="button-lg"
            id="reload-btn"
            primary
            text="Get RentSpree PRO"
            width="100%"
            maxWidth="200px"
          />
        </Link>
      )}
    </StyledHsForm>
  </CenterContent>
)
