/* eslint-disable import/no-named-as-default */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import { buildPath, query } from '@rentspree/path'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { matchPath, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { WrapperWithOutDiamond } from 'components/templates/diamond-wrapper-template-styled'
import * as ROUTE from 'constants/route'
import Breadcrumb2023 from 'containers/breadcrumb-2023'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'
import { useResizeVh } from 'hooks/use-resize-vh'
import { breakpoints } from 'styles/settings'

import { useListPropertiesQuery } from '../apis/property'
import { useGetOrCreateDraftRentalPayment } from '../shared/hooks'

import { CreatePropertyModal } from './modals/create-property-modal'
import { CreateTenantModal } from './modals/create-tenant-modal'
import { PageAgentInitiateDraftPayments } from './set-up-for-landlord'
import AgentInitiatePaymentConfirmationPage from './set-up-for-landlord/confirmation'
import {
  clearRentalPaymentSetup,
  selectProperty,
  selectRenterInfo,
} from './set-up-for-myself/redux/actions'
import { useRentalPaymentSetup } from './set-up-for-myself/redux/use-rental-payment-setup'
import { withStepper } from './set-up-for-myself/stepper/with-stepper'
import { LazyConfirmationStep } from './set-up-for-myself/steps/confirmation'
import { LazyPaymentDetailsStep } from './set-up-for-myself/steps/payment-details'
import { LazyPropertyAndTenantStep } from './set-up-for-myself/steps/property-and-tenant'
import { routeNotFound } from './utils'

export const SelfPaymentSetupRoutes = ({ history, location }) => {
  const dispatch = useDispatch()

  useResizeVh()

  const { selectedProperty, selectedRenterInfo } = useRentalPaymentSetup()

  const { getOrCreateDraftRentalPayment, data: draftRentalPayment } =
    useGetOrCreateDraftRentalPayment()

  const listPropertiesQuery = useListPropertiesQuery()

  const queryParams = useMemo(() => query.parse(location.search), [location.search])
  const { property: propertyId, renterInfo: renterInfoId } = queryParams

  useEffect(() => {
    if (propertyId && renterInfoId) {
      getOrCreateDraftRentalPayment({ propertyId, renterInfoId })
    } else {
      history.replace(buildPath(ROUTE.RENT_PAYMENT_REQUEST_PAGE, {}, queryParams))
    }

    return () => {
      dispatch(clearRentalPaymentSetup())
    }
  }, [])

  // init selected property
  useEffect(() => {
    if (!selectedProperty && propertyId && renterInfoId && listPropertiesQuery.data?.data.length) {
      const property = listPropertiesQuery.data?.data.find(({ _id }) => _id === propertyId)
      dispatch(selectProperty(property))
    }
  }, [listPropertiesQuery.data])

  // init selected renter info
  useEffect(() => {
    if (!selectedRenterInfo && propertyId && renterInfoId && draftRentalPayment) {
      dispatch(selectRenterInfo(draftRentalPayment.renterInfo))
    }
  }, [draftRentalPayment])

  const modals = (
    <>
      <CreatePropertyModal history={history} location={location} />
      <CreateTenantModal history={history} location={location} />
    </>
  )

  return (
    <WrapperWithOutDiamond withStepper>
      <Switch>
        <Route exact path={ROUTE.RENT_PAYMENT_REQUEST_PAGE} component={LazyPropertyAndTenantStep} />
        <Route
          exact
          path={ROUTE.RENT_PAYMENT_REQUEST_PAYMENT_DETAILS_PAGE}
          component={LazyPaymentDetailsStep}
        />
        <Route
          exact
          path={[
            ROUTE.RENT_PAYMENT_REQUEST_CONFIRMATION_PAGE,
            ROUTE.RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER,
            ROUTE.RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER_BUSINESS_TYPE,
            ROUTE.RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER_PERSON_DETAIL,
          ]}
          component={LazyConfirmationStep}
        />
      </Switch>
      {modals}
    </WrapperWithOutDiamond>
  )
}

export const SelfPaymentSetupPage = withStepper(SelfPaymentSetupRoutes)

export const RentPaymentSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledBreadcrumb = styled(Breadcrumb2023)`
  ${breakpoints.mobile(` 
    display: none;
  `)}
`

export const SelfPaymentSetupPageWithBreadcrumb = props => {
  const { history, location } = props

  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  const handleClickBackButton = () => {
    if (matchPath(location?.pathname, { path: ROUTE.RENT_PAYMENT_REQUEST_PAGE }))
      history.push(ROUTE.RENT_PAYMENT)
    if (matchPath(location?.pathname, { path: ROUTE.RENT_PAYMENT_REQUEST_PAYMENT_DETAILS_PAGE }))
      history.push(ROUTE.RENT_PAYMENT_REQUEST_PAGE)
    if (matchPath(location?.pathname, { path: ROUTE.RENT_PAYMENT_REQUEST_CONFIRMATION_PAGE }))
      history.push(ROUTE.RENT_PAYMENT_REQUEST_PAYMENT_DETAILS_PAGE)
  }

  return (
    <RentPaymentSetupContainer>
      {isMobile ? (
        <IconButton
          data-testid="task-item-icon-button"
          color="black"
          style={{ margin: '16px 16px 0' }}
          onClick={handleClickBackButton}
        >
          <ChevronLeftIcon data-testid="chevron-left-icon" />
        </IconButton>
      ) : (
        <StyledBreadcrumb />
      )}
      <div>
        <SelfPaymentSetupPage {...props} />
      </div>
    </RentPaymentSetupContainer>
  )
}

export const RentPaymentSetupRoutes = props => {
  return (
    <Switch>
      {/* Agent setup For Landlord */}
      <Route
        exact
        path={ROUTE.RENT_PAYMENT_REQUEST_AGENT_INITIATED_PAGE}
        render={() => <PageAgentInitiateDraftPayments {...props} />}
      />
      <Route
        exact
        path={ROUTE.RENT_PAYMENT_REQUEST_AGENT_INITIATED_CONFIRMATION_PAGE}
        render={() => <AgentInitiatePaymentConfirmationPage {...props} />}
      />
      {/* Self Setup */}
      <SelfPaymentSetupPageWithBreadcrumb {...props} />

      <Route path="*" render={routeNotFound} />
    </Switch>
  )
}
