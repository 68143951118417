import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectSaga from 'utils/inject-saga'
import {
  selectScreeningFeeData,
  selectIsFetchingLraRequest,
  selectIsUpdatingLraRequest,
} from 'containers/request/step-update-screening-fee/selectors'
import {
  getLraRequestCall,
  updateLraRequestCall,
} from 'containers/request/step-update-screening-fee//actions'
import saga from './saga'
import { selectRequestOptions } from '../selectors'

export const mapStateToProps = createStructuredSelector({
  screeningFeeData: selectScreeningFeeData(),
  isFetchingLraRequest: selectIsFetchingLraRequest(),
  isUpdatingLraRequest: selectIsUpdatingLraRequest(),
  requestOptions: selectRequestOptions,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getLraRequestCall,
        updateLraRequestCall,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)
export const withSaga = injectSaga({ key: 'screeningFeeV2.2', saga })
