import generateApiCall from "utils/generate-api-call"
import {
  SUBMIT_REFUND_CALL,
  SUBMIT_REFUND_REQUEST,
  SUBMIT_REFUND_SUCCESS,
  SUBMIT_REFUND_FAILURE,
} from "./constants"

export const submitRefund = ({ rentalId }) => ({
  type: SUBMIT_REFUND_CALL,
  payload: {
    rentalId,
  },
})

export const submitRefundApiState = generateApiCall([
  SUBMIT_REFUND_REQUEST,
  SUBMIT_REFUND_SUCCESS,
  SUBMIT_REFUND_FAILURE,
])
