import { buildPath } from '@rentspree/path'
import FileSaver from 'file-saver'
import toNumber from 'lodash/toNumber'
import moment from 'moment'
import { call, takeLatest, all, put, select, fork, race, delay } from 'redux-saga/effects'

import { APPLICATION_TYPE } from 'constants/application-type'
import {
  AGENT_GET_SUBMISSION_PARTICIPANT_BY_EMAIL_API,
  DASHBOARD,
  DIGIDOC_GET_FILE,
  GENERATE_REPORT_API,
  GENERATE_REPORTS_PAGE,
  GET_SUBMISSION_PARTICIPANT,
  PRINT_RENTAL_SUBMISSION,
  PRINT_RENTAL_SUBMISSION_WITHOUT_PROPERTY,
  RENTAL_SUBMISSION_API_V2,
  REPORT_TYPE,
} from 'constants/route'
import { POLLING_DELAY, POLLING_MAX_COUNT, POLLING_SHIFT_COUNT, SAVE_PDF_TIMEOUT } from 'env'
import mapWordingTrack from 'legacy/helpers/map-wording-track'
import { isIE } from 'legacy/components/helper/ua-parser-js'
import { EVENT_REPORT } from 'legacy/tracker/const'
import { GENERATE_REPORT_FILES, POLLING_REPORT_FILES_STATUS } from 'legacy/constants/route-consts'
import fetch from 'legacy/helpers/fetch-utils'
import tracker from 'tracker'
import { apiInstanceWithErrorHandler, apiInstance, fileApiInstance } from 'utils/api-interceptor'

import { selectProfile } from 'containers/user/selectors'
import {
  savePDFApi,
  alertSaveTimeout,
  getRentalSubmission,
  getSubmissionParticipant,
  getSubmissionParticipantByEmail,
} from './actions'

import {
  selectRentalDetail,
  selectReportData,
  selectApplicationDetail,
  selectIsOwnReport,
} from './selectors'
import {
  TYPES,
  mapReportType,
  mapTrackReportProps,
  mapDownloadReportParams,
  REPORT_TYPES,
} from './constants'

export const getGenerateMobileLocate = (reportType, applicationType) => {
  if (reportType === REPORT_TYPES.ALL) {
    return generateAllReportMobileLocate
  }
  if (reportType === REPORT_TYPES.APPLICATION && applicationType === APPLICATION_TYPE.CAR_LRA) {
    return generateCarLraMobileLocate
  }
  return generateMobileLocate
}
export const generateCarLraMobileLocate = ({ envelopeId }) =>
  buildPath(DIGIDOC_GET_FILE, { envelopeId })

export const generateMobileLocate = ({ reportType, queryParams }) =>
  buildPath(`${DASHBOARD}${REPORT_TYPE}`, { reportType }, queryParams)

export const generateAllReportMobileLocate = ({ reportType, queryParams }) =>
  buildPath(`${DASHBOARD}${GENERATE_REPORTS_PAGE}`, { reportType }, queryParams)

export const pdfReportApi = async ({ reportType, queryParams }) => {
  const uri = buildPath(GENERATE_REPORT_API, { reportType }, queryParams)
  const response = await fetch(uri, {
    method: 'GET',
    credentials: 'include',
  })
  const header = response.headers.get('Content-Disposition') || ''
  const [, fileName] = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) || []
  const fileData = await response.blob()
  return {
    fileData,
    fileName,
  }
}

export const pdfCarLraReportApi = async ({ applicationDetail }) => {
  const { lraEnvelopeId: envelopeId, firstName, lastName } = applicationDetail
  const date = moment().format('MM.DD.YY')
  const fileName = `${firstName}_${lastName}_Application_${date}.pdf`
  const fileData = await apiInstanceWithErrorHandler.get(
    buildPath(DIGIDOC_GET_FILE, { envelopeId }),
    { responseType: 'blob' },
  )
  return { fileData, fileName }
}

export const generateReportsApi = async payload => apiInstance.post(GENERATE_REPORT_FILES, payload)

export const pollingReportsStatusApi = async transactionId =>
  apiInstance.get(buildPath(POLLING_REPORT_FILES_STATUS, { transactionId }))

export const downloadGeneratedReportsApi = async urlPath =>
  fileApiInstance.get(urlPath, { responseType: 'blob' })

export const getPdfReportApi = (reportType, applicationType) =>
  reportType === REPORT_TYPES.APPLICATION && applicationType === APPLICATION_TYPE.CAR_LRA
    ? pdfCarLraReportApi
    : pdfReportApi

export const getPrintUrlPage = (reportType, applicationType) =>
  reportType === REPORT_TYPES.APPLICATION && applicationType === APPLICATION_TYPE.CAR_LRA
    ? generateCarLraPrintURLPage
    : generatePrintURLPage

export const generateCarLraPrintURLPage = ({ envelopeId, autoPrint }) =>
  buildPath(DIGIDOC_GET_FILE, { envelopeId }, { autoPrint })
export const getSubmissionParticipantAPI = (propertyId, rentalId) =>
  apiInstance.get(buildPath(GET_SUBMISSION_PARTICIPANT, { propertyId, rentalId }))

export const getRentalSubmissionByIdAPI = rentalId =>
  apiInstance.get(buildPath(RENTAL_SUBMISSION_API_V2, { rentalId }))

export const generatePrintURLPage = ({ propertyId, rentalId, type, autoPrint }) =>
  buildPath(
    propertyId ? PRINT_RENTAL_SUBMISSION : PRINT_RENTAL_SUBMISSION_WITHOUT_PROPERTY,
    { propertyId, rentalAppId: rentalId, type },
    { autoPrint },
  )

export const getSubmissionParticipantByEmailAPI = ({ rentalId, email }) =>
  apiInstance.get(buildPath(AGENT_GET_SUBMISSION_PARTICIPANT_BY_EMAIL_API, { rentalId }, { email }))

export function* trackReportSaga(event, properties = {}) {
  const { _id: rentalId, email: applicant } = yield select(selectRentalDetail)
  yield call([tracker, 'trackEvent'], event, {
    rental_id: rentalId,
    applicant,
    ...properties,
  })
}

export function* printReport({ payload }) {
  const { type, autoPrint, isPrintPage, clickFrom, propertyId, isMultiShare, pageUrl, hasBeenShared, hasScreeningReport } = payload
  const { type: applicationType, lraEnvelopeId: envelopeId } = yield select(selectApplicationDetail)
  const reportName = yield call(mapWordingTrack, type)
  const trackReportProps = yield call(mapTrackReportProps, {
    reportName,
    clickFrom,
    isPrintPage,
    isMultiShare,
    pageUrl,
    hasBeenShared,
    hasScreeningReport,
  })

  if (autoPrint) yield fork(trackReportSaga, EVENT_REPORT.printReport, trackReportProps)
  if (!isPrintPage) {
    const { _id: rentalId } = yield select(selectRentalDetail)
    const printPageURL = yield call(getPrintUrlPage(type, applicationType), {
      propertyId,
      rentalId,
      type,
      autoPrint,
      envelopeId,
    })
    window.open(printPageURL, '_blank')
  } else {
    window.print()
  }
}

export function* mobileLocate({
  reportType,
  applicationType,
  queryParams,
  isRedirect,
  envelopeId,
  type,
}) {
  const url = yield call(getGenerateMobileLocate(type, applicationType), {
    reportType,
    queryParams,
    envelopeId,
  })
  if (isRedirect) window.location.href = url
  else yield call([window, 'open'], url)
}

export function* downloadFile({ apiContext, apiAction }) {
  yield put(apiAction.request())
  try {
    const [file = {}, timeout] = yield race([
      call(...apiContext),
      delay(toNumber(SAVE_PDF_TIMEOUT) || 120000, true),
    ])

    if (timeout) {
      yield put(alertSaveTimeout())
      throw new Error()
    }

    const { fileData, fileName } = file
    yield put(
      apiAction.success({
        fileData,
        fileName,
      }),
    )
  } catch (err) {
    yield put(apiAction.failure())
  }
}

export function* pollingReportsStatus(transactionId) {
  let response = {}
  for (let i = 0; i < POLLING_MAX_COUNT; i += 1) {
    yield delay(POLLING_DELAY * (Math.floor(i / POLLING_SHIFT_COUNT) + 1))
    try {
      response = yield call(pollingReportsStatusApi, transactionId)
    } catch (err) {
      // do nothing
    }

    switch (response.status) {
      case 'finished':
        return response
      case 'error':
        throw new Error(response.errorMessage)
      default:
        break
    }
  }
  throw new Error('timeout')
}

export function* generateAndDownloadReports(payload, queryParams) {
  yield put(savePDFApi.request())
  try {
    const { tid } = yield call(generateReportsApi, payload)
    const { fileName, url } = yield* pollingReportsStatus(tid)
    const fileData = yield call(downloadGeneratedReportsApi, url)
    yield put(
      savePDFApi.success({
        fileData,
        fileName,
      }),
    )
    yield fork([FileSaver, 'saveAs'], fileData, fileName)
    const reportName = yield call(mapWordingTrack, payload.reportType)
    yield fork(trackReportSaga, EVENT_REPORT.saveReportPDF, {
      report_name: reportName,
      click_from: queryParams.click_from,
      page: queryParams.page,
    })
  } catch (err) {
    if (typeof err.message === 'string' && err.message.includes('timeout')) {
      yield put(alertSaveTimeout())
    }
    yield put(savePDFApi.failure())
  }
}

export function* savePDFSaga({ payload: { isPrintPage, type, clickFrom, device, propertyId, isMultiShare, pageUrl, hasBeenShared, hasScreeningReport }}) {
  const { _id: rentalId, email: applicantEmail } = yield select(selectRentalDetail)
  const isOwnerReport = yield select(selectIsOwnReport)
  const applicationDetail = yield select(selectApplicationDetail)
  const { type: applicationType, lraEnvelopeId: envelopeId } = applicationDetail

  const queryParams = yield call(mapDownloadReportParams, {
    rentalId,
    applicantEmail,
    device,
    clickFrom,
    isPrintPage,
    propertyId,
    ...(isOwnerReport &&
      applicationType === APPLICATION_TYPE.CAR_LRA &&
      type === REPORT_TYPES.ALL && {
        appType: applicationType,
        envelopeId,
      }),
  })
  const reportType = yield call(mapReportType, type)
  const isMobile = device === 'mobile'

  if (reportType === 'all-report' && !(isIE || isMobile)) {
    yield* generateAndDownloadReports(
      {
        rentalSubmissionId: rentalId,
        reportType,
        propertyId,
        appType: applicationType,
        envelopeId,
      },
      queryParams,
    )
  } else if (isIE || isMobile) {
    yield* mobileLocate({
      applicationType,
      reportType,
      queryParams,
      isRedirect: false,
      envelopeId,
      type,
    })
  } else {
    yield* downloadFile({
      apiContext: [
        getPdfReportApi(type, applicationType),
        { reportType, queryParams, applicationDetail },
      ],
      apiAction: savePDFApi,
    })
    const { fileData, fileName } = yield select(selectReportData)
    if (fileData) {
      yield fork([FileSaver, 'saveAs'], fileData, fileName)
      const { page } = queryParams
      const reportName = yield call(mapWordingTrack, reportType)
      yield fork(trackReportSaga, EVENT_REPORT.saveReportPDF, {
        report_name: reportName,
        click_from: clickFrom,
        page,
        is_multi_share: isMultiShare ? "yes" : "no",
        page_url: pageUrl,
        has_been_shared: hasBeenShared,
        has_screening_report: hasScreeningReport,
      })
    }
  }
}

// TODO: [Tenant Screening] - Remove unused get submission participant APIs
export function* getSubmissionParticipantSaga({ propertyId, rentalId }) {
  yield put(getSubmissionParticipant.request())
  try {
    const response = yield call(getSubmissionParticipantAPI, propertyId, rentalId)
    yield put(getSubmissionParticipant.success(response))
  } catch (err) {
    yield put(getSubmissionParticipant.failure(err))
  }
}

export function* getRentalSaga({ payload }) {
  yield put(getRentalSubmission.request())
  const { userType } = yield select(selectProfile)
  const { rentalId, propertyId } = payload
  if (propertyId && userType === 'landlord') {
    yield fork(getSubmissionParticipantSaga, { propertyId, rentalId })
  }
  try {
    const response = yield call(getRentalSubmissionByIdAPI, rentalId)
    // using this because `generateApiCall` always send payload name as payload but the legacy reducer is need data
    yield put({ type: TYPES.GET_RENTAL_BY_ID_SUCCESS, data: response })
  } catch (err) {
    yield put(getRentalSubmission.failure(err))
  }
}

export function* getSubmissionParticipantByEmailSaga({ payload }) {
  const { rentalId, email } = payload
  yield put(getSubmissionParticipantByEmail.request())
  try {
    const response = yield call(getSubmissionParticipantByEmailAPI, { rentalId, email })
    yield put(getSubmissionParticipantByEmail.success(response))
  } catch (err) {
    yield put(getSubmissionParticipantByEmail.failure(err))
  }
}

export function* watchGetSubmissionParticipantByEmail() {
  yield takeLatest(
    TYPES.GET_SUBMISSION_PARTICIPANT_BY_EMAIL_CALL,
    getSubmissionParticipantByEmailSaga,
  )
}

export function* watchPrintReport() {
  yield takeLatest(TYPES.PRINT_REPORT, printReport)
}

export function* watchSavePDF() {
  yield takeLatest(TYPES.SAVE_PDF_CALL, savePDFSaga)
}

export function* watchGetRentalSubmission() {
  yield takeLatest(TYPES.GET_RENTAL_SUBMISSION_CALL, getRentalSaga)
}

export default function* rootSaga() {
  yield all([
    watchPrintReport(),
    watchSavePDF(),
    watchGetRentalSubmission(),
    watchGetSubmissionParticipantByEmail(),
  ])
}
