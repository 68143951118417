import get from "lodash/get"
import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const getSubmissionParticipant = state =>
  get(state, "submissionParticipant", initialState)

export const selectParticipantData = createSelector(
  getSubmissionParticipant,
  submissionParticipant =>
    get(submissionParticipant, "data", initialState.data),
)

export const selectIsFetching = createSelector(
  getSubmissionParticipant,
  submissionParticipant =>
    get(submissionParticipant, "isFetching", initialState.isFetching),
)

export const selectPropertyId = createSelector(
  getSubmissionParticipant,
  submissionParticipant => get(submissionParticipant, "data.property", ""),
)

export const selectError = createSelector(
  getSubmissionParticipant,
  submissionParticipant => get(submissionParticipant, "error", ""),
)
