import { object } from "yup"
import get from "lodash/get"
import toString from "lodash/toString"

import { screeningFeeValidation } from "constants/validations"
import { validateLength } from "legacy/constants/validations"

export const screeningFeeSchema = ({ screeningFeeData }) => ({
  mapper: {
    creditReportFee: toString(get(screeningFeeData, "creditReportFee", "")),
    outOfPocketExpense: {
      amount: toString(get(screeningFeeData, "outOfPocketExpense.amount", "")),
      for: get(screeningFeeData, "outOfPocketExpense.for", ""),
    },
    processingFee: toString(get(screeningFeeData, "processingFee", "")),
  },
  validation: object({
    creditReportFee: screeningFeeValidation("creditReportFee"),
    outOfPocketExpense: object({
      amount: screeningFeeValidation("outOfPocketExpense"),
      for: validateLength(false, 0, 40),
    }),
    processingFee: screeningFeeValidation("processingFee"),
  }),
})

export default screeningFeeSchema
