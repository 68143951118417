import React from "react"
import { PanelGroup, Panel } from "react-bootstrap"
import { PRO_PAGE, SUBSCRIPTION_PAYMENT } from "constants/route"
import { RouterLink } from "../buttons/buttons"
import { P, Ul } from "../typography"
import { PanelHeading } from "./panel-box"
import { REF_CHECKS_FAQ } from "./wording"

export const NonsubRefChecksFaq = ({ clickPanel, headerWithIcon }) => (
  <PanelGroup accordion>
    <Panel
      eventKey={1}
      onClick={() => clickPanel(REF_CHECKS_FAQ.nonsubEventName[0])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.firstPanel.header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          {REF_CHECKS_FAQ.firstPanel.paragraph.beforeLink}
          <RouterLink
            id={REF_CHECKS_FAQ.nonsubLinkId}
            to={SUBSCRIPTION_PAYMENT}
            inlineLink
            text="activate RentSpree PRO"
          />
          {REF_CHECKS_FAQ.firstPanel.paragraph.afterLink}
        </P>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={2}
      onClick={() => clickPanel(REF_CHECKS_FAQ.nonsubEventName[1])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.otherPanel[0].header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>{REF_CHECKS_FAQ.otherPanel[0].paragraph}</P>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={3}
      onClick={() => clickPanel(REF_CHECKS_FAQ.nonsubEventName[2])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.otherPanel[1].header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>{REF_CHECKS_FAQ.otherPanel[1].paragraph}</P>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={4}
      onClick={() => clickPanel(REF_CHECKS_FAQ.nonsubEventName[3])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.otherPanel[2].header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>{REF_CHECKS_FAQ.otherPanel[2].paragraph}</P>
      </Panel.Body>
    </Panel>
  </PanelGroup>
)

export const SubRefChecksFaq = ({ clickPanel, headerWithIcon }) => (
  <PanelGroup accordion>
    <Panel
      eventKey={1}
      onClick={() => clickPanel(REF_CHECKS_FAQ.subEventName[0])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.firstPanel.header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          {REF_CHECKS_FAQ.firstPanel.paragraph.beforeLink}
          <RouterLink
            id={REF_CHECKS_FAQ.subLinkId}
            to={PRO_PAGE}
            inlineLink
            text="activate RentSpree PRO"
          />
          {REF_CHECKS_FAQ.firstPanel.paragraph.afterLink}
        </P>
        <div>
          <Ul bullet>
            <li>
              <P>{REF_CHECKS_FAQ.firstPanel.bullet.paragraph[0]}</P>
            </li>
            <li>
              <P>{REF_CHECKS_FAQ.firstPanel.bullet.paragraph[1]}</P>
            </li>
          </Ul>
        </div>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={2}
      onClick={() => clickPanel(REF_CHECKS_FAQ.subEventName[1])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.otherPanel[0].header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>{REF_CHECKS_FAQ.otherPanel[0].paragraph}</P>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={3}
      onClick={() => clickPanel(REF_CHECKS_FAQ.subEventName[2])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.otherPanel[1].header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>{REF_CHECKS_FAQ.otherPanel[1].paragraph}</P>
      </Panel.Body>
    </Panel>
    <Panel
      eventKey={4}
      onClick={() => clickPanel(REF_CHECKS_FAQ.subEventName[3])}>
      <PanelHeading>
        {headerWithIcon(REF_CHECKS_FAQ.otherPanel[2].header.title)}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>{REF_CHECKS_FAQ.otherPanel[2].paragraph}</P>
      </Panel.Body>
    </Panel>
  </PanelGroup>
)
