/* eslint-disable no-param-reassign */
import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import { withFormik } from 'formik'
import { get } from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Drawer } from 'v3/components/molecules/drawer'
import { Modal } from 'v3/components/molecules/modal'
import { ButtonWithOutMargin } from 'v3/components/stepper-guide-modal/stepper-guide-modal'
import { API_STATUS } from 'v3/containers/identity-verification/reducers'

import { createTenantSchema } from './schema'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CreateTenantDrawer = props => {
  const {
    isOpen,
    handleChange,
    touched,
    dirty,
    errors,
    apiErrors,
    createTenantApiStatus,
    handleSubmit,
    handleBlur,
    handleReset,
    setFieldValue,
    onClose,
  } = props

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseDrawer = () => {
    handleReset()
    onClose()
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleConfirmLeaving = () => {
    handleCloseModal()
    handleCloseDrawer()
  }

  const handlePreClose = () => {
    if (dirty) {
      setIsOpenModal(true)
    } else {
      handleCloseDrawer()
    }
  }

  // simplifying support for email validation errors from API, needs to be expanded for other server-side validations
  const getError = key => {
    const keyIsTouched = get(touched, key, false)
    return (
      (keyIsTouched && get(errors, key)) ||
      (key === 'email' && !keyIsTouched && get(apiErrors, key))
    )
  }

  return (
    <>
      <Modal
        title="Leave without saving?"
        isOpen={isOpenModal}
        width="650px"
        sx={{ zIndex: '50000!important' }}
        footer={
          <>
            <ButtonWithOutMargin
              variant="text"
              color="secondary"
              className="underline"
              onClick={handleCloseModal}
            >
              Keep editing
            </ButtonWithOutMargin>

            <ButtonWithOutMargin
              variant="contained"
              color="secondary"
              onClick={handleConfirmLeaving}
            >
              Leave
            </ButtonWithOutMargin>
          </>
        }
        onClose={handleCloseModal}
      >
        Your tenant details won’t be saved.
      </Modal>
      <Drawer
        isOpen={isOpen}
        title="Tenant"
        subHeader="Add new tenant"
        continueButtonText="Add tenant"
        closeButtonText="Cancel"
        displayCloseButton={createTenantApiStatus === API_STATUS.UPDATING}
        disableContinueButton={createTenantApiStatus === API_STATUS.UPDATING}
        onContinue={handleSubmit}
        onClose={handlePreClose}
      >
        <InputContainer>
          <TextInput
            label="First name"
            name="firstName"
            onChange={handleChange}
            onBlur={event => {
              event.target.value = event.target.value.trim()
              setFieldValue('firstName', event.target.value)
              handleBlur(event)
            }}
            error={getError('firstName')}
            helperText={getError('firstName')}
            required
          />
          <TextInput
            label="Last name"
            name="lastName"
            onChange={handleChange}
            onBlur={event => {
              event.target.value = event.target.value.trim()
              setFieldValue('lastName', event.target.value)
              handleBlur(event)
            }}
            error={getError('lastName')}
            helperText={getError('lastName')}
            required
          />
          <TextInput
            label="Email"
            name="email"
            onChange={handleChange}
            onBlur={event => {
              event.target.value = event.target.value.trim()
              setFieldValue('email', event.target.value)
              handleBlur(event)
            }}
            error={getError('email')}
            helperText={getError('email')}
            required
          />
          <TextInput
            label="Phone"
            name="phone"
            patternFormatOptions={{
              format: '(###) ###-####',
            }}
            onChange={handleChange}
            onBlur={event => {
              event.target.value = event.target.value.trim()
              setFieldValue('phone', event.target.value)
              handleBlur(event)
            }}
            error={getError('phone')}
            helperText={getError('phone')}
          />
        </InputContainer>
      </Drawer>
    </>
  )
}

export const CreateTenantForm = withFormik(createTenantSchema)(CreateTenantDrawer)
